import React, { FC, useState } from 'react'
import {
  Country,
  EventType,
  FieldStatus,
  getPaginatedCollection,
  ROWS_10,
  ROWS_ALL_RESULT,
  ROWS_WITH_ALL_RESULT,
  SelectOption,
  Table,
  TableTitleItem
} from '@inpi-marques/components'
import Message from '../../constants/Message'
import CountryRowContent from './CountryRowContent'
import { Country as FRMICountry } from '../../interfaces/frmi/FRMI'

interface CountriesTableProps {
    countries: FRMICountry[],
    editing?: boolean,
    page: number,
    countriesFromDB?: Country[],
    countriesOptions?: SelectOption[],
    fieldStatus?: FieldStatus,
    setFieldStatus?: (fieldStatus: FieldStatus) => void,
    setCountries?: (countries: FRMICountry[]) => void,
    onCountriesChanged?: (countries: FRMICountry[]) => void,
    setPage?: (page: number) => void,
}

const CountriesTable: FC<CountriesTableProps> = ({
  countries,
  setCountries,
  editing = false,
  onCountriesChanged,
  page,
  setPage,
  countriesFromDB,
  fieldStatus,
  setFieldStatus
}) => {
  const [pageSize, setPageSize] = useState<number>(ROWS_10.value / 2)

  /** Nombre
   *  d'éléments par page du tableau */
  const countriesPairArray = countries.reduce((acc, cur, currentIndex, array: FRMICountry[]) => {
    if (currentIndex % 2 === 0) {
      acc.push(array.slice(currentIndex, currentIndex + 2))
    }
    return acc
  }, [])
  const paginatedCountries = countriesPairArray ? getPaginatedCollection(countriesPairArray, pageSize) : []

  const onDelete = (countryIndex: number): void => {
    const newCountries: FRMICountry[] = countries.filter((country, index) => index !== countryIndex)
    setCountries && setCountries(newCountries)
    onCountriesChanged && onCountriesChanged(newCountries)
    onDeleteChangePagination(newCountries)
  }

  const getTableTitle = () => {
    const tableTitles: TableTitleItem[] = []
    tableTitles.push({ label: Message.titles_column_label_country_code, className: 'table-column-label w-15' })
    tableTitles.push({ label: Message.titles_column_label_country_name, className: 'table-column-label w-65' })
    tableTitles.push({ label: '', className: 'table-column-label pr-4 w-20' })
    if (countries.length > 1) {
      tableTitles.push({ label: Message.titles_column_label_country_code, className: 'table-column-label w-15' })
      tableTitles.push({ label: Message.titles_column_label_country_name, className: 'table-column-label w-65' })
      tableTitles.push({ label: '', className: 'table-column-label pr-4 w-20' })
    }

    return tableTitles
  }

  const onDeleteChangePagination = (countries: FRMICountry[]): void => {
    const newPaginatedCountry: FRMICountry[][] = countries ? getPaginatedCollection(countries, pageSize) : []
    // Si on supprime le dernier élément d'une page, on passe à la page précédente
    if (newPaginatedCountry.length > 0 && newPaginatedCountry.length < (page + 1)) {
      setCountries && setPage(newPaginatedCountry.length - 1)
    }
  }

  return (
    <>
      <Table
        tableTitle={getTableTitle()}
        tableClassName='title-table'
        actualPage={page + 1}
        onChangePagination={(value) => setPage && setPage(value - 1)}
        showPagination
        totalPage={paginatedCountries.length}
        nbShown={ROWS_WITH_ALL_RESULT}
        valueSelect={pageSize * 2}
        onChangeSelect={(event: EventType) => {
          setPageSize(event.target.value === ROWS_ALL_RESULT.value.toString() ? countries.length : (parseInt(event.target.value, 10) / 2))
          setPage(0)
        }}
      >
        <tbody>
          {paginatedCountries[page] &&
            paginatedCountries[page].map((countriesPair: FRMICountry[], index: number) => {
              const countryIndex: number = ((pageSize * page) + index) * 2
              return (
                <tr key={`${countryIndex}-${index}`}>
                  <CountryRowContent
                    countries={countries}
                    country={countriesPair[0]}
                    setCountries={setCountries}
                    editing={editing}
                    onCountriesChanged={onCountriesChanged}
                    isNewCountry={!countriesPair[0].code}
                    onDelete={onDelete}
                    countriesFromDB={countriesFromDB}
                    fieldStatus={fieldStatus}
                    setFieldStatus={setFieldStatus}
                    countryIndex={countryIndex}
                  />
                  {countriesPair[1] &&
                    <CountryRowContent
                      countries={countries}
                      country={countriesPair[1]}
                      setCountries={setCountries}
                      editing={editing}
                      onCountriesChanged={onCountriesChanged}
                      isNewCountry={!countriesPair[1].code}
                      onDelete={onDelete}
                      countriesFromDB={countriesFromDB}
                      fieldStatus={fieldStatus}
                      setFieldStatus={setFieldStatus}
                      countryIndex={countryIndex + 1}
                    />}
                </tr>
              )
            })}
        </tbody>
      </Table>
    </>

  )
}

export default CountriesTable
