import { Brand, EventType, FieldStatus, SwitchField, TextArea, Transaction } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from '../../../../store/transaction/transactionActions'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import VariousIndicationsTranslation from './components/VariousIndicationsTranslation'

interface VariousIndicationsProps {
  transaction: Transaction,
  fieldStatus: FieldStatus
}

const VariousIndications: FC<VariousIndicationsProps> = ({ transaction, fieldStatus }) => {
  const dispatch = useDispatch()

  const [brand, setBrand] = useState<Brand|undefined>(transaction.frmiDetails?.brand)

  useEffect(() => {
    setBrand(transaction.frmiDetails?.brand)
  }, [transaction.frmiDetails?.brand])

  const onBrandElementsChange = (event: EventType): void => {
    const { name, value } = event.target
    onBrandChange({ ...brand, [name]: value })
  }

  const onIsSoundTypeChange = (): void => {
    onBrandChange({ ...brand, isSoundType: !brand?.isSoundType })
  }

  const onIs3DTypeChange = (): void => {
    onBrandChange({ ...brand, is3DType: !brand?.is3DType })
  }

  const onIsCollectiveTypeChange = (): void => {
    onBrandChange({ ...brand, isCollectiveCertificationOrGuarantee: !brand?.isCollectiveCertificationOrGuarantee })
  }

  const onBrandChange = (updatedBrand: Brand): void => {
    setBrand(updatedBrand)
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      brand: updatedBrand
    }))
  }

  return (
    <div className='colorsClaimed'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='select_frmi_various_indications_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_various_indications_description' /></span>
        </header>
        <InternalReferenceField
          className='col-3'
          transaction={transaction}
        />
      </div>
      <div className='row col-md-12'>
        <div className='d-flex'>
          <h2 className='text-primary'>
            <FormattedMessage id='select_frmi_various_indications_title' />
          </h2>
        </div>
        <div className='row d-flex col-md-12'>
          <div>
            <TextArea
              inputId='transliteration'
              label={<FormattedMessage id='various_indications_transliteration_label' />}
              value={brand?.transliteration}
              onChange={onBrandElementsChange}
              fieldStatus={fieldStatus}
            />
            <div className='mt-4'>
              <SwitchField
                inputId='isSoundType'
                label={<FormattedMessage id='various_indications_is_sound_type_label' />}
                value={brand?.isSoundType}
                onChange={onIsSoundTypeChange}
              />
            </div>
            <div className='mt-4'>
              <SwitchField
                inputId='is3DType'
                label={<FormattedMessage id='various_indications_is_3d_type_label' />}
                value={brand?.is3DType}
                onChange={onIs3DTypeChange}
              />
            </div>
            <div className='mt-4'>
              <SwitchField
                inputId='isCollectiveCertificationOrGuarantee'
                label={<FormattedMessage id='various_indications_is_collective_type_label' />}
                value={brand?.isCollectiveCertificationOrGuarantee}
                onChange={onIsCollectiveTypeChange}
              />
            </div>
            <div className='mt-4'>
              <TextArea
                inputId='verbalElements'
                label={<FormattedMessage id='various_indications_verbal_elements_label' />}
                value={brand?.verbalElements}
                onChange={onBrandElementsChange}
                fieldStatus={fieldStatus}
              />
            </div>
            <div className='mt-4'>
              <TextArea
                inputId='unclaimedElements'
                label={<FormattedMessage id='various_indications_unclaimed_elements_label' />}
                value={brand?.unclaimedElements}
                onChange={onBrandElementsChange}
                fieldStatus={fieldStatus}
              />
            </div>
          </div>
          <div className='mt-3'>
            <div className='d-flex'>
              <h2 className='text-primary'>
                <FormattedMessage id='various_indications_description_part_label' />
              </h2>
            </div>
            <div>
              <TextArea
                inputId='description'
                label={<FormattedMessage id='various_indications_description_label' />}
                value={brand?.description}
                onChange={onBrandElementsChange}
                fieldStatus={fieldStatus}
              />
              <div className='mt-4'>
                <TextArea
                  inputId='voluntaryDescription'
                  label={<FormattedMessage id='various_indications_voluntary_description_label' />}
                  value={brand?.voluntaryDescription}
                  onChange={onBrandElementsChange}
                  fieldStatus={fieldStatus}
                />
              </div>
            </div>
          </div>
          <VariousIndicationsTranslation brand={brand} fieldStatus={fieldStatus} onBrandUpdated={onBrandChange} />
        </div>
      </div>
    </div>
  )
}

export default VariousIndications
