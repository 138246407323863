import React, { FC, useState } from 'react'
import {
  ErrorField,
  EventType,
  FieldStatus,
  isTextLatin,
  PROCEDURE_DEPOSIT,
  PRODUCT_STATUS,
  ProductAndService,
  ProductAndServiceListView,
  ProductAndServicesAccordion,
  ProductClass,
  ProductService as CommonProductsService,
  SelectField,
  SubmitButton,
  TextArea
} from '@inpi-marques/components'
import { range } from 'lodash'
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl'
import ProductsService from 'services/deposit/ProductsService'
import Message from 'constants/Message'

interface ProductAndServicesFreeInputProps {
  productClasses: ProductClass[],
  onProductClassesEdited: (newArray: ProductClass[]) => void,
  classNameFormGroup?: string,
  isEditable?: boolean,
  areContestedProducts?: boolean,
  inModalWithSubmit?: boolean,
  procedureType?: string,
  duplicateClassesOnCreate?: boolean, // si on sélectionne une classe existante et qu'on ajoute un produit, cela créer une nouvelle ligne avec le même numéro de classe
  isInitialVersion?: boolean,
  asLink?: boolean,
  shouldResetSelectOnSubmit?: boolean,
  hasDefaultValue?: boolean,
  classname?: string,
  canAddClasses?: boolean,
  readonly?: boolean
}

/**
 * Ce composant gère l'ajout de produits grâce à une saisie libre
 */
const ProductAndServicesFreeInput: FC<ProductAndServicesFreeInputProps> = ({
  productClasses,
  onProductClassesEdited,
  classNameFormGroup = '',
  isEditable = false,
  areContestedProducts = false,
  inModalWithSubmit = false,
  procedureType,
  duplicateClassesOnCreate,
  isInitialVersion = false,
  asLink = false,
  shouldResetSelectOnSubmit = false,
  hasDefaultValue = true,
  classname,
  canAddClasses = true,
  readonly = false
}) => {
  const intl = useIntl()
  /** Numéro de la classe */
  const [ref, setRef] = useState<number|undefined>(hasDefaultValue ? 1 : undefined)

  const [strProducts, setStrProducts] = useState<string>()
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const [addClass, setAddClass] = useState(false)
  /**
   * On peut ajouter plusieurs produits en une soumission.
   * Pour ce faire, chaque nom de produits doit être séparé par un ';' ou '.'
   */
  const onSubmit = async (): Promise<void> => {
    if (strProducts) {
      if (!isTextLatin(strProducts)) {
        setFieldStatus({ description_missing: Message.text_not_latin })
        return
      }
      setFieldStatus(undefined)

      // On récupère les produits, tous séparés par un ;
      let splittedDescription: string[] = strProducts.split(/;|\./)
      splittedDescription = splittedDescription.map((p: string) => p.trim())
      splittedDescription = [...new Set(splittedDescription)]

      // On filtre au cas où il aurait un produit vide, puis on crée la liste
      // Pour une version Initial, on ne met pas le statut des produits à ADDED
      const products: ProductAndService[] = splittedDescription
        .filter((productName: string) => productName)
        .map((productName: string) => ({
          name: productName,
          ref: productName,
          origin: 'free',
          status: isInitialVersion ? undefined : PRODUCT_STATUS.ADDED,
          separator: strProducts.indexOf(`${productName}.`) !== -1 ? '.' : undefined
        }))

      let updatedProductClass: ProductClass = { ref: ref.toString(), products }

      if (procedureType === PROCEDURE_DEPOSIT.value) {
        const validatedClasses: ProductClass[]|null = await ProductsService.validateTMClass([updatedProductClass])
        if (validatedClasses?.length) {
          updatedProductClass = validatedClasses[0]
        }
      }

      // On met à jour la liste déjà existante
      const updatedSelectedList: ProductClass[] = duplicateClassesOnCreate ? [...productClasses, updatedProductClass] : CommonProductsService.getUpdateSelectedListFromList(productClasses, [updatedProductClass], false)

      // On met à jour la liste globale
      onProductClassesEdited(CommonProductsService.sortClasses(updatedSelectedList))

      // Reset sur champs texte
      setStrProducts('')
      if (shouldResetSelectOnSubmit) {
        setRef(undefined)
      }
    } else {
      setFieldStatus({ description_missing: Message.products_and_services_description_missing })
    }
  }

  /**
   * Lors de la suppression d'un produit
   * @param editedProductClass
   * @param deletedProduct
   */
  const onAccordionProductDelete = (editedProductClass: ProductClass, deletedProduct: ProductAndService): void => {
    onProductClassesEdited(
      productClasses.map((productClass: ProductClass) => editedProductClass.ref === productClass.ref
        ? ({
          ...productClass,
          products: productClass.products.filter((product: ProductAndService) => product.name !== deletedProduct.name)
        })
        : productClass)
    )
  }

  /**
   * Lors de l'edition d'un produit
   * @param editedProductClass
   */
  const onAccordionProductEdit = (editedProductClass: ProductClass): void => {
    onProductClassesEdited(
      productClasses.map((productClass: ProductClass) => {
        if (productClass.ref === editedProductClass.ref) {
          return editedProductClass
        }
        return productClass
      })
    )
  }

  /**
   * Lors de la suppression d'une classe contenant un ou plusieurs produits
   * @param deletedClass
   */
  const onAccordionClassDelete = (deletedClass: ProductClass): void => {
    onProductClassesEdited(
      productClasses.filter((productClass: ProductClass) => deletedClass.ref !== productClass.ref)
    )
  }

  /**
   * Suppression d'une classe de produits
   * On met à jour les produits qui existaient dans le version original
   * et on supprime les produits ajoutés recemment (avec statut ADDED)
   * @param deletedProductClass
   * @param indexToDelete
   */
  const onProductClassDeleted = (deletedProductClass: ProductClass, indexToDelete?: number): void => {
    const productClassesSorted = CommonProductsService.sortClasses(productClasses)
    onProductClassesEdited(CommonProductsService.editProductsOnProductClassDeleted(productClassesSorted, deletedProductClass, indexToDelete))
  }

  /**
   * Rollback d'une suppression d'une classe de produits
   * @param classToRollback
   * @param index
   */
  const onProductClassUndoDeleted = (classToRollback: ProductClass, index?: number): void => {
    const productClassesSorted = CommonProductsService.sortClasses(productClasses)
    onProductClassesEdited(CommonProductsService.editProductsOnProductClassUndoDeleted(productClassesSorted, classToRollback, index))
  }

  /**
   * Rollback de suppression d'un produit
   * @param classToRollback
   * @param product
   * @param index
   */
  const onProductUndoDeleted = (classToRollback: ProductClass, product: ProductAndService, index?: number): void => {
    const productClassesSorted = CommonProductsService.sortClasses(productClasses)
    onProductClassesEdited(CommonProductsService.editProductsOnProductUndoDeleted(productClassesSorted, classToRollback, product, index))
  }

  /**
   * Suppression d'un produit de la classe
   * Si le produit a un statut ADDED, alors on le supprime complètement de la liste des produits
   * @param editedProductClass
   * @param deletedProduct
   * @param classIndex
   */
  const onListViewProductDelete = (editedProductClass: ProductClass, deletedProduct: ProductAndService, classIndex?: number): void => {
    const productClassesSorted = CommonProductsService.sortClasses(productClasses)
    onProductClassesEdited(productClassesSorted.map((productClass: ProductClass, index: number) => (index !== undefined ? classIndex === index : editedProductClass.ref === productClass.ref)
      ? {
        ...productClass,
        products: productClass.products
          .filter((product: ProductAndService) => !(product.name === deletedProduct.name && product.status === PRODUCT_STATUS.ADDED))
          .map((product: ProductAndService) => product.name === deletedProduct.name
            ? { ...product, name: product.name?.replace(/\.|;/g, ''), status: PRODUCT_STATUS.DELETED } : product
          )
      }
      : productClass)
    )
  }

  /**
   * A l'édition de la liste de produits d'une classe
   * @param editedProductClass
   * @param stringProducts
   * @param productClassIndex
   */
  const onProductClassesChanged = async (editedProductClass: ProductClass, stringProducts: string, productClassIndex?: number): Promise<void> => {
    const productClassesSorted = CommonProductsService.sortClasses(productClasses)
    // On compare avec la version originale, car des produits déjà existant qui ont potentiellement était supprimés peuvent à ajouter.
    const originalClass: ProductClass|undefined = productClassesSorted.find((productClass: ProductClass, index: number) => editedProductClass.ref === productClass.ref && (typeof productClassIndex !== 'undefined' ? index === productClassIndex : true))

    if (originalClass) {
      let updatedProductClass: ProductClass = CommonProductsService.updateProductClassFromProducts(originalClass, stringProducts)

      // On check la validité des produits ajoutés
      if (procedureType === PROCEDURE_DEPOSIT.value) {
        const checkedProductClasses: ProductClass[] | null = await ProductsService.validateTMClass([updatedProductClass])
        if (checkedProductClasses?.length) {
          updatedProductClass = checkedProductClasses[0]
        }
      }

      onProductClassesEdited(productClassesSorted.map((productClass: ProductClass, index: number) => updatedProductClass.ref === productClass.ref && (typeof productClassIndex !== 'undefined' ? index === productClassIndex : true) ? updatedProductClass : productClass))
    }
  }

  const productClassesToDisplay: ProductClass[] = isEditable ? productClasses : CommonProductsService.getProductsByOrigin(productClasses, 'free')

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className={`mt-5 ${classname || ''}`}>
        {asLink && canAddClasses &&
          <div className='btn btn-link text-primary' onClick={() => setAddClass(!addClass)}>
            <FormattedMessage
              id={addClass ? 'contested_registration_products_and_services_less' : 'contested_registration_products_and_services_add_ps'}
            />
          </div>}
        {(!asLink || addClass) && canAddClasses &&
          <>
            <SelectField
              label={<FormattedMessage id='products_and_services_class' />}
              classNameFormGroup={`col-md-12 col-lg-6 ${classNameFormGroup}`}
              inputId='type'
              placeholder={intl.formatMessage({ id: 'products_and_services_select_class' })}
              value={ref}
              onChange={(event: EventType) => setRef(event.target.value)}
              options={[...range(1, 46)].map((classNumber: number) => ({
                label: `${Message.products_and_services_class} ${classNumber}`,
                value: classNumber
              }))}
            />

            <TextArea
              inputId='brand-description'
              classNameFormGroup={`col-md-12 col-lg-6 ${classNameFormGroup}`}
              label={<FormattedMessage id={`${procedureType?.toLowerCase()}_products_and_services_description`} />}
              subtitle={(
                <div className='small'>
                  <FormattedMessage
                    id={
                      areContestedProducts
                        ? `${procedureType?.toLowerCase()}_contested_products_and_services_description_subtitle`
                        : (inModalWithSubmit ? 'products_and_services_in_modal_description_subtitle' : 'products_and_services_description_subtitle')
                    }
                    values={{
                      linebreak: <br />
                    }}
                  />
                </div>
              )}
              value={strProducts}
              onChange={(event: EventType) => setStrProducts(event.target.value)}
              required
              maxLength={30000}
              fieldStatus={fieldStatus}
              nameFieldStatus='description_missing'
            />
            <SubmitButton
              className='btn-primary col-1 ml-3 mt-2'
              onClick={onSubmit}
              disabled={!ref}
            >
              <FormattedMessage id='common_add' />
            </SubmitButton>
          </>}
        {
          fieldStatus && fieldStatus.products && <ErrorField message={fieldStatus.products} className='mt-3' />
        }
        {productClasses.length > 0 &&
          <>
            {isEditable &&
              <ProductAndServiceListView
                productClasses={productClasses}
                onProductClassDelete={onProductClassDeleted}
                onProductClassUndoDelete={onProductClassUndoDeleted}
                onProductUndoDelete={onProductUndoDeleted}
                onProductDelete={onListViewProductDelete}
                onProductClassEdited={onProductClassesChanged}
                readOnly={readonly}
              />}
            {!isEditable &&
              <ProductAndServicesAccordion
                procedureType={procedureType}
                productClasses={productClassesToDisplay}
                onProductDelete={onAccordionProductDelete}
                onProductEdit={onAccordionProductEdit}
                onClassDelete={onAccordionClassDelete}
                displayHelpBlock
                className='mt-5'
              />}
          </>}
      </div>
    </IntlProvider>

  )
}
export default ProductAndServicesFreeInput
