import React, { FC, useEffect, useState } from 'react'
import {
  FilAriane,
  FieldStatus,
  DEFAULT_CONTRIBUTOR,
  ModalComponent,
  EditorialBlock,
  PROCEDURE_OPPOSITION,
  OPPOSITION_CONTRIBUTORS_OPTIONS,
  OPPOSITION_CONTRIBUTORS_TYPES,
  ContributorValidator,
  TransactionDocument,
  Transaction,
  EventType, Contributor, containsErrors, CommonContributorService, CONTRIBUTOR_OPPONENTS
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import ContributorFrontForm from '../../contributors/ContributorFrontForm'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from 'store/transaction/transactionActions'
import TransactionService from 'services/transaction/TransactionService'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import ContributorAgent from '../../contributors/ContributorAgent'
import ContributorService from '../../../services/contributors/ContributorService'
import ContentService from '../../../services/content/ContentService'
import ContributorList from '../../contributors/ContributorList'
import OpponentsValidator from './opponents/validator/OpponentsValidator'
import StatementOfMeans from './statementOfMeans/StatementOfMeans'
import StatementValidator from './statementOfMeans/validator/StatementOfMeansValidator'
import OppositionAnnexes from './annexes/OppositionAnnexes'
import Recap from '../../recap/Recap'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import {
  PAYMENT_LABELS,
  PAYMENT_OBJECTS,
  PAYMENT_URL_OPPOSITION
} from '../../../constants/PaymentConstants'
import PaymentForm from '../../payment/PaymentForm'
import RegistrationRequest from './registrationRequest/RegistrationRequest'
import ContestedRequestValidator from './registrationRequest/validator/ContestedRequestValidator'

import FoundationsForm from './foundations/Foundations'
import FoundationValidator from './foundations/validator/FoundationValidator'
import DocumentService from 'services/document/DocumentService'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
/* global localStorage */

interface OppositionFormProps extends RouteComponentProps {
  isNewTransaction : boolean
}

const OppositionForm: FC<OppositionFormProps> = ({
  history,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const fieldStatus: FieldStatus = useSelector((state : RootStateOrAny) => state.transactionFieldStatus)
  const transaction: Transaction = useSelector((state : RootStateOrAny) => state.transaction)
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const [documents, setDocuments] = useState<TransactionDocument[]>([])
  const [required, setIsRequired] = useState<boolean>(false)

  const handleSubmit = () => {
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        setDocuments(result.documents)
      })
    } else {
      dispatch(storeTransactionInit(PROCEDURE_OPPOSITION.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-OPPOSITION').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  useEffect(() => {
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  const saveObjectionOpponents = async () => {
    // Supprime les opposants non conformes
    let newContributorList : Contributor[] = []
    transaction.opponents.map((contributor) => {
      const fieldStatusContributor = ContributorValidator.validateContributor(contributor, transaction, CONTRIBUTOR_OPPONENTS.value)
      if (!containsErrors(fieldStatusContributor)) {
        newContributorList = [...newContributorList, contributor]
      }
    })

    if (isNew) {
      setIsNew(false)
      return await TransactionService.createTransaction({ ...transaction, opponents: newContributorList }, PROCEDURE_OPPOSITION.value, [OPPOSITION_CONTRIBUTORS_TYPES.OPPONENTS.value])
    } else {
      return await TransactionService.updateTransactionBDDFromStore({ ...transaction, opponents: newContributorList }, [OPPOSITION_CONTRIBUTORS_TYPES.OPPONENTS.value])
    }
  }

  const onDocumentChanged = (event: EventType, type: string) => {
    const { value } = event.target

    const newFile: TransactionDocument = {
      id: documents.length,
      type: type,
      internalName: '',
      name: value.name,
      format: value.type,
      file: value
    }
    setDocuments([...documents, newFile])
  }

  const onDocumentDeleted = (event: EventType) => {
    const { value } = event.target
    setDocuments(documents.filter((file: TransactionDocument) => file.internalName !== value.internalName))
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_opposition' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='breadcrumb_new_opposition' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        views={[
          {
            id: 'contributors',
            label: <FormattedMessage id='opposition_contributors_label' />
          },
          {
            id: 'contributors_opponent',
            label: <FormattedMessage id='contributors_opponent_label' />,
            level: 1,
            component: (
              <ContributorList
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={OPPOSITION_CONTRIBUTORS_TYPES.OPPONENTS.value}
                contributorTypeToCopy={[
                  OPPOSITION_CONTRIBUTORS_TYPES.AGENT.value,
                  OPPOSITION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  OPPOSITION_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={OPPOSITION_CONTRIBUTORS_OPTIONS.opponents}
                procedure={PROCEDURE_OPPOSITION.value}
                validateContributor={ContributorValidator.validateContributor}
                openOnFirstOne={!transaction?.opponents?.length}
                setIsRequired={setIsRequired}
              />),
            stepButtonsAsChildren: true,
            validateGoToStep: () => OpponentsValidator.validateOnChangeStepOpponents(),
            onGoToStep: () => saveObjectionOpponents()
          },
          {
            id: 'contributors_agent',
            label: <FormattedMessage id='contributor_agent' />,
            level: 1,
            component: (
              <ContributorAgent
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorTypeToCopy={[
                  OPPOSITION_CONTRIBUTORS_TYPES.OPPONENTS.value,
                  OPPOSITION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  OPPOSITION_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                procedure={PROCEDURE_OPPOSITION.value}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepAgentDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [OPPOSITION_CONTRIBUTORS_TYPES.AGENT.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, OPPOSITION_CONTRIBUTORS_TYPES.AGENT.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={OPPOSITION_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  OPPOSITION_CONTRIBUTORS_TYPES.OPPONENTS.value,
                  OPPOSITION_CONTRIBUTORS_TYPES.AGENT.value,
                  OPPOSITION_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={OPPOSITION_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_OPPOSITION.value}
                defaultContributor={{ ...DEFAULT_CONTRIBUTOR, email: user.email, consentNotificationByEmail: user.consentNotificationByEmailPreferences }}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore({
              ...transaction,
              [OPPOSITION_CONTRIBUTORS_TYPES.RECIPIENT.value]: CommonContributorService.filterContributorCivilityCode(transaction[OPPOSITION_CONTRIBUTORS_TYPES.RECIPIENT.value])
            }, [OPPOSITION_CONTRIBUTORS_TYPES.RECIPIENT.value])
          },
          {
            id: 'contributors_signatory_opposition',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={OPPOSITION_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={OPPOSITION_CONTRIBUTORS_OPTIONS.signatory}
                procedure={PROCEDURE_OPPOSITION.value}
                defaultContributor={ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [OPPOSITION_CONTRIBUTORS_TYPES.SIGNATORY.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, OPPOSITION_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'registration_request_contested',
            label: <FormattedMessage id='opposition_registration_request_contested_label' />,
            component: (
              <RegistrationRequest
                transaction={transaction}
                fieldStatus={fieldStatus}
                onDocumentDeleted={onDocumentDeleted}
                documents={documents}
                setDocuments={setDocuments}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: () => ContestedRequestValidator.validateOnChangeStepContestedRequest(documents, PROCEDURE_OPPOSITION.value),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['opposition'])
          },
          {
            id: 'opposition_foundations',
            label: <FormattedMessage id='opposition_foundations_label' />,
            component: <FoundationsForm setTransactionDocuments={setDocuments} transaction={transaction} fieldStatus={fieldStatus} setIsRequired={setIsRequired} />,
            stepButtonsAsChildren: true,
            validateGoToStep: () => FoundationValidator.validateOnChangeStep(transaction),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['opposition'])
          },
          {
            id: 'statement_of_means',
            label: <FormattedMessage id='statement_of_means_label' />,
            component: (
              <StatementOfMeans
                transaction={transaction}
                onDocumentChanged={onDocumentChanged}
                onDocumentDeleted={onDocumentDeleted}
                documents={documents}
                fieldStatus={fieldStatus}
                required={DocumentService.isStatementOfMeanRequired(transaction)}
              />
            ),
            validateGoToStep: () => StatementValidator.getValidatedDocument(transaction, documents),
            onGoToStep: () => TransactionService.updateDocumentTransaction(transaction, documents)
          },
          {
            id: 'annexes',
            label: <FormattedMessage id='annexes_label' />,
            component: (
              <OppositionAnnexes
                transaction={transaction}
                documents={documents}
                setDocuments={setDocuments}
              />
            ),
            onGoToStep: () => TransactionService.updateDocumentTransaction(transaction, documents)
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.OPPOSITION}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_OPPOSITION}
              />
            )
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(OppositionForm)
