/**
 * TYPE DE FONDEMENT
 */

import FoundationAoigForm from '../../../src/opposition/foundations/types/aoig/FoundationAoigForm'
import FoundationBrandForm from '../../../src/opposition/foundations/types/brand/FoundationBrandForm'
import FoundationCollectivityForm from '../../../src/opposition/foundations/types/collectivity/FoundationCollectivityForm'
import FoundationDenominationForm from '../../../src/opposition/foundations/types/denomination/FoundationDenominationForm'
import FoundationDomainNameForm from '../../../src/opposition/foundations/types/domain-name/FoundationDomainNameForm'
import FoundationIgCraftForm from '../../../src/opposition/foundations/types/igcraft/FoundationIgCraftForm'
import FoundationInstitutionForm from '../../../src/opposition/foundations/types/institution/FoundationInstitutionForm'
import FoundationRenownedForm from '../../../src/opposition/foundations/types/renowned/FoundationRenownedForm'
import FoundationTradeNameForm from '../../../src/opposition/foundations/types/trade-name/FoundationTradeNameForm'
import FoundationUnauthorizedForm from '../../../src/opposition/foundations/types/unauthorized/FoundationUnauthorizedForm'
import { OppositionFoundationType } from '../../../src/interfaces/opposition/Opposition'
import Message from '../Message'
import { SelectOption } from '../../form/FormInterfaces'

export const OPPOSITION_FOUNDATION_TYPE_BRAND: OppositionFoundationType = {
  value: 'BRAND',
  label: Message.foundation_type_brand_label,
  component: FoundationBrandForm
}

export const OPPOSITION_FOUNDATION_TYPE_RENOWNED: OppositionFoundationType = {
  value: 'RENOWNED',
  label: Message.foundation_type_renowned_label,
  component: FoundationRenownedForm
}

export const OPPOSITION_FOUNDATION_TYPE_COLLECTIVITY: OppositionFoundationType = {
  value: 'COLLECTIVITY',
  label: Message.foundation_type_collectivity_label,
  component: FoundationCollectivityForm
}

export const OPPOSITION_FOUNDATION_TYPE_INSTITUTION: OppositionFoundationType = {
  value: 'INSTITUTION',
  label: Message.foundation_type_institution_label,
  component: FoundationInstitutionForm
}

export const OPPOSITION_FOUNDATION_TYPE_IGCRAFT: OppositionFoundationType = {
  value: 'IGCRAFT',
  label: Message.foundation_type_igcraft_label,
  component: FoundationIgCraftForm
}

export const OPPOSITION_FOUNDATION_TYPE_AOIG: OppositionFoundationType = {
  value: 'AOIG',
  label: Message.foundation_type_aoig_label,
  component: FoundationAoigForm
}

export const OPPOSITION_FOUNDATION_TYPE_UNAUTHORIZED: OppositionFoundationType = {
  value: 'UNAUTHORIZED',
  label: Message.foundation_type_unauthorized_label,
  component: FoundationUnauthorizedForm
}

export const OPPOSITION_FOUNDATION_TYPE_DENOMINATION: OppositionFoundationType = {
  value: 'DENOMINATION',
  label: Message.foundation_type_denomination_label,
  component: FoundationDenominationForm
}

export const OPPOSITION_FOUNDATION_TYPE_TRADE_NAME: OppositionFoundationType = {
  value: 'TRADE_NAME',
  label: Message.foundation_type_trade_name_label,
  component: FoundationTradeNameForm
}

export const OPPOSITION_FOUNDATION_TYPE_DOMAIN_NAME: OppositionFoundationType = {
  value: 'DOMAIN_NAME',
  label: Message.foundation_type_domain_name_label,
  component: FoundationDomainNameForm
}

export const OPPOSITION_FOUNDATION_TYPES: OppositionFoundationType[] = [
  OPPOSITION_FOUNDATION_TYPE_BRAND,
  OPPOSITION_FOUNDATION_TYPE_RENOWNED,
  OPPOSITION_FOUNDATION_TYPE_COLLECTIVITY,
  OPPOSITION_FOUNDATION_TYPE_INSTITUTION,
  OPPOSITION_FOUNDATION_TYPE_IGCRAFT,
  OPPOSITION_FOUNDATION_TYPE_AOIG,
  OPPOSITION_FOUNDATION_TYPE_UNAUTHORIZED,
  OPPOSITION_FOUNDATION_TYPE_DENOMINATION,
  OPPOSITION_FOUNDATION_TYPE_TRADE_NAME,
  OPPOSITION_FOUNDATION_TYPE_DOMAIN_NAME
]

export const OPPOSITION_FOUNDATION_TYPES_SELECT_OPTIONS : SelectOption[] = [
  {
    value: OPPOSITION_FOUNDATION_TYPE_DOMAIN_NAME.value,
    label: OPPOSITION_FOUNDATION_TYPE_DOMAIN_NAME.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_TRADE_NAME.value,
    label: OPPOSITION_FOUNDATION_TYPE_TRADE_NAME.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_DENOMINATION.value,
    label: OPPOSITION_FOUNDATION_TYPE_DENOMINATION.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_UNAUTHORIZED.value,
    label: OPPOSITION_FOUNDATION_TYPE_UNAUTHORIZED.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_AOIG.value,
    label: OPPOSITION_FOUNDATION_TYPE_AOIG.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_IGCRAFT.value,
    label: OPPOSITION_FOUNDATION_TYPE_IGCRAFT.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_INSTITUTION.value,
    label: OPPOSITION_FOUNDATION_TYPE_INSTITUTION.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_COLLECTIVITY.value,
    label: OPPOSITION_FOUNDATION_TYPE_COLLECTIVITY.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_RENOWNED.value,
    label: OPPOSITION_FOUNDATION_TYPE_RENOWNED.label
  }, {
    value: OPPOSITION_FOUNDATION_TYPE_BRAND.value,
    label: OPPOSITION_FOUNDATION_TYPE_BRAND.label
  }
]

/**
 * ORIGINE DE LA MARQUE
 */

export const OPPOSITION_FOUNDATION_BRAND_ORIGIN_FR = {
  value: 'FR',
  label: 'Marque française'
}

export const OPPOSITION_FOUNDATION_BRAND_ORIGIN_FRMI = {
  value: 'FRMI',
  label: 'Marque internationale désignant la France'
}

export const OPPOSITION_FOUNDATION_BRAND_ORIGIN_UEMI = {
  value: 'UEMI',
  label: 'Marque internationale désignant l\'Union Européenne'
}

export const OPPOSITION_FOUNDATION_BRAND_ORIGIN_UE = {
  value: 'UE',
  label: 'Marque de l’Union Européenne'
}

export const OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS = {
  value: 'NOTORIOUS',
  label: 'Marque notoire au sens de l’article 6bis de la Convention de Paris'
}

export const OPPOSITION_FOUNDATION_BRAND_ORIGINS = [
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_FR,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_FRMI,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_UEMI,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_UE,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS
]

export const OPPOSITION_FOUNDATION_BRAND_RENOWNED_ORIGINS = [
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_FR,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_FRMI,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_UEMI,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_UE
]

/**
 * QUALITE DE L'OPPOSANT
 */

export const OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER = {
  value: 'ORIGINAL_OWNER',
  label: 'Propriétaire dès l\'origine'
}

export const OPPOSITION_FOUNDATION_OPPONENT_QUALITY_TRANSFERED_OWNER = {
  value: 'TRANSFERED_OWNER',
  label: 'Propriétaire par suite d\'une transmission de propriété'
}

export const OPPOSITION_FOUNDATION_OPPONENT_QUALITY_LICENSEE = {
  value: 'LICENSEE',
  label: 'Licencié exclusif'
}

export const OPPOSITION_FOUNDATION_OPPONENT_QUALITIES = [
  OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER,
  OPPOSITION_FOUNDATION_OPPONENT_QUALITY_TRANSFERED_OWNER,
  OPPOSITION_FOUNDATION_OPPONENT_QUALITY_LICENSEE
]

export const OPPOSITION_FOUNDATION_UNAUTHORIZED_OPPONENT_QUALITIES = [
  OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER,
  OPPOSITION_FOUNDATION_OPPONENT_QUALITY_TRANSFERED_OWNER
]

export const OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_COMMERCIAL = {
  value: 'COMMERCIAL',
  label: 'Nom commercial'
}

export const OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_SIGN = {
  value: 'SIGN',
  label: 'Enseigne'
}

export const OPPOSITION_FOUNDATION_TRADENAME_ORIGINS = [
  OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_COMMERCIAL,
  OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_SIGN
]
