import React, { FC, FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router-dom'
import Header from '../component/header/Header'
import Footer from '../component/footer/Footer'
import { ENV_PRODUCTION } from '@inpi-marques/components'

interface PrivateRouteProps {
  component: FC<any>,
  authed: boolean,
  path: string,
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  authed,
  path,
  ...rest
}) => {
  return (
    <Route
      path={path}
      render={props => (
        authed ? (
          <>
            <Header />
            <div className='main px-4'>
              <Component path={path} {...rest} {...props} />
            </div>
            <Footer />
          </>
        ) : (
          process.env.REACT_APP_ENV === ENV_PRODUCTION
            ? (
              window.location.replace('https://procedures.inpi.fr/?/')
            )
            : (
              <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
              }}
              />)
        )
      )}
    />
  )
}

export default PrivateRoute
