import React, { FC } from 'react'

const FilAriane: FC = ({
  children
}) => (
  <ol className='breadcrumb'>
    {children}
  </ol>
)

export default FilAriane
