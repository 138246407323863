import React, { FC } from 'react'
import {
  DateUtils, FRMI_TYPE_EFFECT_STOP, Transaction
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import DivisionContentOverview from './overview/DivisionContentOverview'

interface AdditionalInformationOverviewProps {
    transaction: Transaction
}

const AdditionalInformationOverview: FC<AdditionalInformationOverviewProps> = ({ transaction }) => {
  return (
    <>
      {/* Nom de l'office */}
      {
        transaction.frmiDetails?.officeName &&
          <div className='mb-3'>
            <h4 className='font-weight-bold'>
              <FormattedMessage id={`${transaction.subProcedureType === FRMI_TYPE_EFFECT_STOP.value ? 'frmi_effect_stop_office_name_label' : 'frmi_nullification_office_name_label'}`} />
            </h4>
            <span>{transaction.frmiDetails?.officeName}</span>
          </div>
      }

      {/* Date de notification de l'enregistrement international */}
      {
        transaction.frmiDetails?.notificationDate &&
          <div className='mb-3'>
            <h4 className='font-weight-bold'>
              <FormattedMessage id='frmi_notification_date_label' />
            </h4>
            <span>{DateUtils.formatDateFr(transaction.frmiDetails?.notificationDate)}</span>
          </div>
      }

      {/* Numéro de référence de l'OMPI */}
      {
        transaction.frmiDetails?.ompiReferenceNumber &&
          <div className='mb-3'>
            <h4 className='font-weight-bold'>
              <FormattedMessage id='frmi_ompi_reference_number_label' />
            </h4>
            <span>{transaction.frmiDetails?.ompiReferenceNumber}</span>
          </div>
      }

      {/* Numéro de notification de l'OMPI */}
      {
        transaction.frmiDetails?.ompiNotificationNumber &&
          <div className='mb-3'>
            <h4 className='font-weight-bold'>
              <FormattedMessage id='frmi_ompi_notification_number_label' />
            </h4>
            <span>{transaction.frmiDetails?.ompiNotificationNumber}</span>
          </div>
      }

      {/* Date à laquelle l’invalidation a été prononcée */}
      {
        transaction.frmiDetails?.nullificationDate &&
          <div className='mb-3'>
            <h4 className='font-weight-bold'>
              <FormattedMessage id='frmi_nullification_date_label' />
            </h4>
            <span>{DateUtils.formatDateFr(transaction.frmiDetails?.nullificationDate)}</span>
          </div>
      }

      {/* Date à laquelle l’invalidation prend effet (le cas échéant) */}
      {
        transaction.frmiDetails?.effectDate &&
          <div className='mb-3'>
            <h4 className='font-weight-bold'>
              <FormattedMessage id={transaction.subProcedureType === FRMI_TYPE_EFFECT_STOP.value ? 'frmi_decision_effect_date_label' : 'frmi_ompi_effect_date_label'} />
            </h4>
            <span>{DateUtils.formatDateFr(transaction.frmiDetails?.effectDate)}</span>
          </div>
      }

      {/* Description */}
      {
        transaction.frmiDetails?.correctionDescription &&
          <div>
            <h4 className='font-weight-bold'>
              <FormattedMessage id='frmi_correction_decription_label' />
            </h4>
            <span>{transaction.frmiDetails?.correctionDescription}</span>
          </div>
      }
      {/* Autorité qui a prononcé l'invalidation */}
      {transaction.frmiDetails?.invalidationAuthority &&
        <div>
          <h4 className='font-weight-bold'>
            <FormattedMessage id='frmi_invalidation_authority_label' />
          </h4>
          <span>{transaction.frmiDetails?.invalidationAuthority}</span>
        </div>}

      {transaction.frmiDetails?.divisionDetails &&
        <DivisionContentOverview divisionDetails={transaction.frmiDetails.divisionDetails} />}
    </>
  )
}

export default AdditionalInformationOverview
