import { createIntl, IntlShape } from 'react-intl'
import axios, { CancelTokenSource } from 'axios'
import { toast } from 'react-toastify'
import { Record, RecordSearchParameters, RecordSearchResult, TransactionCountByStatus, VerifyNumnatParameters } from '@inpi-marques/components'
import Message from '../../constants/Message'
import http from '../../network/http-common'

class RecordService {
  intl: IntlShape
  source: CancelTokenSource

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Récupère le nombre de record aggrégé
   */
   getCount = async (): Promise<TransactionCountByStatus[]> => {
     try {
       return await http.get('/api/records/counts', { cancelToken: this.source.token })
     } catch (error) {
       toast.error(error.message)
       return Promise.reject(error)
     }
   }

   /**
    * Récupération d'un titre
    * @param id
    * @returns
    */
   getRecord = async (id: string): Promise<Record> => {
     try {
       return await http.get(`/api/records/${id}`)
     } catch (error) {
       toast.error(error.message)
       return Promise.reject(error)
     }
   }

   /**
   * Récupère une liste de titre en fonction des paramètres de recherche fournis
   */
    searchRecord = async (params: RecordSearchParameters): Promise<RecordSearchResult> => {
      try {
        return await http.get('/api/records', {
          params: params
        })
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

  /**
   * Vérifie un numnat dans la base publique de l'INPI
   */
  verifyNumnat = async (params: VerifyNumnatParameters, dontThrowError: boolean = false) : Promise<Record[]> => {
    try {
      return await http.get('/api/records/infos', {
        params: params
      })
    } catch (error) {
      if (dontThrowError) {
        return Promise.resolve([])
      } else {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }
  }

  /**
   * Vérifie un numnat dans la base Madrid
   */
   verifyNumnatFromMadrid = async (params: VerifyNumnatParameters, dontThrowError: boolean = false) : Promise<Record[]> => {
     try {
       return await http.get('/api/records/infos-madrid', {
         params: params
       })
     } catch (error) {
       if (dontThrowError) {
         return Promise.resolve([])
       } else {
         toast.error(error.message)
         return Promise.reject(error)
       }
     }
   }

  /**
   * Récupère un document d'un titre
   */
  getDocumentFile = async (idRecord: string, internalName: string): Promise<Blob> => {
    try {
      return await http.get(`/api/records/${idRecord}/documents/${internalName}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

   /**
   * Annule une requête en attente
   */
   cancelRequest = () => {
     this.source.cancel()
     this.source = axios.CancelToken.source()
   }
}

export default new RecordService()
