import { Contest, DateUtils, INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD, LinkedTransaction } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface ContestOverviewProps {
    contest: Contest
}

const ContestOverview: FC<ContestOverviewProps> = ({ contest }) => {
  return (
    <div className='row'>
      <div className='col-6'>
        <h4 className='font-weight-bold'><FormattedMessage id='contest_form_type_label' /></h4>
        <p className='subtitle'><FormattedMessage id={contest.type === 'RANKING' ? 'overview_contest_reward_ranking_label' : 'overview_contest_reward_industrial_label'} /></p>
      </div>

      <div className='col-6'>
        <h4 className='font-weight-bold'><FormattedMessage id='contest_title_label' /></h4>
        <p className='subtitle'>{contest.title}</p>
      </div>

      {contest.startDate !== contest.endDate &&
        <div className='col-12 d-flex p-0'>
          <div className='col-6'>
            <h4 className='font-weight-bold'><FormattedMessage id='contest_form_start_date_label' /></h4>
            <p className='subtitle'>{DateUtils.formatDateFr(contest.startDate)}</p>
          </div>
          <div className='col-6'>
            <h4 className='font-weight-bold'><FormattedMessage id='contest_form_end_date_label' /></h4>
            <p className='subtitle'>{DateUtils.formatDateFr(contest.endDate)}</p>
          </div>
        </div>}

      <div className='col-6'>
        <h4 className='font-weight-bold'><FormattedMessage id='contest_form_place_label' /></h4>
        <p className='subtitle'>{contest.place}</p>
      </div>

      {contest.startDate === contest.endDate &&
        <div className='col-6'>
          <h4 className='font-weight-bold'><FormattedMessage id='contest_form_date_label' /></h4>
          <p className='subtitle'>{DateUtils.formatDateFr(contest.startDate)}</p>
        </div>}

      {contest.type === INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD.value &&
        <div className='col-6'>
          <h4 className='font-weight-bold'><FormattedMessage id='overview_contest_reward_industrial_label' /></h4>
          <pre className='subtitle'>{contest.rewardKind}</pre>
        </div>}

      {contest.linkedTransactions.length > 0 &&
        <div className='col-6'>
          <h4 className='font-weight-bold'><FormattedMessage id='contest_inscriptions_label' /></h4>
          <p className='subtitle'>{contest.linkedTransactions.map((linkedTransaction: LinkedTransaction) => linkedTransaction.numNat).join(', ')}</p>
        </div>}
    </div>
  )
}

export default ContestOverview
