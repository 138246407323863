import React, { FC, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import PaymentForm from './PaymentForm'
import { FormattedMessage } from 'react-intl'
import { PAYMENT_OBJECTS, PAYMENT_URL_NOTIFICATION } from '../../constants/PaymentConstants'
import TransactionService from '../../services/transaction/TransactionService'
import { FilAriane, Notification, Transaction } from '@inpi-marques/components'
import Message from '../../constants/Message'
import { BASKET_HEADER, BASKET_ID, BASKET_TYPES } from '../../constants/BasketConstants'

interface PaymentNotificationProps {
    id: string,
    idNotification: string
}

const PaymentNotification: FC<PaymentNotificationProps> = ({
  id,
  idNotification
}) => {
  const history = useHistory()
  const [transaction, setTransaction] = useState<Transaction>()
  const [notification, setNotification] = useState<Notification>()

  useEffect(() => {
    TransactionService.getTransaction(id)
      .then((transaction) => {
        setTransaction(transaction)
        setNotification(transaction.notifications?.find((notification) => notification.id === idNotification))
      })
      .catch(() => {
        const basketNotif = BASKET_TYPES.find((basket) => basket.id === BASKET_ID.PENDING_NOTIFICATION)
        basketNotif && history.replace(`/liste/${basketNotif.urlParam}?corbeille=${BASKET_HEADER[0].id}`)
      })
  }, [id, idNotification, history])

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <Link key={`folder-${id}`} to={`/liste/notifications/demande/${transaction?.id}`}><FormattedMessage id='deposit_title' />
          {transaction && (transaction.numNat ? transaction.numNat : transaction.id)}
        </Link>
        <span><FormattedMessage id='overview_transaction_notification_one_payment' /></span>
      </FilAriane>
      <div className='container'>
        {transaction && notification?.id &&
          <PaymentForm
            label={Message.overview_transaction_notification_one_payment}
            transaction={transaction}
            urlType={PAYMENT_URL_NOTIFICATION}
            objectName={PAYMENT_OBJECTS.NOTIFICATION}
            objectId={notification?.id.toString()}
            title={
              <>
                <FormattedMessage id='overview_transaction_notification_one_payment' /> - <FormattedMessage id='deposit_title' />
                {transaction && (transaction.numNat ? transaction.numNat : transaction.id)}
              </>
            }
          >
            <div className='row mx-0 my-3 justify-content-center'>
              <Link
                className='col-2 btn btn-block btn-outline-gris'
                to={`/liste/notifications/demande/${transaction?.id}`}
              >
                <FormattedMessage id='button_return' />
              </Link>
            </div>
          </PaymentForm>}
      </div>
    </div>
  )
}

export default PaymentNotification
