import { Foundation, SwitchField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../constants/Message'

interface FoundationActiveProps {
  foundation: Foundation,
  setFoundation: (foundation: Foundation) => void,
  disableActiveField?: boolean,
}

/**
 * PARTIE BLOC FONDEMENT ACTIF OU PAS
 */
const FoundationActive: FC<FoundationActiveProps> = ({ foundation, setFoundation, disableActiveField }) => {
  return (
    <IntlProvider locale='fr' messages={Message}>
      {/* FONDEMENTS */}
      {
        !disableActiveField &&
          <div className='row mt-2 flex-column'>
            <h2 className='text-primary col-12'>
              <FormattedMessage id='foundation_form_active_part_label' />
            </h2>
            <label className='pl-3'><FormattedMessage id='foundation_form_active_label' /></label>
            <div className='pl-4 mt-4'>
              <SwitchField
                divClassName='col-3 justify-content-between'
                labelClassName='mr-5'
                inputId='foundationActive'
                onChange={() => setFoundation({ ...foundation, activated: !foundation.activated })}
                value={foundation.activated}
                label={<FormattedMessage id='foundation_active_label' />}
              />
            </div>
          </div>
      }
    </IntlProvider>
  )
}

export default FoundationActive
