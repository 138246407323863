import {
  containsErrors,
  DOCUMENT_COMMUNICABILITIES,
  DOCUMENT_TYPES,
  FieldStatus,
  Inscription,
  TransactionDocument
} from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class ActTitleFormValidator {
    /**
     * Validation de l'étape d'acte
     * @param inscription
     * @returns
     */
    validatActStep = (documents: TransactionDocument[], inscription?: Inscription): boolean => {
      const fieldStatus: FieldStatus = {
        natureCodes: inscription?.natureCodes && inscription.natureCodes.length > 0 ? '' : Message.correction_form_missing_nature_codes,
        documents_communicability: documents.filter(document => document.type !== DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER).every((document: TransactionDocument) => document.communicability === DOCUMENT_COMMUNICABILITIES.NON_COMMUNICABLE) ? Message.correction_documents_communicability_act_error : ''
      }
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

      return !containsErrors(fieldStatus)
    }
}

export default new ActTitleFormValidator()
