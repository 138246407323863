import { DropArea, Preview, TextArea, EventType, FieldStatus, FIELD_LIMITS } from '@inpi-marques/components'
import { DOCUMENT_FORMATS } from '@inpi-marques/components/src/constants/DocumentConstants'
import { BrandTypeContentProps } from 'interfaces/Deposit'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import { storeTransactionUpdateDeposit } from '../../../../../store/transaction/transactionActions'

/**
 * Le contenu simple contient:
 *  - Un champs de Drag & Drop pour le signe de la marque
 *  - Un champs description
 */
const SimpleContent: FC<BrandTypeContentProps> = ({
  brand,
  brandType,
  fieldStatus,
  onBrandDocumentDroped,
  onBrandDocumentAdded,
  onBrandDocumentDeleted,
  document,
  documentPreview
}) => {
  const dispatch = useDispatch()

  const [description, setDescription] = useState<string>(brand.description ?? '')

  const onDescriptionChanged = (event: EventType): void => {
    const { value } = event.target
    setDescription(value)
  }

  const onDescriptionBlur = (): void => {
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, description } }))
  }

  return (
    <>
      <div className='col-12 mb-3'>
        <DropArea
          accept={brandType.acceptedFormat}
          maxSize={brandType.maxSize}
          text='drop_brand_file'
          onDrop={(files: File[]) => onBrandDocumentDroped(files[0])}
          fieldStatus={fieldStatus}
          nameFieldStatus='brandDocument'
        />
        {document &&
          <Preview
            file={documentPreview ?? (() => onBrandDocumentAdded(brand))}
            document={{ ...document, hash: brand.file?.hash }}
            className={document.format === DOCUMENT_FORMATS.MP4 ? 'col-6' : (document.format === DOCUMENT_FORMATS.MP3 ? 'max-8' : '')}
            onTrashClick={() => onBrandDocumentDeleted(brand)}
            isPrintFormat={document.format !== DOCUMENT_FORMATS.MP4 && document.format !== DOCUMENT_FORMATS.MP3}
          />}
      </div>
      <div className='col-md-12 col-lg-6 mb-3'>
        <TextArea
          inputId='brand-description'
          classNameFormGroup='mb-1'
          label={<FormattedMessage id='brand_type_description_label' />}
          value={description}
          onChange={onDescriptionChanged}
          onBlur={onDescriptionBlur}
          maxLength={FIELD_LIMITS.BRAND_DESCRIPTION_MAX_CHAR}
          fieldStatus={fieldStatus as FieldStatus}
          nameFieldStatus='brandDescription'
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
        />
        <div className='pt-2 d-flex color-primary small'>
          <FormattedMessage
            id='brand_type_description_label_info'
            values={{
              linebreak: <br />
            }}
          />
        </div>
      </div>
    </>
  )
}

export default SimpleContent
