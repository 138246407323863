import React, { FC, useEffect, useState } from 'react'
import {
  Country,
  DEFAULT_CONTRIBUTOR,
  EditorialBlock,
  FieldStatus,
  FilAriane,
  ModalComponent,
  PROCEDURE_FRMI, Seniority,
  Transaction,
  TransactionDocument, UE
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer, { StepView } from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from 'store/transaction/transactionActions'
import TransactionService from 'services/transaction/TransactionService'
import ContentService from '../../../services/content/ContentService'
import FRMIPostOperationTypes from './type/FRMIPostOperationTypes'
import FRMIPostOperationTypesValidator from './type/validator/FRMIPostOperationTypesValidator'
import CommentsFRMIForm from 'component/frmi/form/CommentsFRMIForm'
import FRMIDocuments from 'component/frmi/form/documents/FRMIDocuments'
import { FRMI_TYPES_FORMS } from 'constants/frmi/FRMIConstants'
import { FRMIStep, FRMIStepGuard, FRMITypeForm } from 'interfaces/frmi/FrmiInterfaces'
import FRMIPostOperationsFormValidator from './FRMIPostOperationsFormValidator'
import Recap from 'component/recap/Recap'
import OverviewValidator from 'component/recap/validator/OverviewValidator'
import PaymentForm from 'component/payment/PaymentForm'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_FRMI_POST_OPERATIONS } from 'constants/PaymentConstants'
import ContributorService from '../../../services/contributors/ContributorService'
import FRMIDocumentValidator from '../../frmi/form/documents/validator/FRMIDocumentValidator'

interface FRMIPostOperationsProps extends RouteComponentProps {
  isNewTransaction: boolean
}

const FRMIPostOperationsForm: FC<FRMIPostOperationsProps> = ({
  history,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()

  const transaction: Transaction = useSelector((state: RootStateOrAny) => state.transaction)
  const fieldStatus: FieldStatus = useSelector((state: RootStateOrAny) => state.transactionFieldStatus)
  const user = useSelector((state : RootStateOrAny) => state.user.user)

  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const [required, setIsRequired] = useState<boolean>(false)

  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)

  const handleSubmit = () => {
    setIsNew(isNewTransaction)
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  const save = async () => {
    if (isNew && !transaction.id) {
      setIsNew(false)
      return await TransactionService.createTransaction(transaction, PROCEDURE_FRMI.value, ['frmiDetails', 'subProcedureType', 'recipient'])
    } else {
      return await TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails', 'subProcedureType', 'recipient'], documents)
    }
  }

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        setDocuments(result.documents ?? [])
      })
    } else {
      dispatch(storeTransactionInit(PROCEDURE_FRMI.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-OP').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  /**
   * Ajout des props dynamiques en fonction des variables (needDefaultContributor, etc ...) et de l'étape (id)
   *
   * @param step
   * @returns
   */
  const getProps = (step: FRMIStep): any => {
    if (step.element?.needDefaultContributor) {
      // On ajoute le contributeur par défaut en fonction du contributeur
      if (step.id === 'recipient') {
        return {
          ...step.element.props,
          defaultContributor: { ...DEFAULT_CONTRIBUTOR, email: user.email, consentNotificationByEmail: user.consentNotificationByEmailPreferences }
        }
      } else if (step.id === 'signatory') {
        return {
          ...step.element.props,
          defaultContributor: ContributorService.buildDefaultSignatoryFromUser(user)
        }
      }
    }
    return { ...step.element?.props }
  }

  /**
   * Check si l'étape peut être affichée, en fonction des conditions dans le guard
   */
  const canBeDisplayed = (step: FRMIStep): boolean => {
    if (!step.guard || !transaction.frmiDetails) {
      return true
    }

    const guard: FRMIStepGuard = step.guard
    // Vérifie si le mandataire est aussi le destinataire des correspondances pour les FRMI et supprime l'étape du destinataire si c'est le cas
    if (guard.field === 'agentCanBeRecipient') {
      return guard.value === !!transaction.agent?.isRecipient
    } else if (guard.field === 'claimedSeniorities') {
      return transaction.frmiDetails?.countries?.some((country: Country) => country.code === guard.value)
    }
    const frmi: {[key: string]: string} = transaction.frmiDetails as {[key: string]: string}

    return (frmi[guard.field] === guard.value || !!(guard.notValue && frmi[guard.field] !== guard.notValue))
  }

  /**
   * Edition de la liste des pays
   * @returns
   */
  const onChangeStepTransaction = async (step: FRMIStep): Promise<Transaction|null> => {
    let updatedTransaction: Transaction = transaction
    if (step.id === 'countries') {
      // Supprime les anciennetés si l'UE n'est plus sélectionnée
      let seniorities: Seniority[]|undefined = []
      if (transaction.frmiDetails?.countries?.some((country: Country) => country.code === UE)) {
        seniorities = transaction?.frmiDetails?.seniorities
      }

      updatedTransaction = {
        ...transaction,
        frmiDetails: {
          ...transaction?.frmiDetails,
          seniorities: seniorities
        }
      }
    }

    return await TransactionService.updateTransactionBDDFromStore(updatedTransaction, step.onGoStepPropertyNames ?? [], documents)
  }

  /**
   * Récupération des étapes en fonction du type d'opération choisi et on les maps
   * en tant qu'étapes du StepContainer
   *
   * Les props statiques sont ajoutées dans la constante, alors que les props dynamique du composant sont à ajouter ici, comme transaction et fieldStatus
   *
   * @returns
   */
  const getFrmiTypeSteps = (): StepView[] => {
    const frmiTypeForm: FRMITypeForm|undefined = FRMI_TYPES_FORMS.find((typeForm: FRMITypeForm) => typeForm.type === transaction.subProcedureType)

    if (!frmiTypeForm) {
      return []
    }

    const hasAgentStep: boolean = !!frmiTypeForm.steps?.find((step: FRMIStep) => step.id === 'agent' && (!step.guard || canBeDisplayed(step)))
    return frmiTypeForm.steps.filter((step: FRMIStep) => !step.guard || canBeDisplayed(step))
      .map((step: FRMIStep) => ({
        id: step.id,
        level: step.level,
        label: <FormattedMessage id={`frmi_${step.id}_title`} />,
        onGoToStep: () => onChangeStepTransaction(step),
        validateGoToStep: () => FRMIPostOperationsFormValidator.validatorSwitch(step, transaction, hasAgentStep),
        stepButtonsAsChildren: step.stepButtonsAsChildren,
        component: step.element &&
          <step.element.component {...getProps(step)} transaction={transaction} fieldStatus={fieldStatus} setIsRequired={setIsRequired} currentTypeForm={frmiTypeForm} />
      }))
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_frmi' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='breadcrumb_new_frmi' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        views={[
          /** Type d'opérations */
          {
            id: 'frmi_post_operation_types',
            label: <FormattedMessage id='frmi_post_operation_types_title' />,
            onGoToStep: async () => save(),
            component: <FRMIPostOperationTypes transaction={transaction} fieldStatus={fieldStatus} />,
            validateGoToStep: () => FRMIPostOperationTypesValidator.validateOnChangeStep(transaction)
          },
          /** Etapes dynamique en fonction du type d'opération */
          ...getFrmiTypeSteps(),
          /** Documents */
          {
            id: 'documents',
            label: <FormattedMessage id='documents_label' />,
            component: (
              <FRMIDocuments
                transaction={transaction}
                fieldStatus={fieldStatus}
                setDocuments={setDocuments}
                documents={documents}
              />
            ),
            onGoToStep: () => TransactionService.updateDocumentTransaction(transaction, documents),
            validateGoToStep: () => FRMIDocumentValidator.validateOnChangeStep(documents, transaction?.frmiDetails)
          },
          /** Commentaires */
          {
            id: 'frmi_comments',
            label: <FormattedMessage id='frmi_comments_title' />,
            component: (
              <CommentsFRMIForm transaction={transaction} />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['comments'], documents)
          },
          /** Récapitulatif */
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.INITIAL}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_FRMI_POST_OPERATIONS}
              />
            )
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(FRMIPostOperationsForm)
