class PaginationUtils {
    /**
     * Permet de soit retourner la page ou aller si la page courante n'est pas la bonne
     * soit renvoie -1 si il faut juste recharger les résultats
     * @param currentPage
     * @param maxPage
     * @param pageSize
     * @param totalSize
     * @returns
     */
    calculateNewPage = (currentPage: number, maxPage: number, pageSize: number, totalSize: number): number => {
      const newMaxPage = Math.ceil(totalSize / pageSize)
      let newPage = currentPage

      if (totalSize <= pageSize && maxPage !== 1 && currentPage !== 0) {
        return 0
      } else if (totalSize <= pageSize && maxPage !== 1 && currentPage === 0) {
        return -1
      }

      if (newMaxPage <= currentPage) {
        newPage = Math.max(0, newMaxPage)
      }

      if (currentPage === newMaxPage && totalSize > pageSize) {
        newPage = newMaxPage - 1
      }

      if ((currentPage === newPage || newPage === 0 || newPage === newMaxPage) && newMaxPage >= maxPage) {
        return -1
      }

      if (currentPage !== newPage && currentPage !== 0) {
        if (newPage > 0) {
          return newMaxPage === newPage && maxPage !== newMaxPage && newPage >= 0 ? newPage - 1 : newPage
        }
      }

      return -1
    }
}
export default new PaginationUtils()
