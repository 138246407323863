import {
  DateUtils,
  PROCEDURE_FRMI,
  ProductAndService,
  ProductClass,
  ProductClassVersion
} from '@inpi-marques/components'
import { deburr } from 'lodash'
import { PRODUCT_AND_SERVICE_VERSION_TYPE, PRODUCT_CLASS_VERSION_STATUS, PRODUCT_STATUS } from '../../constants/DepositConstants'

interface IResponseProductClass {
  id?: number,
  ref: string
}

interface IResponseProduct {
  id: number,
  name?: string,
  class: IResponseProductClass
}

class ProductService {
  /**
   * Méthode permettant de rechercher des produits via une valeur.
   *
   * La recherche utilise:
   *  - toLowercase: pour faire une recherche en minuscule
   *  - deburr: pour retirer les accents
   *
   * @param productClasses
   * @param searchValue
   * @returns
   */
  searchProductsByName = (productClasses: ProductClass[], searchValue: string): ProductClass[] => {
    const filteredProductClasses: ProductClass[] = []
    productClasses.forEach((productClass: ProductClass) => {
      const products: ProductAndService[] = productClass.products.filter((product: ProductAndService) => {
        return product.name && deburr(product.name.toLowerCase()).indexOf(deburr(searchValue.toLocaleLowerCase())) > -1
      })

      products.length && filteredProductClasses.push({ ...productClass, products })
    })
    return filteredProductClasses
  }

  /**
   * Convertit un tableau de IResponseProduct en tableau de ProductClass
   * @param productsResponse
   * @param type
   */
  convertResponseProductsToProductClass = (productsResponse : IResponseProduct[], type: 'nice' | 'tmclass') : ProductClass[] => {
    const productClassArray : ProductClass[] = []

    let existingRefClass : string[] = productsResponse.map((niceProduct: IResponseProduct) => niceProduct.class.ref)
    if (type === 'nice') {
      existingRefClass = existingRefClass.sort((first, second) => (parseInt(first, 10) > parseInt(second, 10) ? 1 : -1))
    }
    const existingUniqueRefClass : string[] = existingRefClass.filter((ref, index) => existingRefClass.indexOf(ref) === index)

    existingUniqueRefClass.forEach((classRef: string) => {
      const newProductAndServiceArray : ProductAndService[] = productsResponse
        .filter((niceProduct: IResponseProduct) => niceProduct.class.ref === classRef)
        .map((niceProduct: IResponseProduct) => ({ name: niceProduct.name, ref: niceProduct.id.toString(), origin: type }))
      const newProductClass : ProductClass = {
        ref: classRef,
        products: newProductAndServiceArray
      }
      productClassArray.push(newProductClass)
    })

    return productClassArray
  }

  /**
   * Initialise une nouvelle version via l'ancienne
   * @param version
   * @param procedureType
   * @param type
   * @param isRecord
   * @returns
   */
  clearProductClassVersion = (version: ProductClassVersion, procedureType?: string, type?: string, isRecord?: boolean): ProductClassVersion => {
    const updatedProductClasses: ProductClass[] = []
    version?.productClasses && version.productClasses.forEach((productClass: ProductClass) => {
      const updatedProducts: ProductAndService[] = []
      productClass.products.forEach((product: ProductAndService) => {
        if (product.status !== PRODUCT_STATUS.DELETED && PROCEDURE_FRMI.value !== procedureType) {
          updatedProducts.push({ ...product, status: undefined })
        }
        if (PROCEDURE_FRMI.value === procedureType && product.status !== PRODUCT_STATUS.DELETED) {
          if (product.status) {
            updatedProducts.push({ ...product })
          } else {
            updatedProducts.push({ ...product, status: undefined })
          }
        }
      })
      if (updatedProducts.length || (isRecord && productClass.products.length === 0 && !productClass.deleted)) {
        updatedProductClasses.push({ ...productClass, products: updatedProducts })
      }
    })
    return { id: isRecord ? version.id : undefined, type: type ?? PRODUCT_AND_SERVICE_VERSION_TYPE.PARTIAL_WITHDRAW_VERSION, createdDate: DateUtils.now(), productClasses: updatedProductClasses }
  }

  /**
   * Récupère la version finale à envoyer au webservice
   * Tous les produits supprimés sont supprimés, et les valeurs éditée son mise à jour
   *
   * @param version
   * @param noStatusChange
   * @returns
   */
  getFinalVersion = (version: ProductClassVersion, noStatusChange?: boolean): ProductClassVersion => {
    const productClasses: ProductClass[] = version.productClasses

    const updatedProductClasses: ProductClass[] = []

    productClasses.forEach((productClass: ProductClass) => {
      const newProducts: ProductAndService[] = productClass.products
        // On retire de la liste les produits supprimés sans id, car il s'agit des produits ajoutés et supprimé en même temps qu'une classe
        .filter((product: ProductAndService) => !(product.status === PRODUCT_STATUS.DELETED && !product.id))
        .map((product: ProductAndService) => ({ ...product, name: product.editValue ? product.editValue : product.name }))

      if (newProducts.length > 0) {
        updatedProductClasses.push({
          ref: productClass.ref,
          products: newProducts
        })
      }
    })
    if (noStatusChange) {
      return { ...version, productClasses: updatedProductClasses }
    }

    let type = version.type
    if (type !== PRODUCT_AND_SERVICE_VERSION_TYPE.BO_VERSION) {
      if (version.notificationId) {
        type = PRODUCT_AND_SERVICE_VERSION_TYPE.VERSION_REGULARIZATION
      } else {
        type = productClasses.every((productClass) => productClass.products.every((product) => product.status === PRODUCT_STATUS.DELETED))
          ? PRODUCT_AND_SERVICE_VERSION_TYPE.TOTAL_WITHDRAW_VERSION
          : PRODUCT_AND_SERVICE_VERSION_TYPE.PARTIAL_WITHDRAW_VERSION
      }
    }
    return { ...version, productClasses: updatedProductClasses, type: type }
  }

  /**
   * On vérifie si le nom du produit édité n'est pas celui qui était là dans la liste originale.
   * Si c'est le cas et que celui ci n'a pas déjà été modifié alors on return true afin de ne pas modifié le statut.
   *
   * @param originalProductClasses
   * @param editedProductClass
   * @param editedProduct
   * @param value
   * @returns
   */
  isProductValueWasReset = (originalProductClasses: ProductClass[], editedProductClass: ProductClass, editedProduct: ProductAndService, value: string): boolean => originalProductClasses.some((productClass: ProductClass) => editedProductClass.ref === productClass.ref &&
    productClass.products.some((product: ProductAndService) => product.id === editedProduct.id && product.name === value))

  /**
   * Check sur un produit est déjà présent dans la liste fournie.
   *
   * @param selectedProductClasses
   * @param productClassName
   * @param product
   * @returns
   */
  isExists = (selectedProductClasses: ProductClass[], productClassName: string, product: ProductAndService): boolean =>
    selectedProductClasses.some((productClass: ProductClass) =>
      productClass.ref === productClassName && productClass.products?.some((productAndService: ProductAndService) =>
        productAndService.origin === product.origin && productAndService.name === product.name))

  /**
   * Gère l'ajout / la suppression d'un produit à une classe
   *
   * @param selectedProductClasses
   * @param productClassName
   * @param product
   * @param hasToRemove Définit si on supprime ou non un produit existant (Pour une saisie libre, on ne fait rien s'il existe contrairement aux autres)
   * @returns
   */
  getUpdatedSelectedList = (selectedProductClasses: ProductClass[], productClassName: string, product: ProductAndService, hasToRemove: boolean): ProductClass[] => {
    if (this.isExists(selectedProductClasses, productClassName, product)) {
      // On supprime
      if (hasToRemove) {
        const newClassSelected : ProductClass [] = selectedProductClasses.map(classSelected => {
          if (classSelected.ref === productClassName) {
            return {
              ...classSelected,
              products: classSelected.products.filter(productSelected =>
                !(productSelected.ref === product.ref && productSelected.origin === product.origin)
              )
            }
          }
          return classSelected
        })
        return newClassSelected.filter(classSelected => classSelected.products.length > 0)
      }
    } else {
      /** Si une classe existe et que le produit n'y est pas encore, alors on y ajoute le nouveau produit
       Sinon, on crée une nouvelle classe avec le nouveau produit */
      if (selectedProductClasses.find(productClass => productClass.ref === productClassName || ((parseInt(productClass.ref, 10) === parseInt(productClassName, 10)) && !productClass.ref.includes(',')))) {
        return selectedProductClasses.map(classSelected => {
          const existingProduct: ProductAndService | undefined = classSelected.products.find((currentProduct: ProductAndService) => currentProduct.name === product.name)
          if (classSelected.ref === productClassName || (parseInt(classSelected.ref, 10) === parseInt(productClassName, 10) && !classSelected.ref.includes(',') && !existingProduct)) {
            return {
              ...classSelected,
              products: [...classSelected.products, product],
              deleted: classSelected.deleted !== undefined && classSelected.deleted !== null ? false : undefined
            }
          }
          return { ...classSelected, deleted: classSelected.deleted !== undefined && classSelected.deleted !== null ? false : undefined }
        })
      }
      return [...selectedProductClasses, {
        ref: productClassName,
        products: [product]
      }]
    }
    return selectedProductClasses
  }

  /**
   * Gère l'ajout / la suppression de produits via une autre liste de produits
   * @param selectedProductClasses
   * @param newProductClasses
   * @param hasToRemove
   * @returns
   */
  getUpdateSelectedListFromList = (selectedProductClasses: ProductClass[], newProductClasses: ProductClass[], hasToRemove: boolean): ProductClass[] => {
    newProductClasses.forEach((newProductClass: ProductClass) => {
      newProductClass.products.forEach((product: ProductAndService) => {
        selectedProductClasses = this.getUpdatedSelectedList(selectedProductClasses, newProductClass.ref, product, hasToRemove)
      })
    })
    return selectedProductClasses
  }

  /**
   * Méthode permettant de rechercher des produits via son origine
   * @param productClasses
   * @param origin
   * @returns
   */
  getProductsByOrigin = (productClasses: ProductClass[], origin: string): ProductClass[] => {
    const filteredProductClasses: ProductClass[] = []
    productClasses.forEach((productClass: ProductClass) => {
      const products: ProductAndService[] = productClass.products.filter((product: ProductAndService) => {
        return product.origin === origin
      })

      products.length && filteredProductClasses.push({ ...productClass, products })
    })
    return filteredProductClasses
  }

  /**
   * Récupère les classes de produits qui seront à valider.
   * On ne cherche à valider que les produits insérés à la main, ou importés
   * @param productClasses
   * @returns
   */
  getClassesToValidate = (productClasses: ProductClass[]): ProductClass[] => {
    const filteredProductClasses: ProductClass[] = []
    productClasses.forEach((productClass: ProductClass) => {
      const products: ProductAndService[] = productClass.products.filter((product: ProductAndService) => {
        return product.origin === 'free' || product.origin === 'import'
      })

      products.length && filteredProductClasses.push({ ...productClass, products })
    })
    return filteredProductClasses
  }

/**
 * Récupération de la version courante
 * On prends la dernière version acceptée
 * @param versions
 * @returns
 */
getCurrentVersion = (versions?: ProductClassVersion[]): ProductClassVersion | undefined => {
  if (!versions) {
    return undefined
  }

  // Filtrer les versions acceptées
  const acceptedVersions = versions.filter(
    (version: ProductClassVersion) => version.status === PRODUCT_CLASS_VERSION_STATUS.ACCEPTED
  )

  // Vérifier si au moins une version a la propriété isCurrent définie
  const hasCurrentDefined = acceptedVersions.some(version => version.isCurrent !== undefined && version.isCurrent === true)

  if (hasCurrentDefined) {
    // Si isCurrent est définie, on filtre par isCurrent === true et on trie par date
    return acceptedVersions
      .filter((version: ProductClassVersion) => version.isCurrent === true)
      .sort((firstDate, secondDate) =>
        DateUtils.isBefore(firstDate.updatedAt || firstDate.createdDate, secondDate.updatedAt || secondDate.createdDate) ? 1 : -1
      )[0]
  } else {
    // Si isCurrent n'est pas définie, on prend la dernière version acceptée
    return acceptedVersions
      .sort((firstDate, secondDate) =>
        DateUtils.isBefore(firstDate.updatedAt || firstDate.createdDate, secondDate.updatedAt || secondDate.createdDate) ? 1 : -1
      )[0]
  }
}

  /**
   * Récupère la version DRAFT
   * @param versions
   * @returns
   */
  getDraftedVersion = (versions: ProductClassVersion[]): ProductClassVersion | undefined =>
    versions.find((version: ProductClassVersion) => version.status === PRODUCT_CLASS_VERSION_STATUS.DRAFT_FO)

  /**
   * Récupération des classes éditées.
   * Il s'agit des classes qui ont des produits EDITED, ADDED (mais pas tous), DELETED(mais pas tous)
   * @param productClasses
   * @returns
   */
  getEditedClasses = (productClasses: ProductClass[]): ProductClass[] =>
    productClasses.filter((productClass: ProductClass) =>
      (productClass.products.some((product: ProductAndService) => product.status === PRODUCT_STATUS.ADDED) &&
          !productClass.products.every((product: ProductAndService) => product.status === PRODUCT_STATUS.ADDED)) ||
            (productClass.products.some((product: ProductAndService) => product.status === PRODUCT_STATUS.DELETED) &&
                !productClass.products.every((product: ProductAndService) => product.status === PRODUCT_STATUS.DELETED))
    )

  /**
   * Check s'il s'agit ou non d'une version au statut DRAFT_FO
   * @param version
   * @returns
   */
  isDraftFoVersion = (version: ProductClassVersion): boolean => version.status === PRODUCT_CLASS_VERSION_STATUS.DRAFT_FO

  /**
   * Récupération des produits éditées
   */
  getEditedProductsClass = (productClasses: ProductClass[]) : ProductClass[] =>
    productClasses.map((productClass) => ({
      ...productClass,
      products: productClass.products.filter((product) => product.status === PRODUCT_STATUS.ADDED || product.status === PRODUCT_STATUS.DELETED)
    }))

  /**
   * Crée une nouvelle version de produits et services
   */
  createProductsAndServicesVersion = (type: string, status: string, productsClasses: ProductClass[]) => (
    {
      type: type,
      status: status,
      productClasses: productsClasses,
      createdDate: DateUtils.now()
    })

  /**
   * Récupère une version via son type
   * @param versions
   * @param type
   * @returns
   */
  getVersionByType = (versions: ProductClassVersion[] = [], type: string): ProductClassVersion|undefined =>
    versions.find((version: ProductClassVersion) => version.type === type)

  /**
   * Récupère une version via son statut
   * @param versions
   * @param status
   * @returns
   */
  getVersionByStatus = (versions: ProductClassVersion[] = [], status: string): ProductClassVersion|undefined =>
    versions.find((version: ProductClassVersion) => version.status === status)

  /**
   * Reset les versions du produits et service en cleanant les ids
   * @param versions
   * @returns
   */
  resetVersions = (versions?: ProductClassVersion[]): ProductClassVersion[] =>
    !versions ? [] : versions.map((version: ProductClassVersion) =>
      ({
        ...version,
        id: undefined,
        createdDate: DateUtils.formatToBeginOfDay(new Date().toString()),
        productClasses: version.productClasses.map((productClass: ProductClass) => ({
          ...productClass,
          products: productClass.products.map((product: ProductAndService) => ({
            ...product,
            id: undefined
          }))
        }))
      })
    )

  updateProductClassFromProducts = (productClass: ProductClass, strProducts: string, fromBO: boolean = false): ProductClass => {
    const classProducts: ProductAndService[] = productClass.products
    const newProducts: ProductAndService[] = []

    /**
     * On regarde si les nouveaux produits existent déjà dans la classe
     * S'il n'existe pas, on les ajoute avec le statut ADDED, s'ils existent, on les ajoute sans statut
     */
    strProducts.split(/;|\./).forEach((strProduct: string) => {
      if (strProduct) {
        const existingProduct: ProductAndService | undefined =
          classProducts.find((classProduct: ProductAndService) => strProduct.trim().replace(/\.|;/g, '') === classProduct.name?.trim().replace(/\.|;/g, ''))
        // Si le produit a été ajouté en doublon, on ne l'ajoute pas
        if (!newProducts.some((classProduct: ProductAndService) => strProduct.trim().replace(/\.|;/g, '') === classProduct.name?.trim().replace(/\.|;/g, ''))) {
          if (existingProduct) {
            newProducts.push({
              ...existingProduct,
              name: existingProduct.name?.replace(/\.|;/g, ''),
              separator: strProducts.indexOf(`${strProduct}.`) !== -1 ? '.' : undefined
            })
          } else {
            newProducts.push({
              name: strProduct.replace(/\.|;/g, ''),
              origin: 'free',
              status: PRODUCT_STATUS.ADDED,
              editedByBo: fromBO,
              separator: strProducts.indexOf(`${strProduct}.`) !== -1 ? '.' : undefined
            })
          }
        }
      }
    })

    /** On regarde si les anciens produits sont présents dans la nouvelle liste
     * S'ils n'y sont pas on les ajoute avec un statut DELETED
     */
    classProducts.forEach((classProduct: ProductAndService) => {
      const existingProduct: ProductAndService|undefined = newProducts.find((newProduct: ProductAndService) => classProduct.name?.trim().replace(/\.|;/g, '') === newProduct.name?.trim().replace(/\.|;/g, ''))

      if (!existingProduct) {
        newProducts.push({ ...classProduct, name: classProduct.name?.replace(/\.|;/g, ''), status: PRODUCT_STATUS.DELETED, editedByBo: fromBO && classProduct.status !== PRODUCT_STATUS.DELETED })
      }
    })
    return { ...productClass, products: newProducts, deleted: productClass.deleted !== undefined && productClass.deleted !== null ? !newProducts.some((product: ProductAndService) => product.status !== PRODUCT_STATUS.DELETED) : undefined }
  }

  /**
   * Récupération des produits en string
   * On remontera uniquement les produits qui ne sont pas supprimés
   * @param products
   * @returns
   */
  getStringProductValue = (products: ProductAndService[]): string => {
    let stringProducts = ''
    const productList = products.filter((product: ProductAndService) => product.status !== PRODUCT_STATUS.DELETED)
    productList.forEach((product: ProductAndService) => {
      stringProducts += `${product.name}${product.separator ? product.separator : ';'}`
    })

    return stringProducts
  }

  /**
   * Verifie s'il s'agit d'une version Draft qu'elle soit FO ou BO
   * @param version
   * @returns
   */
  isDraftVersion = (version: ProductClassVersion): boolean =>
    version.status === PRODUCT_CLASS_VERSION_STATUS.DRAFT_BO || version.status === PRODUCT_CLASS_VERSION_STATUS.DRAFT_FO

  /**
   * Compare une version par rapport à la version initiale (celle du client)
   * @param originalClasses
   * @param currentVersionClasses
   * @returns
   */
  getComparedVersionFromInitial = (originalClasses: ProductClass[], currentVersionClasses: ProductClass[]): ProductClass[] => {
    const comparedVersionClasses: ProductClass[] =

      [...originalClasses].map((originalClass: ProductClass) => {
        const currentClass: ProductClass|undefined =
          currentVersionClasses.find((currentVersionClass: ProductClass) => originalClass.ref === currentVersionClass.ref)

        // Si la classe n'existe plus, on passe tous les produits à DELETED
        if (!currentClass) {
          return { ...originalClass, products: originalClass.products.map((product: ProductAndService) => ({ ...product, status: PRODUCT_STATUS.DELETED })) }
        }

        const updatedProducts: ProductAndService[] = []

        // Si elle existe, regarde si les produits y sont toujours
        originalClass.products.forEach((originalProduct: ProductAndService) => {
          if (currentClass.products.some((currentProduct: ProductAndService) => originalProduct.name?.trim() === currentProduct.name?.trim())) {
            updatedProducts.push({ ...originalProduct, status: undefined })
          } else {
            updatedProducts.push({ ...originalProduct, status: PRODUCT_STATUS.DELETED })
          }
        })

        // On regarde egalement les produits de la version courante afin de voir s'il y en a des nouvelles
        currentClass.products.forEach((currentProduct: ProductAndService) => {
          if (!originalClass.products.some((originalProduct: ProductAndService) => currentProduct.name === originalProduct.name)) {
            updatedProducts.push({ ...currentProduct, status: PRODUCT_STATUS.ADDED })
          }
        })

        return { ...originalClass, products: updatedProducts }
      })

    // Si une classe n'existe pas dans l'ancienne version mais dans la nouvelle, on l'ajoute
    currentVersionClasses.forEach((currentClass: ProductClass) => {
      if (!comparedVersionClasses.some((comparedVersionClass: ProductClass) => currentClass.ref === comparedVersionClass.ref)) {
        comparedVersionClasses.push({ ...currentClass, products: currentClass.products.map((product: ProductAndService) => ({ ...product, status: PRODUCT_STATUS.ADDED })) })
      }
    })

    return comparedVersionClasses
  }

  /**
   * Mise à jour d'une classes supprimée.
   * On passe les statuts de tous ses produits à DELETED
   *
   * @param productClasses
   * @param deletedProductClass
   * @param indexToDelete
   * @param fromBO
   * @param filter
   * @returns
   */
  editProductsOnProductClassDeleted = (productClasses: ProductClass[], deletedProductClass: ProductClass, indexToDelete?: number, fromBO: boolean = false, filter: boolean = true) => {
    let updatedProductClasses = productClasses.map((productClass: ProductClass, index: number) => (indexToDelete !== undefined ? indexToDelete === index : productClass.ref === deletedProductClass.ref)
      ? {
        ...productClass,
        products: productClass.products
          .filter((product: ProductAndService) => product.status !== PRODUCT_STATUS.ADDED)
          .map((product: ProductAndService) => ({ ...product, status: PRODUCT_STATUS.DELETED, editedByBo: fromBO })),
        deleted: true
      }
      : productClass)
    if (filter) {
      updatedProductClasses = updatedProductClasses.filter((productClasses: ProductClass) => productClasses.products.length > 0)
    }
    return updatedProductClasses
  }

  /**
   * Rollback d'une classes supprimée.
   * On passe les statuts de tous ses produits à vide
   *
   * @param productClasses
   * @param classToRollback
   * @param indexToUndo
   * @returns
   */
  editProductsOnProductClassUndoDeleted = (productClasses: ProductClass[], classToRollback: ProductClass, indexToUndo?: number) =>
    productClasses.map((productClass: ProductClass, index: number) => productClass.ref === classToRollback.ref && (indexToUndo !== undefined ? indexToUndo === index : true)
      ? {
        ...productClass,
        products: productClass.products
          .map((product: ProductAndService) => ({ ...product, status: product.status === PRODUCT_STATUS.DELETED ? undefined : product.status })),
        deleted: productClass.deleted !== undefined && productClass.deleted !== null ? false : undefined
      }
      : productClass)

  /**
   * Rollback d'un produit supprimé d'une classe.
   *
   * @param productClasses
   * @param classToRollback
   * @param productToRollback
   * @param classIndex
   * @returns
   */
  editProductsOnProductUndoDeleted = (productClasses: ProductClass[], classToRollback: ProductClass, productToRollback: ProductAndService, classIndex?: number) =>
    productClasses.map((productClass: ProductClass, index: number) => (classIndex !== undefined ? classIndex === index : productClass.ref === classToRollback.ref)
      ? {
        ...productClass,
        products: productClass.products
          .map((product: ProductAndService) => (product.name === productToRollback.name ? { ...product, status: undefined } : product)),
        deleted: productClass.deleted !== undefined && productClass.deleted !== null ? false : undefined
      }
      : productClass)

  /**
   * Mets à jour une liste de classes via une autre
   * @param productClasses
   * @param newProductClasses
   * @returns
   */
  updateProductClassesFromAnotherList = (productClasses: ProductClass[], newProductClasses: ProductClass[]): ProductClass[] =>
    productClasses.map((productClass: ProductClass) => {
      const validatedClass: ProductClass | undefined = newProductClasses.find((validatedClass: ProductClass) => validatedClass.ref === productClass.ref)
      return validatedClass ? ({
        ...productClass,
        products: productClass.products.map((product: ProductAndService) => {
          const existingProduct: ProductAndService|undefined = validatedClass.products.find((validatedProduct: ProductAndService) => validatedProduct.name === product.name)
          if (existingProduct) {
            return existingProduct
          }
          return product
        }
        )
      }) : productClass
    })

  /**
   * On check si la classe est valide (composé uniquement de produit Nice,TMClass ou validés)
   * @param productClass
   * @returns
   */
  isValidClass = (productClass: ProductClass): boolean =>
    productClass.products.every((product: ProductAndService) => product.origin === 'nice' || product.origin === 'tmclass' || product.validityStatus === 'OK')

  /**
   * Vérifie si tous les produits de la classe sont harmonisés (OK suite à l'appel à l'api tmclass)
   * @param productClass
   */
  isClassHarmonized = (productClass: ProductClass): boolean => {
    return !(productClass.products.find(p => p.validityStatus !== 'OK'))
  }

  /**
   * Retourne la liste des classes de produits triées par leur nom de classe
   * @param productClasses
   * @returns
   */
  sortClasses = (productClasses: ProductClass[]): ProductClass[] => {
    const classes0 = productClasses.filter((productClass: ProductClass) => productClass.ref.includes(','))
    let otherClasses = productClasses.filter((productClass: ProductClass) => !productClass.ref.includes(','))
    otherClasses = otherClasses.sort((first: ProductClass, second: ProductClass) => {
      if (parseInt(first.ref, 10) > parseInt(second.ref, 10)) {
        return 1
      } else if (parseInt(first.ref, 10) < parseInt(second.ref, 10)) {
        return -1
      } else if (first.origin && second.origin) {
        return parseInt(first.origin, 10) > parseInt(second.origin, 10) ? 1 : -1
      }

      return -1
    })

    return [...classes0, ...otherClasses]
  }

  /**
   * Met à jour tous les produits et services d'un version via le statut renseigné en paramètre
   * @param version
   * @param status
   * @returns
   */
  updateAllProductsStatus = (version: ProductClassVersion, status?: string): ProductClassVersion => (
    {
      ...version,
      productClasses: version.productClasses.map((productClass: ProductClass) => ({
        ...productClass,
        products: productClass.products.map((product: ProductAndService) => ({
          ...product,
          status
        }))
      }))
    }
  )

  /**
   * Créer une nouvelle version de produits et services qui sera pas défaut au status PENDING
   * @param version
   * @param type
   * @returns
   */
   createCleanedVersion = (version?: ProductClassVersion, type?: string): ProductClassVersion => {
     // S'il la version n'existe pas, on en crée une, pour pouvoir travailler avec
     if (!version) {
       return { type: type ?? PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION, status: PRODUCT_CLASS_VERSION_STATUS.PENDING, createdDate: DateUtils.now(), productClasses: [] }
     }

     const updatedProductClasses: ProductClass[] = []
    version?.productClasses && version.productClasses.forEach((productClass: ProductClass) => {
       const updatedProducts: ProductAndService[] = []
       productClass.products.forEach((product: ProductAndService) => {
         // On récupère tous les produits, sauf ceux supprimés
         if (product.status !== PRODUCT_STATUS.DELETED) {
           updatedProducts.push({ ...product, status: undefined })
         }
       })
       // Si on a récupéré au moins un produit, alors on récupère la classe
       if (updatedProducts.length) {
         updatedProductClasses.push({ ref: productClass.ref, products: updatedProducts })
       }
     })

    return { ...version, id: undefined, createdDate: DateUtils.now(), productClasses: updatedProductClasses, type: type ?? version.type }
   }

  /**
   * Check si une version possède des produits "actifs" (non supprimé)
   * @param version
   * @returns
   */
  hasProducts = (version: ProductClassVersion): boolean =>
    !!version.productClasses.length && version.productClasses.some((productClass: ProductClass) => !productClass.products.every((product: ProductAndService) => product.status === PRODUCT_STATUS.DELETED))

  /**
   * On supprime le produit de la classe.
   * Si celui-ci a un statut ADDED, c'est qu'il a été ajouté à la version initiale, on le supprime donc, car inutile de la garder
   * S'il n'a pas de statut, on lui ajoute le statut DELETE
   * @param productClasses
   * @param productClassToEdit
   * @param deletedProduct
   * @returns
   */
  deleteProductFromProductClass = (productClasses: ProductClass[], productClassToEdit: ProductClass, deletedProduct: ProductAndService): ProductClass[] =>
    productClasses.map((productClass: ProductClass) => productClass.ref !== productClassToEdit.ref ? productClass : ({
      ...productClass,
      products: productClass.products
        .filter((product: ProductAndService) => !(product.name === deletedProduct.name && product.status === PRODUCT_STATUS.ADDED))
        .map((product: ProductAndService) => product.name === deletedProduct.name
          ? { ...product, status: PRODUCT_STATUS.DELETED } : product
        )
    }))

  filterProductClassesByProductNotStatus = (productClasses: ProductClass[], notStatus: string): ProductClass[] =>
    productClasses.map((productClass: ProductClass) => (
      {
        ...productClass,
        products: productClass.products.filter((product: ProductAndService) => product.status !== notStatus)
      }
    )).filter((productClass: ProductClass) => productClass.products?.some((product: ProductAndService) => !!product))

  /**
   * Check si la version a subit une modification
   * @param version
   * @returns
   */
   isVersionUpdated = (version: ProductClassVersion): boolean =>
     version.productClasses.some((productClass: ProductClass) => productClass.products.some((product: ProductAndService) => product.status))

  /**
   * Récupération de tous les noms de classes possédant au moins un produit non supprimés
   * Tous les noms de classes seront séparés par une virgule
   * @param productClasses
   * @returns
   */
  getConcatenatedClassNumbers = (productClasses: ProductClass[]): string =>
    productClasses
      .filter((productClass: ProductClass) => productClass.products?.some((product: ProductAndService) => product.status !== PRODUCT_STATUS.DELETED))
      .map((productClass: ProductClass) => productClass.ref)
      .join(', ')

  /**
   * On met à jour les produits et services potentiellement édité avec une autre liste de P&S
   * @param currentClasses
   * @param newClasses
   * @returns
   */
   mergeProductClassesInExistingList = (currentClasses: ProductClass[], newClasses: ProductClass[]): ProductClass[] => {
     const newProductClasses: ProductClass[] = []

     currentClasses.forEach((currentClass: ProductClass) => {
       const newClass: ProductClass|undefined = newClasses.find((productClass: ProductClass) => productClass.ref === currentClass.ref)
       // Si classe dans existe dans la liste courante, mais pas la nouvelle, on l'ajoute comme telle
       if (!newClass) {
         newProductClasses.push(currentClass)
       } else {
         // Si classe existe dans les deux listes, on la mets à jour
         const newProducts: ProductAndService[] = []

         currentClass.products.forEach((currentProduct: ProductAndService) => {
           const newClassProduct: ProductAndService|undefined = newClass.products.find((frmiProduct: ProductAndService) => currentProduct.name?.replace(/\.|;/g, '') === frmiProduct.name?.replace(/\.|;/g, ''))

           // Si le produit n'a jamais été modifié dans les classes courants, on ajoute celui de la nouvelle classe
           if (newClassProduct && !currentProduct.status) {
             newProducts.push(newClassProduct)
           } else {
             newProducts.push(currentProduct)
           }
         })

         // Si le produit pas dans la classe courant mais dans la nouvelle, on l'ajoute
         newClass.products.forEach((product: ProductAndService) => {
           if (!newProducts.some((newProduct: ProductAndService) => newProduct.name?.replace(/\.|;/g, '') === product.name?.replace(/\.|;/g, ''))) {
             newProducts.push(product)
           }
         })
         newProductClasses.push({ ...currentClass, products: newProducts })
       }
     })

     // Si une classe n'existe pas dans les classes courants mais dans la nouvelle, on l'ajoute
     newClasses.forEach((newClass: ProductClass) => {
       if (!newProductClasses.some((comparedVersionClass: ProductClass) => newClass.ref === comparedVersionClass.ref)) {
         newProductClasses.push({ ...newClass, products: newClass.products.map((product: ProductAndService) => ({ ...product, status: PRODUCT_STATUS.ADDED })) })
       }
     })

     return newProductClasses
   }

   /**
   * Check s'il s'agit ou non d'une version au statut DRAFT_BO
   * @param version
   * @returns
   */
   isDraftBoVersion = (version?: ProductClassVersion): boolean => version?.status === PRODUCT_CLASS_VERSION_STATUS.DRAFT_BO

   /**
   * Récupère la version BO Draft ou la version courante.
   *
   * @param versions
   * @returns
   */
  getDraftOrCurrentVersion = (versions: ProductClassVersion[]): ProductClassVersion | undefined =>
    versions.find((version: ProductClassVersion) => version.status === PRODUCT_CLASS_VERSION_STATUS.DRAFT_BO) ||
  this.getCurrentVersion(versions)

  /**
   * Récupère les versions examinateurs
   * @param versions
   * @returns
   */
  getExaminatorsVersions = (versions: ProductClassVersion[] = []): ProductClassVersion[] =>
    versions.filter((version: ProductClassVersion) => version.status === PRODUCT_CLASS_VERSION_STATUS.ACCEPTED)
}
export default new ProductService()
