import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ProductClassVersion, Table } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import Message from '../../../../constants/Message'
import ProductsAndServicesReadOnly from '../../ProductsAndServicesReadOnly'

interface OverviewLimitedProductsAndServicesProps {
  limitedProductsAndServices: ProductClassVersion[],
  editLimitedProductsAndServiceVersion?: (index: number) => void,
  deleteLimitedProductsAndServicesVersion?: (index: number) => void,
  className?: string,
  hasTitle?: boolean
}

const OverviewLimitedProductsAndServices: FC<OverviewLimitedProductsAndServicesProps> = (
  {
    limitedProductsAndServices,
    editLimitedProductsAndServiceVersion,
    deleteLimitedProductsAndServicesVersion,
    className = '',
    hasTitle = false
  }) => {
  return (
    <>
      {hasTitle &&
        <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_options_limitedProductsAndServices' />
        </h4>}
      {limitedProductsAndServices && limitedProductsAndServices.length > 0
        ? (
          <Table
            tableTitle={[
              { label: Message.options_columns_label_id, className: 'pl-2 mr-5' },
              { label: Message.options_columns_label_country, className: 'pl-2 mr-5' },
              { label: Message.options_columns_label_classes_products_and_services, className: 'mr-5' }
            ]}
            tableClassName={`table table-striped not-fixed ${className}`}
          >
            <tbody>
              {
                limitedProductsAndServices.map((limitedProductsAndServicesVersion: ProductClassVersion, index: number) => {
                  const countriesText = limitedProductsAndServicesVersion.countries?.map((country) => country.label)?.join(', ')
                  return (
                    <tr key={`-${index}`}>
                      <td className='pl-3'>{index + 1}</td>
                      <td className='pl-3'>{countriesText}</td>
                      <td><ProductsAndServicesReadOnly productClasses={limitedProductsAndServicesVersion.productClasses} /></td>
                      {(editLimitedProductsAndServiceVersion || deleteLimitedProductsAndServicesVersion) &&
                        <td className='btn-column pr-4'>
                          <div className='d-flex'>
                            {editLimitedProductsAndServiceVersion &&
                              <button
                                className='btn btn-link text-primary'
                                onClick={() => editLimitedProductsAndServiceVersion(index)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>}
                            {deleteLimitedProductsAndServicesVersion &&
                              <button
                                className='btn btn-link text-danger ml-3'
                                onClick={() => deleteLimitedProductsAndServicesVersion(index)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>}
                          </div>
                        </td>}
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        )
        : <div><FormattedMessage id='none_feminine' /></div>}
    </>
  )
}

export default OverviewLimitedProductsAndServices
