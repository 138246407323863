import { SelectOption } from 'src'
import Message from '../Message'

export const STATUS_REVOCATION_STATEMENT = {
  /** En cours de création client  */
  DRAFT_CLIENT: 'draft_client_revocation_statement',
  /** En cours de création */
  DRAFT: 'draft_revocation_statement',
  /** En attente d'un mémoire administratif */
  WAITING_VIR_PAYMENT: 'waiting_vir_payment_revocation_statement',
  /** Mémoire administratif expiré */
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired_revocation_statement',
  /** A attribuer */
  TO_ATTRIBUTE: 'to_attribute_revocation_statement',
  /** A examiner */
  TO_REVIEW: 'to_review_revocation_statement',
  /** En cours d'examen */
  REVIEWING: 'reviewing_revocation_statement',
  /** En attente */
  TO_REGISTER: 'to_register_revocation_statement',
  /** Inscrit */
  REGISTERED: 'registered_revocation_statement',
  /** Décision favorable */
  FAVORABLE: 'favorable_decision_revocation_statement',
  /** Irrecevable */
  NOT_ACCEPTED: 'not_accepted_revocation_statement',
  /** Rejeté */
  REJECTED: 'rejected_revocation_statement',
  /** Désistement */
  DISCONTINUATION: 'discontinuation_revocation_statement',
  /** Affaires classées */
  CLOSED: 'closed_cases_revocation_statement'
}

export const STATUS_REVOCATION_STATEMENT_REVIEWING = [
  STATUS_REVOCATION_STATEMENT.TO_ATTRIBUTE,
  STATUS_REVOCATION_STATEMENT.TO_REVIEW,
  STATUS_REVOCATION_STATEMENT.REVIEWING,
  STATUS_REVOCATION_STATEMENT.TO_REGISTER,
  STATUS_REVOCATION_STATEMENT.FAVORABLE
]

export const STATUS_REVOCATION_STATEMENT_FINAL: string[] = [
  STATUS_REVOCATION_STATEMENT.REJECTED,
  STATUS_REVOCATION_STATEMENT.CLOSED,
  STATUS_REVOCATION_STATEMENT.FAVORABLE,
  STATUS_REVOCATION_STATEMENT.NOT_ACCEPTED,
  STATUS_REVOCATION_STATEMENT.REGISTERED,
  STATUS_REVOCATION_STATEMENT.DISCONTINUATION
]

/**
 * Types de décision
 */

export const REVOCATION_STATEMENT_DECISION_TYPE_POSITIVE: SelectOption = {
  value: 'POSITIVE',
  label: Message.revocation_statement_decision_type_positive_label
}

export const REVOCATION_STATEMENT_DECISION_TYPE_INADMISSIBLE: SelectOption = {
  value: 'INADMISSIBLE',
  label: Message.revocation_statement_decision_type_inadmissible_label
}

export const REVOCATION_STATEMENT_DECISION_TYPE_REJECTED: SelectOption = {
  value: 'REJECTED',
  label: Message.revocation_statement_decision_type_rejected_label
}

export const REVOCATION_STATEMENT_DECISION_TYPE_POINTLESS: SelectOption = {
  value: 'POINTLESS',
  label: Message.revocation_statement_decision_type_pointless_label
}

export const REVOCATION_STATEMENT_DECISION_TYPES: SelectOption[] = [
  REVOCATION_STATEMENT_DECISION_TYPE_POSITIVE,
  REVOCATION_STATEMENT_DECISION_TYPE_INADMISSIBLE,
  REVOCATION_STATEMENT_DECISION_TYPE_REJECTED,
  REVOCATION_STATEMENT_DECISION_TYPE_POINTLESS
]

/**
 * Portée
 */
export const REVOCATION_STATEMENT_SCOPES_TYPE_TOTAL: SelectOption = {
  value: 'TOTAL',
  label: Message.revocation_statement_scope_type_total_label
}

export const REVOCATION_STATEMENT_SCOPES_TYPE_PARTIAL: SelectOption = {
  value: 'PARTIAL',
  label: Message.revocation_statement_scope_type_partial_label
}

export const REVOCATION_STATEMENT_SCOPES_TYPES: SelectOption[] = [
  REVOCATION_STATEMENT_SCOPES_TYPE_TOTAL,
  REVOCATION_STATEMENT_SCOPES_TYPE_PARTIAL
]
