import { NotificationPrice } from '@inpi-marques/components'

/**
 * Classe permettant de récupérer les données administrables en bo
 */
class PriceService {
  /**
   * Récupère le montant total des tarifs passer en paramètres
   * @param prices
   */
  getTotalAmount = (prices?: NotificationPrice[]) => {
    if (prices) {
      return Object.values(prices).reduce((accumulator, currentValue) => accumulator + ((currentValue.price || 0) * (currentValue.quantity || 0)), 0)
    }
    return 0
  }
}

export default new PriceService()
