import {
  FieldStatus,
  Transaction
} from '@inpi-marques/components'
import Message from 'constants/Message'

class SuiteValidator {
  /**
   * Validation d'une suite
   * @param transaction
   * @param fieldStatus
   * @returns
   */
   validate = (transaction: Transaction, fieldStatus?: FieldStatus): string => {
     // Si la checbkox a été cochée, et qu'il n'y a pas de position
     if (transaction.suite && transaction.suite.position === undefined) {
       return Message.suite_position_required
     }
     // Si l'erreur concernant la position déjà utilisé est présente, on la laisse
     if (fieldStatus?.position === Message.suite_position_still_exists) {
       return Message.suite_position_still_exists
     }
     return ''
   }
}

export default new SuiteValidator()
