import {
  CheckboxField,
  EventType,
  Payment,
  PROCESS_EXPEDITED,
  PROCESS_NORMAL,
  Transaction
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import InscriptionService from '../../../services/inscription/InscriptionService'
import TransactionService from '../../../services/transaction/TransactionService'

interface InscriptionProcessOverviewProps {
  transaction: Transaction,
  setPayment?: (payment?: Payment) => void
  setIsPaymentLoading?: (value: boolean) => void
}

const InscriptionProcessOverview: FC<InscriptionProcessOverviewProps> = ({ transaction, setPayment, setIsPaymentLoading }) => {
  const [isTaChecked, setIsTaChecked] = useState(transaction?.process === PROCESS_EXPEDITED)

  useEffect(() => {
    setIsTaChecked(transaction?.process === PROCESS_EXPEDITED)
  }, [])

  /**
     * Modification du process dans le store
     * @param event
     */
  const handleChangeProcess = (event: EventType) => {
    const { value } = event.target

    setIsPaymentLoading && setIsPaymentLoading(true)
    setIsTaChecked(!isTaChecked)
    TransactionService.updateTransaction(transaction?.id, { process: value ? PROCESS_EXPEDITED : PROCESS_NORMAL })
      .then(getInscriptionPaiement)
  }

  const getInscriptionPaiement = () => {
    InscriptionService.getPaiement(transaction?.id).then((payment: Payment) => {
      if (payment) {
        setPayment && setPayment(payment)
      }
      setIsPaymentLoading && setIsPaymentLoading(false)
    })
  }

  return (
    <div className='d-flex align-items-start flex-column col-12'>
      <div className='p-2 my-3'>
        <CheckboxField
          checked={isTaChecked}
          inputId='processExpedited'
          label={<FormattedMessage id='payment_inscription_ta' />}
          onChange={handleChangeProcess}
          className='d-table mb-0'
        />
      </div>
    </div>
  )
}

export default InscriptionProcessOverview
