import {
  ContentStoreAction,
  COUNTRIES_UPDATE,
  MANAGEABLE_QUALITIES_UPDATE,
  PROTECTION_EXTENSIONS_UPDATE,
  OFFICIAL_DOCUMENT_TYPES_UPDATE,
  RENEWAL_DELAYS_UPDATE
} from './contentStoreTypes'
import { Country, ManageableQuality, ProtectionExtension, RenewalDelay, OfficialDocumentType } from '@inpi-marques/components'

interface ContentStore {
  countries: Country[],
  qualities: ManageableQuality[],
  protectionExtensions: ProtectionExtension[],
  renewalDelays: RenewalDelay[],
  officialDocumentTypes: OfficialDocumentType[]
}

const initialContentState: ContentStore = {
  countries: [],
  qualities: [],
  protectionExtensions: [],
  renewalDelays: [],
  officialDocumentTypes: []
}

export function reducerContentState (state = initialContentState, action: ContentStoreAction): ContentStore {
  switch (action.type) {
    case COUNTRIES_UPDATE:
      return { ...state, countries: action.countries || [] }
    case MANAGEABLE_QUALITIES_UPDATE:
      return { ...state, qualities: action.qualities || [] }
    case PROTECTION_EXTENSIONS_UPDATE:
      return { ...state, protectionExtensions: action.protectionExtensions || [] }
    case OFFICIAL_DOCUMENT_TYPES_UPDATE:
      return { ...state, officialDocumentTypes: action.officialDocumentTypes || [] }
    case RENEWAL_DELAYS_UPDATE:
      return { ...state, renewalDelays: action.renewalDelays || [] }
    default:
      return state
  }
}
