import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import {
  containsErrors,
  Country,
  DateUtils,
  FieldStatus,
  FRMI_TYPE_POST_DESIGNATION_REGISTER,
  PRODUCT_STATUS,
  ProductClassVersion,
  ProductService as CommonProductService,
  StepButtonsUtils,
  Transaction
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import ContentService from 'services/content/ContentService'
import StoreService from 'services/StoreService'
import LimitedProductsAndServicesListForm from './LimitedProductsAndServicesListForm'
import LimitedProductsAndServicesValidator from './validator/LimitedProductsAndServicesValidator'
import LimitedProductsAndServicesForm from './LimitedProductsAndServicesForm'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'

interface LimitedProductsAndServicesProps {
  transaction: Transaction
}

const LimitedProductsAndServices: FC<LimitedProductsAndServicesProps> = (
  {
    transaction,
    children
  }) => {
  const [limitedProductClassVersions, setLimitedProductClassVersions] = useState<ProductClassVersion[]>(transaction.frmiDetails?.limitedProductsAndServicesVersions ?? [])
  const [limitedProductClassVersion, setLimitedProductClassVersion] = useState<ProductClassVersion>()
  const [editIndex, setEditIndex] = useState<number>(-1)
  const [isEditingMode, setEditingMode] = useState<boolean>(false)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus[]>([])
  const [countries, setCountries] = useState<Country[]>([])
  const [countriesState, setCountriesState] = useState<Country[]>([])
  const stepButtonsRef = useRef()
  const parentRef = useRef()

  /**
   * Ajout d'une limitation
   */
  const addlimitation = () => {
    const index = limitedProductClassVersions.length
    setEditIndex(index)
    setLimitedProductClassVersion({
      productClasses: transaction.frmiDetails?.productsAndServicesVersions?.[0]?.productClasses
        ? CommonProductService.filterProductClassesByProductNotStatus(transaction.frmiDetails?.productsAndServicesVersions?.[0]?.productClasses, PRODUCT_STATUS.DELETED) : []
    } as ProductClassVersion)
    setEditingMode(false)
  }

  /**
   * Annule un ajout ou la modification d'une limitation
   */
  const cancel = () => {
    setFieldStatus(fieldStatus.filter((status, index) => index !== editIndex))
    setEditIndex(-1)
    setLimitedProductClassVersion(undefined)
    setCountriesState(countries.filter((country: Country) =>
      !transaction.frmiDetails?.limitedProductsAndServicesVersions?.some((limitedPS: ProductClassVersion) =>
            limitedPS.countries?.find((countryPS: Country) => countryPS.code === country.code))))
  }

  /**
   * Valide la limitation
   */
  const validate = () => {
    const fieldStatusLimitation = LimitedProductsAndServicesValidator.validate(limitedProductClassVersion)
    const newFieldStatus = [...fieldStatus]
    newFieldStatus[editIndex] = fieldStatusLimitation
    setFieldStatus(newFieldStatus)

    if (!containsErrors(fieldStatusLimitation)) {
      const newList = [...limitedProductClassVersions]
      newList[editIndex] = { ...limitedProductClassVersion, createdDate: DateUtils.now() } as ProductClassVersion
      setLimitedProductClassVersions(newList)
      setLimitedProductClassVersion(undefined)
      setEditIndex(-1)
      setCountriesState(countries.filter((country: Country) =>
        !newList?.some((limitedPS: ProductClassVersion) =>
              limitedPS.countries?.find((countryPS: Country) => countryPS.code === country.code))))
    }
  }

  /**
   * Supprime une limitation de la liste
   * @param index
   */
  const deleteLimitation = (index: number) => {
    const newLimitedProductsAndServicesVersions = [...limitedProductClassVersions]
    newLimitedProductsAndServicesVersions.splice(index, 1)
    setLimitedProductClassVersions(newLimitedProductsAndServicesVersions)
    const updatedFieldStatus = [...fieldStatus]
    updatedFieldStatus.splice(index, 1)
    setFieldStatus(updatedFieldStatus)
    setCountriesState(countries.filter((country: Country) =>
      !newLimitedProductsAndServicesVersions?.some((limitedPS: ProductClassVersion) =>
            limitedPS.countries?.find((countryPS: Country) => countryPS.code === country.code))))
  }

  useEffect(() => {
    ContentService.getCountries().then((countries: Country[]) => {
      const countryList = countries.filter((country: Country) => transaction.frmiDetails?.countries?.find((frmiCountry: Country) => country.code === frmiCountry.code))
      setCountries(countryList)
      setCountriesState(countryList.filter((country: Country) => !transaction.frmiDetails?.limitedProductsAndServicesVersions?.some((limitedPS: ProductClassVersion) =>
          limitedPS.countries?.find((countryPS: Country) => countryPS.code === country.code))))
    })

    const { observer, move } = StepButtonsUtils.initStepButtonsListener(parentRef, stepButtonsRef)

    return () => {
      ContentService.cancelRequest()
      window.removeEventListener('scroll', move)
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    // On enregistre dans le store
    StoreService.changeStore({
      ...transaction,
      frmiDetails: { ...transaction.frmiDetails, limitedProductsAndServicesVersions: limitedProductClassVersions }
    })
  }, [limitedProductClassVersions])

  return (
    <div className='options' ref={parentRef}>
      <div className='row mb-4 justify-content-between'>
        <header>
          <h1><FormattedMessage id='frmi_limited_products_and_services_title' /></h1>
          <span className='subtitle'>
            <FormattedMessage
              id={`frmi_${transaction.subProcedureType === FRMI_TYPE_POST_DESIGNATION_REGISTER.value ? 'post_operation_' : ''}limited_products_and_services_description`}
            />
          </span>
        </header>
        <InternalReferenceField
          className='col-3'
          transaction={transaction}
        />

      </div>
      {editIndex === -1 ? (
        <LimitedProductsAndServicesListForm
          limitedProductsAndServices={limitedProductClassVersions}
          editLimitedProductsAndServiceVersion={(index: number) => {
            setEditIndex(index)
            setEditingMode(true)
            setLimitedProductClassVersion(limitedProductClassVersions[index])
          }}
          deleteLimitedProductsAndServicesVersion={deleteLimitation}
          addLimitedProductsAndServiceVersion={addlimitation}
        />
      ) : (
        <LimitedProductsAndServicesForm
          limitedProductsAndServices={limitedProductClassVersion}
          setLimitedProductsAndServices={setLimitedProductClassVersion}
          editIndex={editIndex}
          isEditingMode={isEditingMode}
          fieldStatus={fieldStatus[editIndex]}
          validate={validate}
          cancel={cancel}
          countries={countriesState}
        />
      )}
      <div className='step-buttons--fixed mb-3' ref={stepButtonsRef}>
        {StepButtonsUtils.getEditedChildren(children as ReactElement)}
      </div>
    </div>
  )
}

export default LimitedProductsAndServices
