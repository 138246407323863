import { PaymentMethodContentProps, TextField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'

const DirectDebitOMPIForm: FC<PaymentMethodContentProps> = ({ fieldStatus, onValueChange, paymentMethod }) => {
  return (
    <div className='directDebitOMPI'>
      <div className='mt-3'>
        <div className='col-12'>
          <TextField
            inputId='accountHolder'
            required
            value={paymentMethod?.accountHolder}
            label={<FormattedMessage id='fees_and_taxes_account_holder' />}
            onChange={onValueChange}
            nameFieldStatus='directDebitAccountHolder'
            fieldStatus={fieldStatus}
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
        <div className='col-12'>
          <TextField
            inputId='accountNumber'
            required
            value={paymentMethod?.accountNumber}
            label={<FormattedMessage id='fees_and_taxes_account_number' />}
            onChange={onValueChange}
            fieldStatus={fieldStatus}
            nameFieldStatus='directDebitAccountNumber'
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
        <div className='col-12'>
          <TextField
            inputId='instructionsAuthor'
            required
            value={paymentMethod?.instructionsAuthor}
            label={<FormattedMessage id='fees_and_taxes_instructions_author' />}
            onChange={onValueChange}
            fieldStatus={fieldStatus}
            nameFieldStatus='directDebitInstructionsAuthor'
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
      </div>
    </div>
  )
}

export default DirectDebitOMPIForm
