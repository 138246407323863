import http, { API_ADDRESS_SEARCH } from '../network/http-common'
import { AddressApiResult } from '../interfaces/contributors/AddressInterfaces'

interface GeoCodeProperties {
  // libellé complet de l’adresse
  label: string,
  // numéro de maison
  housenumber: string,
  // identifiant au sein de l'api gouv
  id: string,
  // type de résultat trouvé
  type: string,
  // numéro éventuel et nom de voie ou lieu dit
  name: string,
  // code postal
  postcode: string,
  // code INSEE de la commune
  citycode: string,
  // nom de la commune
  city: string,
  // n° de département, nom de département et de région
  context: string,
  // nom de la rue
  street: string,
}

interface GeoCodeGeometry {
  coordinates: number[],
}

interface GeoCodeFeature {
  geometry: GeoCodeGeometry,
  properties: GeoCodeProperties,
}

interface GeoCodeJSON {
  limit: number,
  query: string|null,
  features: GeoCodeFeature[],
}

class AddressApiService {
  /* Création d'une adresse à partir des données renvoyées par l'API */
  mapResultToAddressResult = (geoJson: GeoCodeJSON) : AddressApiResult[] => {
    return geoJson.features
      .map(({ properties, geometry }) => ({
        label: properties.label,
        value: {
          building: '',
          label: properties.housenumber && properties.street ? `${properties.housenumber} ${properties.street}` : properties.name,
          complement: '',
          zipCode: properties.postcode,
          city: properties.city,
          country: 'FR',
          longitude: geometry.coordinates[1],
          latitude: geometry.coordinates[0]
        }
      }))
  }

  /* Recherche d'une adresse via l'api data gouv */
  searchAddress = async (query: string, type = 'housenumber', limit = 5): Promise<AddressApiResult[]|void> => {
    // Autocomplétion à partir de 4 caractères uniquement
    if (!query || query.length < 4) {
      return Promise.resolve()
    }

    const paramQuery = `?q=${query}`
    const paramLimit = `&limit=${limit}`
    const paramType = type ? `&type=${type}` : ''

    try {
      const response : GeoCodeJSON = await http.get(`${API_ADDRESS_SEARCH}${paramQuery}${paramLimit}${paramType}`)
      return this.mapResultToAddressResult(response)
    } catch (error) {
      return []
    }
  }
}

export default new AddressApiService()
