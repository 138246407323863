import {
  containsErrors,
  DOCUMENT_COMMUNICABILITIES,
  EventType,
  Transaction,
  TransactionDocument,
  FieldStatus,
  FileButton,
  FileBrowserField,
  DOCUMENT_FORMATS,
  SubmitButton, DOCUMENT_TYPES, HelpBlock
} from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC, useState } from 'react'
import DocumentValidator from '../requests/valitdator/DocumentValidator'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import TransactionService from '../../services/transaction/TransactionService'
import DocumentService from '../../services/document/DocumentService'

interface ErratumProps {
  transaction: Transaction,
  setTransaction: (transaction: Transaction) => void
  setShowModal?: (value: boolean) => void | undefined
}

const Erratum: FC<ErratumProps> = ({ transaction, setTransaction, setShowModal }) => {
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const [document, setDocument] = useState<TransactionDocument>()

  /**
   * Ajoute le document en local
   * @param event
   */
  const handleAddDocument = (event: EventType) => {
    const file: File = event.target.value
    setDocument({
      ...document,
      type: DOCUMENT_TYPES.ERRATUM_REQUEST,
      internalName: '',
      name: `ER-${file.name}`,
      format: file.type,
      communicability: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE,
      file,
      size: file.size
    })
  }

  /**
   * Supprime le document en local
   */
  const deleteErratumDocument = async () => {
    if (!document.internalName) {
      setDocument({})
    }
  }

  /**
   * Enregistrement du document via l'API et mise à jour de la transaction
   */
  const submitErratumDocument = async () => {
    const status = DocumentValidator.validateErratumDocument(document)
    setFieldStatus(status)

    if (!containsErrors(status)) {
      await DocumentService.createDocument(document, transaction.id)
      const updatedTransaction = await TransactionService.getTransaction(transaction.id)
      setTransaction(updatedTransaction)
      setShowModal && setShowModal(false)
      toast.success(Message.request_add_erratum_success)
    } else {
      return null
    }
  }

  return (
    <div className='is-validated'>
      <div className='p-2'>
        <HelpBlock className='mb-3'>
          <FormattedMessage id='erratum_description' />
        </HelpBlock>
        <div className='d-flex align-items-end'>
          <FileBrowserField
            inputId='file-erratum'
            ulClassName='m-0'
            className='my-0 mr-4 pb-3'
            acceptApplication={DOCUMENT_FORMATS.PDF}
            value={document?.name ? [document] : []}
            onChange={handleAddDocument}
            onDelete={document && !document.internalName && deleteErratumDocument}
            maxNumberFile={1}
            buttonLabel={<FileButton label={<FormattedMessage id='form_file_placeholder' />} />}
            required
            nameFieldStatus='fileInputErratum'
            fieldStatus={fieldStatus}
            informationDoc
          />
          <SubmitButton className='btn-primary my-0' onClick={submitErratumDocument}>
            <FormattedMessage id='common_send' />
          </SubmitButton>
        </div>
      </div>
    </div>
  )
}

export default Erratum
