import {
  containsErrors,
  FieldStatus,
  INSCRIPTION_TYPE_ACT_TITLE,
  INSCRIPTION_TYPE_AGENT,
  INSCRIPTION_TYPE_CORRECTION,
  Transaction
} from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class OverviewValidator {
  validateOnChangeStep = (transaction: Transaction) => {
    const transactionFieldStatus: FieldStatus = {}
    if (!transaction.overviewConsented) {
      transactionFieldStatus.overviewConsented = Message.overview_consent_missing
    }
    if (transaction.subProcedureType &&
      [INSCRIPTION_TYPE_CORRECTION.value, INSCRIPTION_TYPE_AGENT.value, INSCRIPTION_TYPE_ACT_TITLE.value].includes(transaction.subProcedureType) &&
      (!transaction.inscription?.natureCodes || transaction.inscription?.natureCodes?.length === 0)) {
      transactionFieldStatus.otherError = Message.overview_nature_code_missing
      switch (transaction.subProcedureType) {
        case INSCRIPTION_TYPE_CORRECTION.value :
          transactionFieldStatus.otherError += Message.correction_title
          break
        case INSCRIPTION_TYPE_AGENT.value :
          transactionFieldStatus.otherError += Message.mandatary_title
          break
        case INSCRIPTION_TYPE_ACT_TITLE.value :
          transactionFieldStatus.otherError += Message.act_title
          break
        default:
          break
      }
    }
    store.dispatch(storeTransactionFieldStatusUpdate(transactionFieldStatus))
    return !containsErrors(transactionFieldStatus)
  }
}

export default new OverviewValidator()
