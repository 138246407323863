import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Contributor, Country, OverviewContributor } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface FrmiContributorListFormItemProps {
    contributors: Contributor[],
    countries: Country[],
    fromFrmiExtension: boolean,
    editContributor: (index: number) => void,
    deleteContributor: (index: number) => void,
    indexSuffix: string,
    indexes: number[],
    otherApplicantCodeToDuplicate?: string,
    duplicateContributor: (index: number) => void,
    canContributorBeDuplicated?: boolean
}

const FrmiContributorListFormItem: FC<FrmiContributorListFormItemProps> = ({ contributors, countries, fromFrmiExtension, editContributor, deleteContributor, indexSuffix, indexes, otherApplicantCodeToDuplicate, duplicateContributor, canContributorBeDuplicated }) =>
  <ul>
    {contributors.map((contributor, index) => (
      <>
        <li key={index + '-' + indexSuffix} className='my-2'>
          <div className='row'>
            <div className='col-8'>
              <OverviewContributor
                contributor={contributor}
                countries={countries}
                fromFrmiExtension={fromFrmiExtension}
                isFirst={indexes[index] === 0}
              />
            </div>
            <div className='col d-flex flex-column'>
              <div>
                <button className='btn btn-link text-primary' onClick={() => editContributor(indexes[index])}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className='btn btn-link text-danger ml-3' onClick={() => deleteContributor(indexes[index])}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
              <div className='d-flex justify-content-start'>
                {contributor.code === otherApplicantCodeToDuplicate && contributor.isOriginal && canContributorBeDuplicated &&
                  <button className='btn btn-outline-primary mt-3 mb-3 mr-3 button-size' onClick={() => duplicateContributor(indexes[index])}>
                    <FormattedMessage
                      id={`contributor_duplicate_${otherApplicantCodeToDuplicate}`}
                    />
                  </button>}
              </div>
            </div>
          </div>
        </li>
      </>

    ))}
  </ul>

export default FrmiContributorListFormItem
