import React, { FC, useState } from 'react'
import { EventType, TextArea, Transaction } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'

interface LicenceWriteOffProps {
    transaction: Transaction
}

/**
 * Etape Licence à radier
 * @param param0
 * @returns
 */
const LicenceWriteOff: FC<LicenceWriteOffProps> = ({ transaction }) => {
  const dispatch = useDispatch()

  const [radiationDescription, setRadiationDescription] = useState<string>(transaction.frmiDetails?.radiationDescription ?? '')

  /**
     * Au blur sur le champs
     */
  const onBlur = (): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      radiationDescription
    }))
  }

  return (
    <div>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_licence_write_off_title' /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div>
        <TextArea
          inputId='radiationDescription'
          label={<FormattedMessage id='frmi_radiation_description_label' />}
          value={radiationDescription}
          onChange={(event: EventType) => setRadiationDescription(event.target.value)}
          onBlur={onBlur}
        />
      </div>

    </div>
  )
}

export default LicenceWriteOff
