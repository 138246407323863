import store from '../../../../../store/store'
import {
  containsErrors,
  isFilled,
  DOCUMENT_TYPES,
  TransactionDocument,
  Deposit,
  FieldStatus
} from '@inpi-marques/components'
import Message from '../../../../../constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import { DEPOSIT_TYPE_GUARANTEE, DEPOSIT_TYPE_EUROPEAN, DEPOSIT_TYPE_COLLECTIVE, DEPOSIT_TYPE_INTERNATIONAL } from 'constants/DepositConstant'
import { DOCUMENT_LIMITS } from '@inpi-marques/components/src/constants/DocumentConstants'
import TransactionService from '../../../../../services/transaction/TransactionService'

class DepositTypeValidator {
  isTransactionDocumentSizeValid = (document: TransactionDocument):boolean => {
    if (document.file) {
      return (document.file.size < DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE)
    } else if (document.internalName) {
      return (document.size < DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE)
    } else {
      return false
    }
  }

  getValidatedFieldStatus = (documents: TransactionDocument[], subProcedureType?: string, deposit?: Deposit): FieldStatus => {
    const depositFieldStatus: FieldStatus = {}

    /** Type de dépôt sélectionné ou non */
    if (!isFilled(subProcedureType)) {
      depositFieldStatus.type = Message.deposit_type_required
      return depositFieldStatus
    }

    /** Type Garantie selectionné et consentement sélectionné */
    if (subProcedureType === DEPOSIT_TYPE_GUARANTEE.value || subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value) {
      if (!deposit || !deposit.typeConsented) {
        depositFieldStatus.typeConsented = Message.deposit_type_guarantee_consent_required
      }
    }

    /** Type de dépôt transformation Européen */
    if (subProcedureType === DEPOSIT_TYPE_EUROPEAN.value) {
      /** Numéro européen renseigné */
      if (!deposit || !deposit.numNatToTransformOrDivide) {
        depositFieldStatus.europeanNumber = Message.deposit_type_european_number_required
      }
      /** Date de dépôt européen renseignée */
      if (!deposit || !deposit.createdAtToTransformOrDivide) {
        depositFieldStatus.europeanDepositDate = Message.deposit_type_date_required
      }
    }

    if (subProcedureType === DEPOSIT_TYPE_INTERNATIONAL.value) {
      /** Numéro national renseigné */
      if (!deposit || !deposit.numNatToTransformOrDivide) {
        depositFieldStatus.internationalNumber = Message.deposit_type_international_number_required
      }
      /** Date de dépôt européen renseignée */
      if (!deposit || !deposit.createdAtToTransformOrDivide) {
        depositFieldStatus.internationalDepositDate = Message.deposit_type_date_required
      }

      /** Taille du fichier traduction de la requête de transformation < 100Mo */
      const internationalDepositFile: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.COPY_INTERNATIONAL_REGISTRATION)
      if (!internationalDepositFile) {
        depositFieldStatus.internationalDepositFile = Message.deposit_type_file_missing
      }

      const internationalDepositTranslationFile: TransactionDocument | undefined = TransactionService.getDocumentByType(
        documents,
        DOCUMENT_TYPES.TRANSLATION_INTERNATIONAL_REGISTRATION
      )
      if (!internationalDepositTranslationFile) {
        depositFieldStatus.internationalDepositTranslationFile = Message.deposit_type_file_missing
      }
      const internationalCancellationCertificate: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.CERTIFICATE_CANCELLATION)
      if (!internationalCancellationCertificate) {
        depositFieldStatus.internationalCancellationCertificate = Message.deposit_type_file_missing
      }
    }
    return depositFieldStatus
  }

  validateOnChangeStep = (documents: TransactionDocument[], subProcedureType?: string, deposit?: Deposit): boolean => {
    const fieldStatus: FieldStatus = this.getValidatedFieldStatus(documents, subProcedureType, deposit)
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new DepositTypeValidator()
