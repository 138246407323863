import http from '../../network/http-common'
import { toast } from 'react-toastify'
import {
  AnswerNotification,
  DateUtils,
  Notification,
  NOTIFICATION_STATUS_NOT_CONSIDERED,
  NotificationOMPI,
  Transaction
} from '@inpi-marques/components'

/**
 * Classe permettant de faire des opérations sur une notification
 */
class NotificationService {
  /**
   * Télécharge la notification au format pdf
   * @param idTransaction
   * @param idNotification
   */
  downloadNotification = async (idTransaction: string, idNotification: string): Promise<Blob> => {
    try {
      return await http.get(`/api/transactions/${idTransaction}/notifications/${idNotification}/file`, { responseType: 'blob' })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Répond à une notification
   * @param idTransaction
   * @param idNotification
   * @param answerNotification
   */
  answerNotification = async (idTransaction: string, idNotification: string, answerNotification: AnswerNotification): Promise<Notification> => {
    try {
      return await http.post(`/api/transactions/${idTransaction}/notifications/${idNotification}/answers`, answerNotification)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  // Retourne la Notification qui a la date limite de réponse la plus proche
  getRecentNotification = (notifications?: Notification[]|NotificationOMPI[]) => {
    if (!notifications || notifications.length === 0) {
      return null
    }
    const notification = notifications.reduce((a, b) => {
      if (a && b) {
        if (a.deadlineDateTime && b.deadlineDateTime) {
          return new Date(a.deadlineDateTime) < new Date(b.deadlineDateTime) ? a : b
        } else if (a.deadlineDateTime) {
          return a
        } else if (b.deadlineDateTime) {
          return b
        }
      }
    })

    return notification ?? notifications?.[0]
  }

  getLastSendingDate = (notifications?: Notification[]|NotificationOMPI[]) => {
    if (!notifications || notifications.length === 0) {
      return ''
    }
    const notification = notifications.reduce((a, b) => {
      if (a && b) {
        if (a.sendingDate && b.sendingDate) {
          return new Date(a.sendingDate) > new Date(b.sendingDate) ? a : b
        } else if (a.sendingDate) {
          return a
        } else if (b.sendingDate) {
          return b
        }
      }
    })

    if (notification?.sendingDate) {
      return DateUtils.formatDateFr(notification.sendingDate)
    }

    if (notifications[0]?.sendingDate) {
      return DateUtils.formatDateFr(notifications[0].sendingDate)
    }

    return ''
  }

  getEarliestDeadlineDate = (notifications?: Notification[]|NotificationOMPI[]) => {
    // Récupération de la date limite la plus proche
    const lastNotif = this.getRecentNotification(notifications)
    return lastNotif?.deadlineDateTime ? DateUtils.formatDateFr(lastNotif.deadlineDateTime) : ''
  }

  /**
   * Edition d'une notification
   * @param transaction
   * @param notification
   * @returns
   */
  edit = async (transaction: Transaction, notification: Notification): Promise<Transaction> => {
    try {
      return await http.put(`/api/transactions/${transaction.id}/notifications`, notification)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * Récupère la date de réception à afficher au client
   * @param notification
   */
  getNotificationReceiptDate = (notification: Notification): string|null => {
    if (notification.status === NOTIFICATION_STATUS_NOT_CONSIDERED.value) {
      return null
    }

    if (notification.fileDownloadDate) {
      return DateUtils.formatDateFr(notification.fileDownloadDate)
    }

    if (notification.receiptDate) {
      return DateUtils.formatDateFr(notification.receiptDate)
    }
  }
}

export default new NotificationService()
