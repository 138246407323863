import React, { FC, useEffect, useState } from 'react'
import {
  Contributor,
  CONTRIBUTOR_AGENT,
  CONTRIBUTOR_GUIDED_ENTITY_FORMATION,
  CONTRIBUTOR_GUIDED_PM,
  CONTRIBUTOR_GUIDED_PP,
  CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS,
  ContributorForm,
  ContributorsOptionsForm,
  ContributorValidator,
  DEFAULT_CONTRIBUTOR,
  DEPOSIT_CONTRIBUTORS_OPTIONS,
  DEPOSIT_CONTRIBUTORS_TYPES,
  downloadFile,
  EMPTY_CONTRIBUTOR,
  EventType,
  FieldStatus,
  INSCRIPTION_TYPE_ACT_TITLE,
  ManageableQuality,
  SubmitButton,
  Transaction,
  TransactionDocument,
  TransactionService
} from '@inpi-marques/components'
import ContentService from '../../services/content/ContentService'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import OpenDataRNCSService from '../../services/content/OpenDataRNCSService'
import StoreService from '../../services/StoreService'
import ContributorService from '../../services/contributors/ContributorService'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import ModalContactList from '../addressesBook/ModalContactList'
import ContactUtils from '../../utils/ContactUtils'
import ModalContactError from '../addressesBook/ModalContactError'
import { Contact } from '../../interfaces/Contact'
import ContactService from '../../services/contact/ContactService'
import { toast } from 'react-toastify'
import Message from '../../constants/Message'
import transactionService from '../../services/transaction/TransactionService'
import DocumentService from '../../services/document/DocumentService'
import DepositService from 'services/deposit/DepositService'

interface ContributorAgentProps {
    procedure: string,
    contributorTypeToCopy: string[],
    transaction: Transaction,
    fieldStatus: FieldStatus,
    setIsRequired: (value: boolean) => void,
    activeGuidedMode?: boolean,
    choiceContributor?: string,
    option?: ContributorsOptionsForm
}

const ContributorAgent: FC<ContributorAgentProps> = ({
  procedure,
  contributorTypeToCopy,
  transaction,
  fieldStatus,
  setIsRequired,
  activeGuidedMode,
  choiceContributor,
  option = DEPOSIT_CONTRIBUTORS_OPTIONS.agent
}) => {
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const getIsEditableView = ((transaction.depositors && transaction.depositors.length > 1) && (!TransactionService.isInscription(transaction) || TransactionService.isRenunciation(transaction))) || (!!transaction.agent && !ContributorService.isDefaultAgentContributor(transaction.agent))
  const contributorType = DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value
  const [contributor, setContributor] = useState<Contributor|undefined>(transaction?.agent || { address: { country: 'FR' } })
  const [stateFieldStatus, setStateFieldStatus] = useState<FieldStatus>(fieldStatus ?? {})
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>([])
  const [isEditableView, setIsEditableView] = useState<boolean>(getIsEditableView)
  const [showAddressBook, setShowAddressBook] = useState<boolean>(false)
  const [showContactError, setShowContactError] = useState<boolean>(false)
  const [contactIdToReplace, setContactIdToReplace] = useState<number>()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEditableView) {
      setIsRequired(true)
    } else {
      setIsRequired(false)
    }
  }, [isEditableView])

  useEffect(() => {
    ContentService.getManageableQualities(TransactionService.getAdministrableContentProcedureType(transaction)).then((result) => {
      // Conditions qui permettent de récupérer les bonnes qualités en fonction du switch contributeur sélectionné et du switch du mode guidé
      let manageableQualities = []
      if (CONTRIBUTOR_GUIDED_PP.idBtnSwitch === choiceContributor && activeGuidedMode) {
        manageableQualities = result.filter((quality) => CONTRIBUTOR_GUIDED_PP.restrictedQualities.includes(quality.code))
      } else if (CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch === choiceContributor && activeGuidedMode) {
        manageableQualities = result.filter((quality) => CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.restrictedQualities.includes(quality.code))
      } else if (CONTRIBUTOR_GUIDED_PM.idBtnSwitch === choiceContributor && activeGuidedMode) {
        manageableQualities = result.filter((quality) => CONTRIBUTOR_GUIDED_PM.restrictedQualities.includes(quality.code))
      } else if (CONTRIBUTOR_GUIDED_ENTITY_FORMATION.idBtnSwitch === choiceContributor && activeGuidedMode) {
        manageableQualities = result.filter((quality) => CONTRIBUTOR_GUIDED_ENTITY_FORMATION.restrictedQualities.includes(quality.code))
      } else {
        manageableQualities = result.filter((quality) => quality.contributors.includes(CONTRIBUTOR_AGENT.value))
      }

      if (transaction?.agent && transaction.agent.manageableQuality?.code && !manageableQualities.find((quality: ManageableQuality) => quality.code === transaction.agent.manageableQuality.code)) {
        manageableQualities = [...manageableQualities, result.find((quality) => transaction.agent.manageableQuality.code === quality.code)]
      }

      setManageableQualities(manageableQualities)
      setContributor(transaction?.agent || { address: { country: 'FR' } })
      setIsEditableView(getIsEditableView)
    })
  }, [activeGuidedMode])

  useEffect(() => {
    StoreService.changeStore({ agent: contributor })
  }, [contributor])

  useEffect(() => {
    setStateFieldStatus(fieldStatus ?? {})
  }, [fieldStatus])

  const title = (
    <h2 className='mb-0 '>
      <FormattedMessage id={`contributor_${contributorType}`} />
    </h2>
  )

  const subtitle = (
    <span className='small'>
      <FormattedMessage
        id={`contributor_${contributorType}_subtitle_${transaction.procedureType?.toLowerCase()}`}
        values={{
          a: (...chunks : ((string) []) | []) => (<a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
          linebreak: <br />
        }}
      />
    </span>
  )

  /**
     * Met à jour l'intervenant (mais pas l'email si l'option isEmailReadOnly est à true)
     * @param updatedContributor
     */
  const updateContributor = (updatedContributor?: Contributor) => {
    let newContributor = { ...updatedContributor }
    if (option.isEmailReadOnly) {
      newContributor = {
        ...newContributor,
        email: contributor?.email
      }
    }

    setContributor(newContributor)
  }

  /**
   * Enregistre un document de pouvoir d'un intervenant
   * @param event
   */
  const addPowerDocument = async (event: EventType) => {
    ContributorService.addPowerDocument(event, contributor, transaction, contributorType).then((newContributor: Contributor) => {
      setContributor(newContributor)
    })
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  /**
   * Supprime le documents de pouvoir associé à l'intervenant
   */
  const deletePowerDocument = async (editedContributor?: Contributor) => {
    const updateContributor: Contributor|undefined = await ContributorService.deletePowerDocument(editedContributor ?? contributor, transaction, contributorType)
    updateContributor && setContributor(updateContributor)
  }

  /**
   * Fonction à appeler pour l'ajout d'un intervenant au carnet d'adresses
   */
  const addContactAddressBook = (contributor? : Contributor) => {
    const contact : Contact | undefined = ContactUtils.mapToContact(contributor)
    ContactService.addContact(contact).then((response) => {
      if (response) {
        if ('errorCode' in response) {
          if (response.errorCode === ContactUtils.ERROR_CONTACT_ALREADY_EXIST) {
            // On récupère l'id du contact déjà existant
            setContactIdToReplace(response.data)
            setShowContactError(true)
          }
        } else {
          toast.success(Message.success_add_contact)
        }
      }
    })
  }

  /**
   * Permet de supprimer le mandataire
   */
  const deleteAgent = () => {
    if (contributor?.id) {
      return transactionService.updateTransaction(transaction.id, { agent: EMPTY_CONTRIBUTOR }, true).finally(() => setIsEditableView(false))
    } else {
      setContributor(undefined)
      setIsEditableView(false)
    }
  }

  return (
    <div>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='contributor_form_title' /></h1>
          <span className='subtitle'><FormattedMessage id={`contributor_form_subtitle_${transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value ? transaction.subProcedureType : procedure}`} /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='mt-5'>
        {isEditableView ? (
          <ContributorForm
            getLanguages={ContentService.getLanguages}
            transaction={transaction}
            optionsForm={option}
            contributorType={contributorType}
            text={{ title: title, subtitle: subtitle }}
            contributor={contributor || DEFAULT_CONTRIBUTOR}
            contributorsToCopy={ContributorService.findAllDistinctContributors(transaction, contributorTypeToCopy)}
            currentETSUser={ContributorService.buildContributorFromUser(user)}
            setContributor={setContributor}
            fieldStatus={stateFieldStatus}
            getCountries={ContentService.getCountries}
            fillContributorInfo={(siren: string) => OpenDataRNCSService.getContributorInfos(siren)}
            findListSirenByNamePromise={(name: string) => OpenDataRNCSService.findListSirenByName(name)}
            manageableQualities={manageableQualities}
            addPowerDocument={addPowerDocument}
            deletePowerDocument={deletePowerDocument}
            dispatch={dispatch}
            resetError={storeTransactionFieldStatusUpdate}
            selectFromAddressBook={() => setShowAddressBook(true)}
            addContributorToAddressBook={addContactAddressBook}
            handleDownload={handleDownload}
            onDownloadClick={() => DepositService.getPowerExampleFile()}
          >
            {(((transaction?.depositors && transaction?.depositors?.length === 1 && (!TransactionService.isInscription(transaction) || TransactionService.isRenunciation(transaction))) || (transaction?.opponents && transaction?.opponents?.length === 1)) ||
                      (TransactionService.isInscription(transaction) && !TransactionService.isRenunciation(transaction))) && transaction.agent &&
                        <div className='row justify-content-center mt-3'>
                          <SubmitButton
                            className='col-4 btn btn-block btn-outline-gris'
                            onClick={() => deleteAgent()}
                          >
                            <FormattedMessage id='contributor_remove_agent' />
                          </SubmitButton>
                        </div>}
          </ContributorForm>
        ) : (
          <div className='form-intervenant is-validated'>
            <div className='form-header mx-0'>
              {title}
              {subtitle}
            </div>
            <div className='row justify-content-center mt-3'>
              <div className='col-12 col-md-4'>
                <button
                  className='btn btn-block btn-outline-primary' onClick={() => {
                    setIsEditableView(true)
                    setContributor({ ...contributor })
                  }}
                >
                  <FormattedMessage id='contributor_add_agent' />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ModalContactList
        showModal={showAddressBook}
        setShowModal={setShowAddressBook}
        setSelectedContact={(contact) => {
          // Ici on :
          // - ContactUtils.mapToContributor(contact) --> récupère les informations du contact
          // - ContributorValidator.cleanCopyContributor --> on filtre les informations en fonction de son type et si c'est PP/PM
          // - updateContributor --> enregistre les informations dans le contributor (en faisant un filtre sur l'email si elle est readOnly)
          updateContributor(ContributorValidator.cleanCopyContributor(ContactUtils.mapToContributor(contact), contributorType, contributor, transaction.subProcedureType))
        }}
      />
      <ModalContactError
        showModal={showContactError}
        setShowModal={setShowContactError}
        contact={ContactUtils.mapToContact(contributor)}
        contactIdToReplace={contactIdToReplace}
      />
    </div>
  )
}

export default ContributorAgent
