import Message from './Message'
import { SelectOption } from '../form/FormInterfaces'

export interface BrandType {
    value: string,
    title: string
}

export const BRAND_TYPE_WORD: BrandType = {
  value: 'BRAND_TYPE_WORD',
  title: 'brand_type_word_title'
}

export const BRAND_TYPE_FIGURATIVE: BrandType = {
  value: 'BRAND_TYPE_FIGURATIVE',
  title: 'brand_type_figurative_title'
}

export const BRAND_TYPE_SEMI_FIGURATIVE: BrandType = {
  value: 'BRAND_TYPE_SEMI_FIGURATIVE',
  title: 'brand_type_semi_figurative_title'
}

export const BRAND_TYPE_COLOR: BrandType = {
  value: 'BRAND_TYPE_COLOR',
  title: 'brand_type_color_title'
}

export const BRAND_TYPE_HOLOGRAM: BrandType = {
  value: 'BRAND_TYPE_HOLOGRAM',
  title: 'brand_type_hologram_title'
}

export const BRAND_TYPE_SHAPE: BrandType = {
  value: 'BRAND_TYPE_SHAPE',
  title: 'brand_type_shape_title'
}

export const BRAND_TYPE_SOUND: BrandType = {
  value: 'BRAND_TYPE_SOUND',
  title: 'brand_type_sound_title'
}

export const BRAND_TYPE_POSITION: BrandType = {
  value: 'BRAND_TYPE_POSITION',
  title: 'brand_type_position_title'
}

export const BRAND_TYPE_PATTERN: BrandType = {
  value: 'BRAND_TYPE_PATTERN',
  title: 'brand_type_pattern_title'
}

export const BRAND_TYPE_MOVEMENT: BrandType = {
  value: 'BRAND_TYPE_MOVEMENT',
  title: 'brand_type_movement_title'
}

export const BRAND_TYPE_MULTIMEDIA: BrandType = {
  value: 'BRAND_TYPE_MULTIMEDIA',
  title: 'brand_type_multimedia_title'
}

export const BRAND_TYPE_OTHER: BrandType = {
  value: 'BRAND_TYPE_OTHER',
  title: 'brand_type_other_title'
}

export const BRAND_TYPES = [
  BRAND_TYPE_WORD,
  BRAND_TYPE_FIGURATIVE,
  BRAND_TYPE_COLOR,
  BRAND_TYPE_HOLOGRAM,
  BRAND_TYPE_SHAPE,
  BRAND_TYPE_SOUND,
  BRAND_TYPE_POSITION,
  BRAND_TYPE_PATTERN,
  BRAND_TYPE_MOVEMENT,
  BRAND_TYPE_MULTIMEDIA,
  BRAND_TYPE_OTHER
]

export const BRAND_TYPES_SELECT_OPTIONS : SelectOption[] = [
  {
    value: 'BRAND_TYPE_WORD',
    label: Message.brand_type_word_title
  }, {
    value: 'BRAND_TYPE_FIGURATIVE',
    label: Message.brand_type_figurative_title
  }, {
    value: 'BRAND_TYPE_SEMI_FIGURATIVE',
    label: Message.brand_type_semi_figurative_title
  }, {
    value: 'BRAND_TYPE_3D',
    label: Message.brand_type_3d_title
  }, {
    value: 'BRAND_TYPE_COLOR',
    label: Message.brand_type_color_title
  }, {
    value: 'BRAND_TYPE_HOLOGRAM',
    label: Message.brand_type_hologram_title
  }, {
    value: 'BRAND_TYPE_SHAPE',
    label: Message.brand_type_shape_title
  }, {
    value: 'BRAND_TYPE_SOUND',
    label: Message.brand_type_sound_title
  }, {
    value: 'BRAND_TYPE_POSITION',
    label: Message.brand_type_position_title
  }, {
    value: 'BRAND_TYPE_PATTERN',
    label: Message.brand_type_pattern_title
  }, {
    value: 'BRAND_TYPE_MOVEMENT',
    label: Message.brand_type_movement_title
  }, {
    value: 'BRAND_TYPE_MULTIMEDIA',
    label: Message.brand_type_multimedia_title
  }, {
    value: 'BRAND_TYPE_OTHER',
    label: Message.brand_type_other_title
  }
]

export const ANTICIPATED_RENEWAL_ASSOCIATED_BRAND = 'Renouvellement effectué en même temps qu’un nouveau dépôt'
