import {
  CommonInscriptionService,
  ContributorValidator,
  DEFAULT_CONTRIBUTOR,
  EditorialBlock,
  FieldStatus,
  FilAriane,
  INSCRIPTION_CONTRIBUTORS_OPTIONS,
  INSCRIPTION_CONTRIBUTORS_TYPES,
  INSCRIPTION_RENUNCIATION_TYPE_TOTAL,
  INSCRIPTION_TYPE_RENUNCIATION,
  ModalComponent,
  NatureCode,
  PROCEDURE_INSCRIPTION,
  ProductClassVersion,
  ProductService,
  Title,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import ContributorAgent from 'component/contributors/ContributorAgent'
import ContributorFrontForm from 'component/contributors/ContributorFrontForm'
import ContributorList from 'component/contributors/ContributorList'
import PaymentForm from 'component/payment/PaymentForm'
import Recap from 'component/recap/Recap'
import OverviewValidator from 'component/recap/validator/OverviewValidator'
import StepContainer from 'component/stepper/StepContainer'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_RENUNCIATION } from 'constants/PaymentConstants'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import ContentService from 'services/content/ContentService'
import ContributorService from 'services/contributors/ContributorService'
import ContributorValidatorAndSaveStore from 'services/contributors/ContributorValidatorAndSaveStore'
import TransactionService from 'services/transaction/TransactionService'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from 'store/transaction/transactionActions'
import RenunciationTypes from './types/RenunciationTypes'
import RenunciationTypeValidator from './types/validator/RenunciationTypeValidator'
import Options from './options/Options'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
/* global localStorage */

interface RenunciationFormProps extends RouteComponentProps {
    isNewTransaction?: boolean
}

const RenunciationForm: FC<RenunciationFormProps> = ({ history, match, isNewTransaction = false }) => {
  const dispatch = useDispatch()

  const transaction: Transaction = useSelector((state: RootStateOrAny) => state.transaction)
  const fieldStatus: FieldStatus = useSelector((state: RootStateOrAny) => state.transactionFieldStatus)
  const [natureCodes, setNatureCode] = useState<NatureCode[]>([])

  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRgpdContent] = useState('')
  const [required, setIsRequired] = useState<boolean>(false)

  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)

  const user = useSelector((state: RootStateOrAny) => state.user.user)

  useEffect(() => {
    ContentService.getNatureCodesByType(INSCRIPTION_TYPE_RENUNCIATION.value).then((response: NatureCode[]) => {
      setNatureCode(response)
    })
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        setDocuments(result.documents ?? [])
      })
    } else {
      dispatch(storeTransactionInit(PROCEDURE_INSCRIPTION.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-RENONCIATION').then((res: EditorialBlock) => {
        if (res.content) {
          setRgpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  const handleSubmit = () => {
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  const onDocumentEdited = (editedDocument: TransactionDocument): void => {
    setDocuments([...documents.map((document: TransactionDocument) =>
      document.name === editedDocument.name && document.createdAt === editedDocument.createdAt
        ? editedDocument : document
    )])
  }

  const onDocumentAdded = (document: TransactionDocument) => {
    setDocuments([...documents, document])
  }

  const onDocumentDeleted = (deletedDocument: TransactionDocument) => {
    setDocuments(documents.filter((file: TransactionDocument) => !(deletedDocument.createdAt === file.createdAt && deletedDocument.internalName === file.internalName && deletedDocument.filename === file.filename)))
  }

  const areAllBrandProductsDeleted = (): boolean|undefined => {
    const title: Title|undefined = transaction.inscription?.titles?.[0]
    const productVersion: ProductClassVersion|undefined = ProductService.getCurrentVersion(title?.productsAndServicesVersions)
    return productVersion?.productClasses.every((productClass) => {
      return productClass.products.every((product) => product.status === 'DELETED')
    })
  }

  const transformPartialRenunciationToTotal = (): Transaction => {
    let transactionUpdated = transaction

    if (CommonInscriptionService.isPartialRenunciation(transaction) && areAllBrandProductsDeleted()) {
      transactionUpdated = {
        ...transaction,
        inscription: {
          ...transaction.inscription,
          renunciation: {
            ...transaction.inscription?.renunciation,
            type: INSCRIPTION_RENUNCIATION_TYPE_TOTAL.value
          }
        }
      }
    }

    const natureCode: NatureCode|undefined = natureCodes.find((natureCode: NatureCode) => CommonInscriptionService.isTotalRenunciation(transactionUpdated) ? natureCode.code === 'RT' : natureCode.code === 'RP')
    transactionUpdated = {
      ...transactionUpdated,
      inscription: {
        ...transactionUpdated.inscription,
        natureCodes: natureCode ? [{ code: natureCode.code, label: natureCode.label, psDisplayType: natureCode.psDisplayType }] : []
      }
    }

    return transactionUpdated
  }

  const saveInscriptionType = async () => {
    const transactionUpdated = transformPartialRenunciationToTotal()
    if (isNew && !transaction.id) {
      setIsNew(false)
      return await TransactionService.createTransaction(
        { ...transactionUpdated, subProcedureType: INSCRIPTION_TYPE_RENUNCIATION.value },
        PROCEDURE_INSCRIPTION.value,
        ['subProcedureType', 'inscription', 'suite'],
        documents)
    } else {
      return await TransactionService.updateTransactionBDDFromStore(transactionUpdated, ['subProcedureType', 'inscription', 'suite'], documents)
    }
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='renunciation_form_title' /></span>
      </FilAriane>
      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='renunciation_title' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        views={[
          {
            id: 'renunciation_type',
            label: <FormattedMessage id='renunciation_form_title' />,
            stepButtonsAsChildren: true,
            onGoToStep: saveInscriptionType,
            component: <RenunciationTypes natureCodes={natureCodes} fieldStatus={fieldStatus} transaction={transaction} onDocumentEdited={onDocumentEdited} onDocumentAdded={onDocumentAdded} onDocumentDeleted={onDocumentDeleted} documents={documents} isEdition={!isNewTransaction} />,
            validateGoToStep: () => RenunciationTypeValidator.validateOnChangeStep(transaction, fieldStatus)
          },
          {
            id: 'contributors',
            label: <FormattedMessage id='contributor_title' />
          },
          {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_depositors_renunciation' />,
            level: 1,
            component: (
              <ContributorList
                openOnFirstOne={!transaction.depositors?.length}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                contributorTypeLabel={`${INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value}_renunciation`}
                contributorTypeToCopy={[
                  INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value
                ]}
                optionForm={INSCRIPTION_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_INSCRIPTION.value}
                validateContributor={ContributorValidator.validateContributor}
                setIsRequired={setIsRequired}
                externalContributorsToCopy={transaction.inscription?.titles?.length ? transaction.inscription?.titles[0].holders : undefined}
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value, 'inscription'])
          },
          {
            id: 'contributors_agent',
            label: <FormattedMessage id='contributor_agent' />,
            level: 1,
            component: (
              <ContributorAgent
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorTypeToCopy={[
                  INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value
                ]}
                procedure={PROCEDURE_INSCRIPTION.value}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepAgentDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value, 'inscription'])
              .then((transaction: Transaction | null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  INSCRIPTION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.AGENT.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.OTHER_APPLICANTS.value,
                  INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value
                ]}
                optionForm={INSCRIPTION_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_INSCRIPTION.value}
                defaultContributor={{
                  ...DEFAULT_CONTRIBUTOR,
                  email: user.email,
                  consentNotificationByEmail: user.consentNotificationByEmailPreferences
                }}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.RECIPIENT.value, 'inscription'])
          },
          {
            id: 'contributors_signatory',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            hasGuidedSwitch: true,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={INSCRIPTION_CONTRIBUTORS_OPTIONS.signatory}
                procedure={PROCEDURE_INSCRIPTION.value}
                defaultContributor={ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value, 'inscription'])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, INSCRIPTION_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'options',
            label: <FormattedMessage id='options_label' />,
            component: (
              <Options transaction={transaction} />),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['inscription', 'internationalExtensions'])
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.INITIAL}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_RENUNCIATION}
              />
            )
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(RenunciationForm)
