import { NotificationConstant } from '../../interfaces/notifications/NotificationInterface'
import Message from '../Message'
import { SelectOption } from '../../form/FormInterfaces'
import {
  FIELD_INTERNAL_REFERENCE,
  FIELD_LAST_UPDATE, FIELD_NOTIFICATIONS_DEADLINE,
  FIELD_NOTIFICATIONS_SENDING_DATE, FIELD_NUMNAT,
  FIELD_PROCEDURE_TYPE
} from '../TransactionConstants'

export const NOTIFICATION_STATUS_ALL: NotificationConstant = {
  value: 'ALL',
  label: Message.notification_status_all
}

export const NOTIFICATION_STATUS_DRAFT: NotificationConstant = {
  value: 'DRAFT',
  label: Message.notification_status_draft
}

export const NOTIFICATION_STATUS_TO_REVIEW: NotificationConstant = {
  value: 'TOREVIEW',
  label: Message.notification_status_to_review
}

export const NOTIFICATION_STATUS_REVIEWED: NotificationConstant = {
  value: 'REVIEWED',
  label: Message.notification_status_reviewed
}

export const NOTIFICATION_STATUS_VALIDATED: NotificationConstant = {
  value: 'VALIDATED',
  label: Message.notification_status_validated
}

export const NOTIFICATION_STATUS_TO_CONSIDER: NotificationConstant = {
  value: 'TOCONSIDER',
  label: Message.notification_status_to_consider
}

export const NOTIFICATION_STATUS_CONSIDERED_FO: NotificationConstant = {
  value: 'CONSIDERED_FO',
  label: Message.notification_status_considered_fo
}

export const NOTIFICATION_STATUS_CONSIDERED: NotificationConstant = {
  value: 'CONSIDERED',
  label: Message.notification_status_considered
}

export const NOTIFICATION_STATUS_TO_ANSWER: NotificationConstant = {
  value: 'TOANSWER',
  label: Message.notification_status_to_answer
}

export const NOTIFICATION_STATUS_TO_ANSWER_OMPI: NotificationConstant = {
  value: 'TOANSWEROMPI',
  label: Message.notification_status_to_answer_ompi
}

export const NOTIFICATION_STATUS_ANSWERED: NotificationConstant = {
  value: 'ANSWERED',
  label: Message.notification_status_answered
}

export const NOTIFICATION_STATUS_CLOSEDANSWERED: NotificationConstant = {
  value: 'CLOSEDANSWERED',
  label: Message.notification_status_closedAnswered
}

export const NOTIFICATION_STATUS_CLOSEDNOTANSWERED: NotificationConstant = {
  value: 'CLOSEDNOTANSWERED',
  label: Message.notification_status_closedNotAnswered
}

export const NOTIFICATION_STATUS_EXPIRED: NotificationConstant = {
  value: 'EXPIRED',
  label: Message.notification_status_expired
}

export const NOTIFICATION_STATUS_NOT_CONSIDERED: NotificationConstant = {
  value: 'NOT_CONSIDERED',
  label: Message.notification_status_not_considered
}

export const NOTIFICATION_STATUS_CLOSED_CONSIDERED: NotificationConstant = {
  value: 'CLOSED_CONSIDERED',
  label: Message.notification_status_closed_considered
}

export const NOTIFICATION_STATUS_CLOSED_NOTCONSIDERED: NotificationConstant = {
  value: 'CLOSED_NOTCONSIDERED',
  label: Message.notification_status_closed_not_considered
}

export const NOTIFICATION_STATUS_CLOSED_SEND: NotificationConstant = {
  value: 'CLOSED_SEND',
  label: Message.notification_status_closed_send
}

export const NOTIFICATION_STATUS_TO_CLOSE_SENT: NotificationConstant = {
  value: 'TO_CLOSE_SENT',
  label: Message.notification_status_to_close_sent
}

export const NOTIFICATION_STATUS: NotificationConstant[] = [
  NOTIFICATION_STATUS_DRAFT,
  NOTIFICATION_STATUS_TO_REVIEW,
  NOTIFICATION_STATUS_REVIEWED,
  NOTIFICATION_STATUS_VALIDATED,
  NOTIFICATION_STATUS_TO_CONSIDER,
  NOTIFICATION_STATUS_CONSIDERED,
  NOTIFICATION_STATUS_TO_ANSWER,
  NOTIFICATION_STATUS_ANSWERED,
  NOTIFICATION_STATUS_CLOSEDANSWERED,
  NOTIFICATION_STATUS_CLOSEDNOTANSWERED,
  NOTIFICATION_STATUS_EXPIRED,
  NOTIFICATION_STATUS_NOT_CONSIDERED,
  NOTIFICATION_STATUS_CLOSED_CONSIDERED,
  NOTIFICATION_STATUS_CLOSED_NOTCONSIDERED,
  NOTIFICATION_STATUS_CLOSED_SEND,
  NOTIFICATION_STATUS_TO_CLOSE_SENT
]

export const NOTIFICATION_OMPI_STATUS: NotificationConstant[] = [
  NOTIFICATION_STATUS_TO_ANSWER_OMPI,
  NOTIFICATION_STATUS_ANSWERED
]

export const NOTIFICATION_TYPE_EXPECTED_RESPONSE: NotificationConstant = {
  value: 'EXPECTED_RESPONSE',
  label: Message.request_notification_response_expected
}
export const NOTIFICATION_TYPE_AGREEMENT_NOTIFICATION: NotificationConstant = {
  value: 'AGREEMENT_NOTIFICATION',
  label: Message.request_notification_agreement_notification
}
export const NOTIFICATION_TYPE_NO_RESPONSE_EXPECTED: NotificationConstant = {
  value: 'NO_RESPONSE_EXPECTED',
  label: Message.request_notification_no_response_expected
}

export const NOTIFICATION_TYPE_INFORMATION_MAIL: NotificationConstant = {
  value: 'INFORMATION_MAIL',
  label: Message.request_notification_information_mail
}

export const NOTIFICATION_TYPES: NotificationConstant[] = [
  NOTIFICATION_TYPE_EXPECTED_RESPONSE,
  NOTIFICATION_TYPE_AGREEMENT_NOTIFICATION,
  NOTIFICATION_TYPE_NO_RESPONSE_EXPECTED,
  NOTIFICATION_TYPE_INFORMATION_MAIL
]

export const NOTIFICATION_STATUS_FILTER_1: NotificationConstant[] = [
  NOTIFICATION_STATUS_DRAFT,
  NOTIFICATION_STATUS_TO_REVIEW,
  NOTIFICATION_STATUS_REVIEWED
]

export const NOTIFICATION_STATUS_FILTER_2: NotificationConstant[] = [
  NOTIFICATION_STATUS_DRAFT,
  NOTIFICATION_STATUS_TO_REVIEW,
  NOTIFICATION_STATUS_REVIEWED,
  NOTIFICATION_STATUS_VALIDATED,
  NOTIFICATION_STATUS_TO_ANSWER,
  NOTIFICATION_STATUS_EXPIRED,
  NOTIFICATION_STATUS_ANSWERED
]

export const NOTIFICATION_STATUS_FILTER_3: NotificationConstant[] = [
  NOTIFICATION_STATUS_DRAFT,
  NOTIFICATION_STATUS_TO_REVIEW,
  NOTIFICATION_STATUS_REVIEWED,
  NOTIFICATION_STATUS_VALIDATED,
  NOTIFICATION_STATUS_TO_CONSIDER,
  NOTIFICATION_STATUS_CONSIDERED_FO,
  NOTIFICATION_STATUS_CONSIDERED
]

export const NOTIFICATION_STATUS_SENT: string[] = [
  NOTIFICATION_STATUS_TO_ANSWER.value,
  NOTIFICATION_STATUS_TO_CONSIDER.value,
  NOTIFICATION_STATUS_CONSIDERED_FO.value,
  NOTIFICATION_STATUS_TO_CLOSE_SENT.value
]

export const NOTIFICATION_STATUS_CLOSED: NotificationConstant[] = [
  NOTIFICATION_STATUS_CLOSEDANSWERED,
  NOTIFICATION_STATUS_CLOSEDNOTANSWERED,
  NOTIFICATION_STATUS_CLOSED_CONSIDERED,
  NOTIFICATION_STATUS_CLOSED_NOTCONSIDERED,
  NOTIFICATION_STATUS_CLOSED_SEND
]

export const NOTIFICATION_STATUS_FO_STATUS: string[] = [
  NOTIFICATION_STATUS_CLOSEDANSWERED.value,
  NOTIFICATION_STATUS_CLOSEDNOTANSWERED.value,
  NOTIFICATION_STATUS_CONSIDERED.value,
  NOTIFICATION_STATUS_NOT_CONSIDERED.value,
  NOTIFICATION_STATUS_CLOSED_CONSIDERED.value,
  NOTIFICATION_STATUS_CLOSED_NOTCONSIDERED.value,
  NOTIFICATION_STATUS_TO_ANSWER.value,
  NOTIFICATION_STATUS_TO_CONSIDER.value,
  NOTIFICATION_STATUS_CONSIDERED_FO.value,
  NOTIFICATION_STATUS_EXPIRED.value,
  NOTIFICATION_STATUS_ANSWERED.value,
  NOTIFICATION_STATUS_CLOSED_SEND.value,
  NOTIFICATION_STATUS_TO_CLOSE_SENT.value
]

export const NOTIFICATION_STATUS_COLLAPSE: string[] = [
  NOTIFICATION_STATUS_TO_ANSWER.value,
  NOTIFICATION_STATUS_TO_CONSIDER.value,
  NOTIFICATION_STATUS_CONSIDERED_FO.value
]

export const NOTIFICATION_PRICE_TABLE_TITLES = [
  {
    label: Message.notification_price_table_code,
    className: 'text-gris border-0'
  }, {
    label: Message.notification_price_table_label,
    className: 'text-gris border-0'
  }, {
    label: Message.notification_price_table_price,
    className: 'text-gris border-0'
  },
  {
    label: Message.notification_price_table_quantity,
    className: 'text-gris border-0'
  },
  {
    label: Message.notification_price_table_amount,
    className: 'text-gris border-0'
  }
]

export const OPTION_EDITIQUE_SIMPLE: SelectOption = {
  value: 'EDITIQUE_SIMPLE',
  label: 'Courrier simple'
}
export const OPTION_EDITIQUE_REGISTERED: SelectOption = {
  value: 'EDITIQUE_REGISTERED',
  label: 'Recommandé sans AR'
}
export const OPTION_EDITIQUE_REGISTERED_AR: SelectOption = {
  value: 'EDITIQUE_REGISTERED_AR',
  label: 'Recommandé avec AR'
}

export const SENDING_PORTAL = 'PORTAL'
export const NOTIF_TYPE_NO_RESPONSE_EXPECTED = 'NO_RESPONSE_EXPECTED'

export const EDITIQUE_SENDING_OPTIONS: SelectOption[] = [
  OPTION_EDITIQUE_SIMPLE,
  OPTION_EDITIQUE_REGISTERED,
  OPTION_EDITIQUE_REGISTERED_AR
]

export const TABLE_TITLE_NOTIFS = [
  {
    label: Message.notification_table_label_procedure,
    value: FIELD_PROCEDURE_TYPE
  },
  {
    label: Message.notification_table_label_number,
    value: FIELD_NUMNAT
  },
  {
    label: Message.notification_table_label_update_date,
    value: FIELD_LAST_UPDATE
  },
  {
    label: Message.notification_table_label_ref,
    value: FIELD_INTERNAL_REFERENCE
  },
  {
    label: Message.notification_table_label_notification_sent_date,
    value: FIELD_NOTIFICATIONS_SENDING_DATE
  },
  {
    label: Message.notification_table_label_response_deadline,
    value: FIELD_NOTIFICATIONS_DEADLINE
  }
]

// Notification ouverte (en cours)
export const NOTIFICATION_OPEN: NotificationConstant[] = [
  NOTIFICATION_STATUS_TO_ANSWER,
  NOTIFICATION_STATUS_TO_REVIEW,
  NOTIFICATION_STATUS_TO_CONSIDER,
  NOTIFICATION_STATUS_CONSIDERED_FO,
  NOTIFICATION_STATUS_CONSIDERED,
  NOTIFICATION_STATUS_REVIEWED,
  NOTIFICATION_STATUS_VALIDATED
]
