import {
  DOCUMENT_FORMATS,
  DOCUMENT_LIMITS,
  ErrorField,
  EventType,
  FileBrowserField,
  FileButton,
  ModalComponent,
  SubmitButton,
  Transaction
} from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import DepositService from '../../../../services/deposit/DepositService'
import { storeTransactionUpdate } from '../../../../store/transaction/transactionActions'
import { useDispatch } from 'react-redux'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface DepositFromXMLProps {
  transaction: Transaction
}
const DepositFromXML: FC<DepositFromXMLProps> = ({ transaction }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [showModalXML, setShowModalXML] = useState(false)
  const [errorsXML, setErrorsXML] = useState<string[]>([])
  const [warningsXML, setWarningsXML] = useState<string[]>([])
  const [documentXML, setDocumentXML] = useState()
  const [hasFailed, setHasFailed] = useState(false)

  /**
   * Lors de l'import d'un fichier, récupération des informations concernant le dépôt
   */
  const onSubmitXMLOrZipFile = () => {
    if (documentXML) {
      setErrorsXML([])
      setWarningsXML([])
      setHasFailed(false)
      return DepositService.createDepositFromXmlOrZipFile(documentXML).then((result) => {
        result && dispatch(storeTransactionUpdate({ ...transaction, ...result }))
        setShowModalXML(false)
        setErrorsXML([])
        setHasFailed(false)
      }).catch(response => {
        setErrorsXML(response.errors ? [...response.errors] : [])
        setWarningsXML(response.warnings ? [...response.warnings] : [])
        setShowModalXML(true)
        setHasFailed(response.hasFailed === true || response.hasFailed === undefined)
        if (!response.hasFailed) {
          dispatch(storeTransactionUpdate({ ...transaction, ...response.transaction }))
        }
      })
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-end mb-3'>
        <SubmitButton
          className='btn-link-primary mr-3'
          onClick={() => setShowModalXML(true)}
        >
          <FontAwesomeIcon icon={faDownload} size='xs' className='mr-2 icon-download ' />
          <FormattedMessage id='transaction_import_deposit' />
        </SubmitButton>
      </div>
      <ModalComponent
        title={<FormattedMessage id='deposit_file_modal_title' />}
        show={showModalXML}
        handleClose={() => setShowModalXML(false)}
        customContent={() => (
          <div className='is-validated'>
            <FormattedMessage id='deposit_import_file_explain' />
            <div className='mt-3'>
              <FileBrowserField
                inputId='file'
                required
                label={<FormattedMessage id='deposit_import_file_label' />}
                buttonLabel={<div className='text-center'><FileButton /></div>}
                onDelete={() => {
                  setDocumentXML(null)
                  setErrorsXML([])
                  setWarningsXML([])
                  setHasFailed(false)
                }}
                value={documentXML && [documentXML]}
                onChange={(event: EventType) => {
                  const { value } = event.target
                  setDocumentXML(value)
                }}
                acceptApplication={DOCUMENT_FORMATS.XML + ',' + DOCUMENT_FORMATS.ZIP}
                maxNumberFile={1}
                maxFileSize={DOCUMENT_LIMITS.XML_MAX_SIZE}
                informationDoc
              />
            </div>
            {(errorsXML.length > 0 || hasFailed)
              ? (
                <div className='mt-3'>
                  {hasFailed && <span className='text-red'><FormattedMessage id='deposit_file_modal_error' /></span>}
                  {!hasFailed && <span className='text-red'><FormattedMessage id='deposit_file_modal_success_with_error' /></span>}
                  {errorsXML.map((error: string, index: number) =>
                    <div key={index} className='mt-2'>
                      <ErrorField className='relative-position' message={error} />
                    </div>)}
                </div>
              )
              : warningsXML.length > 0 &&
                <div className='mt-3'>
                  <span className='text-red'><FormattedMessage id='deposit_file_modal_success_with_warning' /></span>
                  {warningsXML.map((warning: string, index: number) =>
                    <div key={index} className='mt-2'>
                      <ErrorField className='relative-position' message={warning} />
                    </div>)}
                </div>}
          </div>
        )}
        validateLabel={(errorsXML.length === 0 && warningsXML.length > 0) ? intl.formatMessage({ id: 'common_continue' }) : intl.formatMessage({ id: 'common_validate' })}
        onClick={() => {
          (errorsXML.length === 0 && warningsXML.length > 0) ? setShowModalXML(false) : onSubmitXMLOrZipFile()
        }}
      />
    </div>
  )
}

export default DepositFromXML
