import React, { FC } from 'react'
import {
  FRMI
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'

interface LicenceEditionOverviewProps {
    frmi: FRMI
}

const LicenceEditionOverview: FC<LicenceEditionOverviewProps> = ({ frmi }) => {
  return (
    <>
      <h4 className='font-weight-bold'>
        <FormattedMessage id='frmi_licence_edition_label' />
      </h4>
      {
          frmi?.licenceEdition &&
            <span>{frmi?.licenceEdition}</span>
      }
    </>
  )
}

export default LicenceEditionOverview
