import React, { FC } from 'react'
import { FRMI } from '@inpi-marques/components'
import ReplacementTitlesTable from './ReplacementTitlesTable'

interface ReplacementTitlesOverviewProps {
    frmi: FRMI,
    className?: string
}
const ReplacementTitlesOverview: FC<ReplacementTitlesOverviewProps> = ({ frmi }) => {
  return (
    <>
      {
        frmi.replacementTitles &&
          <ReplacementTitlesTable
            titles={frmi.replacementTitles}
            readOnly
          />
      }
    </>
  )
}

export default ReplacementTitlesOverview
