import React, { Dispatch, FC, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { DATE_ISO } from '../constants/DateFormat'
import { PRODUCT_AND_SERVICE_VERSION_TYPE, PRODUCT_CLASS_VERSION_STATUS } from '../constants/DepositConstants'
import { EventType, FieldStatus } from '../form/FormInterfaces'
import Message from '../constants/Message'
import { DOCUMENT_FORMATS, DOCUMENT_TYPES } from '../constants/DocumentConstants'
import { TransactionDocument } from '../interfaces/Transaction'
import { ContestedRequest } from '../interfaces/opposition/ContestedRequest'
import {
  CONTESTED_REQUEST_FORM_EXCLUDED_FIELDS,
  Country,
  CountrySelectField,
  DateField,
  DateUtils,
  FileBrowserField,
  FileButton,
  NumNatVerifier,
  PROCEDURE_OPPOSITION,
  ProductClass,
  ProductClassVersion,
  ProductService,
  Record,
  SelectField,
  SwitchField,
  TextField
} from '../index'
import { BRAND_TYPES_SELECT_OPTIONS } from '../constants/BrandConstants'

interface ContestedBrandFormProps {
  contestedRequest: ContestedRequest,
  setContestedRequest: (newContestedRequest: ContestedRequest) => void,
  fieldStatus?: FieldStatus,
  dispatch: Dispatch<any>,
  currentVersionProductsAndServices?: ProductClass[],
  handleVerifyNumnat?: () => Promise<Record[]>,
  storeTransactionFieldStatusUpdate?: (fieldStatus: FieldStatus) => any,
  documents?: TransactionDocument[],
  handleDocumentChange?: (event: EventType) => void,
  handleDownload?: (document: TransactionDocument) => void,
  handleDocumentDelete?: (event: EventType) => void,
  getCountries: () => Promise<void | Country[]>,
  onRecordSelected?: (record: Record) => void,
  isBoEdition?: boolean,
  onSwitchIsTotalProductsAndServices?: (checked: boolean) => void,
  totalProductsAndServicesVersion?: ProductClassVersion[],
  setTotalProductsAndServicesVersion?: (versions: ProductClassVersion[]) => void,
  procedureType?: string
}

const ContestedBrandForm: FC<ContestedBrandFormProps> = ({
  contestedRequest,
  setContestedRequest,
  fieldStatus,
  documents,
  dispatch,
  currentVersionProductsAndServices,
  handleVerifyNumnat,
  storeTransactionFieldStatusUpdate,
  handleDocumentChange,
  handleDownload,
  handleDocumentDelete,
  getCountries,
  onRecordSelected,
  isBoEdition = false,
  onSwitchIsTotalProductsAndServices,
  setTotalProductsAndServicesVersion,
  totalProductsAndServicesVersion = [],
  procedureType
}) => {
  const [isPriorityClaimed, setIsPriorityClaimed] = useState<boolean>(contestedRequest.isPriorityClaimed || !!contestedRequest?.priority)
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    getCountries().then(response => {
      response && setCountries(response)
    })
  }, [])

  /**
   * Enregistrement des modifications de la demande d'enregistrement contestée
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target

    const newContestedRequest = {
      ...contestedRequest,
      [name]: value
    }
    setContestedRequest(newContestedRequest)
  }

  const onDateChanged = (event: EventType) => {
    const { name, value } = event.target
    const newContestedRequest = {
      ...contestedRequest,
      [name]: DateUtils.formatToBeginOfDay(value)
    }

    setContestedRequest(newContestedRequest)
  }

  /**
   * Changement marque française / internationale
   * Supprime les informations au changement
   * @param isInternationalBrand
   */
  const handleBrandTypeChange = (isInternationalBrand: boolean) => {
    const newContestedRequest = {
      numnat: contestedRequest.numnat,
      isInternationalBrand: isInternationalBrand
    }
    setContestedRequest(newContestedRequest)
  }

  const handleSwitchChangeIsPriorityClaimed = (checked: boolean) => {
    setIsPriorityClaimed(checked)
    let newContestedRequest = {
      ...contestedRequest,
      isPriorityClaimed: checked
    }
    if (!checked) {
      newContestedRequest = {
        ...newContestedRequest,
        priority: undefined
      }
    }
    setContestedRequest(newContestedRequest)
  }

  const handleSwitchChangeIsTotalProductsAndServices = (checked: boolean) => {
    let newContestedRequest = contestedRequest
    if (checked) {
      // Si l'opposition porte sur l'intégralité des produits et services
      // - soit on a trouvé une transaction de dépôt, alors on récupère les P&S de celle-ci
      // - sinon :
      //      - soit on a déjà un titre (avec le idRecord), on va le chercher et on récupère les P&S
      //      - sinon, on regarde s'il existe un titre dans le référentiel
      // Si on a trouvé un seul titre dans le référentiel, il faut créer le titre
      // Si on en a trouvé plusieurs, on affiche une popin pour que l'utilisateur choisisse un titre à créer
      // Si aucun titre trouvé, il ne peut pas cocher l'intégralité des P&S
      if (currentVersionProductsAndServices && (currentVersionProductsAndServices?.length || !isBoEdition)) {
        newContestedRequest = {
          ...newContestedRequest,
          isTotalProductsAndServices: checked
        }
        setContestedRequest(newContestedRequest)

        if (setTotalProductsAndServicesVersion) {
          setTotalProductsAndServicesVersion(contestedRequest.productsAndServicesVersions ? [...totalProductsAndServicesVersion, ProductService.createProductsAndServicesVersion(PRODUCT_AND_SERVICE_VERSION_TYPE.OPPOSITION_BO_VERSION, PRODUCT_CLASS_VERSION_STATUS.ACCEPTED, currentVersionProductsAndServices)]
            : [...totalProductsAndServicesVersion, ProductService.createProductsAndServicesVersion(PRODUCT_AND_SERVICE_VERSION_TYPE.OPPOSITION_INITIAL_VERSION, PRODUCT_CLASS_VERSION_STATUS.ACCEPTED, currentVersionProductsAndServices)]
          )
        }
      } else if (isBoEdition) {
        onSwitchIsTotalProductsAndServices && onSwitchIsTotalProductsAndServices(checked)
      }
    } else {
      setContestedRequest({
        ...contestedRequest,
        isTotalProductsAndServices: checked
      })
      setTotalProductsAndServicesVersion && setTotalProductsAndServicesVersion([])
    }
  }

  const onPriorityChange = (event: EventType, isDate?: boolean) => {
    const { name, value } = event.target
    const newPriority = {
      ...contestedRequest.priority,
      [name]: isDate ? DateUtils.formatToBeginOfDay(value) : value
    }

    const newContestedRequest: ContestedRequest = {
      ...contestedRequest,
      priority: newPriority
    }

    setContestedRequest(newContestedRequest)
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div>
        {
          !isBoEdition &&
            <label className='form-label mb-3'>
              <FormattedMessage id='request_identification_brand_type_label' />
            </label>
        }
        <div className='col-12 p-0'>
          <button
            className={`col-md-4 col-5 mb-4 mr-4 btn no-text-uppercase ${!contestedRequest.isInternationalBrand ? 'bg-primary' : 'btn-outline-primary'}`}
            onClick={() => handleBrandTypeChange(false)}
          >
            <FormattedMessage id='request_identification_french_brand' />
          </button>
          <button
            className={`col-md-5 col-6 mb-4 btn no-text-uppercase ${contestedRequest.isInternationalBrand ? 'bg-primary' : 'btn-outline-primary'}`}
            onClick={() => handleBrandTypeChange(true)}
          >
            <FormattedMessage id='request_identification_international_brand' />
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='mt-4 col-6 p-0'>
          <div className='col-12 mt-4'>
            <NumNatVerifier
              inputId='numnat'
              classNameFormGroup=''
              className='col-6 p-0'
              labelClassname='font-italic'
              verifyNumNat={handleVerifyNumnat}
              value={contestedRequest?.numnat}
              setValue={(value: string) => setContestedRequest({ ...contestedRequest, numnat: value })}
              isInternationalBrand={contestedRequest.isInternationalBrand}
              fieldStatus={fieldStatus}
              setFieldStatus={storeTransactionFieldStatusUpdate}
              countries={countries}
              onRecordSelected={onRecordSelected}
            />
          </div>
          <div className='col-12 mt-4'>
            <SelectField
              label={Message.brand_type_label}
              inputId='brandType'
              required
              classNameFormGroup='col-6 p-0'
              options={BRAND_TYPES_SELECT_OPTIONS}
              value={contestedRequest?.brandType}
              onChange={handleChanges}
              fieldStatus={fieldStatus}
              resetError={storeTransactionFieldStatusUpdate}
              placeholder={Message.brand_type_placeholder}
            />
          </div>
          <div className='col-12 mt-4'>
            <TextField
              inputId='contestedBrand'
              classNameFormGroup=''
              className='col-6 p-0'
              value={contestedRequest?.contestedBrand}
              label={<FormattedMessage id={`${contestedRequest.isInternationalBrand ? 'request_identification_contested_brand_name_international' : 'request_identification_contested_brand_name'}`} />}
              placeholder={contestedRequest.isInternationalBrand ? Message.form_brand_name_international_placeholder : Message.form_brand_name_placeholder}
              onChange={handleChanges}
              fieldStatus={fieldStatus}
              nameFieldStatus='contestedBrand'
              dispatch={dispatch}
              resetError={storeTransactionFieldStatusUpdate}
            />
          </div>
          <div className='col-12 mt-4'>
            <DateField
              inputId='depositDate'
              required
              className='col-6'
              value={DateUtils.formateDateToFormat(contestedRequest?.depositDate, DATE_ISO) ?? ''}
              label={<FormattedMessage id={`${contestedRequest.isInternationalBrand ? 'request_identification_date_international' : 'request_identification_deposit_date'}`} />}
              placeholder={Message.form_date_placeholder}
              onChange={onDateChanged}
              classNameLabel='col-12 p-0'
              fieldStatus={fieldStatus as FieldStatus}
              dispatch={dispatch}
              resetError={storeTransactionFieldStatusUpdate}
            />
          </div>
          {!CONTESTED_REQUEST_FORM_EXCLUDED_FIELDS[procedureType]?.includes('publicationNumber') &&
            <div className='col-12 mt-4'>
              <TextField
                inputId='publicationNumber'
                classNameFormGroup=''
                className='col-6 p-0'
                required
                value={contestedRequest?.publicationNumber}
                label={<FormattedMessage id={`${contestedRequest.isInternationalBrand ? 'request_identification_ompi_number' : 'request_identification_bopi_number'}`} />}
                placeholder={Message.form_number_placeholder}
                onChange={handleChanges}
                fieldStatus={fieldStatus}
                nameFieldStatus='publicationNumber'
                dispatch={dispatch}
                resetError={storeTransactionFieldStatusUpdate}
              />
            </div>}
          {!CONTESTED_REQUEST_FORM_EXCLUDED_FIELDS?.[`${procedureType}${contestedRequest?.isInternationalBrand ? 'MIFR' : ''}`]?.includes('registrationDate') &&
            <div className='col-12 mt-4'>
              <DateField
                inputId='registrationDate'
                required
                className='col-6'
                value={DateUtils.formateDateToFormat(contestedRequest?.registrationDate, DATE_ISO) ?? ''}
                label={<FormattedMessage id='request_identification_registration_date' />}
                placeholder={Message.form_date_placeholder}
                onChange={onDateChanged}
                classNameLabel='col-12 p-0'
                fieldStatus={fieldStatus as FieldStatus}
                dispatch={dispatch}
                resetError={storeTransactionFieldStatusUpdate}
              />
            </div>}
        </div>

        <div className='mt-4 col-6 p-0'>
          {!CONTESTED_REQUEST_FORM_EXCLUDED_FIELDS[procedureType]?.includes('registrationDate') && contestedRequest.isInternationalBrand &&
            <div className='col-12 mt-4'>
              <DateField
                inputId='grantProtectionDate'
                className='col-6'
                value={DateUtils.formateDateToFormat(contestedRequest?.grantProtectionDate, DATE_ISO) ?? ''}
                label={<FormattedMessage id='request_identification_grant_protection_date' />}
                placeholder={Message.form_date_placeholder}
                onChange={onDateChanged}
                classNameLabel='col-12 p-0'
                dispatch={dispatch}
              />
            </div>}
          {documents && !CONTESTED_REQUEST_FORM_EXCLUDED_FIELDS[procedureType]?.includes('copyRequest') &&
            <div className='col-12 mt-4'>
              <FileBrowserField
                classNameButton='d-contents'
                inputId='copyRequest'
                ulClassName='col-10'
                label={<FormattedMessage id={`${contestedRequest.isInternationalBrand ? 'request_identification_copy_international' : procedureType && procedureType !== PROCEDURE_OPPOSITION.value ? 'request_identification_revocation_copy' : 'request_identification_copy'}`} />}
                buttonLabel={<FileButton label={<FormattedMessage id='button_upload_file' />} />}
                onChange={handleDocumentChange}
                onDelete={handleDocumentDelete}
                maxNumberFile={1}
                value={documents.filter((document: TransactionDocument) => (document.type === DOCUMENT_TYPES.CONTESTED_BRAND_COPY))}
                fieldStatus={fieldStatus}
                acceptApplication={DOCUMENT_FORMATS.PDF}
                dispatch={dispatch}
                resetError={storeTransactionFieldStatusUpdate}
                handleDownload={handleDownload}
                required
                informationDoc
                placementTooltip='bottom'
              />
            </div>}

          <div className='col-12 mt-4'>
            <SwitchField
              inputId='isPriorityClaimed'
              divClassName='col-md-12 align-items-center p-0 mt-3 mb-3'
              className='col-2'
              labelClassName='col-7 p-0'
              onChange={handleSwitchChangeIsPriorityClaimed}
              value={isPriorityClaimed}
              label={<FormattedMessage id='request_identification_priority_claimed' />}
              width={50}
              height={30}
            />
          </div>
          {
            isPriorityClaimed && (
              <>
                <div className='col-12 mt-4'>
                  <CountrySelectField
                    inputId='countryOrOrganization'
                    classNameFormGroup='col-6 p-0'
                    required
                    label={<FormattedMessage id='priority_claimed_country' />}
                    placeholder={Message.form_country_placeholder}
                    value={contestedRequest.priority?.countryOrOrganization}
                    onChange={onPriorityChange}
                    fieldStatus={fieldStatus}
                    getCountries={getCountries}
                    isPriority
                  />
                </div>
                <div className='col-12 mt-4'>
                  <DateField
                    inputId='originalDepositDate'
                    required
                    className='col-6'
                    value={DateUtils.formateDateToFormat(contestedRequest.priority?.originalDepositDate, DATE_ISO) ?? ''}
                    label={<FormattedMessage id='priority_claimed_date' />}
                    placeholder={Message.form_date_placeholder}
                    onChange={(event) => onPriorityChange(event, true)}
                    classNameLabel='col-12 p-0'
                    fieldStatus={fieldStatus as FieldStatus}
                    dispatch={dispatch}
                    resetError={storeTransactionFieldStatusUpdate}
                  />
                </div>
              </>)
          }
          <div className='col-12 mt-4'>
            <SwitchField
              inputId='isTotalProductsAndServices'
              divClassName='col-md-12 align-items-center p-0 mt-3 mb-3'
              className='col-2'
              labelClassName='col-7 p-0'
              onChange={handleSwitchChangeIsTotalProductsAndServices}
              value={contestedRequest?.isTotalProductsAndServices}
              label={<FormattedMessage id={`${procedureType?.toLocaleLowerCase()}_total_products_and_services`} />}
              width={50}
              height={30}
            />
          </div>
        </div>
      </div>
    </IntlProvider>
  )
}

export default ContestedBrandForm
