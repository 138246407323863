import { Contact } from '../interfaces/Contact'
import { Contributor } from '@inpi-marques/components'

class ContactUtils {
  ERROR_CONTACT_ALREADY_EXIST : string = 'contact_already_exist';
  ERROR_CONTACT_MAX_NUMBER : string = 'contact_max_number'

  /**
   * Transforme contact en contributor
   * @param contact
   */
  mapToContributor = (contact?: Contact): Contributor|undefined => {
    if (contact) {
      // Copie les champs de contact sauf:
      //   owner,
      //   building,
      //   label,
      //   complement,
      //   zipCode,
      //   city,
      //   state,
      //   country,
      const contributor: Contributor = this.omit(contact,
        'id',
        'owner',
        'building',
        'label',
        'complement',
        'zipCode',
        'city',
        'state',
        'country')
      contributor.address = {
        building: contact.building,
        label: contact.label,
        complement: contact.complement,
        zipCode: contact.zipCode,
        city: contact.city,
        country: contact.country,
        state: contact.state
      }
      return contributor
    }
  }

  /**
   * Supprimer les champs non voulus
   */
  omit = (obj: any, ...props: any[]) => {
    const result = { ...obj }
    props.forEach(function (prop) {
      delete result[prop]
    })
    return result
  }

  /**
   * Transforme contributor en contact
   * @param contributor
   */
  mapToContact = (contributor?: Contributor) => {
    if (contributor) {
      // Copie les champs de contributor sauf :
      //   idUser,
      //   address,
      //   manageableQuality,
      //   internalNamePowerDocument,
      //   inscriptionNumber,
      //   isRecipient,
      const contact: Contact = this.omit(contributor,
        'id',
        'idUser',
        'address',
        'manageableQuality',
        'internalNamePowerDocument',
        'inscriptionNumber',
        'isRecipient')
      contact.building = contributor.address?.building
      contact.state = contributor.address?.state
      contact.label = contributor.address?.label
      contact.complement = contributor.address?.complement
      contact.zipCode = contributor.address?.zipCode
      contact.city = contributor.address?.city
      contact.country = contributor.address?.country
      return contact
    }
  }
}

export default new ContactUtils()
