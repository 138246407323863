import React, { FC, ReactNode, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutocompleteResult } from '..'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import SelectHolder from './SelectHolder'

interface SelectButtonProps<T> {
  className?: string,
  classNameAutocomplete?: string,
  messageEmpty?: ReactNode,
  disabled?: boolean,
  options: AutocompleteResult<T>[],
  onSelect?: (value: T) => void
}

/**
 * Créer un bouton affichant une liste d'options
 */
const SelectButton: FC<SelectButtonProps<any>> = ({
  className = '',
  classNameAutocomplete,
  messageEmpty,
  disabled = false,
  options,
  onSelect,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (value: any) => {
    setIsOpen(false)
    onSelect && onSelect(value)
  }

  return (
    <SelectHolder
      options={isOpen ? options : undefined}
      onSelect={handleSelect}
      className={classNameAutocomplete}
      messageEmpty={messageEmpty}
      onBlur={() => setIsOpen(false)}
    >
      <button
        className={`shadow-none ${className}`}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
      >
        {children}
        <FontAwesomeIcon icon={faChevronDown} className='ml-1' size='xs' />
      </button>
    </SelectHolder>
  )
}

export default SelectButton
