import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Contact } from '../../interfaces/Contact'
import {
  Country,
  FRANCE,
  PERSONNE_PHYSIQUE
} from '@inpi-marques/components'

interface OverviewAddressProps {
  contact: Contact,
  index: number,
  editContact?: (index: number) => void,
  deleteContact?: (index: number) => void,
  countries: Country[],
  canEdit: boolean,
  onContactSelect?: (contact: Contact) => void
}

const OverviewAddress: FC<OverviewAddressProps> = (
  {
    contact,
    index,
    editContact,
    deleteContact,
    countries,
    canEdit,
    onContactSelect
  }) => {
  const [country, setCountry] = useState<string>('')

  useEffect(() => {
    if (countries) {
      const c = countries.find(item => item.code === contact.country)
      if (c && c.name) {
        setCountry(c.name)
      }
    }
  }, [contact, countries])

  /**
   * Affiche le nom, prénom de l'intervenant
   */
  const renderBasicInfos = () => (
    <div>
      {contact.lastname ? `${contact.lastname} ` : ''}
      {contact.firstname ? `${contact.firstname} ` : ''}
    </div>
  )

  /**
   * Render les informations principales de l'intervenant
   */
  const renderPrimaryInfos = () => {
    if (contact.type === PERSONNE_PHYSIQUE.value) {
      if (contact.personFormation) {
        return (
          <div>
            {contact.companyNameFormation ? `${contact.companyNameFormation}, ` : ''}
            {renderBasicInfos()}
          </div>
        )
      } else {
        return (
          <div>
            {renderBasicInfos()}
          </div>
        )
      }
    } else {
      return (
        <div>
          {contact.personFormation &&
            <div>
              {contact.companyNameFormation ? `${contact.companyNameFormation}` : ''}
              {contact.legalFormFormation ? `, ${contact.legalFormFormation}` : ''}
            </div>}
          <div>
            {contact.fullCompanyName || contact.companyName}
            {renderBasicInfos()}
          </div>
        </div>
      )
    }
  }

  const renderAddress = () => {
    return (
      <div className='address-text'>
        {contact.building ? `${contact.building}, ` : ''}
        {contact.label ? contact.label : ''}
        {contact.state ? contact.state : ''}
        {contact.complement ? ` ${contact.complement}` : ''}
        ,
        {contact.zipCode ? ` ${contact.zipCode}` : ''}
        {` ${contact.city}`}
        {contact.country !== FRANCE && `\n${country}`}
      </div>
    )
  }

  return (
    <tr
      key={`-${index}`}
      className={onContactSelect && 'cursor-pointer'}
      onClick={() => onContactSelect && onContactSelect(contact)}
    >
      <td>{renderPrimaryInfos()}</td>
      <td>{contact.siren}</td>
      <td>{renderAddress()}</td>
      {canEdit &&
        <td className='btn-column pr-4'>
          <button className='btn btn-link text-primary' onClick={() => editContact && editContact(index)}>
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button className='btn btn-link text-danger ml-3' onClick={() => deleteContact && deleteContact(index)}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </td>}
    </tr>
  )
}

export default OverviewAddress
