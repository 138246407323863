import store from '../../../../../store/store'
import {
  BRAND_TYPE_WORD,
  containsErrors,
  FieldStatus,
  FRMI,
  isFilled,
  TransactionDocument
} from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import Message from 'constants/Message'

class BrandReproductionValidator {
  getValidatedFieldStatus = (documents: TransactionDocument[], frmi?: FRMI): FieldStatus => {
    const frmiFieldStatus: FieldStatus = {}

    if ((!frmi?.brand || !frmi?.brand.file || !isFilled(frmi.brand.file.internalName) || !isFilled(frmi.brand.file.hash)) && (!frmi?.titles || frmi?.titles[0].type !== BRAND_TYPE_WORD.value)) {
      frmiFieldStatus.brandDocument = Message.brand_file_required
    } else if (isFilled(frmi.brand?.file?.url) && frmi?.titles?.[0]?.type === BRAND_TYPE_WORD.value) {
      frmiFieldStatus.brandDocument = Message.brand_file_required
    }

    return frmiFieldStatus
  }

  validateOnChangeStep = (documents: TransactionDocument[], frmi?: FRMI) => {
    const fieldStatus: FieldStatus = this.getValidatedFieldStatus(documents, frmi)
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new BrandReproductionValidator()
