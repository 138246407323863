import React, { FC, useEffect, useState } from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ErrorField from '../form/errors/ErrorField'
import { EventType, ModalComponent } from '..'
import FullScreenButton from './FullScreenButton'
import ColorPic from './ColorPic/ColorPicker'
/* global FileReader */

interface EditorComponentProps {
  text: string,
  onBlur?: (value: string) => void,
  label?: any,
  topRightLabel?: any,
  inputId?: string,
  required?: boolean,
  fieldStatus?: any,
  height?: string,
  showFullScreenBtn?: boolean | null,
  disabled?: boolean
  className?: string
}

const EditorComponent: FC<EditorComponentProps> = ({
  text,
  onBlur,
  label,
  inputId,
  required,
  fieldStatus,
  height,
  showFullScreenBtn,
  disabled,
  className,
  topRightLabel
}) => {
  const [editorState, setEditorState] = useState()
  const [fullScreenDisplay, setFullScreenDisplay] = useState<boolean>(false)

  useEffect(() => {
    let state
    if (!text) {
      state = text
    } else {
      state = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(text).contentBlocks))
    }
    setEditorState(state)
  }, [text])

  const onEditorBlur = (event: EventType, valueOnBlurred: any) => {
    if (valueOnBlurred.getCurrentContent().getPlainText()) {
      const rawContentState = convertToRaw(valueOnBlurred.getCurrentContent())
      const value: string = draftToHtml(rawContentState,
        {}, true, ({ type, data }) => {
          // anomalie dans la lib, lorsque l'image est centrée dans l'éditeur, elle n'a pas le bon style dans le html généré
          if (type === 'IMAGE') {
            const alignment = data.alignment || 'none'
            const textAlign = alignment === 'none' ? 'center' : alignment

            return `
                <div style="text-align:${textAlign};">
                    <img src="${data.src}" alt="${data.alt}" style="height: ${data.height};width: ${data.width}"/>
                </div>
            `
          }
        })
      onBlur && onBlur(value)
    } else {
      onBlur && onBlur('')
    }
  }

  const uploadCallback = (file) => {
    return new Promise(
      (resolve) => {
        if (file) {
          const reader = new FileReader()
          reader.onload = (e) => {
            resolve({ data: { link: e.target.result } })
          }
          reader.readAsDataURL(file)
        }
      }
    )
  }

  const renderEditor = () => {
    return disabled
      ? <span dangerouslySetInnerHTML={{ __html: text }} /> : (
        <Editor
          editorState={editorState}
          toolbarClassName={fullScreenDisplay && 'editor-toolbar'}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'history'],
            inline: { inDropdown: false },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            image: {
              urlEnabled: true,
              uploadEnabled: true,
              alignmentEnabled: true,
              uploadCallback: uploadCallback,
              previewImage: true,
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
            },
            colorPicker: {
              component: ColorPic
            },
            history: {
              inDropdown: false,
              options: ['undo', 'redo']
            }
          }}
          spellCheck
          toolbarCustomButtons={showFullScreenBtn ? [
            <FullScreenButton
              key='fullscreen'
              fullScreenDisplay={fullScreenDisplay}
              setFullScreenDisplay={setFullScreenDisplay}
            />
          ] : []}
          onEditorStateChange={setEditorState}
          onBlur={onEditorBlur}
          editorStyle={{ minHeight: height }}
          editorClassName='editor-padding'
          disabled={disabled}
          handlePastedText={() => false}
        />
      )
  }

  return (
    <div className={`form-group ${className}`} id='editorComponent'>
      <div className='d-flex justify-content-between'>
        {label && (
          <label className='form-label' htmlFor={inputId}>
            {label}
            {required && <span className='text-danger'> *</span>}
          </label>
        )}
        {topRightLabel}
      </div>
      <div className='shadow'>
        {!fullScreenDisplay && renderEditor()}
        <ModalComponent
          title=''
          dialogClassName='modal-full-width modal-editor-fullscreen'
          show={fullScreenDisplay}
          customContent={() => renderEditor()}
          handleClose={() => setFullScreenDisplay(false)}
          onClick={() => setFullScreenDisplay(false)}
          size='xl'
          hideHeader
        />
      </div>
      {fieldStatus && inputId && <ErrorField message={fieldStatus[inputId]} />}
    </div>
  )
}

export default EditorComponent
