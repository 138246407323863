import {
  DOCUMENT_TYPES,
  FieldStatus,
  Foundation,
  isFilled,
  messageIsFilled,
  FoundationService as CommonFoundationService,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS,
  OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER,
  OPPOSITION_FOUNDATION_TYPE_BRAND,
  OPPOSITION_FOUNDATION_TYPE_RENOWNED,
  OPPOSITION_FOUNDATION_TYPE_COLLECTIVITY,
  OPPOSITION_FOUNDATION_TYPE_AOIG,
  OPPOSITION_FOUNDATION_TYPE_UNAUTHORIZED,
  OPPOSITION_FOUNDATION_TYPE_TRADE_NAME,
  OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_COMMERCIAL,
  OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_SIGN,
  FoundationService,
  OPPOSITION_FOUNDATION_TYPE_IGCRAFT,
  PROCEDURE_OPPOSITION,
  OPPOSITION_FOUNDATION_TYPE_INSTITUTION,
  OPPOSITION_FOUNDATION_TYPE_DENOMINATION,
  OPPOSITION_FOUNDATION_TYPE_DOMAIN_NAME
} from '@inpi-marques/components'
import { FoundationDocument } from '@inpi-marques/components/src/interfaces/opposition/Opposition'

import Message from '../../../constants/Message'
import { boolIsFilled } from '../../../utils/validationUtils'

class FoundationValidator {
  validateBrandFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    if (foundation.entity?.origin) {
      const signFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGNREPRESENTATION)
      const rightFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_EXISTENCE)
      const brandCopyFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, foundation.type === OPPOSITION_FOUNDATION_TYPE_RENOWNED.value ? DOCUMENT_TYPES.FOUNDATION_RENOWNED_SIGNCOPY : DOCUMENT_TYPES.MOTIVE_BRAND_SIGNCOPY)

      return {
        entity_name: foundation.entity.isWord ? messageIsFilled(foundation.entity?.name) : '',
        registering_number: CommonFoundationService.isInternationalBrand(foundation.entity.origin) ? messageIsFilled(foundation.entity?.registeringNumber) : '',
        deposit_number: !CommonFoundationService.isInternationalBrand(foundation.entity.origin) && foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value ? messageIsFilled(foundation.entity?.depositNumber) : '',
        registering_date: CommonFoundationService.isInternationalBrand(foundation.entity.origin) ? messageIsFilled(foundation.entity?.registeringDate) : '',
        deposit_date: !CommonFoundationService.isInternationalBrand(foundation.entity.origin) && foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value ? messageIsFilled(foundation.entity?.depositDate) : '',
        opponent_quality: messageIsFilled(foundation.opponent?.quality),
        subscription_number: foundation.opponent?.quality !== OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER.value && foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value ? messageIsFilled(foundation.opponent?.inscriptionNumber) : '',
        subscription_date: foundation.opponent?.quality !== OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER.value && foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value ? messageIsFilled(foundation.opponent?.inscriptionDate) : '',
        priority_country: isFilled(foundation.entity?.priorityDate) ? messageIsFilled(foundation.entity?.priorityCountry) : '',
        priority_date: isFilled(foundation.entity?.priorityCountry) ? messageIsFilled(foundation.entity?.priorityDate) : '',
        products: procedure !== PROCEDURE_OPPOSITION.value ? '' : messageIsFilled(foundation.productsAndServicesVersions),
        entity_type: foundation.entity.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value ? messageIsFilled(foundation.entity?.type) : '',
        sign_file: foundation.entity?.origin === OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value && !foundation.entity.isWord && !disableFiles && !signFiles.length ? Message.required_field : '',
        foundation_product: foundation.type === OPPOSITION_FOUNDATION_TYPE_BRAND.value && !foundation.areProductsIdentical && !foundation.areProductsSimilar ? Message.required_field : '',
        foundation_sign: foundation.type === OPPOSITION_FOUNDATION_TYPE_BRAND.value && !foundation.areSignsIdentical && !foundation.areSignsSimilar ? Message.required_field : '',
        rightFile: procedure !== PROCEDURE_OPPOSITION.value && foundation.entity?.origin === OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value && !rightFiles.length ? Message.required_field : '',
        brand_copy: foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value && procedure !== PROCEDURE_OPPOSITION.value && !disableFiles && !brandCopyFiles.length ? Message.required_field : ''
      }
    }
    return {
      entity_origin: Message.required_field,
      opponent_quality: messageIsFilled(foundation.opponent?.quality),
      products: procedure !== PROCEDURE_OPPOSITION.value ? '' : messageIsFilled(foundation.productsAndServicesVersions)
    }
  }

  validateCollectivityFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    const signFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, procedure === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LOCAL_AUTHORITY_SIGN : DOCUMENT_TYPES.FOUNDATION_EPCI_SIGN)
    const rightFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, procedure === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LOCAL_AUTHORITY_EXISTENCE : DOCUMENT_TYPES.FOUNDATION_EPCI_EXISTENCE)
    const proofFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_COLLECTIVITY_PROOF)

    return {
      entity_name: foundation.entity?.isWord ? messageIsFilled(foundation.entity.name) : '',
      sign_file: !foundation.entity?.isWord && !signFiles.length ? Message.required_field : '',
      activity: procedure === PROCEDURE_OPPOSITION.value ? messageIsFilled(foundation.activity) : '',
      rightFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles && !rightFiles.length ? Message.required_field : '',
      proofFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles && !proofFiles.length ? Message.required_field : ''
    }
  }

  validateInstitutionFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    const rightFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_ENTITY_PUBLIC_EXISTENCE)
    return {
      entity_name: messageIsFilled(foundation.entity?.name),
      rightFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(rightFiles) : '',
      activity: procedure === PROCEDURE_OPPOSITION.value ? messageIsFilled(foundation.activity) : ''
    }
  }

  validateDenominationFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    const rightFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_CORPORATE_EXISTENCE)
    return {
      entity_name: messageIsFilled(foundation.entity?.name),
      rightFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(rightFiles) : '',
      activity: procedure === PROCEDURE_OPPOSITION.value ? messageIsFilled(foundation.activity) : ''
    }
  }

  validateDomainNameFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    const rightFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_DOMAINNAME_EXISTENCE)
    return {
      entity_name: messageIsFilled(foundation.entity?.name),
      rightFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(rightFiles) : '',
      activity: procedure === PROCEDURE_OPPOSITION.value ? messageIsFilled(foundation.activity) : ''
    }
  }

  commonValidateFoundation = (foundation: Foundation): FieldStatus => ({
    entity_name: messageIsFilled(foundation.entity?.name)
  })

  validateAoigFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    const rightFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_AOIG_EXISTENCE)
    const opponentRightsFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, procedure === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LEGITIMACY : DOCUMENT_TYPES.FOUNDATION_OPPONENT_LEGITIMACY)

    return {
      entity_name: messageIsFilled(foundation.entity?.name),
      entity_type: messageIsFilled(foundation.entity?.type),
      protectingAct: messageIsFilled(foundation.entity?.protectingAct),
      activity: procedure === PROCEDURE_OPPOSITION.value ? messageIsFilled(foundation.activity) : '',
      rightFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(rightFiles) : '',
      opponentRightsFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(opponentRightsFiles) : ''
    }
  }

  validateUnauthorizedFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    const signFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGN)
    const rightFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.CONTESTED_BRAND_COPY_PERMISSION)
    return {
      entity_name: foundation.entity?.isWord ? messageIsFilled(foundation.entity?.name) : '',
      priority_country: isFilled(foundation.entity?.priorityDate) ? messageIsFilled(foundation.entity?.priorityCountry) : '',
      priority_date: isFilled(foundation.entity?.priorityCountry) ? messageIsFilled(foundation.entity?.priorityDate) : '',
      sign_file: !foundation.entity?.isWord && !signFiles.length ? Message.required_field : '',
      opponent_quality: messageIsFilled(foundation.opponent?.quality),
      products: procedure !== PROCEDURE_OPPOSITION.value ? '' : messageIsFilled(foundation.productsAndServicesVersions),
      territory: messageIsFilled(foundation.entity?.territory),
      rightFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(rightFiles) : '',
      isWord: boolIsFilled(foundation.entity?.isWord)
    }
  }

  validateTradeNameFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    const signFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_COMMERCIAL_SIGN)
    const rightFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_COMMERCIAL_EXISTENCE)

    return {
      entity_type: messageIsFilled(foundation.entity?.type),
      entity_name: foundation.entity?.isWord || foundation.entity?.type === OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_COMMERCIAL.value ? messageIsFilled(foundation.entity?.name) : '',
      sign_file: !foundation.entity?.isWord && foundation.entity?.type === OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_SIGN.value && !signFiles.length ? Message.required_field : '',
      activity: procedure === PROCEDURE_OPPOSITION.value ? messageIsFilled(foundation.activity) : '',
      rightFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(rightFiles) : ''
    }
  }

  validateIgCraftFoundation = (foundation: Foundation, disableFiles = false, procedure?: string|null): FieldStatus => {
    const homologationFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_IG_HOMOLOGATION)
    const opponentRightsFiles: FoundationDocument[] = FoundationService.getDocumentsByType(foundation.documents, procedure === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LEGITIMACY : DOCUMENT_TYPES.FOUNDATION_OPPONENT_LEGITIMACY)

    return {
      entity_name: messageIsFilled(foundation.entity?.name),
      opponentQuality: messageIsFilled(foundation.opponent?.quality),
      depositNumber: messageIsFilled(foundation.entity?.depositNumber),
      homologationFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(homologationFiles) : '',
      opponentRightsFile: procedure !== PROCEDURE_OPPOSITION.value && !disableFiles ? messageIsFilled(opponentRightsFiles) : '',
      activity: procedure === PROCEDURE_OPPOSITION.value ? messageIsFilled(foundation.activity) : ''
    }
  }

  validateEditForm = (foundation: Foundation, procedure: string|null = null, disableFiles = false) => {
    // Si le type du fondement n'est pas renseigné
    if (!foundation.type) {
      return { type: Message.required_field }
    }

    switch (foundation.type) {
      case OPPOSITION_FOUNDATION_TYPE_BRAND.value:
      case OPPOSITION_FOUNDATION_TYPE_RENOWNED.value:
        return this.validateBrandFoundation(foundation, disableFiles, procedure)
      case OPPOSITION_FOUNDATION_TYPE_COLLECTIVITY.value:
        return this.validateCollectivityFoundation(foundation, disableFiles, procedure)
      case OPPOSITION_FOUNDATION_TYPE_INSTITUTION.value:
        return this.validateInstitutionFoundation(foundation, disableFiles, procedure)
      case OPPOSITION_FOUNDATION_TYPE_DENOMINATION.value:
        return this.validateDenominationFoundation(foundation, disableFiles, procedure)
      case OPPOSITION_FOUNDATION_TYPE_DOMAIN_NAME.value:
        return this.validateDomainNameFoundation(foundation, disableFiles, procedure)
      case OPPOSITION_FOUNDATION_TYPE_IGCRAFT.value:
        return this.validateIgCraftFoundation(foundation, disableFiles, procedure)
      case OPPOSITION_FOUNDATION_TYPE_AOIG.value:
        return this.validateAoigFoundation(foundation, disableFiles, procedure)
      case OPPOSITION_FOUNDATION_TYPE_UNAUTHORIZED.value:
        return this.validateUnauthorizedFoundation(foundation, disableFiles, procedure)
      case OPPOSITION_FOUNDATION_TYPE_TRADE_NAME.value:
        return this.validateTradeNameFoundation(foundation, disableFiles, procedure)
      default:
        return this.commonValidateFoundation(foundation)
    }
  }
}

export default new FoundationValidator()
