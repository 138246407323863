import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { SubmitButton } from '../index'

interface ModalProps {
  title: any,
  show: boolean,
  handleClose?: () => void,
  onClick?: () => void,
  titleContent?: any,
  customContent?: () => any,
  size?: 'sm' | 'lg' | 'xl',
  hideFooter?: boolean | null
  customButtonId?: string,
  isNotCancellable?: boolean,
  validateLabel?: string | ReactNode,
  dialogClassName?: string,
  hideHeader?: boolean,
  link?: string,
  linkLabel?: string | ReactNode,
  ctrlEnterSubmit?: boolean,
  disableSubmitButton?: boolean,
  enableAbandonButton?: boolean
}

/**
 * Création d'une modale customisable
 * @param show - Valeur qui définie si la modale doit être affichée
 * @param handleClose - CallBack appelé quand la modale doit se fermer
 * @param onClick
 * @param size - Taille de la modale
 * @param titleContent - Titre du content
 * @param customContent - Body de la modale customisable
 * @param title - Titre de la modale
 * @param hideFooter - Affichage le footer
 * @param customButtonId - Nom personnalisé du bouton de validation
 * @param dialogClassName
 * @param validateLabel
 * @param isNotCancellable
 * @param hideHeader
 * @param link
 * @param linkLabel
 * @param ctrlEnterSubmit
 * @param children
 * @param disableSubmitButton - Mettre en gris le bouton submit
 * @param enableAbandonButton
 */
const ModalComponent: FC<ModalProps> = ({
  show,
  handleClose,
  onClick,
  size,
  titleContent,
  customContent,
  hideFooter,
  title,
  customButtonId,
  dialogClassName,
  validateLabel,
  isNotCancellable = false,
  hideHeader = false,
  link,
  linkLabel,
  ctrlEnterSubmit = true,
  children,
  enableAbandonButton = false
}) => {
  const submitRef = useRef()

  const keydownSubmitHandler = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      submitRef.current && submitRef.current.click && submitRef.current.click()
    }
  }
  const onClickAbandonne = () => {
    window.location.href = '/'
  }

  useEffect(() => {
    ctrlEnterSubmit && document.addEventListener('keydown', keydownSubmitHandler)

    return () => {
      ctrlEnterSubmit && document.removeEventListener('keydown', keydownSubmitHandler)
    }
  }, [])

  return (
    <IntlProvider locale='fr' messages={Message}>
      <Modal dialogClassName={dialogClassName} show={show} onHide={handleClose} size={size} scrollable backdrop={isNotCancellable ? 'static' : undefined}>
        {!hideHeader &&
          <Modal.Header closeButton={!!handleClose} className='bg-primary text-white border-bottom-0'>
            <Modal.Title style={{ fontSize: '1.8rem' }}>
              {title}
            </Modal.Title>
          </Modal.Header>}
        <Modal.Body>
          {titleContent && <label className='form-label'>{titleContent}</label>}
          {customContent && customContent()}
          {children}
        </Modal.Body>
        {
          !hideFooter &&
            <Modal.Footer className='d-block'>
              <div className='d-flex justify-content-around'>
                {enableAbandonButton &&
                  <SubmitButton className='btn-primary' onClick={onClickAbandonne}>
                    {validateLabel || <FormattedMessage id='common_abandonner' />}
                  </SubmitButton>}
                <SubmitButton className={enableAbandonButton ? 'btn-validate' : 'btn-primary'} ref={submitRef} onClick={onClick}>
                  {validateLabel || <FormattedMessage id={customButtonId || 'common_validate'} />}
                </SubmitButton>
              </div>
              {linkLabel && link &&
                <div className='d-flex justify-content-around mt-2'>
                  <a href={link} target='_blank' rel='noopener noreferrer'>{linkLabel}</a>
                </div>}
            </Modal.Footer>
        }
      </Modal>
    </IntlProvider>
  )
}

export default ModalComponent
