import {
  containsErrors,
  EventType,
  Transaction,
  TransactionDocument,
  CardBlock,
  DateUtils,
  DATE_ISO,
  FieldStatus,
  DateField,
  FileButton,
  FileBrowserField,
  DOCUMENT_FORMATS,
  SubmitButton,
  DOCUMENT_TYPES,
  DOCUMENT_DEFAULT,
  HelpBlock,
  downloadFile
} from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC, useEffect, useState } from 'react'
import DocumentService from '../../services/document/DocumentService'
import DocumentValidator from '../requests/valitdator/DocumentValidator'
import { toast } from 'react-toastify'
import { FormattedMessage, useIntl } from 'react-intl'
import TransactionService from '../../services/transaction/TransactionService'

interface FaxProps {
  transaction: Transaction,
  setTransaction: (transaction: Transaction) => void
}

const Fax: FC<FaxProps> = ({ transaction, setTransaction }) => {
  const intl = useIntl()
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const [document, setDocument] = useState<TransactionDocument>()

  useEffect(() => {
    // Récupération du document de type Fax si il en existe déjà un sur la transaction
    const faxDocument = transaction.documents?.find(item => item.type === DOCUMENT_TYPES.FAX)
    setDocument(faxDocument)
  }, [transaction.documents])

  const onFaxDateChanged = (event: EventType) => {
    const { value } = event.target
    setDocument({ ...document, createdAt: DateUtils.formatToBeginOfDay(value) })
  }

  /**
   * Ajoute le document en local
   * @param event
   */
  const handleAddDocument = (event: EventType) => {
    const file: File = event.target.value
    setDocument({
      ...document,
      type: DOCUMENT_TYPES.FAX,
      internalName: '',
      name: file.name,
      format: file.type,
      communicability: DOCUMENT_DEFAULT,
      file,
      size: file.size
    })
  }

  /**
   * Supprime le document en local
   */
  const deleteFaxDocument = async () => {
    if (!document.internalName) {
      setDocument({
        createdAt: document.createdAt
      })
    }
  }

  /**
   * Enregistrement du document via l'API et mise à jour de la transaction
   */
  const submitFaxDocument = async () => {
    const status = DocumentValidator.validateFaxDocument(document)
    setFieldStatus(status)

    if (!containsErrors(status)) {
      const result = await DocumentService.createDocument(document, transaction.id)
      if (result) {
        const updatedTransaction = await TransactionService.getTransaction(transaction.id)
        setTransaction(updatedTransaction)
        return toast.success(Message.request_add_fax_success)
      }
    } else {
      return null
    }
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  return (
    <div className='is-validated'>
      <CardBlock header={<FormattedMessage id='overview_fax' />} shadow>
        <HelpBlock className='mb-3'>
          <FormattedMessage id='fax_description' />
        </HelpBlock>
        <div className='d-flex align-items-end'>
          <DateField
            inputId='fax-deposit-date'
            required
            value={DateUtils.formateDateToFormat(document?.createdAt, DATE_ISO) ?? ''}
            label={<FormattedMessage id='fax_date' />}
            placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
            onChange={onFaxDateChanged}
            classNameFormGroup='my-0 mr-4'
            fieldStatus={fieldStatus as FieldStatus}
            nameFieldStatus='createdAt'
            readOnly={document && document.internalName}
          />
          <FileBrowserField
            inputId='file-fax'
            ulClassName='m-0'
            className='my-0 mr-4'
            acceptApplication={DOCUMENT_FORMATS.PDF}
            value={document?.name ? [document] : []}
            onChange={handleAddDocument}
            onDelete={document && !document.internalName && deleteFaxDocument}
            maxNumberFile={1}
            buttonLabel={<FileButton label={<FormattedMessage id='placeholder_fax_select' />} />}
            required
            nameFieldStatus='fileInputFax'
            fieldStatus={fieldStatus}
            handleDownload={handleDownload}
          />
          {(!document || !document.internalName) &&
            <SubmitButton className='btn-primary my-0' onClick={submitFaxDocument}>
              <FormattedMessage id='common_validate' />
            </SubmitButton>}
        </div>
      </CardBlock>
    </div>
  )
}

export default Fax
