import {
  PROCEDURE_DEPOSIT,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION,
  PROCEDURE_DIVISION,
  PROCEDURE_MIFR,
  PROCEDURE_FRMI,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_REVOCATION_STATEMENT, PROCEDURE_RENEWAL, PROCEDURE_RENUNCIATION
} from './ProcedureConstants'
import Message from './Message'
import { STATUS_OPPOSITION } from './OppositionConstants'
import { STATUS_ND } from './NDConstants'
import { DepositType } from '../interfaces/deposit/Deposit'
import { STATUS_DIVISION } from './DivisionConstants'
import { STATUS_MIFR } from './MIFRConstants'
import { STATUS_INSCRIPTION } from './InscriptionConstants'
import { STATUS_FRMI } from './FRMIConstants'
import { STATUS_OFFICIAL_DOCUMENT } from './OfficialDocumentConstants'
import { STATUS_REVOCATION_STATEMENT } from './revocation-statement/RevocationStatement'
import { STATUS_RENEWAL } from './RenewalConstants'

export const FIELD_LIMITS = {
  BRAND_DESCRIPTION_MAX_CHAR: 2000
}

export const STATUS_DEPOSIT = {
  /** Brouillon */
  DRAFT: 'draft',
  /** En attente d'un mémoire administratif */
  WAITING_VIR_PAYMENT: 'waiting_vir_payment',
  /** Mémoire administratif expiré */
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired',
  /** A attribuer */
  TO_ATTRIBUTE_ACCEPTABILITY: 'to_attribute_acceptability',
  /** Examen de recevabilité */
  CHECK_ACCEPTABILITY: 'check_acceptability',
  /** Arrêté recevabilité */
  STOP_ACCEPTABILITY: 'stop_acceptability',
  /** Irrecevable */
  NOT_ACCEPTED: 'not_accepted',
  /** A attribuer fond/forme */
  TO_ATTRIBUTE_REVIEW: 'to_attribute_review',
  /** Examen fond */
  TO_REVIEW_FIRST_EXAM: 'to_review_first_exam',
  /** Arrêté examen fond */
  FIRST_EXAM_STAND_BY: 'first_exam_stand_by',
  /** Examen forme */
  TO_REVIEW_SECOND_EXAM: 'to_review_second_exam',
  /** Arrêté examen forme */
  SECOND_EXAM_STAND_BY: 'second_exam_stand_by',
  /** Rejetée */
  REJECTED: 'rejected',
  /** Régulier */
  REGULAR: 'regular',
  /** Arrêté opposition */
  STOP_OPPOSITION: 'stop_opposition',
  /** A enregister */
  TO_REGISTER: 'to_register',
  /** Enregistrement en cours */
  REGISTERING: 'registering',
  /** Enregistré */
  REGISTERED: 'registered',
  /** Retrait total */
  TOTAL_WITHDRAWAL: 'total_withdrawal'

}

export const STATUS_DEPOSIT_REVIEWING = [
  STATUS_DEPOSIT.TO_ATTRIBUTE_ACCEPTABILITY,
  STATUS_DEPOSIT.CHECK_ACCEPTABILITY,
  STATUS_DEPOSIT.STOP_ACCEPTABILITY,
  STATUS_DEPOSIT.TO_ATTRIBUTE_REVIEW,
  STATUS_DEPOSIT.TO_REVIEW_FIRST_EXAM,
  STATUS_DEPOSIT.TO_REVIEW_SECOND_EXAM,
  STATUS_DEPOSIT.FIRST_EXAM_STAND_BY,
  STATUS_DEPOSIT.SECOND_EXAM_STAND_BY,
  STATUS_DEPOSIT.REGULAR,
  STATUS_DEPOSIT.TO_REGISTER,
  STATUS_DEPOSIT.NOT_ACCEPTED
]

export const STATUS_DEPOSIT_FINAL = [
  STATUS_DEPOSIT.REGISTERED,
  STATUS_DEPOSIT.REJECTED,
  STATUS_DEPOSIT.TOTAL_WITHDRAWAL
]

export const STATUS_DEPOSIT_CONTINUOUS_REVIEW : string[] = [
  STATUS_DEPOSIT.CHECK_ACCEPTABILITY
]

export const STATUS_DEPOSIT_IS_READ_CONTINUOUS_REVIEW : string[] = [
  STATUS_DEPOSIT.TO_REVIEW_FIRST_EXAM,
  STATUS_DEPOSIT.TO_REVIEW_SECOND_EXAM,
  STATUS_MIFR.TO_REVIEW
]

export const STATUS_REJECTED_OR_NOT = [
  {
    label: Message.rejected_inscription_total,
    value: [STATUS_DEPOSIT.REJECTED]
  },
  {
    label: Message.rejected_inscription_partial,
    value: [STATUS_DEPOSIT.REGULAR, STATUS_DEPOSIT.FIRST_EXAM_STAND_BY, STATUS_DEPOSIT.SECOND_EXAM_STAND_BY, STATUS_DEPOSIT.TO_REGISTER, STATUS_DEPOSIT.REGISTERING, STATUS_DEPOSIT.REGISTERED]
  }
]

export const PRODUCT_AND_SERVICE_VERSION_TYPE = {
  INITIAL_VERSION: 'INITIAL_VERSION',
  VERSION_REGULARIZATION: 'VERSION_REGULARIZATION',
  BO_VERSION: 'BO_VERSION',
  TOTAL_WITHDRAW_VERSION: 'TOTAL_WITHDRAW_VERSION',
  PARTIAL_WITHDRAW_VERSION: 'PARTIAL_WITHDRAW_VERSION',
  OPPOSITION_BO_VERSION: 'OPPOSITION_BO_VERSION',
  OPPOSITION_INITIAL_VERSION: 'OPPOSITION_INITIAL_VERSION',
  RENUNCIATION_TYPE: 'RENUNCIATION_TYPE',
  SUBDIVISION: 'SUBDIVISION'
}

export const PRODUCT_AND_SERVICE_VERSION_TYPE_FILTER_WITHDRAW = [
  {
    label: Message.product_and_services_total_withdrawal,
    value: 'TOTAL_WITHDRAW_VERSION'
  },
  {
    label: Message.product_and_services_partial_withdrawal,
    value: 'PARTIAL_WITHDRAW_VERSION'
  }
]

export const WITHDRAW_TO_REGISTER = [
  {
    label: Message.product_and_services_total_withdrawal,
    value: [STATUS_DEPOSIT.TOTAL_WITHDRAWAL]
  },
  {
    label: Message.product_and_services_partial_withdrawal,
    value: [STATUS_DEPOSIT.REGULAR, STATUS_DEPOSIT.FIRST_EXAM_STAND_BY, STATUS_DEPOSIT.SECOND_EXAM_STAND_BY, STATUS_DEPOSIT.TO_REVIEW_FIRST_EXAM, STATUS_DEPOSIT.TO_REVIEW_SECOND_EXAM, STATUS_DEPOSIT.TO_REGISTER, STATUS_DEPOSIT.REGISTERING, STATUS_DEPOSIT.REGISTERED]
  }
]

export const PRODUCT_STATUS = {
  DELETED: 'DELETED',
  ADDED: 'ADDED'
}

export type Status = typeof STATUS_DEPOSIT | typeof STATUS_OPPOSITION | typeof STATUS_ND | typeof STATUS_DIVISION | typeof STATUS_MIFR | typeof STATUS_REVOCATION_STATEMENT | typeof STATUS_INSCRIPTION | typeof STATUS_FRMI | typeof STATUS_OFFICIAL_DOCUMENT | typeof STATUS_RENEWAL

export interface IStatus {
  value: string
  status: Status
}

export const STATUS_ALL : IStatus[] = [
  {
    value: PROCEDURE_DEPOSIT.value,
    status: STATUS_DEPOSIT
  },
  {
    value: PROCEDURE_OPPOSITION.value,
    status: STATUS_OPPOSITION
  },
  {
    value: PROCEDURE_NULLITY.value,
    status: STATUS_ND
  },
  {
    value: PROCEDURE_REVOCATION.value,
    status: STATUS_ND
  },
  {
    value: PROCEDURE_DIVISION.value,
    status: STATUS_DIVISION
  },
  {
    value: PROCEDURE_MIFR.value,
    status: STATUS_MIFR
  },
  {
    value: PROCEDURE_INSCRIPTION.value,
    status: STATUS_INSCRIPTION
  },
  {
    value: PROCEDURE_FRMI.value,
    status: STATUS_FRMI
  },
  {
    value: PROCEDURE_OFFICIAL_DOCUMENT.value,
    status: STATUS_OFFICIAL_DOCUMENT
  },
  {
    value: PROCEDURE_REVOCATION_STATEMENT.value,
    status: STATUS_REVOCATION_STATEMENT
  },
  {
    value: PROCEDURE_RENEWAL.value,
    status: STATUS_RENEWAL
  },
  {
    value: PROCEDURE_RENUNCIATION.value,
    status: STATUS_INSCRIPTION
  }
]

export const STATUS_ALL_RAW = [
  ...Object.values(STATUS_DEPOSIT),
  ...Object.values(STATUS_ND),
  ...Object.values(STATUS_OPPOSITION),
  ...Object.values(STATUS_DIVISION),
  ...Object.values(STATUS_MIFR),
  ...Object.values(STATUS_INSCRIPTION),
  ...Object.values(STATUS_FRMI),
  ...Object.values(STATUS_OFFICIAL_DOCUMENT),
  ...Object.values(STATUS_REVOCATION_STATEMENT),
  ...Object.values(STATUS_RENEWAL)
]

export const PRODUCT_CLASS_VERSION_STATUS = {
  ACCEPTED: 'ACCEPTED',
  REFUSED: 'REFUSED',
  PENDING: 'PENDING',
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  DRAFT_FO: 'DRAFT_FO',
  DRAFT_BO: 'DRAFT_BO'
}

export const DEPOSIT_TYPE_NATIONAL: DepositType = {
  value: 'DEPOSIT_TYPE_NATIONAL',
  label: 'deposit_type_national'
}

export const DEPOSIT_TYPE_GUARANTEE: DepositType = {
  value: 'DEPOSIT_TYPE_GUARANTEE',
  label: 'deposit_type_guarantee'
}

export const DEPOSIT_TYPE_COLLECTIVE: DepositType = {
  value: 'DEPOSIT_TYPE_COLLECTIVE',
  label: 'deposit_type_collective'
}

export const DEPOSIT_TYPE_EUROPEAN: DepositType = {
  value: 'DEPOSIT_TYPE_EUROPEAN',
  label: 'deposit_type_european'
}

export const DEPOSIT_TYPE_INTERNATIONAL: DepositType = {
  value: 'DEPOSIT_TYPE_INTERNATIONAL',
  label: 'deposit_type_international'
}

export const DEPOSIT_TYPE_DIVISION: DepositType = {
  value: 'DEPOSIT_TYPE_DIVISION',
  label: 'deposit_type_division'
}

export const DEPOSIT_TYPE_DIVISION_REGISTERED: DepositType = {
  value: 'DEPOSIT_TYPE_DIVISION_REGISTERED',
  label: 'deposit_type_division_registered'
}

export const DEPOSIT_TYPES: DepositType[] = [
  DEPOSIT_TYPE_NATIONAL,
  DEPOSIT_TYPE_GUARANTEE,
  DEPOSIT_TYPE_COLLECTIVE,
  DEPOSIT_TYPE_EUROPEAN,
  DEPOSIT_TYPE_INTERNATIONAL,
  DEPOSIT_TYPE_DIVISION,
  DEPOSIT_TYPE_DIVISION_REGISTERED
]

export const LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION = {
  value: 'PREVIOUS_REGISTRATION',
  label: Message.linked_transaction_type_previous_registration
}

export const LINKED_TRANSACTION_TYPE_SIMULTANEOUS_REGISTRATION = {
  value: 'SIMULTANEOUS_REGISTRATION',
  label: Message.linked_transaction_type_simultaneous_registration
}

export const LINKED_TRANSACTION_TYPE_REVIEWING_REGISTRATION = {
  value: 'REVIEWING_REGISTRATION',
  label: Message.linked_transaction_type_reviewing_registration
}

export const LINKED_TRANSACTION_TYPE_RENEWAL = {
  value: 'RENEWAL',
  label: Message.linked_transaction_type_renewal
}

export const LINKED_TRANSACTION_TYPES = [
  LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION,
  LINKED_TRANSACTION_TYPE_SIMULTANEOUS_REGISTRATION,
  LINKED_TRANSACTION_TYPE_REVIEWING_REGISTRATION,
  LINKED_TRANSACTION_TYPE_RENEWAL
]
