import store from '../../../../../store/store'
import {
  containsErrors, CONTRIBUTOR_OPPONENTS,
  ContributorValidator,
  isFilled
} from '@inpi-marques/components'
import Message from '../../../../../constants/Message'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'

class OpponentsValidator {
  validateOnChangeStepOpponents = () => {
    const transaction = store.getState().transaction
    let fieldStatus = {}
    if (!isFilled(transaction.opponents)) {
      fieldStatus = {
        opponents: Message.contributor_opponent_required
      }
    } else if (transaction.opponents?.length) {
      fieldStatus = ContributorValidator.validateContributor(transaction.opponents[0], transaction, CONTRIBUTOR_OPPONENTS.value)
    }
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new OpponentsValidator()
