import { BrandTypeExample } from 'constants/BrandConstants'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface BrandTypeExamplesProps {
  examples: BrandTypeExample[]
}
const BrandTypeExamples: FC<BrandTypeExamplesProps> = ({ examples }) =>
  <div className='brand-type-examples col-9'>
    {examples.map((example: BrandTypeExample, index: number) =>
      <div key={index} className='brand-type-examples__item'>
        <div className='brand-type-examples__item-title text-primary mb-3'><FormattedMessage id='brand_type_example_text' /> {index + 1}</div>

        {example.type === 'image' && <img className='brand-type-examples__image mb-4' src={example.src} alt={example.alternativeValue} />}
        {example.type === 'video' && <video width='100%' className='mb-4' poster={example.alternativeValue} controls><source src={example.src} type='video/mp4' /></video>}
        {example.type === 'audio' && <audio className='mb-4' controls><source src={example.src} type='audio/mpeg' /></audio>}
      </div>
    )}
  </div>

export default BrandTypeExamples
