import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { DateUtils, InternationalExtension } from '@inpi-marques/components'

interface OverviewInternationalExtensionProps {
  internationalExtensions?: InternationalExtension[]
}

const OverviewInternationalExtension : FC<OverviewInternationalExtensionProps> = (
  {
    internationalExtensions
  }) => {
  return (
    <>
      <h4 className='font-weight-bold mb-2'><FormattedMessage id='overview_deposit_international_extension_title' /></h4>
      {internationalExtensions && internationalExtensions.filter(internationalExtension => internationalExtension.numNat !== undefined && internationalExtension.arrivalDate !== undefined).length > 0
        ? internationalExtensions.filter(internationalExtension => internationalExtension.numNat !== undefined && internationalExtension.arrivalDate !== undefined).map((internationalExtension: InternationalExtension, key: number) =>
          <p key={key}><span className='font-weight-bold'><FormattedMessage id='overview_inscription_international_extension_number' /></span> : {internationalExtension.numNat} - <span className='font-weight-bold'><FormattedMessage id='overview_inscription_international_extension_date' /></span> : {DateUtils.formatDateFr(internationalExtension.arrivalDate)}</p>
        )
        : <div><FormattedMessage id='none_feminine' /></div>}
    </>
  )
}

export default OverviewInternationalExtension
