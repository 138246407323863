import Message from './Message'

export const BLOCKED_OPPO_FILTER = [
  {
    label: Message.search_blocked_oppo_oppo,
    value: '1'
  },
  {
    label: Message.search_blocked_oppo_exam,
    value: '0'
  }
]
