import { COUNT_DEPOSIT_RELOAD, COUNT_DEPOSIT_RESET, COUNT_DEPOSIT_UPDATE, CountDepositAction } from './countDepositTypes'
import { TransactionCountByStatus } from '@inpi-marques/components'

interface CountDeposit {
  depositCounts?: TransactionCountByStatus[]
}

const initialCountDepositState = {
  depositCounts: [],
  reload: true
}

export function reducerCountDepositState (state = initialCountDepositState, action: CountDepositAction): CountDeposit {
  switch (action.type) {
    case COUNT_DEPOSIT_UPDATE:
      return { ...state, depositCounts: [...state.depositCounts, ...action.depositCounts] || [] }
    case COUNT_DEPOSIT_RESET:
      return { ...state, depositCounts: [] }
    case COUNT_DEPOSIT_RELOAD:
      return { ...state, reload: action.reload }
    default:
      return state
  }
}
