import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, ReactNode, useContext } from 'react'
import { Card, Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import Foundations from '../Foundations'
import { ErrorField, FieldStatus, PROCEDURE_NULLITY, PROCEDURE_REVOCATION, Transaction, TransactionDocument } from '@inpi-marques/components'
import MotivesAccordion from './MotivesAccordion'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'

interface AccordionCustomHeader {
    children: ReactNode,
    eventKey: string
  }

const ContextAwareToggle: FC<AccordionCustomHeader> = ({ children, eventKey }) => {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(eventKey)

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div
      onClick={decoratedOnClick}
      className='accordion-header text-primary'
    >
      {children}
      <FontAwesomeIcon className='accordion-header__icon h-100' icon={isCurrentEventKey ? faMinus : faPlus} />
    </div>
  )
}

interface MotivesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    setDocuments: (documents: TransactionDocument[]) => void,
    setIsRequired: (value: boolean) => void
}

const Motives: FC<MotivesProps> = ({ transaction, fieldStatus, setDocuments, setIsRequired }) =>
  <div className='is-validated'>
    <div className='row mb-4 justify-content-between'>
      <header className='col-8'>
        <h1><FormattedMessage id='motives_title' /></h1>
        <span className='subtitle'>
          <FormattedMessage id={`motives_${transaction.procedureType?.toLowerCase()}_form_subtitle`} />
        </span>
      </header>
      <InternalReferenceField
        transaction={transaction}
        className='col-3'
      />
    </div>
    {transaction.procedureType === PROCEDURE_NULLITY.value &&
      <Accordion className='shadow'>
        <Card>
          <Card.Header className='cursor-pointer'>
            <ContextAwareToggle eventKey='0'>
              <FormattedMessage id='motives_relative_expand_title' />
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className='p-4'>
              <Foundations setTransactionDocuments={setDocuments} transaction={transaction} fieldStatus={fieldStatus} hideTitle setIsRequired={setIsRequired} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>}

    <Accordion className='mt-4 shadow' defaultActiveKey={transaction.procedureType === PROCEDURE_REVOCATION.value ? '1' : ''}>
      <Card>
        <Card.Header className='cursor-pointer'>
          <ContextAwareToggle eventKey='1'>
            <FormattedMessage id={`motives_${transaction.procedureType?.toLowerCase()}_expand_title`} />
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey='1'>
          <Card.Body className='p-4'>
            <MotivesAccordion transaction={transaction} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
    <ErrorField className='mt-4' message={fieldStatus && fieldStatus.motives} />
  </div>

export default Motives
