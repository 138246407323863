import {
  DateUtils,
  DATE_ISO8601,
  Foundation,
  FoundationDocument,
  FoundationEntity,
  FoundationOpponent,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_FRMI,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_UEMI,
  ProductClassVersion,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  Record,
  TransactionDocument,
  PROCEDURE_NULLITY, PROCEDURE_REVOCATION
} from '../../index'
import ProductsService from '../Product/ProductsService'
import RecordService from '../Record/RecordService'

class FoundationService {
  /**
     * Check si une marque est d'origine internationale
     * @param value
     * @returns
     */
     isInternationalBrand = (value: string): boolean =>
       value === OPPOSITION_FOUNDATION_BRAND_ORIGIN_FRMI.value || value === OPPOSITION_FOUNDATION_BRAND_ORIGIN_UEMI.value

     /**
     * Met à jour l'entité d'un fondement
     * @param foundation
      * @param key
     * @param value
     * @returns
     */
    getUpdatedFoundationByEntity = (foundation: Foundation, key: string, value: string|boolean) : Foundation => {
      const entity: FoundationEntity = foundation.entity ?? {}
      return ({ ...foundation, entity: { ...entity, [key]: value } })
    }

    /**
     * Récupère tous les documents d'un fondement via leur type
     * @param foundationDocuments
     * @param type
     * @returns
     */
     getDocumentsByType = (foundationDocuments: FoundationDocument[] = [], type: string): FoundationDocument[] =>
       foundationDocuments.filter((document: FoundationDocument) => document.type === type)

     /**
     * Récupère le document de la transaction lié au document du fondement via leur type
     * @param transactionDocuments
     * @param foundationDocuments
     * @param type
     * @returns
     */
     getFoundationTransactionDocumentByType = (transactionDocuments: TransactionDocument[] = [], foundationDocuments: FoundationDocument[] = [], type: string):
         TransactionDocument[] => transactionDocuments.filter((document: TransactionDocument) =>
       this.getDocumentsByType(foundationDocuments, type).find((file) => file.name === document.internalName ||
             DateUtils.formateDateToFormat(file.createdAt, DATE_ISO8601) === DateUtils.formateDateToFormat(document.createdAt, DATE_ISO8601)))

     /**
     * Met à jour l'opposant d'un fondement
     * @param foundation
     * @param key
     * @param value
     * @returns
     */
    getUpdatedFoundationByOpponent = (foundation: Foundation, key: string, value: string|boolean) : Foundation => {
      const opponent: FoundationOpponent = foundation.opponent ?? {}
      return ({ ...foundation, opponent: { ...opponent, [key]: value } })
    }

    /**
     * Met à jour la marque d'un fondement avec les informations récupérées de la base publique
     * @param foundation
     * @param record
     * @param procedureType
     * @returns
     */
     getUpdatedFoundationByRecord = (foundation: Foundation, record: Record, procedureType?: string): Foundation => {
       const currentVersion: ProductClassVersion | undefined = record.details?.productsAndServicesVersions &&
           ProductsService.getCurrentVersion(record.details?.productsAndServicesVersions)

       const currentEntity: FoundationEntity = foundation.entity ?? {}

       const productsAndServicesVersions: ProductClassVersion[] = currentVersion?.productClasses
         ? [ProductsService.createProductsAndServicesVersion(
           PRODUCT_AND_SERVICE_VERSION_TYPE.OPPOSITION_INITIAL_VERSION,
           PRODUCT_CLASS_VERSION_STATUS.ACCEPTED,
           currentVersion.productClasses
         )]
         : []

       return {
         ...foundation,
         productsAndServicesVersions: procedureType && [PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value]?.includes(procedureType) ? undefined : productsAndServicesVersions,
         entity: {
           ...currentEntity,
           depositDate: this.isInternationalBrand(foundation.entity?.origin) ? undefined : record.depositDate,
           name: record.details?.brand?.text,
           bopiNumber: procedureType && [PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value]?.includes(procedureType) ? undefined : RecordService.getContestedRequestPublication(record)?.numBopi,
           type: record.details?.brand?.type
         }
       }
     }
}

export default new FoundationService()
