import React, { FC } from 'react'
import {
  CardBlock
} from '@inpi-marques/components'

interface HelpBlockProps {
  className?: string,
  image?: string
}

const HelpBlock: FC<HelpBlockProps> = ({ children, className = '', image = '/img/picto-help.png' }) => {
  return (
    <CardBlock className={`${className} help-container`} bodyClassName='p-4 px-5'>
      <img src={image} className='help-container-image' />
      {children}
    </CardBlock>
  )
}

export default HelpBlock
