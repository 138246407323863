import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ErrorField,
  EventType,
  FieldStatus,
  ModalComponent,
  PROCEDURE_DIVISION,
  ProductAndService,
  ProductClass,
  ProductClassVersion,
  SelectField,
  SelectOption,
  SubmitButton,
  Transaction
} from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ProductsService from 'services/deposit/ProductsService'
import ProductsText from './ProductsText'
import { range } from 'lodash'

interface DivisionProductAndServiceListProps {
    transaction: Transaction,
    parentVersion: ProductClassVersion,
    childVersion: ProductClassVersion,
    originalVersion: ProductClassVersion,
    setChildVersion: (version: ProductClassVersion) => void,
    setParentVersion: (version: ProductClassVersion) => void,
    setOriginalVersion: (version: ProductClassVersion) => void
}

const DivisionProductAndServiceList: FC<DivisionProductAndServiceListProps> = ({
  transaction,
  parentVersion,
  childVersion,
  originalVersion,
  setChildVersion,
  setParentVersion,
  setOriginalVersion
}) => {
  const intl = useIntl()

  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const [addChildClass, setAddChildClass] = useState<boolean>(false)
  const [addParentClass, setAddParentClass] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [editedProductClass, setEditedProductClass] = useState<ProductClass>()
  const [typeProductClass, setTypeProductClass] = useState<string>('')

  /**
   * A la suppression d'une classe
   * @param type
   */
  const onClassDeleted = (type?: 'parent'|'child'): void => {
    if (type === 'child') {
      const updatedChildVersion: ProductClassVersion = {
        ...childVersion,
        productClasses: childVersion.productClasses.filter((productClass: ProductClass) =>
          productClass.ref !== editedProductClass?.ref)
      }
      const updatedParentVersion: ProductClassVersion | null = ProductsService.updateParentVersionFromEditedClass(updatedChildVersion, originalVersion, true)
      setChildVersion(updatedChildVersion)
      updatedParentVersion && setParentVersion(updatedParentVersion)
    } else {
      setParentVersion(
        {
          ...parentVersion,
          productClasses: parentVersion.productClasses.filter((productClass: ProductClass) =>
            productClass.ref !== editedProductClass?.ref)
        }
      )
    }
  }

  /**
   * Mets à jour la liste original de produits et service.
   *
   * @param editedProductClass
   * @returns
   */
  const updateOriginalVersionProducts = (editedProductClass: ProductClass): ProductAndService [] => {
    const originalClass: ProductClass|undefined = originalVersion.productClasses.find((productClass: ProductClass) => productClass.ref === editedProductClass.ref)
    const childClass: ProductClass|undefined = childVersion.productClasses.find((productClass: ProductClass) => productClass.ref === editedProductClass.ref)

    const originalProducts: ProductAndService[] = [...editedProductClass.products]

    // Si dans l'original et dans l'enfant, mais pas dans le parent, alors on le garde dans l'original pour la suppression
    originalClass && originalClass.products.forEach((originalProduct: ProductAndService) => {
      // Si pas dans originalProducts mais dans child alors on ajoute
      if (childClass?.products.find((childProduct: ProductAndService) => childProduct.name === originalProduct.name) &&
      !editedProductClass?.products.find((editedProduct: ProductAndService) => editedProduct.name === originalProduct.name)
      ) {
        originalProducts.push(originalProduct)
      }
    })
    return originalProducts
  }

  /**
   * Edition d'une classe de la version mère'
   *
   * @param editedProductClass
   */
  const onOriginalProductsEdit = (editedProductClass: ProductClass): void => {
    setParentVersion({
      ...parentVersion,
      productClasses: parentVersion.productClasses.map((productClass: ProductClass) =>
        productClass.ref === editedProductClass.ref ? editedProductClass : productClass)
    })

    setOriginalVersion({
      ...originalVersion,
      productClasses: originalVersion.productClasses.map((productClass: ProductClass) =>
        productClass.ref === editedProductClass.ref ? {
          ...productClass,
          products: updateOriginalVersionProducts(editedProductClass)
        } : productClass
      )
    })
  }

  /**
   * Edition d'une classe de la version fille
   * @param editedProductClass
   */
  const onProductsEdit = (editedProductClass: ProductClass): void => {
    const updatedChildVersion: ProductClassVersion = {
      ...childVersion,
      productClasses: childVersion.productClasses.map((productClass: ProductClass) =>
        productClass.ref === editedProductClass.ref ? editedProductClass : productClass)
    }

    if (originalVersion.productClasses?.length > 0) {
      const updatedParentVersion: ProductClassVersion | null = ProductsService.updateParentVersionFromEditedClass(updatedChildVersion, parentVersion, false)
      if (updatedParentVersion) {
        setChildVersion(updatedChildVersion)
        setParentVersion(updatedParentVersion)
        setFieldStatus(undefined)
      } else {
        setFieldStatus({ parentVersion: Message.division_parent_version_error })
      }
    } else {
      setChildVersion(updatedChildVersion)
      setFieldStatus(undefined)
    }
  }

  /**
   * Ajout d'une classe via le select
   * @param event
   * @param type
   */
  const onProductClassRefSelected = (event: EventType, type: 'parent'|'child'): void => {
    const value = event.target.value
    if (type === 'child') {
      setChildVersion({
        ...childVersion,
        productClasses: [...childVersion.productClasses, { ref: value, products: [] }]
      })
      setAddChildClass(false)
    } else {
      setParentVersion({
        ...parentVersion,
        productClasses: [...parentVersion.productClasses, { ref: value, products: [] }]
      })
      setAddParentClass(false)
    }
  }

  /**
   * Récupère les options des selects permettant l'ajout d'une classe
   *
   * @param type
   * @returns
   */
  const getSelectFieldOptions = (type: 'parent' | 'child'): SelectOption[] => {
    const version: ProductClassVersion = type === 'parent' ? parentVersion : childVersion
    return [...range(1, 46)].filter((number) => !version.productClasses.find((productClass: ProductClass) => parseInt(productClass.ref, 10) === number))
      .map((number) => ({
        label: `${Message.products_and_services_class} ${number}`,
        value: number
      }))
  }

  const handleOnClick = (productClass: ProductClass, type: string) => {
    setShowModal(true)
    setEditedProductClass(productClass)
    setTypeProductClass(type)
  }

  return (
    <div className='division__products-and-services row mt-4'>
      {/* CLASSES DE LA MARQUES MERE */}
      <div className='col-6 p-2'>
        <div className='row m-0 mb-4'>
          <h4 className='h4 m-0 text-uppercase font-weight-bold'>
            <FormattedMessage id='division_parent_product_version_title' />
          </h4>
          {
            (transaction.procedureType === PROCEDURE_DIVISION.value || !parentVersion.productClasses?.length) &&
              <SubmitButton className='btn-link-primary ml-3' onClick={() => setAddParentClass(true)}>
                <FormattedMessage id='division_add_product_class_button_label' />
              </SubmitButton>
          }
        </div>
        {addParentClass &&
          <div className='row m-0'>
            <SelectField
              inputId='parentsProductClassRef'
              options={getSelectFieldOptions('parent')}
              onChange={(event: EventType) => onProductClassRefSelected(event, 'parent')}
              placeholder={intl.formatMessage({ id: 'products_and_services_select_class' })}
            />
          </div>}
        {parentVersion.productClasses.map((productClass: ProductClass, index: number) =>
          <div className='product-class row flex-column m-0 mb-3' key={index}>
            <div className='product-class__name text-uppercase text-primary font-weight-bold'>
              <FormattedMessage id='products_and_services_class' /> {productClass.ref}
            </div>
            <ProductsText
              productClass={productClass}
              index={index}
              onProductsEdit={onOriginalProductsEdit}
            />
            <SubmitButton
              onClick={() => handleOnClick(productClass, 'parent')}
              className='product-class__delete-button btn-icon absolute text-danger'
            >
              <FontAwesomeIcon icon={faTrash} />
            </SubmitButton>
          </div>
        )}
      </div>
      {/* CLASSES DE LA MARQUES FILLE */}
      <div className='col-6 p-2'>
        <div className='row m-0 mb-4'>
          <h4 className='h4 m-0 text-uppercase font-weight-bold'>
            <FormattedMessage id='division_product_version_title' />
          </h4>
          {
            (transaction.procedureType === PROCEDURE_DIVISION.value || !parentVersion.productClasses?.length) &&
              <SubmitButton className='btn-link-primary ml-3' onClick={() => setAddChildClass(true)}>
                <FormattedMessage id='division_add_product_class_button_label' />
              </SubmitButton>
          }
        </div>
        {addChildClass &&
          <div className='row m-0'>
            <SelectField
              inputId='productClassRef'
              options={getSelectFieldOptions('child')}
              onChange={(event: EventType) => onProductClassRefSelected(event, 'child')}
              placeholder={intl.formatMessage({ id: 'products_and_services_select_class' })}
            />
          </div>}
        {childVersion.productClasses.map((productClass: ProductClass, index: number) =>
          <div className='product-class row flex-column m-0 mb-3' key={index}>
            <div className='product-class__name text-uppercase text-primary font-weight-bold'>
              <FormattedMessage id='products_and_services_class' /> {productClass.ref}
            </div>
            <ProductsText
              productClass={productClass}
              index={index}
              onProductsEdit={onProductsEdit}
            />
            <SubmitButton
              onClick={() => handleOnClick(productClass, 'child')}
              className='product-class__delete-button btn-icon absolute text-danger'
            >
              <FontAwesomeIcon icon={faTrash} />
            </SubmitButton>
          </div>
        )}
      </div>
      {fieldStatus && (<div className='col-12'> <ErrorField message={fieldStatus.parentVersion} className='fade alert alert-danger show position-relative mt-4' /></div>)}
      {
        showModal &&
          <ModalComponent
            title={<FormattedMessage id='popin_product_and_services_title' />}
            show={showModal}
            customContent={() => <FormattedMessage id='popin_product_and_services_detail' />}
            handleClose={() => setShowModal(false)}
            onClick={() => { onClassDeleted(typeProductClass); setShowModal(false) }}
          />
      }
    </div>)
}

export default DivisionProductAndServiceList
