import React, { FC } from 'react'
import { Priority, SubmitButton } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import OverviewPrioritiesFRMI from './OverviewPrioritiesFRMI'

interface PriorityFRMIListFormProps {
    priorityList: Priority[],
    editPriority: (index: number) => void,
    deletePriority: (index: number) => void,
    addPriority: () => void,
}

const PriorityFRMIListForm: FC<PriorityFRMIListFormProps> = (
  {
    priorityList,
    deletePriority,
    editPriority,
    addPriority
  }) => {
  return (
    <>
      <div>
        <SubmitButton
          className='btn-select btn-outline-primary btn-add no-text-uppercase'
          onClick={() => addPriority()}
        >
          <FormattedMessage id='options_add_priority' />
          <FontAwesomeIcon className='ml-2' icon={faPlus} />
        </SubmitButton>
      </div>
      {priorityList && priorityList.length > 0 && (
        <OverviewPrioritiesFRMI
          priorities={priorityList}
          deletePriority={deletePriority}
          editPriority={editPriority}
          className='mt-5'
        />
      )}
    </>
  )
}

export default PriorityFRMIListForm
