import { DOCUMENT_FORMATS, DOCUMENT_TYPES, EventType, FileBrowserField, FileButton, FoundationService, OppositionFoundationTypeComponent, PROCEDURE_OPPOSITION, TextArea, TextField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT DE TYPE INDICATION GEOGRAPHIQUE (au sens de l'article L. 722-1 a et c)
 */
const FoundationAoigForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentDeleted, onDocumentAdded, documents, procedureType, setFieldStatus, disableFiles, addToRefs, disableActiveField, handleDownload }) => (
  <IntlProvider locale='fr' messages={Message}>
    <div className='row'>
      {/* NOM */}
      <TextField
        addToRefs={addToRefs}
        inputId='entity_name'
        label={<FormattedMessage id='foundation_aoig_name_label' />}
        classNameFormGroup='col-4'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'name', event.target.value))}
        value={foundation.entity?.name}
        required
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      {/* TYPE */}
      <TextField
        addToRefs={addToRefs}
        inputId='entity_type'
        label={<FormattedMessage id='foundation_aoig_type_label' />}
        classNameFormGroup='col-4'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'type', event.target.value))}
        value={foundation.entity?.type}
        required
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      {/* ACTES A LA PROTECTION */}
      <TextArea
        classNameFormGroup='col-4'
        inputId='protectingAct'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'protectingAct', event.target.value))}
        value={foundation.entity?.protectingAct}
        required
        label={<FormattedMessage id='foundation_aoig_protecting_act_label' />}
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      {/* Document(s) justifiant de l’existence et de la portée du droit antérieur */}
      {!disableFiles &&
        <FileBrowserField
          className='col-4'
          inputId='rightFile'
          classNameButton='d-contents'
          buttonLabel={<FileButton />}
          label={<FormattedMessage id='foundation_right_file_label' />}
          acceptApplication={DOCUMENT_FORMATS.PDF}
          onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_AOIG_EXISTENCE)}
          value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_AOIG_EXISTENCE)}
          onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
          required={procedureType !== PROCEDURE_OPPOSITION.value}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
          informationDoc
          handleDownload={handleDownload}
        />}
      {/* QUALITE DE L'OPPOSANT */}
      <TextField
        classNameFormGroup='col-4'
        inputId='opponent-label'
        label={<FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_opponent_quality_label`} />}
        value={foundation.opponent?.quality}
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByOpponent(foundation, 'quality', event.target.value))}
      />
      {/* Document justifiant que l’opposant est autorisé à exercer les droits découlant du droit antérieur invoqué */}
      {!disableFiles &&
        <FileBrowserField
          className='col-4'
          inputId='opponentRightsFile'
          buttonLabel={<FileButton />}
          classNameButton='d-contents'
          label={<FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_opponent_right_file_label`} />}
          acceptApplication={DOCUMENT_FORMATS.PDF}
          onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LEGITIMACY : DOCUMENT_TYPES.FOUNDATION_OPPONENT_LEGITIMACY)}
          value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LEGITIMACY : DOCUMENT_TYPES.FOUNDATION_OPPONENT_LEGITIMACY)}
          onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
          maxNumberFile={1}
          required={procedureType !== PROCEDURE_OPPOSITION.value}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
          informationDoc
          placementTooltip='bottom'
          handleDownload={handleDownload}
        />}
      {/* Indiquez quel est le produit bénéficiant de l’AO ou de l’IG invoqué à l’appui de l’opposition */}
      {procedureType === PROCEDURE_OPPOSITION.value &&
        <TextArea
          classNameFormGroup='col-4'
          inputId='activity'
          onChange={(event: EventType) => setFoundation({ ...foundation, activity: event.target.value })}
          value={foundation.activity}
          required
          label={<FormattedMessage id='foundation_form_activity_label_aoig' />}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
        />}
    </div>
    {/* FONDEMENT ACTIF */}
    <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
  </IntlProvider>
)

export default FoundationAoigForm
