import React, { FC, useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import './colorPick.scss'
import variables from '../../styles/variables.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette'
import Message from '../../constants/Message'
import { ControlledTabs } from '../../index'

interface ColorPicProps {
    expanded: boolean,
    onExpandEvent: () => void,
    onChange: (subject: string, value: string) => void,
    currentState: Object
}

/**
 * Sélecteur de couleur de police avancé
 * @param expanded si le picker est ouvert
 * @param onExpandEvent lorsque l'on clique sur l'icone
 * @param onChange changement soit surlignage soit couleur texte
 * @param currentState contient les valeurs de couleur de la sélection
 * @constructor
 */
const ColorPic: FC<ColorPicProps> = ({
  expanded,
  onExpandEvent,
  onChange,
  currentState
}) => {
  const [colors, setColors] = useState<{ text: Object | undefined, highlight: Object | undefined }>({
    text: undefined,
    highlight: undefined
  })
  const tab = useRef(0)

  const onPickerColorChange = (color: Object) => {
    setColors({ ...colors, [tab.current !== 0 ? 'highlight' : 'text']: { ...color } })
  }

  const stopPropagation = (event: MouseEvent) => event.stopPropagation()

  const pickerComponent = (colorToUse: string) => (
    <SketchPicker
      color={colors?.[colorToUse] ?? {}}
      onChangeComplete={onPickerColorChange}
      presetColors={[
        '#2196f3',
        '#4caf50',
        '#f18f00',
        '#e51c23',
        variables.RgbPrimary,
        variables.RgbSecondary,
        variables.RgbBlack,
        variables.RgbInpi,
        variables.brown,
        variables.gray_very_dark,
        variables.gray_middle,
        variables.gray_light,
        variables.off_white
      ]}
      onChange={onPickerColorChange}
    />
  )

  useEffect(() => {
    setColors({
      text: undefined,
      highlight: undefined
    })

    if (currentState?.color || currentState?.bgColor) {
      setColors({
        text: currentState.color,
        highlight: currentState.bgColor
      })
    }
    tab.current = 0
  }, [currentState])

  return (
    <div
      className='color-wrapper'
      aria-haspopup='true'
      aria-expanded={expanded}
      aria-label='rdw-color-picker'
    >
      <div
        onClick={onExpandEvent}
        className='icon-wrapper'
      >
        <FontAwesomeIcon icon={faPalette} />
      </div>
      {expanded ? (
        <div
          className='color-modal'
          onClick={stopPropagation}
        >
          <ControlledTabs
            className='bg-white'
            tabClassName='rounded-0'
            id='color-picker'
            setActiveKey={(key) => {
              tab.current = parseInt(key)
            }}
            defaultActiveKey={tab.current}
            tabs={[
              {
                label: Message.color_picker_text_tab,
                component: () => pickerComponent('text')
              },
              {
                label: Message.color_picker_highlight_tab,
                component: () => pickerComponent('highlight')
              }
            ]}
          />
          <button
            className='btn btn-primary h-50 w-100'
            onClick={() => onChange(tab.current !== 0 ? 'bgcolor' : 'color', colors?.[tab.current !== 0 ? 'highlight' : 'text'].hex)}
          >
            {Message.color_picker_save}
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
export default ColorPic
