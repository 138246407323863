import { FieldStatus } from '@inpi-marques/components'
import { FieldStatusAction, FIELD_STATUS_RESET, FIELD_STATUS_UPDATE } from './fieldStatusTypes'

/**
 * @param fieldStatus Une partie des fieldStatus de FieldStatus
 */
export const storeTransactionFieldStatusUpdate = (fieldStatus: FieldStatus): FieldStatusAction => ({
  type: FIELD_STATUS_UPDATE,
  fieldStatus
})

export const storeTransactionFieldStatusReset = () => ({
  type: FIELD_STATUS_RESET
})
