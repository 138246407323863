import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateUtils, DOCUMENT_FORMATS, DOCUMENT_LIMITS, DOCUMENT_STATUS, downloadFile, EventType, FileBrowserField, FileButton, INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTIONS, SelectField, SubmitButton, TextField, Transaction, TransactionDocument } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import DocumentService from 'services/document/DocumentService'

interface DocumentsBlockItemProps {
    document: TransactionDocument,
    useDescription?: boolean,
    key?: number,
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentDeleted?: (document: TransactionDocument)=> void,
    onDocumentEdited: (document: TransactionDocument) => void,
    documentType: string,
    transaction?: Transaction
}

const DocumentsBlockItem: FC<DocumentsBlockItemProps> = ({ document, useDescription = false, key, onDocumentAdded, onDocumentDeleted, onDocumentEdited, documentType, transaction }) => {
  const [stateDocument, setStateDocument] = useState<TransactionDocument>(document)

  useEffect(() => {
    setStateDocument(document)
  }, [document])

  const onDocumentUploaded = (event: EventType): void => {
    const { value } = event.target

    onDocumentAdded({
      type: documentType,
      internalName: '',
      name: value.name,
      format: value.type,
      file: value,
      description: stateDocument.description,
      status: DOCUMENT_STATUS.NEW,
      communicability: stateDocument.communicability,
      originEmail: '',
      createdAt: DateUtils.now()
    })
  }

  const handleDownload = (document:TransactionDocument) => {
    transaction?.id && document.internalName && DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  return (
    <div className='row m-0 align-items-center my-3'>
      {useDescription &&
        <TextField
          inputId={`document_${documentType.toLocaleLowerCase()}_${key}_description`}
          classNameFormGroup='col-4 m-0'
          value={stateDocument.description}
          onChange={(event: EventType) => setStateDocument({ ...stateDocument, description: event.target.value })}
          onBlur={(event: EventType) => stateDocument.internalName || stateDocument.file ? onDocumentEdited({ ...document, description: event.target.value }) : setStateDocument({ ...document, description: event.target.value })}
        />}
      <FileBrowserField
        inputId={`document_${documentType.toLocaleLowerCase()}_${key}_file`}
        className='col-3 m-0'
        buttonLabel={<FileButton />}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
        onChange={onDocumentUploaded}
        maxNumberFile={1}
        value={stateDocument.internalName || stateDocument.file ? [stateDocument] : undefined}
        handleDownload={handleDownload}
        classNameButton='text-primary'
      />
      <SelectField
        inputId={`document_${documentType.toLocaleLowerCase()}_${key}_type`}
        classNameFormGroup='col-4 m-0'
        options={INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTIONS}
        value={stateDocument.communicability}
        onChange={(event: EventType) => stateDocument.internalName || stateDocument.file ? onDocumentEdited({ ...document, communicability: event.target.value }) : setStateDocument({ ...stateDocument, communicability: event.target.value })}
      />
      {onDocumentDeleted &&
        <SubmitButton
          className='btn-icon text-secondary'
          onClick={() => onDocumentDeleted(document)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </SubmitButton>}
    </div>
  )
}

export default DocumentsBlockItem
