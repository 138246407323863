import {
  DateField,
  TransactionDocument,
  DOCUMENT_TYPES,
  EventType,
  FieldStatus,
  FileBrowserField,
  FileButton,
  TextField,
  DateUtils,
  DATE_ISO,
  DOCUMENT_LIMITS,
  downloadFile
} from '@inpi-marques/components'
import { DOCUMENT_FORMATS } from '@inpi-marques/components/src/constants/DocumentConstants'
import { DepositTypeContentProps } from 'interfaces/Deposit'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import { storeTransactionUpdateDeposit } from '../../../../../store/transaction/transactionActions'
import TransactionService from '../../../../../services/transaction/TransactionService'
import DocumentService from '../../../../../services/document/DocumentService'

const InternationalContent: FC<DepositTypeContentProps> = ({
  deposit,
  transaction,
  fieldStatus,
  onDocumentChanged,
  onDocumentDeleted,
  documents
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [internationalNumber, setInternationalNumber] = useState<string>(deposit.numNatToTransformOrDivide ?? '')

  const onInternationalNumberChanged = (event: EventType) => {
    const { value } = event.target
    setInternationalNumber(value)
  }

  const onInternationalNumberBlur = () => {
    dispatch(storeTransactionUpdateDeposit({ numNatToTransformOrDivide: internationalNumber }))
  }

  const onDepositDateChanged = (event: EventType) => {
    const { value } = event.target
    dispatch(storeTransactionUpdateDeposit({ createdAtToTransformOrDivide: DateUtils.formatToBeginOfDay(value) }))
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  const internationalDepositFile: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.COPY_INTERNATIONAL_REGISTRATION)
  const internationalDepositTranslationFile: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.TRANSLATION_INTERNATIONAL_REGISTRATION)
  const internationalCancellationCertificate: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.CERTIFICATE_CANCELLATION)

  return (
    <div className='international-content d-flex flex-wrap'>
      <div className='international-number__wrapper col-6 p-0'>
        <TextField
          inputId='international-number'
          classNameFormGroup=''
          className='col-6 p-0'
          required
          value={internationalNumber}
          label={<FormattedMessage id='deposit_type_international_number_label' />}
          placeholder={intl.formatMessage({ id: 'form_number_placeholder' })}
          onChange={onInternationalNumberChanged}
          onBlur={onInternationalNumberBlur}
          fieldStatus={fieldStatus}
          nameFieldStatus='internationalNumber'
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
        />
      </div>
      <div className='international-deposit-date__wrapper col-6 p-0'>
        <DateField
          inputId='international-deposit-date'
          required
          className='col-6 p-0'
          value={DateUtils.formateDateToFormat(deposit.createdAtToTransformOrDivide, DATE_ISO) ?? ''}
          label={<FormattedMessage id='deposit_type_international_date_label' />}
          placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
          onChange={onDepositDateChanged}
          fieldStatus={fieldStatus as FieldStatus}
          nameFieldStatus='internationalDepositDate'
          classNameLabel='col-12 p-0'
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
          maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) || undefined}
        />
      </div>

      <FileBrowserField
        inputId='international-deposit-file'
        className='col-6 p-0'
        ulClassName='col-8'
        classNameButton='d-block one-line-ellipsis'
        label={<FormattedMessage id='deposit_type_international_deposit_file' />}
        buttonLabel={<FileButton />}
        onChange={(event: EventType) => onDocumentChanged && onDocumentChanged(event, DOCUMENT_TYPES.COPY_INTERNATIONAL_REGISTRATION)}
        onDelete={onDocumentDeleted}
        maxNumberFile={1}
        maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
        value={internationalDepositFile && [internationalDepositFile]}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        required
        fieldStatus={fieldStatus}
        nameFieldStatus='internationalDepositFile'
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
        informationDoc
        handleDownload={handleDownload}
      />

      <FileBrowserField
        inputId='international-deposit-translation-file'
        className='col-6 p-0'
        ulClassName='col-8'
        classNameButton='d-block one-line-ellipsis'
        label={<FormattedMessage id='deposit_type_international_deposit_tranlastion_file' />}
        buttonLabel={<FileButton />}
        onChange={(event: EventType) => onDocumentChanged && onDocumentChanged(event, DOCUMENT_TYPES.TRANSLATION_INTERNATIONAL_REGISTRATION)}
        onDelete={onDocumentDeleted}
        maxNumberFile={1}
        value={internationalDepositTranslationFile && [internationalDepositTranslationFile]}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
        required
        fieldStatus={fieldStatus}
        nameFieldStatus='internationalDepositTranslationFile'
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
        informationDoc
        handleDownload={handleDownload}
      />
      <FileBrowserField
        inputId='international-cancellation-certificate'
        className='col-6 p-0'
        ulClassName='col-8'
        classNameButton='d-block one-line-ellipsis'
        label={<FormattedMessage id='deposit_type_international_cancellation_certificate' />}
        buttonLabel={<FileButton />}
        onChange={(event: EventType) => onDocumentChanged && onDocumentChanged(event, DOCUMENT_TYPES.CERTIFICATE_CANCELLATION)}
        onDelete={onDocumentDeleted}
        maxNumberFile={1}
        value={internationalCancellationCertificate && [internationalCancellationCertificate]}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
        required
        fieldStatus={fieldStatus}
        nameFieldStatus='internationalCancellationCertificate'
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
        informationDoc
        handleDownload={handleDownload}
      />
    </div>)
}

export default InternationalContent
