import {
  BRAND_ORIGIN_REVOCATION_STATEMENT_OPTIONS,
  BRAND_ORIGINS_SELECT_OPTIONS,
  BRAND_TYPES_SELECT_OPTIONS,
  DATE_ISO,
  DateField,
  DateUtils,
  EventType,
  FieldStatus,
  FRMI_TYPE_EXTENSION,
  HelpBlock,
  ORIGIN_UNKNOWN,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_REVOCATION_STATEMENT,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  PRODUCT_STATUS,
  ProductAndService,
  ProductAndServiceListView,
  ProductClass,
  ProductClassVersion,
  ProductService as CommonProductsService,
  ProductService,
  SelectField,
  TextField,
  Title,
  Transaction,
  TransactionService
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../store/fieldStatus/fieldStatusActions'
import Message from '../../../../constants/Message'
import { BRAND_TYPE_FIGURATIVE, BRAND_TYPE_SEMI_FIGURATIVE, BRAND_TYPE_WORD } from '../../../../constants/BrandConstants'
import TitleFilePreview from './TitleFilePreview'

interface TitleFormProps {
    fieldStatus?: FieldStatus,
    // titre actuel à modifier
    title?: Title,
    // fonction pour modifier le titre
    setTitle: (newTitle: Title) => void,
    isEditingMode: boolean,
    showProductsAndServices?: boolean,
    procedureType?: string,
    recordId?: string,
    transaction?: Transaction,
    showBrandLabel?: boolean
}

const TitleForm: FC<TitleFormProps> = ({
  fieldStatus,
  title,
  setTitle,
  isEditingMode,
  showProductsAndServices = false,
  procedureType,
  recordId,
  transaction,
  showBrandLabel
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [brandType, setBrandType] = useState<string | undefined>(title?.type)
  const brandTypeHasText: boolean = [BRAND_TYPE_WORD.value, BRAND_TYPE_FIGURATIVE.value, BRAND_TYPE_SEMI_FIGURATIVE.value].includes(brandType ?? '')

  useEffect(() => {
    setBrandType(title?.type)
  }, [title])

  const onTitleSelectTypeChanged = (event: EventType) => {
    const { value } = event.target
    setBrandType(value)
    setTitle({ ...title, unmodified: false, text: !brandTypeHasText ? title?.text : '', type: value, from: (title?.from || ORIGIN_UNKNOWN) })
  }

  const onTitleValueChanged = (event: EventType) => {
    let { name, value } = event.target
    if (name.includes('Date')) {
      value = DateUtils.formatToBeginOfDay(value)
    }
    let updatedTitle: Title = { ...title, [name]: value }
    // On met à jour le millésime du titre s'il n'est pas rattaché à un Record ou si le titre n'en a pas
    if (name === 'depositDate' && (!title?.from || (title?.from && !title?.millesime)) && DateUtils.isAfter(value, '1999-12-31') && title?.origin !== 'WO') {
      updatedTitle = { ...updatedTitle, millesime: DateUtils.formatMillesimeYear(value) ?? undefined }
    }
    if (title?.origin === 'WO') {
      updatedTitle = { ...updatedTitle, millesime: undefined }
    }
    setTitle({ ...updatedTitle, unmodified: false })
  }

  const onProductClassesChanged = (products: ProductClass[]) => {
    setTitle({
      ...title,
      unmodified: false,
      productsAndServicesVersions: [ProductService.createProductsAndServicesVersion(PRODUCT_AND_SERVICE_VERSION_TYPE.RENUNCIATION_TYPE, PRODUCT_CLASS_VERSION_STATUS.ACCEPTED, products)]
    })
  }

  /**
   * Suppression d'une classe de produits
   * On met à jour les produits qui existaient dans la version originale
   * et on supprime les produits ajoutés récemment (avec statut ADDED)
   * @param deletedProductClass
   */
  const onProductClassDeleted = (deletedProductClass: ProductClass): void => {
    currentVersion?.productClasses && onProductClassesChanged(CommonProductsService.editProductsOnProductClassDeleted(currentVersion?.productClasses, deletedProductClass))
  }

  /**
   * Suppression d'un produit de la classe
   * Si le produit a un statut ADDED, alors on le supprime complètement de la liste des produits
   * @param editedProductClass
   * @param deletedProduct
   */
  const onProductDelete = (editedProductClass: ProductClass, deletedProduct: ProductAndService): void => {
    currentVersion?.productClasses && onProductClassesChanged(currentVersion?.productClasses?.map((productClass: ProductClass) => editedProductClass.ref === productClass.ref
      ? {
        ...productClass,
        products: productClass.products
          .filter((product: ProductAndService) => !(product.name === deletedProduct.name && product.status === PRODUCT_STATUS.ADDED))
          .map((product: ProductAndService) => product.name === deletedProduct.name
            ? { ...product, status: PRODUCT_STATUS.DELETED } : product
          )
      }
      : productClass)
    )
  }

  /**
   * À l'édition de la liste de produits d'une classe
   * @param editedProductClass
   * @param stringProducts
   */
  const onProductClassesEdited = async (editedProductClass: ProductClass, stringProducts: string): Promise<void> => {
    // On compare avec la version originale, car des produits déjà existants qui ont potentiellement été supprimés peuvent être ajoutés.
    const originalClass: ProductClass|undefined = currentVersion?.productClasses.find((productClass: ProductClass) => editedProductClass.ref === productClass.ref)

    if (originalClass) {
      const updatedProductClass: ProductClass = CommonProductsService.updateProductClassFromProducts(originalClass, stringProducts)
      currentVersion?.productClasses && onProductClassesChanged(currentVersion?.productClasses?.map((productClass: ProductClass) => updatedProductClass.ref === productClass.ref ? updatedProductClass : productClass))
    }
  }

  const currentVersion: ProductClassVersion|undefined = ProductService.getCurrentVersion(title?.productsAndServicesVersions)
  return (
    <>
      {!isEditingMode && (
        <div>
          <HelpBlock className='mb-2 mt-2 col-12 p-0'>
            <FormattedMessage id={`deposit_type_${transaction && TransactionService.isFrmiOP(transaction) ? 'frmi' : 'division'}_not_found`} />
          </HelpBlock>
        </div>)}
      {title && <TitleFilePreview title={title} transaction={transaction} recordId={recordId} />}
      <div className='row d-flex mt-2 p-0'>
        {(procedureType === PROCEDURE_INSCRIPTION.value || procedureType === PROCEDURE_REVOCATION_STATEMENT.value) &&
          <div className='col-12 col-lg-4'>
            <SelectField
              inputId='origin'
              required
              label={<FormattedMessage id='deposit_type_inscription_origin' />}
              placeholder={Message.deposit_type_inscription_origin}
              value={title?.origin}
              onChange={onTitleValueChanged}
              fieldStatus={fieldStatus}
              nameFieldStatus='titleOrigin'
              classNameLabel='col-12 p-0'
              dispatch={dispatch}
              resetError={storeTransactionFieldStatusUpdate}
              options={procedureType === PROCEDURE_INSCRIPTION.value ? BRAND_ORIGINS_SELECT_OPTIONS : BRAND_ORIGIN_REVOCATION_STATEMENT_OPTIONS}
            />
          </div>}
        <div className={`col-12 col-lg-4 ${procedureType === PROCEDURE_INSCRIPTION.value ? 'mr-2' : ''}`}>
          <DateField
            inputId='depositDate'
            required
            value={DateUtils.formateDateToFormat(title?.depositDate, DATE_ISO) ?? ''}
            label={<FormattedMessage id={`deposit_type_${procedureType?.toLowerCase()}_deposit_date`} />}
            placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
            onChange={onTitleValueChanged}
            fieldStatus={fieldStatus}
            nameFieldStatus='titleDepositDate'
            classNameLabel='col-12 p-0'
            dispatch={dispatch}
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
        {
          procedureType === PROCEDURE_FRMI.value && transaction?.subProcedureType === FRMI_TYPE_EXTENSION.value &&
            <div className='col-12 col-lg-4 mr-2'>
              <DateField
                inputId='registeredDate'
                value={DateUtils.formateDateToFormat(title?.registeredDate, DATE_ISO) ?? ''}
                label={<FormattedMessage id='deposit_type_frmi_registered_date' />}
                placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
                onChange={onTitleValueChanged}
                fieldStatus={fieldStatus}
                nameFieldStatus='titleRegisteredDate'
                classNameLabel='col-12 p-0'
                dispatch={dispatch}
                resetError={storeTransactionFieldStatusUpdate}
              />
            </div>
        }
        {
          procedureType === PROCEDURE_FRMI.value && transaction?.subProcedureType !== FRMI_TYPE_EXTENSION.value &&
            <div className='col-12 col-lg-4 mr-2'>
              <DateField
                inputId='expirationDate'
                value={DateUtils.formateDateToFormat(title?.expirationDate, DATE_ISO) ?? ''}
                label={<FormattedMessage id='deposit_type_frmi_expiration_date' />}
                placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
                onChange={onTitleValueChanged}
                fieldStatus={fieldStatus}
                nameFieldStatus='titleExpirationDate'
                classNameLabel='col-12 p-0'
                dispatch={dispatch}
                resetError={storeTransactionFieldStatusUpdate}
              />
            </div>
        }
        <div className='col-12 col-lg-4'>
          <SelectField
            inputId='type'
            required
            label={<FormattedMessage id='deposit_type_division_registered_type' />}
            placeholder={Message.brand_type_title_isListing}
            value={title?.type}
            onChange={onTitleSelectTypeChanged}
            fieldStatus={fieldStatus}
            nameFieldStatus='titleBrandType'
            classNameLabel='col-12 p-0'
            dispatch={dispatch}
            resetError={storeTransactionFieldStatusUpdate}
            options={BRAND_TYPES_SELECT_OPTIONS}
          />
        </div>
        {brandType && brandTypeHasText &&
          <div className='col-12 col-lg-6'>
            <TextField
              inputId='text'
              required={brandType === BRAND_TYPE_WORD.value}
              value={title?.text}
              label={<FormattedMessage id={showBrandLabel ? 'inscription_form_title_label' : 'deposit_type_inscription_registered_marque'} />}
              onChange={onTitleValueChanged}
              fieldStatus={fieldStatus}
              nameFieldStatus='titleBrandText'
              classNameLabel='col-12 p-0'
              dispatch={dispatch}
              resetError={storeTransactionFieldStatusUpdate}
              maxLength={500}
            />
          </div>}
        {showProductsAndServices && currentVersion &&
          <div className='col-12 col-lg-12 mr-2'>
            <ProductAndServiceListView
              productClasses={currentVersion.productClasses}
              onProductClassDelete={onProductClassDeleted}
              onProductDelete={onProductDelete}
              onProductClassEdited={onProductClassesEdited}
            />
          </div>}
      </div>
    </>
  )
}

export default TitleForm
