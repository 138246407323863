import { DateUtils, InscriptionCorrection, InscriptionNatureCode, NATURE_CODES } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface CorrectionOverviewProps {
    correction: InscriptionCorrection,
    natureCodes: InscriptionNatureCode[]
}

const CorrectionOverview: FC<CorrectionOverviewProps> = ({ correction, natureCodes }) =>

  <div className='row'>
    <div className='col-6'>
      <h4 className='font-weight-bold'><FormattedMessage id='overview_nature_codes_label' /></h4>
      <p className='subtitle'>{natureCodes.map((natureCode: InscriptionNatureCode) => natureCode.freeLabel || natureCode.label).join(', ')}</p>
    </div>
    <div className='col-6'>
      <h4 className='font-weight-bold'><FormattedMessage id='overview_statement_label' /></h4>
      {correction.replacedText &&
        <pre className='subtitle mb-0'><span className='font-weight-bold'><FormattedMessage id='overview_correction_replaced_text_label' /> : </span><br />{correction.replacedText}</pre>}
      {correction.replacementText &&
        <pre className='subtitle mb-0'><span className='font-weight-bold'><FormattedMessage id='overview_correction_replacement_text_label' /> : </span><br />{correction.replacementText}</pre>}
      {correction.comment &&
        <pre className='subtitle mb-0'><span className='font-weight-bold'><FormattedMessage id='overview_correction_comment_label' /> : </span><br />{correction.comment}</pre>}
    </div>
    {natureCodes.some((natureCode: InscriptionNatureCode) => natureCode.code === NATURE_CODES.EM) &&
      <div className='col-12'>
        <h4 className='font-weight-bold'><FormattedMessage id='correction_rectify_block_title' /></h4>
        <ul>
          {correction.areDepositFiles &&
            <li className='mt-2'>
              <FormattedMessage id='correction_rectify_part_is_deposit_label' />
            </li>}
          {correction.areActFiles &&
            <li className='mt-2'>
              <FormattedMessage id='correction_rectify_part_is_act_label' />
              {correction.actInscriptionNumber &&
                <p className='pl-2 my-1'><FormattedMessage id='correction_rectify_part_act_inscription_number_label' /> : {correction.actInscriptionNumber}</p>}
              {correction.actInscriptionDate &&
                <p className='pl-2 my-1'><FormattedMessage id='correction_rectify_part_act_inscription_date_label' /> : {DateUtils.formatDateFr(correction.actInscriptionDate)}</p>}
            </li>}
          {correction.areRenewalFiles &&
            <li className='mt-2'>
              <FormattedMessage id='correction_rectify_part_is_renewal_label' />
              {correction.renewalNumber &&
                <p className='pl-2 my-1'><FormattedMessage id='correction_rectify_part_act_renewal_inscription_number_label' /> : {correction.renewalNumber}</p>}
              {correction.renewalDate &&
                <p className='pl-2 my-1'><FormattedMessage id='correction_rectify_part_act_renewal_inscription_date_label' /> : {DateUtils.formatDateFr(correction.renewalDate)}</p>}
            </li>}
          {correction.areDivisionFiles &&
            <li className='mt-2'>
              <FormattedMessage id='correction_rectify_part_is_division_label' />
              {correction.divisionNumber &&
                <p className='pl-2 my-1'><FormattedMessage id='correction_rectify_part_division_number_label' /> : {correction.divisionNumber}</p>}
              {correction.divisionDate &&
                <p className='pl-2 my-1'><FormattedMessage id='correction_rectify_part_division_date_label' /> : {DateUtils.formatDateFr(correction.divisionDate)}</p>}
            </li>}
        </ul>
      </div>}

  </div>

export default CorrectionOverview
