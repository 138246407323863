import { CardBlock, CheckboxField, DateField, DateUtils, DATE_ISO, ErrorField, EventType, FieldStatus, InscriptionCorrection, TextField } from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface RectifyBlockProps {
    correction: InscriptionCorrection,
    setCorrection: (correction: InscriptionCorrection) => void,
    fieldStatus: FieldStatus
}

const RectifyBlock: FC<RectifyBlockProps> = ({ correction, setCorrection, fieldStatus }) => {
  const [inscriptionNumber, setInscriptionNumber] = useState<string>(correction.actInscriptionNumber ?? '')
  const [renewalNumber, setRenewalNumber] = useState<string>(correction.renewalNumber ?? '')
  const [divisionNumber, setDivisionNumber] = useState<string>(correction.divisionNumber ?? '')

  const [actInscriptionDate, setActInscriptionDate] = useState<string>(correction.actInscriptionDate ?? '')
  const [renewalDate, setRenewalDate] = useState<string>(correction.renewalDate ?? '')
  const [divisionDate, setDivisionDate] = useState<string>(correction.divisionDate ?? '')

  useEffect(() => {
    setInscriptionNumber(correction.actInscriptionNumber ?? '')
    setRenewalNumber(correction.renewalNumber ?? '')
    setDivisionNumber(correction.divisionNumber ?? '')
    setActInscriptionDate(correction.actInscriptionDate ?? '')
    setRenewalDate(correction.renewalDate ?? '')
    setDivisionDate(correction.divisionDate ?? '')
  }, [correction])

  /**
   * Au clic sur la checkbox
   * @param event
   */
  const onCheckboxChanged = (event: EventType): void => {
    const { name, value } = event.target
    let updatedCorrection: InscriptionCorrection = { ...correction, [name]: value }

    /**
     * On clean les champs correspondant aux checkbox
     */
    switch (name) {
      case 'areActFiles':
        updatedCorrection = { ...updatedCorrection, actInscriptionNumber: undefined, actInscriptionDate: undefined }
        break
      case 'areRenewalFiles':
        updatedCorrection = { ...updatedCorrection, renewalNumber: undefined, renewalDate: undefined }
        break
      case 'areDivisionFiles':
        updatedCorrection = { ...updatedCorrection, divisionNumber: undefined, divisionDate: undefined }
        break
    }

    setCorrection(updatedCorrection)
  }

  return (
    <CardBlock header={<FormattedMessage id='correction_rectify_block_title' />} shadow>
      <FormattedMessage id='correction_rectify_block_label' />

      <div className='row flex-column mt-3 pl-4'>
        <div>
          <CheckboxField
            inputId='areDepositFiles'
            label={<FormattedMessage id='correction_rectify_part_is_deposit_label' />}
            checked={correction.areDepositFiles}
            value={correction.areDepositFiles}
            onChange={onCheckboxChanged}
          />
        </div>
        <div>
          <CheckboxField
            inputId='areActFiles'
            label={<FormattedMessage id='correction_rectify_part_is_act_label' />}
            checked={correction.areActFiles}
            value={correction.areActFiles}
            onChange={onCheckboxChanged}
          />
          {correction.areActFiles &&
            <div className='row'>
              <TextField
                inputId='actInscriptionNumber'
                classNameFormGroup='col-4'
                label={<FormattedMessage id='correction_rectify_part_act_inscription_number_label' />}
                placeholder={Message.correction_rectify_part_act_inscription_number_placeholder}
                value={inscriptionNumber}
                onChange={(event: EventType) => setInscriptionNumber(event.target.value)}
                onBlur={(event: EventType) => setCorrection({ ...correction, actInscriptionNumber: event.target.value })}
                required
                fieldStatus={fieldStatus}
              />
              <DateField
                inputId='actInscriptionDate'
                classNameFormGroup='col-4'
                value={DateUtils.formateDateToFormat(actInscriptionDate, DATE_ISO) ?? ''}
                label={<FormattedMessage id='correction_rectify_part_act_inscription_date_label' />}
                onChange={(event: EventType) => setActInscriptionDate(DateUtils.formatToBeginOfDay(event.target.value) ?? '')}
                onBlur={() => setCorrection({ ...correction, actInscriptionDate })}
              />
            </div>}
        </div>
        <div>
          <CheckboxField
            inputId='areRenewalFiles'
            label={<FormattedMessage id='correction_rectify_part_is_renewal_label' />}
            checked={correction.areRenewalFiles}
            value={correction.areRenewalFiles}
            onChange={onCheckboxChanged}
          />
          {correction.areRenewalFiles &&
            <div className='row'>
              <TextField
                inputId='renewalNumber'
                classNameFormGroup='col-4'
                label={<FormattedMessage id='correction_rectify_part_renewal_number_label' />}
                placeholder=''
                value={renewalNumber}
                onChange={(event: EventType) => setRenewalNumber(event.target.value)}
                onBlur={(event: EventType) => setCorrection({ ...correction, renewalNumber: event.target.value })}
                required
                fieldStatus={fieldStatus}
              />
              <DateField
                inputId='renewalDate'
                classNameFormGroup='col-4'
                value={DateUtils.formateDateToFormat(renewalDate, DATE_ISO) ?? ''}
                label={<FormattedMessage id='correction_rectify_part_renewal_date_label' />}
                onChange={(event: EventType) => setRenewalDate(DateUtils.formatToBeginOfDay(event.target.value) ?? '')}
                onBlur={() => setCorrection({ ...correction, renewalDate })}
              />
            </div>}
        </div>
        <div>
          <CheckboxField
            inputId='areDivisionFiles'
            label={<FormattedMessage id='correction_rectify_part_is_division_label' />}
            checked={correction.areDivisionFiles}
            value={correction.areDivisionFiles}
            onChange={onCheckboxChanged}
          />
          {correction.areDivisionFiles &&
            <div className='row'>
              <TextField
                inputId='divisionNumber'
                classNameFormGroup='col-4'
                label={<FormattedMessage id='correction_rectify_part_division_number_label' />}
                placeholder={Message.correction_rectify_part_division_number_placeholder}
                value={divisionNumber}
                onChange={(event: EventType) => setDivisionNumber(event.target.value)}
                onBlur={(event: EventType) => setCorrection({ ...correction, divisionNumber: event.target.value })}
                required
                fieldStatus={fieldStatus}
              />
              <DateField
                inputId='divisionDate'
                classNameFormGroup='col-4'
                value={DateUtils.formateDateToFormat(divisionDate, DATE_ISO) ?? ''}
                label={<FormattedMessage id='correction_rectify_part_division_date_label' />}
                onChange={(event: EventType) => setDivisionDate(DateUtils.formatToBeginOfDay(event.target.value) ?? '')}
                onBlur={() => setCorrection({ ...correction, divisionDate })}
              />
            </div>}
        </div>
      </div>
      <div>
        {fieldStatus.rectifyContent && <ErrorField message={fieldStatus.rectifyContent} />}
      </div>
    </CardBlock>)
}

export default RectifyBlock
