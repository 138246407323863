import { NatureCode } from '@inpi-marques/components'

class NatureCodeService {
  /**
   * Permet de trier les codes nature par leur position pour l'affichage en front
   * @param codes
   * @returns
   */
  sortNatureCodes = (codes: NatureCode[]): NatureCode[][] => {
    /** On récupère toutes les positions */
    const positions: number[] = []
    codes.forEach((code: NatureCode) => {
      if (code.position !== undefined && !positions.find((position: number) => code.position === position)) {
        positions.push(code.position)
      }
    })

    positions.sort((first: number, second: number) => first - second)

    /** Grâce à ces positions, on récupère les codes natures correspondants */
    const sortedNatureCodes: NatureCode[][] = []

    positions.forEach((position: number) => {
      sortedNatureCodes.push(codes.filter((code: NatureCode) => code.position === position))
    })

    /** On récupère les code nature sans position, qui apparaitront à la fin */
    const codesWithoutPosition = codes.filter((code: NatureCode) => code.position === undefined)

    if (codesWithoutPosition.length) {
      sortedNatureCodes.push(codesWithoutPosition)
    }

    return sortedNatureCodes
  }
}

export default new NatureCodeService()
