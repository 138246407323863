import React, { FC, useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  containsErrors,
  Contributor,
  CONTRIBUTOR_DEPOSITORS,
  ContributorValidator,
  DateUtils,
  DEPOSIT_CONTRIBUTORS_TYPES,
  DOCUMENT_STATUS,
  DOCUMENT_TYPES,
  EventType,
  FieldStatus,
  FilAriane,
  OFFICIAL_DOCUMENT_CONTRIBUTORS_OPTIONS,
  OFFICIAL_DOCUMENTS_CONTRIBUTORS_TYPES,
  PROCEDURE_OFFICIAL_DOCUMENT,
  Record,
  Title,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import TransactionService from '../../../services/transaction/TransactionService'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from '../../../store/transaction/transactionActions'
import OfficialDocumentsRequestedValidator
  from './officialDocumentsRequested/validator/OfficialDocumentsRequestedValidator'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import Recap from '../../recap/Recap'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import OfficialDocumentsRequested from './officialDocumentsRequested/OfficialDocumentsRequested'
import PaymentForm from '../../payment/PaymentForm'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_OFFICIAL_DOCUMENT } from '../../../constants/PaymentConstants'
import RecordService from '../../../services/opposition/RecordService'
import TitleService from '../../../services/inscription/TitleService'
import qs from 'qs'
import ContributorFrontForm from '../../contributors/ContributorFrontForm'
import ContributorService from '../../../services/contributors/ContributorService'
import CommentsFRMIForm from '../../frmi/form/CommentsFRMIForm'
import ConfigurationService from '../../../services/configuration/ConfigurationService'

/* global localStorage */

interface OfficialDocumentFormProps extends RouteComponentProps {
    isNewTransaction: boolean
}

const OfficialDocumentForm: FC<OfficialDocumentFormProps> = ({
  history,
  location,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()
  const [required, setIsRequired] = useState<boolean>(false)
  const transaction: Transaction = useSelector((state: RootStateOrAny) => state.transaction)
  const fieldStatus: FieldStatus = useSelector((state: RootStateOrAny) => state.transactionFieldStatus)
  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const [defaultTitle, setDefaultTitle] = useState<Title|undefined>()
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const currentETSUser = ContributorService.buildContributorFromUser(user)

  const handleSubmit = () => {
    setIsNew(isNewTransaction)
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  const saveOfficialDocumentType = async () => {
    if (isNew) {
      setIsNew(false)
      return await TransactionService.createTransaction(transaction, PROCEDURE_OFFICIAL_DOCUMENT.value, ['officialDocument'], documents)
    } else {
      return await TransactionService.updateTransactionBDDFromStore(transaction, ['officialDocument'], documents)
    }
  }

  const onDocumentChanged = (event: EventType) => {
    const { value } = event.target
    const newFile: TransactionDocument = {
      type: DOCUMENT_TYPES.OFFICIAL_DOCUMENT_JUSTIFICATION,
      name: value.name,
      format: value.type,
      file: value,
      status: DOCUMENT_STATUS.NEW,
      createdAt: DateUtils.now()
    }
    setDocuments([...documents, newFile])
  }

  const onDocumentDeleted = (documentToDelete?: TransactionDocument|EventType) => {
    if (documentToDelete) {
      const internalName = documentToDelete?.target?.value ? documentToDelete.target.value.internalName : documentToDelete?.internalName
      setDocuments(documents.filter((file: TransactionDocument) => file.internalName !== internalName))
    } else {
      setDocuments([])
    }
  }

  /**
   * Edition de la liste des déposants
   * @returns
   */
  const onDepositorsEdited = async (): Promise<Transaction|null> => {
    // Supprime les déposants non conformes
    let newContributorList : Contributor[] = []
    transaction.depositors && transaction.depositors.forEach((contributor: Contributor) => {
      const fieldStatusContributor = ContributorValidator.validateContributor(contributor, transaction.procedureType, CONTRIBUTOR_DEPOSITORS.value)
      if (!containsErrors(fieldStatusContributor)) {
        newContributorList = [...newContributorList, contributor]
      }
    })

    return await TransactionService.updateTransactionBDDFromStore({ ...transaction, depositors: newContributorList }, [DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value])
  }

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        result.documents && setDocuments(result.documents)
      })
    } else {
      dispatch(storeTransactionInit(PROCEDURE_OFFICIAL_DOCUMENT.value))
    }
  }, [isNewTransaction])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.from) {
      RecordService.getRecord(query.from).then((record: Record) => {
        const title = TitleService.getTitleFromRecord(record, transaction.procedureType)
        if (title) {
          setDefaultTitle(title)
        }
      })
    }

    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_request_official_documents' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='official_documents_selection_title' />}
        textBtnSwitch={<FormattedMessage id='button_guide' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        setStep={location.search.includes('payment') ? 4 : 0}
        views={[
          {
            id: 'deposit_type',
            label: <FormattedMessage id='select_official_documents_type_title' />,
            hasGuidedSwitch: false,
            onGoToStep: saveOfficialDocumentType,
            component: <OfficialDocumentsRequested defaultTitle={defaultTitle} fieldStatus={fieldStatus} transaction={transaction} currentETSUser={currentETSUser} />,
            validateGoToStep: () => OfficialDocumentsRequestedValidator.validateOnChangeStep(documents, TransactionService.getOfficialDocument(transaction))
          },
          {
            id: 'contributors',
            label: <FormattedMessage id='contributor_title' />
          },
          {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_depositors_official_documents' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={OFFICIAL_DOCUMENTS_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                optionForm={OFFICIAL_DOCUMENT_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_OFFICIAL_DOCUMENT.value}
                contributorTypeLabel={`${OFFICIAL_DOCUMENTS_CONTRIBUTORS_TYPES.DEPOSITORS.value}_official_documents`}
                setIsRequired={setIsRequired}
                currentETSUser={currentETSUser}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: () => onDepositorsEdited()
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={OFFICIAL_DOCUMENTS_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  OFFICIAL_DOCUMENTS_CONTRIBUTORS_TYPES.DEPOSITORS.value
                ]}
                optionForm={OFFICIAL_DOCUMENT_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_OFFICIAL_DOCUMENT.value}
                defaultContributor={ContributorService.buildContributorFromUser(user)}
                setIsRequired={setIsRequired}
              />
            ),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [OFFICIAL_DOCUMENTS_CONTRIBUTORS_TYPES.RECIPIENT.value])
          },
          {
            id: 'do_comments',
            label: <FormattedMessage id='frmi_comments_title' />,
            component: (
              <CommentsFRMIForm transaction={transaction} />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['comments'], documents)
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} onDocumentChanged={onDocumentChanged} onDocumentDeleted={onDocumentDeleted} documents={documents} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['officialDocument'], documents)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.INITIAL}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_OFFICIAL_DOCUMENT}
              />
            )
          }
        ]}
      />
    </>
  )
}

export default withRouter(OfficialDocumentForm)
