import { CardBlock, CheckboxField, FieldStatus, Transaction, TransactionDocument, NatureCode, ErrorField, HelpBlock, DOCUMENT_TYPES, InscriptionNatureCode, TextField, EventType } from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import ContentService from 'services/content/ContentService'
import { storeTransactionUpdateInscription } from 'store/transaction/transactionActions'
import DocumentsBlock from '../correction/DocumentsBlock'

interface ActTitleFormProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    documents: TransactionDocument[],
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentEdited: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument)=> void,
}

/**
 * Etape "Acte" pour une inscription d'un acte affectant la propriété ou la joiuissance d'une titre
 */
const ActTitleForm: FC<ActTitleFormProps> = ({ transaction, fieldStatus, documents, onDocumentAdded, onDocumentEdited, onDocumentDeleted }) => {
  const dispatch = useDispatch()

  /** Code natures récupérées du Back */
  const [natureCodes, setNatureCodes] = useState<NatureCode[]>([])

  useEffect(() => {
    ContentService.getNatureCodesByType(transaction.subProcedureType).then((response: NatureCode[]) => {
      setNatureCodes(response)
    })
  }, [])

  const onAppealRevocationChanged = (): void => {
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      isAppealOrRevocation: !transaction.inscription?.isAppealOrRevocation
    }))
  }

  const onRenewalChanged = (): void => {
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      isRenewal: !transaction.inscription?.isRenewal
    }))
  }

  /**
   * A la section du code nature
   * @param natureCode
   */
  const onNatureCodeSelected = (natureCode: NatureCode): void => {
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      natureCodes: natureCode ? [{ code: natureCode.code, label: natureCode.label, psDisplayType: natureCode.psDisplayType }] : []
    }))
  }

  /**
   * A l'edition du label 'free' d'un code nature
   * @param event
   * @param natureCode
   */
  const onFreeLabelChanged = (event: EventType, natureCode: NatureCode): void => {
    const { value } = event.target
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      natureCodes: transaction.inscription?.natureCodes?.map((inscriptionNatureCode: InscriptionNatureCode) =>
        inscriptionNatureCode.code === natureCode.code ? ({
          ...inscriptionNatureCode,
          freeLabel: value
        }) : inscriptionNatureCode
      )
    }))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='act_title' /></h1>
          <span className='subtitle'><FormattedMessage id='act_form_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row'>
        <CardBlock className='col-12' shadow>
          <CheckboxField
            inputId='isAppealOrRevocation'
            className='mt-2'
            checked={transaction.inscription?.isAppealOrRevocation}
            label={<FormattedMessage id='act_title_form_is_appeal_revocation_label' />}
            onChange={onAppealRevocationChanged}
          />
          <CheckboxField
            inputId='isRenewal'
            className='m-0'
            checked={transaction.inscription?.isRenewal}
            label={<FormattedMessage id='act_title_form_is_renewal_label' />}
            onChange={onRenewalChanged}
          />
        </CardBlock>
        {/* NATURE DE LA RECTIFICATION */}
        <CardBlock className='col-12 p-0 mt-3' bodyClassName='flex-wrap flex-row' header={<FormattedMessage id='act_title_nature_code_block_title' />} shadow>
          {
            natureCodes.map((natureCode: NatureCode, index: number) => (
              <div key={index} className='row col-6'>
                <div className='form-check form-check-inline col-12 p-0 mx-0 mb-2'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id={`nature_code_${index}`}
                    checked={transaction.inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => inscriptionNatureCode.code === natureCode.code)}
                    onChange={() => onNatureCodeSelected(natureCode)}
                  />
                  <label className='form-check-label ml-2' htmlFor={`nature_code_${index}`}>{natureCode.label}</label>
                </div>
                {natureCode.displayText && transaction.inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) =>
                  natureCode.code === inscriptionNatureCode.code
                  ) &&
                    <TextField
                      inputId={`nature_code_${index}_label`}
                      value={transaction.inscription?.natureCodes?.find((inscriptionNatureCode: InscriptionNatureCode) => inscriptionNatureCode.code === natureCode.code)?.freeLabel}
                      onChange={(event: EventType) => onFreeLabelChanged(event, natureCode)}
                    />}
              </div>
            ))
          }
          {fieldStatus.natureCodes &&
            <div>
              <ErrorField message={fieldStatus.natureCodes} />
            </div>}
        </CardBlock>
        <CardBlock className='col-12 p-0 mt-4' header={<FormattedMessage id='correction_documents_block_title' />} shadow>
          <HelpBlock>
            <FormattedMessage id='documents_communicability_warning_text' />
          </HelpBlock>

          <DocumentsBlock
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            title={<FormattedMessage id='inscription_act_property_change_files_label' />}
            documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_ACT_PROPERTY_CHANGE) ?? []}
            documentType={DOCUMENT_TYPES.INSCRIPTION_ACT_PROPERTY_CHANGE}
            transaction={transaction}
          />
          <DocumentsBlock
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            title={<FormattedMessage id='inscription_mandatory_power_files_label' />}
            documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER) ?? []}
            documentType={DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER}
            transaction={transaction}
          />
          <DocumentsBlock
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            title={<FormattedMessage id='inscription_act_material_impossibility_files_label' />}
            documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_ACT_MATERIAL_IMPOSSIBILITY) ?? []}
            documentType={DOCUMENT_TYPES.INSCRIPTION_ACT_MATERIAL_IMPOSSIBILITY}
            transaction={transaction}
          />
          <DocumentsBlock
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            title={<FormattedMessage id='inscription_other_files_label' />}
            documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_OTHER) ?? []}
            documentType={DOCUMENT_TYPES.INSCRIPTION_OTHER}
            transaction={transaction}
          />
          {fieldStatus.documents_communicability &&
            <div>
              <ErrorField message={fieldStatus.documents_communicability} />
            </div>}
        </CardBlock>
      </div>
    </>
  )
}

export default ActTitleForm
