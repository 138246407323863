import { ContributorGuided } from '../../interfaces/contributors/ContributorsInterfaces'

export const CONTRIBUTOR_GUIDED_PP: ContributorGuided = {
  question: 'question_guided_pp',
  idBtnSwitch: 'btnPP',
  restrictedQualities: ['DEPOSANT']
}

export const CONTRIBUTOR_GUIDED_PM: ContributorGuided = {
  question: 'question_guided_pm',
  idBtnSwitch: 'btnPM',
  infoText: 'information_guided_pm',
  restrictedQualities: ['REPRESENTANT_PM', 'EMPLOYEE_PM']
}

export const CONTRIBUTOR_GUIDED_ENTITY_FORMATION: ContributorGuided = {
  question: 'question_guided_entity_formation',
  idBtnSwitch: 'btnEntityFormation',
  infoText: 'information_guided_entity_formation',
  restrictedQualities: ['DEPOSANT', 'REPRESENTANT_PM', 'EMPLOYEE_PM']
}

export const CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS: ContributorGuided = {
  question: 'question_guided_various_accounts',
  idBtnSwitch: 'btnVariousAccounts',
  restrictedQualities: ['CO_DEPOSANT']
}

export const CONTRIBUTOR_GUIDE: ContributorGuided[] = [
  CONTRIBUTOR_GUIDED_PP,
  CONTRIBUTOR_GUIDED_PM,
  CONTRIBUTOR_GUIDED_ENTITY_FORMATION,
  CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS
]
