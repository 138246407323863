import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface SimultaneousInscriptionOverviewProps {
    hasSimultaneousInscription?: boolean
}

/**
 * Récapitulatif des inscriptions simultanées
 */
const SimultaneousInscriptionOverview: FC<SimultaneousInscriptionOverviewProps> = ({ hasSimultaneousInscription }) =>

  <>
    <h4 className='font-weight-bold mb-2'>
      <FormattedMessage id='overview_renewal_simultaneous_inscription_title' />
    </h4>
    <div><FormattedMessage id={`overview_renewal_simultaneous_inscription_${hasSimultaneousInscription ? 'yes' : 'no'}`} /></div>
  </>

export default SimultaneousInscriptionOverview
