import React, { FC, useEffect, useState } from 'react'
import {
  Transaction,
  TransactionDocument,
  DropArea,
  DOCUMENT_TYPES
} from '@inpi-marques/components'
import { DOCUMENT_LIMITS, DOCUMENT_FORMATS } from '@inpi-marques/components/src/constants/DocumentConstants'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import DocumentList from '../../../list/DocumentList'

interface OppositionAnnexesProps {
  transaction: Transaction,
  documents: TransactionDocument[],
  // Identique à (document: TransactionDocument), mais grâce à lui, on peut récupérer la liste de documents existant
  setDocuments: React.Dispatch<React.SetStateAction<TransactionDocument[]>>
}

const OppositionAnnexes: FC<OppositionAnnexesProps> = ({
  transaction,
  documents,
  setDocuments
}) => {
  const [documentsAnnexe, setDocumentsAnnexe] = useState<TransactionDocument[]>()

  // Met à jour la liste des documents annexes
  useEffect(() => {
    setDocumentsAnnexe(documents.filter((doc) => doc.type === DOCUMENT_TYPES.OTHER))
  }, [documents])

  /**
   * On ajoute le document
   * @param file
   */
  const onDocumentDropped = (file: File[]) => {
    const newDocuments: TransactionDocument[] = file.map((file) =>
      ({
        name: file.name,
        size: file.size,
        type: DOCUMENT_TYPES.OTHER,
        format: DOCUMENT_FORMATS.PDF,
        file
      })

    )
    setDocuments((existingDocuments: TransactionDocument[]) => [...existingDocuments, ...newDocuments])
  }

  return (
    <>
      <div className='row mb-4 justify-content-between form-header'>
        <header className='col-8 mb-4'>
          <h1>
            <FormattedMessage id='annexes_title' />
          </h1>
          <span className='subtitle'>
            <FormattedMessage id='annexes_description' />
          </span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <DropArea
        accept={`${DOCUMENT_FORMATS.PDF},${DOCUMENT_FORMATS.JPEG},${DOCUMENT_FORMATS.MP4},${DOCUMENT_FORMATS.MP3}`}
        maxSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
        text='document_add_drop'
        onDrop={(files: File[]) => onDocumentDropped(files)}
        nameFieldStatus='additionalDocument'
      />
      <DocumentList
        documents={documentsAnnexe}
        transaction={transaction}
        setDocuments={setDocuments}
        showDeleteButton
      />
    </>
  )
}
export default OppositionAnnexes
