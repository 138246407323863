import React, { FC } from 'react'
import {
  SelectField,
  TextField,
  EventType,
  SelectOption,
  PROCEDURES
} from '@inpi-marques/components'
import Message from '../../constants/Message'

type TransactionListTableHeaderProps = {
  valueSearch?: string,
  onSearch?: (event: EventType) => void,
  valueSelectProcedure?: string,
  onSelectProcedure: (event: EventType) => void,
  optionsProcedure?: SelectOption[],
  valueSelectSubProcedureType?: string,
  onSelectSubProcedureType: (event?: EventType | undefined) => void,
  optionsSubType?: SelectOption[]
};

const TransactionListTableHeader: FC<TransactionListTableHeaderProps> = ({
  valueSearch,
  onSearch,
  valueSelectProcedure,
  onSelectProcedure,
  optionsProcedure = PROCEDURES,
  valueSelectSubProcedureType,
  onSelectSubProcedureType,
  optionsSubType = []
}) => {
  return (
    <div className='row'>
      {
        onSearch &&
          <div className='col-md-5 col-12'>
            <TextField
              inputId='recherche'
              placeholder={Message.placeholder_search}
              onChange={onSearch}
              value={valueSearch}
            />
          </div>
      }
      {optionsProcedure.length > 0 &&
        <div className='col-md-3 col-12'>
          <SelectField
            inputId='select'
            options={optionsProcedure}
            placeholder={Message.procedure_type_label}
            onChange={onSelectProcedure}
            value={valueSelectProcedure}
            readOnly={optionsProcedure.length === 1}
          />
        </div>}
      {optionsSubType.length > 0 &&
        <div className='col-md-3 col-12'>
          <SelectField
            inputId='select'
            options={optionsSubType}
            placeholder={Message.transaction_type}
            onChange={onSelectSubProcedureType}
            value={valueSelectSubProcedureType}
            readOnly={!valueSelectProcedure || optionsSubType.length === 0}
          />
        </div>}
    </div>
  )
}

export default TransactionListTableHeader
