import { faTrash, faPencilAlt, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { containsErrors, DateField, DateUtils, DATE_ISO, EventType, FieldStatus, ReplacementTitle, SubmitButton, TextArea, TextField } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import ReplacementTitlesValidator from './validator/ReplacementTitlesValidator'

interface ReplacementTitleRowProps {
    title: ReplacementTitle,
    onTitleChanged?: (title: ReplacementTitle) => void,
    onTitleRemoved?: () => void
}

const ReplacementTitleRow: FC<ReplacementTitleRowProps> = ({ title, onTitleChanged, onTitleRemoved }) => {
  const [stateTitle, setStateTitle] = useState<ReplacementTitle>(title)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()

  useEffect(() => {
    setStateTitle(title)
  }, [title])

  const onValidate = (): void => {
    const validationFiedStatus: FieldStatus = ReplacementTitlesValidator.validateReplacementTitle(stateTitle)

    if (containsErrors(validationFiedStatus)) {
      setFieldStatus(validationFiedStatus)
    } else {
      setFieldStatus(undefined)
      onTitleChanged && onTitleChanged({ ...stateTitle, selected: false, isNew: false })
    }
  }

  return (
    <tr>
      {!stateTitle.selected &&
        <>
          <td>{stateTitle.depositNumber}</td>
          <td>{DateUtils.formatDateFr(stateTitle.depositDate)}</td>
          <td>{stateTitle.registeredNumber}</td>
          <td>{DateUtils.formatDateFr(stateTitle.registeredDate)}</td>
          <td>{DateUtils.formatDateFr(stateTitle.priorityDate)}</td>
          <td>{stateTitle.otherRightText}</td>
          {
            onTitleRemoved && onTitleChanged &&
              <td>
                <SubmitButton className='cursor-pointer btn-icon' onClick={() => onTitleChanged && onTitleChanged({ ...title, selected: true })}>
                  <FontAwesomeIcon icon={faPencilAlt} className='text-primary' />
                </SubmitButton>
                <SubmitButton className='cursor-pointer btn-icon btn-delete' onClick={onTitleRemoved}>
                  <FontAwesomeIcon icon={faTrash} className='text-secondary' />
                </SubmitButton>
              </td>
          }
        </>}
      {stateTitle.selected &&
        <>
          <td>
            <TextField
              inputId='depositNumber'
              onChange={(event: EventType) => setStateTitle({ ...stateTitle, depositNumber: event.target.value })}
              value={stateTitle.depositNumber}
              fieldStatus={fieldStatus}
            />
          </td>
          <td>
            <DateField
              inputId='depositDate'
              required
              value={DateUtils.formateDateToFormat(stateTitle?.depositDate, DATE_ISO) ?? ''}
              onChange={(event: EventType) => setStateTitle({ ...stateTitle, depositDate: DateUtils.formatToBeginOfDay(event.target.value) })}
              maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) ?? undefined}
              fieldStatus={fieldStatus}
            />
          </td>
          <td>
            <TextField
              inputId='registeredNumber'
              onChange={(event: EventType) => setStateTitle({ ...stateTitle, registeredNumber: event.target.value })}
              value={stateTitle.registeredNumber}
              fieldStatus={fieldStatus}
            />
          </td>
          <td>
            <DateField
              inputId='registeredDate'
              required
              value={DateUtils.formateDateToFormat(stateTitle?.registeredDate, DATE_ISO) ?? ''}
              onChange={(event: EventType) => setStateTitle({ ...stateTitle, registeredDate: DateUtils.formatToBeginOfDay(event.target.value) })}
              maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) ?? undefined}
              fieldStatus={fieldStatus}
            />
          </td>

          <td>
            <DateField
              inputId='priorityDate'
              required
              value={DateUtils.formateDateToFormat(stateTitle?.priorityDate, DATE_ISO) ?? ''}
              onChange={(event: EventType) => setStateTitle({ ...stateTitle, priorityDate: DateUtils.formatToBeginOfDay(event.target.value) })}
              maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) ?? undefined}
            />
          </td>
          <td>
            <TextArea
              inputId='otherRightText'
              required
              value={stateTitle.otherRightText}
              onChange={(event: EventType) => setStateTitle({ ...stateTitle, otherRightText: event.target.value })}
              fieldStatus={fieldStatus}
            />
          </td>
          <td>
            <SubmitButton className='cursor-pointer btn-icon' onClick={onValidate}>
              <FontAwesomeIcon icon={faCheck} className='text-primary' />
            </SubmitButton>

            {
              onTitleRemoved && onTitleChanged &&
                <SubmitButton
                  className='cursor-pointer btn-icon btn-delete'
                  onClick={() => title.isNew ? onTitleRemoved() : onTitleChanged({ ...title, selected: false })}
                >
                  <FontAwesomeIcon icon={faTimes} className='text-secondary' />
                </SubmitButton>
            }
          </td>
        </>}
    </tr>

  )
}

export default ReplacementTitleRow
