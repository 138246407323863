import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import FormatUtils from '../utils/FormatUtils'
import { DOCUMENT_FORMATS, TransactionDocument } from '../../src'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../src/constants/Message'
import PreviewLoader from './PreviewLoader'

/* global Image */

interface PreviewProps {
  file: File|(() => Promise<string|null>)|string,
  className?: string,
  onClick?: () => void,
  refreshAction?: boolean,
  onTrashClick?: () => void,
  document: TransactionDocument
  withControls?: boolean,
  isThumbnail?: boolean,
  isPrintFormat?: boolean,
  imageClassName?: string,
  withInformation?: boolean
}

const Preview: FC<PreviewProps> = ({
  file,
  className,
  onClick,
  refreshAction,
  onTrashClick,
  document,
  withControls = true,
  isThumbnail = false,
  isPrintFormat = false,
  imageClassName,
  withInformation = true
}) => {
  const [preview, setPreview] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [printDimensions, setPrintDimensions] = useState({})

  const initPreview = (image: string) => {
    setPreview(image)
    setLoading(false)
  }

  useEffect(() => {
    let unmounted = false
    // Création d'une preview de l'image
    setLoading(true)
    setError(false)

    // Si file est une fonction, on fetch l'image
    if (typeof file === 'function') {
      file().then((r) => {
        if (!unmounted) {
          if (r) {
            initPreview(r)
          } else {
            setLoading(false)
            setError(true)
          }
        }
      }
      ).catch(() => {
        if (!unmounted) {
          setLoading(false)
          setError(true)
        }
      })

      // Si file est un string, on part du principe que soit c'est une URL
      // soit une image en base 64
    } else if (typeof file === 'string') {
      if (!unmounted) {
        initPreview(file)
      }
      // Sinon on considère que c'est un fichier provenant de l'utilisateur
    } else if (!file.preview) {
      try {
        if (!unmounted) {
          initPreview(URL.createObjectURL(file))
        }
      } catch (error) {}
    } else {
      if (!unmounted) {
        initPreview(file.preview)
      }
    }

    // Suppression de la preview lors de la destruction du composant
    return () => {
      URL.revokeObjectURL(preview)
      unmounted = true
    }
  }, [typeof file, typeof file === 'object' && file.name, document.name, typeof file === 'string' && file, refreshAction])

  useEffect(() => {
    if (isPrintFormat) {
      const img = new Image()
      img.src = preview

      img.onload = () => {
        setPrintDimensions({
          width: img.width,
          height: img.height
        })
      }
    }
  }, [preview])

  const imgSize: number|undefined = document.size
  const format: string|undefined = FormatUtils.formatFileFormat(document.name)

  return loading ? (
    <PreviewLoader
      width={isThumbnail ? 114 : undefined}
      height={isThumbnail ? 114 : undefined}
    />
  ) : (
    error
      ? <FontAwesomeIcon icon={faTimesCircle} className='text-danger my-auto' />
      : (
        <IntlProvider locale='fr' messages={Message}>
          <div className={`preview-wrapper ${isThumbnail ? 'preview-wrapper-thumbnail' : ''} ${isPrintFormat ? 'preview-wrapper-print' : ''} ${className || ''}`}>
            {onTrashClick && <FontAwesomeIcon icon={faTrash} className='text-secondary cursor-pointer' onClick={onTrashClick} />}
            {(document.format !== DOCUMENT_FORMATS.MP4 && document.format !== DOCUMENT_FORMATS.MP3) &&
              <img
                className={`img-preview ${isThumbnail ? 'img-thumbnail' : ''} ${imageClassName || ''}`}
                src={preview}
                style={isPrintFormat ? { width: `${printDimensions.width * 25.4 / 300}mm`, height: `${printDimensions.height * 25.4 / 300}mm` } : {}}
                alt={document.name || file.name || ''}
                onClick={onClick}
              />}
            {document.format === DOCUMENT_FORMATS.MP4 && <video width='100%' className={isThumbnail ? 'video-thumbnail' : ''} controls={isThumbnail ? false : withControls}><source src={preview} type='video/mp4' /></video>}
            {document.format === DOCUMENT_FORMATS.MP3 &&
              <audio controls={withControls}>
                <source src={preview} type='audio/mpeg' />
              </audio>}
          </div>
          {(format || (imgSize && imgSize !== -1)) && !isThumbnail && withInformation &&
            <div className='mt-3'>
              {format && <span>{`.${format}`}</span>}
              {imgSize && imgSize !== -1 && <span className='ml-2'>{FormatUtils.formatFileSize(imgSize)}</span>}
              <FontAwesomeIcon className='preview-check ml-2' icon={faCheckCircle} />
            </div>}
          {(document.format === DOCUMENT_FORMATS.MP4 || document.format === DOCUMENT_FORMATS.MP3) && !isThumbnail && withInformation &&
            <div> <FormattedMessage id='brand_hash_label' /> {document.hash}</div>}
          {isPrintFormat && document.format !== DOCUMENT_FORMATS.MP4 && document.format !== DOCUMENT_FORMATS.MP3 &&
            <div className='text-primary small mb-2'><FormattedMessage id='print_preview' /></div>}
        </IntlProvider>
      )
  )
}

export default Preview
