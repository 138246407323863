import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Foundation, PROCEDURE_OPPOSITION, SubmitButton, Table } from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

interface FoundationListProps {
    foundations: Foundation[],
    onAddClick?: () => void,
    onEditClick?: (foundation: Foundation) => void,
    onDeleteClick?: (foundation: Foundation) => void,
    procedureType?: string,
    hideTitle?: boolean
}

const FoundationList: FC<FoundationListProps> = ({ foundations, onAddClick, onEditClick, onDeleteClick, procedureType, hideTitle = false }) => {
  const tableTitles = [
    { label: Message.foundation_list_number },
    { label: procedureType === PROCEDURE_OPPOSITION.value ? Message.opposition_foundation_list_name : Message.nullity_foundation_list_name },
    { label: Message.foundation_list_brand_name }
  ]
  return (
    <div className={onAddClick && !hideTitle ? 'mt-5' : ''}>
      {onAddClick &&
        <SubmitButton className='btn-primary' onClick={onAddClick}>
          <FormattedMessage id={`${procedureType?.toLowerCase()}_foundations_add_button`} />
        </SubmitButton>}
      {foundations.length > 0 &&
        <Table tableTitle={tableTitles}>
          <tbody>
            {foundations.map((foundation: Foundation, key: number) =>
              <tr key={key}>
                <td>{key + 1}</td>
                <td><FormattedMessage id={`foundation_type_${foundation.type?.toLowerCase()}_label`} /></td>
                <td>{foundation.entity?.name}</td>
                {(onEditClick || onDeleteClick) &&
                  <td>
                    {onEditClick &&
                      <SubmitButton className='cursor-pointer btn-icon' onClick={() => onEditClick(foundation)}>
                        <FontAwesomeIcon icon={faEdit} className='text-primary' />
                      </SubmitButton>}
                    {onDeleteClick &&
                      <SubmitButton className='cursor-pointer btn-icon' onClick={() => onDeleteClick(foundation)}>
                        <FontAwesomeIcon icon={faTrash} className='text-secondary' />
                      </SubmitButton>}
                  </td>}
              </tr>
            )}
          </tbody>
        </Table>}
    </div>)
}

export default FoundationList
