import { SelectOption } from '../form/FormInterfaces'
import Message from './Message'

export const OPTIONS_YES_NO: SelectOption[] = [
  {
    label: Message.common_yes,
    value: true
  },
  {
    label: Message.common_no,
    value: false
  }
]

export const YEAR = {
  value: 'YEAR',
  label: Message.year
}

export const MONTH = {
  value: 'MONTH',
  label: Message.month
}

export const UNDEFINDED = {
  value: 'UNDEFINDED',
  label: Message.undefined
}

export const TIME_DURATION = [
  YEAR,
  MONTH,
  UNDEFINDED
]
