import {
  containsErrors,
  DateUtils,
  FieldStatus,
  FRMI,
  FRMI_TYPE_NULLIFICATION,
  Priority,
  PROCEDURE_FRMI,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  ProductClass,
  ProductClassVersion,
  ProductService as CommonProductService,
  StepButtonsUtils,
  Title,
  Transaction,
  TransactionService
} from '@inpi-marques/components'
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import ProductAndServicesFreeInput from '../../../deposit/form/productsAndServices/ProductAndServicesFreeinput'

import { storeTransactionFieldStatusReset } from '../../../../store/fieldStatus/fieldStatusActions'
import StoreService from '../../../../services/StoreService'
import ProductsService from 'services/deposit/ProductsService'

interface ProductsAndServicesFRMIProps {
  transaction: Transaction,
  fieldStatus: FieldStatus
}

const ProductsAndServicesFRMI: FC<ProductsAndServicesFRMIProps> = ({
  transaction,
  fieldStatus,
  children
}) => {
  const dispatch = useDispatch()
  const stepButtonsRef = useRef()
  const productsAndServicesRef = useRef()
  const user = useSelector((state: RootStateOrAny) => state.user.user)
  const currentMergedProductClasses: ProductClass[] = transaction.frmiDetails?.titles?.filter((title: Title) => title.productsAndServicesVersions?.length).map((title: Title) => CommonProductService.getCurrentVersion(title.productsAndServicesVersions)?.productClasses ?? []).flat(1).sort((a, b) => a.ref < b.ref ? -1 : a.ref > b.ref ? 1 : 0) ?? []
  const [productClassVersion, setProductClassVersion] = useState<ProductClassVersion>()

  /**
   * Permet de créer une version à partir des versions des titres de la transaction FRMI dans le cas
   * où on a pas encore passé cette étape
   */
  const getCurrentProductClassVersion = () => {
    const version = {
      createdDate: DateUtils.now(),
      type: PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION,
      status: PRODUCT_CLASS_VERSION_STATUS.ACCEPTED,
      creator: user,
      productClasses: currentMergedProductClasses
    } as ProductClassVersion

    setProductClassVersion(transaction?.frmiDetails?.productsAndServicesVersions?.[0] ?? version)
  }

  /**
   * A la modification de la liste des produits et services
   * @param productClasses
   */
  const onProductClassesEdited = (productClasses: ProductClass[]): void => {
    setProductClassVersion({ ...productClassVersion, productClasses: productClasses } as ProductClassVersion)
    if (containsErrors(fieldStatus)) {
      dispatch(storeTransactionFieldStatusReset())
    }
  }

  useEffect(() => {
    getCurrentProductClassVersion()

    const { observer, move } = StepButtonsUtils.initStepButtonsListener(productsAndServicesRef, stepButtonsRef)

    return () => {
      window.removeEventListener('scroll', move)
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    let updatedFrmiDetails: FRMI = { ...transaction.frmiDetails, productsAndServicesVersions: productClassVersion ? [productClassVersion] : [] }
    const editedProductClasses: ProductClass[] = productClassVersion?.productClasses ? [...productClassVersion.productClasses] : []

    // Mise à jour des limitations
    if (transaction.frmiDetails?.limitedProductsAndServicesVersions?.length) {
      const updatedLimitedProductsVersions: ProductClassVersion[] =
        transaction.frmiDetails.limitedProductsAndServicesVersions
          .filter((version: ProductClassVersion) => version.type === PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION)
          .map((version: ProductClassVersion) => ({ ...version, productClasses: ProductsService.updatePriorityAndLimitationByFRMIVersion(version.productClasses, editedProductClasses) })
          )
      updatedFrmiDetails = { ...updatedFrmiDetails, limitedProductsAndServicesVersions: updatedLimitedProductsVersions }
    }
    // Mise à jour des priorités
    if (transaction.frmiDetails?.priorities?.length) {
      const updatedPriorities: Priority[] = transaction.frmiDetails.priorities.map((priority: Priority) => ({
        ...priority,
        productAndServices: ProductsService.updatePriorityAndLimitationByFRMIVersion(priority.productAndServices ?? [], editedProductClasses)
      }))
      updatedFrmiDetails = { ...updatedFrmiDetails, priorities: updatedPriorities }
    }

    // On enregistre dans le store
    StoreService.changeStore({
      ...transaction,
      frmiDetails: updatedFrmiDetails
    })
  }, [productClassVersion?.productClasses])

  return (
    <div className='d-flex flex-column' ref={productsAndServicesRef}>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_products_and_services_title' /></h1>
          <span className='subtitle'><FormattedMessage id={`frmi_${TransactionService.isFrmiOP(transaction) ? (transaction.subProcedureType === FRMI_TYPE_NULLIFICATION.value ? transaction.subProcedureType.toLowerCase() + '_' : 'op_') : ''}products_and_services_description`} /></span>
        </header>
        <InternalReferenceField
          className='col-3'
          transaction={transaction}
        />
      </div>
      <ProductAndServicesFreeInput
        duplicateClassesOnCreate
        classname='col-12'
        shouldResetSelectOnSubmit
        hasDefaultValue={false}
        onProductClassesEdited={onProductClassesEdited}
        productClasses={productClassVersion?.productClasses ?? []}
        isEditable
        procedureType={PROCEDURE_FRMI.value}
        asLink
      />

      <div className='step-buttons--fixed mb-3' ref={stepButtonsRef}>
        {StepButtonsUtils.getEditedChildren(children as ReactElement)}
      </div>
    </div>
  )
}

export default ProductsAndServicesFRMI
