import React, { FC } from 'react'
import { SirenDenomination } from '../../interfaces/contributors/OpenDataRNCSInterfaces'
import { Table } from '../../index'
import Message from '../../constants/Message'

interface ListSirenWithDenominationProps {
    listSirenDenomination: SirenDenomination[],
    onSelectCompany: (siren: string) => void
}

const ListSirenWithDenomination: FC<ListSirenWithDenominationProps> = (
  {
    listSirenDenomination,
    onSelectCompany
  }) =>
  (
    <div>
      <Table
        tableTitle={[
          { label: Message.intervenant_table_label_siren, className: 'text-uppercase' },
          { label: Message.intervenant_table_label_denomination }
        ]}
        colorHover
      >
        <tbody>
          {
            listSirenDenomination.map((sirenDenomination: SirenDenomination, index: number) => (
              <tr
                key={`-${index}`} className='cursor-pointer'
                onClick={() => onSelectCompany(sirenDenomination.siren)}
              >
                <td className='border-0 '>{sirenDenomination.siren}</td>
                <td className='border-0 '>{sirenDenomination.denominationSociale}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  )

export default ListSirenWithDenomination
