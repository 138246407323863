import React, { Dispatch, FC } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { Contributor, ContributorsOptionsForm } from '../../interfaces/contributors/ContributorsInterfaces'
import { EventType, FieldStatus } from '../../form/FormInterfaces'
import { buildEventType, MultipleRadioField, TextField } from '../../index'
import { CONSENTEMENT_OPTIONS } from '../../constants/ConsentementConstants'
/* global localStorage */

interface PersonContactFieldsProps extends WrappedComponentProps{
    contributor?: Contributor,
    onChange: (event: EventType) => void,
    fieldStatus?: FieldStatus | any,
    options?: ContributorsOptionsForm,
    dispatch?: Dispatch<any>,
    resetError?: (fieldStatus: FieldStatus) => void,
    isBO?: boolean
}

const PersonContactFields: FC<PersonContactFieldsProps> = ({
  contributor = {},
  onChange,
  fieldStatus,
  intl,
  options,
  dispatch,
  resetError,
  isBO
}) => {
  const canAskConsent = localStorage.getItem('ELECTRONIC_NOTIF') === 'true'

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-4'>
          <TextField
            type='phone'
            inputId='phone'
            label={intl.formatMessage({ id: 'field_telephone_label' })}
            value={contributor.phone || ''}
            onChange={onChange}
            fieldStatus={fieldStatus}
            dispatch={dispatch}
            resetError={resetError}
            readOnly={options?.allFieldReadOnly}
          />
        </div>
        <div className='col-12 col-md-4'>
          <TextField
            type='email'
            inputId='email'
            label={intl.formatMessage({ id: 'field_email_label' })}
            value={contributor.email || ''}
            onChange={onChange}
            fieldStatus={fieldStatus}
            readOnly={options?.isEmailReadOnly || options?.allFieldReadOnly}
            required={options?.isEmailRequired}
            dispatch={dispatch}
            resetError={resetError}
          />
        </div>
      </div>
      <div>
        {
          canAskConsent && options?.askConsent && !isBO &&
            <div className='small row mt-2 col-12 bg-primary p-2 ml-1 card d-flex flex-row'>
              <label className='mr-5 text-white mb-0'>
                <FormattedMessage id='field_email_consent' />
                <span className='text-danger'> *</span>
              </label>
              <MultipleRadioField
                classNameRadioGroup='d-flex text-white'
                inputId='consentNotificationByEmail'
                value={contributor.consentNotificationByEmail}
                radio={CONSENTEMENT_OPTIONS}
                onChange={(event) => onChange(buildEventType('consentNotificationByEmail', event.target.value))}
                fieldStatus={fieldStatus}
                dispatch={dispatch}
                resetError={resetError}
                classNameError='error-radio'
              />
            </div>
        }
      </div>
    </>
  )
}

export default injectIntl(PersonContactFields)
