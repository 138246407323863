import {
  DateField,
  DateUtils,
  DATE_ISO,
  DivisionDetails,
  EventType,
  TextArea,
  TextField, CountrySelectField
} from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ContentService from '../../../../../services/content/ContentService'

interface DivisionContentProps {
    divisionDetails: DivisionDetails,
    onDivisionDetailsChanged: (details: DivisionDetails) => void,
    isMM22bis?: boolean
}

/**
 * Formulaire contenant les informations d'une division d'un enregistrement national (M22-Bis)
 * @returns
 */
const DivisionContent: FC<DivisionContentProps> = ({ divisionDetails, onDivisionDetailsChanged, isMM22bis = false }) => {
  const [officeContractingParty, setOfficeContractingParty] = useState<string>(divisionDetails.officeContractingParty ?? 'FR')
  const [brandNumNat, setBrandNumNat] = useState<string>(divisionDetails.brandNumNat ?? '')
  const [text, setText] = useState<string>(divisionDetails.text ?? Message.frmi_division_details_text)

  useEffect(() => {
    onDivisionDetailsChanged({ ...divisionDetails, officeContractingParty })
  }, [officeContractingParty])

  return (
    <div className='row'>
      {/* Partie contractante de l’Office présentant la demande  */}
      <CountrySelectField
        classNameFormGroup='col-4'
        inputId='officeContractingParty'
        value={officeContractingParty}
        label={<FormattedMessage id='frmi_division_details_office_contracting_party_label' />}
        onChange={(event: EventType) => setOfficeContractingParty(event.target.value)}
        getCountries={ContentService.getCountries}
      />
      {/* Date à laquelle a eu lieu la division de la marque française */}
      {
        isMM22bis &&
          <>
            <DateField
              inputId='date'
              classNameFormGroup='col-4'
              value={DateUtils.formateDateToFormat(divisionDetails.date, DATE_ISO) ?? ''}
              label={<FormattedMessage id='frmi_division_details_date_label' />}
              onChange={(event: EventType) => onDivisionDetailsChanged({ ...divisionDetails, date: DateUtils.formatToBeginOfDay(event.target.value) })}
            />
            {/* N° de la marque française de base résultant de la division */}
            <TextField
              classNameFormGroup='col-4'
              inputId='brandNumNat'
              value={brandNumNat}
              label={<FormattedMessage id='frmi_division_details_brand_numnat_label' />}
              onChange={(event: EventType) => setBrandNumNat(event.target.value)}
              onBlur={() => onDivisionDetailsChanged({ ...divisionDetails, brandNumNat })}
            />
            {/* Date de la demande de la marque française de base résultant de la division */}
            <DateField
              inputId='brandRequestDate'
              classNameFormGroup='col-4'
              value={DateUtils.formateDateToFormat(divisionDetails.brandRequestDate, DATE_ISO) ?? ''}
              label={<FormattedMessage id='frmi_division_details_brand_request_date_label' />}
              onChange={(event: EventType) => onDivisionDetailsChanged({ ...divisionDetails, brandRequestDate: DateUtils.formatToBeginOfDay(event.target.value) })}
            />
            {/* Date de l’enregistrement de la marque française résultant de la division */}
            <DateField
              inputId='brandRegistrationDate'
              classNameFormGroup='col-4'
              value={DateUtils.formateDateToFormat(divisionDetails.brandRegistrationDate, DATE_ISO) ?? ''}
              label={<FormattedMessage id='frmi_division_details_brand_registration_date_label' />}
              onChange={(event: EventType) => onDivisionDetailsChanged({ ...divisionDetails, brandRegistrationDate: DateUtils.formatToBeginOfDay(event.target.value) })}
            />
            {/* Texte à compléter */}
            <TextArea
              classNameFormGroup='col-12'
              inputId='text'
              value={text}
              label={<FormattedMessage id='frmi_division_details_text_label' />}
              onChange={(event: EventType) => setText(event.target.value)}
              onBlur={() => onDivisionDetailsChanged({ ...divisionDetails, text })}
            />
          </>
      }
    </div>
  )
}

export default DivisionContent
