import { CountriesTable, Country, FRMI } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ContentService from 'services/content/ContentService'

interface OverviewCountriesProps {
    frmi: FRMI
}

const OverviewCountries: FC<OverviewCountriesProps> = ({ frmi }) => {
  const [page, setPage] = useState<number>(0)
  const [dbCountries, setDbCountries] = useState<Country[]>()

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setDbCountries(response)
    })
  }, [])

  return (
    <>
      {
        frmi.countries && frmi.countries?.length > 0 &&
          <>
            <CountriesTable countries={frmi.countries ?? []} page={page} setPage={setPage} countriesFromDB={dbCountries} />
            {frmi.regionalLimit &&
              <div>
                <h4 className='font-weight-bold'><FormattedMessage id='frmi_regional_limit_oveview_label' /></h4>
                <p className='subtitle'>{frmi.regionalLimit}</p>
              </div>}
          </>
      }
    </>
  )
}

export default OverviewCountries
