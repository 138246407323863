import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors,
  Contributor,
  CONTRIBUTOR_DEPOSITORS,
  ContributorValidator, Country,
  DEFAULT_CONTRIBUTOR,
  DEPOSIT_CONTRIBUTORS_TYPES,
  EditorialBlock,
  FieldStatus,
  FilAriane,
  FRMI_CONTRIBUTORS_OPTIONS,
  FRMI_CONTRIBUTORS_TYPES,
  FRMI_TYPE_EXTENSION,
  ModalComponent,
  PROCEDURE_FRMI, Seniority, Title,
  Transaction,
  TransactionDocument,
  UE
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from 'store/transaction/transactionActions'
import TransactionService from 'services/transaction/TransactionService'
import ContentService from '../../../services/content/ContentService'
import Titles from '../../inscription/form/titles/Titles'
import TitlesValidator from '../../inscription/form/titles/validator/TitlesValidator'
import BrandReproduction from './brandReproduction/BrandReproduction'
import BrandReproductionValidator from './brandReproduction/validator/BrandReproductionValidator'
import ColorsClaimed from './colorsClaimed/ColorsClaimed'
import ColorsClaimedValidator from './colorsClaimed/validator/ColorsClaimedValidator'
import FRMICountries from './countries/FRMICountries'
import CountryValidator from './countries/validator/CountryValidator'
import PrioritiesFRMIClaimed from './priorities/PrioritiesFRMIClaimed'
import SenioritiesClaimed from './senioritiesClaimed/SenioritiesClaimed'
import ContributorList from '../../contributors/ContributorList'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import ContributorAgent from '../../contributors/ContributorAgent'
import ContributorService from '../../../services/contributors/ContributorService'
import ContributorFrontForm from '../../contributors/ContributorFrontForm'
import FRMIDocuments from './documents/FRMIDocuments'
import VariousIndications from './variousIndications/VariousIndications'
import VariousIndicationsValidator from './variousIndications/validator/VariousIndicationsValidator'
import CommentsFRMIForm from './CommentsFRMIForm'
import ProductsAndServicesFRMI from './ProductsAndServices/ProductsAndServicesFRMI'
import ProductsAndServicesValidator from '../../deposit/form/productsAndServices/validator/ProductsAndServicesValidator'
import LimitedProductsAndServices from './limitedProductsAndServicesVersions/LimitedProductsAndServices'
import Recap from '../../recap/Recap'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import FeesAndTaxes from './feesAndTaxes/FeesAndTaxes'
import FeesAndTaxesValidator from './feesAndTaxes/validator/FeesAndTaxesValidator'
import PaymentForm from '../../payment/PaymentForm'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_FRMI } from '../../../constants/PaymentConstants'
import FRMIDocumentValidator from './documents/validator/FRMIDocumentValidator'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
import TitleService from '../../../services/inscription/TitleService'
/* global localStorage */

interface FRMIFormProps extends RouteComponentProps {
  isNewTransaction: boolean
}

const FRMIForm: FC<FRMIFormProps> = ({
  history,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const [required, setIsRequired] = useState<boolean>(false)
  const fieldStatus: FieldStatus = useSelector((state: RootStateOrAny) => state.transactionFieldStatus)
  const transaction: Transaction = useSelector((state: RootStateOrAny) => state.transaction)
  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [title, setTitle] = useState<Title|undefined>(transaction?.frmiDetails?.titles?.[0])
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const conditionClaimedSeniorities = transaction.frmiDetails?.countries?.some((country) => country.code === UE)
  const [tmpPreview, setTmpPreview] = useState<TransactionDocument>()
  // Gestion de l'affichage du formulaire / de la liste des déposant au premier affichage
  const [depositorsOpenOnFirstOne, setDepositorsOpenOnFirstOne] = useState<boolean>(true)

  const handleSubmit = () => {
    setIsNew(isNewTransaction)
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  const saveBrandList = async () => {
    if (isNew && !transaction.id) {
      setIsNew(false)
      return await TransactionService.createTransaction(transaction, PROCEDURE_FRMI.value, ['frmiDetails', 'subProcedureType'])
    } else {
      let updatedTransaction: Transaction = { ...transaction }
      // Si le titre a été modifié
      if (transaction.frmiDetails?.brand?.id && title?.numNat !== transaction?.frmiDetails?.titles?.[0]?.numNat) {
        updatedTransaction = { ...updatedTransaction, frmiDetails: { ...updatedTransaction?.frmiDetails, brand: { ...updatedTransaction.frmiDetails?.brand, verbalElements: TitleService.isTitleWordType(updatedTransaction?.frmiDetails?.titles?.[0]) ? updatedTransaction?.frmiDetails?.titles?.[0]?.text : undefined } } }
      }
      setTitle(transaction?.frmiDetails?.titles?.[0])
      return await TransactionService.updateTransactionBDDFromStore(updatedTransaction, ['frmiDetails', 'subProcedureType'], documents)
    }
  }

  /**
   * Edition de la liste des déposants
   * @returns
   */
  const onDepositorsEdited = async (): Promise<Transaction|null> => {
    // Supprime les déposants non conformes
    let newContributorList : Contributor[] = []
    transaction.depositors && transaction.depositors.map((contributor) => {
      const fieldStatusContributor = ContributorValidator.validateContributor(contributor, transaction, CONTRIBUTOR_DEPOSITORS.value)
      if (!containsErrors(fieldStatusContributor)) {
        newContributorList = [...newContributorList, contributor]
      }
    })

    depositorsOpenOnFirstOne && setDepositorsOpenOnFirstOne(false)
    return await TransactionService.updateTransactionBDDFromStore({ ...transaction, depositors: newContributorList }, [DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value])
  }

  /**
     * Edition de la liste des pays
     * @returns
     */
  const onCountriesEdited = async (): Promise<Transaction|null> => {
    // Supprime les anciennetés si l'UE n'est plus sélectionnée
    let seniorities: Seniority[]|undefined = []
    if (transaction.frmiDetails?.countries?.some((country: Country) => country.code === UE)) {
      seniorities = transaction?.frmiDetails?.seniorities
    }

    return await TransactionService.updateTransactionBDDFromStore({
      ...transaction,
      frmiDetails: {
        ...transaction?.frmiDetails,
        seniorities: seniorities
      }
    }, ['frmiDetails'], documents)
  }

  // si on a plusieurs déposants dans la transaction on n'affiche pas le mode édition du premier
  // mais la liste des déposants
  useEffect(() => {
    setDepositorsOpenOnFirstOne(!transaction?.depositors || transaction.depositors.length < 2)
  }, [transaction.depositors])

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        result.documents && setDocuments(result.documents)
        setTitle(result?.frmiDetails?.titles?.[0])
      })
      setDepositorsOpenOnFirstOne(false)
    } else {
      dispatch(storeTransactionInit(PROCEDURE_FRMI.value, FRMI_TYPE_EXTENSION.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-FRMI').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_frmi' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='breadcrumb_new_frmi' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        views={[
          {
            id: 'frmi_titles',
            label: <FormattedMessage id='frmi_brand_title' />,
            onGoToStep: async () => saveBrandList(),
            component: (
              <Titles
                transaction={transaction}
                fieldStatus={fieldStatus}
                origin={['FR']}
                setTmpPreview={setTmpPreview}
              />
            ),
            validateGoToStep: () => TitlesValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'frmi_country_step',
            label: <FormattedMessage id='frmi_column_label_country' />,
            component: (
              <FRMICountries
                transaction={transaction}
                fieldStatus={fieldStatus}
              />),
            onGoToStep: onCountriesEdited,
            validateGoToStep: () => CountryValidator.validateOnChangeStep(transaction.frmiDetails)
          },
          {
            id: 'contributors',
            label: <FormattedMessage id='contributor_title' />
          }, {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_holders' />,
            level: 1,
            component: (
              <ContributorList
                openOnFirstOne={depositorsOpenOnFirstOne}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                contributorTypeLabel={`${FRMI_CONTRIBUTORS_TYPES.HOLDERS.value}`}
                contributorTypeToCopy={[
                  FRMI_CONTRIBUTORS_TYPES.AGENT.value,
                  FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={FRMI_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_FRMI.value}
                validateContributor={(contributor: Contributor, transaction?: Transaction, contributorType?: string, isFirst?: boolean) =>
                  ContributorValidator.validateContributor(contributor, transaction, DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value, false, transaction?.frmiDetails?.countries, isFirst)}
                setIsRequired={setIsRequired}
                fromFrmiExtension
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: onDepositorsEdited
          },
          {
            id: 'contributors_agent',
            label: <FormattedMessage id='contributor_agent' />,
            level: 1,
            component: (
              <ContributorAgent
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorTypeToCopy={[
                  FRMI_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  FRMI_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value
                ]}
                option={FRMI_CONTRIBUTORS_OPTIONS.agent}
                procedure={PROCEDURE_FRMI.value}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepAgentDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [FRMI_CONTRIBUTORS_TYPES.AGENT.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, FRMI_CONTRIBUTORS_TYPES.AGENT.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  FRMI_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  FRMI_CONTRIBUTORS_TYPES.AGENT.value,
                  FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  FRMI_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value
                ]}
                optionForm={FRMI_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_FRMI.value}
                defaultContributor={{ ...DEFAULT_CONTRIBUTOR, email: user.email, consentNotificationByEmail: user.consentNotificationByEmailPreferences }}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value])
          },
          {
            id: 'contributors_signatory',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={FRMI_CONTRIBUTORS_OPTIONS.signatory}
                procedure={PROCEDURE_FRMI.value}
                defaultContributor={ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'frmi_brand_reproduction',
            label: <FormattedMessage id='frmi_brand_reproduction_title' />,
            component: (
              <BrandReproduction
                fieldStatus={fieldStatus}
                transaction={transaction}
                tmpPreview={tmpPreview}
                setTmpPreview={setTmpPreview}
              />),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails'], documents),
            validateGoToStep: () => BrandReproductionValidator.validateOnChangeStep(documents, transaction.frmiDetails)
          },
          {
            id: 'frmi_colors_claimed',
            label: <FormattedMessage id='frmi_colors_claimed_title' />,
            component: (
              <ColorsClaimed transaction={transaction} fieldStatus={fieldStatus} />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails'], documents),
            validateGoToStep: () => ColorsClaimedValidator.validateOnChangeStep(documents, transaction.frmiDetails)
          },
          {
            id: 'frmi_various_indications',
            label: <FormattedMessage id='frmi_various_indications_title' />,
            component: (
              <VariousIndications transaction={transaction} fieldStatus={fieldStatus} />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails'], documents),
            validateGoToStep: () => VariousIndicationsValidator.validateOnChangeStep(transaction.frmiDetails)
          },
          {
            id: 'frmi_products_and_services',
            label: <FormattedMessage id='frmi_products_and_services' />,
            component: (
              <ProductsAndServicesFRMI fieldStatus={fieldStatus} transaction={transaction} />
            ),
            validateGoToStep: () => ProductsAndServicesValidator.validateOnChangeStep(transaction.frmiDetails?.productsAndServicesVersions?.[0]),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails']),
            stepButtonsAsChildren: true
          },
          {
            id: 'frmi_limited_products_and_services',
            label: <FormattedMessage id='frmi_limited_products_and_services' />,
            component: (
              <LimitedProductsAndServices transaction={transaction} />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails']),
            stepButtonsAsChildren: true
          },
          {
            id: 'frmi_priorities_claimed',
            label: <FormattedMessage id='frmi_priorities_claimed_title' />,
            component: (
              <PrioritiesFRMIClaimed
                transaction={transaction}
              />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails'], documents)
          },
          {
            id: 'frmi_claimed_seniorities',
            label: <FormattedMessage id='frmi_claimed_seniorities_title' />,
            condition: conditionClaimedSeniorities,
            component: (
              <SenioritiesClaimed
                transaction={transaction}
              />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails'], documents)
          },
          {
            id: 'frmi_fees_and_taxes',
            label: <FormattedMessage id='frmi_fees_and_taxes_title' />,
            component: (
              <FeesAndTaxes
                transaction={transaction}
                fieldStatus={fieldStatus}
              />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['frmiDetails'], documents),
            validateGoToStep: () => FeesAndTaxesValidator.validateOnChangeStep(transaction.frmiDetails)
          },
          {
            id: 'documents',
            label: <FormattedMessage id='documents_label' />,
            component: (
              <FRMIDocuments
                transaction={transaction}
                fieldStatus={fieldStatus}
                setDocuments={setDocuments}
                documents={documents}
              />
            ),
            onGoToStep: () => TransactionService.updateDocumentTransaction(transaction, documents),
            validateGoToStep: () => FRMIDocumentValidator.validateOnChangeStep(documents, transaction?.frmiDetails)
          },
          {
            id: 'frmi_comments',
            label: <FormattedMessage id='frmi_comments_title' />,
            component: (
              <CommentsFRMIForm transaction={transaction} />
            ),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['comments'], documents)
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.INITIAL}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_FRMI}
              />
            )
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(FRMIForm)
