import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Badge, CardBlock } from '@inpi-marques/components'

interface DashboardItemProps {
  url: string,
  label: string,
  badge?: number,
}

const DashboardItem: FC<DashboardItemProps> = ({
  url,
  label,
  badge = 0
}) => (
  <div className='col-12 col-sm-6 col-md-4 col-lg-3 my-3'>
    <Link to={url} className='text-decoration-none'>
      <CardBlock shadow className='cursor-pointer theme-secondary' bodyClassName='d-flex align-items-center'>
        <div className='item d-flex justify-content-between w-100'>
          <span className='text-body my-auto pr-2 flex-1'>
            <FormattedMessage id={label} />
          </span>
          <Badge className={`my-auto d-flex mr-0 justify-content-center ${badge <= 0 ? 'badge-none' : 'badge-secondary'}`}>
            {badge === -1
              ? <FontAwesomeIcon className='loader' icon={faSpinner} />
              : <span>{badge}</span>}
          </Badge>
        </div>
      </CardBlock>
    </Link>
  </div>
)

export default DashboardItem
