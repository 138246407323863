import { DateUtils, DivisionDetails } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ContentService from '../../../../../services/content/ContentService'

interface DivisionContentOverviewProps {
    divisionDetails: DivisionDetails
}

/**
 * Récapitulatif des détails d'une division d'enregistrement National (M22-BIS)
 */
const DivisionContentOverview: FC<DivisionContentOverviewProps> = ({ divisionDetails }) => {
  const [officeContractingParty, setOfficeContractingParty] = useState()

  useEffect(() => {
    divisionDetails.officeContractingParty && ContentService.getCountry(divisionDetails.officeContractingParty)
      .then((response) => {
        setOfficeContractingParty(response?.name)
      })
  }, [])

  return (
    <>
      {/* Partie contractante de l’Office présentant la demande  */}
      {officeContractingParty &&
        <div className='mb-3'>
          <h4 className='font-weight-bold'>
            <FormattedMessage id='frmi_division_details_office_contracting_party_label' />
          </h4>
          <span>{officeContractingParty}</span>
        </div>}
      {/* Date à laquelle a eu lieu la division de la marque française */}
      {divisionDetails.date &&
        <div className='mb-3'>
          <h4 className='font-weight-bold'>
            <FormattedMessage id='frmi_division_details_date_label' />
          </h4>
          <span>{DateUtils.formatDateFr(divisionDetails.date)}</span>
        </div>}
      {/* N° de la marque française de base résultant de la division */}
      {divisionDetails.brandNumNat &&
        <div className='mb-3'>
          <h4 className='font-weight-bold'>
            <FormattedMessage id='frmi_division_details_brand_numnat_label' />
          </h4>
          <span>{divisionDetails.brandNumNat}</span>
        </div>}
      {/* Date de la demande de la marque française de base résultant de la division */}
      {divisionDetails.brandRequestDate &&
        <div className='mb-3'>
          <h4 className='font-weight-bold'>
            <FormattedMessage id='frmi_division_details_brand_request_date_label' />
          </h4>
          <span>{DateUtils.formatDateFr(divisionDetails.brandRequestDate)}</span>
        </div>}
      {/* Date de l’enregistrement de la marque française résultant de la division */}
      {divisionDetails.brandRegistrationDate &&
        <div className='mb-3'>
          <h4 className='font-weight-bold'>
            <FormattedMessage id='frmi_division_details_brand_registration_date_label' />
          </h4>
          <span>{DateUtils.formatDateFr(divisionDetails.brandRegistrationDate)}</span>
        </div>}
      {/* Texte à compléter */}
      {divisionDetails.text &&
        <div className='mb-3'>
          <h4 className='font-weight-bold'>
            <FormattedMessage id='frmi_division_details_text_label' />
          </h4>
          <span>{divisionDetails.text}</span>
        </div>}
    </>
  )
}

export default DivisionContentOverview
