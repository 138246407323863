import {
  CardBlock,
  CommonInscriptionService,
  DateUtils,
  DOCUMENT_TYPES,
  ErrorField,
  ETS_ACCOUNT_STATE_ACTIVE,
  ETSAccount,
  EventType,
  FieldStatus,
  HelpBlock,
  INSCRIPTION_RENUNCIATION_TYPE_TOTAL,
  INSCRIPTION_RENUNCIATION_TYPES,
  MultipleRadioField,
  NatureCode,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  PRODUCT_STATUS,
  ProductClass,
  ProductClassVersion,
  ProductService,
  Publication,
  PUBLICATION_REGISTRATION,
  PUBLICATION_REGISTRATIONAM,
  PUBLICATION_REGISTRATION_TMI_TME,
  PUBLICATION_REGISTRATION_DIV_REG,
  PUBLICATION_REGISTRATION_DIV,
  Renunciation,
  Title,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import DocumentsBlock from 'component/inscription/form/correction/DocumentsBlock'
import TitleValidateContent from 'component/inscription/form/titles/TitleValidateContent'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import ContestedProductsAndServices from 'component/opposition/form/registrationRequest/ContestedProductsAndServices'
import Suite from 'component/suite/Suite'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import TransactionService from 'services/transaction/TransactionService'
import { storeTransactionUpdateInscription } from 'store/transaction/transactionActions'

interface RenunciationTypesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    natureCodes: NatureCode[],
    documents: TransactionDocument[],
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentEdited: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument)=> void,
    isEdition: boolean
}

const RenunciationTypes: FC<RenunciationTypesProps> = ({ transaction, fieldStatus, documents, natureCodes, onDocumentAdded, onDocumentDeleted, onDocumentEdited, children, isEdition }) => {
  const dispatch = useDispatch()

  const currentUser = useSelector((state : RootStateOrAny) => state.user.user)

  /** S'il s'agit d'un retrait, on modifie l'affichage pour rediriger l'utilisateur vers le titre en question */
  const [isWithDrawal, setIsWithDrawal] = useState<boolean>(false)
  const [withDrawalType, setWithDrawalType] = useState<'attachment' | 'redirect' | 'not_found'>()
  const [titleTransaction, setTitleTransaction] = useState<Transaction>()

  /** S'il s'agit d'une renonciation ou d'un retrait et le titre est inconnu alors on peut modifier les P&S */
  const [canEditProducts, setCanEditProducts] = useState<boolean>(isEdition)

  const title: Title|undefined = transaction.inscription?.titles && transaction.inscription.titles.length > 0 ? transaction.inscription.titles[0] : undefined

  /**
   * Si on passe d'une renonciation Partielle à totale on garde la version partielle en mémoire afin d'y revenir en cas de changement inverse
   */
  const [tmpProductVersion, setTmpProductVersion] = useState<ProductClassVersion>()

  /**
   * On check si le titre en question nous aménera à une renonciation ou non
   * @param title
   * @returns
   */
  const isRenunciation = (title: Title): boolean =>
    // S'il possède une publication de type Enregistrement
    title.publications?.some((publication: Publication) =>
      publication.code === PUBLICATION_REGISTRATION ||
      publication.code === PUBLICATION_REGISTRATIONAM ||
      publication.code === PUBLICATION_REGISTRATION_TMI_TME ||
      publication.code === PUBLICATION_REGISTRATION_DIV_REG ||
      publication.code === PUBLICATION_REGISTRATION_DIV ||
      // Ou s'il n'a pas de date de publication mais est dans un BOPI fermé
      (!publication.date && publication.isBopiClosed)) ||
    // Si publication est undefined
    false

  /**
   * A la récupération du titre
   * @param editedTitle
   */
  const onTitleChanged = async (editedTitle: Title): Promise<void> => {
    if (editedTitle.numNat !== title?.numNat) {
      if (!isRenunciation(editedTitle)) {
        const recordTransaction: Transaction|null = await TransactionService.getTransactionByNumnat(editedTitle.numNat)
        if (recordTransaction) {
          setTitleTransaction(recordTransaction)
          const isCurrentUserCurrentEtsAccount: boolean = !!recordTransaction.ETSAccounts?.some((account: ETSAccount) => account.state === ETS_ACCOUNT_STATE_ACTIVE && account.email === currentUser.email)
          if (isCurrentUserCurrentEtsAccount) {
            setWithDrawalType('redirect')
          } else {
            setWithDrawalType('attachment')
          }
          setCanEditProducts(false)
        } else {
          setWithDrawalType('not_found')
          setCanEditProducts(true)
        }

        setIsWithDrawal(true)

        dispatch(storeTransactionUpdateInscription({
          ...transaction.inscription,
          titles: [{ ...editedTitle }]
        }))
        return
      } else {
        setIsWithDrawal(false)
        setCanEditProducts(true)
      }
    }
    /**
     * S'il s'agit d'une renonciation totale, on passe les statuts des produits à Supprimé
     */
    let productVersion: ProductClassVersion|undefined = ProductService.getCurrentVersion(editedTitle.productsAndServicesVersions)

    /**
     * S'il s'agit d'une renonciation totale, alors on supprime les produits
     */
    if (productVersion && CommonInscriptionService.isTotalRenunciation(transaction)) {
      productVersion = ProductService.updateAllProductsStatus(productVersion, PRODUCT_STATUS.DELETED)
    }

    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      titles: [{
        ...editedTitle,
        details: editedTitle.details,
        productsAndServicesVersions: productVersion ? [{ ...productVersion, creator: currentUser }] : undefined
      }]
    }))
  }

  const renunciation: Renunciation|undefined = transaction.inscription?.renunciation

  /**
   * Mise à jour des produits et service de la renonciation
   *
   * @param productClasses
   */
  const handleProductsAndServicesChange = (productClasses : ProductClass[]) => {
    // On crée une version initiale s'il s'agit d'un retrait pour un titre inconnu
    const currentVersion: ProductClassVersion| undefined = ProductService.getCurrentVersion(title?.productsAndServicesVersions) || { status: PRODUCT_CLASS_VERSION_STATUS.ACCEPTED, type: PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION, productClasses: [], createdDate: DateUtils.now() }
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      titles: title ? [{ ...title, productsAndServicesVersions: currentVersion ? [{ ...currentVersion, productClasses }] : undefined }] : []
    }))
  }

  /**
   * Mise à jour du type de renonciation
   * @param event
   */
  const onTypeChanged = (event: EventType): void => {
    const { value } = event.target

    let productVersion: ProductClassVersion|undefined = ProductService.getCurrentVersion(title?.productsAndServicesVersions)

    const natureCode: NatureCode|undefined = natureCodes.find((natureCode: NatureCode) => value === INSCRIPTION_RENUNCIATION_TYPE_TOTAL.value ? natureCode.code === 'RT' : natureCode.code === 'RP')

    /**
     * Si je passe du type 'Renonciation partielle' à 'Renonciation totale', je garde en mémoire la version actuelle au cas où l'utilisateur
     * changerait à nouveau de type.
     * Ceci permet de garder les produits ajoutés et supprimés
     */
    if (productVersion && value === INSCRIPTION_RENUNCIATION_TYPE_TOTAL.value) {
      setTmpProductVersion(productVersion)
      productVersion = ProductService.updateAllProductsStatus(productVersion, PRODUCT_STATUS.DELETED)
    } else if (tmpProductVersion) {
      productVersion = tmpProductVersion
      setTmpProductVersion(undefined)
    } else if (productVersion && !tmpProductVersion) {
      productVersion = ProductService.updateAllProductsStatus(productVersion)
    }

    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      titles: title ? [{ ...title, productsAndServicesVersions: productVersion ? [productVersion] : undefined }] : [],
      renunciation: {
        ...renunciation,
        type: value
      },
      natureCodes: natureCode ? [{ code: natureCode.code, label: natureCode.label, psDisplayType: natureCode.psDisplayType }] : []
    }))
  }

  return (
    <div className='flex-grow-1'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='renunciation_form_title' /></h1>
          <span className='subtitle'><FormattedMessage id='renunciation_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row'>
        {(!isWithDrawal || withDrawalType === 'not_found') &&
          <CardBlock header={<FormattedMessage id='renunciation_type_block_label' />} className='col-12 p-0' shadow>
            <MultipleRadioField
              disabled={!natureCodes}
              inputId='renunciationTypes'
              radio={INSCRIPTION_RENUNCIATION_TYPES}
              value={renunciation?.type ?? ''}
              onChange={onTypeChanged}
              fieldStatus={fieldStatus}
            />
          </CardBlock>}
        <CardBlock header={<FormattedMessage id='renunciation_title_block_label' />} className='col-12 p-0 mt-3' shadow>
          <TitleValidateContent
            title={title}
            setTitle={onTitleChanged}
            className='md-6 mb-3 p-0'
            fieldStatus={fieldStatus}
            isEditingMode={!isWithDrawal || withDrawalType !== 'not_found'}
            onlyVerifier={isWithDrawal && withDrawalType !== 'not_found'}
            origin={['FR']}
            transaction={transaction}
            showBrandLabel
          />
          {(!isWithDrawal || withDrawalType === 'not_found') && CommonInscriptionService.isPartialRenunciation(transaction) &&
              (canEditProducts || ProductService.getCurrentVersion(title?.productsAndServicesVersions)) &&
                <ContestedProductsAndServices
                  productClasses={ProductService.getCurrentVersion(title?.productsAndServicesVersions)?.productClasses ?? []}
                  handleProductsAndServicesChange={handleProductsAndServicesChange}
                  fieldStatus={fieldStatus}
                  procedureType={transaction.procedureType}
                />}
          {isWithDrawal &&
            <>
              {withDrawalType === 'redirect' &&
                <HelpBlock>
                  <FormattedMessage
                    id='renunciation_form_is_with_drawable_message'
                    values={{
                      a: (...chunks : ((string) []) | []) => (<a href={`/liste/en-cours-examen/demande/${titleTransaction?.id}?corbeille=home`} rel='noopener noreferrer'>{chunks}</a>),
                      linebreak: <br />
                    }}
                  />
                </HelpBlock>}
              {withDrawalType === 'attachment' &&
                <HelpBlock>
                  <FormattedMessage
                    id='renunciation_form_is_with_drawable_attachment_message'
                    values={{
                      a: (...chunks : ((string) []) | []) => (<a href={`/rattachements/nouveau-compte?numNat=${titleTransaction?.numNat}`} rel='noopener noreferrer'>{chunks}</a>),
                      linebreak: <br />
                    }}
                  />
                </HelpBlock>}
            </>}
          {fieldStatus.title &&
            <div>
              <ErrorField message={fieldStatus.title} />
            </div>}
        </CardBlock>
        {(!isWithDrawal || withDrawalType === 'not_found') &&
          <>
            <CardBlock className='col-12 p-0 mt-4' header={<FormattedMessage id='correction_documents_block_title' />} shadow>
              <HelpBlock>
                <FormattedMessage id='correction_documents_communicability_warning_text' />
              </HelpBlock>
              <DocumentsBlock
                onDocumentAdded={onDocumentAdded}
                onDocumentDeleted={onDocumentDeleted}
                onDocumentEdited={onDocumentEdited}
                title={<FormattedMessage id='inscription_mandatory_power_files_label' />}
                documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER) ?? []}
                documentType={DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER}
                transaction={transaction}
              />
              <DocumentsBlock
                onDocumentAdded={onDocumentAdded}
                onDocumentDeleted={onDocumentDeleted}
                onDocumentEdited={onDocumentEdited}
                title={<FormattedMessage id='inscription_other_files_label' />}
                documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_OTHER) ?? []}
                documentType={DOCUMENT_TYPES.INSCRIPTION_OTHER}
                transaction={transaction}
              />
            </CardBlock>
            <div className='col-12'>
              <Suite
                transaction={transaction}
                fieldStatus={fieldStatus}
              />
            </div>
          </>}
      </div>
      {(!isWithDrawal || withDrawalType === 'not_found') && children}
    </div>
  )
}

export default RenunciationTypes
