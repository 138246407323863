export const PAYMENT_METHODS = {
  BLUE_CARD: 'CB',
  INPI_ACCOUNT: 'CCL',
  MANAGEABLE_MEMORY: 'VIR',
  FREE: 'FREE'
}

export const PAYMENT_LABELS = {
  INITIAL: 'Demande initiale',
  OPPOSITION: 'Demande initiale d\'opposition',
  NULLITY: 'Demande initiale de nullité',
  REVOCATION: 'Demande initiale de déchéance',
  REGULARIZATION: 'Régularisation des produits et services',
  INSCRIPTION: 'Demande initiale d\'inscription',
  TRANSFORMATION_TN_TA: 'Transformation TN en TA',
  REVOCATION_STATEMENT: 'Demande de relevé de déchéance'
}

export const PAYMENT_OBJECTS = {
  TRANSACTION: 'transaction',
  NOTIFICATION: 'transaction_notification',
  CORRECTION: 'transaction_correction',
  REGULARIZATION: 'transaction_regularization',
  TRANSFORMATION_TN_TA: 'transaction_transformation_ta'
}

export const PAYMENT_URL_DEPOSIT = 'depots'
export const PAYMENT_URL_OPPOSITION = 'oppositions'
export const PAYMENT_URL_NULLITY = 'nullites'
export const PAYMENT_URL_REVOCATION = 'decheances'
export const PAYMENT_URL_DIVISION = 'divisions'
export const PAYMENT_URL_INSCRIPTION = 'inscriptions'
export const PAYMENT_URL_RENUNCIATION = 'renonciations'
export const PAYMENT_URL_FRMI = 'frmi'
export const PAYMENT_URL_FRMI_POST_OPERATIONS = 'operations-posterieures'
export const PAYMENT_URL_RENEWAL = 'renouvellement'
export const PAYMENT_URL_OFFICIAL_DOCUMENT = 'documents-officiels'
export const PAYMENT_URL_CORRECTION = 'corrections'
export const PAYMENT_URL_NOTIFICATION = 'notifications'
export const PAYMENT_URL_REGULARIZATION = 'regularisations'
export const PAYMENT_URL_TRANSFORMATION_TN_TA = 'transformation'
export const PAYMENT_URL_REVOCATION_STATEMENT = 'releves-de-decheance'
