import { SelectOption } from 'src'
import { FRMILicenceType, FRMIType } from '../interfaces/frmi/FRMI'
import Message from './Message'
import { STATUS_DIVISION_FINAL } from './DivisionConstants'
import { STATUS_ND_FINAL } from './NDConstants'
import { STATUS_INSCRIPTION_FINAL } from './InscriptionConstants'
import { STATUS_RENEWAL_FINAL } from './RenewalConstants'

export const STATUS_FRMI = {
  /** Brouillon client */
  DRAFT_CLIENT: 'draft_client_frmi',
  /** Création */
  DRAFT: 'draft_frmi',
  /** En attente de mémoire administratif **/
  WAITING_VIR_PAYMENT: 'waiting_vir_payment_frmi',
  /** Mémoire administratif expiré */
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired_frmi',
  /** Demandes à attribuer **/
  TO_ATTRIBUTE: 'to_attribute_frmi',
  /** Examen **/
  EXAM: 'exam_frmi',
  /** Suspendu **/
  SUSPENDED: 'suspended_frmi',
  /** Retiré **/
  WITHDRAWN: 'withdrawn_frmi',
  /** Notifié INPI **/
  NOTIFIED_INPI: 'notified_inpi_frmi',
  /** Réponse suite avis INPI **/
  ANSWER_FOLLOWING_OPINION_INPI: 'answer_following_opinion_inpi_frmi',
  /** Réponse suite avis OMPI **/
  ANSWER_FOLLOWING_OPINION_OMPI: 'answer_following_opinion_ompi_frmi',
  /** À transmettre à l'OMPI **/
  TO_TRANSMIT_TO_OMPI: 'to_transmit_to_ompi_frmi',
  /** Rejeté **/
  REJECTED: 'rejected_frmi',
  /** A rejter **/
  TO_REJECT: 'to_reject_frmi',
  /** Marque éteinte **/
  EXPIRED_BRAND: 'expired_brand_frmi',
  /** Transmis OMPI **/
  TRANSMITTED_TO_OMPI: 'transmitted_to_ompi_frmi',
  /** Transmission OMPI KO **/
  TRANSMISSION_OMPI_KO: 'transmission_ompi_ko_frmi',
  /** Notifié OMPI **/
  NOTIFIED_OMPI: 'notified_ompi_frmi',
  /** Enregistré OMPI **/
  REGISTERED_OMPI: 'registered_ompi_frmi',
  /** En cours d'examen OMPI **/
  REVIEWING_OMPI: 'reviewing_ompi_frmi',
  /** Abandonné OMPI **/
  ABANDONED_OMPI: 'abandoned_ompi_frmi'
}

export const STATUS_FRMI_REVIEWING = [
  STATUS_FRMI.WAITING_VIR_PAYMENT,
  STATUS_FRMI.TO_ATTRIBUTE,
  STATUS_FRMI.EXAM,
  STATUS_FRMI.SUSPENDED,
  STATUS_FRMI.NOTIFIED_INPI,
  STATUS_FRMI.ANSWER_FOLLOWING_OPINION_OMPI,
  STATUS_FRMI.ANSWER_FOLLOWING_OPINION_INPI,
  STATUS_FRMI.TO_REJECT,
  STATUS_FRMI.TO_TRANSMIT_TO_OMPI,
  STATUS_FRMI.REVIEWING_OMPI,
  STATUS_FRMI.TRANSMITTED_TO_OMPI,
  STATUS_FRMI.ABANDONED_OMPI
]

export const STATUS_RULE_22_23 = [
  ...STATUS_DIVISION_FINAL,
  ...STATUS_ND_FINAL,
  ...STATUS_INSCRIPTION_FINAL,
  ...STATUS_RENEWAL_FINAL
]

export const STATUS_FRMI_FINAL: string[] = [
  STATUS_FRMI.REGISTERED_OMPI,
  STATUS_FRMI.REJECTED,
  STATUS_FRMI.WITHDRAWN
]

/**
 * TYPE D'EXTENSION
 */

export const FRMI_TYPE_EXTENSION: FRMIType = {
  value: 'FRMI_TYPE_EXTENSION',
  label: Message.frmi_type_extension
}

/**
 * TYPE D'OPERATIONS POSTERIEURES
 */

// Désignation postérieure à l'enregistrement International (MM4)
export const FRMI_TYPE_POST_DESIGNATION_REGISTER: FRMIType = {
  value: 'FRMI_TYPE_POST_DESIGNATION_REGISTER',
  label: Message.frmi_type_post_designation_register
}

// Demande d'inscription de changement de titulaire (MM5)
export const FRMI_TYPE_HOLDER_CHANGE: FRMIType = {
  value: 'FRMI_TYPE_HOLDER_CHANGE',
  label: Message.frmi_type_holder_change
}
// Limitation de la liste des produits et services (MM6)
export const FRMI_TYPE_PRODUCTS_LIMIT: FRMIType = {
  value: 'FRMI_TYPE_PRODUCTS_LIMIT',
  label: Message.frmi_type_products_limit
}
// Renonciation (MM7)
export const FRMI_TYPE_RENUNCIATION: FRMIType = {
  value: 'FRMI_TYPE_RENUNCIATION',
  label: Message.frmi_type_renunciation
}

// Radiation (MM8)
export const FRMI_TYPE_RADIATION: FRMIType = {
  value: 'FRMI_TYPE_RADIATION',
  label: Message.frmi_type_radiation
}

// Modification du nom ou de l’adresse du titulaire, ou de sa forme juridique (MM9)
export const FRMI_TYPE_HOLDER_EDITION: FRMIType = {
  value: 'FRMI_TYPE_HOLDER_EDITION',
  label: Message.frmi_type_holder_edition
}

// Modification du nom ou de l’adresse du mandataire (MM10)
export const FRMI_TYPE_AGENT_EDITION: FRMIType = {
  value: 'FRMI_TYPE_AGENT_EDITION',
  label: Message.frmi_type_agent_edition
}

// Renouvellement de l’enregistrement international (MM11)
export const FRMI_TYPE_RENEW: FRMIType = {
  value: 'FRMI_TYPE_RENEW',
  label: Message.frmi_type_renew
}

// Constitution d’un mandataire (MM12)
export const FRMI_TYPE_AGENT_CONSTITUTE: FRMIType = {
  value: 'FRMI_TYPE_AGENT_CONSTITUTE',
  label: Message.frmi_type_agent_constitute
}

// Licence (MM13)
export const FRMI_TYPE_LICENCE_INSCRIPTION: FRMIType = {
  value: 'FRMI_TYPE_LICENCE_INSCRIPTION',
  label: Message.frmi_type_licence_inscription
}

// Modification de l’inscription d’une Licence (MM14)
export const FRMI_TYPE_LICENCE_EDITION: FRMIType = {
  value: 'FRMI_TYPE_LICENCE_EDITION',
  label: Message.frmi_type_licence_edition
}

// Radiation d’une Licence (MM15)
export const FRMI_TYPE_LICENCE_RADIATION: FRMIType = {
  value: 'FRMI_TYPE_LICENCE_RADIATION',
  label: Message.frmi_type_licence_radiation
}

// Désignation postérieure issue d’une conversion (MM16)
export const FRMI_TYPE_POST_DESIGNATION_CONVERSION: FRMIType = {
  value: 'FRMI_TYPE_POST_DESIGNATION_CONVERSION',
  label: Message.frmi_type_post_designation_conversion,
  hasModal: true
}

// Restriction du droit du titulaire (MM19)
export const FRMI_TYPE_HOLDER_RIGHT_RESTRICTION: FRMIType = {
  value: 'FRMI_TYPE_HOLDER_RIGHT_RESTRICTION',
  label: Message.frmi_type_holder_right_restriction
}

// Main Levée d’une restriction du droit du titulaire (MM19)
export const FRMI_TYPE_HOLDER_RIGHT_DELETE: FRMIType = {
  value: 'FRMI_TYPE_HOLDER_RIGHT_DELETE',
  label: Message.frmi_type_holder_right_delete
}

// Requête en poursuite de la procédure (MM20)
export const FRMI_TYPE_CONTINUATION_REQUEST: FRMIType = {
  value: 'FRMI_TYPE_CONTINUATION_REQUEST',
  label: Message.frmi_type_continuation_request,
  hasModal: true
}

// Rectification d’une inscription (MM21)
export const FRMI_TYPE_INSCRIPTION_CORRECTION: FRMIType = {
  value: 'FRMI_TYPE_INSCRIPTION_CORRECTION',
  label: Message.frmi_type_inscription_correction
}

// Division d’un enregistrement International (MM22)
export const FRMI_TYPE_DIVISION_INTERNATIONAL: FRMIType = {
  value: 'FRMI_TYPE_DIVISION_INTERNATIONAL',
  label: Message.frmi_type_division_international
}

// Division d’un enregistrement National (MM22- bis)
export const FRMI_TYPE_DIVISION_NATIONAL: FRMIType = {
  value: 'FRMI_TYPE_DIVISION_NATIONAL',
  label: Message.frmi_type_division_national
}

// Fusion d’enregistrements internationaux (MM23) issus de l’inscription d’un changement partiel de titulaire
export const FRMI_TYPE_FUSION_FROM_HOLDER_EDITION: FRMIType = {
  value: 'FRMI_TYPE_FUSION_FROM_HOLDER_EDITION',
  label: Message.frmi_type_fusion_from_holder_edition
}

// Fusion d’un enregistrement international issu de l’inscription d’une division(MM24)
export const FRMI_TYPE_FUSION_FROM_DIVISION_INSCRIPTION: FRMIType = {
  value: 'FRMI_TYPE_FUSION_FROM_DIVISION_INSCRIPTION',
  label: Message.frmi_type_fusion_from_division_inscription
}

// Cessation des effets de l’enregistrement international dû à la cessation des effets de la demande de base (R22)
export const FRMI_TYPE_EFFECT_STOP: FRMIType = {
  value: 'FRMI_TYPE_EFFECT_STOP',
  label: Message.frmi_type_effect_stop
}

// Invalidation (IJ)
export const FRMI_TYPE_NULLIFICATION: FRMIType = {
  value: 'FRMI_TYPE_NULLIFICATION',
  label: Message.frmi_type_nullification
}

// Invalidation (IJ)
export const FRMI_TYPE_NATIONAL_REPLACEMENT: FRMIType = {
  value: 'FRMI_TYPE_NATIONAL_REPLACEMENT',
  label: Message.frmi_type_national_replacement
}

/**
 * Tous les types d'opérations
 */
export const FRMI_OPERATIONS_TYPES: FRMIType[] = [
  FRMI_TYPE_POST_DESIGNATION_REGISTER, FRMI_TYPE_HOLDER_CHANGE, FRMI_TYPE_PRODUCTS_LIMIT,
  FRMI_TYPE_RENUNCIATION, FRMI_TYPE_RADIATION, FRMI_TYPE_HOLDER_EDITION,
  FRMI_TYPE_AGENT_EDITION, FRMI_TYPE_RENEW, FRMI_TYPE_AGENT_CONSTITUTE,
  FRMI_TYPE_LICENCE_INSCRIPTION, FRMI_TYPE_LICENCE_EDITION, FRMI_TYPE_LICENCE_RADIATION,
  FRMI_TYPE_POST_DESIGNATION_CONVERSION, FRMI_TYPE_HOLDER_RIGHT_RESTRICTION, FRMI_TYPE_HOLDER_RIGHT_DELETE,
  FRMI_TYPE_CONTINUATION_REQUEST, FRMI_TYPE_INSCRIPTION_CORRECTION, FRMI_TYPE_DIVISION_INTERNATIONAL,
  FRMI_TYPE_DIVISION_NATIONAL, FRMI_TYPE_FUSION_FROM_HOLDER_EDITION, FRMI_TYPE_FUSION_FROM_DIVISION_INSCRIPTION,
  FRMI_TYPE_EFFECT_STOP, FRMI_TYPE_NULLIFICATION, FRMI_TYPE_NATIONAL_REPLACEMENT
]

/**
 * Type d'extension et d'opérations
 */

export const FRMI_TYPES: SelectOption[] = [
  FRMI_TYPE_EXTENSION, ...FRMI_OPERATIONS_TYPES
]

/**
 * Type de licence
 */

export const FRMI_LICENCE_TYPE_EXCLUSIVE: FRMILicenceType = {
  value: 'EXCLUSIVE',
  label: Message.frmi_licence_type_exclusive,
  description: Message.frmi_licence_type_exclusive_description
}

export const FRMI_LICENCE_TYPE_UNIQUE: FRMILicenceType = {
  value: 'UNIQUE',
  label: Message.frmi_licence_type_unique,
  description: Message.frmi_licence_type_unique_description
}

export const FRMI_LICENCE_TYPES: FRMILicenceType[] = [
  FRMI_LICENCE_TYPE_EXCLUSIVE,
  FRMI_LICENCE_TYPE_UNIQUE
]

/**
 * Type de date d'effet
 */
export const FRMI_EFFECT_DATE_TYPE_VALIDATION: SelectOption = {
  value: 'VALIDATION',
  label: Message.frmi_effect_date_type_validation
}

export const FRMI_EFFECT_DATE_TYPE_RENEWAL: SelectOption = {
  value: 'RENEWAL',
  label: Message.frmi_effect_date_type_renewal
}

export const FRMI_EFFECT_DATE_TYPE_MODIFICATION_INSCRIPTION: SelectOption = {
  value: 'MODIFICATION_INSCRIPTION',
  label: Message.frmi_effect_date_type_modification_inscription
}

export const FRMI_EFFECT_DATE_TYPES : SelectOption[] = [
  FRMI_EFFECT_DATE_TYPE_VALIDATION,
  FRMI_EFFECT_DATE_TYPE_RENEWAL,
  FRMI_EFFECT_DATE_TYPE_MODIFICATION_INSCRIPTION
]

/**
 * Type de décision
 */

export const FRMI_DECISION_TYPE_FROM_THIRD_PARTY: SelectOption = {
  value: 'FROM_THIRD_PARTY',
  label: Message.frmi_decision_type_from_third_party
}

export const FRMI_DECISION_TYPE_NOT_FROM_THIRD_PARTY: SelectOption = {
  value: 'NOT_FROM_THIRD_PARTY',
  label: Message.frmi_decision_type_not_from_third_party
}

export const FRMI_DECISION_TYPE_PARTIAL_WITHDRAW: SelectOption = {
  value: 'PARTIAL_WITHDRAW',
  label: Message.frmi_decision_type_partial_withdraw
}

export const FRMI_DECISION_TYPE_TOTAL_WITHDROW: SelectOption = {
  value: 'TOTAL_WITHDROW',
  label: Message.frmi_decision_type_total_withdraw
}

export const FRMI_DECISION_TYPES: SelectOption[] = [
  FRMI_DECISION_TYPE_FROM_THIRD_PARTY,
  FRMI_DECISION_TYPE_NOT_FROM_THIRD_PARTY,
  FRMI_DECISION_TYPE_PARTIAL_WITHDRAW,
  FRMI_DECISION_TYPE_TOTAL_WITHDROW
]

/**
 * Sous-type de radiation partielle pour les produits et service
 */

export const FRMI_PARTIAL_RADIATION_SUBTYPE_WITH_IMPACT: SelectOption = {
  value: 'WITH_IMPACT',
  label: Message.frmi_partial_radiation_subtype_with_impact
}

export const FRMI_PARTIAL_RADIATION_SUBTYPE_WITHOUT_IMPACT: SelectOption = {
  value: 'WITHOUT_IMPACT',
  label: Message.frmi_partial_radiation_subtype_without_impact
}

export const FRMI_PARTIAL_RADIATION_SUBTYPES: SelectOption[] = [
  FRMI_PARTIAL_RADIATION_SUBTYPE_WITH_IMPACT,
  FRMI_PARTIAL_RADIATION_SUBTYPE_WITHOUT_IMPACT
]

/** Valeure par défaut de l'office qui envoie la notification */
export const FRMI_DEFAULT_OFFICE_NAME = 'INPI FRANCE'

export const PRODUCTS_EXTENSION_TYPE_TOTAL = 'TOTAL'
export const PRODUCTS_EXTENSION_TYPE_PARTIAL = 'PARTIAL'

/**
 * Type d'etendue des produits et service du changement de titulaire
 */

export const FRMI_HOLDER_CHANGE_EXTENSION_TYPE_TOTAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_TOTAL,
  label: Message.frmi_holder_change_extension_type_total
}

export const FRMI_HOLDER_CHANGE_EXTENSION_TYPE_PARTIAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_PARTIAL,
  label: Message.frmi_holder_change_extension_type_partial
}

/**
 * Type d'étendue d'une inscription de changement de titulaire
 */

export const FRMI_HOLDER_CHANGE_EXTENSION_TYPES: SelectOption[] = [
  FRMI_HOLDER_CHANGE_EXTENSION_TYPE_TOTAL,
  FRMI_HOLDER_CHANGE_EXTENSION_TYPE_PARTIAL
]

/**
 * Type d'etendue des produits et service du remplacement
 */
export const FRMI_REPLACEMENT_TYPE_PARTIAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_PARTIAL,
  label: Message.frmi_replacement_type_partial
}

export const FRMI_REPLACEMENT_TYPE_TOTAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_TOTAL,
  label: Message.frmi_replacement_type_total
}

export const FRMI_REPLACEMENT_TYPES: SelectOption[] = [
  FRMI_REPLACEMENT_TYPE_TOTAL,
  FRMI_REPLACEMENT_TYPE_PARTIAL
]

/**
 * Type d'etendue des produits et service d'une invalidation
 */

export const FRMI_INVALIDATION_TYPE_TOTAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_TOTAL,
  label: Message.frmi_invalidation_type_total
}

export const FRMI_INVALIDATION_TYPE_PARTIAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_PARTIAL,
  label: Message.frmi_invalidation_type_partial
}

export const FRMI_INVALIDATION_TYPES: SelectOption[] = [
  FRMI_INVALIDATION_TYPE_TOTAL,
  FRMI_INVALIDATION_TYPE_PARTIAL
]

/**
 * Type d'etendue des produits et service d'une radiation
 */

export const FRMI_RADIATION_TYPE_TOTAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_TOTAL,
  label: Message.frmi_radiation_type_total
}

export const FRMI_RADIATION_TYPE_PARTIAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_PARTIAL,
  label: Message.frmi_radiation_type_partial
}

export const FRMI_RADIATION_TYPES: SelectOption[] = [
  FRMI_RADIATION_TYPE_TOTAL,
  FRMI_RADIATION_TYPE_PARTIAL
]

/**
 * Type d'etendue des produits et service d'une licence
 */

export const FRMI_LICENCE_PRODUCTS_EXTENSION_TYPE_TOTAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_TOTAL,
  label: Message.frmi_licence_products_extension_type_total
}

export const FRMI_LICENCE_PRODUCTS_EXTENSION_TYPE_PARTIAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_PARTIAL,
  label: Message.frmi_licence_products_extension_type_partial
}

export const FRMI_LICENCE_PRODUCTS_EXTENSION_TYPES: SelectOption[] = [
  FRMI_LICENCE_PRODUCTS_EXTENSION_TYPE_TOTAL,
  FRMI_LICENCE_PRODUCTS_EXTENSION_TYPE_PARTIAL
]

/**
 * Type d'etendue des pays d'une licence
 */

export const FRMI_LICENCE_COUNTRIES_EXTENSION_TYPE_TOTAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_TOTAL,
  label: Message.frmi_licence_countries_extension_type_total
}

export const FRMI_LICENCE_COUNTRIES_EXTENSION_TYPE_PARTIAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_PARTIAL,
  label: Message.frmi_licence_countries_extension_type_partial
}

export const FRMI_LICENCE_COUNTRIES_EXTENSION_TYPES: SelectOption[] = [
  FRMI_LICENCE_COUNTRIES_EXTENSION_TYPE_TOTAL,
  FRMI_LICENCE_COUNTRIES_EXTENSION_TYPE_PARTIAL
]

/**
 * Type d'etendue d'un renouvellement d'enregistrement international
 */

export const FRMI_RENEW_COUNTRIES_EXTENSION_TYPE_TOTAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_TOTAL,
  label: Message.frmi_renew_countries_extension_type_total
}

export const FRMI_RENEW_COUNTRIES_EXTENSION_TYPE_PARTIAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_PARTIAL,
  label: Message.frmi_renew_countries_extension_type_partial
}

export const FRMI_RENEW_COUNTRIES_EXTENSION_TYPES: SelectOption[] = [
  FRMI_RENEW_COUNTRIES_EXTENSION_TYPE_TOTAL,
  FRMI_RENEW_COUNTRIES_EXTENSION_TYPE_PARTIAL
]

/**
 * Type d'etendue d'une restriction du droit de titulaire
 */

export const FRMI_HOLDER_RIGHT_RESTRICTION_COUNTRIES_EXTENSION_TYPE_TOTAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_TOTAL,
  label: Message.frmi_holder_right_restriction_countries_extension_type_total
}

export const FRMI_HOLDER_RIGHT_RESTRICTION_COUNTRIES_EXTENSION_TYPE_PARTIAL: SelectOption = {
  value: PRODUCTS_EXTENSION_TYPE_PARTIAL,
  label: Message.frmi_holder_right_restriction_countries_extension_type_partial
}

export const FRMI_HOLDER_RIGHT_RESTRICTION_COUNTRIES_EXTENSION_TYPES: SelectOption[] = [
  FRMI_HOLDER_RIGHT_RESTRICTION_COUNTRIES_EXTENSION_TYPE_TOTAL,
  FRMI_HOLDER_RIGHT_RESTRICTION_COUNTRIES_EXTENSION_TYPE_PARTIAL
]

/**
 * Nom des attributs pour l'extension des pays et P&S
 */
export const FRMI_PRODUCTS_EXTENSION_TYPE_NAME = 'productsExtensionType'
export const FRMI_COUNTRIES_EXTENSION_TYPE_NAME = 'countriesExtensionType'
