import React, { FC } from 'react'
import { ModalComponent, SubmitButton } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import ContactService from '../../services/contact/ContactService'
import { Contact } from '../../interfaces/Contact'
import { toast } from 'react-toastify'
import Message from '../../constants/Message'

interface ModalContactErrorProps {
  showModal: boolean,
  setShowModal: (show: boolean) => void,
  isMultipleContact?: boolean,
  updateList?: () => void,
  contact?: Contact,
  contactIdToReplace?: number,
  addressBookFile?: File,
}

const ModalContactError : FC <ModalContactErrorProps> = (
  {
    showModal,
    setShowModal,
    isMultipleContact = false,
    updateList,
    contact,
    contactIdToReplace,
    addressBookFile
  }) => {
  /**
   * Modifie un contact
   */
  const modifyContact = () => {
    ContactService.modifyContact(contact, contactIdToReplace).then(response => {
      if (response) {
        toast.success(Message.success_modify_contact)
        updateList && updateList()
      }
    })
  }

  /**
   * Force ajout d'un contact
   */
  const forceAddContact = () => {
    ContactService.addContact(contact, true).then((response) => {
      if (response && !('errorCode' in response)) {
        toast.success(Message.success_add_contact)
        updateList && updateList()
      }
    })
  }

  const forceContact = (forceCreate?: boolean, forceUpdate?: boolean) => {
    if (addressBookFile) {
      ContactService.importAddressBook(addressBookFile, forceUpdate, forceCreate).then((response) => {
        if (!('errorCode' in response)) {
          if ('nbErrors' in response && response.nbErrors) {
            toast.error(Message.import_contact_error)
          } else {
            toast.success(Message.success_import_contact)
            updateList && updateList()
          }
        }
      })
    }
  }

  /**
   * Fonction pour afficher la popin demandant de choisir entre ajouter ou remplacer le/les intervenants déjà existants
   */
  const modalAddOrReplaceContact = () => {
    return (
      <div className='row'>
        <span className='col col-12'><FormattedMessage id={isMultipleContact ? 'popin_multiple_already_exist_contact' : 'error_contact_already_exist'} /></span>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <SubmitButton
            className='btn btn-outline-secondary mt-4 mr-4'
            onClick={() => {
              if (isMultipleContact) {
                forceContact(true, false)
              } else {
                forceAddContact()
              }
              setShowModal(false)
            }}
          >
            <FormattedMessage id={isMultipleContact ? 'button_add_all_contact' : 'button_add_one_contact'} />
          </SubmitButton>
          <SubmitButton
            className='btn btn-outline-primary mt-4'
            onClick={() => {
              if (isMultipleContact) {
                forceContact(false, true)
              } else {
                modifyContact()
              }
              setShowModal(false)
            }}
          >
            <FormattedMessage id={isMultipleContact ? 'button_replace_all_contact' : 'button_replace_one_contact'} />
          </SubmitButton>
        </div>
      </div>
    )
  }

  return (
    <ModalComponent
      title={<FormattedMessage id={isMultipleContact ? 'import_contact_error' : 'add_contact_error'} />}
      customContent={() => modalAddOrReplaceContact()}
      handleClose={() => setShowModal(false)}
      show={showModal}
      hideFooter
    />
  )
}

export default ModalContactError
