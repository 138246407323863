import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { Contributor, ContributorsOptionsForm } from '../interfaces/contributors/ContributorsInterfaces'
import {
  EventType,
  FieldStatus, FRMI_TYPE_EXTENSION,
  Language, PROCEDURE_FRMI,
  SelectField, Transaction, UE
} from '../index'
import React, { Dispatch, FC, useEffect, useState } from 'react'
import { Country } from '../interfaces/frmi/FRMI'

interface OtherContributorFieldsProps extends WrappedComponentProps {
  transaction: Transaction
  contributor?: Contributor,
  updateContributor: (newContributor: Contributor) => void,
  handleChanges: (event: EventType) => void,
  fieldStatus?: FieldStatus | any,
  dispatch?: Dispatch<any>,
  resetError?: (fieldStatus: FieldStatus) => void,
  title?: string | undefined,
  propName?: string | undefined,
  subtitle?: string | undefined,
  getLanguages: () => Promise<Language[]>,
  options?: ContributorsOptionsForm,
  isFirst?: boolean
}

const OtherContributorFields: FC<OtherContributorFieldsProps> = ({
  transaction,
  contributor,
  updateContributor,
  handleChanges,
  fieldStatus,
  intl,
  resetError,
  getLanguages,
  options,
  isFirst
}) => {
  const [languages, setLanguages] = useState<Language[]>([])
  const [hasOptions, setHasOptions] = useState(true)

  useEffect(() => {
    (options?.hasOMPILanguageSelect || options?.hasOtherLanguageSelect) && getLanguages && getLanguages()
      .then((languages: Language[]) => setLanguages(languages))
    // Si plusieurs titulaires pour une demande d'extension à l'international,
    // et le premier a une seconde langue de travail on met la même langue aux autres titulaires
    if (transaction.procedureType === PROCEDURE_FRMI.value &&
        transaction.subProcedureType === FRMI_TYPE_EXTENSION.value &&
        transaction.depositors && transaction.depositors.length > 0 &&
        !!transaction.depositors[0].otherLanguage && contributor?.id !== transaction.depositors[0].id) {
      setHasOptions(false)
      updateContributor({ ...contributor, otherLanguage: transaction.depositors[0].otherLanguage })
    }
  }, [])

  return (
    <div className='flex-column d-flex w-100'>
      <div className='row'>
        {options?.hasOMPILanguageSelect && getLanguages && isFirst && (
          <SelectField
            classNameFormGroup='col-12 col-md-4'
            label={<FormattedMessage id='OMPI_language' />}
            inputId='OMPILanguage'
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            value={contributor?.OMPILanguage}
            onChange={handleChanges}
            resetError={resetError}
            fieldStatus={fieldStatus}
            options={languages.filter((language: Language) => language.showOMPILanguage).map((language: Language) => {
              return {
                label: language.name,
                value: language.code
              }
            })}
            required
          />
        )}

        {options?.hasOtherLanguageSelect && transaction?.frmiDetails?.countries?.find((country: Country) => country.code === UE) && isFirst && (
          <SelectField
            label={<FormattedMessage id='other_language_ue' />}
            classNameFormGroup='col-12 col-md-4'
            inputId='otherLanguage'
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            value={contributor?.otherLanguage}
            onChange={handleChanges}
            fieldStatus={fieldStatus}
            resetError={resetError}
            options={languages.filter((language: Language) => hasOptions ? language.showUEOtherLanguage : language.code === transaction.depositors[0].otherLanguage).map((language: Language) => {
              return {
                label: language.name,
                value: language.code
              }
            })}
            required={transaction?.frmiDetails?.countries?.some((country: Country) => country.code === UE)}
          />
        )}
      </div>
    </div>
  )
}
export default injectIntl(OtherContributorFields)
