
import {
  CardBlock,
  containsErrors,
  Contributor, CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_PAYMENT,
  ContributorForm,
  ContributorValidator,
  Country,
  DEPOSIT_CONTRIBUTORS_OPTIONS,
  DEPOSIT_CONTRIBUTORS_TYPES,
  ETS_ACCOUNT_STATE_ACTIVE, ETSAccount,
  FieldStatus,
  ManageableQuality,
  ModalComponent,
  OverviewContributor, PROCEDURE_OFFICIAL_DOCUMENT,
  SubmitButton,
  Transaction,
  TransactionService
} from '@inpi-marques/components'
import { PAYMENT_METHODS } from 'constants/PaymentConstants'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useSelector } from 'react-redux'
import ContentService from 'services/content/ContentService'
import ContributorService from 'services/contributors/ContributorService'
import { PaymentParameter } from 'services/payment/PaymentService'

interface PaymentBlueCardFormProps {
    transaction: Transaction
    onPaymentSubmit: (paymentParameter: PaymentParameter) => void,
    disabled?: boolean
}

const PaymentBlueCardForm: FC<PaymentBlueCardFormProps> = ({ transaction, onPaymentSubmit, disabled }) => {
  const [payer, setPayer] = useState<Contributor>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [customContributor, setCustomContributor] = useState<Contributor>()

  const user = useSelector((state : RootStateOrAny) => state.user.user)

  const [countries, setCountries] = useState<Country[]>([])
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>([])

  useEffect(() => {
    let payerContributor
    if (transaction.recipient || transaction.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value) {
      if (transaction.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value && transaction.depositors?.length) {
        payerContributor = transaction.depositors?.[0]
      } else {
        payerContributor = transaction.recipient
      }
    } else {
      const etsActive = transaction.ETSAccounts.find((etsAccount: ETSAccount) => etsAccount.state === ETS_ACCOUNT_STATE_ACTIVE)
      payerContributor = etsActive
    }

    payerContributor && setPayer(payerContributor)

    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })
    ContentService.getManageableQualities(TransactionService.getAdministrableContentProcedureType(transaction)).then((response: ManageableQuality[]) => {
      setManageableQualities(response)
    })
  }, [])

  const optionsForm = DEPOSIT_CONTRIBUTORS_OPTIONS.paymentContributor
  const [customFieldStatus, setCustomFieldStatus] = useState<FieldStatus>()

  const onModalSubmit = (): void => {
    const fieldStatus: FieldStatus = ContributorValidator.validateContributor(customContributor, transaction, CONTRIBUTOR_DEPOSITORS.value)
    fieldStatus.nationality = ''
    if (!containsErrors(fieldStatus)) {
      setPayer(customContributor)
      setShowModal(false)
    }
    setCustomFieldStatus(fieldStatus)
  }

  return (
    <div className='payment-choices h-100'>
      <CardBlock
        bodyClassName='py-3'
        shadow
        header={<FormattedMessage id='payment_bank_card' />}
      >
        <div className='row justify-content-center flex-grow-1'>
          <div className='col-8'>
            <div className='text-gris'>
              <div><FormattedMessage id='payment_payer' /></div>
            </div>
            {payer && <OverviewContributor contributor={payer} countries={countries} />}
            <button
              disabled={disabled}
              className='btn btn-link-primary mt-2'
              onClick={() => setShowModal(true)}
            >
              <FormattedMessage id='payment_not_payer' />
            </button>
          </div>
          <div className='col-4 d-flex flex-column'>
            <img src='/img/visa-logo.png' alt='' className='img-fluid mb-3 img-payment' />
            <img src='/img/mc-logo.png' alt='' className='img-fluid mt-1 img-payment' />
          </div>
          <div className='d-flex align-self-end justify-content-center'>
            <SubmitButton
              disabled={disabled}
              className='btn-block btn-primary w-25 mt-4 mb-0'
              onClick={() => onPaymentSubmit({ method: PAYMENT_METHODS.BLUE_CARD, payer })}
            >
              <FormattedMessage id='payment_access_button' />
            </SubmitButton>
          </div>
        </div>
      </CardBlock>

      <ModalComponent
        title={<FormattedMessage id='payment_create_payer' />}
        show={showModal}
        customContent={() =>
          <div className='form-intervenant is-validated'>

            <ContributorForm
              transaction={transaction}
              optionsForm={optionsForm}
              contributorType={CONTRIBUTOR_PAYMENT.value}
              contributor={customContributor}
              text={{ title: <FormattedMessage id='payment_payer' /> }}
              contributorsToCopy={ContributorService.findAllDistinctContributors(transaction,
                [
                  DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]
              )}
              currentETSUser={optionsForm.hasAutoCopy ? ContributorService.buildContributorFromUser(user) : undefined}
              setContributor={setCustomContributor}
              fieldStatus={customFieldStatus}
              getCountries={ContentService.getCountries}
              manageableQualities={manageableQualities}
            />
          </div>}
        handleClose={() => setShowModal(false)}
        size='xl'
        hideFooter={false}
        validateLabel='Enregistrer'
        onClick={onModalSubmit}
      />
    </div>
  )
}

export default PaymentBlueCardForm
