import Message from '../../../constants/Message'
import { ETSAccountNavInterface, ETSAccountRequest } from '../../../interfaces/ETSAccountInterface'
import {
  FieldStatus,
  isFilled,
  ManageableQuality,
  messageIsFilled,
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION
} from '@inpi-marques/components'

class ETSAccountRequestValidator {
    /**
     * Valide le formulaire de demande de modification sur les comptes ETS à partir de l'action passée
     * @param etsAccountRequest
     * @param ETSAccountRequestNavItem
     */
    validateForm = (etsAccountRequest: ETSAccountRequest, ETSAccountRequestNavItem: ETSAccountNavInterface) => {
      const fieldStatus: FieldStatus = {}
      if (!isFilled(etsAccountRequest.procedure)) {
        fieldStatus.procedure = Message.required_field
      }
      if (!isFilled(etsAccountRequest.numNat)) {
        fieldStatus.numNat = Message.required_field
      }
      if (ETSAccountRequestNavItem.showConsentNotificationBlock && etsAccountRequest.consentNotificationByEmail == null) {
        fieldStatus.consentNotificationByEmail = Message.required_field
      }

      if (etsAccountRequest.code !== 'HOLDER') {
        this.valideAgentChange(etsAccountRequest, fieldStatus)

        if (etsAccountRequest.procedure === PROCEDURE_OPPOSITION.value || etsAccountRequest.procedure === PROCEDURE_NULLITY.value ||
            etsAccountRequest.procedure === PROCEDURE_REVOCATION.value) {
          fieldStatus.contestedRequestNumnat = messageIsFilled(etsAccountRequest.contestedRequestNumNat)
          fieldStatus.code = messageIsFilled(etsAccountRequest.code)
        }
      }

      return fieldStatus
    }

    valideAgentChange = (etsAccountRequest: ETSAccountRequest, fieldStatus: FieldStatus) => {
      const manageableQuality : ManageableQuality|null|undefined = etsAccountRequest.manageableQuality
      if (!manageableQuality) {
        fieldStatus.manageableQuality = Message.required_field
      }

      if (manageableQuality?.numberNeeded && !isFilled(etsAccountRequest.inscriptionNumber)) {
        fieldStatus.inscriptionNumber = Message.required_field
      }

      if (manageableQuality?.powerNeeded && !etsAccountRequest.powerFile) {
        fieldStatus.powerFile = Message.required_field
      }
    }
}

export default new ETSAccountRequestValidator()
