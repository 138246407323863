import React, { FC, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
export interface StepListItemContent {
  /** Identifiant de l'étape, doit être unique dans toute la liste */
  id: string,

  /** Contenu à afficher dans la liste d'étapes */
  label: ReactNode,

  /** Numéro de l'étape à afficher */
  displayedIndex?: number

  /** Profondeur de l'étape */
  level?: number,

  /** Permet de savoir si l'on affiche ou non cet item */
  condition?: boolean

  className?: string,

  /** Savoir si on doit afficher le bouton du mode guidé */
  hasGuidedSwitch?: boolean
}

export interface StepListItemProps extends StepListItemContent {
  idActive: string,
  index: number,
  onClick?: (index: number) => void,
}

const StepListItem: FC<StepListItemProps> = ({
  id,
  idActive,
  label,
  level = 0,
  index,
  displayedIndex,
  className,
  onClick = () => {}
}) => (
  <div
    className={`list-action cursor-pointer ${idActive.startsWith(id) ? 'active' : ''} level-${level} ${className ?? ''}`}
    onClick={() => onClick(index)}
  >
    <div className='list-action__text d-flex flex-row align-items-center text-break'>
      {displayedIndex && <span className='index'>{displayedIndex}</span>}
      {label}
    </div>
    {idActive === id && (
      <span className='float-right icone'>
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    )}
  </div>
)

export default StepListItem
