import React, { FC } from 'react'
import {
  Table,
  Record, DateUtils, Country, ProductService, ProductClass, SubmitButton, CardBlock
} from '@inpi-marques/components'
import ContributorService from '@inpi-marques/components/src/services/ContributorService'
import { TABLE_RECORD_LIST } from '../constants/RecordListTableConstants'
import { IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ProductAndServiceListView from '../products/ProductAndServiceListView'

interface RecordListTableProps {
  records: Record[],
  onSelectRecord: (index : number) => void,
  countries: Country[],
  onSelectProductsClasses: (index : number) => void,
  selectedProductsClasses: {rowIndex: number, productClasses: ProductClass[]}[]
}

const RecordListTable: FC<RecordListTableProps> = ({
  records,
  onSelectRecord,
  countries,
  onSelectProductsClasses,
  selectedProductsClasses
}) =>
  <IntlProvider locale='fr' messages={Message}>
    <Table
      tableTitle={TABLE_RECORD_LIST}
      colorHover
    >
      <tbody>
        {
          records?.map((record, index) => {
            const productClasses: ProductClass[]|undefined = selectedProductsClasses.find((selected: {rowIndex: number, productClasses: ProductClass[]}) => selected.rowIndex === index)?.productClasses
            const classNumber: string = ProductService.getConcatenatedClassNumbers(ProductService.getCurrentVersion(record.details?.productsAndServicesVersions)?.productClasses ?? []) ?? ''
            return [(
              <tr key={index} className='cursor-pointer' onClick={() => onSelectRecord(index)}>
                <td>{record.numNat}</td>
                <td>{record.details?.brand?.text}</td>
                <td>{countries.find(item => item.code === record.country)?.name || record.country}</td>
                <td>{DateUtils.formatDateFr(record.depositDate)}</td>
                <td>{record.holders?.map((holder, index) =>
                    `${index ? ', ' : ''}${ContributorService.getName(holder)}`
                  )}
                </td>
                <td>
                  {classNumber}
                  {classNumber &&
                    <SubmitButton className='btn-icon' onClick={() => onSelectProductsClasses(index)}>
                      <FontAwesomeIcon icon={productClasses ? faChevronDown : faChevronRight} className='text-primary' />
                    </SubmitButton>}
                </td>
              </tr>
            ), (
              !!productClasses &&
                <tr key={`${index}-products`}>
                  <td colSpan={6}>
                    <CardBlock shadow className='mt-3'>
                      <ProductAndServiceListView
                        productClasses={productClasses}
                        readOnly
                      />
                    </CardBlock>
                  </td>
                </tr>
            )]
          })
        }
      </tbody>
    </Table>
  </IntlProvider>

export default RecordListTable
