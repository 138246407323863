import store from '../../../../../store/store'
import {
  containsErrors,
  isFilled,
  TransactionDocument,
  Brand,
  Deposit,
  FieldStatus,
  FIELD_LIMITS,
  isTextLatin
} from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import Message from 'constants/Message'
import { BRAND_TYPE_COLOR, BRAND_TYPE_FIGURATIVE, BRAND_TYPE_WORD } from 'constants/BrandConstants'
import { DOCUMENT_LIMITS } from '@inpi-marques/components/src/constants/DocumentConstants'

class BrandTypeValidator {
  isTransactionDocumentSizeValid = (document: TransactionDocument):boolean => {
    return !!(document.file && document.file.size < DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE)
  }

  getValidatedFieldStatus = (documents: TransactionDocument[], brand?: Brand): FieldStatus => {
    const depositFieldStatus: FieldStatus = {}

    /** Si le type de marque n'est pas renseigné */
    if (!brand || !brand.type) {
      depositFieldStatus.brandType = Message.brand_type_required
      return depositFieldStatus
    }

    if (brand.type === BRAND_TYPE_WORD.value) {
      if (!isFilled(brand.text)) {
        depositFieldStatus.brandText = Message.brand_type_required
      } else {
        if (!isTextLatin(brand.text)) {
          depositFieldStatus.brandText = Message.brand_text_not_latin
        }
      }
      if (brand.hasTranslation && !isFilled(brand.translation)) {
        depositFieldStatus.brandTranslation = Message.brand_type_translation_required
      }
      if (brand.hasTranslation && !isTextLatin(brand.translation)) {
        depositFieldStatus.brandTranslation = Message.text_not_latin
      }
    }

    if (brand.type !== BRAND_TYPE_WORD.value) {
      if (!brand.file) {
        depositFieldStatus.brandDocument = Message.brand_file_required
      }

      if (((brand.type === BRAND_TYPE_FIGURATIVE.value && brand.hasColors) || brand.type === BRAND_TYPE_COLOR.value) && !brand.colors) {
        depositFieldStatus.brandColors = Message.brand_colors_required
      }
      if (brand.colors && !isTextLatin(brand.colors)) {
        depositFieldStatus.brandColors = Message.text_not_latin
      }
    }

    if (brand.description?.length && brand.description.length > FIELD_LIMITS.BRAND_DESCRIPTION_MAX_CHAR) {
      depositFieldStatus.brandDescription = Message.brand_description_size_error
    }
    if (brand.description && !isTextLatin(brand.description)) {
      depositFieldStatus.brandDescription = Message.text_not_latin
    }

    return depositFieldStatus
  }

  validateOnChangeStep = (deposit: Deposit, documents: TransactionDocument[]) => {
    const fieldStatus: FieldStatus = this.getValidatedFieldStatus(documents, deposit.brand)
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new BrandTypeValidator()
