import {
  AssociatedBrand,
  AssociatedBrands,
  CardBlock,
  DOCUMENT_ADDITIONAL_TYPES,
  DocumentsBlock,
  DocumentType,
  downloadFile,
  FieldStatus,
  RENEWAL_TYPE_PARTIAL,
  Title,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import DocumentService from 'services/document/DocumentService'
import ProtectionExtensionRenewal from './ProtectionExtensionRenewal'
import InternationalExtensionsOptions from '../../../division/form/options/InternationalExtensionsOptions'
import StoreService from 'services/StoreService'
import SimultaneousInscription from './SimultaneousInscription'

interface RenewalOptionsProps {
    transaction: Transaction,
    documents: TransactionDocument[],
    fieldStatus: FieldStatus,
    documentTypes: DocumentType[]
    onDocumentEdited: (document: TransactionDocument) => void,
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument) => void
}
const RenewalOptions: FC<RenewalOptionsProps> = ({ transaction, documents, fieldStatus, documentTypes, onDocumentAdded, onDocumentDeleted, onDocumentEdited }) => {
  const handleDownload = (document:TransactionDocument) => {
    transaction?.id && document.internalName && DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  const onAssociatedBrandsUpdated = (associatedBrands: AssociatedBrand[]): void => {
    StoreService.changeStore(
      {
        ...transaction,
        renewalDetails: {
          ...transaction.renewalDetails,
          associatedBrands
        }
      }
    )
  }

  const onSimultaneousInscriptionUpdated = (hasSimultaneousInscription: boolean): void => {
    StoreService.changeStore(
      {
        ...transaction,
        renewalDetails: {
          ...transaction.renewalDetails,
          hasSimultaneousInscription
        }
      }
    )
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='renewal_demand_details' /></h1>
          <span className='subtitle'><FormattedMessage id='renewal_demand_details_subtitle' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <ProtectionExtensionRenewal
        transaction={transaction}
      />
      <div>
        {transaction?.renewalDetails?.type === RENEWAL_TYPE_PARTIAL.value &&
          <CardBlock
            shadow
            className='mt-4'
            bodyClassName='pb-2 pt-3'
            header={<FormattedMessage id='international_extension_title' />}
          >
            <InternationalExtensionsOptions
              transaction={transaction}
              numNat={transaction?.renewalDetails?.titles?.[0]?.numNat}
              titlesIds={transaction?.renewalDetails?.titles?.map((title: Title) => title.id)}
            />
          </CardBlock>}
        <CardBlock
          shadow
          className='mt-4'
          bodyClassName='pb-2 pt-3'
          header={<FormattedMessage id='options_renewal_simultaneous_inscription_title' />}
        >
          <SimultaneousInscription
            transaction={transaction}
            onSimultaneousInscriptionUpdated={onSimultaneousInscriptionUpdated}
          />
        </CardBlock>
        <CardBlock
          shadow
          className='mt-4'
          bodyClassName='pb-2 pt-3'
          header={<FormattedMessage id='options_renewal_associated_brands_title' />}
        >
          <AssociatedBrands
            associatedBrands={transaction.renewalDetails?.associatedBrands ?? []}
            onAssociatedBrandsUpdated={onAssociatedBrandsUpdated}
            editing
            showWarning
          />
        </CardBlock>
        <CardBlock
          className='col-12 p-0 mt-4'
          header={<FormattedMessage id='renewal_options_files_title' />}
          shadow
        >
          <DocumentsBlock
            additionalType={DOCUMENT_ADDITIONAL_TYPES.RENEWAL_OPTIONS}
            documents={documents.filter((document: TransactionDocument) => document.additionalType === DOCUMENT_ADDITIONAL_TYPES.RENEWAL_OPTIONS)}
            documentTypes={documentTypes}
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            fieldStatus={fieldStatus}
            handleDownload={handleDownload}
          />
        </CardBlock>
      </div>
    </>
  )
}

export default RenewalOptions
