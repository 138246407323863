import Message from '../Message'

export const SOCIETE = {
  value: 'Société',
  label: Message.legal_status_formation_society,
  label_recap: 'legal_status_formation_society_label'
}

export const ASSOCIATION = {
  value: 'Association',
  label: Message.legal_status_formation_association,
  label_recap: 'legal_status_formation_association_label'
}

export const ENTITE = {
  value: 'Autre entité',
  label: Message.legal_status_formation_entity,
  label_recap: 'legal_status_formation_entity_label'
}

export const LEGAL_STATUS_FORMATION = [
  SOCIETE,
  ASSOCIATION,
  ENTITE
]
