import React, { FC, useEffect, useState } from 'react'
import {
  Contributor,
  CONTRIBUTOR_AGENT,
  CONTRIBUTOR_DEPOSITOR,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_GUIDED_ENTITY_FORMATION,
  CONTRIBUTOR_GUIDED_PM,
  CONTRIBUTOR_GUIDED_PP,
  CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS,
  CONTRIBUTOR_RECIPIENT,
  CONTRIBUTOR_SIGNATORY,
  ContributorForm,
  ContributorsOptionsForm,
  ContributorValidator,
  DEFAULT_CONTRIBUTOR,
  DEPOSIT_CONTRIBUTORS_TYPES,
  DOCUMENT_TYPES,
  downloadFile,
  EditorialBlock,
  EventType,
  FieldStatus,
  HelpBlock,
  INSCRIPTION_TYPE_ACT_TITLE,
  ManageableQuality,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_REVOCATION_STATEMENT,
  Transaction,
  TransactionDocument,
  TransactionService as CommonTransactionService
} from '@inpi-marques/components'
import ContentService from '../../services/content/ContentService'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import OpenDataRNCSService from '../../services/content/OpenDataRNCSService'
import StoreService from '../../services/StoreService'
import ContributorService from '../../services/contributors/ContributorService'
import ModalContactList from '../addressesBook/ModalContactList'
import ContactUtils from '../../utils/ContactUtils'
import { Contact } from '../../interfaces/Contact'
import ContactService from '../../services/contact/ContactService'
import { toast } from 'react-toastify'
import Message from '../../constants/Message'
import ModalContactError from '../addressesBook/ModalContactError'
import TransactionService from '../../services/transaction/TransactionService'
import { storeTransactionFieldStatusUpdate } from '../../store/fieldStatus/fieldStatusActions'
import DocumentService from '../../services/document/DocumentService'
import DepositService from 'services/deposit/DepositService'

interface ContributorFrontFormProps {
  procedure: string,
  contributorType: string,
  contributorTypeLabel?:string,
  contributorTypeToCopy?: string[],
  defaultContributor?: Contributor,
  transaction: Transaction,
  fieldStatus: FieldStatus,
  optionForm: ContributorsOptionsForm,
  actionWithAddressBook?: boolean,
  setIsRequired: (value: boolean) => void,
  activeGuidedMode?: boolean,
  choiceContributor?: string
}

const ContributorFrontForm: FC<ContributorFrontFormProps> = ({
  procedure,
  contributorType,
  contributorTypeLabel,
  contributorTypeToCopy = [],
  defaultContributor = DEFAULT_CONTRIBUTOR,
  transaction,
  fieldStatus,
  optionForm,
  actionWithAddressBook = true,
  setIsRequired,
  activeGuidedMode,
  choiceContributor
}) => {
  const [contributor, setContributor] = useState<Contributor|undefined>(TransactionService.getContributor(transaction, contributorType) || defaultContributor)
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>([])
  const [showAddressBook, setShowAddressBook] = useState<boolean>(false)
  const [showContactError, setShowContactError] = useState<boolean>(false)
  const [contactIdToReplace, setContactIdToReplace] = useState<number>()
  const [editorialContent, setEditorialContent] = useState<string|null>(null)
  const user = useSelector((state : RootStateOrAny) => state.user.user)

  /**
   * Rempli les données de l'intervenant "Demandeur" par les données de l'utilisateur connecté à l'arrivée sur le formulaire
   * Réinitialise le destinataire pour ne garder que le champ "Email"
   */
  useEffect(() => {
    if ((transaction.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value || transaction.procedureType === PROCEDURE_REVOCATION_STATEMENT.value) &&
      contributorType === CONTRIBUTOR_RECIPIENT.value) {
      updateContributor(DEFAULT_CONTRIBUTOR)
    }
  }, [contributorType])

  /**
   * Je récupère les qualités en fonction du mode guidé et des informations du déposant
   */
  useEffect(() => {
    setIsRequired(true)
    ContentService.getManageableQualities(CommonTransactionService.getAdministrableContentProcedureType(transaction)).then(result => {
      // Conditions qui permettent de récupérer les bonnes qualités en fonction du switch contributeur sélectionné et du switch du mode guidé
      if (CONTRIBUTOR_GUIDED_PP.idBtnSwitch === choiceContributor && activeGuidedMode) {
        setManageableQualities(result.filter((quality) => CONTRIBUTOR_GUIDED_PP.restrictedQualities.includes(quality.code)))
      } else if (CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch === choiceContributor && activeGuidedMode) {
        setManageableQualities(result.filter((quality) => CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.restrictedQualities.includes(quality.code)))
      } else if (CONTRIBUTOR_GUIDED_PM.idBtnSwitch === choiceContributor && activeGuidedMode) {
        setManageableQualities(result.filter((quality) => CONTRIBUTOR_GUIDED_PM.restrictedQualities.includes(quality.code)))
      } else if (CONTRIBUTOR_GUIDED_ENTITY_FORMATION.idBtnSwitch === choiceContributor && activeGuidedMode) {
        setManageableQualities(result.filter((quality) => CONTRIBUTOR_GUIDED_ENTITY_FORMATION.restrictedQualities.includes(quality.code)))
      } else {
        setManageableQualities(result.filter((quality) => quality.contributors.includes(CONTRIBUTOR_SIGNATORY.value)))
      }
    })
  }, [activeGuidedMode])

  useEffect(() => {
    // Texte d'information sur le mode guidé
    ContentService.getEditorialBlockByCode('INTERVENANT_GUIDE').then((res: EditorialBlock) => {
      if (res.content) {
        setEditorialContent(res.content)
      }
    })

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  useEffect(() => {
    const getContributor = TransactionService.getContributor(transaction, contributorType)
    if ((transaction.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value || transaction.procedureType === PROCEDURE_REVOCATION_STATEMENT.value) &&
      getContributor?.length === 0) {
      setContributor(ContributorService.buildContributorFromUser(user))
    } else {
      // On vérifie si getContributor est un tableau, car dans le cas des Documents officiels et des relevés de déchéance, on utilise transaction.depositors pour les demandeurs
      if (Array.isArray(getContributor)) {
        setContributor(getContributor[0] || defaultContributor)
      } else {
        setContributor(getContributor || defaultContributor)
      }
    }
  }, [contributorType, transaction.id])

  useEffect(() => {
    let change = {}

    switch (contributorType) {
      case CONTRIBUTOR_DEPOSITOR.value:
        change = { depositor: contributor }
        break
      case CONTRIBUTOR_DEPOSITORS.value:
        change = { depositors: [contributor] }
        break
      case CONTRIBUTOR_RECIPIENT.value:
        change = { recipient: contributor }
        break
      case CONTRIBUTOR_SIGNATORY.value:
        change = { signatory: contributor }
        break
      case CONTRIBUTOR_AGENT.value:
        change = { agent: contributor }
        break
    }
    StoreService.changeStore(change)
  }, [contributor])

  /**
   * Met à jour l'intervenant (mais pas l'email si l'option isEmailReadOnly est à true)
   * @param updatedContributor
   * @param noEmailChange
   */
  const updateContributor = (updatedContributor?: Contributor, noEmailChange: boolean = false) => {
    let newContributor = { ...updatedContributor }
    if (optionForm.isEmailReadOnly && !noEmailChange) {
      newContributor = {
        ...newContributor,
        email: contributor?.email
      }
    }

    setContributor(newContributor)
  }

  /**
   * Enregistre un document de pouvoir d'un intervenant
   * @param event
   */
  const addPowerDocument = async (event: EventType) => {
    ContributorService.addPowerDocument(event, contributor, transaction, contributorType).then((newContributor: Contributor) => {
      setContributor(newContributor)
    })
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  /**
   * Supprime le documents de pouvoir associé à l'intervenant
   */
  const deletePowerDocument = async (editedContributor?: Contributor) => {
    const updateContributor: Contributor|undefined = await ContributorService.deletePowerDocument(editedContributor ?? contributor, transaction, contributorType)
    updateContributor && setContributor(updateContributor)
  }

  /**
   * Fonction à appeler pour l'ajout d'un intervenant au carnet d'adresses
   */
  const addContactAddressBook = (contributor? : Contributor) => {
    const contact : Contact | undefined = ContactUtils.mapToContact(contributor)
    ContactService.addContact(contact).then((response) => {
      if (response) {
        if ('errorCode' in response) {
          if (response.errorCode === ContactUtils.ERROR_CONTACT_ALREADY_EXIST) {
            // On récupère l'id du contact déjà existant
            setContactIdToReplace(response.data)
            setShowContactError(true)
          }
        } else {
          toast.success(Message.success_add_contact)
        }
      }
    })
  }

  // Rempli par défaut la qualité du signataire par celle du mandataire
  useEffect(() => {
    if (contributorType === DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value && transaction.agent) {
      const powerFiles: TransactionDocument[] = transaction.documents?.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.POWER) ?? []
      const signatoryInternalFileNames: string [] = transaction.signatory?.internalNameDocuments ?? []
      const agentInternalFileNames: string [] = transaction.agent?.internalNameDocuments ?? []

      const signatory = {
        ...defaultContributor,
        manageableQuality: transaction.signatory?.manageableQuality ?? transaction.agent.manageableQuality,
        inscriptionNumber: transaction.signatory?.inscriptionNumber ?? transaction.agent.inscriptionNumber,
        internalNameDocuments:
          powerFiles.length &&
          signatoryInternalFileNames.some((name: string) =>
            powerFiles.some((file: TransactionDocument) => file.internalName === name)) ? signatoryInternalFileNames : [...signatoryInternalFileNames, ...agentInternalFileNames]
      }
      updateContributor(signatory, true)
    }
  }, [contributorType])

  return (
    <div>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='contributor_form_title' /></h1>
          <span className='subtitle'><FormattedMessage id={`contributor_form_subtitle_${transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value ? transaction.subProcedureType : procedure}`} /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='mt-5'>
        <ContributorForm
          transaction={transaction}
          optionsForm={optionForm}
          contributorType={contributorType}
          contributorTypeLabel={contributorTypeLabel}
          text={{
            title: (
              <h2 className='mb-0'>
                <FormattedMessage id={contributorTypeLabel ? `contributor_${contributorTypeLabel}` : `contributor_${contributorType}`} />
              </h2>
            ),
            subtitle: (
              <div className='small'>
                <FormattedMessage
                  id={contributorTypeLabel ? `contributor_${contributorTypeLabel}_subtitle_${transaction.procedureType?.toLowerCase()}`
                    : `contributor_${contributorType}_subtitle_${transaction.procedureType?.toLowerCase()}`}
                  values={{
                    a: (...chunks : ((string) []) | []) => (<a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
                    linebreak: <br />
                  }}
                />
              </div>
            )
          }}
          contributor={contributor}
          contributorsToCopy={ContributorService.findAllDistinctContributors(transaction, contributorTypeToCopy)}
          currentETSUser={optionForm.hasAutoCopy ? ContributorService.buildContributorFromUser(user) : undefined}
          setContributor={setContributor}
          fieldStatus={fieldStatus}
          getCountries={ContentService.getCountries}
          manageableQualities={manageableQualities}
          fillContributorInfo={OpenDataRNCSService.getContributorInfos}
          findListSirenByNamePromise={OpenDataRNCSService.findListSirenByName}
          addPowerDocument={addPowerDocument}
          deletePowerDocument={deletePowerDocument}
          dispatch={useDispatch()}
          resetError={storeTransactionFieldStatusUpdate}
          selectFromAddressBook={() => setShowAddressBook(true)}
          actionWithAddressBook={actionWithAddressBook}
          addContributorToAddressBook={addContactAddressBook}
          handleDownload={handleDownload}
          onDownloadClick={() => DepositService.getPowerExampleFile()}
        />
      </div>
      <ModalContactList
        showModal={showAddressBook}
        setShowModal={setShowAddressBook}
        setSelectedContact={(contact) => {
          // Ici on :
          // - ContactUtils.mapToContributor(contact) --> récupère les informations du contact
          // - ContributorValidator.cleanCopyContributor --> on filtre les informations en fonction de son type et si c'est PP/PM
          // - updateContributor --> enregistre les informations dans le contributor (en faisant un filtre sur l'email si elle est readOnly)
          updateContributor(ContributorValidator.cleanCopyContributor(ContactUtils.mapToContributor(contact), contributorType, contributor, transaction.subProcedureType))
        }}
      />
      <ModalContactError
        showModal={showContactError}
        setShowModal={setShowContactError}
        contact={ContactUtils.mapToContact(contributor)}
        contactIdToReplace={contactIdToReplace}
      />

      {editorialContent && activeGuidedMode && contributorType !== CONTRIBUTOR_RECIPIENT.value &&
        <HelpBlock className='mt-5'><div dangerouslySetInnerHTML={{ __html: editorialContent }} /></HelpBlock>}
    </div>
  )
}

export default ContributorFrontForm
