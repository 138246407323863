import {
  Brand,
  CheckboxField,
  DOCUMENT_FORMATS,
  DOCUMENT_LIMITS,
  DOCUMENT_STATUS,
  DOCUMENT_TYPES,
  DocumentService as CommonDocumentService,
  DropArea,
  FieldStatus,
  Preview,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from '../../../../store/transaction/transactionActions'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import DocumentService from '../../../../services/document/DocumentService'
import TransactionService from '../../../../services/transaction/TransactionService'
import TitleService from '../../../../services/inscription/TitleService'

/* global fetch */
/* global File */

interface BrandReproductionProps {
  transaction: Transaction,
  fieldStatus: FieldStatus,
  tmpPreview?: TransactionDocument,
  setTmpPreview?: (preview: TransactionDocument|undefined) => void,
}

const BrandReproduction: FC<BrandReproductionProps> = ({ transaction, fieldStatus, tmpPreview, setTmpPreview }) => {
  const dispatch = useDispatch()

  const [brand, setBrand] = useState<Brand|undefined>(transaction.frmiDetails?.brand
    ? {
      ...transaction.frmiDetails.brand,
      file: tmpPreview ?? transaction.frmiDetails.brand.file
    } : { hasStandardCharacters: false, hasColors: false, isOnlyColors: false, file: tmpPreview, verbalElements: transaction?.frmiDetails?.titles?.[0]?.type && TitleService.isTitleWordType(transaction?.frmiDetails?.titles?.[0]) ? transaction?.frmiDetails?.titles?.[0]?.text : undefined })
  const [load, setLoad] = useState<boolean>(true)

  useEffect(() => {
    if (load) {
      let updatedBrand: Brand = transaction.frmiDetails?.brand ?? { hasStandardCharacters: false, hasColors: false, isOnlyColors: false, verbalElements: transaction?.frmiDetails?.titles?.[0]?.type && TitleService.isTitleWordType(transaction?.frmiDetails?.titles?.[0]) ? transaction?.frmiDetails?.titles?.[0]?.text : undefined }
      if (updatedBrand.file) {
        updatedBrand = { ...updatedBrand, file: { ...updatedBrand.file, status: DOCUMENT_STATUS.ACCEPTED } }
      }
      setBrand(updatedBrand)
      // Si le titre déposé possède un fichier de marque, on modifie le fichier de la marque et de la transaction
      if (tmpPreview) {
        onBrandChange(updatedBrand, tmpPreview)
        setTmpPreview && setTmpPreview(undefined)
      }
      setLoad(false)
    }
  }, [transaction.frmiDetails?.brand, tmpPreview])

  /** Gestion de l'upload du document */
  const [brandDocument, setBrandDocument] = useState<TransactionDocument | undefined>(tmpPreview || transaction.frmiDetails?.brand?.file)
  const [brandDocumentPreview, setBrandDocumentPreview] = useState<string | undefined>()

  useEffect(() => {
    if (!brandDocumentPreview && brandDocument) {
      // Dans le cas d'une édition, si le fichier de la marque n'est pas en local
      // Ou dans le cas de récupération du fichier de la marque depuis le titre déposé, on récupère la prévisualisation depuis le fichier ou l'API
      getBrandFile().then(setBrandDocumentPreview)
    }
  }, [])

  /**
   * Une fois le fichier déposé
   * On garde le fichier tout juste upload dans le state
   * car on ne peut sauvegarder un fichier dans le store
   * @param file
   */
  const onBrandDocumentDropped = (file: File): void => {
    if (file) {
      setBrandDocumentPreview(undefined)
      setBrandDocument({
        type: DOCUMENT_TYPES.BRAND,
        internalName: '',
        name: file.name,
        format: file.type,
        file,
        size: file.size,
        status: DOCUMENT_STATUS.ACCEPTED
      })
    }
  }

  /**
   * Une fois le fichier inséré dans le Drop Area, on le crée en base et on récupère sa prévisualisation
   * @returns
   */
  const onBrandDocumentAdded = async (document: TransactionDocument): Promise<string | null> => {
    const editedBrand = { ...brand, file: { ...document, status: DOCUMENT_STATUS.ACCEPTED } }
    if (!transaction.frmiDetails?.brand?.id) {
      await TransactionService.updateTransactionBDDFromStore({
        ...transaction,
        frmiDetails: { ...transaction.frmiDetails, brand: editedBrand }
      }, ['frmiDetails'])
    }
    if (document && editedBrand) {
      return await DocumentService.createAndGetFRMIDocumentPreview(document, transaction, editedBrand)
    }
    return null
  }

  const getBrandFile = async () => {
    const brandFile = tmpPreview ?? (brand?.preview && brand.preview.internalName ? brand.preview : brand?.file)
    // Si l'image de la marque provient de la base publique, on la récupère et on la sauvegarde dans le dossier
    if (brandFile?.url) {
      fetch(brandFile.url).then(res => res.blob())
        .then(async blob => {
          const format: string = CommonDocumentService.getFileFormat(undefined, brandFile?.url)
          const file = new File([blob], brandFile.name, { type: format })
          const preview = { ...brandFile, name: `${brandFile.name}.${CommonDocumentService.getFileExtension(format)}`, format: format, file: file, status: DOCUMENT_STATUS.ACCEPTED }
          setBrandDocument(preview)
        })
        .catch(() => {
          onBrandDocumentDeleted()
        })
    // Si l'image de la marque provient du référentiel, on la récupère et on la sauvegarde dans le dossier
    } else if (brandFile && brandFile.idRecordTmp) {
      return DocumentService.getBrandPreviewDocumentRecord(brandFile, { id: brandFile.idRecordTmp })
    // Si le fichier de la marque possède déjà une preview, on la récupère
    } else if (brandFile?.previewData) {
      return brandFile.previewData
    // Dans le cas d'une édition, si le fichier de la marque n'est pas en local, on récupère la prévisualisation depuis l'API
    } else {
      if (!brand?.id) {
        await TransactionService.updateTransactionBDDFromStore({
          ...transaction,
          frmiDetails: { ...transaction.frmiDetails, brand: brand }
        }, ['frmiDetails'])
      }
      return brandFile && await DocumentService.getBrandPreviewDocument(transaction, brandFile)
    }
  }

  const onBrandDocumentDeleted = (): void => {
    setBrandDocument(undefined)
    onBrandChange({ ...brand, file: undefined })
  }

  const onHasColorsChange = (): void => {
    onBrandChange({ ...brand, hasColors: !brand?.hasColors })
  }

  const onHasStandardCharactersChange = (): void => {
    onBrandChange({ ...brand, hasStandardCharacters: !brand?.hasStandardCharacters })
  }

  const onIsOnlyColorsChange = (): void => {
    onBrandChange({ ...brand, isOnlyColors: !brand?.isOnlyColors })
  }

  const onBrandChange = (updatedBrand: Brand, document?: TransactionDocument): void => {
    let file = document ?? updatedBrand?.file
    if (file) {
      file = { ...file, status: DOCUMENT_STATUS.ACCEPTED }
    }
    const brand: Brand = { ...updatedBrand, file }
    setBrand(brand)
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      brand: brand
    }))
  }

  return (
    <div className='brandReproduction'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='select_frmi_brand_reproduction_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_brand_reproduction_description' /></span>
        </header>
        <InternalReferenceField
          className='col-3'
          transaction={transaction}
        />
      </div>
      <div className='justify-content-between'>
        <div>
          <div className='d-flex'>
            <h2 className='text-primary'>
              <FormattedMessage id='frmi_brand_reproduction_title' />
            </h2>
          </div>
          <div className='col-12 mt-3 mb-3'>
            {brandDocument && brand && !load &&
              <Preview
                file={brandDocumentPreview ?? (() => onBrandDocumentAdded(brandDocument))}
                onTrashClick={() => onBrandDocumentDeleted()}
                document={brandDocument}
              />}
          </div>
          <div className='col-12'>
            <div className='small mt-2'>
              <FormattedMessage id='reproduction_brand_subtitle' />
            </div>
            <div>
              <DropArea
                accept={`${DOCUMENT_FORMATS.JPEG},${DOCUMENT_FORMATS.MP3},${DOCUMENT_FORMATS.MP4}`}
                maxSize={DOCUMENT_LIMITS.DEPOSIT_MULTIPLE_SIZE}
                text='drop_brand_file'
                onDrop={(files: File[]) => onBrandDocumentDropped(files[0])}
                fieldStatus={fieldStatus}
                nameFieldStatus='brandDocument'
                useJpg
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5 col-md-12'>
        <div className='col-md-12 col-lg-12'>
          <CheckboxField
            inputId='hasColors'
            label={<FormattedMessage id='reproduction_brand_has_color_label' />}
            checked={brand?.hasColors}
            onChange={onHasColorsChange}
          />
          <CheckboxField
            inputId='hasStandardCharacters'
            label={<FormattedMessage id='reproduction_brand_has_standard_characters_label' />}
            checked={brand?.hasStandardCharacters}
            onChange={onHasStandardCharactersChange}
          />
          <CheckboxField
            inputId='isOnlyColors'
            label={<FormattedMessage id='reproduction_brand_has_only_colors_label' />}
            checked={brand?.isOnlyColors}
            onChange={onIsOnlyColorsChange}
          />
          <div className='d-flex color-primary small'>
            <FormattedMessage
              id='reproduction_brand_has_only_colors_example_label'
              values={{
                linebreak: <br />
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandReproduction
