import React, { FC } from 'react'
import { FieldStatus, OfficialDocumentRequested, SubmitButton } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import OverviewOfficialDocumentsRequested from './OverviewOfficialDocumentsRequested'

interface OfficialDocumentRequestedListProps {
  officialDocumentsRequested: OfficialDocumentRequested[],
  editOfficialDocumentRequested: (index: number) => void,
  deleteOfficialDocumentRequested: (index: number) => void,
  addOfficialDocumentRequested: () => void,
  isEditingMode: boolean,
  fieldStatus?: FieldStatus,
  setQuantity: (inde: number, quantity?: number) => void
}

/**
 * Composant contenant la liste des demandes de documents officiels et le bouton pour en ajouter
 *
 * @param officialDocumentsRequested - la liste des demandes pour des types de documents officiels
 * @param deleteOfficialDocumentRequested - Fonction pour supprimer une demande
 * @param editOfficialDocumentRequested - Fonction pour modifier une demande
 * @param addOfficialDocumentRequested - Fonction pour ajouter une demande
 * @param isEditingMode - Savoir si on est à l'édition
 * @param setQuantity - Fonction pour modifier la quantité d'une demande
 * @param fieldStatus - Objet contenant les erreurs du formulaire
 * @constructor
 */
const OfficialDocumentRequestedList: FC<OfficialDocumentRequestedListProps> = (
  {
    officialDocumentsRequested,
    deleteOfficialDocumentRequested,
    editOfficialDocumentRequested,
    addOfficialDocumentRequested,
    isEditingMode,
    setQuantity,
    fieldStatus
  }) => {
  return (
    <>
      <div className='row mb-4 justify-content-between'>
        {(!officialDocumentsRequested.length || !isEditingMode) &&
          <div className='col-7'>
            <SubmitButton
              className='btn-select btn-outline-primary btn-add'
              onClick={addOfficialDocumentRequested}
            >
              <FormattedMessage id='official_document_add_request' />
              <FontAwesomeIcon className='ml-2' icon={faPlus} />
            </SubmitButton>
          </div>}
      </div>
      {officialDocumentsRequested && officialDocumentsRequested.length > 0 &&
        <OverviewOfficialDocumentsRequested
          officialDocumentsRequested={officialDocumentsRequested}
          deleteOfficialDocumentRequested={deleteOfficialDocumentRequested}
          editOfficialDocumentRequested={editOfficialDocumentRequested}
          className='mt-5'
          setQuantity={setQuantity}
          fieldStatus={fieldStatus}
        />}
    </>
  )
}

export default OfficialDocumentRequestedList
