import React, { FC, ReactNode, useContext, useState } from 'react'
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faEdit,
  faExclamationTriangle,
  faMinus,
  faPlus,
  faQuestion,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, IntlProvider } from 'react-intl'
import {
  FieldStatus,
  HelpBlock,
  PROCEDURE_DEPOSIT,
  ProductAndService,
  ProductClass,
  Table
} from '@inpi-marques/components'
import Message from '../../src/constants/Message'
import TextField from '../form/fields/TextField'

interface ProductAndServicesAccordionProps {
  productClasses: ProductClass[],
  onProductDelete?: (productClass: ProductClass, product: ProductAndService) => void,
  onProductEdit?: (editedProductClass: ProductClass) => void,
  onClassDelete?: (productClass: ProductClass) => void,
  className?: string,
  displayHelpBlock?: boolean,
  procedureType?: string
}

interface AccordionCustomHeader {
  children: ReactNode,
  eventKey: string,
  validityStatusIcon?: JSX.Element,
}

const ContextAwareToggle: FC<AccordionCustomHeader> = ({ children, eventKey, validityStatusIcon }) => {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(eventKey)
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <div className='d-flex'>
      {validityStatusIcon &&
        <span className='mr-2'>{validityStatusIcon}</span>}
      <div
        onClick={decoratedOnClick}
        className='accordion-header text-primary flex-grow-1 d-flex'
      >
        {children}
        <FontAwesomeIcon className='h-100' icon={isCurrentEventKey ? faMinus : faPlus} />
      </div>
    </div>

  )
}

/**
 * Accordéon des produits et service.
 * Celui-ci permet d'afficher sous forme d'accordéons les produits et service, et de les supprimer, si la fonction
 * est renseignée
 * @param param0
 * @returns
 */
const ProductAndServicesAccordion: FC<ProductAndServicesAccordionProps> = (
  {
    productClasses,
    onProductDelete,
    onProductEdit,
    onClassDelete,
    className,
    displayHelpBlock = false,
    procedureType
  }) => {
  /** Array des produits en cours d'edition  */
  const [editingProducts, setEditingProducts] = React.useState<{product: ProductAndService, productIndex: number, productClassIndex: number}[]>([])
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})

  const typeLabels = {
    nice: Message.product_and_services_type_nice,
    tmclass: Message.product_and_services_type_tmclass,
    free: Message.product_and_services_type_free,
    import: Message.product_and_services_type_import
  }

  const tableTitles = procedureType === PROCEDURE_DEPOSIT.value ? [
    { label: '' },
    { label: Message.product_and_services_table_label },
    { label: Message.product_and_services_table_origin }
  ] : [{ label: Message.product_and_services_table_label }]

  /** On affiche la colonne supprimer si la fonction existe */
  if (onProductDelete) {
    tableTitles.push({ label: Message.product_and_services_table_delete })
  }

  /** On affiche la colonne modifier si la fonction existe */
  if (onProductEdit) {
    tableTitles.push({ label: Message.product_and_services_table_edit })
  }

  const editProduct = (productClass: ProductClass, productClassIndex: number, index: number, editedProduct?: ProductAndService) => {
    setFieldStatus({ ...fieldStatus, [`${productClassIndex}_${index}`]: undefined })

    if (!editedProduct?.name?.trim().length) {
      setFieldStatus({ ...fieldStatus, [`${productClassIndex}_${index}`]: Message.required_field })
      return
    }

    if (editedProduct) {
      const editedProducts = productClass.products.map((product, productIndex) => {
        if (productIndex === index) {
          return {
            ...editedProduct
          }
        }
        return product
      })
      onProductEdit!({ ...productClass, products: editedProducts })
    }

    setEditingProducts(editingProducts.filter((product) => !(product.productIndex === index && product.productClassIndex === productClassIndex)))
  }

  /**
   * Récupère l'icone du statut du produit
   * @param status
   * @returns
   */
  const getValidityStatusIcon = (status: string) => {
    switch (status) {
      case 'OK':
        return <FontAwesomeIcon className='text-primary' icon={faCheck} />
      case 'None':
        return <FontAwesomeIcon icon={faQuestion} />
      case 'Not OK':
        return <FontAwesomeIcon className='text-danger' icon={faExclamationTriangle} />
      default:
        return <FontAwesomeIcon className='text-warning' icon={faExclamationTriangle} />
    }
  }

  /**
   * Récupération de l'icone de statut de la classe.
   * Si les produits de la classe en question possèdent tous le même status de validité, alors on l'affiche sur la classe en question
   * @param productClass
   * @returns
   */
  const getProductClassStatusIcon = (productClass: ProductClass) => {
    const firstProductValidityStatus: string | undefined = productClass.products.length ? productClass.products[0].validityStatus : undefined

    if (displayHelpBlock && firstProductValidityStatus) {
      if (productClass.products.every((product: ProductAndService) => product.validityStatus === firstProductValidityStatus)) {
        return getValidityStatusIcon(firstProductValidityStatus)
      }
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className={className}>
        {productClasses
          .map((productClass: ProductClass, productClassIndex: number) =>
            <Accordion key={`product-class-accordion-${productClassIndex}`} defaultActiveKey='0'>
              <Card key={productClassIndex}>
                <Card.Header className='cursor-pointer'>
                  <ContextAwareToggle eventKey={productClassIndex.toString()} validityStatusIcon={getProductClassStatusIcon(productClass)}>
                    <div className='d-flex justify-content-between w-100'>
                      <p className='mb-0'>
                        <FormattedMessage id='products_and_services_class' /> {productClass.ref}
                      </p>
                      {onClassDelete &&
                        <button
                          className='btn btn-link text-danger mr-4'
                          onClick={() => onClassDelete(productClass)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>}
                    </div>
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={productClassIndex.toString()}>
                  <Card.Body className='p-4'>
                    <Table
                      tableTitle={tableTitles}
                      tableClassName='products-and-services__table not-fixed'
                    >
                      <tbody>
                        {
                          productClass.products
                            .map((product: ProductAndService, productIndex: number) => (
                              <tr key={`-${productIndex}`}>
                                {procedureType === PROCEDURE_DEPOSIT.value &&
                                  <td className='border-0 w-10'>
                                    {product.validityStatus && getValidityStatusIcon(product.validityStatus)}
                                  </td>}
                                <td className='border-0'>
                                  {editingProducts.find(editingProduct => editingProduct.productIndex === productIndex && editingProduct.productClassIndex === productClassIndex)
                                    ? (
                                      <TextField
                                        fieldStatus={fieldStatus}
                                        nameFieldStatus={`${productClassIndex}_${productIndex}`}
                                        inputId={`product-${productIndex}`}
                                        value={editingProducts.find(editingProduct => editingProduct.productIndex === productIndex && editingProduct.productClassIndex === productClassIndex)?.product.name}
                                        onChange={e => setEditingProducts(editingProducts.map(editingProduct => {
                                          if (editingProduct.productIndex === productIndex && editingProduct.productClassIndex === productClassIndex) {
                                            return {
                                              product: {
                                                ...editingProduct.product,
                                                name: e.target.value
                                              },
                                              productIndex,
                                              productClassIndex
                                            }
                                          }
                                          return editingProduct
                                        }
                                        ))}
                                        onKeypress={e => {
                                          if (e.key === 'Enter') {
                                            editProduct(productClass, productClassIndex, productIndex, editingProducts.find(editingProduct => editingProduct.productIndex === productIndex && editingProduct.productClassIndex === productClassIndex)?.product)
                                          }
                                        }}
                                      />
                                    )
                                    : product.name}
                                </td>
                                {procedureType === PROCEDURE_DEPOSIT.value &&
                                  <td className='border-0'>{typeLabels[product.origin]}</td>}
                                {onProductDelete &&
                                  <td className='border-0'>
                                    <button
                                      className='btn btn-link text-danger'
                                      onClick={() => onProductDelete(productClass, product)}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                  </td>}
                                {onProductEdit &&
                                  (
                                    editingProducts.find(editingProduct => editingProduct.productIndex === productIndex && editingProduct.productClassIndex === productClassIndex)
                                      ? (
                                        <td className='border-0'>
                                          <button
                                            className='btn btn-link text-primary'
                                            onClick={() => editProduct(productClass, productClassIndex, productIndex, editingProducts.find(editingProduct => editingProduct.productIndex === productIndex && editingProduct.productClassIndex === productClassIndex)?.product)}
                                          >
                                            <FontAwesomeIcon icon={faCheck} />
                                          </button>
                                        </td>
                                      )
                                      : (
                                        <td className='border-0'>
                                          <button
                                            className='btn btn-link text-primary'
                                            onClick={() => setEditingProducts([
                                              ...editingProducts,
                                              { product, productIndex, productClassIndex }
                                            ])}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                          </button>
                                        </td>
                                      )
                                  )}
                              </tr>
                            ))
                        }
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}
        {/* LEGENDE DES ICONES DE STATUT
          Affiché uniquement s'il y a un des icones présent dans la liste */}
        {displayHelpBlock && productClasses.some((productClass: ProductClass) => productClass.products.some((product: ProductAndService) => product.validityStatus)) &&
          <HelpBlock className='col-6 mt-2 auto-size'>
            <div>
              <div className='mb-2'><FontAwesomeIcon className='text-primary w-10' icon={faCheck} /> <FormattedMessage id='product_and_service_status_ok' /></div>
              <div className='mb-2'><FontAwesomeIcon className='text-danger w-10' icon={faExclamationTriangle} /> <FormattedMessage id='product_and_service_status_notok' /></div>
              <div className='mb-2'><FontAwesomeIcon className='w-10' icon={faQuestion} /> <FormattedMessage id='product_and_service_status_none' /></div>
              <div><FontAwesomeIcon className='text-warning w-10' icon={faExclamationTriangle} /> <FormattedMessage id='product_and_service_status_other' /></div>
            </div>
          </HelpBlock>}
      </div>
    </IntlProvider>
  )
}

export default ProductAndServicesAccordion
