import { ProductAndServiceListView, ProductClassVersion, SubmitButton } from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface WithdrawOverviewProps {
    currentVersion: ProductClassVersion,
    withDrawVersion: ProductClassVersion
}

const WithdrawOverview: FC<WithdrawOverviewProps> = ({ withDrawVersion, currentVersion }) => {
  const [selectedTab, setSelectedTab] = useState<'INPI_VERSION'|'WITHDRAW_VERSION'>('INPI_VERSION')

  return (
    <div>
      <div className='text-center my-3'>
        <SubmitButton className={`mr-3 ${selectedTab === 'INPI_VERSION' ? 'btn-primary' : 'text-gris'}`} onClick={() => setSelectedTab('INPI_VERSION')}>
          <FormattedMessage id='withdraw_overview_inpi_version_label' />
        </SubmitButton>
        <SubmitButton className={`${selectedTab === 'WITHDRAW_VERSION' ? 'btn-primary' : 'text-gris'}`} onClick={() => setSelectedTab('WITHDRAW_VERSION')}>
          <FormattedMessage id='withdraw_overview_withdraw_version_label' />
        </SubmitButton>
      </div>
      <div>
        {selectedTab === 'INPI_VERSION' &&
          <ProductAndServiceListView productClasses={currentVersion.productClasses} readOnly />}

        {selectedTab === 'WITHDRAW_VERSION' &&
          <ProductAndServiceListView productClasses={withDrawVersion.productClasses} readOnly />}
      </div>
    </div>)
}

export default WithdrawOverview
