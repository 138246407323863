import React, { FC, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Notification, SubmitButton } from '@inpi-marques/components'
import truncate from 'truncate-html'

interface OverviewNotificationsInfoProps {
  notification:Notification,
  download:() => void
}

const OverviewNotificationsInfo:FC<OverviewNotificationsInfoProps> = ({
  notification,
  download
}) => {
  const contentRef = useRef(null)

  return (
    <div>
      <div>
        <h3><FormattedMessage id='overview_transaction_notification_content_label' /></h3>
      </div>
      <div ref={contentRef} id={`notification-${notification.id}`} className='border p-2 mb-2'>
        <span
          dangerouslySetInnerHTML={{
            __html: truncate(notification.content, {
              decodeEntities: true,
              byWords: true,
              length: 20
            })
          }}
        />
      </div>
      <SubmitButton className='btn btn-link notif-ellipsis' onClick={download}>
        <FormattedMessage id='overview_transaction_notification_ellipsis' />
      </SubmitButton>
    </div>
  )
}

export default OverviewNotificationsInfo
