import { Transaction } from '@inpi-marques/components'
import store from '../store/store'
import { storeTransactionUpdate } from '../store/transaction/transactionActions'

class StoreService {
    /**
     * Met a jour le store
     * @param transaction
     */
    changeStore = (transaction: Transaction) => {
      store.dispatch(storeTransactionUpdate(transaction))
    }
}

export default new StoreService()
