import {
  DEPOSIT_TYPE_DIVISION,
  PROCEDURE_DEPOSIT,
  PROCEDURE_RENEWAL,
  STATUS_DEPOSIT,
  SubmitButton
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface ProductAndServicesOverviewHeaderProps {
  editMode: boolean,
  setEditMode: (editMode: boolean) => void,
  regularizeNotification: () => void,
  transactionStatus?: string,
  hasRegularization?: boolean,
  procedureType?: string,
  editable?: boolean,
  subProcedureType?: string
}

const ProductAndServicesOverviewHeader: FC<ProductAndServicesOverviewHeaderProps> = ({
  editMode,
  setEditMode,
  regularizeNotification,
  transactionStatus,
  hasRegularization,
  procedureType,
  editable = true,
  subProcedureType
}) => (
  <div className='d-flex justify-content-between align-items-center'>
    <FormattedMessage id={`overview_deposit_products_title${procedureType === PROCEDURE_RENEWAL.value ? '_renewal' : ''}`} />
    {editable && ![STATUS_DEPOSIT.TOTAL_WITHDRAWAL, STATUS_DEPOSIT.WAITING_VIR_PAYMENT, STATUS_DEPOSIT.DRAFT, STATUS_DEPOSIT.REGISTERED, STATUS_DEPOSIT.REJECTED, STATUS_DEPOSIT.TO_REGISTER,
      STATUS_DEPOSIT.REGISTERING].includes(transactionStatus) && procedureType === PROCEDURE_DEPOSIT.value && subProcedureType !== DEPOSIT_TYPE_DIVISION.value &&
        <div>
          {!editMode && hasRegularization &&
            <SubmitButton className='btn-link-primary font-weight-bold text-normalcase mr-4' onClick={regularizeNotification}>
              <FormattedMessage id='product_and_services_validate_regularization_title' />
            </SubmitButton>}
          <SubmitButton className='btn-link-primary font-weight-bold text-normalcase' onClick={() => setEditMode(!editMode)}>
            {!editMode && <FormattedMessage id='overview_deposit_products_revocation_title' />}
            {editMode && <FormattedMessage id='overview_deposit_products_listing_mode_title' />}
          </SubmitButton>
        </div>}
  </div>
)

export default ProductAndServicesOverviewHeader
