import { containsErrors, DOCUMENT_TYPES, FieldStatus, FRMI, TransactionDocument } from '@inpi-marques/components'
import {
  PAYMENT_RECEIVED_AND_CONFIRMED_BY_OMPI,
  PAYMENT_TO_OMPI_BANK_ACCOUNT,
  PAYMENT_TO_OMPI_POSTAL_ACCOUNT
} from '../../../../../constants/PaymentMethodsConstants'
import Message from 'constants/Message'
import store from '../../../../../store/store'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'

class DocumentValidator {
  /**
   * Retourne les erreurs éventuelles sur un document ajouté
   */
  validateDocuments = (documentToAddList: TransactionDocument[], frmiDetails?: FRMI): FieldStatus => {
    let fieldStatus: FieldStatus = {}
    // Si la méthode de paiement est un versement de l'OMPI ou paiement reçu par l'OMPI, alors il est obligatoire de fournir un justificatif de paiement à l'OMPI
    if (!documentToAddList.some(document => document.type === DOCUMENT_TYPES.OMPI_PROOF_PAYMENT) && frmiDetails?.paymentMethod?.type && [PAYMENT_TO_OMPI_POSTAL_ACCOUNT.value, PAYMENT_TO_OMPI_BANK_ACCOUNT.value, PAYMENT_RECEIVED_AND_CONFIRMED_BY_OMPI.value].includes(frmiDetails?.paymentMethod?.type)) {
      fieldStatus = { ...fieldStatus, ompi_proof_payment_missing: Message.fees_and_taxes_payment_proof_document_missing }
    }
    return fieldStatus
  }

  validateOnChangeStep = (documentToAddList: TransactionDocument[], frmi?: FRMI) => {
    const fieldStatus: FieldStatus = this.validateDocuments(documentToAddList, frmi)
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new DocumentValidator()
