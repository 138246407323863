import { DropArea, EventType, FIELD_LIMITS, Preview, SwitchField, TextArea, TextField } from '@inpi-marques/components'
import { BrandTypeContentProps } from 'interfaces/Deposit'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import { storeTransactionUpdateDeposit } from '../../../../../store/transaction/transactionActions'

const FigurativeContent: FC<BrandTypeContentProps> = ({
  brand,
  brandType,
  fieldStatus,
  onBrandDocumentDroped,
  onBrandDocumentAdded,
  onBrandDocumentDeleted,
  document,
  documentPreview
}) => {
  const dispatch = useDispatch()

  const [hasColor, setHasColor] = useState<boolean>(!!brand.colors?.length)
  const [colorText, setColorText] = useState<string>(brand.colors ?? '')
  const [description, setDescription] = useState<string>(brand.description ?? '')

  useEffect(() => {
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, hasColors: hasColor } }))
  }, [])

  const onSwitchChanged = (): void => {
    const newValue: boolean = !hasColor
    setHasColor(newValue)
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, hasColors: newValue } }))
  }

  const onColorTextChanged = (event: EventType): void => {
    const { value } = event.target
    setColorText(value)
  }

  const onColorTextBlur = (): void => {
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, colors: colorText } }))
  }

  const onDescriptionChanged = (event: EventType): void => {
    const { value } = event.target
    setDescription(value)
  }

  const onDescriptionBlur = (): void => {
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, description } }))
  }

  return (
    <>
      <div className='col-12'>
        <DropArea
          accept={brandType.acceptedFormat}
          maxSize={brandType.maxSize}
          text='drop_brand_file'
          onDrop={(files: File[]) => onBrandDocumentDroped(files[0])}
          fieldStatus={fieldStatus}
          nameFieldStatus='brandDocument'
        />
        {document &&
          <Preview
            file={documentPreview ?? (() => onBrandDocumentAdded(brand))}
            onTrashClick={() => onBrandDocumentDeleted(brand)}
            document={document}
            isPrintFormat
          />}
      </div>

      <SwitchField
        inputId='hasTranslation'
        divClassName='col-md-12 col-lg-6 align-items-center'
        className='col-2'
        labelClassName='col-8'
        onChange={onSwitchChanged}
        value={hasColor}
        label={<FormattedMessage id='brand_type_has_color_label' />}
        displayStringValue
        width={50}
        height={30}
      />

      <div className='col-md-12 col-lg-6 mb-3'>
        <TextArea
          inputId='brandDescription'
          classNameFormGroup='mb-1'
          label={<FormattedMessage id='brand_type_description_label' />}
          value={description}
          onChange={onDescriptionChanged}
          onBlur={onDescriptionBlur}
          maxLength={FIELD_LIMITS.BRAND_DESCRIPTION_MAX_CHAR}
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
          fieldStatus={fieldStatus}
        />
        <div className='pt-2 d-flex color-primary small'>
          <FormattedMessage
            id='brand_type_description_label_info'
            values={{
              linebreak: <br />
            }}
          />
        </div>
      </div>

      {hasColor &&
        <TextField
          inputId='figurative-brand-color-text'
          label={<FormattedMessage id='brand_type_colors_text_label' />}
          classNameFormGroup='col-md-12 col-lg-6'
          value={colorText}
          onChange={onColorTextChanged}
          onBlur={onColorTextBlur}
          fieldStatus={fieldStatus}
          nameFieldStatus='brandColors'
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
          required
        />}
    </>
  )
}

export default FigurativeContent
