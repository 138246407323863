import React, { FC, ReactNode } from 'react'
import { EventType, FieldProps, FieldStatus } from '../FormInterfaces'
import { buildEventType, ErrorField } from '../../index'
import { resetError as resetErrorUtils, resetStateError } from '../../utils/formUtils'

interface CheckboxFieldProps extends FieldProps {
  inputId: string,
  name?: string,
  label: ReactNode,
  checked?: boolean,
  onChange?: (event: EventType) => void
  fieldStatus?: FieldStatus,
  className?: string,
  labelClassName?: string,
  disabled?: boolean,
  nameFieldStatus?: string
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  inputId,
  name,
  label,
  checked = false,
  onChange,
  fieldStatus = {},
  className = '',
  labelClassName = '',
  disabled,
  nameFieldStatus,
  dispatch,
  resetError,
  readOnly
}) => {
  const onInternalChange = (): void => {
    onChange && onChange(buildEventType(name || inputId, !checked))
    if (dispatch && resetError) {
      resetErrorUtils(dispatch, resetError, fieldStatus, nameFieldStatus || inputId)
    } else if (resetError) {
      resetStateError(resetError, fieldStatus, nameFieldStatus || inputId)
    }
  }

  return (
    <div className={`${!readOnly ? 'form-group custom-control custom-checkbox' : ''} ${className}`}>
      {!readOnly && (
        <input
          type='checkbox'
          className='custom-control-input'
          id={inputId}
          name={name || inputId}
          checked={checked}
          onChange={onInternalChange}
          disabled={disabled}
        />
      )}
      <label className={`${!readOnly ? 'form-check-label custom-control-label' : ''} ${labelClassName}`} htmlFor={inputId}>
        {label}
      </label>

      <ErrorField className='mt-1' message={fieldStatus[nameFieldStatus || inputId]} />
    </div>
  )
}

export default CheckboxField
