import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const NotFound = () => (
  <div className='page-not-found'>
    <h1 className='title'>
      <FormattedMessage id='page_not_found_title' />
    </h1>
    <div className='desc'>
      <FormattedMessage id='page_not_found_description' />
    </div>
    <Link to='/'>
      <button className='btn-block bg-primary btn-lg'>
        <FormattedMessage id='button_return' />
      </button>
    </Link>
  </div>
)

export default NotFound
