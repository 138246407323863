import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { CardBlock, Dropdown, ModalComponent, SubmitButton } from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { History } from 'history'
import { ACCESS_DEMAND_ITEM_ATTACH_PM, ACCESS_DEMAND_ITEMS } from '../../constants/ETSAccountConstants'
import { ETSAccountNavInterface } from '../../interfaces/ETSAccountInterface'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { BASKET_HEADER, BASKET_HEADER_LOT_2 } from '../../constants/BasketConstants'
import BasketCountItems from '../count/BasketCountItems'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { BasketFilter } from '../../interfaces/BasketInterface'
import { useLocation } from 'react-router'
import { storeCountDepositReset, storeCountDepositUpdate } from '../../store/countDeposit/countDepositActions'
import TransactionService from '../../services/transaction/TransactionService'
import RecordService from '../../services/opposition/RecordService'
import Observations from '../deposit/observations/Observations'
import ConfigurationService from '../../services/configuration/ConfigurationService'
/* global localStorage */

interface HomeComponentProps {
  history : History
}

const Home = (props: HomeComponentProps) => {
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [headerSelected, setHeaderSelected] = useState<BasketFilter>(BASKET_HEADER[0])
  const [showModalObservations, setShowModalObservations] = useState<boolean>(false)
  const location = useLocation()
  const homeCount = useSelector((state : RootStateOrAny) => state.homeCount)
  const dispatch = useDispatch()
  const intl = useIntl()
  const baskets = process.env.REACT_APP_LOT_2 === 'true' ? BASKET_HEADER_LOT_2 : BASKET_HEADER
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const basketParam = queryParams.get('corbeille')
    getTransactionCountByHeader(baskets.find(header => header.id === basketParam) || baskets[0])
  }, [location.search])

  useEffect(() => {
    ConfigurationService.getConfigurationsValues(['REGEX_LATIN', 'ELECTRONIC_NOTIF']).then(result => {
      localStorage.setItem('REGEX_LATIN', result?.[0].value)
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[1].value)
    })
  }, [])

  const getTransactionCountByHeader = (filter: BasketFilter) => {
    let params : { type: string, procedures?: string[] } = { type: 'status' }
    if (filter.procedures) {
      params = { ...params, procedures: filter.procedures }
    }
    setHeaderSelected(filter)
    dispatch(storeCountDepositReset())
    TransactionService.getTransactionsCount(params).then((result) => {
      dispatch(storeCountDepositUpdate(result))
    })
    TransactionService.getTransactionsCount({ ...params, type: 'notifs' }).then((result) => {
      dispatch(storeCountDepositUpdate(result))
    })
    TransactionService.getTransactionsCount({ ...params, type: 'others' }).then((result) => {
      dispatch(storeCountDepositUpdate(result))
    })
    RecordService.getCount().then((result) => {
      dispatch(storeCountDepositUpdate(result))
    })

    return homeCount
  }

  return (
    <div className='home mt-4'>
      <div className='w-50 pb-4'>
        <h1>
          <FormattedMessage id={`home_title_${headerSelected.id}`} />
        </h1>
        <span className='subtitle'><FormattedMessage id={`home_sub_title_${headerSelected.id}`} /></span>
      </div>
      <CardBlock shadow className='procedureFilter border-0' bodyClassName='p-0 row m-0'>
        {
          baskets.map(filter => (
            <SubmitButton
              key={`${filter.id}-header`}
              className={`${headerSelected === filter ? 'active btn-secondary' : 'btn-primary'} btn home-btn m-1 p-2`}
              onClick={() => getTransactionCountByHeader(filter)}
              backgroundColor={`${headerSelected === filter ? '#FF5A60' : ''}`}
            >
              {filter.isHome && <FontAwesomeIcon className='mr-2' icon={faHome} />}
              <FormattedMessage id={`home_filter_header_label_${filter.id}`} />
            </SubmitButton>
          ))
        }
      </CardBlock>
      <CardBlock>
        <header className='row mb-3'>
          <div className='d-flex justify-content-end w-100'>

            <div className='w-50 text-right'>
              {
                headerSelected?.buttons
                  .map(elem => (
                    <SubmitButton key={elem.buttonText} className='btn btn-primary mr-3 mb-2 procedure-btn' onClick={() => props.history.push(elem.buttonUrl)}>
                      {elem.buttonText}
                    </SubmitButton>
                  ))
              }
            </div>
          </div>
          <div className='d-flex justify-content-end w-100'>
            {headerSelected?.id === 'deposit' &&
              <SubmitButton className='btn btn-link text-primary mr-4' onClick={() => setShowModalObservations(true)}>
                <FormattedMessage id='home_button_observations' />
              </SubmitButton>}
            <Dropdown
              className='nav-primary'
              items={user.isManager ? [...ACCESS_DEMAND_ITEMS, ACCESS_DEMAND_ITEM_ATTACH_PM] : ACCESS_DEMAND_ITEMS}
              name={<FormattedMessage id='ets_account_request_ask_for_access' />}
              buttonClassName='text-right text-primary'
              onSelect={(demand: ETSAccountNavInterface) => props.history.push(demand.url)}
              arrow
            />
          </div>
        </header>
        <div>
          <BasketCountItems isForDashboard headerBasketSelected={headerSelected} />
        </div>
      </CardBlock>
      <ModalComponent
        size='xl'
        hideFooter
        show={showModalObservations}
        handleClose={() => setShowModalObservations(false)}
        title={<FormattedMessage id='home_button_observations' />}
        customContent={() => <Observations setShowModal={setShowModalObservations} />}
        validateLabel={intl.formatMessage({ id: 'common_validate' })}
      />
    </div>
  )
}

export default withRouter(Home)
