import { CardBlock, SubmitButton } from '@inpi-marques/components'
import { BrandType } from 'constants/BrandConstants'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useSelector } from 'react-redux'

interface BrandTypeItemProps {
    brandType: BrandType,
    className?: string,
    onExampleClick: (brandType: BrandType) => void,
    onSelect: (brandType: BrandType, mode: string) => void,
    active: boolean,
}

const BrandTypeItem: FC<BrandTypeItemProps> = ({ brandType, className, onExampleClick, onSelect, active }) => {
  const guidedMode = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode)

  return (
    <div className={`brand-type__item ${className ?? ''}`}>
      <CardBlock className={active ? 'active' : ''} bodyClassName='p-0 justify-content-between' shadow>
        <header>
          <div className='brand-type__item-title'><FormattedMessage id={brandType.title} /></div>
          {
            brandType.displayIfGuidedMode && guidedMode?.activeGuidedMode &&
              <button className='btn btn-link color-primary' onClick={() => onSelect(brandType, 'isDiagnosis')}><FormattedMessage id='brand_type_link_isDiagnosis' /></button>
          }
        </header>
        <div className='brand-type__item-description mt-2'>
          <FormattedMessage id={brandType.description} />
        </div>
        <div className='d-flex mt-4'>
          <SubmitButton
            className={`btn-select ${active ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => onSelect(brandType, 'isEditing')}
          >
            <FormattedMessage id={active ? 'button_edit' : 'button_select'} />
          </SubmitButton>
          {brandType.examples && <button className='btn btn-link btn-link-gris ml-3' onClick={() => onExampleClick(brandType)}> <FormattedMessage id='button_example' /> </button>}
        </div>
      </CardBlock>
    </div>
  )
}

export default BrandTypeItem
