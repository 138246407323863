import { Contributor } from '../../interfaces/contributors/ContributorsInterfaces'
import PersonneFieldValidator from './PersonneFieldValidator'
import AddressFieldsValidator from './AddressFieldsValidator'
import Message from '../../constants/Message'
import { boolIsFilled, isFilled, messageIsFilled } from '../../utils/validationUtils'
import { PERSONNE_MORALE, PERSONNE_PHYSIQUE } from '../../constants/contributors/PersonConstants'
import {
  CONTRIBUTOR_ADDRESS_BOOK,
  CONTRIBUTOR_AGENT,
  CONTRIBUTOR_AGENT_DEPOSITOR,
  CONTRIBUTOR_AGENTS_TMC,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_HOLDERS,
  CONTRIBUTOR_HOLDERS_CONTESTED_MARK,
  CONTRIBUTOR_OLD_HOLDERS,
  CONTRIBUTOR_OLD_LICENSEES,
  CONTRIBUTOR_OPPONENTS,
  CONTRIBUTOR_OTHER_APPLICANTS,
  CONTRIBUTOR_PAYMENT,
  CONTRIBUTOR_RECIPIENT,
  CONTRIBUTOR_RECIPIENTS_TMC, CONTRIBUTOR_SIGNATORY
} from '../../constants/contributors/ContributorsConstants'
import { FieldStatus } from '../../form/FormInterfaces'
import { Transaction, TransactionDocument } from '../../interfaces/Transaction'
import { DEPOSIT_TYPES, DEPOSIT_TYPE_COLLECTIVE } from '../../constants/DepositConstants'
import { PROCEDURE_FRMI } from '../../constants/ProcedureConstants'
import { Country } from '../../interfaces/frmi/FRMI'
import { FRANCE, UE } from '../../constants/contributors/AddressConstants'
import {
  FRMI_TYPE_EXTENSION,
  FRMI_TYPE_HOLDER_CHANGE,
  FRMI_TYPE_HOLDER_EDITION,
  FRMI_TYPE_LICENCE_EDITION,
  FRMI_TYPE_POST_DESIGNATION_REGISTER
} from '../../constants/FRMIConstants'
import { DepositType, TransactionService } from '../../index'
import FRMIService from '../frmi/FRMIService'
import { PROCEDURE_OFFICIAL_DOCUMENT } from '@inpi-marques/components'
/* global localStorage */

class ContributorValidator {
    /**
     * Valide les informations d'un intervenant d'un dépôt
     *
     * @param contributor L'intervenant à valider
     * @param transaction
     * @param contributorType
     * @param isBO
     * @param countriesChosen Les pays sélectionnés en cas de procédure FRMI
     * @param isFirst
     * @return Un objet FieldStatus contenant le résultat de la validation de chaque champ
     */
    validateContributor = (contributor: Contributor = {}, transaction?: Transaction, contributorType?: string, isBO: boolean = false, countriesChosen?: Country[], isFirst?: boolean): FieldStatus => {
      const contributorAddress = contributor.address || {}
      const addressValidate = transaction && TransactionService.isFrmiOP(transaction) && contributorType === CONTRIBUTOR_OTHER_APPLICANTS.value && contributor.code === CONTRIBUTOR_OLD_HOLDERS.value ? {} : AddressFieldsValidator.validateMandatory(contributorAddress, transaction?.procedureType, contributor)
      return {
        ...PersonneFieldValidator.validateAll(contributor, transaction, contributorType, isBO, true),
        ...addressValidate,
        otherLanguage: transaction?.procedureType === PROCEDURE_FRMI.value && (transaction?.subProcedureType === FRMI_TYPE_EXTENSION.value ||
          transaction?.subProcedureType === FRMI_TYPE_POST_DESIGNATION_REGISTER.value) && countriesChosen && countriesChosen.some((country: Country) => country.code === UE) && isFirst
          ? messageIsFilled(contributor?.otherLanguage) : '',
        OMPILanguage: transaction?.procedureType === PROCEDURE_FRMI.value && (transaction?.subProcedureType === FRMI_TYPE_EXTENSION.value || (transaction?.subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value && contributorType === CONTRIBUTOR_HOLDERS.value)) && isFirst
          ? messageIsFilled(contributor?.OMPILanguage) : ''
      } as FieldStatus
    }

  /**
   * Valide les informations d'un destinataire d'un dépôt
   *
   * @param contributor L'intervenant à valider
   * @param procedure
   * @return Un objet FieldStatus contenant le résultat de la validation de chaque champ
   */
  validateContributorRecipient = (contributor: Contributor = {}, procedure?: string): FieldStatus => {
    const contributorAddress = contributor.address || {}
    const askConsent = localStorage.getItem('ELECTRONIC_NOTIF') === 'true' && procedure !== PROCEDURE_OFFICIAL_DOCUMENT.value

    return {
      ...PersonneFieldValidator.validateAllRecipient(contributor, procedure),
      ...AddressFieldsValidator.validateMandatory(contributorAddress),
      consentNotificationByEmail: askConsent ? boolIsFilled(contributor.consentNotificationByEmail) : ''
    }
  }

  /**
   * Valide les informations d'un destinataire
   * @param recipient
   * @param isBo
   */
  validateRecipient = (recipient: Contributor = {}, isBo: boolean = false) => ({
    ...this.validateContributorRecipient(recipient, undefined),
    firstname: recipient.type === PERSONNE_PHYSIQUE.value && messageIsFilled(recipient.firstname),
    lastname: recipient.type === PERSONNE_PHYSIQUE.value && messageIsFilled(recipient.lastname),
    civilityCode: !isBo && recipient.type === PERSONNE_PHYSIQUE.value && messageIsFilled(recipient.civilityCode)
  })

  /**
   * Valide les informations d'un mandataire
   * @param agent
   * @param transaction
   * @param contributorType
   * @param isBO
   */
  validateAgentContributor = (agent: Contributor = {}, transaction: Transaction, contributorType?: string, isBO: boolean = false) => ({
    ...PersonneFieldValidator.validateAllAgent(agent, transaction, contributorType, isBO),
    ...AddressFieldsValidator.validateMandatory(agent.address || {}),
    firstname: PersonneFieldValidator.validateFirstName(agent, contributorType !== CONTRIBUTOR_AGENTS_TMC.value),
    lastname: PersonneFieldValidator.validateLastName(agent, contributorType !== CONTRIBUTOR_AGENTS_TMC.value),
    civilityCode: isBO ? '' : messageIsFilled(agent.civilityCode),
    manageableQuality: contributorType === CONTRIBUTOR_AGENTS_TMC.value && isBO ? '' : messageIsFilled(agent.manageableQuality?.code),
    inscriptionNumber: agent.manageableQuality?.numberNeeded && !isFilled(agent.inscriptionNumber) ? Message.required_field : '',
    powerDocument: agent.manageableQuality?.powerNeeded && !transaction.documents?.find((document: TransactionDocument) => (
          agent?.internalNameDocuments && agent?.internalNameDocuments.length > 0 && document.internalName === agent?.internalNameDocuments[0])) ? Message.required_field : ''
  })

  /**
   * Valide les informations d'un mandataire et s'il est obligatoire
   * @param transaction
   * @param agent
   * @param contributorType
   * @param isBO
   */
  validateAgent = (transaction: Transaction, agent: Contributor, contributorType?: string, isBO: boolean = false) => {
    let fieldStatusAgent = {}

    if (agent) {
      fieldStatusAgent = this.validateAgentContributor(agent, transaction, contributorType, isBO)
    } else if (
      (transaction.depositors && transaction.depositors?.length > 1 && (!TransactionService.isInscription(transaction) || TransactionService.isRenunciation(transaction)))) {
      // mandataire obligatoire si des déposants sont présents
      fieldStatusAgent = this.validateAgentContributor({ type: PERSONNE_PHYSIQUE.value }, transaction, contributorType, isBO)
    }

    return fieldStatusAgent
  }

  /**
   * Valide la qualité d'un signataire
   * @param signatory
   * @param agent
   * @param hasAgentStep
   */
  validateSignatoryManageableQuality = (signatory: Contributor = {}, agent?: Contributor, hasAgentStep: boolean = true) => {
    if (messageIsFilled(signatory.manageableQuality?.code) !== '') {
      return messageIsFilled(signatory.manageableQuality?.code)
    } else if (signatory.manageableQuality?.agentNeeded && !agent && hasAgentStep) {
      return Message.error_agent_needed
    }

    return ''
  }

  /**
   * Valide les informations d'un signataire
   * @param signatory
   * @param documents
   * @param agent
   * @param isBO
   * @param hasAgentStep
   */
  validateSignatory = (signatory: Contributor = {}, documents: TransactionDocument[]|undefined, agent?: Contributor, isBO: boolean = false, hasAgentStep: boolean = true) => ({
    firstname: PersonneFieldValidator.validateFirstName(signatory),
    lastname: PersonneFieldValidator.validateLastName(signatory),
    civilityCode: isBO ? '' : PersonneFieldValidator.validateCivility(signatory),
    email: PersonneFieldValidator.validateEmail(signatory),
    manageableQuality: this.validateSignatoryManageableQuality(signatory, agent, hasAgentStep),
    inscriptionNumber: signatory.manageableQuality?.numberNeeded && !isFilled(signatory.inscriptionNumber) ? Message.required_field : '',
    powerDocument: signatory.manageableQuality?.powerNeeded && !documents?.find((document: TransactionDocument) => (
        signatory?.internalNameDocuments && signatory?.internalNameDocuments.length > 0 && document.internalName === signatory?.internalNameDocuments[0])) ? Message.required_field : ''
  })

  /**
   * Supprime les attributs qui ne correspondent pas au type de l'intervenant
   * @param contributor
   * @param contributorType
   * @param oldContributor
   * @param subProcedureType
   * @param isAdded
   * @param isEdited
   */
  cleanCopyContributor = (contributor: Contributor = {}, contributorType: string, oldContributor?: Contributor, subProcedureType?: string, isAdded?: boolean, isEdited?: boolean): Contributor => {
    if (contributorType === CONTRIBUTOR_AGENT.value || contributorType === CONTRIBUTOR_AGENT_DEPOSITOR.value) {
      contributor.type = PERSONNE_PHYSIQUE.value
      contributor.legalForm = undefined
      contributor.siren = undefined
    } else if (contributorType === CONTRIBUTOR_DEPOSITORS.value ||
        contributorType === CONTRIBUTOR_OPPONENTS.value ||
        contributorType === CONTRIBUTOR_HOLDERS_CONTESTED_MARK.value ||
        contributorType === CONTRIBUTOR_ADDRESS_BOOK.value ||
        contributorType === CONTRIBUTOR_PAYMENT.value) {
      if (subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value && contributorType === CONTRIBUTOR_DEPOSITORS.value) {
        contributor.type = PERSONNE_MORALE.value
      }
      if (contributor.type === PERSONNE_PHYSIQUE.value) {
        contributor.companyName = undefined
        contributor.legalForm = undefined
        contributor.siren = undefined
      } else if (contributor.type === PERSONNE_MORALE.value) {
        contributor.firstname = undefined
        contributor.lastname = undefined
        contributor.civilityCode = undefined
      }
    } else {
      if (contributor.type === PERSONNE_PHYSIQUE.value) {
        contributor.companyName = undefined
        contributor.legalForm = undefined
        contributor.siren = undefined
      }
    }

    if (contributorType === CONTRIBUTOR_RECIPIENT.value || contributorType === CONTRIBUTOR_RECIPIENTS_TMC.value) {
      contributor.personFormation = undefined
      contributor.legalFormFormation = undefined
      contributor.companyNameFormation = undefined
      contributor.descriptionFormation = undefined
    }

    // On persiste si l'intervenant a été ajouté ou modifié
    if ((contributorType === CONTRIBUTOR_OLD_LICENSEES.value && subProcedureType === FRMI_TYPE_LICENCE_EDITION.value) ||
        (contributorType === CONTRIBUTOR_OLD_HOLDERS.value && subProcedureType === FRMI_TYPE_HOLDER_EDITION.value)) {
      if (isAdded || isEdited) {
        contributor.isOriginal = false
        contributor.state = isAdded ? 'ADDED' : 'EDITED'
      }
    }

    // On persiste si l'intervenant a été ajouté ou modifié
    if (subProcedureType && FRMIService.isFromFRMIExtension(subProcedureType) && (contributorType === CONTRIBUTOR_OLD_HOLDERS.value || contributorType === CONTRIBUTOR_HOLDERS.value || contributorType === CONTRIBUTOR_DEPOSITORS.value)) {
      contributor.otherLanguage = undefined
      contributor.OMPILanguage = undefined
    }

    // On enlève l'adresse et la nationalité si c'est pour un titulaire actuel
    if (contributorType === CONTRIBUTOR_OLD_HOLDERS.value) {
      contributor.address = undefined
      contributor.nationality = undefined
    }

    contributor.id = undefined

    // on persiste le consentement quand on copie un intervenant
    if (oldContributor) {
      contributor.consentNotificationByEmail = oldContributor.consentNotificationByEmail
      if (oldContributor.isOriginal !== undefined) {
        contributor.isOriginal = oldContributor.isOriginal
      }
      if (oldContributor.state !== undefined) {
        contributor.state = oldContributor.state
      }

      // On supprime la qualité si l'intervenant n'est pas un mandataire ou signataire
      if (contributorType !== CONTRIBUTOR_AGENT.value && contributorType !== CONTRIBUTOR_SIGNATORY.value) {
        contributor.manageableQuality = undefined
      }
    }

    // On force la maj du type d'intervenant (surtout dans le cas compte ETS)
    contributor.code = contributorType
    contributor.state = undefined

    contributor.hasBeenModifiedByInscription = false
    // On supprime la nationalité si ce n'est pas une personne physique hors dépôt ou alors si on ne copie pas dans les déposants
    const isDeposit = DEPOSIT_TYPES.some((type: DepositType) => type.value === subProcedureType)
    if (isDeposit && contributorType === CONTRIBUTOR_DEPOSITORS.value && !contributor.nationality) {
      contributor.nationality = FRANCE
    } else if ((contributor.type !== PERSONNE_PHYSIQUE.value && !isDeposit) || contributorType !== CONTRIBUTOR_DEPOSITORS.value) {
      contributor.nationality = undefined
    }
    return contributor
  }
}

export default new ContributorValidator()
