import { PROCEDURE_DEPOSIT, PROCEDURE_RENEWAL, Transaction } from '@inpi-marques/components'
import {
  TransactionAction,
  TRANSACTION_INIT,
  TRANSACTION_INIT_DEPOSIT,
  TRANSACTION_REMOVE,
  TRANSACTION_UPDATE,
  TRANSACTION_UPDATE_DEPOSIT,
  BTN_GUIDED_MODE_UPDATE,
  BTN_CONTRIBUTOR_UPDATE,
  TRANSACTION_UPDATE_OPPOSITION,
  TRANSACTION_RESET_DEPOSIT,
  TRANSACTION_UPDATE_INSCRIPTION,
  TRANSACTION_UPDATE_FRMI,
  TRANSACTION_INIT_FRMI,
  TRANSACTION_UPDATE_OFFICIAL_DOCUMENT_TYPE,
  TRANSACTION_UPDATE_RENEWAL,
  TRANSACTION_UPDATE_REVOCATION_STATEMENT,
  TRANSACTION_INIT_RENEWAL
} from './transactionTypes'
import TransactionService from '../../services/transaction/TransactionService'

const defaultGuidedModeState = {
  choiceContributor: '',
  activeGuidedMode: true,
  restrictedQualities: []
}

export function reducerTransactionState (state: Transaction = {}, action: TransactionAction): Transaction {
  switch (action.type) {
    case TRANSACTION_UPDATE:
      return action?.onlyApiTransaction && action.transaction ? action.transaction : TransactionService.getUpdatedTransaction(state, action.transaction)
    case TRANSACTION_REMOVE:
      return {}
    case TRANSACTION_INIT:
      return {
        guidedMode: {
          ...defaultGuidedModeState,
          activeGuidedMode: action.transactionType === PROCEDURE_DEPOSIT.value
        },
        procedureType: action.transactionType,
        subProcedureType: action.subProcedureType
      }
    case TRANSACTION_UPDATE_DEPOSIT:
      return {
        ...state,
        deposit: {
          ...state.deposit,
          ...action.deposit
        }
      }
    case TRANSACTION_RESET_DEPOSIT:
      return {
        ...state,
        deposit: action.deposit
      }
    case TRANSACTION_INIT_DEPOSIT:
      return {
        ...state,
        subProcedureType: action.subProcedureType,
        deposit: {}
      }

    case BTN_GUIDED_MODE_UPDATE:
      return {
        ...state,
        guidedMode: {
          ...state.guidedMode,
          activeGuidedMode: !state.guidedMode?.activeGuidedMode
        }
      }

    case BTN_CONTRIBUTOR_UPDATE:
      return {
        ...state,
        guidedMode: {
          ...state.guidedMode,
          restrictedQualities: action.guidedMode.restrictedQualities,
          choiceContributor: action.guidedMode.choiceContributor
        }
      }
    case TRANSACTION_UPDATE_OPPOSITION:
      return {
        ...state,
        opposition: action.opposition
      }
    case TRANSACTION_UPDATE_INSCRIPTION:
      return {
        ...state,
        inscription: action.inscription
      }
    case TRANSACTION_UPDATE_FRMI:
      return {
        ...state,
        frmiDetails: action.frmi
      }
    case TRANSACTION_INIT_FRMI:
      return {
        ...state,
        subProcedureType: action.subProcedureType,
        frmiDetails: action.frmi,
        recipient: action.recipient
      }
    case TRANSACTION_UPDATE_OFFICIAL_DOCUMENT_TYPE:
      return {
        ...state,
        officialDocumentType: action.officialDocumentType
      }
    case TRANSACTION_UPDATE_REVOCATION_STATEMENT:
      return {
        ...state,
        revocationStatement: action.revocationStatement
      }
    case TRANSACTION_UPDATE_RENEWAL:
    {
      return {
        ...state,
        renewalDetails: action.renewal
      }
    }
    case TRANSACTION_INIT_RENEWAL:
      return {
        ...state,
        procedureType: PROCEDURE_RENEWAL.value,
        renewalDetails: action.renewal
      }
    default:
      return state
  }
}
