import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { FormattedMessage, injectIntl, useIntl, WrappedComponentProps } from 'react-intl'
import {
  CardBlock,
  DownloadLink,
  EventType,
  FRMI_TYPES,
  INSCRIPTION_TYPES,
  ListLoader,
  ModalComponent,
  NoResult,
  ORDER_ASC,
  ORDER_DESC,
  PROCEDURE_DEPOSIT,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURES,
  PROCEDURES_LOT_2,
  Record,
  RecordSearchResult,
  ROWS,
  ROWS_25,
  SelectOption,
  SubmitButton,
  Table,
  TableOrderBy,
  Transaction,
  TransactionSearchParameters,
  TransactionSearchResult,
  PAGE_SIZE_CSV_DOWNLOAD,
  TableTitleItem,
  BUTTON_DELETE
} from '@inpi-marques/components'
import { BasketFilter, BasketInterface } from '../../interfaces/BasketInterface'
import TransactionService from '../../services/transaction/TransactionService'
import TransactionListTableBody from './TransactionListTableBody'
import { TABLE_TRANSACTION_LIST } from '../../constants/BasketConstants'
import { DEPOSIT_TYPES } from '../../constants/DepositConstant'
import RecordService from 'services/opposition/RecordService'
import { TRANSACTION_TABLE_ORDER_BY } from '@inpi-marques/components/src/constants/TableConstant'
import TransactionListTableHeader from './TransactionListTableHeader'

const DEFAULT_BASKET : BasketInterface = {
  id: 'all',
  urlParam: '',
  statusFiltres: [],
  headers: TABLE_TRANSACTION_LIST,
  forProcedures: []
}

interface TransactionListProps extends WrappedComponentProps {
  subtitle?: ReactNode,
  basket?: BasketInterface,
  headerBasketSelected?: BasketFilter,
  searchText?: string,
  procedureSearch?: string,
  getTransactionCountByHeader?: (filter?: BasketFilter) => any
}

const TransactionList: FC<TransactionListProps> = ({
  subtitle,
  basket = DEFAULT_BASKET,
  headerBasketSelected,
  searchText,
  procedureSearch,
  getTransactionCountByHeader
}) => {
  const intl = useIntl()
  const [list, setList] = useState<Transaction[]|Record[]>([])
  const [pageSize, setPageSize] = useState(ROWS_25.value)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [search, setSearch] = useState(searchText)
  const [isFirstCall, setIsFirstCall] = useState<boolean>(true)
  const [procedure, setProcedure] = useState(procedureSearch ?? '')
  const [subProcedureType, setSubProcedureType] = useState()
  const [sortBy, setSortBy] = useState<TableOrderBy|undefined>(basket.serviceType === 'records' ? undefined : (basket.urlParam === 'en-cours-examen' ? { field: 'arrivalDate', order: ORDER_DESC } : TRANSACTION_TABLE_ORDER_BY))
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState<boolean>(false)
  const [idToDelete, setIdToDelete] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [optionSubProcedure, setOptionSubProcedure] = useState<SelectOption[]>([])
  const procedures = process.env.REACT_APP_LOT_2 === 'true' ? PROCEDURES_LOT_2 : PROCEDURES

  useEffect(() => {
    if (headerBasketSelected?.procedures && headerBasketSelected.procedures.length === 1) {
      setProcedure(headerBasketSelected.procedures[0])
    }
  }, [headerBasketSelected])

  useEffect(() => {
    searchTransactions()
    return () => {
      TransactionService.cancelRequest()
    }
  }, [pageSize, procedure, page, sortBy, subProcedureType])

  useEffect(() => {
    setSearch(searchText)
  }, [searchText])

  useEffect(() => {
    if (procedure === PROCEDURE_DEPOSIT.value) {
      setOptionSubProcedure(DEPOSIT_TYPES.map(type => ({ ...type, label: intl.formatMessage({ id: `sub_procedure_${type.label}` }) })))
    } else if (procedure === PROCEDURE_INSCRIPTION.value) {
      setOptionSubProcedure(INSCRIPTION_TYPES.map(type => ({ ...type, label: intl.formatMessage({ id: type.label }) })))
    } else if (procedure === PROCEDURE_FRMI.value) {
      setOptionSubProcedure(FRMI_TYPES)
    } else {
      setOptionSubProcedure([])
    }
  }, [procedure])

  /**
   * Recherche les transactions
   */
  const searchTransactions = (download? : boolean) => {
    !download && setIsLoading(true)
    let params : TransactionSearchParameters = {
      pageSize,
      page,
      procedures: procedure ? [procedure] : headerBasketSelected?.procedures,
      subProcedureType: subProcedureType && [PROCEDURE_FRMI.value, PROCEDURE_INSCRIPTION.value, PROCEDURE_DEPOSIT.value].includes(procedure) ? [subProcedureType] : undefined,
      search,
      order: sortBy?.order,
      sortBy: sortBy?.field
    }
    if (basket.isNotif) {
      params = { ...params, notifStatus: basket.statusFiltres }
    } else if (!basket.isOther) {
      params = { ...params, status: basket.statusFiltres }
    }
    if (basket.searchParams) {
      params = { ...params, ...basket.searchParams }
    }

    if (download) {
      params = {
        ...params,
        pageSize: PAGE_SIZE_CSV_DOWNLOAD,
        asCsv: true
      }
    }

    if (basket.headers) {
      params = {
        ...params,
        fieldPaths: basket.headers
          .filter((header: TableTitleItem) => header.value !== BUTTON_DELETE)
          .map((header: TableTitleItem) => (header.value as string) ?? '')
      }
    }

    if (basket.serviceType === 'records') {
      return RecordService.searchRecord(params).then((result: RecordSearchResult) => {
        if (!download) {
          if (result && result.records && result.records.length) {
            setPage(result.page)
            setMaxPage(result.maxPage)
            setPageSize(result.pageSize)
            setList(result.records)
          } else {
            setList([])
          }
          setIsLoading(false)
          setIsFirstCall(false)
        } else {
          return result
        }
      })
    } else {
      return TransactionService.searchTransaction(params)
        .then((result: TransactionSearchResult) => {
          if (!download) {
            if (result && result.transactions && result.transactions.length) {
              setPage(result.page)
              setMaxPage(result.maxPage)
              setPageSize(result.pageSize)
              setList(result.transactions)
            } else {
              setList([])
            }
            setIsFirstCall(false)
            setIsLoading(false)
          } else {
            return result
          }
        })
    }
  }

  /**
   * Recherche sur les transactions si on modifie le champs 'search' mais pas immédiatement --> debounce
   */
  const delayedSearch = useCallback(debounce(searchTransactions, 500), [search])

  useEffect(() => {
    // Pas besoin de lancer la recherche à l'initialisation de la page
    if (!isFirstCall && search !== undefined) {
      delayedSearch()
    }
    return delayedSearch.cancel
  }, [delayedSearch])

  /**
   * Met à jour le tris
   * @param field
   */
  const handleChangeOrderBy = (field?: string) => {
    let updatedSort

    if (field) {
      if (!sortBy || sortBy.field !== field) {
        updatedSort = {
          field,
          order: ORDER_ASC
        }
      } else if (sortBy.order === ORDER_ASC) {
        updatedSort = {
          field,
          order: ORDER_DESC
        }
      }
      setPage(1)
    }

    setSortBy(updatedSort)
  }

  /**
   * Supprime une transaction (pour les transaction en brouillon)
   */
  const onDeleteTransaction = async () => {
    if (!idToDelete) {
      return
    }

    await TransactionService.deleteTransaction(idToDelete)
    await searchTransactions()
    getTransactionCountByHeader && getTransactionCountByHeader(headerBasketSelected)
    // Ferme la popin
    setShowDeleteConfirmation(false)
  }

  /**
   * Supprime toutes les transactions en brouillon
   */
  const onDeleteAllTransaction = async () => {
    await TransactionService.deleteDraftTransactionsByProcedures({
      procedures: procedure ? [procedure] : headerBasketSelected?.procedures,
      subProcedureType: subProcedureType && procedure === PROCEDURE_DEPOSIT.value ? [subProcedureType] : undefined
    })
    await searchTransactions()
    getTransactionCountByHeader && getTransactionCountByHeader(headerBasketSelected)
    setShowDeleteAllConfirmation(false)
  }

  /**
   * Fonction pour afficher la popin demandant confirmation de suppression d'une transaction
   */
  const modalBeforeDeleteTransaction = () => {
    return (
      <div className='row'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <SubmitButton
            className='btn btn-outline-secondary mt-4'
            onClick={() => setShowDeleteConfirmation(false)}
          >
            <FormattedMessage id='button_cancel' />
          </SubmitButton>
          <SubmitButton
            className='btn btn-outline-primary mt-4'
            onClick={onDeleteTransaction}
          >
            <FormattedMessage id='button_delete_draft' />
          </SubmitButton>
        </div>
      </div>
    )
  }

  /**
   * Fonction pour afficher la popin demandant confirmation de suppression de toutes les transactions
   */
  const modalBeforeDeleteAllTransaction = () => {
    return (
      <div className='row'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <SubmitButton
            className='btn btn-outline-secondary mt-4'
            onClick={() => setShowDeleteAllConfirmation(false)}
          >
            <FormattedMessage id='button_cancel' />
          </SubmitButton>
          <SubmitButton
            className='btn btn-outline-primary mt-4'
            onClick={onDeleteAllTransaction}
          >
            <FormattedMessage id='button_delete_all_draft' />
          </SubmitButton>
        </div>
      </div>
    )
  }

  const renderTable = () => (
    <div className='mt-5'>
      <FormattedMessage id='click_on_headers_to_search' />
      <Table
        tableTitle={basket?.headers || []}
        onSort={handleChangeOrderBy}
        totalPage={maxPage}
        actualPage={page}
        onChangePagination={(page) => setPage(page)}
        nbShown={ROWS}
        valueSelect={pageSize}
        onChangeSelect={(event) => {
          setPage(1)
          setPageSize(parseInt(event.target.value, 10))
        }}
        sortBy={sortBy}
        colorHover
        onDeleteAll={() => {
          setShowDeleteAllConfirmation(true)
        }}
      >
        <TransactionListTableBody
          list={list}
          basket={basket}
          onDelete={(idTransactionToDelete: string) => {
            setIdToDelete(idTransactionToDelete)
            setShowDeleteConfirmation(true)
          }}
          headerBasketSelected={headerBasketSelected}
        />
      </Table>
      <DownloadLink
        label={<FormattedMessage id='button_export_transaction_list' />}
        onClick={() => { return searchTransactions(true) }}
      />
    </div>

  )

  const renderNoResult = () => (
    <div className='mt-4'>
      <NoResult />
      <div className='d-flex justify-content-center align-items-center text-gris'>
        <FormattedMessage id='help_search_no_result' />
      </div>
    </div>
  )

  /**
   * On affiche la liste de sélection de procédures uniquement pour les transactions et non les records.
   */
  let optionProcedure: SelectOption[] = []
  if (basket.serviceType === 'transactions') {
    optionProcedure = headerBasketSelected?.procedures ? procedures.filter(procedure => headerBasketSelected?.procedures?.includes(procedure.value)) : procedures
  }

  return (
    <CardBlock>
      <header className='ml-3 mb-4 d-flex justify-content-between'>
        <div>
          <h1><FormattedMessage id={`basket_label_${basket.id}`} /></h1>
          {
            subtitle && <div className='subtitle'>{subtitle}</div>
          }
        </div>
      </header>
      <TransactionListTableHeader
        onSearch={(event: EventType) => {
          setSearch(event.target.value)
          setPage(1)
        }}
        valueSearch={search}
        onSelectProcedure={(event) => setProcedure(event.target.value)}
        valueSelectProcedure={procedure}
        optionsProcedure={optionProcedure}
        onSelectSubProcedureType={(event) => setSubProcedureType(event ? event.target.value : undefined)}
        valueSelectSubProcedureType={subProcedureType}
        optionsSubType={optionSubProcedure}
      />
      {
        list || isLoading ? (
          isLoading ? <ListLoader />
            : (list && list.length > 0 ? renderTable() : renderNoResult())
        ) : null
      }
      <ModalComponent
        title={<FormattedMessage id='popin_delete_draft_title' />}
        customContent={() => modalBeforeDeleteTransaction()}
        handleClose={() => setShowDeleteConfirmation(false)}
        show={showDeleteConfirmation}
        hideFooter
      />
      <ModalComponent
        title={<FormattedMessage id='popin_delete_all_draft_title' />}
        customContent={() => modalBeforeDeleteAllTransaction()}
        handleClose={() => setShowDeleteAllConfirmation(false)}
        show={showDeleteAllConfirmation}
        hideFooter
        size='lg'
      />
    </CardBlock>
  )
}

export default injectIntl(TransactionList)
