import {
  containsErrors,
  EventType,
  Transaction,
  TransactionDocument,
  FieldStatus,
  FileButton,
  FileBrowserField,
  DOCUMENT_FORMATS,
  Payment as IPayment,
  CardBlock,
  DOCUMENT_TYPES,
  DOCUMENT_DEFAULT,
  downloadFile,
  HelpBlock
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import DocumentValidator from '../requests/valitdator/DocumentValidator'
import { FormattedMessage } from 'react-intl'
import TransactionAmountOverview from '../transactions/overview/TransactionAmount'
import PaymentChoices from '../payment/PaymentChoices'
import { useHistory } from 'react-router'
import PaymentService, { PaymentParameter } from '../../services/payment/PaymentService'
import { PAYMENT_METHODS, PAYMENT_OBJECTS, PAYMENT_URL_CORRECTION } from '../../constants/PaymentConstants'
import CorrectionService from '../../services/correction/CorrectionService'
import DocumentService from '../../services/document/DocumentService'

interface CorrectionProps {
  transaction: Transaction,
  setCorrectionHasDocument: (value: boolean) => void
}

const Correction: FC<CorrectionProps> = ({ transaction, setCorrectionHasDocument }) => {
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const [document, setDocument] = useState<TransactionDocument>()
  const [payment, setPayment] = useState<IPayment>()
  const history = useHistory()

  useEffect(() => {
    if (transaction.id) {
      CorrectionService.getPaiement(transaction.id).then((response: IPayment) => {
        setPayment(response)
      })
    }
  }, [])

  const onPaymentSubmit = async (parameter: PaymentParameter): Promise<void> => {
    const status = DocumentValidator.validateErratumDocument(document)
    setFieldStatus(status)

    if (!containsErrors(status)) {
      await DocumentService.createDocument(document, transaction.id)

      parameter = {
        ...parameter,
        label: 'Demande de correction',
        objectName: PAYMENT_OBJECTS.CORRECTION
      }
      try {
        // Paiement de la correction
        const createdPayment: string = await PaymentService.createPayment(transaction.id, parameter)
        if (parameter.method === PAYMENT_METHODS.BLUE_CARD) {
          PaymentService.displayPayBox(createdPayment)
        } else {
          const url = `/${PAYMENT_URL_CORRECTION}/${transaction.id}/paiement/confirmation`
          history.push(url)
        }
      } catch (error) {
        history.push(`/${PAYMENT_URL_CORRECTION}/${transaction.id}/paiement/erreur`)
      }
    }
  }

  /**
   * Ajoute le document en local
   * @param event
   */
  const handleAddDocument = (event: EventType) => {
    const file: File = event.target.value
    setDocument({
      ...document,
      type: DOCUMENT_TYPES.MATERIAL_IRREGULARITY,
      internalName: '',
      name: `EM-${file.name}`,
      format: file.type,
      communicability: DOCUMENT_DEFAULT,
      file,
      size: file.size
    })
    setCorrectionHasDocument(true)
  }

  /**
   * Supprime le document en local
   */
  const deleteCorrectionDocument = () => {
    if (!document.internalName) {
      setDocument({})
      setCorrectionHasDocument(false)
    }
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  return (
    <div className='is-validated'>
      <HelpBlock className='mb-3'>
        <FormattedMessage id='erratum_modal_description' />
      </HelpBlock>
      <div className='p-2'>
        <FileBrowserField
          inputId='file-correction'
          ulClassName='m-0'
          className='mb-5'
          acceptApplication={DOCUMENT_FORMATS.PDF}
          value={document?.name ? [document] : []}
          onChange={handleAddDocument}
          onDelete={document && !document.internalName && deleteCorrectionDocument}
          maxNumberFile={1}
          buttonLabel={<FileButton label={<FormattedMessage id='form_file_placeholder' />} />}
          label={<FormattedMessage id='correction_file' />}
          required
          nameFieldStatus='fileInputErratum'
          fieldStatus={fieldStatus}
          informationDoc
          handleDownload={handleDownload}
        />
        {payment &&
          <div className='mb-3'>
            <CardBlock header={<FormattedMessage id='overview_amount_title' />} shadow>
              <TransactionAmountOverview payment={payment} />
            </CardBlock>
          </div>}
        {payment && payment.totalAmount > 0 &&
          <div className='mb-3'>
            <PaymentChoices onPaymentSubmit={onPaymentSubmit} amount={payment.totalAmount} transaction={transaction} />
          </div>}
      </div>
    </div>
  )
}

export default Correction
