import { Inscription, InscriptionNatureCode } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface ActOverviewProps {
    inscription: Inscription
}

const ActOverview: FC<ActOverviewProps> = ({ inscription }) =>
  <div className='row'>
    <div className='col-6'>
      <h4 className='font-weight-bold'><FormattedMessage id='act_title_form_is_appeal_revocation_label' /></h4>
      <FormattedMessage id={inscription.isAppealOrRevocation ? 'common_yes' : 'common_no'} />
    </div>
    <div className='col-6'>
      <h4 className='font-weight-bold'><FormattedMessage id='act_title_form_is_renewal_label' /></h4>
      <FormattedMessage id={inscription.isRenewal ? 'common_yes' : 'common_no'} />
    </div>
    <div className='col-6 mt-2'>
      <h4 className='font-weight-bold'><FormattedMessage id='overview_nature_codes_label' /></h4>
      <p className='subtitle'>{inscription.natureCodes?.map((natureCode: InscriptionNatureCode) => natureCode.freeLabel || natureCode.label).join(', ')}</p>
    </div>
  </div>

export default ActOverview
