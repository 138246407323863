import React, { FC, useEffect, useState } from 'react'
import { ProtectionExtension, SwitchField, Transaction } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import ContentService from 'services/content/ContentService'
import StoreService from 'services/StoreService'

interface ProtectionExtensionsProps {
  transaction: Transaction
}

const ProtectionExtensions: FC<ProtectionExtensionsProps> = ({ transaction }) => {
  const [protectionExtensions, setProtectionExtensions] = useState<ProtectionExtension[]>(transaction.deposit?.protectionExtensions || [])

  useEffect(() => {
    ContentService.getProtectionExtensions().then((response) => {
      const result : ProtectionExtension[] = response.map((protectionExtension) => ({
        ...protectionExtension,
        activated: transaction.deposit?.protectionExtensions?.some(item => item.id === protectionExtension.id) || false
      }))
      setProtectionExtensions(result)
    })
  }, [])

  useEffect(() => {
    StoreService.changeStore({
      deposit: {
        ...transaction.deposit,
        protectionExtensions: protectionExtensions.filter((protectionExtension) => protectionExtension.activated)
      }
    })
  }, [protectionExtensions])

  const handleProtectionExtensionsChange = (checked: boolean, event : React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent, id: string) => {
    const index = protectionExtensions.findIndex((protectionExtension) => protectionExtension.id?.toString() === id)
    const newProtectionExtension = {
      ...protectionExtensions[index],
      activated: checked
    }
    const newList = [...protectionExtensions]
    newList[index] = { ...newProtectionExtension }
    setProtectionExtensions(newList)
  }

  return (
    <div>
      <h1 className='pb-4'><FormattedMessage id='options_extension_title' /></h1>
      <span className='subtitle'>
        <FormattedMessage id='options_extension_description' />
      </span>
      <div className='pt-4 pb-4'>
        {protectionExtensions.map((protectionExtension, key: number) =>
          <div className='row col-12' key={protectionExtension.id}>
            <SwitchField
              inputId={protectionExtension.id?.toString() || key.toString()}
              divClassName='col-md-12 align-items-center p-0 mt-3 mb-3'
              className='col-2'
              onChange={handleProtectionExtensionsChange}
              value={protectionExtension.activated}
              label={protectionExtension.label}
              width={50}
              height={30}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProtectionExtensions
