import { Payment } from '@inpi-marques/components/src/interfaces/deposit/Deposit'
import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import {
  PROCEDURE_DEPOSIT,
  STATUS_DEPOSIT,
  Transaction
} from '@inpi-marques/components'

class CorrectionService {
  intl: IntlShape

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  /**
   * Vérifie les conditions pour pouvoir effectuer une demande de correction
   * @param transaction
   */
  canAskForCorrection = (transaction: Transaction): boolean => {
    const canAskForCorrection = transaction.procedureType === PROCEDURE_DEPOSIT.value

    // Impossible si la transaction est en brouillon ou si elle est déjà enregistré ou rejetée
    if ([STATUS_DEPOSIT.DRAFT,
      STATUS_DEPOSIT.WAITING_VIR_PAYMENT,
      STATUS_DEPOSIT.REGISTERED,
      STATUS_DEPOSIT.REJECTED,
      STATUS_DEPOSIT.TOTAL_WITHDRAWAL,
      STATUS_DEPOSIT.TO_REGISTER,
      STATUS_DEPOSIT.REGISTERING].includes(transaction.status)) {
      return false
    }

    return canAskForCorrection
  }

  /**
   * Vérifie les conditions pour pouvoir effectuer une demande d'erratum
   * @param transaction
   */
  canAskForErratum = (transaction: Transaction): boolean => {
    // On ne peut faire des erratum que sur les Dépôts
    if (transaction.procedureType !== PROCEDURE_DEPOSIT.value) {
      return false
    }

    // On peut faire une demande erratum seulement si la transaction n'a aucun des statuts de la condition
    return ![STATUS_DEPOSIT.DRAFT, STATUS_DEPOSIT.WAITING_VIR_PAYMENT, STATUS_DEPOSIT.CHECK_ACCEPTABILITY, STATUS_DEPOSIT.TO_ATTRIBUTE_ACCEPTABILITY].includes(transaction.status)
  }

  /**
   * Récupère le récapitulatif de paiement d'une correction matérielle.
   * @param id
   * @returns
   */
  getPaiement = async (id: string): Promise<Payment> => {
    try {
      const result: Payment = await http.get(`/api/transactions/${id}/corrections/paiements`)
      return Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new CorrectionService()
