import React, { FC, useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import {
  DateUtils,
  downloadFile,
  NOTIF_TYPE_NO_RESPONSE_EXPECTED,
  Notification,
  NOTIFICATION_STATUS_CLOSED,
  NOTIFICATION_STATUS_COLLAPSE,
  NOTIFICATION_STATUS_TO_ANSWER,
  NOTIFICATION_STATUS_TO_CLOSE_SENT,
  NOTIFICATION_STATUS_TO_CONSIDER,
  NOTIFICATION_STATUS_CONSIDERED_FO,
  NotificationItem,
  SubmitButton,
  Transaction
} from '@inpi-marques/components'
import NotificationService from '../../../services/transaction/NotificationService'
import OverviewNotificationsInfo from './OverviewNotificationsInfo'
import NotificationsAnswerForm from './NotificationsAnswerForm'
import OverviewNotificationsAnswer from './OverviewNotificationsAnswer'

interface OverviewNotificationBodyProps {
  notification: Notification
  transaction: Transaction
  index: number
  setReload?: () => void
  scrollToProductAndService?: (id: string) => void
}

const OverviewNotificationBody: FC<OverviewNotificationBodyProps> = ({
  notification,
  transaction,
  index,
  setReload,
  scrollToProductAndService
}) => {
  const [notificationToAnswer, setNotificationToAnswer] = useState<Notification|null>(null)

  /**
   * Les premiers champs qui seront toujours visible, si on clique sur le chevron on ouvre l'accordéon
   * */
  const ContextAwareToggle: FC = ({ eventKey }) => {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey
    )
    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type='button'
        onClick={decoratedOnClick}
        className='bg-transparent border-0 col-1 btn btn-link-primary'
      >
        <FormattedMessage id={isCurrentEventKey ? 'overview_transaction_notification_close' : 'overview_transaction_notification_open'} />
      </button>
    )
  }

  /**
   * Lance le téléchargement de la notification au format pdf
   * */
  const downloadNotificationPDF = (notificationToDownload: Notification) => {
    // Cas d'une notification à lire
    if (!NOTIFICATION_STATUS_CLOSED.find((status) => status.value === notificationToDownload.status) &&
      notificationToDownload.type === NOTIF_TYPE_NO_RESPONSE_EXPECTED &&
      !notification.sendingMode?.includes('EDITIQUE')) {
      setNotificationToAnswer(notificationToDownload)
    }
    NotificationService.downloadNotification(transaction.id!, notificationToDownload.id).then((response: any) => {
      setReload && setReload()
      return downloadFile(response)
    })
  }

  return (
    <div key={notification.id} className='row mx-0 mb-3'>
      <Accordion
        className='w-100' defaultActiveKey={NOTIFICATION_STATUS_COLLAPSE.includes(notification.status) ? `collapse-notification-${index}` : ''}
      >
        <div className='header-accordion d-flex justify-content-between border-top pt-2 mt-2'>
          <NotificationItem className='col-2' label={<FormattedMessage id='overview_transaction_notification_title_label' />}>
            {notification.title}
          </NotificationItem>
          <NotificationItem className='col-2' label={<FormattedMessage id='overview_transaction_notification_received' />}>
            {DateUtils.formatDateFr(notification.sendingDate)}
          </NotificationItem>
          <NotificationItem className='col-2' label={<FormattedMessage id='overview_transaction_notification_receipt_date' />}>
            {NotificationService.getNotificationReceiptDate(notification) ?? '-'}
          </NotificationItem>
          <NotificationItem className='col-2' label={<FormattedMessage id='overview_transaction_notification_due_to' />}>
            {notification.deadline ? DateUtils.formatDateFr(notification.deadlineDateTime) : '-'}
          </NotificationItem>
          <NotificationItem className='col-2' label={<FormattedMessage id='overview_transaction_notification_status_label' />}>
            <FormattedMessage id={`overview_transaction_notification_status_${notification.status}`} />
          </NotificationItem>
          <div className='col-1 d-flex justify-content-center align-items-center'>
            {notification.file
              ? (
                <SubmitButton className='btn btn-link btn-override-width text-primary bg-transparent' onClick={() => downloadNotificationPDF(notification)}>
                  <FontAwesomeIcon icon={faDownload} className='mr-2' />
                </SubmitButton>
              ) : null}
          </div>
          <ContextAwareToggle eventKey={`collapse-notification-${index}`} />
        </div>
        <Accordion.Collapse eventKey={`collapse-notification-${index}`}>
          <div className='row mt-3'>
            <div className='col-6'>
              <OverviewNotificationsInfo notification={notification} download={() => downloadNotificationPDF(notification)} />
            </div>
            {
              notification.status === NOTIFICATION_STATUS_TO_ANSWER.value &&
                <div className='col-6'>
                  <NotificationsAnswerForm
                    initialNotification={notification}
                    index={index}
                    transaction={transaction}
                    setReload={setReload}
                    scrollToProductAndService={scrollToProductAndService}
                    answerNotification={notificationToAnswer?.id === notification.id}
                    setNotificationToAnswer={setNotificationToAnswer}
                  />
                </div>
            }
            {
              notification.status !== NOTIFICATION_STATUS_TO_CONSIDER.value &&
              notification.status !== NOTIFICATION_STATUS_CONSIDERED_FO.value &&
              notification.status !== NOTIFICATION_STATUS_TO_ANSWER.value &&
              notification.status !== NOTIFICATION_STATUS_TO_CLOSE_SENT.value &&
                <div className='col-6'>
                  <OverviewNotificationsAnswer
                    notification={notification}
                    index={index}
                    transaction={transaction}
                    scrollToProductAndService={scrollToProductAndService}
                  />
                </div>
            }
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  )
}

export default OverviewNotificationBody
