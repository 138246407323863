import store from '../../../../../store/store'
import {
  containsErrors,
  DOCUMENT_TYPES,
  TransactionDocument,
  FieldStatus,
  BRAND_TYPE_WORD,
  Transaction, isFilled
} from '@inpi-marques/components'
import Message from '../../../../../constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import { DOCUMENT_LIMITS } from '@inpi-marques/components/src/constants/DocumentConstants'
import TransactionService from '../../../../../services/transaction/TransactionService'
import SuiteValidator from 'component/suite/validator/SuiteValidator'

class DepositTypeValidator {
  isTransactionDocumentSizeValid = (document: TransactionDocument):boolean => {
    return !!(document.file && document.file.size < DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE)
  }

  getValidatedFieldStatus = (documents: TransactionDocument[], fieldStatus: FieldStatus, transaction: Transaction): FieldStatus => {
    const divisionFieldStatus: FieldStatus = {}

    if (!isFilled(transaction.deposit?.numNatToTransformOrDivide)) {
      divisionFieldStatus.nationalDepositNumber = Message.deposit_type_division_national_number_missing
    } else {
      if (!transaction?.deposit?.createdAtToTransformOrDivide) {
        divisionFieldStatus.nationalDepositNumber = Message.deposit_type_division_national_number_not_verified
      }
    }

    if (!transaction.deposit?.createdAtToTransformOrDivide) {
      divisionFieldStatus.nationalDepositDate = Message.deposit_type_date_required
    }

    if (!transaction.deposit?.brand || !transaction.deposit.brand.type) {
      divisionFieldStatus.nationalDepositType = Message.deposit_type_division_national_type_error
    } else {
      if (transaction.deposit.brand.type === BRAND_TYPE_WORD.value && !transaction.deposit.brand.text) {
        divisionFieldStatus.nationalDepositText = Message.deposit_type_division_national_text_error
      }
    }

    /** Taille du fichier traduction de la requête de transformation < 100Mo */
    const ndProof: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.BRAND_RECDIV_INVALIDITY_REVOCATION)
    if (transaction.deposit?.hasCurrentNullityRequest && !ndProof) {
      divisionFieldStatus.ndProof = Message.deposit_type_file_missing
    }

    divisionFieldStatus.position = SuiteValidator.validate(transaction, fieldStatus)

    return divisionFieldStatus
  }

  validateOnChangeStep = (documents: TransactionDocument[], fieldStatus: FieldStatus, transaction: Transaction): boolean => {
    const newFieldStatus: FieldStatus = this.getValidatedFieldStatus(documents, fieldStatus, transaction)
    store.dispatch(storeTransactionFieldStatusUpdate(newFieldStatus))
    return !containsErrors(newFieldStatus)
  }
}

export default new DepositTypeValidator()
