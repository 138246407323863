import React, { FC, useEffect, useState } from 'react'
import { Country, FieldProps, SelectField, SelectOption } from '../../index'

interface CountrySelectFieldProps extends FieldProps {
  required?: boolean,
  getCountries: () => Promise<void | Country[]>
  isPriority?: boolean,
  isMadrid?: boolean
}

const CountrySelectField: FC<CountrySelectFieldProps> = (props) => {
  const [countries, setCountries] = useState<SelectOption[]>([])

  useEffect(() => {
    let unmounted = false
    if (typeof props.getCountries === 'function' && countries.length === 0) {
      props.getCountries().then(result => {
        if (!unmounted && result) {
          if (props.isMadrid) {
            result = result.filter(country => country.isMadrid)
          } else if (props.isPriority) {
            result = result.filter(country => country.isPriority)
          } else {
            result = result.filter(country => country.isPostal)
          }

          setCountries(
            result.map(country => ({
              value: country.code || '',
              label: country.name || ''
            }))
          )
        }
      })
    }
    return () => {
      unmounted = true
    }
  }, [props.getCountries])

  return (
    <SelectField
      {...props}
      options={countries.sort((a, b) => (a.label && b.label && a.label.toString().localeCompare(b.label.toString())) || 0)}
    />
  )
}

export default CountrySelectField
