import { Contact, ContactResponse, ErrorContactResponse, ImportContactResponse } from '../../interfaces/Contact'
import http from '../../network/http-common'
import { toast } from 'react-toastify'
import { createIntl, IntlShape } from 'react-intl'
import Message from '../../constants/Message'
import ContactUtils from '../../utils/ContactUtils'

/* global FormData */

class ContactService {
  intl: IntlShape

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  CONTACT_RESPONSE_INIT: ContactResponse = {
    totalSize: 0,
    page: 0,
    maxPage: 0,
    pageSize: 0,
    contacts: []
  }

  /**
   * Récupération de la liste des contacts
   * @returns Promise<Contact[]>
   */
  getContacts = async (actualPage?: number, searchText?: string, sortByName?: string): Promise<ContactResponse> => {
    let contactResponse: ContactResponse
    let params = `page=${actualPage}`
    if (searchText && searchText !== '') {
      params = `${params}&searchText=${searchText}`
    }
    if (sortByName) {
      params = `${params}&sortByName=${sortByName}`
    }
    try {
      contactResponse = await http.get(`/api/contacts${actualPage ? `?${params}` : ''}`)
    } catch (error) {
      contactResponse = this.CONTACT_RESPONSE_INIT
    }
    return Promise.resolve(contactResponse)
  }

  /**
   * Récupération de la liste des contacts au format csv
   * @returns Promise<Contact[]>
   */
  getContactsAsCsv = async (): Promise<ArrayBuffer> => {
    try {
      return await http.get('/api/contacts/csv', {
        responseType: 'arraybuffer'
      })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Récupération d'un contact
   * @returns Promise<Contact>
   */
  getContact = async (id: string): Promise<Contact> => {
    try {
      return await http.get(`/api/contacts/${id}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Ajout d'un contact
   */
  addContact = async (contact?: Contact, forceCreate?: boolean): Promise<Contact | ErrorContactResponse | null> => {
    if (contact) {
      try {
        return await http.post(`/api/contacts${forceCreate ? '?forceCreate=1' : ''}`, contact)
      } catch (error) {
        switch (error.errorCode) {
          case ContactUtils.ERROR_CONTACT_ALREADY_EXIST:
            return Promise.resolve(error)
          case ContactUtils.ERROR_CONTACT_MAX_NUMBER:
            toast.error(this.intl.formatMessage({ id: 'error_contact_max_number' }))
            break
          default:
            toast.error(this.intl.formatMessage({ id: 'add_contact_error' }))
        }
      }
    }
    return null
  }

  /**
   * Modification d'un contact
   */
  modifyContact = async (contact?: Contact, contactIdToReplace?: number): Promise<Contact | null> => {
    if (contact) {
      try {
        return await http.put(`/api/contacts/${contact.id || contactIdToReplace}`, contact)
      } catch (error) {
        toast.error(this.intl.formatMessage({ id: 'modify_contact_error' }))
      }
    }
    return null
  }

  /**
   * Suppresssion d'un contact
   */
  deleteContact = async (contactId?: number): Promise<boolean> => {
    if (contactId) {
      try {
        await http.delete(`api/contacts/${contactId}`)
        return true
      } catch (error) {
        toast.error(this.intl.formatMessage({ id: 'delete_contact_error' }))
        return false
      }
    }
    return false
  }

  /**
   * Import du carnet d'adresse
   */
  importAddressBook = async (fileAddressBook: File, forceUpdate?: boolean, forceCreate?: boolean): Promise<ImportContactResponse | ErrorContactResponse > => {
    try {
      const formData = new FormData()
      formData.append('file', fileAddressBook)
      return await http.post(`/api/contacts/csv${forceCreate ? '?forceCreate=1' : forceUpdate ? '?forceUpdate=1' : ''}`, formData)
    } catch (error) {
      switch (error.errorCode) {
        case ContactUtils.ERROR_CONTACT_ALREADY_EXIST:
          break
        case ContactUtils.ERROR_CONTACT_MAX_NUMBER:
          toast.error(this.intl.formatMessage({ id: 'error_contact_max_number' }))
          break
        default:
          toast.error(this.intl.formatMessage({ id: 'import_contact_error' }))
      }
      return error
    }
  }
}

export default new ContactService()
