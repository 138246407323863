import { EventType, FieldStatus, ORDER_ASC, STATUS_DIVISION, TextField, Transaction, TransactionSearchResult, SwitchField } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdate } from 'store/transaction/transactionActions'
import qs from 'qs'
import { useLocation } from 'react-router'
import TransactionService from 'services/transaction/TransactionService'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import Message from 'constants/Message'

interface SuiteProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    setPreviousTransactionId?: (transactionId: string) => void
}
const Suite: FC<SuiteProps> = ({ transaction, fieldStatus, setPreviousTransactionId }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()

  const position: string = transaction.suite?.position ?? ''
  const query = qs.parse(location.search, { ignoreQueryPrefix: true })
  const [isSuite, setIsSuite] = useState<boolean>()

  const [suiteTransactions, setSuiteTransactions] = useState<Transaction[]>()

  /**
   * Récupération des transactions faisant partie de la suite
   */
  useEffect(() => {
    setPreviousTransactionId && setPreviousTransactionId(query.suite)
    query.suite && TransactionService.searchTransaction({ suite: query.suite, sortBy: 'suite.position', order: ORDER_ASC, notStatus: [STATUS_DIVISION.DRAFT] }).then((response: TransactionSearchResult) => {
      setSuiteTransactions(response.transactions)
    })
    // Si ce nouveau formulaire fait partie d'une suite, on coche par défaut la suite
    if (transaction.suite || (!transaction.id && query.suite)) {
      setIsSuite(true)
    }
  }, [transaction.suite])

  /**
   * Au clique sur la checkbox
   * @param event
   */
  const onCheckboxChanged = (): void => {
    dispatch(storeTransactionUpdate(
      { ...transaction, suite: !isSuite ? { ...transaction.suite, transactionIds: query.suite ? [query.suite] : [] } : undefined }
    ))
    setIsSuite(!isSuite)
  }

  /**
   * A l'édition de la position
   * @param event
   */
  const onPositionChanged = (event: EventType): void => {
    dispatch(storeTransactionUpdate(
      {
        ...transaction,
        suite: transaction.suite ? { ...transaction.suite, position: event.target.value } : { position: event.target.value, transactionIds: query.suite ? [query.suite] : [] }
      }
    ))
  }

  /**
   * Check si la position renseigné a déjà été choisie ou non au Blur
   */
  const onPositionBlur = (): void => {
    if (suiteTransactions?.find((transaction: Transaction) => transaction.suite?.position === position)) {
      dispatch(storeTransactionFieldStatusUpdate({ ...fieldStatus, position: Message.suite_position_still_exists }))
    } else {
      dispatch(storeTransactionFieldStatusUpdate({ ...fieldStatus, position: undefined }))
    }
  }

  return (
    <div className='col-12 p-0 mt-4 is-validated'>
      <SwitchField
        inputId='isSuite'
        displayStringValue
        divClassName='d-flex'
        className='p-0 mr-2 ml-5'
        value={isSuite}
        label={<FormattedMessage id={`${transaction.procedureType?.toLowerCase()}_suite_checkbox_label`} />}
        onChange={onCheckboxChanged}
      />
      {isSuite &&
        <div className='row'>
          <TextField
            inputId='position'
            classNameFormGroup='col-4'
            value={position}
            onChange={onPositionChanged}
            label={intl.formatMessage({ id: 'suite_input_label' })}
            fieldStatus={fieldStatus}
            resetError={storeTransactionFieldStatusUpdate}
            onBlur={onPositionBlur}
          />
          {suiteTransactions && suiteTransactions.length > 0 &&
            <div className='mt-3'>
              <span className='font-weight-bold'><FormattedMessage id={`${transaction.procedureType?.toLowerCase()}_suite_list_title`} /></span>
              {suiteTransactions.map((transaction: Transaction, key: number) =>
                <div key={key}>{transaction.suite?.position} - {transaction.numNat}</div>
              )}
            </div>}
        </div>}
    </div>)
}

export default Suite
