import store from '../../../../../store/store'
import {
  BRAND_TYPE_WORD,
  containsErrors,
  FieldStatus,
  messageIsFilled,
  Title,
  Transaction
} from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import Message from 'constants/Message'
import SuiteValidator from 'component/suite/validator/SuiteValidator'

class RenunciationTypeValidator {
  /**
   * Validation du titre
   * @param titles
   * @returns
   */
    validateTitle = (titles?: Title[]): FieldStatus => {
      if (!titles || !titles.length) {
        return {
          title: Message.inscription_title_required
        }
      }
      const title: Title = titles[0]
      return {
        titleBrandType: title.numNat ? messageIsFilled(title.type) : '',
        titleBrandText: title.numNat && title.type === BRAND_TYPE_WORD.value ? messageIsFilled(title.text) : '',
        titleOrigin: title.numNat ? messageIsFilled(title.origin) : '',
        titleNumNat: messageIsFilled(title.numNat),
        titleDepositDate: title.numNat ? messageIsFilled(title.depositDate) : ''
      }
    }

    /**
     * Validation de la premier étape
     * @param transaction
     * @param fieldStatus
     * @returns
     */
    validateOnChangeStep = (transaction: Transaction, fieldStatus?: FieldStatus): boolean => {
      const newFieldStatus: FieldStatus = {
        renunciationTypes: messageIsFilled(transaction.inscription?.renunciation?.type),
        ...this.validateTitle(transaction.inscription?.titles),
        position: SuiteValidator.validate(transaction, fieldStatus)
      }
      store.dispatch(storeTransactionFieldStatusUpdate(newFieldStatus))
      return !containsErrors(newFieldStatus)
    }
}

export default new RenunciationTypeValidator()
