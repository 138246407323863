import store from '../../../../../store/store'
import {
  containsErrors,
  FieldStatus,
  messageIsFilled,
  Transaction
} from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'

class FRMIPostOperationTypesValidator {
    validateOnChangeStep = (transaction: Transaction): boolean => {
      const fieldStatus: FieldStatus = { subProcedureType: messageIsFilled(transaction.subProcedureType) }
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
      return !containsErrors(fieldStatus)
    }
}

export default new FRMIPostOperationTypesValidator()
