import Message from '../constants/Message'
import { isNumberValid } from '../utils/validationUtils'
import DateUtils from '../utils/DateUtils'

class BrandNumberValidator {
  validateBrandNumber = (depositDate : string | undefined, numnat: string) => {
    if (!depositDate) {
      return Message.request_identification_numnat_required
    } else if (DateUtils.isBetween(depositDate, '1921-01-01', '1952-05-31')) {
      // Dépôt entre 1921 et le 31 mai 1952
      // --> numéro sur 7 chiffres et se terminant par A
      if (!numnat.toLowerCase().endsWith('a') || !isNumberValid(numnat.slice(0, -1)) || numnat.length !== 7) {
        return Message.request_identification_numnat_endsWithA
      }
    } else if (DateUtils.isBetween(depositDate, '1952-06-01', '1965-07-31')) {
      // Dépôt entre le 1er juin 1952 et le 31 juillet 1965
      // --> numéro sur 7 chiffres et se terminant par B
      if (!numnat.toLowerCase().endsWith('b') || !isNumberValid(numnat.slice(0, -1)) || numnat.length !== 7) {
        return Message.request_identification_numnat_endsWithB
      }
    } else if (DateUtils.isBetween(depositDate, '1991-12-28', '1999-12-31')) {
      // Dépôt entre le 28 décembre 1991 et le 31 décembre 1999
      // --> numéro sur 8 chiffres
      if (!isNumberValid(numnat)) {
        return Message.request_identification_numnat_error
      } else if (numnat.length !== 8) {
        return Message.request_identification_numnat_length8
      }
    } else {
      // Dépôt entre 1857 et 1920
      // Dépôt entre le 1er août 1965 et le 27 décembre 1991
      // Dépôt postérieur au 31 décembre 1999
      // --> numéro sur 7 chiffres
      if (!isNumberValid(numnat)) {
        return Message.request_identification_numnat_error
      } else if (numnat.length !== 7) {
        return Message.request_identification_numnat_length7
      }
    }
    return ''
  }
}

export default new BrandNumberValidator()
