import { FoundationType } from '@inpi-marques/components'
import http from 'network/http-common'

class FoundationTypeService {
  /**
     * Récupère tous les types de fondement en fonction de leur procedure
     * @param procedure
     * @returns
     */
      getAll = async (procedure?: string): Promise<FoundationType[]> => {
        try {
          return await http.get('/api/foundation-types',
            { params: { procedure } })
        } catch (e) {
          return Promise.reject(e)
        }
      }
}

export default new FoundationTypeService()
