import {
  CardBlock,
  Contributor,
  Deposit,
  EventType,
  FieldStatus,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { DepositTypeContentProps } from 'interfaces/Deposit'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface DepositTypeItemProps {
    value: string,
    label?: string,
    onClick?: (value: string) => void,
    className?: string,
    active: boolean,
    deposit: Deposit,
    transaction: Transaction,
    contentComponent?: FC<DepositTypeContentProps>,
    fieldStatus: FieldStatus,
    onDocumentChanged?: (event: EventType, type: string) => void,
    onDocumentDeleted?: (event: EventType)=> void,
    documents: TransactionDocument[],
    setHolders?: (holders: Contributor[]) => void
}

const SubProcedureTypeItem: FC<DepositTypeItemProps> = ({
  value,
  label,
  onClick,
  fieldStatus,
  deposit,
  transaction,
  onDocumentChanged,
  onDocumentDeleted,
  documents,
  active,
  contentComponent: Component,
  className,
  setHolders
}) => {
  const props: DepositTypeContentProps = { value, deposit, transaction, fieldStatus, onDocumentChanged, onDocumentDeleted, documents, setHolders }
  return (
    <div className={`deposit-type__item ${className}`}>
      {label &&
        <CardBlock
          shadow
          onClick={() => onClick && onClick(value)}
          className={`theme-primary${active ? ' active' : ''}`}
          bodyClassName='p-0'
        >
          <FormattedMessage id={label} />
        </CardBlock>}
      {Component && active &&
        <div className='deposit-type__item-content'>
          <Component {...props} />
        </div>}
    </div>

  )
}

export default SubProcedureTypeItem
