import { containsErrors, EventType, FieldStatus, SelectOption, SubmitButton, Country } from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPencilAlt, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { FC, useEffect, useState } from 'react'
import CountryValidator from './validator/CountryValidator'
import EditableSelectField from '../../form/fields/EditableSelectField'
import { Country as FRMICountry } from '../../interfaces/frmi/FRMI'

interface CountryRowContentProps {
    key?: string,
    countries: FRMICountry[],
    country: FRMICountry,
    setCountries?: (value: FRMICountry[]) => void,
    editing: boolean,
    onCountriesChanged: any,
    isNewCountry?: boolean,
    onDelete: (index: number) => void,
    countriesFromDB?: Country[]
    fieldStatus?: any,
    setFieldStatus?: any,
    countryIndex: number
}

const CountryRowContent: FC<CountryRowContentProps> = ({
  countries,
  country,
  setCountries,
  editing,
  onCountriesChanged,
  isNewCountry = false,
  onDelete,
  countriesFromDB = [],
  fieldStatus,
  setFieldStatus,
  countryIndex
}) => {
  // Permet de stocker les modifications qui sont faites sur le pays
  const [countryInfo, setCountryInfo] = useState<FRMICountry | undefined>(country)
  const [infosEditable, setInfosEditable] = useState<boolean>(isNewCountry && !country.code)

  // Mise à jour en cas de suppression d'un pays
  useEffect(() => {
    setCountryInfo(country)
  }, [countries])

  useEffect(() => {
    if (!editing) {
      // Si on repasse en mode lecture alors on met les champs modifiables en lecture aussi
      setInfosEditable(false)
      setCountryInfo(country)
    }
  }, [editing, country])

  /**
   * Modification des infos du pays */
  const onChangeInfos = (event: EventType) => {
    const { name, value } = event.target
    // Si on modifie le pays, il faut modifier le nom et le code
    if (name === 'label') {
      setCountryInfo({
        ...countryInfo,
        ...countriesFromDB.map((countryTmp: Country) => ({ [name]: countryTmp.name, code: countryTmp.code }))
          .find((countryChanged: FRMICountry) => countryChanged.code === value)
      }
      )
    } else {
      const elemToUpdate = { [name]: value }
      // On modifie les infos principales du pays
      setCountryInfo({ ...countryInfo, ...elemToUpdate })
    }
  }

  /** Validation des modifications du pays */
  const onValidateChange = () => {
    const errors: FieldStatus = CountryValidator.validateCountry(countryInfo)
    if (containsErrors(errors)) {
      setFieldStatus(errors)
    } else {
      // On applique les modifications du pays dans la liste
      const newCountries: FRMICountry[] = countries.map((modifiedCountry: FRMICountry, index: number) => countryInfo && countryIndex === index
        ? countryInfo
        : modifiedCountry)
      setCountries && setCountries(newCountries)
      onCountriesChanged(newCountries)

      setInfosEditable(false)
      setFieldStatus({})
    }
  }

  /** Annulation des modifications du pays */
  const onCancelChange = () => {
    // On remet l'état initial du pays
    setCountryInfo(country)
    setInfosEditable(false)
    onCountriesChanged(countries)
    setFieldStatus({})
  }

  /** Suppression d'un pays */
  const onDeleteCountry = () => {
    country.code && onDelete(countryIndex)

    setInfosEditable(false)
  }

  const countriesOptions: SelectOption[] = countriesFromDB.map((dbCountry: Country) => ({ label: dbCountry.label ?? '', value: dbCountry.code ?? '' }))

  return (
    <>
      <td>{countryInfo?.code}</td>
      <td>
        <EditableSelectField
          label='label'
          className=''
          options={countriesOptions}
          value={countryInfo?.code}
          displayValue={countriesOptions ? countriesOptions.find((country: SelectOption) => country.value === countryInfo?.code)?.label as string : ''}
          isEditable={infosEditable}
          onChange={onChangeInfos}
          fieldStatus={fieldStatus}
          required
        />
      </td>
      {editing &&
        <td className='pr-4'>
          {infosEditable &&
            <>
              <SubmitButton className='btn-icon-primary'>
                <FontAwesomeIcon icon={faCheck} onClick={onValidateChange} />
              </SubmitButton>
              <SubmitButton className='ml-3 btn-icon-secondary'>
                <FontAwesomeIcon icon={faTimes} onClick={onCancelChange} />
              </SubmitButton>
            </>}
          {!infosEditable &&
            <>
              <SubmitButton
                className='btn btn-link text-primary mr-1 cursor-pointer' onClick={() =>
                  setInfosEditable(!infosEditable)}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </SubmitButton>
            </>}
          <SubmitButton className='btn btn-link text-danger ml-3' onClick={onDeleteCountry}>
            <FontAwesomeIcon icon={faTrash} />
          </SubmitButton>
        </td>}
      {
        !editing &&
          <td className='btn-column pr-4' />
      }
    </>

  )
}

export default CountryRowContent
