import React, { FC } from 'react'
import { Seniority, SubmitButton } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import OverviewSeniorities from './OverviewSeniorities'

interface SeniorityListFormProps {
    seniorityList: Seniority[],
    editSeniority: (index: number) => void,
    deleteSeniority: (index: number) => void,
    addSeniority: () => void
}

const SeniorityListForm: FC<SeniorityListFormProps> = (
  {
    seniorityList,
    deleteSeniority,
    editSeniority,
    addSeniority
  }) => {
  return (
    <>
      <div>
        <SubmitButton
          className='btn-select btn-outline-primary btn-add no-text-uppercase'
          onClick={() => addSeniority()}
        >
          <FormattedMessage id='button_add_new_seniority' />
          <FontAwesomeIcon className='ml-2' icon={faPlus} />
        </SubmitButton>
      </div>
      {seniorityList && seniorityList.length > 0 && (
        <OverviewSeniorities
          seniorities={seniorityList}
          deleteSeniority={deleteSeniority}
          editSeniority={editSeniority}
          className='mt-5'
          readOnly={false}
        />
      )}
    </>
  )
}
export default SeniorityListForm
