import React, { FC, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { NotificationOMPI, TextArea } from '@inpi-marques/components'

interface OverviewNotificationOMPICommentProps {
  notification: NotificationOMPI
}

/**
 * Composant contenant les informations générales sur l'avis d'irrégularité d'une notification OMPI
 *
 * @param notification - Objet notification
 * @constructor
 */
const OverviewNotificationOMPIComment:FC<OverviewNotificationOMPICommentProps> = ({
  notification
}) => {
  const contentRef = useRef(null)

  return (
    <div>
      <div>
        <h3><FormattedMessage id='overview_transaction_notification_ompi_comment_title' /></h3>
      </div>
      <div ref={contentRef} id={`notification-${notification.id}`} className='border p-2 mb-2 row'>
        <TextArea
          classNameFormGroup='col-12 mt-3'
          inputId='inpiCommentToRecipient'
          value={notification.inpiCommentToRecipient}
          disabled
          label={<FormattedMessage id='overview_transaction_notification_ompi_comment_label' />}
        />
      </div>
    </div>
  )
}

export default OverviewNotificationOMPIComment
