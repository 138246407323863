import {
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION
} from './ProcedureConstants'

/**
 * Champs à afficher par procédure
 */
export const CONTESTED_REQUEST_FORM_EXCLUDED_FIELDS = {
  [PROCEDURE_NULLITY.value]: ['publicationNumber'],
  [PROCEDURE_REVOCATION.value]: ['publicationNumber'],
  [`${PROCEDURE_NULLITY.value}MIFR`]: ['publicationNumber', 'registrationDate'],
  [`${PROCEDURE_REVOCATION.value}MIFR`]: ['publicationNumber', 'registrationDate'],
  [PROCEDURE_OPPOSITION.value]: ['grantProtectionDate', 'registrationDate'],
  [`${PROCEDURE_OPPOSITION.value}MIFR`]: ['registrationDate']
}
