import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'

interface NoResultProps {
    message?: string,
    divClassname?: string
}
const NoResult: FC<NoResultProps> = ({
  message,
  divClassname
}) => (
  <IntlProvider locale='fr' messages={Message}>
    <div className={`d-flex justify-content-center align-items-center text-gris ${divClassname}`}>
      {message
        ? <span>{message}</span>
        : <FormattedMessage id='request_no_result' />}
    </div>
  </IntlProvider>
)

export default NoResult
