import { DateUtils, DATE_ISO8601, Foundation, TransactionDocument } from '@inpi-marques/components'
import { FoundationDocument, FoundationEntity, FoundationOpponent } from '@inpi-marques/components/src/interfaces/opposition/Opposition'

class FoundationService {
    /**
     * Met à jour l'entité d'un fondement
     * @param foundation
      * @param key
     * @param value
     * @returns
     */
    getUpdatedFoundationByEntity = (foundation: Foundation, key: string, value: string|boolean) : Foundation => {
      const entity: FoundationEntity = foundation.entity ?? {}
      return ({ ...foundation, entity: { ...entity, [key]: value } })
    }

    /**
     * Met à jour l'opposant d'un fondement
     * @param foundation
     * @param key
     * @param value
     * @returns
     */
    getUpdatedFoundationByOpponent = (foundation: Foundation, key: string, value: string|boolean) : Foundation => {
      const opponent: FoundationOpponent = foundation.opponent ?? {}
      return ({ ...foundation, opponent: { ...opponent, [key]: value } })
    }

    /**
     * Récupère tous les documents d'un fondement via leur type
     * @param foundationDocuments
     * @param type
     * @returns
     */
    getDocumentsByType = (foundationDocuments: FoundationDocument[] = [], type: string): FoundationDocument[] =>
      foundationDocuments.filter((document: FoundationDocument) => document.type === type)

    /**
     * Récupère le document de la transaction lié au document du fondement via leur type
     * @param transactionDocuments
     * @param foundationDocuments
     * @param type
     * @returns
     */
    getFoundationTransactionDocumentByType = (transactionDocuments: TransactionDocument[], foundationDocuments: FoundationDocument[] = [], type: string): TransactionDocument[] =>
      transactionDocuments.filter((document: TransactionDocument) =>
        this.getDocumentsByType(foundationDocuments, type).find((file) => file.name === document.internalName || DateUtils.formateDateToFormat(file.createdAt, DATE_ISO8601) === DateUtils.formateDateToFormat(document.createdAt, DATE_ISO8601)))

    /**
     * Récupère le document de la transaction lié au document du fondement
     * @param transactionDocuments
     * @param foundationDocuments
     * @returns
     */
     getFoundationTransactionDocument = (transactionDocuments: TransactionDocument[], foundationDocuments: FoundationDocument[] = []): TransactionDocument[] =>
       transactionDocuments.filter((document: TransactionDocument) =>
         foundationDocuments.some((file) => file.name === document.internalName || DateUtils.formateDateToFormat(file.createdAt, DATE_ISO8601) === DateUtils.formateDateToFormat(document.createdAt, DATE_ISO8601)))

      /**
       * Compare deux documents entre eux. Retourne true s'ils sont identique, false sinon
       * @param firstDocument
       * @param secondDocument
       * @returns
       */
      compareDocuments = (firstDocument: TransactionDocument, secondDocument: TransactionDocument|FoundationDocument): boolean => DateUtils.formateDateToFormat(firstDocument.createdAt, DATE_ISO8601) === DateUtils.formateDateToFormat(secondDocument.createdAt, DATE_ISO8601) &&
        firstDocument.type === secondDocument.type &&
        (firstDocument.internalName === secondDocument.name || firstDocument.name === secondDocument.name)

      getLastFoundation = (foundations: Foundation[]|null): Foundation|null|undefined => {
        return foundations ? foundations[foundations.length - 1] : null
      }
}

export default new FoundationService()
