import React, { FC, ReactNode, useEffect, useState } from 'react'
import {
  containsErrors,
  Contributor,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_HOLDERS_CONTESTED_MARK,
  CONTRIBUTOR_OPPONENTS, CONTRIBUTOR_ORGANISERS,
  CONTRIBUTOR_OTHER_APPLICANTS,
  ContributorForm,
  ContributorListForm,
  ContributorsOptionsForm,
  ContributorValidator, Country,
  DEFAULT_CONTRIBUTOR,
  DEPOSIT_TYPE_COLLECTIVE, EditorialBlock,
  ErrorField,
  FieldStatus, HelpBlock,
  PERSONNE_MORALE, PERSONNE_PHYSIQUE, PROCEDURE_DEPOSIT,
  SubmitButton,
  Transaction,
  TransactionDocument,
  CONTRIBUTOR_GUIDED_ENTITY_FORMATION,
  CONTRIBUTOR_GUIDED_PM,
  CONTRIBUTOR_GUIDED_PP,
  CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS,
  PROCESS_FAST_TRACK_RENEWAL,
  DEFAULT_CONTRIBUTOR_COLLECTIVITY,
  INSCRIPTION_TYPE_ACT_TITLE,
  ContributorService as CommonContributorService,
  isDepositDepositor,
  DEFAULT_DEPOSIT_DEPOSITOR
} from '@inpi-marques/components'
import ContentService from '../../services/content/ContentService'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import { RootStateOrAny, useSelector } from 'react-redux'
import OpenDataRNCSService from '../../services/content/OpenDataRNCSService'
import StoreService from '../../services/StoreService'
import ContributorService from '../../services/contributors/ContributorService'
import ContactUtils from '../../utils/ContactUtils'
import ModalContactList from '../addressesBook/ModalContactList'
import { Contact } from '../../interfaces/Contact'
import ContactService from '../../services/contact/ContactService'
import { toast } from 'react-toastify'
import Message from '../../constants/Message'
import ModalContactError from '../addressesBook/ModalContactError'
import TransactionService from '../../services/transaction/TransactionService'

import DepositService from 'services/deposit/DepositService'

interface ContributorListProps {
  procedure: string,
  contributorType: string,
  contributorTypeLabel?: string,
  contributorTypeToCopy?: string[],
  externalContributorsToCopy?: Contributor[],
  transaction: Transaction,
  fieldStatus: FieldStatus,
  optionForm: ContributorsOptionsForm,
  children?: ReactNode,
  validateContributor: (contributor: Contributor, transaction?: Transaction, contributorType?: string, isFirst?: boolean) => FieldStatus,
  openOnFirstOne?: boolean,
  actionWithAddressBook?: boolean,
  activeGuidedMode?: boolean,
  choiceContributor?: string,
  setIsRequired: (value: boolean) => void,
  setDocuments?: (documents: TransactionDocument[]) => Promise<Transaction | null>,
  fromFrmiExtension?: boolean
}

const ContributorList: FC<ContributorListProps> = ({
  procedure,
  contributorType,
  contributorTypeLabel,
  contributorTypeToCopy = [],
  externalContributorsToCopy = [],
  transaction,
  fieldStatus,
  optionForm,
  children,
  validateContributor,
  openOnFirstOne = false,
  actionWithAddressBook = true,
  activeGuidedMode,
  choiceContributor,
  setIsRequired,
  fromFrmiExtension = false
}) => {
  const user = useSelector((state: RootStateOrAny) => state.user.user)
  const isDepositorFromDeposit: boolean = isDepositDepositor(transaction, contributorType)
  const [editIndex, setEditIndex] = useState<number>(-1)
  const [contributorList, setContributorList] = useState<Contributor[]>(transaction[contributorType] ? transaction[contributorType] instanceof Array ? transaction[contributorType] : [transaction[contributorType]] : [])
  const [oldContributorList, setOldContributorList] = useState<Contributor[]>([])
  const [stateFieldStatus, setStateFieldStatus] = useState<FieldStatus[]>(fieldStatus ? [fieldStatus] : [])
  const [showAddressBook, setShowAddressBook] = useState<boolean>(false)
  const [showContactError, setShowContactError] = useState<boolean>(false)
  const [contactIdToReplace, setContactIdToReplace] = useState<number>()
  const [countries, setCountries] = useState<Country[]>([])
  const [editorialContent, setEditorialContent] = useState<string|null>(null)

  useEffect(() => {
    // Texte d'information sur le mode guidé
    ContentService.getEditorialBlockByCode('INTERVENANT_GUIDE').then((res: EditorialBlock) => {
      if (res.content) {
        setEditorialContent(res.content)
      }
    })

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  useEffect(() => {
    if (editIndex === -1) {
      setIsRequired(false)
    } else {
      setIsRequired(true)
    }
  }, [editIndex])

  useEffect(() => {
    setOldContributorList(transaction[contributorType] ? transaction[contributorType] instanceof Array ? transaction[contributorType] : [transaction[contributorType]] : [])

    if (openOnFirstOne) {
      if ((!transaction[contributorType] || transaction[contributorType]?.length === 0) && contributorList.length === 0) {
        const defaultContributor = isDepositorFromDeposit ? DEFAULT_DEPOSIT_DEPOSITOR : DEFAULT_CONTRIBUTOR
        setContributorList([defaultContributor])
        setOldContributorList([defaultContributor])
      }
      (!transaction[contributorType] || transaction[contributorType]?.length <= 1) ? setEditIndex(0) : setEditIndex(-1)
    }
  }, [contributorType])

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  /**
   * Deux dépendances qui permettent de ne pas avoir l'erreur de liste de contributors
   * vide en mettant à jour à chaque re render du composant
   */
  useEffect(() => {
    const contributorOrContributors: Contributor|Contributor[]|undefined = TransactionService.getContributor(transaction, contributorType)

    setContributorList(() => changeContributorListPersonType(
      contributorOrContributors ? (Array.isArray(contributorOrContributors) ? contributorOrContributors : [contributorOrContributors]) : [])
    )
  }, [contributorType, transaction.id])

  useEffect(() => {
    let change = {}
    if (contributorType === CONTRIBUTOR_DEPOSITORS.value) {
      change = { depositors: contributorList }
    } else if (contributorType === CONTRIBUTOR_OPPONENTS.value) {
      change = { opponents: contributorList }
    } else if (contributorType === CONTRIBUTOR_HOLDERS_CONTESTED_MARK.value) {
      change = { holdersContestedMark: contributorList }
    } else if (contributorType === CONTRIBUTOR_OTHER_APPLICANTS.value) {
      change = { otherApplicants: contributorList }
    } else if (contributorType === CONTRIBUTOR_ORGANISERS.value) {
      change = { organisers: contributorList }
    }
    // En mode guidé, si le type du déposant n'est pas celui correspondant à l'identification des intervenants, on adapte l'identification à ce nouveau type
    if (procedure === PROCEDURE_DEPOSIT.value && transaction.guidedMode?.activeGuidedMode) {
      if (contributorList.length < 2) {
        const valueSwitchIntervenant = transaction.guidedMode?.choiceContributor
        if (contributorList?.find((depositor, index) => depositor.personFormation && index === 0)) {
          change = {
            ...change,
            guidedMode: {
              ...transaction.guidedMode,
              choiceContributor: CONTRIBUTOR_GUIDED_ENTITY_FORMATION.idBtnSwitch,
              restrictedQualities: CONTRIBUTOR_GUIDED_ENTITY_FORMATION.restrictedQualities
            }
          }
        } else if (contributorList?.find((depositor, index) => depositor.type === PERSONNE_MORALE.value && index === 0) &&
            (valueSwitchIntervenant === CONTRIBUTOR_GUIDED_PP.idBtnSwitch || valueSwitchIntervenant !== CONTRIBUTOR_GUIDED_ENTITY_FORMATION.idBtnSwitch ||
                valueSwitchIntervenant === CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch)) {
          change = {
            ...change,
            guidedMode: {
              ...transaction.guidedMode,
              choiceContributor: CONTRIBUTOR_GUIDED_PM.idBtnSwitch,
              restrictedQualities: CONTRIBUTOR_GUIDED_PM.restrictedQualities
            }
          }
        } else if (contributorList?.find((depositor, index) => depositor.type === PERSONNE_PHYSIQUE.value && index === 0) &&
            (valueSwitchIntervenant === CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch || valueSwitchIntervenant === CONTRIBUTOR_GUIDED_PM.idBtnSwitch)) {
          change = {
            ...change,
            guidedMode: {
              ...transaction.guidedMode,
              choiceContributor: CONTRIBUTOR_GUIDED_PP.idBtnSwitch,
              restrictedQualities: CONTRIBUTOR_GUIDED_PP.restrictedQualities
            }
          }
        }
      }

      if (contributorList.length > 1 && contributorList.filter((depositor) => depositor.type === PERSONNE_PHYSIQUE.value).length === contributorList.length) {
        change = {
          ...change,
          guidedMode: {
            ...transaction?.guidedMode,
            choiceContributor: CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch,
            restrictedQualities: CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.restrictedQualities
          }
        }
      }
    }
    StoreService.changeStore(change)
  }, [contributorList]
  )

  useEffect(() => {
    setStateFieldStatus(fieldStatus ? [fieldStatus] : [])
  }, [fieldStatus])

  /**
   * Met à jour l'intervenant (mais pas l'email si l'option isEmailReadOnly est à true)
   * @param updatedContributor
   * @param indexContributor
   */
  const updateContributor = (indexContributor: number, updatedContributor?: Contributor): Contributor => {
    let newContributor = { ...updatedContributor }
    if (optionForm.isEmailReadOnly) {
      newContributor = {
        ...newContributor,
        email: contributorList[indexContributor]?.email
      }
    }

    return newContributor
  }

  const changeContributorListPersonType = (contributorList: Contributor[]): Contributor[] => {
    let contributorListCopy: Contributor[] = []
    contributorList.forEach(contributor => {
      let newContributor = { ...contributor }
      if (contributorType === CONTRIBUTOR_DEPOSITORS.value && transaction.subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value) {
        newContributor = {
          ...changeContributorPersonType(contributor),
          civilityCode: '',
          lastname: '',
          firstname: ''
        }
      }
      contributorListCopy = [
        ...contributorListCopy,
        newContributor
      ]
    })

    return contributorListCopy
  }

  const changeContributorPersonType = (contributor: Contributor, personType: string = PERSONNE_MORALE.value): Contributor => {
    return {
      ...contributor,
      type: personType
    }
  }

  /**
   * Ajout d'un intervenant
   */
  const addContributor = () => {
    setOldContributorList([...contributorList])
    const index = contributorList.length
    let contributor = {}
    if (transaction.subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value && contributorType === CONTRIBUTOR_DEPOSITORS.value) {
      contributor = changeContributorPersonType(contributor)
    }
    setContributorList([...contributorList, { ...contributor, address: { country: 'FR' } }])
    setEditIndex(index)
  }

  /**
   * Valide l'intervenant
   */
  const validate = () => {
    setStateFieldStatus([])
    const fieldStatusContributor = validateContributor(contributorList[editIndex], transaction, contributorType, editIndex === 0 && !isDepositorFromDeposit)
    const newFieldStatus = [...stateFieldStatus]
    newFieldStatus[editIndex] = fieldStatusContributor

    setStateFieldStatus(newFieldStatus)
    if (!containsErrors(fieldStatusContributor)) {
      let propertyNames = [contributorType]

      if (activeGuidedMode) {
        propertyNames = [...propertyNames, 'guidedMode']
      }

      if (transaction.id) {
        return TransactionService.updateTransactionBDDFromStore(transaction, propertyNames).then(() => {
          setOldContributorList(contributorList)
          setEditIndex(-1)
        })
      } else {
        setOldContributorList(contributorList)
        setEditIndex(-1)
      }
    }
  }

  /**
   * Annule un ajout ou la modification d'un intervenant
   */
  const cancel = () => {
    // Suppression des intervenants qui sont non conformes
    const reset = oldContributorList.filter((contributor) => {
      const fieldStatusContributor = ContributorValidator.validateContributor(contributor, transaction, contributorType, false, [], editIndex === 0 && !isDepositorFromDeposit)
      return !containsErrors(fieldStatusContributor)
    })

    setContributorList([...reset])
    setEditIndex(-1)
  }

  /**
   * Supprime un intervenant de la liste
   * @param index
   */
  const deleteContributor = (index: number) => {
    const newContributors = [...contributorList]
    newContributors.splice(index, 1)
    setContributorList(newContributors)
    const updatedFieldStatus = [...stateFieldStatus]
    updatedFieldStatus.splice(index, 1)
    setStateFieldStatus(updatedFieldStatus)
  }

  /**
   * Fonction à appeler pour l'ajout d'un intervenant au carnet d'adresses
   */
  const addContactAddressBook = (contributor?: Contributor) => {
    const contact: Contact | undefined = ContactUtils.mapToContact(contributor)
    ContactService.addContact(contact).then((response) => {
      if (response) {
        if ('errorCode' in response) {
          if (response.errorCode === ContactUtils.ERROR_CONTACT_ALREADY_EXIST) {
            // On récupère l'id du contact déjà existant
            setContactIdToReplace(response.data)
            setShowContactError(true)
          }
        } else {
          toast.success(Message.success_add_contact)
        }
      }
    })
  }

  const getDefaultContributor = () => {
    if (isDepositorFromDeposit) {
      return DEFAULT_DEPOSIT_DEPOSITOR
    }
    if (transaction.subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value && contributorType === CONTRIBUTOR_DEPOSITORS.value) {
      return DEFAULT_CONTRIBUTOR_COLLECTIVITY
    }
    return DEFAULT_CONTRIBUTOR
  }

  /**
   * Affiche la liste d'intervenant
   */
  const renderListContributorOverview = () => (
    <ContributorListForm
      optionsForms={optionForm}
      contributorList={contributorList}
      editContributor={(index: number) => {
        setOldContributorList(contributorList)
        setEditIndex(index)
      }}
      deleteContributor={deleteContributor}
      contributorType={contributorType}
      text={{
        title: (
          <h2 className='mb-0 '>
            <FormattedMessage
              id={contributorTypeLabel ? `contributor_${contributorTypeLabel}` : `contributor_${contributorType}`}
            />
          </h2>
        ),
        subtitle: (
          <div className='small flex flex-col'>
            <FormattedMessage
              id={contributorTypeLabel ? `contributor_${CommonContributorService.getContributorSubtitle(transaction, contributorTypeLabel)}_subtitle` : `contributor_${CommonContributorService.getContributorSubtitle(transaction, contributorType)}_subtitle`}
              values={{
                a: (...chunks: ((string) []) | []) => (
                  <a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
                linebreak: <br />
              }}
            />
            {transaction.process === PROCESS_FAST_TRACK_RENEWAL &&
              <div className='block text-danger font-weight-bold mt-2'>
                <FormattedMessage id='renewal_fast_track_warning' />
              </div>}
          </div>
        )
      }}
      countries={countries}
      fromFrmiExtension={fromFrmiExtension}
      procedureType={procedure}
    >
      {
        (CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch === choiceContributor || CONTRIBUTOR_GUIDED_PP.idBtnSwitch === choiceContributor || !activeGuidedMode) &&
          <div className='row justify-content-center py-3 mb-4'>
            <button className='col-4 btn btn-outline-primary' onClick={addContributor}>
              <FormattedMessage
                id={contributorTypeLabel ? `contributor_add_${contributorTypeLabel}` : `contributor_add_${contributorType}`}
              />
            </button>
          </div>
      }
    </ContributorListForm>
  )

  /**
   * Affiche le formulaire d'un intervenant
   */
  const renderFormOneContributor = () =>
    <ContributorForm
      transaction={transaction}
      optionsForm={optionForm}
      contributorType={contributorType}
      contributorTypeLabel={contributorTypeLabel}
      contributorList={contributorList}
      activeGuidedMode={activeGuidedMode}
      choiceContributor={choiceContributor}
      text={{
        title: (
          <h2 className='mb-0 '>
            <FormattedMessage
              id={contributorTypeLabel ? `contributor_${contributorTypeLabel}` : `contributor_${contributorType}`}
            />
          </h2>
        ),
        subtitle: (
          <span className='small'>
            <FormattedMessage
              id={contributorTypeLabel ? `contributor_${contributorTypeLabel}_subtitle` : `contributor_${contributorType}_subtitle`}
              values={{
                a: (...chunks: ((string) []) | []) => (
                  <a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
                linebreak: <br />
              }}
            />
          </span>
        )
      }}
      contributor={contributorList[editIndex] || getDefaultContributor()}
      contributorsToCopy={[...externalContributorsToCopy, ...ContributorService.findAllDistinctContributors(transaction, contributorTypeToCopy)]}
      currentETSUser={ContributorService.buildContributorFromUser(user)}
      setContributor={(contributor) => {
        const newList = [...contributorList]
        newList[editIndex] = { ...contributor }
        setContributorList(newList)
      }}
      fieldStatus={stateFieldStatus[editIndex]}
      getCountries={ContentService.getCountries}
      fillContributorInfo={(siren: string) => OpenDataRNCSService.getContributorInfos(siren)}
      findListSirenByNamePromise={(name: string) => OpenDataRNCSService.findListSirenByName(name)}
      selectFromAddressBook={() => setShowAddressBook(true)}
      addContributorToAddressBook={addContactAddressBook}
      actionWithAddressBook={actionWithAddressBook}
      onDownloadClick={() => DepositService.getPowerExampleFile()}
      getLanguages={ContentService.getLanguages}
      isFirst={editIndex === 0}
    >
      <div className='row mt-4 justify-content-center step-buttons'>
        <SubmitButton className='bg-white text-gris py-3 px-4' onClick={cancel}>
          <FormattedMessage id='button_return' />
        </SubmitButton>
        <SubmitButton className='bg-primary py-3 px-4' onClick={validate}>
          <FormattedMessage
            id={contributorTypeLabel ? `contributor_save_${contributorTypeLabel}` : `contributor_save_${contributorType}`}
          />
        </SubmitButton>
      </div>
    </ContributorForm>

  return (
    <>
      <div>
        <div className='row mb-4 justify-content-between'>
          <header className='col-8'>
            <h1><FormattedMessage id='contributor_form_title' /></h1>
            <span className='subtitle'><FormattedMessage id={`contributor_form_subtitle_${transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value ? transaction.subProcedureType : procedure}`} /></span>
          </header>
          <InternalReferenceField
            transaction={transaction}
            className='col-3'
          />
        </div>
        {
          editIndex === -1 ? renderListContributorOverview() : renderFormOneContributor()
        }
        {editIndex === -1 && children}
        {stateFieldStatus.length > 0 && stateFieldStatus[0].opponents && (
          <div className='col-12'>
            <ErrorField
              message={stateFieldStatus[0].opponents}
              className='fade alert alert-danger show position-relative mt-4'
            />
          </div>
        )}
        {stateFieldStatus.length > 0 && stateFieldStatus[0].depositors && (
          <div className='col-12'>
            <ErrorField
              message={stateFieldStatus[0].depositors}
              className='fade alert alert-danger show position-relative mt-4'
            />
          </div>
        )}
        {stateFieldStatus.length > 0 && editIndex === -1 &&
          (stateFieldStatus[0].expertModeChange ? (
            <div className='col-12'>
              <ErrorField
                message={stateFieldStatus[0].expertModeChange}
                className='fade alert alert-danger show position-relative mt-4'
              />
            </div>
          ) : (containsErrors(stateFieldStatus[0]) &&
            <div className='col-12'>
              <ErrorField
                message={<FormattedMessage id='required_field_long' />}
                className='fade alert alert-danger show position-relative mt-4'
              />
            </div>
          ))}
        {stateFieldStatus.length > 0 && stateFieldStatus[0].otherApplicants && (
          <div className='col-12'>
            <ErrorField
              message={stateFieldStatus[0].otherApplicants}
              className='fade alert alert-danger show position-relative mt-4'
            />
          </div>
        )}
        {stateFieldStatus.length > 0 && stateFieldStatus[0].organisers && (
          <div className='col-12'>
            <ErrorField
              message={stateFieldStatus[0].organisers}
              className='fade alert alert-danger show position-relative mt-4'
            />
          </div>
        )}
        {stateFieldStatus.length > 0 && stateFieldStatus[0].otherAddress && (
          <div className='col-12'>
            <ErrorField
              message={stateFieldStatus[0].otherApplicants}
              className='fade alert alert-danger show position-relative mt-4'
            />
          </div>
        )}
      </div>
      {editorialContent && activeGuidedMode && editIndex === -1 &&
        <HelpBlock className='mt-5 h-auto'><div dangerouslySetInnerHTML={{ __html: editorialContent }} /></HelpBlock>}
      <ModalContactList
        showModal={showAddressBook}
        setShowModal={setShowAddressBook}
        setSelectedContact={(contact) => {
          const contributorListCopy = [...contributorList]
          // Ici on :
          // - ContactUtils.mapToContributor(contact) --> récupère les informations du contact
          // - ContributorValidator.cleanCopyContributor --> on filtre les informations en fonction de son type et si c'est PP/PM
          // - updateContributor --> enregistre les informations dans le contributor (en faisant un filtre sur l'email si elle est readOnly)
          contributorListCopy[editIndex] = updateContributor(
            editIndex,
            ContributorValidator.cleanCopyContributor(ContactUtils.mapToContributor(contact),
              contributorType,
              contributorList[editIndex],
              transaction.subProcedureType
            )
          )
          setContributorList(contributorListCopy)
        }}
      />

      <ModalContactError
        showModal={showContactError}
        setShowModal={setShowContactError}
        contact={ContactUtils.mapToContact(contributorList[editIndex])}
        contactIdToReplace={contactIdToReplace}
      />
    </>
  )
}

export default ContributorList
