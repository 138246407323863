import { FormatUtils, Payment, PaymentLine, Table } from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface TransactionAmountOverviewProps {
    payment: Payment,
    isPaymentLoading?: boolean
}

const TransactionAmountOverview: FC<TransactionAmountOverviewProps> = ({ payment, isPaymentLoading }) => {
  return (
    <div className='overview__deposit-payment'>
      {isPaymentLoading ? <FontAwesomeIcon className='loader mr-1' icon={faSpinner} /> : (
        <>
          <Table
            tableTitle={[
              { label: Message.overview_payment_label },
              { label: Message.overview_payment_amount },
              { label: Message.overview_payment_quantity },
              { label: Message.overview_payment_line_amount }
            ]}
          >
            <tbody>
              {
                payment.lines.map((line: PaymentLine, index: number) => {
                  return (
                    <tr key={`-${index}`}>
                      <td>{line.label}</td>
                      <td>{line.amount > 0 ? FormatUtils.formatToStringPrice(line.amount) : '0 €'}</td>
                      <td>{line.quantity}</td>
                      <td>{line.amount > 0 ? FormatUtils.formatToStringPrice(line.amount * line.quantity) : '0 €'}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
          <div className='font-weight-bold'>
            <FormattedMessage
              id='overview_payment_total_amount'
            /> {payment.totalAmount > 0 ? FormatUtils.formatToStringPrice(payment.totalAmount) : '0 €'}
          </div>
        </>
      )}
    </div>
  )
}

export default TransactionAmountOverview
