import { BASIC_DATE, DateUtils, Record } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface InformationsOverviewProps {
    record: Record
}

const InformationsOverview: FC<InformationsOverviewProps> = ({ record }) => {
  return (
    <div className='row'>
      <div className='col-12'>
        <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_type_label' /></h4>
        <p className='subtitle'><FormattedMessage id={`overview_${record?.depositType?.toLowerCase()}`} /></p>
      </div>
      <div className='col-12'>
        <h4 className='font-weight-bold'><FormattedMessage id='overview_internal_reference_label' /></h4>
        <p className='subtitle'>{record.internalReference ?? '-'}</p>
      </div>
      <div className='col-12'>
        <h4 className='font-weight-bold'><FormattedMessage id='overview_brand_number_label' /></h4>
        <p className='subtitle'> {record.numNat} </p>
      </div>
      <div className='col-12'>
        <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_date_label' /></h4>
        <p className='subtitle'> {DateUtils.formateDateToFormat(record?.depositDate, BASIC_DATE)} </p>
      </div>
    </div>
  )
}

export default InformationsOverview
