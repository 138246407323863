import {
  containsErrors,
  Contributor,
  ErrorField,
  FieldStatus,
  OfficialDocumentRequested,
  Title,
  Transaction
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import OfficialDocumentRequestedList from './OfficialDocumentRequestedList'
import OfficialDocumentRequestedValidateContent from './OfficialDocumentRequestedValidateContent'
import OfficialDocumentsRequestedValidator from './validator/OfficialDocumentsRequestedValidator'
import { storeTransactionUpdate } from '../../../../store/transaction/transactionActions'
import { useDispatch } from 'react-redux'

interface OfficialDocumentTypesProps {
  transaction: Transaction,
  fieldStatus: FieldStatus,
  defaultTitle: Title|undefined,
  currentETSUser?: Contributor
}

/**
 * Composant permettant d'afficher la liste des documents officiels et d'afficher le formulaire d'ajout d'un document
 *
 * @param transaction - Objet transaction
 * @param fieldStatus - Objet contenant les erreurs des champs du formulaire
 * @param location
 * @param defaultTitle
 * @constructor
 */
const OfficialDocumentsRequested: FC<OfficialDocumentTypesProps> = ({
  transaction,
  fieldStatus,
  defaultTitle,
  currentETSUser
}) => {
  const dispatch = useDispatch()
  const [editIndex, setEditIndex] = useState<number>(-1)
  const [isEditingMode, setEditingMode] = useState<boolean>(false)
  const [officialDocumentRequested, setOfficialDocumentRequested] = useState<OfficialDocumentRequested>()
  const [officialDocumentsRequested, setOfficialDocumentsRequested] = useState<OfficialDocumentRequested[]>(transaction.officialDocument?.officialDocumentsRequested || [])
  const [fieldStatusList, setFieldStatusList] = useState<FieldStatus[]>([])

  useEffect(() => {
    setOfficialDocumentsRequested(transaction.officialDocument?.officialDocumentsRequested ?? [])
  }, [transaction.id])

  /**
   * Ajout d'un document officiel
   */
  const addOfficialDocumentRequested = () => {
    const request: OfficialDocumentRequested = { quantity: 1 }
    if (defaultTitle?.numNat) {
      request.title = defaultTitle
    }
    setOfficialDocumentRequested(request)
    const index = officialDocumentsRequested.length
    setEditIndex(index)
    setEditingMode(false)
  }

  /**
   * Valide le document officiel
   */
  const validate = () => {
    const newList: OfficialDocumentRequested[] = [...officialDocumentsRequested]
    newList[editIndex] = { ...officialDocumentRequested, quantity: officialDocumentRequested?.usePostalRoute ? officialDocumentRequested?.quantity : 0 }

    const fieldStatusDocument = OfficialDocumentsRequestedValidator.validateOfficialDocumentRequested(editIndex, newList)
    const newFieldStatus = [...fieldStatusList]
    newFieldStatus[editIndex] = fieldStatusDocument
    setFieldStatusList(newFieldStatus)
    if (!containsErrors(fieldStatusDocument)) {
      onOfficialDocumentsChange(newList)
      setOfficialDocumentRequested({})
      setEditIndex(-1)
      setEditingMode(false)
    }
  }

  /**
   * Annule un ajout ou la modification d'un document officiel
   */
  const cancel = () => {
    setFieldStatusList(fieldStatusList.filter((status, index) => index !== editIndex))
    setEditIndex(-1)
    setOfficialDocumentRequested({})
    setEditingMode(false)
  }

  /**
   * Supprime un document officiel de la liste
   * @param index
   */
  const deleteOfficialDocumentRequested = (index: number) => {
    const newOfficialDocumentsRequested = [...officialDocumentsRequested]
    newOfficialDocumentsRequested.splice(index, 1)
    onOfficialDocumentsChange(newOfficialDocumentsRequested)
    const updatedFieldStatus = [...fieldStatusList]
    updatedFieldStatus.splice(index, 1)
    setFieldStatusList(updatedFieldStatus)
  }

  const onOfficialDocumentsChange = (updatedDocuments: OfficialDocumentRequested[]): void => {
    setOfficialDocumentsRequested(updatedDocuments)
    dispatch(storeTransactionUpdate({
      ...transaction,
      officialDocument: {
        ...transaction.officialDocument,
        officialDocumentsRequested: updatedDocuments
      }
    }))
  }

  /**
   * Modification de la quantité
   * @param quantity
   * @param index
   */
  const setQuantity = (index: number, quantity?: number) => {
    const newList = [...officialDocumentsRequested]
    newList[index] = { ...newList[index], quantity: quantity }
    onOfficialDocumentsChange(newList)
  }

  return (
    <div className='official-documents-requested'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='official_documents_type_title' /></h1>
          <span className='subtitle'><FormattedMessage id='official_documents_type_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='justify-content-center col-12 p-0'>
        {editIndex === -1 ? (
          <OfficialDocumentRequestedList
            officialDocumentsRequested={officialDocumentsRequested}
            editOfficialDocumentRequested={(index: number) => {
              setEditIndex(index)
              setEditingMode(true)
              setOfficialDocumentRequested(officialDocumentsRequested[index])
            }}
            deleteOfficialDocumentRequested={deleteOfficialDocumentRequested}
            addOfficialDocumentRequested={addOfficialDocumentRequested}
            isEditingMode={isEditingMode}
            setQuantity={setQuantity}
            fieldStatus={fieldStatus}
          />
        ) : (
          <OfficialDocumentRequestedValidateContent
            officialDocumentRequested={officialDocumentRequested}
            setOfficialDocumentRequested={(officialDocumentRequested: OfficialDocumentRequested) => {
              setOfficialDocumentRequested(officialDocumentRequested)
              setFieldStatusList([])
            }}
            isEditingMode={isEditingMode}
            fieldStatus={fieldStatusList[editIndex]}
            validate={validate}
            cancel={cancel}
            transaction={transaction}
            currentETSUser={currentETSUser}
          />
        )}
      </div>
      {fieldStatus.officialDocumentsRequested && (<div className='col-12'> <ErrorField message={fieldStatus.officialDocumentsRequested} className='fade alert alert-danger show position-relative mt-4' /></div>)}
      {(fieldStatus.officialDocumentsFamily || fieldStatusList.find((fieldStatus: FieldStatus) => fieldStatus?.officialDocumentsFamily && fieldStatus?.officialDocumentsFamily !== '')) && (<div className='col-12'> <ErrorField message={fieldStatus.officialDocumentsFamily ?? fieldStatusList.find((fieldStatus: FieldStatus) => fieldStatus?.officialDocumentsFamily && fieldStatus.officialDocumentsFamily !== '')?.officialDocumentsFamily} className='fade alert alert-danger show position-relative mt-4' /></div>)}
    </div>
  )
}

export default OfficialDocumentsRequested
