import Payment from 'component/payment/Payment'
import { PAYMENT_URL_FRMI } from 'constants/PaymentConstants'
import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import FRMIForm from './form/FRMIForm'

const FRMIRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <FRMIForm isNewTransaction />} />
    <Route path={`${match.url}/:id/edition`} component={FRMIForm} />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment transactionId={props.match.params.id} state={props.match.params.state} type={PAYMENT_URL_FRMI} />}
    />
  </Switch>
)

export default FRMIRoutes
