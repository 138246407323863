import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import { toast } from 'react-toastify'
import axios, { CancelTokenSource } from 'axios'

interface IPatch {
  [key: string]: string
}
class DelayService {
  intl: IntlShape
  source: CancelTokenSource

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Récupère la date mise à jour
   * @returns
   * @param dateToUpdate
   */
  getUpdatedDelayDate = async (dateToUpdate: string): Promise<string> => {
    try {
      return await http.get(`/api/delays/${dateToUpdate}`, { cancelToken: this.source.token })
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'common_error' }))
      return Promise.reject(error)
    }
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new DelayService()
