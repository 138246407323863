import React, { FC } from 'react'
import {
  CheckboxField,
  DOCUMENT_COMMUNICABILITIES,
  DOCUMENT_DEFAULT,
  DOCUMENT_FORMATS,
  DOCUMENT_LIMITS,
  DropArea,
  EventType,
  FieldStatus,
  SelectField,
  Table,
  Transaction,
  TransactionDocument,
  TransactionDocumentType,
  TransactionService
} from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import Message from '../../../constants/Message'
import { MAX_NUMBER_FILE } from '../overview/DocumentsOverview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'

interface AddDocumentsWithTypeContentProps {
  documentToAddList: TransactionDocument[],
  onDocumentToAddEdited: (documents: TransactionDocument[]) => void,
  fieldStatus: FieldStatus,
  setFieldStatus: (fieldStatus: FieldStatus) => void,
  transaction: Transaction,
  typeDocumentList: TransactionDocumentType[] | undefined
}

const AddDocumentsWithTypeContent: FC<AddDocumentsWithTypeContentProps> = ({
  documentToAddList,
  onDocumentToAddEdited,
  fieldStatus,
  setFieldStatus,
  transaction,
  typeDocumentList
}) => {
  const intl = useIntl()

  /**
     * Modifie l'attribut correspondant au nom de la target du document concerné
     * @param event
     * @param documentIndex
     */
  const handleChange = (event: EventType, documentIndex: number) => {
    const { name, value } = event.target
    const list = documentToAddList
    list[documentIndex] = { ...documentToAddList[documentIndex], [name]: value }
    onDocumentToAddEdited([...list])
  }

  /**
     * Changement de communicabilité
     * @param value
     * @param documentIndex
     */
  const handleCheckBoxChange = (value: boolean, documentIndex: number) => {
    const list = documentToAddList
    list[documentIndex] = {
      ...documentToAddList[documentIndex],
      communicability: value ? DOCUMENT_COMMUNICABILITIES.NON_COMMUNICABLE : DOCUMENT_DEFAULT
    }
    onDocumentToAddEdited([...list])
  }

  /**
     * Supprime tous les documents en attente d'ajout
     */
  const handleReset = () => {
    onDocumentToAddEdited([])
    setFieldStatus && setFieldStatus({})
  }

  /**
   * Supprime un document
   * @param documentIndex
   */
  const handleDelete = (documentIndex: number) => {
    onDocumentToAddEdited([...documentToAddList.filter((document: TransactionDocument, index: number) => index !== documentIndex)])
  }

  /**
     * Ajoute le ou les documents à la liste en local
     * @param fileList
     */
  const handleAddDocuments = (fileList: File[]) => {
    const currentDocumentsCount = documentToAddList.length
    let documents: TransactionDocument[] = [...documentToAddList]

    setFieldStatus({})

    for (let index = 0; index < fileList.length; index++) {
      if ((index + currentDocumentsCount >= MAX_NUMBER_FILE) || (currentDocumentsCount === 0 && index >= MAX_NUMBER_FILE)) {
        setFieldStatus({
          ...fieldStatus,
          baseFileInput: `${intl.formatMessage({ id: 'max_file_limit_exceeded' })}${MAX_NUMBER_FILE}`
        })
        break
      }
      const file = fileList[index]

      // Ne pas prendre en compte le fichier si il est déjà présent dans la liste
      if (documents.some((occurence) => occurence.name === file.name)) {
        continue
      }

      documents = [...documents, {
        type: '',
        internalName: '',
        name: file.name,
        format: file.type,
        communicability: DOCUMENT_DEFAULT,
        file,
        size: file.size
      } as TransactionDocument]
    }
    onDocumentToAddEdited(documents)
  }

  return (
    <div className='is-validated pb-5'>
      <div className='d-flex flex-column justify-content-center'>
        <DropArea
          accept={`${DOCUMENT_FORMATS.PDF},
                 ${DOCUMENT_FORMATS.MP4}, ${DOCUMENT_FORMATS.MP3}, ${DOCUMENT_FORMATS.JPEG}`}
          maxSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
          text='document_add_drop'
          onDrop={handleAddDocuments}
          fieldStatus={fieldStatus}
          nameFieldStatus='baseFileInput'
        />

        {documentToAddList.length > 0 && (
          <button className='btn btn-outline-primary align-self-start' onClick={handleReset}>
            <FormattedMessage id='reset_button' />
          </button>
        )}
      </div>
      <Table
        tableTitle={[
          { label: Message.request_add_document_table_name, className: 'table-column-label', colSpan: 2 },
          { label: Message.request_add_document_type_label, className: 'table-column-label', colSpan: 2 },
          {
            label: `${TransactionService.isDivision(transaction) || TransactionService.isRevocationStatement(transaction) ? Message.request_add_document_table_non_communicable : ''}`,
            className: 'table-column-label text-center'
          }
        ]}
        tableClassName={`mt-4 ${documentToAddList.length === 0 ? 'd-none' : ''}`}
        children={
          <tbody>
            {documentToAddList.map((document, index) => {
              return (
                <tr key={index}>
                  <td colSpan={2} className='text-break'>{document.name}</td>
                  <td colSpan={2}>
                    <SelectField
                      inputId='type'
                      className='pl-3'
                      placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                      value={documentToAddList[index].type}
                      onChange={(event: EventType) => handleChange(event, index)}
                      fieldStatus={fieldStatus}
                      nameFieldStatus={`type_${index}`}
                      options={typeDocumentList?.map((type: TransactionDocumentType) => ({
                        label: type.name,
                        value: type.code
                      })) || []}
                      required
                    />
                  </td>
                  {
                    (TransactionService.isDivision(transaction) || TransactionService.isRevocationStatement(transaction)) &&
                      <td className='text-center'>
                        <CheckboxField
                          inputId={`communicability${index}`} label=''
                          nameFieldStatus={`comValue_${index}`}
                          onChange={(event: EventType) => handleCheckBoxChange(event.target.value, index)}
                          checked={document?.communicability === DOCUMENT_COMMUNICABILITIES.NON_COMMUNICABLE}
                        />
                      </td>
                  }
                  <td className='text-center'>
                    <button
                      className='btn btn-link text-danger' onClick={() => {
                        handleDelete(index)
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </td>
                </tr>
              )
            }
            )}
          </tbody>
        }
      />
    </div>
  )
}
export default AddDocumentsWithTypeContent
