export const PUBLICATION_TYPE = {
  FIRST_PUB: 'FIRST_PUB',
  FIRST_UE: 'FIRST_UE',
  FIRST_INTER: 'FIRST_INTER',
  FIRST_DIVISION: 'FIRST_DIVISION',
  FIRST_DIVISION_REG: 'FIRST_DIVISION_REG',
  FIRST_OPBM: 'FIRST_OPBM',
  FIRST_NONE: 'FIRST_NONE',
  FIRST_MISSING: 'FIRST_MISSING',
  FIRST_IRREG: 'FIRST_IRREG',
  FIRST_IRREC: 'FIRST_IRREC',
  FIRST_IRREG_TMI: 'FIRST_IRREG_TMI',
  FIRST_IRREG_DIVISION: 'FIRST_IRREG_DIVISION',
  FIRST_IRREG_DIVISION_REG: 'FIRST_IRREG_DIVISION_REG',
  REGISTRATION: 'REGISTRATION',
  REGISTRATIONAM: 'REGISTRATIONAM',
  REGISTRATION_DIVISION: 'REGISTRATION_DIVISION',
  REGISTRATION_DIVISION_REG: 'REGISTRATION_DIVISION_REG',
  REGISTRATION_TMI_TME: 'REGISTRATION_TMI_TME',
  NOTIF_ERRREG: 'NOTIF_ERRREG',
  NOTIF_ERRATAO: 'NOTIF_ERRATAO',
  NOTIF_ERRATA: 'NOTIF_ERRATA',
  PUBLICATION_PND: 'PUBLICATION_PND',
  INSCRIPTION: 'INSCRIPTION',
  INSCRIPTION_DIVISION: 'Inscription - Marque divisée',
  REGISTRATION_OLD_LAW: 'Enregistrement ancienne loi',
  REGLEMENTUSAGE: 'REGLEMENTUSAGE',
  RENEWAL_TOTAL: 'RENEWAL_TOTAL',
  RENEWAL_PARTIAL: 'RENEWAL_PARTIAL',
  RENEWAL_ERRATA: 'RENEWAL_ERRATA',
  RENUNCIATION: 'RENUNCIATION',
  INSCRIPTION_INDUSTRIAL_REWARD: 'INSCRIPTION_INDUSTRIAL_REWARD',
  INSCRIPTION_RANKING: 'INSCRIPTION_RANKING',
  INSCRIPTION_ERRATA: 'INSCRIPTION_ERRATA',
  INSCRIPTION_REWARD_ERRATA: 'INSCRIPTION_REWARD_ERRATA'
}
