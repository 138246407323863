import {
  Transaction
} from '@inpi-marques/components'
import ProductsAndServicesValidator from 'component/deposit/form/productsAndServices/validator/ProductsAndServicesValidator'
import FeesAndTaxesValidator from 'component/frmi/form/feesAndTaxes/validator/FeesAndTaxesValidator'
import TitlesValidator from 'component/inscription/form/titles/validator/TitlesValidator'
import { FRMIStep } from 'interfaces/frmi/FrmiInterfaces'
import ContributorValidatorAndSaveStore from 'services/contributors/ContributorValidatorAndSaveStore'
import EffectDateValidator from './effectDate/validator/EffectDateValidator'
import ReplacementTitlesValidator from './replacement/titles/validator/ReplacementTitlesValidator'
import PostDesignationVariousIndicationsValidator from './variousIndications/postDesignation/validator/PostDesignationVariousIndicationsValidator'
import ExtensionTypeValidator from './extension/validator/ExtensionTypeValidator'
import CountryValidator from 'component/frmi/form/countries/validator/CountryValidator'
import FrmiContributorValidator from './contributor/validator/FrmiContributorValidator'

class FRMIPostOperationFormValidator {
    validatorSwitch = (step: FRMIStep, transaction: Transaction, hasAgentStep: boolean): boolean => {
      switch (step.id) {
        case 'title':
        case 'titles':
          return TitlesValidator.validateOnChangeStep(transaction)
        case 'products':
          return ProductsAndServicesValidator.validateOnChangeStep(transaction.frmiDetails?.productsAndServicesVersions?.[0])
        case 'designation_various_indications':
          return PostDesignationVariousIndicationsValidator.validateOnChangeStep(transaction.frmiDetails)
        case 'designation_application_date':
          return EffectDateValidator.validateOnChangeStep(transaction)
        case 'taxes':
          return FeesAndTaxesValidator.validateOnChangeStep(transaction.frmiDetails)
        case 'replacement_titles':
          return ReplacementTitlesValidator.validateOnChangeStep(transaction)
        case 'recipient':
          return ContributorValidatorAndSaveStore.validateOnChangeStepRecipient()
        case 'holders':
        case 'old_holders':
          return FrmiContributorValidator.validateOnChangeStepHolders(step.element?.props.newContributorRequired)
        case 'licensees':
          return ContributorValidatorAndSaveStore.validateOnChangeStepLicensees(step.element?.props.newContributorRequired)
        case 'agent':
          return ContributorValidatorAndSaveStore.validateOnChangeStepAgent(step.element?.props.newContributorRequired)
        case 'holder_change_extension':
        case 'licence_inscription_change_extension':
          return ExtensionTypeValidator.validateStep(transaction, true, true)
        case 'renew_extension':
        case 'holder_right_restriction_extension':
          return ExtensionTypeValidator.validateStep(transaction, false, true)
        case 'radiation_extension':
        case 'effect_stop_extension':
        case 'replacement_extension':
        case 'nullification_extension':
          return ExtensionTypeValidator.validateStep(transaction, true)
        case 'countries':
          return CountryValidator.validateOnChangeStep(transaction.frmiDetails)
        case 'signatory':
          return ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit(hasAgentStep)
        default:
          return true
      }
    }
}

export default new FRMIPostOperationFormValidator()
