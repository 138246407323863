import {
  Contributor,
  Deposit,
  FRMI,
  Inscription, OfficialDocumentType,
  Opposition,
  Renewal,
  RevocationStatement,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'

export const TRANSACTION_UPDATE = 'TRANSACTION_UPDATE'
export const TRANSACTION_REMOVE = 'TRANSACTION_REMOVE'
export const TRANSACTION_INIT = 'TRANSACTION_INIT'
export const TRANSACTION_UPDATE_DEPOSIT = 'TRANSACTION_UPDATE_DEPOSIT'
export const TRANSACTION_INIT_DEPOSIT = 'TRANSACTION_INIT_DEPOSIT'
export const TRANSACTION_INIT_FRMI = 'TRANSACTION_INIT_FRMI'
export const BTN_GUIDED_MODE_UPDATE = 'BTN_GUIDED_MODE_UPDATE'
export const BTN_CONTRIBUTOR_UPDATE = 'BTN_CONTRIBUTOR_UPDATE'
export const GUIDED_MODE_RESET = 'GUIDED_MODE_RESET'
export const TRANSACTION_UPDATE_OPPOSITION = 'TRANSACTION_UPDATE_OPPOSITION'
export const TRANSACTION_RESET_DEPOSIT = 'TRANSACTION_RESET_DEPOSIT'
export const TRANSACTION_UPDATE_INSCRIPTION = 'TRANSACTION_UPDATE_INSCRIPTION'
export const TRANSACTION_UPDATE_FRMI = 'TRANSACTION_UPDATE_FRMI'
export const TRANSACTION_UPDATE_OFFICIAL_DOCUMENT_TYPE = 'TRANSACTION_UPDATE_OFFICIAL_DOCUMENT_TYPE'
export const TRANSACTION_INIT_RENEWAL = 'TRANSACTION_INIT_RENEWAL'
export const TRANSACTION_UPDATE_RENEWAL = 'TRANSACTION_UPDATE_RENEWAL'
export const TRANSACTION_UPDATE_REVOCATION_STATEMENT = 'TRANSACTION_UPDATE_REVOCATION_STATEMENT'

export interface TransactionAction {
  type: string,
  transaction?: Transaction,
  transactionType?: string,
  deposit?: Deposit,
  officialDocumentType?: OfficialDocumentType,
  subProcedureType?: string,
  documents?: TransactionDocument[],
  guidedMode?: {
    restrictedQualities: string[],
    activeGuidedMode: boolean,
    choiceContributor: string
  },
  opponents?: Contributor[],
  opposition?: Opposition,
  onlyApiTransaction?: boolean, // permet de spécifier qu'on veut écraser tout le state actuel par la transaction retournée par l'API
  inscription?: Inscription,
  frmi?: FRMI,
  renewal?: Renewal,
  revocationStatement?: RevocationStatement,
  recipient?: Contributor
}

export interface BtnSwitchValue {
  type: string,
  activeGuidedMode: boolean,
  choiceContributor : string,
  restrictedQualities : []
}
