import {
  CONTRIBUTOR_AGENT,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_HOLDERS,
  CONTRIBUTOR_LICENSEE,
  CONTRIBUTOR_OLD_AGENTS,
  CONTRIBUTOR_OLD_HOLDERS,
  CONTRIBUTOR_OLD_LICENSEES,
  CONTRIBUTOR_RECIPIENT,
  CONTRIBUTOR_SIGNATORY,
  DecisionsOverview,
  FRMI_CONTRIBUTORS_OPTIONS,
  FRMI_CONTRIBUTORS_TYPES,
  FRMI_HOLDER_RIGHT_RESTRICTION_COUNTRIES_EXTENSION_TYPES,
  FRMI_INVALIDATION_TYPES,
  FRMI_LICENCE_COUNTRIES_EXTENSION_TYPES,
  FRMI_LICENCE_PRODUCTS_EXTENSION_TYPES,
  FRMI_RADIATION_TYPES,
  FRMI_RENEW_COUNTRIES_EXTENSION_TYPES,
  FRMI_REPLACEMENT_TYPES,
  FRMI_TYPE_AGENT_CONSTITUTE,
  FRMI_TYPE_AGENT_EDITION,
  FRMI_TYPE_DIVISION_INTERNATIONAL,
  FRMI_TYPE_DIVISION_NATIONAL,
  FRMI_TYPE_EFFECT_STOP,
  FRMI_TYPE_FUSION_FROM_DIVISION_INSCRIPTION,
  FRMI_TYPE_FUSION_FROM_HOLDER_EDITION,
  FRMI_TYPE_HOLDER_CHANGE,
  FRMI_TYPE_HOLDER_EDITION,
  FRMI_TYPE_HOLDER_RIGHT_DELETE,
  FRMI_TYPE_HOLDER_RIGHT_RESTRICTION,
  FRMI_TYPE_INSCRIPTION_CORRECTION,
  FRMI_TYPE_LICENCE_EDITION,
  FRMI_TYPE_LICENCE_INSCRIPTION,
  FRMI_TYPE_LICENCE_RADIATION,
  FRMI_TYPE_NATIONAL_REPLACEMENT,
  FRMI_TYPE_NULLIFICATION,
  FRMI_TYPE_POST_DESIGNATION_REGISTER,
  FRMI_TYPE_PRODUCTS_LIMIT,
  FRMI_TYPE_RADIATION,
  FRMI_TYPE_RENEW,
  FRMI_TYPE_RENUNCIATION,
  PROCEDURE_FRMI,
  UE
} from '@inpi-marques/components'
import ContributorFrontForm from 'component/contributors/ContributorFrontForm'
import FRMICountries from 'component/frmi/form/countries/FRMICountries'
import FeesAndTaxes from 'component/frmi/form/feesAndTaxes/FeesAndTaxes'
import LimitedProductsAndServices
  from 'component/frmi/form/limitedProductsAndServicesVersions/LimitedProductsAndServices'
import ProductsAndServicesFRMI from 'component/frmi/form/ProductsAndServices/ProductsAndServicesFRMI'
import AdditionalInformation from 'component/frmiPostOperations/form/additionalInformation/AdditionalInformation'
import FrmiContributorList from 'component/frmiPostOperations/form/contributor/FrmiContributorList'
import Decisions from 'component/frmiPostOperations/form/decisions/Decisions'
import EffectDate from 'component/frmiPostOperations/form/effectDate/EffectDate'
import FactsSummary from 'component/frmiPostOperations/form/factsSummary/FactsSummary'
import LicenceWriteOff from 'component/frmiPostOperations/form/licence/LicenceWriteOff'
import ReplacementTitles from 'component/frmiPostOperations/form/replacement/titles/ReplacementTitles'
import LicenceInscriptionVariousIndications
  from 'component/frmiPostOperations/form/variousIndications/licenceInscription/LicenceInscriptionVariousIndications'
import PostDesignationVariousIndications
  from 'component/frmiPostOperations/form/variousIndications/postDesignation/PostDesignationVariousIndications'
import Titles from 'component/inscription/form/titles/Titles'
import { FRMITypeForm } from 'interfaces/frmi/FrmiInterfaces'
import FeesAndTaxesOverview from '../../component/frmi/form/feesAndTaxes/FeesAndTaxesOverview'
import OverviewCountries from '../../component/frmi/form/countries/OverviewCountries'
import ReplacementTitlesOverview
  from '../../component/frmiPostOperations/form/replacement/titles/ReplacementTitlesOverview'
import FactsSummaryOverview from '../../component/frmiPostOperations/form/factsSummary/FactsSummaryOverview'
import LicenceWriteOffOverview from '../../component/frmiPostOperations/form/licence/LicenceWriteOffOverview'
import LicenceInscriptionVariousIndicationsOverview
  from '../../component/frmiPostOperations/form/variousIndications/licenceInscription/LicenceInscriptionVariousIndicationsOverview'
import AdditionalInformationOverview
  from '../../component/frmiPostOperations/form/additionalInformation/AdditionalInformationOverview'
import EffectDateOverview from '../../component/frmiPostOperations/form/effectDate/EffectDateOverview'
import PostDesignationVariousIndicationsOverview
  from '../../component/frmiPostOperations/form/variousIndications/postDesignation/PostDesignationVariousIndicationsOverview'
import ProducsAndServicesOverview from '../../component/frmi/form/ProductsAndServices/ProductsAndServicesOverview'
import OverviewLimitedProductsAndServices
  from '../../component/frmi/form/limitedProductsAndServicesVersions/OverviewLimitedProductsAndServices'
import HolderChangeExtention from 'component/frmiPostOperations/form/extension/HolderChangeExtention'
import HolderChangeExtentionOverview from 'component/frmiPostOperations/form/extension/HolderChangeExtentionOverview'
import ExtensionsType from '../../component/frmiPostOperations/form/extension/ExtensionsType'
import ExtensionsTypeOverview from 'component/frmiPostOperations/form/extension/ExtensionsTypeOverview'
import LicenceEditionOverview from '../../component/frmiPostOperations/form/licence/LicenceEditionOverview'
import LicenceEdition from '../../component/frmiPostOperations/form/licence/LicenceEdition'
import SenioritiesClaimed from '../../component/frmi/form/senioritiesClaimed/SenioritiesClaimed'
import OverviewSeniorities from '../../component/frmi/form/senioritiesClaimed/OverviewSeniorities'

// Désignation postérieure à l'enregistrement International (MM4)
export const FRMI_TYPE_POST_DESIGNATION_REGISTER_FORM: FRMITypeForm = {
  type: FRMI_TYPE_POST_DESIGNATION_REGISTER.value,
  steps: [
    // Titre
    {
      id: 'title',
      element: {
        component: Titles,
        props: {
          origin: ['WO'],
          isMonoTitle: true,
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Pays
    {
      id: 'countries',
      overview: OverviewCountries,
      element: {
        component: FRMICountries
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOtherLanguageSelect: true,
            hasOMPILanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Correspondant (PROD)
    {
      id: 'recipient',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value,
          actionWithAddressBook: false
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Produits et Service (en fonction des pays)
    {
      id: 'products',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Limitation Produits & Services
    {
      id: 'limited_products_and_services',
      overview: OverviewLimitedProductsAndServices,
      element: {
        component: LimitedProductsAndServices
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Anciennetés revendiquées
    {
      id: 'claimed_seniorities',
      overview: OverviewSeniorities,
      element: {
        component: SenioritiesClaimed
      },
      guard: {
        field: 'claimedSeniorities',
        value: UE
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Indication Diverses, Les termes contenus dans la marque n’ont pas de signification et ne peuvent être traduits (VariousIndication ?), Description Volontaire de la marque
    {
      id: 'designation_various_indications',
      overview: PostDesignationVariousIndicationsOverview,
      element: {
        component: PostDesignationVariousIndications
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Date d'effet
    {
      id: 'designation_application_date',
      overview: EffectDateOverview,
      element: {
        component: EffectDate
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Taxes
    {
      id: 'taxes',
      overview: FeesAndTaxesOverview,
      element: {
        component: FeesAndTaxes
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Demande d'inscription de changement de titulaire (MM5)
export const FRMI_TYPE_HOLDER_CHANGE_FORM: FRMITypeForm = {
  type: FRMI_TYPE_HOLDER_CHANGE.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants', 'holders']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Nouveau + titulaires actuels
    {
      id: 'holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_HOLDERS.value, CONTRIBUTOR_OLD_HOLDERS.value],
          newContributorRequired: true,
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value,
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.depositors,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants', 'holders']
    },
    // Mandataire
    {
      id: 'agent',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          contributorType: CONTRIBUTOR_AGENT.value,
          codes: [CONTRIBUTOR_AGENT.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.HOLDERS.value,
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.agent
        }
      },
      onGoStepPropertyNames: ['agent']
    },
    // Correspondant
    {
      id: 'recipient',
      level: 1,
      guard: {
        field: 'agentCanBeRecipient',
        value: false
      },
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.HOLDERS.value,
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    {
      id: 'holder_change_extension',
      overview: HolderChangeExtentionOverview,
      element: {
        component: HolderChangeExtention
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    {
      id: 'countries',
      overview: OverviewCountries,
      element: {
        component: FRMICountries,
        props: {
          countriesFromTitle: true
        }
      },
      guard: {
        field: 'countriesExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Produits et services
    {
      id: 'products_partial',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      guard: {
        field: 'productsExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Taxes
    {
      id: 'taxes',
      overview: FeesAndTaxesOverview,
      element: {
        component: FeesAndTaxes
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}
// Limitation de la liste des produits et services (MM6)
export const FRMI_TYPE_PRODUCTS_LIMIT_FORM: FRMITypeForm = {
  type: FRMI_TYPE_PRODUCTS_LIMIT.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Pays
    {
      id: 'countries',
      overview: OverviewCountries,
      element: {
        component: FRMICountries,
        props: {
          countriesFromTitle: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Produits et services (limitation)
    {
      id: 'products_brand',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Taxes
    {
      id: 'taxes',
      overview: FeesAndTaxesOverview,
      element: {
        component: FeesAndTaxes
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}
// Renonciation (MM7)
export const FRMI_TYPE_RENUNCIATION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_RENUNCIATION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value,
          actionWithAddressBook: false
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Pays
    {
      id: 'countries',
      overview: OverviewCountries,
      element: {
        component: FRMICountries,
        props: {
          countriesFromTitle: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Radiation (MM8)
export const FRMI_TYPE_RADIATION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_RADIATION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    {
      id: 'radiation_extension',
      overview: ExtensionsTypeOverview,
      element: {
        component: ExtensionsType,
        props: {
          productsExtensionTypes: FRMI_RADIATION_TYPES
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Produits et services
    {
      id: 'products_brand',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      guard: {
        field: 'productsExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Modification du nom ou de l’adresse du titulaire, ou de sa forme juridique (MM9)
export const FRMI_TYPE_HOLDER_EDITION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_HOLDER_EDITION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value,
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasAddressField: true,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value,
          otherApplicantCodeToDuplicate: CONTRIBUTOR_OLD_HOLDERS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Correspondant
    {
      id: 'recipient',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.HOLDERS.value,
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Taxes
    {
      id: 'taxes',
      overview: FeesAndTaxesOverview,
      element: {
        component: FeesAndTaxes
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Modification du nom ou de l’adresse du mandataire (MM10)
export const FRMI_TYPE_AGENT_EDITION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_AGENT_EDITION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Nouveau + mandataires actuels
    {
      id: 'agent',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          contributorType: CONTRIBUTOR_AGENT.value,
          codes: [CONTRIBUTOR_AGENT.value, CONTRIBUTOR_OLD_AGENTS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.agent,
          newContributorRequired: true
        }
      },
      onGoStepPropertyNames: ['agent', 'otherApplicants']
    },
    // Correspondant (Prod)
    {
      id: 'recipient',
      level: 1,
      guard: {
        field: 'agentCanBeRecipient',
        value: false
      },
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    }
  ]
}

// Renouvellement de l’enregistrement international (MM11)
export const FRMI_TYPE_RENEW_FORM: FRMITypeForm = {
  type: FRMI_TYPE_RENEW.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Correspondant (Prod)
    {
      id: 'recipient',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    {
      id: 'renew_extension',
      overview: ExtensionsTypeOverview,
      element: {
        component: ExtensionsType,
        props: {
          countriesExtensionTypes: FRMI_RENEW_COUNTRIES_EXTENSION_TYPES
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    {
      id: 'countries',
      overview: OverviewCountries,
      element: {
        component: FRMICountries,
        props: {
          countriesFromTitle: true
        }
      },
      guard: {
        field: 'countriesExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Taxes
    {
      id: 'taxes',
      overview: FeesAndTaxesOverview,
      element: {
        component: FeesAndTaxes
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Constitution d’un mandataire (MM12)
export const FRMI_TYPE_AGENT_CONSTITUTE_FORM: FRMITypeForm = {
  type: FRMI_TYPE_AGENT_CONSTITUTE.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Mandataire
    {
      id: 'agent',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          contributorType: CONTRIBUTOR_AGENT.value,
          codes: [CONTRIBUTOR_AGENT.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.HOLDERS.value,
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.agent,
          newContributorRequired: true
        }
      },
      onGoStepPropertyNames: ['agent']
    },
    // Correspondant (prod)
    {
      id: 'recipient',
      level: 1,
      guard: {
        field: 'agentCanBeRecipient',
        value: false
      },
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.AGENT.value
          ],
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    }
  ]
}

// Licence (MM13)
export const FRMI_TYPE_LICENCE_INSCRIPTION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_LICENCE_INSCRIPTION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Mandataire
    {
      id: 'agent',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          contributorType: CONTRIBUTOR_AGENT.value,
          codes: [CONTRIBUTOR_AGENT.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.HOLDERS.value
          ],
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.agent
        }
      },
      onGoStepPropertyNames: ['agent']
    },
    // Preneur de licence
    {
      id: 'licensees',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          contributorType: CONTRIBUTOR_DEPOSITORS.value,
          codes: [CONTRIBUTOR_LICENSEE.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.depositors,
            hasOtherLanguageSelect: false
          },
          newContributorRequired: true
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    {
      id: 'licence_inscription_change_extension',
      overview: ExtensionsTypeOverview,
      element: {
        component: ExtensionsType,
        props: {
          productsExtensionTypes: FRMI_LICENCE_PRODUCTS_EXTENSION_TYPES,
          countriesExtensionTypes: FRMI_LICENCE_COUNTRIES_EXTENSION_TYPES
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Produits et services
    {
      id: 'products_partial',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      guard: {
        field: 'productsExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    {
      id: 'countries',
      overview: OverviewCountries,
      element: {
        component: FRMICountries,
        props: {
          countriesFromTitle: true
        }
      },
      guard: {
        field: 'countriesExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Indication diverses
    {
      id: 'licence_various_indications',
      overview: LicenceInscriptionVariousIndicationsOverview,
      element: {
        component: LicenceInscriptionVariousIndications
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Taxes
    {
      id: 'taxes',
      overview: FeesAndTaxesOverview,
      element: {
        component: FeesAndTaxes
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Modification de l’inscription d’une Licence (MM14)
export const FRMI_TYPE_LICENCE_EDITION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_LICENCE_EDITION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasAddressField: true,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Correspondant (prod)
    {
      id: 'recipient',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Nouveau + preneurs de licence actuels
    {
      id: 'licensees',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          contributorType: CONTRIBUTOR_DEPOSITORS.value,
          codes: [CONTRIBUTOR_OLD_LICENSEES.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.depositors,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          otherApplicantCodeToDuplicate: CONTRIBUTOR_OLD_LICENSEES.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Licence à modifier
    {
      id: 'licence_edition',
      overview: LicenceEditionOverview,
      element: {
        component: LicenceEdition
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Taxes
    {
      id: 'taxes',
      overview: FeesAndTaxesOverview,
      element: {
        component: FeesAndTaxes
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Radiation d’une Licence (MM15)
export const FRMI_TYPE_LICENCE_RADIATION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_LICENCE_RADIATION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Correspondant (Prod)
    {
      id: 'recipient',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Nouveau preneur de licence
    {
      id: 'licensees',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          contributorType: CONTRIBUTOR_DEPOSITORS.value,
          codes: [CONTRIBUTOR_OLD_LICENSEES.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.depositors,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          }
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Licence à radier
    {
      id: 'licence_write_off',
      overview: LicenceWriteOffOverview,
      element: {
        component: LicenceWriteOff
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Restriction du droit du titulaire (MM19)
export const FRMI_TYPE_HOLDER_RIGHT_RESTRICTION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_HOLDER_RIGHT_RESTRICTION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Correspondant (prod)
    {
      id: 'recipient',
      level: 1,
      guard: {
        field: 'agentCanBeRecipient',
        value: false
      },
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Pays
    {
      id: 'holder_right_restriction_extension',
      overview: ExtensionsTypeOverview,
      element: {
        component: ExtensionsType,
        props: {
          countriesExtensionTypes: FRMI_HOLDER_RIGHT_RESTRICTION_COUNTRIES_EXTENSION_TYPES
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    {
      id: 'countries',
      overview: OverviewCountries,
      element: {
        component: FRMICountries,
        props: {
          countriesFromTitle: true
        }
      },
      guard: {
        field: 'countriesExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Résumé des faits
    {
      id: 'facts_summary',
      overview: FactsSummaryOverview,
      element: {
        component: FactsSummary,
        props: {
          type: 'restriction'
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Main Levée d’une restriction du droit du titulaire (MM19)
export const FRMI_TYPE_HOLDER_RIGHT_DELETE_FORM: FRMITypeForm = {
  type: FRMI_TYPE_HOLDER_RIGHT_DELETE.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant (Prod)
    {
      id: 'contributors'
    },
    // Titulaires actuels (Prod)
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Correspondant (prod)
    {
      id: 'recipient',
      level: 1,
      guard: {
        field: 'agentCanBeRecipient',
        value: false
      },
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_RECIPIENT.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.recipient,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value]
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Résumé des faits
    {
      id: 'facts_summary',
      overview: FactsSummaryOverview,
      element: {
        component: FactsSummary,
        props: {
          type: 'deletion'
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Rectification d’une inscription (MM21)
export const FRMI_TYPE_INSCRIPTION_CORRECTION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_INSCRIPTION_CORRECTION.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    /**
     * NUMERO DE REFERENCE OMPI si présenté par le titulaire ou mandataire
     * NUMERO DE NOTIFICATION OMPI si présenté par l’INPI
     * DESCRIPTION DE LA RECTIFICATION DEMANDEE
     */
    {
      id: 'additional_information',
      overview: AdditionalInformationOverview,
      element: {
        component: AdditionalInformation
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Division d’un enregistrement International (MM22)
export const FRMI_TYPE_DIVISION_INTERNATIONAL_FORM: FRMITypeForm = {
  type: FRMI_TYPE_DIVISION_INTERNATIONAL.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant (Prod)
    {
      id: 'contributors'
    },
    // Titulaires actuels (Prod)
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Produits et services
    {
      id: 'products_partial',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    /**
     * Détails d'une division
     */
    {
      id: 'additional_information',
      overview: AdditionalInformationOverview,
      element: {
        component: AdditionalInformation
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Division d’un enregistrement National (MM22- bis)
export const FRMI_TYPE_DIVISION_NATIONAL_FORM: FRMITypeForm = {
  type: FRMI_TYPE_DIVISION_NATIONAL.value,
  steps: [
    // Titres
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant (Prod)
    {
      id: 'contributors'
    },
    // Titulaires actuels (Prod)
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // produits et services
    {
      id: 'products_partial',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    /**
     * Détails d'une division
     */
    {
      id: 'additional_information',
      overview: AdditionalInformationOverview,
      element: {
        component: AdditionalInformation
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Fusion d’enregistrements internationaux (MM23) issus de l’inscription d’un changement partiel de titulaire
export const FRMI_TYPE_FUSION_FROM_HOLDER_EDITION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_FUSION_FROM_HOLDER_EDITION.value,
  steps: [
    // Titres à fusionner
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    }
  ]
}

// Fusion d’un enregistrement international issu de l’inscription d’une division(MM24)
export const FRMI_TYPE_FUSION_FROM_DIVISION_INSCRIPTION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_FUSION_FROM_DIVISION_INSCRIPTION.value,
  steps: [
    // Titres à fusionner
    {
      id: 'titles',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.depositors,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    }
  ]
}

// Cessation des effets de l’enregistrement international dû à la cessation des effets de la demande de base (R22)
export const FRMI_TYPE_EFFECT_STOP_FORM: FRMITypeForm = {
  type: FRMI_TYPE_EFFECT_STOP.value,
  steps: [
    // Titre
    {
      id: 'title',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          isMonoTitle: true,
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          contributorTypeToCopy: [
            FRMI_CONTRIBUTORS_TYPES.RECIPIENT.value
          ],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Faits et decisions
    {
      id: 'decisions',
      overview: DecisionsOverview,
      element: {
        component: Decisions
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    {
      id: 'effect_stop_extension',
      overview: ExtensionsTypeOverview,
      element: {
        component: ExtensionsType,
        props: {
          productsExtensionTypes: FRMI_RADIATION_TYPES
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Produits et services
    {
      id: 'products_radiation',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      guard: {
        field: 'productsExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    /**
     * Office qui envoie la notification
     * Demande de radiation de l'enregistrement international
     */
    {
      id: 'additional_information',
      overview: AdditionalInformationOverview,
      element: {
        component: AdditionalInformation
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Invalidation (IJ)
export const FRMI_TYPE_NULLIFICATION_FORM: FRMITypeForm = {
  type: FRMI_TYPE_NULLIFICATION.value,
  steps: [
    // Titre
    {
      id: 'title',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          isMonoTitle: true,
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Informations concernant l'invalidation
    {
      id: 'nullification_extension',
      overview: ExtensionsTypeOverview,
      element: {
        component: ExtensionsType,
        props: {
          productsExtensionTypes: FRMI_INVALIDATION_TYPES
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Produits et services
    {
      id: 'products_brand',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      guard: {
        field: 'productsExtensionType',
        notValue: 'TOTAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    /**
     * Nom de l'office
     * Date de notification de l'enregistrement international
     * Autorite qui a prononce l'invalidation
     * Date d'effet (ou date à laquelle l'invalidation a ete prononcee)
     * Autorité qui a prononcé l'invalidation
     */
    {
      id: 'additional_information',
      overview: AdditionalInformationOverview,
      element: {
        component: AdditionalInformation
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

// Remplacement d’un enregistrement national ou régional par un enregistrement international
export const FRMI_TYPE_NATIONAL_REPLACEMENT_FORM: FRMITypeForm = {
  type: FRMI_TYPE_NATIONAL_REPLACEMENT.value,
  steps: [
    // Titre
    {
      id: 'title',
      element: {
        component: Titles,
        props: {
          showDownloadExampleFile: true,
          showImportTitleFile: true,
          origin: ['WO'],
          isMonoTitle: true,
          fromMadrid: true
        }
      },
      onGoStepPropertyNames: ['frmiDetails', 'otherApplicants']
    },
    // Intervenant
    {
      id: 'contributors'
    },
    // Titulaires actuels
    {
      id: 'old_holders',
      level: 1,
      stepButtonsAsChildren: true,
      element: {
        component: FrmiContributorList,
        props: {
          codes: [CONTRIBUTOR_OLD_HOLDERS.value],
          optionForm: {
            ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
            hasOMPILanguageSelect: false,
            hasOtherLanguageSelect: false
          },
          contributorType: CONTRIBUTOR_DEPOSITORS.value
        }
      },
      onGoStepPropertyNames: ['otherApplicants']
    },
    // Signataire
    {
      id: 'signatory',
      level: 1,
      element: {
        component: ContributorFrontForm,
        props: {
          contributorType: CONTRIBUTOR_SIGNATORY.value,
          optionForm: FRMI_CONTRIBUTORS_OPTIONS.signatory,
          procedure: PROCEDURE_FRMI.value
        },
        needDefaultContributor: true
      },
      onGoStepPropertyNames: [FRMI_CONTRIBUTORS_TYPES.SIGNATORY.value]
    },
    // Informations concernant l'enregistrement
    {
      id: 'replacement_titles',
      overview: ReplacementTitlesOverview,
      element: {
        component: ReplacementTitles
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Informations concernant la portée du remplacement
    {
      id: 'replacement_extension',
      overview: ExtensionsTypeOverview,
      element: {
        component: ExtensionsType,
        props: {
          productsExtensionTypes: FRMI_REPLACEMENT_TYPES
        }
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Produits et services
    {
      id: 'replacement_products',
      overview: ProducsAndServicesOverview,
      element: {
        component: ProductsAndServicesFRMI
      },
      guard: {
        field: 'productsExtensionType',
        value: 'PARTIAL'
      },
      onGoStepPropertyNames: ['frmiDetails']
    },
    // Nom de l'office
    {
      id: 'additional_information',
      overview: AdditionalInformationOverview,
      element: {
        component: AdditionalInformation
      },
      onGoStepPropertyNames: ['frmiDetails']
    }
  ]
}

export const FRMI_TYPES_FORMS: FRMITypeForm[] = [
  FRMI_TYPE_POST_DESIGNATION_REGISTER_FORM,
  FRMI_TYPE_HOLDER_CHANGE_FORM,
  FRMI_TYPE_PRODUCTS_LIMIT_FORM,
  FRMI_TYPE_RENUNCIATION_FORM,
  FRMI_TYPE_RADIATION_FORM,
  FRMI_TYPE_HOLDER_EDITION_FORM,
  FRMI_TYPE_AGENT_EDITION_FORM,
  FRMI_TYPE_RENEW_FORM,
  FRMI_TYPE_AGENT_CONSTITUTE_FORM,
  FRMI_TYPE_LICENCE_INSCRIPTION_FORM,
  FRMI_TYPE_LICENCE_EDITION_FORM,
  FRMI_TYPE_LICENCE_RADIATION_FORM,
  FRMI_TYPE_HOLDER_RIGHT_RESTRICTION_FORM,
  FRMI_TYPE_HOLDER_RIGHT_DELETE_FORM,
  FRMI_TYPE_INSCRIPTION_CORRECTION_FORM,
  FRMI_TYPE_DIVISION_INTERNATIONAL_FORM,
  FRMI_TYPE_DIVISION_NATIONAL_FORM,
  FRMI_TYPE_FUSION_FROM_HOLDER_EDITION_FORM,
  FRMI_TYPE_FUSION_FROM_DIVISION_INSCRIPTION_FORM,
  FRMI_TYPE_EFFECT_STOP_FORM,
  FRMI_TYPE_NULLIFICATION_FORM,
  FRMI_TYPE_NATIONAL_REPLACEMENT_FORM
]
