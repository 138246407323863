import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'
import { SubmitButtonLoader } from '../index'

interface ListLoaderProps {
  isLittleSkeleton?: boolean,
  numberOfLines?: number,
  noBottomButtons?: boolean,
  className?: string,
}

const ListLoader: FC<ListLoaderProps> = ({ isLittleSkeleton = false, numberOfLines = 5, noBottomButtons = false, className }) => {
  return (
    <div className={`${className || 'mt-4'}`}>
      <div className='mb-3 w-100'>
        <ContentLoader
          speed={2}
          style={{ width: '100%', height: `${numberOfLines ? `${numberOfLines * 55 + 25}` : isLittleSkeleton ? '275px' : '550px'}` }}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          {
            numberOfLines &&
              <>
                {
                  [...Array(numberOfLines)].map(
                    (line, index) => <rect key={index} x={30} y={30 + index * 55} rx='5' ry='5' width='95%' height={15} />)
                }
              </>
          }
        </ContentLoader>
      </div>
      {!noBottomButtons &&
        <>
          <div className='d-flex justify-content-end row'>
            <div className='col-12 col-md-3'>
              <SubmitButtonLoader
                height={38}
                width={294}
              />
            </div>
            <div className='col-12 col-md-4 d-flex justify-content-end '>
              <SubmitButtonLoader
                height={38}
                width={213}
              />
            </div>
          </div>
          <div className='d-flex justify-content-end mr-3'>
            <SubmitButtonLoader
              height={24}
              width={170}
            />
          </div>
        </>}
    </div>
  )
}

export default ListLoader
