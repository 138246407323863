import {
  CONTRIBUTOR_LICENSEE,
  CONTRIBUTOR_OLD_AGENTS,
  CONTRIBUTOR_OLD_HOLDERS,
  CONTRIBUTOR_OLD_LICENSEES,
  Country,
  FRMI_TYPE_HOLDER_CHANGE,
  FRMI_TYPE_LICENCE_INSCRIPTION,
  FRMI_TYPE_POST_DESIGNATION_REGISTER,
  isFilled,
  PROCEDURE_FRMI,
  Transaction,
  TransactionService
} from '@inpi-marques/components'
import FrmiOtherApplicantsOverview from 'component/frmiPostOperations/form/contributor/FrmiOtherApplicantsOverview'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ContentService from 'services/content/ContentService'
import ContributorListOverview from './ContributorListOverview'

interface IntervenantsOverviewProps {
    transaction: Transaction
}

const IntervenantsOverview: FC<IntervenantsOverviewProps> = ({ transaction }) => {
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  return (
    <div className='d-flex flex-wrap row'>
      <div className='col-12 col-md-6'>
        {transaction.opponents && isFilled(transaction.opponents) &&
          <div className='mb-3'>
            <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_opponents' /></h4>
            <ContributorListOverview contributors={transaction.opponents} countries={countries} />
          </div>}

        {transaction.depositors && isFilled(transaction.depositors) &&
          <div className='mb-3'>
            <h4 className='mb-2 font-weight-bold'><FormattedMessage id={`contributor_depositors_${TransactionService.isRenewal(transaction) ? 'renewal' : 'nullity'}`} /></h4>
            <ContributorListOverview contributors={transaction.depositors} countries={countries} />
          </div>}

        {transaction.holders && isFilled(transaction.holders) &&
          <div className='mb-3'>
            <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_holders' /></h4>
            <ContributorListOverview contributors={transaction.holders} countries={countries} />
          </div>}

        {transaction.agent &&
          <div className='mb-3'>
            <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_agent' /></h4>
            <ContributorListOverview contributors={[transaction.agent]} countries={countries} />
          </div>}

        {transaction.recipient &&
          <div className=' mb-3'>
            <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_recipient' /></h4>
            <ContributorListOverview contributors={[transaction.recipient]} countries={countries} />
          </div>}

        {transaction.signatory &&
          <div className='mb-3'>
            <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_signatory' /></h4>
            <ContributorListOverview contributors={[transaction.signatory]} countries={countries} />
          </div>}

        {transaction?.holdersContestedMark && transaction.holdersContestedMark.length > 0 &&
          <div className='mb-3'>
            <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_holdersContestedMark' />
            </h4>
            <ContributorListOverview contributors={transaction.holdersContestedMark} countries={countries} />
          </div>}

        {transaction.depositor &&
          <div className='col-12 col-md-6 mb-3'>
            <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_recipient_opposant' /></h4>
            <ContributorListOverview contributors={[transaction.depositor]} countries={countries} />
          </div>}

        {transaction.procedureType === PROCEDURE_FRMI.value && transaction.otherApplicants && isFilled(transaction.otherApplicants) &&
          <FrmiOtherApplicantsOverview
            contributors={transaction.otherApplicants}
            countries={countries}
            codes={[CONTRIBUTOR_OLD_HOLDERS.value, CONTRIBUTOR_OLD_AGENTS.value, CONTRIBUTOR_LICENSEE.value, CONTRIBUTOR_OLD_LICENSEES.value]}
            fromFrmiExtension={transaction.subProcedureType === FRMI_TYPE_POST_DESIGNATION_REGISTER.value || transaction.subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value ||
              transaction.subProcedureType === FRMI_TYPE_LICENCE_INSCRIPTION.value}
            subProcedureType={transaction.subProcedureType}
          />}
      </div>
    </div>
  )
}

export default IntervenantsOverview
