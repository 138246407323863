import { Transaction } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import TransactionService from 'services/transaction/TransactionService'

interface TimeLineProps {
    transaction : Transaction
}

const Timeline: FC<TimeLineProps> = ({ transaction }) => {
  const [timeline, setTimeline] = useState<string>()

  useEffect(() => {
    TransactionService.getTimeline(transaction).then(setTimeline)

    return () => {
      TransactionService.cancelRequest()
    }
  }, [])

  return timeline
    ? (
      <div className='d-flex justify-content-end'>
        <div className='col-9' dangerouslySetInnerHTML={{ __html: timeline }} />
      </div>)
    : <></>
}

export default Timeline
