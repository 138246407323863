import store from '../../../../../store/store'
import {
  containsErrors,
  FieldStatus,
  Transaction, isFilled
} from '@inpi-marques/components'
import Message from '../../../../../constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import SuiteValidator from 'component/suite/validator/SuiteValidator'

class InscriptionTypeValidator {
    /**
     * Validation de l'étape du choix de type d'inscription
     * @param transaction
     * @param fieldStatus
     * @returns
     */
    validateOnChangeStep = (transaction: Transaction, fieldStatus?: FieldStatus): boolean => {
      const newFieldStatus: FieldStatus = {
        type: !isFilled(transaction.subProcedureType) ? Message.inscription_type_required : '',
        position: SuiteValidator.validate(transaction, fieldStatus)
      }
      store.dispatch(storeTransactionFieldStatusUpdate(newFieldStatus))
      return !containsErrors(newFieldStatus)
    }
}

export default new InscriptionTypeValidator()
