import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import ContentService from 'services/content/ContentService'
import cloneDeep from 'lodash.clonedeep'
import {
  Country,
  DATE_ISO,
  DateField,
  DateUtils,
  EventType,
  FieldStatus,
  HelpBlock,
  NumNatVerifier,
  Priority,
  PriorityService,
  ProductClass,
  ProductService,
  Record,
  TextField,
  FRMI,
  PRODUCT_STATUS,
  SelectField,
  SelectOption,
  Title
} from '@inpi-marques/components'
import CountrySelectField from '@inpi-marques/components/src/contributors/address/CountrySelectField'
import ProductAndServicesFreeInput from '../../../deposit/form/productsAndServices/ProductAndServicesFreeinput'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../store/fieldStatus/fieldStatusActions'
import RecordService from '../../../../services/opposition/RecordService'

interface PriorityFRMIFieldsProps extends WrappedComponentProps {
    priority?: Priority,
    onChange: (event: EventType) => void,
    fieldStatus?: FieldStatus | any,
    setPriority: (newPriority: Priority) => void,
    frmiDetails: FRMI
}

const PriorityFRMIFields: FC<PriorityFRMIFieldsProps> = (
  {
    priority,
    onChange,
    fieldStatus,
    intl,
    setPriority,
    frmiDetails
  }) => {
  const dispatch = useDispatch()
  const [newProductClasses, setNewProductClasses] = useState<ProductClass[]>(priority?.productAndServices || [])
  const [nationalNumber, setNationalNumber] = useState<string>(priority?.originalDepositNumber ?? '')
  const [country, setCountry] = useState<Country>()
  const [notExist, setNotExist] = useState<boolean>(false)
  const [titleFrNumNats, setTitleFrNumNats] = useState<SelectOption[]>([])

  useEffect(() => {
    setPriority({ ...priority, productAndServices: newProductClasses })
  }, [newProductClasses])

  useEffect(() => {
    ContentService.getCountry('fr').then(response => setCountry(response))
  }, [])

  useEffect(() => {
    if (priority?.countryOrOrganization === 'FR' && frmiDetails.titles?.length) {
      if (frmiDetails.titles.length === 1) {
        if (priority?.productAndServices?.length === 0) {
          onTitleFrSelected(frmiDetails.titles[0])
        }
      } else {
        setTitleFrNumNats(frmiDetails.titles.map((title: Title) => ({ value: title.numNat ?? '', label: `${title.numNat} - ${title.text}` })))
      }
    }
  }, [priority?.countryOrOrganization])

  const onTitleFrSelected = (selectedTitle?: Title): void => {
    if (selectedTitle) {
      setNationalNumber(selectedTitle.numNat ?? '')

      let productClasses: ProductClass[]|null = null
      if (frmiDetails?.productsAndServicesVersions?.[0] && selectedTitle.numNat) {
        productClasses = cloneDeep(frmiDetails.productsAndServicesVersions[0]).productClasses.filter((productClass: ProductClass) => !productClass.origin || productClass.origin === selectedTitle.numNat)
      }

      setNewProductClasses(ProductService.filterProductClassesByProductNotStatus(productClasses ?? [], PRODUCT_STATUS.DELETED))
      const newPriority: Priority = { originalDepositNumber: selectedTitle.numNat ?? '', originalDepositDate: DateUtils.formatToBeginOfDay(selectedTitle.depositDate), countryOrOrganization: 'FR', productAndServices: [] }
      setPriority(newPriority)
      verifyNumNat()
    }
  }

  const onPriorityDateChanged = (event: EventType) => {
    const { value } = event.target

    const newPriority = {
      ...priority,
      originalDepositDate: DateUtils.formatToBeginOfDay(value)
    }
    setPriority(newPriority)
  }

  /**
     * Récupère les informations de la marque via la base publique.
     */
  const verifyNumNat = async (): Promise<Record[]> => {
    // On reset uniquement les erreurs en rapport liées au composant
    dispatch(storeTransactionFieldStatusUpdate({
      ...fieldStatus,
      originalDepositNumber: undefined,
      originalDepositDate: undefined
    }))

    if (nationalNumber) {
      const response: Record[] = await RecordService.verifyNumnat({ numNat: nationalNumber, origin: ['FR'] }, true)
      if (response.length) {
        return response
      } else {
        setNotExist(true)
        const newPriority: Priority = { originalDepositNumber: nationalNumber, originalDepositDate: undefined, countryOrOrganization: 'FR', productAndServices: [] }
        setPriority(newPriority)
        setNewProductClasses([])
      }
    }
  }

  /**
   * Génère une priorité à partir de la marque récupérée
   * @param record
   */
  const onRecordSelected = (record: Record) => {
    setNotExist(false)
    setPriority(PriorityService.getPriorityFromRecord(record))
    setNewProductClasses(ProductService.getCurrentVersion(record?.details?.productsAndServicesVersions)?.productClasses || [])
  }

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <CountrySelectField
            inputId='countryOrOrganization'
            label={intl.formatMessage({ id: 'form_office_label' })}
            value={priority?.countryOrOrganization}
            onChange={onChange}
            fieldStatus={fieldStatus}
            required
            placeholder={intl.formatMessage({ id: 'form_office_placeholder' })}
            getCountries={ContentService.getCountries}
            isPriority
          />
        </div>
        {titleFrNumNats.length > 0 &&
          <div className='col-12 col-md-6'>
            <SelectField
              inputId='numnats'
              options={titleFrNumNats}
              onChange={(event: EventType) => onTitleFrSelected(frmiDetails.titles?.find((title: Title) => title.numNat === event.target.value))}
              label={<FormattedMessage id='form_deposit_number_select' />}
              value={priority?.originalDepositNumber}
              placeholder={intl.formatMessage({ id: 'form_deposit_number_select_placeholder' })}
            />
          </div>}
        <div className='col-12 col-md-6'>
          {priority?.countryOrOrganization !== 'FR' &&
            <TextField
              inputId='originalDepositNumber'
              required
              value={priority?.originalDepositNumber}
              label={<FormattedMessage id='form_deposit_number' />}
              onChange={onChange}
              fieldStatus={fieldStatus}
              nameFieldStatus='originalDepositNumber'
            />}
          {priority?.countryOrOrganization === 'FR' &&
            <NumNatVerifier
              inputId='originalDepositNumber'
              className='col p-0'
              labelClassname='mr-2'
              value={nationalNumber}
              fieldStatus={fieldStatus}
              dispatch={dispatch}
              setValue={setNationalNumber}
              countries={country ? [country] : []}
              verifyNumNat={verifyNumNat}
              label={<FormattedMessage id='form_deposit_number' />}
              onRecordSelected={verifyNumNat ? (record: Record) => {
                onRecordSelected(record)
              } : undefined}
              nameFieldStatus='originalDepositNumber'
              setFieldStatus={storeTransactionFieldStatusUpdate}
              disabled={false}
            />}
        </div>
        <div className='col-12 col-md-6'>
          <DateField
            inputId='originalDepositDate'
            required
            value={DateUtils.formateDateToFormat(priority?.originalDepositDate, DATE_ISO) ?? ''}
            label={<FormattedMessage id='form_deposit_date' />}
            onChange={onPriorityDateChanged}
            fieldStatus={fieldStatus}
            nameFieldStatus='originalDepositDate'
            classNameLabel='col-12 p-0'
            maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) ?? undefined}
          />
        </div>
        {
          notExist && priority?.countryOrOrganization === 'FR' &&
            <div className='col-12'><HelpBlock className='mb-2 mt-2 col-12 p-0'><FormattedMessage id='priority_frmi_record_not_found' /></HelpBlock></div>
        }
      </div>
      <ProductAndServicesFreeInput
        productClasses={newProductClasses}
        onProductClassesEdited={(newClasses: ProductClass[]) => setNewProductClasses(newClasses)}
        isEditable
        asLink
      />
    </>
  )
}

export default injectIntl(PriorityFRMIFields)
