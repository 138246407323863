import { DOCUMENT_COMMUNICABILITIES, DOCUMENT_TYPES, Transaction, TransactionDocument } from '@inpi-marques/components'
import React, { FC, ReactNode } from 'react'
import DocumentsBlockItem from './DocumentsBlockItem'

interface DocumentsBlockProps {
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument)=> void,
    onDocumentEdited: (document: TransactionDocument) => void,
    title: ReactNode,
    documents: TransactionDocument[],
    documentType: string,
    transaction: Transaction,
    required?: boolean
}

const DocumentsBlock: FC<DocumentsBlockProps> = ({ documents, title, onDocumentAdded, onDocumentDeleted, onDocumentEdited, documentType, transaction, required = false }) => {
  return (
    <div className='my-4'>
      <div className='font-weight-bold'>
        {title}
        {required && <span className='text-danger'> *</span>}
      </div>
      {documents.map((document: TransactionDocument, key: number) =>
        <DocumentsBlockItem
          document={document}
          key={key}
          onDocumentAdded={onDocumentAdded}
          onDocumentDeleted={onDocumentDeleted}
          onDocumentEdited={onDocumentEdited}
          documentType={documentType}
          useDescription={documentType === DOCUMENT_TYPES.INSCRIPTION_OTHER}
          transaction={transaction}
        />
      )}
      <DocumentsBlockItem
        document={{ name: '', format: '', type: documentType, communicability: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE, originEmail: '' }}
        onDocumentAdded={onDocumentAdded}
        onDocumentEdited={onDocumentEdited}
        documentType={documentType}
        useDescription={documentType === DOCUMENT_TYPES.INSCRIPTION_OTHER}
        transaction={transaction}
      />
    </div>)
}

export default DocumentsBlock
