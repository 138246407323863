import {
  CardBlock,
  DownloadLink, EventType,
  FieldStatus,
  FRMI_TYPE_EXTENSION,
  Payment,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION, PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_NULLITY,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_RENEWAL,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION,
  PROCEDURE_REVOCATION_STATEMENT,
  Transaction,
  TransactionDocument,
  ErrorField
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import DepositService from 'services/deposit/DepositService'
import TransactionService from 'services/transaction/TransactionService'
import OppositionOverview from '../opposition/overview/OppositionOverview'
import DepositOverview from '../deposit/overview/DepositOverview'
import TransactionAmountOverview from '../transactions/overview/TransactionAmount'
import ConsentRecap from './ConsentRecap'
import OppositionService from '../../services/opposition/OppositionService'
import NullityService from '../../services/nullity/NullityService'
import DivisionService from '../../services/division/DivisionService'
import InscriptionOverview from '../inscription/overview/InscriptionOverview'
import InscriptionService from '../../services/inscription/InscriptionService'
import InscriptionProcessOverview from '../inscription/overview/InscriptionProcessOverview'
import FRMIOverview from '../frmi/FRMIOverview'
import FRMIService from '../../services/frmi/FRMIService'
import FRMIPostOperationOverview from 'component/frmiPostOperations/overview/FRMIPostOperationOverview'
import OfficialDocumentOverview from '../officialDocument/OfficialDocumentOverview'
import OfficialDocumentService from '../../services/officialDocument/OfficialDocumentService'
import RevocationStatementOverview from 'component/revocationStatement/overview/RevocationStatementOverview'
import RevocationStatementService from 'services/revocation-statement/RevocationStatementService'
import RenewalService from '../../services/renewal/RenewalService'
import RenewalOverview from '../renewal/overview/RenewalOverview'

interface RecapProps {
  transaction: Transaction,
  fieldStatus: FieldStatus,
  onDocumentChanged?: (event: EventType) => void
  onDocumentDeleted?: (event?: EventType) => void,
  documents?: TransactionDocument[]
}

const Recap: FC<RecapProps> = ({ transaction, fieldStatus, onDocumentDeleted, onDocumentChanged, documents }) => {
  const [payment, setPayment] = useState<Payment>()
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)

  useEffect(() => {
    let unmounted = false
    if (transaction?.id) {
      switch (transaction.procedureType) {
        case PROCEDURE_DEPOSIT.value:
          DepositService.getPaiement(transaction?.id).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        case PROCEDURE_OPPOSITION.value:
          OppositionService.getPaiement(transaction?.id).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        case PROCEDURE_REVOCATION.value:
        case PROCEDURE_NULLITY.value:
          NullityService.getPaiement(transaction?.id).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        case PROCEDURE_DIVISION.value:
          DivisionService.getPaiement(transaction?.id).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        case PROCEDURE_INSCRIPTION.value:
          InscriptionService.getPaiement(transaction?.id).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        case PROCEDURE_FRMI.value:
          FRMIService.getPaiement(transaction.id).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        case
          PROCEDURE_OFFICIAL_DOCUMENT.value:
          OfficialDocumentService.getPaiement(transaction.id, transaction.officialDocument?.isAdministration).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        case PROCEDURE_REVOCATION_STATEMENT.value:
          RevocationStatementService.getPaiement(transaction).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        case PROCEDURE_RENEWAL.value:
          RenewalService.getPaiement(transaction).then((payment: Payment) => {
            if (!unmounted && payment) {
              setPayment(payment)
            }
          })
          break
        default:
          break
      }
    }
    return () => {
      unmounted = true
    }
  }, [])

  return (
    <div className='d-flex flex-wrap'>
      <div className='col-12 mb-5'>
        <h1><FormattedMessage id='overview_title' /></h1>
        <DownloadLink
          label={<FormattedMessage id='payment_download' />}
          onClick={() => TransactionService.getOverview(transaction.id)}
        />
      </div>

      {
        (transaction.procedureType === PROCEDURE_DEPOSIT.value || transaction.procedureType === PROCEDURE_DIVISION.value) &&
          <DepositOverview transaction={transaction} isUserActive />
      }
      {
        transaction.procedureType &&
        [PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value].includes(transaction.procedureType) &&
          <OppositionOverview transaction={transaction} isUserActive />
      }
      {
        (transaction.procedureType === PROCEDURE_INSCRIPTION.value) &&
          <>
            <InscriptionOverview transaction={transaction} />
            <InscriptionProcessOverview transaction={transaction} setPayment={setPayment} setIsPaymentLoading={setIsPaymentLoading} />
          </>
      }
      {transaction.procedureType === PROCEDURE_FRMI.value &&
        <>
          {transaction.subProcedureType === FRMI_TYPE_EXTENSION.value && <FRMIOverview transaction={transaction} isUserActive />}
          {transaction.subProcedureType !== FRMI_TYPE_EXTENSION.value && <FRMIPostOperationOverview transaction={transaction} isUserActive />}
        </>}
      {transaction.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value &&
        <OfficialDocumentOverview
          transaction={transaction}
          isUserActive isRequestView
          setPayment={setPayment}
          setIsPaymentLoading={setIsPaymentLoading}
          onDocumentDeleted={onDocumentDeleted}
          onDocumentChanged={onDocumentChanged}
          documents={documents}
        />}

      {transaction.procedureType === PROCEDURE_REVOCATION_STATEMENT.value &&
        <RevocationStatementOverview transaction={transaction} />}

      {
        transaction.procedureType === PROCEDURE_RENEWAL.value &&
          <RenewalOverview transaction={transaction} isUserActive />
      }

      {/* Montant */}
      {payment &&
        <div className='col-12 mb-3'>
          <CardBlock header={<FormattedMessage id='overview_amount_title' />} shadow>
            <TransactionAmountOverview payment={payment} isPaymentLoading={isPaymentLoading} />
          </CardBlock>
        </div>}

      <ConsentRecap transaction={transaction} fieldStatus={fieldStatus} />

      {fieldStatus?.otherError && (
        <div className='col-12'>
          <ErrorField message={fieldStatus.otherError} className='fade alert alert-danger show position-relative mt-4' />
        </div>)}
    </div>
  )
}

export default Recap
