import { Brand, EventType, FieldStatus, HelpBlock, SwitchField, TextArea, Transaction } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from '../../../../store/transaction/transactionActions'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'

interface ColorsClaimedProps {
  transaction: Transaction,
  fieldStatus: FieldStatus
}

const ColorsClaimed: FC<ColorsClaimedProps> = ({ transaction, fieldStatus }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [brand, setBrand] = useState<Brand|undefined>(transaction.frmiDetails?.brand)
  const [isColorDistinctive, setIsColorDistinctive] = useState<boolean>(!!transaction.frmiDetails?.brand?.colors?.length)

  useEffect(() => {
    setBrand(transaction.frmiDetails?.brand)
  }, [transaction.frmiDetails?.brand])

  useEffect(() => {
    dispatch(storeTransactionUpdateFRMI({ ...transaction.frmiDetails, brand: { ...brand, isColorDistinctive: isColorDistinctive } }))
  }, [])

  const onBrandColorsChange = (event: EventType): void => {
    const { name, value } = event.target
    onBrandChange({ ...brand, [name]: value })
  }

  const onIsColorDistinctiveChange = (): void => {
    const updatedIsColorDistinctive = !isColorDistinctive
    setIsColorDistinctive(updatedIsColorDistinctive)
    onBrandChange({ ...brand, isColorDistinctive: updatedIsColorDistinctive, colors: updatedIsColorDistinctive ? brand?.colors : undefined, colorsDescription: updatedIsColorDistinctive ? brand?.colorsDescription : undefined })
  }

  const onBrandChange = (updatedBrand: Brand): void => {
    setBrand(updatedBrand)
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      brand: updatedBrand
    }))
  }

  return (
    <div className='colorsClaimed'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='select_frmi_colors_claimed_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_colors_claimed_description' /></span>
        </header>
        <InternalReferenceField
          className='col-3'
          transaction={transaction}
        />
      </div>
      <div className='row mt-5 col-md-12'>
        {
            brand?.hasColors &&
              <div className='col-md-12 col-lg-12'>
                <SwitchField
                  inputId='isColorDistinctive'
                  label={<FormattedMessage id='colors_claimed_is_color_distinctive_label' />}
                  value={isColorDistinctive}
                  onChange={onIsColorDistinctiveChange}
                />
                {
                  isColorDistinctive &&
                    <div className='mt-4'>
                      <TextArea
                        inputId='colors'
                        required
                        label={<FormattedMessage id='colors_claimed_colors_description_label' />}
                        value={brand?.colors}
                        onChange={onBrandColorsChange}
                        fieldStatus={fieldStatus}
                      />
                      <div className='mt-4'>
                        <TextArea
                          inputId='colorsDescription'
                          label={<FormattedMessage id='colors_claimed_description_label' />}
                          value={brand?.colorsDescription}
                          onChange={onBrandColorsChange}
                          fieldStatus={fieldStatus}
                          infoHelp={intl.formatMessage({ id: 'colors_claimed_description_help_hover' })}
                        />
                      </div>
                    </div>
                }
              </div>
        }
        {
          !brand?.hasColors &&
            <div className='mb-3 d-flex flex-column align-items-start col-10'><HelpBlock className='mt-3 align-self-start w-100'><FormattedMessage id='colors_claimed_no_color' /></HelpBlock></div>
        }
      </div>
    </div>
  )
}

export default ColorsClaimed
