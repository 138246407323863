import React, { FC } from 'react'
import { CommonInscriptionService, DownloadLink, HelpBlock, SubmitButton, Title } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import OverviewTitles from './OverviewTitles'
import Message from '../../../../constants/Message'
import TitleService from '../../../../services/inscription/TitleService'

interface TitleListProps {
  titles: Title[],
  editTitle: (index: number) => void,
  deleteTitle: (index: number) => void,
  addTitle: () => void
  setShowModalCsv?: (show: boolean) => void,
  showImportTitleFile?: boolean
  showDownloadExampleFile?: boolean,
  showDownloadTitleList?: boolean,
  procedureType?: string,
  isMonoTitle?: boolean,
  subProcedureType?: string
}

const TitleList: FC<TitleListProps> = (
  {
    titles,
    deleteTitle,
    editTitle,
    addTitle,
    setShowModalCsv,
    showImportTitleFile = false,
    showDownloadExampleFile = false,
    showDownloadTitleList = false,
    procedureType,
    isMonoTitle = false,
    subProcedureType
  }) =>
  (
    <>
      {titles && CommonInscriptionService.hasDuplicatedTitlesByNumNat(titles) &&
        <HelpBlock className='mb-3'>
          <FormattedMessage id='inscription_titles_identic_numnat_message' />
        </HelpBlock>}
      <div className='row mb-4 justify-content-between align-items-center px-3'>
        {!(isMonoTitle && titles.length) &&
          <SubmitButton
            className='btn-select btn-outline-primary btn-add mr-2'
            onClick={() => addTitle()}
          >
            <FormattedMessage id='inscription_add_title' />
            <FontAwesomeIcon className='ml-2' icon={faPlus} />
          </SubmitButton>}
        {(showImportTitleFile || showDownloadExampleFile) && (
          <div className='d-flex mt-3 mt-md-0 align-content-center justify-content-start justify-content-lg-end '>
            {showImportTitleFile &&
              <SubmitButton
                className='btn-link-primary mr-3'
                onClick={() => setShowModalCsv && setShowModalCsv(true)}
              >
                <FormattedMessage id='inscription_import_titles' />
              </SubmitButton>}
            {showDownloadExampleFile &&
              <DownloadLink
                label={<FormattedMessage id='product_and_services_help_link' />}
                name={Message.inscription_titles_example_file}
                onClick={() => TitleService.getExampleFile()}
              />}
          </div>
        )}
      </div>
      {titles && titles.length > 0 &&
        <OverviewTitles
          titles={titles}
          deleteTitle={deleteTitle}
          editTitle={editTitle}
          className='mt-5'
          procedureType={procedureType}
          subProcedureType={subProcedureType}
          showDownloadTitleList={showDownloadTitleList}
          isMonoTitle={isMonoTitle}
        />}
    </>
  )
export default TitleList
