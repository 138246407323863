
import {
  CardBlock,
  Contributor,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_OPPONENTS,
  CONTRIBUTOR_OTHER_APPLICANTS,
  SubmitButton,
  Transaction,
  TransactionService as CommonTransactionService
} from '@inpi-marques/components'
import { PAYMENT_METHODS } from 'constants/PaymentConstants'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaymentParameter } from 'services/payment/PaymentService'
import InpiAccountForm from './InpiAccountForm'
import PaymentBlueCardForm from './PaymentBlueCardForm'
import TransactionService from '../../services/transaction/TransactionService'

interface PaymentChoicesProps {
    amount: Number
    transaction: Transaction
    onPaymentSubmit: (paymentParameter: PaymentParameter) => Promise<void>
}

const PaymentChoices: FC<PaymentChoicesProps> = ({ transaction, onPaymentSubmit }) => {
  const manageableMemoryContibutorFields: string[] = [CONTRIBUTOR_DEPOSITORS.value, CONTRIBUTOR_OPPONENTS.value]
  // Pour une inscription on ajoute les intervenants parties à l'actes
  if (CommonTransactionService.isInscription(transaction)) {
    manageableMemoryContibutorFields.push(CONTRIBUTOR_OTHER_APPLICANTS.value)
  }
  const contributors: Contributor[] = TransactionService.findContributors(transaction, manageableMemoryContibutorFields)

  const [isPaying, setIsPaying] = useState<boolean>(false)

  const handlePaymentSubmit = async (paymentParameter: PaymentParameter) => {
    setIsPaying(true)
    return onPaymentSubmit(paymentParameter).finally(() => setIsPaying(false))
  }

  return (
    <div className='payment-choices row'>
      <div className='col-12 col-md-5'>
        <PaymentBlueCardForm disabled={isPaying} transaction={transaction} onPaymentSubmit={handlePaymentSubmit} />
      </div>
      <div className='col-12 col-md-4'>
        <InpiAccountForm disabled={isPaying} onPaymentSubmit={handlePaymentSubmit} />
      </div>
      <div className='col-12 col-md-3'>
        <CardBlock
          bodyClassName='py-3'
          header={<FormattedMessage id='payment_manageable_memory' />}
          shadow
        >
          <div className='row justify-content-center h-100'>
            <div className='col-12'>
              <div className='alert alert-warning'>
                <FormattedMessage id='payment_manageable_memory_info' />
              </div>
            </div>
            <div className='d-flex align-self-end justify-content-center'>
              <SubmitButton
                className={`btn-block ${!TransactionService.canContributorAccessToManageableMemory(contributors) ? 'btn-outline-gris' : 'btn-primary'} w-25`}
                onClick={() => handlePaymentSubmit({ method: PAYMENT_METHODS.MANAGEABLE_MEMORY })}
                disabled={!TransactionService.canContributorAccessToManageableMemory(contributors) || isPaying}
              >
                <FormattedMessage id='payment_access_button' />
              </SubmitButton>
            </div>
          </div>
        </CardBlock>
      </div>
    </div>
  )
}

export default PaymentChoices
