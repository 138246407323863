import React, { FC } from 'react'
import {
  DateUtils,
  DOCUMENT_FORMATS,
  downloadFile,
  FileBrowserField,
  FormatUtils,
  NOTIF_TYPE_NO_RESPONSE_EXPECTED,
  Notification, NOTIFICATION_TYPE_INFORMATION_MAIL,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import PriceService from '../../../services/content/PriceService'
import DocumentService from '../../../services/document/DocumentService'
import NotificationService from '../../../services/transaction/NotificationService'

interface OverviewNotificationsAnswerProps {
  transaction: Transaction,
  notification: Notification,
  index: number,
  setReload?: ()=>void,
  answerNotification?: boolean,
  setNotificationToAnswer: (notification: Notification|null) => void
}

const OverviewNotificationsAnswer: FC<OverviewNotificationsAnswerProps> = ({
  transaction,
  notification,
  index
}) => {
  const RESPONSE_TYPE_AGREEMENT_NOTIFICATION = 'AGREEMENT_NOTIFICATION'

  const handleDownload = (document: TransactionDocument) => {
    return DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => {
      downloadFile(data, document.name)
    })
  }

  return (
    <div>
      {
        notification.paid &&
        (
          <div className='border-bottom mb-3 pb-3'>
            <h3><FormattedMessage id='overview_transaction_notification_payment' /></h3>
            <p className='font-weight-bold'>
              <FormattedMessage id='overview_transaction_notification_amount_to_pay' /> : {FormatUtils.formatToStringPrice(PriceService.getTotalAmount(notification.prices))}
            </p>
            <p><FormattedMessage id='overview_transaction_notification_already_paid' />{notification.answeredAt && <><FormattedMessage id='at' />{DateUtils.formatDateFr(notification.answeredAt)}</>}</p>
          </div>
        )
      }
      {notification.type !== NOTIF_TYPE_NO_RESPONSE_EXPECTED && notification.type !== NOTIFICATION_TYPE_INFORMATION_MAIL.value &&
        <div className='row m-0'>
          <h3><FormattedMessage id='overview_transaction_notification_answer' /></h3>
        </div>}
      {
        notification.type === RESPONSE_TYPE_AGREEMENT_NOTIFICATION &&
        !transaction.documents?.filter((doc) => notification.supportingDocuments?.includes(doc.internalName))?.length &&
        !(transaction.deposit?.productsAndServicesVersions?.find(v => v.notificationId === notification.id)) && notification.answered &&
          <div className='reception-confirm mt-3'>
            <FormattedMessage id='overview_transaction_notification_agreement_title' />
            {notification.answeredAt && <><FormattedMessage id='at' />{DateUtils.formatDateFr(notification.answeredAt)}</>}
          </div>
      }
      {
        NotificationService.getNotificationReceiptDate(notification) &&
          <div className='reception-confirm mt-3'><FormattedMessage id='overview_transaction_notification_answer_receipt' />{NotificationService.getNotificationReceiptDate(notification)}</div>
      }
      {transaction.documents?.filter((doc) => notification.supportingDocuments?.includes(doc.internalName))?.length > 0 &&
        <FileBrowserField
          inputId={`documents-${index}`}
          label={<FormattedMessage id='overview_transaction_notification_supporting_document_label' />}
          buttonLabel={
            <div className='border'>
              <div className='text-center my-1 mx-2'>
                <FormattedMessage id='button_find_file' />
              </div>
            </div>
          }
          acceptApplication={DOCUMENT_FORMATS.PDF}
          value={transaction.documents?.filter((doc) => notification.supportingDocuments?.includes(doc.internalName))}
          handleDownload={handleDownload}
          readonly
        />}
      {
        (transaction.deposit?.productsAndServicesVersions?.find(v => v.notificationId === notification.id)) &&
          <FormattedMessage id='overview_transaction_notification_regularisation_done' />
      }
    </div>
  )
}

export default OverviewNotificationsAnswer
