import http from '../../network/http-common'
import { toast } from 'react-toastify'
import { createIntl, IntlShape } from 'react-intl'
import Message from '../../constants/Message'
import { ContributorInfos, SirenDenomination } from '@inpi-marques/components'

/**
 * Classe permettant de faire des opérations sur l'api OpenDataRNCS
 */
class OpenDataRNCSService {
  intl: IntlShape

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  /**
   * Permet de récupérer la liste des siren correspondant à la dénomination fournie
   * @returns Promise<SirenDenomination[]|null>
   */
  findListSirenByName = async (companyName: string): Promise<SirenDenomination[]|null> => {
    try {
      return await http.get('/api/opendata/sirens', {
        params: {
          companyName
        }
      })
    } catch (error) {
      error.message && toast.error(this.intl.formatMessage({ id: 'error_get_siren_from_denomination' }))
      return Promise.resolve(null)
    }
  }

  /**
   * Permet de récupérer les infos d'un intervenant PM à partir de son siren
   * @returns Promise<ContributorInfos|null>
   */
  getContributorInfos = async (siren: string): Promise<ContributorInfos|null> => {
    try {
      return await http.get(`/api/opendata/contributors/${siren}`)
    } catch (error) {
      if (error.code) {
        if (error.code === '500') {
          toast.error(this.intl.formatMessage({ id: 'intervenant_error_api_error' }))
        } if (error.code === '404') {
          toast.error(this.intl.formatMessage({ id: 'error_siren_not_found' }).replace('X', siren))
        } else {
          toast.error(this.intl.formatMessage({ id: 'error_get_siren_from_denomination' }))
        }
      }
      return Promise.resolve(null)
    }
  }
}

export default new OpenDataRNCSService()
