import React, { FC, useEffect, useState } from 'react'
import {
  EventType,
  FieldStatus,
  FRMI_EFFECT_DATE_TYPE_MODIFICATION_INSCRIPTION,
  FRMI_EFFECT_DATE_TYPES,
  MultipleRadioField,
  SelectOption,
  TextArea,
  Transaction
} from '@inpi-marques/components'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'

interface EffectDateProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

const EffectDate: FC<EffectDateProps> = ({ transaction, fieldStatus }) => {
  const dispatch = useDispatch()

  const [effectDateInformations, setEffectDateInformations] = useState<string>(transaction.frmiDetails?.effectDateInformations ?? '')

  useEffect(() => {
    setEffectDateInformations(transaction.frmiDetails?.effectDateInformations ?? '')
  }, [transaction.frmiDetails?.effectDateInformations])

  /**
   * Au changement de type de date d'effet
   * @param effectDateType
   */
  const onTypeChanged = (effectDateType: string): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      effectDateType,
      effectDateInformations: effectDateType !== FRMI_EFFECT_DATE_TYPE_MODIFICATION_INSCRIPTION.value ? undefined : transaction.frmiDetails?.effectDateInformations
    }))
  }

  /**
   * On enregistre l'information dans redux au blur
   */
  const onInformationsBlur = (): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      effectDateInformations
    }))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_designation_application_date_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_designation_application_date_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <MultipleRadioField
        className='col-12'
        inputId='procedures'
        onChange={(event: EventType) => onTypeChanged(event.target.value)}
        value={transaction.frmiDetails?.effectDateType}
        radio={FRMI_EFFECT_DATE_TYPES.map((effectDateType: SelectOption) => ({ label: effectDateType.label, value: effectDateType.value as string }))}
      />
      {transaction.frmiDetails?.effectDateType === FRMI_EFFECT_DATE_TYPE_MODIFICATION_INSCRIPTION.value &&
        <div className='mt-2'>
          <TextArea
            inputId='effectDateInformations'
            required
            label={<FormattedMessage id='frmi_effect_date_informations_label' />}
            value={effectDateInformations}
            onChange={(event: EventType) => setEffectDateInformations(event.target.value)}
            onBlur={onInformationsBlur}
            fieldStatus={fieldStatus}
            classNameFormGroup='col-5 ml-0 pl-0'
          />
        </div>}
    </>
  )
}

export default EffectDate
