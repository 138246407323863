import {
  BRAND_ORIGIN_INTERNATIONAL,
  COPY_OF_INSCRIPTION,
  COPY_OF_NULLITY,
  COPY_OF_OPPOSITION,
  COPY_OF_RENEWAL,
  COPY_OF_REVOCATION,
  DATE_ISO,
  DateUtils,
  FieldStatus,
  NumNatTransactionTitleVerifier,
  OfficialDocumentType,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION,
  Transaction,
  TransactionInfos,
  TransactionSearchParameters,
  TransactionSearchResult
} from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import TransactionService from '../../../../../services/transaction/TransactionService'
import TransactionFromTitleForm from './TransactionFromTitleForm'
import TransactionFromTitleRegisteredOverview from './TransactionFromTitleRegisteredOverview'

interface TransactionsFromTitleProps {
    // transaction récupérée à partir d'un titre à modifier
    transactionFromTitle?: TransactionInfos,
    // fonction pour modifier la transaction
    setTransactionFromTitle: (newTransactionFromTitle?: TransactionInfos) => void,
    // informations concernant les erreurs sur le formulaire
    fieldStatus?: FieldStatus,
    isEditingMode?: boolean,
    className?: string,
    onlyVerifier?: boolean,
    origin?: string[],
    transaction: Transaction,
    officialDocumentType?: OfficialDocumentType
}

/**
 * Composant permettant de rechercher et d'ajouter une transaction à partir d'un titre
 */
const TransactionFromTitle: FC<TransactionsFromTitleProps> = ({
  transactionFromTitle,
  setTransactionFromTitle,
  fieldStatus,
  isEditingMode = false,
  className,
  onlyVerifier = false,
  origin = ['FR', 'WO'],
  transaction,
  officialDocumentType
}) => {
  const dispatch = useDispatch()
  const [nationalNumber, setNationalNumber] = useState<string>(transactionFromTitle?.titleNumNat ?? '')
  const [transactionInfos, setTransactionInfos] = useState<Transaction|undefined>()
  const [notExist, setNotExist] = useState<boolean>(false)

  /**
   * Récupère la procédure associée au dossier à copier
   */
  const getProcedureFromOfficialDocumentType = (): string[]|undefined => {
    switch (officialDocumentType?.code) {
      case COPY_OF_INSCRIPTION:
        return [PROCEDURE_INSCRIPTION.value]
      case COPY_OF_NULLITY:
        return [PROCEDURE_NULLITY.value]
      case COPY_OF_REVOCATION:
        return [PROCEDURE_REVOCATION.value]
      case COPY_OF_OPPOSITION:
        return [PROCEDURE_OPPOSITION.value]
      case COPY_OF_RENEWAL:
        return [PROCEDURE_RENEWAL.value]
      default:
        return undefined
    }
  }

  /**
   * Récupère les informations de la transaction dans notre base
   */
  const verifyNumNat = async (): Promise<Transaction[]> => {
    // On reset uniquement les erreurs en rapport liées au composant
    dispatch(storeTransactionFieldStatusUpdate({
      ...fieldStatus,
      transactionFromTitleNumNat: undefined,
      transactionFromTitleArrivalDate: undefined
    }))

    if (nationalNumber) {
      const params: TransactionSearchParameters = {
        procedures: getProcedureFromOfficialDocumentType(),
        titlesNumNat: [nationalNumber],
        sortBy: 'createdAt',
        order: 'ASC'
      }
      const response: TransactionSearchResult|string = await TransactionService.searchTransaction(params, true)
      if (response && typeof response !== 'string' && response?.transactions && response?.transactions.length) {
        return response.transactions
      } else {
        setNotExist(true)
        setTransactionInfos(undefined)
        setTransactionFromTitle({ titleNumNat: nationalNumber, numNat: undefined, arrivalDate: undefined })
      }
    }
  }

  /**
   * Génère une transaction infos à partir d'une transaction
   * @param transactionSelected
   */
  const onTransactionSelected = (transactionSelected: Transaction) => {
    setTransactionInfos(transactionSelected)
    setNotExist(false)

    setTransactionFromTitle({ numNat: transactionSelected.numNat, arrivalDate: DateUtils.formateDateToFormat(transactionSelected.arrivalDate, DATE_ISO), titleNumNat: nationalNumber })
  }

  /**
   * Si on ferme la modale sans rien sélectionner,
   * on affiche les champs pour une saisie manuelle
   */
  const onCloseTransactionTitleModal = () => {
    setNotExist(true)
    setTransactionInfos(undefined)
    setTransactionFromTitle({ titleNumNat: nationalNumber, numNat: undefined, arrivalDate: undefined })
  }

  return (
    <div className='transaction-title-content'>
      <div className={`d-flex flex-wrap ${className}`}>
        <div className='national-number__wrapper col-12 p-0 mb-3'>
          <NumNatTransactionTitleVerifier
            inputId='numNat'
            className='col-4 p-0'
            labelClassname='mr-2'
            value={nationalNumber}
            fieldStatus={fieldStatus}
            dispatch={dispatch}
            setValue={setNationalNumber}
            verifyNumNat={verifyNumNat}
            label={<FormattedMessage id={origin.every((item: string) => BRAND_ORIGIN_INTERNATIONAL.value === item) ? 'international_number_label' : `deposit_type_${transaction.procedureType?.toLowerCase()}_national_number_label`} />}
            onTransactionSelected={verifyNumNat ? (transactionSelected: Transaction) => {
              onTransactionSelected(transactionSelected)
            } : undefined}
            nameFieldStatus='titleNumNat'
            setFieldStatus={storeTransactionFieldStatusUpdate}
            disabled={false}
            required
            handleClose={onCloseTransactionTitleModal}
          />
        </div>
      </div>

      {!onlyVerifier &&
        <>
          {(notExist || isEditingMode) && (
            <TransactionFromTitleForm
              fieldStatus={fieldStatus}
              transactionFromTitle={transactionFromTitle}
              setTransactionFromTitle={setTransactionFromTitle}
            />)}
          {
            transactionFromTitle && transactionInfos && transactionFromTitle?.numNat && !isEditingMode && (
              <TransactionFromTitleRegisteredOverview
                transactionFromTitle={transactionFromTitle}
              />)
          }
        </>}
    </div>
  )
}

export default TransactionFromTitle
