import {
  containsErrors,
  FieldStatus,
  messageIsFilled,
  ReplacementTitle,
  Transaction
} from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class ReplacementTitlesValidator {
    /**
     * Validation de l'étape
     * @param transaction
     * @returns
     */
    validateOnChangeStep = (transaction: Transaction): boolean => {
      const fieldStatus: FieldStatus = { replacementTitles: transaction.frmiDetails?.replacementTitles && transaction.frmiDetails?.replacementTitles.length ? '' : Message.frmi_replacement_missing }
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
      return !containsErrors(fieldStatus)
    }

    /**
     * Validation d'un titre à remplacer
     */
    validateReplacementTitle = (title: ReplacementTitle): FieldStatus => (
      {
        depositNumber: messageIsFilled(title.depositNumber),
        depositDate: messageIsFilled(title.depositDate),
        registeredDate: messageIsFilled(title.registeredDate),
        registeredNumber: messageIsFilled(title.registeredNumber)
      }
    )
}

export default new ReplacementTitlesValidator()
