import {
  FieldStatus,
  Transaction,
  CheckboxField,
  MultipleRadioField,
  CONSENTEMENT_OPTIONS,
  PROCEDURE_OFFICIAL_DOCUMENT
} from '@inpi-marques/components'
import React, { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { storeTransactionUpdate } from '../../store/transaction/transactionActions'
import { useDispatch } from 'react-redux'
import TransactionService from 'services/transaction/TransactionService'
/* global localStorage */

interface ConsentRecapProps {
  transaction: Transaction,
  fieldStatus: FieldStatus
}

const ConsentRecap: FC<ConsentRecapProps> = ({ transaction, fieldStatus }) => {
  const dispatch = useDispatch()
  const askConsent = localStorage.getItem('ELECTRONIC_NOTIF') === 'true' && transaction?.procedureType !== PROCEDURE_OFFICIAL_DOCUMENT.value

  /**
   * Enregistrement des modifications du bouton consentNotificationByEmail
   */
  const handleEmailNotifications = async (value: boolean) => {
    await TransactionService.updateTransaction(transaction.id, { recipient: { ...transaction.recipient, consentNotificationByEmail: value } })
  }

  const handleOverviewConsented = () => {
    dispatch(storeTransactionUpdate({ ...transaction, overviewConsented: !transaction?.overviewConsented }))
  }

  useEffect(() => {
    return () => {
      dispatch(storeTransactionUpdate({ ...transaction, overviewConsented: false }))
    }
  }, [])

  return (
    <div className='d-flex align-items-start flex-column col-12'>
      <div className='p-2 my-3'>
        <CheckboxField
          checked={transaction?.overviewConsented}
          onChange={() => handleOverviewConsented()}
          inputId='overviewConsented'
          label={<FormattedMessage id='overview_consent_form_checkbox_label' />}
          fieldStatus={fieldStatus}
          className='d-table mb-0'
        />
      </div>
      {askConsent && transaction?.recipient?.type &&
        <div className='bg-primary card shadow w-100 align-items-center my-3 d-flex flex-row p-2'>
          <span className='text-white pl-1 pr-4'><FormattedMessage id='field_email_consent_recap' /></span>
          <MultipleRadioField
            classNameRadioGroup='d-flex text-white'
            inputId='consentNotificationByEmail'
            value={transaction?.recipient?.consentNotificationByEmail}
            radio={CONSENTEMENT_OPTIONS}
            onChange={(event) => handleEmailNotifications(event.target.value)}
          />
        </div>}
    </div>
  )
}

export default ConsentRecap
