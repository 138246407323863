import React, { FC } from 'react'
import {
  DecisionType,
  FRMI, FRMI_DECISION_TYPES, SelectOption
} from '@inpi-marques/components'

interface DecisionsOverviewProps {
    frmi: FRMI
}

const DecisionsOverview: FC<DecisionsOverviewProps> = ({ frmi }) => {
  return (
    <ul>
      {frmi.decisionTypes?.map((decisionType: DecisionType) =>
        <li>
          <span>{decisionType.description ? decisionType.description : FRMI_DECISION_TYPES.find((type: SelectOption) => type.value === decisionType.code)?.label}</span>
        </li>
      )}
    </ul>
  )
}

export default DecisionsOverview
