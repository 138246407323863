import { messageIsFilled, FieldStatus } from '@inpi-marques/components'
import { Country } from '../../../interfaces/frmi/FRMI'

class CountryValidator {
    /**
     * Valide un pays
     * @param country
     * @returns
     */
    validateCountry = (country?: Country): FieldStatus => {
      return {
        label: messageIsFilled(country?.label),
        code: messageIsFilled(country?.code)
      }
    }
}

export default new CountryValidator()
