import {
  BASIC_DATE,
  BRAND_ORIGINS_SELECT_OPTIONS,
  BRAND_TYPES_SELECT_OPTIONS,
  DateField,
  DateUtils,
  EventType,
  FieldStatus,
  FRMI_TYPE_EXTENSION,
  HelpBlock,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_REVOCATION_STATEMENT,
  SubmitButton,
  Title,
  Transaction,
  DATE_ISO, TransactionService
} from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { storeTransactionFieldStatusUpdate } from '../../../../store/fieldStatus/fieldStatusActions'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import TitleFilePreview from './TitleFilePreview'

interface TitleRegisteredOverviewProps {
  title?: Title,
  procedureType?: string,
  transaction?: Transaction,
  fieldStatus?: FieldStatus,
  // fonction pour modifier le titre
  setTitle: (newTitle: Title) => void,
  recordId?: string
}

const TitleRegisteredOverview: FC<TitleRegisteredOverviewProps> = ({
  title,
  procedureType,
  transaction,
  fieldStatus,
  setTitle,
  recordId
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [editRegisteredDate, setEditRegisteredDate] = useState<boolean>(false)
  const [editExpirationDate, setEditExpirationDate] = useState<boolean>(false)

  const onTitleRegisteredDateChanged = (event: EventType) => {
    const { name, value } = event.target
    setTitle({ ...title, [name]: DateUtils.formatToBeginOfDay(value) })
    setEditRegisteredDate(false)
  }

  const onTitleExpirationDateChanged = (event: EventType) => {
    const { name, value } = event.target
    setTitle({ ...title, [name]: DateUtils.formatToBeginOfDay(value) })
    setEditExpirationDate(false)
  }

  return (
    <>
      <div className='card'>
        <div className='col-12 p-0'>
          {title && <TitleFilePreview transaction={transaction} title={title} recordId={recordId} />}
          <div className='d-flex'>
            {(procedureType === PROCEDURE_INSCRIPTION.value || procedureType === PROCEDURE_REVOCATION_STATEMENT.value) &&
              <div className='mr-5 d-flex flex-column'>
                <span className='title-info'><FormattedMessage id='deposit_type_inscription_origin' /></span>
                {title?.origin && <span><FormattedMessage id={BRAND_ORIGINS_SELECT_OPTIONS.find((origin) => origin.value === title?.origin)?.label} /></span>}
              </div>}
            {
                title?.text &&
                  <div className='mr-5 d-flex flex-column'>
                    <span className='title-info'><FormattedMessage id='inscription_form_title_label' /></span>
                    <span>{title?.text}</span>
                  </div>
            }
            <div className='mr-5 d-flex flex-column'>
              <span className='title-info'><FormattedMessage id='deposit_type_division_registered_type' /></span>
              {title?.type && <span><FormattedMessage id={BRAND_TYPES_SELECT_OPTIONS.find((brand) => brand.value === title?.type)?.label} /></span>}
            </div>
            <div className={`${procedureType === PROCEDURE_FRMI.value ? 'mr-5' : ''} d-flex flex-column`}>
              <span className='title-info'><FormattedMessage id={`deposit_type_${procedureType?.toLowerCase()}_deposit_date`} /> </span>
              <span>{DateUtils.formateDateToFormat(title?.depositDate, BASIC_DATE) || ''}</span>
            </div>
            {
              procedureType === PROCEDURE_FRMI.value && transaction?.subProcedureType === FRMI_TYPE_EXTENSION.value && title?.registeredDate && !editRegisteredDate &&
                <>
                  <div className='d-flex flex-column'>
                    <span className='title-info'><FormattedMessage id='deposit_type_frmi_registered_date' /> </span>
                    <span>{DateUtils.formateDateToFormat(title?.registeredDate, BASIC_DATE) || ''}</span>
                  </div>
                  <SubmitButton
                    className='btn btn-link text-primary ml-2'
                    onClick={() => setEditRegisteredDate(true)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </SubmitButton>
                </>
            }
            {
              procedureType === PROCEDURE_FRMI.value && transaction?.subProcedureType !== FRMI_TYPE_EXTENSION.value && title?.expirationDate && !editExpirationDate &&
                <>
                  <div className='d-flex flex-column'>
                    <span className='title-info'><FormattedMessage id='deposit_type_frmi_expiration_date' /> </span>
                    <span>{DateUtils.formateDateToFormat(title?.expirationDate, BASIC_DATE) || ''}</span>
                  </div>
                  <SubmitButton
                    className='btn btn-link text-primary ml-2'
                    onClick={() => setEditExpirationDate(true)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </SubmitButton>
                </>
            }
          </div>
          {
            procedureType === PROCEDURE_FRMI.value && transaction?.subProcedureType === FRMI_TYPE_EXTENSION.value && (!title?.registeredDate || editRegisteredDate) &&
              <div className='col-12 col-lg-4 mt-4 p-0'>
                <DateField
                  inputId='registeredDate'
                  value={DateUtils.formateDateToFormat(title?.registeredDate, DATE_ISO) ?? ''}
                  label={<FormattedMessage id='deposit_type_frmi_registered_date' />}
                  placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
                  onChange={onTitleRegisteredDateChanged}
                  fieldStatus={fieldStatus}
                  nameFieldStatus='titleRegisteredDate'
                  classNameLabel='col-12 p-0'
                  dispatch={dispatch}
                  resetError={storeTransactionFieldStatusUpdate}
                />
              </div>
          }
          {
            procedureType === PROCEDURE_FRMI.value && transaction?.subProcedureType !== FRMI_TYPE_EXTENSION.value && (!title?.expirationDate || editExpirationDate) &&
              <div className='col-12 col-lg-4 mt-4 p-0'>
                <DateField
                  inputId='expirationDate'
                  value={DateUtils.formateDateToFormat(title?.expirationDate, DATE_ISO) ?? ''}
                  label={<FormattedMessage id='deposit_type_frmi_expiration_date' />}
                  placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
                  onChange={onTitleExpirationDateChanged}
                  fieldStatus={fieldStatus}
                  nameFieldStatus='titleExpirationDate'
                  classNameLabel='col-12 p-0'
                  dispatch={dispatch}
                  resetError={storeTransactionFieldStatusUpdate}
                />
              </div>
          }
        </div>
      </div>
      <div><HelpBlock className='mb-2 mt-5 col-12 p-0'><FormattedMessage id={`deposit_type_${transaction?.procedureType?.toLowerCase()}${transaction && TransactionService.isFrmiOP(transaction) ? '_op' : ''}_registered_description`} /></HelpBlock></div>
    </>
  )
}

export default TitleRegisteredOverview
