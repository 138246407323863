import React, { FC, useEffect, useState } from 'react'

import {
  CardBlock, Comment, Country, FO_USER_COMMENT_TYPE, FRMI,
  OMPIFees,
  PROCEDURE_FRMI, Seniority,
  Transaction
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import DocumentsOverview from 'component/transactions/overview/DocumentsOverview'
import IntervenantsOverview from 'component/transactions/overview/IntervenantsOpposantOverview'
import OverviewTitles from 'component/inscription/form/titles/OverviewTitles'
import { FRMIOverviewBloc, FRMIStep, FRMIStepGuard, FRMITypeForm } from '../../../interfaces/frmi/FrmiInterfaces'
import { FRMI_TYPES_FORMS } from '../../../constants/frmi/FRMIConstants'
import FRMIService from '../../../services/frmi/FRMIService'

interface FRMIPostOperationOverviewProps {
    transaction: Transaction,
    isUserActive?: boolean,
    showAddDocumentsButton?: boolean,
    setReload?: () => void
}

const FRMIPostOperationOverview: FC<FRMIPostOperationOverviewProps> = ({ transaction, isUserActive, showAddDocumentsButton, setReload }) => {
  const frmi: FRMI | undefined = transaction.frmiDetails
  const [ompiFees, setOmpiFees] = useState<OMPIFees>()
  const [isCalculatorLoading, setIsCalculatorLoading] = useState<boolean>(true)

  /**
   * Check si l'étape peut être affichée, en fonction des conditions dans le guard
   */
  const canBeDisplayed = (step: FRMIStep): boolean => {
    if (!step.guard || !transaction.frmiDetails) {
      return true
    }

    const guard: FRMIStepGuard = step.guard
    if (guard.field === 'claimedSeniorities') {
      return transaction.frmiDetails?.countries?.some((country: Country) => country.code === guard.value)
    }

    return true
  }

  const getFrmiOverviewBlocs = (): FRMIOverviewBloc[] => {
    const frmiTypeForm: FRMITypeForm|undefined = FRMI_TYPES_FORMS.find((typeForm: FRMITypeForm) => typeForm.type === transaction.subProcedureType)

    if (!frmiTypeForm) {
      return []
    }

    return frmiTypeForm.steps.filter((step: FRMIStep) => step.overview && canBeDisplayed(step)).map((step: FRMIStep) => ({
      id: step.id,
      label: <FormattedMessage id={`frmi_${step.id}_title`} />,
      overview: step.overview
    }))
  }

  useEffect(() => {
    transaction.id && FRMIService.getOMPIFees(transaction.id).then((result) => {
      setOmpiFees(result)
      setIsCalculatorLoading(false)
    })
  }, [])

  const comment: Comment|undefined = transaction.comments?.find((comment: Comment) => comment.commentType === FO_USER_COMMENT_TYPE)
  const seniorities: Seniority[] | undefined = frmi && frmi.seniorities

  return (
    <div className='col-12 d-flex flex-wrap p-0'>
      {isUserActive &&
        <>
          {/* Titres */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_inscription_titles' />} shadow>
              <div className='mb-3'>
                <OverviewTitles titles={frmi?.titles} procedureType={PROCEDURE_FRMI.value} showDownloadTitleList subProcedureType={transaction?.subProcedureType} />
              </div>
            </CardBlock>
          </div>

          {/* Intervenants */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_frmi_intervenants_title' />} shadow>
              <IntervenantsOverview transaction={transaction} />
            </CardBlock>
          </div>

          {/* Mapping des autres Overviews */}
          {
            getFrmiOverviewBlocs().map((item: FRMIOverviewBloc) => (
              <>
                {item.overview &&
                  <div className='col-12 mb-3'>
                    <CardBlock header={item.label} shadow>
                      <item.overview
                        frmi={frmi}
                        transaction={transaction}
                        limitedProductsAndServices={frmi?.limitedProductsAndServicesVersions}
                        ompiFees={ompiFees}
                        isCalculatorLoading={isCalculatorLoading}
                        seniorities={seniorities}
                      />
                    </CardBlock>
                  </div>}
              </>
            ))
          }

          {/* Récapitulatif documents */}
          <div className='col-12 mb-3' ref={transaction.documents}>
            <CardBlock header={<FormattedMessage id='overview_file_title' />} shadow>
              <DocumentsOverview
                transaction={transaction}
                showAddDocumentsButton={showAddDocumentsButton}
                isUserActive={isUserActive}
                setReload={setReload}
              />
            </CardBlock>
          </div>

          {/* Commentaires */}
          {comment?.content &&
            <div className='col-12 mb-3'>
              <CardBlock
                header={<FormattedMessage id='overview_frmi_comment_title' />} shadow
              >
                <span>{comment?.content}</span>
              </CardBlock>
            </div>}
        </>}
    </div>
  )
}

export default FRMIPostOperationOverview
