import http from '../../network/http-common'
import store from '../../store/store'
import {
  storeCountriesUpdate,
  storeManageableQualitiesUpdate,
  storeProtectionExtensionsUpdate, storeRenewalDelaysUpdate
} from '../../store/content/contentActions'
import Message from '../../constants/Message'
import { createIntl, IntlShape } from 'react-intl'
import axios, { CancelTokenSource } from 'axios'
import {
  Country,
  isFilled,
  ManageableQuality,
  ProtectionExtension,
  EditorialBlock,
  SelfDiagnosic,
  PROCEDURE_ALL,
  NatureCode, Language, DocumentType, RenewalDelay
} from '@inpi-marques/components'
import { toast } from 'react-toastify'
import ConfigurationService from '../configuration/ConfigurationService'

/**
 * Classe permettant de récupérer les données administrables en bo
 */
class ContentService {
    intl: IntlShape
    source: CancelTokenSource

    constructor () {
      this.intl = createIntl({ locale: 'fr', messages: Message })
      this.source = axios.CancelToken.source()
    }

    /**
     * Récupération de la liste des pays possibles
     * @returns Promise<Country[]>
     */
    getCountries = async (): Promise<Country[]> => {
      let countries = store.getState().adminContent.countries
      if (!isFilled(countries)) {
        try {
          countries = await http.get('/api/countries', {
            cancelToken: this.source.token
          })
          countries = countries.map(country => ({ ...country, label: country.name }))
          store.dispatch(storeCountriesUpdate(countries))
        } catch (error) {
          countries = []
        }
      }
      return Promise.resolve(countries)
    }

    /**
     * Récupération d'un pays via son code pays'
     * @returns Promise<Country>
     */
    getCountry = async (code: string): Promise<Country | undefined> => {
      const countries = store.getState().adminContent.countries
      if (!isFilled(countries)) {
        try {
          return await http.get(`/api/countries/${code}`, { cancelToken: this.source.token })
        } catch (error) {
          toast.error(error.message)
          return Promise.reject(error)
        }
      }
      return countries.find((country: Country) => country.code === code)
    }

    /**
     * Récupération de la liste des qualités administrables
     * @return Promise<ManageableQuality[]>
     */
    getManageableQualities = async (procedure?: string, codes?: string[]): Promise<ManageableQuality[]> => {
      let qualities = store.getState().adminContent.qualities
      if (!isFilled(qualities) || (codes && !!qualities.find(quality => !codes.find(code => code === quality.code)))) {
        try {
          qualities = await http.get('/api/manageable-qualities', {
            cancelToken: this.source.token
          })
          store.dispatch(storeManageableQualitiesUpdate(qualities))
        } catch (error) {
          qualities = []
        }
      }
      if (procedure) {
        qualities = qualities.filter((item) => item.procedures.includes(procedure) || item.procedures.includes(PROCEDURE_ALL.value))
      }

      if (codes) {
        qualities = qualities.filter(quality => codes.find(code => code === quality.code))
      }
      return Promise.resolve(qualities)
    }

    /**
     * Récupération de la liste des questions en mode auto diagnostic
     * @return Promise<SelfDiagnosic[]>
     */
    getSelfDiagnosic = async (typeBrand: string): Promise<SelfDiagnosic[]> => {
      try {
        return await http.get(`/api/autodiagnostics?brandType=${typeBrand}`, { cancelToken: this.source.token })
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Récupération de la liste des extensions de protection
     * @returns Promise<ProtectionExtension[]>
     */
    getProtectionExtensions = async (): Promise<ProtectionExtension[]> => {
      let protectionExtensions = store.getState().adminContent.protectionExtensions
      if (!isFilled(protectionExtensions)) {
        try {
          protectionExtensions = await http.get('/api/protection-extensions?activated=true', {
            cancelToken: this.source.token
          })
          store.dispatch(storeProtectionExtensionsUpdate(protectionExtensions))
        } catch (error) {
          protectionExtensions = []
        }
      }
      return Promise.resolve(protectionExtensions)
    }

    /**
     * Récupération d'un bloc éditorial par son code
     * @returns Promise<EditorialBlock>
     */
    getEditorialBlockByCode = async (code: string): Promise<EditorialBlock> => {
      try {
        return await http.get(`/api/editorial-blocks/${code}`, { cancelToken: this.source.token })
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Récupération des codes natures
     * @param type
     * @returns
     */
    getNatureCodesByType = async (type?: string): Promise<NatureCode[]> => {
      try {
        return await http.get(`/api/nature-codes?type=${type}`, { cancelToken: this.source.token })
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Récupère la liste des langues
     */
    getLanguages = async (): Promise<Language[]> => {
      try {
        return await http.get('/api/languages', {
          cancelToken: this.source.token
        })
      } catch (error) {
        return Promise.resolve([])
      }
    }

    /**
     * Récupère les types de documents en fonction des paramètres renseignés
     *
     * @param procedure
     * @param params
     * @returns
     */
    getDocumentTypes = async (procedure?: string): Promise<DocumentType[]> => {
      try {
        return await http.get(`/api/document-types?procedure=${procedure}`, {
          cancelToken: this.source.token
        })
      } catch (error) {
        return Promise.reject(error)
      }
    }

    /**
     * Annule une requête en attente
     */
    cancelRequest = () => {
      this.source.cancel()
      this.source = axios.CancelToken.source()
    }

    /**
     * Récupération des variables administratives du délai de renouvellement
     * @returns Promise<RenewalDelay[]>
     */
    getRenewalDelays = async (): Promise<RenewalDelay[]> => {
      let delays = store.getState().adminContent.renewalDelays
      if (!isFilled(delays)) {
        try {
          delays = await ConfigurationService.getConfigurationsValues(['BEFORE_END_VALIDITY_DELAY', 'AFTER_END_VALIDITY_DELAY'])

          store.dispatch(storeRenewalDelaysUpdate(delays))
        } catch (error) {
          delays = []
        }
      }
      return Promise.resolve(delays)
    }
}

export default new ContentService()
