import {
  CardBlock,
  ModalComponent,
  Payment,
  Payment as IPayment,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_NULLITY,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_OPPOSITION,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION,
  PROCEDURE_REVOCATION_STATEMENT,
  Transaction,
  TransactionService
} from '@inpi-marques/components'
import TransactionAmountOverview from 'component/transactions/overview/TransactionAmount'
import { PAYMENT_METHODS, PAYMENT_OBJECTS } from 'constants/PaymentConstants'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import DepositService from 'services/deposit/DepositService'
import PaymentService, { PaymentParameter } from 'services/payment/PaymentService'
import PaymentChoices from './PaymentChoices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import PriceService from '../../services/content/PriceService'
import OppositionService from '../../services/opposition/OppositionService'
import NullityService from '../../services/nullity/NullityService'
import DivisionService from '../../services/division/DivisionService'
import InscriptionService from '../../services/inscription/InscriptionService'
import FRMIService from '../../services/frmi/FRMIService'
import OfficialDocumentService from '../../services/officialDocument/OfficialDocumentService'
import RevocationStatementService from 'services/revocation-statement/RevocationStatementService'
import PaymentFree from './PaymentFree'
import RenewalService from '../../services/renewal/RenewalService'

interface PaymentFormProps {
    transaction: Transaction,
    label: string,
    objectName: string,
    urlType: string,
    objectId?:string,
    title?:string|ReactNode,
    isTransformationTA?: boolean,
    isMultiTransaction?: boolean // S'il s'agit d'une transaction générant plusieurs transaction après le paiement (renouvellement ou relevé de déchéance)
}

const PaymentForm: FC<PaymentFormProps> = (
  {
    transaction,
    label,
    objectName,
    urlType,
    objectId,
    title,
    children,
    isTransformationTA,
    isMultiTransaction = false
  }) => {
  const [payment, setPayment] = useState<IPayment>()
  const [showModalPaymentExist, setShowModalPaymentExist] = useState<boolean>(false)
  const history = useHistory()

  const updatePaymentFromTransaction = () => {
    switch (transaction.procedureType) {
      case PROCEDURE_DEPOSIT.value:
        DepositService.getPaiement(transaction?.id).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      case PROCEDURE_OPPOSITION.value:
        OppositionService.getPaiement(transaction?.id).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      case PROCEDURE_REVOCATION.value:
      case PROCEDURE_NULLITY.value:
        NullityService.getPaiement(transaction?.id).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      case PROCEDURE_DIVISION.value:
        DivisionService.getPaiement(transaction?.id).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      case PROCEDURE_INSCRIPTION.value:
        InscriptionService.getPaiement(transaction?.id, isTransformationTA ? 1 : 0).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      case PROCEDURE_FRMI.value:
        FRMIService.getPaiement(transaction.id).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      case PROCEDURE_OFFICIAL_DOCUMENT.value:
        OfficialDocumentService.getPaiement(transaction.id, transaction.officialDocument?.isAdministration).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      case PROCEDURE_REVOCATION_STATEMENT.value:
        RevocationStatementService.getPaiement(transaction).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      case PROCEDURE_RENEWAL.value:
        RenewalService.getPaiement(transaction).then((payment: Payment) => {
          setPayment(payment)
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (transaction.id) {
      switch (objectName) {
        case PAYMENT_OBJECTS.TRANSACTION :
        case PAYMENT_OBJECTS.TRANSFORMATION_TN_TA :
          updatePaymentFromTransaction()
          break
        case PAYMENT_OBJECTS.NOTIFICATION:
          setPayment({
            amountByBo: 0,
            dueTotal: 0,
            lastUpdate: undefined,
            numnatVIR: '',
            orderNum: '',
            orderRef: '',
            status: '',
            type: '',
            lines: transaction.notifications?.find((notif) => notif.id === objectId)?.prices?.map((price) => ({
              ...price,
              amount: price.price
            })) || [],
            totalAmount: PriceService.getTotalAmount(transaction.notifications?.find((notif) => notif.id === objectId).prices)
          })
          break
      }
    }
  }, [objectId])

  const onPaymentSubmit = async (parameter: PaymentParameter): Promise<void> => {
    const transactionId: string|undefined = transaction.id
    if (transactionId) {
      parameter = { ...parameter, label, objectName }
      if (objectId) {
        parameter = {
          ...parameter,
          objectIds: [objectId]
        }
      }
      try {
        const createdPayment: string = await PaymentService.createPayment(transactionId, parameter)
        if (parameter.method === PAYMENT_METHODS.BLUE_CARD) {
          PaymentService.displayPayBox(createdPayment)
        } else {
          let url = `/${urlType}/${transactionId}/paiement/confirmation`
          if (isMultiTransaction) {
            url += '?isMulti'
          }
          history.push(url)
        }
      } catch (error) {
        if (error.errorCode === 'payment_already_exist') {
          setShowModalPaymentExist(true)
        } else {
          history.push(`/${urlType}/${transactionId}/paiement/erreur`)
        }
      }
    }
  }

  return (
    <>
      <div className='d-flex flex-wrap justify-content-center'>
        {
          payment
            ? (
              <div>
                <div className='col-12 mb-5'>
                  <h1>{title || <FormattedMessage id='payment_title' />}</h1>
                </div>
                <div className='col-12 mb-3'>
                  <CardBlock header={<FormattedMessage id='overview_amount_title' />} shadow>
                    <TransactionAmountOverview payment={payment} />
                  </CardBlock>
                </div>
                {payment.totalAmount > 0 &&
                  <div className='col-12 mb-3'>
                    <PaymentChoices onPaymentSubmit={onPaymentSubmit} amount={payment.totalAmount} transaction={transaction} />
                  </div>}
                {(payment.totalAmount === 0 || TransactionService.isRevocationStatement(transaction)) &&
                  <PaymentFree transaction={transaction} onPaymentSubmit={onPaymentSubmit} />}
                {children}
              </div>
            )
            : (
              <div className='d-flex justify-content-center mt-4'>
                <FontAwesomeIcon className='loader' icon={faSpinner} />
              </div>
            )
        }
      </div>
      <ModalComponent
        title={<FormattedMessage id='error_payment_create' />}
        show={showModalPaymentExist}
        customContent={() => <FormattedMessage id='error_payment_already_exist' />}
        handleClose={() => setShowModalPaymentExist(false)}
        hideFooter
      />
    </>
  )
}

export default PaymentForm
