import { CardBlock, References, Transaction } from '@inpi-marques/components'
import OverviewTitles from 'component/inscription/form/titles/OverviewTitles'
import IntervenantsOverview from 'component/transactions/overview/IntervenantsOpposantOverview'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import RevocationStatementRequestOverview from './RevocationStatementRequestOverview'
import DocumentsOverview from '../../transactions/overview/DocumentsOverview'

interface RevocationStatementOverview {
    transaction: Transaction,
    references?: References,
    showAddDocumentsButton?: boolean,
    isUserActive?: boolean,
    setReload?: () => void
}

/**
 * Récapitulatif d'un relevé de déchéance
 */
const RevocationStatementOverview: FC<RevocationStatementOverview> = ({
  transaction,
  references,
  showAddDocumentsButton,
  isUserActive,
  setReload
}) =>
  <div className='col-12 d-flex flex-wrap p-0'>
    <div className='col-12 mb-3'>
      <CardBlock header={<FormattedMessage id='overview_inscription_titles' />} shadow>
        <div className='mb-3'>
          <OverviewTitles titles={transaction.revocationStatement?.titles ?? []} procedureType={transaction.procedureType} showDownloadTitleList />
        </div>
      </CardBlock>
    </div>

    {/* Demande */}
    <div className='col-12 mb-3'>
      <CardBlock header={<FormattedMessage id='overview_revocation_statement_request_title' />} shadow>
        <RevocationStatementRequestOverview transaction={transaction} />
      </CardBlock>
    </div>

    {/* Intervenants */}
    <div className='col-12 mb-3'>
      <CardBlock header={<FormattedMessage id='overview_deposit_intervenants_title' />} shadow>
        <IntervenantsOverview transaction={transaction} />
      </CardBlock>
    </div>

    {/* Récapitulatif documents */}
    <div className='col-12 mb-3' ref={references?.documents}>
      <CardBlock header={<FormattedMessage id='overview_file_title' />} shadow>
        <DocumentsOverview
          transaction={transaction}
          showAddDocumentsButton={showAddDocumentsButton}
          isUserActive={isUserActive}
          setReload={setReload}
        />
      </CardBlock>
    </div>
  </div>

export default RevocationStatementOverview
