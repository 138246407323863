import { Address } from '../../interfaces/contributors/AddressInterfaces'
import { isCodePostalValid, isFilled, isTextLatin, messageIsFilled } from '../../utils/validationUtils'
import Message from '../../constants/Message'
import { FRANCE } from '../../constants/contributors/AddressConstants'
import { Contributor } from '../../interfaces/contributors/ContributorsInterfaces'
import { PROCEDURE_FRMI } from '../../constants/ProcedureConstants'
import { PERSONNE_MORALE } from '../../constants/contributors/PersonConstants'

class AddressFieldsValidator {
  validateCodePostal = (address: Address) => {
    const { zipCode = '', country = '' } = address

    if (!isFilled(zipCode) && country === FRANCE) {
      return Message.required_field
    }

    if (!isCodePostalValid(zipCode) && country === FRANCE) {
      return Message.error_code_postal_format
    }
    return ''
  }

  validateText = (text?: string) => {
    if (!isFilled(text?.trim())) {
      return Message.required_field
    }
    if (text && !isTextLatin(text)) {
      return Message.text_not_latin
    }
    return ''
  }

  validateCountry = (address: Address, procedureType?: string, contributor?: Contributor): string => {
    let fieldStatus = messageIsFilled(address.country)

    if (contributor && !fieldStatus && procedureType === PROCEDURE_FRMI.value && contributor?.address?.country !== 'FR' && contributor.type === PERSONNE_MORALE.value) {
      fieldStatus = ''
    }

    return fieldStatus
  }

  validateMandatory = (address: Address, procedureType?: string, contributor?: Contributor) => {
    let fieldStatus = {
      label: this.validateText(address.label),
      zipCode: this.validateCodePostal(address),
      city: this.validateText(address.city),
      country: this.validateCountry(address, procedureType, contributor),
      building: address.building && !isTextLatin(address.building) ? Message.text_not_latin : '',
      complement: address.complement && !isTextLatin(address.complement) ? Message.text_not_latin : ''
    }

    // Dans certains cas pour les FRMI on doit vérifier la présence d'une seconde adresse
    if ((procedureType === PROCEDURE_FRMI.value && (address?.country === 'UE' || (contributor?.nationality !== 'FR')) && address.country !== 'FR') || contributor?.otherAddress) {
      const otherAddress: Address|undefined = contributor?.otherAddress

      fieldStatus = {
        ...fieldStatus,
        otherAddress_label: messageIsFilled(otherAddress?.label),
        otherAddress_zipCode: !otherAddress ? '' : this.validateCodePostal(otherAddress),
        otherAddress_city: messageIsFilled(otherAddress?.city),
        otherAddress_country: !otherAddress ? Message.required_field : this.validateCountry(otherAddress, procedureType, contributor)
      }
    }

    return fieldStatus
  }
}

export default new AddressFieldsValidator()
