import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { ETSAccountNavInterface, ETSAccountRequest } from '../../interfaces/ETSAccountInterface'
import {
  CardBlock,
  CONSENTEMENT_OPTIONS,
  containsErrors,
  DOCUMENT_FORMATS,
  EventType,
  FieldStatus,
  FilAriane,
  FileBrowserField,
  FileButton,
  MultipleRadioField,
  PROCEDURE_DEPOSIT,
  PROCEDURE_MIFR,
  PROCEDURES_ETS_HOLDER,
  PROCEDURES_ETS_HOLDER_LOT_2,
  SelectField,
  SubmitButton,
  TextField
} from '@inpi-marques/components'
import ETSAccountRequestValidator from './validator/ETSAccountRequestValidator'
import ETSAccountService from '../../services/ets/ETSAccountService'
import { Link } from 'react-router-dom'
import Message from '../../constants/Message'

const INITIAL_REQUEST = {
  procedure: PROCEDURE_DEPOSIT.value,
  numNat: '',
  code: 'HOLDER'
}

export interface ETSAccountHolderProps extends WrappedComponentProps{
  ETSAccountRequestNavItem: ETSAccountNavInterface
}

const ETSAccountHolder : FC<ETSAccountHolderProps> = ({
  ETSAccountRequestNavItem, intl
}) => {
  const [redirect, setRedirect] = useState<string|null>(null)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})
  const [etsAccountRequest, setEtsAccountRequest] = useState<ETSAccountRequest>(INITIAL_REQUEST)
  const procedures = process.env.REACT_APP_LOT_2 === 'true' ? PROCEDURES_ETS_HOLDER_LOT_2 : PROCEDURES_ETS_HOLDER
  const procedureOptions = [...procedures].map(p => p.value === 'MIFR' ? { ...p, label: Message.procedure_type_mifr_ets } : p)

  /**
   * Validation du formulaire de rattachement
   */
  const handleSubmit = () => {
    const fieldStatus = ETSAccountRequestValidator.validateForm(etsAccountRequest, ETSAccountRequestNavItem)
    setFieldStatus(fieldStatus)
    if (!containsErrors(fieldStatus)) {
      let request = { ...etsAccountRequest }
      if (etsAccountRequest.procedure === PROCEDURE_MIFR.value) {
        request = { ...etsAccountRequest, numNat: `MIFR-${etsAccountRequest.numnat}` }
      }
      return ETSAccountService.postETSAccountRequestDeposit(request, ETSAccountRequestNavItem).then(() => {
        toast.success(intl.formatMessage({ id: 'ets_account_request_success_request' }))
        setRedirect('/')
      })
    }
  }

  /**
   * Enregistrement du fichier joint
   * @param event
   * @param field
   */
  const handleFile = (event: EventType, field: string) => {
    const file = event.target.value
    setEtsAccountRequest({
      ...etsAccountRequest,
      [field]: {
        name: file.name,
        file: file
      }
    })
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id={ETSAccountRequestNavItem.breadcrumb} /></span>
      </FilAriane>
      {redirect &&
        <Redirect to={{
          pathname: redirect
        }}
        />}
      <div className='d-flex mt-4 justify-content-center'>
        <CardBlock
          className='col-12'
          shadow
        >
          <div className='d-flex justify-content-center row main'>
            <header className='col-8'>
              <h1><FormattedMessage id={ETSAccountRequestNavItem.label} /></h1>
              <div>
                <FormattedMessage
                  id='ets_account_request_new_holder_ond'
                  values={{
                    a: (...chunks : ((string) []) | []) => (<a href='/rattachements/nouveau-compte'>{chunks}</a>)
                  }}
                />
              </div>
            </header>
          </div>
          <div className='d-flex justify-content-center row mt-4'>
            <div className='is-validated row col-12 col-lg-8 col-xl-6'>
              <div className='col-12 col-md-6 mb-3'>
                <SelectField
                  inputId='procedure'
                  label={<FormattedMessage id='ets_account_request_procedure_label' />}
                  onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, procedure: event.target.value })}
                  placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                  fieldStatus={fieldStatus}
                  value={etsAccountRequest.procedure}
                  options={procedureOptions}
                  required
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <TextField
                  inputId='numNat'
                  label={<FormattedMessage id={`ets_account_request_procedure_numNat_${etsAccountRequest?.procedure?.toLowerCase()}`} />}
                  fieldStatus={fieldStatus}
                  value={etsAccountRequest.numNat}
                  onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, numNat: event.target.value })}
                  required
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <FileBrowserField
                  inputId='proofFile'
                  label={<FormattedMessage id='ets_account_request_proof_file' />}
                  maxNumberFile={1}
                  buttonLabel={<FileButton />}
                  acceptApplication={DOCUMENT_FORMATS.PDF}
                  value={(etsAccountRequest.proofFile && [etsAccountRequest.proofFile]) || []}
                  onChange={(event) => handleFile(event, 'proofFile')}
                  onDelete={() => setEtsAccountRequest({
                    ...etsAccountRequest,
                    proofFile: undefined
                  })}
                  informationDoc
                  handleDownload
                />
              </div>
              {ETSAccountRequestNavItem.showConsentNotificationBlock && (
                <div className='bg-primary card h-auto shadow w-100 align-items-center my-3 d-flex flex-row p-2'>
                  <span className='text-white pl-1 pr-4'><FormattedMessage id='field_email_consent_recap' /></span>
                  <MultipleRadioField
                    classNameRadioGroup='d-flex text-white'
                    inputId='consentNotificationByEmail'
                    value={etsAccountRequest.consentNotificationByEmail}
                    radio={CONSENTEMENT_OPTIONS}
                    fieldStatus={fieldStatus}
                    onChange={(event) => setEtsAccountRequest({ ...etsAccountRequest, consentNotificationByEmail: event.target.value })}
                  />
                </div>
              )}
              <div className='w-100 row my-5'>
                <div className='col'>
                  <div className='d-flex justify-content-center'>
                    <SubmitButton
                      children={<FormattedMessage id='button_confirm' />}
                      className='btn-primary'
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBlock>
      </div>
    </>
  )
}

export default injectIntl(ETSAccountHolder)
