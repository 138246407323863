import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import BasketDetails from './BasketDetails'
import { BASKET_TYPES } from '../../constants/BasketConstants'
import TransactionDetails from '../transactions/TransactionDetails'
import { BasketFilter, BasketInterface } from 'interfaces/BasketInterface'
import RecordDetails from 'component/record/RecordDetails'
import {
  storeCountDepositReload,
  storeCountDepositReset,
  storeCountDepositUpdate
} from '../../store/countDeposit/countDepositActions'
import TransactionService from '../../services/transaction/TransactionService'
import RecordService from '../../services/opposition/RecordService'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

const BasketRoutes: FC<RouteComponentProps> = ({ match }) => {
  const homeCount = useSelector((state : RootStateOrAny) => state.homeCount)
  const dispatch = useDispatch()
  /**
   * Récupère le composant du détails.
   * Pour le moment, il y a deux types: Transaction et Record
   *
   * @param basket
   * @param id
   */
  const getDetailComponent = (basket: BasketInterface, id: string) => {
    if (basket.serviceType === 'records') {
      return <RecordDetails basket={basket} id={id} getTransactionCountByHeader={getTransactionCountByHeader} />
    }
    return <TransactionDetails basket={basket} id={id} getTransactionCountByHeader={getTransactionCountByHeader} />
  }

  /**
   * Mettre à jour le count des corbeilles qui sont à gauche dans la vue avec la liste des transactions
   * @param filter
   * @param reload
   */
  const getTransactionCountByHeader = (filter?: BasketFilter, reload: boolean = true) => {
    let params : { type: string, procedures?: string[] } = { type: 'status' }
    if (filter?.procedures) {
      params = { ...params, procedures: filter?.procedures }
    }
    dispatch(storeCountDepositReset())
    TransactionService.getTransactionsCount(params).then((result) => {
      dispatch(storeCountDepositUpdate(result))
    })
    TransactionService.getTransactionsCount({ ...params, type: 'notifs' }).then((result) => {
      dispatch(storeCountDepositUpdate(result))
    })
    TransactionService.getTransactionsCount({ ...params, type: 'others' }).then((result) => {
      dispatch(storeCountDepositUpdate(result))
    })
    RecordService.getCount().then((result) => {
      dispatch(storeCountDepositUpdate(result))
    })

    dispatch(storeCountDepositReload(reload))

    return homeCount
  }

  return (
    <Switch>
      {BASKET_TYPES.map(basket => (
        <Route
          key={`${basket.id}-depot`}
          path={`${match.url}/${basket.urlParam}/demande/:id`}
          render={(props) => getDetailComponent(basket, props.match.params.id)}
        />
      ))}
      {BASKET_TYPES.map(basket => (
        <Route
          key={basket.id}
          path={`${match.url}/${basket.urlParam}`}
          render={() => <BasketDetails basket={basket} getTransactionCountByHeader={getTransactionCountByHeader} />}
        />
      ))}
    </Switch>
  )
}

export default BasketRoutes
