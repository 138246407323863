import { DateField, DateUtils, DATE_ISO, DOCUMENT_FORMATS, DOCUMENT_TYPES, EventType, FieldStatus, FileBrowserField, FileButton, Foundation, SelectField, SelectOption, TextField, TransactionDocument, OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS, FoundationService, OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER } from '@inpi-marques/components'
import React, { FC, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface OpponentFormProps {
    foundation: Foundation,
    setFoundation: (foundation: Foundation) => void,
    opponentQualities: SelectOption[],
    fieldStatus?: FieldStatus,
    onDocumentAdded?: (event: EventType, type: string) => Promise<void>,
    documents?: TransactionDocument[],
    onDocumentDeleted?: (document: TransactionDocument) => Promise<void>,
    setFieldStatus?: (fieldStatus: FieldStatus) => void,
    disableFiles?: boolean,
    addToRefs?: (element) => void,
    procedureType?: string,
    handleDownload?: (document: TransactionDocument) => void
}

const OpponentForm: FC<OpponentFormProps> = ({ foundation, setFoundation, opponentQualities, fieldStatus, onDocumentAdded, onDocumentDeleted, documents, setFieldStatus, disableFiles, addToRefs, procedureType, handleDownload }) => {
  const intl = useIntl()

  useEffect(() => {
    const licenceFeeFiles = FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_OWNERSHIPTRANSFER)

    if (licenceFeeFiles.length && foundation.opponent?.quality === OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER.value) {
      onDocumentDeleted && onDocumentDeleted(licenceFeeFiles[0])
    }
  }, [foundation.opponent?.quality])

  return (
    <div className='d-flex align-items-end col-12 flex-wrap'>
      <SelectField
        addToRefs={addToRefs}
        inputId='opponent_quality'
        options={opponentQualities}
        classNameFormGroup='col-4 p-0'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByOpponent(foundation, 'quality', event.target.value))}
        value={foundation.opponent?.quality}
        label={<FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_opponent_quality_label`} />}
        placeholder={intl.formatMessage({ id: `${procedureType?.toLowerCase()}_foundation_opponent_quality_placeholder` })}
        required
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      {foundation.opponent?.quality && foundation.opponent.quality !== OPPOSITION_FOUNDATION_OPPONENT_QUALITY_ORIGINAL_OWNER.value &&
        <>
          {!disableFiles &&
            <FileBrowserField
              buttonLabel={<FileButton />}
              inputId='licenceFee'
              classNameButton='d-contents'
              label={<FormattedMessage id='foundation_opponent_licence_fee_label' />}
              className='ml-4 col-5'
              acceptApplication={DOCUMENT_FORMATS.PDF}
              onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_OWNERSHIPTRANSFER)}
              value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_OWNERSHIPTRANSFER)}
              onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
              maxNumberFile={1}
              informationDoc
              handleDownload={handleDownload}
            />}
          {foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value &&
            <>
              <TextField
                addToRefs={addToRefs}
                classNameFormGroup='col-4 pl-0'
                inputId='subscription_number'
                label={<FormattedMessage id='foundation_subscription_number_label' />}
                value={foundation.opponent.inscriptionNumber}
                onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByOpponent(foundation, 'inscriptionNumber', event.target.value))}
                required
                fieldStatus={fieldStatus}
                resetError={setFieldStatus}
              />
              <DateField
                addToRefs={addToRefs}
                classNameFormGroup='col-4'
                inputId='subscription_date'
                label={<FormattedMessage id='foundation_subscription_date_label' />}
                value={DateUtils.formateDateToFormat(foundation.opponent.inscriptionDate, DATE_ISO) ?? ''}
                onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByOpponent(foundation, 'inscriptionDate', DateUtils.formatToBeginOfDay(event.target.value)))}
                required
                fieldStatus={fieldStatus}
                resetError={setFieldStatus}
              />
            </>}
        </>}
    </div>

  )
}

export default OpponentForm
