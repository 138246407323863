import React, { Dispatch, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { FieldStatus } from 'src/form/FormInterfaces'
import { Address, AddressApiResult, Autocomplete } from '../../index'

interface AddressSearchFieldProps {
  inputId: string,
  placeholder?: string,
  onSelect?: (event: Address) => void,
  disabled?: boolean,
  className?: string,
  searchAddress: (query: string, type?: string|null, limit?: number|null) => Promise<void | AddressApiResult[]>
  dispatch?: Dispatch<any>,
  resetError?: (fieldStatus: FieldStatus) => void
}

const AddressSearchField: FC<AddressSearchFieldProps> = ({
  inputId,
  placeholder = '',
  onSelect,
  className = '',
  disabled = false,
  searchAddress,
  dispatch,
  resetError
}) => (
  <Autocomplete
    inputId={inputId}
    placeholder={placeholder}
    search={searchAddress}
    messageEmpty={<FormattedMessage id='autocomplete_no_result' />}
    onSelect={onSelect}
    disabled={disabled}
    className={className}
    dispatch={dispatch}
    resetError={resetError}
  />
)

export default AddressSearchField
