import { HelpBlock, SelfDiagnosic, SwitchField } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Message from '../../../../constants/Message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import ContentService from '../../../../services/content/ContentService'
import { BrandType } from '../../../../constants/BrandConstants'

interface SelfDiagnosisProps {
    currentBrandType: BrandType
}

const SelfDiagnosis: FC<SelfDiagnosisProps> = ({ currentBrandType }) => {
  const [diagnosticList, setDiagnosticList] = useState<SelfDiagnosic[]>([])
  // Supprimer les <p> vident qui empèchent les images de se mettre à côtés
  const regEx = /<p>(\s|(&nbsp))*<\/p>/gmi

  /**
     * Initialisation de la const diagnostic avec les informations récupéré dans la bdd lors du choix type de marque
     */
  useEffect(() => {
    ContentService.getSelfDiagnosic(currentBrandType?.value).then(setDiagnosticList)
  }, [currentBrandType])

  /**
     * On change la valeur du bouton switch lors du click
     * @param id
     */
  const onSelect = (id?: number) => {
    setDiagnosticList(diagnosticList.map((diagnosticContent) =>
      diagnosticContent.id === id
        ? ({ ...diagnosticContent, selected: !diagnosticContent.selected, wantKnowMore: !diagnosticContent.wantKnowMore }) : ({ ...diagnosticContent })
    ))
  }

  /**
   * On change la valeur du bouton en savoir plus lors du click
   * @param id
   */
  const onViewMore = (id?: number) => {
    setDiagnosticList(diagnosticList.map((diagnosticContent) =>
      diagnosticContent.id === id ? ({ ...diagnosticContent, wantKnowMore: !diagnosticContent.wantKnowMore }) : ({ ...diagnosticContent })
    ))
  }

  return (
    <>
      {diagnosticList.map((diagnosticContent, index) => (
        <div className='mb-5' key={index}>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <p className='col-md-10 col-12 d-flex font-weight-bold p-0'>
              <FormattedMessage id={diagnosticContent.question} />
            </p>
            <SwitchField
              className='ml-lg-auto'
              divClassName='flex-row-reverse bd-highlight align-middle col-md-2 col-12'
              onChange={() => onSelect(diagnosticContent.id)}
              labelClassName='ml-3 mt-1'
              label={Message.label_button_switch_yes}
              value={diagnosticContent.selected}
              inputId={diagnosticContent.title}
            />
          </div>
          <div className='col-md-10 col-12 px-0'>
            <button className='btn btn-link text-primary mb-2 d-inline align-middle' onClick={() => onViewMore(diagnosticContent.id)}>
              <FormattedMessage id='button_know_more' />
              <FontAwesomeIcon className={`ml-2 ${diagnosticContent.wantKnowMore ? 'btnIconKnowMore' : ''}`} icon={faChevronDown} />
            </button>
            {
              diagnosticContent.wantKnowMore &&
                <HelpBlock>
                  <div className='w-100' dangerouslySetInnerHTML={{ __html: diagnosticContent.answer.replace(regEx, '') }} />
                </HelpBlock>
            }
          </div>
        </div>
      ))}
    </>
  )
}

export default SelfDiagnosis
