import React, { FC, useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import TransactionService from '../../../services/transaction/TransactionService'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from '../../../store/transaction/transactionActions'
import {
  EditorialBlock,
  FieldStatus,
  FilAriane,
  ModalComponent,
  Transaction,
  TransactionDocument,
  PROCEDURE_REVOCATION_STATEMENT,
  REVOCATION_STATEMENT_CONTRIBUTORS_TYPES,
  REVOCATION_STATEMENT_CONTRIBUTORS_OPTIONS,
  DEFAULT_CONTRIBUTOR,
  DocumentType
} from '@inpi-marques/components'
import ContentService from '../../../services/content/ContentService'
import { FormattedMessage } from 'react-intl'
import StepContainer from '../../stepper/StepContainer'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import PaymentForm from '../../payment/PaymentForm'
import {
  PAYMENT_LABELS,
  PAYMENT_OBJECTS,
  PAYMENT_URL_REVOCATION_STATEMENT
} from '../../../constants/PaymentConstants'
import Recap from '../../recap/Recap'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import Titles from 'component/inscription/form/titles/Titles'
import TitlesValidator from 'component/inscription/form/titles/validator/TitlesValidator'
import RevocationStatementRequestForm from './request/RevocationStatementRequestForm'
import ContributorFrontForm from 'component/contributors/ContributorFrontForm'
import ContributorService from 'services/contributors/ContributorService'
import RevocationStatementValidation from './request/validation/RevocationStatementValidation'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
/* global localStorage */

interface RevocationStatementFormProps extends RouteComponentProps {
  isNewTransaction: boolean
}

const RevocationStatementForm: FC<RevocationStatementFormProps> = ({ history, match, isNewTransaction = false }) => {
  const dispatch = useDispatch()
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const transaction: Transaction = useSelector((state : RootStateOrAny) => state.transaction)
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const [documents, setDocuments] = useState<TransactionDocument[]>([])
  const fieldStatus: FieldStatus = useSelector((state : RootStateOrAny) => state.transactionFieldStatus)
  const [required, setIsRequired] = useState<boolean>(false)
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([])

  const handleSubmit = () => {
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then((result: Transaction) => {
        dispatch(storeTransactionUpdate(result))
        setDocuments(result.documents ?? [])
      })
    } else {
      dispatch(storeTransactionInit(PROCEDURE_REVOCATION_STATEMENT.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-REVOCATION-STATEMENT').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    ContentService.getDocumentTypes(PROCEDURE_REVOCATION_STATEMENT.value).then((response: DocumentType[]) => {
      setDocumentTypes(response)
    })
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  /**
   * Edition d'un document
   * @param editedDocument
   */
  const onDocumentEdited = (editedDocument: TransactionDocument): void => {
    setDocuments([...documents.map((document: TransactionDocument) =>
      document.name === editedDocument.name && document.createdAt === editedDocument.createdAt
        ? editedDocument : document
    )])
  }

  /**
   * Ajout d'un document
   * @param document
   */
  const onDocumentAdded = (document: TransactionDocument) => {
    setDocuments([...documents, document])
  }

  /**
   * Suppression d'un document
   * @param deletedDocument
   */
  const onDocumentDeleted = (deletedDocument: TransactionDocument) => {
    setDocuments(documents.filter((file: TransactionDocument) => !(deletedDocument.createdAt === file.createdAt && deletedDocument.internalName === file.internalName && deletedDocument.filename === file.filename)))
  }

  const saveFirstStep = async () => {
    if (isNew) {
      setIsNew(false)
      return await TransactionService.createTransaction(transaction, PROCEDURE_REVOCATION_STATEMENT.value, ['revocationStatement'])
    }
    return await TransactionService.updateTransactionBDDFromStore(transaction, ['revocationStatement'], documents)
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_revocation_statement' /></span>
      </FilAriane>
      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='breadcrumb_new_revocation_statement' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        setIsRequired={setIsRequired}
        required={required}
        views={[
          {
            id: 'revocation_statement_titles',
            label: <FormattedMessage id='revocation_statement_titles' />,
            onGoToStep: async () => saveFirstStep(),
            component: (
              <Titles
                transaction={transaction}
                fieldStatus={fieldStatus}
                origin={['FR', 'WO']}
                isMonoTitle
              />
            ),
            validateGoToStep: () => TitlesValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'contributors',
            label: <FormattedMessage id='contributor_title' />
          },
          {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_depositors_revocation_statement' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                contributorTypeLabel={`${REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.DEPOSITORS.value}_revocation_statement`}
                optionForm={REVOCATION_STATEMENT_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_REVOCATION_STATEMENT.value}
                setIsRequired={setIsRequired}
              />
            ),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.DEPOSITORS.value])
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={REVOCATION_STATEMENT_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_REVOCATION_STATEMENT.value}
                defaultContributor={{ ...DEFAULT_CONTRIBUTOR, email: user.email, consentNotificationByEmail: user.consentNotificationByEmailPreferences }}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.RECIPIENT.value])
          },
          {
            id: 'contributors_signatory',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={REVOCATION_STATEMENT_CONTRIBUTORS_OPTIONS.signatory}
                procedure={PROCEDURE_REVOCATION_STATEMENT.value}
                defaultContributor={ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.SIGNATORY.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, REVOCATION_STATEMENT_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'request',
            label: <FormattedMessage id='revocation_statement_request_title' />,
            component: (
              <RevocationStatementRequestForm
                fieldStatus={fieldStatus}
                transaction={transaction}
                documents={documents}
                onDocumentEdited={onDocumentEdited}
                onDocumentDeleted={onDocumentDeleted}
                onDocumentAdded={onDocumentAdded}
                documentTypes={documentTypes}
              />),
            validateGoToStep: () => RevocationStatementValidation.validateStep(documents, transaction.revocationStatement),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['revocationStatement'], documents)
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.REVOCATION_STATEMENT}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_REVOCATION_STATEMENT}
                isMultiTransaction={transaction.revocationStatement?.titles && transaction.revocationStatement.titles.length > 1}
              />
            )
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(RevocationStatementForm)
