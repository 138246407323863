import { Brand, ErrorField, EventType, FieldStatus, SwitchField, TextArea } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface VariousIndicationsTranslationProps {
    brand?: Brand,
    fieldStatus: FieldStatus,
    onBrandUpdated: (brand: Brand) => void
}

const VariousIndicationsTranslation: FC<VariousIndicationsTranslationProps> = ({ brand, fieldStatus, onBrandUpdated }) => {
  const [englishTranslation, setEnglishTranslation] = useState<string>(brand?.englishTranslation ?? '')
  const [spanishTranslation, setSpanishTranslation] = useState<string>(brand?.spanishTranslation ?? '')
  const [translation, setTranslation] = useState<string>(brand?.translation ?? '')
  const [hasTranslation, setHasTranslation] = useState<boolean>(!brand?.translation?.length && !brand?.englishTranslation?.length && !brand?.spanishTranslation?.length)

  useEffect(() => {
    setEnglishTranslation(brand?.englishTranslation ?? '')
    setSpanishTranslation(brand?.spanishTranslation ?? '')
    setTranslation(brand?.translation ?? '')
  }, [brand])

  /**
     * A la mise à jour du switch
     */
  const onSwitchChanged = (): void => {
    const newValue = !hasTranslation
    let updatedBrand: Brand = { ...brand }
    // Si les traductions ne sont pas nécessaire, on les supprime
    if (newValue) {
      updatedBrand = { ...updatedBrand, englishTranslation: undefined, spanishTranslation: undefined, translation: undefined }
    }
    setHasTranslation(newValue)
    onBrandUpdated({ ...updatedBrand, hasTranslation: newValue })
  }

  return (
    <div className='mt-3'>
      <div className='d-flex'>
        <h2 className='text-primary'>
          <FormattedMessage id='various_indications_translation_part_label' />
        </h2>
      </div>
      <div>
        <SwitchField
          inputId='hasTranslation'
          label={<FormattedMessage id='various_indications_has_translation_label' />}
          value={hasTranslation}
          onChange={() => onSwitchChanged()}
        />
        {
          !hasTranslation &&
            <div>
              <div className='mt-4'>
                <TextArea
                  inputId='englishTranslation'
                  label={<FormattedMessage id='various_indications_english_translation_label' />}
                  value={brand?.englishTranslation}
                  onChange={(event: EventType) => setEnglishTranslation(event.target.value)}
                  onBlur={() => onBrandUpdated({ ...brand, englishTranslation })}
                  fieldStatus={fieldStatus}
                />
              </div>
              <div className='mt-4'>
                <TextArea
                  inputId='spanishTranslation'
                  label={<FormattedMessage id='various_indications_spanish_translation_label' />}
                  value={brand?.spanishTranslation}
                  onChange={(event: EventType) => setSpanishTranslation(event.target.value)}
                  onBlur={() => onBrandUpdated({ ...brand, spanishTranslation })}
                  fieldStatus={fieldStatus}
                />
              </div>
              <div className='mt-4'>
                <TextArea
                  inputId='translation'
                  label={<FormattedMessage id='various_indications_french_translation_label' />}
                  value={brand?.translation}
                  onChange={(event: EventType) => setTranslation(event.target.value)}
                  onBlur={() => onBrandUpdated({ ...brand, translation })}
                  fieldStatus={fieldStatus}
                />
              </div>
              {fieldStatus?.translationText &&
                <ErrorField className='position-relative mb-2' message={fieldStatus.translationText} />}
            </div>
        }
      </div>
    </div>
  )
}

export default VariousIndicationsTranslation
