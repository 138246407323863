import {
  Country,
  DateUtils,
  EventType,
  FieldStatus,
  FRMI_PARTIAL_RADIATION_SUBTYPES,
  FRMI_RADIATION_TYPE_TOTAL,
  FRMI_TYPE_EFFECT_STOP,
  MultipleRadioField,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  ProductClass, ProductClassVersion,
  PRODUCTS_EXTENSION_TYPE_TOTAL,
  SelectOption,
  Title,
  Transaction,
  ProductService
} from '@inpi-marques/components'
import {
  FRMI_COUNTRIES_EXTENSION_TYPE_NAME,
  FRMI_PRODUCTS_EXTENSION_TYPE_NAME,
  PRODUCTS_EXTENSION_TYPE_PARTIAL
} from '@inpi-marques/components/src/constants/FRMIConstants'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'
import ContentService from '../../../../services/content/ContentService'

interface ExtensionsTypeProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    productsExtensionTypes?: SelectOption[],
    countriesExtensionTypes?: SelectOption[]
}

/**
 * Composant permettant de choisir l'étendue des pays et / ou des produits et services
 */
const ExtensionsType: FC<ExtensionsTypeProps> = ({ transaction, fieldStatus, productsExtensionTypes, countriesExtensionTypes }) => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootStateOrAny) => state.user.user)

  const onTypeChanged = (event: EventType): void => {
    const { value, name } = event.target

    if (name === FRMI_COUNTRIES_EXTENSION_TYPE_NAME && value === PRODUCTS_EXTENSION_TYPE_TOTAL && transaction.frmiDetails?.titles) {
      let countryCodes: string[] = []
      transaction.frmiDetails.titles.forEach((title: Title) => {
        if (title.countryCodes?.length) {
          countryCodes = [...new Set([...countryCodes, ...title.countryCodes])]
        }
      })
      ContentService.getCountries().then((response: Country[]) => {
        const countries: Country[] = response.filter((country: Country) => countryCodes.some((countryCode: string) => countryCode === country.code))
        dispatch(storeTransactionUpdateFRMI({
          ...transaction.frmiDetails,
          [name]: value,
          countries: countries
        }))
      })
    } else if (name === FRMI_PRODUCTS_EXTENSION_TYPE_NAME && value === FRMI_RADIATION_TYPE_TOTAL.value && transaction.frmiDetails?.titles) {
      const allProductClasses: ProductClass[] = transaction.frmiDetails?.titles?.filter((title: Title) => title.productsAndServicesVersions?.length).map((title: Title) => ProductService.getCurrentVersion(title.productsAndServicesVersions)?.productClasses ?? []).flat(1).sort((a, b) => a.ref < b.ref ? -1 : a.ref > b.ref ? 1 : 0)
      const version = {
        createdDate: DateUtils.now(),
        type: PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION,
        status: PRODUCT_CLASS_VERSION_STATUS.ACCEPTED,
        creator: user,
        productClasses: allProductClasses
      } as ProductClassVersion
      dispatch(storeTransactionUpdateFRMI({
        ...transaction.frmiDetails,
        [name]: value,
        productsAndServicesVersions: [version]
      }))
    } else {
      dispatch(storeTransactionUpdateFRMI({
        ...transaction.frmiDetails,
        [name]: value
      }))
    }
  }

  const onSubTypeChanged = (value: string): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      productsExtensionSubType: value
    }))
  }

  const operationType: string|undefined = transaction.subProcedureType

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id={`${operationType?.toLowerCase()}_products_extension_title`} /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      {productsExtensionTypes &&
        <div className='is-validated position-relative'>
          <h2 className='text-primary mb-2'><FormattedMessage id={`${operationType?.toLowerCase()}_products_extension_type_label`} /></h2>
          <MultipleRadioField
            className='col-12'
            classNameError=''
            inputId='productsExtensionType'
            onChange={onTypeChanged}
            value={transaction.frmiDetails?.productsExtensionType}
            radio={productsExtensionTypes.map((type: SelectOption) => ({ label: type.label, value: type.value as string }))}
            fieldStatus={fieldStatus}
          />
          {operationType === FRMI_TYPE_EFFECT_STOP.value && transaction.frmiDetails?.productsExtensionType === PRODUCTS_EXTENSION_TYPE_PARTIAL &&
            <MultipleRadioField
              className='col-12'
              classNameError=''
              classNameRadioGroup='mt-3 px-5'
              inputId='productsExtensionSubType'
              onChange={(event: EventType) => onSubTypeChanged(event.target.value)}
              value={transaction.frmiDetails?.productsExtensionSubType}
              radio={FRMI_PARTIAL_RADIATION_SUBTYPES.map((subType: SelectOption) => ({ label: subType.label, value: subType.value as string }))}
              fieldStatus={fieldStatus}
            />}
        </div>}
      {countriesExtensionTypes &&
        <div className='is-validated position-relative mt-4'>
          <h2 className='text-primary mb-2'><FormattedMessage id={`${operationType?.toLowerCase()}_countries_extension_type_label`} /></h2>
          <MultipleRadioField
            className='col-12'
            classNameError=''
            inputId='countriesExtensionType'
            onChange={onTypeChanged}
            value={transaction.frmiDetails?.countriesExtensionType}
            radio={countriesExtensionTypes.map((type: SelectOption) => ({ label: type.label, value: type.value as string }))}
            fieldStatus={fieldStatus}
          />
        </div>}
    </>
  )
}
export default ExtensionsType
