import React, { FC, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import {
  DateUtils,
  NOTIFICATION_STATUS_ANSWERED,
  NOTIFICATION_STATUS_COLLAPSE,
  NOTIFICATION_STATUS_TO_ANSWER_OMPI,
  NotificationItem,
  NotificationOMPI,
  Transaction
} from '@inpi-marques/components'
import OverviewNotificationOMPIInfo from './OverviewNotificationOMPIInfo'
import NotificationOMPIAnswerForm from './NotificationOMPIAnswerForm'
import OverviewNotificationOMPIAnswer from './OverviewNotificationOMPIAnswer'
import OverviewNotificationOMPIComment from './OverviewNotificationOMPIComment'

interface OverviewNotificationOMPIBodyProps {
  notification: NotificationOMPI
  transaction: Transaction
  index: number
  setReload?: () => void
}

/**
 * Composant contenant une notification OMPI
 * Permet d'afficher l'en-tête (aperçu sur une ligne de la notification),
 * les informations générales non éditables et le bloc pour répondre à la notification
 *
 * @param notification - Objet notification
 * @param index - l'index de la notification dans le tableau
 * @param transaction - Objet transaction
 * @param setReload - Fonction pour modifier si la transaction doit être rechargée
 * @constructor
 */
const OverviewNotificationOMPIBody: FC<OverviewNotificationOMPIBodyProps> = ({
  notification,
  index,
  transaction,
  setReload
}) => {
  /**
   * Les premiers champs qui seront toujours visibles, si on clique sur le chevron, on ouvre l'accordéon
   * */
  const ContextAwareToggle: FC = ({ eventKey }) => {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey
    )
    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type='button'
        onClick={decoratedOnClick}
        className='bg-transparent border-0 col-1 btn btn-link-primary'
      >
        <FormattedMessage id={isCurrentEventKey ? 'overview_transaction_notification_close' : 'overview_transaction_notification_open'} />
      </button>
    )
  }

  return (
    <div key={notification.id} className='row mx-0 mb-3'>
      <Accordion
        className='w-100' defaultActiveKey={NOTIFICATION_STATUS_COLLAPSE.includes(notification.status) ? `collapse-notification-${index}` : ''}
      >
        <div className='header-accordion d-flex justify-content-between border-top pt-2 mt-2'>
          <NotificationItem className='col-3' label={<FormattedMessage id='overview_transaction_notification_title_label' />}>
            {notification.depositorRef}
          </NotificationItem>
          <NotificationItem className='col-2' label={<FormattedMessage id='overview_transaction_notification_received' />}>
            {DateUtils.formatDateFr(notification.sendingDate)}
          </NotificationItem>
          <NotificationItem className='col-2' label={<FormattedMessage id='overview_transaction_notification_due_to' />}>
            {DateUtils.formatDateFr(notification.deadlineDateTime)}
          </NotificationItem>
          <NotificationItem className='col-3' label={<FormattedMessage id='overview_transaction_notification_status_label' />}>
            <FormattedMessage id={`overview_transaction_notification_status_${notification.status}`} />
          </NotificationItem>
          <ContextAwareToggle eventKey={`collapse-notification-ompi-${index}`} />
        </div>
        <Accordion.Collapse eventKey={`collapse-notification-ompi-${index}`}>
          <div>
            <div className='col-12 mt-3'>
              <OverviewNotificationOMPIInfo notification={notification} transaction={transaction} />
            </div>
            {notification.inpiCommentToRecipient &&
              <div className='col-12 mt-3'>
                <OverviewNotificationOMPIComment notification={notification} />
              </div>}
            <div className='mt-3'>
              {
                notification.status === NOTIFICATION_STATUS_TO_ANSWER_OMPI.value &&
                  <NotificationOMPIAnswerForm
                    initialNotification={notification}
                    index={index}
                    transaction={transaction}
                    setReload={setReload}
                  />
              }
              {
                notification.status === NOTIFICATION_STATUS_ANSWERED.value &&
                  <OverviewNotificationOMPIAnswer
                    notification={notification}
                    index={index}
                    transaction={transaction}
                  />
              }
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  )
}

export default OverviewNotificationOMPIBody
