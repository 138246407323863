import {
  CardBlock,
  containsErrors,
  DateUtils,
  ErrorField,
  FieldStatus,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  ProductClass,
  ProductClassVersion,
  ProductService as CommonProductsService,
  Transaction
} from '@inpi-marques/components'
import ProductAndServicesCart from 'component/deposit/form/productsAndServices/ProductAndServiceCart'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import {
  DIVISION_PRODUCTS_AND_SERVICES_TAB_FORM,
  TAB_FORM_CLASSIFICATION_OVERVIEW,
  TAB_FORM_CLASSIFICATION_YOUR_BASKET
} from 'constants/ProductsAndServicesConstants'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import ProductsService from 'services/deposit/ProductsService'
import StoreService from 'services/StoreService'
import { storeTransactionFieldStatusReset } from 'store/fieldStatus/fieldStatusActions'
import DivisionProductAndServiceList from './DivisionProductAndServiceList'

interface ProductsAndServicesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

const DivisionProductsAndServices: FC<ProductsAndServicesProps> = ({ children, transaction, fieldStatus }) => {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState(DIVISION_PRODUCTS_AND_SERVICES_TAB_FORM[0])

  // Version parente mère
  const [parentVersion, setParentVersion] = useState<ProductClassVersion>(CommonProductsService.getCurrentVersion(transaction.deposit?.parentProductsAndServicesVersions) ??
      {
        status: PRODUCT_CLASS_VERSION_STATUS.ACCEPTED,
        type: PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION,
        productClasses: [],
        createdDate: DateUtils.now()
      })
  // Version de la marque fille
  const [childVersion, setChildVersion] = useState<ProductClassVersion>(CommonProductsService.getVersionByType(
      transaction.deposit?.productsAndServicesVersions, PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION) ?? ProductsService.getChildVersionFromParent(parentVersion))

  /** Version mère originale.
   * Celle-ci nous permettra de faire des rollback sur la version mère car, si je supprime un produit de la fille qui appartenait précédemment à la mère,
   * il faut pouvoir le réinséré dans la liste des produits de la mère.
   */
  const [originalVersion, setOriginalVersion] = useState<ProductClassVersion>()

  useEffect(() => {
    setOriginalVersion(parentVersion)
  }, [])

  useEffect(() => {
    // On enregistre dans le store
    StoreService.changeStore({
      ...transaction,
      deposit: {
        ...transaction.deposit,
        productsAndServicesVersions: [{ ...childVersion, productClasses: ProductsService.sortProductClassByRef(childVersion.productClasses ?? []) }],
        parentProductsAndServicesVersions: [{ ...parentVersion, productClasses: ProductsService.sortProductClassByRef(parentVersion.productClasses ?? []) }]
      }
    })

    if (containsErrors(fieldStatus)) {
      dispatch(storeTransactionFieldStatusReset())
    }
  }, [childVersion, parentVersion])

  /**
   * On supprime les classes des marques mères et filles qui n'ont pas de produits
   */
  const cleanVersions = (): void => {
    setParentVersion({
      ...parentVersion,
      productClasses: parentVersion.productClasses.filter((productClass: ProductClass) => productClass.products.length)
    })
    setChildVersion({
      ...childVersion,
      productClasses: childVersion.productClasses.filter((productClass: ProductClass) => productClass.products.length)
    })
  }

  /**
   * On met à jour le boutons 'Etape suivante' du formulaire du StepContainer.
   * Si l'utilisateur appuie sur ce bouton alors qu'il n'est pas dans le panier, alors il y est redirigé et on clean les versions mère et fille
   *
   * @param children
   * @returns
   */
  const getEditedChilden = (children: ReactElement): ReactElement[] => {
    return React.Children.map(children, (child: ReactElement) => {
      // On check si l'élément est valide ou non
      if (React.isValidElement(child)) {
        // On réassigne child afin de pas avoir à le caster à chaque utilisation
        const validatedChild: ReactElement = child
        const updatedChildProps: {children: ReactElement[], onClick?: () => void} = { children: getEditedChilden(validatedChild.props.children) }
        if (validatedChild.props.id === 'stepform-next-button' && selectedTab !== TAB_FORM_CLASSIFICATION_YOUR_BASKET) {
          updatedChildProps.onClick = () => { cleanVersions(); setSelectedTab(TAB_FORM_CLASSIFICATION_YOUR_BASKET) }
        }
        // On retourne le clone de l'enfant mis à jour (ou non)
        return React.cloneElement(child, updatedChildProps)
      }
      return child
    })
  }

  /**
   * Suppression de produits de la classe fille via le panier
   * @param productClasses
   */
  const onProductClassDeleted = (productClasses: ProductClass[]): void => {
    const updatedChildVersion = { ...childVersion, productClasses: productClasses }
    setChildVersion(updatedChildVersion)
    const updatedParentVersion: ProductClassVersion|null = ProductsService.updateParentVersionFromEditedClass(updatedChildVersion, parentVersion, true)
    if (updatedParentVersion) {
      setParentVersion(updatedParentVersion)
    }
  }

  return (
    <>
      <div className='products-and-services'>
        <div className='row mb-4 justify-content-between'>
          <header className='col-8'>
            <h1 className='products-and-services__title'><FormattedMessage id='products_and_services_title' /></h1>
          </header>
          <InternalReferenceField
            transaction={transaction}
            className='col-3'
          />
        </div>
        <div className='products-and-services__tabs row'>
          {
            DIVISION_PRODUCTS_AND_SERVICES_TAB_FORM.map((tab: string) => (
              <div className='col' key={`tab-${tab}`}>
                <CardBlock
                  shadow
                  onClick={() => setSelectedTab(tab)}
                  className={`products-and-services__tab ${selectedTab === tab ? 'border-primary text-primary' : ''}`}
                  bodyClassName='px-3 py-2 text-uppercase justify-content-center align-items-center text-center'
                >
                  <div className='products-and-services__tab-content'>
                    <FormattedMessage id={`products_and_services_tab_${tab.toLowerCase()}`} />
                    {(tab === TAB_FORM_CLASSIFICATION_YOUR_BASKET && childVersion.productClasses && childVersion.productClasses.length > 0) &&
                      <span>  ({ProductsService.countProducts(childVersion.productClasses)})</span>}
                  </div>
                </CardBlock>
              </div>
            ))
          }
        </div>
        {selectedTab === TAB_FORM_CLASSIFICATION_OVERVIEW &&
          <DivisionProductAndServiceList
            transaction={transaction}
            parentVersion={parentVersion}
            childVersion={childVersion}
            setChildVersion={setChildVersion}
            setParentVersion={setParentVersion}
            setOriginalVersion={setOriginalVersion}
            originalVersion={originalVersion}
          />}
        {selectedTab === TAB_FORM_CLASSIFICATION_YOUR_BASKET &&
          <ProductAndServicesCart
            productClasses={childVersion.productClasses ?? []}
            onProductClassesEdited={onProductClassDeleted}
          />}
        {
          (fieldStatus.parentVersion || fieldStatus.childVersion) &&
            <div className='col-12'>
              <ErrorField message={fieldStatus.parentVersion ?? fieldStatus.childVersion} className='fade alert alert-danger show position-relative mt-4' />
            </div>
        }
      </div>
      {getEditedChilden(children as ReactElement)}
    </>)
}

export default DivisionProductsAndServices
