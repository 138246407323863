import React, { FC, useEffect, useState } from 'react'
import {
  CardBlock,
  containsErrors, CONTRIBUTOR_DEPOSITORS, ContributorForm,
  ContributorValidator,
  DEPOSIT_CONTRIBUTORS_OPTIONS,
  DEPOSIT_CONTRIBUTORS_TYPES,
  FieldStatus,
  FilAriane,
  SubmitButton
} from '@inpi-marques/components'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import ContentService from '../../services/content/ContentService'
import { Contact } from '../../interfaces/Contact'
import ContactUtils from '../../utils/ContactUtils'
import OpenDataRNCSService from '../../services/content/OpenDataRNCSService'
import ContactService from '../../services/contact/ContactService'
import ModalContactError from './ModalContactError'
import { toast } from 'react-toastify'
import Message from '../../constants/Message'

interface ContactProps extends RouteComponentProps<{id: string}> {
}

const OneContact : FC<ContactProps> = ({
  history,
  match
}) => {
  const [contact, setContact] = useState<Contact>()
  const [idContactToEdit, setIdContactToEdit] = useState<number>()
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const [showModalError, setShowModalError] = useState<boolean>(false)
  const [contactIdToReplace, setContactIdToReplace] = useState<number>()

  useEffect(() => {
    if (match.params.id) {
      ContactService.getContact(match.params.id).then(result => {
        setContact(result)
        setIdContactToEdit(result.id)
      })
    } else {
      setIdContactToEdit(-1)
    }
  }, [])

  const goToList = () => {
    history.push('/contacts')
  }

  /**
   * Validation d'un intervenant
   */
  const validateContact = () => {
    const fieldStatusContributor = ContributorValidator.validateContributor(ContactUtils.mapToContributor(contact), null, CONTRIBUTOR_DEPOSITORS.value)
    setFieldStatus(fieldStatusContributor)
    if (!containsErrors(fieldStatusContributor)) {
      if (idContactToEdit === -1) {
        // Cas d'un nouvel ajout
        ContactService.addContact(contact).then((response) => {
          if (response) {
            if ('errorCode' in response) {
              if (response.errorCode === ContactUtils.ERROR_CONTACT_ALREADY_EXIST) {
                // On récupère l'id du contact déjà existant
                setContactIdToReplace(response.data)
                setShowModalError(true)
              }
            } else {
              toast.success(Message.success_add_contact)
              goToList()
            }
          }
        })
      } else {
        // Cas d'une modification
        ContactService.modifyContact(contact, idContactToEdit).then((response) => {
          if (response) {
            toast.success(Message.success_modify_contact)
            goToList()
          }
        })
      }
    }
  }

  return (
    <div className='addresses-book'>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_addresses_book' /></span>
        {idContactToEdit && idContactToEdit !== -1 &&
          <span><FormattedMessage id='breadcrumb_modify_addresses_book' /></span>}
        {idContactToEdit && idContactToEdit === -1 &&
          <span><FormattedMessage id='breadcrumb_add_addresses_book' /></span>}
      </FilAriane>
      <CardBlock
        className='step-view card-block-container p-5 is-validated'
      >
        <div className='row justify-content-center'>
          <div className='col-9'>
            <ContributorForm
              transaction={{}}
              optionsForm={DEPOSIT_CONTRIBUTORS_OPTIONS.address_book}
              contributorType={DEPOSIT_CONTRIBUTORS_TYPES.ADDRESS_BOOK.value}
              text={{
                title: (
                  <h1 className='mb-0 pb-2'>
                    {idContactToEdit && idContactToEdit !== -1 &&
                      <span><FormattedMessage id='edit_contributor_title' /></span>}
                    {idContactToEdit && idContactToEdit === -1 &&
                      <span><FormattedMessage id='create_contributor_title' /></span>}
                  </h1>
                )
              }}
              contributor={ContactUtils.mapToContributor(contact)}
              setContributor={(contributor) => {
                setContact(ContactUtils.mapToContact(contributor))
              }}
              fieldStatus={fieldStatus}
              getCountries={ContentService.getCountries}
              fillContributorInfo={(siren: string) => OpenDataRNCSService.getContributorInfos(siren)}
              findListSirenByNamePromise={(name: string) => OpenDataRNCSService.findListSirenByName(name)}
            />
            <div className='step-buttons mt-4 row justify-content-center'>
              <SubmitButton
                className='bg-white text-gris py-2'
                onClick={goToList}
              >
                <FormattedMessage id='button_cancel' />
              </SubmitButton>
              <SubmitButton
                className='bg-primary py-2'
                onClick={validateContact}
              >
                <FormattedMessage id='button_save' />
              </SubmitButton>
            </div>
          </div>
        </div>
      </CardBlock>
      <ModalContactError
        updateList={goToList}
        showModal={showModalError}
        setShowModal={setShowModalError}
        contact={contact}
        contactIdToReplace={contactIdToReplace}
      />
    </div>
  )
}

export default withRouter(OneContact)
