import Message from '../../../constants/Message'
import { containsErrors, isFilled, Transaction } from '@inpi-marques/components'
import store from '../../../store/store'
import { storeTransactionFieldStatusUpdate } from '../../../store/fieldStatus/fieldStatusActions'
import PriorityValidator from '../../../services/options/PriorityValidator'

class PrioritiesValidator {
  validateOnChangeStep = (status: number, transaction: Transaction) => {
    let transactionFieldStatus = {}
    if (status !== -1) {
      transactionFieldStatus = { priority: Message.priority_error }
    } else if (transaction.deposit?.priorities && isFilled(transaction.deposit?.priorities)) {
      const priorities = transaction.deposit?.priorities
      for (let i = 0; i < priorities?.length; i++) {
        const error = PriorityValidator.validatePriority(priorities[i])
        if (containsErrors(error)) {
          transactionFieldStatus = { priority: Message.priority_missing_field_error }
          break
        }
      }
    }
    store.dispatch(storeTransactionFieldStatusUpdate(transactionFieldStatus))
    return !containsErrors(transactionFieldStatus)
  }
}

export default new PrioritiesValidator()
