import React, { FC } from 'react'
import PriorityFields from './PriorityFields'
import {
  EventType,
  FieldStatus,
  Priority,
  SubmitButton,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'

interface PriorityFormProps {
  transaction: Transaction,
  // priorité actuelle à modifier et compléter
  priority?: Priority,
  // fonction pour modifier la priorité
  setPriority: (newPriority: Priority) => void,
  // informations concernant les erreurs sur le formulaire
  fieldStatus?: FieldStatus,
  //  index
  editIndex: number,
  isEditingMode: boolean,
  onDocumentChanged: (event: EventType, documentType: string) => void,
  onDocumentDeleted: (event: EventType) => void,
  documents: TransactionDocument[],
  validate: () => void,
  cancel: () => void
}

const PriorityForm: FC<PriorityFormProps> = (
  {
    transaction,
    priority,
    setPriority,
    fieldStatus,
    editIndex,
    isEditingMode,
    onDocumentChanged,
    onDocumentDeleted,
    documents,
    validate,
    cancel
  }) => {
  /**
   * Enregistrement des modifications de la priorité
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target
    const newPriority = {
      ...priority,
      [name]: value
    }
    setPriority(newPriority)
  }

  return (
    <>
      <h3>
        <FormattedMessage
          id='priority_label_number'
          values={{
            nb: editIndex + 1
          }}
        />
      </h3>

      <PriorityFields
        transaction={transaction}
        priority={priority}
        onChange={handleChanges}
        fieldStatus={fieldStatus}
        onDocumentChanged={onDocumentChanged}
        onDocumentDeleted={onDocumentDeleted}
        documents={documents}
        setPriority={setPriority}
      />
      <div className='row mt-4 justify-content-between'>
        <div className='col-4'>
          <button className='btn btn-block btn-outline-gris' onClick={cancel}>
            <FormattedMessage id='button_cancel' />
          </button>
        </div>
        <div className='col-4'>
          <SubmitButton className='btn btn-block bg-primary' onClick={validate}>
            <FormattedMessage id={`${(isEditingMode ? 'button_modify_priority' : 'button_add_priority')}`} />
          </SubmitButton>
        </div>
      </div>
    </>
  )
}

export default PriorityForm
