import React, { useState, useEffect, FC } from 'react'
import { toast } from 'react-toastify'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  CardBlock,
  EventType,
  FieldStatus, FilAriane,
  isFilled,
  ModalComponent,
  NoResult,
  SelectField,
  SelectOption,
  SubmitButton,
  Table,
  Transaction
} from '@inpi-marques/components'
import { FoUserResponse } from '../../interfaces/User'
import ETSAccountService from '../../services/ets/ETSAccountService'
import Message from '../../constants/Message'
import { ACCESS_DEMAND_ITEM_ATTACH_PM } from '../../constants/ETSAccountConstants'
import AssignPicker from './AssignPicker'
import ToAssignTableBody from './ToAssignTableBody'
import ETSAccountPMLoader from './ETSAccountPMLoader'
import { Link } from 'react-router-dom'

const ETSAccountPM : FC<WrappedComponentProps> = ({ intl }) => {
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})

  const [emailCurrentETSAccount, setEmailCurrentETSAccount] = useState()
  const [emailNewETSAccount, setEmailNewETSAccount] = useState()

  const [transactionsShown, setTransactionsShown] = useState<Transaction[]>()
  const [checkedIdTransactions, setCheckedIdTransactions] = useState<string[]>([])

  const [showAssignModal, setShowAssignModal] = useState(false)
  const [users, setUsers] = useState<SelectOption[]>([])

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // Récupération des PP attachées à la PM
    ETSAccountService.getETSAccountForPM().then(result => {
      setUsers(result.map((r: FoUserResponse) => {
        return {
          label: `${r.firstname} ${r.lastname}`,
          value: r.email
        }
      }))
    })
  }, [])

  /**
   * Sélectionne l'utilisateur et récupère les transactions qui lui sont liées
   */
  const chooseUser = async () => {
    if (!isFilled(emailCurrentETSAccount)) {
      return setFieldStatus({ ...fieldStatus, currentETSAccount: Message.required_field })
    } else {
      setFieldStatus({})

      setIsLoading(true)
      // Récupération des transactions liées au compte ETS sélectionné
      return ETSAccountService.getETSAccountTransactions(emailCurrentETSAccount).then((transactions) => {
        setTransactionsShown(transactions)
        setIsLoading(false)
      })
    }
  }

  /**
   * Assigne les transactions sélectionnées à l'utilisateur sélectionné
   */
  const submitAssign = () => {
    if (!isFilled(emailNewETSAccount)) {
      return setFieldStatus({ ...fieldStatus, assign: Message.required_field })
    } else {
      return ETSAccountService.assignTransaction(emailNewETSAccount, checkedIdTransactions).then(() => {
        toast.success(intl.formatMessage({ id: 'ets_account_request_success_request' }))
        setShowAssignModal(false)
        setIsLoading(true)
        // Mise à jour des transactions attachées au compte
        ETSAccountService.getETSAccountTransactions(emailCurrentETSAccount).then((transactions) => {
          setTransactionsShown(transactions)
          setIsLoading(false)
        })
      })
    }
  }

  /**
   * Ajoute ou supprime tous les transactions affichées dans la liste
   * des dépôts sélectionnés
   * @param isAdd
   */
  const handleCheckAllTransaction = (isAdd: boolean) => {
    let updatedIdChecked = []

    if (isAdd) {
      updatedIdChecked = transactionsShown?.map(t => t.id)
    }

    setCheckedIdTransactions(updatedIdChecked)
  }

  /**
   * Ajoute ou supprime une transaction dans la liste des transactions
   * sélectionnés
   * @param id
   */
  const handleCheckTransaction = (id: string) => {
    const updatedIdChecked = [...checkedIdTransactions]

    const indexId = updatedIdChecked.indexOf(id)
    if (indexId > -1) {
      updatedIdChecked.splice(indexId, 1)
    } else {
      updatedIdChecked.push(id)
    }

    setCheckedIdTransactions(updatedIdChecked)
  }

  /**
   * Vérifie que tous les transactions affichées sont checkées
   */
  const areAllTransactionsChecked = () => {
    if (transactionsShown?.length === 0) {
      return false
    }

    return transactionsShown?.map((t: Transaction) => t.id)
      .filter((id) => id && !checkedIdTransactions.includes(id))
      .length === 0
  }

  const renderLoader = () => <ETSAccountPMLoader />

  const renderTable = () => (
    <div className='mt-4'>
      <Table
        id='ETSAccounts'
        tableTitle={[
          {
            label: Message.transaction_num,
            className: 'text-center'
          },
          {
            label: Message.transaction_lastUpdate,
            className: 'text-center'
          },
          {
            label: Message.field_internal_reference_label,
            className: 'text-center'
          },
          {
            label: Message.transaction_type,
            className: 'text-center'
          }
        ]}
        selectAll={areAllTransactionsChecked()}
        onSelectAll={handleCheckAllTransaction}
        showPagination={false}
      >
        <ToAssignTableBody
          list={transactionsShown}
          onSelect={handleCheckTransaction}
          checkedList={checkedIdTransactions}
        />
      </Table>
      <div className='d-flex justify-content-center'>
        <SubmitButton
          className='btn-outline-primary'
          onClick={() => setShowAssignModal(true)}
          disabled={!checkedIdTransactions.length}
        >
          <FormattedMessage id='ets_account_request_pm_picker_button' />
        </SubmitButton>
      </div>
    </div>
  )

  const renderNoResult = () => (
    <div className='mt-4'>
      <NoResult />
    </div>
  )

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id={ACCESS_DEMAND_ITEM_ATTACH_PM.breadcrumb} /></span>
      </FilAriane>
      <div className='d-flex mt-4 justify-content-center'>
        <CardBlock
          className='col-12'
          shadow
        >
          <div className='d-flex justify-content-center row main'>
            <header className='col-8'>
              <h1><FormattedMessage id={ACCESS_DEMAND_ITEM_ATTACH_PM.label} /></h1>
            </header>
          </div>
          <div className='d-flex justify-content-center row mt-4'>
            <div className='is-validated row col-12 col-lg-8 col-xl-6'>
              <div className='col-8'>
                <SelectField
                  label={<FormattedMessage id='ets_account_request_pm_current_account' />}
                  inputId='currentETSAccount'
                  placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                  value={emailCurrentETSAccount}
                  onChange={(event: EventType) => setEmailCurrentETSAccount(event.target.value)}
                  fieldStatus={fieldStatus}
                  options={users}
                  required
                />
              </div>
              <div className='col-4 d-flex justify-content-end align-items-center mt-2'>
                <SubmitButton
                  children={<FormattedMessage id='button_confirm' />}
                  className='btn-primary'
                  onClick={chooseUser}
                />
              </div>
              <div className='col-12'>
                {
                  transactionsShown || isLoading ? (
                    isLoading ? renderLoader()
                      : (transactionsShown && transactionsShown.length > 0 ? renderTable() : renderNoResult())
                  ) : null
                }
              </div>
            </div>
          </div>
        </CardBlock>
      </div>
      <ModalComponent
        title={<FormattedMessage id='ets_account_request_pm_picker' />}
        customContent={() =>
          <AssignPicker
            users={users}
            onSelect={setEmailNewETSAccount}
            fieldStatus={fieldStatus}
          />}
        show={showAssignModal}
        handleClose={() => setShowAssignModal(false)}
        onClick={submitAssign}
      />
    </>
  )
}

export default injectIntl(ETSAccountPM)
