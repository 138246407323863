import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'

interface FileButtonProps {
  label?: JSX.Element
}
const FileButton: FC<FileButtonProps> = ({ label }) => (
  <IntlProvider locale='fr' messages={Message}>
    <div className='file-button mt-1 cursor-pointer'>
      <span className='file-button__text'>
        {label || <FormattedMessage id='form_file_placeholder' />}
      </span>
      <FontAwesomeIcon icon={faArrowAltCircleDown} />
    </div>
  </IntlProvider>
)

export default FileButton
