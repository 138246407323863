import React, { FC } from 'react'
import LimitedProductsAndServicesFormFields from './LimitedProductsAndServicesFormFields'
import { Country, EventType, FieldStatus, ProductClassVersion, SubmitButton } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'

interface PriorityFormProps {
  // priorité actuelle à modifier et compléter
  limitedProductsAndServices?: ProductClassVersion,
  // fonction pour modifier la priorité
  setLimitedProductsAndServices: (newClassVersion: ProductClassVersion) => void,
  // informations concernant les erreurs sur le formulaire
  fieldStatus?: FieldStatus,
  //  index
  editIndex?: number,
  isEditingMode: boolean,
  validate: () => void,
  cancel: () => void,
  countries: Country[]
}

const LimitedProductsAndServicesForm: FC<PriorityFormProps> = (
  {
    limitedProductsAndServices,
    setLimitedProductsAndServices,
    fieldStatus,
    editIndex,
    isEditingMode,
    validate,
    cancel,
    countries
  }) => {
  /**
   * Enregistrement des modifications de la limitation
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target
    const newLimitedProductsAndServicesVersion = {
      ...limitedProductsAndServices,
      [name]: value
    } as ProductClassVersion
    setLimitedProductsAndServices(newLimitedProductsAndServicesVersion)
  }

  return (
    <div className='mb-5 pb-5'>
      <h3>
        <FormattedMessage
          id='limited_products_services_limitation_number'
          values={{
            nb: editIndex + 1
          }}
        />
      </h3>

      <LimitedProductsAndServicesFormFields
        countries={countries}
        limitedProductsAndServices={limitedProductsAndServices}
        onChange={handleChanges}
        fieldStatus={fieldStatus}
        setLimitedProductsAndServices={setLimitedProductsAndServices}
      />

      <div className='row mt-4 justify-content-between'>
        <div className='col-4'>
          <button className='btn btn-block btn-outline-gris' onClick={cancel}>
            <FormattedMessage id='button_cancel' />
          </button>
        </div>
        <div className='col-4'>
          <SubmitButton className='btn btn-block bg-primary' onClick={validate}>
            <FormattedMessage
              id={`${(isEditingMode ? 'limited_products_services_modify_limitation' : 'limited_products_services_validate_limitation')}`}
            />
          </SubmitButton>
        </div>
      </div>
    </div>
  )
}

export default LimitedProductsAndServicesForm
