import { CardBlock, MotiveList, Transaction } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import DocumentsOverview from '../../transactions/overview/DocumentsOverview'
import ContestedRequestOverview from './ContestedRequestOverview'
import InformationsOverview from '../../transactions/overview/InformationsOverview'
import FoundationList from '../form/foundations/FoundationList'
import IntervenantsOpposantOverview from '../../transactions/overview/IntervenantsOpposantOverview'
import MotiveService from 'services/motives/MotiveService'

interface OverviewProps {
  transaction: Transaction,
  showAddDocumentsButton?: boolean,
  isUserActive?: boolean,
  setReload?: () => void
}

const OppositionOverview: FC<OverviewProps> = ({ transaction, showAddDocumentsButton = false, isUserActive, setReload }) => {
  return (
    <div className='col-12 d-flex flex-wrap p-0'>
      {isUserActive &&
        <div className='col-12 mb-3'>
          <CardBlock header={<FormattedMessage id='overview_deposit_type_title' />} shadow>
            <InformationsOverview transaction={transaction} />
          </CardBlock>
        </div>}

      {/* Récapitulatif documents */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='overview_file_title' />} shadow>
          <DocumentsOverview
            transaction={transaction}
            showAddDocumentsButton={showAddDocumentsButton}
            isUserActive={isUserActive}
            setReload={setReload}
          />
        </CardBlock>
      </div>

      {isUserActive &&
        <>
          {/* Intervenants */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_deposit_intervenants_title' />} shadow>
              <IntervenantsOpposantOverview transaction={transaction} />
            </CardBlock>
          </div>

          {/* Récapitulatif de la demande d'enregitrement contestée */}
          {transaction.opposition && transaction.opposition.contestedRequest &&
            <div className='col-12 mb-3'>
              <CardBlock
                header={<FormattedMessage id={`${transaction.procedureType?.toLowerCase()}_registration_request_contested_label`} />}
                shadow
              >
                <ContestedRequestOverview transaction={transaction} />
              </CardBlock>
            </div>}

          {/* Récapitulatif des fondements / motifs absolus */}
          {transaction.opposition && transaction.opposition.foundations.length > 0 &&
            <div className='col-12 mb-3'>
              <CardBlock
                header={<FormattedMessage id={`overview_${transaction.procedureType?.toLocaleLowerCase()}_foundations_title`} />}
                shadow
              >
                <FoundationList foundations={transaction.opposition?.foundations} />
              </CardBlock>
            </div>}

          {/* Récapitulatif des motifs (relatifs) */}
          {transaction.opposition && transaction.opposition.motives.length > 0 &&
            <div className='col-12 mb-3'>
              <CardBlock
                header={<FormattedMessage id={`overview_${transaction.procedureType?.toLocaleLowerCase()}_motives_title`} />}
                shadow
              >
                <MotiveList
                  cancelRequest={MotiveService.cancelRequest} getAllMotives={MotiveService.getAll}
                  procedureType={transaction.procedureType}
                  motiveCodes={transaction.opposition?.motives}
                />
              </CardBlock>
            </div>}
        </>}
    </div>
  )
}

export default OppositionOverview
