import React, { FC } from 'react'
import {
  DOCUMENT_FORMATS,
  downloadFile,
  FileBrowserField,
  NotificationOMPI,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import DocumentService from '../../../services/document/DocumentService'

interface OverviewNotificationOMPIAnswerProps {
  transaction: Transaction,
  notification: NotificationOMPI,
  index: number
}

/**
 * Composant qui contient l'aperçu de la réponse faite à la notification
 * Permet d'afficher la liste des documents et le commentaire ajouté
 *
 * @param transaction - Objet transaction
 * @param notification - Objet notification
 * @param index - index de la notification dans le tableau
 * @constructor
 */
const OverviewNotificationOMPIAnswer: FC<OverviewNotificationOMPIAnswerProps> = ({
  transaction,
  notification,
  index
}) => {
  const handleDownload = (document: TransactionDocument) => {
    return transaction.id && document.internalName && DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => {
      downloadFile(data, document.name)
    })
  }

  return (
    <div>
      <div className='row m-0 pl-3'>
        <h3><FormattedMessage id='overview_transaction_notification_answer' /></h3>
      </div>
      <div className='d-flex flex-column justify-content-center border p-4 mb-2'>
        {transaction.documents?.filter((doc) => notification.supportingDocuments?.includes(doc.internalName))?.length > 0 &&
          <FileBrowserField
            inputId={`documents-${index}`}
            label={<FormattedMessage id='overview_transaction_notification_supporting_document_label' />}
            classNameFormGroup='col-12'
            buttonLabel={
              <div className='border'>
                <div className='text-center my-1 mx-2'>
                  <FormattedMessage id='button_find_file' />
                </div>
              </div>
            }
            acceptApplication={DOCUMENT_FORMATS.PDF}
            value={transaction.documents?.filter((doc) => notification.supportingDocuments?.includes(doc.internalName))}
            handleDownload={handleDownload}
            readonly
          />}
        <div className='d-flex flex-column-center'>
          <span className='text-gris'><FormattedMessage id='overview_transaction_notification_ompi_answer_label' /></span>
          <span>{notification.irregularitiesAnswerComment ?? '-'}</span>
        </div>
      </div>
    </div>
  )
}

export default OverviewNotificationOMPIAnswer
