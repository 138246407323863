import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CardBlock,
  DownloadLink,
  PROCEDURE_DIVISION,
  PROCEDURE_INSCRIPTION,
  SubmitButton,
  Transaction,
  TransactionService as CommonTransactionService
} from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Prompt, RouteComponentProps, withRouter } from 'react-router'
import PaymentService from 'services/payment/PaymentService'
import TransactionService from 'services/transaction/TransactionService'
import { faCheckCircle, faExclamationCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { IStateInformations, IStateInformationsItem, IStateInformationsType } from 'interfaces/PaymentInterface'
import {
  PAYMENT_URL_CORRECTION,
  PAYMENT_URL_DEPOSIT,
  PAYMENT_URL_DIVISION,
  PAYMENT_URL_FRMI,
  PAYMENT_URL_FRMI_POST_OPERATIONS,
  PAYMENT_URL_INSCRIPTION,
  PAYMENT_URL_NOTIFICATION,
  PAYMENT_URL_NULLITY,
  PAYMENT_URL_OFFICIAL_DOCUMENT,
  PAYMENT_URL_OPPOSITION,
  PAYMENT_URL_REGULARIZATION,
  PAYMENT_URL_RENEWAL,
  PAYMENT_URL_RENUNCIATION,
  PAYMENT_URL_REVOCATION,
  PAYMENT_URL_REVOCATION_STATEMENT,
  PAYMENT_URL_TRANSFORMATION_TN_TA
} from 'constants/PaymentConstants'
import { BASKET_ID, BASKET_TYPES } from '../../constants/BasketConstants'
import { useDispatch } from 'react-redux'
import { storeTransactionInit } from 'store/transaction/transactionActions'
import qs from 'qs'
import { BasketInterface } from 'interfaces/BasketInterface'
import DocumentService from 'services/document/DocumentService'

interface PaymentProps extends RouteComponentProps {
  state: 'confirmation' | 'annulation' | 'erreur',
  transactionId: string,
  type: string
}

const stateInformations: IStateInformations = {
  confirmation: {
    className: 'text-success',
    icon: faCheckCircle,
    types: {
      [PAYMENT_URL_DEPOSIT]: {
        title: 'payment_deposit_success',
        subtitle: 'payment_deposit_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/859?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      },
      [PAYMENT_URL_OPPOSITION]: {
        title: 'payment_opposition_success',
        subtitle: 'payment_opposition_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/862?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      },
      [PAYMENT_URL_NULLITY]: {
        title: 'payment_nullity_success',
        subtitle: 'payment_nullity_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/2760?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      },
      [PAYMENT_URL_REVOCATION]: {
        title: 'payment_revocation_success',
        subtitle: 'payment_revocation_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/2761?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      },
      [PAYMENT_URL_CORRECTION]: {
        title: 'payment_correction_success',
        subtitle: 'payment_correction_text_success',
        showDownloadLink: false
      },
      [PAYMENT_URL_NOTIFICATION]: {
        title: 'payment_notification_success',
        subtitle: 'payment_notification_text_success',
        showDownloadLink: false
      },
      [PAYMENT_URL_REGULARIZATION]: {
        title: 'payment_regularization_success',
        subtitle: 'payment_regularization_text_success',
        showDownloadLink: false
      },
      [PAYMENT_URL_DIVISION]: {
        title: 'payment_division_success',
        subtitle: 'payment_division_text_success',
        showDownloadLink: true
      },
      [PAYMENT_URL_INSCRIPTION]: {
        title: 'payment_inscription_success',
        subtitle: 'payment_inscription_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/863?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      },
      [PAYMENT_URL_RENUNCIATION]: {
        title: 'payment_renunciation_success',
        subtitle: 'payment_renunciation_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/863?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      },
      [PAYMENT_URL_TRANSFORMATION_TN_TA]: {
        title: 'payment_transformation_tn_ta_success',
        subtitle: 'payment_transformation_tn_ta_text_success',
        showDownloadLink: true,
        goTransformationTa: true
      },
      [PAYMENT_URL_FRMI]: {
        title: 'payment_frmi_success',
        subtitle: 'payment_frmi_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/867?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      },
      [PAYMENT_URL_FRMI_POST_OPERATIONS]: {
        title: 'payment_frmi_post_operation_success',
        subtitle: 'payment_frmi_post_operation_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/867?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      },
      [PAYMENT_URL_OFFICIAL_DOCUMENT]: {
        title: 'payment_official_document_success',
        subtitle: 'payment_official_document_text_success',
        showDownloadLink: true
      },
      [PAYMENT_URL_REVOCATION_STATEMENT]: {
        title: 'payment_revocation_statement_success',
        subtitle: 'payment_revocation_statement_text_success',
        showDownloadLink: true
      },
      [PAYMENT_URL_RENEWAL]: {
        title: 'payment_renewal_success',
        subtitle: 'payment_renewal_text_success',
        showDownloadLink: true,
        myAdviceUrl: 'https://jedonnemonavis.numerique.gouv.fr/Demarches/860?&view-mode=formulaire-avis&nd_source=button&key=8ea4b71aed19487ec7c5a74979594fbf'
      }
    }
  },
  annulation: {
    className: 'text-warning',
    icon: faExclamationCircle,
    types: {
      [PAYMENT_URL_DEPOSIT]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_OPPOSITION]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_NULLITY]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_REVOCATION]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_CORRECTION]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_NOTIFICATION]: {
        title: 'payment_notification_annulation',
        subtitle: 'payment_notification_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_REGULARIZATION]: {
        title: 'payment_regularization_annulation',
        subtitle: 'payment_regularization_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_DIVISION]: {
        title: 'payment_division_annulation',
        subtitle: 'payment_division_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_INSCRIPTION]: {
        title: 'payment_inscription_annulation',
        subtitle: 'payment_inscription_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_RENUNCIATION]: {
        title: 'payment_renunciation_annulation',
        subtitle: 'payment_renunciation_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_TRANSFORMATION_TN_TA]: {
        title: 'payment_transformation_tn_ta_annulation',
        subtitle: 'payment_transformation_tn_ta_text_annulation',
        showDownloadLink: true
      },
      [PAYMENT_URL_FRMI]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_FRMI_POST_OPERATIONS]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_OFFICIAL_DOCUMENT]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_REVOCATION_STATEMENT]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      },
      [PAYMENT_URL_RENEWAL]: {
        title: 'payment_annulation',
        subtitle: 'payment_text_annulation',
        showDownloadLink: false
      }
    }
  },
  erreur: {
    className: 'text-danger',
    icon: faTimesCircle,
    types: {
      [PAYMENT_URL_DEPOSIT]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_OPPOSITION]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_NULLITY]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_REVOCATION]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_CORRECTION]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_NOTIFICATION]: {
        title: 'payment_notification_error',
        subtitle: 'payment_notification_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_REGULARIZATION]: {
        title: 'payment_regularization_error',
        subtitle: 'payment_regularization_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_DIVISION]: {
        title: 'payment_division_error',
        subtitle: 'payment_division_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_INSCRIPTION]: {
        title: 'payment_inscription_error',
        subtitle: 'payment_inscription_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_RENUNCIATION]: {
        title: 'payment_renunciation_error',
        subtitle: 'payment_renunciation_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_TRANSFORMATION_TN_TA]: {
        title: 'payment_transformation_tn_ta_error',
        subtitle: 'payment_transformation_tn_ta_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_FRMI]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_FRMI_POST_OPERATIONS]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_OFFICIAL_DOCUMENT]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_REVOCATION_STATEMENT]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      },
      [PAYMENT_URL_RENEWAL]: {
        title: 'payment_error',
        subtitle: 'payment_text_error',
        showDownloadLink: false
      }
    }
  }
}

const Payment:FC<PaymentProps> = ({ state, transactionId, location, type, history }) => {
  const dispatch = useDispatch()
  const [transaction, setTransaction] = useState<Transaction>()
  const stateInformation: IStateInformationsItem = stateInformations[state]
  const stateInformationType: IStateInformationsType = stateInformation.types[type]

  /**
   * S'il s'agit d'une transaction générant plusieurs transactions après le paiement
   * tel que le renouvellement ou le relevé de déchéance.
   */
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  })
  const isMulti: boolean = query.isMulti !== undefined

  const checkPayment = useCallback(async () => {
    const params: URLSearchParams = new URLSearchParams(location.search)
    const paymentId: string | null = params.get('id')
    try {
      // Mise à jour du statut du paiement et récupération du nouveau statut du dépôt
      if (paymentId) {
        if (state === 'annulation') {
          await PaymentService.cancelPayment(transactionId, paymentId)
        } else {
          // Vérification du paiement auprès de Paybox si c'est un paiement par carte
          await PaymentService.checkPayment(transactionId, paymentId)
        }
      }
      const transaction: Transaction = await TransactionService.getTransaction(transactionId)
      setTransaction(transaction)
    } catch (e) {
      history.push(`/${type}/${transactionId}/paiement/erreur`)
    }
  }, [transactionId, location.search, state])

  useEffect(() => {
    checkPayment()
  }, [state, checkPayment])

  const handlePaymentStatusRedirect = () => {
    /**
     * S'il s'agit d'une transaction générant plusieurs transactions après le paiement,
     * on redirige vers la corbeille "En cours d'examen".
     */
    if (isMulti) {
      const typeRequest = BASKET_TYPES.find((type: BasketInterface) => type.id === BASKET_ID.UNDER_REVIEW)
      history.push(`/liste/${typeRequest?.urlParam}?corbeille=${transaction?.procedureType?.toLowerCase()}`)
      return
    }

    if (stateInformationType.goTransformationTa) {
      return TransactionService.getTransaction(transactionId).then(transaction => {
        return TransactionService.getTransaction(transaction.inscription.transformationTa?.idTransaction).then(transactionTA => {
          const typeRequest = BASKET_TYPES.find(type => type.statusFiltres.includes(transactionTA.status))
          history.push(`/liste/${typeRequest?.urlParam}/demande/${transactionTA.id}`)
        })
      })
    } else {
      const typeRequest = BASKET_TYPES.find(type => type.statusFiltres.includes(transaction.status))
      let url = `/liste/${typeRequest?.urlParam}/demande/${transactionId}`
      if (type === PAYMENT_URL_REGULARIZATION && state === 'annulation') {
        url += '?regularisation=true'
      }
      history.push(url)
    }
  }

  const handleSuiteRedirect = () => {
    switch (transaction?.procedureType) {
      case PROCEDURE_DIVISION.value:
        history.push(`/divisions/nouveau?suite=${transaction?.id}`)
        dispatch(storeTransactionInit(PROCEDURE_DIVISION.value))
        break
      case PROCEDURE_INSCRIPTION.value:
        if (CommonTransactionService.isRenunciation(transaction)) {
          history.push(`/renonciations/nouveau?suite=${transaction?.id}`)
        } else {
          history.push(`/inscriptions/nouveau?suite=${transaction?.id}`)
        }
        dispatch(storeTransactionInit(PROCEDURE_DIVISION.value))
        break
      default:
        break
    }
  }

  const handlePaymentRetry = () => {
    history.push(`/${type}/${transactionId}/edition?payment`)
  }

  return (
    <div className='d-flex justify-content-center mt-5 mb-5'>
      <Prompt when={!transaction} message={Message.payment_prompt} />
      <CardBlock flexBody={false} className='payment-div'>
        {transaction
          ? (
            <>
              <div className={`${stateInformation.className} text-center mb-3`}>
                <FontAwesomeIcon icon={stateInformation.icon} size='5x' />
              </div>
              <h1 className='mb-5 text-center'><FormattedMessage id={stateInformationType.title} /></h1>
              <div className='text-center'><FormattedMessage id={stateInformationType.subtitle} /></div>
              <div className='d-flex justify-content-around p-4'>
                {!isMulti && stateInformationType.showDownloadLink &&
                  <DownloadLink
                    label={<FormattedMessage id='payment_download' />}
                    onClick={() => {
                      if (stateInformationType.goTransformationTa) {
                        return TransactionService.getTransaction(transactionId).then(t =>
                          TransactionService.getOverview(t.inscription?.transformationTa?.idTransaction)
                        )
                      } else {
                        return TransactionService.getTransaction(transactionId).then(t =>
                          DocumentService.downloadLastOverviewFile(t)
                        )
                      }
                    }}
                  />}
                {
                  state === 'erreur' &&
                  [PAYMENT_URL_DEPOSIT, PAYMENT_URL_OPPOSITION, PAYMENT_URL_NULLITY, PAYMENT_URL_REVOCATION, PAYMENT_URL_DIVISION, PAYMENT_URL_INSCRIPTION, PAYMENT_URL_RENUNCIATION, PAYMENT_URL_FRMI, PAYMENT_URL_FRMI_POST_OPERATIONS, PAYMENT_URL_OFFICIAL_DOCUMENT, PAYMENT_URL_REVOCATION_STATEMENT, PAYMENT_URL_RENEWAL].includes(type) &&
                    <SubmitButton
                      className='btn-link-primary mb-1'
                      onClick={handlePaymentRetry}
                    >
                      <FormattedMessage id='payment_not_redirection' />
                    </SubmitButton>
                }
                <div className='row flex-column'>
                  <SubmitButton
                    className='btn-link-primary '
                    onClick={handlePaymentStatusRedirect}
                  >
                    <FormattedMessage id={isMulti ? 'payment_redirection_multi' : 'payment_redirection'} />
                  </SubmitButton>
                  {transaction.suite &&
                    <SubmitButton
                      className='btn-link-primary '
                      onClick={handleSuiteRedirect}
                    >
                      <FormattedMessage id={`payment_redirect_${transaction.procedureType?.toLowerCase()}_suite`} />
                    </SubmitButton>}
                </div>
              </div>
              {
                stateInformationType.myAdviceUrl &&
                  <a
                    className='d-flex justify-content-center'
                    href={stateInformationType.myAdviceUrl}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <img src='https://jedonnemonavis.numerique.gouv.fr/static/bouton-blanc.svg' alt='Je donne mon avis' />
                  </a>
              }
            </>)
          : (
            <>
              <div className='d-flex justify-content-center mt-3 mb-3'>
                <FontAwesomeIcon className='loader' icon={faSpinner} />
              </div>
              <div className='waiting-msg'>
                <FormattedMessage id='payment_waiting' />
              </div>
            </>
          )}
      </CardBlock>
    </div>
  )
}

export default withRouter(Payment)
