import { OMPIFees, OMPIFeesLine, Table } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface FeesAndTaxesAmountProps {
    ompiFees?: OMPIFees,
    isCalculatorLoading?: boolean
}

/**
 * Composant permettant d'afficher le résultat du calculateur OMPI sous forme de tableau avec une taxe par ligne
 * @param ompiFees résultat du calcul des taxes OMPI
 * @param isCalculatorLoading savoir si le calcul est en cours ou fini
 * @constructor
 */
const FeesAndTaxesAmount: FC<FeesAndTaxesAmountProps> = ({ ompiFees, isCalculatorLoading }) => {
  return (
    <div className='feesAndTaxesAmount'>
      {isCalculatorLoading ? <div className='row justify-content-center'><FontAwesomeIcon className='loader mr-1' icon={faSpinner} /> </div> : (
        <>
          <Table tableTitle={[]}>
            <tbody>
              {
                ompiFees && ompiFees.lines.map((line: OMPIFeesLine, index: number) => {
                  const prevFee = index > 0 ? ompiFees.lines[index - 1] : { countryCode: '' }
                  const country = prevFee.countryCode !== line.countryCode ? line.countryLabel : ''
                  return (
                    <>
                      {
                        country &&
                          <tr key={`-${index}`}>
                            <td colSpan={12} className='payment-method-label'>{country}</td>
                          </tr>
                      }
                      <tr key={`-${index}`} className={`${index === ompiFees.lines.length - 1 && line.label?.startsWith('TOTAL') ? 'font-weight-bold' : ''} payment-method-label`}>
                        <td colSpan={9}>{line.label?.includes('<br>') ? line.label?.substring(0, line.label?.indexOf('<br>'))
                          : line.label}<br />{line.label?.includes('<br>') ? line.label?.substring(line.label?.indexOf('<br>') + 4) : ''}
                        </td>
                        <td className='text-right' colSpan={3}>{`${line.amount} CHF`}</td>
                      </tr>
                    </>

                  )
                })
              }
            </tbody>
          </Table>
        </>
      )}
    </div>
  )
}

export default FeesAndTaxesAmount
