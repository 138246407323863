import React, { FC, ReactNode } from 'react'
import { IntlProvider } from 'react-intl'
import {
  Contributor,
  ContributorsOptionsForm,
  ContributorsTextForm
} from '../interfaces/contributors/ContributorsInterfaces'
import { Country } from '../interfaces/ContentInterfaces'
import Message from '../constants/Message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import OverviewContributor from './OverviewContributor'

interface ContributorListFormProps {
    contributorList: Contributor[],
    editContributor: (index: number) => void,
    deleteContributor: (index: number) => void,
    text: ContributorsTextForm,
    children?: ReactNode,
    countries: Country[],
    fromFrmiExtension?: boolean,
    optionsForms?: ContributorsOptionsForm,
    procedureType?: string,
    contributorType?: string,
}

const ContributorListForm: FC<ContributorListFormProps> = ({
  contributorList,
  deleteContributor,
  editContributor,
  text,
  children,
  countries,
  fromFrmiExtension,
  procedureType,
  optionsForms,
  contributorType
}) => {
  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='form-header'>
        {text.title}
        {text.subtitle}
      </div>

      {contributorList && contributorList.length > 0 && (
        <ul>
          {contributorList.map((contributor, index) => (
            <li key={index} className='my-2'>
              <div className='row'>
                <div className='col-8'>
                  <OverviewContributor
                    contributor={contributor}
                    countries={countries}
                    fromFrmiExtension={fromFrmiExtension}
                    procedureType={procedureType}
                    isFirst={index === 0}
                    contributorType={contributorType}
                  />
                </div>
                <div className='col'>
                  <button className='btn btn-link text-primary' onClick={() => editContributor(index)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  {!optionsForms?.allFieldReadOnly &&
                    <button className='btn btn-link text-danger ml-3' onClick={() => deleteContributor(index)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div>
        {children}
      </div>
    </IntlProvider>
  )
}

export default ContributorListForm
