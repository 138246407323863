import React, { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import {
  EventType,
  FieldStatus,
  PROCEDURE_OPPOSITION,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import RequestIdentification from './RequestIdentification'
import { useDispatch } from 'react-redux'

interface RegistrationRequestProps {
  transaction: Transaction,
  fieldStatus?: FieldStatus,
  setDocuments: (documents: TransactionDocument[]) => void,
  onDocumentDeleted: (event: EventType)=> void,
  documents: TransactionDocument[],
  setIsRequired: (value: boolean) => void
}

const RegistrationRequest : FC<RegistrationRequestProps> = (
  {
    transaction,
    fieldStatus,
    setDocuments,
    onDocumentDeleted,
    documents,
    setIsRequired
  }
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    setIsRequired(true)
  }, [])

  return (
    <div>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id={`${transaction.procedureType !== PROCEDURE_OPPOSITION.value ? 'registration_request_contested_revocation_title' : 'registration_request_contested_title'}`} /></h1>
          <span className='subtitle'><FormattedMessage id={`${transaction.procedureType !== PROCEDURE_OPPOSITION.value ? `${transaction.procedureType?.toLocaleLowerCase()}_registration_request_contested_revocation_description` : 'registration_request_contested_description'}`} /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <RequestIdentification
        dispatch={dispatch}
        fieldStatus={fieldStatus}
        setDocuments={setDocuments}
        onDocumentDeleted={onDocumentDeleted}
        documents={documents}
        transaction={transaction}
      />
    </div>
  )
}

export default RegistrationRequest
