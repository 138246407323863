import {
  CheckboxField,
  containsErrors,
  Contributor,
  DOCUMENT_FORMATS,
  ErrorField,
  EventType,
  FieldStatus,
  FileBrowserField,
  FileButton,
  FRMI_TYPE_EXTENSION,
  LinkedTransaction,
  ModalComponent,
  PERSONNE_MORALE,
  PERSONNE_PHYSIQUE,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION_STATEMENT,
  PROCESS_FAST_TRACK_RENEWAL,
  PROCESS_NORMAL, ProductAndService, ProductClass, ProductClassVersion,
  ProductService,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  Record,
  RENEWAL_TYPE_PARTIAL,
  RENEWAL_TYPE_TOTAL,
  Title,
  Transaction,
  TransactionDocument,
  TransactionService,
  LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION
} from '@inpi-marques/components'
import {
  CONTRIBUTOR_LICENSEE,
  CONTRIBUTOR_OLD_AGENTS,
  CONTRIBUTOR_OLD_HOLDERS,
  CONTRIBUTOR_OLD_LICENSEES
} from '@inpi-marques/components/src/constants/contributors/ContributorsConstants'
import ContributorService from '@inpi-marques/components/src/services/ContributorService'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import { FRMIStep, FRMITypeForm } from 'interfaces/frmi/FrmiInterfaces'
import qs from 'qs'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import FrontContributorService from '../../../../services/contributors/ContributorService'
import TitleService from '../../../../services/inscription/TitleService'
import RecordService from '../../../../services/opposition/RecordService'
import StoreService from '../../../../services/StoreService'
import TitleList from './TitleList'
import TitleValidateContent from './TitleValidateContent'
import TitlesValidator from './validator/TitlesValidator'
import RenewalService from '../../../../services/renewal/RenewalService'
import { RNM, RNMSearchResult } from 'interfaces/RNMInterfaces'

interface TitlesProps {
  transaction: Transaction,
  fieldStatus: FieldStatus,
  showDownloadExampleFile?: boolean
  showImportTitleFile?: boolean,
  showDownloadTitleList?: boolean,
  origin?: string[],
  setTmpPreview?: (preview: TransactionDocument) => void,
  isMonoTitle?: boolean,
  fromMadrid?: boolean,
  currentTypeForm?: FRMITypeForm
}

/**
 * Composant permettant d'afficher la liste des titres et d'afficher le formulaire d'ajout d'un titre
 */
const Titles: FC<TitlesProps> = ({
  transaction,
  fieldStatus,
  showDownloadExampleFile = false,
  showImportTitleFile = false,
  showDownloadTitleList = false,
  origin = ['FR', 'WO'],
  isMonoTitle = false,
  setTmpPreview,
  fromMadrid = false,
  currentTypeForm
}) => {
  const user = useSelector((state: RootStateOrAny) => state.user.user)
  const [editIndex, setEditIndex] = useState<number>(-1)
  const [isEditingMode, setEditingMode] = useState<boolean>(false)
  const [title, setTitle] = useState<Title>()
  const [fieldStatusList, setFieldStatusList] = useState<FieldStatus[]>([])
  const [idRecordTmp, setIdRecordTmp] = useState<string>()
  const [renewalType, setRenewalType] = useState<boolean>(transaction?.renewalDetails?.type === RENEWAL_TYPE_TOTAL.value)
  const [renewalReclassify, setRenewalReclassify] = useState<boolean>(transaction?.renewalDetails?.titles?.[0]?.reclassify)
  const [showModalCsv, setShowModalCsv] = useState(false)
  const [errorsCsv, setErrorsCsv] = useState<string[]>([])
  const [documentCsv, setDocumentCsv] = useState()
  const location = useLocation()

  /**
   * Retourne les bons titres en fonction de la procédure
   */
  const getTitlesByProcedureType = () => {
    switch (transaction.procedureType) {
      case PROCEDURE_INSCRIPTION.value:
        return transaction.inscription?.titles ?? []
      case PROCEDURE_FRMI.value:
        return transaction.frmiDetails?.titles ?? []
      case PROCEDURE_RENEWAL.value:
        return transaction.renewalDetails?.titles ?? []
      case PROCEDURE_REVOCATION_STATEMENT.value:
        return transaction.revocationStatement?.titles ?? []
      default:
        return []
    }
  }

  const [titles, setTitles] = useState<Title[]>([])

  /**
   * Ajout d'un titre
   */
  const addTitle = () => {
    const index = titles.length
    setEditIndex(index)
    setEditingMode(false)
  }

  // Define an async function to handle the process
  const fetchLinkedTransactions = async () => {
    const params = {
      dateInscription: true,
      cancelledAt: true,
      searchNumTitle: title?.numNat,
      natureCodes: true,
      order: 'ASC',
      sortBy: 'numInscription'
    }
    // Await the result from RenewalService
    const result: RNMSearchResult = await RenewalService.linkedTransactions(params)
    // Map the result to create linked transactions
    const linkedTransactions: LinkedTransaction[] = result.rnmList?.map((rnm: RNM) => ({
      numNat: rnm.numInscription,
      type: LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION.value
    })) ?? []
    return linkedTransactions
  }

  /**
   * Valide le titre
   */
  const validate = async () => {
    const fieldStatusTitle = TitlesValidator.validateTitle(title, transaction.procedureType)
    const newFieldStatus = [...fieldStatusList]
    newFieldStatus[editIndex] = fieldStatusTitle
    setFieldStatusList(newFieldStatus)
    if (!containsErrors(fieldStatusTitle)) {
      const newList = [...titles]
      newList[editIndex] = { ...title, reclassify: renewalReclassify }

      let updatedTransactionState
      let linkedTransactions: LinkedTransaction[] | undefined = []
      if (transaction.procedureType === PROCEDURE_RENEWAL.value) {
        linkedTransactions = await fetchLinkedTransactions()
        updatedTransactionState = {
          ...transaction,
          depositors: transaction.renewalDetails?.titles?.[editIndex]?.numNat !== title?.numNat || !title?.id ? title?.holders ?? [] : transaction.depositors,
          renewalDetails: {
            ...transaction.renewalDetails,
            linkedTransactions,
            type: renewalType ? RENEWAL_TYPE_TOTAL.value : RENEWAL_TYPE_PARTIAL.value,
            associatedBrands: TitleService.getAssociatedBrands(newList, transaction.renewalDetails)
          }
        }
        StoreService.changeStore(updatedTransactionState)
      }

      onTitlesUpdated(newList, updatedTransactionState)
      setTitle({})
      setEditIndex(-1)
      setTmpPreview && setTmpPreview({ ...newList[0].details?.brand?.file, idRecordTmp })
    }
  }

  /**
   * Annule un ajout ou la modification d'un titre
   */
  const cancel = () => {
    setFieldStatusList(fieldStatusList.filter((_, index) => index !== editIndex))
    setEditIndex(-1)
    setTitle({})
    setRenewalType(transaction?.renewalDetails?.type === RENEWAL_TYPE_TOTAL.value)
    setRenewalReclassify(transaction?.renewalDetails?.titles?.[0]?.reclassify)
  }

  /**
   * Supprime un titre de la liste
   * @param index
   */
  const deleteTitle = (index: number) => {
    const deletedTitle: Title | undefined = titles.find((_, titleIndex: number) => titleIndex === index)
    const newTitles = [...titles.filter((_, titleIndex: number) => index !== titleIndex)]
    onTitlesUpdated(newTitles, undefined, deletedTitle)
    const updatedFieldStatus = [...fieldStatusList]
    updatedFieldStatus.splice(index, 1)

    setFieldStatusList(updatedFieldStatus)
  }

  /**
   * Lors de l'import du Csv, récupération des informations concernant les titres
   */
  const onSubmitCsv = () => {
    if (documentCsv) {
      setErrorsCsv([])
      return TitleService.getTitlesFromCsv(documentCsv).then((result) => {
        onTitlesUpdated([...titles, ...result])
        setShowModalCsv(false)
        setErrorsCsv([])
      }).catch(response => {
        setErrorsCsv([...response.errors])
      })
    }
  }

  const onTitlesUpdated = (updatedTitles: Title[], updatedTransactionState?: Transaction, deletedTitle?: Title): void => {
    const baseTransaction = updatedTransactionState ?? transaction

    let updatedTransaction: Transaction = { ...baseTransaction }
    const procedureType = baseTransaction.procedureType

    switch (procedureType) {
      case PROCEDURE_INSCRIPTION.value:
        updatedTransaction = {
          ...baseTransaction,
          inscription: {
            ...baseTransaction.inscription,
            titles: updatedTitles
          }
        }
        break
      case PROCEDURE_FRMI.value: {
        let otherApplicants: Contributor[] = [...(baseTransaction.otherApplicants ?? [])]

        /**
         * Pour les opérations postérieures, on garde en mémoire les titulaires / mandataires actuels
         */
        if (!deletedTitle && baseTransaction.subProcedureType !== FRMI_TYPE_EXTENSION.value) {
          updatedTitles.forEach((title: Title) => {
            const hasToDuplicateHolders: boolean = currentTypeForm?.steps.some((step: FRMIStep) => step.element?.props?.otherApplicantCodeToDuplicate === CONTRIBUTOR_OLD_HOLDERS.value) ?? false
            const hasToDuplicateLicencees: boolean = currentTypeForm?.steps.some((step: FRMIStep) => step.element?.props?.otherApplicantCodeToDuplicate === CONTRIBUTOR_OLD_LICENSEES.value) ?? false

            /** On check si le formulaire a besoin des agents actuels,
             * Puis, on passe le mandataire du titre en mandataire actuel de la transaction s'il n'existe pas déjà
             */
            const needOldAgent: boolean = !!(currentTypeForm && currentTypeForm.steps.some((step: FRMIStep) => step.element?.props?.codes?.some((code: string) => code === CONTRIBUTOR_OLD_AGENTS.value)))
            if (needOldAgent && title.agent && !otherApplicants.some((applicant: Contributor) => applicant.code === CONTRIBUTOR_OLD_AGENTS.value && applicant.originalTitleNumNat === title.numNat)) {
              otherApplicants.push({ ...title.agent, code: CONTRIBUTOR_OLD_AGENTS.value, originalTitleNumNat: title.numNat })
            }
            /** On check si le formulaire a besoin des titulaires actuels,
             * puis on passe les titulaires du site en titulaire actuel de la transaction, et uniquement pour les titulaires actuels n'existant pas dans la liste */
            const needOldHolders: boolean = !!(currentTypeForm && currentTypeForm.steps.some((step: FRMIStep) => step.element?.props?.codes?.some((code: string) => code === CONTRIBUTOR_OLD_HOLDERS.value)))
            if (needOldHolders && title.holders && title.holders.length) {
              otherApplicants = [...otherApplicants,
                ...(title.holders
                  .map((holder: Contributor) => ({
                    type: holder.type,
                    lastname: holder.type === PERSONNE_PHYSIQUE.value ? holder.lastname : '',
                    companyName: holder.type === PERSONNE_MORALE.value ? holder.companyName : '',
                    code: CONTRIBUTOR_OLD_HOLDERS.value,
                    originalTitleNumNat: title.numNat,
                    isOriginal: hasToDuplicateHolders ? true : undefined
                  }))
                  .filter((holder: Contributor) => !otherApplicants.some((applicant: Contributor) => applicant.code === holder.code && applicant.originalTitleNumNat === title.numNat)))]
            }
            /**
             * On check si le formulaire a besoin du preneur de licence actuel
             */
            const needLicencees: boolean = !!(currentTypeForm && currentTypeForm.steps.some((step: FRMIStep) => step.element?.props?.codes?.some((code: string) => code === CONTRIBUTOR_OLD_LICENSEES.value)))
            const licensees: Contributor[] = title.otherApplicants?.filter((otherApplicant: Contributor) => otherApplicant.code === CONTRIBUTOR_LICENSEE.value) ?? []
            if (needLicencees && licensees.length) {
              otherApplicants = [...otherApplicants,
                ...licensees.map((licensee: Contributor) => ({ ...licensee, code: CONTRIBUTOR_OLD_LICENSEES.value, originalTitleNumNat: title.numNat, isOriginal: hasToDuplicateLicencees ? true : undefined }))
              ]
            }
          })
        } else if (deletedTitle) {
          /** Lorsque l'on supprime un titre, on supprime les intervenants precedemment importés qui lui sont liés */
          otherApplicants = otherApplicants.filter((applicant: Contributor) => applicant.originalTitleNumNat !== deletedTitle.numNat)
        }

        let productsAndServicesVersions: ProductClassVersion[] = transaction.frmiDetails?.productsAndServicesVersions ?? []
        const currentVersion: ProductClassVersion | undefined = ProductService.getCurrentVersion(productsAndServicesVersions)

        // Si titre supprimé, on supprime les classes qui lui sont associés
        if (deletedTitle && currentVersion) {
          const titleCurrentVersion: ProductClassVersion | undefined = ProductService.getCurrentVersion(deletedTitle?.productsAndServicesVersions ?? [])
          const updatedCurrentVersion: ProductClassVersion = {
            ...currentVersion,
            productClasses: currentVersion.productClasses.filter((currentProductClass: ProductClass) =>
              !titleCurrentVersion?.productClasses.some((titleProductClass: ProductClass) => titleProductClass.ref === currentProductClass.ref &&
                titleProductClass.products.every((titleProduct: ProductAndService) => currentProductClass.products.some((currentProduct: ProductAndService) => currentProduct.name === titleProduct.name))))
          }
          productsAndServicesVersions = [updatedCurrentVersion]
        } else if (!title?.id || !titles.some((existingTitle: Title) => existingTitle.numNat === title.numNat)) {
          productsAndServicesVersions = [{
            ...ProductService.createProductsAndServicesVersion(
              PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION,
              PRODUCT_CLASS_VERSION_STATUS.ACCEPTED,
              [...currentVersion?.productClasses ?? [], ...ProductService.getCurrentVersion(title?.productsAndServicesVersions ?? [])?.productClasses.map((productClass: ProductClass) => ({
                ...productClass,
                origin: title?.numNat
              })) ?? []]
            )
          }
          ]
        }
        /**
         * Si on update les titres via la suppression
         */
        updatedTransaction = {
          ...baseTransaction,
          frmiDetails: {
            ...baseTransaction.frmiDetails,
            titles: updatedTitles,
            productsAndServicesVersions: productsAndServicesVersions
          },
          otherApplicants
        }
      }
        break
      case PROCEDURE_RENEWAL.value: {
        const canFastTrack: boolean = TitleService.canCheckFastTrack({
          ...baseTransaction.renewalDetails,
          titles: updatedTitles
        }, transaction.depositors ?? updatedTransaction.depositors)

        updatedTransaction = {
          ...baseTransaction,
          process: baseTransaction.process === PROCESS_FAST_TRACK_RENEWAL
            ? canFastTrack
              ? PROCESS_FAST_TRACK_RENEWAL : PROCESS_NORMAL
            : baseTransaction.process,
          renewalDetails: {
            ...baseTransaction.renewalDetails,
            titles: updatedTitles,
            canFastTrack
          }
        }

        if (deletedTitle) {
          updatedTransaction = {
            ...updatedTransaction,
            depositors: transaction.depositors?.filter((contributor: Contributor) => contributor.originalTitleNumNat !== deletedTitle.numNat)
          }
        }
        break
      }
      case PROCEDURE_REVOCATION_STATEMENT.value: {
        // On ajoute les nouveaux titulaires à la transaction, en tant que demandeur
        let newDepositors: Contributor[] = baseTransaction.depositors ?? []
        updatedTitles && updatedTitles.forEach((title: Title) => {
          // On check sur les utilisateurs sont déjà ajoutés ou non
          const notAddedContributors: Contributor[] | undefined = title.holders?.filter((holder: Contributor) => !newDepositors.some((depositor: Contributor) => ContributorService.getName(depositor) === ContributorService.getName(holder)))
          if (notAddedContributors) {
            newDepositors = [...newDepositors, ...notAddedContributors]
          }
        })
        updatedTransaction = {
          ...baseTransaction,
          depositors: newDepositors,
          revocationStatement: {
            ...baseTransaction.revocationStatement,
            titles: updatedTitles
          }
        }
        break
      }
      default:
        break
    }

    StoreService.changeStore(updatedTransaction)
  }

  const handleCheckFastTrack = (value: boolean): void => {
    let updatedTransaction = { ...transaction, process: value ? PROCESS_FAST_TRACK_RENEWAL : PROCESS_NORMAL } as Transaction

    if (value && !transaction?.signatory?.email !== user?.email) {
      updatedTransaction = { ...updatedTransaction, signatory: FrontContributorService.buildContributorFromUser(user) }
    }

    StoreService.changeStore(updatedTransaction)
  }

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.from) {
      RecordService.getRecord(query.from).then((result: Record) => {
        const title = TitleService.getTitleFromRecord(result, transaction.procedureType)
        onTitlesUpdated([title])
      })
    }
  }, [transaction.procedureType])

  useEffect(() => {
    setRenewalType(transaction.renewalDetails?.type === RENEWAL_TYPE_TOTAL.value)
  }, [transaction.renewalDetails?.type])

  useEffect(() => {
    setTitles(getTitlesByProcedureType())
  }, [transaction?.inscription?.titles, transaction?.frmiDetails?.titles, transaction?.renewalDetails?.titles, transaction?.revocationStatement?.titles])

  return (
    <div className='titles'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id={isMonoTitle ? 'select_one_title' : 'select_inscription_brand_title'} /></h1>
          <span className='subtitle'><FormattedMessage id={`${transaction.procedureType?.toLowerCase()}_brand_description${TransactionService.isFrmiOP(transaction) ? '_op' : ''}`} /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='justify-content-center col-12 p-0'>
        {editIndex === -1 ? (
          <TitleList
            titles={transaction.procedureType === PROCEDURE_FRMI.value && titles?.length === 0 ? transaction.frmiDetails?.titles || [] : titles}
            editTitle={(index: number) => {
              setEditIndex(index)
              setEditingMode(true)
              setTitle(titles[index])
            }}
            deleteTitle={deleteTitle}
            addTitle={addTitle}
            setShowModalCsv={setShowModalCsv}
            showDownloadExampleFile={showDownloadExampleFile}
            showImportTitleFile={showImportTitleFile}
            showDownloadTitleList={showDownloadTitleList}
            procedureType={transaction.procedureType}
            isMonoTitle={isMonoTitle}
            subProcedureType={transaction?.subProcedureType}
          />
        ) : (
          <TitleValidateContent
            title={title}
            setTitle={setTitle}
            isEditingMode={isEditingMode}
            className='md-6 mb-3 p-0'
            fieldStatus={fieldStatusList[editIndex]}
            validate={validate}
            cancel={cancel}
            transaction={transaction}
            origin={origin}
            fromMadrid={fromMadrid}
            setIdRecordTmp={setIdRecordTmp}
            editIndex={editIndex}
            renewalType={renewalType}
            renewalReclassify={renewalReclassify}
            setRenewalType={setRenewalType}
            setRenewalReclassify={setRenewalReclassify}
          />
        )}
      </div>

      {editIndex === -1 &&
        (PROCEDURE_RENEWAL.value === transaction.procedureType &&
          transaction.renewalDetails?.canFastTrack) &&
        (
          <div className='col-12'>
            <CheckboxField
              inputId='process'
              label={<FormattedMessage id='renewal_fast_track_checkbox' />}
              labelClassName='pl-2'
              checked={transaction.process === PROCESS_FAST_TRACK_RENEWAL}
              onChange={({ target }) => handleCheckFastTrack(target.value)}
            />
          </div>
        )}
      {fieldStatus.titles && (<div className='col-12'> <ErrorField message={fieldStatus.titles} className='fade alert alert-danger show position-relative mt-4' /></div>)}
      <ModalComponent
        title={<FormattedMessage id='inscription_import_titles' />}
        show={showModalCsv}
        handleClose={() => setShowModalCsv(false)}
        customContent={() => (
          <div className='is-validated'>
            <FormattedMessage id='inscription_import_titles_explain' />
            <div className='mt-3'>
              <FileBrowserField
                inputId='file'
                required
                label={<FormattedMessage id='inscription_import_titles_label' />}
                buttonLabel={<div className='text-center'><FileButton /></div>}
                onDelete={() => setDocumentCsv(null)}
                value={documentCsv && [documentCsv]}
                onChange={(event: EventType) => {
                  const { value } = event.target
                  setDocumentCsv(value)
                }}
                acceptApplication={DOCUMENT_FORMATS.CSV}
                maxNumberFile={1}
              />
            </div>
            {
              errorsCsv.length > 0 &&
              errorsCsv.map(error => <><ErrorField className='relative-position' message={error} /><br /></>)
            }
          </div>
        )}
        onClick={onSubmitCsv}
      />
    </div>
  )
}

export default Titles
