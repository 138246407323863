export const BASIC_DATE = 'DD/MM/YYYY'
export const BASIC_DATE_ALT = 'DD-MM-YYYY'
export const DATE_WITH_HOUR = 'DD/MM/YYYY HH:mm'
export const DATE_ISO = 'YYYY-MM-DD'
export const DATE_MONTH = 'YYYY-MM'
export const DATE_WITH_MONTH_LETTER_AND_YEAR = 'MMMM YYYY'
export const DATE_WITH_HOUR_FR = 'DD/MM/YYYY à HH:mm'
export const DATE_ISO8601 = 'YYYY-MM-DDTHH:mm:ssZ'

export const SECOND_IN_MILLISECONDS = 1000
export const MINUTE_IN_MILLISECONDS = 60000
export const HOUR_IN_MILLISECONDS = 3600000
export const DAY_IN_MILLISECONDS = 86400000
export const MONTH_IN_MILLISECONDS = 2628000000
export const YEAR_IN_MILLISECONDS = 31536000000

export const SECONDS = 'seconds'
export const MINUTES = 'minutes'
export const HOURS = 'hours'
export const DAYS = 'days'
export const MONTHS = 'months'
export const YEARS = 'years'
