import http from '../../network/http-common'
import { toast } from 'react-toastify'
import axios, { CancelTokenSource } from 'axios'
import {
  ANTICIPATED_RENEWAL_ASSOCIATED_BRAND, AssociatedBrand,
  BRAND_ORIGINS_SELECT_OPTIONS,
  Contributor,
  DateUtils,
  DepositService,
  PROCEDURE_RENEWAL,
  ProductClass,
  ProductClassVersion,
  ProductService,
  Publication,
  PUBLICATION_REGISTRATION,
  PUBLICATION_REGISTRATIONAM,
  PUBLICATION_TYPE,
  Record,
  RecordService,
  Renewal,
  RENEWAL_TYPE_TOTAL,
  Title,
  TitleFromCsv
} from '@inpi-marques/components'
import Message from '../../constants/Message'
import { createIntl, IntlShape } from 'react-intl'
import RenewalService from '../renewal/RenewalService'
import ProductsService from '@inpi-marques/components/src/services/Product/ProductsService'
import { BRAND_TYPE_SEMI_FIGURATIVE, BRAND_TYPE_WORD } from '../../constants/BrandConstants'

/* global FormData */

class TitleService {
    source: CancelTokenSource
    intl: IntlShape

    constructor () {
      this.source = axios.CancelToken.source()
      this.intl = createIntl({ locale: 'fr', messages: Message })
    }

  /**
   * Génère le title à partir d'un record.
   * @param record
   * @param procedureType
   * @returns
   */
  getTitleFromRecord = async (record: Record, procedureType?: string): Promise<Title> => {
    const currentProductsVersion: ProductClassVersion|undefined = ProductService.getCurrentVersion(record.details?.productsAndServicesVersions)
    const registeredDateString = record.publications?.find((publication: Publication) => publication.code === PUBLICATION_REGISTRATION || publication.code === PUBLICATION_REGISTRATIONAM)?.date

    return {
      depositType: record?.depositType,
      depositDate: record.depositDate ? DateUtils.formatToBeginOfDay(record.depositDate) : undefined,
      expirationDate: record.validityEndDate ? DateUtils.formatToBeginOfDay(record.validityEndDate) : undefined,
      referenceDate: record.depositDate ? DateUtils.formatToBeginOfDay(record.depositDate) : undefined,
      registeredDate: registeredDateString ? DateUtils.formatToBeginOfDay(registeredDateString) : undefined,
      numNat: record.numNat,
      origin: BRAND_ORIGINS_SELECT_OPTIONS.find((origin) => origin?.label === record?.originLabel)?.value ?? record.country,
      type: record.details?.brand?.type,
      text: record.details?.brand?.text,
      file: record.details?.brand?.file,
      productsAndServicesVersions: currentProductsVersion ? [ProductsService.createCleanedVersion(currentProductsVersion)] : undefined,
      protectionExtensions: record.details?.protectionExtensions ?? [],
      publications: record.publications,
      details: { ...record.details, linkedTransactions: [...record.details?.linkedTransactions ?? [], ...DepositService.getLinkedTransaction(record, procedureType === PROCEDURE_RENEWAL.value)] },
      translation: record.details?.brand?.translation,
      colors: record.details?.brand?.colors,
      description: record.details?.brand?.description,
      codification: record.details?.brand?.codification,
      isCertified: record.details?.isCertified,
      millesime: record?.country !== 'WO' ? (DateUtils.isAfter(record.depositDate, '1999-12-31') ? (record.details?.millesime ?? DateUtils.formatMillesimeYear(record.depositDate)) : undefined) : undefined,
      from: record.from,
      isPublished: RecordService.isPublished(record),
      holders: record.holders,
      agent: record.agent,
      typeDelay: procedureType === PROCEDURE_RENEWAL.value ? await RenewalService.getTypeDelay(record.validityEndDate) : undefined,
      /* eslint-disable camelcase */
      countryCodes: [...record.designatedCountry?.protocol ?? [], ...record.designatedCountry?.protocolArticle9_6 ?? []],
      otherApplicants: record.otherApplicants,
      unmodified: true,
      associatedBrands: record?.associatedBrands,
      status: record?.status,
      transactionInfos: record.transactionInfos
    } as Title
  }

  /**
   * Récupère le fichier d'exemple des titres
   * @returns
   */
  getExampleFile = async (): Promise<string> => {
    try {
      return await http.get('/file/titles.csv')
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_products_and_services_exemple_file' }))
      return Promise.reject(error)
    }
  }

  /**
   * Création d'un csv à partir d'une liste de titres
   * @param titles
   */
  createCSVFromTitles = async (titles: Title[]): Promise<ArrayBuffer> => {
    try {
      return await http.post('/api/titles/export', titles, { responseType: 'arraybuffer', cancelToken: this.source.token })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

    /**
     * Récupère les titres contenus dans un Csv
     * @param file
     */
    getTitlesFromCsv = async (file: string) : Promise<Title[]> => {
      try {
        if (file) {
          const formData = new FormData()
          formData.append('file', file)
          const response : TitleFromCsv = await http.post('/api/titles/import', formData, { cancelToken: this.source.token })
          if (response.errors && response.errors.length > 0) {
            toast.error(Message.inscription_import_titles_error)
            return Promise.reject(response)
          } else {
            toast.success(Message.inscription_import_titles_success)
            return Promise.resolve(response.titles)
          }
        }
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Vérifies si on peut cocher l'option de traitement rapide sur un renouvellement
     * @param renewalDetails
     * @param depositors
     */
    canCheckFastTrack = (renewalDetails: Renewal, depositors?: Contributor[]): boolean => {
      if (!renewalDetails?.titles || !renewalDetails.titles.length || !depositors?.length || renewalDetails?.type !== RENEWAL_TYPE_TOTAL.value || renewalDetails?.titles?.[0]?.reclassify) {
        return false
      }

      let holdersCount = 0
      for (const [titleIndex, title] of (renewalDetails?.titles ?? []).entries()) {
        if (
          !title.publications ||
          !title.publications.length ||
          !title.unmodified ||
          ProductService.getCurrentVersion(title?.productsAndServicesVersions)?.productClasses?.some((productClass: ProductClass) => productClass.ref?.includes(',')) ||
          title.associatedBrands?.some((associatedBrand: AssociatedBrand) => associatedBrand?.numNat?.includes(ANTICIPATED_RENEWAL_ASSOCIATED_BRAND)) ||
          title.publications.some((publication: Publication) => publication.code?.includes(PUBLICATION_TYPE.INSCRIPTION) || (publication.type && [PUBLICATION_TYPE.INSCRIPTION_DIVISION, PUBLICATION_TYPE.REGISTRATION_OLD_LAW].includes(publication.type))) ||
            title.holders?.some((holder: Contributor, index: number) => JSON.stringify({ ...holder, id: undefined }) !== JSON.stringify({ ...depositors[index * titleIndex], id: undefined }))
        ) {
          return false
        }
        holdersCount++
      }

      if (holdersCount !== depositors.length) {
        return false
      }

      return true
    }

    /**
     * Récupère les listes des marques associées
     * @param titles
     * @param renewal
     * @returns
     */
     getAssociatedBrands = (titles: Title[], renewal?: Renewal): AssociatedBrand[] => {
       const associatedBrands: AssociatedBrand[] = [...(renewal?.associatedBrands ?? [])]
       titles.forEach((title: Title) => {
         if (title.associatedBrands) {
           title.associatedBrands.forEach((associatedBrand: AssociatedBrand) => {
             const match = associatedBrand?.numNat?.match(/[0-9]{7,9}/g)
             if (match?.length && !associatedBrands.some((transaction: AssociatedBrand) => transaction?.numNat === associatedBrand?.numNat)) {
               associatedBrands.push({ numNat: match[0] })
             }
           })
         }
       })
       return associatedBrands
     }

  /**
   * Si le titre possède un modèle de marque textuel
   * @returns
   * @param title
   */
  isTitleWordType = (title?: Title): boolean => {
    return !!(title?.type && [BRAND_TYPE_SEMI_FIGURATIVE.value, BRAND_TYPE_WORD.value].includes(title?.type))
  }
}

export default new TitleService()
