import Message from './Message'
import { ETSAccountNavInterface } from '../interfaces/ETSAccountInterface'
/* global localStorage */

export const ACTION_ADD_AGENT = 'TO_ADD'
export const ACTION_EDIT_AGENT = 'TO_EDIT'
export const ACTION_INACTIVATE_AGENT = 'TO_INACTIVATE'

export const ACCESS_DEMAND_ITEM_CREDENTIALS: ETSAccountNavInterface = {
  label: Message.ets_account_request_credentials,
  breadcrumb: Message.breadcrumb_attachment_account_request_credentials,
  action: '',
  url: '/rattachements/code-acces',
  showConsentNotificationBlock: localStorage.getItem('ELECTRONIC_NOTIF') === 'true'
}

export const ACCESS_DEMAND_ITEM_ADD_AGENT: ETSAccountNavInterface = {
  label: Message.ets_account_request_new_agent,
  breadcrumb: Message.breadcrumb_attachment_new_agent,
  action: ACTION_ADD_AGENT,
  url: '/rattachements/nouveau-compte',
  showConsentNotificationBlock: localStorage.getItem('ELECTRONIC_NOTIF') === 'true'
}

export const ACCESS_DEMAND_ITEM_EDIT_AGENT: ETSAccountNavInterface = {
  label: Message.ets_account_request_update_agent_infos,
  breadcrumb: Message.breadcrumb_attachment_request_update_agent_infos,
  action: ACTION_EDIT_AGENT,
  url: '/rattachements/modification-compte',
  showConsentNotificationBlock: localStorage.getItem('ELECTRONIC_NOTIF') === 'true'
}

export const ACCESS_DEMAND_ITEM_DEACTIVATE_AGENT: ETSAccountNavInterface = {
  label: Message.ets_account_request_remove_agent,
  breadcrumb: Message.breadcrumb_attachment_request_remove_agent,
  action: ACTION_INACTIVATE_AGENT,
  url: '/rattachements/detachement-compte'
}

export const ACCESS_DEMAND_ITEM_TMC: ETSAccountNavInterface = {
  label: Message.ets_account_request_tmc,
  breadcrumb: Message.breadcrumb_attachment_tmc_request_credentials,
  action: ACTION_ADD_AGENT,
  url: '/rattachements/attachement-tmc',
  showConsentNotificationBlock: localStorage.getItem('ELECTRONIC_NOTIF') === 'true'
}

export const ACCESS_DEMAND_ITEM_ATTACH_PM: ETSAccountNavInterface = {
  label: Message.ets_account_request_attach_pm,
  breadcrumb: Message.breadcrumb_attachment_request_attach_pm,
  action: '',
  url: '/rattachements/attachement-pm',
  showConsentNotificationBlock: localStorage.getItem('ELECTRONIC_NOTIF') === 'true'
}

export const ACCESS_DEMAND_ITEM_ADD_HOLDER: ETSAccountNavInterface = {
  label: Message.ets_account_request_new_holder,
  breadcrumb: Message.breadcrumb_attachment_new_holder,
  action: ACTION_ADD_AGENT,
  url: '/rattachements/nouveau-titulaire',
  showConsentNotificationBlock: localStorage.getItem('ELECTRONIC_NOTIF') === 'true'
}

export const ACCESS_DEMAND_ITEMS = [
  ACCESS_DEMAND_ITEM_CREDENTIALS,
  ACCESS_DEMAND_ITEM_ADD_AGENT,
  ACCESS_DEMAND_ITEM_EDIT_AGENT,
  ACCESS_DEMAND_ITEM_DEACTIVATE_AGENT,
  ACCESS_DEMAND_ITEM_TMC,
  ACCESS_DEMAND_ITEM_ADD_HOLDER
]
