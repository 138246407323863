class FormatUtils {
  /**
   * Formatte un prix en euros avec deux décimales
   *
   * @param price un prix entier en centimes
   */
  formatToStringPrice = (price: number|null|undefined): string => {
    if (!price) {
      return ''
    }
    return (price / 100).toFixed(2).replace('.', ',') + ' €'
  }

  /**
   * Formatte un prix en centime à euro
   * @param cents
   * @returns
   */
  formatCentsToPrice = (cents: number): string|number => {
    if (!cents) {
      return ''
    }
    return cents / 100
  }

  /**
   * Formatte un prix en centime
   * @param price
   * @returns
   */
  formatPriceToCents = (price: number): string|number => {
    if (!price) {
      return ''
    }
    return price * 100
  }

  /**
   * Formatte un prix en francs suisse avec deux décimales
   *
   * @param price un prix entier en francs suisses
   */
  formatCHFToStringPrice = (price: number|null|undefined): string => {
    if (!price) {
      return ''
    }
    return price.toFixed(2).replace(',', '.') + ' CHF'
  }

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param decimalNumbers Number of decimal places to display.
   *
   * @return Formatted string.
   */
  formatFileSize (bytes: number, decimalNumbers = 1) {
    const thresh = 1000

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let u = -1
    const r = 10 ** decimalNumbers

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(decimalNumbers) + ' ' + units[u]
  }

  formatFileFormat = (fileName: string): string|undefined => fileName && fileName.indexOf('.') !== -1 ? fileName.split('.')[fileName.split('.').length - 1] : undefined
}

export default new FormatUtils()
