import { DOCUMENT_FORMATS, DOCUMENT_TYPES, isFilled } from '../../../src'

class DocumentService {
  /**
   * Récupérer le format d'un fichier'
   * @param format
   * @param url
   */
  getFileFormat = (format?: string, url?: string): string => {
    if (format && isFilled(format)) {
      return format
    } else if (url?.includes('/VIDEO')) {
      return DOCUMENT_FORMATS.MP4
    } else if (url?.includes('/SOUND')) {
      return DOCUMENT_FORMATS.MP3
    }
    return DOCUMENT_FORMATS.JPEG
  }

  /**
   * Récupérer le format du fichier
   * @param format
   */
  getFileTypeByFormat = (format: string): string => {
    switch (format) {
      case DOCUMENT_FORMATS.JPEG:
      case DOCUMENT_FORMATS.PNG:
        return DOCUMENT_TYPES.BRAND_LOGO
      case DOCUMENT_FORMATS.MP3:
        return DOCUMENT_TYPES.BRAND_AUDIO
      case DOCUMENT_FORMATS.MP4:
        return DOCUMENT_TYPES.BRAND_VIDEO
      default:
        return DOCUMENT_TYPES.BRAND
    }
  }

  /**
   * Récupérer le format du fichier de la marque
   * @param format
   */
  getFileExtension = (format: string): string => {
    switch (format) {
      case DOCUMENT_FORMATS.JPEG:
        return 'jpg'
      case DOCUMENT_FORMATS.PNG:
        return 'png'
      case DOCUMENT_FORMATS.MP3:
        return 'mp3'
      case DOCUMENT_FORMATS.MP4:
        return 'mp4'
      default:
        return 'gif'
    }
  }
}

export default new DocumentService()
