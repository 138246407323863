import React, { FC, ReactNode, useEffect, useState } from 'react'
import StepListItem, { StepListItemContent } from './StepListItem'
import { SwitchField, ModalComponent, SubmitButton } from '@inpi-marques/components'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeGuidedValue } from '../../store/transaction/transactionActions'
import TransactionService from '../../services/transaction/TransactionService'
import { FormattedMessage } from 'react-intl'
import StoreService from '../../services/StoreService'

interface StepListProps {
  title?: ReactNode,
  className?: string,
  textSwitch?: ReactNode,
  items: StepListItemContent[],
  idActive: string,
  onClickStep: (indexStepClicked: number) => void,
  goToStepWithoutValidation: (idNextStep: number) => void
}

const StepList: FC<StepListProps> = ({
  title = '',
  className = '',
  textSwitch = '',
  items,
  idActive,
  onClickStep,
  goToStepWithoutValidation
}) => {
  const valueSwitch = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode?.activeGuidedMode)
  const [switchDisabled, setSwitchDisabled] = useState(false)
  const transaction = useSelector((state : RootStateOrAny) => state.transaction)
  const [colorLabel, setColorLabel] = useState('active')
  const [showModalGuideMode, setShowModalGuideMode] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (transaction.id && switchDisabled) {
      TransactionService.updateTransaction(transaction.id, transaction)
        .finally(() => setSwitchDisabled(false))
    } else {
      setSwitchDisabled(false)
    }
  }, [valueSwitch, transaction.id])

  /**
   * Affiche les informations en fonction de l'état du bouton mode guidé
   */
  const switchGuide = async () => {
    setShowModalGuideMode(false)
    // Met à jour la couleur du label "mode guidé"
    if (valueSwitch) {
      setColorLabel('')
    } else {
      setColorLabel('active')
    }
    setSwitchDisabled(true)
    dispatch(storeGuidedValue())
  }

  const deleteAgent = async () => {
    await TransactionService.updateTransaction(transaction.id, {
      agent: {
        type: null
      }
    })
    StoreService.changeStore({ agent: null })

    await switchGuide()

    const currentItemIndex = items.findIndex((item: StepListItemContent) => item.id === idActive)
    goToStepWithoutValidation(items[currentItemIndex + 1].id)
  }

  const hasGuidedSwitch = items.find(item => item.id === idActive)?.hasGuidedSwitch

  // Compteur d'étape principale pour l'affichage. Les étapes principales sont celles qui ne possède pas de variable "level"
  let mainStepIndex = 1

  return (
    <div className={`step-list ${className}`}>
      <div className='d-lg-flex align-items-center justify-content-between'>
        <div className={`title p-0 mr-3 col-12${hasGuidedSwitch ? ' col-lg-6' : ''}`}>{title}</div>
        {hasGuidedSwitch &&
          <SwitchField
            disabled={switchDisabled}
            className='ml-lg-auto guidedMode'
            divClassName='flex-column-reverse'
            onChange={() => {
              if (idActive === 'contributors_agent' && transaction?.depositors?.length < 2 && !valueSwitch) {
                // Si on est sur l'étape mandataire et qu'on passe en mode guidé sans besoin de mandataire,
                // on demande confirmation avant de supprimer le mandataire et passer à l'étape suivante
                setShowModalGuideMode(true)
              } else {
                return switchGuide()
              }
            }}
            labelClassName={`guide ${colorLabel} mt-2`}
            label={textSwitch}
            value={valueSwitch}
            inputId='btnGuide'
          />}
      </div>
      <div className='list col-md-10 p-0'>
        {
          items.map((item, index) => {
            if (item.condition == null || item.condition) {
              let stepListClassName: string | undefined
              const previousItem: StepListItemContent|undefined = items[index - 1]
              const nextItem: StepListItemContent|undefined = items[index + 1]

              if (item.level && item.level !== previousItem?.level) {
                stepListClassName = 'mt-3'
              } else if (!nextItem?.level && item.level) {
                stepListClassName = 'last-level-item pb-3'
              }
              return (
                <StepListItem
                  {...item}
                  key={item.id}
                  displayedIndex={!item.level ? mainStepIndex++ : undefined}
                  index={index}
                  idActive={idActive}
                  onClick={onClickStep}
                  className={stepListClassName}
                />
              )
            } else {
              return null
            }
          })
        }
      </div>
      <ModalComponent
        title={<FormattedMessage id='switch_guided_mode_contributor' />}
        show={showModalGuideMode}
        customContent={() =>
          <>
            <FormattedMessage id='switch_guided_mode_contributor_text' />
            <div className='d-flex justify-content-around mt-4'>
              <SubmitButton className='btn-outline-primary' onClick={() => setShowModalGuideMode(false)}>
                <FormattedMessage id='button_cancel' />
              </SubmitButton>
              <SubmitButton className='btn-primary' onClick={deleteAgent}>
                <FormattedMessage id='common_validate' />
              </SubmitButton>
            </div>
          </>}
        handleClose={() => setShowModalGuideMode(false)}
        hideFooter
      />
    </div>
  )
}

export default StepList
