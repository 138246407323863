import React, { FC } from 'react'

import { EventType, FieldStatus, FRMI, Priority, SubmitButton } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import PriorityFRMIFields from './PriorityFRMIFields'

interface PriorityFRMIFormProps {
    // priorité actuelle à modifier et compléter
    priority?: Priority,
    // fonction pour modifier la priorité
    setPriority: (newPriority: Priority) => void,
    // informations concernant les erreurs sur le formulaire
    fieldStatus?: FieldStatus,
    //  index
    editIndex: number,
    isEditingMode: boolean,
    validate: () => void,
    cancel: () => void,
    frmiDetails: FRMI
}

const PriorityFRMIForm: FC<PriorityFRMIFormProps> = (
  {
    priority,
    setPriority,
    fieldStatus,
    editIndex,
    isEditingMode,
    validate,
    cancel,
    frmiDetails
  }) => {
  /**
     * Enregistrement des modifications de la priorité
     * @param event
     */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target
    const newPriority = {
      ...priority,
      [name]: value
    }
    setPriority(newPriority)
  }

  return (
    <>
      <h3>
        <FormattedMessage
          id='priority_label_number'
          values={{
            nb: editIndex + 1
          }}
        />
      </h3>

      <PriorityFRMIFields
        frmiDetails={frmiDetails}
        priority={priority}
        onChange={handleChanges}
        fieldStatus={fieldStatus}
        setPriority={setPriority}
      />
      <div className='row mt-4 justify-content-between'>
        <div className='col-4'>
          <button className='btn btn-block btn-outline-gris' onClick={cancel}>
            <FormattedMessage id='button_cancel' />
          </button>
        </div>
        <div className='col-4'>
          <SubmitButton className='btn btn-block bg-primary' onClick={validate}>
            <FormattedMessage id={`${(isEditingMode ? 'button_modify_priority_frmi' : 'button_add_priority_frmi')}`} />
          </SubmitButton>
        </div>
      </div>
    </>
  )
}

export default PriorityFRMIForm
