import { CardBlock, CheckboxField, FieldStatus, SubmitButton, Transaction, TransactionService } from '@inpi-marques/components'
import Message from 'constants/Message'
import { PAYMENT_METHODS } from 'constants/PaymentConstants'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaymentParameter } from 'services/payment/PaymentService'

interface PaymentFreeProps {
    transaction: Transaction,
    onPaymentSubmit: (paymentParameter: PaymentParameter) => Promise<void>
}

/**
 * Bloc du payment gratuit
 */
const PaymentFree: FC<PaymentFreeProps> = ({ transaction, onPaymentSubmit }) => {
  const isRevocationStatement: boolean = TransactionService.isRevocationStatement(transaction)
  const [askingFree, setAskingFree] = useState<boolean>(false)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()

  const onPaymentClick = async (): Promise<void> => {
    if (isRevocationStatement && !askingFree) {
      setFieldStatus({ askingFree: Message.required_field })
      return
    }
    setFieldStatus(undefined)

    await onPaymentSubmit({ method: PAYMENT_METHODS.FREE })
  }

  return (
    <div className='row mx-auto mt-3'>
      <div className='col-12'>
        <CardBlock shadow>
          {isRevocationStatement &&
            <CheckboxField
              className='mb-4'
              inputId='askingFree'
              onChange={() => setAskingFree(!askingFree)}
              checked={askingFree}
              label={<FormattedMessage id='payment_confirm_free_revocation_statement_asking_free_label' />}
              fieldStatus={fieldStatus}
            />}
          <FormattedMessage id={`payment_confirm_free_${transaction.procedureType?.toLowerCase()}`} />
          <div className='d-flex w-25 align-self-center mt-4 justify-content-center'>
            <SubmitButton
              className='btn-block btn-secondary'
              onClick={onPaymentClick}
            >
              <FormattedMessage id='button_confirm' />
            </SubmitButton>
          </div>
        </CardBlock>
      </div>
    </div>
  )
}

export default PaymentFree
