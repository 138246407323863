import React, { FC, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import {
  CardBlock,
  DEPOSIT_TYPE_DIVISION,
  DownloadLink,
  Dropdown,
  EditingModes,
  FilAriane,
  FRMI_TYPE_EXTENSION,
  ModalComponent,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_MIFR,
  PROCEDURE_NULLITY,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION,
  PROCEDURE_REVOCATION_STATEMENT,
  PROCEDURE_RENEWAL,
  References,
  SENDING_PORTAL,
  STATUS_DEPOSIT,
  STATUS_DIVISION,
  STATUS_DRAFT,
  STATUS_FRMI,
  STATUS_INSCRIPTION,
  STATUS_ND,
  STATUS_OPPOSITION,
  SubmitButton,
  Transaction,
  TransactionService as CommonTransactionService,
  TRANSFORMATION_TA_MODAL,
  Payment,
  STATUS_REVOCATION_STATEMENT,
  STATUS_OFFICIAL_DOCUMENT,
  NOTIFICATION_STATUS_TO_ANSWER,
  Notification
} from '@inpi-marques/components'
import TransactionService from '../../services/transaction/TransactionService'
import { BASKET_HEADER } from '../../constants/BasketConstants'
import BasketDetailsLayout from '../basket/BasketDetailsLayout'
import { BasketFilter, BasketInterface } from '../../interfaces/BasketInterface'
import { useLocation } from 'react-router'
import Fax from '../fax/Fax'
import Erratum from '../erratum/Erratum'
import Correction from '../erratum/Correction'
import CorrectionService from '../../services/correction/CorrectionService'
import { CORRECTION_MODAL, ERRATUM_MODAL } from '../../constants/DepositConstant'
import DepositOverview from '../deposit/overview/DepositOverview'
import OverviewNotifications from './notifications/OverviewNotifications'
import OppositionOverview from '../opposition/overview/OppositionOverview'
import Timeline from 'component/timeline/Timeline'
import ConfigurationService from '../../services/configuration/ConfigurationService'
import Message from '../../constants/Message'
import { RootStateOrAny, useSelector } from 'react-redux'
import InscriptionOverview from '../inscription/overview/InscriptionOverview'
import InscriptionService from '../../services/inscription/InscriptionService'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_TRANSFORMATION_TN_TA } from '../../constants/PaymentConstants'
import PaymentForm from '../payment/PaymentForm'
import { toast } from 'react-toastify'
import FRMIOverview from '../frmi/FRMIOverview'
import FRMIPostOperationOverview from 'component/frmiPostOperations/overview/FRMIPostOperationOverview'
import OverviewNotificationsOMPI from './notificationsOMPI/OverviewNotificationsOMPI'
import OfficialDocumentOverview from '../officialDocument/OfficialDocumentOverview'
import RevocationStatementOverview from 'component/revocationStatement/overview/RevocationStatementOverview'
import RenewalOverview from '../renewal/overview/RenewalOverview'
import DocumentService from 'services/document/DocumentService'

interface TransactionDetailsProps {
  basket: BasketInterface,
  id: string,
  getTransactionCountByHeader?: (filter?: BasketFilter) => any
}

const TransactionDetails: FC<TransactionDetailsProps> = ({
  basket,
  id,
  getTransactionCountByHeader
}) => {
  const history = useHistory()
  const location = useLocation()
  const currentUser = useSelector((state: RootStateOrAny) => state.user.user)
  const [isUserActive, setIsUserActive] = useState<boolean>(false)
  const [transaction, setTransaction] = useState<Transaction>()
  const [headerBasketSelected, setHeaderBasketSelected] = useState<BasketFilter>()
  const [showModal, setShowModal] = useState<string>('')
  const [showFax, setShowFax] = useState<boolean>()
  const intl = useIntl()
  const [editingModes, setEditingModes] = useState<EditingModes>()
  const [reload, setReload] = useState(false)
  const [correctionHasDocument, setCorrectionHasDocument] = useState<boolean>(false)

  const references: References = {
    productsAndServices: useRef(),
    documents: useRef(),
    notifications: useRef()
  }

  useEffect(() => {
    if (reload && transaction && transaction.id) {
      TransactionService.getTransaction(transaction.id)
        .then(setTransaction)
      getTransactionCountByHeader && getTransactionCountByHeader()
      setReload(false)
    }
  }, [reload])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const basketParam = queryParams.get('corbeille')
    setHeaderBasketSelected(BASKET_HEADER.find(header => header.id === basketParam) || BASKET_HEADER[0])
  }, [location.search])

  useEffect(() => {
    if (transaction?.notifications) {
      // Scroll vers le bloc P&S si le paiement vient d'échouer sur une demande de régularisation
      const queryParams = new URLSearchParams(location.search)
      const regularizationRetry = queryParams.get('regularisation')
      const notifToRetry = transaction?.notifications?.find((notification: Notification) => notification.status === NOTIFICATION_STATUS_TO_ANSWER.value && notification.regularizationAsked)
      if (regularizationRetry && notifToRetry) {
        scrollToReference('productsAndServices', notifToRetry.id)
      }
    }
  }, [transaction?.notifications, location.search, references?.productsAndServices?.current])

  useEffect(() => {
    TransactionService.getTransaction(id)
      .then(setTransaction)
      .catch(() => {
        history.replace(`/liste/${basket.urlParam}${headerBasketSelected ? `?corbeille=${headerBasketSelected.id}` : ''}`)
      })

    return () => TransactionService.cancelRequest()
  }, [id, basket])

  useEffect(() => {
    if (!transaction?.status) return
    !showFax && ConfigurationService.isFaxAvailable().then((value) => setShowFax(value))
  }, [transaction?.status])

  useEffect(() => {
    return () => {
      ConfigurationService.cancelRequest()
    }
  }, [])

  useEffect(() => {
    if ((transaction?.ETSAccounts?.filter(etsAccount => (etsAccount.email === currentUser.email || etsAccount.idCompany === currentUser.idCompany) && (etsAccount.state === 'ACTIVE' || etsAccount.state === 'TO_INACTIVATE' || etsAccount.state === 'TO_EDIT')).length > 0)) {
      setIsUserActive(true)
    }
  }, [transaction?.ETSAccounts])

  /**
   * Retourne sur le bon formulaire
   */
  const editTransaction = () => {
    switch (transaction?.procedureType) {
      case PROCEDURE_DEPOSIT.value:
        history.push(`/depots/${transaction?.id}/edition`)
        break
      case PROCEDURE_OPPOSITION.value:
        history.push(`/oppositions/${transaction?.id}/edition`)
        break
      case PROCEDURE_NULLITY.value:
        history.push(`/nullites/${transaction?.id}/edition`)
        break
      case PROCEDURE_REVOCATION.value:
        history.push(`/decheances/${transaction?.id}/edition`)
        break
      case PROCEDURE_DIVISION.value:
        history.push(`/divisions/${transaction?.id}/edition`)
        break
      case PROCEDURE_INSCRIPTION.value:
        if (CommonTransactionService.isRenunciation(transaction)) {
          history.push(`/renonciations/${transaction?.id}/edition`)
        } else {
          history.push(`/inscriptions/${transaction?.id}/edition`)
        }
        break
      case PROCEDURE_FRMI.value:
        if (transaction.subProcedureType === FRMI_TYPE_EXTENSION.value) {
          history.push(`/frmi/${transaction?.id}/edition`)
        } else {
          history.push(`/operations-posterieures/${transaction?.id}/edition`)
        }
        break
      case PROCEDURE_OFFICIAL_DOCUMENT.value:
        history.push(`/documents-officiels/${transaction?.id}/edition`)
        break
      case PROCEDURE_RENEWAL.value:
        history.push(`/renouvellement/${transaction?.id}/edition`)
        break
      case PROCEDURE_REVOCATION_STATEMENT.value:
        history.push(`/releves-de-decheance/${transaction?.id}/edition`)
        break
      default:
        break
    }
  }

  /**
   * Scroll jusqu'à la bonne référence
   * @param ref
   * @param id
   */
  const scrollToReference = (ref: string, id?: string|undefined): void => {
    if (references && references[ref]) {
      const refTop = references[ref].current?.getBoundingClientRect()?.top ?? 0
      window.scrollTo({
        top: refTop,
        behavior: 'smooth'
      })
    }
    setEditingModes({ [ref]: id || true })
  }

  /**
   * Ferme les modales
   */
  const closeModal = () => setShowModal('')

  const closeCorrectionModal = () => {
    if (correctionHasDocument) {
      toast.warning(intl.formatMessage({ id: 'correction_without_payment' }))
      setCorrectionHasDocument(false)
    }
    setShowModal('')
  }

  /**
   * Récupération des actions possibles en fonction du statut de la transaction et de son type de procedure
   */
  const getActions = () => {
    let items: ({ component: JSX.Element } | { label: string; value: string })[] = [{
      component: (
        <DownloadLink
          classname='m-0'
          label={<FormattedMessage id='payment_download' />}
          onClick={() =>
            DocumentService.downloadLastOverviewFile(transaction)}
        />)
    }]

    if (CorrectionService.canAskForCorrection(transaction)) {
      items = [...items, {
        component: (
          <SubmitButton
            key='correction_button' className='btn btn-link text-primary text-left w-100'
            onClick={() => setShowModal(CORRECTION_MODAL)}
          >
            <FormattedMessage id='overview_correction' />
          </SubmitButton>
        )
      }, {
        label: Message.overview_products_revocation,
        value: 'productsAndServices'
      }]
    }

    if (CorrectionService.canAskForErratum(transaction)) {
      items = [...items, {
        component: (
          <SubmitButton className='btn btn-link text-primary text-right' onClick={() => setShowModal(ERRATUM_MODAL)}>
            <FormattedMessage id='erratum_button' />
          </SubmitButton>
        )
      }]
    }

    if ([PROCEDURE_DEPOSIT.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value].includes(transaction.procedureType!)) {
      items = [...items, {
        label: Message.overview_documents,
        value: 'documents'
      }]
    }

    if (InscriptionService.canAskForTransformationTA(transaction)) {
      items = [...items, {
        component: (
          <SubmitButton className='btn btn-link text-primary text-right' onClick={() => setShowModal(TRANSFORMATION_TA_MODAL)}>
            <FormattedMessage id='request_transform_tn_ta_inscription' />
          </SubmitButton>
        )
      }]
    }

    return items
  }

  const inscriptionTransformationTaModalContent = () => (
    <div>
      <p><FormattedMessage id='request_transform_tn_ta_inscription_modal_content' /></p>
      <PaymentForm
        transaction={transaction}
        label={PAYMENT_LABELS.TRANSFORMATION_TN_TA}
        objectName={PAYMENT_OBJECTS.TRANSFORMATION_TN_TA}
        urlType={PAYMENT_URL_TRANSFORMATION_TN_TA}
        isTransformationTA
      />
    </div>
  )

  const getTransactionIdentifier = () : string => {
    if (transaction.numNat) {
      return transaction.numNat
    }

    const paymentVIR = transaction.payments?.find((payment: Payment) => payment.type === 'VIR')
    return paymentVIR ? paymentVIR.numnatVIR : transaction.id
  }

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        {
          headerBasketSelected && !headerBasketSelected.isHome &&
            <Link to={`/accueil?corbeille=${headerBasketSelected.id}`}>
              <FormattedMessage
                id={`home_filter_header_label_${headerBasketSelected.id}`}
              />
            </Link>
        }
        <Link
          to={`/liste/${basket.urlParam}${headerBasketSelected ? `?corbeille=${headerBasketSelected.id}` : ''}`}
        >
          <FormattedMessage
            id={`basket_label_${basket.id}`}
          />
        </Link>
        {transaction?.procedureType && (
          <span>
            <FormattedMessage id={`${transaction?.procedureType?.toLowerCase()}_title`} />
            {transaction && getTransactionIdentifier()}
          </span>
        )}
      </FilAriane>
      {isUserActive && transaction?.numNat && transaction.procedureType === PROCEDURE_DEPOSIT.value && transaction.subProcedureType !== DEPOSIT_TYPE_DIVISION.value && <Timeline transaction={transaction} />}
      {
        headerBasketSelected &&
          <BasketDetailsLayout idBasket={basket.id} headerBasketSelected={headerBasketSelected} getTransactionCountByHeader={getTransactionCountByHeader}>
            <>
              {
                transaction &&
                  <div>
                    <header className='mx-3 mb-4 d-flex justify-content-between'>
                      <h1>
                        {transaction.procedureType !== PROCEDURE_FRMI.value &&
                          <FormattedMessage
                            id={`${transaction?.procedureType?.toLowerCase()}_title`}
                          />}
                        {transaction.procedureType === PROCEDURE_FRMI.value &&
                          <FormattedMessage
                            id={`${transaction?.subProcedureType?.toLowerCase()}_title`}
                          />}
                        {getTransactionIdentifier()}
                      </h1>

                      {isUserActive &&
                        <>
                          <div className='d-flex justify-content-end'>
                            {
                              STATUS_DRAFT.includes(transaction?.status!) && (
                                <button
                                  className='btn btn-outline-primary mr-3'
                                  onClick={editTransaction}
                                >
                                  <FormattedMessage id='request_go_edit' />
                                </button>
                              )
                            }
                            {getActions().length === 1
                              ? getActions()[0].component
                              : (
                                <Dropdown
                                  className='nav-fo-primary align-right'
                                  items={getActions()}
                                  name={<FormattedMessage id='other_actions' />}
                                  buttonClassName='text-right text-primary'
                                  onSelect={(item) => scrollToReference(item.value)}
                                  arrow
                                />
                              )}
                          </div>
                        </>}
                    </header>

                    {/* Notifications */}
                    {isUserActive && (transaction && transaction.notifications && transaction.notifications
                      .filter((notification) => notification.sendingMode && notification.sendingMode.includes(SENDING_PORTAL)).length > 0) &&
                        <div className='col-12 mb-3' ref={references?.notifications}>
                          <CardBlock
                            header={<FormattedMessage id='overview_transaction_card_notification_title' />}
                            shadow
                          >
                            <OverviewNotifications
                              transaction={transaction}
                              setReload={() => setReload(true)}
                              scrollToProductAndService={(id: string) => scrollToReference('productsAndServices', id)}
                            />
                          </CardBlock>
                        </div>}

                    {/* Notifications OMPI */}
                    {isUserActive && transaction && transaction.notificationsOMPI && transaction.notificationsOMPI.length > 0 &&
                      <div className='col-12 mb-3' ref={references?.notificationsOMPI}>
                        <CardBlock
                          header={<FormattedMessage id='overview_transaction_card_notification_ompi_title' />}
                          shadow
                        >
                          <OverviewNotificationsOMPI
                            transaction={transaction}
                            setReload={() => setReload(true)}
                          />
                        </CardBlock>
                      </div>}

                    {
                      [PROCEDURE_DEPOSIT.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value].includes(transaction.procedureType!) &&
                        <DepositOverview
                          transaction={transaction}
                          showAddDocumentsButton={transaction.status !== STATUS_DEPOSIT.DRAFT &&
                            transaction.status !== STATUS_DIVISION.DRAFT &&
                            transaction.status !== STATUS_DEPOSIT.WAITING_VIR_PAYMENT &&
                            transaction.status !== STATUS_DIVISION.WAITING_VIR_PAYMENT}
                          setTransaction={setTransaction}
                          references={references}
                          editingModes={editingModes}
                          setEditingModes={setEditingModes}
                          isUserActive={isUserActive}
                          scrollToNotifications={(id: string) => scrollToReference('notifications', id)}
                          setReload={() => setReload(true)}
                        />
                    }
                    {
                      [PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value].includes(transaction.procedureType!) &&
                        <OppositionOverview
                          transaction={transaction}
                          showAddDocumentsButton={![STATUS_OPPOSITION.DRAFT, STATUS_ND.DRAFT].includes(transaction?.status!)}
                          setTransaction={setTransaction}
                          isUserActive={isUserActive}
                          setReload={() => setReload(true)}
                        />
                    }
                    {
                      [PROCEDURE_INSCRIPTION.value].includes(transaction.procedureType!) &&
                        <InscriptionOverview
                          transaction={transaction}
                          showAddDocumentsButton={transaction.status !== STATUS_INSCRIPTION.DRAFT}
                          setReload={() => setReload(true)}
                        />
                    }
                    {PROCEDURE_FRMI.value === transaction.procedureType &&
                      <div>
                        {transaction.subProcedureType === FRMI_TYPE_EXTENSION.value &&
                          <FRMIOverview transaction={transaction} isUserActive={isUserActive} showAddDocumentsButton={transaction.status !== STATUS_FRMI.DRAFT} setReload={() => setReload(true)} />}
                        {transaction.subProcedureType !== FRMI_TYPE_EXTENSION.value &&
                          <FRMIPostOperationOverview transaction={transaction} isUserActive={isUserActive} showAddDocumentsButton={transaction.status !== STATUS_FRMI.DRAFT} setReload={() => setReload(true)} />}
                      </div>}
                    {
                      PROCEDURE_OFFICIAL_DOCUMENT.value === transaction.procedureType &&
                        <OfficialDocumentOverview
                          transaction={transaction}
                          isUserActive={isUserActive}
                          showAddDocumentsButton={transaction.status !== STATUS_OFFICIAL_DOCUMENT.DRAFT}
                          references={references}
                          setReload={() => setReload(true)}
                        />
                    }

                    {
                      PROCEDURE_RENEWAL.value === transaction.procedureType &&
                        <RenewalOverview
                          transaction={transaction}
                          isUserActive={isUserActive}
                          showAddDocumentsButton={transaction.status !== STATUS_OFFICIAL_DOCUMENT.DRAFT}
                          references={references}
                          setReload={() => setReload(true)}
                        />
                    }

                    {CommonTransactionService.isRevocationStatement(transaction) &&
                      <RevocationStatementOverview
                        transaction={transaction}
                        isUserActive={isUserActive}
                        showAddDocumentsButton={transaction.status !== STATUS_REVOCATION_STATEMENT.DRAFT}
                        references={references}
                        setReload={() => setReload(true)}
                      />}

                    {isUserActive && showFax &&
                      <div className='col-12 mb-3'>
                        <Fax transaction={transaction} setTransaction={setTransaction} />
                      </div>}
                  </div>
              }
            </>
          </BasketDetailsLayout>
      }
      <ModalComponent
        size='lg'
        hideFooter
        title={<FormattedMessage id='overview_erratum' />}
        show={showModal === ERRATUM_MODAL}
        handleClose={closeModal}
        customContent={() =>
          <Erratum
            transaction={transaction}
            setTransaction={setTransaction}
            setShowModal={closeModal}
          />}
        validateLabel={intl.formatMessage({ id: 'common_send' })}
      />
      <ModalComponent
        size='xl'
        hideFooter
        title={<FormattedMessage id='overview_correction' />}
        show={showModal === CORRECTION_MODAL}
        handleClose={closeCorrectionModal}
        customContent={() => <Correction transaction={transaction} setCorrectionHasDocument={setCorrectionHasDocument} />}
        validateLabel={intl.formatMessage({ id: 'common_send' })}
      />
      <ModalComponent
        size='xl'
        title={<FormattedMessage id='request_transform_tn_ta_inscription_modal_title' />}
        show={showModal === TRANSFORMATION_TA_MODAL}
        customContent={inscriptionTransformationTaModalContent}
        handleClose={closeModal}
        hideFooter
      />
    </div>
  )
}

export default TransactionDetails
