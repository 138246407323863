import React, { FC } from 'react'

interface BadgeProps {
  className?: string,
}

const Badge: FC<BadgeProps> = ({
  className = '',
  children
}) => (
  <span className={`badge ${className}`}>
    {children}
  </span>
)

export default Badge
