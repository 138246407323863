import { COUNT_DEPOSIT_RELOAD, COUNT_DEPOSIT_RESET, COUNT_DEPOSIT_UPDATE, CountDepositAction } from './countDepositTypes'
import { TransactionCountByStatus } from '@inpi-marques/components'

export const storeCountDepositUpdate = (transactionsCounts: TransactionCountByStatus[]): CountDepositAction => ({
  type: COUNT_DEPOSIT_UPDATE,
  depositCounts: transactionsCounts
})

export const storeCountDepositReset = (): CountDepositAction => ({
  type: COUNT_DEPOSIT_RESET,
  reload: true
})

export const storeCountDepositReload = (reload: boolean): CountDepositAction => ({
  type: COUNT_DEPOSIT_RELOAD,
  reload: reload
})
