import React, { FC, ReactNode, useState } from 'react'
import { FieldProps } from '../FormInterfaces'
import ErrorField from '../errors/ErrorField'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { EventType } from 'src'
import { hasEmoticons, REGEX_EMOJI, REGEX_OTHERS } from '../../utils/validationUtils'
import { buildEventType, resetError as resetErrorUtils, resetStateError } from '../../utils/formUtils'
import Message from '../../constants/Message'

interface TextAreaProps extends FieldProps {
  required?: boolean,
  rows?: number,
  spellCheck?: boolean,
  maxLength?: number,
  labelClassName?: string,
  infoHelp?: string,
  subtitle?: ReactNode
}

const TextArea: FC<TextAreaProps> = ({
  inputId,
  label,
  value,
  labelClassName = '',
  className = '',
  fieldStatus = {},
  onChange,
  onBlur,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false,
  rows = 3,
  spellCheck = true,
  maxLength,
  classNameFormGroup = '',
  infoHelp,
  nameFieldStatus,
  resetError,
  dispatch,
  subtitle
}) => {
  const [internalError, setInternalError] = useState<string>()

  /**
   * On clean l'erreur du champs au changement de sa valeur.
   * @param event
   */
  const onInternalChange = (event: EventType): void => {
    /** On clean l'erreur du store au changement */
    if (dispatch && resetError) {
      resetErrorUtils(dispatch, resetError, fieldStatus, nameFieldStatus || inputId)
    } else if (resetError) {
      resetStateError(resetError, fieldStatus, nameFieldStatus || inputId)
    }

    /**
     * Empêche l'utilisateur d'ajouter des émojis dans les champs textes
     */
    const value: string = event.target.value
    if (hasEmoticons(value)) {
      setInternalError(Message.emoji_regex_error)
      onChange && onChange(buildEventType(inputId, value.replace(REGEX_EMOJI, '').replace(REGEX_OTHERS, '')))
    } else {
      setInternalError(undefined)
      onChange && onChange(event)
    }
  }

  return (
    <div className={`form-group ${classNameFormGroup}`}>
      {label &&
        <div className='d-flex align-items-center'>

          <label className={`form-label ${labelClassName}`} htmlFor={inputId}>
            {label}
            {required && <span className='text-danger'> *</span>}
            {subtitle}
          </label>
          {infoHelp &&
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={
                <Popover id='popover-positioned-bottom'>
                  <Popover.Content>
                    {infoHelp}
                  </Popover.Content>
                </Popover>
              }
              rootClose
              rootCloseEvent='click'
            >
              <span className='cursor-pointer ml-2 text-primary'>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            </OverlayTrigger>}
        </div>}
      <textarea
        id={inputId}
        name={inputId}
        className={`form-control ${className}`}
        placeholder={placeholder}
        onChange={onInternalChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        rows={rows}
        spellCheck={spellCheck}
        maxLength={maxLength}
      />

      <ErrorField message={fieldStatus[nameFieldStatus || inputId]} />
      {internalError && <ErrorField message={internalError} />}
    </div>
  )
}

export default TextArea
