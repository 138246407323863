/**
 * Fonction de pagination générique d'une collection
 * @param collection
 * @param nbItemsPerPage
 */
import { ROWS_10, ROWS_ALL_RESULT } from '../constants/TableConstant'
import { ORDER_DESC } from '../constants/RequestConstant'
import { TableOrderBy } from '../interfaces/ListTableInterfaces'

export const getPaginatedCollection = (collection?: any[], nbItemsPerPage: number = ROWS_10.value): any[][] => {
  if (!collection || !collection.length) {
    return []
  }

  if (nbItemsPerPage === ROWS_ALL_RESULT.value) {
    return [collection]
  }

  const paginatedCollection: any[][] = []

  let page: any[] = []
  collection.forEach((item: any, key: number) => {
    page.push(item)
    if ((page.length % nbItemsPerPage === 0) || (key + 1) === collection.length) {
      paginatedCollection.push(page)
      page = []
    }
  })

  return paginatedCollection
}

/**
 * Permet de trier une collection
 * @param collection
 * @param sortBy
 * @param isDate
 */
export const getSortedCollection = (collection: any[], sortBy?: TableOrderBy, isDate?: boolean): any[] => {
  if (!sortBy) {
    return collection
  }
  if (isDate) {
    collection.sort((a, b) => {
      return a[sortBy.field] !== undefined ? new Date(a[sortBy.field]) - new Date(b[sortBy.field]) : -1
    })
  } else {
    collection.sort((a, b) => {
      return a[sortBy.field] > b[sortBy.field] ? 1 : -1
    })
  }

  if (sortBy.order === ORDER_DESC) {
    collection.reverse()
  }

  return collection
}
