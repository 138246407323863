import { containsErrors, DOCUMENT_ADDITIONAL_TYPES, FieldStatus, TransactionDocument } from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class RenewalOptionsValidator {
  /**
     * Validation des documents.
     * Tout document enregistrés doit posséder un type
     * @param documents
     * @returns
     */
     validateDocument = (documents: TransactionDocument[]): FieldStatus => {
       let fieldStatus: FieldStatus = {}

       const optionsDocuments: TransactionDocument[] = documents.filter((document: TransactionDocument) => document.additionalType === DOCUMENT_ADDITIONAL_TYPES.RENEWAL_OPTIONS)
       if (optionsDocuments) {
         optionsDocuments.forEach((file: TransactionDocument, key: number) => {
           if (!file.type) {
             fieldStatus = {
               ...fieldStatus,
               // Correspond à l'inputId renseigner dans le composant DocumentBlockItem
               [`document_${file.additionalType?.toLocaleLowerCase()}_${key}_type`]: Message.required_field
             }
           }
         })
       }
       return fieldStatus
     }

    validateStep = (documents: TransactionDocument[]): boolean => {
      const newFieldStatus: FieldStatus = this.validateDocument(documents)
      store.dispatch(storeTransactionFieldStatusUpdate(newFieldStatus))
      return !containsErrors(newFieldStatus)
    }
}

export default new RenewalOptionsValidator()
