import React, { FC, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import {
  ModalComponent,
  PROCEDURE_FRMI,
  ProductAndService,
  ProductAndServiceListRow,
  ProductClass,
  Table,
  TableTitleItem
} from '@inpi-marques/components'
import Message from '../../src/constants/Message'
import ProductsService from '../services/Product/ProductsService'

interface ProductAndServiceListViewProps {
  productClasses: ProductClass[],
  onProductDelete?: (productClass: ProductClass, product: ProductAndService, index?: number) => void,
  onProductClassDelete?: (productClass: ProductClass, index?: number) => void,
  onProductClassUndoDelete?: (productClass: ProductClass, index?: number) => void,
  onProductUndoDelete?: (productClass: ProductClass, product: ProductAndService, index?: number) => void,
  readOnly?: boolean,
  onProductClassEdited?: (productClass: ProductClass, strProducts: string, index?: number) => Promise<void>,
  className?: string,
  setProductClassEditing?: (editing: boolean) => void,
  displayClassValidation?: boolean,
  procedureType?: string,
  isFRMIPriority?: boolean
}

/**
 * Listing des produits et services au format liste (avec un ';' entre chaques produits)
 *
 * Dans ce composant, il est possible de supprimer produit, supprimer / modifier une classe
 *
 * @param productClasses - Variable qui contient toutes les classes avec leurs produits et services
 * @param onProductDelete - Fonction qui supprime un produit d'une classe
 * @param onProductClassDelete - Fonction qui permet de supprimer une classe
 * @param onProductClassUndoDelete - Fonction qui permet de revenir en arrière après une suppression totale avec la corbeille
 * @param onProductUndoDelete
 * @param onProductClassEdited - Fonction qui permet de mettre à jour les informations éditer dans une classe
 * @param readOnly - Boolean si true, siginifie qu'on est en lecture seule
 * @param className - Variable qui contient des classes CSS
 * @param setProductClassEditing - Fonction qui permet de mettre à jour le boolean d'édition
 * @param displayClassValidation - Boolean pour la vérification des classes
 * @param procedureType - Le type de procédure enregistré dans la transaction
 * @param isFRMIPriority - Si true, signifie que l'on affiche les produits et service d'une priorité d'un FRMI
 * @constructor
 */
const ProductAndServiceListView: FC<ProductAndServiceListViewProps> = (
  {
    productClasses,
    onProductDelete,
    onProductClassDelete,
    onProductClassUndoDelete,
    onProductUndoDelete,
    onProductClassEdited,
    readOnly = false,
    className,
    setProductClassEditing,
    displayClassValidation = false,
    procedureType,
    isFRMIPriority = false
  }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedClass, setSelectedClass] = useState<ProductClass>()
  const [selectedClassIndex, setSelectedClassIndex] = useState<number>()

  const classHasOrigin: boolean = productClasses.some((productClass: ProductClass) => productClass.origin)

  const getTableTitle = () => {
    const tableTitle: TableTitleItem[] = []
    !readOnly && tableTitle.push({ label: '', className: 'w-15' })

    procedureType === PROCEDURE_FRMI.value && !isFRMIPriority && tableTitle.push({ label: Message.product_and_services_table_frmi_status })

    classHasOrigin && tableTitle.push({ label: Message.product_class_origin, className: 'w-10' })
    tableTitle.push({ label: Message.product_and_services_table_number, className: 'w-25' })
    tableTitle.push({ label: Message.product_and_services_table_ps, className: '' })

    return tableTitle
  }

  /**
   * Au clique sur la corbeille d'une classe
   * @param productClass
   * @param index
   */
  const onDeleteClassClick = (productClass: ProductClass, index?: number): void => {
    setSelectedClass(productClass)
    setSelectedClassIndex(index)
    setShowModal(true)
  }

  /**
   * Rollback d'une classe supprimée
   * @param productClass
   * @param indexToUndo
   */
  const onUndoDeleteClassClick = (productClass: ProductClass, indexToUndo?: number): void => {
    onProductClassUndoDelete && onProductClassUndoDelete(productClass, indexToUndo)
  }

  /**
     * Rollback d'un produit supprimé
     * @param productClass
     * @param product
     */
  const onUndoDeleteProductClick = (productClass: ProductClass, product: ProductAndService, index: number): void => {
    onProductUndoDelete && onProductUndoDelete(productClass, product, index)
  }

  /**
   * A la validation de la modal
   */
  const onModalSubmit = (): void => {
    selectedClass && onProductClassDelete && onProductClassDelete(selectedClass, selectedClassIndex)
    setSelectedClass(undefined)
    setSelectedClassIndex(undefined)
    setShowModal(false)
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <Table
        tableTitle={getTableTitle()}
        tableClassName={`products-and-services__table ${className}`}
      >
        <tbody className='fs-085'>
          {ProductsService.sortClasses(productClasses).map((productClass: ProductClass, index: number) =>
            <ProductAndServiceListRow
              productClass={productClass}
              productClassIndex={index}
              onDeleteClassClick={(productClass: ProductClass) => onDeleteClassClick(productClass, index)}
              onUndoDeleteClassClick={(productClass: ProductClass) => onUndoDeleteClassClick(productClass, index)}
              onUndoDeleteProductClick={(productClass: ProductClass, product: ProductAndService) => onUndoDeleteProductClick(productClass, product, index)}
              onEditValidated={onProductClassEdited}
              onProductDelete={(productClass: ProductClass, product: ProductAndService) => onProductDelete && onProductDelete(productClass, product, index)}
              readOnly={readOnly}
              key={index}
              setProductClassEditing={setProductClassEditing}
              displayClassValidation={displayClassValidation}
              procedureType={procedureType}
              displayOrigin={classHasOrigin}
            />
          )}
        </tbody>
      </Table>
      <ModalComponent
        title={<FormattedMessage id='product_and_service_delete_class_modal_title' />}
        show={showModal}
        customContent={() => <FormattedMessage id='product_and_service_delete_class_modal_text' />}
        handleClose={() => setShowModal(false)}
        onClick={() => onModalSubmit()}
      />
    </IntlProvider>
  )
}

export default ProductAndServiceListView
