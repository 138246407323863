import { createContext } from 'react'

/**
 * Contexte des permissions utilisateur
 */
export const PermissionContext = createContext<string[]>([])

/**
 * Fournisseur des permissions utilisateurs
 */
export const PermissionProvider = PermissionContext.Provider
