import React, { FC } from 'react'
import { EventType, FieldStatus, Seniority, SubmitButton, Transaction } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import SeniorityFields from './SeniorityFields'

interface SeniorityFormProps {
    transaction: Transaction,
    // ancienneté actuelle à modifier et compléter
    seniority?: Seniority,
    // fonction pour modifier l'ancienneté
    setSeniority: (newSeniority: Seniority) => void,
    // informations concernant les erreurs sur le formulaire
    fieldStatus?: FieldStatus,
    //  index
    editIndex: number,
    isEditingMode: boolean,
    validate: () => void,
    cancel: () => void
}

const SeniorityForm: FC<SeniorityFormProps> = (
  {
    transaction,
    seniority,
    setSeniority,
    fieldStatus,
    editIndex,
    isEditingMode,
    validate,
    cancel
  }) => {
  /**
     * Enregistrement des modifications de l'ancienneté
     * @param event
     */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target
    const newSeniority = {
      ...seniority,
      [name]: value
    }
    setSeniority(newSeniority)
  }

  return (
    <>
      <h3>
        <FormattedMessage
          id='seniority_label_number'
          values={{
            nb: editIndex + 1
          }}
        />
      </h3>
      <SeniorityFields
        frmiDetails={transaction.frmiDetails}
        seniority={seniority}
        onChange={handleChanges}
        fieldStatus={fieldStatus}
        setSeniority={setSeniority}
        subProcedureType={transaction.subProcedureType}
      />
      <div className='row mt-4 justify-content-between'>
        <div className='col-4'>
          <button className='btn btn-block btn-outline-gris' onClick={cancel}>
            <FormattedMessage id='button_cancel' />
          </button>
        </div>
        <div className='col-4'>
          <SubmitButton className='btn btn-block bg-primary' onClick={validate}>
            <FormattedMessage
              id={`${(isEditingMode ? 'button_modify_seniority' : 'button_add_seniority')}`}
            />
          </SubmitButton>
        </div>
      </div>
    </>
  )
}

export default SeniorityForm
