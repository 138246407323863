import http from '../../network/http-common'
import { toast } from 'react-toastify'
import { FoUserResponse } from '../../interfaces/User'

class FoUserService {
  /**
   * Mise à jour des adresses de correspondance d'un utilisateur front
   * @param id
   * @param emailList
   * @param consent
   */
  putFoUserCorrespondanceEmail = async (id: string, emailList: string[], consent: boolean): Promise<FoUserResponse> => {
    try {
      return await http.put(`/api/fo-users/${id}`, {
        correspondenceEmails: emailList,
        consentNotificationByEmailPreferences: consent
      })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Permet d'envoyer un mail à chaque mail de correspondance de l'utilisateur
   * pour confirmer que ce mail est bien ajouté
   * @param id
   */
  sendConfirmEmail = async (id: string): Promise<any> => {
    return await http.get(`/api/fo-users/${id}/confirm-correspondence-emails`)
      .catch((error) => Promise.reject(error))
  }
}

export default new FoUserService()
