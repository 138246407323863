import { Brand, EventType, FieldStatus, TextArea, Transaction } from '@inpi-marques/components'
import VariousIndicationsTranslation from 'component/frmi/form/variousIndications/components/VariousIndicationsTranslation'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'

interface PostDesignationVariousIndicationsProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

const PostDesignationVariousIndications: FC<PostDesignationVariousIndicationsProps> = ({ transaction, fieldStatus }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [colorsDescription, setColorsDescription] = useState<string>()
  const [voluntaryDescription, setVoluntaryDescription] = useState<string>()

  /**
     * Mise à jour de la marque
     * @param updatedBrand
     */
  const onBrandUpdated = (updatedBrand: Brand): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      brand: updatedBrand
    }))
  }

  return (
    <div className='colorsClaimed'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='select_frmi_various_indications_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_various_indications_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row col-md-12'>
        <TextArea
          inputId='colorsDescription'
          className='col-12'
          label={<FormattedMessage id='colors_claimed_description_label' />}
          value={transaction.frmiDetails?.brand?.colorsDescription}
          onChange={(event: EventType) => setColorsDescription(event.target.value)}
          onBlur={() => onBrandUpdated({ ...transaction.frmiDetails?.brand, colorsDescription })}
          fieldStatus={fieldStatus}
          infoHelp={intl.formatMessage({ id: 'colors_claimed_description_help_hover' })}
        />
        <VariousIndicationsTranslation brand={transaction.frmiDetails?.brand} fieldStatus={fieldStatus} onBrandUpdated={onBrandUpdated} />
      </div>
      <div className='mt-4'>
        <TextArea
          inputId='voluntaryDescription'
          label={<FormattedMessage id='various_indications_voluntary_description_label' />}
          value={transaction.frmiDetails?.brand?.voluntaryDescription}
          onChange={(event: EventType) => setVoluntaryDescription(event.target.value)}
          onBlur={() => onBrandUpdated({ ...transaction.frmiDetails?.brand, voluntaryDescription })}
          fieldStatus={fieldStatus}
        />
      </div>
    </div>

  )
}

export default PostDesignationVariousIndications
