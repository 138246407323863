import { TransactionCountByStatus } from '@inpi-marques/components'

export const COUNT_DEPOSIT_UPDATE = 'COUNT_DEPOSIT_UPDATE'
export const COUNT_DEPOSIT_RESET = 'COUNT_DEPOSIT_RESET'
export const COUNT_DEPOSIT_RELOAD = 'COUNT_DEPOSIT_RELOAD'

export interface CountDepositAction {
  type: string,
  depositCounts?: TransactionCountByStatus[],
  reload?: boolean
}
