import {
  EventType,
  TextField,
  DateField,
  FileBrowserField,
  FileButton,
  DOCUMENT_TYPES,
  TransactionDocument,
  FieldStatus,
  DateUtils,
  DATE_ISO,
  DOCUMENT_LIMITS,
  downloadFile
} from '@inpi-marques/components'
import { DOCUMENT_FORMATS } from '@inpi-marques/components/src/constants/DocumentConstants'
import { DepositTypeContentProps } from 'interfaces/Deposit'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import { storeTransactionUpdateDeposit } from '../../../../../store/transaction/transactionActions'
import TransactionService from '../../../../../services/transaction/TransactionService'
import DocumentService from '../../../../../services/document/DocumentService'

const EuropeanContent: FC<DepositTypeContentProps> = ({
  deposit,
  transaction,
  fieldStatus,
  onDocumentChanged,
  onDocumentDeleted,
  documents
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [europeanNumber, setEuropeanNumber] = useState<string>(deposit.numNatToTransformOrDivide ?? '')

  const onEuropeanNumberChanged = (event: EventType) => {
    const { value } = event.target
    setEuropeanNumber(value)
  }

  const onEuropeanNumberBlur = () => {
    dispatch(storeTransactionUpdateDeposit({ numNatToTransformOrDivide: europeanNumber }))
  }

  const onDepositDateChanged = (event: EventType) => {
    const { value } = event.target
    dispatch(storeTransactionUpdateDeposit({ createdAtToTransformOrDivide: DateUtils.formatToBeginOfDay(value) }))
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  const requestTranslationFile: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.TRANSFORMATION_REQUEST_FRENCH_TRANSLATION)
  const attachmentRequestTranslationFile: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.TRANSFORMATION_REQUEST_ATTACHMENT_TRANSLATION)

  return (
    <div className='european-content d-flex flex-wrap'>
      <div className='european-number__wrapper col-6 p-0'>
        <TextField
          inputId='european-number'
          classNameFormGroup=''
          className='col-6 p-0'
          required
          value={europeanNumber}
          label={<FormattedMessage id='deposit_type_european_number_label' />}
          placeholder={intl.formatMessage({ id: 'form_number_placeholder' })}
          onChange={onEuropeanNumberChanged}
          onBlur={onEuropeanNumberBlur}
          fieldStatus={fieldStatus}
          nameFieldStatus='europeanNumber'
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
        />
      </div>
      <div className='european-deposit-date__wrapper col-6 p-0'>
        <DateField
          inputId='european-deposit-date'
          required
          className='col-6 p-0'
          value={DateUtils.formateDateToFormat(deposit.createdAtToTransformOrDivide, DATE_ISO) ?? ''}
          label={<FormattedMessage id='deposit_type_european_date_label' />}
          placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
          onChange={onDepositDateChanged}
          fieldStatus={fieldStatus as FieldStatus}
          nameFieldStatus='europeanDepositDate'
          classNameLabel='col-12 p-0'
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
          maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) || undefined}
        />
      </div>

      <FileBrowserField
        inputId='european-request-translation'
        className='col-6 p-0'
        ulClassName='col-8'
        classNameButton='d-block one-line-ellipsis'
        label={<FormattedMessage id='deposit_type_european_request_translation' />}
        buttonLabel={<FileButton />}
        onChange={(event: EventType) => onDocumentChanged && onDocumentChanged(event, DOCUMENT_TYPES.TRANSFORMATION_REQUEST_FRENCH_TRANSLATION)}
        onDelete={onDocumentDeleted}
        maxNumberFile={1}
        maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
        value={requestTranslationFile && [requestTranslationFile]}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
        fieldStatus={fieldStatus}
        nameFieldStatus='europeanRequestFileSize'
        informationDoc
        handleDownload={handleDownload}
      />
      <FileBrowserField
        inputId='european-attachment-request-translation'
        className='col-6 p-0'
        ulClassName='col-8'
        classNameButton='d-block one-line-ellipsis'
        label={<FormattedMessage id='deposit_type_european_attachment_request_translation' />}
        buttonLabel={<FileButton />}
        onChange={(event: EventType) => onDocumentChanged && onDocumentChanged(event, DOCUMENT_TYPES.TRANSFORMATION_REQUEST_ATTACHMENT_TRANSLATION)}
        onDelete={onDocumentDeleted}
        maxNumberFile={1}
        value={attachmentRequestTranslationFile && [attachmentRequestTranslationFile]}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
        fieldStatus={fieldStatus}
        nameFieldStatus='europeanAttachmentRequestFileSize'
        informationDoc
        handleDownload={handleDownload}
      />
    </div>)
}

export default EuropeanContent
