import {
  DateField,
  DateUtils,
  DATE_ISO,
  DOCUMENT_FORMATS,
  DOCUMENT_TYPES,
  EventType,
  FileBrowserField,
  FileButton,
  FoundationService,
  OppositionFoundationTypeComponent,
  OPPOSITION_FOUNDATION_UNAUTHORIZED_OPPONENT_QUALITIES,
  TextField,
  SelectField,
  PROCEDURE_OPPOSITION
} from '@inpi-marques/components'
import React, { FC, useEffect } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import FoundationObject from '../../components/Object'
import Priority from '../../components/Priority'
import OpponentForm from '../brand/OpponentForm'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT DE DEPOT NON AUTORISE
 */
const FoundationUnauthorizedForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentAdded, onDocumentDeleted, documents, setFieldStatus, disableFiles, countries = [], addToRefs, procedureType, disableActiveField, handleDownload }) => {
  /**
   * On supprime le fichier de representation de signe, si c'est une marque verbale
   */
  useEffect(() => {
    const signCopyFiles = FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGN)

    if (signCopyFiles.length && foundation.entity?.isWord) {
      onDocumentDeleted && onDocumentDeleted(signCopyFiles[0])
    }
  }, [foundation.entity?.isWord])

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='row align-items-end'>
        <h2 className='text-primary col-12'>
          <FormattedMessage id='foundation_form_brand_part_label' />
        </h2>
        {/* TYPE DE MARQUE */}
        <SelectField
          addToRefs={addToRefs}
          classNameFormGroup='col-4'
          inputId='isWord'
          label={<FormattedMessage id='foundation_is_word_type_unauthorized_label' />}
          placeholder={Message.foundation_signs_type}
          onChange={(e) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'isWord', e.target.value === 'true'))}
          options={[
            {
              value: 'true',
              label: Message.brand_type_word_title
            },
            {
              value: 'false',
              label: Message.brand_type_other_title
            }
          ]}
          fieldStatus={fieldStatus}
          value={foundation.entity?.isWord}
          required
        />
        {/* REPRESENTATION DE LA MARQUE */}
        {!foundation.entity?.isWord &&
          <FileBrowserField
            addToRefs={addToRefs}
            className='col-4'
            inputId='sign_file'
            classNameButton='d-contents'
            buttonLabel={<FileButton />}
            label={<FormattedMessage id='foundation_sign_file_label' />}
            onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGN)}
            value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGN)}
            onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
            maxNumberFile={1}
            required
            fieldStatus={fieldStatus}
            resetError={setFieldStatus}
            informationDoc
            handleDownload={handleDownload}
          />}
        {/* Nom de la marque */}
        <TextField
          inputId='entity_name'
          label={<FormattedMessage id='foundation_brand_name_label' />}
          classNameFormGroup='col-4'
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'name', event.target.value))}
          value={foundation.entity?.name}
          required={!!foundation.entity?.isWord}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
        />
        {/* Territoire */}
        <TextField
          inputId='territory'
          label={<FormattedMessage id='foundation_territory_label' />}
          classNameFormGroup='col-4'
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'territory', event.target.value))}
          value={foundation.entity?.territory}
          required
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
        />
        {/* Numero de depot */}
        <TextField
          inputId='deposit-number'
          label={<FormattedMessage id='foundation_brand_number_label' />}
          classNameFormGroup='col-4'
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'depositNumber', event.target.value))}
          value={foundation.entity?.depositNumber}
        />
        {/* Date de dépôt */}
        <DateField
          classNameFormGroup='col-4'
          inputId='deposit-date'
          label={<FormattedMessage id='foundation_brand_created_date_label' />}
          value={DateUtils.formateDateToFormat(foundation.entity?.depositDate, DATE_ISO) ?? ''}
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'depositDate', DateUtils.formatToBeginOfDay(event.target.value)))}
        />

        {/* Numero d'enregistrement */}
        <TextField
          inputId='registering-number'
          label={<FormattedMessage id='foundation_international_brand_number_label' />}
          classNameFormGroup='col-4'
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'registeringNumber', event.target.value))}
          value={foundation.entity?.registeringNumber}
        />
        {/* Date d'enregistrement */}
        <DateField
          classNameFormGroup='col-4'
          inputId='registering-date'
          label={<FormattedMessage id='foundation_international_brand_created_date_label' />}
          value={DateUtils.formateDateToFormat(foundation.entity?.registeringDate, DATE_ISO) ?? ''}
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'registeringDate', DateUtils.formatToBeginOfDay(event.target.value)))}
        />
        {/* Date d'inscription */}
        <DateField
          classNameFormGroup='col-4'
          inputId='subscription_date'
          label={<FormattedMessage id='foundation_unauthorized_register_date_label' />}
          value={DateUtils.formateDateToFormat(foundation.entity?.inscriptionDate, DATE_ISO) ?? ''}
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'inscriptionDate', DateUtils.formatToBeginOfDay(event.target.value)))}
        />
        <Priority countries={countries} setFieldStatus={setFieldStatus} foundation={foundation} setFoundation={setFoundation} fieldStatus={fieldStatus} onDocumentAdded={onDocumentAdded} onDocumentDeleted={onDocumentDeleted} documents={documents} />
        <DateField
          classNameFormGroup='col-4'
          inputId='renewalRequestDate'
          label={<FormattedMessage id='foundation_brand_claimed_renewal_request_date_label' />}
          value={DateUtils.formateDateToFormat(foundation.entity?.renewalRequestDate, DATE_ISO) ?? ''}
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'renewalRequestDate', DateUtils.formatToBeginOfDay(event.target.value)))}
        />
        <DateField
          classNameFormGroup='col-4'
          inputId='renewalPublicationDate'
          label={<FormattedMessage id='foundation_brand_claimed_renewal_publication_date_label' />}
          value={DateUtils.formateDateToFormat(foundation.entity?.renewalPublicationDate, DATE_ISO) ?? ''}
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'renewalPublicationDate', DateUtils.formatToBeginOfDay(event.target.value)))}
        />

        <FoundationObject foundation={foundation} setFoundation={setFoundation} onDocumentAdded={onDocumentAdded} onDocumentDeleted={onDocumentDeleted} documents={documents} />

        {/* Document(s) justifiant de l'existence et de la portée du droit antérieur */}
        {!disableFiles &&
          <FileBrowserField
            className='col-4 mt-3'
            inputId='rightFile'
            classNameButton='d-contents'
            buttonLabel={<FileButton />}
            label={<FormattedMessage id='foundation_right_file_label' />}
            acceptApplication={DOCUMENT_FORMATS.PDF}
            onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.CONTESTED_BRAND_COPY_PERMISSION)}
            value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.CONTESTED_BRAND_COPY_PERMISSION)}
            onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
            required={procedureType !== PROCEDURE_OPPOSITION.value}
            fieldStatus={fieldStatus}
            resetError={setFieldStatus}
            informationDoc
            handleDownload={handleDownload}
          />}
      </div>
      {/* OPPOSANT */}
      <div className='row mt-2'>
        <h2 className='text-primary col-12'>
          <FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_form_opponent_part_label`} />
        </h2>
        <OpponentForm
          procedureType={procedureType}
          addToRefs={addToRefs}
          disableFiles={disableFiles}
          fieldStatus={fieldStatus}
          setFieldStatus={setFieldStatus}
          foundation={foundation}
          setFoundation={setFoundation}
          opponentQualities={OPPOSITION_FOUNDATION_UNAUTHORIZED_OPPONENT_QUALITIES}
          onDocumentAdded={onDocumentAdded}
          onDocumentDeleted={onDocumentDeleted}
          documents={documents}
          handleDownload={handleDownload}
        />
      </div>
      {/* FONDEMENT ACTIF */}
      <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
    </IntlProvider>
  )
}

export default FoundationUnauthorizedForm
