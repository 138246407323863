import React, { FC } from 'react'
import {
  Badge,
  PRODUCT_STATUS,
  ProductAndService,
  ProductClass
} from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

interface ProductsAndServicesReadOnlyProps {
    productClasses: ProductClass[]
}

/** Listing des produits et services au format liste (avec un ';' entre chaques produits)
 *
 * Ce composant sert de readonly, c'est-à-dire qu'il ne permet pas de modifier le contenu, simplement de lire le contenu de produits et services d'une classe.
 */
const ProductsAndServicesReadOnly: FC<ProductsAndServicesReadOnlyProps> = (
  {
    productClasses
  }) => {
  return (
    <tbody className='fs-085'>
      {productClasses.map((productClass: ProductClass) =>
        <tr key={`-${productClass.ref}`}>
          <td className='border-0 font-weight-bold'>
            {/* On wrap le texte s'il s'agit d'une ancienne classe (= liste des numéros de classes séparées par une ,) */}
            <Badge className={`${productClass.ref.indexOf(',') > -1 ? 'text-wrap ' : ''}`}>{productClass.ref}</Badge>
          </td>
          <td className='border-0'>
            {productClass.products.map((product: ProductAndService, key: number) => product.status === PRODUCT_STATUS.DELETED ? (
              <span key={key} className='text-secondary'>
                {product.name}
                <FontAwesomeIcon icon={faMinusCircle} />
              </span>)
              : (
                <span className={`product-name--deletable${product.status === PRODUCT_STATUS.ADDED ? ' text-primary' : ''} pr-1 pt-1 pb-1`} key={key}>
                  <span>
                    {product.name}
                  </span>
                  {product.status === PRODUCT_STATUS.ADDED && <FontAwesomeIcon className='text-primary' icon={faPlusCircle} />}
                  {product.separator ? product.separator : <>&nbsp;;&nbsp;</>}
                </span>)
            )}
          </td>
        </tr>
      )}
    </tbody>
  )
}

export default ProductsAndServicesReadOnly
