import {
  containsErrors,
  Contributor,
  CONTRIBUTOR_AGENT,
  CONTRIBUTOR_HOLDERS,
  CONTRIBUTOR_LICENSEE,
  CONTRIBUTOR_OLD_AGENTS,
  CONTRIBUTOR_OLD_HOLDERS,
  CONTRIBUTOR_OLD_LICENSEES,
  ContributorForm,
  ContributorsOptionsForm,
  ContributorValidator,
  Country,
  ErrorField,
  FieldStatus,
  FRMI_CONTRIBUTORS_OPTIONS,
  FRMI_TYPE_AGENT_EDITION,
  FRMI_TYPE_HOLDER_CHANGE,
  FRMI_TYPE_LICENCE_EDITION,
  FRMI_TYPE_LICENCE_INSCRIPTION,
  FRMI_TYPE_POST_DESIGNATION_REGISTER,
  ManageableQuality,
  SubmitButton,
  Transaction,
  TransactionService
} from '@inpi-marques/components'
import ModalContactError from 'component/addressesBook/ModalContactError'
import ModalContactList from 'component/addressesBook/ModalContactList'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import Message from 'constants/Message'
import { Contact } from 'interfaces/Contact'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ContactService from 'services/contact/ContactService'
import ContentService from 'services/content/ContentService'
import OpenDataRNCSService from 'services/content/OpenDataRNCSService'
import ContributorService from 'services/contributors/ContributorService'
import FrmiContributorService from 'services/contributors/FrmiContributorService'
import DepositService from 'services/deposit/DepositService'
import StoreService from 'services/StoreService'
import ContactUtils from 'utils/ContactUtils'
import FrmiContributorListForm from './FrmiContributorListForm'
import FrmiContributorValidator from './validator/FrmiContributorValidator'

interface FrmiContributorListProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    codes: string[],
    optionForm: ContributorsOptionsForm,
    contributorTypeToCopy: string[],
    contributorType: string,
    otherApplicantCodeToDuplicate?: string,
    setIsRequired: (value: boolean) => void
}

/**
 * Liste de contributeurs utilisée lors d'une demande d'opération postérieure.
 * Ce composant a été créé, car il peut y avoir plusieurs types de contributeur pour une même étape,
 * contrairement aux autres intervenants ou il y a une liste d'un seul type de contributeur
 */
const FrmiContributorList: FC<FrmiContributorListProps> = ({ children, transaction, fieldStatus, codes, contributorTypeToCopy, contributorType, optionForm, otherApplicantCodeToDuplicate, setIsRequired }) => {
  const user = useSelector((state: RootStateOrAny) => state.user.user)

  const [editIndex, setEditIndex] = useState<number>(-1)
  const [countries, setCountries] = useState<Country[]>([])
  const [stateFieldStatus, setStateFieldStatus] = useState<FieldStatus>(fieldStatus)
  const [editingContributor, setEditingContributor] = useState<Contributor>()
  const [showAddressBook, setShowAddressBook] = useState<boolean>(false)
  const [showContactError, setShowContactError] = useState<boolean>(false)
  const [contactIdToReplace, setContactIdToReplace] = useState<number>()
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>([])

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })
    ContentService.getManageableQualities(TransactionService.getAdministrableContentProcedureType(transaction)).then((response: ManageableQuality[]) => {
      setManageableQualities(response)
    })
  }, [])

  /**
   * A la modification de l'index
   * @param value
   */
  const updateEditIndex = (value: number): void => {
    if (value === -1) {
      setIsRequired(false)
    } else {
      setIsRequired(true)
    }
    setEditIndex(value)
  }

  /**
   * Ajout d'un intervenant
   */
  const addContributor = (code: string) => {
    let index: number = 0
    if (code === CONTRIBUTOR_HOLDERS.value && transaction.holders) {
      index = transaction.holders.length
    } else if (code !== CONTRIBUTOR_AGENT.value && transaction.otherApplicants) {
      index = transaction.otherApplicants.filter((applicant: Contributor) => applicant.code === code).length
    }

    // Lorsqu'il y a duplication, on set le state pour différencier les intervenants "modifiés" ou "ajoutés"
    const contributor: Contributor = { code, address: code === CONTRIBUTOR_OLD_HOLDERS.value ? undefined : { country: 'FR' }, state: otherApplicantCodeToDuplicate ? 'ADDED' : undefined, isOriginal: false }
    setEditingContributor(contributor)
    updateEditIndex(index)
  }

  const duplicateContributor = (duplicateIndex: number): void => {
    const contributorsToDuplicate: Contributor[] = transaction.otherApplicants?.filter((otherApplicant: Contributor) => otherApplicant.code === otherApplicantCodeToDuplicate) ?? []
    if (contributorsToDuplicate.length) {
      const contributorToDuplicate = contributorsToDuplicate[duplicateIndex]
      if (contributorToDuplicate) {
        setEditingContributor({ ...contributorToDuplicate, state: 'EDITED', isOriginal: false })
        updateEditIndex(contributorsToDuplicate.length)
      }
    }
  }

  /**
   * Suppression d'un intervenant
   * @param code
   * @param index
   */
  const deleteContributor = (code: string, index: number): void => {
    if (code === CONTRIBUTOR_AGENT.value) { // Mandataire
      StoreService.changeStore({ ...transaction, agent: undefined })
    } else if (code === CONTRIBUTOR_HOLDERS.value) { // Titulaire
      StoreService.changeStore({ ...transaction, holders: transaction.holders?.filter((_, holderIndex: number) => holderIndex !== index) })
    } else { // Preneur de licence && Titulaire / mandataire / preneur de licence actuel
      /**
       * Concernant les intervenants dans otherApplicants, on va également se fier à l'index en paramètre
       * L'index représente l'index de l'intervenant en fonction de son code.
       * Si index = 4 et le code = 'old_holders', c'est qu'il faut supprimer non pas le 5e 'otherApplicant',
       * mais le 5e 'old_holders' qui se trouve dans 'otherApplicants'
       */
      const updateOtherApplicants: Contributor[] = []
      let contributorCodeIndex = 0

      transaction.otherApplicants && transaction.otherApplicants.forEach((applicant: Contributor) => {
        if (applicant.code !== code || index !== contributorCodeIndex) {
          updateOtherApplicants.push(applicant)
        }
        if (applicant.code === code) {
          contributorCodeIndex++
        }
      })
      StoreService.changeStore({ ...transaction, otherApplicants: updateOtherApplicants })
    }
  }

  /**
   * Annule un ajout ou la modification d'un intervenant
   */
  const cancel = () => {
    setEditingContributor(undefined)
    updateEditIndex(-1)
  }
  /**
   * Valide l'intervenant
   */
  const validate = (code: string) => {
    const fieldStatusContributor = FrmiContributorValidator.validateContributor(editingContributor, transaction, editingContributor?.code ?? contributorType, false, transaction.frmiDetails?.countries, editIndex === 0)

    if (!containsErrors(fieldStatusContributor)) {
      if (code === CONTRIBUTOR_AGENT.value) { // Mandataire
        StoreService.changeStore({ ...transaction, agent: editingContributor })
      } else if (code === CONTRIBUTOR_HOLDERS.value) { // Titulaire
        const updatedHolders: Contributor[] = []
        let isInList = false
        transaction.holders && transaction.holders.forEach((holder: Contributor, holderIndex) => {
          if (editIndex === holderIndex) {
            editingContributor && updatedHolders.push(editingContributor)
            isInList = true
          } else {
            updatedHolders.push(holder)
          }
        })
        if (!isInList && editingContributor) {
          updatedHolders.push(editingContributor)
        }
        StoreService.changeStore({ ...transaction, holders: updatedHolders })
      } else { // Preneur de licence && Titulaire / Mandataire / Preneur de licence actuel
        /**
         * Concernant les intervenants dans otherApplicants, on va également se fier à l'index en paramètre
         * L'index représente l'index de l'intervenant en fonction de son code.
         * Si index = 4 et le code = 'old_holders', c'est qu'il faut supprimer non pas le 5e 'otherApplicant',
         * mais le 5e 'old_holders' qui se trouve dans 'otherApplicants'
         */
        const updateOtherApplicants: Contributor[] = []
        let contributorCodeIndex = 0
        let isInList = false
        transaction.otherApplicants && transaction.otherApplicants.forEach((applicant: Contributor) => {
          if (applicant.code === code) {
            if (editIndex === contributorCodeIndex) {
              editingContributor && updateOtherApplicants.push(editingContributor)
              isInList = true
            } else {
              updateOtherApplicants.push(applicant)
            }
            contributorCodeIndex++
          } else {
            updateOtherApplicants.push(applicant)
          }
        })
        if (!isInList && editingContributor) {
          updateOtherApplicants.push(editingContributor)
        }
        StoreService.changeStore({ ...transaction, otherApplicants: updateOtherApplicants })
      }
      setEditingContributor(undefined)
      setStateFieldStatus({})
      updateEditIndex(-1)
    } else {
      setStateFieldStatus(fieldStatusContributor)
    }
  }

  /**
   * Fonction à appeler pour l'ajout d'un intervenant au carnet d'adresses
   */
  const addContactAddressBook = (contributor?: Contributor) => {
    const contact: Contact | undefined = ContactUtils.mapToContact(contributor)
    ContactService.addContact(contact).then((response) => {
      if (response) {
        if ('errorCode' in response) {
          if (response.errorCode === ContactUtils.ERROR_CONTACT_ALREADY_EXIST) {
            // On récupère l'id du contact déjà existant
            setContactIdToReplace(response.data)
            setShowContactError(true)
          }
        } else {
          toast.success(Message.success_add_contact)
        }
      }
    })
  }

  const editContributor = (code: string, index: number): void => {
    updateEditIndex(index)

    if (code === CONTRIBUTOR_AGENT.value) { // Mandataire
      setEditingContributor(transaction.agent)
    } else if (code === CONTRIBUTOR_HOLDERS.value) { // Titulaire
      setEditingContributor(transaction.holders?.find((_, holderIndex: number) => holderIndex === index))
    } else { // Preneur de licence && Titulaire / Mandataire / Preneur de licence
      /**
       * Concernant les intervenants dans otherApplicants, on va également se fier à l'index en paramètre
       * L'index représente l'index de l'intervenant en fonction de son code.
       * Si index = 4 et le code = 'old_holders', c'est qu'il faut supprimer non pas le 5e 'otherApplicant',
       * mais le 5e 'old_holders' qui se trouve dans 'otherApplicants'
       */
      let contributorCodeIndex = 0
      transaction.otherApplicants && transaction.otherApplicants.forEach((applicant: Contributor) => {
        if (applicant.code === code) {
          if (index === contributorCodeIndex) {
            setEditingContributor(applicant)
          }
          contributorCodeIndex++
        }
      })
    }
  }

  /**
   * Affiche la liste d'intervenant
   */
  const renderListContributorOverview = () => (
    <>
      {codes.map((code: string, codeIndex: number) => {
        const contributors: Contributor[] = FrmiContributorService.getContributors(transaction, code)
        return contributors.length || (contributors.length === 0 && codeIndex === 0 && (!transaction.otherApplicants?.some(applicant => (applicant.code === CONTRIBUTOR_OLD_HOLDERS.value || applicant.code === CONTRIBUTOR_OLD_AGENTS.value)))) ? (
          <FrmiContributorListForm
            key={codeIndex}
            contributors={contributors}
            countries={countries}
            editContributor={(index: number) => editContributor(code, index)}
            deleteContributor={(index: number) => deleteContributor(code, index)}
            fromFrmiExtension={transaction.subProcedureType === FRMI_TYPE_POST_DESIGNATION_REGISTER.value || transaction.subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value ||
              transaction.subProcedureType === FRMI_TYPE_LICENCE_INSCRIPTION.value}
            code={code}
            contributorType={contributorType}
            otherApplicantCodeToDuplicate={otherApplicantCodeToDuplicate}
            duplicateContributor={(index: number) => duplicateContributor(index)}
            subProcedureType={transaction.subProcedureType}
          />
        ) : <></>
      })}
    </>
  )

  /**
   * Affiche le formulaire d'un intervenant
   */
  const renderFormOneContributor = () => {
    const code: string = editingContributor?.code ?? ''
    const updatedOptionForm = transaction?.subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value && code === CONTRIBUTOR_OLD_HOLDERS.value
      ? {
        ...FRMI_CONTRIBUTORS_OPTIONS.old_holders,
        hasOtherLanguageSelect: false
      } : optionForm
    return (
      <ContributorForm
        transaction={transaction}
        optionsForm={updatedOptionForm}
        contributorType={editingContributor?.code ?? ''}
        text={{
          title: (
            <h2 className='mb-0 '>
              <FormattedMessage
                id={`contributor_${code}_frmi_post_operation`}
              />
            </h2>
          ),
          subtitle: (
            <span className='small'>
              <FormattedMessage
                id={`contributor_${code}_frmi_post_operation_subtitle`}
                values={{
                  a: (...chunks: ((string) []) | []) => (
                    <a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
                  linebreak: <br />
                }}
              />
            </span>
          )
        }}
        contributor={editingContributor}
        contributorsToCopy={[...ContributorService.findAllDistinctContributors(transaction, contributorTypeToCopy)]}
        currentETSUser={ContributorService.buildContributorFromUser(user)}
        setContributor={(contributor: Contributor) => setEditingContributor({ ...contributor, code })}
        fieldStatus={stateFieldStatus}
        getCountries={ContentService.getCountries}
        fillContributorInfo={(siren: string) => OpenDataRNCSService.getContributorInfos(siren)}
        findListSirenByNamePromise={(name: string) => OpenDataRNCSService.findListSirenByName(name)}
        selectFromAddressBook={() => setShowAddressBook(true)}
        addContributorToAddressBook={addContactAddressBook}
        onDownloadClick={() => DepositService.getPowerExampleFile()}
        getLanguages={ContentService.getLanguages}
        manageableQualities={manageableQualities}
        isFirst={editIndex === 0}
        isAdded={editIndex === -1}
        isEdited={editIndex > -1}
      >
        <div className='row mt-4 justify-content-center step-buttons'>
          <SubmitButton className='bg-white text-gris py-3 px-4' onClick={cancel}>
            <FormattedMessage id='button_return' />
          </SubmitButton>
          <SubmitButton className='bg-primary py-3 px-4' onClick={() => validate(code)}>
            <FormattedMessage
              id={`contributor_save_${code}`}
            />
          </SubmitButton>
        </div>
      </ContributorForm>
    )
  }

  return (
    <div>
      <div>
        <div className='row mb-4 justify-content-between'>
          <header className='col-8'>
            <h1><FormattedMessage id='contributor_form_title' /></h1>
            <span className='subtitle'><FormattedMessage id={`contributor_form_subtitle_${transaction.procedureType}_OP`} /></span>
          </header>
          <InternalReferenceField
            transaction={transaction}
            className='col-3'
          />
        </div>
      </div>
      {
        editIndex === -1 ? renderListContributorOverview() : renderFormOneContributor()
      }

      {/* BOUTON D'AJOUT */}
      {editIndex === -1 &&
        <div className='row justify-content-center py-3 mb-4'>
          {codes.map((code: string, key: number) => {
            /** Pas de bouton d'ajout de Mandataire / Preneur de licence s'il y en a déjà un */
            if ((code === CONTRIBUTOR_AGENT.value && transaction.agent) ||
                (code === CONTRIBUTOR_LICENSEE.value && transaction.otherApplicants?.some((applicant: Contributor) => applicant.code === CONTRIBUTOR_LICENSEE.value)) ||
                (transaction.subProcedureType === FRMI_TYPE_LICENCE_EDITION.value && code === CONTRIBUTOR_OLD_LICENSEES.value && transaction.otherApplicants?.some((applicant: Contributor) => applicant.code === CONTRIBUTOR_OLD_LICENSEES.value && (applicant.state === 'EDITED' || applicant.state === 'ADDED')))) {
              return <></>
            }
            return (
              <button className='col-4 btn btn-outline-primary mb-3 mr-3' onClick={() => addContributor(code)} key={key}>
                <FormattedMessage
                  id={`contributor_${transaction.subProcedureType === FRMI_TYPE_AGENT_EDITION.value && code === CONTRIBUTOR_AGENT.value ? 'duplicate' : (transaction.subProcedureType === FRMI_TYPE_LICENCE_EDITION.value && code === CONTRIBUTOR_OLD_LICENSEES.value ? 'edit' : 'add')}_${code}`}
                />
              </button>)
          })}
        </div>}
      {editIndex === -1 && children}
      {editIndex === -1 && fieldStatus.contributor && (<div className='col-12'> <ErrorField message={fieldStatus.contributor} className='fade alert alert-danger show position-relative mt-4' /></div>)}

      <ModalContactList
        showModal={showAddressBook}
        setShowModal={setShowAddressBook}
        setSelectedContact={(contact: Contact) => {
          const newContributor = ContributorValidator.cleanCopyContributor(ContactUtils.mapToContributor(contact), contributorType, editingContributor, transaction.subProcedureType, editIndex === -1, editIndex > -1)
          setEditingContributor({ ...newContributor, code: editingContributor?.code })
        }}
      />
      <ModalContactError
        showModal={showContactError}
        setShowModal={setShowContactError}
        contact={ContactUtils.mapToContact(editingContributor)}
        contactIdToReplace={contactIdToReplace}
      />
    </div>
  )
}

export default FrmiContributorList
