import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import RevocationForm from './form/RevocationForm'
import Payment from '../payment/Payment'
import { PAYMENT_URL_REVOCATION } from '../../constants/PaymentConstants'

const RevocationRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <RevocationForm isNewTransaction />} />
    <Route path={`${match.url}/:id/edition`} component={RevocationForm} />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment transactionId={props.match.params.id} state={props.match.params.state} type={PAYMENT_URL_REVOCATION} />}
    />
  </Switch>
)

export default RevocationRoutes
