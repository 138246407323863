import React, { FC, ReactNode } from 'react'
import { CheckboxField, DecisionType, EventType, FRMI, FRMI_DECISION_TYPES, SelectOption, TextArea } from '@inpi-marques/components'
import { FRMI_DECISION_TYPE_PARTIAL_WITHDRAW, FRMI_DECISION_TYPE_TOTAL_WITHDROW } from '../constants/FRMIConstants'

interface DecisionTypesProps {
    frmi?: FRMI,
    onFRMIChanged: (frmi: FRMI) => void
}

/**
 * Composant permettant de choisir le type de décision de l'opération postérieure R22
 */
const DecisionTypes: FC<DecisionTypesProps> = ({ frmi, onFRMIChanged }) => {
  /**
   * A l'ajout / suppresion d'un type de décision
   * @param type
   */
  const onTypeChanged = (type: SelectOption): void => {
    const updatedFrmiDetails: FRMI = { ...frmi }

    if (!updatedFrmiDetails.decisionTypes?.length || !updatedFrmiDetails.decisionTypes.some((decisionType: DecisionType) => decisionType.code === type.value)) {
      const newDecisionType: DecisionType = { code: type.value as string, description: FRMI_DECISION_TYPES.find((decisionType: SelectOption) => decisionType.value === type.value)?.label as string }
      updatedFrmiDetails.decisionTypes = updatedFrmiDetails.decisionTypes ? [...updatedFrmiDetails.decisionTypes, newDecisionType] : [newDecisionType]
    } else {
      updatedFrmiDetails.decisionTypes = updatedFrmiDetails.decisionTypes.filter((decisionType: DecisionType) => decisionType.code !== type.value)
    }
    onFRMIChanged({ ...updatedFrmiDetails })
  }

  /**
   * A l'édition d'un texte d'une décision sélectionné
   * @param type
   * @param description
   */
  const onDecisionDescriptionChanged = (type: SelectOption, description: string): void => {
    onFRMIChanged({
      ...frmi,
      decisionTypes: frmi?.decisionTypes?.map((decisionType: DecisionType) =>
        decisionType.code !== type.value ? decisionType : { ...decisionType, description }
      ) ?? []
    })
  }

  /**
   * Récupération de la partie "label" de la checkbox
   * Certains label seront simplement des textes, d'autres seront des TextAreas
   * @param type
   * @returns
   */
  const getDecisionTypeOption = (type: SelectOption): ReactNode => {
    if (type.value !== FRMI_DECISION_TYPE_PARTIAL_WITHDRAW.value && type.value !== FRMI_DECISION_TYPE_TOTAL_WITHDROW.value) {
      return type.label
    } else {
      const frmiDecision: DecisionType|undefined = frmi?.decisionTypes?.find((decisionType: DecisionType) => decisionType.code === type.value)

      const decision: SelectOption|undefined = frmiDecision ? { value: frmiDecision.code ?? '', label: frmiDecision.description } : FRMI_DECISION_TYPES.find((decisionType: SelectOption) => type.value === decisionType.value)
      return (
        <TextArea
          inputId={`decision-${type.value}`}
          value={decision?.label}
          onChange={(event: EventType) => onDecisionDescriptionChanged(type, event.target.value)}
          disabled={!frmi?.decisionTypes?.some((decisionType: DecisionType) => decisionType.code === type.value)}
        />)
    }
  }

  return (
    <>
      {FRMI_DECISION_TYPES.map((type: SelectOption, key: number) =>
        <CheckboxField
          key={key}
          inputId={`decision_type_${type.value}`}
          label={getDecisionTypeOption(type)}
          labelClassName='col-6'
          onChange={() => onTypeChanged(type)}
          checked={frmi?.decisionTypes?.some((decisionType: DecisionType) => decisionType.code === type.value)}
          value={frmi?.decisionTypes?.some((decisionType: DecisionType) => decisionType.code === type.value)}
        />
      )}
    </>

  )
}

export default DecisionTypes
