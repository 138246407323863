import { FoundationService, OppositionFoundationTypeComponent, SwitchField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

const FoundationObject: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation }) => (
  <div className='row col-12 mt-4'>
    <h3 className='col-12 mb-4'><FormattedMessage id='foundation_brand_object_label' /></h3>
    <SwitchField
      divClassName='col-4'
      labelClassName='mr-3'
      label={<FormattedMessage id='foundation_brand_object_partial_assignment_label' />}
      inputId='partialAssignment'
      onChange={() => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'isPartialAssignment', !foundation.entity?.isPartialAssignment))}
      value={foundation.entity?.isPartialAssignment}
    />
    <SwitchField
      divClassName='col-4'
      labelClassName='mr-3'
      label={<FormattedMessage id='foundation_brand_object_limitation_label' />}
      inputId='limitation'
      onChange={() => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'isLimitation', !foundation.entity?.isLimitation))}
      value={foundation.entity?.isLimitation}
    />
    <SwitchField
      divClassName='col-4'
      labelClassName='mr-3'
      label={<FormattedMessage id='foundation_brand_object_renunciation_label' />}
      inputId='isRenunciation'
      onChange={() => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'isRenunciation', !foundation.entity?.isRenunciation))}
      value={foundation.entity?.isRenunciation}
    />
  </div>
)

export default FoundationObject
