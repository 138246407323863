import { EventType, ProductClass, TextArea } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ProductsService from 'services/deposit/ProductsService'

interface ProductsTextProps {
    productClass: ProductClass,
    index: number,
    onProductsEdit: (productClass: ProductClass) => void
}

const ProductsText: FC<ProductsTextProps> = ({ productClass, index, onProductsEdit }) => {
  const intl = useIntl()
  const [text, setText] = useState<string>(ProductsService.getProductsStringFromArray(productClass.products))

  useEffect(() => {
    setText(ProductsService.getProductsStringFromArray(productClass.products))
  }, [productClass])

  const onTextAreaBlur = (): void => {
    onProductsEdit({
      ...productClass,
      products: ProductsService.getProductsFromString(text)
    })
  }

  return (
    <TextArea
      inputId={`products-${index}`}
      value={text}
      onChange={(event: EventType) => setText(event.target.value)}
      onBlur={onTextAreaBlur}
      placeholder={intl.formatMessage({ id: 'division_products_text_placeholder' })}
    />)
}

export default ProductsText
