import { EventType, FRMI, TextArea, TextField } from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface InscriptionCorrectionContentProps {
    frmi?: FRMI,
    onFieldBlur: (field: string, value: string) => void
}

const InscriptionCorrectionContent: FC<InscriptionCorrectionContentProps> = ({ frmi, onFieldBlur }) => {
  const [ompiReferenceNumber, setOmpiReferenceNumber] = useState<string>(frmi?.ompiReferenceNumber ?? '')
  const [correctionDescription, setCorrectionDescription] = useState<string>(frmi?.correctionDescription ?? '')

  return (
    <div>
      {/* Numéro de référence OMPI */}
      <TextField
        classNameFormGroup='col-12'
        inputId='ompiReferenceNumber'
        value={ompiReferenceNumber}
        label={<FormattedMessage id='frmi_ompi_reference_number_label' />}
        onChange={(event: EventType) => setOmpiReferenceNumber(event.target.value)}
        onBlur={(event: EventType) => onFieldBlur(event.target.name, ompiReferenceNumber)}
      />
      {/* Description de la rectification demandée */}
      <TextArea
        classNameFormGroup='col-12'
        inputId='correctionDescription'
        value={correctionDescription}
        label={<FormattedMessage id='frmi_correction_decription_label' />}
        onChange={(event: EventType) => setCorrectionDescription(event.target.value)}
        onBlur={(event: EventType) => onFieldBlur(event.target.name, correctionDescription)}
      />
    </div>
  )
}

export default InscriptionCorrectionContent
