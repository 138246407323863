import React, { FC, useEffect, useState } from 'react'
import StoreService from '../../services/StoreService'
import {
  containsErrors,
  EventType,
  FieldStatus,
  Priority, Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import PriorityValidator from '../../services/options/PriorityValidator'
import PriorityListForm from './PriorityListForm'
import PriorityForm from './PriorityForm'
import TransactionService from '../../services/transaction/TransactionService'
import { FormattedMessage } from 'react-intl'

interface PrioritiesProps {
  transaction: Transaction,
  documents: TransactionDocument[],
  setDocuments: (documents: TransactionDocument[]) => void,
  onDocumentDeleted: (documentToDelete: TransactionDocument) => void,
  editIndex: number,
  setEditIndex: (index : number) => void,
  setIsRequired: (value: boolean) => void
}

const Priorities: FC<PrioritiesProps> = (
  {
    transaction,
    documents,
    setDocuments,
    onDocumentDeleted,
    editIndex,
    setEditIndex,
    setIsRequired
  }) => {
  const [isEditingMode, setEditingMode] = useState<boolean>(false)
  const [oldDocuments, setOldDocuments] = useState<TransactionDocument[]>(documents)
  const [priority, setPriority] = useState<Priority>()
  const [priorityList, setPriorityList] = useState<Priority[]>(transaction.deposit?.priorities || [])
  const [fieldStatus, setFieldStatus] = useState<FieldStatus[]>([])

  useEffect(() => {
    StoreService.changeStore({
      deposit: {
        ...transaction.deposit,
        priorities: priorityList
      }
    })
  }, [priorityList])

  useEffect(() => {
    if (editIndex === -1) {
      setIsRequired(false)
    } else {
      setIsRequired(true)
    }
  }, [editIndex])

  /**
   * Ajout d'une priorité
   */
  const addPriority = () => {
    setOldDocuments([...documents])
    const index = priorityList.length
    setEditIndex(index)
    setEditingMode(false)
  }

  /**
   * Valide la priorité
   */
  const validate = () => {
    const fieldStatusPriority = PriorityValidator.validatePriority(priority)
    const newFieldStatus = [...fieldStatus]
    newFieldStatus[editIndex] = fieldStatusPriority
    setFieldStatus(newFieldStatus)
    if (!containsErrors(fieldStatusPriority)) {
      const newList = [...priorityList]
      newList[editIndex] = { ...priority }
      setPriorityList(newList)
      setPriority({})
      setEditIndex(-1)
    }
  }

  /**
   * Annule un ajout ou la modification d'une priorité
   */
  const cancel = () => {
    setDocuments(oldDocuments)
    setFieldStatus(fieldStatus.filter((status, index) => index !== editIndex))
    setEditIndex(-1)
    setPriority({})
  }

  /**
   * Supprime une priorité de la liste
   * @param index
   */
  const deletePriority = (index: number) => {
    setDocuments(documents.filter((doc) => !priorityList[index].documentNames?.includes(doc?.internalName ?? doc.name)))
    const newPriorities = [...priorityList]
    newPriorities.splice(index, 1)
    setPriorityList(newPriorities)
    const updatedFieldStatus = [...fieldStatus]
    updatedFieldStatus.splice(index, 1)
    setFieldStatus(updatedFieldStatus)
  }

  const priorityOnDocumentChange = (event: EventType, typeEvent: string) => {
    const { value } = event.target

    // Suppression du message d'erreur
    const fieldStatusPriority = {
      ...fieldStatus[editIndex],
      priority_file: ''
    }
    const newFieldStatus = fieldStatus ? [...fieldStatus] : []
    newFieldStatus[editIndex] = fieldStatusPriority
    setFieldStatus(newFieldStatus)

    const newFile: TransactionDocument = {
      type: typeEvent,
      internalName: value.lastModified.toString(),
      name: value.name,
      format: value.type,
      file: value
    }

    return TransactionService.updateTransactionBDDFromStore(transaction, [], [...documents, newFile]).then(response => {
      const result: TransactionDocument[] = response?.documents?.slice(-1)[0]
      const priorityDocuments = priority?.documentNames?.filter((priorityDoc => response?.documents?.find((doc) => doc.internalName === priorityDoc))) ?? []
      const newPriority = {
        ...priority,
        documentNames: [...priorityDocuments, ...(result?.internalName ? [result.internalName] : [])]
      }

      setDocuments(response?.documents)

      setPriority(newPriority)
    })
  }

  const priorityOnDocumentDelete = (documentToDelete: TransactionDocument) => {
    onDocumentDeleted(documentToDelete)

    TransactionService.updateTransactionBDDFromStore(transaction, [], documents.filter((file: TransactionDocument) => file.internalName !== documentToDelete.internalName)).then(() => {
      const newPriority = {
        ...priority,
        documentNames: priority?.documentNames?.filter((docName) => docName !== documentToDelete.internalName)
      }
      setPriority(newPriority)
    })
  }

  /**
   * Affiche la liste des priorités
   */
  const renderListPriorityOverview = () => {
    return (
      <PriorityListForm
        priorityList={priorityList}
        editPriority={(index: number) => {
          setOldDocuments(documents)
          setEditIndex(index)
          setEditingMode(true)
          setPriority(priorityList[index])
        }}
        deletePriority={deletePriority}
        addPriority={addPriority}
      />
    )
  }

  /**
   * Affiche le formulaire d'une priorité
   */
  const renderFormOnePriority = () => {
    return (
      <PriorityForm
        transaction={transaction}
        priority={priority}
        setPriority={setPriority}
        editIndex={editIndex}
        isEditingMode={isEditingMode}
        fieldStatus={fieldStatus[editIndex]}
        onDocumentDeleted={priorityOnDocumentDelete}
        onDocumentChanged={priorityOnDocumentChange}
        documents={documents}
        validate={validate}
        cancel={cancel}
      />
    )
  }

  return (
    <div className='options'>
      <div className='mb-4'>
        <h1><FormattedMessage id='options_title' /></h1>
        <span className='subtitle'>
          <FormattedMessage
            id='options_description'
            values={{
              a: (...chunks: ((string) []) | []) => (
                <a
                  href={process.env.REACT_APP_URL_HELP} target='_blank'
                  rel='noopener noreferrer'
                >{chunks}
                </a>)
            }}
          />
        </span>
      </div>
      {editIndex === -1 ? renderListPriorityOverview() : renderFormOnePriority()}
    </div>
  )
}

export default Priorities
