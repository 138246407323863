import React, { ReactElement } from 'react'

/* global MutationObserver */

class StepButtonsUtils {
  /**
   * Permet d'afficher les boutons d'étapes fixes ou non fixes
   * Tout en bas de la liste des produits on les défixe pour qu'ils ne sortent pas
   * du conteneur
   */
  moveStepButtons = (parentRef: React.MutableRefObject<undefined>, stepButtonsRef: React.MutableRefObject<undefined>): void => {
    const products = parentRef.current
    const stepButtonsContainer = stepButtonsRef.current
    if (!stepButtonsContainer || !products) return

    const productsRect = products.getBoundingClientRect()

    // si le bas du conteneur est visible ou qu'il ne l'est pas mais qu'on est à la fin de la page on affiche les boutons
    // en position absolute
    if (stepButtonsContainer.classList.contains('fixed-bottom') &&
      !stepButtonsContainer.classList.contains('fixed-absolute') &&
      (this.hasReachedEndOfProductsContainer(productsRect) || this.hasReachedEndOfPage())
    ) {
      stepButtonsContainer.classList.remove('fixed-bottom')
      stepButtonsContainer.classList.add('fixed-absolute')
    } else {
      // si le bas du conteneur n'est pas visible et que l'on est pas à la fin de la page on affiche les boutons
      // en position fixed
      if (!stepButtonsContainer.classList.contains('fixed-bottom') &&
        !this.hasReachedEndOfProductsContainer(productsRect) && !this.hasReachedEndOfPage()) {
        stepButtonsContainer.classList.remove('fixed-absolute')
        stepButtonsContainer.classList.add('fixed-bottom')
      }
    }
  }

  /**
   * Permet de savoir si on a atteint la partie visible de bas du conteneur des produits et services
   * @param productsRect
   */
  hasReachedEndOfProductsContainer = (productsRect: DOMRect) => {
    return (productsRect.height - Math.abs(productsRect.bottom)) / productsRect.height > 0.7
  }

  /**
   * Permet de savoir si on a atteint la fin de la page
   */
  hasReachedEndOfPage = () => {
    return document.documentElement.scrollTop + document.documentElement.clientHeight > document.documentElement.scrollHeight * 0.9
  }

  /**
   * initialise les listeners et observers
   * @param parentRef
   * @param stepButtonsRef
   */
  initStepButtonsListener = (parentRef: React.MutableRefObject<undefined>, stepButtonsRef: React.MutableRefObject<undefined>): Object => {
    const move = () => {
      this.moveStepButtons(parentRef, stepButtonsRef)
    }
    window.addEventListener('scroll', move)
    move()

    const observer = new MutationObserver(move)
    observer.observe(parentRef.current, { childList: true, subtree: true, attributes: true })

    return { observer, move }
  }

  /**
   *
   * Permet de récupérer les step buttons
   *
   * @param children
   * @returns
   */
  getEditedChildren = (children: ReactElement): ReactElement[] => {
    return React.Children.map(children, (child: ReactElement) => {
      // On check si l'élément est valide ou non
      if (React.isValidElement(child)) {
        // On réassigne child afin de pas avoir à le caster à chaque utilisation
        const validatedChild: ReactElement = child
        const updatedChildProps: { children: ReactElement[], onClick?: () => void } = { children: this.getEditedChildren(validatedChild.props.children) }
        // On retourne le clone de l'enfant mis à jour (ou non)
        return React.cloneElement(child, updatedChildProps)
      }
      return child
    })
  }
}

export default new StepButtonsUtils()
