import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl'
import {
  DATE_ISO,
  DateField,
  DateUtils,
  EventType,
  InternationalExtension,
  SubmitButton,
  TextField,
  Transaction
} from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Message from '../../../../constants/Message'
import StoreService from '../../../../services/StoreService'
import FRMIService from '../../../../services/frmi/FRMIService'

interface InternationalExtensionsOptionsProps {
    transaction: Transaction,
    numNat?: string,
    isRenunciation?: boolean,
    titlesIds?: string[]
}

const InternationalExtensionsOptions: FC<InternationalExtensionsOptionsProps> = (
  {
    transaction,
    numNat,
    isRenunciation = false,
    titlesIds
  }) => {
  const intl = useIntl()
  const [stateInternationalExtensions, setStateInternationalExtensions] = useState<InternationalExtension[]>(transaction?.internationalExtensions || [{ numNat: undefined, arrivalDate: undefined, unmodified: false }])

  useEffect(() => {
    StoreService.changeStore(
      {
        ...transaction,
        internationalExtensions: stateInternationalExtensions
      }
    )
  }, [stateInternationalExtensions])

  useEffect(() => {
    if (transaction?.internationalExtensions?.length === 0) {
        transaction?.id && titlesIds && FRMIService.getFRMIForRule22(transaction?.id, titlesIds).then((response: Transaction[]) => {
        const initInternationalExtensions = response.map(frmi => { return { numNat: frmi.numNat, arrivalDate: frmi.arrivalDate, unmodified: true } })
        setStateInternationalExtensions(initInternationalExtensions)
      })
    }
  }, [])

  const updatedOneInternationalExtension = (name: string, value: string, index: number) => {
    const updatedInternationalExtensionsOptions = stateInternationalExtensions ? [...stateInternationalExtensions] : []
    if (stateInternationalExtensions.length > index) {
      updatedInternationalExtensionsOptions[index] = { ...updatedInternationalExtensionsOptions[index], [name]: value, unmodified: false }
    }

    setStateInternationalExtensions(updatedInternationalExtensionsOptions)
  }

  const deleteOneInternationalExtension = (index: number) => {
    const updatedInternationalExtensionsOptions = stateInternationalExtensions ? [...stateInternationalExtensions] : []
    if (stateInternationalExtensions.length > index) {
      updatedInternationalExtensionsOptions.splice(index, 1)
    }

    setStateInternationalExtensions(updatedInternationalExtensionsOptions)
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='mt-3 pb-4'>
        <h1 className='pb-4'><FormattedMessage id='options_international_extensions_title' /></h1>
        {stateInternationalExtensions?.map((internationalExtension: InternationalExtension, index: number) => (
          <div key={`internationalExtension-${index}`} className='row m-0'>
            <TextField
              label={<FormattedMessage id='request_identification_numnat_international' />}
              inputId={`internationalExtensionNumNat-${index}`}
              value={internationalExtension.numNat}
              onChange={(e: EventType) => updatedOneInternationalExtension('numNat', e.target.value, index)}
              classNameFormGroup='mr-3'
            />
            <DateField
              inputId={`internationalExtensionArrivalDate-${index}`}
              label={<FormattedMessage id='request_identification_date_international' />}
              value={DateUtils.formateDateToFormat(internationalExtension.arrivalDate, DATE_ISO)}
              onChange={(e:EventType) => updatedOneInternationalExtension('arrivalDate', DateUtils.formatToBeginOfDay(e.target.value), index)}
            />
            <div className='d-flex flex-column justify-content-center mb-3 ml-3 mt-4'>
              <FontAwesomeIcon
                icon={faTrash}
                className='text-secondary cursor-pointer'
                onClick={() => deleteOneInternationalExtension(index)}
              />
            </div>
            {internationalExtension.arrivalDate && DateUtils.isBefore(DateUtils.subtractYearsAndFormat(DateUtils.now(), 5), internationalExtension.arrivalDate) &&
              <div className='text-warning'>
                <FormattedMessage
                  id='options_international_extension_text_warning'
                  values={{
                    nature: isRenunciation ? 'renonciation' : intl.formatMessage({ id: `home_button_${transaction.procedureType?.toLowerCase()}` }).toLowerCase(),
                    numNat: numNat,
                    numNatInternational: internationalExtension.numNat,
                    arrivalDateInternational: DateUtils.formatDateFr(internationalExtension.arrivalDate)
                  }}
                />
              </div>}
          </div>
        ))}
        <div>
          <SubmitButton
            className='btn-link-primary'
            onClick={() => setStateInternationalExtensions(stateInternationalExtensions
              ? [...stateInternationalExtensions,
                {
                  numNat: '',
                  arrivalDate: undefined
                }]
              : [{
                numNat: '',
                arrivalDate: undefined
              }])}
          >
            <FormattedMessage id='request_identification_add_international_extension' />
          </SubmitButton>
        </div>
      </div>
    </IntlProvider>
  )
}

export default InternationalExtensionsOptions
