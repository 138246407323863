export const STATUS_OFFICIAL_DOCUMENT = {
  /** Brouillon **/
  DRAFT_CLIENT: 'draft_client_official_document',
  /** En cours de création **/
  DRAFT: 'draft_official_document',
  /** En attente d'un mémoire administratif **/
  WAITING_VIR_PAYMENT: 'waiting_vir_payment_official_document',
  /** Mémoire administratif expiré **/
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired_official_document',
  /** A attribuer **/
  TO_ATTRIBUTE: 'to_attribute_official_document',
  /** A examiner */
  TO_REVIEW: 'to_review_official_document',
  /** Suspendu */
  SUSPENDED: 'suspended_official_document',
  /** A rejeter */
  TO_REJECT: 'to_reject_official_document',
  /** Rejeté */
  REJECTED: 'rejected_official_document',
  /** Traité */
  PROCESSED: 'processed_official_document'
}

export const STATUS_OFFICIAL_DOCUMENT_REVIEWING = [
  STATUS_OFFICIAL_DOCUMENT.TO_ATTRIBUTE,
  STATUS_OFFICIAL_DOCUMENT.TO_REVIEW,
  STATUS_OFFICIAL_DOCUMENT.TO_REJECT,
  STATUS_OFFICIAL_DOCUMENT.SUSPENDED
]

export const STATUS_OFFICIAL_DOCUMENT_FINAL: string[] = [
  STATUS_OFFICIAL_DOCUMENT.REJECTED,
  STATUS_OFFICIAL_DOCUMENT.PROCESSED
]

/** Type de document officiel */

// Code du type de document officiel "Copie officielle d'une marque"
export const OFFICIAL_COPY_BRAND = 'OFFICIAL_COPY_BRAND'

// Code du type de document officiel "Certificat d'identité de marque"
export const CERTIFICATE_OF_IDENTITY_BRAND = 'CERTIFICATE_OF_IDENTITY_BRAND'

// Code du type de document officiel "Certificat d'identité de marque avec état des inscriptions au registre national des marques"
export const CERTIFICATE_OF_IDENTITY_BRAND_AND_STATUS_INSCRIPTION = 'CERTIFICATE_OF_IDENTITY_BRAND_AND_STATUS_INSCRIPTION'

// Code du type de document officiel "Etat des inscriptions au registre national des marques"
export const STATUS_OF_INSCRIPTION = 'STATUS_INSCRIPTION'

// Code du type de document officiel "Copie de dossier de dépôt de marque"
export const COPY_OF_DEPOSIT = 'COPY_OF_DEPOSIT'

// Code du type de document officiel "Copie de dossier d'inscription de marque"
export const COPY_OF_INSCRIPTION = 'COPY_OF_INSCRIPTION'

// Code du type de document officiel "Copie de dossier de renouvellement de marque"
export const COPY_OF_RENEWAL = 'COPY_OF_RENEWAL'

// Code du type de document officiel "Copie de dossier d'opposition de marque"
export const COPY_OF_OPPOSITION = 'COPY_OF_OPPOSITION'

// Code du type de document officiel "Copie de dossier de nullité de marque"
export const COPY_OF_NULLITY = 'COPY_OF_NULLITY'

// Code du type de document officiel "Copie de dossier de déchéance de marque"
export const COPY_OF_REVOCATION = 'COPY_OF_REVOCATION'

// Code du type de document officiel "Copie de certificat de renouvellement"
export const COPY_OF_RENEWAL_CERTIFICATE = 'COPY_OF_RENEWAL_CERTIFICATE'

export const OFFICIAL_DOCUMENT_TYPE_COPY_TRANSACTION = [
  COPY_OF_INSCRIPTION,
  COPY_OF_RENEWAL,
  COPY_OF_OPPOSITION,
  COPY_OF_NULLITY,
  COPY_OF_REVOCATION
]

export const OFFICIAL_DOCUMENT_TYPE_COPY_TITLE = [
  OFFICIAL_COPY_BRAND,
  CERTIFICATE_OF_IDENTITY_BRAND,
  STATUS_OF_INSCRIPTION,
  COPY_OF_DEPOSIT,
  CERTIFICATE_OF_IDENTITY_BRAND_AND_STATUS_INSCRIPTION,
  COPY_OF_RENEWAL_CERTIFICATE
]
