import React, { FC, useEffect, useState } from 'react'
import { ErrorField, FieldStatus, ReplacementTitle, SubmitButton, Transaction } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import ReplacementTitlesTable from './ReplacementTitlesTable'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'

interface ReplacementTitlesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

const ReplacementTitles: FC<ReplacementTitlesProps> = ({ transaction, fieldStatus }) => {
  const dispatch = useDispatch()

  const [replacementTitles, setReplacementTitles] = useState<ReplacementTitle[]>(transaction.frmiDetails?.replacementTitles ?? [])

  useEffect(() => {
    setReplacementTitles(transaction.frmiDetails?.replacementTitles ?? [])
  }, [transaction.frmiDetails?.replacementTitles])

  /**
     * Au clique sur le bouton d'ajout
     */
  const onAddButtonClick = (): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      replacementTitles: [{ selected: true, isNew: true }, ...replacementTitles]
    }))
  }

  /**
     * A l'édition d'un titre
     * @param editedTitle
     * @param index
     */
  const onTitleChanged = (editedTitle: ReplacementTitle, index: number) => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      replacementTitles: replacementTitles.map((title: ReplacementTitle, key: number) => key === index ? editedTitle : title)
    }))
  }

  /**
     * A la suppression d'un titre
     * @param removedIndex
     */
  const onTitleRemoved = (removedIndex: number): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      replacementTitles: replacementTitles.filter((_, index: number) => index !== removedIndex)
    }))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_replacement_titles_subtitle' /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div>
        {!replacementTitles.some((title: ReplacementTitle) => title.selected) &&
          <SubmitButton onClick={onAddButtonClick} className='btn-primary'><FormattedMessage id='frmi_replacement_titles_add_button' /></SubmitButton>}
        {replacementTitles.length > 0 &&
          <ReplacementTitlesTable titles={replacementTitles} onTitleChanged={onTitleChanged} onTitleRemoved={onTitleRemoved} />}
      </div>
      {fieldStatus.replacementTitles && <ErrorField message={fieldStatus.replacementTitles} />}
    </>
  )
}

export default ReplacementTitles
