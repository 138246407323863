import { UserProfile } from '../interfaces/User'
import { EXCLUDED_ROLES } from '../constants/UsersConstants'

class UserUtils {
  formatProfile = (profile? : UserProfile[]) => {
    const newProfile = (profile && profile
      .filter(r => !EXCLUDED_ROLES.includes(
        r.name))) || []

    return (newProfile.map(r => r.label).join(', '))
  }
}

export default new UserUtils()
