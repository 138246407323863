import React, { FC, useEffect, useState } from 'react'
import {
  Country,
  EventType,
  FieldStatus,
  SubmitButton,
  Table,
  InlineTextField,
  ModalComponent, FileBrowserField, DOCUMENT_FORMATS, FileButton, computeOrder, TableOrderBy,
  DownloadLink
} from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Message from '../../constants/Message'
import OverviewAddress from './OverviewAddress'
import { Contact, ErrorContactResponse, ImportContactResponse } from '../../interfaces/Contact'
import ContentService from '../../services/content/ContentService'
import ContactService from '../../services/contact/ContactService'
import { RouteComponentProps, withRouter } from 'react-router'
import { toast } from 'react-toastify'

interface AddressListProps extends RouteComponentProps {
  toReload?: boolean,
  canEdit: boolean,
  onContactSelect?: (contact: Contact) => void,
  setToReload?: (toReload: boolean) => void,
  setAddressBookFile?: (addressBookFile?: File) => void
  setShowModalError?: (showModalErro: boolean) => void
}

const AddressList: FC<AddressListProps> = ({
  history,
  toReload,
  canEdit,
  onContactSelect,
  setToReload,
  setAddressBookFile,
  setShowModalError
}) => {
  const intl = useIntl()
  const [contactList, setContactList] = useState<Contact[]>([])
  const [countries, setCountries] = useState<Country[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [totalSize, setTotalSize] = useState<number>()
  const [totalPage, setTotalPage] = useState<number>()
  const [actualPage, setActualPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(1)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)
  const [deleteIndex, setDeleteIndex] = useState<number>(-1)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus[]>([])
  const [sortBy, setSortBy] = useState<TableOrderBy|undefined>()

  useEffect(() => {
    let unmounted = false
    if (countries.length === 0) {
      ContentService.getCountries().then(result => {
        if (!unmounted && result) {
          setCountries(result)
        }
      })
    }
    return () => {
      unmounted = true
    }
  }, [])

  /**
   * Mise à jour de la liste des contacts
   */
  useEffect(() => {
    getContacts(actualPage)
  }, [actualPage, toReload])

  /**
   * Récupération de la liste des contacts
   * @param pageIndex
   * @param onDelete
   * @param sortByName
   */
  const getContacts = (pageIndex: number, onDelete?: boolean, sortByName?: string) => {
    ContactService.getContacts(pageIndex, '', sortByName).then((response) => {
      setContactList(response.contacts)
      setTotalPage(response.maxPage)
      setTotalSize(response.totalSize)
      setPageSize(response.pageSize)
      // Si le dernier élément de la page a été supprimé, on reviens à la page précédente
      if (onDelete && actualPage > 1 && response.maxPage < actualPage) {
        setActualPage(actualPage - 1)
      }
    })
  }

  /**
   * Recherche fulltext dans le carnet d'adresses
   * @param value
   */
  const onSearchContact = async (value : string) : Promise<void> => {
    ContactService.getContacts(1, value).then((response) => {
      setContactList(response.contacts)
      setTotalPage(response.maxPage)
      setTotalSize(response.totalSize)
      setActualPage(1)
    })
  }

  /**
   * Au changement de page, on set le nouveau index de la page à afficher
   * @param pageIndex
   */
  const onChangePagination = (pageIndex : number) => {
    setActualPage(pageIndex)
  }

  /**
   * Suppression d'un intervenant
   */
  const deleteContact = () => {
    ContactService.deleteContact(contactList[deleteIndex].id).then(success => {
      if (success) {
        getContacts(actualPage, true)
        const updatedFieldStatus = [...fieldStatus]
        updatedFieldStatus.splice(deleteIndex, 1)
        setFieldStatus(updatedFieldStatus)
      }
      // Ferme la popin
      setShowDeleteConfirmation(false)
    })
  }

  /**
   * Fonction pour afficher la popin demandant confirmation de suppression d'un intervenant
   */
  const modalBeforeDeleteContact = () => {
    return (
      <div className='row'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <SubmitButton
            className='btn btn-outline-secondary mt-4'
            onClick={() => setShowDeleteConfirmation(false)}
          >
            <FormattedMessage id='button_cancel' />
          </SubmitButton>
          <SubmitButton
            className='btn btn-outline-primary mt-4'
            onClick={deleteContact}
          >
            <FormattedMessage id='button_delete_contact' />
          </SubmitButton>
        </div>
      </div>
    )
  }

  const callbackAddressBookFile = (response : ImportContactResponse | ErrorContactResponse) => {
    if ('errorCode' in response) {
      setShowModalError && setShowModalError(true)
    } else if ('nbErrors' in response && response.nbErrors) {
      toast.error(Message.import_contact_error)
      setAddressBookFile && setAddressBookFile(undefined)
    } else {
      toast.success(Message.success_import_contact)
      setToReload && setToReload(true)
      setAddressBookFile && setAddressBookFile(undefined)
    }
  }

  const addAddressBookFile = (event: EventType) => {
    setToReload && setToReload(false)
    const { value } = event.target
    setAddressBookFile && setAddressBookFile(value)
    ContactService.importAddressBook(value).then((response) => {
      callbackAddressBookFile(response)
    })
  }

  const deleteAddressBookFile = () => {
    setAddressBookFile && setAddressBookFile(undefined)
  }

  const handleChangeOrderBy = (field?: string) => {
    let updatedSort

    if (field) {
      updatedSort = computeOrder(field, sortBy)
      getContacts(actualPage, false, updatedSort?.order)
    }
    setSortBy(updatedSort)
  }

  return (
    <>
      <div className='py-3 row d-flex justify-content-between'>
        <form className='col-4'>
          <InlineTextField
            inputId='searchValue'
            value={searchValue}
            placeholder={intl.formatMessage({ id: 'products_and_services_list_filter_placeholder' })}
            onChange={(event: EventType) => setSearchValue(event.target.value)}
          >
            <SubmitButton className='btn-primary' onClick={() => onSearchContact(searchValue)} replaceOnLoad>
              <FontAwesomeIcon icon={faSearch} />
            </SubmitButton>
          </InlineTextField>
        </form>
        {
          canEdit &&
            <FileBrowserField
              inputId='addressBookDoc'
              label={<FormattedMessage id='button_import_address_book' />}
              onChange={addAddressBookFile}
              onDelete={deleteAddressBookFile}
              maxNumberFile={1}
              acceptApplication={DOCUMENT_FORMATS.CSV}
              buttonLabel={<FileButton />}
              informationDoc
              placementTooltip='bottom'
            />
        }
      </div>
      {contactList && contactList.length > 0 && (
        <>
          <Table
            tableTitle={[
              { label: Message.address_book_name, className: 'table-column-label', value: 'name' },
              { label: Message.address_book_siren, className: 'table-column-label pl-2' },
              { label: Message.address_book_address, className: 'table-column-label' }
            ]}
            tableClassName='address-book-table'
            totalPage={totalPage}
            totalSize={totalSize}
            actualPage={actualPage}
            valueSelect={pageSize}
            onChangePagination={onChangePagination}
            colorHover={!!onContactSelect}
            onSort={handleChangeOrderBy}
            sortBy={sortBy}
          >
            <tbody>
              {
                contactList.map((contact, index) => (
                  <OverviewAddress
                    key={`overview-address-${index}`}
                    contact={contact}
                    index={index}
                    canEdit={canEdit}
                    onContactSelect={onContactSelect}
                    deleteContact={() => {
                      setDeleteIndex(index)
                      setShowDeleteConfirmation(true)
                    }}
                    editContact={() => history.push(`/contact/${contactList[index].id}`)}
                    countries={countries}
                  />
                ))
              }
            </tbody>
          </Table>
          {canEdit &&
            <DownloadLink
              label={<FormattedMessage id='button_export_address_book' />}
              onClick={() => ContactService.getContactsAsCsv()}
            />}
        </>
      )}
      <ModalComponent
        title={<FormattedMessage id='popin_delete_contact_title' />}
        customContent={() => modalBeforeDeleteContact()}
        handleClose={() => setShowDeleteConfirmation(false)}
        show={showDeleteConfirmation}
        hideFooter
      />
    </>
  )
}

export default withRouter(AddressList)
