import React, { useEffect, useState } from 'react'
import { HELP_TYPE_CPU, LOGO_WHITE_SRC, MAIN_FOOTER_LINK } from '../../constants'
import HeaderNavItem from '../header/HeaderNavItem'
import { FormattedMessage } from 'react-intl'
import { version } from '../../../package.json'
import VersionApiService from '../../services/VersionApiService'
import HelpService from '../../services/content/HelpService'
import { HelpItem } from '../../interfaces/HelpInterfaces'

const Footer = () => {
  const [versionApi, setVersionApi] = useState('')
  const [mainFooterLinks, setMainFooterLinks] = useState(MAIN_FOOTER_LINK)

  useEffect(() => {
    // Récupération de la version de l'api
    VersionApiService.getVersion().then(setVersionApi)
    // Récupération des aides de la BDD
    HelpService.getHelps().then((response: HelpItem[]) => {
      const cpuLink = response.filter((helpItem: HelpItem) => helpItem.type === HELP_TYPE_CPU)?.map((helpItem: HelpItem) => ({ id: 'cpu', label: helpItem.label, url: helpItem.url }))?.shift()
      if (cpuLink) {
        setMainFooterLinks([...mainFooterLinks, ...[cpuLink]])
      }
    })
  }, [])

  return (
    <div id='mainFooter' className='px-3'>
      <ul className='nav d-flex'>
        <li className='nav-item d-flex flex-row align-items-center py-3 version'>
          <div>
            <img src={LOGO_WHITE_SRC} alt='Logo INPI' height='30px' />
          </div>
          <div className='d-flex flex-column ml-4'>
            <span><FormattedMessage id='fo_version_api' /> {versionApi}</span>
            <span><FormattedMessage id='fo_version_front' /> {version}</span>
          </div>
        </li>
        <li className='nav-item m-auto'>
          <ul className='nav'>
            {mainFooterLinks?.map((link) => (
              <HeaderNavItem
                key={link.id}
                label={link.label}
                url={link.url}
                className='separated'
                targetBlank
              />
            ))}
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default Footer
