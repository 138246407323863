const Message = {
  // Upload images
  'file-too-large': 'Le poids du fichier est supérieur à 10MB',
  'file-invalid-type': 'Le format du fichier n\'est pas autorisé',
  drop_files: 'Glissez-déposez vos reproductions ou cliquez pour parcourir',
  overview_deposit_regularize_add_reproductions: 'Ajouter des reproductions à régulariser',
  request_reproduction_completion_new_repro: 'Glissez-déposez ou cliquez pour proposer une nouvelle reproduction',
  official_document_procedure_bloc_generate_do_download_file: 'Glisser-déposer ou cliquer pour parcourir',
  drop_brand_file: 'Glissez-déposez ou cliquez pour proposer une nouvelle marque',
  drop_brand_file_division: 'Glissez-déposez ou cliquez pour transmettre le fichier reprenant le modèle de marque',
  drop_brand_color_file: 'Glissez-déposez ou cliquez pour ajouter la représentation de la marque couleur',
  drop_deposit_file: 'Glissez-déposez ou cliquez pour importer un dépôt de marque',
  file_invalid_type: 'Le type de fichier est incorrect',

  // Common
  common_yes: 'Oui',
  common_no: 'Non',
  common_denied: 'Refusé',
  common_validate: 'Valider',
  common_abandonner: 'Abandonner',
  common_continue: 'Poursuivre',
  common_add: 'Ajouter',
  button_cancel: 'Annuler',
  placeholder_select: 'Sélectionner ...',
  button_return: 'Retour',
  button_save: 'Enregistrer',
  autocomplete_no_result: 'Aucun résultat disponible',
  to_exam: 'À examiner',
  accept: 'Accepté',
  rejet: 'Rejeté',
  common_start_form: 'Débuter le formulaire',
  required_field: 'Champ obligatoire',
  text_not_latin: 'Seuls les caractères latins usuels, chiffres et signes de ponctuation sont autorisés.',
  validation_start_date: 'La date de début est supérieure à la date de fin',
  validation_end_date: 'La date de fin est inférieure à la date de début',
  invalid_number: 'Numéro invalide',
  invalid_password_check: 'Les deux mots de passe doivent être identiques',
  records_inscription_origin_fr: 'D&M Français',
  records_inscription_origin_int_fr: 'D&M International',
  elements_from: 'Élément(s) de ',
  to: ' à ',
  on: ' sur ',
  button_edit: 'Modifier',
  actions: 'Actions',
  dictionary: 'Dictionnaire',
  link_new_window: 'Ouvrir dans une nouvelle fenêtre',
  emoji_regex_error: 'Il est impossible d\'insérer des émojis',
  month: 'Mois',
  year: 'Année',
  undefined: 'Indéfini',

  // Formulaire : Intervenant
  contributor_form_title: 'Identification des intervenants',
  contributor_nationality: 'Nationalité',
  contributor_type: 'Type d\'intervenant',
  contributor_name: 'Nom',
  contributor_address: 'Adresse',

  contributor_depositors: 'Déposant(s)',
  contributor_depositors_nullity: 'Demandeur(s)',
  contributor_depositors_revocation: 'Demandeur(s)',
  contributor_depositors_inscription: 'Demandeur(s)',
  contributor_depositors_renunciation: 'Déclarant(s)',
  contributor_depositors_official_documents: 'Demandeur',
  contributor_depositors_renewal: 'Déclarant(s)',
  contributor_agent: 'Mandataire',
  contributor_recipient: 'Destinataire des correspondances',
  contributor_holdersContestedMark: 'Titulaire(s) de la marque contestée',
  contributor_signatory: 'Signataire',
  contributor_holders: 'Titulaire(s)',
  contributor_organisers: 'Organisateur(s)',
  contributor_old_agents: 'Mandataire(s) actuel(s)',
  contributor_old_holders: 'Titulaire(s) actuel(s)',
  contributor_licensee: 'Preneur de licence',
  contributor_old_licensees: 'Preneur(s) de licence actuel(s)',
  contributor_copy: 'Copier les données d\'un autre intervenant',
  contributor_add: 'Ajouter l\'intervenant à mon carnet d\'adresses',
  contributor_from_address_book: 'Sélectionner depuis mon carnet d\'adresses',
  contributor_reinitialiser: 'Réinitialiser les champs',
  contributor_auto_depositors: 'Je suis le déposant',
  contributor_auto_depositors_nullity: 'Je suis le demandeur',
  contributor_auto_depositors_revocation: 'Je suis le demandeur',
  contributor_auto_depositors_division: 'Je suis le déclarant',
  contributor_auto_depositors_renewal: 'Je suis le déclarant',
  contributor_auto_depositors_inscription: 'Je suis le demandeur',
  contributor_auto_depositors_official_documents: 'Je suis le demandeur',
  contributor_auto_depositors_revocation_statement: 'Je suis le demandeur',
  contributor_auto_recipient: 'Je suis le destinataire des correspondances',
  contributor_auto_depositors_renunciation: 'Je suis le déclarant',
  contributor_auto_holdersContestedMark: 'Je suis titulaire de la marque contestée',
  contributor_auto_agent: 'Je suis le mandataire',
  contributor_auto_otherApplicants: 'Je suis la partie à l\'acte',
  contributor_auto_organisers: 'Je suis l\'organisateur',
  contributor_auto_holders_frmi: 'Je suis le titulaire de la marque à étendre',
  contributor_auto_holders: 'Je suis le titulaire',
  contributor_auto_old_holders: 'Je suis le titulaire actuel',
  contributor_auto_old_agents: 'Je suis le mandataire actuel',
  contributor_auto_old_licensees: 'Je suis le preneur de licence actuel',
  contributor_auto_licensee: 'Je suis le preneur de licence',
  contributor_depositors_edit: 'Déposants',
  contributor_depositors_nullity_edit: 'Demandeurs',
  contributor_depositors_revocation_edit: 'Demandeurs',
  contributor_depositors_inscription_edit: 'Demandeurs',
  contributor_depositors_renunciation_edit: 'Déclarants',
  contributor_depositors_official_documents_edit: 'Demandeur',
  contributor_depositors_revocation_statement_edit: 'Demandeurs',
  contributor_depositors_renewal_edit: 'Titulaires',
  contributor_holdersContestedMark_edit: 'Titulaires de la marque contestée',

  contributor_personne_morale_publique: 'Personne morale de droit public (mairie, ministère, collectivité territoriale, etc.)',
  contributor_personne_physique_formation: 'J\'agis au nom et pour le compte d\'une entité en cours de formation',
  contributor_can_be_recipient: 'Je souhaite être le destinataire des correspondances de la marque à diviser',
  contributor_can_be_recipient_agent_recipient: 'Je souhaite être le destinataire des correspondances de la marque',
  contributor_can_be_recipient_inscription: 'Cochez la case si vous êtes également le destinataire des correspondances afférentes au maintien en vigueur du (des) titre(s) concerné(s)',
  contributor_is_administration: 'Je suis une administration',

  rncs_link: '(Extrait RNCS)',

  intervenant_error_denomination_not_empty: 'La raison sociale ne doit pas être vide pour rechercher une entreprise',
  intervenant_error_bad_siren: 'Le siren ne doit pas être vide et doit contenir 9 chiffres',
  intervenant_error_api_error: 'Service momentanément indisponible',
  intervenant_select_company: 'Sélectionnez une entreprise',
  intervenant_table_label_denomination: 'Raison sociale',
  intervenant_table_label_siren: 'Siren',
  error_get_siren_from_denomination: 'Une erreur est survenue, veuillez réessayer plus tard',

  intervenant_warning_applicant_needed: 'Au moins un demandeur est nécessaire',
  intervenant_warning_other_applicant_needed: 'Au moins une autre partie à l\'acte est nécessaire',
  intervenant_warning_agent_is_recipient: 'Attention, en cochant cette case vous devenez l\'unique destinataire des correspondances adressées par l\'INPI sur ce(s) titre(s), y compris en dehors des procédures',
  intervenant_warning_is_recipient: 'Destinataire des correspondances liées au titre',
  intervenant_status_active: 'Actif',
  intervenant_status_to_add: 'Nouveau',
  intervenant_status_to_edit: 'Modification',
  intervenant_status_to_inactivate: 'Inactif',
  intervenant_created_at: 'Ajouté le ',

  personne_type_physique: 'Personne physique',
  personne_type_morale: 'Personne morale',

  civilite_monsieur: 'Monsieur',
  civilite_madame: 'Madame',

  // Champs de formulaires
  field_personne_type_label: 'Personne',
  field_civilite_label: 'Civilité',
  field_nom_label: 'Nom',
  field_prenom_label: 'Prénom',
  field_telephone_label: 'Téléphone',
  field_email_label: 'Email',
  field_email_consent: 'Je consens à recevoir les notifications électroniques exclusivement par courrier électronique',
  field_raison_sociale_label: 'Raison sociale',
  field_raison_sociale_label_long_text_needed: 'La raison sociale ne peut dépasser 120 caractères, veuillez renseigner la dénomination complète dans le champ ci-dessous. La dénomination indiquée ci-dessus sera utilisée pour les communications avec l\'INPI.',
  field_raison_sociale_formation_label: 'Raison sociale de l\'entité en cours de formation',
  field_forme_juridique_label: 'Forme juridique',
  field_forme_juridique_formation_label: 'Nature de l\'entité en cours de formation',
  field_forme_juridique_description_formation_label: 'Forme juridique de l\'entité en cours de formation',
  field_numero_siren_label: 'N° SIREN',
  field_adresse_label: 'Adresse',
  field_adresse_batiment_label: 'Entrée / Bâtiment (ex: Bâtiment B)',
  field_adresse_libelle_label: 'Adresse (ex: 15 rue des minimes)',
  field_other_address_label: 'Autre adresse',
  field_other_address_subtitle: 'Indiquer l\'adresse de l\'établissement industriel ou commercial du déposant sur le territoire français',
  field_adresse_mention_label: 'Mention spéciale',
  field_adresse_post_office_box_label: 'Boîte postale',
  field_adresse_code_postal_label: 'Code postal',
  field_adresse_state_label: 'État / Région',
  field_adresse_ville_label: 'Ville',
  field_adresse_pays_label: 'Pays',
  field_company_label: 'Cabinet ou société',
  field_manageable_qualities_label: 'J\'agis en qualité de',
  field_inscription_number: 'Numéro',
  field_power_documents_label: 'Pouvoir',
  field_agent_is_recipient: 'Si vous êtes également le destinataire des correspondances afférentes à l\'enregistrement ou à la délivrance et au maintien en vigueur du (des) titres(s) concerné(s), cochez cette case.',
  field_power_help_link: 'Télécharger un fichier d\'exemple',
  field_power_example_file: 'exemple_pouvoir_en_copropriete_v1.doc',

  placeholder_adresse_search: 'Saisir une adresse',
  field_creators_placeholder: 'Renseigner les créateurs ...',

  // legal status formation
  legal_status_formation_society: 'Société',
  legal_status_formation_association: 'Association',
  legal_status_formation_entity: 'Autre entité',
  legal_status_formation_society_label: ' la société ',
  legal_status_formation_association_label: ' l\'association ',
  legal_status_formation_entity_label: ' l\'entité ',

  // search
  search_header_label_numnat: 'NumNat',
  search_header_label_deposit_type: 'Type',
  search_header_label_holders: 'Titulaire(s)',
  search_header_label_status: 'Statut',
  search_header_label_last_transaction: 'Date de la dernière transaction',
  search_header_label_deposit_date: 'Date de dépôt',
  search_header_label_examiner: 'Examinateur',

  // deposit type
  select_depot_type_classique_title: 'Dépôt classique',
  select_depot_type_classique_resume: 'Le dépôt classique est la procédure standard ouverte à tous et qui est soumise au respect de certaines règles. Elle comprend la publication immédiate de l\'ensemble des reproductions jointes au dépôt au Bulletin Officiel de la Propriété Industrielle.',
  select_depot_type_ajourne_title: 'Dépôt ajourné',
  select_depot_type_ajourne_resume: 'L\'ajournement de la publication vous permet de garder au secret votre dépôt tout en prenant le temps de réfléchir à l\'opportunité de rendre publiques vos créations. Dans ce cas, la publication aura lieu 3 ans après le dépôt. Toutefois, vous pouvez la demander à tout moment avant la fin de ce délai. Cette demande de publication portera sur l\'ensemble des reproductions jointes au dépôt.',
  select_depot_type_simplifie_title: 'Dépôt simplifié',
  select_depot_type_simplifie_resume: 'Le dépôt simplifié est une procédure allégée sur le plan formel, qui permet de payer certaines redevances ultérieurement et qui se caractérise par une publication effectuée uniquement sur demande du déposant. Cette procédure est réservée aux seuls créateurs et entreprises renouvelant fréquemment la forme ou le décor de leurs produits. Dans ce cas vous disposez de 30 mois au maximum après le dépôt pour demander la publication. Cette demande peut porter sur l\'ensemble ou sur une partie des reproductions jointes au dépôt.',

  // inscription type
  select_type_inscription_correction_title: 'Rectification',
  select_type_inscription_ownership_title: 'Actes',
  select_type_inscription_disclaimer_title: 'Renonciation',
  select_type_inscription_office_title: 'Office',
  select_process_inscription_tn: 'Traitement normal',
  select_process_inscription_ta: 'Traitement accéléré',
  title_validation_modal_select_title_select_label: ' titres correspondent à la recherche, veuillez choisir un titre',

  // transaction selection
  transaction_selection_modal_select_transaction_subtitle: ' transactions correspondent à la recherche, veuillez en choisir une',

  // Tableaux de records
  records_numnat: 'Numéro',
  records_origin: 'Origine',
  records_deposit_date: 'Date de dépôt',
  records_brand_model: 'Modèle de marque',
  records_classes: 'Classes',
  records_holders: 'Titulaires',
  modal_data_record_title: 'Informations de la marque',

  // Tableaux de transactions
  transactions_numNat: 'Numéro',
  transactions_arrival_date: 'Date de demande',
  modal_data_transaction_title: 'Informations de la transaction',

  // Informations Générales Courriers
  model_info_logo: 'Logo',
  model_info_name_direction: 'Nom de la direction émettrice du courrier',
  model_info_signaure: 'Signature',
  model_info_header: 'DIRECTION DE LA PROPRIÉTÉ INDUSTRIELLE ',
  model_info_adress: '15, rue des Minimes-CS 50001 92677 Courbevoie Cedex',

  // Notification
  request_notification_no_response_expected: 'Sans réponse attendue',
  request_notification_agreement_notification: 'Notification d\'office',
  request_notification_response_expected: 'Réponse attendue',
  request_notification_information_mail: 'Courrier d\'information',
  notification_status_all: 'Toutes',
  notification_status_draft: 'Brouillon',
  notification_status_to_review: 'En relecture',
  notification_status_reviewed: 'Relu',
  notification_status_validated: 'Validé / Signé',
  notification_status_to_consider: 'À lire',
  notification_status_considered_fo: 'À lire',
  notification_status_considered: 'Lu',
  notification_status_not_considered: 'Non lu',
  notification_status_closed_considered: 'Fermé lu',
  notification_status_closed_not_considered: 'Fermé non lu',
  notification_status_closed_send: 'Fermé envoyé',
  notification_status_to_close_sent: 'À fermer',
  notification_status_to_answer: 'À répondre',
  notification_status_to_answer_ompi: 'À répondre suite avis OMPI',
  notification_status_answered: 'Répondu',
  notification_status_closedAnswered: 'Fermé répondu',
  notification_status_closedNotAnswered: 'Fermé non répondu',
  notification_status_expired: 'Délai expiré',
  notification_price_table_code: 'Code',
  notification_price_table_label: 'Libellé',
  notification_price_table_price: 'Tarif',
  notification_price_table_quantity: 'Quantité',
  notification_price_table_amount: 'Total',
  notification_table_label_ref: 'Référence client',
  notification_table_label_number: 'Numéro de transaction',
  notification_table_label_update_date: 'Date de modification',
  notification_table_label_notification_sent_date: 'Date d\'envoi de la notification',
  notification_table_label_response_deadline: 'Date limite de réponse',
  notification_table_label_procedure: 'Type de procédure',

  // status réservés aux notifications enquêtes
  notification_status_draftenq: 'Brouillon enquête',
  notification_status_toreviewenq: 'A relire enquête',
  notification_status_reviewedenq: 'Enquête relue',

  error_email_format: 'L\'email renseigné n\'est pas au bon format',
  error_numero_siren_format: 'Le numéro siren est composé de 9 chiffres et doit être saisi sans espace',
  error_public_siren_invalid: 'Le SIREN ne correspond pas à celui d\'une personne morale de droit public',
  error_phone_format: 'Numéro de téléphone invalide',
  error_code_postal_format: 'Code postal invalide',
  error_agent_needed: 'Cette qualité exige la présence d\'un mandataire',

  // Type de procédure
  procedure_type_all: 'Toutes',
  procedure_type_deposit: 'Dépôt',
  procedure_type_opposition: 'Opposition',
  procedure_type_nullity: 'Nullité',
  procedure_type_revocation: 'Déchéance',
  procedure_type_mifr: 'MIFR',
  procedure_type_inscription: 'Inscription',
  procedure_type_renewal: 'Renouvellement',
  procedure_type_appeal: 'Relevé de déchéance',
  procedure_type_official_document: 'Documents officiels',
  procedure_type_nullity_revocation: 'Nullité/Déchéance',
  procedure_type_division: 'Division',
  procedure_type_frmi: 'FRMI',
  procedure_type_revocation_statement: 'Relevé de déchéance',
  procedure_type_renunciation: 'Renonciation',

  // Tableau
  request_10_rows: 'Afficher 10 résultats',
  request_20_rows: 'Afficher 20 résultats',
  request_25_rows: 'Afficher 25 résultats',
  request_50_rows: 'Afficher 50 résultats',
  request_100_rows: 'Afficher 100 résultats',
  request_all_result: 'Tout afficher',
  request_no_result: 'Aucun résultat',

  // Locarno PopUp
  placeholder_locarno_search: 'Rechercher une catégorie ...',
  placeholder_locarno_search_popup: 'Rechercher dans la classification de Locarno',
  locarno_empty_products: 'Aucun produit n\'a été trouvé dans cette sous-classe',
  lcoarno_not_result_found: 'Aucun résultat, veuillez modifier votre recherche',

  // Overview d'un compte SSO
  sso_account_is_recipient: 'Cette personne a aussi indiqué être le destinataire des correspondances afférentes à l’enregistrement ou au maintien en vigueur du titre concerné',
  sso_account_link_record: 'Accéder au titre lié à la demande',
  sso_account_quality: 'Qualité : ',
  sso_account_inscription_number: 'N° : ',
  sso_account_undefined: 'Compte ETS non défini',
  sso_account_state_active: 'Actif',
  sso_account_state_new: 'Nouveau',
  sso_account_state_edit: 'Modification',
  sso_account_state_inactivate: 'Inactif',

  // Rattachement mandataire
  transaction_etsaccount_code_label: 'Partie représentée',
  etsaccount_code_opponent_label: 'Opposant',
  nullity_etsaccount_code_depositor_label: 'Demandeur',
  revocation_etsaccount_code_depositor_label: 'Demandeur',
  opposition_etsaccount_code_depositor_label: 'Déposant',
  etsaccount_code_holder_contested_mark_label: 'Titulaire d\'une marque contestée',
  etsaccount_code_agent_mifr_label: 'Mandataire déposant',
  etsaccount_code_agent_nullity_label: 'Mandataire demandeur',
  etsaccount_code_agent_revocation_label: 'Mandataire demandeur',
  etsaccount_code_agent_opposition_label: 'Mandataire opposant',
  etsaccount_code_agent_renewal_label: 'Mandataire',
  etsaccount_code_agent_deposit_label: 'Mandataire déposant',
  etsaccount_code_agent_division_label: 'Mandataire déposant',
  etsaccount_code_agent_inscription_label: 'Mandataire demandeur',
  etsaccount_code_holder_nullity_label: 'Titulaire',
  etsaccount_code_holder_revocation_label: 'Titulaire',
  etsaccount_code_holder_mifr_label: 'Déposant',
  etsaccount_code_holder_opposition_label: 'Titulaire',
  etsaccount_code_holder_deposit_label: 'Déposant',
  etsaccount_code_holder_division_label: 'Titulaire',
  etsaccount_code_depositor_opposition_label: 'Mandataire déposant',
  etsaccount_code_agent_tmc_nullity_label: 'Mandataire TMC',
  etsaccount_code_agent_tmc_revocation_label: 'Mandataire TMC',
  etsaccount_code_tmc_nullity_label: 'TMC',
  etsaccount_code_tmc_revocation_label: 'TMC',
  etsaccount_code_applicant_opposition_label: 'Opposant',
  etsaccount_code_applicant_depositor_opposition_label: 'Déposant',
  etsaccount_code_applicant_deposit_label: 'Déposant',
  etsaccount_code_applicant_revocation_label: 'Demandeur',
  etsaccount_code_applicant_nullity_label: 'Demandeur',
  etsaccount_code_applicant_division_label: 'Demandeur',
  etsaccount_code_applicant_inscription_label: 'Demandeur',
  etsaccount_code_agent_frmi_label: 'Mandataire',
  etsaccount_code_applicant_revocation_statement_label: 'Demandeur',
  etsaccount_code_agent_revocation_statement_label: 'Mandataire',

  // Overview d'un intervenant
  contributor_pp_person_formation_start: 'M. ',
  contributor_person_formation_start_acting: 'agissant au nom et pour le compte de ',
  contributor_person_formation_end: ' en cours de formation',
  contributor_pm_person_formation_start: 'Société ',
  contributor_email_consented: 'Consent à recevoir les notifications uniquement par courrier électronique',
  contributor_email_not_consented: 'Ne consent pas à recevoir les notifications uniquement par courrier électronique',
  contributor_frmi_other_address_france: 'France',
  contributor_frmi_nationality: 'Nationalité',
  contributor_frmi_ompi_language: 'Langue souhaitée pour la correspondance OMPI',
  contributor_frmi_ompi_second_language: 'Seconde langue de travail',

  // Intervenant Ajouter dans bénéficiaire
  contributor_add_beneficiary: ' Ajouter comme Bénéficiaire',

  request_actions_error: 'Action impossible',

  form_file_placeholder: 'Sélectionner votre fichier',
  brand_hash_label: 'Empreinte numérique: ',
  print_preview: 'Le modèle de marque est présenté dans une zone d\'impression de 8 x 8 cm représentée par le liseret gris.',

  placeholder_search: 'Rechercher par référence client, numéro national ou email du signataire ...',
  procedure_type_label: 'Type de procédure',
  transaction_type: 'Type',

  // Documents
  documents_createdAt: 'Créé le',
  documents_name: 'Nom',
  documents_type: 'Type',
  documents_subtype: 'Sous-type',
  documents_communicable: 'Communicabilité',
  documents_status: 'Statut',
  documents_archivable: 'Archivable',
  documents_import_date: 'Date d\'ajout',
  documents_empty: 'Pas de document déposé pour cette demande',
  documents_not_authorized: 'Vous n\'avez pas les droits de voir les documents déposés pour cette demande.',
  documents_download: 'Télécharger le fichier',
  document_new: 'Nouveau',
  document_add_drop: 'Glissez-déposez ou cliquez pour ajouter des documents',
  file_max_size: 'Poids maximum autorisé',
  accepted_file_types: 'Types de fichiers autorisés',

  // Types de documents
  documents_type_other: 'Autres pièces ou documents',

  documents_type_observation: 'Observations pour tiers',
  documents_type_observation_cg: 'Observations pour marque collective ou de garantie',

  // Cutting documents
  cutting_document_table_name: 'Nom',
  cutting_document_table_type: 'Type',
  cutting_document_table_options: 'Pages',

  // Statut d'une transaction
  transaction_status_draft: 'Brouillon',
  transaction_status_waiting_vir_payment: 'En attente d\'un mémoire administratif',
  transaction_status_vir_payment_expired: 'Mémoire administratif expiré',
  transaction_status_to_attribute_acceptability: 'A attribuer recevabilité',
  transaction_status_check_acceptability: 'Examen de recevabilité',
  transaction_status_stop_acceptability: 'Arrêté recevabilité',
  transaction_status_not_accepted: 'Irrecevable',
  transaction_status_to_attribute_review: 'A attribuer fond/forme',
  transaction_status_to_review_first_exam: 'Examen fond',
  transaction_status_first_exam_stand_by: 'Arrêté examen fond',
  transaction_status_to_review_second_exam: 'Examen forme',
  transaction_status_second_exam_stand_by: 'Arrêté examen forme',
  transaction_status_rejected: 'Rejeté',
  transaction_status_regular: 'Régulier',
  transaction_status_stop_opposition: 'Arrêté opposition',
  transaction_status_to_register: 'A enregistrer',
  transaction_status_registered: 'Enregistré',
  transaction_status_total_withdrawal: 'Retrait total',
  transaction_status_to_reject: 'A rejeter',

  // Groupe statut transaction
  transaction_group_status_published: 'Inscrites/Publiées',
  transaction_group_status_rejected: 'Rejetées/Irrecevables/Rapportées',
  transaction_group_status_to_review: 'En cours d\'examen',

  rejected_inscription_total: 'Rejet total',
  rejected_inscription_partial: 'Rejet partiel',

  // Produits et service
  product_and_services_type_nice: 'Nice',
  product_and_services_type_tmclass: 'TMClass',
  product_and_services_type_import: 'Importé',
  product_and_services_type_free: 'Saisie libre',
  product_and_services_table_label: 'Libellés',
  product_and_services_table_origin: 'Origine',
  product_and_services_table_status: 'État',
  product_and_services_table_edit: 'Modifier',
  product_and_services_table_delete: 'Supprimer',
  product_and_services_table_validate: 'Validé',
  product_and_service_status_deleted: 'Supprimé',
  class_status_deleted: 'Supprimée',
  product_and_service_status_added: 'Ajouté',
  product_and_service_status_edited: 'Modifié',
  products_and_services_class: 'Classe',
  product_and_services_table_products: 'Produits',
  product_and_services_table_edit_products: 'Produits modifiés',
  product_and_services_validate_partial_withdrawal_button: 'Valider le retrait',
  product_and_services_revocation_title: 'Retrait de produits et services',
  product_and_services_total_withdrawal: 'Retrait total',
  product_and_services_partial_withdrawal: 'Retrait partiel',
  product_and_services_total_revocation_text: 'Attention, cette action est définitive, une fois validé, le retrait total implique la suppression totale de votre marque.',
  product_and_services_partial_revocation_text: 'Attention, le retrait constitue une renonciation définitive aux produits et services supprimés.',
  product_and_services_revocation_button_label: 'Retirer les produits et services',
  products_and_services_description_missing: 'La description est obligatoire',
  product_and_services_added_modal_title: 'Ajouter une classe',
  product_and_services_add_class: 'Ajouter une classe',
  product_and_services_added_modal_validate_label: 'Valider l\'ajout des produits',
  products_and_services_description: 'Énumération des Produits & Services',
  products_and_services_description_subtitle: 'Pour la classe indiquée, saisissez ici les produits et/ou services destinés à être identifiés par la marque.',
  products_and_services_in_modal_description_subtitle: 'Pour ajouter une classe de produits et services, veuillez sélectionner une classe, saisir ci-dessous les produits ou services de la classe puis cliquer sur le bouton Ajouter.{linebreak}Une fois que vous avez ajouté la/les classe(s) de produits et services, veuillez cliquer sur le bouton Valider l\'ajout de produits et services.',
  opposition_contested_products_and_services_description_subtitle: 'Saisissez ici les produits et/ou services objets de l’opposition.',
  nullity_contested_products_and_services_description_subtitle: 'Saisissez ici les produits et/ou services objets de la nullité.',
  revocation_contested_products_and_services_description_subtitle: 'Saisissez ici les produits et/ou services objets de la déchéance.',
  products_and_services_by_class: 'Affichage par classe',
  products_and_services_by_list: 'Affichage par liste',
  product_and_services_table_frmi_status: 'Statut',
  product_and_services_table_ps: 'Produits et services',
  product_and_services_table_number: 'Numéro',
  product_and_service_delete_class_modal_title: 'Supprimer une classe',
  product_and_service_delete_class_modal_text: 'Voulez-vous supprimer cette classe ?',
  product_class_origin: 'Origine',
  tmclass_status_OK: 'Terme trouvé dans la même classe',
  tmclass_status_NotOK: 'Terme trouvé dans une autre classe',
  tmclass_status_None: 'Terme non trouvé',
  tmclass_status_Hint: 'Terme partiellement trouvé dans la même classe ou une autre classe',

  // Opposant
  contributor_auto_opponents: 'Je suis l\'opposant',

  // Fondements
  foundation_type_brand_label: 'Marque',
  foundation_type_renowned_label: 'Marque de renommée',
  foundation_type_collectivity_label: 'Atteinte au nom, à l’image ou à la renommée d’une Collectivité territoriale ou d\'un EPCI',
  foundation_type_institution_label: 'Atteinte au nom d’une entité publique',
  foundation_type_igcraft_label: 'Indication géographique (au sens de l\'article L. 722-1 b)',
  foundation_type_aoig_label: 'Indication géographique (au sens de l\'article L. 722-1 a et c)',
  foundation_type_unauthorized_label: 'Dépôt non autorisé par l\'agent ou le représentant du titulaire de la marque',
  foundation_type_denomination_label: 'Dénomination ou raison sociale',
  foundation_type_trade_name_label: 'Nom commercial ou enseigne',
  foundation_type_domain_name_label: 'Nom de domaine',

  foundations_form_title: 'Fondement(s) de l’opposition',
  foundations_form_subtitle: 'Cette rubrique vous permet d\'identifier le ou les droits antérieurs servant de base à l\'opposition. A minima, un droit antérieur doit être identifié. Pour en savoir plus, consultez consulter l\'aide en ligne.',
  foundation_list_number: 'Numéro',
  foundation_list_brand_name: 'Nom',

  opposition_foundation_brand_origin_label: 'L\'opposant est titulaire d\'une',
  nullity_foundation_brand_origin_label: 'Le demandeur est titulaire d\'une',
  foundation_brand_origin_placeholder: 'Choisissez l\'origine de la marque',
  foundation_brand_name_label: 'Nom de la marque',
  foundation_sign_name_label: 'Désignation du signe',
  foundation_igcraft_name_label: 'Nom de l\'indication géographique',
  foundation_brand_number_label: 'N° de dépôt',
  foundation_brand_created_date_label: 'Date de dépôt',
  foundation_international_brand_number_label: 'N° d\'enregistrement',
  foundation_international_brand_created_date_label: 'Date d\'enregistrement',
  foundation_brand_international_register_date_label_frmi: 'Date d\'inscription au registre international de l\'extension à la France de l\'enregistrement international (le cas échéant)',
  foundation_brand_international_register_date_label_uemi: 'Date d\'inscription au registre international de l\'extension à l\'Union européenne de l\'enregistrement international (le cas échéant)',
  foundation_brand_international_register_date_label: 'Date d\'inscription au registre international de l\'extension à la France ou à l\'Union européenne de l\'enregistrement international (le cas échéant)',
  opposition_foundation_opponent_quality_label: 'Qualité de l\'opposant',
  nullity_foundation_opponent_quality_label: 'Qualité du demandeur',
  foundation_nullity_opponent_quality_label: 'Qualité du demandeur',
  opposition_foundation_opponent_quality_placeholder: 'Choisissez la qualité de l\'opposant',
  nullity_foundation_opponent_quality_placeholder: 'Choisissez la qualité du demandeur',
  opposition_foundation_opponent_right_file_label: 'Document justifiant que l\'opposant est autorisé à exercer les droits découlant du droit antérieur',
  nullity_foundation_opponent_right_file_label: 'Document justifiant que le demandeur est autorisé à exercer les droits découlant du droit antérieur',

  foundation_brand_claimed_priority_label: 'Priorité revendiquée (le cas échéant)',
  foundation_brand_claimed_priority_country_label: 'Pays',
  foundation_brand_claimed_priority_country_placeholder: 'Choisissez un pays',
  foundation_brand_claimed_priority_date_label: 'Date',
  foundation_brand_claimed_renewal_request_date_label: 'Date de la demande de renouvellement (le cas échéant)',
  foundation_brand_claimed_renewal_publication_date_label: 'Date de publication du renouvellement (le cas échéant)',
  foundation_brand_object_label: 'La marque antérieure a-t-elle fait l’objet d’une',
  foundation_brand_object_partial_assignment_label: 'Cession partielle ?',
  foundation_brand_object_limitation_label: 'Limitation ?',
  foundation_brand_object_renunciation_label: 'Renonciation ?',
  foundation_brand_copy_label: 'Copie de la marque antérieure',
  foundation_is_word_type_brand_label: 'Est une marque de type :',
  foundation_is_word_type_collectivity_label: 'Est un type de signe :',
  foundation_is_word_type_unauthorized_label: 'Est une marque de type :',
  foundation_is_word_type_trade_name_label: 'Est-ce un signe de type :',
  foundation_signs_type: 'Type de signe',
  foundation_sign_file_label: 'Représentation du signe antérieur',
  foundation_right_file_label: 'Document(s) justifiant de l\'existence et de la portée du droit antérieur',
  foundation_proof_file_label: 'Document propre à justifier en quoi ce signe identifie la collectivité territoriale ou l\'EPCI',
  foundation_opponent_licence_fee_label: 'Si nécessaire, un document (justificatif de la transmission ou droit de licence) peut être joint',
  foundation_products_label: 'Identifiez si les produits et service sont',
  foundation_similar_label: 'Similaires',
  foundation_identical_label: 'Identiques',
  foundation_active_label: 'Actif',
  foundation_sign_label: 'Identifiez si les signes sont',
  foundation_form_active_label: 'Identifiez si le fondement est',
  foundation_form_activity_label: 'Indiquez quelles sont les missions, attributions, ou domaines d\'intervention invoqués à l\'appui de l\'opposition',
  foundation_form_activity_label_short: 'Identifiez les missions/attributions',
  foundation_form_activity_label_igcraft: 'Indiquez quel est le produit bénéficiant de l\'indication géographique invoqué à l\'appui de l\'opposition',
  foundation_form_activity_label_aoig: 'Indiquez quel est le produit bénéficiant de l\'AO ou de l\'IG invoqué à l\'appui de l\'opposition',

  foundation_option_verbale: 'Verbale',
  foundation_option_other: 'Autre',
  foundation_option_sign_verbale: 'Verbal',

  foundation_bopi_label: 'BOPI de la décision d\'homologation',
  foundation_entity_name_label: 'Désignation de l\'entité publique',
  foundation_entity_igcraft_name_label: 'Nom de l\'indication géographique',
  foundation_entity_igcraft_number_label: 'N° de la demande ou N° national',

  foundation_bopi_number_label: 'Numéro',
  foundation_bopi_date_label: 'Date',
  foundation_homologation_file_label: 'Document d\'homologation',

  foundation_form_brand_part_label: 'Identifiez la marque antérieure',
  opposition_foundation_form_opponent_part_label: 'Opposant',
  nullity_foundation_form_opponent_part_label: 'Demandeur',
  foundation_form_products_part_label: 'Produits et Services',
  foundation_form_sign_part_label: 'Signes :',
  foundation_form_active_part_label: 'Fondement',
  foundation_form_collectivity_sign_part_label: 'Indiquez le signe identifiant la collectivité territoriale ou l\'EPCI',
  foundation_form_activity_part_label: 'Identifiez les missions, attributions ou domaines d\'interventions',
  foundation_form_activity_part_label_short: 'Missions et attributions',

  foundation_aoig_name_label: 'Nom de l\'AO ou de l\'IG',
  foundation_aoig_type_label: 'Type d\'AO ou d\'IG',
  foundation_aoig_protecting_act_label: 'Acte(s) donnant droit à la protection',
  foundation_form_activities_part_label: 'Activités',
  foundation_form_domain_name_activity_label: 'Indiquez quelles sont les activités qui servent de base à l\'opposition',
  opposition_foundation_trade_name_type_label: 'L\'opposant est titulaire d\'un(e)',
  nullity_foundation_trade_name_type_label: 'Le demandeur est titulaire d\'un(e)',
  foundation_denomination_name_label: 'Désignation de la dénomination ou raison sociale',
  foundation_denomination_number_label: 'Numéro d\'immatriculation',
  foundation_denomination_matriculation_date_label: 'Date d\'immatriculation',
  foundation_form_activity_label_denomination: 'Indiquez quelles sont les activités qui servent de base à l\'opposition',
  foundation_territory_label: 'Territoire',
  foundation_unauthorized_register_date_label: 'Date d\'inscription au registre international de l\'extension de l\'enregistrement international (le cas échéant)',
  foundation_subscription_number_label: 'N° d\'inscription',
  foundation_subscription_date_label: 'Date',
  contributors_opponent_label: 'Opposant',
  form_number_placeholder: 'Numéro',
  verify_numnat: 'Vérifier',

  // Opposition fondation
  opposition_foundations_add_button: 'Ajouter un fondement',
  nullity_foundations_add_button: 'Ajouter un motif',
  opposition_foundation_list_name: 'Fondement',
  nullity_foundation_list_name: 'Motif',
  opposition_fondation_type_label: 'Création d’un fondement de ...',
  nullity_fondation_type_label: 'Création d’un motif de ...',
  opposition_fondation_type_placeholder: 'Choisissez le type de fondement',
  nullity_fondation_type_placeholder: 'Choisissez le type de motif',
  opposition_foundation_trade_name_type_placeholder: 'Choisissez le type de fondement',
  nullity_foundation_trade_name_type_placeholder: 'Choisissez le type de motif',
  opposition_foundations_missing: 'Au moins un fondement est requis',
  opposition_foundations_required: 'Veuillez saisir au moins un fondement',
  nullity_foundations_missing: 'Au moins un motif est requis',
  opposition_foundation_delete_modal_title: 'Supprimer un fondement',
  nullity_foundation_delete_modal_title: 'Supprimer un motif',
  opposition_foundation_delete_modal_text: 'Êtes-vous sûr(e) de vouloir supprimer ce fondement ?',
  nullity_foundation_delete_modal_text: 'Êtes-vous sûr(e) de vouloir supprimer ce motif ?',
  foundations_missing: 'Au moins un fondement est requis',
  foundation_delete_modal_title: 'Supprimer un fondement',
  foundation_delete_modal_text: 'Êtes-vous sûr(e) de vouloir supprimer ce fondement ?',
  opposition_delay_type_due: 'Date d\'échéance',

  // Demande d'enregistrement
  request_identification_brand_type_label: 'Vous contestez une',
  request_identification_brand_type_revocation_label: 'Vous demandez la déchéance d\'une',
  request_identification_french_brand: 'Marque française',
  request_identification_international_brand: 'Marque internationale ayant effet en France',
  request_identification_numnat: 'Numéro national',
  request_identification_numnat_international: 'Numéro d\'enregistrement international',
  request_identification_copy: 'Copie de la demande d\'enregistrement contestée',
  request_identification_revocation_copy: 'Copie de la marque contestée',
  request_identification_copy_international: 'Copie de l\'enregistrement international contesté',
  request_identification_contested_brand_name: 'Désignation de la marque contestée',
  request_identification_contested_brand_name_international: 'Libellé de la marque contestée',
  transaction_contested_brand_select_record_modal_title: 'Sélection du titre',
  form_brand_name_placeholder: 'Désignation',
  form_brand_name_international_placeholder: 'Libellé',
  request_identification_deposit_date: 'Date de dépôt',
  request_identification_date_international: 'Date d\'enregistrement international',
  request_identification_priority_claimed: 'Une priorité est-elle revendiquée ?',
  request_identification_bopi_number: 'Numéro de publication BOPI',
  request_identification_ompi_number: 'Numéro de publication gazette OMPI',
  request_identification_registration_date: 'Date d\'enregistrement',
  request_identification_grant_protection_date: 'Date d\'octroi de la protection, le cas échéant',
  request_identification_grant_protection_date_overview: 'Date d\'octroi de la protection',
  opposition_total_products_and_services: 'La demande d\'opposition porte-t-elle sur l\'intégralité des produits et services ?',
  nullity_total_products_and_services: 'La demande en nullité porte-t-elle sur l\'intégralité des produits et services ?',
  revocation_total_products_and_services: 'La demande en déchéance porte-t-elle sur l\'intégralité des produits et services ?',
  priority_claimed_date: 'Date',
  priority_claimed_country: 'Pays',
  form_country_placeholder: 'Sélectionner un pays',
  button_upload_file: 'Téléversez votre document',
  form_date_placeholder: 'Date',
  request_edit_products_and_services_required: 'Veuillez ajouter, modifier ou supprimer au moins un produit',
  request_edit_products_and_services_version_required: 'Veuillez ajouter, modifier ou supprimer au moins un produit dans la dernière version, ou en ajouter une nouvelle version de produits et services',
  request_identification_numnat_error: 'Le numéro ne doit contenir que des chiffres',
  request_identification_numnat_length7: 'Le numéro doit contenir 7 chiffres',
  request_identification_numnat_length8: 'Le numéro doit contenir 8 chiffres',
  request_identification_numnat_endsWithA: 'Le numéro doit contenir 6 chiffres et doit se terminer par A',
  request_identification_numnat_endsWithB: 'Le numéro doit contenir 6 chiffres et doit se terminer par B',
  request_identification_numnat_required: 'Le champ est obligatoire',
  brand_type_placeholder: 'Choisir un type de marque',
  brand_type_label: 'Type de marque',
  brand_type_word_title: 'Marque verbale',
  sign_type_word_title: 'Signe verbal',
  brand_type_figurative_title: 'Marque figurative',
  brand_type_semi_figurative_title: 'Marque semi-figurative',
  brand_type_3d_title: 'Marque tridimensionnelle',
  brand_type_color_title: 'Marque de couleur',
  brand_type_hologram_title: 'Marque hologramme',
  brand_type_shape_title: 'Marque de forme',
  brand_type_sound_title: 'Marque sonore',
  brand_type_position_title: 'Marque de position',
  brand_type_pattern_title: 'Marque de motif',
  brand_type_movement_title: 'Marque de mouvement',
  brand_type_multimedia_title: 'Marque multimedia',
  brand_type_other_title: 'Autre',

  transaction_publications_type_label: 'Type',
  transaction_publications_numBopi_label: 'Numéro BOPI',
  transaction_publications_date_label: 'Date de publication',
  product_and_service_class_ref: 'Classe(s)',
  product_and_service_products_name: 'Produits et services',
  transaction_request_reference: 'Numéro national',
  transaction_request_origin: 'Origine',
  transaction_request_deposit_date: 'Date de dépôt',
  transaction_request_end_date_validity: 'Date de fin de validité',
  transaction_brand_type: 'Type de demande',
  sub_procedure_deposit_type_national: 'Française',
  sub_procedure_deposit_type_guarantee: 'Garantie',
  sub_procedure_deposit_type_collective: 'Collective',
  sub_procedure_deposit_type_european: 'Union européenne',
  sub_procedure_deposit_type_international: 'International',
  sub_procedure_deposit_type_division: 'Division',
  transaction_brand_type_brand: 'Type de marque',
  transaction_brand_text_brand: 'Modèle de marque',
  transaction_brand_color_brand: 'Couleur',
  transaction_brand_translation_brand: 'Traduction de la marque',
  transaction_brand_description_brand: 'Description',
  transaction_contested_brand_create_record_modal_contributor: 'Intervenants de la marque',
  transaction_contested_brand_create_record_modal_publications: 'Publications de la marque',
  transaction_contested_brand_create_record_modal_products_and_services: 'Produits et services de la marque',
  transaction_contested_brand_create_record_modal_title: 'Visualisation du titre',
  transaction_contested_brand_create_record_button: 'Création du titre : ',
  transaction_contested_brand_ask_create_record_button: 'Demande de création du titre : ',
  transaction_contested_brand_bloc_french: 'Marque française',
  transaction_contested_brand_bloc_international: 'Marque internationale',
  record_previous_registration_number_label: 'Dépôt - précédent',
  record_associated_brand_label: 'Dépôt associé',

  // Transactions associées
  linked_transaction_type_previous_registration: 'Inscription antérieure',
  linked_transaction_type_simultaneous_registration: 'Inscription simultanée',
  linked_transaction_type_reviewing_registration: 'Inscription en cours d\'examen',
  linked_transaction_type_renewal: 'Renouvellement',

  // Accordéon produits et service
  product_and_service_status_ok: 'Terme trouvé dans la même classe',
  product_and_service_status_notok: 'Terme trouvé dans une classe différente',
  product_and_service_status_none: 'Terme non trouvé',
  product_and_service_status_other: 'Terme partiellement trouvé dans la même ou une autre classe',

  // Recherche
  search_blocked_oppo_oppo: 'à traiter en tant qu\'opposition',
  search_blocked_oppo_exam: 'à traiter en tant qu\'examen',

  // Origine de la marque
  brand_origin_french: 'Marque française',
  brand_origin_international: 'Marque internationale',
  brand_origin_french_italian: 'Marque franco-italienne',
  brand_origin_italian_french: 'Marque italo-française',
  brand_origin_french_old_law_a: 'Marque FR anc. loi A',
  brand_origin_french_old_law_b: 'Marque FR anc. loi B',

  // Inscription type récompense
  inscription_contest_type_industrial_reward_label: 'Récompense industrielle',
  inscription_contest_type_ranking_label: 'Palmarès',

  // Inscription Renonciation
  inscription_renunciation_type_total_label: 'Renonciation totale',
  inscription_renunciation_type_partial_label: 'Renonciation partielle',

  contest_inscriptions_title: 'Inscriptions antérieures',
  contest_inscriptions_label: 'Numéro d\'inscription ou numéro d\'ordre des inscriptions antérieures',
  contest_inscriptions_add_inscriptionLink: 'Ajouter une inscription antérieure',
  options_inscriptions_add_inscriptionLink: 'Ajouter une inscription antérieure et/ou simultanée',
  options_inscriptions_add_renewalLink: 'Ajouter un renouvellement associé',
  options_inscriptions_title: 'Option : inscriptions antérieures et/ou simultanées ',
  options_inscriptions_label: 'Numéro d\'inscription ou numéro d\'ordre des inscriptions antérieures et/ou simultanées',
  options_renewal_label: 'Numéro de renouvellement',

  inscription_process_normal_label: 'Traitement normal',
  inscription_process_expedited_label: 'Traitement accéléré',
  inscription_process_office_label: 'Inscription d\'office',
  inscription_attachment_procedure_label: 'Procédure',
  inscription_attachment_numnat_label: 'Numéro national',

  OMPI_language: 'Langue de correspondance avec l\'OMPI',
  other_language_ue: 'Seconde langue de travail',

  // Mode de paiement
  direct_debit_on_ompi_account: 'Prélèvement sur le compte OMPI',
  payment_received_and_confirmed_by_ompi: 'Paiement reçu et confirmé par l\'OMPI',
  payment_to_ompi_bank_account: 'Versement sur le compte bancaire de l\'OMPI',
  payment_to_ompi_postal_account: 'Versement sur le compte postal de l\'OMPI',

  // Type FRMI
  frmi_type_extension: 'Demande d\'extension',

  // Opérations postérieures d'une demande de marque internationale
  frmi_type_post_designation_register: 'Désignation postérieure à l\'enregistrement International (MM4)',
  frmi_type_holder_change: 'Demande d\'inscription de changement de titulaire (MM5)',
  frmi_type_products_limit: 'Limitation de la liste des produits et services (MM6)',
  frmi_type_renunciation: 'Renonciation (MM7)',
  frmi_type_radiation: 'Radiation (MM8)',
  frmi_type_holder_edition: 'Modification du nom ou de l’adresse du titulaire, ou de sa forme juridique (MM9)',
  frmi_type_agent_edition: 'Modification du nom ou de l’adresse du mandataire (MM10)',
  frmi_type_renew: 'Renouvellement de l\'enregistrement international (MM11)',
  frmi_type_agent_constitute: 'Constitution d’un mandataire (MM12)',
  frmi_type_licence_inscription: 'Licence (MM13)',
  frmi_type_licence_edition: 'Modification de l’inscription d’une Licence (MM14)',
  frmi_type_licence_radiation: 'Radiation d’une Licence (MM15)',
  frmi_type_post_designation_conversion: 'Désignation postérieure issue d’une conversion (MM16)',
  frmi_type_holder_right_restriction: 'Restriction du droit du titulaire (MM19)',
  frmi_type_holder_right_delete: 'Main Levée d’une restriction du droit du titulaire (MM19)',
  frmi_type_continuation_request: 'Requête en poursuite de la procédure (MM20)',
  frmi_type_inscription_correction: 'Rectification d’une inscription (MM21)',
  frmi_type_division_international: 'Division d’un enregistrement International (MM22)',
  frmi_type_division_national: 'Division d’un enregistrement National (MM22- bis)',
  frmi_type_fusion_from_holder_edition: 'Fusion d\'enregistrements internationaux (MM23) issus de l\'inscription d\'un changement partiel de titulaire',
  frmi_type_fusion_from_division_inscription: 'Fusion d’un enregistrement international issu de l’inscription d’une division(MM24)',
  frmi_type_effect_stop: 'Cessation des effets de l’enregistrement international dû à la cessation des effets de la demande de base (R22)',
  frmi_type_nullification: 'Invalidation (IJ)',
  frmi_type_national_replacement: 'Remplacement d’un enregistrement national ou régional par un enregistrement international',
  frmi_licence_type_exclusive: 'La licence est une licence exclusive',
  frmi_licence_type_exclusive_description: '(la licence est accordée à un seul preneur et le titulaire ne peut utiliser la marque et/ou accorder une licence à toute autre personne)',
  frmi_licence_type_unique: 'La licence est une licence unique',
  frmi_licence_type_unique_description: '(la licence est accordée à un seul preneur et le titulaire ne peut accorder une licence à toute autre personne, mais il peut utiliser la marque)',
  frmi_effect_date_type_validation: 'Cette désignation postérieure prend effet à la date de réception à l\'INPI',
  frmi_effect_date_type_renewal: 'Cette désignation postérieure doit prendre effet après le renouvellement de l’enregistrement international',
  frmi_effect_date_type_modification_inscription: 'Cette désignation postérieure doit prendre effet après l\'inscription au registre international de la modification suivante.',
  frmi_decision_type_from_third_party: 'Résultant d\'une action d’un tiers',
  frmi_holder_change_extension_type_total: 'Changement total',
  frmi_holder_change_extension_type_partial: 'Changement partiel',
  frmi_decision_type_not_from_third_party: 'Ne résultant pas d’une action d’un tiers',
  frmi_decision_type_partial_withdraw: 'Retrait partiel de la marque Française de base n° ........ Lors de ce retrait, la marque internationale n° ...... était encore sous le délai de dépendance de 5 ans. \n Demande de radiation partielle des effets de l’enregistrement international n° .........',
  frmi_decision_type_total_withdraw: 'Retrait total de la marque Française de base n° ........ Lors de ce retrait, la marque internationale n° ...... était encore sous le délai de dépendance de 5 ans. \n Demande de radiation totale des effets de l’enregistrement international n° .........',
  frmi_replacement_type_partial: 'Remplacement partiel',
  frmi_replacement_type_total: 'Remplacement total',
  frmi_invalidation_type_total: 'Invalidation totale',
  frmi_invalidation_type_partial: 'Invalidation partielle',
  frmi_radiation_type_total: 'Radiation totale: Les faits et décisions indiqués ont une incidence à l\'égard de tous les produits et services figurant dans l\'enregistrement international',
  frmi_radiation_type_partial: 'Radiation partielle',
  frmi_partial_radiation_subtype_with_impact: 'Les faits et décisions indiqués ont une incidence uniquement à l\'égard des produits et services ci-après figurant dans l\'enregistrement international',
  frmi_partial_radiation_subtype_without_impact: 'Les faits et décisions indiqués n\'ont pas d\'incidence à l\'égard des produits et services ci-après figurant dans l\'enregistrement international',
  frmi_licence_products_extension_type_total: 'Licence totale',
  frmi_licence_products_extension_type_partial: 'Licence partielle',
  frmi_licence_countries_extension_type_total: 'Licence totale',
  frmi_licence_countries_extension_type_partial: 'Licence partielle',
  frmi_renew_countries_extension_type_total: 'Renouvellement total',
  frmi_renew_countries_extension_type_partial: 'Renouvellement partiel',
  frmi_holder_right_restriction_countries_extension_type_total: 'Restriction totale',
  frmi_holder_right_restriction_countries_extension_type_partial: 'Restriction partielle',

  // FRMI Bloc limitations produits et services par pays
  transaction_limitations_show_original_product_services_version: 'Voir l\'original',
  transaction_limitations_hide_original_product_services_version: 'Masquer l\'original',
  transaction_limitations_original_product_services_version: 'Version base marque',
  transaction_limitations_original_product_services_version_renewal: 'Version client',
  transaction_limitations_examen_product_services_version: 'Version d\'examen',

  // Sous-type de document
  document_type_sub_type_revocation_statement_act_label: 'Relevé de déchéance - Acte à accomplir',
  document_type_sub_type_revocation_statement_supporting_label: 'Relevé de déchéance - Pièces justificative',

  // Sélecteur couleur éditeur
  color_picker_save: 'Appliquer',
  color_picker_text_tab: 'Texte',
  color_picker_highlight_tab: 'Surlignage',

  // Tableau de pays
  titles_column_label_country_name: 'Nom',
  titles_column_label_country_code: 'Code',

  // Type de décision des relevés de déchéance
  revocation_statement_decision_type_positive_label: 'Favorable',
  revocation_statement_decision_type_inadmissible_label: 'Irrecevable',
  revocation_statement_decision_type_rejected_label: 'Rejeté',
  revocation_statement_decision_type_pointless_label: 'Sans objet',
  revocation_statement_scope_type_total_label: 'Portée totale',
  revocation_statement_scope_type_partial_label: 'Portée partielle',

  // Relevé de déchéance - Bloc documents
  revocation_statement_document_block_file_title: 'Fichier',
  revocation_statement_document_block_type_title: 'Type de document',
  revocation_statement_document_block_communicability_title: 'Communicabilité',
  revocation_statement_document_type_placeholder: 'Veuillez choisir un type de document',

  // Type de Renouvellement
  renewal_type_total: 'Renouvellement intégral',
  renewal_type_partial: 'Renouvellement partiel',

  basket_open_multiple_transactions: 'Ouvrir les transactions sélectionnées',
  basket_transaction_filter_final_status: 'Transactions terminées',
  basket_transaction_filter_not_final_status: 'Transactions en cours',

  // Marques associées
  options_renewal_associated_brands_title: 'Marques associées',
  options_renewal_associated_brands_description: 'Numéro d\'enregistrement de la (des) marque(s) à laquelle (auxquelles) la marque objet du renouvellement a été associée',
  options_renewal_associated_brands_warning: '« Attention : si vous souhaitez renouveler ce titre, une déclaration de renouvellement doit être déposée pour cette marque ».',
  options_renewal_add_associated_brands: 'Ajouter une marque associée',
  options_renewal_associated_brands_millesime: 'Millésime',
  options_renewal_associated_brands_numNat: 'Numéro'
}

export default Message
