import Message from '../Message'
import { Contributor } from '../../interfaces/contributors/ContributorsInterfaces'

export const PERSONNE_MORALE = {
  value: 'M',
  label: Message.personne_type_morale
}

export const PERSONNE_PHYSIQUE = {
  value: 'P',
  label: Message.personne_type_physique
}

export const PERSONNE_TYPES = [
  PERSONNE_PHYSIQUE,
  PERSONNE_MORALE
]

export const CIVILITE_MONSIEUR = {
  value: 'MR',
  label: Message.civilite_monsieur
}

export const CIVILITE_MADAME = {
  value: 'MS',
  label: Message.civilite_madame
}

export const CIVILITES = [
  CIVILITE_MONSIEUR,
  CIVILITE_MADAME
]

export const EMPTY_CONTRIBUTOR : Contributor = {
  type: null,
  consentNotificationByEmail: null,
  internalNameDocuments: null,
  isRecipient: null,
  searchableIdentity: null,
  address: null
}
