import {
  Brand,
  CardBlock,
  Deposit,
  DEPOSIT_TYPE_DIVISION,
  EditingModes,
  Priority,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION,
  ProductAndServiceListView,
  ProductAndServicesAccordion,
  ProductClassVersion,
  ProtectionExtension,
  References,
  Transaction
} from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import DepositBrandOverview from './DepositBrand'
import { FormattedMessage } from 'react-intl'
import OverviewPriorities from 'component/priorities/OverviewPriorities'
import OverviewProtectionExtension from '../../protectionExtensions/OverviewProtectionExtension'
import DocumentsOverview from '../../transactions/overview/DocumentsOverview'
import ProductAndServicesOverview from './ProductAndServices/ProductAndServices'
import IntervenantsOverview from '../../transactions/overview/IntervenantsOverview'
import InformationsOverview from '../../transactions/overview/InformationsOverview'
import OverviewInternationalExtension from '../../division/form/options/OverviewInternationalExtension'
import OverviewLinkedTransactions from '../../division/form/options/OverviewLinkedTransactions'
import ProductsService from '@inpi-marques/components/src/services/Product/ProductsService'

interface DepositOverviewProps {
  transaction: Transaction,
  showAddDocumentsButton?: boolean
  setTransaction?: (transaction: Transaction) => void,
  references?: References,
  editingModes?: EditingModes,
  setEditingModes?: (editingModes: EditingModes) => void,
  isUserActive?: boolean,
  scrollToNotifications?: (id: string) => void,
  setReload?: () => void
}

const DepositOverview: FC<DepositOverviewProps> = ({
  transaction,
  showAddDocumentsButton = false,
  setTransaction,
  references,
  editingModes,
  setEditingModes,
  isUserActive,
  scrollToNotifications,
  setReload
}) => {
  const deposit: Deposit | undefined = transaction.deposit
  const brand: Brand|undefined = deposit && deposit.brand
  const priorities: Priority[] | undefined = deposit && deposit.priorities
  const protectionExtensions: ProtectionExtension[] | undefined = deposit && deposit.protectionExtensions
  const productVersion: ProductClassVersion[] | undefined = deposit && deposit.productsAndServicesVersions
  const [viewMode, setViewMode] = useState<'accordion' | 'list'>('accordion')

  return (
    <div className='col-12 d-flex flex-wrap p-0'>
      {isUserActive &&
        <>
          {/* Type de demande */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_deposit_type_title' />} shadow>
              <InformationsOverview transaction={transaction} />
            </CardBlock>
          </div>

          {/* Intervenants */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_deposit_intervenants_title' />} shadow>
              <IntervenantsOverview transaction={transaction} procedureType={transaction.procedureType} />
            </CardBlock>
          </div>

          {/* Marque */}
          {brand &&
            <div className='col-12 mb-3'>
              <CardBlock
                header={(transaction.procedureType === PROCEDURE_DIVISION.value || transaction.subProcedureType === DEPOSIT_TYPE_DIVISION.value)
                  ? <FormattedMessage id='overview_deposit_brand_mother_title' />
                  : <FormattedMessage id='overview_deposit_brand_title' />} shadow
              >
                <DepositBrandOverview brand={brand} transaction={transaction} />
              </CardBlock>
            </div>}

          {/* Produits et service */}
          {transaction.procedureType !== PROCEDURE_DIVISION.value && productVersion && productVersion.length > 0 &&
            <div className='col-12 mb-3' ref={references?.productsAndServices}>
              <ProductAndServicesOverview
                editingMode={editingModes?.productsAndServices}
                setEditingModes={(mode) => setEditingModes && setEditingModes({ productsAndServices: mode })}
                setTransaction={setTransaction} transaction={transaction}
                versions={productVersion}
                scrollToNotifications={scrollToNotifications}
              />
            </div>}
          {transaction.procedureType === PROCEDURE_DIVISION.value &&
            <div className='col-12 mb-3' ref={references?.productsAndServices}>
              <CardBlock
                header={<FormattedMessage id='overview_deposit_products_title' />}
                shadow
              >
                <div className='d-flex justify-content-end mb-4'>
                  {
                    viewMode === 'accordion' &&
                      <button className='btn btn-link text-primary' onClick={() => setViewMode('list')}><FormattedMessage id='products_and_services_by_class' /></button>
                  }
                  {
                    viewMode === 'list' &&
                      <button className='btn btn-link text-primary' onClick={() => setViewMode('accordion')}><FormattedMessage id='products_and_services_by_list' /></button>
                  }
                </div>
                <div className='d-flex justify-content-between flex-row'>
                  <div className='col-6'>
                    <label className='text-uppercase font-weight-bold'>
                      <FormattedMessage id='overview_deposit_brand_mother_title' />
                    </label>
                    {
                      viewMode === 'accordion'
                        ? <ProductAndServicesAccordion productClasses={ProductsService.getCurrentVersion(transaction.deposit?.parentProductsAndServicesVersions)?.productClasses ?? []} />
                        : <ProductAndServiceListView productClasses={ProductsService.getCurrentVersion(transaction.deposit?.parentProductsAndServicesVersions)?.productClasses ?? []} />
                    }
                  </div>
                  <div className='col-6'>
                    <label className='text-uppercase font-weight-bold'>
                      <FormattedMessage id='division_product_version_title' />
                    </label>
                    {
                      viewMode === 'accordion'
                        ? <ProductAndServicesAccordion productClasses={ProductsService.getCurrentVersion(transaction.deposit?.productsAndServicesVersions)?.productClasses ?? []} />
                        : <ProductAndServiceListView productClasses={ProductsService.getCurrentVersion(transaction.deposit?.productsAndServicesVersions)?.productClasses ?? []} />
                    }
                  </div>
                </div>

              </CardBlock>

            </div>}

          {/* Options */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_deposit_options_title' />} shadow>
              {transaction.procedureType === PROCEDURE_DEPOSIT.value && transaction?.subProcedureType !== DEPOSIT_TYPE_DIVISION.value &&
                <div className='mb-3'>
                  <OverviewPriorities priorities={priorities} hasTitle />
                </div>}
              <OverviewProtectionExtension protectionExtensions={protectionExtensions} />
              {(transaction.procedureType === PROCEDURE_DIVISION.value || transaction?.subProcedureType === DEPOSIT_TYPE_DIVISION.value) &&
                <>
                  <div className='mt-3'>
                    <OverviewInternationalExtension
                      internationalExtensions={transaction.internationalExtensions}
                    />
                  </div>
                  <div className='mt-3'>
                    <OverviewLinkedTransactions
                      linkedTransaction={transaction.deposit?.linkedTransactions}
                    />
                  </div>
                </>}
            </CardBlock>
          </div>
        </>}

      {/* Récapitulatif documents */}
      <div className='col-12 mb-3' ref={references?.documents}>
        <CardBlock header={<FormattedMessage id='overview_file_title' />} shadow>
          <DocumentsOverview transaction={transaction} showAddDocumentsButton={showAddDocumentsButton} isUserActive={isUserActive} setReload={setReload} />
        </CardBlock>
      </div>
    </div>
  )
}

export default DepositOverview
