import { CommonInscriptionService, InscriptionNatureCode, Mandatary } from '@inpi-marques/components'
import OverviewLinkedTransactions from 'component/division/form/options/OverviewLinkedTransactions'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface MandataryOverviewProps {
    mandatary: Mandatary,
    natureCodes: InscriptionNatureCode[],
}

const MandataryOverview: FC<MandataryOverviewProps> = ({ mandatary, natureCodes }) => {
  const selectedNatureCode: InscriptionNatureCode|undefined = CommonInscriptionService.getFirstCodeNature(natureCodes)

  return (
    <div className='row'>
      <div className='col-6'>
        <h4 className='font-weight-bold'><FormattedMessage id='overview_nature_codes_label' /></h4>
        <p className='subtitle'>{natureCodes.map((natureCode: InscriptionNatureCode) => natureCode.freeLabel || natureCode.label).join(', ')}</p>
      </div>
      <div className='col-6'>
        <h4 className='font-weight-bold'><FormattedMessage id='overview_statement_label' /></h4>
        {mandatary.oldAgent &&
          <pre className='subtitle mb-0'><span className='font-weight-bold'><FormattedMessage id={CommonInscriptionService.getOldAgentLabel(selectedNatureCode)} /> : </span><br />{mandatary.oldAgent}</pre>}
        {mandatary.newAgent &&
          <pre className='subtitle mb-0'><span className='font-weight-bold'><FormattedMessage id={CommonInscriptionService.getNewAgentLabel(selectedNatureCode)} /> : </span><br />{mandatary.newAgent}</pre>}
      </div>
      {mandatary.attachments && mandatary.attachments.length > 0 &&
        <div className='col-6'>
          <OverviewLinkedTransactions
            customTitle={<FormattedMessage id='overview_attachments_label' />}
            linkedTransaction={mandatary.attachments}
          />
        </div>}
    </div>)
}

export default MandataryOverview
