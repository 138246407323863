import {
  LOG_IN,
  LOG_OUT,
  LogAction, UPDATE_USER, UserState
} from './userStoreTypes'
/* global localStorage */

const initialUserState = {
  token: localStorage.getItem('token-marques-fo'),
  user: localStorage.getItem('user-marques-fo') ? JSON.parse(localStorage.getItem('user-marques-fo')!) : null,
  tokenETS: null
}

export function reduceUserState (state = initialUserState, action: LogAction): UserState {
  switch (action.type) {
    case LOG_IN:
      action.user.token && localStorage.setItem('token-marques-fo', action.user.token)
      action.user.user && localStorage.setItem('user-marques-fo', JSON.stringify(action.user.user))
      action.user.tokenETS && localStorage.setItem('token-marques-ets', action.user.tokenETS)
      return action.user
    case UPDATE_USER:
      action.user.user && localStorage.setItem('user-marques-fo', JSON.stringify(action.user.user))
      return {
        ...action.user
      }
    case LOG_OUT:
      localStorage.removeItem('token-marques-fo')
      localStorage.removeItem('user-marques-fo')
      localStorage.removeItem('token-marques-ets')
      return action.user
    default:
      return state
  }
}
