import React, { Dispatch, FC, ReactElement, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import TextField from '../../TextField'
import TransactionListTable from '../../../../list/TransactionListTable'
import { Transaction } from '../../../../interfaces/Transaction'
import { EventType, FieldStatus } from '../../../FormInterfaces'
import { PROCEDURE_MIFR } from '../../../../constants/ProcedureConstants'
import ModalComponent from '../../../../modal/ModalComponent'

interface NumNatTransactionTitleVerifierProps {
  inputId: string,
  verifyNumNat?: () => Promise<Transaction[]>,
  maxLength?: number,
  classNameFormGroup?: string,
  className?: string,
  loaderClassName?: string,
  value?: string,
  setValue: (value: string) => void,
  isInternationalBrand?: boolean,
  fieldStatus?: FieldStatus,
  setFieldStatus?: (fieldStatus: FieldStatus) => void,
  labelClassname?: string,
  onTransactionSelected?: (transaction: Transaction) => void,
  dispatch?: Dispatch<any>,
  label?: ReactElement|string,
  onBlur?: (event: EventType) => void,
  nameFieldStatus?: string,
  disabled?: boolean,
  required?: boolean,
  handleClose?: () => void
}

const NumNatTransactionTitleVerifier: FC<NumNatTransactionTitleVerifierProps> = ({
  inputId,
  classNameFormGroup,
  labelClassname,
  verifyNumNat,
  className,
  value,
  isInternationalBrand,
  setValue,
  fieldStatus,
  onTransactionSelected,
  loaderClassName,
  dispatch,
  setFieldStatus,
  label,
  onBlur,
  nameFieldStatus,
  disabled = false,
  required = false,
  handleClose
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  const onLabelClick = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response: Transaction[] = verifyNumNat ? await verifyNumNat() : []

      if (response.length) {
        setTransactions(response)
        setShowModal(true)
      }

      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  const onSelectTransaction = (index: number): void => {
    onTransactionSelected && onTransactionSelected(transactions[index])
    setShowModal(false)
  }

  /**
  * Permet de lancer la recherche si clic sur touche "Entrée"
  */
  const handleKeyPress = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      await onLabelClick()
    }
  }

  /**
   * A la fermeture de la modale
   */
  const onCloseModal = () => {
    handleClose && handleClose()
    setShowModal(false)
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <TextField
        inputId={inputId}
        classNameFormGroup={classNameFormGroup}
        className={className}
        labelClassname={labelClassname}
        loaderClassName={loaderClassName}
        value={value}
        label={label ?? <FormattedMessage id={`${isInternationalBrand ? 'request_identification_numnat_international' : 'request_identification_numnat'}`} />}
        placeholder={Message.form_number_placeholder}
        onChange={(event: EventType) => setValue(event.target.value?.trim())}
        fieldStatus={fieldStatus}
        nameFieldStatus={nameFieldStatus}
        dispatch={dispatch}
        resetError={setFieldStatus}
        buttonLabel={!disabled ? Message.verify_numnat : undefined}
        onLabelClick={onTransactionSelected !== undefined && !disabled ? onLabelClick : undefined}
        isLoading={isLoading}
        required={required}
        prefix={isInternationalBrand ? `${PROCEDURE_MIFR.value}-` : undefined}
        onBlur={onBlur}
        disabled={disabled}
        onKeypress={handleKeyPress}
      />
      <ModalComponent
        size='xl'
        show={showModal}
        handleClose={onCloseModal}
        title={<FormattedMessage id='modal_data_transaction_title' />}
        customContent={() =>
          (
            <TransactionListTable
              transactions={transactions}
              onSelectTransaction={onSelectTransaction}
            />)}
        hideFooter
      />
    </IntlProvider>)
}

export default NumNatTransactionTitleVerifier
