import {
  DivisionDetails, FRMI_TYPE_DIVISION_INTERNATIONAL,
  FRMI_TYPE_DIVISION_NATIONAL,
  FRMI_TYPE_EFFECT_STOP,
  FRMI_TYPE_INSCRIPTION_CORRECTION,
  FRMI_TYPE_NATIONAL_REPLACEMENT,
  FRMI_TYPE_NULLIFICATION,
  Transaction
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'
import DivisionContent from './content/DivisionContent'
import EffectStopContent from './content/EffectStopContent'
import InscriptionCorrectionContent from './content/InscriptionCorrectionContent'
import NullificationContent from './content/NullificationContent'
import ReplacementContent from './content/ReplacementContent'

interface AdditionalInformationsProps {
    transaction: Transaction
}

const AdditionalInformation: FC<AdditionalInformationsProps> = ({ transaction }) => {
  const dispatch = useDispatch()

  const onFieldBlur = (field: string, value: string): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      [field]: value
    }))
  }

  const onDivisionDetailsChanged = (divisionDetails: DivisionDetails): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      divisionDetails
    }))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_additional_information_title' /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div>
        {transaction.subProcedureType === FRMI_TYPE_INSCRIPTION_CORRECTION.value &&
          <InscriptionCorrectionContent frmi={transaction.frmiDetails} onFieldBlur={onFieldBlur} />}
        {transaction.subProcedureType === FRMI_TYPE_EFFECT_STOP.value &&
          <EffectStopContent frmi={transaction.frmiDetails} onFieldBlur={onFieldBlur} />}
        {transaction.subProcedureType === FRMI_TYPE_NULLIFICATION.value &&
          <NullificationContent frmi={transaction.frmiDetails} onFieldBlur={onFieldBlur} />}
        {transaction.subProcedureType === FRMI_TYPE_NATIONAL_REPLACEMENT.value &&
          <ReplacementContent frmi={transaction.frmiDetails} onFieldBlur={onFieldBlur} />}
        {(transaction.subProcedureType === FRMI_TYPE_DIVISION_NATIONAL.value || transaction.subProcedureType === FRMI_TYPE_DIVISION_INTERNATIONAL.value) &&
          <DivisionContent
            divisionDetails={transaction.frmiDetails?.divisionDetails ?? {}}
            onDivisionDetailsChanged={onDivisionDetailsChanged}
            isMM22bis={transaction.subProcedureType === FRMI_TYPE_DIVISION_NATIONAL.value}
          />}
      </div>
    </>
  )
}

export default AdditionalInformation
