import { TableOrderBy } from 'src'
import Message from './Message'
import { ORDER_DESC } from './RequestConstant'

export interface TableRow {
  value: number,
  label:string
}

export const ROWS_10: TableRow = {
  value: 10,
  label: Message.request_10_rows
}

export const ROWS_20: TableRow = {
  value: 20,
  label: Message.request_20_rows
}

export const ROWS_25: TableRow = {
  value: 25,
  label: Message.request_25_rows
}

export const ROWS_50: TableRow = {
  value: 50,
  label: Message.request_50_rows
}

export const ROWS_100: TableRow = {
  value: 100,
  label: Message.request_100_rows
}

export const ROWS_ALL_RESULT: TableRow = {
  value: -1,
  label: Message.request_all_result
}

export const ROWS: TableRow[] = [
  ROWS_10,
  ROWS_25,
  ROWS_50,
  ROWS_100
]

export const ROWS_WITH_ALL_RESULT: TableRow[] = [
  ROWS_10,
  ROWS_25,
  ROWS_50,
  ROWS_100,
  ROWS_ALL_RESULT
]

export const TRANSACTION_TABLE_ORDER_BY: TableOrderBy = {
  field: 'lastUpdate', order: ORDER_DESC
}
