import {
  Brand,
  DOCUMENT_DEFAULT,
  DOCUMENT_STATUS,
  DOCUMENT_TYPES,
  MOTIVES,
  PROCEDURE_NULLITY,
  PROCEDURE_REVOCATION,
  Record,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { DOCUMENT_FORMATS } from '@inpi-marques/components/src/constants/DocumentConstants'
import { sortDocumentByCreateAt } from '@inpi-marques/components/src/document/DocumentService'
import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import { toast } from 'react-toastify'
import TransactionService from 'services/transaction/TransactionService'
import store from 'store/store'
import { storeTransactionUpdateDeposit, storeTransactionUpdateFRMI } from '../../store/transaction/transactionActions'
/* global FormData */
/* global FileReader */

interface ITransactionResponse {
  deposit: { brand: {preview?: TransactionDocument, file?: TransactionDocument}}
}
class DocumentService {
  intl: IntlShape

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  /**
   *  Retourne un fichier en base64
   * @param file
   * @returns
   */
  getBase64 = (file: File|Blob): Promise<string|ArrayBuffer|null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  /**
   * Création d'un document d'une transaction
   *
   * @param document
   * @param idTransaction
   * @returns
   */
  createDocument = async (document: TransactionDocument, idTransaction?: string): Promise<TransactionDocument|null> => {
    try {
      if (idTransaction && document.file) {
        const formData = new FormData()
        formData.append('file', document.file, document.name)
        formData.append('type', document.type || '')
        formData.append('communicability', document.communicability ? document.communicability : DOCUMENT_DEFAULT)
        formData.append('archivability', 'true')
        formData.append('status', DOCUMENT_DEFAULT)
        document.additionalType && formData.append('additionalType', document.additionalType)
        if (document.createdAt) {
          formData.append('createdAt', document.createdAt)
        }
        if (document.description) {
          formData.append('description', document.description)
        }

        return await http.post(`/api/transactions/${idTransaction}/documents`, formData)
      }
    } catch (error) {
      if (error?.type !== 'serverError' && error?.message) {
        toast.error(error.message)
      } else if (typeof error === 'string' && error.indexOf('413')) {
        // 413 Request Entity Too Large
        toast.error(this.intl.formatMessage({ id: 'error_transaction_document_too_large' }))
      } else {
        toast.error(this.intl.formatMessage({ id: 'error_transaction_document_create' }))
      }
      throw error
    }
    return null
  }

  /**
   * Suppression d'un document d'une transaction
   *
   * @param internalNameDocumentToDelete
   * @param transaction
   * @returns
   */
  deleteDocument = async (internalNameDocumentToDelete: string, transaction: Transaction): Promise<boolean> => {
    try {
      if (transaction.id && internalNameDocumentToDelete) {
        await http.delete(`/api/transactions/${transaction.id}/documents/${internalNameDocumentToDelete}`)
      }
      return true
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_document_delete' }))
    }
    return false
  }

  /**
   * Modifie un document d'une transaction
   *
   * @returns
   * @param transaction
   * @param internalNameDocumentToUpdate
   * @param newDocument
   */
     updateDocument = async (transaction: Transaction, internalNameDocumentToUpdate: string, newDocument: TransactionDocument): Promise<Transaction> => {
       try {
         return await http.put(`/api/transactions/${transaction.id}/documents/${internalNameDocumentToUpdate}`, newDocument)
       } catch (error) {
         toast.error(this.intl.formatMessage({ id: 'error_transaction_document_update' }))
         return Promise.reject(error)
       }
     }

  /**
   * Création d'un document d'une marque pour les FRMI
   * @param document
   * @param transaction
   * @returns
   */
  createFRMIBrandDocument = async (document: TransactionDocument, transaction: Transaction) : Promise<Brand|null> => {
    try {
      if (transaction.id && document.file) {
        const formData = new FormData()
        formData.append('file', document.file, document.name)
        const response:Transaction = await http.post(`/api/deposits/${transaction.id}/brands/files`, formData)

        return response?.frmiDetails?.brand ?? null
      }
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_document_create' }))
    }
    return null
  }

  /**
   * Création d'un document d'une marque
   * @param document
   * @param transaction
   * @returns
   */
  createBrandDocument = async (document: TransactionDocument, transaction: Transaction) : Promise<TransactionDocument|null> => {
    try {
      if (transaction.id && document.file) {
        const formData = new FormData()
        formData.append('file', document.file, document.name)
        const response:ITransactionResponse = await http.post(`/api/deposits/${transaction.id}/brands/files`, formData)

        return response.deposit.brand.preview ?? response.deposit.brand.file ?? null
      }
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_document_create' }))
    }
    return null
  }

  /**
   * Récupération de la prévisualisation du document de la marque
   * @param transaction
   * @param document
   * @returns
   */
  getBrandPreviewDocument = async (transaction: Transaction, document?: TransactionDocument): Promise<string|null> => {
    try {
      if (transaction.id && document?.internalName) {
        return await http.get(`/api/deposits/${transaction.id}/brands/files/${encodeURIComponent(document.internalName)}`)
      }
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_document_create' }))
    }
    return null
  }

  /**
   * Récupération de la prévisualisation du document de la marque d'un record
   * @param document
   * @param record
   * @returns
   */
  getBrandPreviewDocumentRecord = async (document: TransactionDocument, record: Record): Promise<string|null> => {
    try {
      if (record.id && document.internalName) {
        return await http.get(`/api/records/${record.id}/brands/files/${encodeURIComponent(document.internalName)}`)
      }
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'transaction_brand_preview_error' }))
    }
    return null
  }

  /**
   * Méthode permettant de créer puis de récupérer la prévisualisation du document précédemment uploadé
   *
   * @param document
   * @param transaction
   * @param brand
   * @returns
   */
  createAndGetFRMIDocumentPreview = async (document: TransactionDocument, transaction: Transaction, brand: Brand): Promise<string|null> => {
    const updatedBrand: Brand|null = await this.createFRMIBrandDocument(document, transaction)
    const createdDocument: TransactionDocument|null = updatedBrand?.file
    const createdPreview: TransactionDocument|null = updatedBrand?.preview
    if (createdDocument) {
      let previewData: string|null = null
      // Si c'est un mp4, pas de preview, on retourne le fichier en base64 directement après la création
      if (document.file && (document.format === DOCUMENT_FORMATS.MP4 || document.format === DOCUMENT_FORMATS.MP3)) {
        const based64File: string | ArrayBuffer |null = await this.getBase64(document.file)
        if (!(based64File instanceof ArrayBuffer)) {
          previewData = based64File
        }
      } else if (createdPreview) {
        previewData = await this.getBrandPreviewDocument(transaction, createdPreview)
      } else {
        previewData = await this.getBrandPreviewDocument(transaction, createdDocument)
      }

      if (previewData) {
        // On récupère le store au cas où le brand aurait changé pendant l'upload
        const updatedTransaction: Transaction = store.getState().transaction
        const storeBrand: Brand | undefined = updatedTransaction.frmiDetails?.brand
        const newBrand: Brand = storeBrand ?? brand
        store.dispatch(storeTransactionUpdateFRMI({
          ...transaction.frmiDetails,
          brand: {
            ...newBrand,
            file: { ...createdDocument, previewData, status: DOCUMENT_STATUS.ACCEPTED }
          }
        }))
      }
      return previewData
    }
    return null
  }

  /**
   * Méthode permettant de créer puis de récupérer la prévisualisation du document précédemment uploadé
   *
   * @param document
   * @param transaction
   * @param brand
   * @returns
   */
  createAndGetDocumentPreview = async (document: TransactionDocument, transaction: Transaction, brand: Brand): Promise<string|null> => {
    const createdDocument: TransactionDocument|null = await this.createBrandDocument(document, transaction)
    if (createdDocument) {
      let previewData: string|null = null
      // Si c'est un mp4, pas de preview, on retourne le fichier en base64 directement après la création
      if (document.file && (document.format === DOCUMENT_FORMATS.MP4 || document.format === DOCUMENT_FORMATS.MP3)) {
        const based64File: string | ArrayBuffer |null = await this.getBase64(document.file)
        if (!(based64File instanceof ArrayBuffer)) {
          previewData = based64File
        }
      } else {
        previewData = await this.getBrandPreviewDocument(transaction, createdDocument)
      }

      if (previewData) {
        // On récupère le store au cas où le brand aurait changé pendant l'upload
        const updatedTransaction: Transaction = store.getState().transaction
        const storeBrand: Brand | undefined = updatedTransaction.deposit?.brand
        const newBrand: Brand = storeBrand ?? brand
        store.dispatch(storeTransactionUpdateDeposit({ brand: { ...newBrand, file: { ...createdDocument, previewData } } }))
      }
      return previewData
    }
    return null
  }

  /**
   * Récupère un document d'une transaction
   */
  getDocumentFile = async (idTransaction: string, internalName: string): Promise<Blob> => {
    try {
      return await http.get(`/api/transactions/${idTransaction}/documents/${encodeURIComponent(internalName)}`, { responseType: 'blob' })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Récupère un pdf récépissé comportant la liste de tous les documents associés à la transaction
   */
  getDocumentsReceipt = async (idTransaction: string): Promise<Blob|null> => {
    try {
      return await http.get(`/api/transactions/${idTransaction}/documents-receipt`, { responseType: 'blob', params: { isFromBo: false } })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * On check si le fichier Exposé des moyens est obligatore
   *
   * OPPOSITION : le document n'est pas obligatoire
   * NULLITY : le document est obligatoire
   * REVOCATION : le document est obligatoire sauf si le motif xx existe
   * @param transaction
   * @returns
   */
   isStatementOfMeanRequired = (transaction: Transaction): boolean =>

     transaction.procedureType === PROCEDURE_NULLITY.value ||
      (
        transaction.procedureType === PROCEDURE_REVOCATION.value &&
        !((transaction.opposition?.motives.length === 1) && (transaction.opposition?.motives[0] === MOTIVES.BRAND_NOT_SIGNIFICANT_USE))
      )

   /**
   * Si certaines valeurs du document on été éditée
   *
   * @param newDocument
   * @param oldDocument
   * @returns
   */
   wasEdited = (newDocument: TransactionDocument, oldDocument: TransactionDocument): boolean =>
     newDocument.communicability !== oldDocument.communicability ||
        newDocument.description !== oldDocument.description

  /**
   * Télécharge le dernier fichier récapitulatif généré d'une transaction
   */
  downloadLastOverviewFile = async (transaction?: Transaction): Promise<Blob|ArrayBuffer|null> => {
    if (!transaction) {
      return null
    }

    const overviewDocuments: TransactionDocument[] = transaction.documents?.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.OVERVIEW) ?? []

    if (overviewDocuments.length) {
      /**
       * On les tri dans l'ordre croissant de la date de création
       * pour récupérer le dernier document généré
       */
      sortDocumentByCreateAt(overviewDocuments)
      const lastDocumentInternalName: string|undefined = overviewDocuments[overviewDocuments.length - 1].internalName
      return lastDocumentInternalName && transaction.id ? this.getDocumentFile(transaction.id, lastDocumentInternalName) : null
    }

    return await TransactionService.getOverview(transaction.id)
  }
}

export default new DocumentService()
