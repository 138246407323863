import React, { FC, useCallback, useEffect, useState } from 'react'
import { EventType, ProductClass, ProductService as CommonProductService, ProductAndService, TextField, ProductAndServiceListView } from '@inpi-marques/components'
import { useIntl } from 'react-intl'
import debounce from 'lodash.debounce'

interface ProductAndServicesCartProps {
  productClasses: ProductClass[],
  onProductClassesEdited: (productClasses: ProductClass[]) => void
}

/**
 * Panier des produits et services (Etape de validation)
 */
const ProductAndServicesCart: FC<ProductAndServicesCartProps> = ({
  productClasses, onProductClassesEdited
}) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [displayedProductClasses, setDisplayedProductClasses] = useState<ProductClass[]>(productClasses)
  const intl = useIntl()

  useEffect(() => {
    setDisplayedProductClasses(CommonProductService.searchProductsByName(productClasses, searchValue))
  }, [productClasses])

  const updateSearch = async (): Promise<void> => {
    setDisplayedProductClasses(CommonProductService.searchProductsByName(productClasses, searchValue))
  }

  const delayedSearch = useCallback(debounce(updateSearch, 500), [searchValue])

  useEffect(() => {
    delayedSearch()
    return delayedSearch.cancel
  }, [delayedSearch])

  /**
   * A la suppression d'une classe de produits
   * @param deletedClass
   */
  const onClassDeleted = (deletedClass: ProductClass): void => {
    onProductClassesEdited(productClasses.filter((productClass: ProductClass) =>
      deletedClass.ref !== productClass.ref
    ))
  }

  /**
   * A la suppression d'un produit
   * @param editedProductClass
   * @param deletedProduct
   */
  const onProductDeleted = (editedProductClass: ProductClass, deletedProduct: ProductAndService): void => {
    onProductClassesEdited(productClasses.map((productClass: ProductClass) =>
      productClass.ref === editedProductClass.ref
        ? {
          ...editedProductClass,
          products: editedProductClass.products.filter((product: ProductAndService) =>
            product.name !== deletedProduct.name
          )
        }
        : productClass
    ).filter((productClasses: ProductClass) => productClasses.products.length))
  }

  return (
    <div className='mt-5'>
      <div className='d-flex justify-content-center mb-5'>
        <TextField
          inputId='searchValue'
          value={searchValue}
          placeholder={intl.formatMessage({ id: 'products_and_services_list_filter_placeholder' })}
          onChange={(event: EventType) => setSearchValue(event.target.value)}
        />
      </div>
      {displayedProductClasses.length > 0 &&
        <>
          <ProductAndServiceListView
            onProductClassDelete={onClassDeleted}
            onProductDelete={onProductDeleted}
            productClasses={displayedProductClasses}
          />
        </>}
    </div>
  )
}

export default ProductAndServicesCart
