import Message from './Message'

export const CONFORMITY_TO_EXAM = 'to_exam'
export const CONFORMITY_ACCEPT = 'accept'
export const CONFORMITY_NOT_REJET = 'rejet'

export const PRIORITY_CLAIM_STATUS_OPTIONS = [
  {
    value: CONFORMITY_TO_EXAM,
    label: Message.to_exam
  },
  {
    value: CONFORMITY_ACCEPT,
    label: Message.accept
  },
  {
    value: CONFORMITY_NOT_REJET,
    label: Message.rejet
  }
]
