import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EventType, isFilled, SubmitButton, TextField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { AssociatedBrand } from '../interfaces/renewal/Renewal'

interface AssociatedBrandsProps {
    associatedBrands: AssociatedBrand[],
    editing?: boolean
    onAssociatedBrandsUpdated: (associatedBrands: AssociatedBrand[]) => void,
    showWarning?: boolean,
    showMillesime?: boolean
}

/**
 * Composant permettant d'éditer les marques associées à un renouvellement
 */
const AssociatedBrands: FC<AssociatedBrandsProps> = ({ associatedBrands, editing = false, onAssociatedBrandsUpdated, showWarning = false, showMillesime = false }) => {
  const onUpdate = (event: EventType, editedIndex: number) => {
    const { name, value } = event.target
    const updatedAssociatedBrands: AssociatedBrand[] = [...associatedBrands]

    onAssociatedBrandsUpdated(updatedAssociatedBrands?.map((associatedBrand: AssociatedBrand, index: number) => editedIndex === index ? { ...associatedBrand, [name]: value } : { ...associatedBrand }))
  }

  const onDeleteClick = (index: number) => {
    const updatedAssociatedBrands = [...associatedBrands]
    if (associatedBrands.length > index) {
      updatedAssociatedBrands.splice(index, 1)
    }

    onAssociatedBrandsUpdated(updatedAssociatedBrands)
  }

  const getLabel = (associatedBrand: AssociatedBrand) => {
    let label: string = ''
    if (associatedBrand?.millesime && isFilled(associatedBrand?.millesime)) {
      label = associatedBrand?.millesime
    }
    if (associatedBrand?.numNat && isFilled(associatedBrand?.numNat)) {
      if (isFilled(label)) {
        label += '-'
      }
      label += associatedBrand?.numNat
    }
    return label
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='my-3'>
        <span className={`d-block ${showWarning ? '' : 'pb-3'}`}>
          <FormattedMessage id='options_renewal_associated_brands_description' />
        </span>
        {showWarning &&
          <span className='d-block pb-3 font-italic'>
            <FormattedMessage id='options_renewal_associated_brands_warning' />
          </span>}
        {associatedBrands?.map((associatedBrand: AssociatedBrand, index: number) => (
          <div key={`associatedBrand-${index}`} className='row m-0'>
            {editing &&
              <>
                {showMillesime &&
                  <TextField
                    className='mr-3'
                    inputId='millesime'
                    value={associatedBrand.millesime}
                    onChange={(e: EventType) => onUpdate(e, index)}
                    placeholder={Message.options_renewal_associated_brands_millesime}
                  />}
                <TextField
                  className=''
                  inputId='numNat'
                  value={associatedBrand.numNat}
                  onChange={(e: EventType) => onUpdate(e, index)}
                  placeholder={Message.options_renewal_associated_brands_numNat}
                />
                <div className='d-flex flex-column justify-content-center mb-3 ml-3'>
                  <FontAwesomeIcon
                    icon={faTrash}
                    className='text-secondary cursor-pointer'
                    onClick={() => onDeleteClick(index)}
                  />
                </div>
              </>}
            {!editing &&
              <span>{getLabel(associatedBrand)}</span>}
          </div>
        ))}
        {editing &&
          <div>
            <SubmitButton
              className='btn-link-primary'
              onClick={() => onAssociatedBrandsUpdated([...associatedBrands, { numNat: '', millesime: showMillesime ? '' : undefined }])}
            >
              <FormattedMessage id='options_renewal_add_associated_brands' />
            </SubmitButton>
          </div>}
      </div>
    </IntlProvider>
  )
}

export default AssociatedBrands
