import { Preview, PreviewLoader, Title, Transaction, TransactionDocument } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import DocumentService from '../../../../services/document/DocumentService'

interface TitleFilePreviewProps {
  title: Title,
  transaction?: Transaction,
  recordId?: string
}

const TitleFilePreview: FC<TitleFilePreviewProps> = ({
  title,
  transaction,
  recordId
}) => {
  const [brandDocumentPreview, setBrandDocumentPreview] = useState<string>()
  const [brandDocument, setBrandDocument] = useState<TransactionDocument>()
  const [loadingDocument, setLoadingDocument] = useState(true)

  useEffect(() => {
    setBrandDocument(title?.file || title?.details?.brand?.file || title?.details?.brand?.preview)
    getBrandFile().then((file) => {
      setBrandDocumentPreview(file)
      setLoadingDocument(false)
    })
  }, [title, recordId])

  const getBrandFile = async () => {
    // Si le fichier vient de la base publique
    if (title?.file?.url || title?.details?.brand?.file?.url) {
      return title?.file?.url || title?.details?.brand?.file?.url
    // Si le fichier vient du référentiel
    } else if (recordId && (title?.file || title?.details?.brand?.file || title?.details?.brand?.preview)) {
      return DocumentService.getBrandPreviewDocumentRecord(title?.file || title?.details?.brand?.file || title?.details?.brand?.preview, { id: recordId })
    // Si le fichier de la marque possède déjà une preview, on la récupère
    } else {
      return transaction && DocumentService.getBrandPreviewDocument(transaction, title?.file || title?.details?.brand?.file || title?.details?.brand?.preview)
    }
  }

  return (
    <div className='p-0'>
      {brandDocumentPreview && brandDocument && !loadingDocument && title &&
        <Preview
          file={brandDocumentPreview}
          document={brandDocument}
          className='mb-3'
        />}
      {loadingDocument && <PreviewLoader />}
    </div>
  )
}

export default TitleFilePreview
