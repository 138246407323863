import React, { FC, useEffect, useState } from 'react'
import {
  CardBlock,
  CheckboxField,
  Contest,
  DATE_ISO,
  DateField,
  DateUtils,
  DOCUMENT_TYPES,
  ErrorField,
  EventType,
  FieldStatus,
  HelpBlock,
  INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD,
  INSCRIPTION_CONTEST_TYPES,
  LinkedTransaction,
  PreviousTransactions,
  SelectField,
  TextArea,
  TextField,
  Transaction,
  TransactionDocument,
  LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION
} from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateInscription } from 'store/transaction/transactionActions'
import InscriptionService from 'services/inscription/InscriptionService'
import StoreService from 'services/StoreService'
import DocumentsBlock from '../correction/DocumentsBlock'

interface ContestFormProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    documents: TransactionDocument[],
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentEdited: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument)=> void,
}

/**
 * Etape de description du concours pour l'inscription d'une récompense ou d'un palmarès
 */
const ContestForm: FC<ContestFormProps> = ({ transaction, fieldStatus, documents, onDocumentAdded, onDocumentEdited, onDocumentDeleted }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [contest, setContest] = useState<Contest>(InscriptionService.initContest(transaction.inscription?.contest))

  /** Si les dates des début et de fin sont les mêmes */
  const [areSameDate, setAreSameDate] = useState<boolean>(contest.startDate === contest.endDate)

  useEffect(() => {
    setContest(InscriptionService.initContest(transaction.inscription?.contest))
    setAreSameDate(transaction.inscription?.contest?.startDate === transaction.inscription?.contest?.endDate)
  }, [transaction.inscription?.contest])

  /**
     * Au changement des champs date
     * @param event
     */
  const onDateFieldChanged = (event: EventType): void => {
    const { name, value } = event.target

    let updatedContest: Contest = contest

    // S'il s'agit des deux même date, alors on set la date de fin également
    if (areSameDate) {
      updatedContest = {
        ...updatedContest,
        startDate: DateUtils.formatToBeginOfDay(value),
        endDate: DateUtils.formatToBeginOfDay(value)
      }
    } else {
      updatedContest = {
        ...updatedContest,
        [name]: DateUtils.formatToBeginOfDay(value)
      }
    }

    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      contest: updatedContest
    }))
  }

  /**
     * Au blur des champs texte
     */
  const onFieldBlur = (): void => {
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      contest
    }))
  }

  /**
     * Au choix des dates égales
     */
  const onSameDateChanged = (): void => {
    const updatedValue: boolean = !areSameDate
    setAreSameDate(updatedValue)

    if (updatedValue) {
      dispatch(storeTransactionUpdateInscription({
        ...transaction.inscription,
        contest: {
          ...contest,
          endDate: contest.startDate
        }
      }))
    }
  }

  /**
     * A l'édition d'un champs texte
     * @param event
     */
  const onFieldChanged = (event: EventType): void => {
    const { name, value } = event.target

    setContest({
      ...contest,
      [name]: value
    })
  }

  /**
   * A la mise à jour des transactions liées
   * @param linkedTransactions
   */
  const onLinkedTransactionChanged = (linkedTransactions: LinkedTransaction[]): void => {
    StoreService.changeStore({
      ...transaction,
      inscription: {
        ...transaction.inscription,
        contest: {
          ...contest,
          linkedTransactions
        }
      }
    }
    )
  }

  /**
   * Mise à jour du type de récompense.
   * @param event
   */
  const onTypeChanged = (event: EventType): void => {
    const { value } = event.target
    let updatedContest: Contest = contest

    // Si c'est une palmares, on clean le champs texte correspondant aux récompenses industrielles
    if (value !== INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD.value && contest.rewardKind) {
      updatedContest = { ...updatedContest, rewardKind: undefined }
    }

    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      contest: {
        ...updatedContest,
        type: value
      }
    }))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='contest_title' /></h1>
          <span className='subtitle'><FormattedMessage id='contest_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row'>
        <div className='col-12 p-0'>
          <SelectField
            inputId='type'
            classNameFormGroup='col-4'
            label={<FormattedMessage id='contest_form_type_label' />}
            required
            fieldStatus={fieldStatus}
            options={INSCRIPTION_CONTEST_TYPES}
            onChange={onTypeChanged}
            placeholder={intl.formatMessage({ id: 'contest_form_type_placeholder' })}
            value={contest.type}
          />
        </div>

        <TextArea
          inputId='title'
          classNameFormGroup='col-6'
          label={<FormattedMessage id='contest_form_title_label' />}
          value={contest.title}
          onChange={onFieldChanged}
          onBlur={onFieldBlur}
          required
          fieldStatus={fieldStatus}
          maxLength={300}
          rows={5}
        />
        <div className='row col-12 m-0 p-0'>
          <TextField
            inputId='place'
            classNameFormGroup='col-4'
            label={<FormattedMessage id='contest_form_place_label' />}
            value={contest.place}
            onChange={onFieldChanged}
            onBlur={onFieldBlur}
            required
            fieldStatus={fieldStatus}
          />
        </div>
        <div className='row col-12 m-0 p-0'>
          <DateField
            inputId='startDate'
            label={<FormattedMessage id='contest_form_start_date_label' />}
            classNameFormGroup='col-4'
            value={DateUtils.formateDateToFormat(transaction.inscription?.contest?.startDate, DATE_ISO) ?? ''}
            fieldStatus={fieldStatus}
            onChange={onDateFieldChanged}
            required
          />
          <div className='row col-4 align-items-center pl-5'>
            <CheckboxField
              inputId='areSameDate'
              className='mb-0'
              label={<FormattedMessage id='contest_form_are_same_date_label' />}
              checked={areSameDate}
              onChange={onSameDateChanged}
            />
          </div>
          {!areSameDate &&
            <DateField
              inputId='endDate'
              classNameFormGroup='col-4'
              value={DateUtils.formateDateToFormat(transaction.inscription?.contest?.endDate, DATE_ISO) ?? ''}
              label={<FormattedMessage id='contest_form_end_date_label' />}
              onChange={onDateFieldChanged}
              fieldStatus={fieldStatus}
              required
            />}
        </div>
        {contest.type === INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD.value &&
          <TextArea
            inputId='rewardKind'
            classNameFormGroup='col-4 mt-4'
            label={<FormattedMessage id='contest_form_reward_kind_label' />}
            value={contest.rewardKind}
            onChange={onFieldChanged}
            onBlur={onFieldBlur}
            required
            fieldStatus={fieldStatus}
            maxLength={300}
          />}
        <div className='col-12'>
          <PreviousTransactions
            linkedTransactions={contest.linkedTransactions ?? []}
            onLinkedTransactionChanged={onLinkedTransactionChanged}
            transaction={transaction}
            isEditable
            linkedTransactionType={LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION.value}
          />
        </div>
        <CardBlock className='col-12 p-0 mt-4' header={<FormattedMessage id='correction_documents_block_title' />} shadow>
          <HelpBlock>
            <FormattedMessage id='documents_communicability_warning_text' />
          </HelpBlock>
          <DocumentsBlock
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            title={<FormattedMessage id='inscription_ranking_files_label' />}
            documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_RANKING) ?? []}
            documentType={DOCUMENT_TYPES.INSCRIPTION_RANKING}
            transaction={transaction}
            required
          />
          <DocumentsBlock
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            title={<FormattedMessage id='inscription_other_files_label' />}
            documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_OTHER) ?? []}
            documentType={DOCUMENT_TYPES.INSCRIPTION_OTHER}
            transaction={transaction}
          />
          {fieldStatus.documents &&
            <div>
              <ErrorField message={fieldStatus.documents} />
            </div>}
        </CardBlock>
      </div>
    </>
  )
}

export default ContestForm
