import React, { FC, useEffect, useState } from 'react'
import { Country, Priority, SubmitButton } from '@inpi-marques/components'
import OverviewPriorities from './OverviewPriorities'
import ContentService from '../../services/content/ContentService'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface PriorityListFormProps {
  priorityList: Priority[],
  editPriority: (index: number) => void,
  deletePriority: (index: number) => void,
  addPriority: () => void
}

const PriorityListForm: FC<PriorityListFormProps> = (
  {
    priorityList,
    deletePriority,
    editPriority,
    addPriority
  }) => {
  const [countries, setCountries] = useState<Country[]>([])
  useEffect(() => {
    let unmounted = false
    if (countries.length === 0) {
      ContentService.getCountries().then(result => {
        if (!unmounted && result) {
          setCountries(result)
        }
      })
    }
    return () => {
      unmounted = true
    }
  }, [])

  return (
    <>
      <div>
        <SubmitButton
          className='btn-select btn-outline-primary btn-add'
          onClick={() => addPriority()}
        >
          <FormattedMessage id='options_add_priority' />
          <FontAwesomeIcon className='ml-2' icon={faPlus} />
        </SubmitButton>
      </div>
      {priorityList && priorityList.length > 0 && (
        <OverviewPriorities
          priorities={priorityList}
          countries={countries}
          deletePriority={deletePriority}
          editPriority={editPriority}
          className='mt-5'
        />
      )}
    </>
  )
}

export default PriorityListForm
