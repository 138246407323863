import {
  DateUtils,
  OfficialDocument,
  OfficialDocumentRequested,
  ROWS_10,
  SubmitButton,
  Table,
  TransactionDocument
} from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import Message from '../../../../constants/Message'
import { FormattedMessage, useIntl } from 'react-intl'
import OfficialDocumentService from '../../../../services/officialDocument/OfficialDocumentService'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

interface OfficielDocumentTypesOverviewProps {
  officialDocument?: OfficialDocument,
  handleDownload?: (document?:TransactionDocument, internalName?: string) => void,
  canDownloadDocument?: (internalName?: string) => boolean
}

/**
 * Composant permettant d'afficher la liste des documents officiels demandés
 * sous forme de tableau avec sur chaque ligne le type de document, d'envoi, la quantité et les infos du titres
 *
 * @param officialDocument - Objet de la demande de documents officiels
 * @param handleDownload
 * @param canDownloadDocument
 * @constructor
 */
const OverviewOfficielDocumentTypes: FC<OfficielDocumentTypesOverviewProps> = ({ officialDocument, handleDownload, canDownloadDocument }) => {
  const intl = useIntl()
  const [indexTooltip, setIndexTooltip] = useState<number | undefined>()

  /** Page courante du tableau */
  const [page, setPage] = useState<number>(0)

  /** Nombre d'éléments par page du tableau */
  const nbItemsPerPage: number = ROWS_10.value

  /** On récupère un tableau paginé de documents officiels */
  const paginatedOfficialDocumentsRequested: OfficialDocumentRequested[][] = officialDocument?.officialDocumentsRequested ? OfficialDocumentService.getPaginatedOfficialDocumentsRequested(officialDocument?.officialDocumentsRequested, nbItemsPerPage) : []

  const getTableTitle = () => {
    let tableTitle: {label: string, className: string}[] = [
      { label: Message.official_documents_types_table_type_label, className: 'table-column-label w-15' },
      { label: Message.official_documents_types_table_numnat_label, className: 'table-column-label w-15' },
      { label: Message.official_documents_types_table_deposit_date_label, className: 'table-column-label w-12' },
      { label: Message.official_documents_types_table_brand_name_label, className: 'table-column-label w-13' }
    ]

    // Ajouter colonnes quantités suivant les types d'envoi choisis
    if (officialDocument?.officialDocumentsRequested?.some(officialDocument => officialDocument?.usePostalRoute)) {
      tableTitle = [...tableTitle, { label: Message.official_documents_requested_column_label_quantity, className: 'table-column-label w-10' }]
    }
    if (officialDocument?.officialDocumentsRequested?.some(officialDocument => officialDocument?.useDAS)) {
      tableTitle = [...tableTitle, { label: Message.official_documents_requested_column_label_quantity_das, className: 'table-column-label w-10' }]
    }
    if (officialDocument?.officialDocumentsRequested?.some(officialDocument => officialDocument?.usePdfPortal)) {
      tableTitle = [...tableTitle, { label: Message.official_documents_requested_column_label_quantity_pdf, className: 'table-column-label w-10' }]
    }

    tableTitle = [...tableTitle,
      { label: Message.official_documents_types_table_send_type_label, className: 'table-column-label w-10' },
      { label: '', className: 'table-column-label w-5' }
    ]

    return tableTitle
  }

  const getSendingTypesLabelForRequest = (officialDocumentRequested: OfficialDocumentRequested) => {
    let sendTypes: string[] = []
    if (officialDocumentRequested.usePostalRoute) {
      sendTypes = [...sendTypes, 'mail']
    }
    if (officialDocumentRequested.useDAS) {
      sendTypes = [...sendTypes, 'das']
    }
    if (officialDocumentRequested.usePdfPortal) {
      sendTypes = [...sendTypes, 'pdf']
    }
    return sendTypes
  }

  return (
    <>
      {
        officialDocument &&
          <Table
            tableTitle={getTableTitle()}
            tableClassName='documents-table'
            actualPage={page + 1}
            onChangePagination={(value) => setPage(value - 1)}
            showPagination
            totalPage={paginatedOfficialDocumentsRequested.length}
          >
            <tbody>
              {paginatedOfficialDocumentsRequested[page] &&
            paginatedOfficialDocumentsRequested[page].map((officialDocumentRequested: OfficialDocumentRequested, index: number) => {
              const documentIndex: number = (nbItemsPerPage * page) + index
              return (
                <tr key={`-${documentIndex}`}>
                  <td>{officialDocumentRequested.officialDocumentType?.label}</td>
                  <td>{officialDocumentRequested.title?.numNat ?? officialDocumentRequested.copyNum}</td>
                  <td>{DateUtils.formatDateFr(officialDocumentRequested.title?.depositDate ?? officialDocumentRequested.arrivalDate)}</td>
                  <td>{officialDocumentRequested.title?.text ?? '-'}</td>
                  {officialDocument?.officialDocumentsRequested?.some(officialDocument => officialDocument?.usePostalRoute) && <td>{officialDocumentRequested?.usePostalRoute ? officialDocumentRequested?.quantity : '-'}</td>}
                  {officialDocument?.officialDocumentsRequested?.some(officialDocument => officialDocument?.useDAS) && <td>{officialDocumentRequested?.useDAS ? 1 : '-'}</td>}
                  {officialDocument?.officialDocumentsRequested?.some(officialDocument => officialDocument?.usePdfPortal) && <td>{officialDocumentRequested?.usePdfPortal ? 1 : '-'}</td>}
                  <td>
                    {getSendingTypesLabelForRequest(officialDocumentRequested)?.map((value) => (intl.formatMessage({ id: `official_documents_types_table_sending_by_${value}_label` }))).join(', ')}
                    {officialDocumentRequested.useDAS && (
                      <OverlayTrigger
                        show={indexTooltip === index}
                        placement='bottom'
                        overlay={(props) => (
                          <Tooltip id={`button-tooltip-${index}`} {...props}>
                            <FormattedMessage id='contact_email_label' />: {officialDocumentRequested.email}
                            <br />
                            <FormattedMessage id='contact_secondary_email_label' />: {officialDocumentRequested.secondaryEmail}
                          </Tooltip>
                        )}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className='ml-2'
                          onMouseEnter={() => setIndexTooltip(index)}
                          onMouseLeave={() => setIndexTooltip(undefined)}
                        />
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    {canDownloadDocument && canDownloadDocument(officialDocumentRequested.internalNameDocument) &&
                      <SubmitButton
                        tooltip={intl.formatMessage({ id: 'official_documents_types_table_download_file_label' })}
                        onClick={() => handleDownload && handleDownload(undefined, officialDocumentRequested.internalNameDocument)}
                        className='btn btn-link text-primary ml-2 cursor-pointer'
                        replaceOnLoad
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </SubmitButton>}
                  </td>
                </tr>
              )
            })}
            </tbody>
          </Table>
      }
      {
        !officialDocument?.officialDocumentsRequested && <div><FormattedMessage id='none' /></div>
      }

    </>
  )
}

export default OverviewOfficielDocumentTypes
