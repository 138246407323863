import Message from '@inpi-marques/components/src/constants/Message'

export const FIELD_NUMNAT = 'numNat'
export const FIELD_INTERNAL_REFERENCE = 'internalReference'
export const FIELD_PROCEDURE_TYPE = 'procedureType'
export const FIELD_BRAND_MODEL = 'brandModel'
export const FIELD_ARRIVAL_DATE = 'arrivalDate'
export const FIELD_EXPIRY_DATE = 'expiryDate'
export const FIELD_EXAMINATOR = 'attributed.examinator.lastname'
export const FIELD_LAST_COMMENT = 'comments.last'
export const FIELD_COMMENT_FOND = 'comments.fond'
export const FIELD_LAST_NOTIF = 'notifications.last'
export const FIELD_FOUNDATIONS = 'opposition.foundations'
export const FIELD_MOTIVES = 'opposition.motives'
export const FIELD_CLASS = 'deposit.productsAndServicesVersions.productClasses.ref'
export const FIELD_EXAM = 'exams.examGroups'
export const FIELD_LAST_UPDATE = 'lastUpdate'
export const FIELD_STATUS = 'status'
export const FIELD_SUB_PROCEDURE_TYPE = 'subProcedureType'
export const FIELD_BRAND_TYPE = 'deposit.brand.type'
export const FIELD_READ = 'isRead'
export const FIELD_BRAND_NUMNAT = 'brand.numnat'
export const FIELD_OPPOSITION_END_DATE = 'oppositionEndDate'
export const FIELD_DOCUMENT_TYPE_LABEL = 'lastFile'
export const FIELD_DOCUMENT_DATE = 'lastFileDate'
export const FIELD_SUSPENSION_TYPE = 'opposition.suspensions.type'
export const FIELD_SAME_MA = ''
export const FIELD_INSCRIPTION_TYPE = ''
export const FIELD_AUDITION = 'opposition.audition'
export const FIELD_RESPONSE_DELAY = 'opposition.delay'
export const FIELD_STATE_OF_EXCHANGE = ''
export const FIELD_PU = ''

export const INSCRIPTION_ATTACHMENT_TABLE_TITLES = [
  { label: '' },
  { label: Message.inscription_attachment_numnat_label },
  { label: Message.inscription_attachment_procedure_label },
  { label: Message.contributor_agent }
]
