import store from '../../../../../store/store'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import {
  containsErrors,
  TransactionDocument, ContestedBrandValidator
} from '@inpi-marques/components'

class ContestedRequestValidator {
  validateOnChangeStepContestedRequest = (documents: TransactionDocument[], procedureType: string) => {
    const contestedRequest = store.getState().transaction.opposition?.contestedRequest
    const fieldStatus = ContestedBrandValidator.validateContestedBrand(contestedRequest, documents, procedureType)
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new ContestedRequestValidator()
