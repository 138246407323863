import Payment from 'component/payment/Payment'
import { PAYMENT_URL_RENUNCIATION } from 'constants/PaymentConstants'
import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import RenunciationForm from './form/RenunciationForm'

const RenunciationRoutes: FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <RenunciationForm isNewTransaction />} />
    <Route path={`${match.url}/:id/edition`} component={RenunciationForm} />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment transactionId={props.match.params.id} state={props.match.params.state} type={PAYMENT_URL_RENUNCIATION} />}
    />
  </Switch>
)

export default RenunciationRoutes
