import React, { FC, useEffect, useState } from 'react'
import { Motive } from '@inpi-marques/components'

interface MotiveListProps {
    motiveCodes: string[],
    procedureType?: string,
    getAllMotives?: (procedure?: string) => Promise<Motive[]>,
    cancelRequest: () => void
}

const MotiveList: FC<MotiveListProps> = ({ motiveCodes, procedureType, getAllMotives, cancelRequest }) => {
  const [motives, setMotives] = useState<Motive[]>()

  useEffect(() => {
    getAllMotives && getAllMotives(procedureType).then((response: Motive[]) => {
      setMotives(response)
    })

    return () => { cancelRequest() }
  }, [])
  return (
    <ul>
      {motives && motiveCodes.map((code: string, key: number) =>
        <li key={key}>
          {motives.find((motive: Motive) =>
            motive.code === code
          )?.label}
        </li>
      )}
    </ul>
  )
}

export default MotiveList
