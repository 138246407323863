import React, { FC } from 'react'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubmitButton } from '../../index'

interface ExamButtonProps {
  className?: string,
  isFilled: boolean,
  onClick?: () => void,
  disabled?: boolean
}

const ExamButton: FC<ExamButtonProps> = ({
  className = '',
  isFilled,
  onClick,
  disabled
}) => {
  return (
    <SubmitButton
      className={`btn btn-icon-primary exam-button ${isFilled ? 'exam-button--filled' : ''} ${className}`}
      onClick={onClick}
      replaceOnLoad
      disabled={disabled}
    >
      <FontAwesomeIcon icon={faCheck} size='xs' />
    </SubmitButton>)
}

export default ExamButton
