import React, { FC, useEffect, useState } from 'react'

import {
  CardBlock,
  Comment,
  FO_USER_COMMENT_TYPE,
  FRMI,
  OMPIFees,
  Priority,
  PROCEDURE_FRMI,
  Seniority,
  Transaction,
  UE
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import IntervenantsOverview from '../transactions/overview/IntervenantsOverview'
import DocumentsOverview from '../transactions/overview/DocumentsOverview'
import OverviewTitles from '../inscription/form/titles/OverviewTitles'
import OverviewSeniorities from './form/senioritiesClaimed/OverviewSeniorities'
import OverviewCountries from './form/countries/OverviewCountries'
import OverviewColorsClaimed from './form/colorsClaimed/OverviewColorsClaimed'
import OverviewVariousIndications from './form/variousIndications/OverviewVariousIndications'
import BrandReproductionOverview from './form/brandReproduction/BrandReproductionOverview'
import FeesAndTaxesOverview from './form/feesAndTaxes/FeesAndTaxesOverview'
import ProductsAndServicesOverview from './form/ProductsAndServices/ProductsAndServicesOverview'
import OverviewLimitedProductsAndServices
  from './form/limitedProductsAndServicesVersions/OverviewLimitedProductsAndServices'
import FRMIService from '../../services/frmi/FRMIService'
import { Country } from '@inpi-marques/components/src/interfaces/frmi/FRMI'
import OverviewPrioritiesFRMI from './form/priorities/OverviewPrioritiesFRMI'

interface FRMIOverviewProps {
    transaction: Transaction,
    isUserActive?: boolean,
    showAddDocumentsButton?: boolean,
    setReload?: () => void
}

const FRMIOverview: FC<FRMIOverviewProps> = ({ transaction, isUserActive, showAddDocumentsButton, setReload }) => {
  const frmi: FRMI | undefined = transaction.frmiDetails
  const priorities: Priority[] | undefined = frmi && frmi.priorities
  const seniorities: Seniority[] | undefined = frmi && frmi.seniorities
  const [ompiFees, setOmpiFees] = useState<OMPIFees>()
  const [isCalculatorLoading, setIsCalculatorLoading] = useState<boolean>(true)

  useEffect(() => {
    transaction.id && FRMIService.getOMPIFees(transaction.id).then((result) => {
      setOmpiFees(result)
      setIsCalculatorLoading(false)
    })
  }, [])

  const comment: Comment|undefined = transaction.comments?.find((comment: Comment) => comment.commentType === FO_USER_COMMENT_TYPE)

  return (
    <div className='col-12 d-flex flex-wrap p-0'>
      {isUserActive &&
        <>
          {/* Titres */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_inscription_titles' />} shadow>
              <div className='mb-3'>
                <OverviewTitles titles={frmi?.titles} procedureType={PROCEDURE_FRMI.value} subProcedureType={transaction.subProcedureType} showDownloadTitleList />
              </div>
            </CardBlock>
          </div>

          {/* Pays */}
          {frmi?.countries && frmi.countries.length &&
            <div className='col-12 mb-3'>
              <CardBlock header={<><FormattedMessage id='overview_frmi_countries_title' /> ({frmi.countries?.length})</>} shadow>
                <OverviewCountries frmi={frmi} />
              </CardBlock>
            </div>}

          {/* Intervenants */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_frmi_intervenants_title' />} shadow>
              <IntervenantsOverview transaction={transaction} fromFrmiExtension procedureType={transaction.procedureType} />
            </CardBlock>
          </div>

          {/* Reproduction de la marque */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_frmi_brand_reproduction_title' />} shadow>
              <BrandReproductionOverview transaction={transaction} />
            </CardBlock>
          </div>

          {/* Revendication de couleurs */}
          {!!frmi?.brand?.colors &&
            <div className='col-12 mb-3'>
              <CardBlock header={<FormattedMessage id='overview_frmi_colors_title' />} shadow>
                <div className='mb-3'>
                  <OverviewColorsClaimed frmi={frmi} />
                </div>
              </CardBlock>
            </div>}

          {/* Indications diverses */}
          {
            frmi &&
              <div className='col-12 mb-3'>
                <CardBlock header={<FormattedMessage id='overview_frmi_various_indications_title' />} shadow>
                  <div className='mb-3'>
                    <OverviewVariousIndications frmi={frmi} />
                  </div>
                </CardBlock>
              </div>
          }

          {/* Classes, produits et services */}

          {
            frmi &&
              <div className='col-12 mb-3'>
                <CardBlock header={<FormattedMessage id='overview_frmi_products_and_services_title' />} shadow>
                  <div className='mb-3'>
                    <ProductsAndServicesOverview frmi={frmi} />
                  </div>
                </CardBlock>
              </div>
          }

          {/* Limitation produits et services */}

          {frmi?.limitedProductsAndServicesVersions &&
            <div className='col-12 mb-3'>
              <CardBlock header={<FormattedMessage id='overview_frmi_limit_products_and_services_title' />} shadow>
                <div className='mb-3'>
                  <OverviewLimitedProductsAndServices limitedProductsAndServices={frmi?.limitedProductsAndServicesVersions} />
                </div>
              </CardBlock>
            </div>}

          {/* Priorités revendiquées */}
          {
            priorities &&
              <div className='col-12 mb-3'>
                <CardBlock header={<FormattedMessage id='overview_frmi_priorities_title' />} shadow>
                  <div className='mb-3'>
                    <OverviewPrioritiesFRMI priorities={priorities} hasTitle />
                  </div>
                </CardBlock>
              </div>
          }

          {/* Ancienneté revendiquée */}
          {
            seniorities && transaction.frmiDetails?.countries?.some((country: Country) => country.code === UE) &&
              <div className='col-12 mb-3'>
                <CardBlock header={<FormattedMessage id='overview_frmi_seniorities_title' />} shadow>
                  <div className='mb-3'>
                    <OverviewSeniorities seniorities={seniorities} hasTitle />
                  </div>
                </CardBlock>
              </div>
          }

          {/* Emoluments et taxes */}
          {
            frmi &&
              <div className='col-12 mb-3'>
                <CardBlock header={<FormattedMessage id='overview_frmi_taxes_title' />} shadow>
                  <div className='mb-3'>
                    <FeesAndTaxesOverview frmi={frmi} ompiFees={ompiFees} isCalculatorLoading={isCalculatorLoading} />
                  </div>
                </CardBlock>
              </div>
          }

          {/* Récapitulatif documents */}
          <div className='col-12 mb-3' ref={transaction.documents}>
            <CardBlock header={<FormattedMessage id='overview_file_title' />} shadow>
              <DocumentsOverview
                transaction={transaction}
                showAddDocumentsButton={showAddDocumentsButton}
                isUserActive={isUserActive}
                setReload={setReload}
              />
            </CardBlock>
          </div>

          {/* Commentaires */}
          {comment?.content &&
            <div className='col-12 mb-3'>
              <CardBlock
                header={<FormattedMessage id='overview_frmi_comment_title' />} shadow
              >
                <span>{comment.content}</span>
              </CardBlock>
            </div>}
        </>}
    </div>
  )
}

export default FRMIOverview
