import Payment from 'component/payment/Payment'
import { PAYMENT_URL_DEPOSIT } from 'constants/PaymentConstants'
import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import DepositForm from './form/DepositForm'

const DepositRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <DepositForm isNewTransaction />} />
    <Route path={`${match.url}/:id/edition`} component={DepositForm} />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment transactionId={props.match.params.id} state={props.match.params.state} type={PAYMENT_URL_DEPOSIT} />}
    />
  </Switch>
)

export default DepositRoutes
