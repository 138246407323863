import {
  EventType,
  FieldStatus,
  OfficialDocumentRequested,
  OfficialDocumentType,
  SelectField
} from '@inpi-marques/components'
import { useIntl } from 'react-intl'
import React, { FC } from 'react'

interface OfficialDocumentsTypeSelectionProps {
    officialDocumentTypes: OfficialDocumentType[],
    selectedOfficialDocumentType: OfficialDocumentType | undefined,
    setSelectedOfficialDocument: (officialDocument: OfficialDocumentType | undefined) => void,
    fieldStatus: FieldStatus,
    officialDocumentRequested?: OfficialDocumentRequested,
    setOfficialDocumentRequest: (document: OfficialDocumentRequested) => void
}

/**
 * Composant contenant le formulaire pour choisir le type de document souhaité
 * Permet de choisir le type de document
 *
 * @param officialDocumentTypes - Liste des types de documents
 * @param selectedOfficialDocumentType - Le type de document choisi
 * @param setSelectedOfficialDocument - Fonction pour modifier le type de document
 * @param fieldStatus - Objet contenant les erreurs du formulaire
 * @param officialDocumentRequested - Objet correspondant à la demande
 * @param setOfficialDocumentRequest - Fonction pour modifier la demande
 * @constructor
 */
const OfficialDocumentTypeSelection: FC<OfficialDocumentsTypeSelectionProps> = ({
  officialDocumentTypes,
  selectedOfficialDocumentType,
  setSelectedOfficialDocument,
  fieldStatus,
  officialDocumentRequested,
  setOfficialDocumentRequest
}) => {
  const intl = useIntl()

  /**
     * Au clic sur un type de document officiel, on l'update dans le store
     * @param event
     */
  const onSelectChange = (event: EventType) => {
    const thisOfficialDocument = officialDocumentTypes.find((officialDocument) => officialDocument.code === event.target.value)
    setSelectedOfficialDocument(thisOfficialDocument)
    if (thisOfficialDocument) {
      setOfficialDocumentRequest({ ...officialDocumentRequested, officialDocumentType: thisOfficialDocument })
    }
  }

  return (
    <div className='row mt-3'>
      <div className='col-12 col-md-4'>
        <SelectField
          placeholder={intl.formatMessage({ id: 'official_documents_type_placeholder' })}
          className='col-12'
          options={officialDocumentTypes.map((officialDocument: OfficialDocumentType) => ({
            label: officialDocument.label ?? '',
            value: officialDocument.code ?? ''
          }))}
          onChange={onSelectChange}
          inputId='type'
          value={selectedOfficialDocumentType?.code}
          required
          label={intl.formatMessage({ id: 'official_documents_type_label' })}
          fieldStatus={fieldStatus}
        />
      </div>
    </div>
  )
}

export default OfficialDocumentTypeSelection
