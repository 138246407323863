import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import OppositionForm from './form/OppositionForm'
import Payment from '../payment/Payment'
import { PAYMENT_URL_OPPOSITION } from '../../constants/PaymentConstants'

const OppositionRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <OppositionForm isNewTransaction />} />
    <Route path={`${match.url}/:id/edition`} component={OppositionForm} />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment transactionId={props.match.params.id} state={props.match.params.state} type={PAYMENT_URL_OPPOSITION} />}
    />
  </Switch>
)

export default OppositionRoutes
