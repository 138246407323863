import { DateField, DateUtils, DATE_ISO, EventType, FoundationService, OppositionFoundationTypeComponent, SelectField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const Priority: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, setFieldStatus, countries = [] }) => {
  const intl = useIntl()
  return (
    <div className='row col-12 align-items-end mt-4'>
      <h3 className='col-12'><FormattedMessage id='foundation_brand_claimed_priority_label' /></h3>
      <SelectField
        classNameFormGroup='col-4'
        inputId='priority_country'
        options={countries}
        label={<FormattedMessage id='foundation_brand_claimed_priority_country_label' />}
        value={foundation.entity?.priorityCountry}
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'priorityCountry', event.target.value))}
        placeholder={intl.formatMessage({ id: 'foundation_brand_claimed_priority_country_placeholder' })}
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      <DateField
        classNameFormGroup='col-4'
        inputId='priority_date'
        label={<FormattedMessage id='foundation_brand_claimed_priority_date_label' />}
        value={DateUtils.formateDateToFormat(foundation.entity?.priorityDate, DATE_ISO) ?? ''}
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'priorityDate', DateUtils.formatToBeginOfDay(event.target.value)))}
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
    </div>)
}

export default Priority
