import React, { FC, useState } from 'react'
import {
  CardBlock,
  FilAriane,
  SubmitButton
} from '@inpi-marques/components'
import { Link, withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import AddressList from './AddressList'
import ModalContactError from './ModalContactError'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const AddressesBook : FC = (props) => {
  const [toReload, setToReload] = useState<boolean>(false)
  const [showModalError, setShowModalError] = useState<boolean>(false)
  const [addressBookFile, setAddressBookFile] = useState<File | undefined>()

  return (
    <div className='addresses-book'>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_addresses_book' /></span>
      </FilAriane>
      <CardBlock
        className='mb-4 p-5 is-validated'
      >
        <div className='form-header'>
          <div>
            <h1 className='col-12 px-0'><FormattedMessage id='addresses_book_title' /></h1>
          </div>
          <div className='small col-12 px-0'>
            <FormattedMessage id='addresses_book_description' />
          </div>
        </div>
        <div className='pt-2 d-flex justify-content-between'>
          <SubmitButton
            className='btn-select btn-outline-primary btn-add'
            onClick={() => props.history.push('/nouveau-contact')}
          >
            <FormattedMessage id='button_add_contributor' />
            <FontAwesomeIcon className='ml-2' icon={faPlus} />
          </SubmitButton>
        </div>
        <AddressList
          toReload={toReload}
          canEdit
          setAddressBookFile={setAddressBookFile}
          setShowModalError={setShowModalError}
          setToReload={setToReload}
        />
      </CardBlock>
      {showModalError &&
        <ModalContactError
          showModal={showModalError}
          setShowModal={setShowModalError}
          isMultipleContact
          updateList={() => setToReload(true)}
          addressBookFile={addressBookFile}
        />}
    </div>
  )
}

export default withRouter(AddressesBook)
