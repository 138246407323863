import React, { Component, ReactNode, KeyboardEvent } from 'react'
import { AutocompleteResult } from '..'

const KEY_ARROW_UP = 38
const KEY_ARROW_DOWN = 40
const KEY_ENTER = 13

export interface AutocompleteHolderProps<T> {
  className?: string,
  options?: AutocompleteResult<T>[],
  messageEmpty?: ReactNode,
  onSelect?: (selected: T) => void,
  onBlur?: () => void
}

interface AutocompleteHolderState {
  activeIndex: number,
}

/**
 * Permet d'encapsuler un composant pour afficher une liste d'options en dessous
 */
class SelectHolder<T> extends Component<AutocompleteHolderProps<T>, AutocompleteHolderState> {
  constructor (props: AutocompleteHolderProps<T>) {
    super(props)
    this.state = {
      activeIndex: -1
    }
  }

  /* Gestion du clic sur une suggestion */
  handleSelect = () => {
    const { activeIndex } = this.state
    const { options, onSelect } = this.props

    if (options && activeIndex !== -1) {
      const option = options[activeIndex]
      onSelect && onSelect(option.value)
    }
  }

  /* Mise à jour de l'élément sélectionné dans le state */
  updateActiveIndex = (newIndex: number) => {
    const { options } = this.props

    if (options) {
      let validIndex = newIndex

      if (newIndex < 0 || newIndex >= options.length) {
        validIndex = -1
      }

      this.setState({ activeIndex: validIndex })
    }
  }

  /* Modification de l'index sélectionné puis mise à jour dans le state */
  incrementActiveIndex = (increment: number) => {
    const { activeIndex } = this.state
    const { options } = this.props

    if (options) {
      let newActiveIndex

      // Si aucun élément sélectionné, on sélectionne le dernier ou le premier élément de la liste
      if (activeIndex === -1) {
        if (increment < 0) {
          newActiveIndex = options.length - 1
        } else {
          newActiveIndex = 0
        }
      } else {
        newActiveIndex = activeIndex + increment
      }

      this.updateActiveIndex(newActiveIndex)
    }
  }

  /* Ajout d'actions sur les interactions clavier */
  handleKeyDown = (event: KeyboardEvent) => {
    switch (event.keyCode) {
      case KEY_ENTER:
        this.handleSelect()
        event.preventDefault()
        break
      case KEY_ARROW_UP:
        this.incrementActiveIndex(-1)
        event.preventDefault()
        break
      case KEY_ARROW_DOWN:
        this.incrementActiveIndex(1)
        event.preventDefault()
        break
      default:
        break
    }
  }

  render () {
    const { activeIndex } = this.state
    const {
      className,
      options,
      messageEmpty,
      onBlur,
      children
    } = this.props

    let autocompleteContent

    if (options instanceof Array) {
      if (options.length > 0) {
        autocompleteContent = options.map((option, i) => (
          <div
            key={i}
            className={`item ${i === activeIndex ? 'active' : ''}`}
            onMouseDown={this.handleSelect}
            onMouseEnter={() => this.updateActiveIndex(i)}
          >
            {option.label}
          </div>
        ))
      } else if (messageEmpty) {
        autocompleteContent = (
          <div className='no-result'>
            {messageEmpty}
          </div>
        )
      }
    }

    return (
      <div className={`select-holder ${className || ''}`} onKeyDown={this.handleKeyDown} onBlur={onBlur}>
        {children}

        {autocompleteContent && (
          <div className='select-holder-items'>
            {autocompleteContent}
          </div>
        )}
      </div>
    )
  }
}

export default SelectHolder
