import React, { FC } from 'react'
import { CommonFRMIService, FRMI, FRMI_TYPE_HOLDER_RIGHT_DELETE, Transaction } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'

interface FactsSummaryOverviewProps {
    frmi: FRMI,
    transaction: Transaction

}

const FactsSummaryOverview: FC<FactsSummaryOverviewProps> = ({ frmi, transaction }) => {
  return (
    <>
      <h4 className='font-weight-bold'>
        <FormattedMessage id={`frmi_facts_${transaction.subProcedureType === FRMI_TYPE_HOLDER_RIGHT_DELETE.value ? 'deletion' : 'restriction'}_label`} />
      </h4>
      {frmi?.fact &&
        <span>{CommonFRMIService.getFactSummaryText(frmi?.fact)}</span>}
    </>
  )
}

export default FactsSummaryOverview
