import React, { FunctionComponent } from 'react'
import ErrorField from '../errors/ErrorField'
import { EventType, FieldProps } from '../FormInterfaces'
import { resetError as resetErrorUtils, resetStateError } from '../../utils/formUtils'
import { DateUtils } from '../../index'

export interface DateFieldProps extends FieldProps {
  type?: string,
  required?: boolean,
  minDate?: string
  maxDate?: string
}

const DateField: FunctionComponent<DateFieldProps> = ({
  className = '',
  classNameFormGroup = '',
  inputId,
  minDate,
  maxDate,
  label,
  fieldStatus = {},
  value = '',
  type = 'date',
  onChange,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false,
  classNameLabel,
  nameFieldStatus,
  resetError,
  dispatch,
  addToRefs,
  onBlur
}) => {
  const handleChange = (event: EventType) => {
    // Permet de retourner null à la place d'une string vide pour un champ date
    // -> Le serializer côté symfony interprète une string vide par une nouvelle instance de DateTime (soit la date d'aujourd'hui)
    // alors qu'on veut une date null si la date est vide
    if (event.target.value === '') {
      onChange && onChange({ target: { value: null, name: event.target.name } })
      return
    }

    if (maxDate && !DateUtils.isSameOrBefore(new Date(Date.parse(event.target.value)), new Date(maxDate))) {
      onChange && onChange({ target: { value: null, name: event.target.name } })
      return
    }

    /** On clean l'erreur du store au changement */
    if (dispatch && resetError) {
      resetErrorUtils(dispatch, resetError, fieldStatus, nameFieldStatus || inputId)
    } else if (resetError) {
      resetStateError(resetError, fieldStatus, nameFieldStatus || inputId)
    }

    onChange && onChange(event)
  }

  return (
    <div className={`form-group ${classNameFormGroup}`}>
      {label &&
        <label className={`form-label ${classNameLabel}`} htmlFor={inputId}>
          {label}
          {required && <span className='text-danger'> *</span>}
        </label>}
      <input
        ref={(myRef) => addToRefs && addToRefs(myRef)}
        type={type}
        id={inputId}
        name={inputId}
        className={`form-control ${className}`}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        min={minDate}
        max={maxDate}
        onBlur={onBlur}
      />

      <ErrorField message={fieldStatus[nameFieldStatus || inputId]} />
    </div>
  )
}

export default DateField
