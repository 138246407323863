import React, { Dispatch, FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  DOCUMENT_TYPES,
  downloadFile,
  EventType,
  FieldStatus,
  ProductClass,
  Transaction,
  TransactionDocument,
  ProductClassVersion,
  ProductService,
  Record,
  ContestedRequest,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  ContestedBrandForm,
  DOCUMENT_FORMATS,
  PROCEDURE_REVOCATION, PROCEDURE_NULLITY, PUBLICATION_TYPE,
  RecordService as CommonRecordService,
  PROCEDURE_OPPOSITION, CONTESTED_REQUEST_FORM_EXCLUDED_FIELDS
} from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from '../../../../store/fieldStatus/fieldStatusActions'
import Message from '../../../../constants/Message'
import store from '../../../../store/store'
import ContestedProductsAndServices from './ContestedProductsAndServices'
import DocumentService from '../../../../services/document/DocumentService'
import { storeTransactionUpdate } from '../../../../store/transaction/transactionActions'
import { toast } from 'react-toastify'
import RecordService from '../../../../services/opposition/RecordService'
import ContentService from '../../../../services/content/ContentService'
/* global fetch */
/* global File */

interface RequestIdentificationProps {
  fieldStatus?: FieldStatus,
  dispatch: Dispatch<any>,
  setDocuments: (documents: TransactionDocument[]) => void,
  onDocumentDeleted: (event: EventType)=> void,
  documents: TransactionDocument[],
  transaction: Transaction
}

const RequestIdentification : FC<RequestIdentificationProps> = (
  {
    fieldStatus,
    dispatch,
    setDocuments,
    onDocumentDeleted,
    documents,
    transaction
  }) => {
  const [contestedRequest, setContestedRequest] = useState<ContestedRequest>(transaction.opposition?.contestedRequest || {})
  const [currentVersionProductsAndServices, setCurrentVersionProductsAndServices] = useState<ProductClass[]>([])

  useEffect(() => {
    dispatch(storeTransactionUpdate({
      ...transaction,
      opposition: transaction.opposition ? {
        ...transaction.opposition,
        contestedRequest: contestedRequest
      }
        : { contestedRequest: contestedRequest }
    }))
  }, [contestedRequest])

  const createOppositionProductsAndServicesVersion = (productsClasses: ProductClass[]) => {
    return ProductService.createProductsAndServicesVersion(PRODUCT_AND_SERVICE_VERSION_TYPE.OPPOSITION_INITIAL_VERSION, PRODUCT_CLASS_VERSION_STATUS.ACCEPTED, productsClasses)
  }

  const handleProductsAndServicesChange = (products : ProductClass[]) => {
    setContestedRequest({
      ...contestedRequest,
      productsAndServicesVersions: products.length ? [createOppositionProductsAndServicesVersion(products)] : undefined
    })
  }

  const handleFieldStatusChange = (newFieldStatus : FieldStatus) => {
    store.dispatch(storeTransactionFieldStatusUpdate(newFieldStatus))
  }

  const handleVerifyNumnat = async (): Promise<Record[]> => {
    if (contestedRequest.numnat) {
      const response: Record[] = await RecordService.verifyNumnat({ numNat: contestedRequest.numnat, origin: contestedRequest.isInternationalBrand ? ['WO'] : ['FR'] })
      if (response.length) {
        return response
      } else {
        toast.error(Message.verify_numnat_error)
      }
    } else {
      const newFieldStatus = {
        ...fieldStatus,
        numnat: Message.required_field
      }
      handleFieldStatusChange(newFieldStatus)
    }
    return []
  }

  const handleLoadData = (recordToLoad: Record) => {
    const currentVersion: ProductClassVersion | undefined = recordToLoad.details?.productsAndServicesVersions && ProductService.getCurrentVersion(recordToLoad.details?.productsAndServicesVersions)
    // On retire le status de l'affichage des produits & services provenant de records en FO
    if (currentVersion?.productClasses !== undefined) {
      for (const productClass of currentVersion?.productClasses) {
        for (const product of productClass.products) {
          product.status = undefined
        }
      }
    }
    setCurrentVersionProductsAndServices(currentVersion?.productClasses || [])

    const newContestedRequest : ContestedRequest = {
      ...contestedRequest,
      contestedBrand: recordToLoad.details?.brand?.text,
      brandType: recordToLoad.details?.brand?.type,
      depositDate: recordToLoad.depositDate,
      productsAndServicesVersions: currentVersion?.productClasses ? [createOppositionProductsAndServicesVersion(currentVersion?.productClasses)] : undefined,
      // On récupère le numBopi de la publication d'enregistrement, ou la premiere si elle n'existe pas
      publicationNumber: CONTESTED_REQUEST_FORM_EXCLUDED_FIELDS[transaction?.procedureType]?.includes('publicationNumber') ? undefined : CommonRecordService.getContestedRequestPublication(recordToLoad)?.numBopi
    }

    // on enregistre la date d'enregistrement seulement si elle n'est pas à exclure
    if ([PROCEDURE_REVOCATION.value, PROCEDURE_NULLITY.value].includes(transaction.procedureType) && !contestedRequest.isInternationalBrand) {
      newContestedRequest.registrationDate = recordToLoad?.publications?.find((publication) => [PUBLICATION_TYPE.REGISTRATION, PUBLICATION_TYPE.REGISTRATIONAM].includes(publication?.code))?.date
    }
    if (transaction.procedureType === PROCEDURE_OPPOSITION.value) {
      if (contestedRequest.isInternationalBrand) {
        newContestedRequest.publicationDateInFrance = recordToLoad?.publications?.find((publication) => [PUBLICATION_TYPE.REGISTRATION, PUBLICATION_TYPE.REGISTRATIONAM].includes(publication?.code))?.date
      } else {
        newContestedRequest.publicationDateInFrance = recordToLoad?.publications?.find((publication) => PUBLICATION_TYPE.FIRST_PUB === publication?.code)?.date
      }
    }

    // Ajout du PDF de la marque contestée
    const copyOfMark = recordToLoad.documents?.filter(doc => doc.type === DOCUMENT_TYPES.AVIS_PUBLICATION)?.[0]
    if (copyOfMark) {
      RecordService.getDocumentFile(recordToLoad.id, copyOfMark.internalName).then(result => {
        addCopyOfContestedMark(result, copyOfMark.name)
      })
    } else if (!contestedRequest.isInternationalBrand) {
      addCopyOfContestedMark(`https://opendata-pi.inpi.fr/inpi/marques/bopi/FR${contestedRequest.numnat}`, `Copie-${contestedRequest.numnat}.pdf`)
    }

    setContestedRequest(newContestedRequest)
  }

  const addCopyOfContestedMark = (url: string, name: string) => {
    // Pour ajouter le fichier dans la transaction, on récupère son contenu en base64 ou via une url du bopi et on le transforme en File.
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], name, { type: DOCUMENT_FORMATS.PDF })
        addDocument({
          type: DOCUMENT_TYPES.CONTESTED_BRAND_COPY,
          internalName: '',
          name: name,
          format: DOCUMENT_FORMATS.PDF,
          file: file
        })
      })
  }

  const handleDocumentChange = (event: EventType) => {
    const { value } = event.target

    const newFile: TransactionDocument = {
      type: DOCUMENT_TYPES.CONTESTED_BRAND_COPY,
      internalName: '',
      name: value.name,
      format: value.type,
      file: value
    }
    addDocument(newFile)
  }

  const addDocument = (newFile: TransactionDocument) => {
    DocumentService.createDocument(newFile, transaction.id).then((response) => {
      if (response) {
        // On récupère l'internalName pour le nouveau document
        newFile.internalName = response.internalName

        // Mise à jour des documents avec le nouveau document
        if (!documents.some(doc => doc.internalName === newFile.internalName)) {
          setDocuments([...documents, newFile])
        }
      } else {
        handleFieldStatusChange({ ...fieldStatus, copyRequest: Message.common_error })
      }
    })
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  const handleDocumentDelete = (event: EventType) => {
    // On supprime le document
    const documentToDelete = documents.find((document) => document.type === DOCUMENT_TYPES.CONTESTED_BRAND_COPY)

    if (documentToDelete?.internalName) {
      DocumentService.deleteDocument(documentToDelete.internalName, transaction)
    }
    onDocumentDeleted(event)
  }

  return (
    <div className='contested-brand'>
      <div className='request-identification'>
        <div className='mt-5 form-header'>
          <div className='d-flex justify-content-between'>
            <div>
              <div className='d-flex'>
                <h2 className='mb-0'>
                  <FormattedMessage id='request_identification_title_edition' />
                </h2>
              </div>
              <div className='small mt-2'>
                <FormattedMessage
                  id={`${transaction.procedureType !== PROCEDURE_OPPOSITION.value ? 'request_identification_nd_subtitle' : 'request_identification_subtitle'}`}
                  values={{
                    a: (...chunks : ((string) []) | []) => (<a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
                    linebreak: <br />
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <ContestedBrandForm
            contestedRequest={contestedRequest}
            setContestedRequest={setContestedRequest}
            dispatch={dispatch}
            documents={documents}
            fieldStatus={fieldStatus}
            currentVersionProductsAndServices={currentVersionProductsAndServices}
            handleVerifyNumnat={handleVerifyNumnat}
            storeTransactionFieldStatusUpdate={storeTransactionFieldStatusUpdate}
            handleDocumentChange={handleDocumentChange}
            handleDownload={handleDownload}
            handleDocumentDelete={handleDocumentDelete}
            getCountries={ContentService.getCountries}
            onRecordSelected={handleLoadData}
            procedureType={transaction.procedureType || ''}
          />
        </div>
      </div>
      {
        !contestedRequest.isTotalProductsAndServices &&
          <ContestedProductsAndServices
            productClasses={contestedRequest.productsAndServicesVersions ? ProductService.getCurrentVersion(contestedRequest.productsAndServicesVersions)?.productClasses : []}
            handleProductsAndServicesChange={handleProductsAndServicesChange}
            fieldStatus={fieldStatus}
            procedureType={transaction.procedureType}
          />
      }
    </div>
  )
}

export default RequestIdentification
