import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors,
  FieldStatus,
  Priority,
  Transaction
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from '../../../../store/transaction/transactionActions'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import PriorityFRMIForm from './PriorityFRMIForm'
import PriorityFRMIListForm from './PriorityFRMIListForm'
import PriorityFRMIValidator from './validator/PriorityFRMIValidator'

interface PrioritiesFRMIClaimedProps {
    transaction: Transaction
}

const PrioritiesFRMIClaimed: FC<PrioritiesFRMIClaimedProps> = ({ transaction }) => {
  const dispatch = useDispatch()
  const [isEditingMode, setEditingMode] = useState<boolean>(false)
  const [priority, setPriority] = useState<Priority>()
  const [priorityList, setPriorityList] = useState<Priority[]>(transaction.frmiDetails?.priorities || [])
  const [fieldStatus, setFieldStatus] = useState<FieldStatus[]>([])
  const [editIndex, setEditIndex] = useState<number>(-1)

  /**
     * Sauvegarde dans la transaction de la nouvelle valeur des revendications de priorités
     */
  useEffect(() => {
    dispatch(storeTransactionUpdateFRMI({ ...transaction.frmiDetails, priorities: priorityList }))
  }, [priorityList])

  /**
     * Ajout d'une priorité
     */
  const addPriority = () => {
    const index = priorityList.length
    setEditIndex(index)
    setEditingMode(false)
  }

  /**
     * Valide la priorité
     */
  const validate = () => {
    const fieldStatusPriority = PriorityFRMIValidator.validatePriority(priority)
    const newFieldStatus = [...fieldStatus]
    newFieldStatus[editIndex] = fieldStatusPriority
    setFieldStatus(newFieldStatus)
    if (!containsErrors(fieldStatusPriority)) {
      const newList = [...priorityList]
      newList[editIndex] = { ...priority }
      setPriorityList(newList)
      setPriority({})
      setEditIndex(-1)
    }
  }

  /**
     * Annule un ajout ou la modification d'une priorité
     */
  const cancel = () => {
    setFieldStatus(fieldStatus.filter((status, index) => index !== editIndex))
    setEditIndex(-1)
    setPriority({})
  }

  /**
     * Supprime une priorité de la liste
     * @param index
     */
  const deletePriority = (index: number) => {
    const newPriorities = [...priorityList]
    newPriorities.splice(index, 1)
    setPriorityList(newPriorities)
    const updatedFieldStatus = [...fieldStatus]
    updatedFieldStatus.splice(index, 1)
    setFieldStatus(updatedFieldStatus)
  }

  /**
     * Affiche la liste des priorités
     */
  const renderListPriorityOverview = () => {
    return (
      <PriorityFRMIListForm
        priorityList={priorityList}
        editPriority={(index: number) => {
          setEditIndex(index)
          setEditingMode(true)
          setPriority(priorityList[index])
        }}
        deletePriority={deletePriority}
        addPriority={addPriority}
      />
    )
  }

  /**
     * Affiche le formulaire d'une priorité
     */
  const renderFormOnePriority = () => {
    return (
      <PriorityFRMIForm
        frmiDetails={transaction.frmiDetails}
        priority={priority}
        setPriority={setPriority}
        editIndex={editIndex}
        isEditingMode={isEditingMode}
        fieldStatus={fieldStatus[editIndex]}
        validate={validate}
        cancel={cancel}
      />
    )
  }

  return (
    <div className='prioritiesFRMIClaimed'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_priorities_claimed_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_priorities_claimed_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row'>
        <div className='col-9' />
      </div>
      {editIndex === -1 ? renderListPriorityOverview() : renderFormOnePriority()}
    </div>
  )
}

export default PrioritiesFRMIClaimed
