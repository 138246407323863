import React, { FC } from 'react'

interface TruncatedColumnProps {
    value: string,
    className?: string
}

/**
 * Colonne tronquée d'un tableau
 */
const TruncatedColumn: FC<TruncatedColumnProps> = ({ value, className }) =>
  <td className={`text-truncate ${className}`} title={value}>{value}</td>

export default TruncatedColumn
