import React, { FC } from 'react'
import { DateUtils, FRMI, OMPIFees } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import FeesAndTaxesAmount from './FeesAndTaxesAmount'
import { PAYMENT_METHODS_TYPE_LIST } from '../../../../constants/PaymentMethodsConstants'

interface FeesAndTaxesOverviewProps {
    frmi: FRMI,
    ompiFees?: OMPIFees,
    isCalculatorLoading?: boolean
}
const FeesAndTaxesOverview: FC<FeesAndTaxesOverviewProps> = ({ frmi, ompiFees, isCalculatorLoading }) => {
  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='col-6'>
          <FeesAndTaxesAmount ompiFees={ompiFees} isCalculatorLoading={isCalculatorLoading} />
        </div>
        <div className='col-6'>
          <div className='d-flex flex-column'>
            {
              frmi.paymentMethod?.type &&
                <div className='d-flex flex-column mb-3'>
                  <span className='font-weight-bold'><FormattedMessage id='frmi_payment_method_block_title' /></span>
                  <span>{PAYMENT_METHODS_TYPE_LIST.find(method => method.value === frmi.paymentMethod?.type)?.label}</span>
                </div>
            }
            {
              frmi.paymentMethod?.accountHolder &&
                <div className='d-flex flex-column mb-3'>
                  <span className='font-weight-bold'><FormattedMessage id='fees_and_taxes_account_holder' /></span>
                  <span>{frmi.paymentMethod?.accountHolder}</span>
                </div>
            }
            {
              frmi.paymentMethod?.accountNumber &&
                <div className='d-flex flex-column mb-3'>
                  <span className='font-weight-bold'><FormattedMessage id='fees_and_taxes_account_number' /></span>
                  <span>{frmi.paymentMethod?.accountNumber}</span>
                </div>
            }
            {
              frmi.paymentMethod?.instructionsAuthor &&
                <div className='d-flex flex-column mb-3'>
                  <span className='font-weight-bold'><FormattedMessage id='fees_and_taxes_instructions_author' /></span>
                  <span>{frmi.paymentMethod?.instructionsAuthor}</span>
                </div>
            }
            {
              frmi.paymentMethod?.paymentAuthor &&
                <div className='d-flex flex-column mb-3'>
                  <span className='font-weight-bold'><FormattedMessage id='fees_and_taxes_payment_author' /></span>
                  <span>{frmi.paymentMethod?.paymentAuthor}</span>
                </div>
            }
            {
              frmi.paymentMethod?.transferDate &&
                <div className='d-flex flex-column mb-3'>
                  <span className='font-weight-bold'><FormattedMessage id='fees_and_taxes_payment_transfer_date' /></span>
                  <span>{DateUtils.formatDateFr(frmi.paymentMethod?.transferDate)}</span>
                </div>
            }
            {
              frmi.paymentMethod?.paymentReferences &&
                <div className='d-flex flex-column mb-3'>
                  <span className='font-weight-bold'><FormattedMessage id='fees_and_taxes_payment_references' /></span>
                  <span>{frmi.paymentMethod?.paymentReferences}</span>
                </div>
            }
            {
              frmi.paymentMethod?.receiptNumber &&
                <div className='d-flex flex-column mb-3'>
                  <span className='font-weight-bold'><FormattedMessage id='fees_and_taxes_payment_receipt' /></span>
                  <span>{frmi.paymentMethod?.receiptNumber}</span>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default FeesAndTaxesOverview
