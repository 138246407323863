import {
  Deposit,
  LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION,
  LINKED_TRANSACTION_TYPE_RENEWAL,
  LinkedTransaction,
  ProductService,
  Publication,
  PUBLICATION_TYPE,
  Record
} from '@inpi-marques/components'
import moment from 'moment-timezone'

class DepositService {
  getLinkedTransaction = (record: Record, isLimited = false): LinkedTransaction[] => {
    if (!record.publications) {
      return []
    }

    let renewals = []
    if (!isLimited) {
      renewals = record.publications
        .filter((infos: Publication) => [PUBLICATION_TYPE.RENEWAL_PARTIAL, PUBLICATION_TYPE.RENEWAL_TOTAL].includes(infos.code))
        .sort((a: Publication, b: Publication) => {
          if (!a.date) {
            return b
          }
          if (!b.date) {
            return a
          }
          return new Date(b.date) - new Date(a.date)
        })
    }

    const lastRenewalDate = renewals?.[0]?.date
    const twelveYearsAgo = moment().subtract('12', 'years')

    const inscriptions = record.publications
      .filter((infos: Publication) =>
        [PUBLICATION_TYPE.INSCRIPTION].includes(infos.code) &&
        (!isLimited ||
          (infos.date &&
          (infos.natureCodes?.some((natureCode: string) => (natureCode.toUpperCase().includes('TO') || natureCode.toUpperCase().includes('PT') || natureCode.toUpperCase().includes('TP') || natureCode.toUpperCase().includes('PP') || natureCode.toLowerCase().includes('transmission de propriété') || natureCode.toLowerCase().includes('transmission totale de propriété') || natureCode.toLowerCase().includes('transmission partielle de propriété')))) &&
        ((lastRenewalDate && moment(infos.date).isAfter(moment(lastRenewalDate))) ||
          twelveYearsAgo.isBefore(moment(infos.date))))))

    return [...renewals, ...inscriptions].map((infos: Publication) => ({ numNat: infos.transactionLinkText ?? '', type: infos.code === PUBLICATION_TYPE.INSCRIPTION ? LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION.value : LINKED_TRANSACTION_TYPE_RENEWAL.value }))
  }

  /**
   * Génère le deposit à partir d'un record.
   * @param record
   * @returns
   */
  getDepositFromRecord = (record: Record): Deposit => (
    {
      ...record.details,
      idRecord: record.id,
      from: record.from,
      numNatToTransformOrDivide: record.numNat,
      createdAtToTransformOrDivide: record.depositDate,
      parentProductsAndServicesVersions: ProductService.resetVersions(record.details?.productsAndServicesVersions),
      productsAndServicesVersions: record.details?.tmpChildProductsAndServicesVersions || [],
      associatedBrands: record.associatedBrands,
      linkedTransactions: this.getLinkedTransaction(record),
      registeredDate: record.details?.registeredDate || record.publications?.find((publication) => [PUBLICATION_TYPE.REGISTRATION, PUBLICATION_TYPE.REGISTRATIONAM].includes(publication?.code))?.date
    }
  )
}

export default new DepositService()
