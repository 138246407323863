import React, { FC, ReactElement } from 'react'
import {
  CardBlock
} from '@inpi-marques/components'
import BasketCountItems from '../count/BasketCountItems'
import { BasketFilter } from '../../interfaces/BasketInterface'

interface BasketDetailsLayoutProps {
  idBasket: string,
  children: ReactElement,
  headerBasketSelected: BasketFilter,
  getTransactionCountByHeader?: (filter?: BasketFilter) => any
}

const BasketDetailsLayout: FC<BasketDetailsLayoutProps> = ({ idBasket, children, headerBasketSelected, getTransactionCountByHeader }) => {
  return (
    <div>
      <div className='row my-4'>
        <div className='col-12 col-md-3'>
          <div className='mb-3'>
            <CardBlock>
              <BasketCountItems currentIdBlock={idBasket} headerBasketSelected={headerBasketSelected} getTransactionCountByHeader={getTransactionCountByHeader} />
            </CardBlock>
          </div>
        </div>
        <div className='col-12 col-md-9'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default BasketDetailsLayout
