import store from '../../../../../store/store'
import {
  containsErrors,
  FieldStatus,
  FRMI_EFFECT_DATE_TYPE_MODIFICATION_INSCRIPTION,
  messageIsFilled,
  Transaction
} from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'

class EffectDateValidator {
    validateOnChangeStep = (transaction: Transaction): boolean => {
      const fieldStatus: FieldStatus = { effectDateInformations: transaction.frmiDetails?.effectDateType === FRMI_EFFECT_DATE_TYPE_MODIFICATION_INSCRIPTION.value ? messageIsFilled(transaction.frmiDetails?.effectDateInformations) : '' }
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
      return !containsErrors(fieldStatus)
    }
}

export default new EffectDateValidator()
