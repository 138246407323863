import {
  CheckboxField,
  TransactionDocument,
  DOCUMENT_TYPES,
  EventType,
  FieldStatus,
  FileBrowserField,
  FileButton,
  DOCUMENT_FORMATS,
  downloadFile
} from '@inpi-marques/components'
import { DepositTypeContentProps } from 'interfaces/Deposit'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { DEPOSIT_TYPE_GUARANTEE } from '../../../../../constants/DepositConstant'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import { storeTransactionUpdateDeposit } from '../../../../../store/transaction/transactionActions'
import TransactionService from '../../../../../services/transaction/TransactionService'
import DocumentService from '../../../../../services/document/DocumentService'

/**
 * Ce composant est utilisé pour deux types de dépôt :
 *    - Marque de garantie
 *    - Marque collective
 */
const ConsentContent: FC<DepositTypeContentProps> = ({ value, deposit, transaction, fieldStatus, onDocumentChanged, onDocumentDeleted, documents }) => {
  const dispatch = useDispatch()

  const onCheckboxChanged = () => {
    dispatch(storeTransactionUpdateDeposit({ ...deposit, typeConsented: !deposit.typeConsented }))
  }

  const fileType: string = value === DEPOSIT_TYPE_GUARANTEE.value ? DOCUMENT_TYPES.DEPOSIT_GUARANTEE : DOCUMENT_TYPES.DEPOSIT_COLLECTIVE
  const consentType: string = value === DEPOSIT_TYPE_GUARANTEE.value ? 'guarantee' : 'collective'

  const depositFile: TransactionDocument | undefined = documents && TransactionService.getDocumentByType(documents, fileType)

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  return (
    <div className='consent-content'>
      <div className='content__description text-justify'>
        <FormattedMessage
          id={`deposit_type_${consentType}_description`}
          values={{
            br: <br />
          }}
        />
        <img src='/img/picto-help.png' className='content__description-image' />
      </div>

      <CheckboxField
        checked={deposit.typeConsented}
        onChange={() => onCheckboxChanged()}
        inputId={`${consentType}-consent`}
        label={<FormattedMessage id={`deposit_type_${consentType}_form_checkbox_label`} />}
        fieldStatus={fieldStatus as FieldStatus}
        nameFieldStatus='typeConsented'
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
      />

      <FileBrowserField
        inputId={`${consentType}-file`}
        label={<FormattedMessage id={`deposit_type_${consentType}_form_file_label`} />}
        maxNumberFile={1}
        buttonLabel={<FileButton />}
        onChange={(event: EventType) => onDocumentChanged && onDocumentChanged(event, fileType)}
        value={depositFile && [depositFile]}
        onDelete={onDocumentDeleted}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
        fieldStatus={fieldStatus as FieldStatus}
        nameFieldStatus='docConsented'
        informationDoc
        handleDownload={handleDownload}
      />
    </div>)
}

export default ConsentContent
