import { DOCUMENT_FORMATS, DOCUMENT_LIMITS } from '@inpi-marques/components/src/constants/DocumentConstants'
import { BrandTypeContentProps } from 'interfaces/Deposit'
import { FC } from 'react'
import WordContent from '../component/deposit/form/brandType/content/WordContent'
import FigurativeContent from '../component/deposit/form/brandType/content/FigurativeContent'
import ColorContent from '../component/deposit/form/brandType/content/ColorContent'
import SimpleContent from '../component/deposit/form/brandType/content/SimpleContent'

export interface BrandType {
    value: string,
    title: string,
    description: string
    examples?: BrandTypeExample[]
    component: FC<BrandTypeContentProps>
    acceptedFormat?: string
    displayIfGuidedMode: boolean
    maxSize?: number | { [key: string]: number }
}

export interface BrandTypeExample {
  src: string,
  type: 'image' | 'video' | 'audio'
  alternativeValue?: string
}

export const BRAND_TYPE_WORD: BrandType = {
  value: 'BRAND_TYPE_WORD',
  title: 'brand_type_word_title',
  description: 'brand_type_word_description',
  displayIfGuidedMode: true,
  examples: [
    { src: '/img/brand-types/word/example-1.jpeg', type: 'image', alternativeValue: 'Example de marque verbale 1' },
    { src: '/img/brand-types/word/example-2.jpeg', type: 'image', alternativeValue: 'Example de marque verbale 2' },
    { src: '/img/brand-types/word/example-3.png', type: 'image', alternativeValue: 'Example de marque verbale 3' }
  ],
  component: WordContent
}

export const BRAND_TYPE_FIGURATIVE: BrandType = {
  value: 'BRAND_TYPE_FIGURATIVE',
  title: 'brand_type_figurative_title',
  description: 'brand_type_figurative_description',
  displayIfGuidedMode: true,
  examples: [
    { src: '/img/brand-types/figurative/example-1.jpeg', type: 'image', alternativeValue: 'Example de marque figurative 1' },
    { src: '/img/brand-types/figurative/example-2.jpeg', type: 'image', alternativeValue: 'Example de marque figurative 2' }
  ],
  component: FigurativeContent,
  acceptedFormat: DOCUMENT_FORMATS.JPEG,
  maxSize: DOCUMENT_LIMITS.JPEG_MAX_SIZE
}

export const BRAND_TYPE_COLOR: BrandType = {
  value: 'BRAND_TYPE_COLOR',
  title: 'brand_type_color_title',
  description: 'brand_type_color_description',
  displayIfGuidedMode: false,
  examples: [
    { src: '/img/brand-types/color/example-1.jpeg', type: 'image', alternativeValue: 'Example de marque de couleur  1' },
    { src: '/img/brand-types/color/example-2.jpeg', type: 'image', alternativeValue: 'Example de marque de couleur 2' }
  ],
  component: ColorContent,
  acceptedFormat: DOCUMENT_FORMATS.JPEG,
  maxSize: DOCUMENT_LIMITS.JPEG_MAX_SIZE
}

const BRAND_TYPE_HOLOGRAM: BrandType = {
  value: 'BRAND_TYPE_HOLOGRAM',
  title: 'brand_type_hologram_title',
  description: 'brand_type_hologram_description',
  displayIfGuidedMode: false,
  examples: [
    { src: '/img/brand-types/hologram/example.jpeg', type: 'image', alternativeValue: 'Example de marque hologramme' },
    { src: '/video/brand-types/hologram/example.mp4', type: 'video', alternativeValue: '/img/brand-types/hologram/example-poster.png' }
  ],
  component: SimpleContent,
  acceptedFormat: `${DOCUMENT_FORMATS.JPEG},${DOCUMENT_FORMATS.MP4}`,
  maxSize: DOCUMENT_LIMITS.JPEG_OR_MP4_MAX_SIZE
}

export const BRAND_TYPE_SHAPE: BrandType = {
  value: 'BRAND_TYPE_SHAPE',
  title: 'brand_type_shape_title',
  description: 'brand_type_shape_description',
  displayIfGuidedMode: false,
  examples: [
    { src: '/img/brand-types/shape/example-1.jpeg', type: 'image', alternativeValue: 'Example de marque de forme 1' },
    { src: '/img/brand-types/shape/example-2.jpeg', type: 'image', alternativeValue: 'Example de marque de forme 2' }
  ],
  component: SimpleContent,
  acceptedFormat: DOCUMENT_FORMATS.JPEG,
  maxSize: DOCUMENT_LIMITS.JPEG_MAX_SIZE
}

const BRAND_TYPE_SOUND: BrandType = {
  value: 'BRAND_TYPE_SOUND',
  title: 'brand_type_sound_title',
  description: 'brand_type_sound_description',
  displayIfGuidedMode: false,
  examples: [
    { src: '/img/brand-types/sound/example.jpeg', type: 'image', alternativeValue: 'Example de marque sonore' },
    { src: '/audio/brand-types/sound/example.mp3', type: 'audio' }
  ],
  component: SimpleContent,
  acceptedFormat: `${DOCUMENT_FORMATS.JPEG},${DOCUMENT_FORMATS.MP3}`,
  maxSize: DOCUMENT_LIMITS.JPEG_OR_MP3_MAX_SIZE
}

export const BRAND_TYPE_POSITION: BrandType = {
  value: 'BRAND_TYPE_POSITION',
  title: 'brand_type_position_title',
  description: 'brand_type_position_description',
  displayIfGuidedMode: false,
  examples: [
    { src: '/img/brand-types/position/example-1.jpeg', type: 'image', alternativeValue: 'Example de marque de position 1' },
    { src: '/img/brand-types/position/example-2.jpeg', type: 'image', alternativeValue: 'Example de marque de position 2' }
  ],
  component: SimpleContent,
  acceptedFormat: DOCUMENT_FORMATS.JPEG,
  maxSize: DOCUMENT_LIMITS.JPEG_MAX_SIZE
}

const BRAND_TYPE_PATTERN: BrandType = {
  value: 'BRAND_TYPE_PATTERN',
  title: 'brand_type_pattern_title',
  description: 'brand_type_pattern_description',
  displayIfGuidedMode: false,
  examples: [
    { src: '/img/brand-types/pattern/example-1.jpeg', type: 'image', alternativeValue: 'Example de marque de motif 1' },
    { src: '/img/brand-types/pattern/example-2.jpeg', type: 'image', alternativeValue: 'Example de marque de motif 2' }
  ],
  component: SimpleContent,
  acceptedFormat: DOCUMENT_FORMATS.JPEG,
  maxSize: DOCUMENT_LIMITS.JPEG_MAX_SIZE
}

const BRAND_TYPE_MOVEMENT: BrandType = {
  value: 'BRAND_TYPE_MOVEMENT',
  title: 'brand_type_movement_title',
  description: 'brand_type_movement_description',
  displayIfGuidedMode: false,
  examples: [
    { src: '/img/brand-types/movement/example.jpeg', type: 'image', alternativeValue: 'Example de marque mouvement' },
    { src: '/video/brand-types/movement/example.mp4', type: 'video', alternativeValue: '/img/brand-types/movement/example-poster.png' }
  ],
  component: SimpleContent,
  acceptedFormat: `${DOCUMENT_FORMATS.JPEG},${DOCUMENT_FORMATS.MP4}`,
  maxSize: DOCUMENT_LIMITS.JPEG_OR_MP4_MAX_SIZE
}

const BRAND_TYPE_MULTIMEDIA: BrandType = {
  value: 'BRAND_TYPE_MULTIMEDIA',
  title: 'brand_type_multimedia_title',
  description: 'brand_type_multimedia_description',
  displayIfGuidedMode: false,
  examples: [
    { src: '/video/brand-types/multimedia/example-1.mp4', type: 'video', alternativeValue: '/img/brand-types/multimedia/example-1-poster.png' },
    { src: '/video/brand-types/multimedia/example-2.mp4', type: 'video', alternativeValue: '/img/brand-types/multimedia/example-2-poster.png' }
  ],
  component: SimpleContent,
  acceptedFormat: DOCUMENT_FORMATS.MP4,
  maxSize: DOCUMENT_LIMITS.MP4_MAX_SIZE
}

const BRAND_TYPE_OTHER: BrandType = {
  value: 'BRAND_TYPE_OTHER',
  title: 'brand_type_other_title',
  description: 'brand_type_other_description',
  displayIfGuidedMode: false,
  component: SimpleContent,
  acceptedFormat: `${DOCUMENT_FORMATS.JPEG}, ${DOCUMENT_FORMATS.MP4}, ${DOCUMENT_FORMATS.MP3}`,
  maxSize: DOCUMENT_LIMITS.DEPOSIT_MULTIPLE_SIZE
}

export const BRAND_TYPES = [
  BRAND_TYPE_WORD,
  BRAND_TYPE_FIGURATIVE,
  BRAND_TYPE_COLOR,
  BRAND_TYPE_HOLOGRAM,
  BRAND_TYPE_SHAPE,
  BRAND_TYPE_SOUND,
  BRAND_TYPE_POSITION,
  BRAND_TYPE_PATTERN,
  BRAND_TYPE_MOVEMENT,
  BRAND_TYPE_MULTIMEDIA,
  BRAND_TYPE_OTHER
]

export const BRAND_TYPE_SEMI_FIGURATIVE: BrandType = {
  value: 'BRAND_TYPE_SEMI_FIGURATIVE',
  title: 'brand_type_semi_figurative_title',
  acceptedFormat: DOCUMENT_FORMATS.JPEG
}

export const BRAND_TYPE_OLFACTORY: BrandType = {
  value: 'BRAND_TYPE_OLFACTORY',
  title: 'brand_type_olfactory_title',
  acceptedFormat: DOCUMENT_FORMATS.JPEG
}

export const BRAND_TYPE_3D: BrandType = {
  value: 'BRAND_TYPE_3D',
  title: 'brand_type_3d_title',
  acceptedFormat: DOCUMENT_FORMATS.JPEG
}

export const BRAND_TYPES_ALL = [
  BRAND_TYPE_WORD,
  BRAND_TYPE_FIGURATIVE,
  BRAND_TYPE_COLOR,
  BRAND_TYPE_HOLOGRAM,
  BRAND_TYPE_SHAPE,
  BRAND_TYPE_SOUND,
  BRAND_TYPE_POSITION,
  BRAND_TYPE_PATTERN,
  BRAND_TYPE_MOVEMENT,
  BRAND_TYPE_MULTIMEDIA,
  BRAND_TYPE_OTHER,
  BRAND_TYPE_OLFACTORY,
  BRAND_TYPE_SEMI_FIGURATIVE,
  BRAND_TYPE_3D
]
