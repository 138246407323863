import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import DivisionForm from './form/DivisionForm'
import Payment from '../payment/Payment'
import { PAYMENT_URL_DIVISION } from '../../constants/PaymentConstants'

const DivisionRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <DivisionForm isNewTransaction />} />
    <Route path={`${match.url}/:id/edition`} component={DivisionForm} />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment transactionId={props.match.params.id} state={props.match.params.state} type={PAYMENT_URL_DIVISION} />}
    />
  </Switch>
)

export default DivisionRoutes
