import { FieldStatus, isFilled, ProductClassVersion } from '@inpi-marques/components'
import Message from 'constants/Message'

class LimitedProductsAndServiceValidator {
  validate = (version?: ProductClassVersion): FieldStatus => {
    const fieldStatus: FieldStatus = {}
    if (!isFilled(version?.countries)) {
      fieldStatus.countries = Message.limited_products_services_missing_country
    }

    if (!isFilled(version?.productClasses)) {
      fieldStatus.productsAndServices = Message.product_and_services_missing
    }

    return fieldStatus
  }
}

export default new LimitedProductsAndServiceValidator()
