import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { AssociatedBrand } from '@inpi-marques/components'

interface AssociatedBrandsOverviewProps {
    associatedBrands: AssociatedBrand[]
}

/**
 * Récupitulatif des marques associées d'un renouvellement
 */
const AssociatedBrandsOverview: FC<AssociatedBrandsOverviewProps> = ({ associatedBrands }) =>

  <>
    <h4 className='font-weight-bold mb-2'>
      <FormattedMessage id='overview_renewal_associated_brands_title' />
    </h4>
    {associatedBrands && associatedBrands.length > 0
      ? associatedBrands.map((associatedBrand: AssociatedBrand, key: number) =>
        <p key={key}><span className='font-weight-bold'><FormattedMessage id='overview_renewal_associated_brand_title' /></span> : {associatedBrand.numNat}</p>
      )
      : <div><FormattedMessage id='none_feminine' /></div>}
  </>

export default AssociatedBrandsOverview
