import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import Payment from '../payment/Payment'
import { PAYMENT_URL_REVOCATION_STATEMENT } from '../../constants/PaymentConstants'
import RevocationStatementForm from './form/RevocationStatementForm'

const RevocationStatementRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/nouveau`} render={() => <RevocationStatementForm isNewTransaction />} />
    <Route path={`${match.url}/:id/edition`} component={RevocationStatementForm} />
    <Route
      path={`${match.url}/:id/paiement/:state`}
      render={(props) => <Payment transactionId={props.match.params.id} state={props.match.params.state} type={PAYMENT_URL_REVOCATION_STATEMENT} />}
    />
  </Switch>
)

export default RevocationStatementRoutes
