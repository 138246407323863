import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { OfficialDocumentType } from '@inpi-marques/components'

interface OfficialDocumentTypeInformationProps {
    selectedOfficialDocument: OfficialDocumentType|undefined,
}

const OfficialDocumentTypeInformation: FC<OfficialDocumentTypeInformationProps> = ({
  selectedOfficialDocument
}) => {
  return (
    <>
      {selectedOfficialDocument && (
        <>
          <div className='row mt-5'>
            <div className='col-12'>
              <h2>{selectedOfficialDocument.label}</h2>
              <div dangerouslySetInnerHTML={{ __html: selectedOfficialDocument.description ? selectedOfficialDocument.description : '' }} />
            </div>
            <div className='col-12'>
              <FormattedMessage id='official_documents_type_processing_delay_label' />
              <span className='font-weight-bold'> {selectedOfficialDocument.processingDelay}</span>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default OfficialDocumentTypeInformation
