import React, { FC } from 'react'
import { FRMI, Table } from '@inpi-marques/components'

import Message from '../../../../constants/Message'
import { FormattedMessage } from 'react-intl'

interface OverviewBrandReproductionProps {
    frmi: FRMI,
    className?: string
}
const OverviewColorsClaimed: FC<OverviewBrandReproductionProps> = ({ frmi, className }) => {
  const getTableTitle = () => {
    return [
      { label: Message.colors_column_label_field, className: 'table-column-label' },
      { label: Message.colors_column_label_value, className: 'table-column-label' }
    ]
  }

  return (
    <>
      <Table
        tableTitle={getTableTitle()}
        tableClassName={`title-table ${className}`}
      >
        <tbody>
          <tr>
            <td><FormattedMessage id='frmi_overview_claimed_colors_table_title' /></td>
            <td>{frmi.brand?.colors}</td>
          </tr>
          <tr>
            <td><FormattedMessage id='various_indications_field_description' /></td>
            <td>{frmi.brand?.colorsDescription ?? '-'}</td>
          </tr>

        </tbody>
      </Table>
    </>
  )
}

export default OverviewColorsClaimed
