import { createIntl, IntlShape } from 'react-intl'
import Message from '../../constants/Message'
import axios, { CancelTokenSource } from 'axios'
import http from '../../network/http-common'
import store from '../../store/store'
import { isFilled, OfficialDocumentType } from '@inpi-marques/components'
import { storeOfficialDocumentTypesUpdate } from '../../store/content/contentActions'

class OfficialDocumentTypesService {
    intl: IntlShape
    source: CancelTokenSource

    constructor () {
      this.intl = createIntl({ locale: 'fr', messages: Message })
      this.source = axios.CancelToken.source()
    }

    /**
     * Récupération de la liste des types de documents officiels
     * @returns Promise<OfficialDocumentType[]>
     */
    getOfficialDocumentsTypes = async (): Promise<OfficialDocumentType[]> => {
      let officialDocumentsTypes: OfficialDocumentType[] = store.getState().adminContent.officialDocumentTypes
      if (!isFilled(officialDocumentsTypes)) {
        try {
          officialDocumentsTypes = await http.get('/api/official-document-types', {
            cancelToken: this.source.token
          })
          officialDocumentsTypes = officialDocumentsTypes.map(officialDocumentType => ({ ...officialDocumentType, label: officialDocumentType.label }))
          store.dispatch(storeOfficialDocumentTypesUpdate(officialDocumentsTypes))
        } catch (error) {
          officialDocumentsTypes = []
        }
      }
      return Promise.resolve(officialDocumentsTypes)
    }
}

export default new OfficialDocumentTypesService()
