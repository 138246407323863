import React, { FC } from 'react'
import { CheckboxField, DateUtils, Transaction, PROCEDURES } from '@inpi-marques/components'

interface ToAssignTableBodyProps {
    list: Transaction[],
    onSelect: (idRequest: string) => void,
    checkedList: string[]
}

const ToAssignTableBody: FC<ToAssignTableBodyProps> = ({
  list,
  onSelect,
  checkedList
}) => {
  return (
    <tbody>
      {list.map((elem: Transaction) => {
        const procedureType = PROCEDURES.find(procedure => procedure.value === elem.procedureType)
        return (
          <tr key={elem.id}>
            <td
              onClick={(e) => {
                e.stopPropagation()
              }}
              className='border-0 table-checkbox'
            >
              <CheckboxField
                inputId={`check-${elem.id}`}
                label=''
                onChange={() => onSelect(elem.id)}
                checked={checkedList.includes(elem.id)}
              />
            </td>
            <td className='border-0 text-center'>{DateUtils.formatDateFr(elem.lastUpdate)}</td>
            <td className='border-0 text-center'>{elem.numNat}</td>
            <td className='border-0 text-center'>{elem.internalReference}</td>
            <td className='border-0 text-center'>
              <span className={`rounded px-2 procedure-type-${procedureType?.value?.toLowerCase()}`}>
                {procedureType?.label}
              </span>
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

export default ToAssignTableBody
