import { DocumentType, DOCUMENT_COMMUNICABILITIES, FieldStatus, TransactionDocument } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../constants/Message'
import DocumentsBlockItem from './DocumentsBlockItem'

interface DocumentsBlockProps {
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument)=> void,
    onDocumentEdited: (document: TransactionDocument) => void,
    handleDownload: (document:TransactionDocument) => void
    documents: TransactionDocument[],
    additionalType: string,
    documentTypes: DocumentType[],
    fieldStatus?: FieldStatus,
    fromBO?: boolean,
    useCommunicability?: boolean
}

/**
 * Composant permettant l'ajout des focument d'Acte à accomplir et Pièce justificative d'un relevé de déchéance
 */
const DocumentsBlock: FC<DocumentsBlockProps> = ({ documents, fieldStatus, documentTypes, fromBO = false, useCommunicability = false, onDocumentAdded, onDocumentDeleted, onDocumentEdited, additionalType, handleDownload }) =>
  <IntlProvider locale='fr' messages={Message}>
    <div>
      <div className='row p-0'>
        <div className='col-3'><FormattedMessage id='revocation_statement_document_block_file_title' /></div>
        <div className='col-3'><FormattedMessage id='revocation_statement_document_block_type_title' /></div>
        {useCommunicability &&
          <div className='col-3'><FormattedMessage id='revocation_statement_document_block_communicability_title' /></div>}

        {documents.map((document: TransactionDocument, key: number) =>
          <DocumentsBlockItem
            className='mb-3'
            key={key}
            index={key}
            document={document}
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={fromBO && document.isAddedByFo ? undefined : onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            additionalType={additionalType}
            documentTypes={documentTypes}
            fieldStatus={fieldStatus}
            handleDownload={handleDownload}
            useCommunicability={useCommunicability}
          />
        )}
      </div>
      <DocumentsBlockItem
        className='mt-4'
        document={{ name: '', format: '', type: '', additionalType: additionalType, communicability: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE }}
        onDocumentAdded={onDocumentAdded}
        onDocumentEdited={onDocumentEdited}
        additionalType={additionalType}
        documentTypes={documentTypes}
        fieldStatus={fieldStatus}
        handleDownload={handleDownload}
        useCommunicability={useCommunicability}
      />
    </div>
  </IntlProvider>

export default DocumentsBlock
