import React, { FC } from 'react'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import {
  EventType,
  Transaction,
  TransactionDocument,
  FieldStatus,
  ErrorField
} from '@inpi-marques/components'
import ProtectionExtensions from '../../../protectionExtensions/ProtectionExtensions'
import Priorities from '../../../priorities/Priorities'

interface OptionsProps {
  transaction: Transaction,
  documents: TransactionDocument[],
  setDocuments: (documents: TransactionDocument[]) => void,
  onDocumentDeleted: (event: EventType) => void,
  editIndex: number,
  setEditIndex: (index : number) => void,
  fieldStatus: FieldStatus,
    setIsRequired: (value: boolean) => void
}

const Options: FC<OptionsProps> = ({ transaction, documents, editIndex, fieldStatus, setDocuments, onDocumentDeleted, setEditIndex, setIsRequired }) =>
  <>
    <div className='row mb-4 justify-content-end'>
      <InternalReferenceField
        transaction={transaction}
        className='col-3'
      />
    </div>

    <div className='row'>
      <div className='col-9'>
        <div className='mb-5'>
          <Priorities
            transaction={transaction}
            documents={documents}
            setDocuments={setDocuments}
            onDocumentDeleted={onDocumentDeleted}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            setIsRequired={setIsRequired}
          />
        </div>

        <ProtectionExtensions
          transaction={transaction}
        />
      </div>
    </div>
    {fieldStatus.priority && (
      <div>
        <ErrorField
          message={fieldStatus.priority}
          className='fade alert alert-danger show position-relative mt-4'
        />
      </div>
    )}
  </>

export default Options
