import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

type SubmitButtonLoaderProps = {
    height: number,
    width: number,
    rectangleHeight?: number,
    rectangleWidth?: number,
    startX?: number,
    startY?: number
};

const SubmitButtonLoader: FC<SubmitButtonLoaderProps> = ({
  height = 15,
  width = 15,
  startX = 10,
  startY = 10
}) => {
  return (
    <>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
      >
        <rect x={startX} y={startY} rx='5' ry='5' width={width} height={height} />
      </ContentLoader>
    </>
  )
}

export default SubmitButtonLoader
