import { DateUtils, Priority, ProductService, Record } from '@inpi-marques/components'

class PriorityService {
  /**
   * Génère la priorité à partir d'un record.
   * @param record
   * @returns
   */
  getPriorityFromRecord = (record: Record): Priority => (
    {
      originalDepositNumber: record.numNat,
      originalDepositDate: DateUtils.formatToBeginOfDay(record.depositDate),
      countryOrOrganization: record.country ?? 'FR',
      productAndServices: ProductService.getCurrentVersion(record?.details?.productsAndServicesVersions)?.productClasses || []
    }
  )
}

export default new PriorityService()
