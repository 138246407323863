import http from '../../network/http-common'
import axios, { CancelTokenSource } from 'axios'
import {
  OFFICIAL_COPY_BRAND,
  OfficialDocumentRequested,
  OfficialDocumentType,
  Payment,
  Record,
  RecordService,
  UserLight
} from '@inpi-marques/components'
import Message from '../../constants/Message'
import { createIntl, IntlShape } from 'react-intl'

class OfficialDocumentService {
    source: CancelTokenSource
    intl: IntlShape

    constructor () {
      this.source = axios.CancelToken.source()
      this.intl = createIntl({ locale: 'fr', messages: Message })
    }

    /**
     * Récupère le récapitulatif de paiement d'une demande de documents officiels.
     * @param id
     * @param isAdministration
     * @returns
     */
    getPaiement = async (id: string, isAdministration?: boolean): Promise<Payment> => {
      try {
        const result: Payment = await http.get(`/api/official-documents/${id}/paiements`, {
          params: {
            isAdministration: isAdministration ? 1 : 0
          }
        })
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    /**
     * Retourne un tableau paginé de documents officiels
     * @param officialDocumentsRequested
     * @param nbItemsPerPage
     * @returns
     */
    getPaginatedOfficialDocumentsRequested = (officialDocumentsRequested: OfficialDocumentRequested[], nbItemsPerPage: number): OfficialDocumentRequested[][] => {
      const paginatedOfficialDocumentsRequested: OfficialDocumentRequested[][] = []

      let page: OfficialDocumentRequested[] = []
      officialDocumentsRequested.forEach((officialDocumentRequested: OfficialDocumentRequested, key: number) => {
        page.push(officialDocumentRequested)
        if ((page.length % nbItemsPerPage === 0) || (key + 1) === officialDocumentsRequested.length) {
          paginatedOfficialDocumentsRequested.push(page)
          page = []
        }
      })
      return paginatedOfficialDocumentsRequested
    }

    /**
     * Vérifie que le compte rattaché à la transaction peut faire une demande de documents pour la marque
     * dans le cas d'une demande de copie officielle de marque
     * @returns
     * @param officialDocumentType
     * @param record
     * @param user
     */
    canAskDocumentForRecord = (record: Record, user: UserLight, officialDocumentType?: OfficialDocumentType): boolean => {
      if (officialDocumentType?.code === OFFICIAL_COPY_BRAND) {
        return (RecordService.isPublished(record) || record.recipient?.email === user.email)
      }
      return true
    }
}

export default new OfficialDocumentService()
