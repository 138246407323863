import { Contributor, FieldStatus, OfficialDocumentRequested, SubmitButton, Transaction } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import OfficialDocumentRequestedForm from './OfficialDocumentRequestedForm'

interface OfficialDocumentRequestedValidateContentProps {
    // document officiel actuel à modifier
    officialDocumentRequested?: OfficialDocumentRequested,
    // fonction pour modifier le document officiel
    setOfficialDocumentRequested: (newDocument: OfficialDocumentRequested) => void,
    // informations concernant les erreurs sur le formulaire
    fieldStatus: FieldStatus,
    isEditingMode?: boolean,
    validate?: () => void,
    cancel?: () => void,
    transaction: Transaction,
    currentETSUser?: Contributor
}

/**
 * Composant permettant d'ajouter ou de modifier un type de document officiel.
 * Il permet également de rechercher et d'ajouter/modifier le titre associé au type de document officiel
 *
 * @param officialDocumentRequested - Objet regroupant un type de document officiel et un titre
 * @param setOfficialDocumentRequested - Fonction pour modifier la demande
 * @param fieldStatus - Objet contenant les erreurs du formulaire
 * @param isEditingMode - Savoir si on est à l'édition ou ajout
 * @param validate - Fonction pour valider l'ajout ou les modifications
 * @param cancel - Fonction pour annuler l'ajout/modifications
 * @param transaction - Objet transaction
 * @param currentETSUser - Objet qui contient les données de l'utilisateur connecté
 * @constructor
 */
const OfficialDocumentRequestedValidateContent: FC<OfficialDocumentRequestedValidateContentProps> = ({
  officialDocumentRequested,
  setOfficialDocumentRequested,
  fieldStatus,
  isEditingMode = false,
  validate,
  cancel,
  transaction,
  currentETSUser
}) => {
  return (
    <div className='inscription-content'>
      <>
        <OfficialDocumentRequestedForm
          fieldStatus={fieldStatus}
          officialDocumentRequested={officialDocumentRequested}
          setOfficialDocumentRequest={setOfficialDocumentRequested}
          transaction={transaction}
          isEditingMode={isEditingMode}
          currentETSUser={currentETSUser}
        />
        {(cancel || validate) && officialDocumentRequested &&
          <div className='row mt-4 justify-content-end'>
            {cancel &&
              <SubmitButton className='btn btn-link text-primary mr-5' onClick={cancel}>
                <FormattedMessage id='button_cancel' />
              </SubmitButton>}
            {validate &&
              <SubmitButton className='bg-primary' onClick={validate}>
                <FormattedMessage
                  id={`${(isEditingMode ? 'button_modify_request' : 'button_add_request')}`}
                />
              </SubmitButton>}
          </div>}
      </>
    </div>
  )
}

export default OfficialDocumentRequestedValidateContent
