import React, { FC } from 'react'
import {
  FRMI, FRMI_LICENCE_TYPES, FRMILicenceType, SelectOption
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { TIME_DURATION } from '@inpi-marques/components/src/constants/CommonConstants'

interface LicenceInscriptionVariousIndicationsOverviewProps {
    frmi: FRMI
}

const LicenceInscriptionVariousIndicationsOverview: FC<LicenceInscriptionVariousIndicationsOverviewProps> = ({ frmi }) => {
  return (
    <>
      {
                frmi?.licenceType &&
                  <>
                    <h4 className='font-weight-bold'>
                      <FormattedMessage id='frmi_overview_licence_type' />
                    </h4>
                    <span><FormattedMessage id={FRMI_LICENCE_TYPES.find((type: FRMILicenceType) => type.value === frmi?.licenceType)?.label} /></span>
                  </>
      }
      {
        (frmi?.licenceDuration || frmi.licenceDurationType) &&
          <>
            <h4 className='font-weight-bold mt-3'>
              <FormattedMessage id='frmi_licence_duration_label' />
            </h4>
            <span>{frmi?.licenceDuration} {TIME_DURATION.find((duration: SelectOption) => duration.value === frmi.licenceDurationType)?.label}</span>
          </>
      }
    </>
  )
}

export default LicenceInscriptionVariousIndicationsOverview
