import Message from './Message'
import { TableTitleItem } from '@inpi-marques/components'

export const FIELD_NUMNAT = 'numNat'
export const FIELD_BRAND_MODEL = 'details.brand.text'
export const FIELD_ORIGIN = 'origin'
export const FIELD_DEPOSIT_DATE = 'details.depositDate'
export const FIELD_CREATED_AT = 'createdAt'
export const FIELD_HOLDERS = 'holders'
export const FIELD_CLASS = 'details.productsAndServicesVersions.productClasses.ref'
export const FIELD_BRAND_TYPE_RECORD = 'details.brand.type'
export const FIELD_DEPOSIT_DATE_RECORD = 'details.depositDate'

export const TABLE_RECORD_LIST : TableTitleItem[] = [
  {
    label: Message.records_numnat,
    value: FIELD_NUMNAT
  },
  {
    label: Message.records_brand_model,
    value: FIELD_BRAND_MODEL
  },
  {
    label: Message.records_origin,
    value: FIELD_ORIGIN,
    isNotSortingValue: true
  },
  {
    label: Message.records_deposit_date,
    value: FIELD_DEPOSIT_DATE_RECORD
  },
  {
    label: Message.records_holders,
    value: FIELD_HOLDERS,
    isNotSortingValue: true
  },
  {
    label: Message.records_classes,
    value: FIELD_CLASS,
    isNotSortingValue: true
  }
]

export const TABLE_RECORD_LIST_LIGHT_ADVANCED_SEARCH : TableTitleItem[] = [
  {
    label: Message.records_numnat,
    value: FIELD_NUMNAT
  },
  {
    label: Message.records_brand_model,
    value: FIELD_BRAND_MODEL,
    isNotSortingValue: true
  },
  {
    label: Message.records_deposit_date,
    value: FIELD_DEPOSIT_DATE_RECORD
  },
  {
    label: Message.records_classes,
    value: FIELD_CLASS,
    isNotSortingValue: true
  }
]
