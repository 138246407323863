import {
  downloadFile,
  DropArea,
  EventType,
  FileBrowserField,
  FileButton,
  Preview,
  TextField,
  TransactionDocument
} from '@inpi-marques/components'
import { DOCUMENT_FORMATS, DOCUMENT_LIMITS, DOCUMENT_TYPES } from '@inpi-marques/components/src/constants/DocumentConstants'
import { BrandTypeContentProps } from 'interfaces/Deposit'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import TransactionService from '../../../../../services/transaction/TransactionService'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import { storeTransactionUpdateDeposit } from '../../../../../store/transaction/transactionActions'
import DocumentService from '../../../../../services/document/DocumentService'

const ColorContent: FC<BrandTypeContentProps> = ({
  transaction,
  brand,
  brandType,
  fieldStatus,
  onBrandDocumentDroped,
  onBrandDocumentAdded,
  onBrandDocumentDeleted,
  document,
  documentPreview,
  onDocumentChanged,
  onDocumentDeleted,
  documents
}) => {
  const dispatch = useDispatch()

  const [color, setColor] = useState<string>(brand.colors ?? '')

  const onColorChanged = (event: EventType): void => {
    const { value } = event.target
    setColor(value)
  }

  const onColorBlur = (): void => {
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, colors: color } }))
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  const proofDocument: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.PROOF_OF_USE)

  return (
    <>
      <div className='col-12 mb-3'>
        <DropArea
          accept={brandType.acceptedFormat}
          maxSize={brandType.maxSize}
          text='drop_brand_color_file'
          onDrop={(files: File[]) => onBrandDocumentDroped(files[0])}
          fieldStatus={fieldStatus}
          nameFieldStatus='brandDocument'
        />
        {document &&
          <Preview
            file={documentPreview ?? (() => onBrandDocumentAdded(brand))}
            onTrashClick={() => onBrandDocumentDeleted(brand)}
            document={document}
            isPrintFormat
          />}
      </div>
      <TextField
        inputId='color-text'
        classNameFormGroup='col-md-12 col-lg-6'
        className='col-6 pl-0'
        required
        value={color}
        label={<FormattedMessage id='brand_type_color_text_label' />}
        onChange={onColorChanged}
        onBlur={onColorBlur}
        fieldStatus={fieldStatus}
        nameFieldStatus='brandColors'
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
      />
      <FileBrowserField
        inputId='brand_type_proof_file'
        nameFieldStatus='brandAdditionalFile'
        label={<FormattedMessage id='brand_type_proof_form_file_label' />}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        maxNumberFile={1}
        maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
        fieldStatus={fieldStatus}
        buttonLabel={<FileButton />}
        onChange={(event: EventType) => onDocumentChanged(event, DOCUMENT_TYPES.PROOF_OF_USE)}
        value={proofDocument && [proofDocument]}
        onDelete={onDocumentDeleted}
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
        informationDoc
        handleDownload={handleDownload}
      />
    </>
  )
}

export default ColorContent
