import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { BASIC_DATE, Country, DateUtils, Priority, Table } from '@inpi-marques/components'
import Message from '../../constants/Message'
import ContentService from 'services/content/ContentService'
import { FormattedMessage } from 'react-intl'

interface OverviewPrioritiesProps {
  priorities?: Priority[],
  editPriority?: (index: number) => void,
  deletePriority?: (index: number) => void,
  className?: string,
  hasTitle?: boolean
}

const OverviewPriorities: FC<OverviewPrioritiesProps> = (
  {
    priorities,
    editPriority,
    deletePriority,
    className = '',
    hasTitle = false
  }) => {
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })
  }, [])

  return (
    <>
      {hasTitle && <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_options_priorities' /></h4>}
      {priorities && priorities.length > 0
        ? (
          <Table
            tableTitle={[
              { label: Message.options_columns_label_id, className: 'table-column-label pl-2' },
              { label: Message.options_columns_label_number, className: 'table-column-label' },
              { label: Message.options_columns_label_date, className: 'table-column-label' },
              { label: Message.options_columns_label_country, className: 'table-column-label table-column-country' }
            ]}
            tableClassName={`priority-table ${className}`}
          >
            <tbody>
              {
                priorities.map((priority: Priority, index: number) => {
                  const country: Country | undefined = countries.find(item => item.code === priority.countryOrOrganization)
                  return (
                    <tr key={`-${index}`}>
                      <td className='pl-4'>{index + 1}</td>
                      <td>{priority.originalDepositNumber}</td>
                      <td>{DateUtils.formateDateToFormat(priority.originalDepositDate, BASIC_DATE)}</td>
                      <td>{country?.name}</td>
                      {(editPriority || deletePriority) &&
                        <td className='btn-column pr-4'>
                          {editPriority &&
                            <button className='btn btn-link text-primary' onClick={() => editPriority(index)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>}
                          {deletePriority &&
                            <button className='btn btn-link text-danger ml-3' onClick={() => deletePriority(index)}>
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>}
                        </td>}
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        )
        : <div><FormattedMessage id='none_feminine' /></div>}
    </>
  )
}

export default OverviewPriorities
