import React, { FC, useEffect, useState } from 'react'
import {
  CardBlock,
  CheckboxField,
  DOCUMENT_TYPES,
  ErrorField,
  EventType,
  FieldStatus,
  HelpBlock,
  InscriptionCorrection,
  NatureCode,
  TextArea,
  Transaction,
  TransactionDocument,
  InscriptionNatureCode,
  NATURE_CODES,
  CommonInscriptionService
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import ContentService from 'services/content/ContentService'
import NatureCodeService from 'services/nature-code/NatureCodeService'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateInscription } from 'store/transaction/transactionActions'
import DocumentsBlock from './DocumentsBlock'
import RectifyBlock from './RectifyBlock'

interface CorrectionFormProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    documents: TransactionDocument[],
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentEdited: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument)=> void,
}

/**
 * Etape de Rectification pour une Inscription de rectification
 */
const CorrectionForm: FC<CorrectionFormProps> = ({ transaction, fieldStatus, documents, onDocumentAdded, onDocumentDeleted, onDocumentEdited }) => {
  const dispatch = useDispatch()

  /** Code natures récupérées du Back */
  const [natureCodes, setNatureCodes] = useState<NatureCode[]>([])

  /** Champs texte */
  const [correction, setCorrection] = useState<InscriptionCorrection>(transaction.inscription?.correction ?? {})

  useEffect(() => {
    setCorrection(transaction.inscription?.correction ?? {})
  }, [transaction.inscription?.correction])

  useEffect(() => {
    ContentService.getNatureCodesByType(transaction.subProcedureType).then((response: NatureCode[]) => {
      setNatureCodes(response)
    })
  }, [])

  const handleNatureCodeChanged = (editedNatureCode: NatureCode): void => {
    const selectedNatureCodes: NatureCode[] = natureCodes.filter((natureCode: NatureCode) =>
      transaction.inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => inscriptionNatureCode.code === natureCode.code))

    let updatedInscriptionNatureCodes = transaction.inscription?.natureCodes ?? []

    // Si on selectionne un code d'une autre catégorie (position), supprime les autres
    if (editedNatureCode.code && !selectedNatureCodes.every((selectedNatureCode: NatureCode) => selectedNatureCode.position === editedNatureCode.position)) {
      updatedInscriptionNatureCodes = [{ code: editedNatureCode.code, label: editedNatureCode.label, psDisplayType: editedNatureCode.psDisplayType }]
    } else if (transaction.inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => inscriptionNatureCode.code === editedNatureCode.code)) {
      // Si le code nature est déjà sélectionner, on le desélectionner
      updatedInscriptionNatureCodes = transaction.inscription?.natureCodes.filter((inscriptionNatureCode: InscriptionNatureCode) => inscriptionNatureCode.code !== editedNatureCode.code)
    } else if (editedNatureCode.code) {
      // Sinon on l'ajout
      const currentNatureCodes: InscriptionNatureCode[] = transaction.inscription?.natureCodes ?? []
      updatedInscriptionNatureCodes = [...currentNatureCodes, { code: editedNatureCode.code, label: editedNatureCode.label, psDisplayType: editedNatureCode.psDisplayType }]
    }

    // Mise à jour du store
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      natureCodes: updatedInscriptionNatureCodes
    }))
  }

  const onTextFieldBlur = (): void => {
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      correction
    }))
  }

  const onCorrectionChanged = (event: EventType): void => {
    const { name, value } = event.target
    setCorrection({
      ...correction,
      [name]: value
    })
  }

  const onRectifyBlockChanged = (editedCorrection: InscriptionCorrection): void => {
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      correction: editedCorrection
    }))
  }

  /** Nature code triés par leur position */
  const sortedNatureCodes: NatureCode[][] = NatureCodeService.sortNatureCodes(natureCodes)
  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='correction_title' /></h1>
          <span className='subtitle'><FormattedMessage id='correction_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row justify-content-between'>
        <div className='col-6'>
          <CardBlock header={<FormattedMessage id='correction_nature_code_block_title' />} shadow>
            {natureCodes && natureCodes.length > 0 && sortedNatureCodes.map((filteredNatureCodes: NatureCode[], key: number) =>
              <div className='row flex-column' key={key}>
                <div>
                  {filteredNatureCodes.map((natureCode: NatureCode, codeIndex: number) =>
                    <CheckboxField
                      key={codeIndex}
                      inputId={`nature_code_${natureCode.code?.toLocaleLowerCase()}`}
                      label={natureCode.label}
                      value={transaction.inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => natureCode.code === inscriptionNatureCode.code)}
                      checked={transaction.inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => natureCode.code === inscriptionNatureCode.code)}
                      onChange={() => handleNatureCodeChanged(natureCode)}
                    />
                  )}
                </div>

                {key + 1 !== sortedNatureCodes.length &&
                  <div className='d-flex'>
                    <hr className='col-4' />
                    <span className='col-2 text-center p-1 font-weight-bold text-uppercase'> <FormattedMessage id='or' /> </span>
                    <hr className='col-4' />
                  </div>}
              </div>
            )}
            {fieldStatus.natureCodes &&
              <div>
                <ErrorField message={fieldStatus.natureCodes} />
              </div>}
          </CardBlock>
        </div>
        <div className='col-6'>
          <CardBlock header={<FormattedMessage id='correction_statement_block_title' />} shadow>
            <TextArea
              inputId='replacedText'
              label={<FormattedMessage id='correction_replaced_text_label' />}
              rows={4}
              value={correction.replacedText}
              onChange={onCorrectionChanged}
              onBlur={onTextFieldBlur}
              required={CommonInscriptionService.isFieldMandatory(transaction?.inscription?.natureCodes, natureCodes, 'replacedText')}
              fieldStatus={fieldStatus}
            />
            <TextArea
              inputId='replacementText'
              label={<FormattedMessage id='correction_replacement_text_label' />}
              rows={4}
              value={correction.replacementText}
              onChange={onCorrectionChanged}
              onBlur={onTextFieldBlur}
              required={CommonInscriptionService.isFieldMandatory(transaction?.inscription?.natureCodes, natureCodes, 'replacementText')}
              fieldStatus={fieldStatus}
            />
            <TextArea
              inputId='comment'
              label={<FormattedMessage id='correction_comment_label' />}
              rows={2}
              value={correction.comment}
              onChange={onCorrectionChanged}
              onBlur={onTextFieldBlur}
            />
          </CardBlock>
        </div>
        {transaction.inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => inscriptionNatureCode.code === NATURE_CODES.EM) &&
          <div className='col-12 mt-4'>
            <RectifyBlock correction={correction} setCorrection={onRectifyBlockChanged} fieldStatus={fieldStatus} />
          </div>}
        <div className='col-12 mt-4'>
          <CardBlock header={<FormattedMessage id='correction_documents_block_title' />} shadow>
            <HelpBlock>
              <FormattedMessage id='documents_communicability_warning_text' />
            </HelpBlock>
            <DocumentsBlock
              onDocumentAdded={onDocumentAdded}
              onDocumentDeleted={onDocumentDeleted}
              onDocumentEdited={onDocumentEdited}
              title={<FormattedMessage id='inscription_other_files_label' />}
              documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_OTHER) ?? []}
              documentType={DOCUMENT_TYPES.INSCRIPTION_OTHER}
              transaction={transaction}
            />
            <DocumentsBlock
              onDocumentAdded={onDocumentAdded}
              onDocumentDeleted={onDocumentDeleted}
              onDocumentEdited={onDocumentEdited}
              title={<FormattedMessage id='inscription_mandatory_power_files_label' />}
              documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER) ?? []}
              documentType={DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER}
              transaction={transaction}
            />
            {fieldStatus.documents_communicability &&
              <div>
                <ErrorField message={fieldStatus.documents_communicability} />
              </div>}
          </CardBlock>
        </div>

      </div>
    </>
  )
}

export default CorrectionForm
