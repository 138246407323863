import React, { Dispatch, FC } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Contributor } from '../../interfaces/contributors/ContributorsInterfaces'
import { EventType, FieldStatus } from '../../form/FormInterfaces'
import {
  CIVILITES,
  SelectField,
  TextField
} from '../../index'

interface PhysicalPersonFieldsProps extends WrappedComponentProps{
    contributor?: Contributor,
    onChange: (event: EventType) => void,
    fieldStatus?: FieldStatus | any,
    dispatch?: Dispatch<any>,
    resetError?: (fieldStatus: FieldStatus) => void,
    readOnly?: boolean,
    allRequired?: boolean,
    isBO?: boolean
}

const PhysicalPersonFields: FC<PhysicalPersonFieldsProps> = ({
  contributor = {},
  onChange,
  fieldStatus,
  intl,
  dispatch,
  resetError,
  readOnly = false,
  allRequired,
  isBO = false
}) => (
  <div className='row'>
    <div className='col-12 col-md-4'>
      <SelectField
        inputId='civilityCode'
        label={intl.formatMessage({ id: 'field_civilite_label' })}
        placeholder={intl.formatMessage({ id: 'placeholder_select' })}
        value={contributor.civilityCode || ''}
        options={CIVILITES}
        onChange={onChange}
        fieldStatus={fieldStatus}
        required={isBO ? false : allRequired}
        readOnly={readOnly}
      />
    </div>
    <div className='col-12 col-md-4'>
      <TextField
        inputId='lastname'
        label={intl.formatMessage({ id: 'field_nom_label' })}
        value={contributor.lastname || ''}
        onChange={onChange}
        fieldStatus={fieldStatus}
        maxLength={100}
        required={allRequired}
        dispatch={dispatch}
        resetError={resetError}
        readOnly={readOnly}
      />
    </div>
    <div className='col-12 col-md-4'>
      <TextField
        inputId='firstname'
        label={intl.formatMessage({ id: 'field_prenom_label' })}
        value={contributor.firstname || ''}
        onChange={onChange}
        fieldStatus={fieldStatus}
        maxLength={100}
        required={allRequired}
        dispatch={dispatch}
        resetError={resetError}
        readOnly={readOnly}
      />
    </div>
  </div>
)

export default injectIntl(PhysicalPersonFields)
