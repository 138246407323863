import {
  containsErrors,
  Contributor,
  CONTRIBUTOR_HOLDERS,
  CONTRIBUTOR_OLD_HOLDERS,
  ContributorValidator,
  DEPOSIT_CONTRIBUTORS_TYPES,
  FieldStatus,
  FRMI_TYPE_AGENT_EDITION,
  FRMI_TYPE_HOLDER_CHANGE, FRMI_TYPE_HOLDER_EDITION,
  FRMI_TYPE_POST_DESIGNATION_REGISTER,
  FRMICountry,
  isFilled,
  messageIsFilled,
  PersonneFieldValidator,
  Transaction,
  TransactionService,
  UE
} from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class FrmiContributorValidator {
    /**
     * Valide les contributeurs des
     * @param contributor
     * @param transaction
     * @param contributorType
     * @param isBO
     * @param countriesChosen
     * @param isFirst
     * @returns
     */
    validateContributor = (contributor: Contributor = {}, transaction?: Transaction, contributorType?: string, isBO: boolean = false, countriesChosen?: FRMICountry[], isFirst?: boolean): FieldStatus => {
      // Pour toutes les OP sauf MM10, les adresses ne sont pas obligatoires
      if (transaction && TransactionService.isFrmiOP(transaction) && transaction?.subProcedureType !== FRMI_TYPE_AGENT_EDITION.value) {
        return {
          ...PersonneFieldValidator.validateAll(contributor, transaction, contributorType, isBO, false),
          email: '',
          otherLanguage: transaction?.subProcedureType === FRMI_TYPE_POST_DESIGNATION_REGISTER.value && countriesChosen && countriesChosen.some((country: FRMICountry) => country.code === UE) && isFirst
            ? messageIsFilled(contributor?.otherLanguage) : '',
          OMPILanguage: (transaction?.subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value && contributorType === CONTRIBUTOR_HOLDERS.value)
            ? messageIsFilled(contributor?.OMPILanguage) : ''
        } as FieldStatus
      }

      return transaction && (contributorType === 'agent' || contributorType === 'old_agent') ? ContributorValidator.validateAgent(transaction, contributor, contributorType) : ContributorValidator.validateContributor(contributor, transaction, contributorType, false, countriesChosen, isFirst)
    }

  /**
  * Valide les Nouveaux ou titulaires actuels
  * @returns
  */
  validateOnChangeStepHolders = (newDepositorRequired: boolean = false): boolean => {
    const transaction = store.getState().transaction
    let fieldStatus = {}
    if (!isFilled(transaction.otherApplicants) || !transaction.otherApplicants?.some((otherApplicant: Contributor) => otherApplicant.code === CONTRIBUTOR_OLD_HOLDERS.value)) {
      fieldStatus = {
        contributor: Message.contributor_old_holders_frmi_required
      }
    } else if (transaction.otherApplicants?.length) {
      let isFirst: boolean = true
      for (let i = 0; i < transaction.otherApplicants.length; i++) {
        if (transaction.otherApplicants[i].code && transaction.otherApplicants[i].code === 'old_holders') {
          const error = this.validateContributor(transaction.otherApplicants[i], transaction, DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value, false, transaction.frmiDetails?.countries, isFirst)
          isFirst = false
          if (containsErrors(error)) {
            fieldStatus = {
              contributor: Message.contributor_holders_frmi_missing_field
            }
            break
          }
        }
      }
    }
    if (newDepositorRequired && !isFilled(transaction.holders)) {
      fieldStatus = {
        contributor: Message.contributor_holders_frmi_required
      }
    } else if (transaction.subProcedureType === FRMI_TYPE_HOLDER_EDITION.value && !transaction.otherApplicants?.some((contributor: Contributor) => (contributor.state === 'EDITED' || contributor.state === 'ADDED') && contributor.code === CONTRIBUTOR_OLD_HOLDERS.value)) {
      fieldStatus = {
        contributor: Message.contributor_old_holders_frmi_update_required
      }
    }

    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus)
  }
}

export default new FrmiContributorValidator()
