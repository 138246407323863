import {
  DateUtils,
  Payment,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  ProductAndService,
  ProductClass,
  ProductClassVersion,
  ProductService as CommonProductService,
  Transaction,
  PRODUCT_STATUS
} from '@inpi-marques/components'
import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import { toast } from 'react-toastify'

/* global FormData */

interface IResponseProductClass {
  id?: number,
  ref: string
}

interface IResponseProduct {
  id: number,
  name?: string,
  class: IResponseProductClass
}

class ProductsService {
  intl: IntlShape

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  /**
   * Recherche des produits et services grâce au type de produits et à son nom
   * @returns
   * @param type
   * @param searchValue
   */
  searchProducts = async (type: 'nice'|'tmclass', searchValue?: string): Promise<ProductClass[]> => {
    try {
      const responseProducts: IResponseProduct[] = await http.get(`/api/${type}/products${searchValue ? `?search=${searchValue}` : ''}`)
      return CommonProductService.convertResponseProductsToProductClass(responseProducts, type)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_edit' }))
      return Promise.reject(error)
    }
  }

  importCsvFile = async (file: File) : Promise<ProductClass[]> => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const response: ProductClass[] = await http.post('/api/products', formData)

      return response.map((productClass: ProductClass) => ({
        ...productClass,
        products: productClass.products.map((product: ProductAndService) => ({
          ...product,
          origin: 'import'
        }))
      }))
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_edit' }))
      return Promise.reject(error)
    }
  }

  /**
   * Méthode permettant de sélectionner ou désélectionner tous les produits d'une classe
   */
  getUpdatedSelectedClassList = (selectedProductClasses: ProductClass[], productClassName: string, products: ProductAndService[], type: string): ProductClass[] => {
    const existClass : ProductClass | undefined = selectedProductClasses.find(productClass => productClass.ref === productClassName)
    // Si la classe existe et est déjà sélectionnée, on désélectionne tous les produits de la classe
    if (existClass && existClass?.products.filter(product => product.origin === type).length === products.length) {
      const newClassSelected : ProductClass [] = selectedProductClasses.map(classSelected => {
        if (classSelected.ref === productClassName) {
          return {
            ...classSelected,
            products: classSelected.products.filter(product => product.origin !== type)
          }
        }
        return classSelected
      })
      return newClassSelected.filter(classSelected => classSelected.products.length > 0)
    } else {
      /** Si une classe existe alors on y ajoute tous ses produits
       Sinon, on crée une nouvelle classe avec les nouveaux produits */
      if (existClass) {
        return selectedProductClasses.map(classSelected => {
          if (classSelected.ref === productClassName) {
            return {
              ...classSelected,
              products: classSelected.products.filter(product => product.origin !== type).concat(products)
            }
          }
          return classSelected
        })
      }
      return [...selectedProductClasses, {
        ref: productClassName,
        products: products.map((product: ProductAndService) => (product))
      }]
    }
  }

  /**
   * Méthode permettant de compte le nombre d'objets présents dans une liste de classes
   *
   * @param productClasses
   * @returns
   */
  countProducts = (productClasses: ProductClass[]): number => {
    let count: number = 0
    productClasses.forEach((productClass: ProductClass) => {
      count += productClass.products.length
    })
    return count
  }

  /**
   * Méthode permettant de trier les classes par leur ref
   *
   * @param productClasses
   * @returns
   */
  sortProductClassByRef = (productClasses: ProductClass[]): ProductClass[] => {
    const classes0 = productClasses.filter((productClass: ProductClass) => productClass.ref.includes(','))
    let otherClasses = productClasses.filter((productClass: ProductClass) => !productClass.ref.includes(','))
    otherClasses = otherClasses.sort((first: ProductClass, second: ProductClass) => parseInt(first.ref, 10) > parseInt(second.ref, 10) ? 1 : -1)
    return [...classes0, ...otherClasses]
  }

  /**
   * Récupère le fichier d'exemple des produits et services
   * @returns
   */
  getExampleFile = async (): Promise<string> => {
    try {
      return await http.get('/file/nice.csv')
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_products_and_services_exemple_file' }))
      return Promise.reject(error)
    }
  }

  /**
   * On récupère le paiement d'une régularisation
   * @param transaction
   * @param version
   * @returns
   */
  getRegularizationPaiement = async (transaction: Transaction, version: ProductClassVersion): Promise<Payment|null> => {
    try {
      return await http.post(`/api/transactions/${transaction.id}/regularizations/paiements`, version)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_products_and_services_exemple_file' }))
      return Promise.reject(error)
    }
  }

  /**
   * Validation d'une classe via l'api TMClass
   * @returns
   * @param classes
   */
  validateTMClass = async (classes: ProductClass[]): Promise<ProductClass[]|null> => {
    try {
      // On ne cherche pas à valider les produits Nice et TMClass
      const validatedClasses: ProductClass[] = await http.post('/api/products/validations', CommonProductService.getClassesToValidate(classes))

      return CommonProductService.updateProductClassesFromAnotherList(classes, validatedClasses)
    } catch (error) {
      toast.warning(this.intl.formatMessage({ id: 'error_tmclass_validation' }))
      return Promise.resolve(null)
    }
  }

  /**
   * Génère la version initiale de la marque fille via la version
   * @param parentVersion
   * @returns
   */
  getChildVersionFromParent = (parentVersion: ProductClassVersion): ProductClassVersion => ({
    createdDate: DateUtils.now(),
    productClasses: parentVersion.productClasses
      .filter((productClass: ProductClass) => productClass.ref.length <= 2)
      .map((productClass: ProductClass) => ({
        products: [],
        ref: productClass.ref
      })),
    type: PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION,
    status: PRODUCT_CLASS_VERSION_STATUS.ACCEPTED
  })

  /**
   * Génère un tableau de produits via une liste de produits en chaine de caractère (split par ; ou .)
   * @param strProducts
   * @returns
   */
  getProductsFromString = (strProducts: string): ProductAndService[] =>
    strProducts
      .split(/;|\./)
      .filter((strProduct: string) => strProduct && strProduct.trim())
      .map((strProduct: string) => ({
        origin: 'free',
        name: strProduct.trim()
      }))

  /**
   * Génère une chaine de charactere de produits via un tableau de produit
   * @param products
   * @returns
   */
  getProductsStringFromArray = (products: ProductAndService[]): string =>
    products.map((product: ProductAndService) => product.name).join('; ')

  /**
   * Met à jour la version mère via la version fille
   * @param editedClassVersion
   * @param parentVersion
   * @param updateEmptyParentClass: boolean : supprime automatiquement les classes parents vides si true
   * @returns
   */
  updateParentVersionFromEditedClass = (editedClassVersion: ProductClassVersion, parentVersion: ProductClassVersion, updateEmptyParentClass: boolean): ProductClassVersion|null => {
    // On met à jour la version parent
    let updatedVersion: ProductClassVersion = {
      ...parentVersion,
      productClasses: parentVersion.productClasses.map((productClass: ProductClass) => {
        const editedClass = editedClassVersion.productClasses.find((editedProductClass: ProductClass) => productClass.ref === editedProductClass.ref)
        if (editedClass) {
          return {
            ...productClass,
            products: productClass.products.filter((product: ProductAndService) =>
              !editedClass.products.find((editedProduct: ProductAndService) => editedProduct.name === product.name)
            )
          }
        }
        return productClass
      })
    }

    if (updateEmptyParentClass) {
      // On supprime les classes qui n'ont plus de produits
      updatedVersion = {
        ...updatedVersion,
        productClasses: updatedVersion.productClasses.filter((productClass: ProductClass) => productClass.products.length)
      }
    }
    // S'il n'y a pas de classe, alors on return null pour remonter une erreur
    if (!updatedVersion.productClasses.length) {
      return null
    }

    return updatedVersion
  }

  /**
   * On met à jour les produits et services d'une limitation ou des priorités lors de la mise à jour des produits et service d'une FRMI
   * @param limitationClasses
   * @param frmiClasses
   * @returns
   */
  updatePriorityAndLimitationByFRMIVersion = (limitationClasses: ProductClass[], frmiClasses: ProductClass[]): ProductClass[] => {
    const newProductClasses: ProductClass[] = []

    limitationClasses.forEach((limitationClass: ProductClass) => {
      const frmiClass: ProductClass|undefined = frmiClasses.find((productClass: ProductClass) => productClass.ref === limitationClass.ref && productClass.origin === limitationClass.origin)
      // Si classe dans limitation et pas dans FRMI, on laisse
      if (!frmiClass) {
        newProductClasses.push(limitationClass)
      } else {
        // Si classe dans limitation et FRMI, on met à jour
        const newProducts: ProductAndService[] = []

        limitationClass.products.forEach((limitationProduct: ProductAndService) => {
          const frmiProduct: ProductAndService|undefined = frmiClass.products.find((frmiProduct: ProductAndService) => limitationProduct.name?.replace(/\.|;/g, '') === frmiProduct.name?.replace(/\.|;/g, ''))
          // Si le produit n'a jamais été modifié dans les limitations/priorités, on ajoute celui des frmis
          if (frmiProduct && (!frmiProduct.status || !limitationProduct.status)) {
            newProducts.push(frmiProduct)
          } else {
            newProducts.push(limitationProduct)
          }
        })

        // Si le produit pas dans limitation et dans FRMI, ajout du produit
        frmiClass.products.forEach((frmiProduct: ProductAndService) => {
          if (!newProducts.some((newProduct: ProductAndService) => newProduct.name?.replace(/\.|;/g, '') === frmiProduct.name?.replace(/\.|;/g, ''))) {
            newProducts.push(frmiProduct)
          }
        })
        newProductClasses.push({ ...limitationClass, products: newProducts })
      }
    })

    // Si une classe n'existe pas dans les versions mais dans la nouvelle, on l'ajoute
    frmiClasses.forEach((frmiClass: ProductClass) => {
      if (!newProductClasses.some((comparedVersionClass: ProductClass) => (frmiClass.ref === comparedVersionClass.ref)) && frmiClass.products?.some((product: ProductAndService) => product.status !== PRODUCT_STATUS.DELETED)) {
        newProductClasses.push({ ...frmiClass, products: frmiClass.products.map((product: ProductAndService) => ({ ...product, status: product.status })) })
      }
    })

    return newProductClasses
  }
}

export default new ProductsService()
