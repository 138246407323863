import { Contributor, CONTRIBUTOR_AGENT, CONTRIBUTOR_DEPOSITORS, CONTRIBUTOR_HOLDERS, CONTRIBUTOR_OLD_AGENTS, Transaction } from '@inpi-marques/components'

class FrmiContributorService {
  /**
   * Récupère les intervenants à afficher
   * @param transaction
   * @param code
   * @returns
   */
  getContributors = (transaction: Transaction, code: string): Contributor[] => {
    if (code === CONTRIBUTOR_AGENT.value && transaction.agent) {
      return [transaction.agent]
    } else if (code === CONTRIBUTOR_HOLDERS.value && transaction.holders) {
      return transaction.holders
    }
    return transaction.otherApplicants?.filter((applicant: Contributor) => applicant.code === code) ?? []
  }

  /**
   * Récupère le type de contributeur en fonction du code de celui-ci
   * @param code
   * @returns
   */
  getContributorTypeByCode = (code: string): string =>
    code === CONTRIBUTOR_AGENT.value || CONTRIBUTOR_OLD_AGENTS.value ? CONTRIBUTOR_AGENT.value : CONTRIBUTOR_DEPOSITORS.value
}

export default new FrmiContributorService()
