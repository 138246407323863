import fileDownload from 'js-file-download'
import { TransactionDocument } from 'src'
/**
   * Télécharge un fichier
   */
export const downloadFile = (response : any, name? : string) => {
  if (response) {
    if (response.data && response.filename) {
      fileDownload(response.data, decodeURIComponent(response.filename.replace(/\+/g, ' ')))
    } else if (name && name.length) {
      fileDownload(response, name)
    }
  }
}

/**
 * Récupère le nom d'un document
 * @param document
 * @returns
 */
export const getDocumentName = (document: TransactionDocument): string => {
  if (document.name?.length) {
    return document.name
  }
  if (document.filename?.length) {
    return document.filename
  }
  return document.internalName
}

/**
 * Tri une liste de document par leur date de d'upload
 * @param documents
 */
export const sortDocumentByCreateAt = (documents: TransactionDocument[]): void => {
  documents.sort((first: TransactionDocument, second: TransactionDocument) => {
    if (!first.createdAt) {
      return -1
    }
    if (!second.createdAt) {
      return 1
    }
    return first.createdAt > second.createdAt ? 1 : -1
  })
}

/**
 * Check si un document a eu sa communicabilité ou son type édité
 * @param existingDocument
 * @param updatedDocument
 * @returns
 */
export const wasEdited = (existingDocument: TransactionDocument, updatedDocument: TransactionDocument): boolean =>
  existingDocument.type !== updatedDocument.type || (existingDocument.communicability && existingDocument.communicability !== updatedDocument.communicability)

/**
 * On récupère le document dans la liste
 * @param documents
 * @param document
 * @returns
 */
export const getNotEditedDocument = (documents: TransactionDocument[], document: TransactionDocument): TransactionDocument|undefined =>
  documents.find((item: TransactionDocument) =>
    item.internalName &&
    item.internalName === document.internalName &&
    !wasEdited(item, document))

/**
 * On récupère un document qui a potentiellement été modifier par son type ou sa communicabilité
 * @param documents
 * @param internalName
 * @returns
 */
export const getEditedDocument = (documents: TransactionDocument[], document: TransactionDocument): TransactionDocument|undefined =>
  documents.find((item: TransactionDocument) =>
    item.internalName &&
    item.internalName === document.internalName &&
    wasEdited(item, document))
