import {
  Contest,
  DateUtils,
  PROCEDURE_INSCRIPTION,
  PROCESS_NORMAL,
  STATUS_INSCRIPTION,
  Transaction,
  Title
} from '@inpi-marques/components'
import { Payment } from '@inpi-marques/components/src/interfaces/deposit/Deposit'
import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import axios, { CancelTokenSource } from 'axios'
import { toast } from 'react-toastify'

class InscriptionService {
    intl: IntlShape
    source: CancelTokenSource

    constructor () {
      this.intl = createIntl({ locale: 'fr', messages: Message })
      this.source = axios.CancelToken.source()
    }

    /**
     * Vérifie les conditions pour pouvoir effectuer une demande de transformation TA
     * @param transaction
     */
    canAskForTransformationTA = (transaction: Transaction): boolean => {
      return transaction.procedureType === PROCEDURE_INSCRIPTION.value &&
            transaction.status === STATUS_INSCRIPTION.TO_ATTRIBUTE &&
            transaction.process === PROCESS_NORMAL
    }

    /**
     * Récupère le récapitulatif de paiement d'une inscription.
     * @returns
     */
    getPaiement = async (id: string, isTA: number = 0): Promise<Payment> => {
      try {
        const result: Payment = await http.get(`/api/inscriptions/${id}/paiements?isTransformationTA=${isTA}`)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    /**
     * Récupère les transactions pouvant être rattacher à une inscription de constitution de mandataire
     * @param inscription
     * @returns
     */
    getAttachments = async (inscription: Transaction): Promise<Transaction[]> => {
      try {
        return await http.get(`/api/inscriptions/${inscription.id}/attachments`, { cancelToken: this.source.token })
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Annule une requête en attente
     */
    cancelRequest = () => {
      this.source.cancel()
      this.source = axios.CancelToken.source()
    }

    /**
     * Initialise un concours
     * @param contest
     * @returns
     */
    initContest = (contest?: Contest): Contest =>
      contest ?? {
        type: '',
        title: '',
        place: '',
        startDate: DateUtils.formatToBeginOfDay(DateUtils.now()),
        endDate: DateUtils.formatToBeginOfDay(DateUtils.now()),
        linkedTransactions: []
      }

  /**
   * Retourne un tableau paginé de titre
   * @param titles
   * @returns
   */
  getPaginatedTitles = (titles: Title[], nbItemsPerPage: number): Title[][] => {
    const paginatedTitles: Title[][] = []

    let page: Title[] = []
    titles.forEach((title: Title, key: number) => {
      page.push(title)
      if ((page.length % nbItemsPerPage === 0) || (key + 1) === titles.length) {
        paginatedTitles.push(page)
        page = []
      }
    })
    return paginatedTitles
  }
}

export default new InscriptionService()
