import { Contributor } from '@inpi-marques/components'
import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import { toast } from 'react-toastify'

export interface IAccountParameter {
    login: string,
    password: string
}
export interface PaymentParameter {
    method: string,
    account?: IAccountParameter,
    label?: string,
    payer?: Contributor|null
    objectName?: string,
    objectIds?: string[],
}

class PaymentService {
    intl: IntlShape

    constructor () {
      this.intl = createIntl({ locale: 'fr', messages: Message })
    }

    /**
     * Création d'un paiment
     * @param id
     * @param payment
     * @returns
     */
    createPayment = async (id: string, payment: PaymentParameter): Promise<string> => {
      try {
        return await http.post(`/api/transactions/${id}/payments`, payment)
      } catch (error) {
        if (error.errorCode !== 'payment_already_exist') {
          toast.error(this.intl.formatMessage({ id: 'error_payment_create' }))
        }
        return Promise.reject(error)
      }
    }

  /**
   * Affiche le formulaire paybox de paiement en pleine page
   * @param result
   */
  displayPayBox = (result: string) => {
    const windowProxy: WindowProxy | null = window.open('', '_self', '')
    if (windowProxy) {
      const document = windowProxy.document
      document.open()
      document.write(result)
      document.close()
    }
  }

  /**
   * Annulation d'un paiement
   */
   cancelPayment = async (id: string, idPayment: string): Promise<any> => {
     try {
       return await http.delete(`/api/transactions/${id}/payments/${idPayment}`)
     } catch (error) {
       toast.error(error.message)
       return Promise.reject(error)
     }
   }

   /**
   * Vérifie le statut du paiement
   */
   checkPayment = async (id: string, idPayment: string): Promise<any> => {
     try {
       return await http.get(`/api/transactions/${id}/payments/${idPayment}`)
     } catch (error) {
       toast.error(error.message)
       return Promise.reject(error)
     }
   }
}

export default new PaymentService()
