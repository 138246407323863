import React, { FunctionComponent, ReactNode } from 'react'

interface ErrorFieldProps {
    message: ReactNode,
    className? : string
}

const ErrorField: FunctionComponent<ErrorFieldProps> = ({
  message,
  className = ''
}) => (message
  ? (
    <span className={`validation-error ${className}`}>
      {message}
    </span>
  ) : (<></>)
)

export default ErrorField
