import { FRMI, FRMI_HOLDER_CHANGE_EXTENSION_TYPES, SelectOption } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface HolderChangeExtentionOverviewProps {
    frmi: FRMI
}

/**
 * Récapitulatif d'une extension de produits
 */
const HolderChangeExtentionOverview: FC<HolderChangeExtentionOverviewProps> = ({ frmi }) =>
  <>
    <h4 className='font-weight-bold'>
      <FormattedMessage id='frmi_extension_overview_title' />
    </h4>
    {
                frmi?.productsExtensionType && frmi?.countriesExtensionType &&
                  <span>
                    {FRMI_HOLDER_CHANGE_EXTENSION_TYPES.find((type: SelectOption) => type.value === frmi.productsExtensionType)?.label}
                  </span>
    }
  </>

export default HolderChangeExtentionOverview
