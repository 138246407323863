import Message from './Message'

export const PUBLICATIONS_TABLE_TITLE_MODAL_ADD_RECORD = [
  {
    label: Message.transaction_publications_type_label
  },
  {
    label: Message.transaction_publications_numBopi_label
  },
  {
    label: Message.transaction_publications_date_label
  }
]

export const PUBLICATION_REGISTRATION = 'REGISTRATION'

export const PUBLICATION_REGISTRATIONAM = 'REGISTRATIONAM'

export const PUBLICATION_REGISTRATION_TMI_TME = 'REGISTRATION_TMI_TME'

export const PUBLICATION_REGISTRATION_DIV_REG = 'REGISTRATION_DIVISION_REG'

export const PUBLICATION_REGISTRATION_DIV = 'REGISTRATION_DIVISION'

export const PUBLICATION_CODE_RENUNCIATION = 'RNN'
export const PUBLICATION_CODE_RADIATION = 'RAN'
export const PUBLICATION_CODE_LIMITATION = 'LIN'
