import { DateUtils, ErrorField, FieldStatus, Foundation, FoundationDocument, FoundationType, ModalComponent, Opposition, Transaction, TransactionDocument } from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import DocumentService from 'services/document/DocumentService'
import FoundationTypeService from 'services/foundation-types/FoundationTypeService'
import TransactionService from 'services/transaction/TransactionService'
import { storeTransactionFieldStatusReset } from 'store/fieldStatus/fieldStatusActions'
import FoundationForm from './FoundationForm'
import FoundationList from './FoundationList'

interface FoundationProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    hideTitle?: boolean,
    setTransactionDocuments: (documents: TransactionDocument[]) => void,
  setIsRequired: (value: boolean) => void
}
const Foundations: FC<FoundationProps> = ({ transaction, fieldStatus, children, hideTitle = false, setTransactionDocuments, setIsRequired }) => {
  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedFoundation, setSelectedFoundation] = useState<Foundation>()
  const [showModal, setShowModal] = useState<boolean>(false)

  const [foundations, setFoundations] = useState<Foundation[]>(transaction.opposition?.foundations ?? [])
  const [foundationTypes, setFoundationTypes] = useState<FoundationType[]>([])

  useEffect(() => {
    if (isEditing) {
      setIsRequired(true)
    } else {
      setIsRequired(false)
    }
  }, [isEditing])

  useEffect(() => {
    /**
     * Seuls les internalName des documents de fondements sont sauvegardés en BDD.
     * Afin de bien gérer les documents dans le state, on créer des FoundationDocuments via les internalNames, et les documents de la transaction.
     *
     * Cette gestion de documents et faite pour que l'enregistrement de ceux-ci en base se fasse uniquement à la validation du formulaire d'un fondement,
     * et non à l'ajout / suppression du fichier dans le formulaire, afin d'avoir à éviter d'appeler les WS pour rien.
     */

    if (transaction.opposition?.foundations) {
      const updatedFoundations: Foundation[] = transaction.opposition.foundations.map((initialFoundation: Foundation) => {
        if (initialFoundation.internalNameDocuments?.length) {
          const internalNameDocuments: string[] = initialFoundation.internalNameDocuments
          const foundationDocuments: FoundationDocument[] = []

          for (const internalNameDocument of internalNameDocuments) {
            const transactionDocument: TransactionDocument|undefined = transaction.documents?.find((document) => document.internalName === internalNameDocument)

            transactionDocument && foundationDocuments.push(
              {
                name: transactionDocument.internalName ?? transactionDocument.name,
                type: transactionDocument.type,
                createdAt: transactionDocument.createdAt ?? DateUtils.now()
              })
          }
          return { ...initialFoundation, documents: foundationDocuments }
        }

        return initialFoundation
      })
      setFoundations(updatedFoundations)
    } else {
      setFoundations([])
    }
  }, [transaction])

  useEffect(() => {
    FoundationTypeService.getAll(transaction.procedureType).then((response: FoundationType[]) => {
      setFoundationTypes(response)
    })
  }, [])

  /**
   * Au clique du bouton d'ajout
   */
  const onAddButtonClick = (): void => {
    setSelectedFoundation({})
    setIsEditing(true)
  }

  /**
   * Au clique sur le bouton d'édition de la liste
   * @param foundation
   */
  const onEditButtonClick = (foundation: Foundation): void => {
    setSelectedFoundation(foundation)
    setIsEditing(true)
  }

  /**
   * Au clique sur le bouton de suppression de la liste
   * @param foundation
   */
  const onDeleteClick = (foundation: Foundation): void => {
    setSelectedFoundation(foundation)
    setShowModal(true)
  }

  /**
   * Fermeture de la modale de suppression
   */
  const onModalClose = (): void => {
    setSelectedFoundation(undefined)
    setShowModal(false)
  }

  /**
   * A la confirmation de la suppression d'un fondement (modale)
   */
  const onModalSubmit = async (): Promise<void> => {
    const updatedFoundations: Foundation[] = selectedFoundation
      ? foundations.filter((foundation: Foundation) => foundation.id !== selectedFoundation.id)
      : [...foundations]
    const updatedOpposition: Opposition = transaction.opposition ? { ...transaction.opposition, foundations: updatedFoundations } : { foundations: updatedFoundations }

    // On supprime les fichiers uploadés
    if (selectedFoundation?.documents && transaction.documents) {
      for (const document of transaction.documents) {
        if (selectedFoundation?.documents.find((foundationDocument: FoundationDocument) => foundationDocument.name === document.internalName)) {
          await DocumentService.deleteDocument(document.internalName, transaction)
        }
      }
    }

    const updatedTransaction = await TransactionService.updateTransactionBDDFromStore({ ...transaction, opposition: updatedOpposition }, ['opposition'])
    setShowModal(false)
    setTransactionDocuments(updatedTransaction?.documents ?? [])
  }

  /**
   * A la validation de l'edition d'un fondement
   * @param editedFoundation
   * @param updatedDocuments
   */
  const onFoundationEdited = async (editedFoundation: Foundation, updatedDocuments: TransactionDocument[]): Promise<void> => {
    // On enregistre les internalNames
    editedFoundation.internalNameDocuments = editedFoundation.documents?.map((document: FoundationDocument) => document.name) ?? []

    const existingFoundation: Foundation|undefined = foundations.find((foundation: Foundation) => foundation.id === editedFoundation.id)
    let updatedFoundations: Foundation[]
    if (existingFoundation) {
      updatedFoundations = foundations.map((foundation: Foundation) => foundation.id === editedFoundation.id ? editedFoundation : foundation)
    } else {
      updatedFoundations = [...foundations, editedFoundation]
    }

    if (transaction.documents) {
      // S'il y a des documents en moins, on les supprime
      for (const document of transaction.documents) {
        if (!updatedDocuments.some((updatedDocument: TransactionDocument) => updatedDocument.internalName === document.internalName)) {
          await DocumentService.deleteDocument(document.internalName, transaction)
        }
      }
    }

    setFoundations(updatedFoundations)
    const updatedOpposition: Opposition = transaction.opposition ? { ...transaction.opposition, foundations: updatedFoundations } : { foundations: updatedFoundations }
    const updatedTransaction = await TransactionService.updateTransactionBDDFromStore({ ...transaction, opposition: updatedOpposition }, ['opposition'])
    setIsEditing(false)
    dispatch(storeTransactionFieldStatusReset())
    setSelectedFoundation(undefined)
    setTransactionDocuments(updatedTransaction?.documents ?? [])
  }

  /**
   * Au clique sur le bouton annuler du formulaire
   */
  const onFormCancel = (): void => {
    setIsEditing(false)
    setSelectedFoundation(undefined)
  }

  return (
    <>
      <div className='is-validated'>
        {!hideTitle &&
          <div className='row mb-4 justify-content-between'>
            <header className='col-8'>
              <h1><FormattedMessage id='foundations_form_title' /></h1>
              <span className='subtitle'><FormattedMessage id='foundations_form_subtitle' /></span>
            </header>
            <InternalReferenceField
              transaction={transaction}
              className='col-3'
            />
          </div>}
        <div>
          {!isEditing &&
            <>
              <FoundationList hideTitle={hideTitle} foundations={foundations} onAddClick={onAddButtonClick} onEditClick={onEditButtonClick} onDeleteClick={onDeleteClick} procedureType={transaction.procedureType} />
              <ErrorField className='mt-3' message={fieldStatus && fieldStatus.foundations} />
            </>}
          {isEditing && selectedFoundation && <FoundationForm foundationTypes={foundationTypes} onFoundationEdited={onFoundationEdited} foundation={selectedFoundation} onFormCancel={onFormCancel} transaction={transaction} />}
        </div>
      </div>
      {!isEditing &&
        <>
          {children}
        </>}
      <ModalComponent
        title={<FormattedMessage id={`${transaction.procedureType?.toLocaleLowerCase()}_foundation_delete_modal_title`} />}
        show={showModal}
        customContent={() => <FormattedMessage id={`${transaction.procedureType?.toLocaleLowerCase()}_foundation_delete_modal_text`} />}
        handleClose={onModalClose}
        onClick={onModalSubmit}
      />
    </>)
}

export default Foundations
