import {
  DOCUMENT_TYPES,
  ErrorField,
  EventType,
  ModalComponent,
  Transaction,
  TransactionDocument,
  Deposit,
  Brand,
  FieldStatus,
  HelpBlock
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import { BrandType as IBrandType, BRAND_TYPES } from 'constants/BrandConstants'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import DocumentService from 'services/document/DocumentService'
import { storeTransactionUpdateDeposit } from 'store/transaction/transactionActions'
import BrandTypeExamples from './BrandTypeExamples'
import BrandTypeItem from './BrandTypeItem'
import TransactionService from '../../../../services/transaction/TransactionService'
import { storeTransactionFieldStatusReset } from 'store/fieldStatus/fieldStatusActions'
import SelfDiagnosis from './SelfDiagnosis'

interface BrandTypeProps {
    transaction: Transaction,
    deposit: Deposit,
    fieldStatus: FieldStatus,
    onDocumentChanged: (event: EventType, type: string) => void,
    onDocumentDeleted: (event: EventType) => void,
    documents: TransactionDocument[],
    setIsRequired: (value: boolean) => void
}

enum BrandTypeMode {
  List = 'isListing',
  Edit = 'isEditing',
  Diagnostic = 'isDiagnosis'
}

const BrandType: FC<BrandTypeProps> = ({
  transaction,
  fieldStatus,
  deposit,
  children,
  onDocumentChanged,
  onDocumentDeleted,
  documents,
  setIsRequired
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const depositBrand: Brand | undefined = deposit.brand
  const depositBrandType: IBrandType | undefined = depositBrand && BRAND_TYPES.find((type: IBrandType) => type.value === depositBrand.type)

  const [showModal, setShowModal] = useState<boolean>(false)
  const [isBrandingTypeMode, setIsBrandingTypeMode] = useState<string>(BrandTypeMode.List)
  const [currentExampleBrand, setCurrentExampleBrand] = useState<IBrandType | undefined>()
  const [currentBrandType, setCurrentBrandType] = useState<IBrandType | undefined>(depositBrandType)
  const [displayedBrandList, setDisplayedBrandList] = useState([])
  const [moreButtonClicked, setShowMoreButtonClicked] = useState<boolean>(false)
  const guidedMode = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode)

  useEffect(() => {
    if (isBrandingTypeMode === BrandTypeMode.Edit) {
      setIsRequired(true)
    } else {
      setIsRequired(false)
    }
  }, [isBrandingTypeMode])

  const onExampleClick = (brandType: IBrandType): void => {
    setShowModal(true)
    setCurrentExampleBrand(brandType)
  }

  const onModalClose = (): void => {
    setShowModal(false)
    setCurrentExampleBrand(undefined)
  }

  /**
     * Ajoute un filtre en fonction de la valeur du bouton mode guidé et du type de marque sélectionné
     */
  useEffect(() => {
    const filterBrandTypeIfGuidedMode = BRAND_TYPES.filter(brand => brand.displayIfGuidedMode === (guidedMode?.activeGuidedMode))
    if (guidedMode?.activeGuidedMode && (currentBrandType === undefined || filterBrandTypeIfGuidedMode.includes(currentBrandType))) {
      setDisplayedBrandList(filterBrandTypeIfGuidedMode)
    } else {
      setDisplayedBrandList(BRAND_TYPES)
    }
  }, [guidedMode, currentBrandType])

  /**
     * Enlève le filtre du mode guidé lors du click sur le bouton voir plus d'option
     */
  const viewBtnMore = () => {
    setShowMoreButtonClicked(true)
  }

  /**
   * Selection du type de marque --> enregistrement dans le store et dans la bdd
   * @param brandType
   * @param typeMode
   */
  const onBrandTypeClick = async (brandType: IBrandType, typeMode: string): Promise<void> => {
    if (brandType.value !== currentBrandType?.value) {
      dispatch(storeTransactionUpdateDeposit({ brand: { type: brandType.value } }))
      dispatch(storeTransactionFieldStatusReset())
      await TransactionService.updateTransactionBDDFromStore({
        ...transaction,
        deposit: { ...deposit, brand: { type: brandType.value } }
      }, ['deposit'])
      setCurrentBrandType(brandType)
      setBrandDocument(undefined)
      setBrandDocumentPreview(undefined)
    }
    setIsBrandingTypeMode(typeMode)
  }

  /** Gestion de l'upload du document géré dans chaque Content */
  const [brandDocument, setBrandDocument] = useState<TransactionDocument | undefined>(depositBrand?.file)
  const [brandDocumentPreview, setBrandDocumentPreview] = useState<string | undefined>(depositBrand?.file?.previewData)

  useEffect(() => {
    // Dans le cas d'une édition, si le fichier de la marque n'est pas en local, on récupère la prévisualisation depuis l'API
    if (!brandDocumentPreview && brandDocument) {
      // Si le fichier est image, on veut récupérer l'image traitée (preview), sinon on récupère le fichier
      const file = depositBrand.preview && depositBrand.preview.internalName ? depositBrand.preview : depositBrand.file
      DocumentService.getBrandPreviewDocument(transaction, file).then(setBrandDocumentPreview)
    }
  }, [])

  /**
     * Une fois le fichier déposé
     * On garde le fichier tout juste upload dans le state
     * car on ne peux sauvegarder un fichier dans le store
     * @param file
     */
  const onBrandDocumentDroped = (file: File): void => {
    if (file) {
      setBrandDocumentPreview(undefined)
      setBrandDocument({
        type: DOCUMENT_TYPES.BRAND,
        internalName: '',
        name: file.name,
        format: file.type,
        file,
        size: file.size
      })
    }
  }

  /**
     * Une fois le fichier inséré dans le Drop Area, on le crée en base et on récupère sa prévisualisation
     * @returns
     */
  const onBrandDocumentAdded = async (brand: Brand): Promise<string | null> => {
    if (brandDocument) {
      return await DocumentService.createAndGetDocumentPreview(brandDocument, transaction, brand)
    }
    return null
  }

  const onBrandDocumentDeleted = (brand: Brand): void => {
    setBrandDocument(undefined)
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, file: undefined } }))
  }

  /**
     * On met à jour les boutons du formulaire du StepContainer en fonction du mode d'édition ou non
     *    - Si je clique sur 'Etape précédente' quand je suis dans un formulaire d'une type de marque, alors je reviens sur la liste
     *    - Si je clique sur 'Etape suivante' quand on je suis sur la liste et qu'un type est selectionné, je passe au formulaire de ce type
     *
     * Pour le moment, children n'est pas volumineux (cf StepContainer.tsx L 225), mais s'il venait à grandir, il faudra faire attention à ce
     * que cela n'engendre pas des problèmes de perf.
     *
     * @param children
     * @returns
     */
  const getEditedChilden = (children: ReactElement): ReactElement[] => {
    return React.Children.map(children, (child: ReactElement) => {
      // On check si l'élément est valide ou non
      if (React.isValidElement(child)) {
        // On réassigne child afin de pas avoir à le caster à chaque utilisation
        const validatedChild: ReactElement = child
        const updatedChildProps: { children: ReactElement[], onClick?: () => void } = { children: getEditedChilden(validatedChild.props.children) }
        if ((isBrandingTypeMode === BrandTypeMode.Edit || isBrandingTypeMode === BrandTypeMode.Diagnostic) && validatedChild.props.id === 'stepform-prev-button') {
          updatedChildProps.onClick = () => { setIsBrandingTypeMode(BrandTypeMode.List) }
        } else if ((isBrandingTypeMode === BrandTypeMode.List || isBrandingTypeMode === BrandTypeMode.Diagnostic) && currentBrandType &&
            validatedChild.props.id === 'stepform-next-button') {
          updatedChildProps.onClick = () => { setIsBrandingTypeMode(BrandTypeMode.Edit) }
        }
        // On retourne le clone de l'enfant mis à jour (ou non)
        return React.cloneElement(child, updatedChildProps)
      }
      return child
    })
  }

  return (
    <>
      <div className='brand-type'>
        <div className='row mb-4 justify-content-between'>
          <header className='col-8'>
            <h1>
              {isBrandingTypeMode === BrandTypeMode.Edit ? <FormattedMessage id={currentBrandType?.title} />
                : <FormattedMessage id={`brand_type_title_${isBrandingTypeMode}`} />}
            </h1>
            <span className='subtitle'>
              <FormattedMessage
                id={isBrandingTypeMode === BrandTypeMode.Edit ? currentBrandType?.description : `brand_type_description_${isBrandingTypeMode}`}
                values={{
                  a: (...chunks: ((string) []) | []) => (
                    <a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>
                      {chunks}
                    </a>
                  ),
                  linebreak: <br />
                }}
              />
            </span>
          </header>
          <InternalReferenceField
            transaction={transaction}
            className='col-3'
          />
        </div>
        {/* Si nous sommes dans le mode liste, on affiche la liste des types de marque */}
        {isBrandingTypeMode === BrandTypeMode.List &&
          <>
            <div className='row'>
              {displayedBrandList.map((brandType: IBrandType, index) =>
                <BrandTypeItem
                  key={index}
                  brandType={brandType}
                  className='col-lg-6 col-md-12 mb-5'
                  onExampleClick={onExampleClick}
                  onSelect={onBrandTypeClick}
                  active={brandType.value === currentBrandType?.value}
                />
              )}
            </div>
            {
              displayedBrandList.length !== BRAND_TYPES.length &&
                <div className='d-flex  flex-column align-items-end w-100'>
                  <button className='btn btn-link text-primary mb-3 btn-more' onClick={viewBtnMore}>
                    <span className='text-button ml-1'>
                      <FormattedMessage id='button_more_text' />
                    </span>
                  </button>

                  {moreButtonClicked && (
                    <HelpBlock className='mt-3 align-self-start w-100'><FormattedMessage id='disable_guided_mode_to_see_more_brand_types' /></HelpBlock>
                  )}
                </div>
            }

            {currentExampleBrand && currentExampleBrand.examples &&
              <ModalComponent
                title={`${intl.formatMessage({ id: 'brand_type_examples_text' })} : ${intl.formatMessage({ id: currentExampleBrand.title })}`}
                handleClose={onModalClose}
                show={showModal}
                hideFooter
                customContent={() => <BrandTypeExamples examples={currentExampleBrand.examples ?? []} />}
                size='xl'
              />}
          </>}
        {/* Si un type de marque est selectionné, et que nous sommes dans le mode d'édition,
          alors on se retrouve dans le formulaire du contenu du type */}
        {isBrandingTypeMode === BrandTypeMode.Edit && depositBrand && currentBrandType &&
          <>
            <div className='d-flex flex-column'>
              <div className='row mt-5'>
                <currentBrandType.component
                  brand={depositBrand} brandType={currentBrandType} fieldStatus={fieldStatus}
                  transaction={transaction} onBrandDocumentDroped={onBrandDocumentDroped}
                  onBrandDocumentAdded={onBrandDocumentAdded}
                  onBrandDocumentDeleted={onBrandDocumentDeleted} document={brandDocument}
                  documentPreview={brandDocumentPreview}
                  onDocumentChanged={onDocumentChanged} onDocumentDeleted={onDocumentDeleted}
                  documents={documents}
                />
              </div>
            </div>
          </>}
        {/* Si nous sommes dans le mode auto diagnostique, alors on affiche la liste des questions */}
        {isBrandingTypeMode === BrandTypeMode.Diagnostic &&
          <SelfDiagnosis
            currentBrandType={currentBrandType}
          />}

        {fieldStatus.brandType && (
          <div className='col-12'>
            <ErrorField
              message={fieldStatus.brandType}
              className='fade alert alert-danger show position-relative mt-4'
            />
          </div>
        )}
      </div>
      {getEditedChilden(children as ReactElement)}
    </>
  )
}

export default BrandType
