import React, { FC } from 'react'
import {
  FRMI
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'

interface PostDesignationVariousIndicationsOverviewProps {
    frmi: FRMI
}

const PostDesignationVariousIndicationsOverview: FC<PostDesignationVariousIndicationsOverviewProps> = ({ frmi }) => {
  return (
    <>
      {/* Indication pour chaque couleur */}
      {
        frmi?.brand?.colorsDescription &&
          <div className='d-flex flex-column mb-3'>
            <span className='font-weight-bold'><FormattedMessage id='overview_frmi_color_indication' /></span>
            <span>{frmi?.brand?.colorsDescription}</span>
          </div>
      }

      {/* Traduction anglaise */}
      {
        frmi?.brand?.englishTranslation &&
          <div className='d-flex flex-column mb-3'>
            <span className='font-weight-bold'><FormattedMessage id='overview_frmi_english_translation' /></span>
            <span>{frmi?.brand?.englishTranslation}</span>
          </div>
      }

      {/* Traduction espagnole */}
      {
        frmi?.brand?.spanishTranslation &&
          <div className='d-flex flex-column mb-3'>
            <span className='font-weight-bold'><FormattedMessage id='overview_frmi_spanish_translation' /></span>
            <span>{frmi?.brand?.spanishTranslation}</span>
          </div>
      }

      {/* Traduction française */}
      {
        frmi?.brand?.translation &&
          <div className='d-flex flex-column mb-3'>
            <span className='font-weight-bold'><FormattedMessage id='overview_frmi_french_translation' /></span>
            <span>{frmi?.brand?.translation}</span>
          </div>
      }

      {/* Description volontaire de la marque */}
      {
        frmi?.brand?.voluntaryDescription &&
          <div className='d-flex flex-column'>
            <span className='font-weight-bold'><FormattedMessage id='overview_frmi_volunteer_brand_description' /></span>
            <span>{frmi?.brand?.voluntaryDescription}</span>
          </div>
      }
    </>
  )
}

export default PostDesignationVariousIndicationsOverview
