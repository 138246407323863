import React, { FC, ReactNode } from 'react'

interface NotificationItemProps {
    label: string|ReactNode,
    children: ReactNode,
    className?: string,
}

const NotificationItem: FC<NotificationItemProps> = (
  {
    label,
    children,
    className
  }) => {
  return (
    <div className={`${className || ''}`}>
      <div className='text-gris'>
        {label}
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

export default NotificationItem
