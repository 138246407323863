import {
  EventType,
  FieldStatus,
  FRMI_LICENCE_TYPES,
  FRMILicenceType,
  Transaction,
  SelectField,
  TextField
} from '@inpi-marques/components'
import { TIME_DURATION, UNDEFINDED } from '@inpi-marques/components/src/constants/CommonConstants'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'

interface LicenceInscriptionVariousIndicationsProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

const LicenceInscriptionVariousIndications: FC<LicenceInscriptionVariousIndicationsProps> = ({ transaction, fieldStatus }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [licenceDuration, setLicenceDuration] = useState<string>(transaction.frmiDetails?.licenceDuration ?? '')
  const [monthYear, setMonthYear] = useState<string>(transaction.frmiDetails?.licenceDurationType ?? '')

  /**
   * Au changement de type de licence
   *
   * @param licenceType
   */
  const onLicenceTypeChanged = (licenceType: string): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      licenceType
    }))
  }

  const onMonthYearChange = (value: string): void => {
    setMonthYear(value)
    value === UNDEFINDED.value && setLicenceDuration('')
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      licenceDurationType: value,
      licenceDuration: value === UNDEFINDED.value ? '' : licenceDuration
    }))
  }

  const onLicenceDurationChange = (value: number): void => {
    const testedValue = value < 1 ? 1 : value > 100 ? 100 : value
    setLicenceDuration(testedValue.toString())

    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      licenceDuration: testedValue.toString()
    }))
  }

  return (
    <div className='colorsClaimed'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='select_frmi_various_indications_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_various_indications_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row col-md-12'>
        <div className='col-12'>
          {FRMI_LICENCE_TYPES.map((licenceType: FRMILicenceType, index: number) => (
            <>
              <div key={index} className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  id={`licence_type_${index}`}
                  checked={transaction.frmiDetails?.licenceType === licenceType.value}
                  onChange={() => onLicenceTypeChanged(licenceType.value)}
                />
                <label className='form-check-label' htmlFor={`licence_type_${index}`}>{licenceType.label}</label>
              </div>
              <div className='text-gris'>{licenceType.description}</div>
            </>
          ))}
        </div>
        <div className='mt-3'>
          <TextField
            className='licence-field'
            inputId='duration'
            label={<FormattedMessage id='frmi_licence_duration_label' />}
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            value={licenceDuration}
            fieldStatus={fieldStatus}
            onChange={(event: EventType) => onLicenceDurationChange(event.target.value)}
            required={!!monthYear}
            type='number'
            min={1}
            max={100}
            disabled={monthYear === UNDEFINDED.value}
          />
          <SelectField
            inputId='year_month'
            classNameFormGroup='mt-2'
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            value={monthYear}
            options={TIME_DURATION}
            onChange={(event: EventType) => onMonthYearChange(event.target.value)}
            fieldStatus={fieldStatus}
            required={!!licenceDuration}
          />
        </div>
      </div>
    </div>
  )
}

export default LicenceInscriptionVariousIndications
