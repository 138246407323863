import {
  containsErrors,
  FieldStatus,
  isFilled,
  ProductAndService,
  ProductClass,
  ProductClassVersion
} from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class ProductsAndServiceValidator {
  validateOnChangeStep = (version?: ProductClassVersion) => {
    const depositFieldStatus: FieldStatus = {}
    if (!version || !isFilled(version.productClasses) ||
      version.productClasses.filter((productClass: ProductClass) => productClass.products.every((product: ProductAndService) => !product.name?.trim().length))?.length === version.productClasses.length
    ) {
      depositFieldStatus.productsAndServices = Message.product_and_services_missing
    }
    store.dispatch(storeTransactionFieldStatusUpdate(depositFieldStatus))
    return !containsErrors(depositFieldStatus)
  }
}

export default new ProductsAndServiceValidator()
