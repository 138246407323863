export const STATUS_MIFR = {
  /** Brouillon */
  DRAFT: 'draft_mifr',
  /** A attribuer */
  TO_ATTRIBUTE: 'to_attribute_mifr',
  /** Examen fond */
  TO_REVIEW: 'to_review_mifr',
  /** Arrêté examen fond */
  EXAM_STAND_BY: 'exam_stand_by_mifr',
  /** Rejeté */
  REJECTED: 'rejected_mifr',
  /** Régulier */
  REGULAR: 'regular_mifr',
  /** Arrêt de procédure */
  TOTAL_WITHDRAWAL: 'total_withdrawal_mifr',
  /** Enregistré */
  REGISTERED: 'registered_mifr'
}

export const STATUS_MIFR_REVIEWING = [
  STATUS_MIFR.TO_ATTRIBUTE,
  STATUS_MIFR.TO_REVIEW,
  STATUS_MIFR.EXAM_STAND_BY,
  STATUS_MIFR.REGULAR
]

export const STATUS_MIFR_FINAL: string[] = [
  STATUS_MIFR.REGISTERED,
  STATUS_MIFR.REJECTED,
  STATUS_MIFR.TOTAL_WITHDRAWAL
]
