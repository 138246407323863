import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { downloadFile, SubmitButton } from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import Message from '../../constants/Message'

interface DownloadLinkProps {
  name?: string,
  classname?: string,
  buttonClassname?: string,
  label?: JSX.Element | string
  onClick?: () => Promise<ArrayBuffer|string|Blob|null>
  onFinished?: (response?: ArrayBuffer|string|Blob|null) => void,
  onError?: () => void,
  icon? : boolean,
  displayLabel?: boolean
  replaceOnLoad?: boolean,
  disabled?: boolean
}

const DownloadLink : FC<DownloadLinkProps> = ({
  classname,
  buttonClassname,
  label,
  onClick,
  name,
  onFinished,
  onError,
  icon = true,
  replaceOnLoad,
  displayLabel = true,
  disabled = false

}) => {
  const handleClick = async (): Promise<void> => {
    if (onClick) {
      try {
        const response = await onClick()
        downloadFile(response, name)
        onFinished && onFinished(response)
      } catch (error) {
        onError && onError()
      }
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className={`${classname || 'justify-content-end'} d-flex`}>
        <SubmitButton disabled={disabled} className={buttonClassname ?? 'btn btn-link text-primary'} onClick={handleClick} replaceOnLoad={replaceOnLoad}>
          {icon ? <FontAwesomeIcon icon={faDownload} size='xs' className='mr-2 icon-download ' /> : ''}
          {displayLabel &&
            <>
              {label || <FormattedMessage id='download_label' />}
            </>}
        </SubmitButton>
      </div>
    </IntlProvider>
  )
}

export default DownloadLink
