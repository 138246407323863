import React, { FC, useEffect, useState } from 'react'
import {
  CardBlock, containsErrors,
  ContributorForm,
  FieldStatus,
  FilAriane,
  isMailFormatValid,
  SubmitButton
} from '@inpi-marques/components'
import { Link } from 'react-router-dom'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { RootStateOrAny, useSelector } from 'react-redux'
import ContributorService from '../../services/contributors/ContributorService'
import ContentService from '../../services/content/ContentService'
import MyProfileEmailsAndConsent from './MyProfileEmailsAndConsent'
import FoUserService from '../../services/user/FoUserService'
import { toast } from 'react-toastify'
import store from '../../store/store'
import { storeUpdateUser } from '../../store/user/userActions'

const MyProfile : FC<WrappedComponentProps> = ({ intl }) => {
  const userInfoStore = useSelector((state : RootStateOrAny) => state.user)
  const currentUser = userInfoStore.user

  const [correspondenceEmailList, setCorrespondenceEmailList] = useState<string[]>(currentUser.correspondenceEmails)
  const [consentNotificationByEmailPreferences, setConsentNotificationByEmailPreferences] = useState<boolean>(currentUser.consentNotificationByEmailPreferences)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus[]>()
  const [canSendConfirmEmails, setCanSendConfirmEmails] = useState<boolean>(currentUser.correspondenceEmails.length > 0)

  useEffect(() => {
    updateSendConfirmEmail()
  }, [correspondenceEmailList, currentUser.correspondenceEmails])

  /**
   * Vérifie et met à jour les fieldStatus
   */
  const verifyEmail = () => {
    return correspondenceEmailList.map((email) => ({ email: isMailFormatValid(email) ? '' : intl.formatMessage({ id: 'error_email_format' }) }))
  }

  /**
   * Mise à jour des adresses de correspondance
   */
  const updateUser = () => {
    const newFieldStatus = verifyEmail()
    setFieldStatus(newFieldStatus)
    if (!containsErrors(newFieldStatus)) {
      return FoUserService.putFoUserCorrespondanceEmail(currentUser.id, correspondenceEmailList, consentNotificationByEmailPreferences).then((foUser) => {
        store.dispatch(storeUpdateUser({ ...userInfoStore, user: foUser }))
        toast.success(intl.formatMessage({ id: 'my_profile_success' }))
      })
    }
  }

  /**
   * Pour savoir si on peut envoyer un mail de confirmation
   * les deux listes doivent être égales, cela indique le bon enregistrement côté serveur
   * @param value
   */
  const updateSendConfirmEmail = (value: boolean | undefined = undefined) => {
    const listsEqual = JSON.stringify(currentUser.correspondenceEmails) === JSON.stringify(correspondenceEmailList) && currentUser.correspondenceEmails.length > 0
    setCanSendConfirmEmails(value !== false ? listsEqual : value)
  }

  /**
  * Envoi confirmation aux adresses de correspondance
  */
  const sendConfirmEmail = () => {
    return FoUserService.sendConfirmEmail(currentUser.id)
      .then(() => {
        toast.success(intl.formatMessage({ id: 'my_profile_confirm_emails_sent' }))
      })
      .catch(() => toast.error(intl.formatMessage({ id: 'my_profile_confirm_emails_error' })))
      .finally(() => updateSendConfirmEmail())
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='my_profile' /></span>
      </FilAriane>

      <CardBlock className='border mb-4 p-5'>
        <ContributorForm
          contributor={ContributorService.buildContributorFromUser(currentUser)}
          optionsForm={{
            hasAutoCopy: false,
            couldBePPOrPM: false,
            hasPhysiqueField: true,
            hasPublicLegalCompanyField: false,
            hasContactField: true,
            hasCompanyField: false,
            hasFormationField: false,
            hasAddressField: true,
            hasManageableQualities: false,
            showSiren: true,
            allFieldReadOnly: true
          }}
          text={{
            title: (
              <h1 className='col-12 px-0'><FormattedMessage id='my_profile' /></h1>
            ),
            subtitle: (
              <div className='small col-12 px-0'>
                <FormattedMessage id='my_profile_subtitle' />
              </div>
            )
          }}
          getCountries={ContentService.getCountries}
        />

        <MyProfileEmailsAndConsent
          correspondenceEmailList={correspondenceEmailList}
          setCorrespondenceEmailList={setCorrespondenceEmailList}
          consentNotificationByEmailPreferences={consentNotificationByEmailPreferences}
          setConsentNotificationByEmailPreferences={setConsentNotificationByEmailPreferences}
          sendConfirmEmail={sendConfirmEmail}
          canSendConfirmEmails={canSendConfirmEmails}
          fieldStatus={fieldStatus}
        />

        <div className='step-buttons mt-4 row justify-content-center'>
          <SubmitButton
            className='bg-white text-gris py-3 px-4'
            onClick={() => setCorrespondenceEmailList(currentUser.correspondenceEmails)}
          >
            <FormattedMessage id='button_cancel' />
          </SubmitButton>
          <SubmitButton
            className='bg-primary py-3 px-4'
            onClick={updateUser}
          >
            <FormattedMessage id='button_save' />
          </SubmitButton>
        </div>
      </CardBlock>
    </>
  )
}

export default injectIntl(MyProfile)
