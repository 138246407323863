import {
  Deposit,
  FRMI,
  Inscription,
  OfficialDocumentType,
  Opposition,
  Transaction,
  RevocationStatement,
  Renewal,
  Contributor
} from '@inpi-marques/components'
import {
  TransactionAction,
  TRANSACTION_INIT,
  TRANSACTION_REMOVE,
  TRANSACTION_INIT_DEPOSIT,
  TRANSACTION_UPDATE,
  TRANSACTION_UPDATE_DEPOSIT,
  BTN_GUIDED_MODE_UPDATE,
  BTN_CONTRIBUTOR_UPDATE,
  GUIDED_MODE_RESET,
  TRANSACTION_UPDATE_OPPOSITION,
  TRANSACTION_RESET_DEPOSIT,
  TRANSACTION_UPDATE_INSCRIPTION,
  TRANSACTION_UPDATE_FRMI,
  TRANSACTION_INIT_FRMI,
  TRANSACTION_UPDATE_OFFICIAL_DOCUMENT_TYPE,
  TRANSACTION_UPDATE_RENEWAL,
  TRANSACTION_UPDATE_REVOCATION_STATEMENT,
  TRANSACTION_INIT_RENEWAL
} from './transactionTypes'

export const storeTransactionUpdate = (transaction: Transaction, onlyApiTransaction: boolean = false): TransactionAction => ({
  type: TRANSACTION_UPDATE,
  transaction,
  onlyApiTransaction
})

export const storeTransactionRemove = (): TransactionAction => ({
  type: TRANSACTION_REMOVE
})

export const storeTransactionResetDeposit = (deposit: Deposit): TransactionAction => ({
  type: TRANSACTION_RESET_DEPOSIT,
  deposit
})

export const storeTransactionInit = (type: string, subProcedureType?: string): TransactionAction => ({
  type: TRANSACTION_INIT,
  transactionType: type,
  subProcedureType: subProcedureType
})

export const storeTransactionUpdateDeposit = (deposit: Deposit): TransactionAction => ({
  type: TRANSACTION_UPDATE_DEPOSIT,
  deposit: deposit
})

export const storeTransactionOfficialDocumentType = (officialDocumentType: OfficialDocumentType) => ({
  type: TRANSACTION_UPDATE_OFFICIAL_DOCUMENT_TYPE,
  officialDocumentType: officialDocumentType
})

export const storeTransactionInitDeposit = (type: string): TransactionAction => ({
  type: TRANSACTION_INIT_DEPOSIT,
  subProcedureType: type
})

export const storeTransactionInitFrmi = (type: string, frmiDetails?: FRMI, recipient?: Contributor) => ({
  type: TRANSACTION_INIT_FRMI,
  subProcedureType: type,
  frmi: frmiDetails,
  recipient
})

export const storeGuidedValue = (): TransactionAction => ({
  type: BTN_GUIDED_MODE_UPDATE
})

export const storeGuidedModeReset = (): TransactionAction => ({
  type: GUIDED_MODE_RESET
})

export const storeBtnIntervenantId = (choiceContributor: string, restrictedQualities : string[]) => ({
  type: BTN_CONTRIBUTOR_UPDATE,
  guidedMode: {
    choiceContributor,
    restrictedQualities
  }
})

export const storeTransactionUpdateInscription = (inscription: Inscription): TransactionAction => ({
  type: TRANSACTION_UPDATE_INSCRIPTION,
  inscription
})

export const storeTransactionUpdateOpposition = (opposition: Opposition): TransactionAction => ({
  type: TRANSACTION_UPDATE_OPPOSITION,
  opposition
})

export const storeTransactionUpdateFRMI = (frmi: FRMI): TransactionAction => ({
  type: TRANSACTION_UPDATE_FRMI,
  frmi
})

export const storeTransactionUpdateRevocationStatement = (revocationStatement: RevocationStatement): TransactionAction => ({
  type: TRANSACTION_UPDATE_REVOCATION_STATEMENT,
  revocationStatement
})

export const storeTransactionUpdateRenewal = (renewal: Renewal) => ({
  type: TRANSACTION_UPDATE_RENEWAL,
  renewal
})

export const storeTransactionInitRenewal = (renewal: Renewal): TransactionAction => ({
  type: TRANSACTION_INIT_RENEWAL,
  renewal
})
