import { SelectOption } from 'src/index'
import Message from './Message'

export const OPTION_VERBALE: SelectOption = {
  value: true,
  label: Message.foundation_option_verbale
}
export const OPTION_OTHER: SelectOption = {
  value: false,
  label: Message.foundation_option_other
}

export const TYPE_MARQUE_OPTIONS: SelectOption[] = [
  OPTION_VERBALE,
  OPTION_OTHER
]

export const OPTION_SIGN_VERBALE: SelectOption = {
  value: true,
  label: Message.foundation_option_sign_verbale
}

export const TYPE_SIGN_OPTIONS: SelectOption[] = [
  OPTION_SIGN_VERBALE,
  OPTION_OTHER
]
