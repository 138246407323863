import React, { FC, useState } from 'react'
import { EventType, TextArea, Transaction } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'

interface LicenceEditionProps {
    transaction: Transaction
}

/**
 * Etape Licence à modifier
 * @param param0
 * @returns
 */
const LicenceEdition: FC<LicenceEditionProps> = ({ transaction }) => {
  const dispatch = useDispatch()

  const [licenceEdition, setLicenceEdition] = useState<string>(transaction.frmiDetails?.licenceEdition ?? '')

  /**
     * Au blur sur le champ
     */
  const onBlur = (): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      licenceEdition
    }))
  }

  return (
    <div>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_licence_edition_title' /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div>
        <TextArea
          inputId='licenceEdition'
          label={<FormattedMessage id='frmi_licence_edition_label' />}
          value={licenceEdition}
          onChange={(event: EventType) => setLicenceEdition(event.target.value)}
          onBlur={onBlur}
        />
      </div>

    </div>
  )
}

export default LicenceEdition
