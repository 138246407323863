import { DepositType } from '../interfaces/Deposit'
import ConsentContent from '../component/deposit/form/depositType/content/ConsentContent'
import EuropeanContent from '../component/deposit/form/depositType/content/EuropeanContent'
import InternationalContent from '../component/deposit/form/depositType/content/InternationalContent'

export const DEPOSIT_TYPE_NATIONAL: DepositType = {
  value: 'DEPOSIT_TYPE_NATIONAL',
  label: 'deposit_type_national',
  valueGuidedMode: true
}

export const DEPOSIT_TYPE_GUARANTEE: DepositType = {
  value: 'DEPOSIT_TYPE_GUARANTEE',
  label: 'deposit_type_guarantee',
  valueGuidedMode: false,
  component: ConsentContent
}

export const DEPOSIT_TYPE_COLLECTIVE: DepositType = {
  value: 'DEPOSIT_TYPE_COLLECTIVE',
  label: 'deposit_type_collective',
  valueGuidedMode: false,
  component: ConsentContent
}

export const DEPOSIT_TYPE_EUROPEAN: DepositType = {
  value: 'DEPOSIT_TYPE_EUROPEAN',
  label: 'deposit_type_european',
  valueGuidedMode: false,
  component: EuropeanContent
}

export const DEPOSIT_TYPE_INTERNATIONAL: DepositType = {
  value: 'DEPOSIT_TYPE_INTERNATIONAL',
  label: 'deposit_type_international',
  valueGuidedMode: false,
  component: InternationalContent
}

export const DEPOSIT_TYPES: DepositType[] = [
  DEPOSIT_TYPE_NATIONAL,
  DEPOSIT_TYPE_EUROPEAN,
  DEPOSIT_TYPE_INTERNATIONAL,
  DEPOSIT_TYPE_GUARANTEE,
  DEPOSIT_TYPE_COLLECTIVE
]

export const ERRATUM_MODAL = 'ERRATUM_MODAL'

export const CORRECTION_MODAL = 'CORRECTION_MODAL'
