import React, { FC } from 'react'
import { faExpandArrowsAlt, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface FullScreenButtonProps {
  setFullScreenDisplay: (fullScreenDisplay: boolean) => void,
  fullScreenDisplay: boolean
}

const FullScreenButton: FC<FullScreenButtonProps> = ({ setFullScreenDisplay, fullScreenDisplay }) => (
  <div onClick={() => { setFullScreenDisplay(!fullScreenDisplay) }} className='rdw-image-wrapper'>
    <div className='rdw-option-wrapper' title={fullScreenDisplay ? 'Compress' : 'Expand'}>
      <FontAwesomeIcon icon={fullScreenDisplay ? faCompressArrowsAlt : faExpandArrowsAlt} />
    </div>
  </div>
)

export default FullScreenButton
