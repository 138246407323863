import React, { useEffect, useState } from 'react'
import {
  containsErrors,
  EditorComponent,
  SelectField,
  SubmitButton,
  TextField
} from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { RootStateOrAny, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { MailObject } from '../../constants/MessageToInpiConstants'
import ContactValidator from './validator/MessageToInpiValidator'
import ContactMailService from '../../services/messageToInpi/MessageToInpiService'

interface MessageToInpiProps {
  setShowPopUp: (showContact: boolean) => void
}

const MessageToInpi = ({ setShowPopUp }: MessageToInpiProps) => {
  const user = useSelector((state: RootStateOrAny) => state.user.user)
  const intl = useIntl()

  const [message, setMessage] = useState()
  const [currentUser, setCurrentUser] = useState(user)
  const [fieldStatus, setFieldStatus] = useState()
  const [mailObjects, setMailObjects] = useState<MailObject[]>([])

  const updateField = (e: any) => {
    setCurrentUser({
      ...currentUser,
      [e.target.name]: e.target.value
    })
  }

  const updateMessage = (e: any) => {
    setMessage({
      ...message,
      [e.target.name]: e.target.value
    })
  }

  /**
   * Permet de gérer la validation du formulaire
   */
  const handleSubmit = async () => {
    const fieldStatusError = ContactValidator.validateForm(currentUser, message)
    if (containsErrors(fieldStatusError)) {
      setFieldStatus(fieldStatusError)
      return
    }
    await ContactMailService.sendMessage(currentUser, message)
    setShowPopUp(false)
  }

  useEffect(() => {
    ContactMailService.getMailObjects().then(result => {
      result.forEach(r => {
        r.value = r.label
      })
      setMailObjects(result)
    })
  }, [])

  return (
    <div>
      <div className='my-2 row justify-content-md-center is-validated'>
        <div className='col-6'>
          <TextField
            inputId='lastname'
            label={<FormattedMessage id='contact_lastname_label' />}
            required
            value={currentUser.lastname}
            onChange={updateField}
            fieldStatus={fieldStatus}
          />
        </div>
        <div className='col-6'>
          <TextField
            inputId='firstname'
            label={<FormattedMessage id='contact_firstname_label' />}
            required
            value={currentUser.firstname}
            onChange={updateField}
            fieldStatus={fieldStatus}
          />
        </div>
        <div className='col-6'>
          <TextField
            inputId='email'
            label={<FormattedMessage id='contact_email_label' />}
            required
            value={currentUser.email}
            onChange={updateField}
            fieldStatus={fieldStatus}
          />
        </div>
        <div className='col-6'>
          <TextField
            inputId='mobilePhone'
            label={<FormattedMessage id='contact_phone_number_label' />}
            required
            value={currentUser.mobilePhone || ''}
            onChange={updateField}
            fieldStatus={fieldStatus}
          />
        </div>
        <div className='col-12'>
          <SelectField
            inputId='object'
            placeholder={intl.formatMessage({ id: 'contact_choose_object' })}
            label={<FormattedMessage id='contact_object_label' />}
            value={(message && message.object) || ''}
            options={mailObjects}
            onChange={updateMessage}
            required
            fieldStatus={fieldStatus}
          />
        </div>
        <div className='col-12'>
          <EditorComponent
            inputId='content'
            required
            label={<FormattedMessage id='contact_message_label' />}
            text={(message && message.content) || ''}
            onBlur={(value) => setMessage({ ...message, content: value })}
            fieldStatus={fieldStatus}
            height='250px'
          />
        </div>
        <SubmitButton className='btn btn-outline-primary mt-4' onClick={handleSubmit}>
          <FormattedMessage id='contact_send_button' />
          <FontAwesomeIcon className='ml-2' icon={faEnvelope} />
        </SubmitButton>
      </div>
    </div>
  )
}

export default MessageToInpi
