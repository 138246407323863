import { FilAriane, Record, Dropdown, SubmitButton } from '@inpi-marques/components'
import BasketDetailsLayout from 'component/basket/BasketDetailsLayout'
import { BASKET_HEADER } from 'constants/BasketConstants'
import { BasketFilter, BasketInterface } from 'interfaces/BasketInterface'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import RecordService from 'services/opposition/RecordService'
import RecordOverview from './overview/RecordOverview'
interface RecordDetailsProps {
    basket: BasketInterface,
    id: string,
    getTransactionCountByHeader?: (filter?: BasketFilter) => any
  }

const RecordDetails: FC<RecordDetailsProps> = ({ basket, id, getTransactionCountByHeader }) => {
  const history = useHistory()
  const location = useLocation()
  const [record, setRecord] = useState<Record>()
  const [headerBasketSelected, setHeaderBasketSelected] = useState<BasketFilter>()

  useEffect(() => {
    RecordService.getRecord(id)
      .then(setRecord)
      .catch(() => {
        history.replace(`/liste/${basket.urlParam}${headerBasketSelected ? `?corbeille=${headerBasketSelected.id}` : ''}`)
      })

    return () => RecordService.cancelRequest()
  }, [id, basket])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const basketParam = queryParams.get('corbeille')
    setHeaderBasketSelected(BASKET_HEADER.find(header => header.id === basketParam) || BASKET_HEADER[0])
  }, [location.search])

  const getActions = () => {
    return [{
      component: (
        <SubmitButton
          className='btn btn-link text-primary text-right'
          onClick={() => history.push(`/divisions/nouveau?from=${id}`)}
        >
          <FormattedMessage id='record_create_division' />
        </SubmitButton>)
    },
    {
      component: (
        <SubmitButton
          className='btn btn-link text-primary text-right'
          onClick={() => history.push(`/inscriptions/nouveau?from=${id}`)}
        >
          <FormattedMessage id='record_create_inscription' />
        </SubmitButton>)
    }
    ]
  }

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        {
          headerBasketSelected && !headerBasketSelected.isHome &&
            <Link to={`/accueil?corbeille=${headerBasketSelected.id}`}>
              <FormattedMessage
                id={`home_filter_header_label_${headerBasketSelected.id}`}
              />
            </Link>
        }
        <Link
          to={`/liste/${basket.urlParam}${headerBasketSelected ? `?corbeille=${headerBasketSelected.id}` : ''}`}
        >
          <FormattedMessage
            id={`basket_label_${basket.id}`}
          />
        </Link>

        <span>
          <FormattedMessage id='record_title' />
          {record && (record.numNat ? record.numNat : record.id)}
        </span>
      </FilAriane>
      {
        headerBasketSelected &&
          <BasketDetailsLayout idBasket={basket.id} headerBasketSelected={headerBasketSelected} getTransactionCountByHeader={getTransactionCountByHeader}>
            <>
              {
                record &&
                  <div>
                    <header className='mx-3 mb-4 d-flex justify-content-between'>
                      <h1>
                        <FormattedMessage
                          id='record_title'
                        />
                        {record.numNat ? record.numNat : record.id}
                      </h1>

                      <div className='d-flex justify-content-end'>
                        {getActions().length === 1
                          ? getActions()[0].component
                          : (
                            <Dropdown
                              className='nav-white align-right'
                              items={getActions()}
                              name={<FormattedMessage id='other_actions' />}
                              buttonClassName='text-right text-primary'
                              arrow
                            />
                          )}
                      </div>
                    </header>
                    <RecordOverview
                      record={record}
                    />
                  </div>
              }
            </>
          </BasketDetailsLayout>
      }
    </div>)
}

export default RecordDetails
