import { FRMI, PRODUCTS_EXTENSION_TYPE_TOTAL, Transaction } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface ExtensionsTypeOverviewProps {
    transaction: Transaction
}

/**
 * Récapitulatif d'une extension de produits
 */
const ExtensionsTypeOverview: FC<ExtensionsTypeOverviewProps> = ({ transaction }) => {
  const frmi: FRMI|undefined = transaction.frmiDetails
  const frmiType: string|undefined = transaction.subProcedureType?.toLocaleLowerCase()

  return (
    <>
      {frmi?.productsExtensionType &&
        <div>
          <h4 className='font-weight-bold'>
            <FormattedMessage id={`${frmiType}_products_extension_type_label`} />
          </h4>
          <span>
            <FormattedMessage id={`${frmiType}_products_extension_type_${frmi.productsExtensionType.toLowerCase()}_label`} />
          </span>
        </div>}
      {frmi?.productsExtensionSubType && frmi.productsExtensionType !== PRODUCTS_EXTENSION_TYPE_TOTAL &&
        <div>
          <span>
            <FormattedMessage id={`${frmiType}_products_extension_sub_type_${frmi.productsExtensionSubType.toLowerCase()}_label`} />
          </span>
        </div>}
      {frmi?.countriesExtensionType &&
        <div>
          <h4 className='font-weight-bold'>
            <FormattedMessage id={`${frmiType}_countries_extension_type_label`} />
          </h4>
          <span>
            <FormattedMessage id={`${frmiType}_countries_extension_type_${frmi.countriesExtensionType.toLowerCase()}_label`} />
          </span>
        </div>}
    </>
  )
}

export default ExtensionsTypeOverview
