import React, { FC } from 'react'
import { DateUtils, Table, Transaction } from '@inpi-marques/components'
import { IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { TABLE_TRANSACTION_LIST } from '../constants/TransactionListTableConstants'

interface TransactionListTableProps {
  transactions: Transaction[],
  onSelectTransaction: (index : number) => void
}

const TransactionListTable: FC<TransactionListTableProps> = ({ transactions, onSelectTransaction }) =>
  <IntlProvider locale='fr' messages={Message}>
    <Table
      tableTitle={TABLE_TRANSACTION_LIST}
      colorHover
    >
      <tbody>
        {
          transactions?.map((transaction, index) => {
            return [(
              <tr key={index} className='cursor-pointer' onClick={() => onSelectTransaction(index)}>
                <td>{transaction.numNat}</td>
                <td>{DateUtils.formatDateFr(transaction.arrivalDate)}</td>
              </tr>
            )]
          })
        }
      </tbody>
    </Table>
  </IntlProvider>

export default TransactionListTable
