import { Brand, containsErrors, FieldStatus, FRMI } from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import Message from 'constants/Message'
import store from 'store/store'

class PostDesignationVariousIndicationsValidator {
  getValidatedFieldStatus = (brand?: Brand): FieldStatus => {
    const frmiFieldStatus: FieldStatus = {}

    if (brand?.hasTranslation !== undefined && brand?.hasTranslation !== true && !brand?.hasTranslation && !brand?.translation?.length && !brand?.englishTranslation?.length && !brand?.spanishTranslation?.length) {
      frmiFieldStatus.translationText = Message.various_indications_form_translation_text_error
    }

    return frmiFieldStatus
  }

  validateOnChangeStep = (frmi?: FRMI) => {
    const fieldStatus: FieldStatus = this.getValidatedFieldStatus(frmi?.brand)
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new PostDesignationVariousIndicationsValidator()
