import {
  Brand,
  BRAND_TYPES_SELECT_OPTIONS,
  DATE_ISO,
  DateField,
  DateUtils,
  DEPOSIT_TYPE_DIVISION,
  DOCUMENT_FORMATS,
  DOCUMENT_TYPES,
  DropArea,
  EventType,
  FieldStatus,
  HelpBlock,
  Preview,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION,
  SelectField,
  SwitchField,
  TextField,
  Transaction,
  TransactionDocument,
  ORIGIN_UNKNOWN,
  TransactionService as CommonTransactionService
} from '@inpi-marques/components'
import { DepositTypeContentProps } from 'interfaces/Deposit'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../store/fieldStatus/fieldStatusActions'
import Message from '../../../../constants/Message'
import { BRAND_TYPE_WORD, BRAND_TYPES_ALL, BrandType as IBrandType } from '../../../../constants/BrandConstants'
import { storeTransactionUpdate, storeTransactionUpdateDeposit } from '../../../../store/transaction/transactionActions'
import { withRouter } from 'react-router-dom'
import DocumentService from '../../../../services/document/DocumentService'
import TransactionService from '../../../../services/transaction/TransactionService'

const RecordNotFoundCreation: FC<DepositTypeContentProps> = ({
  deposit,
  fieldStatus
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [depositBrand, setDepositBrand] = useState<Brand | undefined>(deposit.brand)
  const [brandDocument, setBrandDocument] = useState<TransactionDocument | undefined>(depositBrand?.file)
  const [isRegistered, setIsRegistered] = useState<boolean>(true)
  const transaction: Transaction = useSelector((state: RootStateOrAny) => state.transaction)

  const onDepositDateChanged = (event: EventType) => {
    const { value } = event.target
    dispatch(storeTransactionUpdateDeposit({ createdAtToTransformOrDivide: DateUtils.formatToBeginOfDay(value) }))
  }

  const onDepositRegisteredDateChanged = (event: EventType) => {
    const { value } = event.target
    dispatch(storeTransactionUpdateDeposit({ registeredDate: DateUtils.formatToBeginOfDay(value) }))
  }

  const onDepositSelectChanged = (event: EventType) => {
    const { value } = event.target
    setDepositBrand({ ...deposit.brand, type: value, text: undefined, file: undefined, preview: undefined })
    setBrandDocument(undefined)
    dispatch(storeTransactionUpdateDeposit({ from: ORIGIN_UNKNOWN, brand: { ...deposit.brand, type: value, text: undefined, file: undefined, preview: undefined } }))
  }

  const onDepositTextChanged = (event: EventType) => {
    const { value } = event.target
    dispatch(storeTransactionUpdateDeposit({ brand: { ...deposit.brand, text: value } }))
  }

  /**
   * Une fois le fichier déposé
   * On garde le fichier tout juste upload dans le state
   * car on ne peut sauvegarder un fichier dans le store
   * @param file
   */
  const onBrandDocumentDroped = async (file: File): Promise<void> => {
    setBrandDocument({
      type: DOCUMENT_TYPES.BRAND,
      internalName: '',
      name: file.name,
      format: file.type,
      file,
      size: file.size
    })
  }

  /**
   * Une fois le fichier inséré dans le Drop Area, on le crée en base et on récupère sa prévisualisation
   * @returns
   */
  const onBrandDocumentAdded = async (brand: Brand) => {
    if (brandDocument && brandDocument.file) {
      let newTransaction = { ...transaction }
      if (!newTransaction.id) {
        newTransaction = await TransactionService.createTransaction(transaction, PROCEDURE_DIVISION.value, ['deposit', 'subProcedureType', 'suite'])
      } else {
        // Update de la transaction pour mettre à jour le type de la marque
        await TransactionService.updateTransactionBDDFromStore(transaction, ['deposit'])
      }
      // Enregistrement du fichier de la marque
      return await DocumentService.createAndGetDocumentPreview(brandDocument, newTransaction, brand)
    } else if (depositBrand?.file) {
      // Si l'image de la transaction enregistrée est déjà upload
      return await DocumentService.getBrandPreviewDocument(transaction, depositBrand?.file)
    } else {
      onBrandDocumentDeleted(brand)
    }
  }

  const onBrandDocumentDeleted = (brand: Brand): void => {
    setBrandDocument(undefined)
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, file: undefined } }))
  }

  const handleSwitchRegistered = (isRegistered: boolean): void => {
    setIsRegistered(isRegistered)

    let valuesToUpdate
    if (isRegistered) {
      valuesToUpdate = {
        procedureType: PROCEDURE_DIVISION.value,
        subProcedureType: null
      }
    } else {
      valuesToUpdate = {
        procedureType: PROCEDURE_DEPOSIT.value,
        subProcedureType: DEPOSIT_TYPE_DIVISION.value
      }
    }

    dispatch(storeTransactionUpdate({ ...transaction, procedureType: valuesToUpdate.procedureType, subProcedureType: valuesToUpdate.subProcedureType }))
  }

  return (
    <>
      <div><HelpBlock className='mb-2 mt-2 col-12 p-0'><FormattedMessage id={`deposit_type_${CommonTransactionService.isFrmiOP(transaction) ? 'frmi' : 'division'}_not_found`} /></HelpBlock></div>
      <div className='row d-flex mt-2 p-0'>
        <div className='col-12 col-lg-4 mr-2'>
          <DateField
            inputId='division-deposit-date'
            required
            value={DateUtils.formateDateToFormat(deposit.createdAtToTransformOrDivide, DATE_ISO) ?? ''}
            label={<FormattedMessage id='deposit_type_division_registered_deposit_date' />}
            placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
            onChange={onDepositDateChanged}
            fieldStatus={fieldStatus as FieldStatus}
            nameFieldStatus='nationalDepositDate'
            classNameLabel='col-12 p-0'
            dispatch={dispatch}
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
        <div className='col-12 col-lg-4'>
          <SelectField
            inputId='division-deposit-type'
            required
            label={<FormattedMessage id='deposit_type_division_registered_type' />}
            placeholder={Message.brand_type_title_isListing}
            value={deposit.brand?.type}
            onChange={onDepositSelectChanged}
            fieldStatus={fieldStatus as FieldStatus}
            nameFieldStatus='nationalDepositType'
            classNameLabel='col-12 p-0'
            dispatch={dispatch}
            resetError={storeTransactionFieldStatusUpdate}
            options={BRAND_TYPES_SELECT_OPTIONS}
          />
        </div>
        <div className='col-12 col-lg-4 mr-2'>
          <DateField
            inputId='division-deposit-registered-date'
            value={DateUtils.formateDateToFormat(deposit.registeredDate, DATE_ISO) ?? ''}
            label={<FormattedMessage id='deposit_type_division_registered_date' />}
            placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
            onChange={onDepositRegisteredDateChanged}
            fieldStatus={fieldStatus as FieldStatus}
            nameFieldStatus='nationalDepositRegisteredDate'
            classNameLabel='col-12 p-0'
            dispatch={dispatch}
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
      </div>
      {depositBrand &&
        <>
          {depositBrand?.type === BRAND_TYPE_WORD.value
            ? (
              <div className='row'>
                <div className='col-12 col-lg-4'>
                  <TextField
                    inputId='division-deposit-text'
                    required
                    value={deposit.brand?.text}
                    label={<FormattedMessage id='deposit_type_division_registered_marque' />}
                    onChange={onDepositTextChanged}
                    fieldStatus={fieldStatus as FieldStatus}
                    nameFieldStatus='nationalDepositText'
                    classNameLabel='col-12 p-0'
                    dispatch={dispatch}
                    resetError={storeTransactionFieldStatusUpdate}
                    maxLength={500}
                  />
                </div>
              </div>
            ) : (
              <DropArea
                accept={BRAND_TYPES_ALL.find((type: IBrandType) => type.value === depositBrand?.type)?.acceptedFormat}
                maxSize={BRAND_TYPES_ALL.find((type: IBrandType) => type.value === depositBrand?.type)?.maxSize}
                text={transaction.procedureType === PROCEDURE_DIVISION.value ? 'drop_brand_file_division' : 'drop_brand_file'}
                onDrop={(files: File[]) => onBrandDocumentDroped(files[0])}
                fieldStatus={fieldStatus}
                nameFieldStatus='brandDocument'
              />
            )}
          {brandDocument &&
            <Preview
              file={() => onBrandDocumentAdded(depositBrand)}
              document={{ ...brandDocument, hash: depositBrand?.file?.hash }}
              className={brandDocument.format === DOCUMENT_FORMATS.MP4 ? 'col-6' : ''}
              onTrashClick={() => onBrandDocumentDeleted(depositBrand)}
              isPrintFormat
            />}
        </>}
      <div className='national-deposit-date__wrapper col-12 p-0 mt-4'>
        <SwitchField
          inputId='is-registered'
          className='p-0 mr-2 ml-5'
          displayStringValue
          divClassName='d-flex'
          value={isRegistered}
          label={<FormattedMessage id='deposit_type_division_is_registered' />}
          onChange={() => handleSwitchRegistered(!isRegistered)}
          fieldStatus={fieldStatus as FieldStatus}
          nameFieldStatus='isRegistered'
        />
      </div>
    </>
  )
}

export default withRouter(RecordNotFoundCreation)
