import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router'
import PaymentNotification from './PaymentNotification'

const PaymentRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route
      path={`${match.url}/:id/notifications/:idNotification`}
      render={(props) => <PaymentNotification id={props.match.params.id} idNotification={props.match.params.idNotification} />}
    />
  </Switch>
)

export default PaymentRoutes
