import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import store from '../store/store'
import { storeLogout } from '../store/user/userActions'
/* global localStorage */

const API_ADDRESS_SEARCH = 'https://api-adresse.data.gouv.fr/search/'

const http = axios.create({
  headers: {
    'Content-type': 'application/json'
  }
})

function onSuccess (response: AxiosResponse) {
  const contentDisposition = response.headers['content-disposition']
  if (contentDisposition && contentDisposition.split(';').length) {
    const file = contentDisposition.split(';')[1]
    if (file && file.split('=').length) {
      const filename = file.split('=')[1]
      return {
        filename: filename,
        data: response.data
      }
    }
  }
  return response.data
}

function onReject (error: any) {
  if (error.response) {
    // Si l'utilisateur n'est pas connecté, on le supprime du store
    if (error.response.status === 403) {
      store.dispatch(storeLogout())
      error.response.data.message = 'Votre session a expiré, veuillez vous reconnecter.'
    } else if (error.response.status === 401) {
      error.response.data.message = 'La ressource demandée n\'existe pas.'
    }
  }
  return Promise.reject(error.response ? error.response.data : error)
}

/**
 * Ajout du token jwt dans les headers des requêtes
 * @param config AxiosRequestConfig
 * @returns AxiosRequestConfig
 */
function injectSessionToken (config: AxiosRequestConfig): AxiosRequestConfig {
  if (config.url && config.url.indexOf(API_ADDRESS_SEARCH) === -1) {
    const sessionToken = localStorage.getItem('token-marques-fo')
    if (sessionToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${sessionToken}`
        }
      }
    }
  }

  return config
}

http.interceptors.request.use(
  injectSessionToken
)

http.interceptors.response.use(
  onSuccess, onReject
)

/**
 * Configuration à ajouter en second paramètre de http.get()
 * pour correctement télécharger des fichiers binaires
 */
const DOWNLOAD_FILE: AxiosRequestConfig = { responseType: 'blob' }

export default http

export { DOWNLOAD_FILE }
