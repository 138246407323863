import {
  Country,
  DATE_ISO,
  DateField,
  DateUtils,
  DOCUMENT_FORMATS,
  DOCUMENT_TYPES,
  EventType,
  FieldStatus,
  FileBrowserField,
  FileButton,
  Foundation,
  FoundationService as CommonFondationService,
  FoundationService,
  NumNatVerifier,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS,
  OPPOSITION_FOUNDATION_TYPE_RENOWNED,
  Record,
  SelectField,
  SelectOption,
  TextField,
  TransactionDocument,
  BRAND_TYPES_SELECT_OPTIONS,
  MultipleRadioField,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_FRMI,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_UE,
  PROCEDURE_OPPOSITION
} from '@inpi-marques/components'

import { FoundationDocument } from '@inpi-marques/components/src/interfaces/opposition/Opposition'
import React, { FC, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import FoundationObject from '../../components/Object'
import Priority from '../../components/Priority'
import Message from '../../../../constants/Message'
import { OPTION_OTHER, TYPE_MARQUE_OPTIONS } from '../../../../constants/TypeMarqueConstants'

interface BrandFormProps {
    foundation: Foundation,
    setFoundation: (foundation: Foundation) => void,
    foundationType: string,
    brandOrigins: SelectOption[],
    fieldStatus?: FieldStatus,
    onDocumentAdded?: (event: EventType, type: string) => Promise<void>,
    documents?: TransactionDocument[],
    onDocumentDeleted?: (document: TransactionDocument) => Promise<void>,
    onDocumentsDeleted?: (documents: FoundationDocument[]) => void,
    setFieldStatus?: (fieldStatus: FieldStatus) => void,
    disableFiles?: boolean,
    countries: Country[],
    verifyNumnat?: () => Promise<Record[]>,
    references?: {} | undefined,
    addToRefs?: (element) => void,
    procedureType?: string,
    handleDownload?: (document: TransactionDocument) => void
}

const BrandForm: FC<BrandFormProps> = ({
  setFieldStatus,
  foundation,
  setFoundation,
  foundationType,
  brandOrigins,
  fieldStatus,
  onDocumentAdded,
  documents,
  onDocumentDeleted,
  onDocumentsDeleted,
  disableFiles,
  countries,
  verifyNumnat,
  addToRefs,
  procedureType,
  handleDownload
}) => {
  const intl = useIntl()

  /**
     * On supprime les fichiers qui n'ont pu lieu d'être présent quand l'origine ou le type de marque change
     */
  useEffect(() => {
    const brandCopyFile = FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, foundation.type === OPPOSITION_FOUNDATION_TYPE_RENOWNED.value ? DOCUMENT_TYPES.FOUNDATION_RENOWNED_SIGNCOPY : DOCUMENT_TYPES.MOTIVE_BRAND_SIGNCOPY)
    if (brandCopyFile.length && foundation.entity?.origin === OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value) {
      onDocumentDeleted && onDocumentDeleted(brandCopyFile[0])
    }
    const notoriousFiles = [...FoundationService.getDocumentsByType(foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGNREPRESENTATION),
      ...FoundationService.getDocumentsByType(foundation.documents, foundation.type === OPPOSITION_FOUNDATION_TYPE_RENOWNED.value ? DOCUMENT_TYPES.FOUNDATION_RENOWNED_SIGNCOPY : DOCUMENT_TYPES.FOUNDATION_BRAND_EXISTENCE)]
    if (notoriousFiles.length && foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value) {
      onDocumentsDeleted && onDocumentsDeleted(notoriousFiles)
    }

    const signCopyFile = FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGNREPRESENTATION)
    if (signCopyFile.length && !foundation.entity?.isWord) {
      onDocumentDeleted && onDocumentDeleted(signCopyFile[0])
    }
  }, [foundation.entity?.isWord, foundation.entity?.origin])

  /**
     * Lorsqu'on verifie le numéro de dépôt / d'enregistrement
     */
  const onVerifyNumber = async (): Promise<Record[]> => {
    return verifyNumnat ? await verifyNumnat() : []
  }

  return (
    <div className='d-flex flex-wrap align-items-end is-validated brand-form'>
      {/* Origine de la Marque */}
      <SelectField
        addToRefs={addToRefs}
        inputId='entity_origin'
        options={brandOrigins}
        classNameFormGroup='col-4'
        label={<FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_brand_origin_label`} />}
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'origin', event.target.value))}
        value={foundation.entity?.origin}
        placeholder={intl.formatMessage({ id: 'foundation_brand_origin_placeholder' })}
        required
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />

      {/* Nom de la marque */}
      <TextField
        inputId='entity_name'
        label={<FormattedMessage id='foundation_brand_name_label' />}
        classNameFormGroup='col-4'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'name', event.target.value))}
        value={foundation.entity?.name}
        required={!!foundation.entity?.isWord}
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />

      {foundation.entity?.origin && foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value &&
        <>
          {/* NUMERO D'ENREGISTREMENT */}
          {CommonFondationService.isInternationalBrand(foundation.entity.origin) &&
            <NumNatVerifier
              inputId='registering_number'
              classNameFormGroup='col-4 p-0'
              className='col-12 p-0'
              labelClassname='font-italic mr-2'
              loaderClassName='brand-form__input-loader'
              verifyNumNat={onVerifyNumber}
              value={foundation.entity.registeringNumber}
              setValue={(value: string) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'registeringNumber', value))}
              isInternationalBrand={foundation.entity.origin === OPPOSITION_FOUNDATION_BRAND_ORIGIN_FRMI.value}
              fieldStatus={fieldStatus}
              setFieldStatus={setFieldStatus}
              countries={countries}
              onRecordSelected={verifyNumnat ? (record: Record) => setFoundation(FoundationService.getUpdatedFoundationByRecord(foundation, record, procedureType)) : undefined}
              label={<FormattedMessage id='request_identification_numnat_international' />}
            />}

          {/* NUMERO DE DEPOT */}
          {!CommonFondationService.isInternationalBrand(foundation.entity.origin) &&
            <NumNatVerifier
              inputId='deposit_number'
              classNameFormGroup='col-4'
              className='col-12 p-0'
              labelClassname='font-italic mr-2'
              loaderClassName='brand-form__input-loader'
              verifyNumNat={onVerifyNumber}
              value={foundation.entity.depositNumber}
              setValue={(value: string) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'depositNumber', value))}
              isInternationalBrand={false}
              fieldStatus={fieldStatus}
              setFieldStatus={setFieldStatus}
              countries={countries}
              onRecordSelected={verifyNumnat ? (record: Record) => setFoundation(FoundationService.getUpdatedFoundationByRecord(foundation, record, procedureType)) : undefined}
              label={foundation.entity.origin === OPPOSITION_FOUNDATION_BRAND_ORIGIN_UE.value ? <FormattedMessage id='foundation_brand_number_label' /> : undefined}
            />}
          {/* TYPE DE LA MARQUE */}
          <SelectField
            label={Message.brand_type_label}
            inputId='type'
            required={foundation.entity?.origin !== OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value}
            classNameFormGroup='col-4'
            options={BRAND_TYPES_SELECT_OPTIONS}
            value={foundation.entity.type}
            onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'type', event.target.value))}
            fieldStatus={fieldStatus}
            nameFieldStatus='entity_type'
            resetError={setFieldStatus}
            placeholder={Message.brand_type_placeholder}
          />
          {/* DATE D'ENREGISTREMENT */}
          {CommonFondationService.isInternationalBrand(foundation.entity.origin) &&
            <DateField
              addToRefs={addToRefs}
              classNameFormGroup='col-4'
              inputId='registering_date'
              label={<FormattedMessage id='foundation_international_brand_created_date_label' />}
              value={DateUtils.formateDateToFormat(foundation.entity.registeringDate, DATE_ISO) ?? ''}
              onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'registeringDate', DateUtils.formatToBeginOfDay(event.target.value)))}
              required
              fieldStatus={fieldStatus}
              resetError={setFieldStatus}
            />}
          {/* DATE DE DEPOT */}
          {!CommonFondationService.isInternationalBrand(foundation.entity.origin) &&
            <DateField
              addToRefs={addToRefs}
              classNameFormGroup='col-4'
              inputId='deposit_date'
              label={<FormattedMessage id='foundation_brand_created_date_label' />}
              value={DateUtils.formateDateToFormat(foundation.entity.depositDate, DATE_ISO) ?? ''}
              onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'depositDate', DateUtils.formatToBeginOfDay(event.target.value)))}
              required
              fieldStatus={fieldStatus}
              resetError={setFieldStatus}
            />}
          {/* Date d’inscription au registre international de l’extension à l'Union européenne */}
          {CommonFondationService.isInternationalBrand(foundation.entity.origin) &&
            <DateField
              addToRefs={addToRefs}
              classNameFormGroup='col-4'
              inputId='subscription_date'
              label={<FormattedMessage id={`foundation_brand_international_register_date_label_${foundation.entity.origin.toLowerCase()}`} />}
              value={DateUtils.formateDateToFormat(foundation.entity.inscriptionDate, DATE_ISO) ?? ''}
              onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'inscriptionDate', DateUtils.formatToBeginOfDay(event.target.value)))}
            />}
          {/* Date d’inscription au registre international de l’extension à la France */}
          {foundationType === OPPOSITION_FOUNDATION_TYPE_RENOWNED.value && !CommonFondationService.isInternationalBrand(foundation.entity.origin) &&
            <DateField
              addToRefs={addToRefs}
              classNameFormGroup='col-4'
              inputId='subscription_date'
              label={<FormattedMessage id='foundation_brand_international_register_date_label' />}
              value={DateUtils.formateDateToFormat(foundation.entity?.inscriptionDate, DATE_ISO) ?? ''}
              onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'inscriptionDate', DateUtils.formatToBeginOfDay(event.target.value)))}
            />}
          {/* PRIORITÉ REVENDIQUÉE */}
          <Priority
            countries={countries} setFieldStatus={setFieldStatus} foundation={foundation}
            setFoundation={setFoundation} fieldStatus={fieldStatus} onDocumentAdded={onDocumentAdded}
            documents={documents} onDocumentDeleted={onDocumentDeleted}
          />

          <DateField
            addToRefs={addToRefs}
            classNameFormGroup='col-4'
            inputId='renewalRequestDate'
            label={<FormattedMessage id='foundation_brand_claimed_renewal_request_date_label' />}
            value={DateUtils.formateDateToFormat(foundation.entity?.renewalRequestDate, DATE_ISO) ?? ''}
            onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'renewalRequestDate', DateUtils.formatToBeginOfDay(event.target.value)))}
          />
          <DateField
            addToRefs={addToRefs}
            classNameFormGroup='col-4'
            inputId='renewalPublicationDate'
            label={<FormattedMessage id='foundation_brand_claimed_renewal_publication_date_label' />}
            value={DateUtils.formateDateToFormat(foundation.entity?.renewalPublicationDate, DATE_ISO) ?? ''}
            onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'renewalPublicationDate', DateUtils.formatToBeginOfDay(event.target.value)))}
          />
          {/* LA MARQUE ANTÉRIEURE A-T-ELLE FAIT L’OBJET D’UNE */}
          <FoundationObject
            setFieldStatus={setFieldStatus} foundation={foundation}
            setFoundation={setFoundation} fieldStatus={fieldStatus}
            onDocumentAdded={onDocumentAdded} documents={documents}
            onDocumentDeleted={onDocumentDeleted}
          />

          {/* COPIE DE LA MARQUE ANTERIEURE */}
          {!disableFiles &&
            <FileBrowserField
              addToRefs={addToRefs}
              className='pl-3 mt-3'
              inputId='brand_copy'
              classNameButton='d-contents'
              buttonLabel={<FileButton />}
              acceptApplication={DOCUMENT_FORMATS.PDF}
              label={<FormattedMessage id='foundation_brand_copy_label' />}
              onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, foundation.type === OPPOSITION_FOUNDATION_TYPE_RENOWNED.value ? DOCUMENT_TYPES.FOUNDATION_RENOWNED_SIGNCOPY : DOCUMENT_TYPES.MOTIVE_BRAND_SIGNCOPY)}
              value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, foundation.type === OPPOSITION_FOUNDATION_TYPE_RENOWNED.value ? DOCUMENT_TYPES.FOUNDATION_RENOWNED_SIGNCOPY : DOCUMENT_TYPES.MOTIVE_BRAND_SIGNCOPY)}
              onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
              maxNumberFile={1}
              informationDoc
              required={procedureType !== PROCEDURE_OPPOSITION.value}
              fieldStatus={fieldStatus}
              handleDownload={handleDownload}
            />}
        </>}

      {/* MARQUE NOTOIRE */}
      {foundation.entity?.origin === OPPOSITION_FOUNDATION_BRAND_ORIGIN_NOTORIOUS.value &&
        <div className='row col-12 align-items-top'>
          {/* EST UNE MARQUE VERBALE ? */}
          <div className='form-group col-4 align-items-top'>
            <label className='form-label'><FormattedMessage id='foundation_is_word_type_brand_label' /></label>
            <MultipleRadioField
              classNameRadioGroup='d-flex'
              inputId='isWord'
              value={foundation.entity?.isWord ?? OPTION_OTHER.value}
              radio={TYPE_MARQUE_OPTIONS}
              onChange={() => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'isWord', !foundation.entity?.isWord))}
            />
          </div>
          {/* REPRESENTATION DU SIGNE ANTERIEUR */}
          {!disableFiles && !foundation.entity?.isWord &&
            <FileBrowserField
              className='col-4'
              classNameButton='d-contents'
              inputId='sign_file'
              buttonLabel={<FileButton />}
              label={<FormattedMessage id='foundation_sign_file_label' />}
              onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGNREPRESENTATION)}
              value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_SIGNREPRESENTATION)}
              onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
              maxNumberFile={1}
              required={!foundation.entity?.isWord}
              fieldStatus={fieldStatus}
              resetError={setFieldStatus}
              informationDoc
              handleDownload={handleDownload}
            />}
          {/* Document(s) justifiant de l'existence et de la portée du droit antérieur */}
          {!disableFiles &&
            <FileBrowserField
              className='col-4'
              classNameButton='d-contents'
              inputId='rightFile'
              acceptApplication={DOCUMENT_FORMATS.PDF}
              buttonLabel={<FileButton />}
              label={<FormattedMessage id='foundation_right_file_label' />}
              onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_BRAND_EXISTENCE)}
              value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_BRAND_EXISTENCE)}
              onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
              required={procedureType !== PROCEDURE_OPPOSITION.value}
              fieldStatus={fieldStatus}
              resetError={setFieldStatus}
              informationDoc
              placementTooltip={!foundation.entity?.isWord ? 'bottom' : 'right'}
              handleDownload={handleDownload}
            />}
        </div>}
    </div>

  )
}

export default BrandForm
