import React, { FC } from 'react'
import moment from 'moment'
import { Transaction } from '@inpi-marques/components'
import OverviewNotificationOMPIBody from './OverviewNotificationOMPIBody'

interface OverviewNotificationsOMPIProps {
  transaction: Transaction,
  setReload?: ()=>void
}

/**
 * Composant qui contient toutes les notifications OMPI
 * Permet d'afficher les notifications OMPI et d'y répondre
 *
 * @param transaction - Objet transaction
 * @param setReload - Fonction pour modifier si la transaction doit être rechargée
 * @constructor
 */
const OverviewNotificationsOMPI: FC<OverviewNotificationsOMPIProps> = ({
  transaction,
  setReload
}) => {
  return (
    <div id='overview-notifications-ompi'>
      {transaction?.notificationsOMPI?.sort((a, b) => (a.sendingDate && !b.sendingDate) || moment(a.sendingDate) > moment(b.sendingDate) ? -1 : 1)
          .map((notification, index) => {
            return (
              <OverviewNotificationOMPIBody
                key={index}
                notification={notification}
                transaction={transaction}
                index={index}
                setReload={setReload}
              />)
          })}
    </div>
  )
}

export default OverviewNotificationsOMPI
