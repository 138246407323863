import {
  DATE_ISO,
  DateField,
  DateUtils,
  HelpBlock,
  PaymentMethodContentProps,
  TextField
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import Message from '../../../../../constants/Message'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import { PAYMENT_TO_OMPI_POSTAL_ACCOUNT } from '../../../../../constants/PaymentMethodsConstants'

const PaymentToOMPIForm: FC<PaymentMethodContentProps> = ({ fieldStatus, type, onValueChange, paymentMethod }) => {
  const prefixLabel: string = type === PAYMENT_TO_OMPI_POSTAL_ACCOUNT.value ? 'postal' : 'bank'

  return (
    <div className='paymentToOMPI'>
      <div className='mt-3'>
        <HelpBlock>
          <FormattedMessage id='fees_and_taxes_payment_proof_description' />
        </HelpBlock>
        <HelpBlock className='mt-3'>
          <FormattedMessage
            id='fees_and_taxes_payment_ompi_page_description'
            values={{
              a: (message : ((string) | '')) => (<a href={Message.fees_and_taxes_payment_ompi_page_link} target='_blank' rel='noopener noreferrer'><u>{message}</u></a>),
              linebreak: <br />
            }}
          />
        </HelpBlock>
      </div>
      <div className='mt-3'>
        <div className='col-12'>
          <TextField
            inputId='paymentAuthor'
            required
            value={paymentMethod?.paymentAuthor}
            label={<FormattedMessage id='fees_and_taxes_payment_author' />}
            onChange={onValueChange}
            fieldStatus={fieldStatus}
            nameFieldStatus={`${prefixLabel}PaymentAuthor`}
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
        <div className='col-12'>
          <TextField
            inputId='paymentReferences'
            required
            value={paymentMethod?.paymentReferences}
            label={<FormattedMessage id='fees_and_taxes_payment_references' />}
            onChange={onValueChange}
            fieldStatus={fieldStatus}
            nameFieldStatus={`${prefixLabel}PaymentReferences`}
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
        <div className='col-12'>
          <DateField
            inputId='transferDate'
            required
            value={DateUtils.formateDateToFormat(paymentMethod?.transferDate, DATE_ISO) ?? ''}
            label={<FormattedMessage id='fees_and_taxes_payment_transfer_date' />}
            onChange={onValueChange}
            fieldStatus={fieldStatus}
            nameFieldStatus={`${prefixLabel}TransferDate`}
            resetError={storeTransactionFieldStatusUpdate}
            classNameLabel='col-12 p-0'
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentToOMPIForm
