import {
  DOCUMENT_FORMATS,
  DOCUMENT_TYPES,
  EventType,
  FileBrowserField,
  FileButton,
  FoundationService,
  MultipleRadioField,
  OppositionFoundationTypeComponent,
  PROCEDURE_OPPOSITION,
  TextArea,
  TextField
} from '@inpi-marques/components'
import React, { FC, useEffect } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import { OPTION_OTHER, TYPE_SIGN_OPTIONS } from '../../../../constants/TypeMarqueConstants'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT D'UNE ATTEINTE AU NOM, IMAGE OU RENOMMEE D'UNE COLLECTIVITE
 */
const FoundationCollectivityForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentAdded, onDocumentDeleted, documents, procedureType, setFieldStatus, disableFiles, addToRefs, disableActiveField, handleDownload }) => {
  /**
   * On supprime le fichier de representation de signe, si c'est une marque verbale ou si on passe sur un type "nom commercial"
   */
  useEffect(() => {
    const signCopyFiles = FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LOCAL_AUTHORITY_SIGN : DOCUMENT_TYPES.FOUNDATION_EPCI_SIGN)

    if (signCopyFiles.length && foundation.entity?.isWord) {
      onDocumentDeleted && onDocumentDeleted(signCopyFiles[0])
    }
  }, [foundation.entity?.isWord])

  return (
    <IntlProvider locale='fr' messages={Message}>
      {/* SIGNE IDENTIFIANT LA COLLECTIVITE */}
      <div className='row align-items-top'>
        <h2 className='text-primary col-12 mb-4'>
          <FormattedMessage id='foundation_form_collectivity_sign_part_label' />
        </h2>
        {/* TYPE DE SIGNE */}
        <div className='form-group col-4 align-items-top'>
          <label className='form-label'><FormattedMessage id='foundation_is_word_type_collectivity_label' /></label>
          <MultipleRadioField
            classNameRadioGroup='d-flex'
            inputId='isWord'
            value={foundation.entity?.isWord ?? OPTION_OTHER.value}
            radio={TYPE_SIGN_OPTIONS}
            onChange={() => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'isWord', !foundation.entity?.isWord))}
          />
        </div>

        {/* DESIGNATION DU SIGNE */}
        <TextField
          addToRefs={addToRefs}
          inputId='entity_name'
          label={<FormattedMessage id='foundation_sign_name_label' />}
          classNameFormGroup='col-4'
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'name', event.target.value))}
          value={foundation.entity?.name}
          required={foundation.entity?.isWord}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
        />
        <div className='row col-12 my-3'>
          {/* REPRESENTATION DU SIGNE ANTERIEUR */}
          {!disableFiles && !foundation.entity?.isWord &&
            <FileBrowserField
              addToRefs={addToRefs}
              className='col-4'
              classNameButton='d-contents'
              inputId='sign_file'
              buttonLabel={<FileButton />}
              label={<FormattedMessage id='foundation_sign_file_label' />}
              acceptApplication={DOCUMENT_FORMATS.PDF}
              required
              onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LOCAL_AUTHORITY_SIGN : DOCUMENT_TYPES.FOUNDATION_EPCI_SIGN)}
              value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LOCAL_AUTHORITY_SIGN : DOCUMENT_TYPES.FOUNDATION_EPCI_SIGN)}
              onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
              maxNumberFile={1}
              fieldStatus={fieldStatus}
              resetError={setFieldStatus}
              informationDoc
              handleDownload={handleDownload}
            />}

          {/* DOCUMENT JUSTIFIANT DE L'EXISTENCE ET DE LA PORTEE DU DROIT ANTERIEUR */}
          {!disableFiles &&
            <FileBrowserField
              className='col-4'
              inputId='rightFile'
              classNameButton='d-contents'
              buttonLabel={<FileButton />}
              label={<FormattedMessage id='foundation_right_file_label' />}
              acceptApplication={DOCUMENT_FORMATS.PDF}
              onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LOCAL_AUTHORITY_EXISTENCE : DOCUMENT_TYPES.FOUNDATION_EPCI_EXISTENCE)}
              value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LOCAL_AUTHORITY_EXISTENCE : DOCUMENT_TYPES.FOUNDATION_EPCI_EXISTENCE)}
              onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
              required={procedureType !== PROCEDURE_OPPOSITION.value}
              fieldStatus={fieldStatus}
              resetError={setFieldStatus}
              informationDoc
              handleDownload={handleDownload}
            />}

          {/* DOCUMENT PROPRE A JUSTIFIER EN QUOI CE SIGNE IDENTIFIE LA COLLECTIVITE OU EPCI */}
          {!disableFiles &&
            <FileBrowserField
              className='col-4'
              inputId='proofFile'
              classNameButton='d-contents'
              buttonLabel={<FileButton />}
              label={<FormattedMessage id='foundation_proof_file_label' />}
              acceptApplication={DOCUMENT_FORMATS.PDF}
              onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_COLLECTIVITY_PROOF)}
              value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_COLLECTIVITY_PROOF)}
              onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
              maxNumberFile={1}
              required={procedureType !== PROCEDURE_OPPOSITION.value}
              fieldStatus={fieldStatus}
              resetError={setFieldStatus}
              informationDoc
              placementTooltip='bottom'
              handleDownload={handleDownload}
            />}
        </div>
      </div>
      {procedureType === PROCEDURE_OPPOSITION.value &&
        <div className='row'>
          <h2 className='text-primary col-12'>
            <FormattedMessage id='foundation_form_activity_part_label' />
          </h2>
          {/* MISSIONS, ATTRIBUTIONS OU DOMAINES D'INTERVENTION */}
          <TextArea
            classNameFormGroup='col-4'
            inputId='activity'
            onChange={(event: EventType) => setFoundation({ ...foundation, activity: event.target.value })}
            value={foundation.activity}
            label={<FormattedMessage id='foundation_form_activity_label' />}
            required
            fieldStatus={fieldStatus}
            resetError={setFieldStatus}
          />
        </div>}
      {/* FONDEMENT ACTIF */}
      <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
    </IntlProvider>
  )
}

export default FoundationCollectivityForm
