import React, { Dispatch, FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Contributor, ContributorsOptionsForm } from '../../interfaces/contributors/ContributorsInterfaces'
import { EventType, FieldStatus } from '../../form/FormInterfaces'
import {
  CONTRIBUTOR_HOLDERS_CONTESTED_MARK,
  DEPOSIT_CONTRIBUTORS_TYPES,
  ModalComponent,
  PERSONNE_MORALE,
  SirenDenomination,
  TextArea,
  TextField
} from '../../index'
import ListSirenWithDenomination from './ListSirenWithDenomination'
import { toast } from 'react-toastify'
import { SubmitButton, InlineTextField } from '@inpi-marques/components'

// composant pour les champs d'une personne morale
interface LegalCompanyFieldsProps {
  // intervenant a modifier
  contributor?: Contributor,
  // fonction pour modifier l'intervenant
  onChange: (event: EventType) => void,
  // informations sur les erreurs
  fieldStatus?: FieldStatus,
  // options pour le formulaire
  options?: ContributorsOptionsForm,
  // fonction permettant de compléter un intervenant depuis son siren
  fillContributorInfo?: (siren: string) => void,
  // fonction permettant de trouver une liste de siren depuis un nom d'entreprise
  findListSirenByNamePromise?: (companyName: string) => Promise<SirenDenomination[]|null>
  dispatch?: Dispatch<any>,
  resetError?: (fieldStatus: FieldStatus) => void,
  contributorType?: string,
  isBO?: boolean
}

const LegalCompanyFields: FC<LegalCompanyFieldsProps> = ({
  contributor = {},
  onChange,
  fieldStatus,
  options,
  fillContributorInfo,
  findListSirenByNamePromise,
  dispatch,
  resetError,
  contributorType,
  isBO = false
}) => {
  const [listDenominationModal, setListDenominationModal] = useState(false)
  const [listSirenDenomination, setListSirenDenomination] = useState<SirenDenomination[]>([])
  const intl = useIntl()

  /**
   * Ouvre sous forme de modal un tableau contenant les siren trouvés à partir du nom d'entreprise recherché
   */
  const openListDenominationModal = () => {
    if (findListSirenByNamePromise && contributor?.companyName && contributor?.companyName.length > 0) {
      findListSirenByNamePromise(contributor?.companyName).then((result: SirenDenomination[]|null) => {
        if (result) {
          setListSirenDenomination(result)
          setListDenominationModal(true)
        } else {
          toast.error(intl.formatMessage({ id: 'intervenant_error_api_error' }))
        }
      })
    } else {
      toast.error(intl.formatMessage({ id: 'intervenant_error_denomination_not_empty' }))
    }
  }

  /**
   * Sélectionne une entreprise
   * @param siren
   */
  const onSelectCompany = (siren: string) => {
    fillContributorInfo && fillContributorInfo(siren)
    setListDenominationModal(false)
  }

  return (
    <>
      <div className='row'>
        <div className={`col-12 col-md-8 ${options?.showSiren ? 'd-flex justify-content-between' : ''}`}>
          {(options?.showSiren && findListSirenByNamePromise)
            ? (
              <InlineTextField
                inputId='companyName'
                value={contributor?.companyName || ''}
                className='col-11 p-0 mb-2'
                onChange={onChange}
                label={intl.formatMessage({ id: 'field_raison_sociale_label' })}
                fieldStatus={fieldStatus}
                maxLength={120}
                required
                dispatch={dispatch}
                resetError={resetError}
                readOnly={options?.allFieldReadOnly}
              >

                <SubmitButton className='btn-primary btn-icon ml-1' onClick={openListDenominationModal}>
                  <FontAwesomeIcon icon={faSearch} />
                </SubmitButton>
              </InlineTextField>)
            : (
              <TextField
                inputId='companyName'
                value={contributor?.companyName || ''}
                onChange={onChange}
                label={intl.formatMessage({ id: 'field_raison_sociale_label' })}
                classNameFormGroup={`${options?.showSiren ? 'col-11 pl-0' : ''}`}
                fieldStatus={fieldStatus}
                maxLength={120}
                required
                dispatch={dispatch}
                resetError={resetError}
                readOnly={options?.allFieldReadOnly}
              />)}
        </div>
        {
          options?.showSiren &&
            <div className='col-12 col-md-4 d-flex justify-content-between'>
              {fillContributorInfo
                ? (
                  <InlineTextField
                    inputId='siren'
                    value={contributor?.siren || ''}
                    onChange={onChange}
                    label={intl.formatMessage({ id: 'field_numero_siren_label' })}
                    classNameFormGroup='col-11 pl-0'
                    fieldStatus={fieldStatus}
                    maxLength={20}
                    dispatch={dispatch}
                    resetError={resetError}
                    readOnly={options?.allFieldReadOnly}
                  >
                    <SubmitButton className='btn-primary' onClick={() => fillContributorInfo(contributor?.siren || '')}>
                      <FontAwesomeIcon icon={faSearch} />
                    </SubmitButton>
                  </InlineTextField>)
                : (
                  <TextField
                    inputId='siren'
                    value={contributor?.siren || ''}
                    onChange={onChange}
                    label={intl.formatMessage({ id: 'field_numero_siren_label' })}
                    classNameFormGroup='col-11 pl-0'
                    fieldStatus={fieldStatus}
                    maxLength={20}
                    dispatch={dispatch}
                    resetError={resetError}
                    readOnly={options?.allFieldReadOnly}
                  />)}
            </div>
        }
        <div className='col-12 col-md-8'>
          <TextField
            inputId='legalForm'
            label={intl.formatMessage({ id: 'field_forme_juridique_label' })}
            value={contributor?.legalForm || ''}
            onChange={onChange}
            fieldStatus={fieldStatus}
            maxLength={100}
            required={!(contributorType === DEPOSIT_CONTRIBUTORS_TYPES.RECIPIENT.value && contributor?.type === PERSONNE_MORALE.value) &&
              !(contributorType === CONTRIBUTOR_HOLDERS_CONTESTED_MARK.value && isBO) && (!options?.isLegalFormOptional)}
            dispatch={dispatch}
            resetError={resetError}
            readOnly={options?.allFieldReadOnly}
          />
        </div>
      </div>
      {
        (contributor?.fullCompanyName || (contributor?.companyName && contributor.companyName.length > 119)) &&
          <div className='row'>
            <div className='col-12'>
              <div className='contributor__full-company--warning text-gris font-italic'>
                <FormattedMessage id='field_raison_sociale_label_long_text_needed' />
              </div>
              <TextArea
                inputId='fullCompanyName'
                value={contributor.fullCompanyName || ''}
                onChange={onChange}
                fieldStatus={fieldStatus}
                dispatch={dispatch}
                resetError={resetError}
                readOnly={options?.allFieldReadOnly}
              />
            </div>
          </div>
      }
      <ModalComponent
        size='lg'
        title={<FormattedMessage id='intervenant_select_company' />}
        handleClose={() => setListDenominationModal(false)}
        show={listDenominationModal}
        hideFooter
        customContent={() => <ListSirenWithDenomination listSirenDenomination={listSirenDenomination} onSelectCompany={onSelectCompany} />}
        dialogClassName='modal-companies-list'
      />
    </>
  )
}

export default LegalCompanyFields
