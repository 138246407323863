import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorField, FieldStatus, ProductClass } from '@inpi-marques/components'
import ProductAndServicesFreeInput from 'component/deposit/form/productsAndServices/ProductAndServicesFreeinput'

interface FoundationProductsAndServicesProps {
  productClasses? : ProductClass[],
  handleProductsAndServicesChange : (products : ProductClass[]) =>void,
  fieldStatus?: FieldStatus
}

const FoundationProductsAndServices : FC<FoundationProductsAndServicesProps> = ({
  productClasses,
  handleProductsAndServicesChange,
  fieldStatus
}) => {
  return (
    <div>
      <div className='mt-5 form-header'>
        <div className='d-flex justify-content-between'>
          <div>
            <div className='d-flex'>
              <h2 className='mb-0'>
                <FormattedMessage id='foundation_products_and_services_title' />
              </h2>
            </div>
            <div className='small mt-2'>
              <FormattedMessage
                id='foundation_products_and_services_subtitle'
                values={{
                  a: (...chunks : ((string) []) | []) => (<a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
                  linebreak: <br />
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {
        fieldStatus && fieldStatus.products && <ErrorField message={fieldStatus.products} className='mt-3' />
      }
      <ProductAndServicesFreeInput
        productClasses={productClasses || []}
        onProductClassesEdited={handleProductsAndServicesChange}
        isEditable
        asLink
      />
    </div>
  )
}

export default FoundationProductsAndServices
