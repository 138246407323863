export const STATUS_OPPOSITION = {
  /** Brouillon */
  DRAFT: 'draft_opposition',
  /** En attente de mémoire administratif **/
  WAITING_VIR_PAYMENT: 'waiting_vir_payment_opposition',
  /** mémoire administratif expiré **/
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired_opposition',
  /** Préparation **/
  PREPARATION: 'preparation_opposition',
  /** Attribution **/
  TO_ATTRIBUTE: 'to_attribute_opposition',
  /** Opposition formelle **/
  OPPOSITION_FORMELLE: 'opposition_formelle',
  /** Opposition MI **/
  OPPOSITION_MI: 'opposition_mi',
  /** A notifier **/
  TO_NOTIFY: 'to_notify_opposition',
  /** En attente de réponse **/
  WAITING_ANSWER: 'waiting_answer_opposition',
  /** Décision **/
  DECISION: 'decision_opposition',
  /** Finalisation **/
  FINALISATION: 'finalisation_opposition',
  /** Irrecevable **/
  NOT_ACCEPTED: 'not_accepted_opposition',
  /** Clôture **/
  CLOSE: 'close_opposition',
  /** Terminé **/
  DONE: 'done_opposition',
  /** Suspension **/
  SUSPENSION: 'suspension_opposition',
  /** Recours **/
  APPEAL: 'appeal_opposition'
}

export const STATUS_OPPOSITION_REVIEWING = [
  STATUS_OPPOSITION.PREPARATION,
  STATUS_OPPOSITION.TO_ATTRIBUTE,
  STATUS_OPPOSITION.OPPOSITION_FORMELLE,
  STATUS_OPPOSITION.OPPOSITION_MI,
  STATUS_OPPOSITION.TO_NOTIFY,
  STATUS_OPPOSITION.WAITING_ANSWER,
  STATUS_OPPOSITION.DECISION,
  STATUS_OPPOSITION.FINALISATION,
  STATUS_OPPOSITION.SUSPENSION,
  STATUS_OPPOSITION.NOT_ACCEPTED,
  STATUS_OPPOSITION.CLOSE
]

export const STATUS_OPPOSITION_FINAL: string[] = [
  STATUS_OPPOSITION.DONE,
  STATUS_OPPOSITION.NOT_ACCEPTED,
  STATUS_OPPOSITION.FINALISATION
]
