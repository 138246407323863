import { CheckboxField, EventType, Transaction } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface SimultaneousInscriptionProps {
    transaction: Transaction,
    onSimultaneousInscriptionUpdated: (value: boolean) => void
}
const SimultaneousInscription: FC<SimultaneousInscriptionProps> = ({ transaction, onSimultaneousInscriptionUpdated }) => {
  return (
    <>
      <CheckboxField
        className='col-12 mt-4'
        inputId='hasSimultaneousInscription'
        label={<FormattedMessage id='options_renewal_simultaneous_inscriptions_label' />}
        labelClassName='font-weight-bold'
        checked={transaction?.renewalDetails?.hasSimultaneousInscription}
        onChange={(event: EventType) => onSimultaneousInscriptionUpdated(event.target.value)}
      />
    </>
  )
}

export default SimultaneousInscription
