import React, { FC, useEffect } from 'react'
import { TransactionCountByStatus } from '@inpi-marques/components'
import { BASKET_TYPES, BASKET_TYPES_LOT_2 } from '../../constants/BasketConstants'
import { BasketFilter, BasketInterface } from '../../interfaces/BasketInterface'
import CountItem from './CountItem'
import DashboardItem from './DashboardItem'
import { RootStateOrAny, useSelector } from 'react-redux'

interface BasketCountItemsProps {
  isForDashboard?: boolean,
  currentIdBlock?: string,
  headerBasketSelected: BasketFilter,
  getTransactionCountByHeader?: (filter: BasketFilter, reload: boolean) => any
}

const BasketCountItems : FC<BasketCountItemsProps> = ({
  isForDashboard = false,
  currentIdBlock,
  headerBasketSelected,
  getTransactionCountByHeader
}) => {
  const homeCount = useSelector((state : RootStateOrAny) => state.homeCount)
  const baskets = process.env.REACT_APP_LOT_2 === 'true' ? BASKET_TYPES_LOT_2 : BASKET_TYPES

  useEffect(() => {
    if (homeCount.reload) {
      getTransactionCountByHeader && getTransactionCountByHeader(headerBasketSelected, false)
    }
  }, [])

  /**
   * Calcule la somme des éléments trouvés en les regroupant par les statuts définis dans le type de demande fourni
   * @param basket Type de corbeille (regroupant plusieurs statuts)
   * @param transactionsCounts Objet associant un statut au nombre de transaction sous ce même statut
   */
  const sumCountByBasket = (basket: BasketInterface, transactionsCounts: TransactionCountByStatus[]) => {
    let sum = 0

    transactionsCounts.filter(statusCount => basket.statusFiltres.includes(statusCount.status))
      .forEach(statusCount => {
        sum = sum + statusCount.count
      })

    return sum
  }

  return (
    <div className={`${isForDashboard ? '' : 'basket-count'} row`}>
      {
        baskets.filter(basket => !headerBasketSelected.procedures ||
            headerBasketSelected.procedures.filter(procedure => basket.forProcedures.includes(procedure)).length > 0).map((basket: BasketInterface) => (
          isForDashboard
            ? (
              <DashboardItem
                key={basket.id}
                url={`/liste/${basket.urlParam}?corbeille=${headerBasketSelected.id}`}
                label={`basket_label_${basket.id}`}
                badge={homeCount.depositCounts ? sumCountByBasket(basket, homeCount.depositCounts) : -1}
              />
            )
            : (
              <CountItem
                key={basket.id}
                url={`/liste/${basket.urlParam}?corbeille=${headerBasketSelected.id}`}
                label={`basket_label_${basket.id}`}
                badge={homeCount.depositCounts ? sumCountByBasket(basket, homeCount.depositCounts) : -1}
                className={`${currentIdBlock === basket.id ? 'active' : 'noActive'} count-item py-3 `}
              />
            )
        ))
      }
    </div>
  )
}

export default BasketCountItems
