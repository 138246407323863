import React, { FunctionComponent } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Link } from 'react-router-dom'

interface LogoProps extends WrappedComponentProps {
  width?: string,
}

const Logo: FunctionComponent<LogoProps> = ({
  width,
  intl
}) => {
  const alt = intl.formatMessage({ id: 'logo_alt' })

  return (
    <Link to='/' className='logo'>
      <img src='/Logo_RF_Marques.svg' alt={alt} className='img-fluid' width={width} />
    </Link>
  )
}

export default injectIntl(Logo)
