export const TAB_FORM_CLASSIFICATION_OVERVIEW = 'OVERVIEW'
export const TAB_FORM_CLASSIFICATION_ADVANCED_SEARCH = 'ADVANCED_SEARCH'
export const TAB_FORM_CLASSIFICATION_FREE_INPUT = 'FREE_INPUT'
export const TAB_FORM_CLASSIFICATION_IMPORT_BY_FILE = 'IMPORT_BY_FILE'
export const TAB_FORM_CLASSIFICATION_YOUR_BASKET = 'YOUR_BASKET'

export const DEPOSIT_PRODUCTS_AND_SERVICES_TAB_FORM = [
  TAB_FORM_CLASSIFICATION_OVERVIEW,
  TAB_FORM_CLASSIFICATION_ADVANCED_SEARCH,
  TAB_FORM_CLASSIFICATION_FREE_INPUT,
  TAB_FORM_CLASSIFICATION_IMPORT_BY_FILE,
  TAB_FORM_CLASSIFICATION_YOUR_BASKET
]

export const DIVISION_PRODUCTS_AND_SERVICES_TAB_FORM = [
  TAB_FORM_CLASSIFICATION_OVERVIEW,
  TAB_FORM_CLASSIFICATION_YOUR_BASKET
]
