import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import {
  DEPOSIT_TYPE_COLLECTIVE,
  EditorialBlock,
  ErrorField,
  FieldStatus,
  HelpBlock,
  SwitchField,
  Transaction
  ,
  CONTRIBUTOR_GUIDE,
  CONTRIBUTOR_GUIDED_PP,
  CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS,
  ContributorGuided
} from '@inpi-marques/components'
import Message from '../../constants/Message'

import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeBtnIntervenantId } from '../../store/transaction/transactionActions'
import ContentService from '../../services/content/ContentService'
import TransactionService from '../../services/transaction/TransactionService'
import { storeTransactionFieldStatusReset } from '../../store/fieldStatus/fieldStatusActions'

interface ContributorGuideProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

const ContributorGuide: FC<ContributorGuideProps> = ({
  transaction,
  fieldStatus
}) => {
  const valueSwitch = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode?.choiceContributor)
  const dispatch = useDispatch()
  const [editorialContent, setEditorialContent] = useState<string|null>(null)
  const [disableSwitches, setDisableSwitches] = useState<boolean>(false)

  useEffect(() => {
    dispatch(storeTransactionFieldStatusReset())
  }, [])

  useEffect(() => {
    // Texte d'information sur le mode guidé
    ContentService.getEditorialBlockByCode('INTERVENANT_GUIDE').then((res: EditorialBlock) => {
      if (res.content) {
        setEditorialContent(res.content)
      }
    })

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  useEffect(() => {
    // on met à jour la transaction lorsque le changement de celle-ci dans le state est fait
    if (transaction.id && disableSwitches) {
      TransactionService.updateTransaction(transaction.id, transaction)
        .finally(() => setDisableSwitches(false))
    }
  }, [valueSwitch])

  /**
  * On met à jour la variable valueSwitch avec le nom du bouton switch actif
 * @param btnId
 * @param restrictedQualities
 */
  const switchIntervenant = (btnId: string, restrictedQualities: string[]) => {
    if (valueSwitch !== btnId) {
      setDisableSwitches(true)
      dispatch(storeBtnIntervenantId(btnId, restrictedQualities))
    }
  }

  return (
    <div>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='contributor_form_title' /></h1>
          <span className='subtitle'><FormattedMessage id='contributor_mode_guide' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='mt-5'>
        {
          CONTRIBUTOR_GUIDE.map((contributorContent: ContributorGuided, index: number) => (
            <div key={`contributor_guide_${index}`}>
              {
                !(transaction.subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value && (contributorContent.question === CONTRIBUTOR_GUIDED_PP.question ||
                    contributorContent.question === CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.question)) &&
                      <div className='d-flex align-items-center justify-content-between mb-4 flex-wrap' key={index}>
                        <span className='ml-3 font-weight-bold'>
                          <FormattedMessage id={contributorContent.question} />
                        </span>
                        <SwitchField
                          className='ml-lg-auto'
                          divClassName='flex-row-reverse bd-highlight m-0'
                          onChange={() => switchIntervenant(contributorContent.idBtnSwitch, contributorContent.restrictedQualities)}
                          labelClassName={`ml-3 mt-1 ${valueSwitch === contributorContent.idBtnSwitch ? 'color-primary' : ''} `}
                          label={valueSwitch === contributorContent.idBtnSwitch ? Message.label_button_switch_yes : Message.label_button_switch_no}
                          value={valueSwitch === contributorContent.idBtnSwitch}
                          inputId={contributorContent.idBtnSwitch}
                          disabled={disableSwitches}
                        />
                      </div>
              }
              {
                contributorContent.infoText && !(transaction.subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value && (contributorContent.question === CONTRIBUTOR_GUIDED_PP.question ||
                      contributorContent.question === CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.question)) &&
                        <p className='font-italic bg-text-info p-3'><FormattedMessage id={contributorContent.infoText} /></p>
              }
            </div>
          ))
        }
        {
          editorialContent &&
            <HelpBlock className='mt-5'><div dangerouslySetInnerHTML={{ __html: editorialContent }} /></HelpBlock>
        }
        {
          fieldStatus.choiceContributor &&
            <div className='col-12'> <ErrorField message={fieldStatus.choiceContributor} className='fade alert alert-danger show position-relative mt-4' /></div>
        }
      </div>
    </div>
  )
}

export default ContributorGuide
