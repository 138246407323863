export const NATURE_CODES = {
  CA: 'CA', // Changement d'adresse
  CD: 'CD', // Changement de nom
  CN: 'CN', // Changement de forme juridique
  EM: 'EM', // Erreur matérielle
  IM: 'IM', // Imatriculation de société
  PE: 'PE', // Extension Polynésie française retirée,
  CM: 'CM', // Constitution de mandataire
  RM: 'RM', // Résiliation de mandataire
  MM: 'MM', // Modification de mandataire,
  TO: 'TO', // Transmission Totale de propriété,
  RT: 'RT', // Renonciation totale
  RP: 'RP' // Renonciation partielle
}
