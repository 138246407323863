import store from '../../store/store'
import { storeTransactionFieldStatusUpdate } from '../../store/fieldStatus/fieldStatusActions'
import {
  containsErrors,
  Contributor,
  CONTRIBUTOR_AGENT,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_GUIDED_PM,
  CONTRIBUTOR_GUIDED_PP,
  CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS,
  CONTRIBUTOR_LICENSEE,
  CONTRIBUTOR_OLD_LICENSEES,
  CONTRIBUTOR_OTHER_APPLICANTS,
  ContributorValidator,
  FieldStatus,
  FRMI_TYPE_EXTENSION,
  FRMI_TYPE_LICENCE_EDITION,
  INSCRIPTION_CONTRIBUTORS_TYPES,
  INSCRIPTION_TYPE_ACT_TITLE,
  INSCRIPTION_TYPE_REWARD,
  isFilled,
  NULLITY_CONTRIBUTORS_TYPES,
  PERSONNE_MORALE,
  PERSONNE_PHYSIQUE,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_RENEWAL
} from '@inpi-marques/components'
import Message from '../../constants/Message'
import ContributorService from './ContributorService'

class ContributorValidatorAndSaveStore {
  validateOnChangeStepDepositorsDeposit = () => {
    const transaction = store.getState().transaction
    let fieldStatus = {}
    const procedureType = transaction.procedureType
    if (!isFilled(transaction.depositors) || transaction.depositors?.length === 0) {
      const missingDepositorMessage = {
        [PROCEDURE_INSCRIPTION.value]: Message.contributor_depositors_required_inscription,
        [PROCEDURE_DIVISION.value]: Message.contributor_depositors_division_required,
        [PROCEDURE_FRMI.value]: Message.contributor_holders_frmi_required,
        [PROCEDURE_OFFICIAL_DOCUMENT.value]: Message.contributor_depositors_official_documents_required,
        [PROCEDURE_RENEWAL.value]: Message.contributor_depositors_renewal_required
      }
      fieldStatus = {
        depositors: missingDepositorMessage[transaction.procedureType] ?? Message.contributor_depositors_required
      }
    } else if (transaction.depositors?.length) {
      // Cas de demande d'extension à l'international, on vérifie que le titulaire a bien une autre langue si on active l'UE
      fieldStatus = ContributorValidator.validateContributor(
        transaction.depositors[0],
        transaction,
        CONTRIBUTOR_DEPOSITORS.value,
        undefined,
        procedureType === PROCEDURE_FRMI.value && transaction.subProcedureType === FRMI_TYPE_EXTENSION.value ? transaction.frmiDetails?.countries : undefined
      )
      if (containsErrors(fieldStatus) && procedureType === PROCEDURE_FRMI.value) {
        fieldStatus = { ...fieldStatus, depositors: Message.contributor_holders_frmi_missing_field }
      }
    }

    // on vérifie, si on est mode guidé, que les types des déposants correspondent au type coché
    if (transaction.depositors && transaction?.guidedMode?.activeGuidedMode && transaction?.guidedMode?.choiceContributor && procedureType === PROCEDURE_DEPOSIT.value) {
      let depositorsInError = []
      transaction.depositors.forEach((depositor) => {
        if (([CONTRIBUTOR_GUIDED_PP.idBtnSwitch, CONTRIBUTOR_GUIDED_VARIOUS_ACCOUNTS.idBtnSwitch].includes(transaction.guidedMode?.choiceContributor) && depositor.type !== PERSONNE_PHYSIQUE.value) || (transaction.guidedMode?.choiceContributor === CONTRIBUTOR_GUIDED_PM.idBtnSwitch && depositor.type !== PERSONNE_MORALE.value)) {
          depositorsInError = [...depositorsInError, depositor.email]
        }
      })
      fieldStatus = depositorsInError?.length > 0 ? { expertModeChange: `${Message.contributor_depositors_wrong_type.replace('$1', depositorsInError.join(', '))}` } : fieldStatus
    }

    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }

  validateOnChangeStepRecipient = () => {
    const transaction = store.getState().transaction
    const fieldStatus = {
      recipient: ContributorValidator.validateContributorRecipient(transaction.recipient, transaction.procedureType)
    }
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus.recipient)
  }

  validateOnChangeStepHoldersContestedMark = () => {
    const transaction = store.getState().transaction
    let fieldStatus = {
      holdersContestedMark: {}
    }
    if (!transaction.holdersContestedMark || transaction.holdersContestedMark?.length === 0) {
      fieldStatus = {
        holdersContestedMark: ContributorValidator.validateContributor({}, transaction, NULLITY_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value)
      }
    }
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus.holdersContestedMark)
  }

  validateOnChangeStepAgentDeposit = () => {
    const transaction = store.getState().transaction
    if (ContributorService.isDefaultAgentContributor(transaction.agent)) {
      transaction.agent = undefined
    }
    // inscription et non renonciation seulement
    // si plusieurs demandeurs ou plusieurs parties à l'acte mandataire non obligatoire
    const fieldStatus = {
      agent: ContributorValidator.validateAgent(transaction, transaction.agent, CONTRIBUTOR_AGENT.value)
    }
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus.agent)
  }

  validateOnChangeStepSignatoryDeposit = (hasAgentStep?: boolean) => {
    const transaction = store.getState().transaction
    const fieldStatus = {
      signatory: ContributorValidator.validateSignatory(transaction.signatory, transaction?.documents, transaction.agent, false, hasAgentStep)
    }
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus)
  }

  /**
   * Validation d'un licencier
   * @param newLicenceRequired
   */
  validateOnChangeStepLicensees = (newLicenceRequired: boolean = false): boolean => {
    const transaction = store.getState().transaction
    let fieldStatus: FieldStatus = {}

    if (newLicenceRequired && !transaction.otherApplicants?.some((otherApplicant: Contributor) => otherApplicant.code === CONTRIBUTOR_LICENSEE.value)) {
      fieldStatus = {
        contributor: Message.contributor_licensee_frmi_missing_field
      }
    } else if (transaction.subProcedureType === FRMI_TYPE_LICENCE_EDITION.value && !transaction.otherApplicants?.some((contributor: Contributor) => (contributor.state === 'EDITED' || contributor.state === 'ADDED') && contributor.code === CONTRIBUTOR_OLD_LICENSEES.value)) {
      fieldStatus = {
        contributor: Message.contributor_old_licensees_frmi_update_required
      }
    }

    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus)
  }

  /**
   * Validation d'un mandataire
   * @param newAgentRequired
   */
   validateOnChangeStepAgent = (newAgentRequired: boolean = false): boolean => {
     const transaction = store.getState().transaction
     let fieldStatus: FieldStatus = {}

     if (newAgentRequired && !transaction.agent) {
       fieldStatus = {
         contributor: Message.contributor_agent_frmi_missing_field
       }
     }

     store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

     return !containsErrors(fieldStatus)
   }

  validateOnChangeStepGuidedMode = (isActive: boolean, choiceContributor?: string) => {
    if (!isActive) {
      return true
    }

    if (!isFilled(choiceContributor)) {
      const fieldStatus = { choiceContributor: Message.contributor_choice_mode_guided_required }
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
      return false
    }

    return true
  }

  validateOnChangeStepOtherApplicants = () => {
    const transaction = store.getState().transaction
    let fieldStatus = {}
    if (!isFilled(transaction.otherApplicants) && transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value) {
      fieldStatus = {
        otherApplicants: Message.contributor_otherApplicants_required
      }
    } else if (transaction.otherApplicants?.length) {
      fieldStatus = ContributorValidator.validateContributor(transaction.otherApplicants[0], transaction, CONTRIBUTOR_OTHER_APPLICANTS.value)
    }

    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus)
  }

  validateOnChangeStepOrganisers = () => {
    const transaction = store.getState().transaction
    let fieldStatus = {}
    if (!isFilled(transaction.organisers) && transaction.subProcedureType === INSCRIPTION_TYPE_REWARD.value) {
      fieldStatus = {
        organisers: Message.contributor_organisers_required
      }
    } else if (transaction.organisers?.length) {
      fieldStatus = ContributorValidator.validateContributor(transaction.organisers[0], transaction, INSCRIPTION_CONTRIBUTORS_TYPES.ORGANISERS.value)
    }

    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus)
  }
}

export default new ContributorValidatorAndSaveStore()
