import http from '../../network/http-common'
import { toast } from 'react-toastify'
import { Configuration } from 'interfaces/ConfigurationInterface'
import { DateUtils } from '@inpi-marques/components'
import { createIntl, IntlShape } from 'react-intl'
import axios, { CancelTokenSource } from 'axios'
import Message from '../../constants/Message'

class ConfigurationService {
  intl: IntlShape
  source: CancelTokenSource

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Récupère les informations de configurations par codes
   * @param codes si non renseigné l'API renverra toutes les configurations disponibles
   */
  getConfigurationsValues = async (codes?: string[]): Promise<Configuration[]> => {
    try {
      return await http.get('/api/configurations', {
        cancelToken: this.source.token,
        params: {
          codes
        }
      })
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'common_error' }))
      return Promise.reject(error)
    }
  }

  /**
   * Vérifie que la date maximale de régularisation par faxe définie dans l'administration n'est pas dépassée
   * Elle permet de voir le bloc fax même en brouillon. Pas d'incidences sur les autres statuts
   */
  isFaxAvailable = async () => {
    const availibityEndDate: Configuration = (await this.getConfigurationsValues(['FAX_AVAILABLE_DELAY']))?.[0]
    // On reçoit une date au format dd/mm/yyyy il faut donc la transformer à la main, Date.parse ne fonctionne pas sur une date au format FR
    const transformedDate = DateUtils.transformFrFormatIntoDate(availibityEndDate.value)
    return !availibityEndDate?.value ? false : DateUtils.isBefore(DateUtils.now(), transformedDate)
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new ConfigurationService()
