import { EventType, FRMI, TextField } from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface EffectStopContentProps {
    frmi?: FRMI,
    onFieldBlur: (field: string, value: string) => void
}

const EffectStopContent: FC<EffectStopContentProps> = ({ frmi, onFieldBlur }) => {
  const [officeName, setOfficeName] = useState<string>(frmi?.officeName ?? '')

  return (
    <div>
      {/* Office qui envoie la notification */}
      <TextField
        classNameFormGroup='col-6'
        inputId='officeName'
        value={officeName}
        label={<FormattedMessage id='frmi_effect_stop_office_name_label' />}
        onChange={(event: EventType) => setOfficeName(event.target.value)}
        onBlur={(event: EventType) => onFieldBlur(event.target.name, officeName)}
      />
      {/* Demande de radiation de l'enregistrement international (?) */}
    </div>
  )
}

export default EffectStopContent
