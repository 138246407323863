import React, { FC, useState } from 'react'
import {
  CardBlock,
  DOCUMENT_FORMATS,
  EventType,
  FieldStatus,
  FileBrowserField,
  FileButton,
  ProductClass,
  DownloadLink,
  ProductService,
  ProductAndServicesAccordion,
  HelpBlock
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import ProductsService from 'services/deposit/ProductsService'
import Message from 'constants/Message'
import { toast } from 'react-toastify'

interface ProductAndServicesFileImportProps {
  productClasses: ProductClass[],
  onProductClassesEdited: (newArray: ProductClass[]) => void,
  procedureType?: string
}

/**
 * Import des produits et service via un fichier CSV
 */
const ProductAndServicesFileImport: FC<ProductAndServicesFileImportProps> = ({
  productClasses,
  onProductClassesEdited,
  procedureType
}) => {
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const [file, setFile] = useState<File>()

  const onFileChanged = async (event: EventType): Promise<void> => {
    const { value } = event.target

    if (value) {
      setFile(value)
      setFieldStatus({})
      const products: ProductClass[] = await ProductsService.importCsvFile(value)
      toast.success(Message.products_and_services_file_upload_success)
      onProductClassesEdited(ProductService.getUpdateSelectedListFromList(productClasses, products, true))
      setFile(undefined)
    } else {
      setFieldStatus({ file_missing: Message.products_and_services_file_missing })
    }
  }

  const importedProducts = ProductService.getProductsByOrigin(productClasses, 'import')

  return (
    <div className='mt-5'>
      <div className='d-flex justify-content-between'>
        <CardBlock className='col-10 col-md-5 border-gris products-and-services__import' bodyClassName='justify-content-center'>
          <FileBrowserField
            inputId='product_and_services_file'
            label={<FormattedMessage id='product_and_services_file_label' />}
            acceptApplication={DOCUMENT_FORMATS.CSV}
            maxNumberFile={1}
            buttonLabel={<FileButton label={<FormattedMessage id='form_file_csv_placeholder' />} />}
            onChange={onFileChanged}
            value={file && [file]}
            fieldStatus={fieldStatus}
            nameFieldStatus='file_missing'
            errorMessage={Message.product_and_services_import_file_error}
            informationDoc
          />
        </CardBlock>
        <HelpBlock className='ml-3'>
          <FormattedMessage id='product_and_services_help' />
          <DownloadLink
            label={<FormattedMessage id='product_and_services_help_link' />}
            name={Message.product_and_services_example_file}
            onClick={() => ProductsService.getExampleFile()}
            classname='mt-3'
          />
        </HelpBlock>
      </div>

      {importedProducts.length > 0 && <ProductAndServicesAccordion procedureType={procedureType} className='mt-5' productClasses={importedProducts} displayHelpBlock />}
    </div>
  )
}

export default ProductAndServicesFileImport
