import React, { FC, useEffect, useState } from 'react'
import { CheckboxField, LinkedTransaction, ListLoader, Mandatary, NoResult, ROWS_25, Table, Transaction, TransactionService } from '@inpi-marques/components'
import InscriptionService from 'services/inscription/InscriptionService'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import AgentAttachmentTableBody from './AgentAttachmentTableBody'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateInscription } from 'store/transaction/transactionActions'
import { INSCRIPTION_ATTACHMENT_TABLE_TITLES } from 'constants/TransactionListTableConstants'

interface AgentAttachmentProps {
    transaction: Transaction
}

const AgentAttachment: FC<AgentAttachmentProps> = ({ transaction }) => {
  const dispatch = useDispatch()

  const [attachments, setAttachments] = useState<Transaction[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // Gestion de pagination
  const [page, setPage] = useState<number>(0)
  const nbItemsPerPage: number = ROWS_25.value

  useEffect(() => {
    InscriptionService.getAttachments(transaction).then((response: Transaction[]) => {
      setAttachments(response)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  /**
   * A la selection d'une transaction
   * @param selectedAttachment
   */
  const onSelect = (selectedAttachment: Transaction): void => {
    const mandatary: Mandatary = transaction.inscription?.mandatary ?? {}

    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      mandatary: {
        ...mandatary,
        attachments: mandatary.attachments?.some((attachment: LinkedTransaction) => attachment.numNat === selectedAttachment.numNat)
          ? mandatary.attachments?.filter((item: LinkedTransaction) => item.numNat !== selectedAttachment.numNat) ?? [] : [...mandatary.attachments ?? [], { numNat: selectedAttachment.numNat ?? '', type: selectedAttachment.procedureType ?? '' }]
      }
    }))
  }

  /**
   * Au clique sur le checbox de selection globale
   */
  const onSelectAll = (): void => {
    const mandatary: Mandatary = transaction.inscription?.mandatary ?? {}
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      mandatary: {
        ...mandatary,
        attachments: mandatary.attachments && mandatary.attachments.length === attachments.length ? [] : attachments.map((attachment: Transaction) => ({ numNat: attachment.numNat ?? '', type: attachment.procedureType ?? '' }))
      }
    }))
  }

  const paginatedAttachments = TransactionService.getPaginatedTransactions(attachments, nbItemsPerPage)

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='inscription_agent_attachment_title' /></h1>
          <span className='subtitle'><FormattedMessage id='inscription_agent_attachment_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      {
        isLoading ? (
          <ListLoader
            isLittleSkeleton
            numberOfLines={2}
            noBottomButtons
            className='m-0'
          />
        )
          : (
            paginatedAttachments && paginatedAttachments.length > 0 ? (
              <>
                <h2 className='color-primary mb-5'><FormattedMessage id='inscription_attachment_table_title' /></h2>
                <CheckboxField
                  inputId='select-all-attachments'
                  className='mb-0'
                  label={<FormattedMessage id='inscription_agent_attachment_select_all' />}
                  onChange={onSelectAll}
                  checked={transaction.inscription?.mandatary?.attachments && transaction.inscription.mandatary.attachments.length === attachments.length}
                />
                <Table
                  tableTitle={INSCRIPTION_ATTACHMENT_TABLE_TITLES}
                  tableClassName='table mb-3 not-fixed mb-0'
                  colorHover
                  actualPage={page + 1}
                  onChangePagination={(value) => setPage(value - 1)}
                  showPagination
                  totalPage={paginatedAttachments.length}
                >
                  <AgentAttachmentTableBody transactionAttachments={transaction.inscription?.mandatary?.attachments ?? []} attachments={paginatedAttachments[page]} onSelect={onSelect} />
                </Table>
              </>
            ) : <NoResult />)
      }
      <div className='row justify-content-between' />
    </>
  )
}

export default AgentAttachment
