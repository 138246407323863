import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AuthService from '../../services/user/AuthService'
import { storeLogin, storeLogout } from '../../store/user/userActions'

const LoginEts = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // On arrive du portail procédure avec un jwt du sso dans l'url
    AuthService.login(props.token)
      .then((user) => {
        const currentUser = {
          ...user,
          tokenETS: props.token
        }
        dispatch(storeLogin(currentUser))
      })
      .catch(() => {
        dispatch(storeLogout())
        props.history.push('/login')
      })
  }, [props, dispatch])

  return null
}

export default withRouter(LoginEts)
