import React, { FC } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import ContentService from 'services/content/ContentService'
import {
  DATE_ISO,
  DateField,
  DateUtils,
  DOCUMENT_FORMATS,
  DOCUMENT_PRIORITY,
  downloadFile,
  EventType,
  FieldStatus, FileBrowserField, FileButton,
  Priority,
  TextField, Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import CountrySelectField from '@inpi-marques/components/src/contributors/address/CountrySelectField'
import DocumentService from '../../services/document/DocumentService'

interface PriorityFieldsProps extends WrappedComponentProps {
  transaction: Transaction,
  priority?: Priority,
  onChange: (event: EventType) => void,
  fieldStatus?: FieldStatus | any,
  onDocumentChanged: (event: EventType, documentType: string) => void,
  onDocumentDeleted: (documentToDelete: TransactionDocument) => void,
  documents: TransactionDocument[],
  setPriority: (newPriority: Priority) => void,
}

const PriorityFields: FC<PriorityFieldsProps> = (
  {
    transaction,
    priority,
    onChange,
    fieldStatus,
    intl,
    onDocumentDeleted,
    onDocumentChanged,
    documents,
    setPriority
  }) => {
  const priorityOnDocumentDeleted = (event: EventType) => {
    const { value } = event.target

    onDocumentDeleted(value as TransactionDocument)
    const newPriority = {
      ...priority,
      documentNames: priority?.documentNames?.filter((docName) => docName !== value.internalName)
    }
    setPriority(newPriority)
  }

  const onPriorityDateChanged = (event: EventType) => {
    const { value } = event.target

    const newPriority = {
      ...priority,
      originalDepositDate: DateUtils.formatToBeginOfDay(value)
    }
    setPriority(newPriority)
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <CountrySelectField
            inputId='countryOrOrganization'
            label={intl.formatMessage({ id: 'form_office_label' })}
            value={priority?.countryOrOrganization}
            onChange={onChange}
            fieldStatus={fieldStatus}
            required
            placeholder={intl.formatMessage({ id: 'form_office_placeholder' })}
            getCountries={ContentService.getCountries}
            isPriority
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextField
            inputId='originalDepositNumber'
            required
            value={priority?.originalDepositNumber}
            label={<FormattedMessage id='form_deposit_number' />}
            onChange={onChange}
            fieldStatus={fieldStatus}
            nameFieldStatus='originalDepositNumber'
          />
        </div>
        <div className='col-12 col-md-6'>
          <DateField
            inputId='originalDepositDate'
            required
            value={DateUtils.formateDateToFormat(priority?.originalDepositDate, DATE_ISO) ?? ''}
            label={<FormattedMessage id='form_deposit_date' />}
            onChange={onPriorityDateChanged}
            fieldStatus={fieldStatus}
            nameFieldStatus='originalDepositDate'
            classNameLabel='col-12 p-0'
            maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) ?? undefined}
          />
        </div>
      </div>
      <div className='row'>
        {
          DOCUMENT_PRIORITY.map((docPriority, index) => (
            <div className='col-12 col-md-6' key={index}>
              <FileBrowserField
                classNameButton='d-block one-line-ellipsis text-left'
                inputId={docPriority.id}
                label={<FormattedMessage id={`form_${docPriority.id}`} />}
                buttonLabel={<FileButton />}
                value={documents?.filter((document: TransactionDocument) => !!document?.internalName && priority?.documentNames?.find(doc => doc === document.internalName) && docPriority.value === document.type)}
                onChange={(event) => onDocumentChanged(event, docPriority.value)}
                onDelete={priorityOnDocumentDeleted}
                acceptApplication={DOCUMENT_FORMATS.PDF}
                fieldStatus={fieldStatus}
                informationDoc
                handleDownload={handleDownload}
                maxNumberFile={1}
              />
            </div>
          )
          )
        }
      </div>
    </>
  )
}

export default injectIntl(PriorityFields)
