import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { HelpInfo } from '../../interfaces/HelpInterfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

type HelpCardProps = {
    helpInfo: HelpInfo
};

const HelpCard: FC<HelpCardProps> = ({ helpInfo }) => {
  return (
    <Card className='shadow'>
      <Card.Header>
        {helpInfo.type}
      </Card.Header>
      <Card.Body className='p-0'>
        {
          helpInfo.items.map((item, index) =>
            <div key={index}>
              <hr className='m-0' />
              <a href={item.url} target='_blank' rel='noopener noreferrer' className='d-flex p-3 text-dark'>
                <span className='mr-md-2 text-danger'>
                  <FontAwesomeIcon icon={faFilePdf} />
                </span>
                {item.label}
              </a>
            </div>
          )
        }
      </Card.Body>
    </Card>
  )
}

export default HelpCard
