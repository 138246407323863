import React, { FC } from 'react'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { LinkedTransaction, LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION, PreviousTransactions, PROCEDURE_DIVISION, Transaction } from '@inpi-marques/components'
import ProtectionExtensions from '../../../protectionExtensions/ProtectionExtensions'
import StoreService from 'services/StoreService'
import { FormattedMessage } from 'react-intl'
import InternationalExtensionsOptions from './InternationalExtensionsOptions'

interface OptionsProps {
  transaction: Transaction
}

const Options: FC<OptionsProps> = ({ transaction }) => {
  /**
   * A la mise à jour des transactions liées
   * @param linkedTransactions
   */
  const onLinkedTransactionChanged = (linkedTransactions: LinkedTransaction[]): void => {
    StoreService.changeStore(
      {
        ...transaction,
        deposit: {
          ...transaction.deposit,
          linkedTransactions: [...linkedTransactions]
        }
      }
    )
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='renewal_demand_details' /></h1>
          <span className='subtitle'><FormattedMessage id='renewal_demand_details_subtitle' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row'>
        <div className='col-9'>
          <ProtectionExtensions
            transaction={transaction}
          />
        </div>
      </div>
      <InternationalExtensionsOptions
        transaction={transaction}
        numNat={transaction.deposit?.numNatToTransformOrDivide}
        titlesIds={transaction.deposit?.numNatToTransformOrDivide ? [transaction.deposit?.numNatToTransformOrDivide] : []}
      />
      <div>
        <PreviousTransactions
          linkedTransactions={(transaction?.procedureType === PROCEDURE_DIVISION.value ? transaction.deposit?.linkedTransactions : transaction.renewalDetails?.linkedTransactions) || []}
          onLinkedTransactionChanged={onLinkedTransactionChanged}
          transaction={transaction}
          isEditable
          linkedTransactionType={LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION.value}
        />
      </div>
    </>
  )
}

export default Options
