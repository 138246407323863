import React, { FC, useState } from 'react'
import { EventType, InlineTextField, SubmitButton } from '@inpi-marques/components'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router'

const SearchField: FC = () => {
  const [search, setSearch] = useState<string>('')
  const intl = useIntl()
  const history = useHistory()

  return (
    <div className='search-field'>
      <form className='mr-3'>
        <InlineTextField
          inputId='searchHeader'
          onChange={(e: EventType) => setSearch(e.target.value)}
          value={search}
          placeholder={intl.formatMessage({ id: 'header_search_placeholder' })}
        >
          <SubmitButton className='btn-primary' onClick={() => history.push(`/recherche?search=${search}`)}>
            <FontAwesomeIcon icon={faSearch} />
          </SubmitButton>
        </InlineTextField>
      </form>
    </div>
  )
}

export default SearchField
