import http from '../../network/http-common'
import { toast } from 'react-toastify'
import {
  ETSAccountNavInterface,
  ETSAccountRequest,
  ETSAccountRequestCredentials
} from '../../interfaces/ETSAccountInterface'
import { FoUserResponse } from '../../interfaces/User'
import { Transaction } from '@inpi-marques/components'
/* global FormData */

class ETSAccountService {
  /**
   *
   * @param ETSAccountCredentials
   */
  postETSAccountRequestCredentials = async (ETSAccountCredentials: ETSAccountRequestCredentials) => {
    try {
      return await http.post('/api/ets-accounts/credentials', ETSAccountCredentials)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

    /**
     * Permet de vérifier si la transaction concernée par les paramètres existent, si oui, on fait le demande de modification sur les comptes ETS
     * @param ETSAccountRequestData
     * @param ETSAccountRequestNavItem
     */
    postETSAccountRequestDeposit = async (ETSAccountRequestData: ETSAccountRequest, ETSAccountRequestNavItem: ETSAccountNavInterface) => {
      const formData = new FormData()
      ETSAccountRequestData.procedure && formData.append('procedure', ETSAccountRequestData.procedure)
      formData.append('numNat', ETSAccountRequestData.numNat)
      ETSAccountRequestData.contestedRequestNumNat && formData.append('contestedRequestNumNat', ETSAccountRequestData.contestedRequestNumNat)
      if (ETSAccountRequestData.manageableQuality) {
        formData.append('manageableQuality', JSON.stringify(ETSAccountRequestData.manageableQuality))
      }
      if (ETSAccountRequestData.inscriptionNumber) {
        formData.append('inscriptionNumber', ETSAccountRequestData.inscriptionNumber)
      }
      if (ETSAccountRequestData.powerFile) {
        formData.append('powerFile', ETSAccountRequestData.powerFile.file, ETSAccountRequestData.powerFile.name)
      }
      if (ETSAccountRequestData.proofFile) {
        formData.append('proofFile', ETSAccountRequestData.proofFile.file, ETSAccountRequestData.proofFile.name)
      }
      if (ETSAccountRequestData.consentNotificationByEmail !== null) {
        formData.append('consentNotificationByEmail', ETSAccountRequestData.consentNotificationByEmail)
      }

      formData.append('action', ETSAccountRequestNavItem.action)
      ETSAccountRequestData.code && formData.append('code', ETSAccountRequestData.code)

      try {
        return await http.post('/api/ets-accounts', formData)
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

  /**
   * Permet de récupérer la liste des utilisateurs d'une PM
   */
  getETSAccountForPM = async () : Promise<FoUserResponse[]> => {
    try {
      return await http.get('/api/ets-accounts')
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * Permet de récupérer la liste des transactions associées à un compte ETS
   * @param email
   */
  getETSAccountTransactions = async (email: string): Promise<Transaction[]> => {
    try {
      return await http.get(`/api/ets-accounts/${email}/transactions`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Permet de modifier un compte ETS sur une liste de transaction
   * @param email
   * @param idTransactions
   */
  assignTransaction = async (email: string, idTransactions: string[]) : Promise<FoUserResponse[]> => {
    try {
      return await http.put(`/api/ets-accounts/${email}/transactions`, {
        idTransactions: idTransactions
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new ETSAccountService()
