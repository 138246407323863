import React, { FC, useEffect, useState } from 'react'
import {
  CountrySelectField,
  DATE_ISO,
  DateField,
  DateUtils,
  EventType,
  FieldStatus,
  FRMI,
  FRMI_TYPE_POST_DESIGNATION_REGISTER,
  ProductClass,
  ProductService,
  SelectField,
  SelectOption,
  Seniority,
  TextField,
  Title
} from '@inpi-marques/components'
import ContentService from '../../../../services/content/ContentService'
import { FormattedMessage, useIntl } from 'react-intl'
import ProductAndServicesFreeInput from 'component/deposit/form/productsAndServices/ProductAndServicesFreeinput'

interface SeniorityFieldsProps {
    frmiDetails?: FRMI,
    seniority?: Seniority,
    onChange: (event: EventType) => void,
    fieldStatus?: FieldStatus,
    setSeniority: (newSeniority: Seniority) => void,
    subProcedureType?: string
}

const SeniorityFields: FC<SeniorityFieldsProps> = ({ frmiDetails, seniority, onChange, fieldStatus, setSeniority, subProcedureType }) => {
  const intl = useIntl()

  const [registrationNumberOptions, setRegistrationNumberOptions] = useState<SelectOption[]>([])
  const [newProductClasses, setNewProductClasses] = useState<ProductClass[]>(seniority?.productAndServices || [])

  useEffect(() => {
    setSeniority({ ...seniority, productAndServices: newProductClasses })
  }, [newProductClasses])

  useEffect(() => {
    // Si la france est choisie, on ne peut choisir que les numéros des titres renseignés au début
    if (seniority?.countryCode === 'FR' && subProcedureType !== FRMI_TYPE_POST_DESIGNATION_REGISTER.value && frmiDetails?.titles) {
      const titleOptions: SelectOption[] = []
      for (const title of frmiDetails?.titles) {
        titleOptions.push({ label: title.numNat + (title.text ? ' - ' + title.text : ''), value: title.numNat ?? '' })
      }
      setRegistrationNumberOptions(titleOptions)
    } else {
      setRegistrationNumberOptions([])
    }
  }, [seniority?.countryCode])

  const onSeniorityDateChanged = (event: EventType) => {
    const { name, value } = event.target

    const newSeniority = {
      ...seniority,
      [name]: DateUtils.formatToBeginOfDay(value)
    }
    setSeniority(newSeniority)
  }

  const onRegistrationNumberSelectChanged = (event: EventType): void => {
    const { value } = event.target

    setSeniority({
      ...seniority,
      registrationNumber: value,
      registrationDate: DateUtils.formateDateToFormat(frmiDetails?.titles && frmiDetails?.titles.find(item => item.numNat === value)?.depositDate) ?? ''
    })
    const title: Title|undefined = frmiDetails?.titles?.find((item: Title) => item.numNat === value)
    title && setNewProductClasses(ProductService.getCurrentVersion(title.productsAndServicesVersions)?.productClasses ?? [])
  }
  return (
    <div className='row'>
      <div className='col-12 col-md-6'>
        <CountrySelectField
          inputId='countryCode'
          label={intl.formatMessage({ id: 'form_office_label' })}
          value={seniority?.countryCode}
          onChange={onChange}
          fieldStatus={fieldStatus}
          required
          placeholder={intl.formatMessage({ id: 'form_office_placeholder' })}
          getCountries={ContentService.getCountries}
          isPriority
        />
      </div>
      <div className='col-12 col-md-6'>
        {
          (registrationNumberOptions?.length > 0)
            ? (
              <SelectField
                classNameFormGroup='mb-0'
                inputId='type'
                required
                options={registrationNumberOptions}
                value={seniority?.registrationNumber}
                placeholder={intl.formatMessage({ id: 'transaction_frmi_french_registration_number_type_placeholder' })}
                onChange={onRegistrationNumberSelectChanged}
                fieldStatus={fieldStatus}
                label={<FormattedMessage id='form_deposit_number' />}
                nameFieldStatus='registrationNumber'
              />
            )
            : (
              <TextField
                inputId='registrationNumber'
                required
                value={seniority?.registrationNumber}
                label={<FormattedMessage id='form_deposit_number' />}
                onChange={onChange}
                fieldStatus={fieldStatus}
                nameFieldStatus='registrationNumber'
              />
            )
        }
      </div>
      <div className='col-12 col-md-6'>
        <DateField
          inputId='registrationDate'
          required
          value={DateUtils.formateDateToFormat(seniority?.registrationDate, DATE_ISO) ?? ''}
          label={<FormattedMessage id='form_deposit_date' />}
          onChange={onSeniorityDateChanged}
          fieldStatus={fieldStatus}
          nameFieldStatus='registrationDate'
          classNameLabel='col-12 p-0'
          maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) ?? undefined}
        />
      </div>
      <div className='col-12 col-md-6'>
        <DateField
          inputId='registeredDate'
          value={DateUtils.formateDateToFormat(seniority?.registeredDate, DATE_ISO) ?? ''}
          label={<FormattedMessage id='seniority_registered_date' />}
          onChange={onSeniorityDateChanged}
          fieldStatus={fieldStatus}
          nameFieldStatus='registeredDate'
          classNameLabel='col-12 p-0'
          maxDate={DateUtils.formateDateToFormat(new Date(), DATE_ISO) ?? undefined}
        />
      </div>
      <div className='col-12'>
        <ProductAndServicesFreeInput
          productClasses={newProductClasses}
          onProductClassesEdited={(newClasses: ProductClass[]) => setNewProductClasses(newClasses)}
          isEditable
          asLink
        />
      </div>
    </div>
  )
}

export default SeniorityFields
