import { FoUserResponse } from '../../interfaces/User'

export const LOG_OUT = 'LOG_OUT'
export const LOG_IN = 'LOG_IN'
export const UPDATE_USER = 'UPDATE_USER'

export interface UserState {
  token: string|null,
  user: FoUserResponse|null,
  tokenETS: string|null
}

export interface LogAction {
  type: string,
  user: UserState,
}
