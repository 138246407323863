import React, { FC, useState } from 'react'
import {
  DOCUMENT_FORMATS,
  DOCUMENT_TYPES,
  downloadFile,
  EventType,
  FileBrowserField,
  ModalComponent,
  NotificationOMPI,
  SubmitButton,
  TextArea,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import DocumentService from '../../../services/document/DocumentService'
import { toast } from 'react-toastify'
import NotificationOMPIService from '../../../services/transaction/NotificationOMPIService'

interface NotificationOMPIAnswerFormProps {
  transaction: Transaction,
  initialNotification: NotificationOMPI,
  index: number,
  setReload?: () => void
}

/**
 * Composant qui contient le bloc pour répondre à la notification OMPI
 * Permet d'ajouter des documents et/ou un commentaire pour répondre à la notification
 *
 * @param transaction - Objet transaction
 * @param initialNotification - Notification initiale avant modification
 * @param index - index de la notification
 * @param setReload - Fonction pour modifier si la transaction doit être rechargée
 * @constructor
 */
const NotificationOMPIAnswerForm: FC<NotificationOMPIAnswerFormProps> = ({
  transaction,
  initialNotification,
  index,
  setReload
}) => {
  const [notification, setNotification] = useState<NotificationOMPI>(initialNotification)
  const [showSendModal, setShowSendModal] = useState(false)
  const [documents, setDocuments] = useState<TransactionDocument[]>([])
  const intl = useIntl()

  /**
   * Gère l'envoi de la réponse à la notification
   */
  const handleSend = async () => {
    // Mise à jour des documents
    let updatedDocuments = [...documents]
    for (const document of documents) {
      const updatedDocument: TransactionDocument | null = await DocumentService.createDocument(document, transaction.id)
      updatedDocuments = updatedDocuments.map(d => {
        if (d.name === updatedDocument?.name) {
          return { ...d, internalName: updatedDocument.internalName }
        }

        return d
      })
    }

    setDocuments(updatedDocuments)

    const answerNotificationOMPI = {
      answerComment: notification.irregularitiesAnswerComment,
      supportingDocuments: updatedDocuments.map(d => d.internalName)
    }

    // Réponse à la notification
    return transaction.id && notification.id && NotificationOMPIService.answerNotificationOMPI(transaction.id, notification.id, answerNotificationOMPI).then((newNotification: NotificationOMPI) => {
      toast.success(intl.formatMessage({ id: 'overview_transaction_notification_success_answer' }))
      setNotification(newNotification)
      setReload && setReload()
      setShowSendModal(false)
    })
  }

  const handleDownload = (document: TransactionDocument) => {
    return transaction.id && document.internalName && DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => {
      downloadFile(data, document.name)
    })
  }

  const onDocumentChange = (event: EventType) => {
    const { value } = event.target

    const newFile: TransactionDocument = {
      name: value.name,
      internalName: '',
      format: value.type,
      type: DOCUMENT_TYPES.NOTIFICATION,
      file: value,
      originEmail: ''
    }

    setDocuments([...documents, newFile])
  }

  const onDocumentDelete = (event: EventType) => {
    const updatedDocuments = [...documents]
    setDocuments(updatedDocuments.filter(doc => doc.name !== event.target.value.name))
  }

  const onNotificationChange = (event: EventType) => {
    const { name, value } = event.target
    setNotification({ ...notification, [name]: value })
  }

  return (
    <form className='is-validated mt-3'>
      <div className='row m-0 pl-3'>
        <h3><FormattedMessage id='overview_transaction_notification_answer' /></h3>
      </div>
      <div className='d-flex flex-column justify-content-center border p-2 mb-2'>
        <div className=' d-flex justify-content-center'>
          <FileBrowserField
            inputId={`documents-${index}`}
            className='w-50 mb-1 mt-2'
            classNameLabel='w-100'
            classNameFormGroup='col-12'
            buttonLabel={
              <div className='btn btn-outline-primary text-unset col-12'>
                <FormattedMessage id='overview_transaction_notification_documents' />
              </div>
            }
            acceptApplication={DOCUMENT_FORMATS.PDF}
            value={documents}
            onChange={onDocumentChange}
            onDelete={onDocumentDelete}
            handleDownload={handleDownload}
          />
        </div>
        <div className='mt-3'>
          <TextArea
            classNameFormGroup='col-12'
            inputId='irregularitiesAnswerComment'
            value={notification.irregularitiesAnswerComment}
            label={<FormattedMessage id='overview_transaction_notification_ompi_answer_label' />}
            onChange={onNotificationChange}
          />
        </div>
        <div className='d-flex justify-content-around pt-2 mb-2'>
          <SubmitButton className='btn btn-primary' disabled={!documents.length && !notification.irregularitiesAnswerComment?.length} onClick={() => setShowSendModal(true)}>
            <FormattedMessage id='overview_transaction_notification_answer_button' />
          </SubmitButton>
        </div>
      </div>

      <ModalComponent
        title={<FormattedMessage id='overview_transaction_notification_send_title' />}
        customContent={() => <div><FormattedMessage id='overview_transaction_notification_modal_send_message' /> ?</div>}
        handleClose={() => setShowSendModal(false)}
        show={showSendModal}
        onClick={handleSend}
      />
    </form>
  )
}

export default NotificationOMPIAnswerForm
