import { ContestedRequest } from '../interfaces/opposition/ContestedRequest'
import { FieldStatus } from '../form/FormInterfaces'
import { isFilled, messageIsFilled } from '../utils/validationUtils'
import { DOCUMENT_TYPES } from '../constants/DocumentConstants'
import { ProductClass } from '../interfaces/deposit/Deposit'
import { PRODUCT_STATUS } from '../constants/DepositConstants'
import { TransactionDocument } from '../interfaces/Transaction'
import Message from '../constants/Message'
import BrandNumberValidator from './BrandNumberValidator'
import { PROCEDURE_NULLITY, PROCEDURE_OPPOSITION, PROCEDURE_REVOCATION, ProductService } from '../index'

class ContestedBrandValidator {
  validateContestedBrand = (contestedRequest: ContestedRequest, documents: TransactionDocument[], procedureType: string, isFromBo = false) : FieldStatus => {
    const fieldStatus : FieldStatus = {}
    if (!isFilled(contestedRequest?.numnat)) {
      fieldStatus.numnat = Message.required_field
    } else if (!contestedRequest?.isInternationalBrand && isFilled(contestedRequest?.depositDate)) {
      fieldStatus.numnat = BrandNumberValidator.validateBrandNumber(contestedRequest?.depositDate, contestedRequest?.numnat)
    }
    fieldStatus.depositDate = messageIsFilled(contestedRequest?.depositDate)
    if (procedureType === PROCEDURE_OPPOSITION.value) {
      fieldStatus.publicationNumber = messageIsFilled(contestedRequest?.publicationNumber)
    }
    fieldStatus.brandType = messageIsFilled(contestedRequest?.brandType)
    if (!isFromBo && !documents.find((document) => document.type === DOCUMENT_TYPES.CONTESTED_BRAND_COPY)) {
      fieldStatus.copyRequest = Message.required_field
    }
    if (!contestedRequest?.isTotalProductsAndServices) {
      // Si le demande d'opposition ne porte pas sur l'intégralité des produits et services,
      // Il faut au moins un produit qui soit ajouté, modifié ou supprimé dans la dernière version de P&S acceptée
      if (contestedRequest?.productsAndServicesVersions && contestedRequest?.productsAndServicesVersions?.length) {
        const currentVersion = ProductService.getCurrentVersion(contestedRequest?.productsAndServicesVersions)
        if (!currentVersion?.productClasses.some((productClass : ProductClass) =>
          productClass.products.some((product) => product.status === PRODUCT_STATUS.ADDED || product.status === PRODUCT_STATUS.DELETED))) {
          if (contestedRequest.productsAndServicesVersions.length === 1) {
            // Cas de l'enregistrement de la marque contestée côté FO
            fieldStatus.products = Message.request_edit_products_and_services_required
          } else {
            // Cas de l'ajout/modification de version côté BO
            fieldStatus.products = Message.request_edit_products_and_services_version_required
          }
        }
      } else {
        fieldStatus.products = Message.request_edit_products_and_services_required
      }
    }
    if (contestedRequest?.isPriorityClaimed) {
      fieldStatus.countryOrOrganization = messageIsFilled(contestedRequest?.priority?.countryOrOrganization)
      fieldStatus.originalDepositDate = messageIsFilled(contestedRequest?.priority?.originalDepositDate)
    }

    if (!contestedRequest?.isInternationalBrand && [PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value].includes(procedureType)) {
      fieldStatus.registrationDate = messageIsFilled(contestedRequest?.registrationDate)
    }

    return fieldStatus
  }
}

export default new ContestedBrandValidator()
