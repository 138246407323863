import { FC, ReactElement, useContext } from 'react'
import { PermissionContext } from './PermissionContext'

interface PermissionProps {
    /** Nom de la permission à vérifier (Nom seul ou tableau de nom) */
    name?: string|string[],
    /** Fonction permettant de vérifier des droits supplémentaires */
    guard?: () => boolean,
    /** Élément à afficher si les permissions de l'utilisateur le permettent */
    yes?: () => ReactElement|null,
    /** Élément à afficher si l'utilisateur ne possède pas cette permission */
    no?: () => ReactElement
}

const Permission: FC<PermissionProps> = ({
  name,
  guard = () => true,
  yes = () => null,
  no = () => null
}) => {
  const userPermissions = useContext<string[]>(PermissionContext)

  let hasPermission = true
  if (name) {
    if (typeof name === 'string') {
      name = [name]
    }
    hasPermission = !name.some((permissionName) => !userPermissions?.includes(permissionName))
  }

  return (hasPermission && guard())
    ? yes()
    : no()
}

export default Permission
