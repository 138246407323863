import { Payment } from '@inpi-marques/components/src/interfaces/deposit/Deposit'
import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import axios, { CancelTokenSource } from 'axios'

class NullityService {
    intl: IntlShape
    source: CancelTokenSource

    constructor () {
      this.intl = createIntl({ locale: 'fr', messages: Message })
      this.source = axios.CancelToken.source()
    }

    /**
     * Récupère le récapitulatif de paiement d'une nullité.
     * @returns
     */
    getPaiement = async (id: string): Promise<Payment> => {
      try {
        const result: Payment = await http.get(`/api/nullities/${id}/paiements`)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    /**
     * Annule une requête en attente
     */
    cancelRequest = () => {
      this.source.cancel()
      this.source = axios.CancelToken.source()
    }
}

export default new NullityService()
