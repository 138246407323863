import store from '../../../../../store/store'
import { Brand, containsErrors, FieldStatus, FRMI, messageIsFilled, TransactionDocument } from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'

class ColorsClaimedValidator {
  getValidatedFieldStatus = (documents: TransactionDocument[], brand?: Brand): FieldStatus => {
    const frmiFieldStatus: FieldStatus = {}

    if (brand?.hasColors && brand?.isColorDistinctive) {
      frmiFieldStatus.colors = messageIsFilled(brand.colors)
    }

    return frmiFieldStatus
  }

  validateOnChangeStep = (documents: TransactionDocument[], frmi?: FRMI) => {
    const fieldStatus: FieldStatus = this.getValidatedFieldStatus(documents, frmi?.brand)
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new ColorsClaimedValidator()
