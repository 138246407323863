import {
  DOCUMENT_FORMATS, DOCUMENT_LIMITS, DOCUMENT_TYPES, downloadFile, EventType, FieldStatus,
  FileBrowserField,
  FileButton,
  HelpBlock,
  Transaction, TransactionDocument
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import TransactionService from '../../../../services/transaction/TransactionService'
import { storeTransactionFieldStatusUpdate } from '../../../../store/fieldStatus/fieldStatusActions'
import { useDispatch } from 'react-redux'
import DocumentService from '../../../../services/document/DocumentService'

interface StatementOfMeansProps {
  onDocumentChanged: (event: EventType, type: string) => void,
  onDocumentDeleted: (event: EventType)=> void,
  transaction: Transaction,
  fieldStatus: FieldStatus,
  documents: TransactionDocument[],
  required?: boolean,
}

const StatementOfMeans: FC<StatementOfMeansProps> = ({
  transaction,
  onDocumentChanged,
  onDocumentDeleted,
  fieldStatus,
  documents,
  required = false
}) => {
  const dispatch = useDispatch()
  const fileType: string = DOCUMENT_TYPES.OPPOSITION_STATEMENT_MEANS
  const oppositionFile: TransactionDocument | undefined = documents && TransactionService.getDocumentByType(documents, fileType)

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  return (
    <div>
      <div className='row mb-4 justify-content-between form-header'>
        <header className='col-8 mb-4'>
          <h1>
            <FormattedMessage id='statement_of_means_title' />
          </h1>
          <span className='subtitle'>
            <FormattedMessage id={`${transaction.procedureType?.toLowerCase()}_statement_of_means_description`} />
          </span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='border p-4 col-4'>
        <FileBrowserField
          inputId='attachment'
          label={<FormattedMessage id='statement_of_means_file' />}
          acceptApplication={DOCUMENT_FORMATS.PDF}
          buttonLabel={<FileButton />}
          onChange={(event: EventType) => onDocumentChanged(event, fileType)}
          nameFieldStatus='document'
          fieldStatus={fieldStatus}
          value={oppositionFile && [oppositionFile]}
          maxNumberFile={1}
          onDelete={onDocumentDeleted}
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
          required={required}
          maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
          informationDoc
          handleDownload={handleDownload}
        />
      </div>
      <div>
        <HelpBlock className='mt-5'><FormattedMessage id='statement_of_means_help' /></HelpBlock>
      </div>
    </div>
  )
}

export default StatementOfMeans
