import React, { FC, useState } from 'react'
import {
  BASIC_DATE,
  CardBlock,
  Country,
  DateUtils,
  ModalComponent,
  ProductAndServiceListView,
  Seniority,
  SubmitButton
} from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'

interface SenioritiesFRMIRowContentProps {
  seniority: Seniority,
  index: number,
  country: Country,
  editSeniority: any,
  deleteSeniority: any,
  readOnly?: boolean
}

const SenioritiesFRMIRowContent: FC<SenioritiesFRMIRowContentProps> = ({ seniority, index, country, editSeniority, deleteSeniority, readOnly = true }) => {
  const [showClass, setShowClass] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)

  return (
    <>
      <tr key={`-${index}`}>
        <td className='pl-4'>{index + 1}</td>
        <td>{seniority.registrationNumber}</td>
        <td>{DateUtils.formateDateToFormat(seniority.registrationDate, BASIC_DATE)}</td>
        <td>{DateUtils.formateDateToFormat(seniority.registeredDate, BASIC_DATE)}</td>
        <td>{country?.name}</td>
        <td>
          {seniority.productAndServices && seniority.productAndServices.length > 0
            ? (
              <>
                {seniority.productAndServices?.map((product) => product.ref).join(', ')}
                <SubmitButton
                  className='btn-icon'
                  onClick={() => setShowClass(!showClass)}
                >
                  <FontAwesomeIcon
                    icon={showClass ? faChevronDown : faChevronRight}
                    className='text-primary'
                  />
                </SubmitButton>
              </>)
            : <></>}
        </td>
        {(editSeniority || deleteSeniority) &&
          <td className='btn-column pr-4'>
            {editSeniority &&
              <button className='btn btn-link text-primary' onClick={() => editSeniority(index)}>
                <FontAwesomeIcon icon={faEdit} />
              </button>}
            {deleteSeniority &&
              <button
                className='btn btn-link text-danger ml-3'
                onClick={() => setShowModalDelete(true)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>}
          </td>}
      </tr>
      {showClass && seniority.productAndServices && seniority.productAndServices?.length > 0 &&
        <tr>
          <td colSpan={readOnly ? 6 : 7}>
            <CardBlock shadow className='mt-3'>
              <ProductAndServiceListView
                productClasses={seniority.productAndServices}
                readOnly
                isFRMIPriority
              />
            </CardBlock>
          </td>
        </tr>}
      <ModalComponent
        title={<FormattedMessage id='seniority_claimed_delete_modal_title' />}
        show={showModalDelete}
        handleClose={() => setShowModalDelete(false)}
        customContent={() => <FormattedMessage id='seniority_claimed_delete_modal_text' />}
        onClick={() => {
          deleteSeniority && deleteSeniority(index)
          setShowModalDelete(false)
        }}
      />
    </>
  )
}

export default SenioritiesFRMIRowContent
