import {
  DOCUMENT_TYPES,
  EventType,
  FileBrowserField,
  FileButton,
  FoundationService,
  OppositionFoundationTypeComponent,
  OPPOSITION_FOUNDATION_TRADENAME_ORIGINS,
  OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_COMMERCIAL,
  OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_SIGN,
  PROCEDURE_OPPOSITION,
  SelectField,
  TextArea,
  TextField
} from '@inpi-marques/components'
import React, { FC, useEffect } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT DE TYPE Nom commercial ou enseigne
 */
const FoundationTradeNameForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentDeleted, onDocumentAdded, documents, procedureType, setFieldStatus, disableFiles, addToRefs, disableActiveField, handleDownload }) => {
  /**
   * On supprime le fichier de representation de signe, si c'est une marque verbale ou si on passe sur un type "nom commercial"
   */
  useEffect(() => {
    const signCopyFiles = FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_COMMERCIAL_SIGN)

    if (signCopyFiles.length && (foundation.entity?.isWord || foundation.entity?.type === OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_COMMERCIAL.value)) {
      onDocumentDeleted && onDocumentDeleted(signCopyFiles[0])
    }
  }, [foundation.entity?.isWord, foundation.entity?.type])

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='row'>
        {/* L'opposant est titulaire d'un(e) : */}
        <SelectField
          addToRefs={addToRefs}
          inputId='entity_type'
          classNameFormGroup='col-4'
          options={OPPOSITION_FOUNDATION_TRADENAME_ORIGINS}
          value={foundation.entity?.type}
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'type', event.target.value))}
          placeholder={Message[`${procedureType?.toLowerCase()}_foundation_trade_name_type_placeholder`]}
          label={<FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_trade_name_type_label`} />}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
          required
        />

        {foundation.entity?.type === OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_SIGN.value &&
          <SelectField
            addToRefs={addToRefs}
            inputId='isWord'
            classNameFormGroup='col-4'
            label={<FormattedMessage id='foundation_is_word_type_trade_name_label' />}
            placeholder={Message.foundation_signs_type}
            onChange={(e) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'isWord', e.target.value === 'true'))}
            options={[
              {
                value: 'true',
                label: Message.sign_type_word_title
              },
              {
                value: 'false',
                label: Message.brand_type_other_title
              }
            ]}
            fieldStatus={fieldStatus}
            value={foundation.entity?.isWord}
            required
          />}

        {!disableFiles && !foundation.entity?.isWord && foundation.entity?.type === OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_SIGN.value &&
          <FileBrowserField
            classNameButton='d-contents'
            className='col-4'
            inputId='sign_file'
            buttonLabel={<FileButton />}
            label={<FormattedMessage id='foundation_sign_file_label' />}
            required
            fieldStatus={fieldStatus}
            resetError={setFieldStatus}
            onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_COMMERCIAL_SIGN)}
            value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_COMMERCIAL_SIGN)}
            onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
            maxNumberFile={1}
            informationDoc
            handleDownload={handleDownload}
          />}

        {/* DESIGNATION DU SIGNE */}
        <TextField
          inputId='entity_name'
          label={<FormattedMessage id='foundation_sign_name_label' />}
          classNameFormGroup='col-4'
          onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'name', event.target.value))}
          value={foundation.entity?.name}
          required={foundation.entity?.isWord || foundation.entity?.type === OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_COMMERCIAL.value}
          fieldStatus={fieldStatus}
        />

        {!disableFiles &&
          <FileBrowserField
            classNameButton='d-contents'
            className='col-4'
            inputId='rightFile'
            buttonLabel={<FileButton />}
            label={<FormattedMessage id='foundation_right_file_label' />}
            onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_COMMERCIAL_EXISTENCE)}
            value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_COMMERCIAL_EXISTENCE)}
            onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
            fieldStatus={fieldStatus}
            resetError={setFieldStatus}
            required={procedureType !== PROCEDURE_OPPOSITION.value}
            informationDoc
            placementTooltip={!foundation.entity?.isWord && foundation.entity?.type === OPPOSITION_FOUNDATION_TRADENAME_ORIGIN_SIGN.value ? 'bottom' : 'right'}
            handleDownload={handleDownload}
          />}
      </div>
      {procedureType === PROCEDURE_OPPOSITION.value &&
        <div className='row'>
          <h2 className='text-primary col-12'>
            <FormattedMessage id='foundation_form_activities_part_label' />
          </h2>

          <TextArea
            classNameFormGroup='col-4'
            inputId='activity'
            onChange={(event: EventType) => setFoundation({ ...foundation, activity: event.target.value })}
            value={foundation.activity}
            required
            label={<FormattedMessage id='foundation_form_domain_name_activity_label' />}
            fieldStatus={fieldStatus}
            resetError={setFieldStatus}
          />
        </div>}
      {/* FONDEMENT ACTIF */}
      <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
    </IntlProvider>
  )
}

export default FoundationTradeNameForm
