import React, { FC, useEffect, useState, ReactElement } from 'react'
import {
  Contributor,
  DateUtils,
  FIELD_ARRIVAL_DATE,
  FIELD_BRAND_MODEL,
  FIELD_BRAND_TYPE_RECORD,
  FIELD_DEPOSIT_DATE,
  FIELD_DEPOSITOR_SEARCHABLE_ENTITY,
  FIELD_INTERNAL_REFERENCE,
  FIELD_LAST_UPDATE,
  FIELD_NOTIFICATIONS_DEADLINE,
  FIELD_NOTIFICATIONS_SENDING_DATE,
  FIELD_NUMNAT,
  FIELD_PROCEDURE_TYPE,
  FIELD_SUB_PROCEDURE_TYPE,
  FoundationType,
  Motive,
  PreviewThumbnailTableBody,
  PROCEDURE_DEPOSIT,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_OFFICIAL_DOCUMENT,
  Record,
  Transaction,
  Payment,
  SubmitButton,
  PROCEDURE_RENEWAL,
  PROCEDURE_MIFR,
  PROCEDURE_DIVISION,
  PROCEDURE_REVOCATION_STATEMENT,
  TruncatedColumn
} from '@inpi-marques/components'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TransactionService from '../../services/transaction/TransactionService'
import { useIntl } from 'react-intl'
import { BasketFilter, BasketInterface } from '../../interfaces/BasketInterface'
import { useHistory, useLocation } from 'react-router'
import { BASKET_ID, BASKET_TYPES, HEADER_TO_DELETE } from '../../constants/BasketConstants'
import NotificationService from '../../services/transaction/NotificationService'
import foundationService from '../../services/opposition/FoundationService'
import motiveService from '../../services/motives/MotiveService'
import foundationTypeService from '../../services/foundation-types/FoundationTypeService'
import { BrandType, BRAND_TYPES_ALL } from '../../constants/BrandConstants'
import DocumentService from 'services/document/DocumentService'
import Message from '../../constants/Message'
import { FIELD_DEPOSIT_DATE_RECORD } from '@inpi-marques/components/src/constants/RecordListTableConstants'

type TransactionListTableBodyProps = {
  list?: Transaction[]|Record[],
  basket?: BasketInterface,
  onDelete?: (idTransactionToDelete: string) => void,
  headerBasketSelected?: BasketFilter
};

const TransactionListTableBody: FC<TransactionListTableBodyProps> = ({
  list,
  basket,
  onDelete,
  headerBasketSelected
}) => {
  const intl = useIntl()
  const history = useHistory()
  const headers = basket?.headers?.map((header) => header.value)
  const [foundationTypes, setFoundationTypes] = useState<FoundationType[]>([])
  const [motiveTypes, setMotiveTypes] = useState<Motive[]>([])

  const location = useLocation()
  /**
   * Gère le click sur une corbeille
   * @param transaction
   */
  const handleOnClickBasket = (transaction: Transaction) => {
    let corbeilleSlug = basket?.urlParam
    if (basket?.id === 'all') {
      corbeilleSlug = BASKET_TYPES.find((status) => transaction.status && status.statusFiltres && status.statusFiltres.includes(transaction.status))?.urlParam
    }
    history.push(`/liste/${corbeilleSlug}/demande/${transaction.id}${headerBasketSelected ? `?corbeille=${headerBasketSelected.id}` : ''}`)
  }

  useEffect(() => {
    // si on est pas sur la corbeille dépôt on va chercher les infos
    // sur les motifs et fondements
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.get('corbeille') !== PROCEDURE_DEPOSIT.value.toLowerCase()) {
      foundationTypeService.getAll().then((foundationTypeList) => {
        setFoundationTypes(foundationTypeList)
      })
      motiveService.getAll().then((motiveTypeList) => {
        setMotiveTypes(motiveTypeList)
      })
    }
  }, [])

  const getContributorName = (transaction: Transaction): string => {
    let contributor: Contributor|undefined

    if (transaction.opponents && transaction.opponents.length > 0) {
      contributor = transaction.opponents[0]
    } else if (transaction.depositors && transaction.depositors.length > 0) {
      contributor = transaction.depositors[0]
    }

    if (contributor) {
      return contributor.type === 'M' ? contributor.searchableIdentity ?? '' : contributor.lastname ?? ''
    }

    return ''
  }

  /**
   * Valeur de la colonne Type
   *
   * @param transaction
   * @param lastFoundationType
   * @param lastMotive
   * @returns
   */
  const getSubProcedureTypeLabel = (transaction: Transaction, lastFoundationType?: FoundationType, lastMotive?: Motive): string => {
    switch (transaction.procedureType) {
      case PROCEDURE_DEPOSIT.value:
        return intl.formatMessage({ id: `sub_procedure_abbreviation_${TransactionService.getSubProcedureType(transaction)?.label}` })
      case PROCEDURE_INSCRIPTION.value:
        return intl.formatMessage({ id: `${TransactionService.getSubProcedureType(transaction)?.label}` })
      case PROCEDURE_FRMI.value:
        return transaction.subProcedureType ? intl.formatMessage({ id: `${transaction.subProcedureType?.toLowerCase()}_title` }) : ''
      case PROCEDURE_OFFICIAL_DOCUMENT.value:
        if (transaction.officialDocument?.officialDocumentsRequested?.length) {
          return transaction.officialDocument?.officialDocumentsRequested[0].officialDocumentType?.label ?? ''
        }
        return ''
      case PROCEDURE_RENEWAL.value:
        return transaction.renewalDetails?.type ? intl.formatMessage({ id: `${transaction.renewalDetails?.type?.toLowerCase()}` }) : ''
      default:
        if (lastFoundationType) {
          return lastFoundationType.label
        } else if (lastMotive) {
          return lastMotive.label
        }
    }

    return ''
  }

  /**
   * Valeur de la colonne Date de la demande
   * @param transaction
   * @returns
   */
  const getFieldArrivalDateLabel = (transaction: Transaction): string => {
    if (transaction.arrivalDate) {
      return DateUtils.formatDateFr(transaction.arrivalDate)
    } else if (transaction.deposit?.depositDate) {
      return DateUtils.formatDateFr(transaction.deposit.depositDate)
    }
    return ''
  }

  /**
   * Valeur de la colonne Marque
   * @param transaction
   * @param index
   * @returns
   */
  const getFieldBrandModel = (transaction: Transaction, index: number = 0): ReactElement|string => {
    switch (transaction.procedureType) {
      case PROCEDURE_DEPOSIT.value:
      case PROCEDURE_MIFR.value:
      case PROCEDURE_DIVISION.value:
        if (transaction?.deposit?.brand?.text || transaction?.opposition?.contestedRequest?.contestedBrand) {
          return transaction?.deposit?.brand?.text ?? transaction?.opposition?.contestedRequest?.contestedBrand
        }
        if (transaction?.deposit?.brand && (transaction.deposit.brand.preview || transaction.deposit.brand.file)) {
          return (
            <PreviewThumbnailTableBody
              file={() => DocumentService.getBrandPreviewDocument(transaction, transaction.deposit?.brand?.preview || transaction.deposit?.brand?.file)}
              brand={transaction.deposit.brand}
              index={index}
              listLength={list?.length}
            />)
        }
        break

      case PROCEDURE_FRMI.value:
        if (transaction?.frmiDetails?.titles?.length && transaction?.frmiDetails?.titles[0].text) {
          return transaction?.frmiDetails?.titles[0].text
        } else if (transaction?.frmiDetails?.brand && (transaction.frmiDetails.brand.preview || transaction.frmiDetails.brand.file)) {
          return (
            <PreviewThumbnailTableBody
              file={() => DocumentService.getBrandPreviewDocument(transaction, transaction.frmiDetails?.brand?.preview || transaction.frmiDetails?.brand?.file)}
              brand={transaction.frmiDetails.brand}
              index={index}
              listLength={list?.length}
            />)
        }
        break

      case PROCEDURE_OFFICIAL_DOCUMENT.value:
        if (transaction?.officialDocument?.officialDocumentsRequested?.length && transaction?.officialDocument?.officialDocumentsRequested[0].title?.text) {
          return transaction?.officialDocument?.officialDocumentsRequested[0].title.text
        }
        break
      case PROCEDURE_REVOCATION_STATEMENT.value:
        if (transaction?.revocationStatement?.titles?.length && transaction?.revocationStatement?.titles[0].text) {
          return transaction?.revocationStatement?.titles[0].text
        }
        break
      case PROCEDURE_RENEWAL.value:
        if (transaction?.renewalDetails?.titles?.length && transaction.renewalDetails.titles[0].text) {
          return transaction?.renewalDetails?.titles[0].text
        }
        break
    }
    return ''
  }

  /**
   * Récupère l'attribute title de la colonne Marque
   * @param transaction
   * @returns
   */
  const getFieldBrandModelTitleAttribute = (transaction: Transaction): string => {
    /**
     * On ne recherche à retourner uniquement une chaîne de caractère
     * On ne va donc pas chercher les previews (cf fonction du dessus getFieldBrandModel)
     */
    if (![PROCEDURE_DEPOSIT.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value].some((procedure: string) => procedure === transaction.subProcedureType) ||
     (transaction?.deposit?.brand?.text ?? transaction?.opposition?.contestedRequest?.contestedBrand)
    ) {
      return getFieldBrandModel(transaction) as string
    }
    return ''
  }

  /**
   * Récupère la valeur de la colonne de type de marque
   */
  const getFieldBrandTypeRecordLabel = (transaction: Record): string => {
    const brandType: BrandType|undefined = BRAND_TYPES_ALL.find(brandType => brandType.value === transaction?.details?.brand?.type)
    if (!brandType) {
      return ''
    }
    return intl.formatMessage({ id: brandType.title })
  }

  return (
    <tbody>
      {
      list?.map((transaction: Transaction, index: number) => {
        const lastFoundation: FoundationType|undefined = foundationTypes && foundationTypes.find(type => type.code === foundationService.getLastFoundation(transaction?.opposition?.foundations)?.type)
        const lastMotive: Motive|undefined = motiveTypes.find(type => type.code === motiveService.getLastMotive(transaction?.opposition?.motives))

        return (
          <tr key={index} className='cursor-pointer' onClick={() => handleOnClickBasket(transaction)}>
            {
              headers?.includes(FIELD_PROCEDURE_TYPE) &&
                <td className='border-0 row m-0' title={TransactionService.getProcedureType(transaction)?.label}>
                  <div className={`rounded px-2 procedure-type-${transaction.procedureType?.toLowerCase()} text-truncate`}>
                    {TransactionService.getProcedureType(transaction)?.label}
                  </div>
                </td>
            }
            {
              headers?.includes(FIELD_NUMNAT) &&
                <TruncatedColumn className='border-0' value={transaction.numNat ?? ''} />
            }
            {
              headers?.includes(FIELD_BRAND_MODEL) &&
                <td className='border-0' title={getFieldBrandModelTitleAttribute(transaction)}>
                  {getFieldBrandModel(transaction, index)}
                </td>
            }
            {
              headers?.includes(FIELD_LAST_UPDATE) &&
                <TruncatedColumn className='border-0' value={DateUtils.formatDateFr(transaction.lastUpdate)} />
            }
            {
              headers?.includes(FIELD_DEPOSIT_DATE) &&
                <TruncatedColumn className='border-0' value={DateUtils.formatDateFr(transaction.deposit?.depositDate)} />
            }
            {
              headers?.includes(FIELD_DEPOSIT_DATE_RECORD) &&
                <TruncatedColumn className='border-0' value={DateUtils.formatDateFr(transaction.details?.depositDate)} />
            }
            {
              headers?.includes(FIELD_INTERNAL_REFERENCE) &&
                <TruncatedColumn className='border-0' value={transaction.internalReference ?? ''} />
            }
            {
              headers?.includes(FIELD_DEPOSITOR_SEARCHABLE_ENTITY) &&
                <TruncatedColumn className='border-0' value={getContributorName(transaction)} />
            }
            {
              headers?.includes(FIELD_SUB_PROCEDURE_TYPE) &&
                <td
                  className='border-0 row'
                  title={getSubProcedureTypeLabel(transaction, lastFoundation, lastMotive)}
                >
                  <span className={`px-2 ${transaction.subProcedureType && transaction.procedureType === PROCEDURE_DEPOSIT.value ? `rounded sub-procedure-type-${transaction.subProcedureType.toLowerCase()}` : ''} text-truncate`}>
                    {getSubProcedureTypeLabel(transaction, lastFoundation, lastMotive)}
                  </span>
                </td>
            }
            {
              headers?.includes(FIELD_ARRIVAL_DATE) &&
                <TruncatedColumn className='border-0' value={getFieldArrivalDateLabel(transaction)} />
            }
            {
              headers?.includes(FIELD_NOTIFICATIONS_SENDING_DATE) &&
                <TruncatedColumn className='border-0' value={NotificationService.getLastSendingDate(basket?.id === BASKET_ID.PENDING_NOTIFICATION_OMPI ? transaction.notificationsOMPI : transaction.notifications)} />
            }
            {
              headers?.includes(FIELD_NOTIFICATIONS_DEADLINE) &&
                <TruncatedColumn className='border-0' value={NotificationService.getEarliestDeadlineDate(basket?.id === BASKET_ID.PENDING_NOTIFICATION_OMPI ? transaction.notificationsOMPI : transaction.notifications)} />
            }
            {
              headers?.includes(HEADER_TO_DELETE.value) && onDelete &&
                <td className='border-0 text-secondary text-center'>
                  <SubmitButton
                    disabled={transaction.payments?.some((payment: Payment) => (payment.status === 'pending' || payment.status === 'paid'))}
                    tooltip={transaction.payments?.some((payment: Payment) => (payment.status === 'pending' || payment.status === 'paid')) ? Message.delete_transaction_impossible : ''}
                    className='btn btn-icon-secondary mr-3'
                    onClick={() => onDelete(transaction.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </SubmitButton>
                </td>
            }
            {
              headers?.includes(FIELD_BRAND_TYPE_RECORD) &&
                <TruncatedColumn className='border-0' value={getFieldBrandTypeRecordLabel(transaction)} />
            }
          </tr>
        )
      })
      }
    </tbody>
  )
}

export default TransactionListTableBody
