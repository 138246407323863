import http from '../../network/http-common'
import Message from '../../constants/Message'
import { createIntl } from 'react-intl'
import { FoUserResponse } from '../../interfaces/User'
import { InpiMessage, InpiMessageRequest } from '../../interfaces/InpiMessageInterface'
import { toast } from 'react-toastify'
import { MailObject } from '../../constants/MessageToInpiConstants'

/**
 * Classe permettant de gérer les demandes de contact FO
 */
class MessageToInpiService {
  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
  }

  /** Envoie du mail */
  sendMessage = async (user: FoUserResponse, message: InpiMessage) => {
    const messageRequest: InpiMessageRequest = {
      user: user,
      object: message.object,
      content: message.content
    }
    return await http.post('/api/mails-inpi',
      messageRequest
    ).then(() => {
      toast.success(this.intl.formatMessage({ id: 'contact_success_message' }))
      return Promise.resolve()
    }).catch(error => {
      toast.error(error.message)
      return Promise.reject(error)
    })
  }

  /**
   * Récupération des objets de mail définis dans le BO
   * @returns Promise<MailObject[]
   */
  getMailObjects = async (): Promise<MailObject[]> => {
    try {
      return await http.get('/api/mails-inpi/objects')
    } catch (error) {
      return Promise.resolve([])
    }
  }
}

export default new MessageToInpiService()
