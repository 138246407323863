import { messageIsFilled, Seniority } from '@inpi-marques/components'
import Message from '../../../../../constants/Message'
import moment from 'moment'

class SeniorityValidator {
    validateSeniority = (seniority: Seniority = {}) => {
      return {
        countryCode: messageIsFilled(seniority.countryCode),
        registrationNumber: messageIsFilled(seniority.registrationNumber),
        registrationDate: this.validateSeniorityDate(seniority.registrationDate)
      }
    }

    validateSeniorityDate = (date?: string) => {
      if (!date) {
        return Message.seniority_deposit_date_required
      } else if (!moment(date).isValid()) {
        return Message.seniority_deposit_date_wrong_format
      } else if (moment(date) > moment()) {
        return Message.seniority_deposit_date_too_late
      }
      return ''
    }
}

export default new SeniorityValidator()
