import React, { FC, useEffect, useState } from 'react'

import { Preview, Transaction, TransactionDocument } from '@inpi-marques/components'
import DocumentService from '../../../../services/document/DocumentService'
import { FormattedMessage } from 'react-intl'

interface BrandReproductionOverviewProps{
    transaction: Transaction
}

const BrandReproductionOverview: FC<BrandReproductionOverviewProps> = ({ transaction }) => {
  const brand = transaction.frmiDetails?.brand ?? { hasStandardCharacters: false, hasColors: false, isOnlyColors: false }

  /** Gestion de l'upload du document */
  const brandDocument = transaction.frmiDetails?.brand?.file
  const [brandDocumentPreview, setBrandDocumentPreview] = useState<string | undefined>(transaction.frmiDetails?.brand?.file?.previewData)

  useEffect(() => {
    if (!brandDocumentPreview && brandDocument) {
      // Dans le cas d'une édition, si le fichier de la marque n'est pas en local, on récupère la prévisualisation depuis l'API
      const file = brand?.preview && brand.preview.internalName ? brand.preview : brand?.file
      DocumentService.getBrandPreviewDocument(transaction, file).then(setBrandDocumentPreview)
    }
  }, [])

  /**
     * Récupère l'image de la marque si non stockée
     * @returns
     */
  const getBrandDocument = async (document: TransactionDocument): Promise<string | null> => {
    if (document) {
      return await DocumentService.getBrandPreviewDocument(transaction, document)
    }
    return null
  }

  return (
    <div className='row d-flex flex-wrap'>
      {(brand.file?.previewData || brand.preview || brand.file) &&
        <div className='col-5'>
          {brandDocument && brand &&
            <Preview
              file={brandDocumentPreview ?? (() => getBrandDocument(brandDocument))}
              document={brandDocument}
              isPrintFormat
            />}
        </div>}
      <div className='col-7 overview__brand-informations'>
        <div>
          <h4 className='font-weight-bold'><FormattedMessage id='frmi_overview_colors' /></h4>
          <p className='subtitle'><FormattedMessage id={transaction.frmiDetails.brand?.hasColors ? 'common_yes' : 'common_no'} />
          </p>
        </div>
        <div>
          <h4 className='font-weight-bold'><FormattedMessage id='frmi_overview_standard_characters' /></h4>
          <p className='subtitle'><FormattedMessage id={transaction.frmiDetails.brand?.hasStandardCharacters ? 'common_yes' : 'common_no'} /></p>
        </div>
        <div>
          <h4 className='font-weight-bold'><FormattedMessage id='frmi_overview_color_combination' /></h4>
          <p className='subtitle'><FormattedMessage id={transaction.frmiDetails.brand?.isOnlyColors ? 'common_yes' : 'common_no'} /></p>
        </div>
      </div>
    </div>
  )
}

export default BrandReproductionOverview
