import {
  CardBlock,
  INSCRIPTION_TYPE_ACT_TITLE,
  INSCRIPTION_TYPE_AGENT,
  INSCRIPTION_TYPE_CORRECTION,
  INSCRIPTION_TYPE_REWARD,
  PROCEDURE_INSCRIPTION,
  Transaction,
  TransactionService as CommonTransactionService
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import IntervenantsOverview from '../../transactions/overview/IntervenantsOverview'
import InformationsOverview from '../../transactions/overview/InformationsOverview'
import DocumentsOverview from '../../transactions/overview/DocumentsOverview'
import OverviewTitles from '../form/titles/OverviewTitles'
import CorrectionOverview from './correction/CorrectionOverview'
import ActOverview from './act/ActOverview'
import ContestOverview from './contest/ContestOverview'
import MandataryOverview from './mandatary/MandataryOverview'
import OverviewRenunciationTitle from 'component/renunciation/overview/OverviewRenunciationTitle'
import OverviewInternationalExtension from '../../division/form/options/OverviewInternationalExtension'
import OverviewLinkedTransactions from '../../division/form/options/OverviewLinkedTransactions'

interface InscriptionOverviewProps {
  transaction: Transaction,
  showAddDocumentsButton?: boolean,
  setReload?: () => void
}

const InscriptionOverview: FC<InscriptionOverviewProps> = ({ transaction, showAddDocumentsButton = false, setReload }) => {
  return (
    <div className='col-12 d-flex flex-wrap p-0'>
      {/* Type de demande */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='overview_deposit_type_title' />} shadow>
          <InformationsOverview transaction={transaction} />
        </CardBlock>
      </div>

      {/* Intervenants */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='overview_deposit_intervenants_title' />} shadow>
          <IntervenantsOverview transaction={transaction} procedureType={transaction.procedureType} />
        </CardBlock>
      </div>

      {/* Titres */}
      {
        transaction.subProcedureType !== INSCRIPTION_TYPE_REWARD.value && !CommonTransactionService.isRenunciation(transaction) &&
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_inscription_titles' />} shadow>
              <div className='mb-3'>
                <OverviewTitles titles={transaction.inscription?.titles} procedureType={PROCEDURE_INSCRIPTION.value} showDownloadTitleList />
              </div>
            </CardBlock>
          </div>
      }

      {(CommonTransactionService.isRenunciation(transaction) || CommonTransactionService.isOfficeInscription(transaction)) &&
        <>
          {CommonTransactionService.isRenunciation(transaction) &&
            <div className='col-12 mb-3'>
              <CardBlock header={<FormattedMessage id='overview_inscription_renunciation_title' />} shadow>
                <div className='mb-3'>
                  <OverviewRenunciationTitle transaction={transaction} />
                </div>
              </CardBlock>
            </div>}

          {/* Options */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_deposit_options_title' />} shadow>
              <div className='mb-3'>
                <OverviewInternationalExtension
                  internationalExtensions={transaction.internationalExtensions}
                />
              </div>
              {CommonTransactionService.isRenunciation(transaction) &&
                <div className='mb-3'>
                  <OverviewLinkedTransactions
                    linkedTransaction={transaction.inscription?.renunciation?.linkedTransactions}
                  />
                </div>}
            </CardBlock>
          </div>
        </>}

      {transaction.subProcedureType === INSCRIPTION_TYPE_CORRECTION.value && transaction.inscription?.correction &&
        <div className='col-12 mb-3'>
          <CardBlock header={<FormattedMessage id='correction_title' />} shadow>
            <CorrectionOverview correction={transaction.inscription?.correction} natureCodes={transaction.inscription.natureCodes ?? []} />
          </CardBlock>
        </div>}
      {transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value && transaction.inscription &&
        <div className='col-12 mb-3'>
          <CardBlock header={<FormattedMessage id='act_title' />} shadow>
            <ActOverview inscription={transaction.inscription} />
          </CardBlock>
        </div>}
      {transaction.subProcedureType === INSCRIPTION_TYPE_REWARD.value && transaction.inscription?.contest &&
        <div className='col-12 mb-3'>
          <CardBlock header={<FormattedMessage id='contest_title' />} shadow>
            <ContestOverview contest={transaction.inscription?.contest} />
          </CardBlock>
        </div>}
      {transaction.subProcedureType === INSCRIPTION_TYPE_AGENT.value && transaction.inscription?.mandatary &&
        <div className='col-12 mb-3'>
          <CardBlock header={<FormattedMessage id='mandatary_title' />} shadow>
            <MandataryOverview mandatary={transaction.inscription?.mandatary} natureCodes={transaction.inscription.natureCodes ?? []} />
          </CardBlock>
        </div>}

      {/* Récapitulatif documents */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='overview_file_title' />} shadow>
          <DocumentsOverview transaction={transaction} showAddDocumentsButton={showAddDocumentsButton} setReload={setReload} />
        </CardBlock>
      </div>

    </div>
  )
}

export default InscriptionOverview
