import React, { FC, useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import TransactionService from '../../../services/transaction/TransactionService'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from '../../../store/transaction/transactionActions'
import {
  containsErrors,
  Contributor,
  ContributorValidator,
  DEFAULT_CONTRIBUTOR,
  EditorialBlock,
  EventType,
  FieldStatus,
  FilAriane,
  ModalComponent,
  PROCEDURE_REVOCATION,
  REVOCATION_CONTRIBUTORS_OPTIONS,
  REVOCATION_CONTRIBUTORS_TYPES,
  Transaction,
  TransactionDocument,
  CommonContributorService, CONTRIBUTOR_DEPOSITORS
} from '@inpi-marques/components'
import ContentService from '../../../services/content/ContentService'
import { FormattedMessage } from 'react-intl'
import StepContainer from '../../stepper/StepContainer'
import ContributorFrontForm from '../../contributors/ContributorFrontForm'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import ContributorList from '../../contributors/ContributorList'
import ContributorAgent from '../../contributors/ContributorAgent'
import ContributorService from '../../../services/contributors/ContributorService'
import RegistrationRequest from '../../opposition/form/registrationRequest/RegistrationRequest'
import ContestedRequestValidator from '../../opposition/form/registrationRequest/validator/ContestedRequestValidator'
import StatementOfMeans from '../../opposition/form/statementOfMeans/StatementOfMeans'
import StatementValidator from '../../opposition/form/statementOfMeans/validator/StatementOfMeansValidator'
import OppositionAnnexes from '../../opposition/form/annexes/OppositionAnnexes'
import PaymentForm from '../../payment/PaymentForm'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_REVOCATION } from '../../../constants/PaymentConstants'
import Motives from 'component/opposition/form/foundations/motives/Motives'
import FoundationValidator from 'component/opposition/form/foundations/validator/FoundationValidator'
import DocumentService from 'services/document/DocumentService'
import Recap from '../../recap/Recap'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
/* global localStorage */

interface RevocationFormProps extends RouteComponentProps {
  isNewTransaction: boolean
}
const RevocationForm: FC<RevocationFormProps> = ({
  history,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const transaction: Transaction = useSelector((state : RootStateOrAny) => state.transaction)
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const [documents, setDocuments] = useState<TransactionDocument[]>([])
  const fieldStatus: FieldStatus = useSelector((state : RootStateOrAny) => state.transactionFieldStatus)
  const [required, setIsRequired] = useState<boolean>(false)

  const handleSubmit = () => {
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        setDocuments(result.documents)
      })
    } else {
      dispatch(storeTransactionInit(PROCEDURE_REVOCATION.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-REVOCATION').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  const saveFirstStep = async () => {
    // Supprime les demandeurs non conformes
    let newContributorList : Contributor[] = []
    transaction.depositors.map((contributor) => {
      const fieldStatusContributor = ContributorValidator.validateContributor(contributor, transaction, CONTRIBUTOR_DEPOSITORS.value)
      if (!containsErrors(fieldStatusContributor)) {
        newContributorList = [...newContributorList, contributor]
      }
    })

    if (isNew) {
      setIsNew(false)
      return await TransactionService.createTransaction({ ...transaction, depositors: newContributorList }, PROCEDURE_REVOCATION.value, [REVOCATION_CONTRIBUTORS_TYPES.DEPOSITORS.value])
    } else {
      return await TransactionService.updateTransactionBDDFromStore({ ...transaction, depositors: newContributorList }, [REVOCATION_CONTRIBUTORS_TYPES.DEPOSITORS.value])
    }
  }

  const onDocumentChanged = (event: EventType, type: string) => {
    const { value } = event.target

    const newFile: TransactionDocument = {
      id: documents.length,
      type: type,
      internalName: '',
      name: value.name,
      format: value.type,
      file: value
    }
    setDocuments([...documents, newFile])
  }

  const onDocumentDeleted = (event: EventType) => {
    const { value } = event.target
    setDocuments(documents.filter((file: TransactionDocument) => file.internalName !== value.internalName))
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_revocation' /></span>
      </FilAriane>
      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='breadcrumb_new_revocation' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        setIsRequired={setIsRequired}
        required={required}
        views={[
          {
            id: 'contributors',
            label: <FormattedMessage id='contributor_title' />
          }, {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_depositors_revocation' />,
            level: 1,
            component: (
              <ContributorList
                openOnFirstOne={!transaction.depositors?.length}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={REVOCATION_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                contributorTypeLabel={`${REVOCATION_CONTRIBUTORS_TYPES.DEPOSITORS.value}_revocation`}
                contributorTypeToCopy={[
                  REVOCATION_CONTRIBUTORS_TYPES.AGENT.value,
                  REVOCATION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  REVOCATION_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value,
                  REVOCATION_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={REVOCATION_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_REVOCATION.value}
                validateContributor={ContributorValidator.validateContributor}
                setIsRequired={setIsRequired}
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: () => saveFirstStep()
          },
          {
            id: 'contributors_agent',
            label: <FormattedMessage id='contributor_agent' />,
            level: 1,
            component: (
              <ContributorAgent
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorTypeToCopy={[
                  REVOCATION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  REVOCATION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  REVOCATION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  REVOCATION_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value
                ]}
                procedure={PROCEDURE_REVOCATION.value}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepAgentDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [REVOCATION_CONTRIBUTORS_TYPES.AGENT.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, REVOCATION_CONTRIBUTORS_TYPES.AGENT.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={REVOCATION_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  REVOCATION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  REVOCATION_CONTRIBUTORS_TYPES.AGENT.value,
                  REVOCATION_CONTRIBUTORS_TYPES.SIGNATORY.value,
                  REVOCATION_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value
                ]}
                optionForm={REVOCATION_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_REVOCATION.value}
                defaultContributor={{ ...DEFAULT_CONTRIBUTOR, email: user.email, consentNotificationByEmail: user.consentNotificationByEmailPreferences }}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore({
              ...transaction,
              [REVOCATION_CONTRIBUTORS_TYPES.RECIPIENT.value]: CommonContributorService.filterContributorCivilityCode(transaction[REVOCATION_CONTRIBUTORS_TYPES.RECIPIENT.value])
            }, [REVOCATION_CONTRIBUTORS_TYPES.RECIPIENT.value])
          },
          {
            id: 'contributors_holdersContestedMark',
            label: <FormattedMessage id='contributor_holdersContestedMark' />,
            level: 1,
            component: (
              <ContributorList
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={REVOCATION_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value}
                optionForm={REVOCATION_CONTRIBUTORS_OPTIONS.holdersContestedMark}
                procedure={PROCEDURE_REVOCATION.value}
                validateContributor={ContributorValidator.validateContributor}
                openOnFirstOne={!transaction.holdersContestedMark?.length}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepHoldersContestedMark,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [REVOCATION_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value])
          },
          {
            id: 'contributors_signatory',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={REVOCATION_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={REVOCATION_CONTRIBUTORS_OPTIONS.signatory}
                procedure={PROCEDURE_REVOCATION.value}
                defaultContributor={ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [REVOCATION_CONTRIBUTORS_TYPES.SIGNATORY.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, REVOCATION_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'registration_request_contested',
            label: <FormattedMessage id='registration_request_contested_revocation_label' />,
            component: (
              <RegistrationRequest
                transaction={transaction}
                fieldStatus={fieldStatus}
                onDocumentDeleted={onDocumentDeleted}
                documents={documents}
                setDocuments={setDocuments}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: () => ContestedRequestValidator.validateOnChangeStepContestedRequest(documents, PROCEDURE_REVOCATION.value),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['opposition'])
          },
          {
            id: 'revocation_foundations',
            label: <FormattedMessage id='motives_title' />,
            component: (
              <Motives transaction={transaction} fieldStatus={fieldStatus} setDocuments={setDocuments} setIsRequired={setIsRequired} />
            ),
            validateGoToStep: () => FoundationValidator.validateOnChangeStep(transaction),
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, ['opposition'])
          },
          {
            id: 'statement_of_means',
            label: <FormattedMessage id='statement_of_means_label' />,
            component: (
              <StatementOfMeans
                transaction={transaction}
                onDocumentChanged={onDocumentChanged}
                onDocumentDeleted={onDocumentDeleted}
                documents={documents}
                fieldStatus={fieldStatus}
                required={DocumentService.isStatementOfMeanRequired(transaction)}
              />
            ),
            validateGoToStep: () => StatementValidator.getValidatedDocument(transaction, documents, PROCEDURE_REVOCATION.value),
            onGoToStep: () => TransactionService.updateDocumentTransaction(transaction, documents)
          },
          {
            id: 'annexes',
            label: <FormattedMessage id='annexes_label' />,
            component: (
              <OppositionAnnexes
                transaction={transaction}
                documents={documents}
                setDocuments={setDocuments}
              />
            ),
            onGoToStep: () => TransactionService.updateDocumentTransaction(transaction, documents)
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.REVOCATION}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_REVOCATION}
              />
            )
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(RevocationForm)
