
import { Transaction, FieldStatus, containsErrors, FRMI_TYPE_EFFECT_STOP, messageIsFilled } from '@inpi-marques/components'
import { PRODUCTS_EXTENSION_TYPE_PARTIAL } from '@inpi-marques/components/src/constants/FRMIConstants'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import store from '../../../../../store/store'

class ExtensionTypeValidator {
    /**
     * Validation de l'étape permettant de renseigner l'étendue sur les produits et service ou pays d'une opération postérieure
     * @param transaction
     * @param productsRequired
     * @param countriesRequired
     * @returns
     */
    validateStep = (transaction: Transaction, productsRequired?: boolean, countriesRequired?: boolean): boolean => {
      const fieldStatus: FieldStatus = {
        productsExtensionType: productsRequired ? messageIsFilled(transaction.frmiDetails?.productsExtensionType) : '',
        productsExtensionSubType: transaction.subProcedureType === FRMI_TYPE_EFFECT_STOP.value && transaction.frmiDetails?.productsExtensionType === PRODUCTS_EXTENSION_TYPE_PARTIAL ? messageIsFilled(transaction.frmiDetails.productsExtensionSubType) : '',
        countriesExtensionType: countriesRequired ? messageIsFilled(transaction.frmiDetails?.countriesExtensionType) : ''
      }
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
      return !containsErrors(fieldStatus)
    }
}

export default new ExtensionTypeValidator()
