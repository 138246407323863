import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FilAriane } from '@inpi-marques/components'
import ConfigurationService from 'services/configuration/ConfigurationService'
import { Link } from 'react-router-dom'
import TransactionList from '../list/TransactionList'
import { BasketFilter, BasketInterface } from '../../interfaces/BasketInterface'
import { BASKET_HEADER, BASKET_ID } from '../../constants/BasketConstants'
import BasketDetailsLayout from './BasketDetailsLayout'
import { useLocation } from 'react-router'
import { Configuration } from '../../interfaces/ConfigurationInterface'

interface BasketDetailsProps {
  basket: BasketInterface,
  getTransactionCountByHeader?: (filter?: BasketFilter) => any
}

const BasketDetails: FC<BasketDetailsProps> = ({ basket, getTransactionCountByHeader }) => {
  const intl = useIntl()
  const location = useLocation()

  const [subtitle, setSubtitle] = useState<string>('')
  const [headerBasketSelected, setHeaderBasketSelected] = useState<BasketFilter>()

  useEffect(() => {
    if (basket.id === BASKET_ID.DRAFT) {
      fetchDraftDisplayPeriod()
    }

    const queryParams = new URLSearchParams(location.search)
    const basketParam = queryParams.get('corbeille')
    setHeaderBasketSelected(BASKET_HEADER.find(header => header.id === basketParam) || BASKET_HEADER[0])
  }, [location.search])

  /**
   * Récupère et affiche le temps de sauvegarde d'une transaction en brouillon
   */
  const fetchDraftDisplayPeriod = () => {
    ConfigurationService.getConfigurationsValues(['DRAFT_DISPLAY_PERIOD']).then((result: Configuration[]) => {
      const draftDisplayPeriod = result?.[0]
      if (draftDisplayPeriod != null && draftDisplayPeriod.value) {
        setSubtitle(intl.formatMessage({ id: 'basket_draft_subtitle' }, { draftDisplayPeriodValue: draftDisplayPeriod.value }))
      }
    })
  }

  return (
    <div>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        {
          headerBasketSelected && !headerBasketSelected.isHome &&
            <Link to={`/accueil?corbeille=${headerBasketSelected.id}`}><FormattedMessage id={`home_filter_header_label_${headerBasketSelected.id}`} /></Link>
        }
        <span><FormattedMessage id={`basket_label_${basket.id}`} /></span>
      </FilAriane>
      {
        headerBasketSelected &&
          <BasketDetailsLayout idBasket={basket.id} headerBasketSelected={headerBasketSelected} getTransactionCountByHeader={getTransactionCountByHeader}>
            <TransactionList subtitle={subtitle} basket={basket} headerBasketSelected={headerBasketSelected} getTransactionCountByHeader={getTransactionCountByHeader} />
          </BasketDetailsLayout>
      }
    </div>
  )
}

export default BasketDetails
