import { DateField, DateUtils, DATE_ISO, DOCUMENT_FORMATS, DOCUMENT_TYPES, EventType, FileBrowserField, FileButton, FoundationService, OppositionFoundationTypeComponent, PROCEDURE_OPPOSITION, TextArea, TextField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT INDICATION GEOGRAPHIQUE
 */
const FoundationIgCraftForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentAdded, onDocumentDeleted, documents, procedureType, setFieldStatus, disableFiles, addToRefs, disableActiveField, handleDownload }) => (
  <IntlProvider locale='fr' messages={Message}>
    <div className='row'>
      {/* NOM DE L'INDICATION GEOGRAPHIQUE */}
      <TextField
        addToRefs={addToRefs}
        inputId='entity_name'
        label={<FormattedMessage id='foundation_igcraft_name_label' />}
        classNameFormGroup='col-4'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'name', event.target.value))}
        value={foundation.entity?.name}
        required
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      {/* NUMERO DE LA DEMANDE */}
      <TextField
        classNameFormGroup='col-4'
        inputId='depositNumber'
        label={<FormattedMessage id='foundation_entity_igcraft_number_label' />}
        required
        value={foundation.entity?.depositNumber}
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'depositNumber', event.target.value))}
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
    </div>
    <div className='row'>
      <label className='pl-3 col-12'><FormattedMessage id='foundation_bopi_label' /></label>
      {/* NUMERO DU BOPI */}
      <TextField
        inputId='bopiNumber'
        label={<FormattedMessage id='foundation_bopi_number_label' />}
        classNameFormGroup='col-4'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'bopiNumber', event.target.value))}
        value={foundation.entity?.bopiNumber}
      />
      {/* DATE DU BOPI */}
      <DateField
        classNameFormGroup='col-4'
        inputId='bopiDate'
        label={<FormattedMessage id='foundation_bopi_date_label' />}
        value={DateUtils.formateDateToFormat(foundation.entity?.bopiDate, DATE_ISO) ?? ''}
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'bopiDate', DateUtils.formatToBeginOfDay(event.target.value)))}
      />
      {/* FICHIER D'HOMOLOGATION */}
      {!disableFiles &&
        <FileBrowserField
          className='col-4'
          inputId='homologationFile'
          classNameButton='d-contents'
          buttonLabel={<FileButton />}
          label={<FormattedMessage id='foundation_homologation_file_label' />}
          acceptApplication={DOCUMENT_FORMATS.PDF}
          onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_IG_HOMOLOGATION)}
          value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_IG_HOMOLOGATION)}
          onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
          maxNumberFile={1}
          required={procedureType !== PROCEDURE_OPPOSITION.value}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
          informationDoc
          placementTooltip='bottom'
          handleDownload={handleDownload}
        />}
    </div>
    <div className='row mt-4'>
      {/* QUALITE DE L'OPPOSANT / DU DEMANDEUR */}
      <TextField
        classNameFormGroup='col-4'
        inputId='opponentQuality'
        label={<FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_opponent_quality_label`} />}
        value={foundation.opponent?.quality}
        required
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByOpponent(foundation, 'quality', event.target.value))}
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      {/* Document justifiant que l’opposant est autorisé à exercer les droits découlant du droit antérieur */}
      {!disableFiles &&
        <FileBrowserField
          className='col-4'
          inputId='opponentRightsFile'
          classNameButton='d-contents'
          buttonLabel={<FileButton />}
          label={<FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_opponent_right_file_label`} />}
          acceptApplication={DOCUMENT_FORMATS.PDF}
          onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LEGITIMACY : DOCUMENT_TYPES.FOUNDATION_OPPONENT_LEGITIMACY)}
          value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, procedureType === PROCEDURE_OPPOSITION.value ? DOCUMENT_TYPES.FOUNDATION_LEGITIMACY : DOCUMENT_TYPES.FOUNDATION_OPPONENT_LEGITIMACY)}
          onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
          maxNumberFile={1}
          required={procedureType !== PROCEDURE_OPPOSITION.value}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
          informationDoc
          handleDownload={handleDownload}
        />}
      {/* Indiquez quel est le produit bénéficiant de l’indication géographique invoqué à l’appui de l’opposition */}
      {procedureType === PROCEDURE_OPPOSITION.value &&
        <TextArea
          classNameFormGroup='col-5'
          inputId='activity'
          onChange={(event: EventType) => setFoundation({ ...foundation, activity: event.target.value })}
          value={foundation.activity}
          required
          label={<FormattedMessage id='foundation_form_activity_label_igcraft' />}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
        />}
    </div>
    {/* FONDEMENT ACTIF */}
    <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
  </IntlProvider>
)

export default FoundationIgCraftForm
