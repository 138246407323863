import {
  containsErrors,
  Country,
  DateUtils, downloadFile,
  EventType,
  FieldStatus,
  Foundation,
  FoundationDocument,
  FoundationService as CommonFoundationService,
  FoundationType,
  FoundationValidator,
  OPPOSITION_FOUNDATION_BRAND_ORIGIN_UE,
  OPPOSITION_FOUNDATION_TYPE_BRAND,
  OPPOSITION_FOUNDATION_TYPE_RENOWNED,
  OPPOSITION_FOUNDATION_TYPE_UNAUTHORIZED,
  OPPOSITION_FOUNDATION_TYPES,
  PROCEDURE_OPPOSITION,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  PRODUCT_CLASS_VERSION_STATUS,
  ProductClass,
  ProductService,
  Record,
  SelectField,
  SubmitButton,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { OppositionFoundationType } from '@inpi-marques/components/src/interfaces/opposition/Opposition'
import Message from 'constants/Message'
import React, { createRef, FC, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import ContentService from 'services/content/ContentService'
import DocumentService from 'services/document/DocumentService'
import FoundationService from 'services/opposition/FoundationService'
import RecordService from 'services/opposition/RecordService'
import FoundationProductsAndServices from './FoundationProductsAndServices'

interface FoundationFormProps {
  foundation: Foundation,
  onFormCancel: () => void,
  onFoundationEdited: (foundation: Foundation, updatedDocuments: TransactionDocument[]) => Promise<void>,
  transaction: Transaction,
  foundationTypes: FoundationType[]
}

/** On conserve l'ancien state pour la suppression de documents du store */
const usePrevious = (foundation: Foundation) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = foundation
  })
  return ref.current
}

const FoundationForm: FC<FoundationFormProps> = ({
  foundation,
  onFormCancel,
  transaction,
  onFoundationEdited,
  foundationTypes
}) => {
  const intl = useIntl()

  const [stateFoundation, setStateFoundation] = useState<Foundation>(foundation)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})

  const previousStateFoundation: Foundation | undefined = usePrevious(stateFoundation)
  const currentFoundationType: OppositionFoundationType | undefined = OPPOSITION_FOUNDATION_TYPES.find((type: OppositionFoundationType) => stateFoundation.type === type.value)

  const references = useRef({})

  /** Documents */
  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })
  }, [])

  useEffect(() => {
    setStateFoundation(foundation)
  }, [foundation])

  /**
   * Au changement de type de foundement, on doit supprimer les fichiers qui étaient dans le state precedents
   */
  useEffect(() => {
    setFieldStatus({})
    const previewState: Foundation | undefined = previousStateFoundation
    if (previewState?.documents) {
      onDocumentsDeleted(previewState?.documents)
    }

    // on supprime les références non utilisées par le formulaire actuel
    const referencesToKeep = Object.keys(FoundationValidator.validateEditForm(stateFoundation))

    for (const reference of Object.keys(references.current)) {
      if (!referencesToKeep.includes(reference)) {
        delete references.current[reference]
      }
    }
  }, [stateFoundation.type])

  /**
   * Methode utilisée lors d'une suppression spécifique de documents:
   *  - au changement de type fondement
   *  - lorsque champ fichier disparait suite au choix de l'utilisateur
   *
   * @param foundationDocumentToDelete
   */
  const onDocumentsDeleted = (foundationDocumentToDelete: FoundationDocument[]): void => {
    const transactionDocumentsToDelete: TransactionDocument[] = FoundationService.getFoundationTransactionDocument(documents, foundationDocumentToDelete)
    // On supprime les documents du fondement
    setStateFoundation({
      ...stateFoundation,
      documents: stateFoundation.documents
        ? [...stateFoundation.documents.filter((document) =>
          !foundationDocumentToDelete.some((documentToDelete) => documentToDelete === document))]
        : []
    })
    // On supprime les documents de la transaction qui n'ont plus à être là
    setDocuments([...documents.filter((document: TransactionDocument) =>
      !transactionDocumentsToDelete.some((documentsToDelete: TransactionDocument) =>
        FoundationService.compareDocuments(document, documentsToDelete)
      )
    )])
  }

  /**
   * Permet d'ajouter un champs en référence pour le scroll sur les erreurs
   * @param element
   * @param inputId
   */
  const addToRefs = (element, inputId?: string) => {
    if (element) {
      const ref = createRef()
      ref.current = element
      references.current[inputId ?? element.id] = ref
    }
  }

  /**
   * Validation de la création / edition d'un fondement
   */
  const onFormSubmit = async (): Promise<void> => {
    const formFieldStatus: FieldStatus = FoundationValidator.validateEditForm(stateFoundation, transaction.procedureType)
    if (containsErrors(formFieldStatus)) {
      // on récupère les clés des champs en erreur
      const invalidFields = Object.keys(formFieldStatus).filter(key => formFieldStatus[key] !== '')
      if (invalidFields.length > 0) {
        let maxOffset = document.body.scrollHeight
        // on stocke l'offset top du champ en erreur qui est le plus haut dans la page
        for (const key of Object.keys(references.current)) {
          const field = invalidFields.find((field) => field === key)
          const offsetTop = field ? references.current[field]?.current?.getBoundingClientRect().top : undefined
          if (offsetTop && offsetTop < maxOffset) {
            maxOffset = offsetTop
          }
        }
        // scroll vers la position du premier champ en erreur - un espace de marge car les références sont sur les
        // inputs et pas sur leurs conteneurs (pour voir le titre du champs)
        window.scrollTo({
          top: maxOffset + window.scrollY - document.querySelector('#mainHeader').clientHeight,
          behavior: 'smooth'
        })
      }
      setFieldStatus(formFieldStatus)
    } else {
      setFieldStatus({})
      const newDocuments: TransactionDocument[] = []

      // On récupère les documents qui ne sont pas encore uploadés (qui n'ont pas d'internalName)
      const documentsToUpload: TransactionDocument[] = documents.filter((document) => !document.internalName.length)

      // On les uploads afin de les ajouter à la transaction et de récupérer leurs internalName
      for (const documentToUpload of documentsToUpload) {
        const newDocument: TransactionDocument | null = await DocumentService.createDocument(documentToUpload, transaction.id)
        newDocument && newDocuments.push(newDocument)
      }

      // On mets à jour les internalNames des documents de fondement et les documents de la transaction
      const updatedDocuments: TransactionDocument[] = [...documents].map((document: TransactionDocument) => {
        const uploadedDocument: TransactionDocument | undefined = newDocuments.find((newDocument) => newDocument.type === document.type && newDocument.createdAt === document.createdAt)
        return uploadedDocument ?? document
      })

      const updatedFoundationDocuments: FoundationDocument[] = [...stateFoundation.documents ?? []].map((foundationDocument) => {
        const uploadedDocument: TransactionDocument | undefined = newDocuments.find((newDocument) => newDocument.type === foundationDocument.type && newDocument.createdAt === foundationDocument.createdAt)
        return uploadedDocument ? { ...foundationDocument, name: uploadedDocument.internalName } : foundationDocument
      })

      await onFoundationEdited({
        ...stateFoundation,
        documents: updatedFoundationDocuments,
        internalNameDocuments: updatedFoundationDocuments.map((document: FoundationDocument) => document.name)
      }, updatedDocuments)
    }
  }

  /**
   * Methode utilisée à l'ajout d'un document
   * @param event
   * @param type
   */
  const onDocumentAdded = async (event: EventType, type: string): Promise<void> => {
    const { value } = event.target
    const createdAt: string = DateUtils.now()

    const newTransactionDocument: TransactionDocument = {
      type,
      internalName: '',
      name: value.name,
      format: value.type,
      file: value,
      createdAt
    }

    const newFoundationDocument: FoundationDocument = {
      createdAt,
      type,
      name: value.name
    }

    const foundationDocuments: FoundationDocument[] = stateFoundation.documents ?? []
    setStateFoundation({ ...stateFoundation, documents: [...foundationDocuments, newFoundationDocument] })
    setDocuments([...documents, newTransactionDocument])
  }

  /**
   * Methode utilisée à la suppression d'un document
   * @param document
   */
  const onDocumentDeleted = async (document: TransactionDocument): Promise<void> => {
    const foundationDocuments = stateFoundation.documents
    // On supprime le document du fondement
    if (foundationDocuments) {
      setStateFoundation({
        ...stateFoundation,
        documents: foundationDocuments.filter((foundationDocument: FoundationDocument) =>
          !(FoundationService.compareDocuments(document, foundationDocument)))
      })
    }
    // On supprime le documents des documents de la transaction
    setDocuments([...documents.filter((currentDocument: TransactionDocument) =>
      !(FoundationService.compareDocuments(document, currentDocument)))])
  }

  const handleProductsAndServicesChange = (products: ProductClass[]) => {
    setStateFoundation({
      ...stateFoundation,
      productsAndServicesVersions: [ProductService.createProductsAndServicesVersion(PRODUCT_AND_SERVICE_VERSION_TYPE.OPPOSITION_INITIAL_VERSION, PRODUCT_CLASS_VERSION_STATUS.ACCEPTED, products)]
    })
  }

  /**
   * Récupère les informations de la marque via la base publique et met à jour le fondement si elle existe.
   */
  const verifyNumNat = async (): Promise<Record[]> => {
    if (stateFoundation.entity?.origin) {
      const brandOrigin: string = stateFoundation.entity.origin
      const isInternationalBrand: boolean = CommonFoundationService.isInternationalBrand(brandOrigin)
      const numNat: string | undefined = isInternationalBrand ? stateFoundation.entity?.registeringNumber : stateFoundation.entity?.depositNumber
      const origin: string = isInternationalBrand ? 'WO' : brandOrigin === OPPOSITION_FOUNDATION_BRAND_ORIGIN_UE.value ? 'EU' : 'FR'
      if (numNat && origin) {
        const response: Record[] = await RecordService.verifyNumnat({ numNat, origin: [origin] })
        if (response.length) {
          return response
        } else {
          toast.error(Message.verify_numnat_error)
        }
      }
    }
    return []
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  return (
    <>
      <div className='row'>
        <SelectField
          inputId='type'
          options={foundationTypes.map((foundationType: FoundationType) => ({
            value: foundationType.code,
            label: foundationType.label
          }))}
          label={<FormattedMessage id={`${transaction.procedureType?.toLowerCase()}_fondation_type_label`} />}
          classNameFormGroup='col-5'
          onChange={(event: EventType) => setStateFoundation({ id: stateFoundation.id, type: event.target.value })}
          value={stateFoundation.type}
          placeholder={intl.formatMessage({ id: `${transaction.procedureType?.toLocaleLowerCase()}_fondation_type_placeholder` })}
          fieldStatus={fieldStatus}
          required
          resetError={setFieldStatus}
        />
      </div>
      {currentFoundationType &&
        <currentFoundationType.component
          verifyNumnat={verifyNumNat}
          countries={countries}
          procedureType={transaction.procedureType}
          setFieldStatus={setFieldStatus}
          foundation={stateFoundation}
          setFoundation={setStateFoundation}
          fieldStatus={fieldStatus}
          onDocumentAdded={onDocumentAdded}
          documents={documents}
          onDocumentDeleted={onDocumentDeleted}
          onDocumentsDeleted={onDocumentsDeleted}
          addToRefs={addToRefs}
          disableActiveField
          handleDownload={handleDownload}
        />}

      {
        transaction.procedureType === PROCEDURE_OPPOSITION.value && (stateFoundation.type === OPPOSITION_FOUNDATION_TYPE_BRAND.value ||
          stateFoundation.type === OPPOSITION_FOUNDATION_TYPE_RENOWNED.value ||
          stateFoundation.type === OPPOSITION_FOUNDATION_TYPE_UNAUTHORIZED.value) &&
            <FoundationProductsAndServices
              productClasses={stateFoundation.productsAndServicesVersions ? ProductService.getCurrentVersion(stateFoundation.productsAndServicesVersions)?.productClasses : []}
              handleProductsAndServicesChange={handleProductsAndServicesChange}
              fieldStatus={fieldStatus}
            />
      }
      <div className='step-buttons mt-4 row justify-content-center'>
        <SubmitButton
          id='stepform-prev-button'
          className='bg-white text-gris py-3 px-4'
          onClick={onFormCancel}
        >
          <FormattedMessage id='button_cancel' />
        </SubmitButton>

        <SubmitButton
          id='stepform-next-button'
          className='bg-primary py-3 px-4'
          onClick={onFormSubmit}
        >
          <FormattedMessage id='common_validate' />
        </SubmitButton>
      </div>
    </>
  )
}

export default FoundationForm
