import { EventType, FieldStatus, SwitchField, TextArea, TextField } from '@inpi-marques/components'
import { BrandTypeContentProps } from 'interfaces/Deposit'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import { storeTransactionUpdateDeposit } from '../../../../../store/transaction/transactionActions'

const WordContent: FC<BrandTypeContentProps> = ({ fieldStatus, brand }) => {
  const dispatch = useDispatch()

  const [brandText, setBrandText] = useState<string>(brand.text ?? '')
  const [hasTranslation, setHasTranslation] = useState<boolean>(!!brand.translation?.length)
  const [brandTranslation, setBrandTranslation] = useState<string>(brand.translation?.length ? brand.translation : '')

  useEffect(() => {
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, hasTranslation: hasTranslation } }))
  }, [])

  const onBrandTextChanged = (event: EventType) => {
    const { value } = event.target
    setBrandText(value)
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, text: value } }))
  }

  const onBrandTranslationChanged = (event: EventType) => {
    const { value } = event.target
    setBrandTranslation(value)
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, translation: value } }))
  }

  const onSwitchChanged = () => {
    const newValue = !hasTranslation
    setHasTranslation(newValue)
    dispatch(storeTransactionUpdateDeposit({ brand: { ...brand, hasTranslation: newValue } }))
  }

  return (
    <>
      <TextField
        inputId='word-text'
        classNameFormGroup='col-md-12 col-lg-6'
        required
        value={brandText}
        label={<FormattedMessage id='brand_type_word_text_label' />}
        onChange={onBrandTextChanged}
        fieldStatus={fieldStatus}
        nameFieldStatus='brandText'
        dispatch={dispatch}
        resetError={storeTransactionFieldStatusUpdate}
        maxLength={500}
      />

      <SwitchField
        inputId='hasTranslation'
        divClassName='col-md-12 col-lg-6 align-items-center'
        className='col-2'
        labelClassName='col-8'
        onChange={onSwitchChanged}
        value={hasTranslation}
        label={<FormattedMessage id='brand_type_has_translation_label' />}
        displayStringValue
        width={50}
        height={30}
      />

      {hasTranslation &&
        <TextArea
          inputId='word-text-translation'
          classNameFormGroup='col-md-12 col-lg-6 order-lg-4 order-md-3 mt-3'
          label={<FormattedMessage id='brand_type_word_text_translation_label' />}
          value={brandTranslation}
          fieldStatus={fieldStatus as FieldStatus}
          nameFieldStatus='brandTranslation'
          onChange={onBrandTranslationChanged}
          required
          dispatch={dispatch}
          resetError={storeTransactionFieldStatusUpdate}
        />}
    </>
  )
}

export default WordContent
