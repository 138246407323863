import React, { FC, useState } from 'react'
import {
  Preview,
  Brand
} from '@inpi-marques/components'
import { OverlayTrigger, Popover } from 'react-bootstrap'

type PreviewThumbnailTableBodyProps = {
  brand: Brand,
  file: () => File|(() => Promise<string|null>)|string,
  index: number,
  listLength?: number
};

const PreviewThumbnailTableBody : FC<PreviewThumbnailTableBodyProps> = ({
  brand,
  file,
  index,
  listLength = 0
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean[]>(new Array(listLength).fill(false))

  /**
   * Change la visibilité d'une preview
   * @param value
   * @param index
   */
  const changeShowTooltip = (value:boolean, index:number) => {
    const newShow = [...showTooltip]
    newShow[index] = value
    setShowTooltip(newShow)
  }

  return (
    <OverlayTrigger
      placement='right'
      show={showTooltip[index]}
      overlay={
        <Popover
          id='popover-positioned-bottom'
          className='popover-preview'
          onMouseEnter={() => changeShowTooltip(true, index)}
          onMouseLeave={() => changeShowTooltip(false, index)}
        >
          <Popover.Content>
            <Preview
              file={file}
              document={brand.preview || brand.file}
            />
          </Popover.Content>
        </Popover>
      }
    >
      <div onMouseEnter={() => changeShowTooltip(true, index)} onMouseLeave={() => changeShowTooltip(false, index)}>
        <Preview
          file={file}
          document={brand.preview || brand.file}
          isThumbnail
        />
      </div>
    </OverlayTrigger>
  )
}

export default PreviewThumbnailTableBody
