import { CardBlock, CommonInscriptionService, DOCUMENT_TYPES, ErrorField, EventType, FieldStatus, HelpBlock, InscriptionNatureCode, Mandatary, MultipleRadioField, NatureCode, NATURE_CODES, TextArea, Transaction, TransactionDocument } from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import ContentService from 'services/content/ContentService'
import { storeTransactionUpdateInscription } from 'store/transaction/transactionActions'
import DocumentsBlock from '../correction/DocumentsBlock'

interface MandataryFormProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    documents: TransactionDocument[],
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentEdited: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument)=> void,
}

const MandataryForm: FC<MandataryFormProps> = ({ transaction, fieldStatus, documents, onDocumentAdded, onDocumentDeleted, onDocumentEdited }) => {
  const dispatch = useDispatch()

  /** Code natures récupérées du Back */
  const [natureCodes, setNatureCodes] = useState<NatureCode[]>([])

  const [mandatary, setMandatary] = useState<Mandatary>(transaction.inscription?.mandatary ?? {})

  useEffect(() => {
    setMandatary(transaction.inscription?.mandatary ?? {})
  }, [transaction.inscription])

  useEffect(() => {
    ContentService.getNatureCodesByType(transaction.subProcedureType).then((response: NatureCode[]) => {
      setNatureCodes(response)
    })
  }, [])

  const selectedNatureCode: InscriptionNatureCode|undefined = CommonInscriptionService.getFirstCodeNature(transaction.inscription?.natureCodes ?? [])

  /**
         * A la section du code nature
         * @param natureCode
         */
  const onNatureCodeSelected = (code: string): void => {
    const natureCode: NatureCode|undefined = natureCodes.find((natureCode: NatureCode) => natureCode.code === code)
    natureCode && dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      natureCodes: natureCode ? [{ code: natureCode.code, label: natureCode.label, psDisplayType: natureCode.psDisplayType }] : [],
      mandatary: {
        newAgent: '',
        oldAgent: ''
      }
    }))
  }

  /**
   * Sauvegarde dans le store au blur
   */
  const onTextFieldBlur = (): void => {
    dispatch(storeTransactionUpdateInscription({
      ...transaction.inscription,
      mandatary
    }))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='mandatary_title' /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row justify-content-between'>
        <div className='col-6'>
          <CardBlock header={<FormattedMessage id='mandatary_nature_code_block_title' />} shadow>
            <MultipleRadioField
              inputId='nature_codes'
              classNameRadioGroup='row m-0 flex-column'
              onChange={(event: EventType) => onNatureCodeSelected(event.target.value)}
              value={selectedNatureCode?.code ?? ''}
              radio={natureCodes.map((natureCode: NatureCode) => ({ value: natureCode.code ?? '', label: natureCode.label ?? '' }))}
            />
            {fieldStatus.natureCodes &&
              <div>
                <ErrorField message={fieldStatus.natureCodes} />
              </div>}
          </CardBlock>
        </div>
        {selectedNatureCode &&
          <div className='col-6'>
            <CardBlock header={<FormattedMessage id='mandatary_block_title' />} shadow>
              {(selectedNatureCode.code === NATURE_CODES.RM || selectedNatureCode.code === NATURE_CODES.MM) &&
                <TextArea
                  inputId='oldAgent'
                  label={<FormattedMessage id={CommonInscriptionService.getOldAgentLabel(selectedNatureCode)} />}
                  value={mandatary.oldAgent}
                  onChange={(event: EventType) => setMandatary({ ...mandatary, oldAgent: event.target.value })}
                  onBlur={onTextFieldBlur}
                  required
                  fieldStatus={fieldStatus}
                  rows={5}
                />}
              {(selectedNatureCode.code === NATURE_CODES.CM || selectedNatureCode.code === NATURE_CODES.MM) &&
                <TextArea
                  inputId='newAgent'
                  label={<FormattedMessage id={CommonInscriptionService.getNewAgentLabel(selectedNatureCode)} />}
                  value={mandatary.newAgent}
                  onChange={(event: EventType) => setMandatary({ ...mandatary, newAgent: event.target.value })}
                  onBlur={onTextFieldBlur}
                  required
                  fieldStatus={fieldStatus}
                  rows={5}
                />}
            </CardBlock>
          </div>}
        <div className='col-12 mt-4'>
          <CardBlock header={<FormattedMessage id='correction_documents_block_title' />} shadow>
            <HelpBlock>
              <FormattedMessage id='documents_communicability_warning_text' />
            </HelpBlock>
            <DocumentsBlock
              onDocumentAdded={onDocumentAdded}
              onDocumentDeleted={onDocumentDeleted}
              onDocumentEdited={onDocumentEdited}
              title={<FormattedMessage id='inscription_other_files_label' />}
              documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_OTHER) ?? []}
              documentType={DOCUMENT_TYPES.INSCRIPTION_OTHER}
              transaction={transaction}
            />
            <DocumentsBlock
              onDocumentAdded={onDocumentAdded}
              onDocumentDeleted={onDocumentDeleted}
              onDocumentEdited={onDocumentEdited}
              title={<FormattedMessage id='inscription_mandatory_power_files_label' />}
              documents={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER) ?? []}
              documentType={DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER}
              transaction={transaction}
            />
          </CardBlock>
        </div>
      </div>
    </>
  )
}

export default MandataryForm
