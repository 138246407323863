import { ReplacementTitle, ROWS_10, Table, TableService, TableTitleItem } from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC, useEffect, useState } from 'react'
import ReplacementTitleRow from './ReplacementTitlesRow'

interface ReplacementTitlesTableProps {
    titles: ReplacementTitle[],
    onTitleChanged?: (title: ReplacementTitle, index: number) => void,
    onTitleRemoved?: (index: number) => void,
    readOnly?: boolean
}

const ReplacementTitlesTable: FC<ReplacementTitlesTableProps> = ({ titles, onTitleChanged, onTitleRemoved, readOnly = false }) => {
  const ndItemsPerPage = ROWS_10.value

  /** Page courante du tableau */
  const [page, setPage] = useState<number>(0)

  const [paginatedReplacementTitles, setPaginatedReplacementTitles] = useState<ReplacementTitle[][]>(TableService.getPagination(titles, ndItemsPerPage))

  useEffect(() => {
    setPaginatedReplacementTitles(TableService.getPagination(titles, ndItemsPerPage))
    setPage(0)
  }, [titles])

  /**
     * Récupération des titres du tableau
     */
  const getTableTitle = (): TableTitleItem[] => {
    const titleList: TableTitleItem[] = [
      { label: Message.frmi_deposit_number_label },
      { label: Message.frmi_deposit_date_label },
      { label: Message.frmi_registered_number_label },
      { label: Message.frmi_registered_date_label },
      { label: Message.frmi_priority_date_label },
      { label: Message.frmi_other_right_text_label }
    ]

    if (!readOnly) {
      titleList.push({ label: '' })
    }
    return titleList
  }

  return (
    paginatedReplacementTitles.length > 0 ? (
      <Table
        tableTitle={getTableTitle()}
        tableClassName='title-table w-100 mt-3'
        actualPage={page + 1}
        totalPage={paginatedReplacementTitles.length}
        onChangePagination={(value) => setPage(value - 1)}
        paginationClassName='w-100'
      >
        <tbody>
          {paginatedReplacementTitles[page].map((title: ReplacementTitle, key: number) =>
            <ReplacementTitleRow
              key={key}
              title={title}
              onTitleChanged={onTitleChanged && ((title: ReplacementTitle) => onTitleChanged(title, (page * ndItemsPerPage) + key))}
              onTitleRemoved={onTitleRemoved && (() => onTitleRemoved((page * ndItemsPerPage) + key))}
            />
          )}
        </tbody>
      </Table>)
      : <></>
  )
}

export default ReplacementTitlesTable
