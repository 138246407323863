import { SelectOption } from 'src'
import Message from './Message'

export const DOCUMENT_TYPES = {
  DEPOSIT_GUARANTEE: 'DEPOSIT_GUARANTEE',
  DEPOSIT_COLLECTIVE: 'DEPOSIT_COLLECTIVE',
  DIVIDED_BRAND_PRODUCTS_AND_SERVICES: 'DIVIDED_BRAND_PRODUCTS_AND_SERVICES',
  COPY_INTERNATIONAL_REGISTRATION: 'COPY_INTERNATIONAL_REGISTRATION',
  TRANSLATION_INTERNATIONAL_REGISTRATION: 'TRANSLATION_INTERNATIONAL_REGISTRATION',
  CERTIFICATE_CANCELLATION: 'CERTIFICATE_CANCELLATION',
  TRANSFORMATION_REQUEST_FRENCH_TRANSLATION: 'TRANSFORMATION_REQUEST_FRENCH_TRANSLATION',
  TRANSFORMATION_REQUEST_ATTACHMENT_TRANSLATION: 'TRANSFORMATION_REQUEST_ATTACHMENT_TRANSLATION',
  PROOF_OF_USE: 'PROOF_OF_USE',
  BRAND: 'BRAND',
  POWER: 'POWER',
  PRIORITY_DOCUMENTS: 'PRIORITY_DOCUMENTS',
  SIGNATURE: 'SIGNATURE',
  COVER_PAGE: 'COVER_PAGE',
  BACKGROUND_PAGE: 'BACKGROUND_PAGE',
  APPEALS: 'APPEALS',
  NOTIFICATION: 'NOTIFICATION',
  ERRATUM_REQUEST: 'ERRATUM_REQUEST',
  FAX: 'FAX',
  OTHER: 'OTHER',
  UPDATE_BRAND_LOGO: 'UPDATE_BRAND_LOGO',
  UPDATE_BRAND_AUDIO: 'UPDATE_BRAND_AUDIO',
  UPDATE_BRAND_VIDEO: 'UPDATE_BRAND_VIDEO',
  BRAND_LOGO: 'BRAND_LOGO',
  BRAND_AUDIO: 'BRAND_AUDIO',
  BRAND_VIDEO: 'BRAND_VIDEO',
  MATERIAL_IRREGULARITY: 'MATERIAL_IRREGULARITY',
  OPPOSITION_STATEMENT_MEANS: 'OPPOSITION_STATEMENT_MEANS',
  CONTESTED_BRAND_COPY: 'CONTESTED_BRAND_COPY',
  MOTIVE_BRAND_SIGNCOPY: 'MOTIVE_BRAND_SIGNCOPY',
  FOUNDATION_RENOWNED_SIGNCOPY: 'FOUNDATION_RENOWNED_SIGNCOPY',
  CONTESTED_BRAND_COPY_PERMISSION: 'CONTESTED_BRAND_COPY_PERMISSION',
  FOUNDATION_BRAND_EXISTENCE: 'FOUNDATION_BRAND_EXISTENCE',
  FOUNDATION_LOCAL_AUTHORITY_EXISTENCE: 'FOUNDATION_LOCAL_AUTHORITY_EXISTENCE',
  FOUNDATION_ENTITY_PUBLIC_EXISTENCE: 'FOUNDATION_ENTITY_PUBLIC_EXISTENCE',
  FOUNDATION_AOIG_EXISTENCE: 'FOUNDATION_AOIG_EXISTENCE',
  FOUNDATION_CORPORATE_EXISTENCE: 'FOUNDATION_CORPORATE_EXISTENCE',
  FOUNDATION_COMMERCIAL_EXISTENCE: 'FOUNDATION_COMMERCIAL_EXISTENCE',
  FOUNDATION_EPCI_EXISTENCE: 'FOUNDATION_EPCI_EXISTENCE',
  FOUNDATION_LEGITIMACY: 'FOUNDATION_LEGITIMACY',
  FOUNDATION_OPPONENT_LEGITIMACY: 'FOUNDATION_OPPONENT_LEGITIMACY',
  FOUNDATION_BRAND_COPY: 'FOUNDATION_BRAND_COPY',
  FOUNDATION_BRAND_SIGNREPRESENTATION: 'FOUNDATION_BRAND_SIGNREPRESENTATION',
  FOUNDATION_LOCAL_AUTHORITY_SIGN: 'FOUNDATION_LOCAL_AUTHORITY_SIGN',
  FOUNDATION_EPCI_SIGN: 'FOUNDATION_EPCI_SIGN',
  FOUNDATION_BRAND_SIGN: 'FOUNDATION_BRAND_SIGN',
  FOUNDATION_COMMERCIAL_SIGN: 'FOUNDATION_COMMERCIAL_SIGN',
  FOUNDATION_OWNERSHIPTRANSFER: 'FOUNDATION_OWNERSHIPTRANSFER',
  FOUNDATION_COLLECTIVITY_PROOF: 'FOUNDATION_COLLECTIVITY_PROOF',
  FOUNDATION_IG_HOMOLOGATION: 'FOUNDATION_IG_HOMOLOGATION',
  FOUNDATION_DOMAINNAME_EXISTENCE: 'FOUNDATION_DOMAINNAME_EXISTENCE',
  DECISION: 'DECISION',
  DECISION_DOCX: 'DECISION_DOCX',
  AVIS_PUBLICATION: 'AVIS_PUBLICATION',
  OVERVIEW: 'DEPOSIT_SUMMARY',
  BRAND_RECDIV_INVALIDITY_REVOCATION: 'BRAND_RECDIV_INVALIDITY_REVOCATION',
  APPLICABLE_TEXTS: 'APPLICABLE_TEXTS',
  OFFICIAL_COPY_OF_PREVIOUS: 'OFFICIAL_COPY_OF_PREVIOUS APPLICATION',
  RIGHT_TO_CLAIM_PRIORITY_JUSTIFICATION: 'RIGHT_TO_CLAIM_PRIORITY_JUSTIFICATION',
  OFFICIAL_TRANSLATION_OF_PREVIOUS_REQUEST: 'OFFICIAL_TRANSLATION_OF_PREVIOUS_REQUEST',
  RIGHT_TO_CLAIM_PRIORITY_JUSTIFICATION_TRANSLATION: 'RIGHT_TO_CLAIM_PRIORITY_JUSTIFICATION_TRANSLATION',
  INSCRIPTION_MANDATORY_POWER: 'INSCRIPTION_MANDATORY_POWER',
  INSCRIPTION_OTHER: 'INSCRIPTION_OTHER',
  INSCRIPTION_ACT_PROPERTY_CHANGE: 'INSCRIPTION_ACT_PROPERTY_CHANGE',
  INSCRIPTION_ACT_MATERIAL_IMPOSSIBILITY: 'INSCRIPTION_ACT_MATERIAL_IMPOSSIBILITY',
  INSCRIPTION_RANKING: 'INSCRIPTION_RANKING',
  MM18: 'MM18',
  OFFICIAL_DOCUMENT_JUSTIFICATION: 'OFFICIAL_DOCUMENT_JUSTIFICATION',
  REVOCATION_STATEMENT_REQUEST: 'REVOCATION_STATEMENT_REQUEST',
  REVOCATION_STATEMENT_ACT: 'REVOCATION_STATEMENT_ACT',
  REVOCATION_STATEMENT_SUPPORTING: 'REVOCATION_STATEMENT_SUPPORTING',
  GEOGRAPHICAL_INDICATION: 'GEOGRAPHICAL_INDICATION',
  OMPI_PROOF_PAYMENT: 'OMPI OMPI_PAYMENT_PROOF'
}

export const DOCUMENT_ADDITIONAL_TYPES = {
  REVOCATION_STATEMENT_ACT: 'REVOCATION_STATEMENT_ACT',
  REVOCATION_STATEMENT_SUPPORTING: 'REVOCATION_STATEMENT_SUPPORTING',
  RENEWAL_OPTIONS: 'RENEWAL_OPTIONS'
}

export const SUBPROCEDURE_DOCUMENT_TYPES = [
  DOCUMENT_TYPES.DEPOSIT_GUARANTEE,
  DOCUMENT_TYPES.DEPOSIT_COLLECTIVE,
  DOCUMENT_TYPES.COPY_INTERNATIONAL_REGISTRATION,
  DOCUMENT_TYPES.TRANSLATION_INTERNATIONAL_REGISTRATION,
  DOCUMENT_TYPES.CERTIFICATE_CANCELLATION,
  DOCUMENT_TYPES.TRANSFORMATION_REQUEST_FRENCH_TRANSLATION,
  DOCUMENT_TYPES.TRANSFORMATION_REQUEST_ATTACHMENT_TRANSLATION
]

export const DOCUMENTS_TYPES_FILTER = [
  {
    label: Message.documents_type_observation,
    value: 'THIRD_PARTY_OBSERVATION'
  },
  {
    label: Message.documents_type_observation_cg,
    value: 'OBSERVATION_CG'
  }
]

export const DOCUMENTS_TABLE_TITLES = [
  {
    label: Message.documents_name,
    className: 'text-gris border-0'
  }, {
    label: Message.documents_createdAt,
    className: 'text-gris border-0'
  }, {
    label: Message.documents_type,
    className: 'text-gris border-0'
  }, {
    label: Message.documents_communicable,
    className: 'text-gris border-0'
  }, {
    label: Message.documents_status,
    className: 'text-gris border-0'
  }, {
    label: Message.documents_archivable,
    className: 'text-gris border-0'
  }
]

export const DOCUMENTS_CUTTED_TABLE_TITLES = [
  {
    label: Message.cutting_document_table_name,
    className: 'text-gris border-0'
  }, {
    label: Message.cutting_document_table_type,
    className: 'text-gris border-0'
  }, {
    label: Message.cutting_document_table_options,
    className: 'text-gris border-0'
  },
  {
    label: '',
    className: 'action-read-col'
  }
]

export const DOCUMENT_COMMUNICABILITIES = {
  COMMUNICABLE: 'Communicable',
  NON_COMMUNICABLE: 'Non-communicable',
  PUBLIC: 'Publique',
  INTERNAL: 'Interne',
  COMMUNICABLE_PARTAGE: 'Communicable - Partagé'
}

export const DOCUMENT_STATUS = {
  NEW: 'Nouveau',
  WAITING: 'En attente',
  ACCEPTED: 'Accepté',
  REJECTED: 'Rejeté',
  TREATED: 'Traité',
  ONGOING_EXAM: 'En cours d’examen',
  WAITING_PAYMENT: 'waiting_payment',
  DELETED: 'Supprimé'
}

export const DOCUMENT_STATUS_FINAL = [
  DOCUMENT_STATUS.REJECTED,
  DOCUMENT_STATUS.TREATED,
  DOCUMENT_STATUS.ACCEPTED,
  DOCUMENT_STATUS.DELETED
]

export const DOCUMENT_FORMATS = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  TIFF: 'image/tiff',
  PDF: 'application/pdf',
  DOC: '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  MP4: 'video/mp4',
  MP3: 'audio/mpeg',
  CSV: 'text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  XML: 'text/xml',
  ZIP: 'application/zip, application/x-zip-compressed'
}

export const UPDATE_BRAND_CODES = [
  DOCUMENT_TYPES.UPDATE_BRAND_LOGO,
  DOCUMENT_TYPES.UPDATE_BRAND_VIDEO,
  DOCUMENT_TYPES.UPDATE_BRAND_AUDIO
]

export const BRAND_CODES = [
  DOCUMENT_TYPES.BRAND_LOGO,
  DOCUMENT_TYPES.BRAND_AUDIO,
  DOCUMENT_TYPES.BRAND_VIDEO
]

export const DOCUMENT_LIMITS = {
  DEPOSIT_MAX_SIZE: 10e7,
  XML_MAX_SIZE: 2e6,
  JPEG_MAX_SIZE: {
    [DOCUMENT_FORMATS.JPEG]: 2e6
  },
  JPEG_OR_MP3_MAX_SIZE: {
    [DOCUMENT_FORMATS.JPEG]: 2e6,
    [DOCUMENT_FORMATS.MP3]: 2e6
  },
  MP4_MAX_SIZE: {
    [DOCUMENT_FORMATS.MP4]: 20e6
  },
  JPEG_OR_MP4_MAX_SIZE: {
    [DOCUMENT_FORMATS.JPEG]: 2e6,
    [DOCUMENT_FORMATS.MP4]: 20e6
  },
  DEPOSIT_MULTIPLE_SIZE: {
    [DOCUMENT_FORMATS.JPEG]: 2e6,
    [DOCUMENT_FORMATS.MP3]: 2e6,
    [DOCUMENT_FORMATS.MP4]: 20e6
  }
}

export const DOCUMENT_DEFAULT = 'DEFAULT'

export const DOCUMENT_PRIORITY = [
  {
    value: DOCUMENT_TYPES.OFFICIAL_COPY_OF_PREVIOUS,
    id: 'priority_file_copy_request'
  },
  {
    value: DOCUMENT_TYPES.OFFICIAL_TRANSLATION_OF_PREVIOUS_REQUEST,
    id: 'priority_file_copy_request_traduction'
  },
  {
    value: DOCUMENT_TYPES.RIGHT_TO_CLAIM_PRIORITY_JUSTIFICATION,
    id: 'priority_file_justification'
  },
  {
    value: DOCUMENT_TYPES.RIGHT_TO_CLAIM_PRIORITY_JUSTIFICATION_TRANSLATION,
    id: 'priority_file_justification_traduction'
  }
]

export const DOCUMENT_TYPE_SUB_TYPE_REVOCATION_STATEMENT_ACT: SelectOption = {
  label: Message.document_type_sub_type_revocation_statement_act_label,
  value: DOCUMENT_TYPES.REVOCATION_STATEMENT_ACT
}

export const DOCUMENT_TYPE_SUB_TYPE_REVOCATION_STATEMENT_SUPPORTING: SelectOption = {
  label: Message.document_type_sub_type_revocation_statement_supporting_label,
  value: DOCUMENT_TYPES.REVOCATION_STATEMENT_SUPPORTING
}

export const DOCUMENT_TYPE_SUB_TYPES: SelectOption[] = [
  DOCUMENT_TYPE_SUB_TYPE_REVOCATION_STATEMENT_ACT,
  DOCUMENT_TYPE_SUB_TYPE_REVOCATION_STATEMENT_SUPPORTING
]
