import React, { FC, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import Logo from '../../common/Logo'
import HeaderNavItem from './HeaderNavItem'
import { storeLogout } from '../../store/user/userActions'
import SearchField from '../search/SearchField'
import { EditorialBlock, ModalComponent } from '@inpi-marques/components'
import MessageToInpi from '../messageToInpi/MessageToInpi'
import ContentService from '../../services/content/ContentService'

const Header: FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const currentUser = useSelector((state : RootStateOrAny) => state.user.user)
  const [showPopUp, setShowPopUp] = useState(false)

  const [editorialMessage, setEditorialMessage] = useState<string | undefined>()

  useEffect(() => {
    ContentService.getEditorialBlockByCode('HEAD').then((res: EditorialBlock) => {
      if (res.content) {
        setEditorialMessage(res.content)
      }
    })

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  return (
    <>
      <div id='mainHeader' className='px-3'>
        <ul className='nav'>
          <li className='nav-item'>
            <Logo />
          </li>
          <li className='nav-item mr-5 ml-sm-auto header-search-field'>
            <SearchField />
          </li>
          <HeaderNavItem
            key='help'
            img='/img/picto-help.svg'
            url='/aide'
            label={intl.formatMessage({ id: 'help_hover' })}
            infoHelp={intl.formatMessage({ id: 'help_hover' })}
            className='text-center'
            labelClassName='text-primary'
          />
          <HeaderNavItem
            key='contacts'
            img='/img/picto-address-book.svg'
            url='/contacts'
            label={intl.formatMessage({ id: 'breadcrumb_addresses_book' })}
            infoHelp={intl.formatMessage({ id: 'breadcrumb_addresses_book' })}
            className='text-center'
            labelClassName='text-primary'
          />
          <HeaderNavItem
            key='contact'
            img='/img/picto-mail.svg'
            onClick={() => setShowPopUp(true)}
            noRedirection
            label={intl.formatMessage({ id: 'contact_hover' })}
            infoHelp={intl.formatMessage({ id: 'contact_hover' })}
            className='text-center'
            labelClassName='text-primary'
          />
          <HeaderNavItem
            key='my-profile'
            img='/img/picto-user.svg'
            url='/mon-compte'
            label={`${intl.formatMessage({ id: 'user_hover' })}`}
            infoHelp={`${intl.formatMessage({ id: 'user_hover' })} : ${currentUser.firstname} ${currentUser.lastname}`}
            className='text-center'
            labelClassName='text-primary'
          />
          <HeaderNavItem
            key='logout'
            img='/img/picto-logout.svg'
            onClick={() => dispatch(storeLogout())}
            url={process.env.REACT_APP_URL_PROCEDURES_HOME}
            label={intl.formatMessage({ id: 'logout_hover' })}
            infoHelp={intl.formatMessage({ id: 'logout_hover' })}
            className='text-center'
            labelClassName='text-primary'
          />
        </ul>
        <ModalComponent
          title={<FormattedMessage id='contact_title' />}
          customContent={() => <MessageToInpi setShowPopUp={setShowPopUp} />}
          handleClose={() => setShowPopUp(false)}
          show={showPopUp}
          hideFooter
          size='xl'
        />
      </div>
      {editorialMessage && (
        <div className='header-msg' dangerouslySetInnerHTML={{ __html: editorialMessage }} />
      )}
    </>
  )
}

export default Header
