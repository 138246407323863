import { DateField, DateUtils, DATE_ISO, EventType, FRMI, TextField } from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface NullificationContentProps {
    frmi?: FRMI,
    onFieldBlur: (field: string, value: string) => void
}

const NullificationContent: FC<NullificationContentProps> = ({ frmi, onFieldBlur }) => {
  const [officeName, setOfficeName] = useState<string>(frmi?.officeName ?? '')
  const [invalidationAuthority, setInvalidationAuthority] = useState<string>(frmi?.invalidationAuthority ?? '')

  return (
    <div>
      {/* Nom de l'office */}
      <TextField
        classNameFormGroup='col-4'
        inputId='officeName'
        value={officeName}
        label={<FormattedMessage id='frmi_nullification_office_name_label' />}
        onChange={(event: EventType) => setOfficeName(event.target.value)}
        onBlur={(event: EventType) => onFieldBlur(event.target.name, officeName)}
      />
      {/* Date de notification de l'enregistrement international */}
      <DateField
        inputId='notificationDate'
        classNameFormGroup='col-4'
        required
        value={DateUtils.formateDateToFormat(frmi?.notificationDate, DATE_ISO) ?? ''}
        label={<FormattedMessage id='frmi_notification_date_label' />}
        onChange={(event: EventType) => onFieldBlur(event.target.name, DateUtils.formatToBeginOfDay(event.target.value))}
      />
      {/* Date à laquelle l’invalidation a été prononcée */}
      <DateField
        inputId='nullificationDate'
        classNameFormGroup='col-4'
        required
        value={DateUtils.formateDateToFormat(frmi?.nullificationDate, DATE_ISO) ?? ''}
        label={<FormattedMessage id='frmi_nullification_date_label' />}
        onChange={(event: EventType) => onFieldBlur(event.target.name, DateUtils.formatToBeginOfDay(event.target.value))}
      />
      {/* Date à laquelle l’invalidation prend effet (le cas échéant) */}
      <DateField
        inputId='effectDate'
        classNameFormGroup='col-4'
        required
        value={DateUtils.formateDateToFormat(frmi?.effectDate, DATE_ISO) ?? ''}
        label={<FormattedMessage id='frmi_nullification_effect_date_label' />}
        onChange={(event: EventType) => onFieldBlur(event.target.name, DateUtils.formatToBeginOfDay(event.target.value))}
      />
      {/* Autorité qui a prononcé l'invalidation */}
      <TextField
        classNameFormGroup='col-4'
        inputId='invalidationAuthority'
        value={invalidationAuthority}
        label={<FormattedMessage id='frmi_invalidation_authority_label' />}
        onChange={(event: EventType) => setInvalidationAuthority(event.target.value)}
        onBlur={(event: EventType) => onFieldBlur(event.target.name, invalidationAuthority)}
      />
    </div>
  )
}

export default NullificationContent
