import React, { FC } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import ETSAccountCredentials from './ETSAccountRequestCredentials'
import ETSAccount from './ETSAccount'
import {
  ACCESS_DEMAND_ITEM_ADD_AGENT,
  ACCESS_DEMAND_ITEM_ADD_HOLDER,
  ACCESS_DEMAND_ITEM_DEACTIVATE_AGENT,
  ACCESS_DEMAND_ITEM_EDIT_AGENT,
  ACCESS_DEMAND_ITEM_TMC
} from '../../constants/ETSAccountConstants'
import ETSAccountPM from './ETSAccountPM'
import ETSAccountTMC from './ETSAccountTMC'
import ETSAccountHolder from './ETSAccountHolder'

const ETSRoutes: FC<RouteComponentProps> = ({
  match
}) => (
  <Switch>
    <Route path={`${match.url}/code-acces`} render={() => <ETSAccountCredentials />} />
    <Route path={`${match.url}/nouveau-compte`} render={() => <ETSAccount ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_ADD_AGENT} />} />
    <Route path={`${match.url}/modification-compte`} render={() => <ETSAccount ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_EDIT_AGENT} />} />
    <Route path={`${match.url}/detachement-compte`} render={() => <ETSAccount ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_DEACTIVATE_AGENT} />} />
    <Route path={`${match.url}/attachement-pm`} render={() => <ETSAccountPM />} />
    <Route path={`${match.url}/attachement-tmc`} render={() => <ETSAccountTMC ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_TMC} />} />
    <Route path={`${match.url}/nouveau-titulaire`} render={() => <ETSAccountHolder ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_ADD_HOLDER} />} />
  </Switch>
)

export default ETSRoutes
