import React, { FC } from 'react'
import {
  FRMI
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'

interface LicenceWriteOffOverviewProps {
    frmi: FRMI
}

const LicenceWriteOffOverview: FC<LicenceWriteOffOverviewProps> = ({ frmi }) => {
  return (
    <>
      <h4 className='font-weight-bold'>
        <FormattedMessage id='frmi_radiation_description_label' />
      </h4>
      {
          frmi?.radiationDescription &&
            <span>{frmi?.radiationDescription}</span>
      }
    </>
  )
}

export default LicenceWriteOffOverview
