import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Badge } from '@inpi-marques/components'

interface CountItemProps {
  url: string,
  label: string,
  badge?: number,
  className?: string
}

const CountItem: FC<CountItemProps> = ({
  url,
  label,
  badge = 0,
  className = ''
}) => (
  <div className={`w-100 ${className}`}>
    <Link to={url} className='text-decoration-none'>
      <div className='item d-flex justify-content-between h-100'>
        <span className='text-body my-auto'>
          <FormattedMessage id={label} />
        </span>
        <Badge className={`my-auto d-flex pl-2 mr-0 justify-content-center ${badge <= 0 ? 'badge-none' : 'badge-secondary'}`}>
          {badge === -1
            ? <FontAwesomeIcon className='loader' icon={faSpinner} />
            : <span>{badge}</span>}
        </Badge>
      </div>
    </Link>
  </div>
)

export default CountItem
