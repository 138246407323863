import { EventType, FieldStatus, SelectField, SelectOption } from '@inpi-marques/components'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'

interface EditableSelectFieldProps{
  label: string,
  isEditable: boolean,
  fieldStatus?: FieldStatus,
  value?: string|number|undefined,
  displayValue?: string,
  options: SelectOption[],
  required?: boolean,
  classNameFormGroup?: string,
  className?: string,
  onChange?: (event: EventType) => void,
  translateValue?: boolean,
  disabled?: boolean,
  hideCarret?: boolean, // ne pas afficher de tiret si pas en mode edition et pas de value
  noPlaceholder?: boolean
}

const EditableSelectField: FC<EditableSelectFieldProps> = (
  {
    label,
    isEditable,
    fieldStatus,
    value,
    displayValue,
    required = false,
    className = 'mb-3 w-75',
    onChange,
    options,
    translateValue = false,
    disabled,
    hideCarret = false,
    classNameFormGroup,
    noPlaceholder = false
  }) => {
  const intl = useIntl()
  return isEditable ? (
    <SelectField
      classNameFormGroup={classNameFormGroup}
      className={className}
      inputId={label}
      placeholder={noPlaceholder ? undefined : intl.formatMessage({ id: 'placeholder_select' })}
      value={value}
      options={options}
      onChange={onChange}
      required={required}
      fieldStatus={fieldStatus}
      disabled={disabled}
    />
  ) : <>{displayValue || (value ? (translateValue ? intl.formatMessage({ id: value.toLowerCase() }) : value) : hideCarret ? '' : '-')}</>
}

export default EditableSelectField
