import React, { FC, useEffect, useState } from 'react'
import {
  DOCUMENT_FORMATS,
  DOCUMENT_TYPES,
  downloadFile,
  EventType,
  FileBrowserField,
  FormatUtils,
  ModalComponent,
  NOTIF_TYPE_NO_RESPONSE_EXPECTED,
  Notification,
  NOTIFICATION_STATUS_CLOSED,
  Transaction,
  TransactionDocument,
  SubmitButton,
  DateUtils, NOTIFICATION_TYPE_NO_RESPONSE_EXPECTED, HelpBlock, PRODUCT_CLASS_VERSION_STATUS,
  Payment, NOTIFICATION_TYPE_INFORMATION_MAIL
} from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { faChevronDown, faChevronUp, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import NotificationService from '../../../services/transaction/NotificationService'
import PriceService from '../../../services/content/PriceService'
import DocumentService from '../../../services/document/DocumentService'
import { toast } from 'react-toastify'

interface NotificationsAnswerFormProps {
  transaction: Transaction,
  initialNotification: Notification,
  index: number,
  setReload?: ()=>void,
  scrollToProductAndService?: (id: string) => void,
  answerNotification?: boolean,
  setNotificationToAnswer: (notification: Notification|null) => void
}

const NotificationsAnswerForm: FC<NotificationsAnswerFormProps> = ({
  transaction,
  initialNotification,
  index,
  setReload,
  scrollToProductAndService,
  answerNotification = false,
  setNotificationToAnswer
}) => {
  const RESPONSE_TYPE_AGREEMENT_NOTIFICATION = 'AGREEMENT_NOTIFICATION'

  const [answerComment] = useState<string>(initialNotification.answerComment || '')
  const [notification, setNotification] = useState<Notification>(initialNotification)
  const [showSendModal, setShowSendModal] = useState(false)
  const [documents, setDocuments] = useState<TransactionDocument[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // La partie autres types de réponses est visible pour le cas des notifs avec attente de réponse
  // ou si une partie de réponse a déjà été apportée sur une notif d'office
  const [seeOptions, setSeeOptions] = useState<boolean>(notification.type !== RESPONSE_TYPE_AGREEMENT_NOTIFICATION ||
    (documents.length > 0 || (transaction.deposit?.productsAndServicesVersions?.find(v => v.notificationId === notification.id))))
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    if (answerNotification) {
      NotificationService.answerNotification(transaction.id, notification.id, {}).then((newNotification) => {
        setNotification(newNotification)
        setNotificationToAnswer(null)
        setReload && setReload()
      })
    }
  }, [answerNotification])

  useEffect(() => {
    setSeeOptions(notification.type !== RESPONSE_TYPE_AGREEMENT_NOTIFICATION ||
      (documents.length > 0 || (transaction.deposit?.productsAndServicesVersions?.find(v => v.notificationId === notification.id))))
  }, [transaction.deposit?.productsAndServicesVersions])

  /**
   * Gère l'envoi de la réponse à la notification
   */
  const handleSend = async () => {
    setIsLoading(true)
    const answerNotification = {
      answerComment
    }

    const newTransaction: Transaction = {
      ...transaction,
      notifications: transaction.notifications?.map((notif) => notif.id === notification.id ? notification : notif)
    }

    // Mise à jour des documents
    let updatedDocuments = [...documents]
    for (const document of documents) {
      const updatedDocument: TransactionDocument | null = await DocumentService.createDocument(document, transaction.id)
      updatedDocuments = updatedDocuments.map(d => {
        if (d.name === updatedDocument.name) {
          return { ...d, internalName: updatedDocument.internalName }
        }

        return d
      })
    }

    setDocuments(updatedDocuments)

    // Mise à jour de la notification
    return NotificationService.edit(newTransaction, {
      ...notification,
      supportingDocuments: updatedDocuments.map(d => d.internalName)
    }).then(() => {
      return NotificationService.answerNotification(transaction.id, notification.id, answerNotification).then((newNotification) => {
        toast.success(intl.formatMessage({ id: 'overview_transaction_notification_success_answer' }))
        setNotification(newNotification)
        setReload && setReload()
        setShowSendModal(false)
      })
    }).finally(() => setIsLoading(false))
  }

  const handleDownload = (document: TransactionDocument) => {
    return DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => {
      downloadFile(data, document.name)
    })
      .then(async () => {
        // Cas Notification à lire
        if (document.internalName === notification.file.internalName && !isNotificationClosed() && !responseIsNeeded()) {
          // au téléchargement, on passe la notification à lue
          await handleSend()
        }
      })
  }

  const isNotificationClosed = () : boolean => {
    return !!NOTIFICATION_STATUS_CLOSED.find((status) => status.value === notification.status)
  }
  const responseIsNeeded = () : boolean => {
    return notification.type !== NOTIF_TYPE_NO_RESPONSE_EXPECTED && notification.type !== NOTIFICATION_TYPE_INFORMATION_MAIL.value
  }

  const onDocumentChange = (event: EventType) => {
    const { value } = event.target

    const newFile: TransactionDocument = {
      name: value.name,
      internalName: '',
      format: value.type,
      type: DOCUMENT_TYPES.NOTIFICATION,
      file: value
    }

    setDocuments([...documents, newFile])
  }

  const onDocumentDelete = (event: EventType) => {
    const updatedDocuments = [...documents]
    setDocuments(updatedDocuments.filter(doc => doc.name !== event.target.value.name))
  }

  const hasPendingPayment = (): boolean => {
    return transaction?.payments?.find(
      (payment: Payment) =>
        payment.objectIds?.find((object: string) => object === notification.id) &&
        (payment.status === 'pending' || payment.status === 'paid'))
  }

  return (
    <form className='is-validated'>
      {
        notification.paymentAttached &&
        PriceService.getTotalAmount(notification.prices) !== 0 &&
          <div className='border-bottom mb-3 pb-3'>
            <h3><FormattedMessage id='overview_transaction_notification_payment' /></h3>
            <p className='font-weight-bold'>
              <FormattedMessage id='overview_transaction_notification_amount_to_pay' /> : {FormatUtils.formatToStringPrice(PriceService.getTotalAmount(notification.prices))}
            </p>
            {notification.paid
              ? <p><FormattedMessage id='overview_transaction_notification_already_paid' />{notification.answeredAt && <><FormattedMessage id='at' />{DateUtils.formatDateFr(notification.answeredAt)}</>}</p>
              : (
                <>
                  {hasPendingPayment() &&
                    <FormattedMessage
                      id='error_payment_notification_already_exist'
                    />}
                  <div className='d-flex justify-content-center'>
                    <button
                      type='button'
                      className='btn btn-primary mr-2'
                      onClick={() => history.push(`/paiement/${transaction.id}/notifications/${notification.id}`)}
                      disabled={hasPendingPayment()}
                    >
                      <FormattedMessage id='overview_transaction_notification_payment_button' />
                      <FontAwesomeIcon className='ml-2' icon={faCreditCard} />
                    </button>
                  </div>
                </>
              )}
          </div>

      }
      <div className='row m-0'>
        <h3><FormattedMessage id='overview_transaction_notification_answer' /></h3>
      </div>
      {
        NotificationService.getNotificationReceiptDate(notification) &&
          <div className='reception-confirm mt-3'><FormattedMessage id='overview_transaction_notification_answer_receipt' />{NotificationService.getNotificationReceiptDate(notification)}</div>
      }
      {notification.type === RESPONSE_TYPE_AGREEMENT_NOTIFICATION &&
        <>
          <div className='d-flex justify-content-around mt-3'>
            <button disabled={documents.length > 0 || (transaction.deposit?.productsAndServicesVersions?.find(v => v.notificationId === notification.id && v.status !== PRODUCT_CLASS_VERSION_STATUS.DRAFT_FO))} type='button' className='btn btn-primary' onClick={() => setShowSendModal('isAgreement')}>
              <FormattedMessage id='overview_transaction_notification_agreement_title' />
            </button>
          </div>
          <div className='d-flex justify-content-end'>
            <SubmitButton className='btn btn-link text-primary' onClick={() => setSeeOptions(!seeOptions)}>
              <span className='pr-2'><FormattedMessage id='overview_transaction_notification_more_options' /></span>
              {seeOptions ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </SubmitButton>
          </div>
        </>}
      {seeOptions &&
        <div className='d-flex flex-column justify-content-center mt-3'>
          <FileBrowserField
            inputId={`documents-${index}`}
            className='w-100 mb-1'
            classNameLabel='w-100'
            buttonLabel={
              <div className='btn btn-outline-primary text-unset col-12'>
                <FormattedMessage id='overview_transaction_notification_documents' />
              </div>
            }
            acceptApplication={DOCUMENT_FORMATS.PDF}
            value={documents}
            onChange={onDocumentChange}
            onDelete={onDocumentDelete}
            handleDownload={handleDownload}
          />
          {
            notification.regularizationAsked && (
              !(transaction.deposit?.productsAndServicesVersions?.find(v => v.notificationId === notification.id && v.status !== PRODUCT_CLASS_VERSION_STATUS.DRAFT_FO))
                ? (
                  <span
                    className='btn btn-outline-primary text-unset col-12'
                    onClick={() => scrollToProductAndService(notification.id)}
                  >
                    <FormattedMessage id='overview_transaction_notification_regularisation' />
                  </span>
                ) : (
                  <span>
                    <FormattedMessage id='overview_transaction_notification_regularisation_done' />
                  </span>
                ))
          }
          <div className='d-flex justify-content-around pt-2'>
            <SubmitButton className='btn btn-primary' disabled={!documents.length && !(transaction.deposit?.productsAndServicesVersions?.find(v => v.notificationId === notification.id && v.status !== PRODUCT_CLASS_VERSION_STATUS.DRAFT_FO))} onClick={() => setShowSendModal(true)}>
              <FormattedMessage id='overview_transaction_notification_answer_button' />
            </SubmitButton>
          </div>
          {
            notification.type !== NOTIFICATION_TYPE_NO_RESPONSE_EXPECTED.value && notification.type !== NOTIFICATION_TYPE_INFORMATION_MAIL.value &&
              <div className='d-flex justify-content-around mt-3'>
                <HelpBlock className='mb-2'><FormattedMessage id='overview_transaction_notification_send_answer_info' /></HelpBlock>
              </div>
          }
        </div>}

      <ModalComponent
        title={<FormattedMessage id='overview_transaction_notification_send_title' />}
        customContent={() => (
          <>
            {showSendModal === 'isAgreement'
              ? (
                <>
                  <div className='text-underline pb-2'>
                    <FormattedMessage id='overview_transaction_notification_send_inpi' />
                  </div>
                  <FormattedMessage
                    id='overview_transaction_notification_answer_description'
                    values={{
                      linebreak: <br />
                    }}
                  />
                </>
              ) : <div><FormattedMessage id='overview_transaction_notification_modal_send_message' /> ?</div>}
            <div className='mt-4 d-flex justify-content-center'>
              <SubmitButton className='btn-outline-primary mr-2' disabled={isLoading} onClick={() => setShowSendModal(false)}>
                <FormattedMessage id='button_cancel' />
              </SubmitButton>
              <SubmitButton className='btn-primary' onClick={handleSend}>
                <FormattedMessage id='common_validate' />
              </SubmitButton>
            </div>
          </>
        )}
        show={showSendModal}
        hideFooter
        isNotCancellable
      />
    </form>
  )
}

export default NotificationsAnswerForm
