import {
  BASIC_DATE,
  Brand,
  DateUtils,
  DEPOSIT_TYPE_EUROPEAN,
  DEPOSIT_TYPE_INTERNATIONAL, DOCUMENT_FORMATS,
  Preview,
  PROCEDURE_DIVISION,
  Record,
  STATUS_DEPOSIT,
  Transaction
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import DocumentService from '../../../services/document/DocumentService'
import { BRAND_TYPES_ALL } from '../../../constants/BrandConstants'

interface DepositBrandOverviewProps {
  brand: Brand,
  transaction: Transaction| Record
}

const DepositBrandOverview: FC<DepositBrandOverviewProps> = ({ brand, transaction }) => {
  const getBrandFile = () => {
    if (brand.file?.previewData) {
      return brand.file?.previewData
    } else if (brand?.file?.url) {
      return brand.file?.url
    } else {
      if (!transaction.details) {
        return () => DocumentService.getBrandPreviewDocument(transaction, brand.preview || brand.file)
      } else {
        // Cas d'un dossier dans le référentiel
        return () => DocumentService.getBrandPreviewDocumentRecord(brand.preview || brand.file, transaction)
      }
    }
  }

  const getBrandType = (): string => {
    if (transaction.deposit?.brand?.type) {
      return BRAND_TYPES_ALL.find((type) => type.value === transaction.deposit.brand.type)?.title ?? ''
    }
    return ''
  }

  const getDepositTypeLabel = (): string => {
    let label: string = 'overview_brand_deposit_type'

    if (transaction.subProcedureType === DEPOSIT_TYPE_EUROPEAN.value || transaction.subProcedureType === DEPOSIT_TYPE_INTERNATIONAL.value) {
      label = `overview_brand_deposit_type_${transaction.subProcedureType.toLowerCase()}`
    }
    return label
  }

  const getBrandNumberLabel = (): string => {
    let label: string = 'overview_brand_number_label'

    if (transaction.subProcedureType === DEPOSIT_TYPE_EUROPEAN.value || transaction.subProcedureType === DEPOSIT_TYPE_INTERNATIONAL.value) {
      label = `overview_brand_number_label_${transaction.subProcedureType.toLowerCase()}`
    }
    return label
  }

  return (
    <div className='row d-flex flex-wrap'>
      {(brand.file?.previewData || brand.preview || brand.file) &&
        <div className='col-5'>
          <Preview
            file={getBrandFile()}
            document={brand.preview || brand.file}
            isPrintFormat={transaction.status === STATUS_DEPOSIT.DRAFT && brand.file?.format !== DOCUMENT_FORMATS.MP4 && brand.file?.format !== DOCUMENT_FORMATS.MP3}
          />
        </div>}
      <div className='col-7 overview__brand-informations'>
        {transaction.deposit?.brand?.type &&
          <div>
            <h4 className='font-weight-bold'><FormattedMessage id={getDepositTypeLabel()} /></h4>
            <p className='subtitle'> <FormattedMessage id={getBrandType()} /> </p>
          </div>}
        {transaction.deposit?.numNatToTransformOrDivide &&
          <div>
            <h4 className='font-weight-bold'><FormattedMessage id={getBrandNumberLabel()} /></h4>
            <p className='subtitle'> {transaction.deposit.numNatToTransformOrDivide} </p>
          </div>}
        {transaction.deposit?.createdAtToTransformOrDivide &&
          <div>
            <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_date_label' /></h4>
            <p className='subtitle'> {DateUtils.formateDateToFormat(transaction.deposit.createdAtToTransformOrDivide, BASIC_DATE)} </p>
          </div>}
        {transaction.deposit?.registeredDate &&
          <div>
            <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_registered_date_bopi' /></h4>
            <p className='subtitle'> {DateUtils.formateDateToFormat(transaction.deposit?.registeredDate, BASIC_DATE)} </p>
          </div>}
        {brand.text &&
          <div>
            <h4 className='font-weight-bold'>
              <FormattedMessage id={transaction.procedureType === PROCEDURE_DIVISION.value ? 'division_overview_brand_text' : 'overview_brand_text'} />
            </h4>
            <p className='subtitle'>{brand.text}</p>
          </div>}
        {brand.translation &&
          <div>
            <h4 className='font-weight-bold'><FormattedMessage id='overview_brand_translation' /></h4>
            <p className='subtitle'>{brand.translation}</p>
          </div>}
        {brand.colors &&
          <div>
            <h4 className='font-weight-bold'><FormattedMessage id='brand_type_colors_text_label' /></h4>
            <p className='subtitle'>{brand.colors}</p>
          </div>}
        {brand.description &&
          <div>
            <h4 className='font-weight-bold'><FormattedMessage id='overview_brand_description' /></h4>
            <p className='subtitle'>{brand.description}</p>
          </div>}
      </div>
    </div>
  )
}

export default DepositBrandOverview
