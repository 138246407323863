import {
  DateUtils,
  DOCUMENT_TYPE_SUB_TYPES,
  DownloadLink,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_REVOCATION_STATEMENT,
  SelectOption,
  Table,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Message from '@inpi-marques/components/src/constants/Message'
import { RootStateOrAny, useSelector } from 'react-redux'

interface DocumentListProps {
    documents: TransactionDocument[],
    transaction?: Transaction,
    showDeleteButton?: boolean,
    downloadDocument?: (transactionId: string, internalName: string) => void,
    setDocuments?: (documents: TransactionDocument[]) => void,
    setTransaction?: (transaction: Transaction) => void
}

const DocumentList: FC<DocumentListProps> = ({
  documents,
  transaction,
  showDeleteButton,
  downloadDocument,
  setDocuments
}) => {
  const currentUser = useSelector((state: RootStateOrAny) => state.user.user)

  const handleDeleteDocument = (document: TransactionDocument) => {
    setDocuments && setDocuments((previousDocuments: TransactionDocument[]) => previousDocuments.filter((previousDocument: TransactionDocument) => document !== previousDocument))
  }

  const notAuthorizedUser = (): boolean => {
    return transaction.ETSAccounts?.filter(etsAccount => etsAccount.email === currentUser.email && etsAccount.state === 'ACTIVE').length === 0
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      {
        documents?.length > 0 ? (
          <Table
            tableTitle={[
              { label: Message.documents_name },
              { label: Message.documents_type },
              ...(transaction?.procedureType === PROCEDURE_REVOCATION_STATEMENT.value ? [{ label: Message.documents_subtype }] : []),
              { label: Message.documents_import_date },
              ...(transaction?.procedureType === PROCEDURE_INSCRIPTION.value ? [{ label: Message.documents_communicable }] : []),
              ...(downloadDocument ? [{ label: Message.documents_download }] : []),
              ...(showDeleteButton ? [{ label: Message.actions }] : [])
            ]}
          >
            <tbody>
              {
                documents.map((document: TransactionDocument, index: number) => {
                  const { name, createdAt, internalName, type, typeLabel, description, additionalType } = document

                  return (
                    <tr key={`document-${index}`}>
                      <td className='text-break'>{name}</td>
                      <td className='text-break'>{description ?? (typeLabel ?? <FormattedMessage id={`documents_type_${type?.toLowerCase()}`} />)}</td>
                      {
                        transaction?.procedureType === PROCEDURE_REVOCATION_STATEMENT.value &&
                          <td className='text-break'>{DOCUMENT_TYPE_SUB_TYPES.find((type: SelectOption) => type.value === additionalType)?.label}</td>
                      }
                      <td>{!internalName ? DateUtils.formatDateFr(new Date()) : DateUtils.formatDateFr(createdAt)}</td>
                      {
                        transaction?.procedureType === PROCEDURE_INSCRIPTION.value && (
                          <td>
                            <span>{document.communicability}</span>
                          </td>
                        )
                      }
                      {
                        downloadDocument &&
                          (
                            <td>
                              <DownloadLink
                                disabled={!internalName}
                                onClick={() => downloadDocument(transaction.id, internalName)}
                                label={<FormattedMessage id='documents_download' />}
                                classname='justify-content-start'
                              />
                            </td>
                          )
                      }
                      {
                        showDeleteButton && (
                          <td>
                            <span
                              className='icon-delete cursor-pointer text-danger'
                              onClick={() => handleDeleteDocument(document)}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </span>
                          </td>
                        )
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        ) : (
          notAuthorizedUser()
            ? <FormattedMessage id='documents_not_authorized' /> : <FormattedMessage id='documents_empty' />
        )
      }
    </IntlProvider>
  )
}
export default DocumentList
