import { FoUserResponse } from '../../interfaces/User'
import {
  Contributor,
  DEFAULT_CONTRIBUTOR, DEPOSIT_CONTRIBUTORS_TYPES, DOCUMENT_TYPES, EventType,
  PERSONNE_MORALE,
  PERSONNE_PHYSIQUE, PROCEDURE_FRMI, Title,
  Transaction, TransactionDocument
} from '@inpi-marques/components'
import DocumentService from '../document/DocumentService'
import TransactionService from '../transaction/TransactionService'
import store from '../../store/store'
import { storeTransactionUpdate } from '../../store/transaction/transactionActions'

class ContributorService {
    /**
     * Construit un intervenant à partir de l'utilisateur FO en paramètre
     * @param user Utilisateur FO
     */
    buildContributorFromUser = (user: FoUserResponse): Contributor => {
      const contributor = {
        idUser: user.id,
        email: user.email
      }
      if (user.hasCompany) {
        return {
          ...contributor,
          type: PERSONNE_MORALE.value,
          phone: user.officePhone,
          companyName: user.companyName,
          legalForm: user.legalForm,
          siren: user.siren,
          civilityCode: user.civilityCode,
          lastname: user.lastname,
          firstname: user.firstname,
          address: {
            label: user.address1Company,
            complement: user.address2Company,
            city: user.cityCompany,
            zipCode: user.zipCodeCompany,
            country: user.countryCodeCompany
          }
        }
      } else {
        return {
          ...contributor,
          type: PERSONNE_PHYSIQUE.value,
          civilityCode: user.civilityCode,
          lastname: user.lastname,
          firstname: user.firstname,
          phone: user.mobilePhone,
          address: {
            label: user.address1,
            complement: user.address2,
            city: user.city,
            zipCode: user.zipCode,
            country: user.countryCode
          }
        }
      }
    }

  /**
   * Construit un signataire à partir de l'utilisateur FO en paramètre
   * @param user
   */
  buildDefaultSignatoryFromUser = (user: FoUserResponse): Contributor => {
    return {
      idUser: user.id,
      email: user.email,
      type: PERSONNE_PHYSIQUE.value,
      civilityCode: user.civilityCode,
      lastname: user.lastname,
      firstname: user.firstname,
      phone: user.mobilePhone
    }
  }

    /**
     * Retourne true si l'objet passer en paramètre est de type DepositIntervenant
     * @param object
     */
    isIntervenant = (object: any): object is Contributor => !!object && object !== DEFAULT_CONTRIBUTOR

    /**
     * Récupère tous les intervenants distincts présents dans une transaction.
     * @param transaction
     * @param contributorsType liste des intervenants à récupérer
     */
    findAllDistinctContributors = (transaction: Transaction, contributorsType?: string[]) => {
      let allContributors = Object.entries(transaction)
        .filter(([key]) => !contributorsType || contributorsType.includes(key))
        .map(([, value]) => value)
        .reduce((a, b) => a.concat(b), [])
        .filter(this.isIntervenant)

      // dans le cas des FRMI on veut avoir les titulaires des titres ajoutés dans la liste des intervenants que
      // l'on peut copier
      if (transaction.procedureType === PROCEDURE_FRMI.value && contributorsType && contributorsType?.length > 0) {
        allContributors = [...allContributors, ...(transaction.frmiDetails?.titles?.map((title: Title) => title.holders)?.flat(1)) ?? []]
      }

      return allContributors.reduce((accumulator: Contributor[], contributor: Contributor) => {
        // Si l'intervenant à un companyName et que son companyName est différent de tous les intervenants (qui ont un companyName)
        // Ou si l'intervenant à un firstName et lastname et que son firstName et son lastName sont différents de tous les intervenants (qui ont un firstName et un lastName)
        // Alors on ajoute l'intervenant à la liste
        if (!!contributor && ((contributor.type === PERSONNE_MORALE.value && accumulator.every(accumulatorIntervenant => !accumulatorIntervenant.companyName || accumulatorIntervenant.companyName !== contributor.companyName)) ||
                (contributor.type === PERSONNE_PHYSIQUE.value && accumulator.every(accumulatorIntervenant => (!accumulatorIntervenant.firstname || !accumulatorIntervenant.lastname) ||
                (accumulatorIntervenant.firstname !== contributor.firstname || accumulatorIntervenant.lastname !== contributor.lastname))))) {
          return [...accumulator, contributor]
        } else {
          return accumulator
        }
      }, [])
    }

  /**
   * Enregistre un document de pouvoir d'un intervenant
   * @param event
   * @param contributor
   * @param transaction
   * @param contributorType
   */
  addPowerDocument = async (event: EventType, contributor: Contributor|undefined, transaction: Transaction, contributorType: string) : Promise<Contributor> => {
    const { value } = event.target

    // Suppression du document de pouvoir existant pour le remplacer par le nouveau
    if (contributor && contributor.internalNameDocuments && contributor.internalNameDocuments.length === 1) {
      await DocumentService.deleteDocument(contributor.internalNameDocuments[0], transaction)
    }
    let newDocument : TransactionDocument = {
      ...value,
      type: DOCUMENT_TYPES.POWER,
      file: value
    }
    newDocument = await DocumentService.createDocument(newDocument, transaction.id)

    if (newDocument && newDocument.internalName) {
      // On met à jour directement la transaction pour ne pas attendre un enregistrement sur un potentiel clique sur étape suivante
      const newTransaction: Transaction | null = await TransactionService.updateTransaction(transaction.id, { [contributorType]: { [contributorType]: { ...contributor, internalNameDocuments: [...contributor?.internalNameDocuments ?? [], newDocument.internalName] } } })
      newTransaction && store.dispatch(storeTransactionUpdate(newTransaction))
    }

    return { ...contributor, internalNameDocuments: [newDocument?.internalName] }
  }

  /**
   * Supprime le document de pouvoir associé à l'intervenant
   */
  deletePowerDocument = async (contributor: Contributor|undefined, transaction: Transaction, contributorType: string): Promise<Contributor|undefined> => {
    if (contributor && contributor.internalNameDocuments && contributor.internalNameDocuments.length === 1) {
      const contributorPowerFileName: string|undefined = contributor.internalNameDocuments.find((name: string) => transaction.documents?.find((document: TransactionDocument) => document.internalName === name))

      if (contributorPowerFileName) {
        /**
         * Si on supprime le pouvoir d'un signataire, qui est aussi le pouvoir du mandataire, on ne supprime que
         * le nom du fichier dans le signataire, sinon, on supprime le fichier
         */
        if (contributorType === DEPOSIT_CONTRIBUTORS_TYPES.SIGNATORY.value && transaction.documents && transaction.documents.length) {
          const powerFiles: TransactionDocument[]|undefined = transaction.documents?.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.POWER)

          if (powerFiles.length) {
            const agentPowerFileName: string|undefined = transaction.agent?.internalNameDocuments?.find((name: string) => transaction.documents?.find((document: TransactionDocument) => document.internalName === name))
            // On supprime le fichier de la transaction, car ce n'est pas le même que celui du mandataire
            if (!(agentPowerFileName && contributorPowerFileName && contributorPowerFileName === agentPowerFileName)) {
              await DocumentService.deleteDocument(contributorPowerFileName, transaction)
            }
          }
        // Si on supprime le fichier et qu'il n'est que rattaché qu'au mandataire, on le supprime définitivement
        } else if (contributorType === DEPOSIT_CONTRIBUTORS_TYPES.AGENT.value &&
            (!transaction.signatory || !transaction.signatory.internalNameDocuments?.some((internalName: string) => internalName === contributorPowerFileName))) {
          await DocumentService.deleteDocument(contributorPowerFileName, transaction)
        }

        // On met à jour directement la transaction pour ne pas attendre un enregistrement sur un potentiel clique sur étape suivante
        const newTransaction: Transaction|null = await TransactionService.updateTransaction(transaction.id, { [contributorType]: { ...contributor, internalNameDocuments: [] } })
        newTransaction && store.dispatch(storeTransactionUpdate(newTransaction))
        return { ...contributor, internalNameDocuments: [] }
      }
    }
    return contributor
  }

  /**
   * met à jour "document" avec les documents de pouvoir
   * @param transaction
   * @param contributorType
   * @param documents
   * @param setDocuments
   */
  updateDocumentFromContributor = (transaction: Transaction, contributorType: string, documents: TransactionDocument[], setDocuments: (docs : TransactionDocument[]) => void) => {
    if (transaction[contributorType] && transaction[contributorType]?.internalNameDocuments && transaction[contributorType]?.internalNameDocuments[0]) {
      const docToAdd = transaction.documents?.find(doc => doc.internalName === transaction[contributorType]?.internalNameDocuments[0])
      setDocuments([...documents, docToAdd])
    }
    return Promise.resolve(true)
  }

  /**
   * Récupère le nom de l'intervenant
   * @param contributor
   */
  getName = (contributor:Contributor) => {
    const name = (contributor.type === PERSONNE_PHYSIQUE.value)
      ? `${contributor.lastname || ''} ${contributor.firstname || ''}`
      : (contributor.companyName || '')

    return name.trim()
  }

  isDefaultAgentContributor = (contributor?: Contributor) => !!contributor && JSON.stringify(contributor) === JSON.stringify({ address: { country: 'FR' } })
}

export default new ContributorService()
