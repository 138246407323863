import Message from '../../../constants/Message'
import { ETSAccountRequestCredentials } from '../../../interfaces/ETSAccountInterface'
import {
  FieldStatus,
  isFilled,
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION
} from '@inpi-marques/components'
/* global localStorage */

class ETSAccountRequestCredentialsValidator {
    /**
     * Valide le formulaire de demande de rattachement par code d'accès
     * @param etsAccountRequestCredentials
     */
    validateForm = (etsAccountRequestCredentials: ETSAccountRequestCredentials) : FieldStatus => {
      const fieldStatus: FieldStatus = {}
      if (!isFilled(etsAccountRequestCredentials.procedure)) {
        fieldStatus.procedure = Message.required_field
      }
      if ([PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value].includes(etsAccountRequestCredentials.procedure) &&
        !isFilled(etsAccountRequestCredentials.profile)) {
        fieldStatus.profile = Message.required_field
      }
      if (!isFilled(etsAccountRequestCredentials.code)) {
        fieldStatus.code = Message.required_field
      }
      if (!isFilled(etsAccountRequestCredentials.password)) {
        fieldStatus.password = Message.required_field
      }
      const askConsent = localStorage.getItem('ELECTRONIC_NOTIF') === 'true'
      if (askConsent && etsAccountRequestCredentials.consentNotificationByEmail == null) {
        fieldStatus.consentNotificationByEmail = Message.required_field
      }

      return fieldStatus
    }
}

export default new ETSAccountRequestCredentialsValidator()
