import { containsErrors, FieldStatus, isFilled, ProductClassVersion } from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class ProductsAndServiceValidator {
  validateOnChangeStep = (parentVersion?: ProductClassVersion, childVersion?: ProductClassVersion) => {
    const fieldStatus: FieldStatus = {}
    if (!parentVersion || !isFilled(parentVersion.productClasses)) {
      fieldStatus.parentVersion = Message.parent_product_and_services_missing
    }
    if (!childVersion || !isFilled(childVersion.productClasses)) {
      fieldStatus.childVersion = Message.child_product_and_services_missing
    }
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new ProductsAndServiceValidator()
