import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  FieldStatus,
  InlineTextField,
  SubmitButton,
  MultipleRadioField,
  CONSENTEMENT_OPTIONS
} from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
/* global localStorage */

interface MyProfileEmailsAndConsentProps {
  correspondenceEmailList: string[],
  setCorrespondenceEmailList: (emailList: string[]) => void,
  sendConfirmEmail: () => void,
  canSendConfirmEmails: boolean,
  fieldStatus?: FieldStatus[],
  consentNotificationByEmailPreferences?: boolean,
  setConsentNotificationByEmailPreferences?: (consent: boolean) => void
}

const MyProfileEmailsAndConsent : FC<MyProfileEmailsAndConsentProps> = ({
  correspondenceEmailList,
  setCorrespondenceEmailList,
  fieldStatus,
  sendConfirmEmail,
  canSendConfirmEmails,
  consentNotificationByEmailPreferences,
  setConsentNotificationByEmailPreferences
}) => {
  const askConsent = localStorage.getItem('ELECTRONIC_NOTIF') === 'true'

  /**
   * Supprime un email de correspondance
   * @param index
   */
  const deleteEmail = (index: number) => {
    const emailList = [...correspondenceEmailList]
    emailList.splice(index, 1)
    setCorrespondenceEmailList(emailList)
  }

  /**
   * Modifie un email de correspondance
   * @param index
   * @param value
   */
  const updateEmail = (index: number, value: string) => {
    const emailList = [...correspondenceEmailList]
    emailList[index] = value
    setCorrespondenceEmailList(emailList)
  }

  /**
   * Ajoute un champ d'email de correspondance
   */
  const addEmail = () => {
    const emailList = [...correspondenceEmailList]
    emailList.push('')
    setCorrespondenceEmailList(emailList)
  }

  return (
    <>
      {askConsent &&
        <div className='small row col-12 my-4'>
          <label className='form-label mr-5'><FormattedMessage id='my_profile_consent_preferences' /></label>
          <MultipleRadioField
            inputId='consentNotificationByEmailPreferences'
            value={consentNotificationByEmailPreferences}
            radio={CONSENTEMENT_OPTIONS}
            onChange={(event) => setConsentNotificationByEmailPreferences(event.target.value)}
          />
        </div>}
      <div className='header-field'>
        <FormattedMessage id='my_profile_email_title' />
      </div>
      <div className='row is-validated'>
        {
          correspondenceEmailList?.map((email: string, index:number) => (
            <div className='col-12 col-md-4 mt-3 mt-md-0' key={index}>
              <InlineTextField
                type='email'
                inputId={`email-${index}`}
                name={`correspondence-${index}`}
                nameFieldStatus='email'
                value={email}
                onChange={(event) => updateEmail(index, event.target.value)}
                className='col-11 pl-0'
                fieldStatus={(fieldStatus && fieldStatus[index]) || ''}
              >
                <SubmitButton className='btn-secondary' onClick={() => { deleteEmail(index) }}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </SubmitButton>
              </InlineTextField>
            </div>
          ))
        }
        {
          (!correspondenceEmailList || correspondenceEmailList.length < 3) &&
            <div className='col-12 col-md-4 d-flex align-items-center mt-3 mt-md-0'>
              <SubmitButton
                className='btn-link-primary'
                onClick={addEmail}
              >
                <FormattedMessage id='my_profile_email_add' />
              </SubmitButton>
            </div>
        }

      </div>
      <SubmitButton
        disabled={!canSendConfirmEmails}
        className='btn-link-primary mt-3'
        onClick={sendConfirmEmail}
      >
        <FormattedMessage id='my_profile_confirm_emails' />
      </SubmitButton>
    </>
  )
}

export default MyProfileEmailsAndConsent
