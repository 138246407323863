import {
  containsErrors,
  Contributor,
  DOCUMENT_COMMUNICABILITIES,
  DOCUMENT_FORMATS,
  DOCUMENT_LIMITS,
  EventType,
  FieldStatus,
  FileBrowserField,
  FileButton,
  isFilled,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION,
  PROCEDURE_MIFR,
  SelectField,
  SubmitButton,
  TextField,
  TransactionDocument
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Message from '../../../constants/Message'
import { toast } from 'react-toastify'
import DocumentService from '../../../services/document/DocumentService'
import TransactionService from '../../../services/transaction/TransactionService'
import { RootStateOrAny, useSelector } from 'react-redux'

const Observations: FC = ({ setShowModal }) => {
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})
  const [document, setDocument] = useState<TransactionDocument>()
  const [numnat, setNumnat] = useState<string>()
  const user = useSelector((state: RootStateOrAny) => state.user.user)

  const intl = useIntl()
  const typeDocumentList = [
    {
      value: 'THIRD_PARTY_OBSERVATION',
      label: Message.third_party_observation
    }, {
      value: 'OBSERVATION_CG',
      label: Message.observation_cg
    }
  ]

  /**
   * Ajoute le document en local
   * @param event
   */
  const handleAddDocument = (event: EventType) => {
    const file: File = event.target.value
    setDocument({
      ...document,
      internalName: '',
      name: file.name,
      format: file.type,
      communicability: DOCUMENT_COMMUNICABILITIES.INTERNAL,
      file,
      size: file.size
    })
  }

  /**
   * Supprime le document en local
   */
  const deleteDocument = async () => {
    if (!document.internalName) {
      setDocument({})
    }
  }

  /**
   * Enregistrement du document via l'API et mise à jour de la transaction
   */
  const submitDocument = async () => {
    setFieldStatus({})
    const status = {}
    if (!isFilled(numnat)) {
      status.numnat = Message.required_field
    }
    if (!document || !document.name) {
      status.fileInputObservations = Message.required_field
    }
    if (!document?.type) {
      status.type = Message.required_field
    }
    setFieldStatus(status)

    if (containsErrors(status)) {
      return null
    }

    const transaction = await TransactionService.getTransactionByNumnat(numnat, [PROCEDURE_DEPOSIT.value, PROCEDURE_MIFR.value, PROCEDURE_DIVISION.value])
    if (!transaction) {
      setFieldStatus({ numnat: Message.brand_not_exist })
      return null
    }

    // Un déposant ne peut envoyer d'observation de tiers sur sa propre transaction
    if (transaction.depositors?.find((depositor: Contributor) => parseInt(depositor?.idUser) === user.id)) {
      setFieldStatus({ numnat: Message.brand_depositors_same_as_user })
      return null
    }

    const createdDocument = await DocumentService.createDocument(document, transaction.id)
    if (createdDocument) {
      setShowModal && setShowModal(false)
      return toast.success(Message.request_add_observations_success)
    }

    return null
  }

  useEffect(() => {
    setFieldStatus({})
  }, [document, numnat])

  return (
    <div className='is-validated'>
      <div className='p-2'>
        <TextField
          className='col-4 pl-0'
          inputId='numnat'
          required
          value={numnat}
          onChange={(event: EventType) => setNumnat(event.target.value)}
          label={<FormattedMessage id='numnat_brand' />}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
        />
        <SelectField
          label={<FormattedMessage id='type_observations' />}
          inputId='type'
          className='pl-3'
          placeholder={intl.formatMessage({ id: 'placeholder_select' })}
          value={document?.type}
          onChange={(event: EventType) => setDocument({ ...document, type: event.target.value })}
          fieldStatus={fieldStatus}
          options={typeDocumentList}
          resetError={setFieldStatus}
          required
        />
        <FileBrowserField
          inputId='file-observations'
          ulClassName='m-0'
          className='mt-1 mr-4 pb-3'
          acceptApplication={DOCUMENT_FORMATS.PDF}
          value={document?.name ? [document] : []}
          onChange={handleAddDocument}
          onDelete={document && !document.internalName && deleteDocument}
          maxNumberFile={1}
          buttonLabel={<FileButton label={<FormattedMessage id='form_file_placeholder' />} />}
          required
          nameFieldStatus='fileInputObservations'
          fieldStatus={fieldStatus}
          maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
          resetError={setFieldStatus}
          handleDownload
        />
        <SubmitButton className='btn-primary my-3' onClick={submitDocument}>
          <FormattedMessage id='common_send' />
        </SubmitButton>
      </div>
    </div>
  )
}

export default Observations
