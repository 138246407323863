import {
  Country,
  HelpBlock,
  INSCRIPTION_TYPE_ACT_TITLE,
  INSCRIPTION_TYPE_REWARD,
  isFilled,
  PROCEDURE_DIVISION,
  PROCEDURE_INSCRIPTION,
  Record,
  Transaction, PROCEDURE_FRMI, TransactionService,
  CONTRIBUTOR_DEPOSITORS
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ContentService from 'services/content/ContentService'
import { RootStateOrAny, useSelector } from 'react-redux'
import Message from '../../../constants/Message'
import ContributorListOverview from './ContributorListOverview'

interface IntervenantsOverviewProps {
  transaction: Transaction|Record,
  fromFrmiExtension?: boolean,
  procedureType?: string
}

const IntervenantsOverview: FC<IntervenantsOverviewProps> = ({ transaction, fromFrmiExtension, procedureType }) => {
  const [countries, setCountries] = useState<Country[]>([])
  const guidedMode = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode)

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  const getContributorLabel = (): string => {
    switch (transaction.procedureType) {
      case PROCEDURE_INSCRIPTION.value:
        return TransactionService.isRenunciation(transaction) ? 'contributor_depositors_renunciation' : 'contributor_depositors_inscription'
      case PROCEDURE_DIVISION.value:
        return 'contributor_depositors_division'
      case PROCEDURE_FRMI.value:
        return 'contributor_depositors_frmi'
      default:
        return 'contributor_depositors'
    }
  }

  return (
    <div className='d-flex flex-wrap row'>
      {transaction.depositors && isFilled(transaction.depositors) &&
        <div className='col-12 col-md-6 mb-3'>
          <h4 className='mb-2 font-weight-bold'>
            <FormattedMessage id={getContributorLabel()} />
          </h4>
          <ContributorListOverview
            contributors={transaction.depositors}
            countries={countries}
            fromFrmiExtension={fromFrmiExtension}
            procedureType={procedureType}
            contributorType={CONTRIBUTOR_DEPOSITORS.value}
          />
        </div>}

      {transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value && transaction.otherApplicants && isFilled(transaction.otherApplicants) &&
        <div className='col-12 col-md-6 mb-3'>
          <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_otherApplicants' /></h4>
          <ContributorListOverview contributors={transaction.otherApplicants} countries={countries} procedureType={procedureType} />
        </div>}

      {transaction.subProcedureType === INSCRIPTION_TYPE_REWARD.value && transaction.organisers && isFilled(transaction.organisers) &&
        <div className='col-12 col-md-6 mb-3'>
          <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_organisers' /></h4>
          <ContributorListOverview contributors={transaction.organisers} countries={countries} procedureType={procedureType} />
        </div>}

      {transaction.agent && transaction.agent.type &&
        <div className='col-12 col-md-6 mb-3'>
          <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_agent' /></h4>
          <ContributorListOverview contributors={[transaction.agent]} countries={countries} procedureType={procedureType} />
        </div>}

      {transaction.recipient && transaction.recipient.type &&
        <div className='col-12 col-md-6 mb-3'>
          <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_recipient' /></h4>
          <ContributorListOverview contributors={[transaction.recipient]} countries={countries} procedureType={procedureType} />
        </div>}

      {transaction.signatory &&
        <div className='col-12 col-md-6 mb-3'>
          <h4 className='mb-2 font-weight-bold'><FormattedMessage id='contributor_signatory' /></h4>
          <ContributorListOverview contributors={[transaction.signatory]} countries={countries} />
          {
            guidedMode && guidedMode.activeGuidedMode && !guidedMode.restrictedQualities.includes(transaction.signatory.manageableQuality?.code) &&
              <HelpBlock className='auto-size' image='/img/picto-warning.png'>
                <div className='small'>
                  <FormattedMessage
                    id='warning_restricted_qualities_signatory'
                    values={{
                      b: (message : ((string) | '')) => <strong>{message}</strong>
                    }}
                  />
                  <FormattedMessage
                    id='recommended_qualities_signatory_message'
                    values={{
                      a: (message : ((string) | '')) => (<a href={Message.recommended_qualities_signatory_link} target='_blank' rel='noopener noreferrer'><u>{message}</u></a>),
                      linebreak: <br />
                    }}
                  />
                </div>
              </HelpBlock>
          }
        </div>}
    </div>
  )
}

export default IntervenantsOverview
