import {
  CardBlock,
  PROCESS_FAST_TRACK_RENEWAL,
  ProtectionExtension,
  References,
  RENEWAL_TYPE_PARTIAL,
  Transaction
} from '@inpi-marques/components'
import OverviewTitles from 'component/inscription/form/titles/OverviewTitles'
import IntervenantsOverview from 'component/transactions/overview/IntervenantsOpposantOverview'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ContentService from '../../../services/content/ContentService'
import ProtectionsExtensionsListByTitle from '../form/options/ProtectionsExtensionsListByTitle'
import ProductAndServicesOverview from '../../deposit/overview/ProductAndServices/ProductAndServices'
import DocumentsOverview from 'component/transactions/overview/DocumentsOverview'
import OverviewInternationalExtension from '../../division/form/options/OverviewInternationalExtension'
import AssociatedBrandsOverview from './AssociatedBrandsOverview'
import SimultaneousInscriptionOverview from './SimultaneousInscriptionOverview'

interface RenewalOverviewProps {
  transaction: Transaction,
  isUserActive?: boolean,
  showAddDocumentsButton?: boolean,
  references?: References,
  setReload?: () => void
}

/**
 * Récapitulatif d'un renouvellement
 */
const RenewalOverview: FC<RenewalOverviewProps> = ({ transaction, isUserActive = false, showAddDocumentsButton = false, references, setReload }) => {
  const [protectionExtensions, setProtectionExtensions] = useState<ProtectionExtension[]>([])

  useEffect(() => {
    // Récupération de la liste des extensions de protections possibles
    ContentService.getProtectionExtensions().then(setProtectionExtensions)

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  return (
    <div className='col-12 d-flex flex-wrap p-0'>

      {/* Titres */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='overview_renewal_titles' />} shadow>
          <div className='mb-3'>
            <OverviewTitles titles={transaction.renewalDetails?.titles ?? []} procedureType={transaction.procedureType} showDownloadTitleList isFastTrack={transaction.process === PROCESS_FAST_TRACK_RENEWAL} />
          </div>
        </CardBlock>
      </div>

      {/* Intervenants */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='overview_deposit_intervenants_title' />} shadow>
          <IntervenantsOverview transaction={transaction} />
        </CardBlock>
      </div>

      {/* Détails de la demande */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='renewal_demand_details' />} shadow>
          <>
            <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_protection_extensions_title' /></h4>
            <ProtectionsExtensionsListByTitle
              extensions={protectionExtensions}
              titles={transaction.renewalDetails?.titles ?? []}
            />
          </>
          {transaction?.renewalDetails?.type === RENEWAL_TYPE_PARTIAL.value &&
            <div className='mt-3'>
              <OverviewInternationalExtension
                internationalExtensions={transaction.internationalExtensions}
              />
            </div>}
          <div className='mt-3'>
            <AssociatedBrandsOverview
              associatedBrands={transaction.renewalDetails.associatedBrands ?? []}
            />
          </div>
          <div className='mt-3'>
            <SimultaneousInscriptionOverview
              hasSimultaneousInscription={transaction.renewalDetails?.hasSimultaneousInscription}
            />
          </div>
        </CardBlock>
      </div>

      {/* Produits et services */}
      {transaction.renewalDetails?.titles[0]?.productsAndServicesVersions && (
        <div className='col-12 mb-3'>
          <ProductAndServicesOverview
            editingMode={false}
            transaction={transaction}
            versions={transaction.renewalDetails?.titles[0]?.productsAndServicesVersions}
          />
        </div>
      )}
      {/* Récapitulatif documents */}
      <div className='col-12 mb-3' ref={references?.documents}>
        <CardBlock header={<FormattedMessage id='overview_file_title' />} shadow>
          <DocumentsOverview
            transaction={transaction}
            showAddDocumentsButton={showAddDocumentsButton}
            isUserActive={isUserActive}
            setReload={setReload}
          />
        </CardBlock>
      </div>
    </div>
  )
}
export default RenewalOverview
