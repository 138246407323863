import { LinkedTransaction } from '@inpi-marques/components'
import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

interface OverviewLinkedTransactionsProps {
  linkedTransaction?: LinkedTransaction[],
  customTitle?: ReactNode
}

const OverviewLinkedTransactions : FC<OverviewLinkedTransactionsProps> = (
  {
    linkedTransaction,
    customTitle
  }) => {
  return (
    <>
      <h4 className='font-weight-bold'>{customTitle ?? <FormattedMessage id='overview_deposit_inscriptions_link_title' />}</h4>
      {linkedTransaction && linkedTransaction.length > 0
        ? linkedTransaction.map((linkedTransaction: LinkedTransaction, key: number) =>
          <p key={key}><span className='font-weight-bold'><FormattedMessage id='overview_inscription_linked_transaction_number' /></span> : {linkedTransaction.numNat}</p>
        )
        : <div><FormattedMessage id='none_feminine' /></div>}
    </>
  )
}

export default OverviewLinkedTransactions
