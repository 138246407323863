import { ModalComponent, NoResult, Payment, ProductAndService, ProductClass, ProductClassVersion, SubmitButton, ProductService as CommonProductService, ProductAndServiceListView, PROCEDURE_DEPOSIT } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PRODUCT_STATUS } from '@inpi-marques/components/src/constants/DepositConstants'
import ProductAndServicesFreeInput from './ProductAndServicesFreeinput'
import ProductsService from 'services/deposit/ProductsService'
import Message from '../../../../constants/Message'

interface ProductsAndServicesEditListProps {
  version: ProductClassVersion
  onProductClassesEdit?: (version: ProductClassVersion) => Promise<void>,
  hasRegularization?: boolean,
  handleRegularization?: (version: ProductClassVersion) => Promise<Payment|null>,
  setEditMode?: (mode: boolean) => void,
  procedureType?: string
}

const ProductsAndServicesEditList: FC<ProductsAndServicesEditListProps> = (
  {
    version,
    onProductClassesEdit,
    hasRegularization = false,
    handleRegularization,
    setEditMode,
    procedureType
  }) => {
  /**
   * On garde la version initiale clean (sans les produits supprimés et avec les statuts clean)
   * Cette version nous permettra de faire la différence entre la nouvelle version
   */
  const clearedVersion: ProductClassVersion = CommonProductService.clearProductClassVersion(version)

  // S'il s'agit d'une version DRAFT, on en crée pas une nouvelle
  const [newVersion, setNewVersion] = useState<ProductClassVersion>(CommonProductService.isDraftFoVersion(version) ? version : CommonProductService.clearProductClassVersion(version))
  const [showModal, setShowModal] = useState<boolean>(false)

  /** Partie d'ajout de produits */
  const [showAddedModal, setShowAddedModel] = useState<boolean>(false)
  const [newProductClasses, setNewProductClasses] = useState<ProductClass[]>([])

  const [withDrawType, setWithDrawType] = useState<'partial'|'total'>()
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    setNewVersion(CommonProductService.isDraftFoVersion(version) ? version : CommonProductService.clearProductClassVersion(version))
  }, [version])

  /**
   * Lorsqu'on accepte le retrait partiel / total de produits grâce à la modale
   */
  const onModalSubmit = async (): Promise<void> => {
    onProductClassesEdit && await onProductClassesEdit(CommonProductService.getFinalVersion(newVersion))
    setShowModal(false)
    setWithDrawType(undefined)
  }

  /**
   * A la fermeture de la modale d'ajout de produits
   */
  const onCloseProductAddModal = (): void => {
    setNewProductClasses([])
    setShowAddedModel(false)
  }

  /**
   * A la confirmation d'ajout de produits
   */
  const onNewClassesSubmit = async (): Promise<void> => {
    // En ajoutant les nouveaux produits, leur status est set à ADDED
    setNewVersion({
      ...newVersion,
      productClasses: CommonProductService.getUpdateSelectedListFromList(newVersion.productClasses,
        newProductClasses.map((newProductClass: ProductClass) => ({ ...newProductClass, deleted: newProductClass.deleted !== undefined && newProductClass.deleted !== null ? false : undefined, products: newProductClass.products.map((product: ProductAndService) => ({ ...product, status: PRODUCT_STATUS.ADDED })) })), false)
    })
    setShowAddedModel(false)
    setNewProductClasses([])
  }

  /**
   * Gère les cliques pour les retraits totaux / partiels
   */
  const onWithdrawalClick = async (version: ProductClassVersion): Promise<void> => {
    if (handleRegularization && hasRegularization) {
      // Dans le cas d'une régularisation, il n'est pas possible de supprimer tous les P&S
      if (version.productClasses.every((productClass: ProductClass) => productClass.products.every((product: ProductAndService) => product.status === PRODUCT_STATUS.DELETED))) {
        setErrorMessage(Message.product_and_services_error_edit_regularization)
        return
      }

      const payment: Payment | null = await handleRegularization(version)
      // Si l'utilisateur n'a pas de paiement à régler suite à la régularisation, on reprends le fonctionnement habituel
      if (!payment) {
        onProductClassesEdit && await onProductClassesEdit(CommonProductService.getFinalVersion(newVersion))
      }
    } else {
      setShowModal(true)
    }
  }

  /**
   * Gère le clique sur le bouton retrait total
   */
  const onTotalWithdrawalClick = async (): Promise<void> => {
    const totalWithdrawalVersion: ProductClassVersion = {
      ...newVersion,
      productClasses: newVersion.productClasses.map((productClass: ProductClass) =>
        ({ ...productClass, products: productClass.products.map((product: ProductAndService) => ({ ...product, status: PRODUCT_STATUS.DELETED })) }))
    }

    setNewVersion(totalWithdrawalVersion)
    onWithdrawalClick(totalWithdrawalVersion)
    setWithDrawType('total')
  }

  /**
   * Suppression d'une classe de produits
   * On met à jour les produits qui existaient dans la version originale
   * et on supprime les produits ajoutés recemment (avec statut ADDED)
   * @param deletedProductClass
   */
  const onProductClassDeleted = (deletedProductClass: ProductClass): void => {
    setNewVersion({
      ...newVersion,
      productClasses: CommonProductService.editProductsOnProductClassDeleted(newVersion.productClasses, deletedProductClass)
    })
  }

  /**
   * Rollback d'une classe de produits supprimée
   * @param classToRollback
   */
  const onProductClassUndoDeleted = (classToRollback: ProductClass): void => {
    setNewVersion({
      ...newVersion,
      productClasses: CommonProductService.editProductsOnProductClassUndoDeleted(newVersion.productClasses, classToRollback)
    })
  }

  /**
   * Suppression d'un produit de la classe
   * Si le produit a un statut ADDED, alors on le supprime completement de la liste des produits
   * @param editedProductClass
   * @param deletedProduct
   */
  const onProductDeleted = (editedProductClass: ProductClass, deletedProduct: ProductAndService): void => {
    setNewVersion({
      ...newVersion,
      productClasses: newVersion.productClasses.map((productClass: ProductClass) => editedProductClass.ref === productClass.ref
        ? {
          ...productClass,
          products: productClass.products
            .filter((product: ProductAndService) => !(product.name === deletedProduct.name && product.status === PRODUCT_STATUS.ADDED))
            .map((product: ProductAndService) => product.name === deletedProduct.name
              ? { ...product, status: PRODUCT_STATUS.DELETED } : product
            )
        }
        : productClass)
    })
  }

  /**
   * A l'édition de la liste de produits d'une classe
   * @param editedProductClass
   * @param strProducts
   */
  const onProductClassesEdited = async (editedProductClass: ProductClass, strProducts: string): Promise<void> => {
    // On compare avec la version originale, car des produits déjà existant qui ont potentiellement était supprimés peuvent à ajouter.
    const originalClass: ProductClass|undefined = clearedVersion.productClasses.find((productClass: ProductClass) => editedProductClass.ref === productClass.ref)

    if (originalClass) {
      let updatedProductClass: ProductClass = CommonProductService.updateProductClassFromProducts(originalClass, strProducts)
      if (procedureType === PROCEDURE_DEPOSIT.value) {
        // On check la validité des produits ajoutés
        const checkedProductClasses: ProductClass[] | null = await ProductsService.validateTMClass([updatedProductClass])
        if (checkedProductClasses?.length) {
          updatedProductClass = checkedProductClasses[0]
        }
      }
      // On check la validité des produits ajoutés
      setNewVersion({ ...newVersion, productClasses: newVersion.productClasses.map((productClass: ProductClass) => updatedProductClass.ref === productClass.ref ? updatedProductClass : productClass) })
    }
  }

  return (
    <>
      {hasRegularization &&
        <>
          <div className='m-0'>
            <h3><FormattedMessage id='product_and_services_validate_regularization_title' /></h3>
          </div>
          <div className='d-flex align-items-center my-3'>
            <SubmitButton
              className='btn-outline-primary'
              disabled={!newVersion.productClasses.some((productClass: ProductClass) => productClass.products.some((product: ProductAndService) => product.status || product.editValue))}
              onClick={() => { setWithDrawType('regularization'); onWithdrawalClick(newVersion) }}
            >
              <FormattedMessage id='product_and_services_validate_regularization_button' />
            </SubmitButton>
            {setEditMode &&
              <SubmitButton
                className='btn-outline-primary ml-3'
                onClick={() => setEditMode(false)}
              >
                <FormattedMessage id='button_cancel' />
              </SubmitButton>}
          </div>
          <SubmitButton
            className='btn-link-primary text-unset'
            onClick={() => setShowAddedModel(true)}
          >
            <FormattedMessage id='product_and_services_add_button' />
          </SubmitButton>
        </>}

      {!hasRegularization &&
        <>
          {withDrawType === 'partial' &&
            <div className='m-0'>
              <h3><FormattedMessage id='product_and_services_validate_partial_withdrawal' /></h3>
            </div>}
          <div className='d-flex align-items-center my-3'>
            <div>
              {/* Choix entre Retrait total et Retrait partiel */}
              {withDrawType !== 'partial' &&
                <>
                  <SubmitButton
                    className='btn-outline-primary ml-3'
                    onClick={onTotalWithdrawalClick}
                  >
                    <FormattedMessage id='product_and_services_validate_total_withdrawal_button' />
                  </SubmitButton>
                  <SubmitButton
                    className='btn-outline-primary ml-3'
                    onClick={() => setWithDrawType('partial')}
                  >
                    <FormattedMessage id='product_and_services_validate_partial_withdrawal_button' />
                  </SubmitButton>
                </>}
              {/* Retrait partiel : Valider / Annuler */}
              {withDrawType === 'partial' &&
                <>
                  <SubmitButton
                    className='btn-outline-primary'
                    disabled={!newVersion.productClasses.some((productClass: ProductClass) => productClass.products.some((product: ProductAndService) => product.status || product.editValue))}
                    onClick={() => onWithdrawalClick(newVersion)}
                  >
                    <FormattedMessage id='common_validate' />
                  </SubmitButton>
                  <SubmitButton
                    className='btn-outline-primary ml-3'
                    onClick={() => {
                      setWithDrawType(undefined)
                      setEditMode && setEditMode(false)
                    }}
                  >
                    <FormattedMessage id='button_cancel' />
                  </SubmitButton>
                </>}
            </div>
          </div>
        </>}
      {errorMessage && <div className='text-red'><FormattedMessage id={errorMessage} /></div>}

      {/* Expands contenant les classes de produits */}
      {newVersion.productClasses.length === 0
        ? <NoResult />
        : (
          <ProductAndServiceListView
            productClasses={newVersion.productClasses}
            onProductClassDelete={onProductClassDeleted}
            onProductClassUndoDelete={onProductClassUndoDeleted}
            onProductDelete={onProductDeleted}
            onProductClassEdited={onProductClassesEdited}
          />
        )}
      {/* Modale d'ajout de produits */}
      <ModalComponent
        title={<FormattedMessage id='product_and_services_added_modal_title' />}
        show={showAddedModal}
        customContent={() => (
          <ProductAndServicesFreeInput
            productClasses={newProductClasses}
            onProductClassesEdited={(newClasses: ProductClass[]) => setNewProductClasses(newClasses)}
            inModalWithSubmit
          />
        )}
        handleClose={() => onCloseProductAddModal()}
        disableSubmitButton={!newProductClasses || newProductClasses.length === 0}
        onClick={() => onNewClassesSubmit()}
        validateLabel={<FormattedMessage id='product_and_services_added_modal_validate_label' />}
        size='xl'
      />
      {/* Modale de validation du retrait partiel / total */}
      <ModalComponent
        title={<FormattedMessage id='product_and_services_revocation_title' />}
        show={showModal}
        customContent={() => <FormattedMessage id={`product_and_services_${withDrawType}_revocation_text`} />}
        handleClose={() => setShowModal(false)}
        onClick={() => onModalSubmit()}
        validateLabel={<FormattedMessage id='product_and_services_revocation_button_label' />}
      />
    </>
  )
}

export default ProductsAndServicesEditList
