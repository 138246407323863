import React, { FC } from 'react'
import {
  FRMI,
  FRMI_EFFECT_DATE_TYPES, SelectOption
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'

interface EffectDateOverviewProps {
    frmi: FRMI
}

const EffectDateOverview: FC<EffectDateOverviewProps> = ({ frmi }) => {
  return (
    <>
      {
        frmi?.effectDateType &&
          <div>
            <span><FormattedMessage id={FRMI_EFFECT_DATE_TYPES.find((type: SelectOption) => type.value === frmi?.effectDateType)?.label} /></span>
          </div>
      }

      {
        frmi?.effectDateInformations &&
          <div className='d-flex flex-column mt-3'>
            <span className='font-weight-bold'>
              <FormattedMessage id='frmi_overview_effect_date_application_in_suit' />
            </span>
            <span>{frmi?.effectDateInformations}</span>
          </div>
      }
    </>
  )
}

export default EffectDateOverview
