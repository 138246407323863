import React, { FC } from 'react'
import { PAGINATION_MAX_BUTTONS_DISPLAYED, SubmitButton } from '../index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

interface PaginationProps {
    nbPage: number,
    currentPage: number,
    onChange: (newPage: number) => any | Promise<any>,
    className?: string
}

const Pagination: FC<PaginationProps> = ({
  nbPage,
  currentPage,
  onChange,
  className
}) => {
  /**
     * Permet d'obtenir l'index de page d'un bouton de pagination
     * @param index
     */
  const getButtonPage = (index: number) => {
    if (nbPage === 0 || nbPage === 1) {
      return 1
    }

    // Si la page courante est 1 index avant le nombre max de boutons de pagination que l'on peut afficher
    if (currentPage > PAGINATION_MAX_BUTTONS_DISPLAYED) {
      if (currentPage - (PAGINATION_MAX_BUTTONS_DISPLAYED) === 1 && nbPage < PAGINATION_MAX_BUTTONS_DISPLAYED) {
        return nbPage - (PAGINATION_MAX_BUTTONS_DISPLAYED) + index
      }

      // Si la page courante est dans les dernières pages on ne doit pas dépasser l'index max (nbPage)
      if (nbPage - currentPage < PAGINATION_MAX_BUTTONS_DISPLAYED - 1) {
        return nbPage - (PAGINATION_MAX_BUTTONS_DISPLAYED) + index
      }

      return currentPage + index - 1
    }

    // dans ce cas on a pas dépassé PAGINATION_MAX_BUTTONS_DISPLAYED et on affiche 2,3,4... jusqu'à PAGINATION_MAX_BUTTONS_DISPLAYED
    // + 2 permet d'éviter d'afficher la page 1
    return index + 2
  }

  const array = nbPage > 0 ? Array(nbPage > PAGINATION_MAX_BUTTONS_DISPLAYED ? PAGINATION_MAX_BUTTONS_DISPLAYED : nbPage === 1 ? 1 : nbPage - 1).fill(null).map((_, index) => getButtonPage(index)) : undefined
  const firstIndex = array ? array[0] : undefined
  const lastIndex = array ? array[array.length - 1] : undefined

  return firstIndex ? (
    <nav>
      <ul className={`pagination ${className}`}>
        <li className='page-item'>
          <SubmitButton
            className='btn-block btn-outline-gris'
            onClick={() => onChange(1)}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </SubmitButton>
        </li>
        <li className='page-item ml-1'>
          <SubmitButton
            className='btn-block btn-outline-gris'
            onClick={() => onChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </SubmitButton>
        </li>

        {firstIndex !== 1 && (
          <li className='page-item ml-1'>
            <SubmitButton
              className={`btn-block ${currentPage === 1 ? 'bg-primary text-white border-color-primary' : 'btn-outline-gris'}`}
              onClick={() => onChange(1)}
            >
                            1
            </SubmitButton>
          </li>
        )}

        {(nbPage > PAGINATION_MAX_BUTTONS_DISPLAYED + 1 && firstIndex - 1 > 1) &&
                    (
                      <li className='page-item ml-1'>
                        <SubmitButton
                          className='btn-block btn-outline-gris'
                          disabled
                        >
                                ...
                        </SubmitButton>
                      </li>
                    )}

        {array?.map((pageIndex: number) => {
          return (
            <li
              key={pageIndex}
              className='page-item ml-1'
            >
              <SubmitButton
                className={`btn-block ${pageIndex === currentPage ? 'bg-primary text-white border-color-primary' : 'btn-outline-gris'}`}
                onClick={() => pageIndex !== currentPage && onChange(pageIndex)}
              >
                {pageIndex}
              </SubmitButton>
            </li>
          )
        })}

        {(nbPage > PAGINATION_MAX_BUTTONS_DISPLAYED && lastIndex && lastIndex + 1 < nbPage) && (
          <li className='page-item ml-1'>
            <SubmitButton
              className='btn-block btn-outline-gris'
              disabled
            >
                            ...
            </SubmitButton>
          </li>
        )}
        {(nbPage > PAGINATION_MAX_BUTTONS_DISPLAYED && lastIndex < nbPage) && (
          <li className='page-item ml-1'>
            <SubmitButton
              className={`btn-block ${currentPage === nbPage ? 'bg-primary text-white border-color-primary' : 'btn-outline-gris'}`}
              onClick={() => currentPage !== nbPage && onChange(nbPage)}
            >
              {nbPage}
            </SubmitButton>
          </li>
        )}

        <li className='page-item ml-1'>
          <SubmitButton
            className='btn-block btn-outline-gris'
            onClick={() => onChange(currentPage + 1)}
            disabled={currentPage === nbPage}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </SubmitButton>
        </li>
        <li className='page-item ml-1'>
          <SubmitButton
            className='btn-block btn-outline-gris'
            onClick={() => onChange(nbPage)}
            disabled={currentPage === nbPage}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </SubmitButton>
        </li>
      </ul>
    </nav>
  ) : <></>
}
export default Pagination
