import React, { Dispatch, FC } from 'react'
import { useIntl } from 'react-intl'
import { Contributor } from '../../interfaces/contributors/ContributorsInterfaces'
import { EventType, FieldStatus } from '../../form/FormInterfaces'
import { PROCEDURE_NULLITY, PROCEDURE_OPPOSITION, PROCEDURE_REVOCATION, SelectField, TextField } from '../../index'
import { ENTITE, LEGAL_STATUS_FORMATION } from '../../constants/contributors/LegalStatusFormationConstants'
import Message from '../../constants/Message'

interface PersonFormationFieldsProps {
    contributor?: Contributor,
    onChange: (event: EventType) => void,
    fieldStatus?: FieldStatus,
    dispatch?: Dispatch<any>,
    resetError?: (fieldStatus: FieldStatus) => void,
    procedureType?: string,
}

const PersonFormationFields: FC<PersonFormationFieldsProps> = ({
  contributor = {},
  onChange,
  fieldStatus,
  dispatch,
  resetError,
  procedureType
}) => {
  const intl = useIntl()
  return (
    <div className='row'>
      <div className='col-12 col-md-4'>
        <TextField
          inputId='companyNameFormation'
          label={intl.formatMessage({ id: 'field_raison_sociale_formation_label' })}
          value={contributor?.companyNameFormation || ''}
          onChange={onChange}
          fieldStatus={fieldStatus}
          maxLength={120}
          required
          dispatch={dispatch}
          resetError={resetError}
        />
      </div>
      {
        procedureType !== PROCEDURE_OPPOSITION.value && procedureType !== PROCEDURE_NULLITY.value && procedureType !== PROCEDURE_REVOCATION.value &&
          <>
            <div className='col-12 col-md-4'>
              <SelectField
                inputId='legalFormFormation'
                placeholder={Message.placeholder_select}
                label={intl.formatMessage({ id: 'field_forme_juridique_formation_label' })}
                value={contributor?.legalFormFormation || ''}
                options={LEGAL_STATUS_FORMATION}
                onChange={onChange}
                required
                fieldStatus={fieldStatus}
                dispatch={dispatch}
                resetError={resetError}
              />
            </div>
            {contributor?.legalFormFormation === ENTITE.value &&
              <div className='col-12 col-md-4'>
                <TextField
                  inputId='descriptionFormation'
                  label={intl.formatMessage({ id: 'field_forme_juridique_description_formation_label' })}
                  value={contributor?.descriptionFormation || ''}
                  onChange={onChange}
                  fieldStatus={fieldStatus}
                  required
                  dispatch={dispatch}
                  resetError={resetError}
                />
              </div>}
          </>
      }

    </div>
  )
}

export default PersonFormationFields
