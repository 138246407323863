import { Country } from '@inpi-marques/components'

class CountryService {
    /**
     *
     * @param countries
     * @returns
     */
    getSortedCountry = (countries: Country[]): Country[] =>
      countries.sort((elemA: Country, elemB: Country) => {
        if (!elemA.code) {
          return 1
        }
        if (!elemB.code) {
          return -1
        }
        return elemA.code > elemB.code ? 1 : -1
      })
}

export default new CountryService()
