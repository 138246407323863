import { CheckboxField, EventType, ProtectionExtension, Title } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import React, { FC } from 'react'

interface ProtectionsExtensionsListByTitleProps {
  titles: Title[],
  extensions: ProtectionExtension[],
  handleExtensionChange?: ((value: boolean, protectionExtensionId: number, titleIndex: number) => void)|null
}

const ProtectionsExtensionsListByTitle: FC<ProtectionsExtensionsListByTitleProps> = ({
  titles,
  extensions,
  handleExtensionChange
}) => {
  const filteredTitles: Title[] = titles.filter((title: Title) => handleExtensionChange ? true : title.protectionExtensions?.length)

  return (
    <div className='mt-3'>
      {filteredTitles.length > 0 && extensions &&
      filteredTitles.map((title: Title, indexTitle: number, filteredTitles: Title[]) => (
        <div key={`titles-${title.id}`}>
          <div className='mb-2'>
            <FormattedMessage id='renewal_options_extensions_title_nb' />
            <span className='font-weight-bold'>{title.numNat}</span>
          </div>
          {
          extensions?.filter((extension: ProtectionExtension) => handleExtensionChange ? true : title.protectionExtensions
            ?.find((titleExtension: ProtectionExtension) => titleExtension.id === extension.id))
            ?.map((protectionExtension: ProtectionExtension) => (
              <div key={`extension-${title.id}-${protectionExtension.id}`}>
                <CheckboxField
                  readOnly={handleExtensionChange === undefined}
                  disabled={handleExtensionChange === null}
                  inputId={`${title.id}-${protectionExtension.id}`}
                  key={`${title.id}-${protectionExtension.id}`}
                  label={<span>{protectionExtension.label}</span>}
                  labelClassName='font-weight-bold pl-3'
                  checked={!!title?.protectionExtensions?.filter((protectionExtensionTitle: ProtectionExtension) => protectionExtensionTitle && protectionExtensionTitle.id === protectionExtension.id)?.length}
                  onChange={(event: EventType) => handleExtensionChange && handleExtensionChange(event.target.value, protectionExtension.id!, indexTitle)}
                />
              </div>
            ))
          }
          {indexTitle !== filteredTitles?.length - 1 && <span className='d-block ml-0 separator mt-1 mb-3' />}
        </div>
      ))}
      {!filteredTitles.length &&
        <div><FormattedMessage id='none_feminine' /></div>}
    </div>
  )
}
export default ProtectionsExtensionsListByTitle
