import store from '../../../../../store/store'
import {
  containsErrors,
  FieldStatus,
  FRMI,
  isFilled
} from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import Message from '../../../../../constants/Message'

class CountryValidator {
  validateOnChangeStep = (frmi?: FRMI) => {
    const frmiFieldStatus: FieldStatus = {}
    if (!isFilled(frmi?.countries)) {
      frmiFieldStatus.countries = Message.select_frmi_country_missing
    }

    store.dispatch(storeTransactionFieldStatusUpdate(frmiFieldStatus))
    return !containsErrors(frmiFieldStatus)
  }
}

export default new CountryValidator()
