import { Country, ManageableQuality, OfficialDocumentType, ProtectionExtension, RenewalDelay } from '@inpi-marques/components'
export const COUNTRIES_UPDATE = 'COUNTRY_UPDATE'
export const MANAGEABLE_QUALITIES_UPDATE = 'MANAGEABLE_QUALITIES_UPDATE'
export const PROTECTION_EXTENSIONS_UPDATE = 'PROTECTION_EXTENSIONS_UPDATE'
export const RENEWAL_DELAYS_UPDATE = 'RENEWAL_DELAYS_UPDATE'
export const OFFICIAL_DOCUMENT_TYPES_UPDATE = 'OFFICIAL_DOCUMENT_TYPES_UPDATE'

export interface ContentStoreAction {
  type: string,
  countries?: Country[],
  qualities?: ManageableQuality[],
  protectionExtensions?: ProtectionExtension[],
  renewalDelays?: RenewalDelay[],
  officialDocumentTypes?: OfficialDocumentType[]
}
