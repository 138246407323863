import React, { FC, useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { ControlledTab } from '../index'

interface ControlledTabsProps {
  id: string,
  tabs: ControlledTab[],
  defaultActiveKey?: string,
  setActiveKey?: (key: string) => void,
  className?: string,
  tabClassName?: string
}

const ControlledTabs: FC<ControlledTabsProps> = ({ id, tabs, defaultActiveKey, setActiveKey, className, tabClassName }) => {
  const [key, setKey] = useState(defaultActiveKey)

  const handleSelect = (newKey: string) => {
    setActiveKey && setActiveKey(newKey)
    setKey(newKey)
  }

  useEffect(() => {
    !defaultActiveKey && setKey(0)
  }, [tabs?.length])

  useEffect(() => {
    setKey(defaultActiveKey)
  }, [defaultActiveKey])

  return (
    <Tabs
      id={id}
      activeKey={key}
      onSelect={handleSelect}
      className={className}
      unmountOnExit
    >
      {tabs.map((tab, index) => (
        <Tab
          unmountOnExit
          tabClassName={tabClassName}
          eventKey={index.toString()}
          key={index}
          title={tab.label}
        >{tab.component && tab.component()}
        </Tab>
      ))}
    </Tabs>
  )
}
export default ControlledTabs
