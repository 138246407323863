import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorField, FieldStatus, PROCEDURE_RENEWAL, ProductClass, SwitchField } from '@inpi-marques/components'
import ProductAndServicesFreeInput from 'component/deposit/form/productsAndServices/ProductAndServicesFreeinput'

interface ContestedProductsAndServicesProps {
  productClasses? : ProductClass[],
  handleProductsAndServicesChange : (products : ProductClass[]) =>void,
  fieldStatus?: FieldStatus,
  procedureType?: string,
  renewalType?: boolean,
  renewalReclassify?: boolean,
  setRenewalType?: (value: boolean) => void,
  setRenewalReclassify?: (value: boolean) => void
}

const ContestedProductsAndServices : FC<ContestedProductsAndServicesProps> = ({
  productClasses,
  handleProductsAndServicesChange,
  fieldStatus,
  procedureType,
  renewalReclassify,
  renewalType,
  setRenewalReclassify,
  setRenewalType
}) => {
  return (
    <div>
      <div className='mt-5 form-header'>
        <div className='d-flex justify-content-between'>
          <div>
            <div className='d-flex'>
              <h2 className='mb-0'>
                <FormattedMessage
                  id={`${procedureType?.toLowerCase()}_contested_registration_products_and_services_title`}
                />
              </h2>
            </div>
            <div className='small mt-2'>
              <FormattedMessage
                id={`${procedureType?.toLowerCase()}_contested_registration_products_and_services_subtitle`}
                values={{
                  a: (...chunks : ((string) []) | []) => (<a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
                  linebreak: <br />
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {
        procedureType === PROCEDURE_RENEWAL.value &&
          <div className='mt-4'>
            <SwitchField
              inputId='renewalType'
              className='p-0 mr-2 ml-5'
              displayStringValue
              divClassName='d-flex'
              value={renewalType}
              label={<FormattedMessage id='renewal_type_products_and_services_label' />}
              onChange={() => setRenewalType && setRenewalType(!renewalType)}
              fieldStatus={fieldStatus}
              nameFieldStatus='renewalType'
            />
            <SwitchField
              inputId='renewalReclassify'
              className='p-0 mr-2 ml-5'
              displayStringValue
              divClassName='d-flex'
              value={renewalReclassify}
              label={<FormattedMessage id='renewal_reclassify_products_and_services_label' />}
              onChange={() => setRenewalReclassify && setRenewalReclassify(!renewalReclassify)}
              fieldStatus={fieldStatus}
              nameFieldStatus='renewalReclassify'
            />
          </div>
      }
      {
        fieldStatus && fieldStatus.products && <ErrorField message={fieldStatus.products} className='mt-3' />
      }
      <ProductAndServicesFreeInput
        onProductClassesEdited={handleProductsAndServicesChange}
        productClasses={productClasses || []}
        isEditable
        procedureType={procedureType}
        areContestedProducts
        asLink
        readonly={procedureType === PROCEDURE_RENEWAL.value && !renewalReclassify && renewalType}
        canAddClasses={procedureType !== PROCEDURE_RENEWAL.value || renewalReclassify || !renewalType}
      />

    </div>
  )
}

export default ContestedProductsAndServices
