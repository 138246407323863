import { containsErrors, DOCUMENT_ADDITIONAL_TYPES, DOCUMENT_TYPES, FieldStatus, isFilled, messageIsFilled, RevocationStatement, TransactionDocument } from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

/**
 * Validateur de l'étape de la demande de relevé de déchéance
 */
class RevocationStatementValidation {
    /**
     * Validation des documents.
     * Tout document enregistrés doit posséder un type
     * @param documents
     * @returns
     */
    validateDocument = (documents: TransactionDocument[]): FieldStatus => {
      let fieldStatus: FieldStatus = {}

      // Acte à accomplir
      const actFiles: TransactionDocument[] = documents.filter((document: TransactionDocument) => document.additionalType === DOCUMENT_ADDITIONAL_TYPES.REVOCATION_STATEMENT_ACT)
      if (actFiles) {
        actFiles.forEach((file: TransactionDocument, key: number) => {
          if (!file.type) {
            fieldStatus = {
              ...fieldStatus,
              // Correspond à l'inputId renseigner dans le composant DocumentBlockItem
              [`document_${file.additionalType?.toLocaleLowerCase()}_${key}_type`]: Message.required_field
            }
          }
        })
      }

      // Pièces justificatives
      const supportingFiles: TransactionDocument[] = documents.filter((document: TransactionDocument) => document.additionalType === DOCUMENT_ADDITIONAL_TYPES.REVOCATION_STATEMENT_SUPPORTING)
      if (supportingFiles) {
        supportingFiles.forEach((file: TransactionDocument, key: number) => {
          if (!file.type) {
            fieldStatus = {
              ...fieldStatus,
              // Correspond à l'inputId renseigner dans le composant DocumentBlockItem
              [`document_${file.additionalType?.toLocaleLowerCase()}_${key}_type`]: Message.required_field
            }
          }
        })
      }
      return fieldStatus
    }

    /**
     * Validation de l'étape de demande de relecé de déchéance
     * @param documents
     * @param revocationStatement
     * @returns
     */
    validateStep = (documents: TransactionDocument[], revocationStatement?: RevocationStatement): boolean => {
      const newFieldStatus: FieldStatus = {
        inscriptionNumber: revocationStatement?.hasInscriptionNumber ? messageIsFilled(revocationStatement.inscriptionNumber) : '',
        request: !isFilled(revocationStatement?.requestText) && !documents.some((document: TransactionDocument) => document.type === DOCUMENT_TYPES.REVOCATION_STATEMENT_REQUEST) ? Message.revocation_statement_request_needed : '',
        ...this.validateDocument(documents)
      }
      store.dispatch(storeTransactionFieldStatusUpdate(newFieldStatus))
      return !containsErrors(newFieldStatus)
    }
}

export default new RevocationStatementValidation()
