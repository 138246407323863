import Message from './Message'
import { TableTitleItem } from '@inpi-marques/components'

export const FIELD_NUMNAT = 'numNat'
export const FIELD_ARRIVAL_DATE = 'arrivalDate'

export const TABLE_TRANSACTION_LIST : TableTitleItem[] = [
  {
    label: Message.transactions_numNat,
    value: FIELD_NUMNAT
  },
  {
    label: Message.transactions_arrival_date,
    value: FIELD_ARRIVAL_DATE
  }
]
