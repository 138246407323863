import React, { FC } from 'react'
import { CheckboxField, LinkedTransaction, Transaction } from '@inpi-marques/components'
import TransactionService from 'services/transaction/TransactionService'

interface AgentAttachmentTableBodyProps {
    transactionAttachments: LinkedTransaction[], // Transaction attachée à l'inscription
    attachments: Transaction[], // Liste de toutes les transactions pouvant être rattachées
    onSelect: (transaction: Transaction) => void
}

const AgentAttachmentTableBody: FC<AgentAttachmentTableBodyProps> = ({ transactionAttachments, attachments, onSelect }) =>
  <tbody>
    {attachments.map((attachment: Transaction, key: number) =>
      <tr key={key}>
        <td>
          <CheckboxField
            inputId={`${attachment.id}-is-selected`}
            onChange={() => onSelect(attachment)}
            checked={transactionAttachments.some((transactionAttachment: LinkedTransaction) => attachment.numNat === transactionAttachment.numNat)}
            value={transactionAttachments.some((transactionAttachment: LinkedTransaction) => attachment.numNat === transactionAttachment.numNat)}
            className='mb-0'
            label=''
          />
        </td>
        <td>{attachment.numNat}</td>
        <td>{TransactionService.getProcedureType(attachment)?.label}</td>
        <td>{attachment.agent?.searchableIdentity}</td>
      </tr>

    )}
  </tbody>

export default AgentAttachmentTableBody
