import { FormattedMessage, useIntl } from 'react-intl'
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  TextField,
  FieldStatus,
  isTextLatin,
  EventType,
  Transaction
} from '@inpi-marques/components'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdate } from 'store/transaction/transactionActions'
import debounce from 'lodash.debounce'
import Message from '../../constants/Message'

interface InternalReferenceFieldProps {
  transaction: Transaction,
  className?:string
}

const InternalReferenceField: FC<InternalReferenceFieldProps> = ({
  transaction,
  className
}) => {
  const dispatch = useDispatch()
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const intl = useIntl()

  const [internalReference, setInternalReference] = useState<string|undefined>(transaction?.internalReference)

  const updateInternaleReference = async (): Promise<void> => {
    if (internalReference && !isTextLatin(internalReference)) {
      setFieldStatus({
        internalReference: Message.text_not_latin
      })
      return
    }

    internalReference !== undefined && dispatch(storeTransactionUpdate({
      ...transaction,
      internalReference
    }))
  }

  const delayedChange = useCallback(debounce(updateInternaleReference, 500), [internalReference])

  useEffect(() => {
    delayedChange()
    return delayedChange.cancel
  }, [delayedChange])

  useEffect(() => {
    setInternalReference(transaction?.internalReference)
  }, [transaction?.internalReference])

  return (
    <div className={className}>
      <TextField
        type='text'
        inputId='internalReference'
        label={<FormattedMessage id='field_internal_reference_label' />}
        value={internalReference}
        onChange={(event: EventType) => setInternalReference(event.target.value)}
        placeholder={intl.formatMessage({ id: 'field_internal_reference_label_placeholder' })}
        className='internal-reference-input'
        isValid={!!internalReference?.length && isTextLatin(internalReference)}
        fieldStatus={fieldStatus}
      />
    </div>
  )
}

export default InternalReferenceField
