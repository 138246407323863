import { containsErrors, DOCUMENT_TYPES, FieldStatus, Inscription, INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD, isFilled, messageIsFilled, TransactionDocument } from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class ContestFormValidator {
  /**
   * Valide la date de fin du concours
   * @param startDate
   * @param endDate
   * @returns
   */
  validateEndDate = (startDate?: string, endDate?: string): string => {
    if (startDate && endDate && startDate !== endDate) {
      return new Date(startDate).getTime() > new Date(endDate).getTime() ? Message.contest_form_end_date_error : ''
    }
    return messageIsFilled(endDate)
  }

    /**
     * Validation de l'étape de la description du concours
     * @param documents
     * @param inscription
     * @returns
     */
    validateContestForm = (documents: TransactionDocument[], inscription?: Inscription): boolean => {
      const rankingDocuments: TransactionDocument[] = documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_RANKING)
      const fieldStatus: FieldStatus = {
        type: messageIsFilled(inscription?.contest?.type),
        title: messageIsFilled(inscription?.contest?.title),
        place: messageIsFilled(inscription?.contest?.place),
        startDate: messageIsFilled(inscription?.contest?.startDate),
        endDate: this.validateEndDate(inscription?.contest?.startDate, inscription?.contest?.endDate),
        rewardKind: inscription?.contest?.type === INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD.value ? messageIsFilled(inscription?.contest?.rewardKind) : '',
        documents: !isFilled(rankingDocuments) ? Message.documents_ranking_missing : ''
      }
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

      return !containsErrors(fieldStatus)
    }
}

export default new ContestFormValidator()
