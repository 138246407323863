import React, { FC } from 'react'
import { DOCUMENT_ADDITIONAL_TYPES, DOCUMENT_TYPES, Transaction, TransactionDocument } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'

interface RevocationStatementRequestOverviewProps {
    transaction: Transaction
}

/**
 * Récapitulatif de la demande de relevé de déchéance
 */
const RevocationStatementRequestOverview: FC<RevocationStatementRequestOverviewProps> = ({ transaction }) => {
  const requestFiles: TransactionDocument[] =
        transaction.documents?.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.REVOCATION_STATEMENT_REQUEST) ?? []

  const actFiles: TransactionDocument[] =
        transaction.documents?.filter((document: TransactionDocument) => document.additionalType === DOCUMENT_ADDITIONAL_TYPES.REVOCATION_STATEMENT_ACT) ?? []

  const supportingFiles: TransactionDocument[] =
        transaction.documents?.filter((document: TransactionDocument) => document.additionalType === DOCUMENT_ADDITIONAL_TYPES.REVOCATION_STATEMENT_SUPPORTING) ?? []

  return (
    <>
      <div>
        <label className='text-gris'><FormattedMessage id='revocation_statement_request_text_overview_label' /></label>
        {transaction.revocationStatement?.requestText &&
          <div>{transaction.revocationStatement.requestText}</div>}
        <div>
          <FormattedMessage id='revocation_statement_request_document_overview_label' /> : {requestFiles.length} <FormattedMessage id='revocation_statement_documents_count_overview_label' />
        </div>
      </div>
      {(actFiles.length > 0 || supportingFiles.length > 0) &&
        <div className='mt-2'>
          {actFiles.length > 0 &&
            <>
              <FormattedMessage id='revocation_statement_act_document_overview_label' /> : {actFiles.length} <FormattedMessage id='revocation_statement_documents_count_overview_label' /><br />
            </>}
          {supportingFiles.length > 0 &&
            <>
              <FormattedMessage id='revocation_statement_supporting_document_overview_label' /> : {supportingFiles.length} <FormattedMessage id='revocation_statement_documents_count_overview_label' />
            </>}
        </div>}

      {transaction.revocationStatement?.inscriptionNumber &&
        <div className='mt-2'>
          <FormattedMessage id='revocation_statement_request_inscription_rnm_overview_label' /> : {transaction.revocationStatement.inscriptionNumber}
        </div>}
    </>
  )
}

export default RevocationStatementRequestOverview
