import { containsErrors, FieldStatus, isFilled, PROCEDURE_OPPOSITION, Transaction, PROCEDURE_NULLITY, PROCEDURE_REVOCATION } from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class FoundationValidator {
  validateOnChangeStep = (transaction: Transaction) => {
    let fieldStatus: FieldStatus = {}
    if (transaction.procedureType === PROCEDURE_OPPOSITION.value) {
      if (!isFilled(transaction.opposition?.foundations)) {
        fieldStatus = { foundations: Message.opposition_foundations_required }
      }
    } else if (transaction.procedureType === PROCEDURE_NULLITY.value) {
      if (!transaction.opposition?.foundations?.length && !transaction.opposition?.motives?.length) {
        fieldStatus = { motives: Message.nullity_foundations_missing }
      }
    } else if (transaction.procedureType === PROCEDURE_REVOCATION.value && !transaction.opposition?.motives?.length) {
      fieldStatus = { motives: Message.nullity_foundations_missing }
    }
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new FoundationValidator()
