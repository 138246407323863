import { Brand, CardBlock, Priority, ProductClassVersion, ProtectionExtension, Record } from '@inpi-marques/components'
import DepositBrandOverview from 'component/deposit/overview/DepositBrand'
import ProductAndServicesOverview from 'component/deposit/overview/ProductAndServices/ProductAndServices'
import OverviewPriorities from 'component/priorities/OverviewPriorities'
import OverviewProtectionExtension from 'component/protectionExtensions/OverviewProtectionExtension'
import IntervenantsOverview from 'component/transactions/overview/IntervenantsOverview'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import InformationsOverview from './InformationsOverview'

interface RecordOverviewProps {
    record: Record
}

const RecordOverview: FC<RecordOverviewProps> = ({ record }) => {
  const brand: Brand | undefined = record?.details?.brand
  const priorities: Priority[] | undefined = record?.details?.priorities
  const protectionExtensions: ProtectionExtension[] | undefined = record?.details?.protectionExtensions
  const productVersion: ProductClassVersion[] | undefined = record?.details?.productsAndServicesVersions

  return (
    <div>
      {/* Type de demande */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='overview_deposit_type_title' />} shadow>
          <InformationsOverview record={record} />
        </CardBlock>
      </div>

      {/* Intervenants */}
      <div className='col-12 mb-3'>
        <CardBlock header={<FormattedMessage id='overview_deposit_intervenants_title' />} shadow>
          <IntervenantsOverview transaction={record} />
        </CardBlock>
      </div>

      {/* Marque */}
      {brand &&
        <div className='col-12 mb-3'>
          <CardBlock header={<FormattedMessage id='overview_deposit_brand_title' />} shadow>
            <DepositBrandOverview brand={brand} transaction={record} />
          </CardBlock>
        </div>}

      {/* Produits et service */}
      {productVersion && productVersion.length > 0 &&
        <div className='col-12 mb-3'>
          <ProductAndServicesOverview editable={false} versions={productVersion} />
        </div>}

      {/* Options */}
      {(priorities || protectionExtensions) &&
        <div className='col-12 mb-3'>
          <CardBlock header={<FormattedMessage id='overview_deposit_options_title' />} shadow>
            {priorities &&
              <div className='mb-3'>
                <OverviewPriorities priorities={priorities} hasTitle />
              </div>}
            {protectionExtensions && <OverviewProtectionExtension protectionExtensions={protectionExtensions} />}
          </CardBlock>
        </div>}
    </div>)
}

export default RecordOverview
