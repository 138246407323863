import { Transaction } from '@inpi-marques/components'
import { Payment } from '@inpi-marques/components/src/interfaces/deposit/Deposit'
import http from 'network/http-common'

class RevocationStatementService {
  /**
   * Récupère le récapitulatif de paiement d'un relevé de déchéance.
   * @returns
   * @param transaction
   */
     getPaiement = async (transaction: Transaction): Promise<Payment> => {
       try {
         const result: Payment = await http.get(`/api/revocation-statements/${transaction.id}/paiements`)
         return Promise.resolve(result)
       } catch (error) {
         return Promise.reject(error)
       }
     }
}

export default new RevocationStatementService()
