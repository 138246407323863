import { DateField, DateUtils, DATE_ISO, DOCUMENT_FORMATS, DOCUMENT_TYPES, EventType, FileBrowserField, FileButton, FoundationService, OppositionFoundationTypeComponent, PROCEDURE_OPPOSITION, TextArea, TextField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../..//constants/Message'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT DE TYPE DENOMINATION OU RAISON SOCIALE
 */
const FoundationDenominationForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentAdded, onDocumentDeleted, documents, procedureType, setFieldStatus, disableFiles, addToRefs, disableActiveField, handleDownload }) => (
  <IntlProvider locale='fr' messages={Message}>
    {/* IDENTIFIER LA MARQUE ANTERIEURE */}
    <div className='row'>
      {/* Désignation de la dénomination ou raison sociale */}
      <TextField
        addToRefs={addToRefs}
        inputId='entity_name'
        label={<FormattedMessage id='foundation_denomination_name_label' />}
        classNameFormGroup='col-4'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'name', event.target.value))}
        value={foundation.entity?.name}
        required
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      {/* NUMERO D'IMMATRICULATION */}
      <TextField
        inputId='registering-number'
        label={<FormattedMessage id='foundation_denomination_number_label' />}
        classNameFormGroup='col-4'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'registeringNumber', event.target.value))}
        value={foundation.entity?.registeringNumber}
      />
      {/* DATE D'IMMATRICULATION */}
      <DateField
        classNameFormGroup='col-4'
        inputId='createdDate'
        label={<FormattedMessage id='foundation_denomination_matriculation_date_label' />}
        value={DateUtils.formateDateToFormat(foundation.entity?.matriculationDate, DATE_ISO)}
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'matriculationDate', DateUtils.formatToBeginOfDay(event.target.value)))}
      />
      {/* Document(s) justifiant de l’existence et de la portée du droit antérieur */}
      {!disableFiles &&
        <FileBrowserField
          className='col-4'
          inputId='rightFile'
          classNameButton='d-contents'
          buttonLabel={<FileButton />}
          label={<FormattedMessage id='foundation_right_file_label' />}
          acceptApplication={DOCUMENT_FORMATS.PDF}
          onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_CORPORATE_EXISTENCE)}
          value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_CORPORATE_EXISTENCE)}
          onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
          required={procedureType !== PROCEDURE_OPPOSITION.value}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
          informationDoc
          handleDownload={handleDownload}
        />}
      {procedureType === PROCEDURE_OPPOSITION.value &&
        <TextArea
          classNameFormGroup='col-4'
          inputId='activity'
          onChange={(event: EventType) => setFoundation({ ...foundation, activity: event.target.value })}
          value={foundation.activity}
          required
          label={<FormattedMessage id='foundation_form_activity_label_denomination' />}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
        />}
    </div>
    {/* FONDEMENT ACTIF */}
    <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
  </IntlProvider>
)

export default FoundationDenominationForm
