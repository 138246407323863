import http from '../../network/http-common'
import { HelpItem } from '../../interfaces/HelpInterfaces'

/**
 * Classe permettant de gérer les aides
 */
class HelpService {
  /**
   * Récupération de toutes les aides
   * @returns Promise<HelpItem[]
   */
  getHelps = async (): Promise<HelpItem[]> => {
    try {
      return await http.get('/api/helps')
    } catch (error) {
      return Promise.resolve([])
    }
  }
}

export default new HelpService()
