import React, { FC } from 'react'
import { ProductClassVersion, SubmitButton } from '@inpi-marques/components'
import OverviewLimitedProductsAndServices from './OverviewLimitedProductsAndServices'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface PriorityListFormProps {
  limitedProductsAndServices: ProductClassVersion[],
  editLimitedProductsAndServiceVersion: (index: number) => void,
  deleteLimitedProductsAndServicesVersion: (index: number) => void,
  addLimitedProductsAndServiceVersion: () => void
}

const LimitedProductsAndServicesListForm: FC<PriorityListFormProps> = (
  {
    limitedProductsAndServices,
    deleteLimitedProductsAndServicesVersion,
    editLimitedProductsAndServiceVersion,
    addLimitedProductsAndServiceVersion
  }) => {
  return (
    <>
      <div>
        <SubmitButton
          className='btn-select btn-outline-primary btn-add'
          onClick={() => addLimitedProductsAndServiceVersion()}
        >
          <FormattedMessage id='limited_products_services_add_limitation' />
          <FontAwesomeIcon className='ml-2' icon={faPlus} />
        </SubmitButton>
      </div>
      {limitedProductsAndServices && limitedProductsAndServices.length > 0 && (
        <OverviewLimitedProductsAndServices
          limitedProductsAndServices={limitedProductsAndServices}
          deleteLimitedProductsAndServicesVersion={deleteLimitedProductsAndServicesVersion}
          editLimitedProductsAndServiceVersion={editLimitedProductsAndServiceVersion}
          className='mt-5'
        />
      )}
    </>
  )
}

export default LimitedProductsAndServicesListForm
