import {
  EventType,
  Transaction,
  DateField,
  DateUtils,
  DATE_ISO,
  FRMI,
  DecisionTypes
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'

interface DecisionsProps {
    transaction: Transaction
}

const Decisions: FC<DecisionsProps> = ({ transaction }) => {
  const dispatch = useDispatch()

  /**
   * Lors de l'édition du type de décision
   * @param frmi
   */
  const onFRMIChanged = (frmi: FRMI): void => {
    dispatch(storeTransactionUpdateFRMI(frmi))
  }
  /**
   * A l'édition de la date d'effet
   */
  const onEffectDateChanged = (value: string): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      effectDate: DateUtils.formatToBeginOfDay(value)
    }))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_decisions_title' /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div>
        <h2 className='text-primary mb-2'>
          <FormattedMessage id='frmi_decisions_title_text' />
        </h2>
        <DecisionTypes frmi={transaction.frmiDetails} onFRMIChanged={onFRMIChanged} />
      </div>
      {/* Date à partir de laquelle des faits et décisions produisent leurs effets */}
      <DateField
        inputId='effectDate'
        classNameFormGroup='col-4 p-0 mt-4'
        value={DateUtils.formateDateToFormat(transaction?.frmiDetails?.effectDate, DATE_ISO) ?? ''}
        label={<FormattedMessage id='frmi_decision_effect_date_label' />}
        onChange={(event: EventType) => onEffectDateChanged(event.target.value)}
      />
    </>
  )
}

export default Decisions
