import React, { FC, useEffect, useState } from 'react'
import {
  BASIC_DATE,
  Country,
  DateUtils,
  PROCEDURE_OPPOSITION,
  ProductClassVersion, ProductService,
  Transaction,
  ProductAndServicesAccordion,
  ProductAndServiceListView
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import ContentService from '../../../services/content/ContentService'
import { BRAND_TYPES } from '../../../constants/BrandConstants'
import ProductsService from '@inpi-marques/components/src/services/Product/ProductsService'

interface ContestedRequestOverviewProps {
  transaction: Transaction
}

const ContestedRequestOverview : FC<ContestedRequestOverviewProps> = ({
  transaction
}) => {
  const contestedRequest = transaction.opposition?.contestedRequest
  const contestedRequestCurrentVersion: ProductClassVersion | undefined = contestedRequest?.productsAndServicesVersions && ProductService.getCurrentVersion(contestedRequest.productsAndServicesVersions)
  const [countries, setCountries] = useState<Country[]>([])
  const [viewMode, setViewMode] = useState<'accordion' | 'list'>('accordion')

  useEffect(() => {
    ContentService.getCountries().then(setCountries)
  }, [])

  return (
    <div className='d-flex flex-wrap row'>
      <div className='col-12 col-md-6 mb-3'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id='overview_brand_type' /> <FormattedMessage id={`${contestedRequest?.isInternationalBrand ? 'overview_brand_type_international' : 'overview_brand_type_french'}`} />
        </h4>
      </div>
      <div className='col-12 col-md-6 mb-3'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id={`${contestedRequest?.isInternationalBrand ? 'request_identification_numnat_international' : 'request_identification_numnat'}`} />
        </h4>
        <p className='subtitle'> {contestedRequest?.numnat} </p>
      </div>
      <div className='col-12 col-md-6 mb-3'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id={`${contestedRequest?.isInternationalBrand ? 'request_identification_contested_brand_name_international' : 'request_identification_contested_brand_name'}`} />
        </h4>
        <p className='subtitle'> {contestedRequest?.contestedBrand} </p>
      </div>
      <div className='col-12 col-md-6 mb-3'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id={`${contestedRequest?.isInternationalBrand ? 'request_identification_date_international' : 'request_identification_deposit_date'}`} />
        </h4>
        <p className='subtitle'> {DateUtils.formateDateToFormat(contestedRequest?.depositDate, BASIC_DATE)} </p>
      </div>
      <div className='col-12 col-md-6 mb-3'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id='request_identification_brand_type_label' />
        </h4>
        {
              contestedRequest?.brandType && BRAND_TYPES.find((type) => type.value === contestedRequest?.brandType) &&
                <p className='subtitle'> <FormattedMessage id={BRAND_TYPES.find((type) => type.value === contestedRequest?.brandType).title} /> </p>
        }
      </div>
      {
        transaction.procedureType === PROCEDURE_OPPOSITION.value &&
          <div className='col-12 col-md-6 mb-3'>
            <h4 className='mb-2 font-weight-bold'>
              <FormattedMessage id={`${contestedRequest?.isInternationalBrand ? 'request_identification_ompi_number' : 'request_identification_bopi_number'}`} />
            </h4>
            <p className='subtitle'> {contestedRequest?.publicationNumber} </p>
          </div>
      }
      {contestedRequest?.registrationDate && (transaction.procedureType === PROCEDURE_OPPOSITION.value || !contestedRequest?.isInternationalBrand) &&
        <div className='col-12 col-md-6 mb-3'>
          <h4 className='mb-2 font-weight-bold'>
            <FormattedMessage id='request_identification_registration_date' />
          </h4>
          <p className='subtitle'> {DateUtils.formateDateToFormat(contestedRequest?.registrationDate, BASIC_DATE)} </p>
        </div>}
      {contestedRequest?.grantProtectionDate &&
        <div className='col-12 col-md-6 mb-3'>
          <h4 className='mb-2 font-weight-bold'>
            <FormattedMessage id='request_identification_grant_protection_date_overview' />
          </h4>
          <p className='subtitle'> {DateUtils.formateDateToFormat(contestedRequest?.grantProtectionDate, BASIC_DATE)} </p>
        </div>}
      {
        contestedRequest?.priority &&
          <div className='col-12 col-md-6 mb-3'>
            <h4 className='mb-2 font-weight-bold'>
              <FormattedMessage id='overview_priority_claimed' />
            </h4>
            <p className='subtitle'> {countries.find(item => item.code === contestedRequest?.priority?.countryOrOrganization)?.name || contestedRequest?.priority?.countryOrOrganization} - {DateUtils.formateDateToFormat(contestedRequest?.priority.originalDepositDate, BASIC_DATE)} </p>
          </div>
      }
      {
        contestedRequest?.isTotalProductsAndServices ? (
          <div className='col-12 mb-3'>
            <h4 className='mb-2 font-weight-bold'>
              <FormattedMessage id={`${transaction.procedureType?.toLowerCase()}_overview_total_product_and_services`} />
            </h4>
          </div>)
          : (
            <div className='col-12 mt-3'>
              <div className='d-flex justify-content-end mb-4'>
                {viewMode === 'accordion' && (<button className='btn btn-link text-primary' onClick={() => setViewMode('list')}><FormattedMessage id='products_and_services_by_class' /></button>)}
                {viewMode === 'list' && (<button className='btn btn-link text-primary' onClick={() => setViewMode('accordion')}><FormattedMessage id='products_and_services_by_list' /></button>)}
              </div>
              <h4 className='mb-2 font-weight-bold'>
                <FormattedMessage id={`${transaction.procedureType?.toLowerCase()}_overview_contested_registration_products_and_services_title`} /> :
              </h4>
              <div className='mt-4'>
                {viewMode === 'accordion'
                  ? <ProductAndServicesAccordion productClasses={contestedRequestCurrentVersion ? ProductsService.clearProductClassVersion(contestedRequestCurrentVersion).productClasses : []} />
                  : <ProductAndServiceListView productClasses={contestedRequestCurrentVersion ? ProductsService.clearProductClassVersion(contestedRequestCurrentVersion).productClasses : []} />}
              </div>
            </div>
          )
      }
    </div>
  )
}

export default ContestedRequestOverview
