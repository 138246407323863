import {
  LogAction,
  LOG_OUT,
  LOG_IN, UPDATE_USER
} from './userStoreTypes'
import { UserInfo } from '../../interfaces/User'

export const storeLogout = (): LogAction => ({
  type: LOG_OUT,
  user: {
    token: null,
    user: null,
    tokenETS: null
  }
})

export const storeLogin = (user: UserInfo): LogAction => ({
  type: LOG_IN,
  user
})

export const storeUpdateUser = (user: UserInfo): LogAction => ({
  type: UPDATE_USER,
  user
})
