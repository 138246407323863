import { FIELD_STATUS_UPDATE, FIELD_STATUS_RESET, FieldStatusAction } from './fieldStatusTypes'
import { FieldStatus } from '@inpi-marques/components'

/**
 * On met à jour le transactionFieldStatus du store.
 *
 * Un field status peux contenir de multiple variable, qui seront soit des strings, soit des FieldStatus
 *
 * Si nous devons mettre à jour une variable qui est un fieldStatus, alors on trouvera dans
 * le parametre nextFieldStatus, un type, qui nous permettra de le mettre à jour.
 *
 * Par exemple:
 *
 *  Si je cherche à mettre à jour la variable 'depositor' (qui est un fieldStatus, car il contient toutes les données
 * présentes dans le formulaire d'un déposant), alors nextFieldStatus contiendra une variable fieldStatusType qui
 * aura pour valeur "depositor".
 * Grâce à ça, je peux donc mettre à jour transactionFieldStatus.depositor ou transactionFieldStatus['depositor']
 *
 * S'il n'y a pas de type dans nextFieldStatus, alors on cherche à mettre à jour directement transactionFieldStatus
 *
 * @param state
 * @param nextFieldStatus
 * @returns
 */
const updateFieldStatus = (state: any, nextFieldStatus?: FieldStatus) => {
  if (nextFieldStatus && nextFieldStatus.fieldStatusType) {
    const updateFieldStatus: FieldStatus = {
      ...state
    }
    updateFieldStatus[(nextFieldStatus.fieldStatusType) as string] = { ...nextFieldStatus }
    return updateFieldStatus
  }
  return { ...nextFieldStatus }
}

export function reducerTransactionFieldStatusState (state = {}, action: FieldStatusAction): FieldStatus {
  switch (action.type) {
    case FIELD_STATUS_UPDATE:
      return updateFieldStatus(state, action.fieldStatus)
    case FIELD_STATUS_RESET:
      return {}
    default:
      return state
  }
}
