import http from '../network/http-common'

class VersionApiService {
    /**
     * Récupère la version de l'api
     */
    getVersion = async (): Promise<string> => {
      try {
        return await http.get('/api/version-api')
      } catch (error) {
        return Promise.reject(error)
      }
    }
}
export default new VersionApiService()
