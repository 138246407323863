import {
  DATE_ISO,
  DateField,
  DateUtils,
  EventType,
  FieldStatus,
  TextField,
  TransactionInfos
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'

interface TransactionFromTitleFormProps {
    fieldStatus?: FieldStatus,
    // transaction trouvée à partir d'un titre à modifier
    transactionFromTitle?: TransactionInfos,
    // fonction pour modifier la transaction
    setTransactionFromTitle: (newTransactionFromTitle: TransactionInfos) => void
}

const TransactionFromTitleForm: FC<TransactionFromTitleFormProps> = ({
  fieldStatus,
  transactionFromTitle,
  setTransactionFromTitle
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const onValueChanged = (event: EventType) => {
    const { name, value } = event.target
    const updatedTransaction: TransactionInfos = { ...transactionFromTitle, [name]: value }
    setTransactionFromTitle(updatedTransaction)
  }

  return (
    <>
      <div className='row d-flex mt-2 p-0'>
        <div className='col-12 col-lg-4'>
          <DateField
            inputId='arrivalDate'
            required
            value={DateUtils.formateDateToFormat(transactionFromTitle?.arrivalDate, DATE_ISO) ?? ''}
            label={<FormattedMessage id='official_documents_requested_column_label_arrival_date' />}
            placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
            onChange={onValueChanged}
            fieldStatus={fieldStatus}
            nameFieldStatus='transactionFromTitleArrivalDate'
            classNameLabel='col-12 p-0'
            dispatch={dispatch}
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
        <div className='col-12 col-lg-4'>
          <TextField
            inputId='numNat'
            required
            value={transactionFromTitle?.numNat}
            label={<FormattedMessage id='official_documents_requested_column_label_numNat' />}
            onChange={onValueChanged}
            fieldStatus={fieldStatus}
            nameFieldStatus='transactionFromTitleNumNat'
            classNameLabel='col-12 p-0'
            dispatch={dispatch}
            resetError={storeTransactionFieldStatusUpdate}
            maxLength={500}
          />
        </div>
      </div>
    </>
  )
}

export default TransactionFromTitleForm
