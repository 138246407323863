import {
  OPPOSITION_FOUNDATION_BRAND_ORIGINS,
  OPPOSITION_FOUNDATION_OPPONENT_QUALITIES,
  OPPOSITION_FOUNDATION_TYPE_BRAND,
  OppositionFoundationTypeComponent,
  SwitchField
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import BrandForm from './BrandForm'
import OpponentForm from './OpponentForm'
import ErrorField from '../../../../form/errors/ErrorField'
import { resetError as resetErrorUtils, resetStateError } from '../../../../utils/formUtils'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT DE TYPE MARQUE
 */
const FoundationBrandForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentAdded, documents, onDocumentDeleted, onDocumentsDeleted, setFieldStatus, disableFiles, countries = [], verifyNumnat, addToRefs, disableActiveField, dispatch, procedureType, handleDownload }) => {
  // Nom du champ d'erreur associé aux switchFields du bloc Signes
  // Permet d'afficher et de reset l'erreur
  const foundationSign = 'foundation_sign'
  // Nom du champ d'erreur associé aux switchFields du bloc Produits et Services
  // Permet d'afficher et de reset l'erreur
  const foundationProduct = 'foundation_product'

  /**
   * On clean l'erreur du champs au changement de sa valeur.
   * @param errorFieldStatus
   * @param nameFieldStatus
   */
  const onSwitchChange = (nameFieldStatus?: string, errorFieldStatus?: string): void => {
    nameFieldStatus && setFoundation({ ...foundation, [nameFieldStatus]: !foundation[nameFieldStatus] })
    /** On clean l'erreur du store au changement */
    if (dispatch && setFieldStatus) {
      resetErrorUtils(dispatch, setFieldStatus, fieldStatus, errorFieldStatus)
    } else if (setFieldStatus) {
      resetStateError(setFieldStatus, fieldStatus, errorFieldStatus)
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      {/* IDENTIFIER LA MARQUE ANTERIEURE */}
      <div className='row'>
        <h2 className='text-primary col-12'>
          <FormattedMessage id='foundation_form_brand_part_label' />
        </h2>
        <BrandForm
          addToRefs={addToRefs}
          verifyNumnat={verifyNumnat}
          countries={countries}
          disableFiles={disableFiles}
          setFieldStatus={setFieldStatus}
          foundation={foundation}
          setFoundation={setFoundation}
          foundationType={OPPOSITION_FOUNDATION_TYPE_BRAND.value}
          brandOrigins={OPPOSITION_FOUNDATION_BRAND_ORIGINS}
          fieldStatus={fieldStatus}
          onDocumentAdded={onDocumentAdded}
          documents={documents}
          onDocumentDeleted={onDocumentDeleted}
          onDocumentsDeleted={onDocumentsDeleted}
          procedureType={procedureType}
          handleDownload={handleDownload}
        />
      </div>
      {/* OPPOSANT */}
      <div className='row mt-2'>
        <h2 className='text-primary col-12'>
          <FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_form_opponent_part_label`} />
        </h2>
        <OpponentForm
          procedureType={procedureType}
          addToRefs={addToRefs}
          disableFiles={disableFiles}
          setFieldStatus={setFieldStatus}
          foundation={foundation}
          setFoundation={setFoundation}
          opponentQualities={OPPOSITION_FOUNDATION_OPPONENT_QUALITIES}
          fieldStatus={fieldStatus}
          onDocumentAdded={onDocumentAdded}
          documents={documents}
          onDocumentDeleted={onDocumentDeleted}
          handleDownload={handleDownload}
        />
      </div>
      {/* PRODUITS ET SERVICES */}
      <div className='row mt-2 flex-column' ref={(ref) => addToRefs && addToRefs(ref)} id={foundationProduct}>
        <h2 className='text-primary col-12'>
          <FormattedMessage id='foundation_form_products_part_label' />
        </h2>
        <label className='pl-3'>
          <FormattedMessage id='foundation_products_label' />
          <span className='text-danger'> *</span>
        </label>
        <div className='pl-4 mt-4 form-group'>
          <SwitchField
            divClassName='col-3 justify-content-between'
            labelClassName='mr-5'
            inputId='productIdentical'
            onChange={() => onSwitchChange('areProductsIdentical', foundationProduct)}
            value={foundation.areProductsIdentical}
            label={<FormattedMessage id='foundation_identical_label' />}
          />
          <SwitchField
            divClassName='col-3 justify-content-between'
            labelClassName='mr-5'
            inputId='productSimilar'
            onChange={() => onSwitchChange('areProductsSimilar', foundationProduct)}
            value={foundation.areProductsSimilar}
            label={<FormattedMessage id='foundation_similar_label' />}
          />
          <ErrorField message={fieldStatus?.[foundationProduct]} />
        </div>
      </div>
      {/* SIGNES */}
      <div className='row mt-2 flex-column' ref={(ref) => addToRefs && addToRefs(ref)} id={foundationSign}>
        <h2 className='text-primary col-12'>
          <FormattedMessage id='foundation_form_sign_part_label' />
        </h2>
        <label className='pl-3'>
          <FormattedMessage id='foundation_sign_label' />
          <span className='text-danger'> *</span>
        </label>
        <div className='pl-4 mt-4'>
          <SwitchField
            divClassName='col-3 justify-content-between'
            labelClassName='mr-5'
            inputId='signIdentical'
            onChange={() => onSwitchChange('areSignsIdentical', foundationSign)}
            value={foundation.areSignsIdentical}
            label={<FormattedMessage id='foundation_identical_label' />}
          />
          <SwitchField
            divClassName='col-3 justify-content-between'
            labelClassName='mr-5'
            inputId='signSimilar'
            onChange={() => onSwitchChange('areSignsSimilar', foundationSign)}
            value={foundation.areSignsSimilar}
            label={<FormattedMessage id='foundation_similar_label' />}
          />
          <ErrorField message={fieldStatus?.[foundationSign]} />
        </div>
      </div>
      {/* FONDEMENTS */}
      <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
    </IntlProvider>
  )
}

export default FoundationBrandForm
