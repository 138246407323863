import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

interface PreviewLoaderProps {
  width?: number,
  height?: number
}

const PreviewLoader : FC<PreviewLoaderProps> = ({
  width = 200,
  height = 200
}) => {
  return (
    <ContentLoader
      speed={2}
      style={{ width: `${width}px`, height: `${height}px` }}
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
    >
      <rect x={0} y={0} rx='5' ry='5' width={400} height='100%' />
    </ContentLoader>
  )
}

export default PreviewLoader
