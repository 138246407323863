import { messageIsFilled, Priority } from '@inpi-marques/components'
import Message from '../../../../../constants/Message'
import moment from 'moment'

class PriorityFRMIValidator {
    validatePriority = (priority: Priority = {}) => {
      return {
        countryOrOrganization: messageIsFilled(priority.countryOrOrganization),
        originalDepositNumber: messageIsFilled(priority.originalDepositNumber),
        originalDepositDate: this.validateDepositDate(priority)
      }
    }

    validateDepositDate = (priority: Priority = {}) => {
      if (!priority || !priority.originalDepositDate) {
        return Message.priority_frmi_deposit_date_required
      } else if (!moment(priority.originalDepositDate).isValid()) {
        return Message.priority_frmi_deposit_date_wrong_format
      } else if (moment(priority.originalDepositDate) > moment()) {
        return Message.priority_frmi_deposit_date_too_late
      }
      return ''
    }
}

export default new PriorityFRMIValidator()
