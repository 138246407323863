import { STATUS_ND, STATUS_OPPOSITION } from '@inpi-marques/components'

export interface WorkflowType {
  label: string,
  workflow: string[]
}

export const WAIT_NOTIFICATION : WorkflowType = {
  label: 'workflow_wait_notification',
  workflow: [STATUS_OPPOSITION.PREPARATION, STATUS_OPPOSITION.TO_ATTRIBUTE, STATUS_OPPOSITION.OPPOSITION_MI, STATUS_OPPOSITION.OPPOSITION_FORMELLE, STATUS_OPPOSITION.TO_NOTIFY, STATUS_ND.PREPARATION, STATUS_ND.TO_ATTRIBUTE, STATUS_ND.TO_NOTIFY, STATUS_ND.SEARCH_TMC]
}

export const UNDER_INVESTIGATION : WorkflowType = {
  label: 'workflow_under_investigation',
  workflow: [STATUS_OPPOSITION.WAITING_ANSWER, STATUS_OPPOSITION.NOT_ACCEPTED, STATUS_ND.WAITING_ANSWER, STATUS_ND.NOT_ACCEPTED]
}

export const WAIT_DECISION : WorkflowType = {
  label: 'workflow_wait_decision',
  workflow: [STATUS_OPPOSITION.DECISION, STATUS_ND.DECISION]
}

export const SUSPENDED : WorkflowType = {
  label: 'workflow_suspended',
  workflow: [STATUS_OPPOSITION.SUSPENSION, STATUS_ND.SUSPENSION]
}

export const COMPLETED : WorkflowType = {
  label: 'workflow_completed',
  workflow: [STATUS_OPPOSITION.CLOSE, STATUS_OPPOSITION.FINALISATION, STATUS_OPPOSITION.DONE, STATUS_OPPOSITION.APPEAL, STATUS_ND.CLOSE, STATUS_ND.FINALISATION, STATUS_ND.DONE]
}

export const STATUS_WORKFLOW : WorkflowType[] = [
  WAIT_NOTIFICATION,
  UNDER_INVESTIGATION,
  WAIT_DECISION,
  SUSPENDED,
  COMPLETED
]
