import { OppositionFoundationTypeComponent, OPPOSITION_FOUNDATION_BRAND_RENOWNED_ORIGINS, OPPOSITION_FOUNDATION_OPPONENT_QUALITIES, OPPOSITION_FOUNDATION_TYPE_RENOWNED } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import BrandForm from '../brand/BrandForm'
import OpponentForm from '../brand/OpponentForm'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT D'UNE MARQUE DE RENOMMEE
 */
const FoundationRenownedForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentAdded, onDocumentDeleted, documents, setFieldStatus, disableFiles, countries = [], verifyNumnat, addToRefs, procedureType, disableActiveField, handleDownload }) =>
  (
    <IntlProvider locale='fr' messages={Message}>
      {/* IDENTIFIER LA MARQUE ANTERIEURE */}
      <div className='row'>
        <h2 className='text-primary col-12'>
          <FormattedMessage id='foundation_form_brand_part_label' />
        </h2>
        <BrandForm
          procedureType={procedureType}
          addToRefs={addToRefs}
          verifyNumnat={verifyNumnat}
          countries={countries}
          disableFiles={disableFiles}
          setFieldStatus={setFieldStatus}
          foundation={foundation}
          setFoundation={setFoundation}
          foundationType={OPPOSITION_FOUNDATION_TYPE_RENOWNED.value}
          brandOrigins={OPPOSITION_FOUNDATION_BRAND_RENOWNED_ORIGINS}
          fieldStatus={fieldStatus}
          onDocumentAdded={onDocumentAdded}
          onDocumentDeleted={onDocumentDeleted}
          documents={documents}
          handleDownload={handleDownload}
        />
      </div>
      {/* OPPOSANT */}
      <div className='row mt-2'>
        <h2 className='text-primary col-12'>
          <FormattedMessage id={`${procedureType?.toLowerCase()}_foundation_form_opponent_part_label`} />
        </h2>
        <OpponentForm
          procedureType={procedureType}
          addToRefs={addToRefs}
          disableFiles={disableFiles}
          setFieldStatus={setFieldStatus}
          foundation={foundation}
          setFoundation={setFoundation}
          opponentQualities={OPPOSITION_FOUNDATION_OPPONENT_QUALITIES}
          fieldStatus={fieldStatus}
          onDocumentAdded={onDocumentAdded}
          onDocumentDeleted={onDocumentDeleted}
          documents={documents}
          handleDownload={handleDownload}
        />
      </div>
      {/* FONDEMENT ACTIF */}
      <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
    </IntlProvider>
  )

export default FoundationRenownedForm
