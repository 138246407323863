import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors,
  DOCUMENT_TYPES,
  ErrorField,
  FieldStatus,
  SubmitButton,
  Transaction,
  TransactionDocument,
  TransactionDocumentType
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { useDispatch } from 'react-redux'
import TransactionService from '../../../../services/transaction/TransactionService'
import AddDocumentsWithTypeContent from '../../../transactions/documents/AddDocumentsWithTypeContent'
import { storeTransactionFieldStatusUpdate } from '../../../../store/fieldStatus/fieldStatusActions'
import DocumentValidator from '../../../requests/valitdator/DocumentValidator'
import DocumentService from '../../../../services/document/DocumentService'
import { toast } from 'react-toastify'
import Message from '../../../../constants/Message'
import DocumentList from '../../../list/DocumentList'

interface FRMIDocumentsProps {
  transaction: Transaction,
  fieldStatus: FieldStatus,
  setDocuments: (documents: TransactionDocument[]) => void,
  documents: TransactionDocument[]
}

const FRMIDocuments: FC<FRMIDocumentsProps> = ({
  transaction,
  fieldStatus,
  setDocuments,
  documents
}) => {
  const [typeDocumentList, setTypeDocumentList] = useState<TransactionDocumentType[]>()
  const [documentToAddList, setDocumentToAddList] = useState<TransactionDocument[]>([])
  const dispatch = useDispatch()

  /**
   * Permet d'envoyer les documents ajoutés
   */
  const handlePostDocuments = async () => {
    const status = DocumentValidator.validateDocuments(documentToAddList, true)
    dispatch(storeTransactionFieldStatusUpdate(status))

    let documentsInError: number[] = []
    let documentsCreated: TransactionDocument[] = []
    if (!containsErrors(status)) {
      for await (const [index, document] of documentToAddList.entries()) {
        try {
          const documentCreated = await DocumentService.createDocument(document, transaction.id)
          if (documentCreated) {
            documentsCreated = [...documentsCreated, documentCreated]
          }
        } catch (e) {
          documentsInError = [...documentsInError, index]
        }
      }

      const documentsToRetry: TransactionDocument[] = documentToAddList.filter((_, docIndex) => documentsInError.includes(docIndex))
      setDocumentToAddList(documentsToRetry)

      setDocuments([...documents, ...documentsCreated])
      if (documentsInError?.length === 0) {
        toast.success(Message.request_add_document_success)
      } else {
        toast.warning(Message.request_add_document_error)
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    // Récupération des types de documents
    TransactionService.getDocumentTypes(transaction.procedureType).then((result) => {
      setTypeDocumentList(result)
    })

    return () => {
      TransactionService.cancelRequest()
    }
  }, [])

  /**
   * Lorsque des documents sont ajoutés, on leur ajoute le type additionnel OTHER
   * @param editedDocuments
   */
  const onDocumentToAddEdited = (editedDocuments: TransactionDocument[]): void => {
    setDocumentToAddList(editedDocuments.map((document: TransactionDocument) => ({ ...document, additionalType: DOCUMENT_TYPES.OTHER })))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between form-header'>
        <header className='col-8 mb-4'>
          <h1>
            <FormattedMessage id='additional_documents_title' />
          </h1>
        </header>
        <InternalReferenceField
          className='col-3'
          transaction={transaction}
        />
      </div>
      <div className='d-flex flex-column'>
        <AddDocumentsWithTypeContent
          documentToAddList={documentToAddList}
          onDocumentToAddEdited={onDocumentToAddEdited}
          fieldStatus={fieldStatus}
          setFieldStatus={(fieldStatus: FieldStatus) => dispatch(storeTransactionFieldStatusUpdate(fieldStatus))}
          transaction={transaction}
          typeDocumentList={typeDocumentList}
        />
        {documentToAddList.length > 0 && (
          <SubmitButton
            onClick={handlePostDocuments}
            className='btn-primary align-self-end btn-select btn-add'
          >
            <FormattedMessage id='common_add' />
          </SubmitButton>
        )}
      </div>
      <div className='d-flex flex-column'>
        <div className='font-weight-bold'>
          <FormattedMessage id='overview_file_title' />
        </div>
        {/* On ne traite que les documents ayant pour type additionnel 'OTHER'  */}
        <DocumentList
          documents={documents?.filter((doc: TransactionDocument) => doc.additionalType === DOCUMENT_TYPES.OTHER)}
          transaction={transaction}
          setDocuments={setDocuments}
          showDeleteButton
          downloadDocument={(transactionId: string, internalName: string) => DocumentService.getDocumentFile(transactionId, internalName)}
        />
      </div>
      {fieldStatus.additionalDocument &&
        (
          <div className='col-12'>
            <ErrorField
              message={fieldStatus.additionalDocument}
              className='fade alert alert-danger show position-relative mt-4'
            />
          </div>
        )}
      {fieldStatus.ompi_proof_payment_missing &&
          (
            <div className='col-12'>
              <ErrorField
                message={fieldStatus.ompi_proof_payment_missing}
                className='fade alert alert-danger show position-relative mt-4'
              />
            </div>
          )}
    </>
  )
}
export default FRMIDocuments
