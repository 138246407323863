import {
  CardBlock,
  ProductClassVersion,
  Transaction,
  NOTIFICATION_STATUS_TO_ANSWER,
  PRODUCT_AND_SERVICE_VERSION_TYPE,
  Payment,
  ModalComponent,
  PRODUCT_CLASS_VERSION_STATUS,
  ProductAndServicesAccordion,
  HelpBlock,
  ProductAndServiceListView,
  PROCEDURE_RENEWAL,
  PROCEDURE_DEPOSIT
} from '@inpi-marques/components'
import ProductsService from '@inpi-marques/components/src/services/Product/ProductsService'
import React, { FC, useEffect, useState } from 'react'
import DepositService from 'services/deposit/DepositService'
import ProductAndServicesOverviewHeader from './HeaderBlock'
import { toast } from 'react-toastify'
import Message from '../../../../constants/Message'
import ProductService from '../../../../services/deposit/ProductsService'
import { FormattedMessage } from 'react-intl'
import RegularizationPaymentForm from './RegularizationPaymentForm'
import ProductsAndServicesEditList from 'component/deposit/form/productsAndServices/ProductAndServiceEditList'
import WithdrawOverview from './WithdrawOverview'

interface ProductAndServicesOverviewProps {
  versions: ProductClassVersion[]
  transaction?: Transaction
  setTransaction?: (transaction: Transaction) => void
  editingMode?: boolean,
  setEditingModes?: (editingMode: boolean) => void,
  editable?: boolean,
  scrollToNotifications?: (id: string) => void
}

const ProductAndServicesOverview: FC<ProductAndServicesOverviewProps> = ({
  versions,
  transaction,
  setTransaction,
  editingMode,
  setEditingModes,
  editable = true,
  scrollToNotifications
}) => {
  const hasRegularization = transaction?.notifications?.some((notif) => notif.status === NOTIFICATION_STATUS_TO_ANSWER.value && notif.regularizationAsked)

  const [currentVersion, setCurrentVersion] = useState<ProductClassVersion | undefined>()
  const [withDrawVersion, setWithDrawVersion] = useState<ProductClassVersion | undefined>()
  const [viewMode, setViewMode] = useState<'accordion' | 'list'>('accordion')

  /** Partie Régularisation */
  const [showModal, setShowModal] = useState<boolean>(false)
  const [payment, setPayment] = useState<Payment>()
  const [regularizationVersion, setRegularizationVersion] = useState<ProductClassVersion>()

  useEffect(() => {
    /** On récupère la dernière version issue d'un retrait */
    setWithDrawVersion(versions.reverse().find((version: ProductClassVersion) => version.status === PRODUCT_CLASS_VERSION_STATUS.PENDING &&
      (version.type === PRODUCT_AND_SERVICE_VERSION_TYPE.PARTIAL_WITHDRAW_VERSION || version.type === PRODUCT_AND_SERVICE_VERSION_TYPE.TOTAL_WITHDRAW_VERSION))
    )
    setCurrentVersion(((hasRegularization && ProductsService.getDraftedVersion(versions))) ? ProductsService.getDraftedVersion(versions) : transaction?.procedureType === PROCEDURE_RENEWAL.value && ProductsService.getVersionByStatus(versions, PRODUCT_CLASS_VERSION_STATUS.PENDING) ? ProductsService.getVersionByStatus(versions, PRODUCT_CLASS_VERSION_STATUS.PENDING) : ProductsService.getCurrentVersion(versions))
  }, [versions])

  /**
   * Utilisé lorsque l'on supprime des produits et services de la marque
   * @param newVersion
   */
  const onProductClassesEdit = async (newVersion: ProductClassVersion): Promise<void> => {
    const fromNotification = editingMode && editingMode !== true
    if (fromNotification) {
      newVersion.type = PRODUCT_AND_SERVICE_VERSION_TYPE.VERSION_REGULARIZATION
      newVersion.notificationId = editingMode
    }
    if (transaction && transaction.deposit) {
      const editedTransaction: Transaction | null = await DepositService.createProductsVersion(transaction.id, newVersion)
      setTransaction && editedTransaction && setTransaction(editedTransaction)
      editedTransaction && toast.success(fromNotification ? Message.product_and_services_success_edit_regularization : Message.product_and_services_success_edit)
      setEditingModes(false)
      if (fromNotification) {
        scrollToNotifications(newVersion.notificationId)
      }
    }
  }

  /**
   * Gestion de la régularisation
   * S'il un paiement doit être effectué, on ouvre la modale de paiement, sinon la modal habituelle
   * de validation de retrait
   *
   * @param newVersion
   * @returns
   */
  const handleRegularization = async (newVersion: ProductClassVersion): Promise<Payment|null> => {
    if (transaction) {
      const payment: Payment | null = await ProductService.getRegularizationPaiement(transaction, newVersion)
      if (payment) {
        newVersion.notificationId = editingMode
        newVersion.status = PRODUCT_CLASS_VERSION_STATUS.DRAFT_FO
        setRegularizationVersion(ProductsService.getFinalVersion(newVersion))
        setPayment(payment)
        setShowModal(true)
        return payment
      }
    }

    return null
  }

  const regularizeNotification = () => {
    setEditingModes && setEditingModes(transaction?.notifications?.find((notif) => notif.status === NOTIFICATION_STATUS_TO_ANSWER.value && notif.regularizationAsked).id)
  }

  const displayProducts = () => viewMode === 'accordion'
    ? <ProductAndServicesAccordion procedureType={transaction?.procedureType} productClasses={ProductsService.clearProductClassVersion(currentVersion!)?.productClasses} displayHelpBlock={transaction?.procedureType === PROCEDURE_DEPOSIT.value} />
    : <ProductAndServiceListView productClasses={ProductsService.clearProductClassVersion(currentVersion!)?.productClasses} readOnly={transaction?.procedureType === PROCEDURE_RENEWAL.value} />

  return currentVersion ? (
    <>
      <CardBlock
        header={
          <ProductAndServicesOverviewHeader
            editable={editable}
            transactionStatus={transaction?.status}
            hasRegularization={hasRegularization}
            editMode={editingMode}
            setEditMode={setEditingModes}
            regularizeNotification={regularizeNotification}
            procedureType={transaction?.procedureType}
            subProcedureType={transaction?.subProcedureType}
          />
        } shadow
      >
        {!editingMode &&
          <>
            {transaction?.procedureType === PROCEDURE_RENEWAL.value &&
              <div>
                <h4 className='font-weight-bold'><FormattedMessage id='renewal_type' /></h4>
                <p className='subtitle'><FormattedMessage id={transaction?.renewalDetails?.type?.toLocaleLowerCase() ?? 'none'} /></p>
              </div>}
            <div className='d-flex justify-content-end mb-4'>
              {
                viewMode === 'accordion' &&
                  <button className='btn btn-link text-primary' onClick={() => setViewMode('list')}><FormattedMessage id='products_and_services_by_class' /></button>
              }
              {
                viewMode === 'list' &&
                  <button className='btn btn-link text-primary' onClick={() => setViewMode('accordion')}><FormattedMessage id='products_and_services_by_list' /></button>
              }
            </div>
            {versions.some((version: ProductClassVersion) => version.status === PRODUCT_CLASS_VERSION_STATUS.PENDING && transaction?.procedureType !== PROCEDURE_RENEWAL.value) &&
              <div><HelpBlock className='mb-2'><FormattedMessage id='product_and_services_has_pending_versions' /></HelpBlock></div>}
            {withDrawVersion
              ? <WithdrawOverview currentVersion={currentVersion} withDrawVersion={withDrawVersion} />
              : displayProducts()}

          </>}
        {editingMode &&
          <ProductsAndServicesEditList
            procedureType={transaction?.procedureType}
            hasRegularization={editingMode !== true}
            onProductClassesEdit={onProductClassesEdit}
            version={currentVersion}
            handleRegularization={handleRegularization}
            setEditMode={setEditingModes}
          />}
      </CardBlock>
      {/* Modale de validation du retrait partiel / total */}
      {transaction && payment && regularizationVersion &&
        <ModalComponent
          size='xl'
          hideFooter
          title={<FormattedMessage id='product_and_services_regularization_title' />}
          show={showModal}
          handleClose={() => setShowModal(false)}
          customContent={() => <RegularizationPaymentForm transaction={transaction} payment={payment} version={regularizationVersion} />}
        />}
    </>
  ) : <></>
}

export default ProductAndServicesOverview
