import React, { FC } from 'react'
import { FRMI, Table } from '@inpi-marques/components'

import Message from '../../../../constants/Message'
import { FormattedMessage } from 'react-intl'

interface OverviewVariousIndicationsProps {
    frmi: FRMI,
    className?: string
}
const OverviewVariousIndications: FC<OverviewVariousIndicationsProps> = ({ frmi, className }) => {
  const getTableTitle = () => {
    return [
      { label: Message.various_indications_field_name, className: 'table-column-label' },
      { label: Message.various_indications_field_value, className: 'table-column-label' }
    ]
  }

  return (
    <>
      <Table
        tableTitle={getTableTitle()}
        tableClassName={`title-table ${className}`}
      >
        <tbody>
          <tr>
            <td><FormattedMessage id='various_indications_field_label_sound_type' /></td>
            <td><FormattedMessage id={frmi.brand?.isSoundType ? 'common_yes' : 'common_no'} /></td>
          </tr>
          <tr>
            <td><FormattedMessage id='various_indications_field_3_dimensional' /></td>
            <td><FormattedMessage id={frmi.brand?.is3DType ? 'common_yes' : 'common_no'} /></td>
          </tr>
          <tr>
            <td><FormattedMessage id='various_indications_field_collective_certification' /></td>
            <td><FormattedMessage id={frmi.brand?.isCollectiveCertificationOrGuarantee ? 'common_yes' : 'common_no'} /></td>
          </tr>
          {
            frmi.brand?.transliteration &&
              <tr>
                <td><FormattedMessage id='various_indications_field_label_transliteration' /></td>
                <td>{frmi.brand?.transliteration}</td>
              </tr>
          }
          {
              frmi.brand?.verbalElements &&
                <tr>
                  <td><FormattedMessage id='various_indications_field_verbal_elements' /></td>
                  <td>{frmi.brand?.verbalElements}</td>
                </tr>
          }
          {
              frmi.brand?.unclaimedElements &&
                <tr>
                  <td><FormattedMessage id='various_indications_field_unclaimed_elements' /></td>
                  <td>{frmi.brand?.unclaimedElements}</td>
                </tr>
          }
          {
              frmi.brand?.voluntaryDescription &&
                <tr>
                  <td><FormattedMessage id='various_indications_field_voluntary_description' /></td>
                  <td>{frmi.brand?.voluntaryDescription}</td>
                </tr>
          }
          {
              frmi.brand?.translation &&
                <tr>
                  <td><FormattedMessage id='various_indications_field_translation' /></td>
                  <td>{frmi.brand?.translation}</td>
                </tr>
          }
          {
              frmi.brand?.englishTranslation &&
                <tr>
                  <td><FormattedMessage id='various_indications_field_english_translation' /></td>
                  <td>{frmi.brand?.englishTranslation}</td>
                </tr>
          }
          {
              frmi.brand?.spanishTranslation &&
                <tr>
                  <td><FormattedMessage id='various_indications_field_spanish_translation' /></td>
                  <td>{frmi.brand?.spanishTranslation}</td>
                </tr>
          }

        </tbody>
      </Table>
    </>
  )
}

export default OverviewVariousIndications
