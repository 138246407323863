import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import {
  EventType,
  LINKED_TRANSACTION_TYPE_RENEWAL,
  LinkedTransaction,
  PROCESS_FAST_TRACK_RENEWAL,
  SubmitButton,
  TextField,
  Transaction,
  TransactionService as CommonTransactionService
} from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Message from '../constants/Message'

interface PreviousTransactionsProps {
    linkedTransactions: LinkedTransaction[],
    onLinkedTransactionChanged: (linkedTransaction: LinkedTransaction[]) => void,
    linkedTransactionType: string,
    transaction: Transaction,
    hideTitle?: boolean,
    isEditable?: boolean,
    isBO?: boolean
}

const PreviousTransactions: FC<PreviousTransactionsProps> = (
  {
    linkedTransactions,
    transaction,
    hideTitle,
    isEditable = false,
    linkedTransactionType,
    onLinkedTransactionChanged,
    isBO = false
  }) => {
  const updatedOneInscriptionLink = (numNat: string, index: number) => {
    const updatedLinkedTransaction: LinkedTransaction[] = [...linkedTransactions]
    if (linkedTransactions.length > index) {
      updatedLinkedTransaction[index] = { numNat, type: linkedTransactionType }
    }

    onLinkedTransactionChanged(updatedLinkedTransaction)
  }

  const deleteOneInscriptionLink = (index: number) => {
    const updatedLinkedTransaction = linkedTransactions
    if (linkedTransactions.length > index) {
      updatedLinkedTransaction.splice(index, 1)
    }

    onLinkedTransactionChanged(updatedLinkedTransaction)
  }

  const getPreviousTransactionsLabel = () => {
    if (CommonTransactionService.isDivision(transaction) ||
          CommonTransactionService.isRenunciation(transaction)) {
      return 'options_inscriptions_label'
    } else if (CommonTransactionService.isRenewal(transaction) && linkedTransactionType === LINKED_TRANSACTION_TYPE_RENEWAL.value) {
      return 'options_renewal_label'
    }
    return 'contest_inscriptions_label'
  }

  const getPreviousTransactionsButtonLabel = () => {
    if (CommonTransactionService.isDivision(transaction) ||
            CommonTransactionService.isRenunciation(transaction)) {
      return 'options_inscriptions_add_inscriptionLink'
    } else if (CommonTransactionService.isRenewal(transaction) && linkedTransactionType === LINKED_TRANSACTION_TYPE_RENEWAL.value) {
      return 'options_inscriptions_add_renewalLink'
    }
    return 'contest_inscriptions_add_inscriptionLink'
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className={hideTitle ? '' : 'mt-3'}>
        {!hideTitle &&
          <h1 className='pb-4'>
            <FormattedMessage
              id={getPreviousTransactionsLabel()}
            />
          </h1>}
        <span className='d-block pb-3'>
          <FormattedMessage
            id={getPreviousTransactionsLabel()}
          />
        </span>
        {linkedTransactions?.map((linkedTransaction: LinkedTransaction, index: number) => (
          <div key={`inscriptionLinkNumNat-${index}`} className='row m-0'>
            {isEditable
              ? (
                <TextField
                  className=''
                  inputId={`inscriptionLinkNumNat-${index}`}
                  value={linkedTransaction.numNat}
                  onChange={(e: EventType) => updatedOneInscriptionLink(e.target.value, index)}
                  disabled={transaction.process === PROCESS_FAST_TRACK_RENEWAL && !isBO}
                />
              )
              : (
                <span>{linkedTransaction.numNat}</span>
              )}
            {isEditable && (transaction.process !== PROCESS_FAST_TRACK_RENEWAL || isBO) &&
              <div className='d-flex flex-column justify-content-center mb-3 ml-3'>
                <FontAwesomeIcon
                  icon={faTrash}
                  className='text-secondary cursor-pointer'
                  onClick={() => deleteOneInscriptionLink(index)}
                />
              </div>}
          </div>
        ))}
        {isEditable && (transaction.process !== PROCESS_FAST_TRACK_RENEWAL || isBO) &&
          <div>
            <SubmitButton
              className='btn-link-primary'
              onClick={() => onLinkedTransactionChanged([...linkedTransactions,
                {
                  numNat: '',
                  type: linkedTransactionType
                }]
              )}
            >
              <FormattedMessage
                id={getPreviousTransactionsButtonLabel()}
              />
            </SubmitButton>
          </div>}
      </div>
    </IntlProvider>
  )
}

export default PreviousTransactions
