import React, { FC, useEffect, useState } from 'react'
import { containsErrors, FieldStatus, Seniority, Transaction } from '@inpi-marques/components'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { FormattedMessage } from 'react-intl'
import SeniorityListForm from './SeniorityListForm'
import SeniorityForm from './SeniorityForm'
import SeniorityValidator from './validator/SeniorityValidator'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from '../../../../store/transaction/transactionActions'

interface SenioritiesClaimedProps{
    transaction: Transaction
}

const SenioritiesClaimed: FC<SenioritiesClaimedProps> = ({ transaction }) => {
  const dispatch = useDispatch()
  const [seniorityList, setSeniorityList] = useState<Seniority[]>(transaction.frmiDetails?.seniorities || [])
  const [isEditingMode, setEditingMode] = useState<boolean>(false)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus[]>([])
  const [seniority, setSeniority] = useState<Seniority>()
  const [editIndex, setEditIndex] = useState<number>(-1)

  /**
   * Sauvegarde dans la transaction de la nouvelle valeur des revendications d'ancienneté
   */
  useEffect(() => {
    dispatch(storeTransactionUpdateFRMI({ ...transaction.frmiDetails, seniorities: seniorityList }))
  }, [seniorityList])

  /**
     * Ajout d'une ancienneté
     */
  const addSeniority = () => {
    const index = seniorityList.length
    setEditIndex(index)
    setEditingMode(false)
  }

  /**
     * Valide l'ancienneté
     */
  const validate = () => {
    const fieldStatusSeniority = SeniorityValidator.validateSeniority(seniority)
    const newFieldStatus = [...fieldStatus]
    newFieldStatus[editIndex] = fieldStatusSeniority
    setFieldStatus(newFieldStatus)
    if (!containsErrors(fieldStatusSeniority)) {
      const newList = [...seniorityList]
      newList[editIndex] = { ...seniority }
      setSeniorityList(newList)
      setSeniority({})
      setEditIndex(-1)
    }
  }

  /**
     * Annule un ajout ou la modification d'une ancienneté
     */
  const cancel = () => {
    setFieldStatus(fieldStatus.filter((status, index) => index !== editIndex))
    setEditIndex(-1)
    setSeniority({})
  }

  /**
     * Supprime une ancienneté de la liste
     * @param index
     */
  const deleteSeniority = (index: number) => {
    const newSeniorities = [...seniorityList]
    newSeniorities.splice(index, 1)
    setSeniorityList(newSeniorities)
    const updatedFieldStatus = [...fieldStatus]
    updatedFieldStatus.splice(index, 1)
    setFieldStatus(updatedFieldStatus)
  }

  /**
     * Affiche la liste des anciennetés
     */
  const renderListSeniorityOverview = () => {
    return (
      <SeniorityListForm
        seniorityList={seniorityList}
        editSeniority={(index: number) => {
          setEditIndex(index)
          setEditingMode(true)
          setSeniority(seniorityList[index])
        }}
        deleteSeniority={deleteSeniority}
        addSeniority={addSeniority}
      />
    )
  }

  /**
     * Affiche le formulaire d'une ancienneté
     */
  const renderFormOneSeniority = () => {
    return (
      <SeniorityForm
        transaction={transaction}
        seniority={seniority}
        setSeniority={setSeniority}
        editIndex={editIndex}
        isEditingMode={isEditingMode}
        fieldStatus={fieldStatus[editIndex]}
        validate={validate}
        cancel={cancel}
      />
    )
  }

  return (
    <div className='senioritiesClaimed'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_claimed_seniorities_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_claimed_seniorities_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row'>
        <div className='col-9' />
      </div>
      {editIndex === -1 ? renderListSeniorityOverview() : renderFormOneSeniority()}
    </div>
  )
}

export default SenioritiesClaimed
