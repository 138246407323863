import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  CardBlock,
  EventType,
  NoResult,
  ProductAndService,
  ProductClass,
  ProductService,
  SwitchField,
  TextField
} from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { IAllProducts, ICurrentSearchValues } from './ProductsAndServices'
import ProductsService from 'services/deposit/ProductsService'
import debounce from 'lodash.debounce'

interface ProductAndServicesListProps {
  productClassesSelected: ProductClass[],
  setProductClassesSelected: (newArray: ProductClass[]) => void
  type: 'nice' | 'tmclass'
  allProducts: IAllProducts
  setAllProducts: (allProducts: IAllProducts) => void
  currentSearchValues: ICurrentSearchValues
  setCurrentSearchValues: (currentSearchValues: ICurrentSearchValues) => void
}

/**
 * Ce composent gère l'affichage des deux liste de produits:
 *  - Nice
 *  - TMClass
 */
const ProductAndServicesList: FC<ProductAndServicesListProps> = ({
  productClassesSelected,
  setProductClassesSelected,
  type,
  setAllProducts,
  allProducts,
  currentSearchValues,
  setCurrentSearchValues
}) => {
  const intl = useIntl()

  /** Valeur de l'input de recherche */
  const [searchValue, setSearchValue] = useState<string>(currentSearchValues[type])

  /**
   * Lorsque l'on change d'onglet, on recupère la liste de produits correspondants
   */
  useEffect(() => {
    setSearchValue(currentSearchValues[type])
  }, [type])

  /**
   * Mise à jour de la liste de produits et service affichée
   */
  const updateSearch = async (): Promise<void> => {
    if ((type === 'tmclass' && searchValue.length > 2) || type === 'nice') {
      const products: ProductClass[] = await ProductsService.searchProducts(type, searchValue)

      const newAllProduct: IAllProducts = { ...allProducts }
      newAllProduct[type] = products

      setAllProducts(newAllProduct)

      const newCurrentSearchValues: ICurrentSearchValues = { ...currentSearchValues }
      newCurrentSearchValues[type] = searchValue
      setCurrentSearchValues(newCurrentSearchValues)
    } else {
      // Si moins de 3 caractères, on réinitialise les résultats tmclass à vide
      const newAllProduct: IAllProducts = { ...allProducts }
      newAllProduct.tmclass = []
      setAllProducts(newAllProduct)
    }
  }

  const delayedSearch = useCallback(debounce(updateSearch, 500), [searchValue])

  useEffect(() => {
    delayedSearch()
    return delayedSearch.cancel
  }, [delayedSearch])

  /**
   * La selection d'un produit l'ajoute ou le supprime dans les éléments sélectionnés du dépôt
   * @param nameClass
   * @param product
   */
  const selectOneProduct = (nameClass: string, product: ProductAndService) => {
    const updatedSelectList: ProductClass[] = ProductService.getUpdatedSelectedList(productClassesSelected, nameClass, product, true)
    setProductClassesSelected(updatedSelectList)
  }

  const handleProductClassSwitchOnChange = (products : ProductAndService[], nameClass: string) => {
    const updatedSelectList: ProductClass[] = ProductsService.getUpdatedSelectedClassList(productClassesSelected, nameClass, products, type)
    setProductClassesSelected(updatedSelectList)
  }

  const isClassActivated = (productClass: ProductClass) => {
    return productClass.products.length === productClassesSelected.find((oneProduct) =>
      oneProduct.ref === productClass.ref)?.products.filter(products => products.origin === type).length
  }

  return (
    <div className='mt-5'>
      <div className='d-flex justify-content-center'>
        <TextField
          inputId='searchValue'
          value={searchValue}
          placeholder={intl.formatMessage({ id: type === 'nice' ? 'products_and_services_list_filter_placeholder' : 'products_and_services_tmclass_search_placeholder' })}
          onChange={(event: EventType) => setSearchValue(event.target.value)}
        />
      </div>
      <div>
        {allProducts[type].length > 0 ? allProducts[type].map((productClass: ProductClass) =>
          (
            <div key={`productClass-${productClass.ref}`} className='mt-5'>
              {type === 'nice'
                ? (
                  <SwitchField
                    inputId={`productClass-switch-${productClass.ref}`}
                    divClassName='col-md-12 align-items-center p-0 mt-3 mb-3'
                    className='col-1'
                    onChange={() => handleProductClassSwitchOnChange(productClass.products, productClass.ref)}
                    value={isClassActivated(productClass)}
                    label={
                      <h2 className='text-primary product-class__title cursor-pointer mb-0'>
                        <FormattedMessage id='products_and_services_class' /> {productClass.ref}
                      </h2>
                    }
                    width={45}
                    height={25}
                  />)
                : (
                  <h2 className='text-primary product-class__title mb-3'>
                    <FormattedMessage id='products_and_services_class' /> {productClass.ref}
                  </h2>)}
              <div className='row product-class__item'>
                {
                  productClass.products?.map((productAndService: ProductAndService) => (
                    <div key={`productAndService-${productAndService.ref}`} className='col-12 col-md-4 mt-1 p-1'>
                      <CardBlock
                        onClick={() => selectOneProduct(productClass.ref, productAndService)}
                        className={`theme-primary shadow-sm${ProductService.isExists(productClassesSelected, productClass.ref, productAndService) ? ' active' : ''}`}
                        bodyClassName='px-3 py-1 justify-content-center'
                      >
                        {productAndService.name}
                      </CardBlock>
                    </div>
                  ))
                }
              </div>
            </div>
          )) : <NoResult />}
      </div>
    </div>
  )
}

export default ProductAndServicesList
