class TableService {
  /**
     * Retourne un tableau paginé de titre
     * @param titles
     * @param nbItemsPerPage
     * @returns
     */
     getPagination = (items: any[], nbItemsPerPage: number): any[][] => {
       const paginatedItems: any[][] = []

       let page: any[] = []
       items.forEach((title: any, key: number) => {
         page.push(title)
         if ((page.length % nbItemsPerPage === 0) || (key + 1) === items.length) {
           paginatedItems.push(page)
           page = []
         }
       })
       return paginatedItems
     }
}

export default new TableService()
