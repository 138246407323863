import {
  Transaction,
  ErrorField,
  EventType,
  TransactionDocument,
  Deposit,
  FieldStatus,
  EditorialBlock,
  HelpBlock,
  SUBPROCEDURE_DOCUMENT_TYPES, BRAND_TYPE_WORD, BRAND_TYPE_FIGURATIVE
} from '@inpi-marques/components'
import { DepositType } from 'interfaces/Deposit'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import { DEPOSIT_TYPE_NATIONAL, DEPOSIT_TYPES } from 'constants/DepositConstant'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeTransactionInitDeposit } from 'store/transaction/transactionActions'
import SubProcedureTypeItem from './SubProcedureTypeItem'
import ContentService from '../../../../services/content/ContentService'
import DepositFromXML from './DepositFromXML'

interface DepositTypesProps {
  onDocumentChanged: (event: EventType, type: string) => void,
  onDocumentDeleted: (event: EventType)=> void,
  transaction: Transaction,
  fieldStatus: FieldStatus,
  documents: TransactionDocument[],
  deposit: Deposit,
  setDocuments: (doc: TransactionDocument[]) => void
}
const DepositTypes: FC<DepositTypesProps> = ({ onDocumentDeleted, onDocumentChanged, transaction, fieldStatus, documents, deposit, setDocuments }) => {
  const dispatch = useDispatch()
  const [selectDeposits, setSelectDeposits] = useState<DepositType[]>([])
  const [editorialContent, setEditorialContent] = useState<string>(null)
  const guidedMode = useSelector((state : RootStateOrAny) => state?.transaction?.guidedMode?.activeGuidedMode)
  const [moreButtonClicked, setMoreButtonClicked] = useState<boolean>(![BRAND_TYPE_WORD.value, BRAND_TYPE_FIGURATIVE.value].includes(transaction.deposit?.brand?.type))

  /**
   * Au clic sur un type de dépôt, on l'initialise dans le store
   * @param type
   */
  const onDepositTypeClick = (type: string) => {
    if (transaction.subProcedureType !== type) {
      dispatch(storeTransactionInitDeposit(type))
      // Suppression des documents liés aux sous-procédures
      setDocuments(documents.filter((doc) => !SUBPROCEDURE_DOCUMENT_TYPES.includes(doc.type)))
    }
  }

  /**
   * Ajoute un filtre en fonction de la valeur du bouton mode guidé de la page dépôt et le type de demande sélectionné
   */
  useEffect(() => {
    if (guidedMode && (!transaction.subProcedureType || transaction.subProcedureType === DEPOSIT_TYPE_NATIONAL.value)) {
      getGuidedModeDepositTypes()
      // Texte d'information sur le mode guidé
      ContentService.getEditorialBlockByCode('MODE_GUIDE_INTRO').then((res: EditorialBlock) => {
        if (res.content) {
          setEditorialContent(res.content)
        }
      })
    } else {
      if (!guidedMode) {
        setEditorialContent(null)
        setMoreButtonClicked(false)
      }
      setSelectDeposits(DEPOSIT_TYPES)
    }
  }, [guidedMode, transaction.subProcedureType])

  /**
   * Récupère les types de dépôt possibles en mode guidé
   */
  const getGuidedModeDepositTypes = () => {
    setSelectDeposits(DEPOSIT_TYPES.filter((deposit) => deposit.valueGuidedMode === guidedMode))
  }

  return (
    <div className='deposit-type'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='deposit_type_title' /></h1>
          <span className='subtitle'><FormattedMessage id='deposit_type_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      {!guidedMode &&
        <DepositFromXML
          transaction={transaction}
        />}
      <div className='d-flex flex-column align-items-start col-10'>
        {editorialContent &&
          <HelpBlock><div dangerouslySetInnerHTML={{ __html: editorialContent }} /></HelpBlock>}
        <u className='mt-3 text-danger font-weight-bold'><FormattedMessage id={guidedMode ? 'guided_mode_active' : 'guided_mode_not_active'} /></u>

        <div className='mt-3 d-flex flex-column align-items-center w-100'>
          {
            selectDeposits
              .map((depositConstant, index) => (
                <SubProcedureTypeItem
                  value={depositConstant.value}
                  key={index}
                  label={depositConstant.label}
                  onClick={(value) => onDepositTypeClick(value)}
                  className='md-6 mb-3 p-0 w-100'
                  active={depositConstant.value === transaction.subProcedureType}
                  deposit={deposit}
                  contentComponent={depositConstant.component}
                  fieldStatus={fieldStatus}
                  onDocumentChanged={onDocumentChanged}
                  onDocumentDeleted={onDocumentDeleted}
                  documents={documents}
                  transaction={transaction}
                />
              ))
          }
          {guidedMode && selectDeposits.length !== DEPOSIT_TYPES.length &&
            <div className='d-flex  flex-column align-items-end w-100'>
              <button className='btn btn-link text-primary mt-3' onClick={() => setMoreButtonClicked(true)}>
                <span className='text-button'>
                  <FormattedMessage id='button_more' />
                </span>
              </button>

              {moreButtonClicked && (
                <HelpBlock className='mt-3 align-self-start w-100'><FormattedMessage id='disable_guided_mode_to_see_brand_types' /></HelpBlock>
              )}
            </div>}
        </div>

        {fieldStatus.type && (
          <div className='col-12'>
            <ErrorField message={fieldStatus.type} className='fade alert alert-danger show position-relative mt-4' />
          </div>
        )}
      </div>
    </div>
  )
}

export default DepositTypes
