import React, { FC, useState } from 'react'
import { Comment, DateUtils, EventType, FO_USER_COMMENT_TYPE, ROWS_10, TextArea, Transaction } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import Message from '../../../constants/Message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdate } from '../../../store/transaction/transactionActions'
import InternalReferenceField from '../../internalReference/InternalReferenceField'

interface CommentsFRMIFormProps {
    transaction: Transaction
}

/**
 * Etape des commentaires d'une FRMI
 */
const CommentsFRMIForm: FC<CommentsFRMIFormProps> = ({ transaction }) => {
  const dispatch = useDispatch()

  /**
   * Récupération du commentaire du l'utilisateur FO
   */
  const existingComment: Comment|undefined = transaction.comments?.find((comment: Comment) => comment.commentType === FO_USER_COMMENT_TYPE)

  /** Contenue du commentaire */
  const [content, setContent] = useState<string>(existingComment?.content ?? '')

  /**
   * Au blur sur le champ du contenu du commentaire
   */
  const onCommentBlur = (): void => {
    let updatedComments: Comment[] = transaction.comments ?? []

    // Si on édite un commentaire existant
    if (existingComment && content) {
      updatedComments = updatedComments.map((item: Comment) => item.commentType === FO_USER_COMMENT_TYPE ? { ...item, content } : item)
    } else if (!existingComment) {
      // Si on ajoute le commentaire
      updatedComments = [...updatedComments, { date: DateUtils.now(), commentType: FO_USER_COMMENT_TYPE, content }]
    } else {
      // Si le commentaire existe et est vide, on le supprime
      updatedComments = updatedComments.filter((item: Comment) => item.commentType !== FO_USER_COMMENT_TYPE)
    }

    dispatch(storeTransactionUpdate({ ...transaction, comments: updatedComments }))
  }

  return (
    <div className='comments'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_comments_title' /></h1>
          <span className='subtitle'>
            <FontAwesomeIcon icon={faComment} /> - <FormattedMessage id='frmi_comments_description' />
          </span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row'>
        <div className='col-9' />
      </div>
      <TextArea
        label={<>{content.length} / 550</>}
        labelClassName='w-100 d-flex justify-content-end'
        inputId='commentsFrmi'
        placeholder={Message.frmi_comments_placeholder}
        rows={ROWS_10.value}
        maxLength={550}
        value={content}
        onChange={(event: EventType) => setContent(event.target.value)}
        onBlur={() => onCommentBlur()}
      />
    </div>
  )
}

export default CommentsFRMIForm
