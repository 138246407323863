import Message from './Message'

export interface IProcedure {
  value: string
  label: string
}

export const PROCEDURE_ALL: IProcedure = {
  value: 'ALL',
  label: Message.procedure_type_all
}

export const PROCEDURE_DEPOSIT: IProcedure = {
  value: 'DEPOSIT',
  label: Message.procedure_type_deposit
}

export const PROCEDURE_OPPOSITION: IProcedure = {
  value: 'OPPOSITION',
  label: Message.procedure_type_opposition
}

export const PROCEDURE_NULLITY: IProcedure = {
  value: 'NULLITY',
  label: Message.procedure_type_nullity
}
export const PROCEDURE_REVOCATION: IProcedure = {
  value: 'REVOCATION',
  label: Message.procedure_type_revocation
}

export const PROCEDURE_MIFR: IProcedure = {
  value: 'MIFR',
  label: Message.procedure_type_mifr
}

export const PROCEDURE_NULLITY_REVOCATION: IProcedure = {
  value: 'NULLITY_REVOCATION',
  label: Message.procedure_type_nullity_revocation
}

export const PROCEDURE_DIVISION: IProcedure = {
  value: 'DIVISION',
  label: Message.procedure_type_division
}

export const PROCEDURE_INSCRIPTION: IProcedure = {
  value: 'INSCRIPTION',
  label: Message.procedure_type_inscription
}

export const PROCEDURE_RENEWAL: IProcedure = {
  value: 'RENEWAL',
  label: Message.procedure_type_renewal
}

export const PROCEDURE_FRMI: IProcedure = {
  value: 'FRMI',
  label: Message.procedure_type_frmi
}

export const PROCEDURE_OFFICIAL_DOCUMENT: IProcedure = {
  value: 'OFFICIAL_DOCUMENT',
  label: Message.procedure_type_official_document
}

export const PROCEDURE_REVOCATION_STATEMENT: IProcedure = {
  value: 'REVOCATION_STATEMENT',
  label: Message.procedure_type_revocation_statement
}

/**
 * La renonciation est un sous type d'inscription
 * On s'en sert ici pour le contenu administrable
 */
export const PROCEDURE_RENUNCIATION: IProcedure = {
  value: 'RENUNCIATION',
  label: Message.procedure_type_renunciation
}

export const PROCEDURES: IProcedure[] = [
  PROCEDURE_DEPOSIT,
  PROCEDURE_OPPOSITION,
  PROCEDURE_NULLITY,
  PROCEDURE_REVOCATION,
  PROCEDURE_MIFR,
  PROCEDURE_DIVISION,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_FRMI,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION_STATEMENT
]

export const PROCEDURES_LOT_2: IProcedure[] = [
  PROCEDURE_DEPOSIT,
  PROCEDURE_OPPOSITION,
  PROCEDURE_NULLITY,
  PROCEDURE_REVOCATION,
  PROCEDURE_MIFR,
  PROCEDURE_DIVISION,
  PROCEDURE_INSCRIPTION
]

export const PROCEDURES_ETS_HOLDER_LOT_2: IProcedure[] = [
  PROCEDURE_DEPOSIT,
  PROCEDURE_MIFR,
  PROCEDURE_DIVISION
]

export const PROCEDURES_ETS_HOLDER: IProcedure[] = [
  PROCEDURE_DEPOSIT,
  PROCEDURE_MIFR,
  PROCEDURE_DIVISION,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_FRMI,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION_STATEMENT
]

export const ALL_PROCEDURES: IProcedure[] = [PROCEDURE_ALL, ...PROCEDURES]
export const ALL_PROCEDURES_HEADER: IProcedure[] = [
  PROCEDURE_ALL,
  PROCEDURE_DEPOSIT,
  PROCEDURE_OPPOSITION,
  PROCEDURE_NULLITY_REVOCATION,
  PROCEDURE_MIFR,
  PROCEDURE_DIVISION,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_FRMI,
  PROCEDURE_OFFICIAL_DOCUMENT,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION_STATEMENT
]
export const FOUNDATION_PROCEDURES_HEADER: IProcedure[] = [
  PROCEDURE_ALL,
  PROCEDURE_OPPOSITION,
  PROCEDURE_NULLITY
]

/**
 * Les procédures utilisées pour le contenu administrable
 * La liste contient toutes les procédures + la sous-procédure Renonciation
 */
export const ALL_ADMINISTRATION_PROCEDURES: IProcedure[] = [
  ...ALL_PROCEDURES, PROCEDURE_RENUNCIATION
]

export const ADMINISTRATION_PROCEDURES: IProcedure[] = [
  ...PROCEDURES, PROCEDURE_RENUNCIATION
]
