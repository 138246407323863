import { StepListItemContent } from './StepListItem'
import { StepView } from './StepContainer'

class StepContainerUtils {
  /**
   * Récupération de l'index étape par son id
   * @param stepList liste des étapes
   * @param id id de l'étape
     */
  findStepIndexById = (
    stepList: StepListItemContent[],
    id: string
  ) => stepList.findIndex(item => item.id === id);

  /**
   * Retourne l'étape à afficher, permet de sauter les étapes sans composant dans des étapes à plusieurs niveaux
   * @param stepList liste des étapes
   * @param from index de l'étape souhaitée
   * @param increment -1 pour chercher l'étape en retour arrière, 1 sinon
   * @returns {number} l'index de l'étape à afficher
     */
  findDisplayableStepIndex = (
    stepList: StepView[],
    from = 0,
    increment = 1 // -1 pour afficher les étapes précédentes (retour arrière)
  ): number => {
    if (from < 0) {
      return this.findDisplayableStepIndex(stepList, 0)
    } else if (from >= stepList.length) {
      return this.findDisplayableStepIndex(stepList, stepList.length - 1, -1)
    }

    let index = from

    // Recherche de la prochaine étape qui contient un composant (utile quand plusieurs niveaux dans une étape du formulaire)
    // et qui n'a pas de condition définie ou alors la condition est remplie
    while (index >= 0 && index <= stepList.length) {
      if (!stepList[index].component || stepList[index].condition === false) {
        index = index + increment
      } else {
        return index
      }
    }

    return from
  }

  findNextDisplayableStep = (
    stepList: StepView[],
    from = 0
  ) => {
    return this.findDisplayableStepIndex(stepList, from + 1)
  }

  findPreviousDisplayableStep = (
    stepList: StepView[],
    from = 0
  ) => {
    return this.findDisplayableStepIndex(stepList, from - 1, -1)
  }
}

export default new StepContainerUtils()
