import React, { Dispatch, FC, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { Contributor, ContributorsOptionsForm } from '../../interfaces/contributors/ContributorsInterfaces'
import { EventType, FieldStatus, SelectOption } from '../../form/FormInterfaces'
import SelectField from '../../form/fields/SelectField'
import {
  CheckboxField,
  CONTRIBUTOR_RECIPIENT,
  CONTRIBUTOR_RECIPIENTS_TMC,
  Country,
  DEPOSIT_TYPE_COLLECTIVE,
  DOCUMENT_FORMATS,
  DownloadLink,
  FileBrowserField,
  ManageableQuality,
  PERSONNE_MORALE,
  PERSONNE_TYPES,
  PersonneFieldValidator,
  PROCEDURE_INSCRIPTION,
  SirenDenomination,
  TextField,
  Transaction,
  TransactionDocument,
  PROCEDURE_OPPOSITION,
  PROCEDURE_NULLITY,
  PROCEDURE_REVOCATION,
  PROCEDURE_FRMI,
  CONTRIBUTOR_AGENTS_TMC,
  isDepositDepositor
} from '../../index'
import PhysicalPersonFields from './PhysicalPersonFields'
import PersonContactFields from './PersonContactFields'
import PersonFormationFields from './PersonFormationFields'
import LegalCompanyFields from './LegalCompanyFields'
import ManageableQualitySelectField from './ManageableQualitySelectField'
import Message from '../../constants/Message'
import { CONTRIBUTOR_ADDRESS_BOOK } from '../../constants/contributors/ContributorsConstants'

interface PersonFieldsProps extends WrappedComponentProps {
  transaction?: Transaction
  contributor?: Contributor,
  onChange:(event: EventType) => void,
  fieldStatus?:FieldStatus | any,
  options: ContributorsOptionsForm,
  fillContributorInfo?: (siren: string) => void,
  findListSirenByNamePromise?: (companyName: string) => Promise<SirenDenomination[]|null>,
  changePPOrPMAndCleanContributorInfo : (event : EventType) => void,
  manageableQualities?: ManageableQuality[],
  addPowerDocument?: (event: EventType) => void,
  deletePowerDocument?: (contributor?: Contributor) => void,
  dispatch?: Dispatch<any>,
  resetError?: (fieldStatus: FieldStatus) => void,
  contributorType?: string,
  handleDownload?: (document: TransactionDocument) => void,
  isBO?: boolean,
  onDownloadClick?: () => Promise<ArrayBuffer|string|Blob|null>,
  getCountries?: (nationalityOnly: boolean) => Promise<void|Country[]>
}

const PersonFields:FC<PersonFieldsProps> = ({
  transaction,
  contributor,
  onChange,
  fieldStatus,
  intl,
  options,
  fillContributorInfo,
  findListSirenByNamePromise,
  changePPOrPMAndCleanContributorInfo,
  manageableQualities,
  addPowerDocument,
  deletePowerDocument,
  resetError,
  dispatch,
  contributorType,
  handleDownload,
  isBO = false,
  onDownloadClick,
  getCountries
}) => {
  const [nationalities, setNationalities] = useState<SelectOption[]>([])

  useEffect(() => {
    getCountries && getCountries(true).then((countries: void|Country[]) => {
      if (!countries || countries.length === 0) {
        return
      }

      const retrievedNationalities: SelectOption[] = countries.filter((country: Country) => country.isPostal).map((country: Country) => {
        return {
          label: country.name ?? '',
          value: country.code ?? ''
        }
      })
      setNationalities(retrievedNationalities)
    })
  }, [])

  return (
    <>
      {
        !isBO &&
          <div className='header-field'>
            <FormattedMessage id='field_personne_type_label' />
          </div>
      }
      <div className='row'>
        {
              options?.couldBePPOrPM &&
                <div className='col-12 col-md-4'>
                  <SelectField
                    inputId='type'
                    placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                    value={contributor?.type || ''}
                    options={PERSONNE_TYPES}
                    onChange={changePPOrPMAndCleanContributorInfo}
                    fieldStatus={fieldStatus}
                    disabled={transaction?.subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value || options?.allFieldReadOnly}
                    required
                  />
                </div>
        }
        {
              options?.hasFormationField &&
                <div className='col-12 col-md-8 d-flex align-items-center'>
                  <CheckboxField
                    inputId='personFormation'
                    label={intl.formatMessage({ id: 'contributor_personne_physique_formation' })}
                    checked={contributor?.personFormation}
                    onChange={onChange}
                    fieldStatus={fieldStatus}
                    dispatch={dispatch}
                    resetError={resetError}
                    disabled={options?.allFieldReadOnly}
                  />
                </div>
        }
      </div>
      {
            options?.hasPublicLegalCompanyField && PersonneFieldValidator.isPersonneMorale(contributor) &&
              <div className='row'>
                <div className='col-12'>
                  <CheckboxField
                    inputId='publicLaw'
                    label={intl.formatMessage({ id: 'contributor_personne_morale_publique' })}
                    checked={contributor?.publicLaw}
                    onChange={onChange}
                    fieldStatus={fieldStatus}
                    dispatch={dispatch}
                    resetError={resetError}
                    disabled={options?.allFieldReadOnly}
                  />
                </div>
              </div>
      }
      {
        (options?.hasPhysiqueField || PersonneFieldValidator.isPersonnePhysique(contributor)) && (
          <PhysicalPersonFields
            contributor={contributor}
            onChange={onChange}
            fieldStatus={fieldStatus}
            dispatch={dispatch}
            resetError={resetError}
            readOnly={options?.allFieldReadOnly}
            // Non requis pour des (agents OU un contact du carnet d'adresse) ET PM
            allRequired={!([CONTRIBUTOR_RECIPIENT.value, CONTRIBUTOR_RECIPIENTS_TMC.value, CONTRIBUTOR_ADDRESS_BOOK.value].includes(contributorType) &&
              contributor?.type === PERSONNE_MORALE.value) && ![CONTRIBUTOR_AGENTS_TMC.value].includes(contributorType)}
            isBO={isBO}
          />
        )
      }
      {
        (PersonneFieldValidator.isPersonneMorale(contributor) ||
          (isBO && (contributor?.companyName || contributor?.siren || contributor?.legalForm))) &&
            <LegalCompanyFields
              contributor={contributor}
              fieldStatus={fieldStatus}
              onChange={onChange}
              options={options}
              fillContributorInfo={fillContributorInfo}
              findListSirenByNamePromise={findListSirenByNamePromise}
              dispatch={dispatch}
              resetError={resetError}
              contributorType={contributorType}
              isBO={isBO}
            />
      }
      {
            options?.hasFormationField && contributor?.personFormation &&
              <PersonFormationFields
                contributor={contributor}
                fieldStatus={fieldStatus}
                onChange={onChange}
                dispatch={dispatch}
                resetError={resetError}
                procedureType={transaction?.procedureType}
              />
      }
      {
            options?.hasContactField &&
              <PersonContactFields
                contributor={contributor}
                fieldStatus={fieldStatus}
                onChange={onChange}
                options={options}
                dispatch={dispatch}
                resetError={resetError}
                isBO={isBO}
              />
      }
      <div className='row'>
        {
              options?.hasCompanyField &&
                <div className='col-12 col-md-4'>
                  <TextField
                    type='companyName'
                    inputId='companyName'
                    label={<FormattedMessage id='field_company_label' />}
                    value={contributor?.companyName || ''}
                    onChange={onChange}
                    fieldStatus={fieldStatus}
                    maxLength={120}
                    dispatch={dispatch}
                    resetError={resetError}
                    readOnly={options?.allFieldReadOnly}
                  />
                </div>
        }
        {
              options?.hasManageableQualities && manageableQualities &&
                <div className='col-12 col-md-4'>
                  <ManageableQualitySelectField
                    inputId='manageableQuality'
                    fieldStatus={fieldStatus}
                    value={contributor?.manageableQuality?.code}
                    onChange={onChange}
                    label={<FormattedMessage id='field_manageable_qualities_label' />}
                    placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                    required={options.isManageableQualitiesRequired}
                    manageableQualities={manageableQualities}
                    procedure={transaction?.procedureType}
                  />
                </div>
        }
        {
          ((contributor?.manageableQuality?.powerNeeded || contributor?.manageableQuality?.powerOptional) && contributorType !== 'recipient') && (contributorType !== 'signatory' || !isBO) &&
            <div className='col-12 col-md-4'>
              <FileBrowserField
                inputId='powerDocument'
                classNameButton='d-block one-line-ellipsis'
                label={<FormattedMessage id='field_power_documents_label' />}
                value={transaction?.documents?.filter((document: TransactionDocument) => (
                        contributor?.internalNameDocuments && contributor?.internalNameDocuments.length > 0 && document.internalName === contributor?.internalNameDocuments[0]
                ))}
                onChange={addPowerDocument}
                onDelete={() => deletePowerDocument(contributor)}
                fieldStatus={fieldStatus}
                maxNumberFile={1}
                acceptApplication={DOCUMENT_FORMATS.PDF}
                buttonLabel={
                  <div className='form-control placeholder'>
                    <FormattedMessage id='placeholder_select' />
                  </div>
                }
                dispatch={dispatch}
                resetError={resetError}
                required={contributor?.manageableQuality?.powerNeeded}
                informationDoc
                handleDownload={handleDownload}
              />
              {onDownloadClick && ![PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value].includes(transaction?.procedureType) &&
                <DownloadLink
                  label={<FormattedMessage id='field_power_help_link' />}
                  name={Message.field_power_example_file}
                  onClick={onDownloadClick}
                  classname='mt-3'
                />}

            </div>
        }
        {
          ((contributor?.manageableQuality?.numberNeeded || contributor?.manageableQuality?.numberOptional) && contributorType !== 'recipient') &&
            <div className='col-12 col-md-4'>
              <TextField
                inputId='inscriptionNumber'
                label={<FormattedMessage id='field_inscription_number' />}
                fieldStatus={fieldStatus}
                value={contributor?.inscriptionNumber}
                onChange={onChange}
                required={contributor?.manageableQuality?.numberNeeded}
                dispatch={dispatch}
                resetError={resetError}
              />
            </div>
        }
        {
          options?.canBeRecipient && !isBO &&
            <div className='col-12'>
              <CheckboxField
                inputId='isRecipient'
                label={intl.formatMessage({ id: `contributor_can_be_recipient${transaction?.procedureType === PROCEDURE_INSCRIPTION.value ? '_inscription' : ''}${transaction?.procedureType === PROCEDURE_FRMI.value ? '_agent_recipient' : ''}` })}
                checked={contributor?.isRecipient}
                onChange={onChange}
                fieldStatus={fieldStatus}
                dispatch={dispatch}
                resetError={resetError}
              />
            </div>
        }
        {options.hasNationality && (contributor?.type !== PERSONNE_MORALE.value || transaction?.procedureType !== PROCEDURE_FRMI.value) && (
          <div className='col-12 col-md-4'>
            <SelectField
              label={<FormattedMessage id='contributor_nationality' />}
              inputId='nationality'
              placeholder={intl.formatMessage({ id: 'placeholder_select' })}
              value={contributor?.nationality}
              options={nationalities}
              onChange={onChange}
              fieldStatus={fieldStatus}
              resetError={resetError}
              required={!(isBO && isDepositDepositor(transaction, contributorType))}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default injectIntl(PersonFields)
