import {
  BASIC_DATE,
  DateUtils,
  Fact,
  FRMI_TYPE_EXTENSION,
  FRMI_TYPE_HOLDER_CHANGE,
  FRMI_TYPE_LICENCE_INSCRIPTION,
  FRMI_TYPE_POST_DESIGNATION_REGISTER
} from '../../index'

class FRMIService {
    /**
     * Récupère le texte complet du résumé des faits
     * @returns
     * @param fact
     */
    getFactSummaryText = (fact?: Fact): string => {
      let factText = fact?.text ?? ''
      if (fact?.act) {
        factText += " d'un acte de " + fact?.act
      }
      if (fact?.restrictionDate) {
        factText += ' en date du ' + DateUtils.formateDateToFormat(fact?.restrictionDate, BASIC_DATE)
      }
      if (fact?.contributor) {
        factText += ' en faveur de ' + fact?.contributor
      }
      if (fact?.registrationDate) {
        factText += ' inscrit le ' + DateUtils.formateDateToFormat(fact?.registrationDate, BASIC_DATE)
      }
      if (factText !== '') {
        factText += '.'
      }

      return factText
    }

    isFromFRMIExtension = (subProcedureType?: string): boolean => {
      return !!subProcedureType && (subProcedureType === FRMI_TYPE_EXTENSION.value ||
              subProcedureType === FRMI_TYPE_POST_DESIGNATION_REGISTER.value ||
              subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value ||
              subProcedureType === FRMI_TYPE_LICENCE_INSCRIPTION.value)
    }
}

export default new FRMIService()
