export const STATUS_DIVISION = {
  /** Brouillon **/
  DRAFT: 'draft_division',
  /** En attente d'un mémoire administratif **/
  WAITING_VIR_PAYMENT: 'waiting_vir_payment_division',
  /** Mémoire administratif expiré **/
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired_division',
  /** A attribuer **/
  TO_ATTRIBUTE: 'to_attribute_division',
  /** A examiner **/
  TO_REVIEW: 'to_review_division',
  /** Suspension **/
  SUSPENSION: 'suspension_division',
  /** En validation **/
  VALIDATING: 'validating_division',
  /** A publier **/
  TO_PUBLISH: 'to_publish_division',
  /** Publication en cours **/
  PUBLISHING: 'publishing_division',
  /** Publié **/
  PUBLISHED: 'published_division',
  /** A retirer **/
  TO_WITHDRAW: 'to_withdraw_division',
  /** Retiré **/
  WITHDRAWN: 'withdrawn_division',
  /** A rejeter **/
  TO_REJECT: 'to_reject_division',
  /** Rejeté **/
  REJECTED: 'rejected_division',
  /** A rapporter **/
  TO_REPORT: 'to_report_division'
}

export const STATUS_DIVISION_REVIEWING = [
  STATUS_DIVISION.TO_ATTRIBUTE,
  STATUS_DIVISION.TO_REVIEW,
  STATUS_DIVISION.SUSPENSION,
  STATUS_DIVISION.VALIDATING,
  STATUS_DIVISION.TO_PUBLISH,
  STATUS_DIVISION.PUBLISHING,
  STATUS_DIVISION.TO_WITHDRAW,
  STATUS_DIVISION.TO_REJECT
]

export const STATUS_DIVISION_FINAL = [
  STATUS_DIVISION.PUBLISHED,
  STATUS_DIVISION.REJECTED,
  STATUS_DIVISION.WITHDRAWN
]
