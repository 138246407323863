import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect, useLocation } from 'react-router'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { ETSAccountNavInterface, ETSAccountRequest } from '../../interfaces/ETSAccountInterface'
import {
  CardBlock,
  CONSENTEMENT_OPTIONS,
  containsErrors,
  CONTRIBUTOR_AGENT,
  DOCUMENT_FORMATS,
  EventType,
  FieldStatus,
  FilAriane,
  FileBrowserField,
  FileButton,
  HelpBlock,
  ManageableQuality,
  ManageableQualitySelectField,
  MultipleRadioField,
  ND_ETS_ACCOUNT_CONTRIBUTORS_CODES,
  OPPOSITION_ETS_ACCOUNT_CONTRIBUTORS_CODES,
  PROCEDURE_DEPOSIT,
  PROCEDURE_MIFR,
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION,
  PROCEDURES,
  PROCEDURES_LOT_2,
  SelectField,
  SubmitButton,
  TextField
} from '@inpi-marques/components'
import {
  ACCESS_DEMAND_ITEM_ADD_AGENT,
  ACCESS_DEMAND_ITEM_EDIT_AGENT,
  ACTION_INACTIVATE_AGENT
} from '../../constants/ETSAccountConstants'
import ContentService from '../../services/content/ContentService'
import ETSAccountRequestValidator from './validator/ETSAccountRequestValidator'
import ETSAccountService from '../../services/ets/ETSAccountService'
import { Link } from 'react-router-dom'
import Message from '../../constants/Message'

const INITIAL_REQUEST = {
  procedure: PROCEDURE_DEPOSIT.value,
  numNat: '',
  code: 'AGENT'
}

export interface ETSAccountProps extends WrappedComponentProps{
  ETSAccountRequestNavItem: ETSAccountNavInterface
}

const ETSAccount : FC<ETSAccountProps> = ({
  ETSAccountRequestNavItem, intl
}) => {
  const location = useLocation()

  const [redirect, setRedirect] = useState<string|null>(null)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>([])
  const procedures = process.env.REACT_APP_LOT_2 === 'true' ? PROCEDURES_LOT_2 : PROCEDURES
  const procedureOptions = [...procedures].map(p => p.value === 'MIFR' ? { ...p, label: Message.procedure_type_mifr_ets } : p)

  /**
   * Initialise la requête avec le queryParam
   * @returns
   */
  const getInitialRequest = (): ETSAccountRequest => {
    let request: ETSAccountRequest = INITIAL_REQUEST

    const queryParams = new URLSearchParams(location.search)
    // Récupération du numNat
    const numNat = queryParams?.get('numNat')
    if (numNat) {
      request = { ...request, numNat }
    }

    return request
  }

  const [etsAccountRequest, setEtsAccountRequest] = useState<ETSAccountRequest>(getInitialRequest())

  useEffect(() => {
    ContentService.getManageableQualities(etsAccountRequest?.procedure).then(setManageableQualities)
  }, [etsAccountRequest.procedure])

  /**
   * Validation du formulaire de rattachement
   */
  const handleSubmit = () => {
    const fieldStatus = ETSAccountRequestValidator.validateForm(etsAccountRequest, ETSAccountRequestNavItem)
    setFieldStatus(fieldStatus)
    if (!containsErrors(fieldStatus)) {
      let request = { ...etsAccountRequest }
      if (etsAccountRequest.procedure === PROCEDURE_MIFR.value) {
        request = { ...etsAccountRequest, numNat: `MIFR-${etsAccountRequest.numNat}` }
      }
      return ETSAccountService.postETSAccountRequestDeposit(request, ETSAccountRequestNavItem).then(() => {
        toast.success(intl.formatMessage({ id: `ets_account_request_success_request${ETSAccountRequestNavItem.action === ACTION_INACTIVATE_AGENT ? '_detach' : ''}` }))
        setRedirect('/')
      })
    }
  }

  /**
   * Enregistrement du fichier joint
   * @param event
   * @param field
   */
  const handleFile = (event: EventType, field: string) => {
    const file = event.target.value
    setEtsAccountRequest({
      ...etsAccountRequest,
      [field]: {
        name: file.name,
        file: file
      }
    })
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id={ETSAccountRequestNavItem.breadcrumb} /></span>
      </FilAriane>
      {redirect &&
        <Redirect to={{
          pathname: redirect
        }}
        />}
      <div className='d-flex mt-4 justify-content-center'>
        <CardBlock
          className='col-12'
          shadow
        >
          <div className='d-flex justify-content-center row main'>
            <header className='col-8'>
              <h1><FormattedMessage id={ETSAccountRequestNavItem.label} /></h1>
            </header>
          </div>
          <div className='d-flex justify-content-center row mt-4'>
            <div className='is-validated row col-12 col-lg-8 col-xl-6'>
              <div className='col-12 col-md-6 mb-3'>
                <SelectField
                  inputId='procedure'
                  label={<FormattedMessage id='ets_account_request_procedure_label' />}
                  onChange={(event: EventType) => setEtsAccountRequest({
                    ...etsAccountRequest,
                    procedure: event.target.value,
                    code: 'AGENT',
                    contestedRequestNumNat: undefined
                  })}
                  placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                  fieldStatus={fieldStatus}
                  value={etsAccountRequest.procedure}
                  options={procedureOptions}
                  required
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <TextField
                  inputId='numNat'
                  label={<FormattedMessage id={`ets_account_request_procedure_numNat_${etsAccountRequest?.procedure?.toLowerCase()}`} />}
                  fieldStatus={fieldStatus}
                  value={etsAccountRequest.numNat}
                  onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, numNat: event.target.value })}
                  required
                />
              </div>
              <>
                {
                  (etsAccountRequest.procedure === PROCEDURE_OPPOSITION.value || etsAccountRequest.procedure === PROCEDURE_NULLITY.value ||
                etsAccountRequest.procedure === PROCEDURE_REVOCATION.value) &&
                  <>
                    <SelectField
                      inputId='code'
                      classNameFormGroup='col-12 col-md-6 mb-3'
                      options={etsAccountRequest.procedure === PROCEDURE_OPPOSITION.value ? OPPOSITION_ETS_ACCOUNT_CONTRIBUTORS_CODES : ND_ETS_ACCOUNT_CONTRIBUTORS_CODES}
                      value={etsAccountRequest.code}
                      label={<FormattedMessage id='ets_account_request_code_label' />}
                      placeholder={intl.formatMessage({ id: 'ets_account_request_code_placeholder' })}
                      onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, code: event.target.value })}
                      required
                      fieldStatus={fieldStatus}
                      resetError={setFieldStatus}
                    />
                    <TextField
                      classNameFormGroup='col-12 col-md-6 mb-3'
                      inputId='contestedRequestNumnat'
                      value={etsAccountRequest.contestedRequestNumNat}
                      onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, contestedRequestNumNat: event.target.value })}
                      label={<FormattedMessage id='ets_account_request_procedure_contested_request_numNat' />}
                      required
                      fieldStatus={fieldStatus}
                      resetError={setFieldStatus}
                    />
                  </>
                }
                <div className='col-12 col-md-6 mb-3'>
                  <ManageableQualitySelectField
                    inputId='manageableQuality'
                    fieldStatus={fieldStatus}
                    value={etsAccountRequest.manageableQuality?.code}
                    onChange={(event: EventType) => setEtsAccountRequest({
                      ...etsAccountRequest,
                      manageableQuality: event.target.value,
                      inscriptionNumber: undefined,
                      powerFile: undefined
                    }
                    )}
                    label={<FormattedMessage id='ets_account_request_contributor_type_label' />}
                    placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                    required
                    manageableQualities={manageableQualities?.filter(manageableQuality => manageableQuality.contributors.includes(CONTRIBUTOR_AGENT.value))}
                    procedure={etsAccountRequest.procedure}
                  />
                </div>
              </>
              {
              etsAccountRequest.manageableQuality?.numberNeeded &&
                <div className='col-12 col-md-6 mb-3'>
                  <TextField
                    inputId='inscriptionNumber'
                    label={<FormattedMessage id='ets_account_request_inscription_number' />}
                    fieldStatus={fieldStatus}
                    value={etsAccountRequest.inscriptionNumber}
                    onChange={(event: EventType) => setEtsAccountRequest({
                      ...etsAccountRequest,
                      inscriptionNumber: event.target.value
                    })}
                    required
                  />
                </div>
              }
              {
              etsAccountRequest.manageableQuality?.powerNeeded &&
                <div className='col-12 col-md-6 mb-3'>
                  <FileBrowserField
                    inputId='powerFile'
                    label={<FormattedMessage id='ets_account_request_power_file' />}
                    maxNumberFile={1}
                    buttonLabel={<FileButton />}
                    acceptApplication={DOCUMENT_FORMATS.PDF}
                    value={(etsAccountRequest.powerFile && [etsAccountRequest.powerFile]) || []}
                    onChange={(event) => handleFile(event, 'powerFile')}
                    onDelete={() => setEtsAccountRequest({
                      ...etsAccountRequest,
                      powerFile: undefined
                    })}
                    fieldStatus={fieldStatus}
                    required
                    informationDoc
                    handleDownload
                  />
                </div>
              }
              <div className='col-12 col-md-6 mb-3'>
                <FileBrowserField
                  inputId='proofFile'
                  label={<FormattedMessage id='ets_account_request_proof_file' />}
                  maxNumberFile={1}
                  buttonLabel={<FileButton />}
                  acceptApplication={DOCUMENT_FORMATS.PDF}
                  value={(etsAccountRequest.proofFile && [etsAccountRequest.proofFile]) || []}
                  onChange={(event) => handleFile(event, 'proofFile')}
                  onDelete={() => setEtsAccountRequest({
                    ...etsAccountRequest,
                    proofFile: undefined
                  })}
                  informationDoc
                  handleDownload
                />
              </div>
              {
                (ETSAccountRequestNavItem === ACCESS_DEMAND_ITEM_ADD_AGENT || ETSAccountRequestNavItem === ACCESS_DEMAND_ITEM_EDIT_AGENT) &&
                  <div className='col-12'>
                    <HelpBlock className='align-self-start w-100'>
                      <FormattedMessage
                        id='ets_account_request_is_recipient_text'
                        values={{
                          br: <br />
                        }}
                      />
                      <FormattedMessage
                        id='ets_account_request_is_recipient_info_text'
                        values={{
                          a: (...chunks : ((string) []) | []) => (<a href='/inscriptions/nouveau'>{chunks}</a>)
                        }}
                      />
                    </HelpBlock>
                  </div>
              }
              {ETSAccountRequestNavItem.showConsentNotificationBlock && (
                <div className='bg-primary card h-auto shadow w-100 align-items-center my-3 d-flex flex-row p-2'>
                  <span className='text-white pl-1 pr-4'><FormattedMessage id='field_email_consent_recap' /></span>
                  <MultipleRadioField
                    classNameRadioGroup='d-flex text-white'
                    inputId='consentNotificationByEmail'
                    value={etsAccountRequest.consentNotificationByEmail}
                    radio={CONSENTEMENT_OPTIONS}
                    fieldStatus={fieldStatus}
                    onChange={(event) => setEtsAccountRequest({ ...etsAccountRequest, consentNotificationByEmail: event.target.value })}
                  />
                </div>
              )}

              <div className='w-100 row my-5'>
                <div className='col'>
                  <div className='d-flex justify-content-center'>
                    <SubmitButton
                      children={<FormattedMessage id='button_confirm' />}
                      className='btn-primary'
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBlock>
      </div>
    </>
  )
}

export default injectIntl(ETSAccount)
