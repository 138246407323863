export const ETS_ACCOUNT_STATE_ACTIVE = 'ACTIVE'
export const ETS_ACCOUNT_STATE_INACTIVE = 'INACTIVE'
export const ETS_ACCOUNT_STATE_REJECTED = 'REJECTED'
export const ETS_ACCOUNT_STATE_TO_ADD = 'TO_ADD'
export const ETS_ACCOUNT_STATE_TO_EDIT = 'TO_EDIT'
export const ETS_ACCOUNT_STATE_TO_INACTIVATE = 'TO_INACTIVATE'

export interface IStateLabel {
    state: string,
    label: string,
    className: string
}

export const ETS_ACCOUNT_STATE_LABEL: IStateLabel[] = [
  {
    state: ETS_ACCOUNT_STATE_ACTIVE,
    label: 'intervenant_status_active',
    className: 'bg-primary'
  },
  {
    state: ETS_ACCOUNT_STATE_TO_ADD,
    label: 'intervenant_status_to_add',
    className: 'bg-success'
  }, {
    state: ETS_ACCOUNT_STATE_TO_EDIT,
    label: 'intervenant_status_to_edit',
    className: 'bg-warning'
  }, {
    state: ETS_ACCOUNT_STATE_TO_INACTIVATE,
    label: 'intervenant_status_to_inactivate',
    className: 'bg-secondary'
  }
]
