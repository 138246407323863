import React, { FC, useEffect, useState } from 'react'
import { Country, Priority, Table } from '@inpi-marques/components'
import ContentService from 'services/content/ContentService'
import { FormattedMessage } from 'react-intl'
import Message from '../../../../constants/Message'
import PrioritiesFRMIRowContent from './PrioritiesFRMIRowContent'

interface OverviewPrioritiesFRMIProps {
    priorities: Priority[],
    editPriority?: (index: number) => void,
    deletePriority?: (index: number) => void,
    className?: string,
    hasTitle?: boolean,
}

const OverviewPrioritiesFRMI: FC<OverviewPrioritiesFRMIProps> = (
  {
    priorities,
    editPriority,
    deletePriority,
    className = '',
    hasTitle = false
  }) => {
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })
  }, [])

  return (
    <>
      {hasTitle &&
        <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_options_priorities' /></h4>}
      {priorities && priorities.length > 0
        ? (
          <Table
            tableTitle={[
              { label: Message.options_columns_label_id, className: 'table-column-label pl-2' },
              { label: Message.options_columns_label_number, className: 'table-column-label' },
              { label: Message.options_columns_label_date, className: 'table-column-label' },
              {
                label: Message.options_columns_label_country,
                className: 'table-column-label table-column-country'
              },
              {
                label: Message.products_and_services_class,
                className: 'table-column-label table-column-country'
              }
            ]}
            tableClassName={`priority-table ${className}`}
          >
            <tbody>
              {
                priorities.map((priority: Priority, index: number) => {
                  const country: Country | undefined = countries.find(item => item.code === priority.countryOrOrganization)
                  return (
                    <PrioritiesFRMIRowContent
                      key={`row-priority-${index}`}
                      priority={priority}
                      index={index}
                      country={country}
                      editPriority={editPriority}
                      deletePriority={deletePriority}
                    />
                  )
                })
              }
            </tbody>
          </Table>) : <div><FormattedMessage id='none_feminine' /></div>}
    </>
  )
}

export default OverviewPrioritiesFRMI
