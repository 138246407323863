import { CardBlock, Payment, ProductClassVersion, ProductService, PRODUCT_AND_SERVICE_VERSION_TYPE, PRODUCT_CLASS_VERSION_STATUS, Transaction } from '@inpi-marques/components'
import PaymentChoices from 'component/payment/PaymentChoices'
import TransactionAmountOverview from 'component/transactions/overview/TransactionAmount'
import { PAYMENT_LABELS, PAYMENT_METHODS, PAYMENT_OBJECTS, PAYMENT_URL_REGULARIZATION } from 'constants/PaymentConstants'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'
import DepositService from 'services/deposit/DepositService'
import PaymentService, { PaymentParameter } from 'services/payment/PaymentService'

interface RegularizationPaymentFormProps {
    payment: Payment,
    transaction: Transaction,
    version: ProductClassVersion
}
const RegularizationPaymentForm: FC<RegularizationPaymentFormProps> = ({ payment, transaction, version }) => {
  const history = useHistory()

  const onPaymentSubmit = async (parameter: PaymentParameter): Promise<void> => {
    // Création de la demande de régularisation
    const newVersion : ProductClassVersion = version.status === PRODUCT_CLASS_VERSION_STATUS.DRAFT_FO
      ? version
      : {
        ...version,
        type: PRODUCT_AND_SERVICE_VERSION_TYPE.VERSION_REGULARIZATION,
        status: PRODUCT_CLASS_VERSION_STATUS.WAITING_PAYMENT
      }
    let updatedTransaction: Transaction | null = null
    if (ProductService.isDraftFoVersion(newVersion) && newVersion.id) {
      updatedTransaction = await DepositService.editProducts(transaction.id, newVersion)
    } else {
      updatedTransaction = await DepositService.createProductsVersion(transaction.id, newVersion)
    }

    const updatedVersion: ProductClassVersion|undefined|null = updatedTransaction &&
        updatedTransaction.deposit?.productsAndServicesVersions?.find((currentVersion: ProductClassVersion) => currentVersion.createdDate === version.createdDate)
    if (updatedVersion) {
      const objectIds = updatedVersion.id ? [updatedVersion.id] : []
      parameter = {
        ...parameter,
        label: PAYMENT_LABELS.REGULARIZATION,
        objectName: PAYMENT_OBJECTS.REGULARIZATION,
        objectIds
      }
      try {
        // Paiement de la régularisation
        const createdPayment: string = await PaymentService.createPayment(transaction.id, parameter)
        if (parameter.method === PAYMENT_METHODS.BLUE_CARD) {
          PaymentService.displayPayBox(createdPayment)
        } else {
          const url = `/${PAYMENT_URL_REGULARIZATION}/${transaction.id}/paiement/confirmation`
          history.push(url)
        }
      } catch (error) {
        history.push(`/${PAYMENT_URL_REGULARIZATION}/${transaction.id}/paiement/annulation?id=${error.message}`)
      }
    }
  }

  return (
    <>
      <div className='mb-3'>
        <CardBlock header={<FormattedMessage id='overview_amount_title' />} shadow>
          <TransactionAmountOverview payment={payment} />
        </CardBlock>
      </div>
      <div className='mb-3'>
        <PaymentChoices onPaymentSubmit={onPaymentSubmit} amount={payment.totalAmount} transaction={transaction} />
      </div>
    </>)
}

export default RegularizationPaymentForm
