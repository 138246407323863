import Message from './Message'

export const PRODUCTS_AND_SERVICES_TABLE_TITLE_MODAL_ADD_RECORD = [
  {
    label: Message.product_and_service_class_ref
  },
  {
    label: Message.product_and_service_products_name,
    colSpan: 10
  }
]
