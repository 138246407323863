import {
  CardBlock,
  CONSENTEMENT_OPTIONS,
  containsErrors,
  EventType,
  FieldStatus,
  FilAriane, ManageableQuality,
  ManageableQualitySelectField,
  MultipleRadioField,
  SubmitButton,
  TextField
} from '@inpi-marques/components'
import { ETSAccountNavInterface, ETSAccountRequest } from 'interfaces/ETSAccountInterface'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ETSAccountService from 'services/ets/ETSAccountService'
import ETSAccountTmcValidator from './validator/ETSAccountTmcValidator'
import { useHistory } from 'react-router'
import ContentService from '../../services/content/ContentService'
/* global localStorage */

interface ETSAccountTMCProps {
    ETSAccountRequestNavItem: ETSAccountNavInterface
}

const ETSAccountTMC: FC<ETSAccountTMCProps> = ({ ETSAccountRequestNavItem }) => {
  const intl = useIntl()
  const history = useHistory()

  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>([])
  const [etsAccountRequest, setEtsAccountRequest] = useState<ETSAccountRequest>({ numNat: '' })
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>()
  const askConsent = localStorage.getItem('ELECTRONIC_NOTIF') === 'true'

  /**
     * Validation du formulaire de rattachement
     */
  const handleSubmit = () => {
    const fieldStatus = ETSAccountTmcValidator.validateForm(etsAccountRequest)
    etsAccountRequest.code = 'TMC'
    setFieldStatus(fieldStatus)
    if (!containsErrors(fieldStatus)) {
      return ETSAccountService.postETSAccountRequestDeposit(etsAccountRequest, ETSAccountRequestNavItem).then(() => {
        toast.success(intl.formatMessage({ id: 'ets_account_request_success_request' }))
        history.push('/')
      })
    }
  }

  useEffect(() => {
    const qualitiesToFetch = ['REPRESENTANT_PM_TMC', 'EMPLOYEE_PM_TMC', 'HOLDER_PERSON_CONTESTED_BRAND']
    ContentService.getManageableQualities(etsAccountRequest?.procedure, qualitiesToFetch).then(setManageableQualities)
  }, [])

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id={ETSAccountRequestNavItem.breadcrumb} /></span>
      </FilAriane>

      <div className='d-flex mt-4 justify-content-center'>
        <CardBlock
          className='col-12'
          shadow
        >
          <div className='d-flex justify-content-center row main'>
            <header className='col-8'>
              <h1><FormattedMessage id={ETSAccountRequestNavItem.label} /></h1>
            </header>
          </div>
          <div className='d-flex justify-content-center row mt-4'>
            <div className='is-validated row col-12 col-lg-8 col-xl-6'>
              <div className='col-12 col-md-6 mb-3'>
                <TextField
                  inputId='numNat'
                  label={<FormattedMessage id='ets_account_request_procedure_numNat_tmc' />}
                  fieldStatus={fieldStatus}
                  value={etsAccountRequest?.numNat}
                  onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, numNat: event.target.value })}
                  required
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <TextField
                  inputId='contestedRequestNumNat'
                  label={<FormattedMessage id='ets_account_request_procedure_contested_request_numNat' />}
                  fieldStatus={fieldStatus}
                  value={etsAccountRequest?.contestedRequestNumNat}
                  onChange={(event: EventType) => setEtsAccountRequest({ ...etsAccountRequest, contestedRequestNumNat: event.target.value })}
                  required
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <ManageableQualitySelectField
                  inputId='manageableQuality'
                  fieldStatus={fieldStatus}
                  value={etsAccountRequest.manageableQuality?.code}
                  onChange={(event: EventType) => setEtsAccountRequest({
                    ...etsAccountRequest,
                    manageableQuality: event.target.value,
                    inscriptionNumber: undefined,
                    powerFile: undefined
                  }
                  )}
                  label={<FormattedMessage id='ets_account_request_contributor_type_label' />}
                  placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                  required
                  manageableQualities={manageableQualities}
                  procedure={etsAccountRequest.procedure}
                />
              </div>
              {askConsent &&
                <div className='bg-primary card h-auto shadow w-100 align-items-center my-3 d-flex flex-row p-2'>
                  <span className='text-white pl-1 pr-4'><FormattedMessage id='field_email_consent_recap' /></span>
                  <MultipleRadioField
                    classNameRadioGroup='d-flex text-white'
                    inputId='consentNotificationByEmail'
                    value={etsAccountRequest.consentNotificationByEmail}
                    radio={CONSENTEMENT_OPTIONS}
                    fieldStatus={fieldStatus}
                    onChange={(event) => setEtsAccountRequest({
                      ...etsAccountRequest,
                      consentNotificationByEmail: event.target.value
                    })}
                  />
                </div>}
              <div className='w-100 row my-5'>
                <div className='col'>
                  <div className='d-flex justify-content-center'>
                    <SubmitButton
                      children={<FormattedMessage id='button_confirm' />}
                      className='btn-primary'
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBlock>
      </div>
    </>
  )
}

export default ETSAccountTMC
