import React, { FC, useEffect, useState } from 'react'
import {
  ContributorValidator,
  EditorialBlock,
  EventType,
  FieldStatus,
  FilAriane,
  ModalComponent,
  PROCEDURE_DIVISION,
  Transaction,
  TransactionDocument,
  DIVISION_CONTRIBUTORS_TYPES,
  DEFAULT_CONTRIBUTOR, DIVISION_CONTRIBUTORS_OPTIONS,
  Contributor,
  ProductService as CommonProductsService,
  CommonContributorService
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import {
  storeTransactionInit,
  storeTransactionRemove,
  storeTransactionUpdate
} from 'store/transaction/transactionActions'
import TransactionService from 'services/transaction/TransactionService'
import ContentService from '../../../services/content/ContentService'
import DivisionBrand from './DivisionBrand'
import ContributorList from '../../contributors/ContributorList'
import ContributorAgent from '../../contributors/ContributorAgent'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import ContributorService from '../../../services/contributors/ContributorService'
import ContributorFrontForm from '../../contributors/ContributorFrontForm'
import Recap from '../../recap/Recap'
import PaymentForm from '../../payment/PaymentForm'
import {
  PAYMENT_LABELS,
  PAYMENT_OBJECTS,
  PAYMENT_URL_DIVISION
} from '../../../constants/PaymentConstants'
import DivisionProductsAndServices from './productsAndServices/DivisionProductsAndServices'
import ProductsAndServiceValidator from './productsAndServices/validator/ProductsAndServicesValidator'
import Options from '../../division/form/options/Options'
import DivisionTypeValidator from './divisionType/validator/DivisionTypeValidator'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
/* global localStorage */

const DivisionForm: FC<RouteComponentProps> = ({
  history,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const fieldStatus: FieldStatus = useSelector((state: RootStateOrAny) => state.transactionFieldStatus)
  const transaction: Transaction = useSelector((state: RootStateOrAny) => state.transaction)
  const [documents, setDocuments] = useState<TransactionDocument[]>([])
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [holders, setHolders] = useState<Contributor[]>()
  const [required, setIsRequired] = useState<boolean>(false)

  const handleSubmit = () => {
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  const onDocumentChanged = (event: EventType, type: string) => {
    const { value } = event.target

    const newFile: TransactionDocument = {
      id: documents.length,
      type: type,
      internalName: '',
      name: value.name,
      format: value.type,
      file: value
    }
    setDocuments([...documents, newFile])
  }

  const onDocumentDeleted = (event: EventType) => {
    const { value } = event.target
    setDocuments(documents.filter((file: TransactionDocument) => file.internalName !== value.internalName))
  }

  const saveBrandDivided = async () => {
    if (isNew && !transaction.id) {
      setIsNew(false)
      const procedureType = transaction.procedureType ? transaction.procedureType : PROCEDURE_DIVISION.value
      return await TransactionService.createTransaction(transaction, procedureType, ['deposit', 'subProcedureType', 'suite'], documents)
    } else {
      return await TransactionService.updateTransactionBDDFromStore(transaction, ['deposit', 'subProcedureType', 'procedureType', 'suite'], documents)
    }
  }

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        setDocuments(result.documents)
      })
    } else {
      dispatch(storeTransactionInit(PROCEDURE_DIVISION.value))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-DIVISION').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_division' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='division_step_list_title' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        views={[
          {
            id: 'division_brand',
            label: <FormattedMessage id='select_division_brand_title' />,
            onGoToStep: saveBrandDivided,
            component: (
              <DivisionBrand
                onDocumentChanged={onDocumentChanged}
                onDocumentDeleted={onDocumentDeleted}
                fieldStatus={fieldStatus}
                transaction={transaction}
                documents={documents}
                setHolders={setHolders}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: () => DivisionTypeValidator.validateOnChangeStep(documents, fieldStatus, transaction)
          },
          {
            id: 'contributors',
            label: <FormattedMessage id='opposition_contributors_label' />
          },
          {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_depositors_division' />,
            level: 1,
            component: (
              <ContributorList
                openOnFirstOne={!transaction.depositors?.length}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={DIVISION_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                contributorTypeToCopy={[
                  DIVISION_CONTRIBUTORS_TYPES.AGENT.value,
                  DIVISION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  DIVISION_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                externalContributorsToCopy={holders}
                contributorTypeLabel={`${DIVISION_CONTRIBUTORS_TYPES.DEPOSITORS.value}_division`}
                optionForm={DIVISION_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_DIVISION.value}
                validateContributor={ContributorValidator.validateContributor}
                setIsRequired={setIsRequired}
              />),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [DIVISION_CONTRIBUTORS_TYPES.DEPOSITORS.value])
          },
          {
            id: 'contributors_agent',
            label: <FormattedMessage id='contributor_agent' />,
            level: 1,
            component: (
              <ContributorAgent
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorTypeToCopy={[
                  DIVISION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  DIVISION_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  DIVISION_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                procedure={PROCEDURE_DIVISION.value}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepAgentDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [DIVISION_CONTRIBUTORS_TYPES.AGENT.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, DIVISION_CONTRIBUTORS_TYPES.AGENT.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={DIVISION_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  DIVISION_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  DIVISION_CONTRIBUTORS_TYPES.AGENT.value,
                  DIVISION_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={DIVISION_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_DIVISION.value}
                defaultContributor={{ ...DEFAULT_CONTRIBUTOR, email: user.email, consentNotificationByEmail: user.consentNotificationByEmailPreferences }}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore({
              ...transaction,
              [DIVISION_CONTRIBUTORS_TYPES.RECIPIENT.value]: CommonContributorService.filterContributorCivilityCode(transaction[DIVISION_CONTRIBUTORS_TYPES.RECIPIENT.value])
            }, [DIVISION_CONTRIBUTORS_TYPES.RECIPIENT.value])
          },
          {
            id: 'contributors_signatory_opposition',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={DIVISION_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={DIVISION_CONTRIBUTORS_OPTIONS.signatory}
                procedure={PROCEDURE_DIVISION.value}
                defaultContributor={ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [DIVISION_CONTRIBUTORS_TYPES.SIGNATORY.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, DIVISION_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'products_and_services',
            label: <FormattedMessage id='products_and_services_title' />,
            component: <DivisionProductsAndServices fieldStatus={fieldStatus} transaction={transaction} />,

            validateGoToStep: () => ProductsAndServiceValidator.validateOnChangeStep(
              CommonProductsService.getCurrentVersion(transaction.deposit?.parentProductsAndServicesVersions),
              CommonProductsService.getCurrentVersion(transaction.deposit?.productsAndServicesVersions)
            ),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['deposit']),
            stepButtonsAsChildren: true
          },
          {
            id: 'options',
            label: <FormattedMessage id='options_label' />,
            component: (
              <Options transaction={transaction} />),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['internationalExtensions', 'deposit'])
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.INITIAL}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_DIVISION}
              />
            )
          }]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(DivisionForm)
