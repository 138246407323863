import Pagination from './Pagination'
import { FormattedMessage } from 'react-intl'
import { EventType, ROWS, SelectField, SelectOption } from '../index'
import React, { FC } from 'react'
import { TableRow } from '../constants/TableConstant'

interface PaginationTableProps {
    actualPage?: number,
    totalPage?: number,
    totalSize?: number,
    valueSelect?: number,
    nbShown?: SelectOption[],
    rows?: TableRow[],
    paginationClassName?: string,
    onChangePagination?: (page: number) => void
    onChangeSelect?: ((event: EventType) => void) | undefined
}

const PaginationTable: FC<PaginationTableProps> = ({
  actualPage = 1,
  totalPage = 1,
  totalSize,
  valueSelect,
  nbShown,
  rows = ROWS,
  paginationClassName,
  onChangePagination,
  onChangeSelect
}) => (
  <div className={`row justify-content-end ${paginationClassName}`}>
    <div className='col-md-4 col-12' />
    <div className='col-md-4 d-flex justify-content-end flex-wrap'>
      {!!totalPage && totalPage > 0 && actualPage && onChangePagination &&
        <Pagination
          nbPage={totalPage}
          currentPage={actualPage}
          onChange={onChangePagination}
        />}
    </div>
    <div className='col-md-4 col-12 d-flex justify-content-end align-items-center'>
      {
        !!totalSize && totalSize > 0 &&
          <div className='mb-3 mr-2 text-primary font-weight-bold'>
            <FormattedMessage id='elements_from' />
            {actualPage && valueSelect ? (actualPage - 1) * valueSelect + 1 : 'X'}
            <FormattedMessage id='to' />
            {actualPage && valueSelect && totalSize ? valueSelect === -1 ? totalSize : Math.min(actualPage * valueSelect, totalSize) : 'X'}
            <FormattedMessage id='on' />
            {totalSize}
          </div>
      }
      {!!nbShown && !!totalPage &&
        <SelectField
          inputId='rows'
          options={nbShown}
          value={!rows?.find(v => v.value === valueSelect) ? -1 : valueSelect}
          onChange={onChangeSelect}
          required
        />}
    </div>
  </div>
)
export default PaginationTable
