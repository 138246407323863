import { SelectOption } from 'src/index'

export const OPTION_REFUSED_CONSENTEMENT: SelectOption = {
  value: true,
  label: 'oui'
}
export const OPTION_ACCEPT_CONSENTEMENT: SelectOption = {
  value: false,
  label: 'non'
}

export const CONSENTEMENT_OPTIONS: SelectOption[] = [
  OPTION_REFUSED_CONSENTEMENT,
  OPTION_ACCEPT_CONSENTEMENT
]
