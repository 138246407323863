import React, { useMemo, useCallback, useState, useEffect, FC } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { ErrorField, FieldStatus } from '../index'
import JSZip from 'jszip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
/* global File */
/* global Blob */

const baseStyle = {
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#DFDFE0',
  borderStyle: 'dashed',
  color: '#171F2A',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#656665'
}

const acceptStyle = {
  borderColor: '#656665'
}

const rejectStyle = {
  borderColor: '#FF1744'
}

interface DropAreaProps{
  maxSize?: number|{[key: string]: number},
  onDrop: (files: File[]) => void,
  accept?: string,
  text?: string|null,
  fieldStatus?: FieldStatus,
  nameFieldStatus?: string|null,
  icon?: IconProp,
  useJpg?: boolean
}

const DropArea: FC<DropAreaProps> = ({
  maxSize,
  onDrop,
  accept,
  text,
  fieldStatus,
  nameFieldStatus,
  icon,
  useJpg
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [fileErrors, setFileErrors] = useState<Array<{ file: { path: string }, errors: { code: string }[] }>>([])

  /**
   * Au moment de faire le drop, on traite les fichiers acceptés
   */
  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    setIsLoading(true)
    // Traitement spécifique pour les fichiers zip, il faut extraire les fichiers et vérifier qu'ils sont conformes
    const zipFiles = acceptedFiles.filter(file => (!file.type || process.env.REACT_APP_ACCEPTED_ZIP_FORMAT?.indexOf(file.type) !== -1))
    if (zipFiles && zipFiles.length) {
      // Liste des fichiers non zippés
      const filesList = acceptedFiles.filter(file => (file.type && process.env.REACT_APP_ACCEPTED_ZIP_FORMAT?.indexOf(file.type) === -1))
      // Récupération du contenu des fichiers zip
      zipFiles.map(file => {
        getFileContent(file).then(files => {
          setIsLoading(false)
          onDrop([...filesList, ...files])
        })
      })
    } else {
      const errors: { file: { path: string }, errors: { code: string }[] }[] = []
      // Pour un fichier pas dans un zip, on vérifie si le poids est autorisé
      acceptedFiles.forEach((file: File, index: number, array) => {
        if (typeof maxSize === 'object') {
          Object.entries(maxSize).forEach(([key, value]) => {
            if (file.type === key && file.size > value) {
              errors.push({ file: { path: file.name }, errors: [{ code: `Le poids du fichier est supérieur à ${(value / 10e5)}Mo` }] })
              array.splice(index, 1)
            }
          })
        } else if (typeof maxSize === 'number') {
          if (file.size > maxSize!) {
            errors.push({ file: { path: file.name }, errors: [{ code: `Le poids du fichier est supérieur à ${(maxSize / 10e5)}Mo` }] })
            array.splice(index, 1)
          }
        }
      })
      setFileErrors((currentFileErrors) => [...currentFileErrors, ...errors])
      setIsLoading(false)
      onDrop(acceptedFiles)
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    accept: accept,
    onDropAccepted
  })

  useEffect(() => {
    setFileErrors(fileRejections)
  }, [fileRejections])

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ])

  /**
     * Récupération du contenu d'un fichier zip
     * @param zipFile
     * @returns {Array}
     */
  const getFileContent = async (zipFile) => {
    const listOfFiles = []
    const errors = [...fileErrors]
    const newZip = new JSZip()

    // Récupération du contenu
    const zip = await newZip.loadAsync(zipFile)

    // Pour chaque fichier du zip, on vérifie si l'extension et le poids sont autorisés
    for (const filename of Object.keys(zip.files)) {
      // Ignore les dossiers
      if (filename.endsWith('/') || filename.endsWith('\\')) {
        continue
      }

      // Ignore le contenu du dossier "__MACOSX" présent dans les archives générées par Mac
      if (filename.startsWith('__MACOSX')) {
        continue
      }

      const extension = filename.substr(filename.lastIndexOf('.') + 1)
      if (process.env.REACT_APP_ACCEPTED_IMAGE_EXTENSION.indexOf(extension) !== -1) {
        // Récupération du contenu du fichier
        const fileData = await zip.files[filename].async('array')
        const blob = new Blob([new Uint8Array(fileData)])
        if (blob.size <= process.env.REACT_APP_ACCEPTED_IMAGE_SIZE) {
          try {
            listOfFiles.push(new File([blob], filename))
          } catch (e) {
            // La création d'un fichier à partir d'un blob ne fonctionne pas sur IE
            // on ajoute les métadonnées d'un fichier sur le blob
            blob.lastModifiedDate = new Date()
            blob.name = filename
            listOfFiles.push(blob)
          }
        } else {
          errors.push({
            file: { path: filename },
            errors: [{
              code: 'file-too-large'
            }]
          })
        }
      } else {
        errors.push({
          file: { path: filename },
          errors: [{
            code: 'file-invalid-type'
          }]
        })
      }
    }
    setFileErrors(fileErrors => [...fileErrors, ...errors])
    return listOfFiles
  }

  // Affichage des erreurs pour les fichiers rejetés (poids, format...)
  const fileRejectionItems = fileErrors.map(({ file, errors }) => (
    <div key={file.path} className='d-flex'>
      <span className='validation-error'>{file.path} :&nbsp;</span>
      {errors?.map((e, index) => {
        return (
          <React.Fragment key={e.code}>
            <span className='validation-error'>{index > 0 ? '\xA0/\xA0' : ''}</span>
            <ErrorField message={<FormattedMessage key={'drop_area_error_' + index} id={e.code} />} />
          </React.Fragment>
        )
      })}
    </div>
  ))

  const formatExtensionMime = (extension: string) => {
    let newExtension = extension.replace(/application\/|image\/|video\/|audio\//gi, '').replace('mpeg', 'mp3')
    if (useJpg) {
      newExtension = newExtension.replace('jpeg', 'jpg')
    }
    return newExtension
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <section className='dropzone-section my-4 is-validated'>
        {isLoading ? (
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon className='loader' icon={faSpinner} size='2x' />
          </div>
        ) : (
          <div className='d-flex flex-column align-items-start'>
            <div className='dropzone-area' {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p> <FontAwesomeIcon className='text-primary' icon={icon || faFileUpload} /><FormattedMessage id={text || 'drop_files'} /></p>
            </div>
            <div>
              {fileRejectionItems}
              {fieldStatus && <ErrorField message={fieldStatus[nameFieldStatus] ?? ''} />}
            </div>
            <div className='d-flex flex-column w-100 text-gray-dark'>
              {accept && (
                <span className='text-break'><FormattedMessage id='accepted_file_types' />: {formatExtensionMime(accept)}</span>
              )}
              {typeof maxSize === 'object' &&
                (
                  <span><FormattedMessage id='file_max_size' />:
                    {
                      Object.keys(maxSize).length > 1
                        ? Object.entries(maxSize).map(([key, value]) => (
                          <div key={key}>{`${(value / 10e5)}Mo - ${formatExtensionMime(key)}`}</div>
                        ))
                        : (<span>{` ${(Object.values(maxSize)[0] / 10e5)}Mo`}</span>)

                    }
                  </span>)}
              {typeof maxSize === 'number' && maxSize !== -1 && (
                <span><FormattedMessage id='file_max_size' />: {(maxSize / 10e5)}Mo</span>
              )}
            </div>
          </div>
        )}
      </section>
    </IntlProvider>
  )
}

export default DropArea
