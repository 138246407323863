import React from 'react'
import {
  FilAriane
} from '@inpi-marques/components'
import { Link, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import TransactionList from '../list/TransactionList'

const SearchResults = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='basket_label_all' /></span>
      </FilAriane>
      <TransactionList searchText={queryParams.get('search')} procedureSearch={queryParams.get('procedure')} />
    </>
  )
}

export default SearchResults
