import { SelectOption } from 'src'
import { DOCUMENT_COMMUNICABILITIES } from './DocumentConstants'
import Message from './Message'

export const STATUS_INSCRIPTION = {
  /** En cours de création */
  DRAFT: 'draft_inscription',
  /** Brouillon */
  DRAFT_CLIENT: 'draft_client_inscription',
  /** En attente d'un mémoire administratif */
  WAITING_VIR_PAYMENT: 'waiting_vir_payment_inscription',
  /** Mémoire administratif expiré */
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired_inscription',
  /** A attribuer */
  TO_ATTRIBUTE: 'to_attribute_inscription',
  /** A examiner */
  TO_REVIEW: 'to_review_inscription',
  /** En validation */
  IN_VALIDATION: 'in_validation_inscription',
  /** A valider */
  TO_VALIDATE: 'to_validate_inscription',
  /** Suspendu */
  SUSPENDED: 'suspended_inscription',
  /** Sans objet */
  NOT_APPLICABLE: 'not_applicable_inscription',
  /** A publier */
  TO_PUBLISH: 'to_publish_inscription',
  /** Publication en cours */
  PUBLICATION_IN_PROGRESS: 'publication_in_progress_inscription',
  /** A rejeter */
  TO_REJECT: 'to_reject_inscription',
  /** A rapporter */
  TO_REPORT: 'to_report_inscription',
  /** Publié */
  PUBLISHED: 'published_inscription',
  /** Rejeté */
  REJECTED: 'rejected_inscription',
  /** Retiré */
  WITHDRAWN: 'withdrawn_inscription',
  /** Bermudes */
  BERMUDA: 'bermuda_inscription',
  /** Annulée */
  CANCELLED: 'cancelled_inscription',
  /** Errata */
  ERRATA: 'errata_inscription'
}

export const INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTION_COMMUNICABLE: SelectOption = {
  value: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE,
  label: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE
}

export const INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTION_NON_COMMUNICABLE: SelectOption = {
  value: DOCUMENT_COMMUNICABILITIES.NON_COMMUNICABLE,
  label: DOCUMENT_COMMUNICABILITIES.NON_COMMUNICABLE
}

export const INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTIONS: SelectOption[] = [
  INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTION_COMMUNICABLE,
  INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTION_NON_COMMUNICABLE
]

export const PROCESS_NORMAL = 'TN'
export const PROCESS_EXPEDITED = 'TA'
export const PROCESS_OFFICE = 'OF'

/**
 * Type de récompenses
 */

export const INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD: SelectOption = {
  value: 'INDUSTRIAL_REWARD',
  label: Message.inscription_contest_type_industrial_reward_label
}

export const INSCRIPTION_CONTEST_TYPE_RANKING: SelectOption = {
  value: 'RANKING',
  label: Message.inscription_contest_type_ranking_label
}

export const INSCRIPTION_CONTEST_TYPES: SelectOption[] = [
  INSCRIPTION_CONTEST_TYPE_INDUSTRIAL_REWARD,
  INSCRIPTION_CONTEST_TYPE_RANKING
]

export const STATUS_INSCRIPTION_REVIEWING = [
  STATUS_INSCRIPTION.WAITING_VIR_PAYMENT,
  STATUS_INSCRIPTION.TO_ATTRIBUTE,
  STATUS_INSCRIPTION.SUSPENDED,
  STATUS_INSCRIPTION.TO_REVIEW,
  STATUS_INSCRIPTION.TO_PUBLISH,
  STATUS_INSCRIPTION.TO_REJECT,
  STATUS_INSCRIPTION.TO_REPORT
]

/**
 * RENONCIATION
 */

export const INSCRIPTION_RENUNCIATION_TYPE_TOTAL: SelectOption = {
  value: 'TOTAL_RENUNCIATION',
  label: Message.inscription_renunciation_type_total_label
}

export const INSCRIPTION_RENUNCIATION_TYPE_PARTIAL: SelectOption = {
  value: 'PARTIAL_RENUNCIATION',
  label: Message.inscription_renunciation_type_partial_label
}

export const INSCRIPTION_RENUNCIATION_TYPES: SelectOption[] = [
  INSCRIPTION_RENUNCIATION_TYPE_TOTAL,
  INSCRIPTION_RENUNCIATION_TYPE_PARTIAL
]

/**
 * Filtre sur les statuts d'une transaction
 */

export const GROUP_STATUS_PUBLISHED = 'GROUP_STATUS_PUBLISHED'
export const GROUP_STATUS_REJECTED = 'GROUP_STATUS_REJECTED'
export const GROUP_STATUS_REVIEWING = 'GROUP_STATUS_REVIEWING'

export const TRANSACTION_GROUP_STATUS_PUBLISHED: SelectOption = {
  value: GROUP_STATUS_PUBLISHED,
  label: Message.transaction_group_status_published
}

export const TRANSACTION_GROUP_STATUS_REVIEWING: SelectOption = {
  value: GROUP_STATUS_REVIEWING,
  label: Message.transaction_group_status_to_review
}

export const TRANSACTION_GROUP_STATUS_REJECTED: SelectOption = {
  value: GROUP_STATUS_REJECTED,
  label: Message.transaction_group_status_rejected
}

export const STATUS_INSCRIPTION_RELATED_TRANSACTIONS: SelectOption[] = [
  TRANSACTION_GROUP_STATUS_PUBLISHED,
  TRANSACTION_GROUP_STATUS_REVIEWING,
  TRANSACTION_GROUP_STATUS_REJECTED
]

export interface InscriptionType {
  value: string,
  label: string
}

export const INSCRIPTION_TYPE_CORRECTION: InscriptionType = {
  value: 'INSCRIPTION_TYPE_CORRECTION',
  label: 'inscription_type_correction'
}

export const INSCRIPTION_TYPE_AGENT: InscriptionType = {
  value: 'INSCRIPTION_TYPE_AGENT',
  label: 'inscription_type_agent'
}

export const INSCRIPTION_TYPE_ACT_TITLE: InscriptionType = {
  value: 'INSCRIPTION_TYPE_ACT_TITLE',
  label: 'inscription_type_act_title'
}

export const INSCRIPTION_TYPE_REWARD: InscriptionType = {
  value: 'INSCRIPTION_TYPE_REWARD',
  label: 'inscription_type_reward'
}

export const INSCRIPTION_TYPE_OFFICE: InscriptionType = {
  value: 'INSCRIPTION_TYPE_OFFICE',
  label: 'inscription_type_office'
}

export const INSCRIPTION_TYPES: InscriptionType[] = [
  INSCRIPTION_TYPE_CORRECTION,
  INSCRIPTION_TYPE_AGENT,
  INSCRIPTION_TYPE_ACT_TITLE,
  INSCRIPTION_TYPE_REWARD
]

export const INSCRIPTION_PROCESS_NORMAL = {
  value: PROCESS_NORMAL,
  label: Message.inscription_process_normal_label
}

export const INSCRIPTION_PROCESS_EXPEDITED = {
  value: PROCESS_EXPEDITED,
  label: Message.inscription_process_expedited_label
}

export const INSCRIPTION_PROCESS_OFFICE = {
  value: PROCESS_OFFICE,
  label: Message.inscription_process_office_label
}

export const INSCRIPTION_PROCESSES: SelectOption[] = [
  INSCRIPTION_PROCESS_NORMAL,
  INSCRIPTION_PROCESS_EXPEDITED
]

export const OFFICE_INSCRIPTION_PROCESSES: SelectOption[] = [
  INSCRIPTION_PROCESS_NORMAL,
  INSCRIPTION_PROCESS_EXPEDITED,
  INSCRIPTION_PROCESS_OFFICE
]

export const INSCRIPTION_TYPE_RENUNCIATION: InscriptionType = {
  value: 'INSCRIPTION_TYPE_RENUNCIATION',
  label: 'inscription_type_renunciation'
}

export const TRANSFORMATION_TA_MODAL = 'TRANSFORMATION_TA_MODAL'

export const TRANSACTION_LIST_TABLE_INSCRIPTION_TYPES = [
  ...INSCRIPTION_TYPES,
  INSCRIPTION_TYPE_RENUNCIATION
]

export const ALL_INSCRIPTION_TYPES = [
  ...INSCRIPTION_TYPES,
  INSCRIPTION_TYPE_RENUNCIATION,
  INSCRIPTION_TYPE_OFFICE
]

export const STATUS_INSCRIPTION_FINAL = [
  STATUS_INSCRIPTION.PUBLISHED,
  STATUS_INSCRIPTION.REJECTED,
  STATUS_INSCRIPTION.WITHDRAWN
]

export const STATUS_OFFICE_INSCRIPTION_TO_APPLY = [
  ...STATUS_INSCRIPTION_FINAL,
  STATUS_INSCRIPTION.DRAFT,
  STATUS_INSCRIPTION.DRAFT_CLIENT,
  STATUS_INSCRIPTION.WAITING_VIR_PAYMENT,
  STATUS_INSCRIPTION.VIR_PAYMENT_EXPIRED
]

/**
 * Statuts Alex
 */
export const INSCRIPTION_ALEX_STATUS_OK = 'OK'
export const INSCRIPTION_ALEX_STATUS_KO = 'KO'
