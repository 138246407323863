import http from '../../network/http-common'
import { toast } from 'react-toastify'
import axios, { CancelTokenSource } from 'axios'
import { OMPIFees, Payment, Transaction } from '@inpi-marques/components'
import Message from '../../constants/Message'
import { createIntl, IntlShape } from 'react-intl'

class FRMIService {
    source: CancelTokenSource
    intl: IntlShape

    constructor () {
      this.source = axios.CancelToken.source()
      this.intl = createIntl({ locale: 'fr', messages: Message })
    }

    /**
     * Récupère les FRMI qui possède déjà la marque
     * @param numnat
     */
    getRelatedFRMIByNumnat = async (numnat: string): Promise<Transaction[]> => {
      try {
        return await http.get(`/api/frmi/${numnat}/related-frmi`, { cancelToken: this.source.token })
      } catch (error) {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }

    /**
     * Récupère le récapitulatif de paiement d'une FRMI.
     * @param id
     * @returns
     */
    getPaiement = async (id: string): Promise<Payment> => {
      try {
        const result: Payment = await http.get(`/api/frmi/${id}/paiements`)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    /**
     * Récupère les taxes de l'OMPI
     * @param id
     * @returns
     */
    getOMPIFees = async (id: string): Promise<OMPIFees> => {
      try {
        const result: OMPIFees = await http.get(`/api/frmi/${id}/ompi-fees`)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    /**
     * Récupère les FRMI qui peuvent appliquer la règle 22
     * @returns
     * @param id
     * @param titlesIds
     */
    getFRMIForRule22 = async (id: string, titlesIds?: string[]): Promise<Transaction[]> => {
      try {
        const result: Transaction[] = await http.get(`/api/frmi/${id}/frmi-rule-22`, { params: { titlesIds: titlesIds } })
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    }
}

export default new FRMIService()
