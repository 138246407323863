import React, { FC } from 'react'
import {
  FRMI,
  ProductAndServiceListView,
  ProductClassVersion
} from '@inpi-marques/components'
import ProductsService from '@inpi-marques/components/src/services/Product/ProductsService'

interface FeesAndTaxesOverviewProps {
    frmi: FRMI,
}

const ProducsAndServicesOverview: FC<FeesAndTaxesOverviewProps> = ({ frmi }) => {
  const currentVersion: ProductClassVersion|undefined = ProductsService.getCurrentVersion(frmi.productsAndServicesVersions)

  return (
    <>
      {currentVersion &&
        <ProductAndServiceListView
          readOnly
          productClasses={ProductsService.clearProductClassVersion(currentVersion).productClasses}
          className='mt-5'
        />}
    </>
  )
}

export default ProducsAndServicesOverview
