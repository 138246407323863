import React, { FC, useEffect, useState } from 'react'
import {
  EventType,
  PROCEDURE_INSCRIPTION,
  PRODUCT_STATUS,
  ProductAndService,
  ProductClass,
  SubmitButton,
  TextArea
} from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faMinusCircle,
  faPencilAlt,
  faPlusCircle,
  faTimes,
  faTrash,
  faUndo
} from '@fortawesome/free-solid-svg-icons'
import ProductsService from '../../src/services/Product/ProductsService'
import Badge from '../block/Badge'

interface ProductAndServiceListRowProps {
  productClass: ProductClass,
  productClassIndex: number,
  readOnly?: boolean,
  onDeleteClassClick: (productClass: ProductClass) => void,
  onUndoDeleteClassClick: (productClass: ProductClass) => void,
  onUndoDeleteProductClick?: (productClass: ProductClass, product: ProductAndService) => void,
  onEditValidated?: (productClass: ProductClass, products: string, index?: number) => Promise<void>,
  onProductDelete?: (productClass: ProductClass, product: ProductAndService) => void,
  setProductClassEditing?: (editing: boolean) => void,
  displayClassValidation?: boolean,
  procedureType?: string,
  displayOrigin?: boolean
}

/**
 * Ligne du listing de produits et service.
 * Une ligne représente une classe, qu'il est possible de modifier
 */
const ProductAndServiceListRow: FC<ProductAndServiceListRowProps> = ({
  productClass,
  productClassIndex,
  readOnly = false,
  displayClassValidation = false,
  onDeleteClassClick,
  onUndoDeleteClassClick,
  onUndoDeleteProductClick,
  onEditValidated,
  onProductDelete,
  setProductClassEditing,
  procedureType,
  displayOrigin = false
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const [editedProducts, setEditedProducts] = useState<string>(ProductsService.getStringProductValue(productClass.products))

  /**
   * Si on passe en readonly et que le formulaire est en cours d'édition, on coupe le mode édition
   */
  useEffect(() => {
    if (readOnly && isEditing) {
      setIsEditing(false)
    }
  }, [readOnly])

  /**
   * Lors de la mise à jour de la classe
   */
  useEffect(() => {
    setEditedProducts(ProductsService.getStringProductValue(productClass.products))
  }, [productClass])

  /**
   * Au clique sur l'annulation de l'édition des produits
   */
  const onEditCanceled = (): void => {
    setIsEditing(false)
    setProductClassEditing && setProductClassEditing(false)
    setEditedProducts(ProductsService.getStringProductValue(productClass.products))
  }

  /**
   * Au clique sur la validation d'édition des produits
   */
  const onEditValidateClick = async (): Promise<void> => {
    onEditValidated && await onEditValidated(productClass, editedProducts, productClassIndex)
    setIsEditing(false)
    setProductClassEditing && setProductClassEditing(false)
  }

  return (
    <tr
      className={readOnly && productClass.products.some((product: ProductAndService) => product.status) && procedureType !== PROCEDURE_INSCRIPTION.value ? 'products-list__row--edited' : ''}
      key={`-${productClass.ref}`}
    >
      {/* BOUTONS D'ACTIONS */}
      {!readOnly &&
        <td className='border-0 w-15'>
          {productClass?.products?.some(p => p.status === 'DELETED') &&
            (
              <SubmitButton
                className='btn btn-icon-gris mr-3'
                onClick={() => onUndoDeleteClassClick(productClass)}
              >
                <FontAwesomeIcon icon={faUndo} />
              </SubmitButton>
            )}
          <SubmitButton
            className='btn btn-icon-secondary mr-3'
            onClick={() => onDeleteClassClick(productClass)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </SubmitButton>
          {!isEditing && onEditValidated &&
            <SubmitButton
              className='btn btn-icon-primary'
              onClick={() => { setIsEditing(true); setProductClassEditing && setProductClassEditing(true) }}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </SubmitButton>}
          {isEditing &&
            <div className='d-inline-block'>
              <SubmitButton
                className='btn btn-icon-primary'
                onClick={onEditValidateClick}
                replaceOnLoad
              >
                <FontAwesomeIcon icon={faCheck} />
              </SubmitButton>
              <SubmitButton
                className='btn btn-icon-secondary ml-3'
                onClick={() => onEditCanceled()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </SubmitButton>
            </div>}
        </td>}
      {displayOrigin &&
        <td className='border-0 w-10'>
          {productClass.origin ?? '-'}
        </td>}
      {/* NOM DE LA CLASSE */}
      <td className='border-0 font-weight-bold w-25'>
        {/* On wrap le texte s'il s'agit d'une ancienne classe (= liste des numéros de classes séparées par une ,) */}
        <Badge className={`${productClass.ref.indexOf(',') > -1 ? 'text-wrap ' : ''}${displayClassValidation ? `product-class ${ProductsService.isValidClass(productClass) ? 'valid' : 'invalid'}` : ''}`}>{productClass.ref}</Badge>
      </td>
      {/* PRODUITS DE LA CLASSE */}
      <td className='border-0'>
        {!isEditing ? productClass.products.map((product: ProductAndService, key: number) => product.status === PRODUCT_STATUS.DELETED ? (
          <span key={key} className={`text-secondary ${!readOnly ? 'text-hover-bold' : ''}`}>
            {product.name}
            <SubmitButton
              disabled={readOnly}
              className={`btn p-0  text-secondary m-0  mw-unset ${readOnly ? 'cursor-default' : ''}`}
              onClick={() => onUndoDeleteProductClick && onUndoDeleteProductClick(productClass, product)}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </SubmitButton>
          </span>)
          : (
            <span className={`product-name--deletable${product.status === PRODUCT_STATUS.ADDED ? ' text-primary' : ''} pr-1 pt-1 pb-1 ${!readOnly ? 'text-hover-bold' : ''}`} key={key}>
              <span>
                {product.name}
              </span>
              {product.status === PRODUCT_STATUS.ADDED && <FontAwesomeIcon className='text-primary' icon={faPlusCircle} />}

              {!readOnly &&
                <button
                  className='btn btn-icon-secondary ml-1'
                  onClick={() => { onProductDelete && onProductDelete(productClass, product) }}
                >
                    X
                </button>}
              {product.separator ? product.separator : <>&nbsp;;&nbsp;</>}
            </span>)
        )
          : (
            <TextArea
              inputId='products'
              value={editedProducts}
              onChange={(event: EventType) => setEditedProducts(event.target.value)}
            />)}
      </td>
    </tr>
  )
}

export default ProductAndServiceListRow
