import {
  containsErrors,
  DOCUMENT_TYPES, FieldStatus, PROCEDURE_NULLITY, PROCEDURE_REVOCATION,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { DOCUMENT_LIMITS } from '@inpi-marques/components/src/constants/DocumentConstants'
import TransactionService from '../../../../../services/transaction/TransactionService'
import Message from '../../../../../constants/Message'
import store from '../../../../../store/store'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import DocumentService from 'services/document/DocumentService'

class StatementOfMeansValidator {
  isTransactionDocumentSizeValid = (document: TransactionDocument):boolean => {
    return !!(document.file && document.file.size < DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE)
  }

  getValidatedDocument = (transaction: Transaction, documents: TransactionDocument[], procedureType: string) => {
    const fieldStatus: FieldStatus = {}
    const document: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.OPPOSITION_STATEMENT_MEANS)

    if (!DocumentService.isStatementOfMeanRequired(transaction) || document?.internalName) {
      return true
    }

    if ((procedureType === PROCEDURE_NULLITY.value || procedureType === PROCEDURE_REVOCATION.value) && !document) {
      fieldStatus.document = Message.deposit_type_file_missing
    }
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

    return !containsErrors(fieldStatus)
  }
}

export default new StatementOfMeansValidator()
