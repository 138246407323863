import store from '../../../../../store/store'
import {
  BRAND_TYPE_WORD,
  containsErrors,
  FieldStatus,
  isFilled,
  messageIsFilled,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION_STATEMENT,
  PRODUCT_STATUS,
  ProductAndService,
  ProductClass,
  ProductService,
  Title,
  Transaction
} from '@inpi-marques/components'
import Message from '../../../../../constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'

class TitlesValidator {
    getValidatedFieldStatus = (transaction: Transaction): FieldStatus => {
      const titlesFieldStatus: FieldStatus = {}

      /** Au moins un titre sélectionné */
      let titles: Title[] | undefined = []
      switch (transaction?.procedureType) {
        case PROCEDURE_INSCRIPTION.value:
          titles = transaction.inscription?.titles
          break
        case PROCEDURE_FRMI.value:
          titles = transaction.frmiDetails?.titles
          break
        case PROCEDURE_RENEWAL.value:
          titles = transaction.renewalDetails?.titles
          break
        case PROCEDURE_REVOCATION_STATEMENT.value:
          titles = transaction.revocationStatement?.titles
          break
        default:
          break
      }

      if (!isFilled(titles)) {
        titlesFieldStatus.titles = Message.inscription_title_required
      }

      return titlesFieldStatus
    }

    validateOnChangeStep = (transaction: Transaction): boolean => {
      const newFieldStatus: FieldStatus = this.getValidatedFieldStatus(transaction)
      store.dispatch(storeTransactionFieldStatusUpdate(newFieldStatus))
      return !containsErrors(newFieldStatus)
    }

    /**
     * Valide les informations d'un titre d'une inscription
     *
     * @param title Le titre à valider
     * @param procedureType
     * @return Un objet FieldStatus contenant le résultat de la validation de chaque champ
     */
    validateTitle = (title: Title = {}, procedureType?: string) => {
      const currentVersion = ProductService.getCurrentVersion(title.productsAndServicesVersions)
      switch (procedureType) {
        case PROCEDURE_INSCRIPTION.value:
          return {
            titleOrigin: title.numNat ? messageIsFilled(title.origin) : '',
            ...this.validateCommonTitle(title)
          }
        case PROCEDURE_RENEWAL.value:
          return {
            products: !currentVersion || !currentVersion?.productClasses || currentVersion?.productClasses.length === 0 ? Message.renewal_no_products_and_services_required : !currentVersion?.productClasses?.some((productClass: ProductClass) => productClass.products.some((product: ProductAndService) => product.status !== PRODUCT_STATUS.DELETED)) ? Message.renewal_edit_products_and_services_required : '',
            ...this.validateCommonTitle(title)
          }
        default:
          return { ...this.validateCommonTitle(title) }
      }
    }

    validateCommonTitle = (title: Title = {}) => ({
      titleBrandType: title.numNat ? messageIsFilled(title.type) : '',
      titleBrandText: title.numNat && title.type === BRAND_TYPE_WORD.value ? messageIsFilled(title.text) : '',
      titleNumNat: messageIsFilled(title.numNat),
      titleDepositDate: title.numNat ? messageIsFilled(title.depositDate) : ''
    })
}

export default new TitlesValidator()
