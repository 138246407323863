import React, { FC, useEffect, useState } from 'react'
import {
  CheckboxField,
  Country,
  EditorialBlock,
  ErrorField,
  EventType,
  FieldStatus, FRMI_TYPE_EXTENSION, FRMI_TYPE_HOLDER_RIGHT_RESTRICTION,
  FRMI_TYPE_LICENCE_INSCRIPTION, FRMI_TYPE_POST_DESIGNATION_REGISTER, FRMI_TYPE_PRODUCTS_LIMIT, FRMI_TYPE_RENEW,
  ModalComponent,
  SubmitButton,
  TextArea,
  TextField,
  Title,
  Transaction, TransactionService
} from '@inpi-marques/components'
import { FormattedMessage, useIntl } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { storeTransactionUpdateFRMI } from '../../../../store/transaction/transactionActions'
import { useDispatch } from 'react-redux'
import ContentService from '../../../../services/content/ContentService'
import CountryService from 'services/country/CountryService'

interface FRMICountriesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    countriesFromTitle?: boolean // récupération des pays des titres
}

const FRMICountries: FC<FRMICountriesProps> = ({
  transaction, fieldStatus, countriesFromTitle = false
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [userInput, setUserInput] = useState<string | undefined>(undefined)
  const [modalsInformations, setModalsInformations] = useState<Country[]>([])
  const [bottomNotes, setBottomNotes] = useState<string>('')
  const [countries, setCountries] = useState<Country[]>()
  const [filteredCountryList, setFilteredCountryList] = useState<Country[][]>([])
  const [selectedCountries, setSelectedCountries] = useState<Country[]>(transaction.frmiDetails?.countries ?? [])
  const [regionalLimit, setRegionalLimit] = useState<string>(transaction.frmiDetails?.regionalLimit ?? '')

  const sliceInColumns = (data: Country[]) => {
    const result = []
    const chunkSize = Math.ceil(data.length / 4)
    for (let i = 0; i < data.length; i += chunkSize) {
      result.push(data.slice(i, i + chunkSize))
    }
    return result
  }

  useEffect(() => {
    // Récupère la liste de tous les pays OMPI
    ContentService.getCountries().then((response: Country[]) => {
      const madridCountries: Country[] = response.filter(country => country.isMadrid)

      /**
             * Pour certaines opérations postérieures, il ne faut proposer que les pays concernés par les titres
             */
      if (countriesFromTitle && transaction.frmiDetails?.titles?.length) {
        let countryCodes: string[] = []
        transaction.frmiDetails.titles.forEach((title: Title) => {
          if (title.countryCodes?.length) {
            countryCodes = [...new Set([...countryCodes, ...title.countryCodes])]
          }
        })
        const filteredCountries: Country[] = madridCountries.filter((madridCountry: Country) => countryCodes.some((countryCode: string) => countryCode === madridCountry.code))
        if (filteredCountries.length) {
          setCountries(filteredCountries)
          setFilteredCountryList(sliceInColumns(CountryService.getSortedCountry(filteredCountries)))
          return
        }
      }
      setCountries(madridCountries)
      setFilteredCountryList(sliceInColumns(CountryService.getSortedCountry(madridCountries)))
    })

    ContentService.getEditorialBlockByCode('FRMI-PAYS').then((res: EditorialBlock) => {
      if (res.content) {
        setBottomNotes(res.content)
      }
    })
  }, [])

  /**
     * Filtre les pays à chaque nouvelle entrée dans la barre de recherche
     */
  useEffect(() => {
    if (countries) {
      countryFilter()
    }
  }, [userInput])

  /**
     * Fonction qui permet de mettre à jour la liste sélectionné dans la transaction et donc de jouer sur l'affichage des checkbox et d'afficher
     * la modal d'information complémentaire si le pays contient des informations complémentaires
     * @param country
     */
  const onSelect = (country: Country): void => {
    let updatedCountries: Country[] = []

    if (!selectedCountries.find(c => c.code === country.code)) {
      updatedCountries = [...selectedCountries, country]

      if (transaction.subProcedureType && [FRMI_TYPE_EXTENSION.value, FRMI_TYPE_POST_DESIGNATION_REGISTER.value].includes(transaction.subProcedureType) && country.informations) {
        setModalsInformations([...modalsInformations, country])
      }
    } else {
      updatedCountries = selectedCountries.filter((countryInArray: Country) => countryInArray.code !== country.code)
    }

    dispatch(storeTransactionUpdateFRMI({ ...transaction.frmiDetails, countries: updatedCountries }))
  }

  /**
     * Fonction qui filtre les pays en fonction de la recherche transforme les données en lowercase pour que la recherche ne soit pas sensitive
     */
  const countryFilter = () => {
    const userInputLowerCase = userInput?.toLowerCase()
    let filteredCountries = []

    if (userInput === '' || !userInput) {
      filteredCountries = [...countries]
    } else {
      // On filtre notre tableau de pays en fonction du contenu de la barre de recherche
      filteredCountries = countries.filter((country: Country) => {
        return country.name?.toLowerCase().indexOf(userInputLowerCase) !== -1 || country.code?.toLowerCase().indexOf(userInputLowerCase) !== -1
      })
    }

    // On trie le tableau de pays par code pays dans l'ordre ascendant
    setFilteredCountryList(sliceInColumns(filteredCountries.sort((elemA, elemB) => elemA.code > elemB.code ? 1 : -1)))
  }

  /**
     * Au Blur du champs des restrictions territoriales
     */
  const onRegionalLimitBlur = (): void => {
    dispatch(storeTransactionUpdateFRMI({ ...transaction.frmiDetails, regionalLimit }))
  }

  const handleSelectAllCountries = () => {
    if (!countries?.length) {
      return
    }

    const updatedCountries = selectedCountries.length !== countries?.length ? countries : []

    if (transaction.subProcedureType && [FRMI_TYPE_EXTENSION.value, FRMI_TYPE_POST_DESIGNATION_REGISTER.value].includes(transaction.subProcedureType)) {
      const modalInformations: Country[] = updatedCountries.filter((country: Country) =>
        !!country.informations && (!transaction.frmiDetails?.countries || !transaction.frmiDetails?.countries?.some((selectedCountry: Country) => selectedCountry.code === country.code))
      )
      setModalsInformations(modalInformations)
    }

    dispatch(storeTransactionUpdateFRMI({ ...transaction.frmiDetails, countries: updatedCountries }))
  }

  useEffect(() => {
    setSelectedCountries(transaction.frmiDetails?.countries ?? [])
  }, [transaction.frmiDetails?.countries])

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='select_frmi_country_title' /></h1>
          <span className='subtitle'><FormattedMessage id={`frmi_${TransactionService.isFrmiOP(transaction) ? 'op_' : ''}country_description`} /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row mx-0 mb-5'>
        <TextField
          inputId='searchCountry'
          placeholder={intl.formatMessage({ id: 'frmi_country_search_bar_placeholder' })}
          value={userInput}
          onChange={(event) => setUserInput(event.target.value)}
          classNameFormGroup='col-3 p-0'
        />
      </div>

      <span className='font-weight-bold'><FormattedMessage id='frmi_country_text' /></span>
      <div className='d-flex flex-column align-items-start mt-2'>
        {transaction.subProcedureType && [FRMI_TYPE_LICENCE_INSCRIPTION.value, FRMI_TYPE_PRODUCTS_LIMIT.value, FRMI_TYPE_RENEW.value, FRMI_TYPE_HOLDER_RIGHT_RESTRICTION.value].includes(transaction.subProcedureType) && !!filteredCountryList.length && (
          <SubmitButton
            className='btn-link text-primary'
            onClick={handleSelectAllCountries}
          >
            <FormattedMessage
              id={selectedCountries.length !== countries?.length ? 'common_select_all' : 'common_unselect_all'}
            />
          </SubmitButton>
        )}
        <div className='row mt-1 w-100'>
          {filteredCountryList?.map((chunk, i) => (
            <div className='col-3' key={`chunk${i}`}>
              {
                chunk.map((country: Country) => (
                  <div
                    className='d-flex justify-content-between align-items-center country-label mb-2 cursor-pointer'
                    key={country.code}
                    onClick={(e) => {
                      e.preventDefault()
                      onSelect(country)
                    }}
                  >
                    <div
                      title={`${country.code} - ${country.name}`}
                    >{country.code} - {country.name}
                    </div>
                    <CheckboxField
                      inputId={`isSelected${country.id}`}
                      checked={selectedCountries?.some((countrySelected: Country) => countrySelected.code === country.code)}
                      label=''
                      className='country-checkbox'
                      labelClassName='country-checkbox'
                    />
                  </div>
                ))
              }
            </div>
          )
                    )}
        </div>
      </div>

      {bottomNotes &&
        <div className='mt-5' dangerouslySetInnerHTML={{ __html: bottomNotes }} />}
      {transaction.subProcedureType && [FRMI_TYPE_EXTENSION.value, FRMI_TYPE_POST_DESIGNATION_REGISTER.value].includes(transaction.subProcedureType) &&
                !!selectedCountries.length && !!countries?.length &&
                countries?.some((c: Country) => selectedCountries.some((selectedCountry: Country) => selectedCountry.code === c.code && c.informations)) &&
                (
                  <div className='country-info p-3'>
                    {countries.filter((c: Country) => !!c.informations && selectedCountries.some((selectedCountry: Country) => selectedCountry.code === c.code))
                      .map((c: Country, index: number) => (
                        <div className={`${index !== 0 ? 'mt-1' : ''}`} key={c.code}>
                          <span dangerouslySetInnerHTML={{ __html: c.informations as string }} />
                        </div>
                      )
                      )}
                  </div>
                )}
      {transaction.subProcedureType === FRMI_TYPE_LICENCE_INSCRIPTION.value &&
        <TextArea
          inputId='regionalLimit'
          classNameFormGroup='col-6 p-0 mt-2'
          label={<FormattedMessage id='frmi_regional_limit_label' />}
          value={regionalLimit}
          onChange={(event: EventType) => setRegionalLimit(event.target.value)}
          onBlur={() => onRegionalLimitBlur()}
        />}
      {fieldStatus &&
        <ErrorField
          message={fieldStatus.countries}
          className='fade alert alert-danger show position-relative mt-4'
        />}
      {modalsInformations.map((country: Country, index: number) => (
        <ModalComponent
          key={index}
          title={
            <div className='d-flex flex-column'>
              <FormattedMessage id='frmi_title_complementary_information_modal' />
              <h1 className='mt-1 country-name'>{country.name}</h1>
            </div>
          }
          show={index === 0}
          handleClose={() => setModalsInformations(modalsInformations.slice(1, -1))}
          children={
            <div>
              <span dangerouslySetInnerHTML={{ __html: country.informations as string }} />
            </div>
          }
          hideFooter
        />
      ))}
    </>
  )
}

export default FRMICountries
