import React, { Dispatch, FC, ReactElement, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import {
  Country,
  EventType,
  FieldStatus,
  ModalComponent,
  PROCEDURE_MIFR,
  PRODUCT_STATUS,
  ProductClass,
  ProductClassVersion,
  ProductService,
  Record
} from '@inpi-marques/components'
import RecordListTable from '../../../list/RecordListTable'
import Message from '../../../constants/Message'
import TextField from '../TextField'

interface NumNatVerifierProps {
    inputId: string,
    verifyNumNat?: () => Promise<Record[]>,
    maxLength?: number,
    classNameFormGroup?: string,
    className?: string,
    loaderClassName?: string,
    value?: string,
    setValue: (value: string) => void,
    isInternationalBrand?: boolean,
    fieldStatus?: FieldStatus,
    setFieldStatus?: (fieldStatus: FieldStatus) => void,
    labelClassname?: string,
    countries: Country[],
    onRecordSelected?: (record: Record) => void,
    dispatch?: Dispatch<any>,
    label?: ReactElement|string,
    onBlur?: (event: EventType) => void,
    nameFieldStatus?: string,
    disabled?: boolean
}

const NumNatVerifier: FC<NumNatVerifierProps> = ({
  inputId,
  classNameFormGroup,
  labelClassname,
  verifyNumNat,
  className,
  value,
  isInternationalBrand,
  setValue,
  fieldStatus,
  countries,
  onRecordSelected,
  loaderClassName,
  dispatch,
  setFieldStatus,
  label,
  onBlur,
  nameFieldStatus,
  disabled = false
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [records, setRecords] = useState<Record[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedProductsClasses, setSelectedProductClasses] = useState<{rowIndex: number, productClasses: ProductClass[]}[]>([])

  const onLabelClick = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response: Record[] = verifyNumNat ? await verifyNumNat() : []

      if (response.length && response.length === 1) {
        onRecordSelected && onRecordSelected(response[0])
      } else if (response.length) {
        const sortedRecordsVersions = response.map((record: Record) => (
                    {
                      ...record,
                      details: {
                        ...record.details,
                        productsAndServicesVersions: record.details?.productsAndServicesVersions?.map((version: ProductClassVersion) => ({
                          ...version,
                          productClasses: ProductService.filterProductClassesByProductNotStatus(version.productClasses, PRODUCT_STATUS.DELETED)
                        }
                        ))
                      }
                    } as Record
        ))
        setRecords(sortedRecordsVersions)
        setShowModal(true)
      }

      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  const onSelectRecord = (index: number): void => {
    onRecordSelected && onRecordSelected(records[index])
    setShowModal(false)
  }

  /**
     * Permet de lancer la recherche si clic sur touche "Entrée"
     */
  const handleKeyPress = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      await onLabelClick()
    }
  }

  /**
     * Affiche les produits de la marque sélectionnée
     * @param index
     */
  const onSelectProductsClasses = (index: number) => {
    const newSelectedProductClasses: {rowIndex: number, productClasses: ProductClass[]}[] = selectedProductsClasses.some((selected: {rowIndex: number, productClasses: ProductClass[]}) => selected.rowIndex === index)
      ? selectedProductsClasses.filter((selected: {rowIndex: number, productClasses: ProductClass[]}) => selected.rowIndex !== index)
      : [...selectedProductsClasses, {
        rowIndex: index,
        productClasses: ProductService.filterProductClassesByProductNotStatus(ProductService
          .getCurrentVersion(records[index].details?.productsAndServicesVersions)?.productClasses ?? [], PRODUCT_STATUS.DELETED)
      }]

    setSelectedProductClasses(newSelectedProductClasses)
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <TextField
        inputId={inputId}
        classNameFormGroup={classNameFormGroup}
        className={className}
        labelClassname={labelClassname}
        loaderClassName={loaderClassName}
        value={value}
        label={label ?? <FormattedMessage id={`${isInternationalBrand ? 'request_identification_numnat_international' : 'request_identification_numnat'}`} />}
        placeholder={Message.form_number_placeholder}
        onChange={(event: EventType) => setValue(event.target.value?.trim())}
        fieldStatus={fieldStatus}
        nameFieldStatus={nameFieldStatus}
        dispatch={dispatch}
        resetError={setFieldStatus}
        buttonLabel={!disabled ? Message.verify_numnat : undefined}
        onLabelClick={onRecordSelected !== undefined && !disabled ? onLabelClick : undefined}
        isLoading={isLoading}
        required
        prefix={isInternationalBrand ? `${PROCEDURE_MIFR.value}-` : undefined}
        onBlur={onBlur}
        disabled={disabled}
        onKeypress={handleKeyPress}
      />
      <ModalComponent
        size='xl'
        show={showModal}
        handleClose={() => setShowModal(false)}
        title={<FormattedMessage id='modal_data_record_title' />}
        customContent={() =>
          (
            <RecordListTable
              records={records}
              onSelectRecord={onSelectRecord}
              countries={countries}
              selectedProductsClasses={selectedProductsClasses}
              onSelectProductsClasses={onSelectProductsClasses}
            />)}
        hideFooter
      />
    </IntlProvider>)
}

export default NumNatVerifier
