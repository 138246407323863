import { SelectOption } from '../form/FormInterfaces'
import Message from './Message'

export const STATUS_RENEWAL = {
  /** Brouillon client **/
  DRAFT_CLIENT: 'draft_client_renewal',
  /** Création */
  DRAFT: 'draft_renewal',
  /** En attente de mémoire administratif **/
  WAITING_VIR_PAYMENT: 'waiting_vir_payment_renewal',
  /** Mémoire administratif expiré */
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired_renewal',
  /** Demandes à attribuer **/
  TO_ATTRIBUTE: 'to_attribute_renewal',
  /** A examiner **/
  TO_REVIEW: 'to_review_renewal',
  /** Suspendu **/
  SUSPENDED: 'suspended_renewal',
  /** A vérifier **/
  IN_VALIDATION: 'in_validation_renewal',
  /** A refuser **/
  TO_REFUSE: 'to_refuse_renewal',
  /** A rapporter **/
  TO_REPORT: 'to_report_renewal',
  /** Bermudes **/
  BERMUDA: 'bermuda_renewal',
  /** Irrecevable **/
  NOT_ACCEPTED: 'not_accepted_renewal',
  /** A rejeter **/
  TO_REJECT: 'to_reject_renewal',
  /** Rejeté **/
  REJECTED: 'rejected_renewal',
  /** A Retiré **/
  TO_WITHDRAW: 'to_withdraw_renewal',
  /** Retiré **/
  WITHDRAWN: 'withdrawn_renewal',
  /** A publier **/
  TO_PUBLISH: 'to_publish_renewal',
  /** Publication en cours **/
  PUBLISHING: 'publishing_renewal',
  /** Publié **/
  PUBLISHED: 'published_renewal',
  /** Errata **/
  ERRATA: 'errata_renewal'
}

export const STATUS_RENEWAL_REVIEWING = [
  STATUS_RENEWAL.TO_ATTRIBUTE,
  STATUS_RENEWAL.TO_REVIEW,
  STATUS_RENEWAL.IN_VALIDATION,
  STATUS_RENEWAL.SUSPENDED,
  STATUS_RENEWAL.TO_REFUSE,
  STATUS_RENEWAL.TO_REPORT,
  STATUS_RENEWAL.TO_REJECT,
  STATUS_RENEWAL.TO_WITHDRAW,
  STATUS_RENEWAL.PUBLISHING,
  STATUS_RENEWAL.TO_PUBLISH
]

export const STATUS_RENEWAL_FINAL = [
  STATUS_RENEWAL.PUBLISHED,
  STATUS_RENEWAL.REJECTED,
  STATUS_RENEWAL.WITHDRAWN,
  STATUS_RENEWAL.NOT_ACCEPTED
]

export const PROCESS_FAST_TRACK_RENEWAL = 'FAST_TRACK_RENEWAL'

export const RENEWAL_DELAY_LABEL_START_RENEWAL = 'Délai précédant la fin de validité d\'une publication (mois)'
export const RENEWAL_DELAY_LABEL_END_GRACE = 'Délai suivant la fin de validité d\'une publication (mois)'

export const TYPE_DELAY_BEFORE_RENEWAL = 'TYPE_DELAY_BEFORE_RENEWAL'
export const TYPE_DELAY_RENEWAL = 'TYPE_DELAY_RENEWAL'
export const TYPE_DELAY_GRACE = 'TYPE_DELAY_GRACE'
export const TYPE_DELAY_AFTER_GRACE = 'TYPE_DELAY_AFTER_GRACE'

export const FAST_TRACK_YES: SelectOption = {
  label: Message.common_yes,
  value: 'YES'
}

export const FAST_TRACK_NO: SelectOption = {
  label: Message.common_no,
  value: 'NO'
}

export const FAST_TRACK_DENIED: SelectOption = {
  label: Message.common_denied,
  value: 'DENIED'
}

export const FAST_TRACK_OPTIONS: SelectOption[] = [
  FAST_TRACK_YES,
  FAST_TRACK_NO,
  FAST_TRACK_DENIED
]

export const RENEWAL_TYPE_TOTAL : SelectOption = {
  value: 'RENEWAL_TYPE_TOTAL',
  label: Message.renewal_type_total
}

export const RENEWAL_TYPE_PARTIAL : SelectOption = {
  value: 'RENEWAL_TYPE_PARTIAL',
  label: Message.renewal_type_partial
}

export const RENEWAL_TYPES_SELECT_OPTIONS : SelectOption[] = [
  RENEWAL_TYPE_TOTAL,
  RENEWAL_TYPE_PARTIAL
]

// Codes nature pour lesquels le fast track n'est pas proposé
export const FAST_TRACK_NATURE_CODES_TO_EXCLUDE: string[] = ['CD', 'CA', 'CN', 'IM', 'EM', 'TO', 'PT', 'PP', 'AN', 'VP', 'RP', 'DM', 'DV', 'HY', 'NY', 'VA', 'RT', 'HX', 'NX']
