import { STATUS_OPPOSITION, STATUS_OPPOSITION_FINAL, STATUS_OPPOSITION_REVIEWING } from './OppositionConstants'
import { STATUS_DEPOSIT, STATUS_DEPOSIT_FINAL, STATUS_DEPOSIT_REVIEWING } from './DepositConstants'
import { STATUS_DIVISION, STATUS_DIVISION_FINAL, STATUS_DIVISION_REVIEWING } from './DivisionConstants'
import { STATUS_ND, STATUS_ND_FINAL, STATUS_ND_REVIEWING } from './NDConstants'
import { STATUS_INSCRIPTION, STATUS_INSCRIPTION_FINAL, STATUS_INSCRIPTION_REVIEWING } from './InscriptionConstants'
import { STATUS_RENEWAL, STATUS_RENEWAL_FINAL } from './RenewalConstants'
import { STATUS_FRMI, STATUS_FRMI_FINAL } from './FRMIConstants'
import {
  STATUS_OFFICIAL_DOCUMENT,
  STATUS_OFFICIAL_DOCUMENT_FINAL,
  STATUS_OFFICIAL_DOCUMENT_REVIEWING
} from './OfficialDocumentConstants'
import { SelectOption } from 'src'
import {
  STATUS_REVOCATION_STATEMENT,
  STATUS_REVOCATION_STATEMENT_FINAL
} from './revocation-statement/RevocationStatement'
import {
  STATUS_MIFR_FINAL
} from './MIFRConstants'
import Message from './Message'

export const STATUS_DRAFT : string[] = [
  STATUS_DEPOSIT.DRAFT,
  STATUS_OPPOSITION.DRAFT,
  STATUS_ND.DRAFT,
  STATUS_DIVISION.DRAFT,
  STATUS_INSCRIPTION.DRAFT,
  STATUS_INSCRIPTION.DRAFT_CLIENT,
  STATUS_FRMI.DRAFT_CLIENT,
  STATUS_OFFICIAL_DOCUMENT.DRAFT_CLIENT,
  STATUS_OFFICIAL_DOCUMENT.DRAFT,
  STATUS_RENEWAL.DRAFT,
  STATUS_RENEWAL.DRAFT_CLIENT,
  STATUS_REVOCATION_STATEMENT.DRAFT_CLIENT
]

export const STATUS_REVIEWING : string[] = [
  ...STATUS_DEPOSIT_REVIEWING,
  ...STATUS_OPPOSITION_REVIEWING,
  ...STATUS_ND_REVIEWING,
  ...STATUS_DIVISION_REVIEWING,
  ...STATUS_INSCRIPTION_REVIEWING,
  ...STATUS_OFFICIAL_DOCUMENT_REVIEWING
]

export const STATUS_PUBLISHED : string[] = [
  STATUS_DEPOSIT.REGISTERED,
  STATUS_OPPOSITION.DONE,
  STATUS_ND.DONE,
  STATUS_DIVISION.PUBLISHED,
  STATUS_INSCRIPTION.PUBLISHED
]

export const STATUS_REJECTED: string[] = [
  STATUS_DEPOSIT.REJECTED,
  STATUS_DEPOSIT.NOT_ACCEPTED,
  STATUS_OPPOSITION.NOT_ACCEPTED,
  STATUS_ND.NOT_ACCEPTED,
  STATUS_DIVISION.REJECTED,
  STATUS_DIVISION.WITHDRAWN,
  STATUS_INSCRIPTION.REJECTED,
  STATUS_INSCRIPTION.WITHDRAWN
]

export const STATUS_FINAL: string[] = [
  ...STATUS_DEPOSIT_FINAL,
  ...STATUS_DIVISION_FINAL,
  ...STATUS_ND_FINAL,
  ...STATUS_OPPOSITION_FINAL,
  ...STATUS_MIFR_FINAL,
  ...STATUS_INSCRIPTION_FINAL,
  ...STATUS_FRMI_FINAL,
  ...STATUS_OFFICIAL_DOCUMENT_FINAL,
  ...STATUS_REVOCATION_STATEMENT_FINAL,
  ...STATUS_RENEWAL_FINAL
]

export const STATUS_FINAL_OPTIONS: SelectOption[] = [
  {
    value: 'FINAL',
    label: Message.basket_transaction_filter_final_status
  },
  {
    value: 'NOT_FINAL',
    label: Message.basket_transaction_filter_not_final_status
  }
]

const OPPOSITION_EXCHANGE_STAGE_RD: SelectOption = {
  value: 'RD / RTMC',
  label: 'RD / RTMC'
}

const OPPOSITION_EXCHANGE_STAGE_RO: SelectOption = {
  value: 'RO / RDEM',
  label: 'RO / RDEM'
}

const OPPOSITION_EXCHANGE_STAGE_RO2: SelectOption = {
  value: 'RDO2 / RDEM2',
  label: 'RO2 / RDEM2'
}

const OPPOSITION_EXCHANGE_STAGE_RD2: SelectOption = {
  value: 'RD2 / RTMC2',
  label: 'RD2 / RTMC2'
}

const OPPOSITION_EXCHANGE_STAGE_RD3: SelectOption = {
  value: 'RD3 / RTMC3',
  label: 'RD3 / RTMC3'
}

export const OPPOSITION_EXCHANGE_STAGES : SelectOption[] = [
  OPPOSITION_EXCHANGE_STAGE_RD,
  OPPOSITION_EXCHANGE_STAGE_RO,
  OPPOSITION_EXCHANGE_STAGE_RD2,
  OPPOSITION_EXCHANGE_STAGE_RO2,
  OPPOSITION_EXCHANGE_STAGE_RD3
]

const ND_EXCHANGE_STAGE_RTMC: SelectOption = {
  value: 'RTMC',
  label: 'RTMC'
}

const ND_EXCHANGE_STAGE_RDEM: SelectOption = {
  value: 'RDEM',
  label: 'RDEM'
}

const ND_EXCHANGE_STAGE_RTMC2: SelectOption = {
  value: 'RTMC2',
  label: 'RTMC2'
}

const ND_EXCHANGE_STAGE_RDEM2: SelectOption = {
  value: 'RDEM2',
  label: 'RDEM2'
}

const ND_EXCHANGE_STAGE_RTMC3: SelectOption = {
  value: 'RTMC3',
  label: 'RTMC3'
}

export const ND_EXCHANGE_STAGES: SelectOption[] = [
  ND_EXCHANGE_STAGE_RTMC,
  ND_EXCHANGE_STAGE_RDEM,
  ND_EXCHANGE_STAGE_RTMC2,
  ND_EXCHANGE_STAGE_RDEM2,
  ND_EXCHANGE_STAGE_RTMC3
]

// champs d'une transaction
export const FIELD_NUMNAT = 'numNat'
export const FIELD_INTERNAL_REFERENCE = 'internalReference'
export const FIELD_PROCEDURE_TYPE = 'procedureType'
export const FIELD_BRAND_MODEL = 'brandModel'
export const FIELD_ARRIVAL_DATE = 'arrivalDate'
export const FIELD_EXPIRY_DATE = 'expiryDate'
export const FIELD_EXAMINATOR = 'attributed.examinator.lastname'
export const FIELD_LAST_COMMENT = 'comments.last'
export const FIELD_COMMENT_FOND = 'comments.fond'
export const FIELD_LAST_NOTIF = 'notifications.last'
export const FIELD_FOUNDATIONS = 'opposition.foundations'
export const FIELD_MOTIVES = 'opposition.motives'
export const FIELD_CLASS = 'deposit.productsAndServicesVersions.productClasses.ref'
export const FIELD_EXAM = 'exams.examGroups'
export const FIELD_LAST_UPDATE = 'lastUpdate'
export const FIELD_STATUS = 'status'
export const FIELD_SUB_PROCEDURE_TYPE = 'subProcedureType'
export const FIELD_BRAND_TYPE = 'deposit.brand.type'
export const FIELD_READ = 'isRead'
export const FIELD_BRAND_NUMNAT = 'brand.numnat'
export const FIELD_OPPOSITION_END_DATE = 'oppositionEndDate'
export const FIELD_SUSPENSION_TYPE = 'opposition.suspensions.type'
export const FIELD_SAME_MA = ''
export const FIELD_INSCRIPTION_TYPE = ''
export const FIELD_AUDITION = 'opposition.audition'
export const FIELD_RESPONSE_DELAY = 'opposition.delay'
export const FIELD_STATE_OF_EXCHANGE = ''
export const FIELD_PU = ''
export const FIELD_NOTIFICATIONS_SENDING_DATE = 'notifications.sendingDate'
export const FIELD_NOTIFICATIONS_DEADLINE = 'notifications.deadlineDateTime'
export const FIELD_DEPOSITOR_SEARCHABLE_ENTITY = 'depositors.searchableIdentity'
export const FIELD_DEPOSIT_DATE = 'depositDate'

// Bouton pour supprimer une transaction
export const BUTTON_DELETE = 'toDelete'

// Bouton pour vérifier l'ensemble des titres d'une transaction
export const BUTTON_VALIDATION_RECORD = 'validationRecord'

// Bouton pour valider l'ensemble des titres d'une transaction
export const BUTTON_VALIDATION_TITLE = 'validationTitle'
