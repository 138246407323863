import { Contributor, CONTRIBUTOR_OLD_LICENSEES, Country, FRMI_TYPE_LICENCE_EDITION } from '@inpi-marques/components'
import ContributorListOverview from 'component/transactions/overview/ContributorListOverview'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface FrmiOtherApplicantsOverviewProps {
    contributors: Contributor[],
    codes: string[],
    countries: Country[],
    fromFrmiExtension?: boolean,
    subProcedureType?: string
}

/**
 * Affichage des intervenants contenu dans otherApplicant pour une FRMI.
 * On affiche alors:
 *    - Les titulaires actuels
 *    - Les mandataires actuels
 *    - Le preneur de licence
 *    - Les preneurs de licence actuels
 */
const FrmiOtherApplicantsOverview: FC<FrmiOtherApplicantsOverviewProps> = ({ contributors, codes, countries, fromFrmiExtension, subProcedureType }) =>
  <>
    {codes.map((code: string) => {
      const contributorsByCode: Contributor[] = contributors.filter((contributor: Contributor) => contributor.code === code)
      if (contributorsByCode.length) {
        const duplicatedContributors: Contributor[] = contributorsByCode.filter((contributor: Contributor) => contributor.isOriginal === false && contributor.state === 'EDITED')
        const addedContributors: Contributor[] = contributorsByCode.filter((contributor: Contributor) => contributor.isOriginal === false && contributor.state === 'ADDED')
        let defaultContributors: Contributor[] = contributorsByCode.filter((contributor: Contributor) => contributor.state !== 'EDITED' && contributor.state !== 'ADDED')
        // Dans le cas d'une MM14, on affiche seulement le preneur de licence d'origine de la modification
        if (subProcedureType === FRMI_TYPE_LICENCE_EDITION.value && code === CONTRIBUTOR_OLD_LICENSEES.value) {
          const updatedContributor = duplicatedContributors?.[0]
          if (updatedContributor) {
            defaultContributors = defaultContributors.filter((contributor: Contributor) => contributor.id === updatedContributor.id)
          } else {
            defaultContributors = []
          }
        }
        return (
          <>
            {defaultContributors.length > 0 &&
              <div className='mb-3' key={`${code}1`}>
                <h4 className='mb-2 font-weight-bold'>
                  <FormattedMessage id={`contributor_${code}${code === CONTRIBUTOR_OLD_LICENSEES.value && subProcedureType === FRMI_TYPE_LICENCE_EDITION.value ? '_' + subProcedureType.toLowerCase() : ''}`} />
                </h4>
                <ContributorListOverview contributors={defaultContributors} countries={countries} fromFrmiExtension={fromFrmiExtension} />
              </div>}
            {duplicatedContributors.length > 0 &&
              <div className='mb-3' key={`${code}2`}>
                <h4 className='mb-2 font-weight-bold'>
                  <FormattedMessage id={`contributor_${code}${CONTRIBUTOR_OLD_LICENSEES.value && subProcedureType === FRMI_TYPE_LICENCE_EDITION.value ? '_' + subProcedureType.toLowerCase() : ''}`} /> - <FormattedMessage id='original_contributor_label' />
                </h4>
                <ContributorListOverview contributors={duplicatedContributors} countries={countries} fromFrmiExtension={fromFrmiExtension} />
              </div>}
            {addedContributors.length > 0 &&
              <div className='mb-3' key={`${code}3`}>
                <h4 className='mb-2 font-weight-bold'>
                  <FormattedMessage id={`contributor_${code}${CONTRIBUTOR_OLD_LICENSEES.value && subProcedureType === FRMI_TYPE_LICENCE_EDITION.value ? '_' + subProcedureType.toLowerCase() : ''}`} /> - <FormattedMessage id={`${CONTRIBUTOR_OLD_LICENSEES.value && subProcedureType === FRMI_TYPE_LICENCE_EDITION.value ? 'original' : 'duplicated'}_contributor_label`} />
                </h4>
                <ContributorListOverview contributors={addedContributors} countries={countries} fromFrmiExtension={fromFrmiExtension} />
              </div>}
          </>)
      }
    }
    )}
  </>

export default FrmiOtherApplicantsOverview
