import Message from '../../../constants/Message'
import { ETSAccountRequest } from '../../../interfaces/ETSAccountInterface'
import { FieldStatus, isFilled } from '@inpi-marques/components'
/* global localStorage */

class ETSAccountTMCValidator {
  /**
   * Valide le formulaire de demande de rattachement TMC
   * @param etsAccountRequest
   */
  validateForm = (etsAccountRequest: ETSAccountRequest) => {
    const fieldStatus: FieldStatus = {}
    const askConsent = localStorage.getItem('ELECTRONIC_NOTIF') === 'true'

    fieldStatus.numNat = !isFilled(etsAccountRequest.numNat) ? Message.required_field : ''
    fieldStatus.contestedRequestNumNat = !isFilled(etsAccountRequest.contestedRequestNumNat) ? Message.required_field : ''
    fieldStatus.manageableQuality = !etsAccountRequest.manageableQuality ? Message.required_field : ''
    fieldStatus.consentNotificationByEmail = (!askConsent || etsAccountRequest.consentNotificationByEmail !== undefined) ? '' : Message.required_field

    return fieldStatus
  }
}

export default new ETSAccountTMCValidator()
