import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateUtils, DocumentType, DOCUMENT_FORMATS, DOCUMENT_STATUS, EventType, FieldStatus, FileBrowserField, FileButton, INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTIONS, SelectField, SubmitButton, TransactionDocument } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

interface DocumentsBlockItemProps {
  index?: number,
  className?: string,
  document: TransactionDocument,
  additionalType: string,
  documentTypes: DocumentType[],
  fieldStatus?: FieldStatus,
  useCommunicability: boolean,
  onDocumentAdded: (document: TransactionDocument) => void,
  onDocumentDeleted?: (document: TransactionDocument)=> void,
  onDocumentEdited: (document: TransactionDocument) => void,
  handleDownload: (document:TransactionDocument) => void
}

const DocumentsBlockItem: FC<DocumentsBlockItemProps> = ({ document, fieldStatus, className, index, useCommunicability, onDocumentAdded, onDocumentDeleted, onDocumentEdited, additionalType, documentTypes, handleDownload }) => {
  const intl = useIntl()

  const [stateDocument, setStateDocument] = useState<TransactionDocument>(document)

  useEffect(() => {
    setStateDocument(document)
  }, [document])

  const onDocumentUploaded = (event: EventType): void => {
    const { value } = event.target

    onDocumentAdded({
      type: '',
      additionalType: additionalType,
      internalName: '',
      name: value.name,
      format: value.type,
      file: value,
      description: stateDocument.description,
      status: DOCUMENT_STATUS.NEW,
      communicability: stateDocument.communicability,
      createdAt: DateUtils.now()
    })
  }

  return (
    <div className={`d-flex flex-wrap col-12 p-0 mt-2 align-items-center ${className}`}>
      <FileBrowserField
        inputId={`document_${additionalType.toLocaleLowerCase()}_${index}_file`}
        className='col-3 m-0 p-0'
        buttonLabel={<FileButton />}
        acceptApplication={DOCUMENT_FORMATS.PDF}
        onChange={onDocumentUploaded}
        maxNumberFile={1}
        value={stateDocument.internalName || stateDocument.file ? [stateDocument] : undefined}
        handleDownload={handleDownload}
        classNameButton='text-primary'
      />
      <SelectField
        inputId={`document_${additionalType.toLocaleLowerCase()}_${index}_type`}
        classNameFormGroup='col-3 m-0'
        options={documentTypes.map((documentType: DocumentType) => ({ value: documentType.code ?? '', label: documentType.name }))}
        placeholder={intl.formatMessage({ id: 'revocation_statement_document_type_placeholder' })}
        value={stateDocument.type}
        onChange={(event: EventType) => stateDocument.internalName || stateDocument.file ? onDocumentEdited({ ...document, type: event.target.value }) : setStateDocument({ ...stateDocument, type: event.target.value })}
        fieldStatus={fieldStatus}
      />
      {useCommunicability &&
        <SelectField
          inputId={`document_${additionalType.toLocaleLowerCase()}_${index}_type`}
          classNameFormGroup='col-3 m-0'
          options={INSCRIPTION_DOCUMENT_COMMUNICABILITY_OPTIONS}
          value={stateDocument.communicability}
          onChange={(event: EventType) => stateDocument.internalName || stateDocument.file ? onDocumentEdited({ ...document, communicability: event.target.value }) : setStateDocument({ ...stateDocument, communicability: event.target.value })}
        />}
      {onDocumentDeleted &&
        <SubmitButton
          className='btn-icon text-secondary'
          onClick={() => onDocumentDeleted(document)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </SubmitButton>}
    </div>
  )
}

export default DocumentsBlockItem
