import { CommonInscriptionService, containsErrors, FieldStatus, Inscription, InscriptionNatureCode, messageIsFilled, NATURE_CODES, TransactionDocument } from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class MandataryFormValidator {
    /**
     * Validation de l'étape du renseignement d'inscription mandataire
     * @param document
     * @param inscription
     * @returns
     */
    validateMandataryForm = (document: TransactionDocument[], inscription?: Inscription): boolean => {
      const selectedNatureCode: InscriptionNatureCode|undefined = CommonInscriptionService.getFirstCodeNature(inscription?.natureCodes ?? [])
      const fieldStatus: FieldStatus = {
        natureCodes: messageIsFilled(inscription?.natureCodes),
        oldAgent: selectedNatureCode?.code === NATURE_CODES.RM || selectedNatureCode?.code === NATURE_CODES.MM ? messageIsFilled(inscription?.mandatary?.oldAgent) : '',
        newAgent: selectedNatureCode?.code === NATURE_CODES.CM || selectedNatureCode?.code === NATURE_CODES.MM ? messageIsFilled(inscription?.mandatary?.newAgent) : ''
      }

      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

      return !containsErrors(fieldStatus)
    }
}

export default new MandataryFormValidator()
