import { Motive } from '@inpi-marques/components'
import http from '../../network/http-common'
import axios, { CancelTokenSource } from 'axios'

class MotiveService {
  source: CancelTokenSource

  constructor () {
    this.source = axios.CancelToken.source()
  }

  /**
   * Récupère tous les motifs en fonction de la procédure si celle-ci est renseignée.
   *
   * @param procedure
   * @returns
   */
  getAll = async (procedure?: string): Promise<Motive[]> => {
    try {
      return await http.get('/api/motives', { params: { procedure }, cancelToken: this.source.token })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  /**
   * Retourne le dernier motif s'il y en a
   * @param motives
   */
  getLastMotive = (motives: Motive[]|string[]|null): Motive|string|null|undefined => {
    return motives ? motives[motives.length - 1] : null
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new MotiveService()
