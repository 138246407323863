import { FC } from 'react'
import { PaymentMethodContentProps } from '@inpi-marques/components'
import DirectDebitOMPIForm from '../component/frmi/form/feesAndTaxes/paymentMethod/DirectDebitOMPIForm'
import PaymentReceivedOMPIForm from '../component/frmi/form/feesAndTaxes/paymentMethod/PaymentReceivedOMPIForm'
import PaymentToOMPIForm from '../component/frmi/form/feesAndTaxes/paymentMethod/PaymentToOMPIForm'
import Message from '@inpi-marques/components/src/constants/Message'

export interface PaymentMethod {
  value: string,
  label: string,
  component: FC<PaymentMethodContentProps>
}

export const DIRECT_DEBIT_ON_OMPI_ACCOUNT: PaymentMethod = {
  value: 'DIRECT_DEBIT_ON_OMPI_ACCOUNT',
  label: Message.direct_debit_on_ompi_account,
  component: DirectDebitOMPIForm
}

export const PAYMENT_RECEIVED_AND_CONFIRMED_BY_OMPI: PaymentMethod = {
  value: 'PAYMENT_RECEIVED_AND_CONFIRMED_BY_OMPI',
  label: Message.payment_received_and_confirmed_by_ompi,
  component: PaymentReceivedOMPIForm
}

export const PAYMENT_TO_OMPI_BANK_ACCOUNT: PaymentMethod = {
  value: 'PAYMENT_TO_OMPI_BANK_ACCOUNT',
  label: Message.payment_to_ompi_bank_account,
  component: PaymentToOMPIForm
}

export const PAYMENT_TO_OMPI_POSTAL_ACCOUNT: PaymentMethod = {
  value: 'PAYMENT_TO_OMPI_POSTAL_ACCOUNT',
  label: Message.payment_to_ompi_postal_account,
  component: PaymentToOMPIForm
}
export const PAYMENT_METHODS_TYPE_LIST: PaymentMethod[] = [
  DIRECT_DEBIT_ON_OMPI_ACCOUNT,
  PAYMENT_RECEIVED_AND_CONFIRMED_BY_OMPI,
  PAYMENT_TO_OMPI_BANK_ACCOUNT,
  PAYMENT_TO_OMPI_POSTAL_ACCOUNT
]
