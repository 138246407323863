import React, { FunctionComponent } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { FormContentProps, SubmitButton, TextField } from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import { useDispatch } from 'react-redux'

interface LoginFormContentProps extends FormContentProps, WrappedComponentProps {
}

const LoginFormContent: FunctionComponent<LoginFormContentProps> = ({
  object, handleChange, fieldStatus, submitForm, intl
}) => {
  const dispatch = useDispatch()
  return (
    <>
      <div className='row'>
        <div className='col'>
          <TextField
            inputId='username'
            label={<FormattedMessage id='login_username_label' />}
            fieldStatus={fieldStatus}
            value={object.username}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'login_username_placeholder' })}
            resetError={storeTransactionFieldStatusUpdate}
            dispatch={dispatch}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <TextField
            type='password'
            inputId='password'
            label={<FormattedMessage id='login_password_label' />}
            placeholder={intl.formatMessage({ id: 'login_password_placeholder' })}
            fieldStatus={fieldStatus}
            value={object.password}
            onChange={handleChange}
            resetError={storeTransactionFieldStatusUpdate}
            dispatch={dispatch}
          />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <div className='row justify-content-md-center'>
            <div className='col-lg-10'>
              <SubmitButton
                className='btn-block btn-secondary'
                onClick={submitForm}
              >
                <FormattedMessage id='login_connexion_button_label' />
              </SubmitButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectIntl(LoginFormContent)
