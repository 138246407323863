import Message from '../../constants/Message'
import moment from 'moment'
import { messageIsFilled, Priority } from '@inpi-marques/components'

class PriorityValidator {
  /**
   * Valide les informations d'une priorité d'un dépôt
   *
   * @param priority La priorité à valider
   * @return Un objet FieldStatus contenant le résultat de la validation de chaque champ
   */
  validatePriority = (priority: Priority = {}) => {
    return {
      countryOrOrganization: messageIsFilled(priority.countryOrOrganization),
      originalDepositNumber: messageIsFilled(priority.originalDepositNumber),
      originalDepositDate: this.validateDepositDate(priority)
    }
  }

  validateDepositDate = (priority: Priority = {}) => {
    if (!priority || !priority.originalDepositDate) {
      return Message.priority_deposit_date_required
    } else if (!moment(priority.originalDepositDate).isValid()) {
      return Message.priority_deposit_date_wrong_format
    } else if (moment(priority.originalDepositDate) > moment()) {
      return Message.priority_deposit_date_too_late
    }
    return ''
  }
}

export default new PriorityValidator()
