import {
  Contributor,
  CONTRIBUTOR_AGENT,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_HOLDERS_CONTESTED_MARK,
  CONTRIBUTOR_OPPONENTS, CONTRIBUTOR_ORGANISERS,
  CONTRIBUTOR_OTHER_APPLICANTS,
  CONTRIBUTOR_RECIPIENT,
  CONTRIBUTOR_SIGNATORY,
  Deposit,
  DEPOSIT_TYPE_DIVISION,
  getDocumentByNameAndType,
  PersonneFieldValidator,
  PROCEDURE_DEPOSIT, PROCEDURE_INSCRIPTION,
  PROCEDURES,
  ProductClassVersion,
  Transaction,
  TransactionCountByStatus,
  TransactionDocument,
  TransactionSearchParameters,
  TransactionSearchResult,
  TRANSACTION_LIST_TABLE_INSCRIPTION_TYPES,
  OfficialDocument,
  FIELD_INTERNAL_REFERENCE
} from '@inpi-marques/components'
import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import { toast } from 'react-toastify'
import DocumentService from 'services/document/DocumentService'
import store from 'store/store'
import { DEPOSIT_TYPES } from '../../constants/DepositConstant'
import { storeTransactionUpdate } from '../../store/transaction/transactionActions'
import axios, { CancelTokenSource } from 'axios'
import { getEditedDocument, getNotEditedDocument } from '@inpi-marques/components/src/document/DocumentService'

class TransactionService {
  intl: IntlShape
  source: CancelTokenSource

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Récupère une transaction à partir de son identifiant
   */
  getTransaction = async (id: string): Promise<Transaction> => {
    try {
      return await http.get(`/api/transactions/${id}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Création d'une Transaction
   * @param transaction
   * @param procedure
   * @param propertyNames
   * @param documents
   * @returns
   */
  createTransaction = async (transaction: Transaction, procedure: string, propertyNames: string[], documents?: TransactionDocument[]|undefined): Promise<Transaction|null> => {
    try {
      const savedTransaction: Transaction = await http.post(`/api/transactions?procedure=${procedure}`)
      const newTransaction: Transaction = { ...transaction, id: savedTransaction.id }
      return await this.updateTransactionBDDFromStore(newTransaction, propertyNames, documents)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_create' }))
    }
    return null
  }

  /**
   * Enregistre les documents d'une transaction
   *
   * @param transaction Transaction présente dans le store
   * @param documents documents du state DepositForm
   * @returns
   */
  updateDocumentTransaction = async (transaction: Transaction, documents: TransactionDocument[]): Promise <Transaction|null> => {
    const updatedDocuments: TransactionDocument[] = []
    try {
      // On supprime les fichiers plus utilisés
      const transactionDocuments: TransactionDocument[] = transaction.documents ?? []
      if (transactionDocuments) {
        for (const transactionDocument of transactionDocuments) {
          // Document existant mais pas modifié
          const existingDocument: TransactionDocument | undefined = getNotEditedDocument(documents, transactionDocument)
          // Document existant mais modifié
          const updatedDocument: TransactionDocument | undefined = getEditedDocument(documents, transactionDocument)

          /** Si le document n'a pas été modifié, on l'ajoute */
          if (existingDocument) {
            updatedDocuments.push(transactionDocument)
          } else if (updatedDocument) {
            /** S'il a été modifié on ajoute le nouveau */
            await DocumentService.updateDocument(transaction.id, updatedDocument.internalName, updatedDocument)
            updatedDocuments.push(updatedDocument)
          } else {
            /** S'il n'est toujours pas trouvé, alors il n'existe plus, donc on le supprime */
            await DocumentService.deleteDocument(transactionDocument.internalName, transaction)
          }
        }
      }

      // On Upload les nouveaux fichiers
      for (const document of documents) {
        if (!transaction.documents || (!getDocumentByNameAndType(transaction.documents, document.name, document.type) && !getEditedDocument(transaction.documents, document))) {
          const updatedDocument: TransactionDocument|null = await DocumentService.createDocument(document, transaction.id)
          if (updatedDocument) {
            updatedDocuments.push(updatedDocument)
          }
        }
      }
      store.dispatch(storeTransactionUpdate({ ...transaction, documents: updatedDocuments }))
      return { ...transaction, documents: updatedDocuments }
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_edit' }))
      throw error
    }
  }

  /**
   * Mise à jour des propriétés d'une transaction correspondant à une étape du formulaire
   * @param transaction
   * @param propertyNames
   * @param documents
   */
  updateTransactionBDDFromStore = async (transaction: Transaction, propertyNames: string[], documents?: TransactionDocument[]): Promise<Transaction|null> => {
    if (documents) {
      await this.updateDocumentTransaction(transaction, documents)
    }

    propertyNames = [...propertyNames, FIELD_INTERNAL_REFERENCE]

    if (transaction?.id) {
      const patches = {}
      propertyNames.forEach((propertyName) => {
        patches[propertyName] = transaction[propertyName]
      })

      try {
        const result = await this.updateTransaction(transaction.id, patches)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      return Promise.reject(new Error(this.intl.formatMessage({ id: 'error_transaction_edit' })))
    }
  }

  /**
   * Appel serveur pour mettre à jour la transaction en bdd
   * @param id id de la transaction
   * @param patches liste des modifications
   * @param onlyApiTransaction
   * @returns Promise<Transaction|null>
   */
  updateTransaction = async (id: string, patches: any, onlyApiTransaction: boolean = false): Promise<Transaction|null> => {
    try {
      const transactions: Transaction = await http.put(`/api/transactions/${id}`,
        patches
      )
      store.dispatch(storeTransactionUpdate(transactions, onlyApiTransaction))
      return Promise.resolve(transactions)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Récupération du fichier récapitulatif d'une transaction
   * @param id
   * @returns
   */
  getOverview = async (id?: string): Promise<ArrayBuffer|null> => {
    try {
      return id ? await http.get(`/api/transactions/${id}/overview`, {
        responseType: 'arraybuffer'
      }) : null
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Récupère une liste de transaction en fonction des paramètres de recherche fournis
   */
  searchTransaction = async (params: TransactionSearchParameters, dontThrowError: boolean = false): Promise<TransactionSearchResult|string> => {
    try {
      return await http.get('/api/transactions', {
        params: params,
        cancelToken: this.source.token
      })
    } catch (error) {
      if (dontThrowError) {
        return Promise.resolve('')
      } else {
        toast.error(error.message)
        return Promise.reject(error)
      }
    }
  }

  /**
   * Récupère une transaction à partir de son numnat
   * @param numnat
   * @param procedureTypes tableau de procédures
   */
  getTransactionByNumnat = async (numnat: string | undefined, procedureTypes?: string[]|null): Promise<Transaction> => {
    try {
      return await http.get('/api/transactions/infos', {
        params: {
          numNat: numnat,
          procedureTypes
        }
      }
      )
    } catch (error) {
      return Promise.resolve()
    }
  }

  /**
   * Récupère le nombre de transactions aggrégé
   */
  getTransactionsCount = async (params: { type: string, procedures?: string[] }): Promise<TransactionCountByStatus[]> => {
    try {
      return await http.get('/api/transactions/counts', { params: params, cancelToken: this.source.token })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Suppression d'une transaction
   * @param id
   * @returns Promise<>
   */
  deleteTransaction = async (id: string): Promise<any> => {
    try {
      return await http.delete(`/api/transactions/${id}`)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Suppression des transactions brouillons d'un utilisateur
   * @returns Promise<>
   * @param params
   */
  deleteDraftTransactionsByProcedures = async (params: { procedures: string[], subProcedureType?: string[] }): Promise<any> => {
    try {
      return await http.delete('/api/transactions/drafts', { params: params, cancelToken: this.source.token })
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Récupération de la timeline d'un dépôt
   * @param transaction
   * @returns
   */
  getTimeline = async (transaction: Transaction) : Promise<string|undefined> => {
    try {
      return await http.get(`/api/transactions/timeline?numNat=${transaction.numNat}&withSearch=1`)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * Récupère la bonne procédure
   * @param transaction
   */
   getProcedureType = (transaction: Transaction) => {
     return PROCEDURES.find(procedure => procedure.value === transaction.procedureType)
   }

  /**
   * Récupère la bonne sous-procédure
   * @param transaction
   */
  getSubProcedureType = (transaction: Transaction) => {
    if (transaction.procedureType === PROCEDURE_DEPOSIT.value && transaction.subProcedureType === DEPOSIT_TYPE_DIVISION.value) {
      return DEPOSIT_TYPE_DIVISION
    } else if (transaction.procedureType === PROCEDURE_INSCRIPTION.value) {
      return TRANSACTION_LIST_TABLE_INSCRIPTION_TYPES.find(subProcedure => subProcedure.value === transaction.subProcedureType)
    }
    return DEPOSIT_TYPES.find(subProcedure => subProcedure.value === transaction.subProcedureType)
  }

  /**
   * Récupération des types de documents définis dans le BO en application
   * @returns Promise<DocumentType[]>
   */
  getDocumentTypes = async (procedureType?: string): Promise<any> => {
    try {
      return await http.get('/api/document-types', {
        params: { procedure: procedureType }
      })
    } catch (error) {
      return Promise.resolve([])
    }
  }

  getOfficialDocument = (transaction: Transaction) : OfficialDocument|undefined => transaction.officialDocument

  getDeposit = (transaction: Transaction): Deposit|undefined => transaction.deposit

  getContributor = (transaction: Transaction, contributorType: string): Contributor|Contributor[]|undefined => {
    if (contributorType === CONTRIBUTOR_RECIPIENT.value) {
      return transaction.recipient
    } else if (contributorType === CONTRIBUTOR_AGENT.value) {
      return transaction.agent
    } else if (contributorType === CONTRIBUTOR_SIGNATORY.value) {
      return transaction.signatory
    } else if (contributorType === CONTRIBUTOR_DEPOSITORS.value) {
      return transaction.depositors
    } else if (contributorType === CONTRIBUTOR_OPPONENTS.value) {
      return transaction.opponents
    } else if (contributorType === CONTRIBUTOR_HOLDERS_CONTESTED_MARK.value) {
      return transaction.holdersContestedMark
    } else if (contributorType === CONTRIBUTOR_OTHER_APPLICANTS.value) {
      return transaction.otherApplicants
    } else if (contributorType === CONTRIBUTOR_ORGANISERS.value) {
      return transaction.organisers
    }
    return undefined
  }

  getDocumentByType = (documents: TransactionDocument[], type: string): TransactionDocument|undefined =>
    documents.find((document: TransactionDocument) => document.type === type)

  /**
   * On met à jour le store complétement, afin qu'on puisse garder la preview dans le fichier d'une marque
   *
   * @param oldTransaction
   * @param newTransaction
   * @returns
   */
  getUpdatedTransaction = (oldTransaction: Transaction, newTransaction?: Transaction) => {
    const updatedTransaction: Transaction = {
      ...oldTransaction,
      ...newTransaction
    }

    /** S'il existait une preview, on la remet dans le nouveau fichier afin de ne pas la re-télécharger */
    if (oldTransaction.deposit?.brand?.file?.previewData && updatedTransaction.deposit?.brand?.file?.previewData) {
      updatedTransaction.deposit.brand.file.previewData = oldTransaction.deposit?.brand?.file.previewData
    }

    return updatedTransaction
  }

  /**
   * Retourne true si l'objet passé en paramètre est de type Contributor
   * @param object
   */
  isContributor = (object: any): object is Contributor => !!object

  /**
   * Récupère tous les intervenants distincts présents dans une transaction.
   *
   * @param transaction
   * @param fields liste des champs contenant les intervenants à récupérer
   */
  findContributors = (transaction: Transaction, fields?: string[]) =>
    Object.entries(transaction)
      .filter(([key]) => !fields || fields.includes(key))
      .map(([, value]) => value)
      .reduce((a, b) => a.concat(b), [])
      .filter(this.isContributor)

  /**
   * Méthode permettant de savoir si on peut accéder à la Mémoire Administratif du bloc de paiement
   * @param contributors
   * @returns
   */
  canContributorAccessToManageableMemory = (contributors: Contributor[]): boolean =>
    contributors.some((contributor: Contributor) => contributor && PersonneFieldValidator.isPersonneMorale(contributor) && contributor.publicLaw)

  /**
   *
   * @param type
   * @param versions
   * @returns
   */
  getProductClassVersionByName = (type: string, versions: ProductClassVersion[]): ProductClassVersion|undefined =>
    versions.sort((firstVersion: ProductClassVersion, secondVersion: ProductClassVersion) =>
      new Date(secondVersion.createdDate).getTime() - new Date(firstVersion.createdDate).getTime()).find((version: ProductClassVersion) => version.type === type)

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }
}

export default new TransactionService()
