import { ProtectionExtension } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface OverviewProtectionExtensionProps {
  protectionExtensions?: ProtectionExtension[]
}

const OverviewProtectionExtension : FC<OverviewProtectionExtensionProps> = (
  {
    protectionExtensions
  }) => {
  return (
    <>
      <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_protection_extensions_title' /></h4>
      {protectionExtensions && protectionExtensions.length > 0
        ? protectionExtensions.map((protectionExtension) => <p key={protectionExtension.id}>{protectionExtension.label}</p>)
        : <div><FormattedMessage id='none_feminine' /></div>}
    </>
  )
}

export default OverviewProtectionExtension
