import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FieldStatus, OfficialDocumentRequested, ROWS_10, Table, TextField } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import Message from '../../../../constants/Message'
import OfficialDocumentService from '../../../../services/officialDocument/OfficialDocumentService'

interface OverviewOfficialDocumentsRequestedProps {
  officialDocumentsRequested?: OfficialDocumentRequested[],
  editOfficialDocumentRequested?: (index: number) => void,
  deleteOfficialDocumentRequested?: (index: number) => void,
  className?: string,
  fieldStatus?: FieldStatus,
  setQuantity: (inde: number, quantity?: number) => void
}

/**
 * Composant contenant la liste de toutes les demandes de types de documents officiels
 * affichée sous forme de tableau
 * Permet également de modifier ou supprimer une ligne du tableau
 *
 * @param officialDocumentsRequested - La liste de toutes les demandes
 * @param editOfficialDocumentRequested - Fonction pour modifier une demande
 * @param deleteOfficialDocumentRequested - Fonction pour supprimer une demande
 * @param className - Chaine contenant les classes à appliquer au style du tableau
 * @param setQuantity - Fonction pour modifier la quantité d'une demande
 * @param fieldStatus - Objet contenant les erreurs du formulaire
 * @constructor
 */
const OverviewOfficialDocumentsRequested: FC<OverviewOfficialDocumentsRequestedProps> = (
  {
    officialDocumentsRequested,
    editOfficialDocumentRequested,
    deleteOfficialDocumentRequested,
    className = '',
    setQuantity,
    fieldStatus
  }) => {
  const getTableTitle = () => {
    let tableTitle: {label: string, className: string}[] = [
      { label: Message.official_documents_requested_column_label_id, className: 'table-column-label pl-2 w-10' },
      { label: Message.official_documents_requested_column_label_type, className: 'table-column-label w-30' },
      { label: Message.official_documents_requested_column_label_numNat, className: 'table-column-label w-30' }
    ]

    // Ajouter colonnes quantités suivant les types d'envoi choisis
    if (officialDocumentsRequested?.some(officialDocument => officialDocument?.usePostalRoute)) {
      tableTitle = [...tableTitle, { label: Message.official_documents_requested_column_label_quantity, className: 'table-column-label w-20' }]
    }
    if (officialDocumentsRequested?.some(officialDocument => officialDocument?.useDAS)) {
      tableTitle = [...tableTitle, { label: Message.official_documents_requested_column_label_quantity_das, className: 'table-column-label w-20' }]
    }
    if (officialDocumentsRequested?.some(officialDocument => officialDocument?.usePdfPortal)) {
      tableTitle = [...tableTitle, { label: Message.official_documents_requested_column_label_quantity_pdf, className: 'table-column-label w-20' }]
    }

    if (editOfficialDocumentRequested || deleteOfficialDocumentRequested) {
      tableTitle = [...tableTitle, { label: '', className: 'table-column-label w-25' }]
    }
    return tableTitle
  }

  /** Page courante du tableau */
  const [page, setPage] = useState<number>(0)

  /** Nombre d'éléments par page du tableau */
  const nbItemsPerPage: number = ROWS_10.value

  const onDelete = (documentIndex: number): void => {
    const newPaginatedOfficialDocumentsRequested: OfficialDocumentRequested[][] = officialDocumentsRequested ? OfficialDocumentService.getPaginatedOfficialDocumentsRequested(officialDocumentsRequested.filter((_, key: number) => key !== documentIndex), nbItemsPerPage) : []

    // Si on supprime le dernier élément d'une page, on passe à la page précédente
    if (newPaginatedOfficialDocumentsRequested.length < (page + 1)) {
      setPage(newPaginatedOfficialDocumentsRequested.length - 1)
    }
    deleteOfficialDocumentRequested && deleteOfficialDocumentRequested(documentIndex)
  }

  /** On récupère un tableau paginé de documents officiels */
  const paginatedOfficialDocumentsRequested: OfficialDocumentRequested[][] = officialDocumentsRequested ? OfficialDocumentService.getPaginatedOfficialDocumentsRequested(officialDocumentsRequested, nbItemsPerPage) : []

  return (
    <>
      {paginatedOfficialDocumentsRequested && paginatedOfficialDocumentsRequested.length > 0
        ? (
          <>
            <Table
              tableTitle={getTableTitle()}
              tableClassName={`title-table ${className}`}
              actualPage={page + 1}
              onChangePagination={(value) => setPage(value - 1)}
              showPagination
              totalPage={paginatedOfficialDocumentsRequested.length}
            >
              <tbody>
                {paginatedOfficialDocumentsRequested[page] &&
                  paginatedOfficialDocumentsRequested[page].map((officialDocumentRequested: OfficialDocumentRequested, index: number) => {
                    const documentIndex : number = (nbItemsPerPage * page) + index
                    return (
                      <tr key={`-${index}`}>
                        <td className='pl-4'>{documentIndex + 1}</td>
                        <td>{officialDocumentRequested.officialDocumentType?.label}</td>
                        <td>{officialDocumentRequested.title?.numNat ?? officialDocumentRequested.copyNum}</td>
                        {officialDocumentsRequested?.some(officialDocument => officialDocument?.usePostalRoute) && officialDocumentRequested?.usePostalRoute &&
                          <td>
                            <TextField
                              fieldStatus={fieldStatus}
                              inputId='quantity'
                              nameFieldStatus={'quantity' + index}
                              type='number'
                              className='d-flex justify-content-start'
                              required
                              value={officialDocumentRequested.quantity}
                              onChange={(event) => setQuantity(index, event.target.value)}
                              step='1'
                              min='1'
                            />
                          </td>}
                        {officialDocumentsRequested?.some(officialDocument => officialDocument?.usePostalRoute) && !officialDocumentRequested?.usePostalRoute && <td>-</td>}
                        {officialDocumentsRequested?.some(officialDocument => officialDocument?.useDAS) && <td>{officialDocumentRequested?.useDAS ? 1 : '-'}</td>}
                        {officialDocumentsRequested?.some(officialDocument => officialDocument?.usePdfPortal) && <td>{officialDocumentRequested?.usePdfPortal ? 1 : '-'}</td>}
                        {(editOfficialDocumentRequested || deleteOfficialDocumentRequested) &&
                          <td className='btn-column pr-4'>
                            {editOfficialDocumentRequested &&
                              <button className='btn btn-link text-primary' onClick={() => editOfficialDocumentRequested(documentIndex)}>
                                <FontAwesomeIcon icon={faEdit} />
                              </button>}
                            {deleteOfficialDocumentRequested &&
                              <button className='btn btn-link text-danger ml-3' onClick={() => onDelete(documentIndex)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>}
                          </td>}
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </>
        )
        : <div><FormattedMessage id='none' /></div>}
    </>
  )
}

export default OverviewOfficialDocumentsRequested
