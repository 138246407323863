import { ORDER_ASC, ORDER_DESC, TableOrderBy } from '..'

/**
 * Détermine un nouveau tri à partir du nom de champ à
 * trier et du tri courant
 *
 * @param fieldname nom du champ à trier
 * @param currentSort Tri courant
 */
export function computeOrder (
  fieldname: string,
  currentSort: TableOrderBy|undefined
)
    : TableOrderBy|undefined {
  if (!currentSort || currentSort.field !== fieldname) {
    return {
      field: fieldname,
      order: ORDER_ASC
    }
  } else if (currentSort.order === ORDER_ASC) {
    return {
      field: fieldname,
      order: ORDER_DESC
    }
  }

  return undefined
}
