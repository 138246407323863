import { CheckboxField, Motive, Transaction } from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import MotiveService from 'services/motives/MotiveService'
import { storeTransactionUpdateOpposition } from 'store/transaction/transactionActions'

interface MotivesAccordionProps {
  transaction: Transaction
}

const MotivesAccordion: FC<MotivesAccordionProps> = ({ transaction }) => {
  const dispatch = useDispatch()
  const [motives, setMotives] = useState<Motive[]>([])

  useEffect(() => {
    MotiveService.getAll(transaction.procedureType).then((response: Motive[]) => {
      setMotives(response)
    })
  }, [])

  const onMotiveClick = (motive: Motive): void => {
    if (transaction.opposition) {
      let updatedMotives: string[] = []
      if (transaction.opposition?.motives.some((code: string) => motive.code === code)) {
        updatedMotives = [...transaction.opposition.motives.filter((code: string) => motive.code !== code)]
      } else {
        updatedMotives = [...transaction.opposition.motives, motive.code]
      }
      dispatch(storeTransactionUpdateOpposition({ ...transaction.opposition, motives: updatedMotives }))
    }
  }

  return (
    <>
      {motives.map((motive: Motive, key: number) =>
        <div key={key}>
          <CheckboxField
            inputId={`motive_${key}`}
            checked={transaction.opposition?.motives.some((code: string) => motive.code === code)}
            label={motive.label}
            onChange={() => onMotiveClick(motive)}
          />
        </div>
      )}
    </>
  )
}

export default MotivesAccordion
