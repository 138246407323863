import {
  Contributor,
  EventType,
  FieldStatus,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import Suite from 'component/suite/Suite'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import DivisionRegisteredContent from './divisionType/DivisionRegisteredContent'

interface DivisionBrandProps {
  onDocumentChanged: (event: EventType, type: string) => void,
  onDocumentDeleted: (event: EventType) => void,
  transaction: Transaction,
  fieldStatus: FieldStatus,
  documents: TransactionDocument[],
  setHolders: (holders: Contributor[]) => void,
  setIsRequired: (value: boolean) => void
}

const DivisionBrand: FC<DivisionBrandProps> = ({
  onDocumentDeleted,
  onDocumentChanged,
  transaction,
  fieldStatus,
  documents,
  setHolders,
  setIsRequired
}) => {
  const [previousTransactionId, setPreviousTransactionId] = useState<string>()

  useEffect(() => {
    setIsRequired(true)
  }, [])

  return (
    <div className='deposit-type'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='select_division_brand_title' /></h1>
          <span className='subtitle'><FormattedMessage id='division_brand_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='justify-content-center col-12 p-0'>
        <DivisionRegisteredContent
          transaction={transaction}
          className='md-6 mb-3 p-0'
          fieldStatus={fieldStatus}
          onDocumentChanged={onDocumentChanged}
          onDocumentDeleted={onDocumentDeleted}
          documents={documents}
          setHolders={setHolders}
          previousTransactionId={previousTransactionId}
        />
      </div>
      <Suite
        transaction={transaction}
        fieldStatus={fieldStatus}
        setPreviousTransactionId={setPreviousTransactionId}
      />
    </div>
  )
}

export default DivisionBrand
