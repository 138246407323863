import {
  DateUtils,
  INSCRIPTION_TYPES,
  INSCRIPTION_RENUNCIATION_TYPES,
  SelectOption,
  Transaction,
  TransactionService as CommonTransactionService,
  DELAY_TYPE_DUE
} from '@inpi-marques/components'
import { DEPOSIT_TYPES } from 'constants/DepositConstant'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { DepositType } from '../../../interfaces/Deposit'
import { STATUS_WORKFLOW } from '../../../constants/WorkflowConstants'
import { InscriptionType } from '../../../interfaces/Inscription'

interface GeneralInformationsOverviewProps {
    transaction: Transaction
}

/**
 * Informations générales dans détails de la transaction
 * @param transaction
 * @constructor
 */
const InformationsOverview: FC<GeneralInformationsOverviewProps> = ({ transaction }) => {
  const depositType: DepositType | undefined = DEPOSIT_TYPES.find((type: DepositType) => type.value === transaction.subProcedureType)
  const inscriptionType: InscriptionType | undefined = INSCRIPTION_TYPES.find((type: InscriptionType) => type.value === transaction.subProcedureType)
  const statusFile: string | undefined = STATUS_WORKFLOW.find((status) => status.workflow.includes(transaction.status))?.label

  return (
    <div className='row'>
      {depositType &&
        <div className='col-12'>
          <h4 className='font-weight-bold'><FormattedMessage id='overview_deposit_type_label' /></h4>
          <p className='subtitle'><FormattedMessage id={`overview_${depositType.label}`} /></p>
        </div>}
      {inscriptionType &&
        <div className='col-12'>
          <h4 className='font-weight-bold'><FormattedMessage id='overview_inscription_type_label' /></h4>
          <p className='subtitle'><FormattedMessage id={inscriptionType.label} /></p>
        </div>}
      <div className='col-12'>
        <h4 className='font-weight-bold'><FormattedMessage id='overview_request_date' /></h4>
        <p className='subtitle'>{DateUtils.formatDateFr(transaction.arrivalDate ?? transaction.createdAt)}</p>
      </div>
      <div className='col-12'>
        <h4 className='font-weight-bold'><FormattedMessage id='overview_internal_reference_label' /></h4>
        <p className='subtitle'>{transaction.internalReference ?? '-'}</p>
      </div>
      {transaction.suite?.position &&
        <div className='col-12'>
          <h4 className='font-weight-bold'><FormattedMessage id={`overview_${transaction.procedureType?.toLowerCase()}_suite`} /></h4>
          <p className='subtitle'>{transaction.suite.position}</p>
        </div>}
      {
        statusFile &&
          <div className='col-12'>
            <h4 className='font-weight-bold'><FormattedMessage id='overview_etape_workflow' /></h4>
            <p className='subtitle'><FormattedMessage id={statusFile} /></p>
          </div>
      }
      {
        transaction.opposition?.decision?.date && transaction.opposition?.delay?.type === DELAY_TYPE_DUE.value && transaction.opposition?.delay.endDate &&
          <div className='col-12'>
            <h4 className='font-weight-bold'><FormattedMessage id='overview_deadline_date' /></h4>
            <p className='subtitle'>{DateUtils.formatDateFr(transaction.opposition?.delay.endDate)}</p>
          </div>
      }
      {CommonTransactionService.isRenunciation(transaction) &&
        <>
          <div className='col-12'>
            <h4 className='font-weight-bold'><FormattedMessage id='overview_renunciation_type_label' /></h4>
            <p className='subtitle'>{INSCRIPTION_RENUNCIATION_TYPES.find((type: SelectOption) => type.value === transaction.inscription?.renunciation?.type).label}</p>
          </div>
        </>}
      {CommonTransactionService.isInscription(transaction) && transaction.inscription?.originTn &&
        <div className='col-12'>
          <h4 className='font-weight-bold'><FormattedMessage id='overview_inscription_tn_origin_label' /></h4>
          <p className='subtitle'>{transaction.inscription.originTn.numNat}</p>
        </div>}
      {CommonTransactionService.isInscription(transaction) && transaction.inscription?.transformationTa &&
        <div className='col-12'>
          <h4 className='font-weight-bold'><FormattedMessage id='overview_inscription_ta_replace_label' /></h4>
          <p className='subtitle'>{transaction.inscription.transformationTa.numNat}</p>
        </div>}
    </div>
  )
}

export default InformationsOverview
