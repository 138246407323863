import {
  BASIC_DATE,
  Country,
  DATE_ISO,
  DateField,
  DateUtils,
  Deposit,
  DEPOSIT_TYPE_DIVISION,
  DepositService,
  DOCUMENT_FORMATS,
  DOCUMENT_LIMITS,
  DOCUMENT_TYPES,
  downloadFile,
  EventType,
  FieldStatus,
  FileBrowserField,
  FileButton,
  HelpBlock, InternationalExtension,
  NumNatVerifier,
  Preview,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION,
  Publication,
  PUBLICATION_REGISTRATION,
  PUBLICATION_REGISTRATIONAM,
  PUBLICATION_TYPE,
  Record,
  SubmitButton,
  SwitchField,
  TransactionDocument
} from '@inpi-marques/components'
import { DepositTypeContentProps } from 'interfaces/Deposit'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import {
  storeTransactionFieldStatusReset,
  storeTransactionFieldStatusUpdate
} from '../../../../store/fieldStatus/fieldStatusActions'
import TransactionService from '../../../../services/transaction/TransactionService'
import ContentService from '../../../../services/content/ContentService'
import RecordService from '../../../../services/opposition/RecordService'
import { BRAND_TYPES_ALL } from '../../../../constants/BrandConstants'
import {
  storeTransactionResetDeposit,
  storeTransactionUpdate,
  storeTransactionUpdateDeposit
} from '../../../../store/transaction/transactionActions'
import qs from 'qs'
import { withRouter } from 'react-router-dom'
import RecordNotFoundCreation from './RecordNotFoundCreation'
import DocumentService from '../../../../services/document/DocumentService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons'

const DivisionRegisteredContent: FC<DepositTypeContentProps> = ({
  fieldStatus,
  onDocumentChanged,
  onDocumentDeleted,
  documents,
  className,
  setHolders,
  location,
  transaction,
  previousTransactionId
}) => {
  const productFile: TransactionDocument | undefined = TransactionService.getDocumentByType(documents, DOCUMENT_TYPES.BRAND_RECDIV_INVALIDITY_REVOCATION)
  const [nationalNumber, setNationalNumber] = useState<string>(transaction?.deposit?.numNatToTransformOrDivide ?? '')
  const [country, setCountry] = useState<Country>()
  const [record, setRecord] = useState<Record>()
  const [notExist, setNotExist] = useState<boolean>(false)
  const [editingRegisteredDate, setEditingRegisteredDate] = useState<boolean>(false)
  const intl = useIntl()
  const dispatch = useDispatch()

  const onDepositRegisteredDateChanged = (event: EventType) => {
    const { value } = event.target
    // Undefined si la date n'est pas correct en cas de modification à la main
    dispatch(storeTransactionUpdateDeposit({ registeredDate: DateUtils.formatToBeginOfDay(value) || undefined }))
  }

  /**
   * Récupère les informations de la marque via la base publique et met à jour le fondement si elle existe.
   */
  const verifyNumNat = async (): Promise<Record[]|undefined> => {
    setHolders && setHolders([])
    dispatch(storeTransactionFieldStatusReset())

    if (nationalNumber) {
      const response: Record[] = await RecordService.verifyNumnat({ numNat: nationalNumber, origin: ['FR'] }, true)
      if (response.length) {
        return response
      } else {
        setNotExist(true)
        setRecord(undefined)
      }
    }
  }

  const getBrandFile = () => {
    if (record?.details?.brand?.file?.url) {
      return record?.details?.brand.file?.url
    } else {
      if (record.id) {
        return () => DocumentService.getBrandPreviewDocumentRecord(record?.details?.brand?.preview || record?.details?.brand?.file, record)
      } else {
        return () => DocumentService.getBrandPreviewDocument(
          previousTransactionId ? { id: previousTransactionId } : transaction,
            record?.details?.brand?.preview || record?.details?.brand?.file
        )
      }
    }
  }

  const onRecordSelected = (record: Record) => {
    setRecord(record)
    setNotExist(false)
    setHolders && setHolders(record?.holders)
    const isRegistered = record?.publications?.find((publication: Publication) => [PUBLICATION_REGISTRATION, PUBLICATION_REGISTRATIONAM].includes(publication?.code))
    let valuesToUpdate
    if (isRegistered || !record.publications) {
      valuesToUpdate = {
        procedureType: PROCEDURE_DIVISION.value,
        subProcedureType: null
      }
    } else {
      valuesToUpdate = {
        procedureType: PROCEDURE_DEPOSIT.value,
        subProcedureType: DEPOSIT_TYPE_DIVISION.value
      }
    }

    const deposit: Deposit = DepositService.getDepositFromRecord(record)
    let internationalExtensions: InternationalExtension[]
    if (record?.registrationWoDate || record?.numNatWo) {
      internationalExtensions = [{ numNat: record?.numNatWo, arrivalDate: record?.registrationWoDate }]
    } else {
      internationalExtensions = record?.internationalExtensions ? [...record?.internationalExtensions] : []
    }
    !deposit.registeredDate && setEditingRegisteredDate(true)
    dispatch(storeTransactionUpdate({
      ...valuesToUpdate,
      deposit,
      internationalExtensions
    }))
  }

  const onNullitySwitchChanged = () => {
    dispatch(storeTransactionUpdateDeposit({ hasCurrentNullityRequest: !transaction?.deposit?.hasCurrentNullityRequest }))
  }

  /**
   * Permet de sauvegarder temporairement le numnat à diviser entré dans le champ pour
   * pouvoir afficher un message d'erreur différent (numnat vérifié / pas entré)
   */
  const saveTemporaryNumnatToDivide = () => {
    if (!nationalNumber || (nationalNumber !== transaction?.deposit?.numNatToTransformOrDivide)) {
      dispatch(storeTransactionResetDeposit({
        numNatToTransformOrDivide: nationalNumber
      }))
    }
  }

  const handleDownload = (document: TransactionDocument) => {
    DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  useEffect(() => {
    if (transaction?.deposit?.numNatToTransformOrDivide) {
      setNationalNumber(transaction?.deposit?.numNatToTransformOrDivide)
    }
  }, [transaction?.deposit?.numNatToTransformOrDivide])

  useEffect(() => {
    // Dans le cas d'une suite de division, on initialise la marque avec la transaction précédente
    if (previousTransactionId) {
      TransactionService.getTransaction(previousTransactionId).then(result => {
        if (result?.deposit) {
          onRecordSelected({
            numNat: result.deposit.numNatToTransformOrDivide,
            from: result.deposit.from,
            depositDate: result.deposit.createdAtToTransformOrDivide,
            publications: [{ date: result?.deposit?.registeredDate, code: PUBLICATION_TYPE.REGISTRATION }],
            details: {
              ...result.deposit,
              // Les produits mère de la transaction précédente deviennent les produits mère de la transaction courante
              productsAndServicesVersions: [...result.deposit.parentProductsAndServicesVersions]
            },
            inscriptionsLink: result.inscriptionsLink,
            associatedBrands: result.deposit?.associatedBrands
          })
        }
      })
    }
  }, [previousTransactionId])

  useEffect(() => {
    // Si la division a été faite à partir d'un titre, on récupère l'id dans l'url
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    if (!transaction?.id && !query.from) {
      return
    }

    if (query.from) {
      RecordService.getRecord(query.from).then((result: Record) => {
        setNationalNumber(result.numNat)
        onRecordSelected(result)
      })
    } else if (transaction?.deposit?.numNatToTransformOrDivide) {
      // Si le dépôt existe déjà et que certaines infos ont déjà été remplies
      setNationalNumber(transaction?.deposit?.numNatToTransformOrDivide)
      onRecordSelected({
        numNat: transaction?.deposit?.numNatToTransformOrDivide,
        from: transaction?.deposit?.from,
        depositDate: transaction?.deposit?.createdAtToTransformOrDivide,
        publications: [{ date: transaction?.deposit?.registeredDate, code: PUBLICATION_TYPE.REGISTRATION }],
        holders: record?.holders,
        details: {
          ...transaction?.deposit,
          // Les produits mère de la transaction précédente deviennent les produits mère de la transaction courante
          productsAndServicesVersions: transaction?.deposit?.parentProductsAndServicesVersions ? [...transaction?.deposit?.parentProductsAndServicesVersions] : [],
          tmpChildProductsAndServicesVersions: transaction?.deposit?.productsAndServicesVersions ? [...transaction?.deposit?.productsAndServicesVersions] : []
        }
      })
    }
  }, [transaction?.id])

  useEffect(() => {
    ContentService.getCountry('fr').then(setCountry)

    return () => {
      ContentService.cancelRequest()
      RecordService.cancelRequest()
    }
  }, [])
  return (
    <div className='division-content'>
      <div className={`d-flex flex-wrap ${className}`}>
        <div className='national-number__wrapper col-12 p-0 mb-3'>
          <NumNatVerifier
            inputId='national-number'
            className='col-4 p-0'
            labelClassname='mr-2'
            value={nationalNumber}
            fieldStatus={fieldStatus}
            dispatch={dispatch}
            setValue={setNationalNumber}
            countries={[country]}
            verifyNumNat={verifyNumNat}
            onBlur={saveTemporaryNumnatToDivide}
            label={<FormattedMessage id='deposit_type_division_national_number_label' />}
            onRecordSelected={verifyNumNat ? (record: Record) => {
              onRecordSelected(record)
            } : undefined}
            nameFieldStatus='nationalDepositNumber'
            setFieldStatus={storeTransactionFieldStatusUpdate}
            disabled={previousTransactionId}
          />
        </div>
      </div>
      {notExist && (
        <RecordNotFoundCreation
          deposit={transaction?.deposit}
          fieldStatus={fieldStatus}
        />)}
      {previousTransactionId && !record &&
        <div className=''>
          <FontAwesomeIcon className='loader' icon={faSpinner} />
        </div>}
      {
        record &&
          <div className='card'>
            <div className='col-12 p-0'>
              {
                (record?.details?.brand?.preview || record?.details?.brand?.file) &&
                  <Preview
                    className='mb-3'
                    file={getBrandFile()}
                    document={record.details?.brand?.preview || record.details?.brand?.file}
                  />
              }
              <div className='d-flex'>
                {
                      record.details?.brand?.text &&
                        <div className='mr-5 d-flex flex-column'>
                          <span className='title-info'><FormattedMessage id='deposit_type_division_registered_marque' /></span>
                          <span>{record.details?.brand?.text}</span>
                        </div>
                }
                <div className='mr-5 d-flex flex-column'>
                  <span className='title-info'><FormattedMessage id='deposit_type_division_registered_type' /></span>
                  <span><FormattedMessage id={BRAND_TYPES_ALL.find((brand) => brand.value === record.details?.brand?.type)?.title} /></span>
                </div>
                <div className='mr-5 d-flex flex-column'>
                  <span className='title-info'><FormattedMessage id='deposit_type_division_registered_deposit_date' /> </span>
                  <span>{DateUtils.formateDateToFormat(record.depositDate, BASIC_DATE) || ''}</span>
                </div>
                {!editingRegisteredDate &&
                  <div className='d-flex'>
                    <div className='d-flex flex-column'>
                      <span className='title-info'><FormattedMessage id='deposit_type_division_registered_date' /> </span>
                      <span>{DateUtils.formateDateToFormat(transaction?.deposit?.registeredDate, BASIC_DATE)}</span>
                    </div>
                    <SubmitButton
                      className='btn btn-link text-primary ml-2'
                      onClick={() => setEditingRegisteredDate(true)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </SubmitButton>
                  </div>}
              </div>
              {editingRegisteredDate &&
                <div className='col-12 col-lg-4 mt-4 p-0'>
                  <DateField
                    inputId='division-deposit-registered-date'
                    value={DateUtils.formateDateToFormat(transaction?.deposit?.registeredDate, DATE_ISO) ?? ''}
                    label={<FormattedMessage id='deposit_type_division_registered_date' />}
                    placeholder={intl.formatMessage({ id: 'form_date_placeholder' })}
                    onChange={onDepositRegisteredDateChanged}
                    onBlur={() => transaction?.deposit?.registeredDate && setEditingRegisteredDate(false)}
                    fieldStatus={fieldStatus as FieldStatus}
                    classNameLabel='col-12 p-0'
                    dispatch={dispatch}
                    resetError={storeTransactionFieldStatusUpdate}
                  />
                </div>}
            </div>
          </div>
      }
      {
        (record || notExist) && transaction?.procedureType === PROCEDURE_DIVISION.value &&
          <>
            <div className='national-deposit-date__wrapper col-12 p-0 mt-4'>
              <SwitchField
                inputId='request-nullity-decheance'
                className='p-0 mr-2 ml-5'
                displayStringValue
                divClassName='d-flex'
                value={transaction?.deposit?.hasCurrentNullityRequest}
                label={<FormattedMessage id='deposit_type_division_registered_request_nullity_decheance' />}
                onChange={onNullitySwitchChanged}
                fieldStatus={fieldStatus as FieldStatus}
                nameFieldStatus='requestNullityDecheance'
              />
            </div>
            {
              transaction?.deposit?.hasCurrentNullityRequest &&
                <FileBrowserField
                  inputId='justificatif'
                  className='col-12 col-md-6 p-0'
                  ulClassName='col-8'
                  label={<FormattedMessage id='deposit_type_division_registered_justificatif' />}
                  buttonLabel={<FileButton />}
                  onChange={(event: EventType) => onDocumentChanged(event, DOCUMENT_TYPES.BRAND_RECDIV_INVALIDITY_REVOCATION)}
                  onDelete={onDocumentDeleted}
                  maxNumberFile={1}
                  value={productFile && [productFile]}
                  acceptApplication={DOCUMENT_FORMATS.PDF}
                  maxFileSize={DOCUMENT_LIMITS.DEPOSIT_MAX_SIZE}
                  fieldStatus={fieldStatus}
                  nameFieldStatus='ndProof'
                  required
                  dispatch={dispatch}
                  resetError={storeTransactionFieldStatusUpdate}
                  informationDoc
                  handleDownload={handleDownload}
                />
            }
          </>
      }
      {
        record &&
          <div><HelpBlock className='mb-2 mt-5 col-12 p-0'><FormattedMessage id='deposit_type_division_registered_description' /></HelpBlock></div>
      }
    </div>
  )
}

export default withRouter(DivisionRegisteredContent)
