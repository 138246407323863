import React from 'react'
import ContentLoader from 'react-content-loader'
import { SubmitButtonLoader } from '@inpi-marques/components'

const ETSAccountPMLoader = () => {
  return (
    <div className='mt-4'>
      <div className='mb-3 w-100'>
        <ContentLoader
          speed={2}
          style={{ width: '100%', height: '170px' }}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <circle cx='20' cy={35} r='8' />
          <rect x={55} y={30} rx='5' ry='5' width='90%' height={15} />

          <circle cx='20' cy={90} r='8' />
          <rect x={55} y={85} rx='5' ry='5' width='90%' height={15} />

          <circle cx='20' cy={145} r='8' />
          <rect x={55} y={140} rx='5' ry='5' width='90%' height={15} />
        </ContentLoader>
      </div>
      <div className='d-flex justify-content-center'>
        <SubmitButtonLoader
          height={38}
          width={250}
        />
      </div>
    </div>
  )
}

export default ETSAccountPMLoader
