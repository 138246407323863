import store from '../../../../../store/store'
import {
  containsErrors,
  FieldStatus,
  FRMI,
  messageIsFilled
} from '@inpi-marques/components'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import Message from '../../../../../constants/Message'
import {
  DIRECT_DEBIT_ON_OMPI_ACCOUNT,
  PAYMENT_RECEIVED_AND_CONFIRMED_BY_OMPI, PAYMENT_TO_OMPI_BANK_ACCOUNT, PAYMENT_TO_OMPI_POSTAL_ACCOUNT
} from '../../../../../constants/PaymentMethodsConstants'

class FeesAndTaxesValidator {
  getValidatedFieldStatus = (frmi?: FRMI): FieldStatus => {
    const frmiFieldStatus: FieldStatus = {}
    if (!frmi?.paymentMethod || !frmi.paymentMethod?.type) {
      frmiFieldStatus.paymentMethod = Message.fees_and_taxes_payment_method_missing
      return frmiFieldStatus
    }

    switch (frmi.paymentMethod.type) {
      case DIRECT_DEBIT_ON_OMPI_ACCOUNT.value:
        return {
          directDebitAccountHolder: messageIsFilled(frmi.paymentMethod.accountHolder),
          directDebitAccountNumber: messageIsFilled(frmi.paymentMethod.accountNumber),
          directDebitInstructionsAuthor: messageIsFilled(frmi.paymentMethod.instructionsAuthor)
        }
      case PAYMENT_RECEIVED_AND_CONFIRMED_BY_OMPI.value:
        return {
          paymentReceivedPaymentAuthor: messageIsFilled(frmi.paymentMethod.paymentAuthor),
          paymentReceivedOmpiReceiptNumber: messageIsFilled(frmi.paymentMethod.ompiReceiptNumber)
        }
      case PAYMENT_TO_OMPI_POSTAL_ACCOUNT.value:
        return {
          postalPaymentAuthor: messageIsFilled(frmi.paymentMethod.paymentAuthor),
          postalPaymentReferences: messageIsFilled(frmi.paymentMethod.paymentReferences),
          postalTransferDate: messageIsFilled(frmi.paymentMethod.transferDate)
        }
      case PAYMENT_TO_OMPI_BANK_ACCOUNT.value:
        return {
          bankPaymentAuthor: messageIsFilled(frmi.paymentMethod.paymentAuthor),
          bankPaymentReferences: messageIsFilled(frmi.paymentMethod.paymentReferences),
          bankTransferDate: messageIsFilled(frmi.paymentMethod.transferDate)
        }
      default:
        return frmiFieldStatus
    }
  }

  validateOnChangeStep = (frmi?: FRMI) => {
    const fieldStatus: FieldStatus = this.getValidatedFieldStatus(frmi)
    store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
    return !containsErrors(fieldStatus)
  }
}

export default new FeesAndTaxesValidator()
