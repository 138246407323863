import { DATE_ISO, DateField, DateUtils, EventType, Fact, TextField, Transaction } from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'
import Message from '../../../../constants/Message'

interface FactsSummaryProps {
    transaction: Transaction,
    type: 'restriction'|'deletion'
}

const FactsSummary: FC<FactsSummaryProps> = ({ transaction, type }) => {
  const dispatch = useDispatch()

  const [fact, setFact] = useState<Fact|undefined>(transaction.frmiDetails?.fact ?? { type: type, text: type === 'restriction' ? Message.frmi_facts_restriction_start_text : Message.frmi_facts_deletion_start_text })

  const onFactsChange = (event: EventType): void => {
    let { name, value } = event.target
    if (name.includes('Date')) {
      value = DateUtils.formatToBeginOfDay(value)
    }
    const updatedFact = {
      ...fact,
      [name]: value
    }
    setFact(updatedFact)
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      fact: updatedFact
    }))
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_facts_summary_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_facts_summary_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div>
        <label className='text-gris mb-2'><FormattedMessage id={`frmi_facts_${type}_label`} /></label>
        <div className='d-flex flex-column p-3 facts-summary'>
          <span className='mb-3'><FormattedMessage id={`frmi_facts_${type}_start_text`} /></span>
          {type === 'restriction' &&
            <div className='d-flex flex-row align-items-baseline'>
              <span><FormattedMessage id='frmi_facts_restriction_act_text' /></span>
              <TextField
                inputId='act'
                classNameFormGroup='col-5'
                value={fact?.act}
                onChange={onFactsChange}
              />
            </div>}
          <div className='d-flex flex-row align-items-baseline'>
            <span className='mr-2 ml-1'><FormattedMessage id='frmi_facts_date_text' /></span>
            <DateField
              inputId='restrictionDate'
              classNameFormGroup='col-5'
              value={DateUtils.formateDateToFormat(fact?.restrictionDate, DATE_ISO)}
              onChange={onFactsChange}
            />
          </div>
          <div className='d-flex flex-row align-items-baseline'>
            <span><FormattedMessage id='frmi_facts_contributor_text' /></span>
            <TextField
              inputId='contributor'
              classNameFormGroup='col-5'
              value={fact?.contributor}
              onChange={onFactsChange}
            />
          </div>
          {type === 'deletion' &&
            <div className='d-flex flex-row align-items-baseline'>
              <span className='mr-4 ml-1'><FormattedMessage id='frmi_facts_deletion_registration_date_text' /></span>
              <DateField
                inputId='registrationDate'
                classNameFormGroup='col-5'
                value={DateUtils.formateDateToFormat(fact?.registrationDate, DATE_ISO)}
                onChange={onFactsChange}
              />
            </div>}
        </div>
      </div>
    </>
  )
}

export default FactsSummary
