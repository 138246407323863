import {
  Country, DateUtils,
  EventType,
  FieldStatus,
  FRMI_HOLDER_CHANGE_EXTENSION_TYPES,
  MultipleRadioField, PRODUCT_AND_SERVICE_VERSION_TYPE, PRODUCT_CLASS_VERSION_STATUS, ProductClass, ProductClassVersion,
  PRODUCTS_EXTENSION_TYPE_TOTAL,
  SelectOption, Title,
  Transaction,
  ProductService
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeTransactionUpdateFRMI } from 'store/transaction/transactionActions'
import ContentService from '../../../../services/content/ContentService'

interface HolderChangeExtentionProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

/**
 * Etendue du changement de titulaire
 */
const HolderChangeExtention: FC<HolderChangeExtentionProps> = ({ transaction, fieldStatus }) => {
  const dispatch = useDispatch()
  const user = useSelector((state: RootStateOrAny) => state.user.user)

  const onTypeChanged = (event: EventType): void => {
    const { value } = event.target

    if (value === PRODUCTS_EXTENSION_TYPE_TOTAL && transaction.frmiDetails?.titles) {
      let countryCodes: string[] = []
      transaction.frmiDetails.titles.forEach((title: Title) => {
        if (title.countryCodes?.length) {
          countryCodes = [...new Set([...countryCodes, ...title.countryCodes])]
        }
      })
      ContentService.getCountries().then((response: Country[]) => {
        const countries: Country[] = response.filter((country: Country) => countryCodes.some((countryCode: string) => countryCode === country.code))
        const allProductClasses: ProductClass[] | undefined = transaction.frmiDetails?.titles?.filter((title: Title) => title.productsAndServicesVersions?.length).map((title: Title) => ProductService.getCurrentVersion(title.productsAndServicesVersions)?.productClasses ?? []).flat(1).sort((a, b) => a.ref < b.ref ? -1 : a.ref > b.ref ? 1 : 0)
        const version = {
          createdDate: DateUtils.now(),
          type: PRODUCT_AND_SERVICE_VERSION_TYPE.INITIAL_VERSION,
          status: PRODUCT_CLASS_VERSION_STATUS.ACCEPTED,
          creator: user,
          productClasses: allProductClasses
        } as ProductClassVersion
        dispatch(storeTransactionUpdateFRMI({
          ...transaction.frmiDetails,
          countriesExtensionType: value,
          productsExtensionType: value,
          countries: countries,
          productsAndServicesVersions: [version]
        }))
      })
    } else {
      dispatch(storeTransactionUpdateFRMI({
        ...transaction.frmiDetails,
        countriesExtensionType: value,
        productsExtensionType: value
      }))
    }
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_holder_change_extension_title' /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='is-validated position-relative'>
        <h2 className='text-primary mb-2'><FormattedMessage id='frmi_holder_change_products_extension_type_label' /></h2>
        <MultipleRadioField
          className='col-12'
          inputId='productsExtensionType'
          onChange={onTypeChanged}
          value={transaction.frmiDetails?.productsExtensionType && transaction.frmiDetails?.countriesExtensionType}
          fieldStatus={fieldStatus}
          radio={FRMI_HOLDER_CHANGE_EXTENSION_TYPES.map((effectDateType: SelectOption) => ({ label: effectDateType.label, value: effectDateType.value as string }))}
        />
      </div>
    </>
  )
}

export default HolderChangeExtention
