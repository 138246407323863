import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { ETSAccountRequestCredentials } from '../../interfaces/ETSAccountInterface'
import {
  CardBlock,
  CONSENTEMENT_OPTIONS,
  containsErrors,
  EventType,
  FieldStatus,
  FilAriane,
  MultipleRadioField,
  ND_ETS_ACCOUNT_CONTRIBUTORS_CODES,
  OPPOSITION_ETS_ACCOUNT_CONTRIBUTORS_CODES,
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION,
  PROCEDURES,
  PROCEDURES_LOT_2,
  SelectField,
  SubmitButton,
  TextField
} from '@inpi-marques/components'
import ETSAccountRequestCredentialsValidator from './validator/ETSAccountRequestCredentialsValidator'
import ETSAccountService from '../../services/ets/ETSAccountService'
import { ACCESS_DEMAND_ITEM_CREDENTIALS } from '../../constants/ETSAccountConstants'
import { Link } from 'react-router-dom'
import Message from '../../constants/Message'
/* global localStorage */

const ETSAccountCredentials: FC<WrappedComponentProps> = ({ intl }) => {
  const [redirect, setRedirect] = useState<string|null>(null)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})
  const [credentials, setCredentials] = useState<ETSAccountRequestCredentials>(
    {
      code: '',
      password: ''
    }
  )
  const procedures = process.env.REACT_APP_LOT_2 === 'true' ? PROCEDURES_LOT_2 : PROCEDURES
  const procedureOptions = [...procedures].map(p => p.value === 'MIFR' ? { ...p, label: Message.procedure_type_mifr_ets } : p)
  const askConsent = localStorage.getItem('ELECTRONIC_NOTIF') === 'true'

  /**
   * Validation du formulaire de rattachement
   */
  const handleSubmit = () => {
    const fieldStatus = ETSAccountRequestCredentialsValidator.validateForm(credentials)
    if (containsErrors(fieldStatus)) {
      return setFieldStatus(fieldStatus)
    } else {
      setFieldStatus({})

      return ETSAccountService.postETSAccountRequestCredentials(credentials).then(() => {
        toast.success(intl.formatMessage({ id: 'ets_account_request_success_request' }))
        setRedirect('/')
      })
    }
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id={ACCESS_DEMAND_ITEM_CREDENTIALS.breadcrumb} /></span>
      </FilAriane>
      {redirect &&
        <Redirect to={{
          pathname: redirect
        }}
        />}
      <div className='mt-4'>
        <CardBlock
          className='col-12'
          shadow
        >
          <div className='d-flex justify-content-center row main'>
            <header className='col-8'>
              <h1><FormattedMessage id={ACCESS_DEMAND_ITEM_CREDENTIALS.label} /></h1>
            </header>
          </div>
          <div className='d-flex flex-column align-items-center mt-4 is-validated'>
            <div className='row col-12 col-lg-8 col-xl-6'>
              <div className='col-12 col-md-6'>
                <SelectField
                  inputId='procedure'
                  label={<FormattedMessage id='ets_account_request_procedure_label' />}
                  onChange={(event: EventType) => setCredentials({ ...credentials, procedure: event.target.value, profile: undefined })}
                  placeholder={intl.formatMessage({ id: 'placeholder_select' })}
                  fieldStatus={fieldStatus}
                  value={credentials.procedure}
                  options={procedureOptions}
                  required
                />
              </div>
              {(credentials.procedure === PROCEDURE_OPPOSITION.value || credentials.procedure === PROCEDURE_NULLITY.value ||
                  credentials.procedure === PROCEDURE_REVOCATION.value) &&
                    <div className='col-12 col-md-6'>
                      <SelectField
                        inputId='profile'
                        options={credentials.procedure === PROCEDURE_OPPOSITION.value ? OPPOSITION_ETS_ACCOUNT_CONTRIBUTORS_CODES : ND_ETS_ACCOUNT_CONTRIBUTORS_CODES}
                        value={credentials.profile}
                        label={<FormattedMessage id='ets_account_request_code_label' />}
                        placeholder={intl.formatMessage({ id: 'ets_account_request_code_placeholder' })}
                        onChange={(event: EventType) => setCredentials({ ...credentials, profile: event.target.value })}
                        required
                        fieldStatus={fieldStatus}
                      />
                    </div>}
            </div>
            <div className='row col-12 col-lg-8 col-xl-6'>
              <div className='col-12 col-md-6'>
                <TextField
                  inputId='code'
                  label={<FormattedMessage id='ets_account_request_credentials_code' />}
                  fieldStatus={fieldStatus}
                  value={credentials.code}
                  onChange={(event: EventType) => setCredentials({ ...credentials, code: event.target.value })}
                  required
                />
              </div>
              <div className='col-12 col-md-6'>
                <TextField
                  inputId='password'
                  label={<FormattedMessage id='ets_account_request_credentials_password' />}
                  fieldStatus={fieldStatus}
                  value={credentials.password}
                  onChange={(event: EventType) => setCredentials({ ...credentials, password: event.target.value })}
                  required
                />
              </div>
            </div>
            {askConsent &&
              <div className='bg-primary card h-auto shadow align-items-center my-3 d-flex flex-row p-2 row col-12 col-lg-8 col-xl-6'>
                <span className='text-white pl-1 pr-4'><FormattedMessage id='field_email_consent_recap' /></span>
                <MultipleRadioField
                  classNameRadioGroup='d-flex text-white'
                  inputId='consentNotificationByEmail'
                  value={credentials?.consentNotificationByEmail}
                  radio={CONSENTEMENT_OPTIONS}
                  fieldStatus={fieldStatus}
                  onChange={(event) => setCredentials({
                    ...credentials,
                    consentNotificationByEmail: event.target.value
                  })}
                />
              </div>}
            <div className='w-100 row my-5'>
              <div className='col'>
                <div className='d-flex justify-content-center'>
                  <SubmitButton
                    children={<FormattedMessage id='button_confirm' />}
                    className='btn-primary'
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBlock>
      </div>
    </>
  )
}

export default injectIntl(ETSAccountCredentials)
