import {
  BRAND_ORIGINS_SELECT_OPTIONS,
  CommonInscriptionService,
  DateUtils,
  ProductAndServicesAccordion,
  ProductAndServiceListView,
  ProductClass,
  ProductClassVersion,
  ProductService,
  PRODUCT_STATUS,
  Title,
  Transaction
} from '@inpi-marques/components'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { BRAND_TYPES_ALL } from '../../../constants/BrandConstants'
import Message from '../../../constants/Message'

interface OverviewRenunciationTitleProps {
    transaction: Transaction
}

const OverviewRenunciationTitle: FC<OverviewRenunciationTitleProps> = ({ transaction }) => {
  const currentTitle: Title|undefined = transaction.inscription?.titles && transaction.inscription.titles.length > 0 ? transaction.inscription.titles[0] : undefined
  const currentVersion: ProductClassVersion|undefined = ProductService.getCurrentVersion(currentTitle?.productsAndServicesVersions)
  const productToDisplay: ProductClass[]|undefined = CommonInscriptionService.isPartialRenunciation(transaction)
    ? currentVersion?.productClasses.map((productClass) => ({
      ...productClass,
      products: productClass.products.filter(product => !product.status || (product.status === PRODUCT_STATUS.ADDED && !product.editedByBo) || (product.status === PRODUCT_STATUS.DELETED && product.editedByBo))
    })).filter(productClass => productClass.products.length > 0) : []
  const [viewMode, setViewMode] = useState<'accordion' | 'list'>('accordion')

  return (
    <div className='row'>
      <div className='col-4'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id='titles_column_label_numNat' />
        </h4>
        <p className='subtitle'>{currentTitle?.numNat}</p>
      </div>
      <div className='col-4'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id='titles_column_label_origin' />
        </h4>
        <p className='subtitle'>{BRAND_ORIGINS_SELECT_OPTIONS.find(option => option.value === currentTitle?.origin)?.label}</p>
      </div>
      <div className='col-4'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id='titles_column_label_type' />
        </h4>
        <p className='subtitle'><FormattedMessage id={BRAND_TYPES_ALL.find(type => type.value === currentTitle?.type)?.title} /></p>
      </div>
      <div className='col-4'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id='titles_column_label_text' />
        </h4>
        <p className='subtitle'>{currentTitle?.text}</p>
      </div>
      <div className='col-4'>
        <h4 className='mb-2 font-weight-bold'>
          <FormattedMessage id='titles_column_label_date' />
        </h4>
        <p className='subtitle'>{DateUtils.formatDateFr(currentTitle?.depositDate)}</p>
      </div>
      {currentVersion &&
        <div className='col-12'>
          <h4 className='mb-2 font-weight-bold'>
            <FormattedMessage id='products_and_services_title_renunciation' />
          </h4>
          <div className='d-flex justify-content-end mb-4'>
            {
              viewMode === 'accordion' &&
                <button className='btn btn-link text-primary' onClick={() => setViewMode('list')}><FormattedMessage id='products_and_services_by_class' /></button>
            }
            {
              viewMode === 'list' &&
                <button className='btn btn-link text-primary' onClick={() => setViewMode('accordion')}><FormattedMessage id='products_and_services_by_list' /></button>
            }
          </div>
          {productToDisplay && productToDisplay.length > 0 ? (
            viewMode === 'accordion'
              ? <ProductAndServicesAccordion productClasses={productToDisplay} />
              : <ProductAndServiceListView productClasses={productToDisplay} readOnly />
          ) : <div className='justify-content-start'><FormattedMessage id={Message.none} /></div>}
        </div>}

    </div>
  )
}

export default OverviewRenunciationTitle
