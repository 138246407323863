import React, { FunctionComponent, ReactElement, useState } from 'react'
import ErrorField from '../errors/ErrorField'
import { FieldProps, FieldStatus } from '../FormInterfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { EventType } from 'src'
import { buildEventType, resetError as resetErrorUtils, resetStateError } from '../../utils/formUtils'
import { hasEmoticons, REGEX_EMOJI, REGEX_OTHERS } from '../../utils/validationUtils'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'

import Message from '../../constants/Message'

export interface TextFieldProps extends FieldProps {
    type?: string,
    required?: boolean,
    fieldStatus?: FieldStatus | any,
    min?: string|number|undefined,
    integersOnly?: boolean,
    labelInInput?: ReactElement,
    max?: number,
    infoHelp?: string,
    maxLength?: number,
    autoComplete?: string,
    onKeypress?: (event: KeyboardEvent) => void,
    icon?: IconDefinition,
    onIconClick?: () => void,
    buttonLabel?: string,
    labelClassname?: string,
    onLabelClick?: () => void,
    isLoading? : boolean,
    loaderClassName?: string,
    addToRefs?: (ref) => void,
    prefix?: string,
    isValid?: boolean,
    classNameInput?: string,
}

const TextField: FunctionComponent<TextFieldProps> = ({
  className = '',
  classNameFormGroup = '',
  classNameLabel = '',
  inputId,
  label,
  fieldStatus = {},
  nameFieldStatus,
  value = '',
  type = 'text',
  min,
  integersOnly = false,
  onChange,
  onFocus,
  onBlur,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false,
  maxLength,
  max,
  step = '',
  labelInInput,
  infoHelp,
  resetError,
  dispatch,
  autoComplete,
  onKeypress,
  icon,
  onIconClick,
  buttonLabel,
  labelClassname,
  onLabelClick,
  isLoading,
  loaderClassName,
  addToRefs,
  prefix,
  isValid = false,
  classNameInput
}) => {
  const [internalError, setInternalError] = useState<string>()

  /**
   * On clean l'erreur du champs au changement de sa valeur.
   * @param event
   */
  const onInternalChange = (event: EventType): void => {
    /** On clean l'erreur du store au changement */
    if (dispatch && resetError) {
      resetErrorUtils(dispatch, resetError, fieldStatus, nameFieldStatus || inputId)
    } else if (resetError) {
      resetStateError(resetError, fieldStatus, nameFieldStatus || inputId)
    }

    /**
     * Empêche l'utilisateur d'ajouter des émojis dans les champs textes
     */
    const value: string = event.target.value
    if (hasEmoticons(value)) {
      setInternalError(Message.emoji_regex_error)
      onChange && onChange(buildEventType(inputId, value.replace(REGEX_EMOJI, '').replace(REGEX_OTHERS, '')))
    } else {
      setInternalError(undefined)
      onChange && onChange(event)
    }
  }

  /**
   * Permet de ne pas autoriser certains caractères dans des cas précis
   * Appelle la fonction props.onKeyPress si aucun caractère bloquant
   * @param event
   */
  const onInternalKeyPress = (event: KeyboardEvent): void => {
    if (type === 'number') {
      // Si on veut un nombre supérieur à 0, on empêche l'utilisation du tiret
      if ((min >= 0 || value?.length > 1) && event.key === '-') {
        event.preventDefault()
        return
      }

      // On empêche l'ajout du "+" si il n'est pas le premier caractère dans la valeur
      if (value?.length >= 1 && event.key === '+') {
        event.preventDefault()
        return
      }

      // Si on ne veut un nombre entier on empeêche la virgule et le point
      if (integersOnly && (event.key === ',' || event.key === '.')) {
        event.preventDefault()
        return
      }
    }

    onKeypress && onKeypress(event)
  }

  return (
    <div className={`form-group ${labelInInput ? 'd-flex text-field-inline' : ''} ${classNameFormGroup}`}>
      {label &&
        <div className={`d-flex align-items-center ${classNameLabel}`}>
          <label className='form-label' htmlFor={inputId}>
            {label}
            {required && <span className='text-danger'> *</span>}
          </label>
          {infoHelp &&
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={
                <Popover id='popover-positioned-bottom'>
                  <Popover.Content>
                    {infoHelp}
                  </Popover.Content>
                </Popover>
              }
              rootClose
              rootCloseEvent='click'
            >
              <span className='cursor-pointer ml-2 text-primary'>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            </OverlayTrigger>}
        </div>}
      {labelInInput &&
        <span className='input-group-text' id={inputId}>{labelInInput}</span>}
      {icon &&
        <span className='cursor-pointer absolute-right-icon text-primary'>
          <FontAwesomeIcon icon={icon} onClick={onIconClick} />
        </span>}
      <div className={`${className} ${prefix ? 'd-flex align-items-center' : ''}`}>
        {prefix && <span className='textfield-prefix d-flex align-items-center'>{prefix}</span>}
        <input
          ref={(myRef) => addToRefs && addToRefs(myRef)}
          type={type}
          id={inputId}
          name={inputId}
          min={min}
          max={max}
          className={`form-control one-line-ellipsis ${prefix ? 'pl-0' : ''} ${classNameInput}`}
          placeholder={placeholder}
          onChange={onInternalChange}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          step={step}
          onFocus={onFocus}
          maxLength={maxLength}
          onBlur={onBlur}
          onClick={(event) => event.stopPropagation()}
          autoComplete={autoComplete}
          onKeyPress={(event: KeyboardEvent) => onInternalKeyPress(event)}
        />
        {buttonLabel && onLabelClick &&
          (
            isLoading ? (
              <FontAwesomeIcon className={`loader absolute absolute-loading ${loaderClassName}`} icon={faSpinner} />
            )
              : (
                <span className={`cursor-pointer text-primary absolute ${labelClassname}`} onClick={onLabelClick}>
                  {buttonLabel}
                </span>
              )
          )}
        {
          isValid &&
            <FontAwesomeIcon className='absolute text-success valid-icon' icon={faCheck} />
        }
      </div>
      <ErrorField message={fieldStatus[nameFieldStatus || inputId]} />
      {internalError && <ErrorField message={internalError} />}
    </div>
  )
}

export default TextField
