import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { LinkedTransaction, LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION, PreviousTransactions, Transaction } from '@inpi-marques/components'
import StoreService from 'services/StoreService'
import InternationalExtensionsOptions from '../../../division/form/options/InternationalExtensionsOptions'

interface OptionsProps {
  transaction: Transaction
}

const Options: FC<OptionsProps> = (
  {
    transaction
  }) => {
  /**
   * A la mise à jour des transactions liées
   * @param linkedTransactions
   */
  const onLinkedTransactionChanged = (linkedTransactions: LinkedTransaction[]): void => {
    StoreService.changeStore({
      ...transaction,
      inscription: {
        ...transaction.inscription,
        renunciation: {
          ...transaction.inscription?.renunciation,
          linkedTransactions: [...linkedTransactions]

        }
      }
    }
    )
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='renunciation_options_title' /></h1>
          <span className='subtitle'><FormattedMessage id='renunciation_options_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <InternationalExtensionsOptions
        transaction={transaction}
        numNat={transaction.inscription?.titles?.[0]?.numNat}
        titlesIds={transaction.inscription?.titles?.[0]?.numNat ? [transaction.inscription?.titles?.[0]?.numNat] : []}
        isRenunciation
      />
      <PreviousTransactions
        linkedTransactions={transaction.inscription?.renunciation?.linkedTransactions ?? []}
        onLinkedTransactionChanged={onLinkedTransactionChanged}
        transaction={transaction}
        isEditable
        linkedTransactionType={LINKED_TRANSACTION_TYPE_PREVIOUS_REGISTRATION.value}
      />
    </>
  )
}

export default Options
