import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  BASIC_DATE,
  BRAND_ORIGINS_SELECT_OPTIONS,
  BRAND_TYPES_SELECT_OPTIONS,
  CardBlock,
  DateUtils,
  DownloadLink,
  FRMI_TYPE_EXTENSION,
  ORIGIN_UNKNOWN,
  PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_RENEWAL,
  PROCEDURE_REVOCATION_STATEMENT,
  PRODUCT_STATUS,
  ProductAndServiceListView,
  ProductClass,
  ProductClassVersion,
  ProductService,
  ROWS_10,
  SubmitButton,
  Table,
  Title
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import TitleService from '../../../../services/inscription/TitleService'
import InscriptionService from 'services/inscription/InscriptionService'
import Message from '../../../../constants/Message'
import ProductsService from '../../../../services/deposit/ProductsService'

interface OverviewTitlesProps {
  titles?: Title[],
  editTitle?: (index: number) => void,
  deleteTitle?: (index: number) => void,
  className?: string,
  procedureType?: string,
  showDownloadTitleList?: boolean,
  isMonoTitle?: boolean,
  subProcedureType?: string,
  isFastTrack?: boolean
}

const OverviewTitles: FC<OverviewTitlesProps> = (
  {
    titles,
    editTitle,
    deleteTitle,
    className = '',
    procedureType,
    showDownloadTitleList = false,
    isMonoTitle = false,
    subProcedureType,
    isFastTrack
  }) => {
  const getTableTitle = () => {
    let tableTitle: {label: string, className: string}[] = [
      { label: Message.titles_column_label_id, className: 'table-column-label pl-2' },
      { label: procedureType === PROCEDURE_FRMI.value && subProcedureType !== FRMI_TYPE_EXTENSION.value ? Message.titles_column_label_international_numNat : Message.titles_column_label_numNat, className: 'table-column-label' }
    ]

    switch (procedureType) {
      case PROCEDURE_INSCRIPTION.value:
      case PROCEDURE_RENEWAL.value:
      case PROCEDURE_REVOCATION_STATEMENT.value:
        tableTitle = [...tableTitle,
          { label: Message.titles_column_label_origin, className: 'table-column-label' },
          { label: Message.titles_column_label_type, className: 'table-column-label' },
          { label: Message.titles_column_label_text, className: 'table-column-label' },
          { label: Message.titles_column_label_date, className: 'table-column-label' }
        ]
        break
      case PROCEDURE_FRMI.value:
        tableTitle = [...tableTitle,
          { label: Message.titles_column_label_type, className: 'table-column-label' },
          { label: Message.titles_column_label_text, className: 'table-column-label' },
          { label: Message.titles_column_label_deposit_date, className: 'table-column-label' },
          { label: subProcedureType !== FRMI_TYPE_EXTENSION.value ? Message.titles_column_label_expiration_date : Message.titles_column_label_registration_date, className: 'table-column-label' }
        ]
        break
      default:
        break
    }

    if (procedureType === PROCEDURE_RENEWAL.value) {
      tableTitle = [...tableTitle, { label: Message.titles_column_label_classes, className: 'table-column-label' }]
    }

    if ((editTitle || deleteTitle)) {
      tableTitle = [...tableTitle, { label: '', className: 'table-column-label' }]
    }

    return tableTitle
  }

  /** Afficher la liste des produits et services */
  const [selected, setSelected] = useState<boolean>(false)

  /** Page courante du tableau */
  const [page, setPage] = useState<number>(0)

  /** Nombre d'éléments par page du tableau */
  const nbItemsPerPage: number = ROWS_10.value

  const onDelete = (titleIndex: number): void => {
    const newPaginatedTitle: Title[][] = titles ? InscriptionService.getPaginatedTitles(titles.filter((_, key: number) => key !== titleIndex), nbItemsPerPage) : []

    // Si on supprime le dernier élément d'une page, on passe à la page précédente
    if (newPaginatedTitle.length < (page + 1)) {
      setPage(newPaginatedTitle.length - 1)
    }
    deleteTitle && deleteTitle(titleIndex)
  }

  /** On récupère un tableau paginé de titres */
  const paginatedTitles: Title[][] = titles ? InscriptionService.getPaginatedTitles(titles, nbItemsPerPage) : []

  return (
    <>
      {paginatedTitles && paginatedTitles.length > 0
        ? (
          <>
            <Table
              tableTitle={getTableTitle()}
              tableClassName={`title-table ${className}`}
              actualPage={page + 1}
              onChangePagination={(value) => setPage(value - 1)}
              showPagination={!isMonoTitle}
              totalPage={paginatedTitles.length}
            >
              <tbody>
                {paginatedTitles[page] &&
                  paginatedTitles[page].map((title: Title, index: number) => {
                    const titleIndex : number = (nbItemsPerPage * page) + index
                    const currentVersion: ProductClassVersion | undefined = ProductService.getCurrentVersion(title.productsAndServicesVersions)
                    return (
                      <>
                        <tr key={`-${index}`}>
                          <td className='pl-4'>{titleIndex + 1}</td>
                          <td>{title.numNat}</td>
                          {
                            (procedureType === PROCEDURE_INSCRIPTION.value || procedureType === PROCEDURE_RENEWAL.value || procedureType === PROCEDURE_REVOCATION_STATEMENT.value) &&
                              <td>{BRAND_ORIGINS_SELECT_OPTIONS.find((origin) => origin.value === title?.origin)?.label}</td>
                          }
                          <td>{BRAND_TYPES_SELECT_OPTIONS.find((brand) => brand.value === title?.type)?.label}</td>
                          <td>{title.text}</td>
                          <td>{DateUtils.formateDateToFormat(title.depositDate, BASIC_DATE)}</td>
                          {
                            procedureType === PROCEDURE_FRMI.value && subProcedureType !== FRMI_TYPE_EXTENSION.value &&
                              <td>{DateUtils.formateDateToFormat(title.expirationDate, BASIC_DATE)}</td>
                          }
                          {
                            procedureType === PROCEDURE_FRMI.value && subProcedureType === FRMI_TYPE_EXTENSION.value &&
                              <td>{DateUtils.formateDateToFormat(title.registeredDate, BASIC_DATE)}</td>
                          }
                          {procedureType === PROCEDURE_RENEWAL.value && (
                            <td>
                              {currentVersion && currentVersion?.productClasses &&
                                <>
                                  {ProductsService.sortProductClassByRef(currentVersion.productClasses.filter((productClass: ProductClass) => productClass.products.some(product => product.status !== PRODUCT_STATUS.DELETED))).map((productClass) => productClass.ref).join(', ')}
                                  <SubmitButton className='btn-icon' onClick={() => setSelected(!selected)}>
                                    <FontAwesomeIcon icon={selected ? faChevronDown : faChevronRight} className='text-primary' />
                                  </SubmitButton>
                                </>}
                            </td>
                          )}
                          {(editTitle || deleteTitle) &&
                            <td className='btn-column pr-4'>
                              {/* On ne peut modifier que les titres saisis manuellement */}
                              {((!title.from || title.from === ORIGIN_UNKNOWN) || procedureType === PROCEDURE_RENEWAL.value) && editTitle &&
                                <button className='btn btn-link text-primary' onClick={() => editTitle(titleIndex)}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </button>}
                              {deleteTitle &&
                                <button className='btn btn-link text-danger ml-3' onClick={() => onDelete(titleIndex)}>
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </button>}
                            </td>}
                        </tr>
                        {selected && currentVersion?.productClasses &&
                          <tr key={`${index}-products`}>
                            <td colSpan={getTableTitle().length}>
                              <CardBlock shadow className='mt-3'>
                                <ProductAndServiceListView
                                  productClasses={currentVersion?.productClasses}
                                  readOnly
                                />
                              </CardBlock>
                            </td>
                          </tr>}
                      </>
                    )
                  })}
              </tbody>
            </Table>
            <div className={isFastTrack ? 'row d-flex justify-content-between ml-2 mr-2' : ''}>
              {isFastTrack &&
                <span className='font-weight-bold'><FormattedMessage id='renewal_fast_track_checkbox' /></span>}
              {showDownloadTitleList &&
                <DownloadLink
                  name='titres-inpi.csv'
                  onClick={() => TitleService.createCSVFromTitles(titles)}
                  label={<FormattedMessage id='inscription_titles_download_csv' />}
                  classname='justify-content-end'
                />}
            </div>
          </>
        )
        : <div><FormattedMessage id='none' /></div>}
    </>
  )
}

export default OverviewTitles
