import {
  containsErrors,
  downloadFile,
  DownloadLink,
  FieldStatus,
  ModalComponent,
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION,
  Transaction,
  TransactionDocument,
  TransactionDocumentType,
  TransactionService as CommonTransactionService
} from '@inpi-marques/components'
import Message from 'constants/Message'
import React, { FC, useEffect, useState } from 'react'
import DocumentService from '../../../services/document/DocumentService'
import { FormattedMessage, useIntl } from 'react-intl'
import TransactionService from '../../../services/transaction/TransactionService'
import DocumentValidator from '../../requests/valitdator/DocumentValidator'
import { toast } from 'react-toastify'
import DocumentList from '../../list/DocumentList'
import { RootStateOrAny, useSelector } from 'react-redux'
import AddDocumentsWithTypeContent from '../documents/AddDocumentsWithTypeContent'
import NotificationService from '../../../services/transaction/NotificationService'

interface DocumentsOverviewProps {
  transaction: Transaction,
  showAddDocumentsButton?: boolean,
  isUserActive?: boolean,
  setReload?: () => void
}

export const MAX_NUMBER_FILE = 30

const DocumentsOverview: FC<DocumentsOverviewProps> = ({ transaction, showAddDocumentsButton, isUserActive, setReload }) => {
  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [fieldStatus, setFieldStatus] = useState<FieldStatus>({})
  const [documentToAddList, setDocumentToAddList] = useState<TransactionDocument[]>([])
  const [typeDocumentList, setTypeDocumentList] = useState<TransactionDocumentType[]>()
  const currentUser = useSelector((state: RootStateOrAny) => state.user.user)
  const intl = useIntl()

  useEffect(() => {
    // Récupération des types de documents
    TransactionService.getDocumentTypes(CommonTransactionService.getAdministrableContentProcedureType(transaction)).then((result) => {
      // Filtre des documents en fonction de l'utilisateur (déposant ou opposant)
      if ([PROCEDURE_OPPOSITION.value, PROCEDURE_NULLITY.value, PROCEDURE_REVOCATION.value].includes(transaction.procedureType)) {
        const account = transaction.ETSAccounts?.find((account) => account.email === currentUser.email)
        if (account?.code !== 'AGENT' && account?.code !== 'APPLICANT') {
          result = result.filter(r => r.needOwnerContestedMark)
        } else {
          result = result.filter(r => r.needTransactionOwner)
        }
      }
      setTypeDocumentList(result)
    })

    return () => {
      TransactionService.cancelRequest()
    }
  }, [transaction.procedureType])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  const handlePostDocuments = async () => {
    const status = DocumentValidator.validateDocuments(documentToAddList)
    setFieldStatus(status)

    let documentsInError: number[] = []
    if (!containsErrors(status)) {
      for await (const [index, document] of documentToAddList.entries()) {
        const addedDocument = await DocumentService.createDocument(document, transaction.id)
        if (addedDocument) {
          setDocuments([...(transaction?.documents ?? []), addedDocument])
        } else {
          documentsInError = [...documentsInError, index]
        }
      }

      setDocumentToAddList(documentToAddList.filter((_, docIndex) => documentsInError.includes(docIndex)))

      if (documentsInError?.length === 0) {
        setShowAddDocument(false)
        toast.success(Message.request_add_document_success)
      } else {
        toast.warning(Message.request_add_document_error)
      }
    } else {
      return null
    }
  }

  const downloadDocument = (transactionId: string, internalName: string) => {
    const notif = transaction.notifications?.find((notification: Notification) => {
      return notification.file?.internalName === internalName
    })
    if (notif) {
      NotificationService.downloadNotification(transactionId, notif.id).then((response) => {
        setReload && setReload()
        return downloadFile(response)
      })
    } else {
      return DocumentService.getDocumentFile(transactionId, internalName)
    }
  }

  const displayAsyncReceiptMessage = (response?: string | Blob | ArrayBuffer | null | undefined) => {
    if (!response || response?.size === 0) {
      toast.success(Message.request_download_receipt_async)
    }
  }

  return (
    <div>
      <DocumentList
        documents={documents?.sort((documentA, documentB) => documentA.createdAt < documentB.createdAt ? 1 : -1)}
        transaction={transaction}
        downloadDocument={downloadDocument}
      />
      <div className='d-flex align-items-end flex-column'>
        {showAddDocumentsButton &&
          <div>
            <button
              className='btn btn-block btn-outline-primary mr-0 ml-auto'
              onClick={() => setShowAddDocument(true)}
            >
              <FormattedMessage id='request_add_document_button' />
            </button>
          </div>}
        {isUserActive && documents && documents.length > 0 && (
          <div className='mt-2'>
            <DownloadLink
              label={<FormattedMessage id='request_add_document_receipt' />}
              onClick={() => DocumentService.getDocumentsReceipt(transaction.id)}
              onFinished={(response) => displayAsyncReceiptMessage(response)}
            />
          </div>
        )}
      </div>

      {showAddDocumentsButton && (
        <ModalComponent
          size='xl'
          show={showAddDocument}
          handleClose={() => setShowAddDocument(false)}
          title={<FormattedMessage id='request_add_document_button' />}
          customContent={() => AddDocumentsWithTypeContent(
            { documentToAddList, onDocumentToAddEdited: setDocumentToAddList, fieldStatus, setFieldStatus, transaction, typeDocumentList }
          )}
          onClick={handlePostDocuments}
          validateLabel={intl.formatMessage({ id: 'common_validate' })}
        />
      )}
    </div>
  )
}

export default DocumentsOverview
