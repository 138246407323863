import React, { FC } from 'react'

import {
  CardBlock,
  CheckboxField, Comment,
  DOCUMENT_COMMUNICABILITIES,
  DOCUMENT_FORMATS,
  downloadFile,
  EventType,
  FileBrowserField,
  FileButton, FO_USER_COMMENT_TYPE,
  OfficialDocument,
  Payment,
  References,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import IntervenantsOverview from '../transactions/overview/IntervenantsOverview'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdate } from '../../store/transaction/transactionActions'
import OfficialDocumentService from '../../services/officialDocument/OfficialDocumentService'
import OfficialDocumentTypesOverview from './form/officialDocumentsRequested/OfficialDocumentTypesOverview'
import DocumentsOverview from '../transactions/overview/DocumentsOverview'
import DocumentService from 'services/document/DocumentService'

interface OfficialDocumentOverviewProps {
    transaction: Transaction,
    isUserActive?: boolean,
    isRequestView?: boolean,
    setPayment?: (payment?: Payment) => void,
    setIsPaymentLoading?: (value: boolean) => void,
    onDocumentChanged?: (event: EventType) => void,
    onDocumentDeleted?: (event?: EventType) => void,
    documents?: TransactionDocument[],
    showAddDocumentsButton?: boolean,
    references?: References,
    setReload?: () => void
}

/**
 * Composant permettant d'afficher le récapitulatif d'une demande de documents officiels.
 * Il contient les blocs intervenants, liste de documents officiels et documents
 *
 * @param transaction - Objet transaction
 * @param isUserActive - si l'utilisateur est actif
 * @param isRequestView - si on est sur la derniere étape du formulaire
 * @param setPayment - Fonction pour modifier le paiement
 * @param setIsPaymentLoading - Fonction pour modifier que le calcul du paiement est en cours
 * @param onDocumentChanged - Fonction pour modifier un document
 * @param onDocumentDeleted - Fonction pour supprimer un document
 * @param documents - Les documents de la transaction
 * @param showAddDocumentsButton
 * @param references
 * @constructor
 */
const OfficialDocumentOverview: FC<OfficialDocumentOverviewProps> = ({
  transaction,
  isUserActive,
  isRequestView = false,
  setPayment,
  setIsPaymentLoading,
  onDocumentChanged,
  onDocumentDeleted,
  documents,
  showAddDocumentsButton,
  references,
  setReload
}) => {
  const dispatch = useDispatch()
  const officialDocument: OfficialDocument | undefined = transaction.officialDocument
  const comment: Comment|undefined = transaction.comments?.find((comment: Comment) => comment.commentType === FO_USER_COMMENT_TYPE)

  /**
   * Permet de recalculer le prix des redevances suivant si le demandeur est une administration
   * @param event
  */
  const handleIsAdministration = async (event: EventType) => {
    const { value } = event.target
    dispatch(storeTransactionUpdate({
      ...transaction,
      officialDocument: {
        ...transaction.officialDocument,
        isAdministration: value
      }
    }))
    value && onDocumentDeleted && onDocumentDeleted()
    getOfficialDocumentPaiement(value)
  }

  /**
   * Calcule le prix des redevances
   * @param isAdministration
   */
  const getOfficialDocumentPaiement = (isAdministration: boolean) => {
    setIsPaymentLoading && setIsPaymentLoading(true)
    OfficialDocumentService.getPaiement(transaction?.id, isAdministration).then((payment: Payment) => {
      if (payment) {
        setPayment && setPayment(payment)
      }
      setIsPaymentLoading && setIsPaymentLoading(false)
    })
  }

  const handleDownload = (document?:TransactionDocument, internalName?: string) => {
    const fileInternalName = internalName ?? document?.internalName
    const fileName = internalName ?? document?.name
    return transaction?.id && fileInternalName && DocumentService.getDocumentFile(transaction.id, fileInternalName).then(data => downloadFile(data, fileName))
  }

  const canDownloadDocument = (internalName?: string): boolean => {
    return !!(internalName && transaction.documents?.find(document => document.internalName === internalName && document.communicability === DOCUMENT_COMMUNICABILITIES.COMMUNICABLE))
  }

  return (
    <div className='col-12 d-flex flex-wrap p-0'>
      {isUserActive &&
        <>
          {/* Titres */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_official_document_documents' />} shadow>
              <div className='mb-3'>
                <OfficialDocumentTypesOverview officialDocument={officialDocument} handleDownload={handleDownload} canDownloadDocument={canDownloadDocument} />
              </div>
            </CardBlock>
          </div>

          {/* Intervenants */}
          <div className='col-12 mb-3'>
            <CardBlock header={<FormattedMessage id='overview_official_document_intervenants_title' />} shadow>
              <IntervenantsOverview transaction={transaction} procedureType={transaction.procedureType} />
              {isRequestView &&
                <>
                  <div className='d-flex mt-2'>
                    <CheckboxField
                      labelClassName='font-weight-bold'
                      inputId='isAdministration'
                      label={<FormattedMessage id='official_document_is_administration' />}
                      checked={transaction.officialDocument?.isAdministration}
                      onChange={handleIsAdministration}
                    />
                  </div>
                  {transaction.officialDocument?.isAdministration && (
                    <div className='d-flex'>
                      <FileBrowserField
                        label={<FormattedMessage id='official_document_justificatif_file' />}
                        inputId='file-justificatif'
                        value={documents}
                        onChange={onDocumentChanged}
                        onDelete={onDocumentDeleted}
                        maxNumberFile={1}
                        acceptApplication={DOCUMENT_FORMATS.PDF}
                        buttonLabel={<FileButton />}
                        handleDownload={handleDownload}
                      />
                    </div>
                  )}
                </>}
            </CardBlock>
          </div>

          {/* Récapitulatif documents */}
          {transaction.numNat &&
            <div className='col-12 mb-3' ref={references?.documents}>
              <CardBlock header={<FormattedMessage id='overview_file_title' />} shadow>
                <DocumentsOverview
                  transaction={transaction}
                  showAddDocumentsButton={showAddDocumentsButton}
                  isUserActive={isUserActive}
                  setReload={setReload}
                />
              </CardBlock>
            </div>}

          {/* Commentaires */}
          {comment?.content &&
            <div className='col-12 mb-3'>
              <CardBlock
                header={<FormattedMessage id='overview_frmi_comment_title' />} shadow
              >
                <span>{comment.content}</span>
              </CardBlock>
            </div>}
        </>}
    </div>
  )
}

export default OfficialDocumentOverview
