import React, { FunctionComponent } from 'react'
import { EventType, FieldProps, SelectOption } from '../FormInterfaces'
import ErrorField from '../errors/ErrorField'
import { resetError as resetErrorUtils, resetStateError } from '../../utils/formUtils'

interface SelectFieldProps extends FieldProps {
  options: SelectOption[]|Promise<SelectOption[]>,
  multiple?: boolean,
  required?: boolean,
}

const SelectField: FunctionComponent<SelectFieldProps> = ({
  className = '',
  inputId,
  classNameFormGroup,
  label,
  options,
  fieldStatus = {},
  value = '',
  onChange,
  onFocus,
  placeholder = '',
  disabled = false,
  readOnly = false,
  multiple = false,
  required = false,
  nameFieldStatus,
  classNameLabel,
  dispatch, resetError,
  addToRefs
}) => {
  /**
   * On clean l'erreur du champs au changement de sa valeur.
   * @param event
   */
  const onInternalChange = (event: EventType): void => {
    /** On clean l'erreur du store au changement */
    if (dispatch && resetError) {
      resetErrorUtils(dispatch, resetError, fieldStatus, nameFieldStatus || inputId)
    } else if (resetError) {
      resetStateError(resetError, fieldStatus, nameFieldStatus || inputId)
    }

    onChange && onChange(event)
  }
  return (
    <div className={`form-group ${classNameFormGroup || ''}`}>
      {label && (
        <label className={`form-label ${classNameLabel}`} htmlFor={inputId}>
          {label}
          {required && <span className='text-danger'> *</span>}
        </label>
      )}
      <select
        ref={(selectRef) => addToRefs && addToRefs(selectRef)}
        id={inputId}
        name={inputId}
        className={`form-control custom-select  one-line-ellipsis ${value === '' ? 'placeholder' : ''} ${className}`}
        placeholder={placeholder}
        onChange={onInternalChange}
        value={value}
        disabled={disabled || readOnly}
        multiple={multiple}
        onFocus={onFocus}
      >
        {placeholder && (
          <option key='placeholder' value='' className={required ? 'd-none' : ''}>{placeholder}</option>
        )}
        {options && options.length > 0 && options.map((option, index) => (
          <option key={`${index}-${option.value}`} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </select>

      <ErrorField message={fieldStatus[nameFieldStatus ?? inputId]} />
    </div>
  )
}

export default SelectField
