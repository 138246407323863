import http from '../../network/http-common'
import { toast } from 'react-toastify'
import { AnswerNotificationOMPI, NotificationOMPI } from '@inpi-marques/components'

/**
 * Classe permettant de faire des opérations sur une notification OMPI
 */
class NotificationOMPIService {
  /**
   * Répond à une notification
   * @param idTransaction
   * @param idNotification
   * @param answerNotification
   */
  answerNotificationOMPI = async (idTransaction: string, idNotification: string, answerNotification: AnswerNotificationOMPI): Promise<NotificationOMPI> => {
    try {
      return await http.post(`/api/transactions/${idTransaction}/notifications-ompi/${idNotification}/answers`, answerNotification)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }
}

export default new NotificationOMPIService()
