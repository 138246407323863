import React, { FC } from 'react'
import { ModalComponent } from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import AddressList from './AddressList'
import { Contact } from '../../interfaces/Contact'

interface ModalContactListProps {
  showModal: boolean,
  setShowModal: (show: boolean) => void,
  setSelectedContact: (contact: Contact) => void
}

const ModalContactList : FC<ModalContactListProps> = (
  {
    showModal,
    setShowModal,
    setSelectedContact
  }) => {
  return (
    <ModalComponent
      title={<FormattedMessage id='popin_import_from_address_book' />}
      customContent={() =>
        <AddressList
          canEdit={false}
          onContactSelect={(contact: Contact) => {
            setSelectedContact(contact)
            setShowModal(false)
          }}
        />}
      handleClose={() => setShowModal(false)}
      show={showModal}
      hideFooter
      size='xl'
    />
  )
}

export default ModalContactList
