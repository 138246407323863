import React, { FC } from 'react'
import moment from 'moment'
import {
  SENDING_PORTAL,
  Transaction
} from '@inpi-marques/components'
import OverviewNotificationBody from './OverviewNotificationBody'

interface OverviewNotificationsProps {
  transaction: Transaction,
  setReload?: ()=>void,
  scrollToProductAndService?: (id: string) => void
}

const OverviewNotifications: FC<OverviewNotificationsProps> = ({
  transaction,
  setReload,
  scrollToProductAndService
}) => {
  return (
    <div id='overview-notification'>
      {transaction?.notifications?.sort((a, b) => (a.sendingDate && !b.sendingDate) || moment(a.sendingDate) > moment(b.sendingDate) ? -1 : 1)
            .filter((notification) => notification.sendingMode && notification.sendingMode.includes(SENDING_PORTAL)
            )
            .map((notification, index) => {
              return (
                <OverviewNotificationBody
                  key={index}
                  notification={notification}
                  transaction={transaction}
                  index={index}
                  setReload={setReload}
                  scrollToProductAndService={scrollToProductAndService}
                />)
            })}
    </div>
  )
}

export default OverviewNotifications
