import React, { FC } from 'react'
import ErrorField from '../errors/ErrorField'
import { TextFieldProps } from './TextField'
import { Dropdown, DropDownItem } from '../../index'
import { EventType } from '../FormInterfaces'
import { resetError as resetErrorUtils, resetStateError } from '../../utils/formUtils'

interface InlineTextFieldProps extends TextFieldProps {
  inputClassName?: string,
  needDropDown?: boolean,
  dropDownItems?: DropDownItem[],
  dropDownName?: string,
  onDropDownOnSelect?: (item: DropDownItem) => void,
  dropDownClassName?: string
  name?: string
}

const InlineTextField: FC<InlineTextFieldProps> = ({
  type = 'text',
  className = '',
  inputId,
  label,
  fieldStatus = {},
  value = '',
  name = inputId,
  onChange,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false,
  children,
  inputClassName,
  needDropDown,
  dropDownItems,
  dropDownName,
  onDropDownOnSelect,
  dropDownClassName,
  nameFieldStatus,
  resetError,
  dispatch
}) => {
  const onInternalChange = (event: EventType): void => {
    onChange && onChange(event)

    /** On clean l'erreur du store au changement */
    if (dispatch && resetError) {
      resetErrorUtils(dispatch, resetError, fieldStatus, nameFieldStatus || inputId)
    } else if (resetError) {
      resetStateError(resetError, fieldStatus, nameFieldStatus || inputId)
    }
  }

  return (
    <div className={`inline-text-field ${className}`}>
      {label && <label className='form-label' htmlFor={inputId}>{label}{required && <span className='text-danger'> *</span>}</label>}

      <div className='input-group'>
        {needDropDown && (
          <div className={`input-group-prepend ${dropDownClassName}`}>
            <Dropdown
              items={dropDownItems}
              name={dropDownName}
              onSelect={onDropDownOnSelect}
              buttonClassName='text-dark'
              className='custom-dropdown-header nav-white'
              arrow
            />
          </div>
        )}
        <input
          type={type}
          id={inputId}
          name={name}
          className={`form-control one-line-ellipsis ${inputClassName}`}
          placeholder={placeholder}
          onChange={onInternalChange}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
        />
        <div className='input-group-append'>
          {children}
        </div>
      </div>

      <ErrorField message={fieldStatus[nameFieldStatus || inputId]} />
    </div>
  )
}

export default InlineTextField
