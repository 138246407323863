export const STATUS_ND = {
  /** Brouillon */
  DRAFT: 'draft_nd',
  /** En attente de mémoire administratif **/
  WAITING_VIR_PAYMENT: 'waiting_vir_payment_nd',
  /** mémoire administratif expiré **/
  VIR_PAYMENT_EXPIRED: 'vir_payment_expired_nd',
  /** Préparation **/
  PREPARATION: 'preparation_nd',
  /** Attribution **/
  TO_ATTRIBUTE: 'to_attribute_nd',
  /** Recherche TMC **/
  SEARCH_TMC: 'search_tmc_nd',
  /** A notifier **/
  TO_NOTIFY: 'to_notify_nd',
  /** En attente de réponse **/
  WAITING_ANSWER: 'waiting_answer_nd',
  /** Décision **/
  DECISION: 'decision_nd',
  /** Finalisation **/
  FINALISATION: 'finalisation_nd',
  /** Irrecevable **/
  NOT_ACCEPTED: 'not_accepted_nd',
  /** Clôture **/
  CLOSE: 'close_nd',
  /** Terminé **/
  DONE: 'done_nd',
  /** Suspension **/
  SUSPENSION: 'suspension_nd'
}

export const STATUS_ND_REVIEWING = [
  STATUS_ND.PREPARATION,
  STATUS_ND.TO_ATTRIBUTE,
  STATUS_ND.SEARCH_TMC,
  STATUS_ND.TO_NOTIFY,
  STATUS_ND.WAITING_ANSWER,
  STATUS_ND.DECISION,
  STATUS_ND.FINALISATION,
  STATUS_ND.SUSPENSION,
  STATUS_ND.NOT_ACCEPTED,
  STATUS_ND.CLOSE
]

export const STATUS_ND_FINAL = [
  STATUS_ND.DONE,
  STATUS_ND.FINALISATION,
  STATUS_ND.NOT_ACCEPTED
]
