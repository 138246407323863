export const LOGO_WHITE_SRC = '/img/logo-inpi-blanc.png'

export const MAIN_FOOTER_LINK = [
  {
    id: 'legal_notice',
    label: 'footer_legal_notice',
    url: 'https://www.inpi.fr/mentions-legales'
  },
  {
    id: 'personal_data',
    label: 'footer_personal_datas',
    url: 'https://www.inpi.fr/fr/donnees-personnelles'
  }, {
    id: 'cookies',
    label: 'footer_cookies',
    url: 'https://www.inpi.fr/fr/information-sur-les-cookies'
  },
  {
    id: 'contact_us',
    label: 'footer_contact_us',
    url: 'https://www.inpi.fr/fr/contactez-nous'
  },
  {
    id: 'cgu',
    label: 'footer_cgu',
    url: '/aide/CGU_Portail_Marques_2022.pdf'
  }
]

export const HELP_TYPE_CPU = 'CPU'
