import React, { FC, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  DateUtils,
  DOCUMENT_FORMATS,
  downloadFile,
  FileBrowserField,
  NotificationOMPI,
  TextArea,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import DocumentService from '../../../services/document/DocumentService'

interface OverviewNotificationOMPIInfoProps {
  notification: NotificationOMPI,
  transaction: Transaction
}

/**
 * Composant contenant les informations générales sur l'avis d'irrégularité d'une notification OMPI
 *
 * @param notification - Objet notification
 * @param transaction - Objet transaction
 * @constructor
 */
const OverviewNotificationOMPIInfo:FC<OverviewNotificationOMPIInfoProps> = ({
  notification,
  transaction
}) => {
  const contentRef = useRef(null)

  const handleDownload = (document: TransactionDocument) => {
    return transaction.id && document.internalName && DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => {
      downloadFile(data, document.name)
    })
  }

  return (
    <div>
      <div>
        <h3><FormattedMessage id='overview_transaction_notification_ompi_content_label' /></h3>
      </div>
      <div ref={contentRef} id={`notification-${notification.id}`} className='border p-2 mb-2 row'>
        <div className='col-4 d-flex flex-column-center justify-content-around'>
          <div className='d-flex flex-column-center'>
            <span className='font-weight-bold'><FormattedMessage id='overview_transaction_notification_ompi_sending_date_label' /></span>
            <span>{DateUtils.formatDateFr(notification.sendingDate)}</span>
          </div>
          <div className='d-flex flex-column-center'>
            <span className='font-weight-bold'><FormattedMessage id='overview_transaction_notification_ompi_answer_before_date_label' /></span>
            <span>{DateUtils.formatDateFr(notification.deadlineDateTime)}</span>
          </div>

        </div>
        <div className='col-4 d-flex flex-column-center'>
          <span className='font-weight-bold'><FormattedMessage id='overview_transaction_notification_ompi_ref_ompi_label' /></span>
          <span>{notification.ompiRef ?? '-'}</span>
          <span className='font-weight-bold'><FormattedMessage id='overview_transaction_notification_ompi_ref_inpi_label' /></span>
          <span>{transaction.numNat ?? '-'}</span>
          <span className='font-weight-bold'><FormattedMessage id='overview_transaction_notification_ompi_ref_depositor_label' /></span>
          <span>{notification.depositorRef ?? '-'}</span>
        </div>
        <div className='col-4 d-flex flex-column-center'>
          <span className='font-weight-bold'><FormattedMessage id='overview_transaction_notification_ompi_mobile_label' /></span>
          <span>{notification.contactPhone ?? '-'}</span>
          <span className='font-weight-bold'><FormattedMessage id='overview_transaction_notification_ompi_email_label' /></span>
          <span>{notification.contactEmail ?? '-'}</span>
          <span className='font-weight-bold'><FormattedMessage id='overview_transaction_notification_ompi_examinator_label' /></span>
          <span>{notification.contactName ?? '-'}</span>
        </div>
        {notification.irregularitiesNotices && notification.irregularitiesNotices.length > 0 &&
          <div className='form-group col-12 mt-3'>
            <span className='text-gris'><FormattedMessage id='overview_transaction_notification_ompi_irregularities_notices_label' /></span>
            {notification.irregularitiesNotices?.map((irreg) => {
              return (
                <TextArea
                  inputId='irregularitiesNotices'
                  classNameFormGroup='mb-2'
                  value={irreg.text}
                  disabled
                  rows={5}
                />)
            })}
          </div>}
        {notification.ompiFees && notification.ompiFees?.length > 0 &&
          <div className='form-group col-12 mt-3'>
            <span className='text-gris'><FormattedMessage id='overview_transaction_notification_ompi_fees_and_taxes_label' /></span>
            {notification.ompiFees?.map((fee) => {
              return (
                <div className='container-border col p-2 mb-2 m-1 row'>
                  {fee.ompiFeeAmounts?.map((feeAmount) => {
                    return (
                      <>
                        <div className='col-8 d-flex justify-content-start'>
                          <span>{feeAmount.description}</span>
                        </div>
                        <div className='col-2 d-flex justify-content-end'>
                          <span>{feeAmount.country}</span>
                        </div>
                        <div className='col-2 d-flex justify-content-end'>
                          <span>{feeAmount.amount ? feeAmount.amount + ' CHF' : ''}</span>
                        </div>
                      </>)
                  })}
                  <div className='col-12 d-flex justify-content-end'>
                    <span className='font-weight-bold'>{fee.totalAmount ? fee.totalAmount + ' CHF' : ''}</span>
                  </div>
                </div>)
            })}
          </div>}

        {transaction.documents?.filter((doc) => notification.attachments?.includes(doc.internalName))?.length > 0 &&
          <FileBrowserField
            inputId='documents-ompi'
            label={<FormattedMessage id='overview_transaction_notification_ompi_documents_label' />}
            className='col-12 mt-3'
            classNameFormGroup='col-12'
            buttonLabel={
              <div className='border'>
                <div className='text-center my-1 mx-2'>
                  <FormattedMessage id='button_find_file' />
                </div>
              </div>
            }
            acceptApplication={DOCUMENT_FORMATS.PDF}
            value={transaction.documents?.filter((doc) => notification.attachments?.includes(doc.internalName))}
            handleDownload={handleDownload}
            readonly
          />}
      </div>
    </div>
  )
}

export default OverviewNotificationOMPIInfo
