import {
  CheckboxField,
  Contributor,
  EventType,
  FieldStatus,
  OfficialDocumentRequested,
  TextField
} from '@inpi-marques/components'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface OfficialDocumentSendingModeProps {
    fieldStatus: FieldStatus,
    officialDocumentRequested?: OfficialDocumentRequested,
    setOfficialDocumentRequest: (document: OfficialDocumentRequested) => void,
    currentETSUser?: Contributor,
    canShowDAS?: boolean
}

/**
 * Composant contenant le formulaire pour choisir le type d'envoi du document
 *
 * @param fieldStatus - Objet contenant les erreurs du formulaire
 * @param officialDocumentRequested - Objet correspondant à la demande
 * @param setOfficialDocumentRequest - Fonction pour modifier la demande
 * @param currentETSUser - Objet contenant les données de l'utilisateur connecté
 * @param canShowDAS - Booléen pour savoir s'il faut afficher l'option DAS
 * @constructor
 */
const OfficialDocumentSendingMode: FC<OfficialDocumentSendingModeProps> = ({
  fieldStatus,
  officialDocumentRequested,
  setOfficialDocumentRequest,
  currentETSUser,
  canShowDAS = false
}) => {
  const intl = useIntl()

  const [usePostalRoute, setUsePostalRoute] = useState<boolean|undefined>(officialDocumentRequested?.usePostalRoute)
  const [useDAS, setUseDAS] = useState<boolean|undefined>(officialDocumentRequested?.useDAS)
  const [usePdfPortal, setUsePdfPortal] = useState<boolean|undefined>(officialDocumentRequested?.usePdfPortal)
  const [secondaryEmail, setSecondaryEmail] = useState<string|undefined>(officialDocumentRequested?.secondaryEmail)
  const email: string|undefined = currentETSUser?.email

  /**
   * Met à jour le state OfficialDocumentRequest avec l'email de l'utilisateur connecté
   */
  useEffect(() => {
    setOfficialDocumentRequest({ ...officialDocumentRequested, email: email })
  }, [])

  useEffect(() => {
    setUseDAS(officialDocumentRequested?.useDAS)
    setUsePostalRoute(officialDocumentRequested?.usePostalRoute)
    setSecondaryEmail(officialDocumentRequested?.secondaryEmail)
  }, [officialDocumentRequested])

  const onOfficialDocumentRequestedValueChanged = (event: EventType) => {
    const { name, value } = event.target
    const updatedDocument: OfficialDocumentRequested = { ...officialDocumentRequested, [name]: value }
    setSecondaryEmail(value)
    setOfficialDocumentRequest(updatedDocument)
  }

  const onUseDASChange = (event: EventType) => {
    const useDAS = event.target.value
    let updatedSecondaryEmail = secondaryEmail
    setUseDAS(useDAS)
    if (!useDAS) {
      updatedSecondaryEmail = undefined
      setSecondaryEmail(updatedSecondaryEmail)
    }
    setOfficialDocumentRequest({ ...officialDocumentRequested, useDAS, secondaryEmail: updatedSecondaryEmail })
  }

  const onUsePostalChange = (event: EventType) => {
    const usePostal = event.target.value
    setUsePostalRoute(usePostal)
    setOfficialDocumentRequest({ ...officialDocumentRequested, usePostalRoute: usePostal })
  }

  const onUsePdfPortalChange = (event: EventType) => {
    const usePortal = event.target.value
    setUsePdfPortal(usePortal)
    setOfficialDocumentRequest({ ...officialDocumentRequested, usePdfPortal: usePortal })
  }

  return (
    <div className='row mt-3'>
      <div className='col-12 mb-2'>
        <h2><FormattedMessage id='official_documents_sending_mode_title' /></h2>
      </div>
      <div className='row col-12 m-0'>
        <CheckboxField
          inputId='usePostalRoute'
          className='col-3'
          label={intl.formatMessage({ id: 'official_documents_type_postal_route' })}
          value={usePostalRoute}
          checked={usePostalRoute}
          onChange={onUsePostalChange}
          fieldStatus={fieldStatus}
        />
        <CheckboxField
          inputId='usePdfPortal'
          className='col-4'
          label={intl.formatMessage({ id: 'official_documents_type_pdf_portal_route' })}
          value={usePdfPortal}
          checked={usePdfPortal}
          onChange={onUsePdfPortalChange}
          fieldStatus={fieldStatus}
        />
        {canShowDAS &&
          <CheckboxField
            inputId='useDAS'
            className='col-3 ml-5'
            label={intl.formatMessage({ id: 'official_documents_type_das_route' })}
            value={useDAS}
            checked={useDAS}
            onChange={onUseDASChange}
            fieldStatus={fieldStatus}
          />}
      </div>
      {canShowDAS && useDAS && (
        <div className='row col-12 mt-3'>
          <div className='col-12 col-md-4'>
            <TextField
              inputId='email'
              fieldStatus={fieldStatus}
              label={intl.formatMessage({ id: 'official_documents_type_email_label' })}
              value={email}
              required
              readOnly
              placeholder={intl.formatMessage({ id: 'official_documents_type_email_placeholder' })}
            />
          </div>
          <div className='col-12 col-md-4'>
            <TextField
              inputId='secondaryEmail'
              fieldStatus={fieldStatus}
              label={intl.formatMessage({ id: 'official_documents_type_secondary_email_label' })}
              value={secondaryEmail}
              onChange={onOfficialDocumentRequestedValueChanged}
              placeholder={intl.formatMessage({ id: 'official_documents_type_secondary_email_placeholder' })}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default OfficialDocumentSendingMode
