import React, { FC, ReactNode } from 'react'
import {
  BUTTON_DELETE,
  BUTTON_VALIDATION_TITLE,
  CheckboxField,
  DownloadLink,
  EventType,
  ORDER_ASC,
  ORDER_DESC,
  Permission,
  ROWS,
  SelectOption,
  SubmitButton,
  TableOrderBy,
  TableTitleItem
} from '..'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import PaginationTable from '../navigation/PaginationTable'
import { BUTTON_VALIDATION_RECORD } from '../constants/TransactionConstants'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import ExamButton from '../transaction/examButton/ExamButton'

type TableProps = {
  tableTitle: TableTitleItem[],
  onSort?: (value?: string) => void,
  totalPage?: number,
  totalSize?: number,
  actualPage?: number,
  onChangePagination?: (page: number) => any | Promise<any>,
  nbShown?: SelectOption[],
  valueSelect?: number,
  onChangeSelect?: (event: EventType) => void,
  sortBy?: TableOrderBy,
  tableClassName?: string,
  selectAll?: boolean,
  onSelectAll?: (value: boolean) => void,
  labelSelectAll?: ReactNode,
  showPagination?: boolean,
  id?: string,
  keyTable?:string,
  colorHover?: boolean,
  isEditing?: boolean,
  onDeleteAll?: () => void,
  paginationClassName?: string,
  hasDoublePagination?: boolean,
  checkedList?: string[],
  openMultipleTabs?: () => void,
  onValidateAllTitles?: () => void,
  checkAllValidations?: boolean,
  isLoading?: boolean,
  tableRef?: React.Ref<any>,
  validationRecordComponent?: React.ReactNode,
  permission?: string
};

const Table: FC<TableProps> = ({
  tableTitle,
  onSort,
  totalPage = 0,
  totalSize,
  actualPage,
  onChangePagination,
  nbShown,
  valueSelect,
  onChangeSelect,
  children,
  sortBy,
  tableClassName = '',
  selectAll = false,
  onSelectAll,
  labelSelectAll = '',
  showPagination = true,
  id,
  keyTable = null,
  colorHover = false,
  isEditing = false,
  onDeleteAll,
  paginationClassName = '',
  hasDoublePagination,
  checkedList,
  openMultipleTabs,
  onValidateAllTitles,
  checkAllValidations,
  isLoading,
  tableRef,
  validationRecordComponent,
  permission
}) => {
  return (
    <IntlProvider locale='fr' messages={Message}>
      {((hasDoublePagination && showPagination) || onSelectAll) && (
        <div className='d-flex flex-column mt-2'>
          {
            hasDoublePagination && showPagination &&
              <PaginationTable
                actualPage={actualPage}
                totalPage={totalPage}
                totalSize={totalSize}
                valueSelect={valueSelect}
                nbShown={nbShown}
                rows={ROWS}
                paginationClassName={`mt-4 mb-n2 ${paginationClassName}`}
                onChangePagination={onChangePagination}
                onChangeSelect={onChangeSelect}
              />
          }
          {onSelectAll && openMultipleTabs && <DownloadLink disabled={checkedList?.length === 0} icon={false} classname='justify-content-start position-relative' label={<FormattedMessage id='basket_open_multiple_transactions' />} onClick={() => openMultipleTabs()} />}
        </div>
      )}

      <table className={`table table-striped ${colorHover ? 'color-text-hover-primary' : ''} ${tableClassName}`} ref={tableRef}>
        <thead>
          <tr>
            {onSelectAll && (
              <Permission
                name={permission}
                guard={() => !isEditing}
                yes={() =>
                  <th scope='col' className='table-checkbox border-0'>
                    <CheckboxField
                      inputId={`tableCheckAll${id || ''}`}
                      className='ml-4 pl-0'
                      label={labelSelectAll}
                      onChange={() => onSelectAll(!selectAll)}
                      checked={selectAll}
                    />
                  </th>}
                no={() => <></>}
              />
            )}
            {tableTitle.map((title, index) => (
              <th key={`${keyTable}-${index}` || title.label} colSpan={title.colSpan} scope='col' className={`border-0 ${title.className || ''}`}>
                {
                  (!title.value || (title.value !== BUTTON_DELETE && title.value?.indexOf(BUTTON_VALIDATION_RECORD) === -1 && title.value?.indexOf(BUTTON_VALIDATION_TITLE) === -1)) &&
                    <SubmitButton
                      className={`btn-text text-left fit-parent ${title.className && (title.className.indexOf('w-') < 0)
                            ? title.className : ''}${sortBy?.field === title.value && ' d-flex align-items-center flex-nowrap'}`}
                      onClick={() => onSort && !title.isNotSortingValue && onSort(title.value)}
                      disabled={!onSort || !title.value || title.isNotSortingValue}
                    >
                      <span>{title.label}{title.required && isEditing && <span className='text-danger'> *</span>}</span>
                      <span className='ml-1'>
                        {sortBy &&
                          sortBy.field === title.value &&
                          sortBy.order === ORDER_ASC &&
                          (<FontAwesomeIcon icon={faChevronUp} />)}
                        {sortBy &&
                            sortBy.field === title.value &&
                            sortBy.order === ORDER_DESC &&
                            (<FontAwesomeIcon icon={faChevronDown} />)}
                      </span>
                    </SubmitButton>

                }
                {
                  title.value === BUTTON_DELETE &&
                    <DownloadLink
                      label={<FormattedMessage id={title.label} />}
                      onClick={() => onDeleteAll && onDeleteAll()}
                      icon={false}
                    />
                }
                {
                  onValidateAllTitles && title.value && title.value?.indexOf(BUTTON_VALIDATION_TITLE) !== -1 && permission &&
                    <>
                      <Permission
                        name={permission}
                        guard={() => !isEditing}
                        yes={() =>
                          isLoading ? (
                            <FontAwesomeIcon className='loader mr-3' icon={faSpinner} />
                          ) : (
                            <ExamButton
                              className='mr-3'
                              isFilled={!!checkAllValidations}
                              onClick={onValidateAllTitles}
                            />)}
                        no={() =>
                          <ExamButton
                            className='mr-3'
                            isFilled={!!checkAllValidations}
                            onClick={onValidateAllTitles}
                            disabled
                          />}
                      />
                    </>
                }
                {
                  title.value && title.value?.indexOf(BUTTON_VALIDATION_RECORD) !== -1 &&
                  validationRecordComponent
                }
              </th>
            ))}
          </tr>
        </thead>
        {children}
      </table>
      {
        showPagination &&
          <PaginationTable
            actualPage={actualPage}
            totalPage={totalPage}
            totalSize={totalSize}
            valueSelect={valueSelect}
            nbShown={nbShown}
            rows={ROWS}
            paginationClassName={`mt-4 mb-n2 ${paginationClassName}`}
            onChangePagination={onChangePagination}
            onChangeSelect={onChangeSelect}
          />
      }
    </IntlProvider>
  )
}

export default Table
