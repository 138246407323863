import { TransactionDocument, FieldStatus, isFilled } from '@inpi-marques/components'
import Message from '../../../constants/Message'

class DocumentValidator {
  /**
   * Retourne les erreurs éventuelles sur un document ajouté
   */
  validateDocuments = (documentToAddList: TransactionDocument[], allowEmpty?: boolean = false): FieldStatus => {
    if (!allowEmpty && documentToAddList.length === 0) {
      return {
        baseFileInput: Message.request_add_document_no_file
      }
    }

    let newFieldStatus: FieldStatus = {}
    documentToAddList.forEach((documentToAdd, index) => {
      if (!isFilled(documentToAdd.type) || documentToAdd.type === '') {
        newFieldStatus = { ...newFieldStatus, [`type_${index}`]: Message.required_field }
      }
    })
    return newFieldStatus
  }

  /**
   * Retourne les erreurs sur un document de type fax
   * @param document
   */
  validateFaxDocument = (document: TransactionDocument): FieldStatus => {
    let fieldStatus = {}
    if (!document || !document.createdAt) {
      fieldStatus = {
        createdAt: Message.required_field
      }
    }

    if (!document || !document.name) {
      fieldStatus = {
        ...fieldStatus,
        fileInputFax: Message.required_field
      }
    }

    return fieldStatus
  }

  /**
   * Retourne les erreurs sur un document de type erratum
   * @param document
   */
  validateErratumDocument = (document: TransactionDocument): FieldStatus => {
    let fieldStatus = {}

    if (!document || !document.name) {
      fieldStatus = {
        ...fieldStatus,
        fileInputErratum: Message.required_field
      }
    }

    return fieldStatus
  }
}

export default new DocumentValidator()
