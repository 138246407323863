import React, { FunctionComponent, ReactNode } from 'react'
import ErrorField from '../errors/ErrorField'
import { FieldStatus } from '../FormInterfaces'
import Switch from 'react-switch'
import variables from '../../styles/variables.scss'

export interface SwitchFieldProps {
    fieldStatus?: FieldStatus | any,
    onChange?: (
        checked: boolean,
        event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
        id: string
    ) => void,
    inputId: string,
    label?: ReactNode,
    value?: any,
    className?: string,
    disabled?: boolean,
    onColor?: string,
    offColor?: string,
    uncheckedIcon?: boolean,
    checkedIcon?: boolean,
    nameFieldStatus?: string,
    height?: number,
    width?: number,
    divClassName?: string
    displayStringValue?: boolean
    labelClassName?: string,
    hideSwitch?: boolean
}

const SwitchField: FunctionComponent<SwitchFieldProps> = ({
  divClassName,
  className = '',
  inputId,
  label,
  fieldStatus = {},
  value = false,
  onChange,
  onColor = variables.primary,
  offColor = variables.secondary,
  disabled = false,
  uncheckedIcon = false,
  nameFieldStatus,
  checkedIcon = false,
  height = 30,
  width = 50,
  displayStringValue,
  labelClassName,
  hideSwitch = false
}) => {
  return (
    <div className={`form-group d-flex ${divClassName || 'flex-column'}`} onClick={(event) => event.stopPropagation()}>
      {label && <label className={`form-label ${labelClassName}`} htmlFor={inputId}>{label}</label>}
      {!hideSwitch && onChange &&
        <Switch
          id={inputId}
          name={inputId}
          className={`${className}`}
          onChange={onChange}
          checked={value}
          onColor={onColor}
          offColor={offColor}
          uncheckedIcon={uncheckedIcon}
          checkedIcon={checkedIcon}
          height={height}
          width={width}
          disabled={disabled}
        />}
      <ErrorField message={fieldStatus[nameFieldStatus || inputId]} />
      {displayStringValue && <span>{value ? 'Oui' : 'Non'}</span>}
    </div>
  )
}

export default SwitchField
