import {
  CardBlock,
  ErrorField,
  FieldStatus,
  FRMI_OPERATIONS_TYPES,
  FRMIType,
  ModalComponent,
  Transaction,
  FRMI,
  FRMI_TYPE_EFFECT_STOP,
  FRMI_DEFAULT_OFFICE_NAME
} from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { storeTransactionInitFrmi } from 'store/transaction/transactionActions'
import { FRMI_TYPES_FORMS } from '../../../../constants/frmi/FRMIConstants'
import { FRMIStep, FRMITypeForm } from '../../../../interfaces/frmi/FrmiInterfaces'
import ContributorService from '../../../../services/contributors/ContributorService'

interface FRMIPostOperationTypesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

/**
 * Formulaire du sélection d'un type d'opération postérieure
 */
const FRMIPostOperationTypes: FC<FRMIPostOperationTypesProps> = ({ transaction, fieldStatus }) => {
  const dispatch = useDispatch()

  const [selectedType, setSelectedType] = useState<FRMIType>()
  const user = useSelector((state: RootStateOrAny) => state.user.user)
  /**
   * A la sélection du type
   * @param type
   */
  const onTypeSelected = (type: FRMIType): void => {
    /** Certains types ne permettent pas à l'utilisateur de continuer le formulaire, on affiche donc une modale */
    if (type.hasModal) {
      setSelectedType(type)
    } else {
      // Pour l'opération R22, le nom de l'office qui envoie la notification est settée par défaut
      let currentDetails: FRMI = { ...transaction.frmiDetails }
      if (type.value === FRMI_TYPE_EFFECT_STOP.value && !currentDetails.officeName) {
        currentDetails = { ...currentDetails, officeName: FRMI_DEFAULT_OFFICE_NAME }
      }
      /* Pour gérer les conflits sur le récapitulatif d'une OP à cause de l'étape "Extension". Certaines OP peuvent étendre les pays et les P&S,
      d'autres uniquement les P&S. Pour éviter un problème d'affichage dans le récap on réinitialise donc les valeurs en cas de changement d'OP */
      if (type !== selectedType) {
        currentDetails = { ...currentDetails, countriesExtensionType: '', productsExtensionType: '' }
      }

      const hasRecipientStep = FRMI_TYPES_FORMS.find((form: FRMITypeForm) => form.type === type.value)?.steps?.some((step: FRMIStep) => step.id === 'recipient')

      dispatch(storeTransactionInitFrmi(type.value, currentDetails, !hasRecipientStep
        ? ContributorService.buildContributorFromUser(user)
        : undefined))
    }
  }

  /**
   * Lorsque l'on ferme la modal
   */
  const onModalClose = (): void => {
    setSelectedType(undefined)
  }

  return (
    <>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_post_operation_types_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_post_operation_types_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='mt-3 row'>
        {FRMI_OPERATIONS_TYPES.map((type: FRMIType, index: number) =>
          <div className='col-6 mt-3' key={index}>
            <CardBlock
              shadow
              onClick={() => onTypeSelected(type)}
              className={`theme-primary ${transaction.subProcedureType === type.value ? ' active' : ''}`}
              bodyClassName='p-4 flex-row align-items-center'
            >
              {type.label}
            </CardBlock>
          </div>
        )}
      </div>
      {fieldStatus.subProcedureType &&
        <div className='col-4'>
          <ErrorField message={fieldStatus.subProcedureType} className='fade alert alert-danger show position-relative mt-4' />
        </div>}
      <ModalComponent
        title={selectedType?.label}
        handleClose={onModalClose}
        show={!!selectedType}
        customContent={() => <FormattedMessage id={`frmi_post_operation_types_${selectedType?.value.toLocaleLowerCase()}_modal_text`} />}
        hideFooter
        size='lg'
      />
    </>
  )
}

export default FRMIPostOperationTypes
