import { HelpBlock, PaymentMethodContentProps, TextField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import Message from '../../../../../constants/Message'

const PaymentReceivedOMPIForm: FC<PaymentMethodContentProps> = ({ fieldStatus, onValueChange, paymentMethod }) => {
  return (
    <div className='paymentReceivedOMPI'>
      <div className='mt-3'>
        <HelpBlock>
          <FormattedMessage id='fees_and_taxes_payment_proof_description' />
        </HelpBlock>
        <HelpBlock className='mt-3'>
          <FormattedMessage
            id='fees_and_taxes_payment_ompi_page_description'
            values={{
              a: (message : ((string) | '')) => (<a href={Message.fees_and_taxes_payment_ompi_page_link} target='_blank' rel='noopener noreferrer'><u>{message}</u></a>),
              linebreak: <br />
            }}
          />
        </HelpBlock>
      </div>
      <div className='mt-3'>
        <div className='col-12'>
          <TextField
            inputId='paymentAuthor'
            required
            value={paymentMethod?.paymentAuthor}
            label={<FormattedMessage id='fees_and_taxes_payment_author' />}
            onChange={onValueChange}
            fieldStatus={fieldStatus}
            nameFieldStatus='paymentReceivedPaymentAuthor'
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
        <div className='col-12'>
          <TextField
            inputId='ompiReceiptNumber'
            required
            value={paymentMethod?.ompiReceiptNumber}
            label={<FormattedMessage id='fees_and_taxes_ompi_receipt_number' />}
            onChange={onValueChange}
            fieldStatus={fieldStatus}
            nameFieldStatus='paymentReceivedOmpiReceiptNumber'
            resetError={storeTransactionFieldStatusUpdate}
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentReceivedOMPIForm
