import { BASIC_DATE, DateUtils, TransactionInfos } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

interface TransactionFromTitleRegisteredOverviewProps {
  transactionFromTitle?: TransactionInfos
}

const TransactionFromTitleRegisteredOverview: FC<TransactionFromTitleRegisteredOverviewProps> = ({
  transactionFromTitle
}) => {
  return (
    <>
      <div className='card'>
        <div className='col-12 p-0'>
          <div className='d-flex'>
            <div className='mr-5 d-flex flex-column'>
              <span className='transaction-info'><FormattedMessage id='official_documents_requested_column_label_numNat' /></span>
              <span>{transactionFromTitle?.numNat}</span>
            </div>
            <div className='d-flex flex-column'>
              <span className='transaction-info'><FormattedMessage id='official_documents_requested_column_label_arrival_date' /> </span>
              <span>{DateUtils.formateDateToFormat(transactionFromTitle?.arrivalDate, BASIC_DATE) || ''}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionFromTitleRegisteredOverview
