import React, { FC, useEffect, useState } from 'react'

import { Country, Seniority, Table } from '@inpi-marques/components'
import ContentService from '../../../../services/content/ContentService'
import { FormattedMessage } from 'react-intl'
import Message from '../../../../constants/Message'
import SenioritiesFRMIRowContent from './SenioritiesFRMIRowContent'

interface OverviewSenioritiesProps {
  seniorities: Seniority[],
  deleteSeniority?: (index: number) => void,
  editSeniority?: (index: number) => void,
  className?: string,
  hasTitle?: boolean,
  readOnly?: boolean
}

const OverviewSeniorities: FC<OverviewSenioritiesProps> = (
  {
    seniorities,
    editSeniority,
    deleteSeniority,
    className = '',
    hasTitle,
    readOnly = true
  }) => {
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    ContentService.getCountries().then((response: Country[]) => {
      setCountries(response)
    })
  }, [])

  return (
    <>
      {hasTitle && <h4 className='font-weight-bold'><FormattedMessage id='overview_frmi_seniorities_title' /></h4>}
      {seniorities && seniorities.length > 0
        ? (
          <Table
            tableTitle={[
              { label: Message.options_columns_label_id, className: 'table-column-label pl-2' },
              { label: Message.options_columns_label_number, className: 'table-column-label' },
              { label: Message.options_columns_label_date, className: 'table-column-label' },
              { label: Message.seniority_registered_date, className: 'table-column-label' },
              { label: Message.options_columns_label_country, className: 'table-column-label table-column-country' },
              {
                label: Message.products_and_services_class,
                className: 'table-column-label table-column-country'
              }
            ]}
            tableClassName={`priority-table ${className}`}
          >
            <tbody>
              {
                seniorities.map((seniority: Seniority, index: number) => {
                  const country: Country | undefined = countries.find(item => item.code === seniority.countryCode)
                  return (
                    <SenioritiesFRMIRowContent
                      key={`row-seniority-${index}`}
                      seniority={seniority}
                      index={index}
                      country={country}
                      editSeniority={editSeniority}
                      deleteSeniority={deleteSeniority}
                      readOnly={readOnly}
                    />
                  )
                })
              }
            </tbody>
          </Table>
        )
        : <div><FormattedMessage id='none_feminine' /></div>}
    </>
  )
}

export default OverviewSeniorities
