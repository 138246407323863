import { DOCUMENT_FORMATS, DOCUMENT_TYPES, EventType, FileBrowserField, FileButton, FoundationService, OppositionFoundationTypeComponent, PROCEDURE_OPPOSITION, TextArea, TextField } from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../../../../constants/Message'
import FoundationActive from '../../components/Active'

/**
 * FORMULAIRE D'UN FONDEMENT D'UNE ATTEINTE AU NOM D'UNE ENTITE PUBLIQUE
 */
const FoundationInstitutionForm: FC<OppositionFoundationTypeComponent> = ({ foundation, setFoundation, fieldStatus, onDocumentDeleted, onDocumentAdded, documents, procedureType, setFieldStatus, disableFiles, addToRefs, disableActiveField, handleDownload }) => (
  <IntlProvider locale='fr' messages={Message}>
    {/* IDENTIFIER LA MARQUE ANTERIEURE */}
    <div className='row align-items-start'>
      <TextField
        addToRefs={addToRefs}
        inputId='entity_name'
        label={<FormattedMessage id='foundation_entity_name_label' />}
        classNameFormGroup='col-4'
        onChange={(event: EventType) => setFoundation(FoundationService.getUpdatedFoundationByEntity(foundation, 'name', event.target.value))}
        value={foundation.entity?.name}
        required
        fieldStatus={fieldStatus}
        resetError={setFieldStatus}
      />
      {/* DOCUMENT JUSTIFIANT DE L'EXISTENCE ET DE LA PORTEE DU DROIT ANTERIEUR */}
      {!disableFiles &&
        <FileBrowserField
          className='col-4'
          inputId='rightFile'
          classNameButton='d-contents'
          buttonLabel={<FileButton />}
          label={<FormattedMessage id='foundation_right_file_label' />}
          acceptApplication={DOCUMENT_FORMATS.PDF}
          onChange={(event: EventType) => onDocumentAdded && onDocumentAdded(event, DOCUMENT_TYPES.FOUNDATION_ENTITY_PUBLIC_EXISTENCE)}
          value={FoundationService.getFoundationTransactionDocumentByType(documents, foundation.documents, DOCUMENT_TYPES.FOUNDATION_ENTITY_PUBLIC_EXISTENCE)}
          onDelete={(event: EventType) => onDocumentDeleted && onDocumentDeleted(event.target.value)}
          required={procedureType !== PROCEDURE_OPPOSITION.value}
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
          informationDoc
          handleDownload={handleDownload}
        />}
    </div>
    {procedureType === PROCEDURE_OPPOSITION.value &&
      <div className='row'>
        <h2 className='text-primary col-12'>
          <FormattedMessage id='foundation_form_activity_part_label_short' />
        </h2>
        {/* MISSIONS, ATTRIBUTIONS OU DOMAINES D'INTERVENTION */}
        <TextArea
          classNameFormGroup='col-4'
          inputId='activity'
          onChange={(event: EventType) => setFoundation({ ...foundation, activity: event.target.value })}
          value={foundation.activity}
          label={<FormattedMessage id='foundation_form_activity_label_short' />}
          required
          fieldStatus={fieldStatus}
          resetError={setFieldStatus}
        />
      </div>}
    {/* FONDEMENT ACTIF */}
    <FoundationActive foundation={foundation} setFoundation={setFoundation} disableActiveField={disableActiveField} />
  </IntlProvider>
)

export default FoundationInstitutionForm
