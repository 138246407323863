import { CardBlock, CheckboxField, DateUtils, DocumentsBlock, DocumentType, DOCUMENT_ADDITIONAL_TYPES, DOCUMENT_COMMUNICABILITIES, DOCUMENT_FORMATS, DOCUMENT_TYPES, DOCUMENT_TYPE_SUB_TYPE_REVOCATION_STATEMENT_ACT, DOCUMENT_TYPE_SUB_TYPE_REVOCATION_STATEMENT_SUPPORTING, downloadFile, ErrorField, EventType, FieldStatus, FileBrowserField, FileButton, RevocationStatement, TextArea, TextField, Transaction, TransactionDocument } from '@inpi-marques/components'
import InternalReferenceField from 'component/internalReference/InternalReferenceField'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import DocumentService from 'services/document/DocumentService'
import { storeTransactionUpdateRevocationStatement } from 'store/transaction/transactionActions'

interface RevocationStatementRequestFormProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    documents: TransactionDocument[],
    documentTypes: DocumentType[],
    onDocumentAdded: (document: TransactionDocument) => void,
    onDocumentEdited: (document: TransactionDocument) => void,
    onDocumentDeleted: (document: TransactionDocument)=> void,
}

const RevocationStatementRequestForm: FC<RevocationStatementRequestFormProps> = ({ transaction, documentTypes, documents, fieldStatus, onDocumentAdded, onDocumentDeleted, onDocumentEdited }) => {
  const dispatch = useDispatch()

  const [inscriptionNumber, setInscriptionNumber] = useState<string>(transaction.revocationStatement?.requestText ?? '')
  const [hasInscriptionNumber, setHasInscriptionNumber] = useState<boolean>(transaction.revocationStatement?.hasInscriptionNumber || !!inscriptionNumber.length)
  const [requestText, setRequestText] = useState<string>(transaction.revocationStatement?.requestText ?? '')

  useEffect(() => {
    const newInscriptionNumber: string = transaction.revocationStatement?.inscriptionNumber ?? ''
    setHasInscriptionNumber(!!transaction.revocationStatement?.hasInscriptionNumber || !!newInscriptionNumber.length)
    setInscriptionNumber(newInscriptionNumber)
  }, [transaction.revocationStatement])

  const onInscriptionNumberBlur = (): void => {
    dispatch(storeTransactionUpdateRevocationStatement({
      ...transaction.revocationStatement,
      inscriptionNumber
    }))
  }

  const onRequestTextBlur = (): void => {
    dispatch(storeTransactionUpdateRevocationStatement({
      ...transaction.revocationStatement,
      requestText
    }))
  }

  const onHasPendingTransactionChanged = (): void => {
    const newValue: boolean = !hasInscriptionNumber

    let updatedRequest: RevocationStatement = { ...transaction.revocationStatement }
    if (!newValue && inscriptionNumber) {
      updatedRequest = { ...updatedRequest, inscriptionNumber: '' }
    }

    dispatch(storeTransactionUpdateRevocationStatement({ ...updatedRequest, hasInscriptionNumber: newValue }))
  }

  const onRequestFileDeleted = (event: EventType): void => {
    const { value } = event.target
    onDocumentDeleted(value)
  }

  const onRequestFileChanged = (event: EventType): void => {
    const { value } = event.target
    const newFile: TransactionDocument = {
      type: DOCUMENT_TYPES.REVOCATION_STATEMENT_REQUEST,
      internalName: '',
      name: value.name,
      format: value.type,
      file: value,
      createdAt: DateUtils.now(),
      communicability: DOCUMENT_COMMUNICABILITIES.COMMUNICABLE
    }
    onDocumentAdded(newFile)
  }

  const handleDownload = (document:TransactionDocument) => {
    transaction?.id && document.internalName && DocumentService.getDocumentFile(transaction.id, document.internalName).then(data => downloadFile(data, document.name))
  }

  return (
    <div>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='revocation_statement_request_title' /></h1>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>

      <div className='row'>
        <CardBlock className='col-12 p-0 mb-3' shadow>
          <CheckboxField
            className='m-0'
            inputId='hasInscriptionNumber'
            label={<FormattedMessage id='revocation_statement_has_pending_inscription_label' />}
            onChange={() => onHasPendingTransactionChanged()}
            checked={hasInscriptionNumber}
            value={hasInscriptionNumber}
          />
          {hasInscriptionNumber &&
            <TextField
              classNameFormGroup='mt-3 col-6 p-0'
              inputId='inscriptionNumber'
              label={<FormattedMessage id='revocation_statement_inscription_number_label' />}
              fieldStatus={fieldStatus}
              onChange={(event: EventType) => setInscriptionNumber(event.target.value)}
              onBlur={() => onInscriptionNumberBlur()}
              value={inscriptionNumber}
            />}
        </CardBlock>
        <CardBlock className='col-12 p-0 mb-3' header={<FormattedMessage id='revocation_statement_request_title' />} shadow>
          <label className='font-weight-bold'><FormattedMessage id='revocation_statement_request_text_title' /></label>
          <TextArea
            inputId='requestText'
            label={<FormattedMessage id='revocation_statement_request_text_label' />}
            value={requestText}
            fieldStatus={fieldStatus}
            onChange={(event: EventType) => setRequestText(event.target.value)}
            onBlur={() => onRequestTextBlur()}
          />
          <FileBrowserField
            inputId='requestFile'
            buttonLabel={<FileButton />}
            value={documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.REVOCATION_STATEMENT_REQUEST)}
            className='col-12 mb-0 mt-2'
            label={<FormattedMessage id='revocation_statement_request_file_label' />}
            onChange={onRequestFileChanged}
            onDelete={onRequestFileDeleted}
            acceptApplication={DOCUMENT_FORMATS.PDF}
            fieldStatus={fieldStatus}
            handleDownload={handleDownload}
          />
          {fieldStatus.request &&
            <div className='mt-3'>
              <ErrorField message={fieldStatus.request} />
            </div>}
        </CardBlock>
        <CardBlock className='col-12 p-0 mb-3' header={<FormattedMessage id='revocation_statement_request_act_files_title' />} shadow>
          <DocumentsBlock
            additionalType={DOCUMENT_ADDITIONAL_TYPES.REVOCATION_STATEMENT_ACT}
            documents={documents.filter((document: TransactionDocument) => document.additionalType === DOCUMENT_ADDITIONAL_TYPES.REVOCATION_STATEMENT_ACT)}
            documentTypes={documentTypes.filter((documentType: DocumentType) => documentType.subTypes?.some((subType: string) => subType === DOCUMENT_TYPE_SUB_TYPE_REVOCATION_STATEMENT_ACT.value))}
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            fieldStatus={fieldStatus}
            handleDownload={handleDownload}
            useCommunicability
          />
        </CardBlock>
        <CardBlock className='col-12 p-0' header={<FormattedMessage id='revocation_statement_request_supporting_files_title' />} shadow>
          <DocumentsBlock
            additionalType={DOCUMENT_ADDITIONAL_TYPES.REVOCATION_STATEMENT_SUPPORTING}
            documents={documents.filter((document: TransactionDocument) => document.additionalType === DOCUMENT_ADDITIONAL_TYPES.REVOCATION_STATEMENT_SUPPORTING)}
            documentTypes={documentTypes.filter((documentType: DocumentType) => documentType.subTypes?.some((subType: string) => subType === DOCUMENT_TYPE_SUB_TYPE_REVOCATION_STATEMENT_SUPPORTING.value))}
            onDocumentAdded={onDocumentAdded}
            onDocumentDeleted={onDocumentDeleted}
            onDocumentEdited={onDocumentEdited}
            fieldStatus={fieldStatus}
            handleDownload={handleDownload}
            useCommunicability
          />
        </CardBlock>
      </div>

    </div>
  )
}

export default RevocationStatementRequestForm
