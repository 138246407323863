import http from '../../network/http-common'
import { UserAuth, UserInfo } from '../../interfaces/User'

class AuthService {
  async getUserInfo (user: UserAuth): Promise<UserInfo> {
    return await http.post('/api/sso/login', user)
  }

  async login (token: string): Promise<UserInfo> {
    try {
      return await http.get(`/api/sso/ets/${token}`)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new AuthService()
