import React, { FC, useState } from 'react'
import {
  BASIC_DATE,
  CardBlock,
  Country,
  DateUtils,
  ModalComponent,
  Priority,
  ProductAndServiceListView,
  SubmitButton
} from '@inpi-marques/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'

interface PrioritiesFRMIRowContentProps {
    priority: Priority,
    setPriority?: (newPriority: Priority) => void
    index: number,
    country: Country,
    editPriority: any,
    deletePriority: any
}

const PrioritiesFRMIRowContent: FC<PrioritiesFRMIRowContentProps> = ({ priority, index, country, editPriority, deletePriority }) => {
  const [showClass, setShowClass] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)

  return (
    <>
      <tr key={`-${index}`}>
        <td className='pl-4'>{index + 1}</td>
        <td>{priority.originalDepositNumber}</td>
        <td>{DateUtils.formateDateToFormat(priority.originalDepositDate, BASIC_DATE)}</td>
        <td>{country?.name}</td>
        <td>
          {priority.productAndServices && priority.productAndServices.length > 0
            ? (
              <>
                {priority.productAndServices?.map((product) => product.ref).join(', ')}
                <SubmitButton
                  className='btn-icon'
                  onClick={() => setShowClass(!showClass)}
                >
                  <FontAwesomeIcon
                    icon={showClass ? faChevronDown : faChevronRight}
                    className='text-primary'
                  />
                </SubmitButton>
              </>)
            : <></>}
        </td>
        {(editPriority || deletePriority) &&
          <td className='btn-column pr-4'>
            {editPriority &&
              <button
                className='btn btn-link text-primary'
                onClick={() => editPriority(index)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>}
            {deletePriority &&
              <button
                className='btn btn-link text-danger ml-3'
                onClick={() => setShowModalDelete(true)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>}
          </td>}
      </tr>
      {showClass && priority.productAndServices && priority.productAndServices?.length > 0 &&
        <tr>
          <td colSpan={editPriority ? 6 : 5}>
            <CardBlock shadow className='mt-3'>
              <ProductAndServiceListView
                productClasses={priority.productAndServices}
                readOnly
                isFRMIPriority
              />
            </CardBlock>
          </td>
        </tr>}
      <ModalComponent
        title={<FormattedMessage id='priority_claimed_delete_modal_title' />}
        show={showModalDelete}
        handleClose={() => setShowModalDelete(false)}
        customContent={() => <FormattedMessage id='priority_claimed_delete_modal_text' />}
        onClick={() => {
          deletePriority && deletePriority(index)
          setShowModalDelete(false)
        }}
      />
    </>
  )
}

export default PrioritiesFRMIRowContent
