import {
  ContentStoreAction,
  COUNTRIES_UPDATE,
  MANAGEABLE_QUALITIES_UPDATE, OFFICIAL_DOCUMENT_TYPES_UPDATE,
  PROTECTION_EXTENSIONS_UPDATE, RENEWAL_DELAYS_UPDATE
} from './contentStoreTypes'
import {
  Country,
  ManageableQuality,
  OfficialDocumentType,
  ProtectionExtension,
  RenewalDelay
} from '@inpi-marques/components'

export const storeCountriesUpdate = (countries: Country[]): ContentStoreAction => ({
  type: COUNTRIES_UPDATE,
  countries
})

export const storeManageableQualitiesUpdate = (qualities: ManageableQuality[]): ContentStoreAction => ({
  type: MANAGEABLE_QUALITIES_UPDATE,
  qualities
})

export const storeProtectionExtensionsUpdate = (protectionExtensions: ProtectionExtension[]): ContentStoreAction => ({
  type: PROTECTION_EXTENSIONS_UPDATE,
  protectionExtensions
})

export const storeOfficialDocumentTypesUpdate = (officialDocumentTypes: OfficialDocumentType[]): ContentStoreAction => ({
  type: OFFICIAL_DOCUMENT_TYPES_UPDATE,
  officialDocumentTypes
})

export const storeRenewalDelaysUpdate = (renewalDelays: RenewalDelay[]): ContentStoreAction => ({
  type: RENEWAL_DELAYS_UPDATE,
  renewalDelays
})
