import React, { FC } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  Country,
  ErrorField,
  EventType,
  FieldStatus,
  PROCEDURE_FRMI,
  ProductClass,
  ProductClassVersion
} from '@inpi-marques/components'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import ProductAndServicesFreeInput from '../../../deposit/form/productsAndServices/ProductAndServicesFreeinput'

interface PriorityFieldsProps extends WrappedComponentProps {
  limitedProductsAndServices?: ProductClassVersion,
  onChange: (event: EventType) => void,
  fieldStatus?: FieldStatus | any,
  setLimitedProductsAndServices: (newClassVersion: ProductClassVersion) => void,
  countries: Country[]
}

const LimitedProductsAndServicesFormFields: FC<PriorityFieldsProps> = (
  {
    limitedProductsAndServices,
    fieldStatus,
    setLimitedProductsAndServices,
    countries
  }) => {
  const animatedComponents = makeAnimated()

  const onProductClassesEdited = (productClasses: ProductClass[]): void => {
    setLimitedProductsAndServices({
      ...limitedProductsAndServices,
      productClasses: productClasses
    } as ProductClassVersion)
  }

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='col-6 form-group'>
          <label className='form-label'>
            <FormattedMessage id='limited_products_services_limitation_countries' />
          </label>
          <Select
            classNamePrefix='react-select'
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={limitedProductsAndServices?.countries}
            isSearchable
            isMulti
            id='countries'
            getOptionLabel={(option) => `${option?.name ?? option?.label} (${option.code})`}
            getOptionValue={(option) => option?.code}
            options={countries}
            onChange={(countries) => setLimitedProductsAndServices({ ...limitedProductsAndServices, countries })}
            placeholder={<FormattedMessage id='limited_products_services_limitation_countries_placeholder' />}
          />
          <ErrorField message={fieldStatus && fieldStatus.countries} />
        </div>

        <div className='form-group mt-2 pl-3'>
          <label className='form-label'>
            <FormattedMessage id='products_and_services_title' />
          </label>
          <ProductAndServicesFreeInput
            classname=''
            onProductClassesEdited={onProductClassesEdited}
            productClasses={limitedProductsAndServices?.productClasses ?? []}
            isEditable
            procedureType={PROCEDURE_FRMI.value}
            canAddClasses={false}
          />
          <ErrorField message={fieldStatus && fieldStatus.productsAndServices} />
        </div>
      </div>
    </>
  )
}

export default injectIntl(LimitedProductsAndServicesFormFields)
