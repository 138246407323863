import React, { Component, ReactElement } from 'react'
import { EventType, FieldStatus, FieldValidator } from './FormInterfaces'
import { containsErrors, validateFields } from '../utils/formUtils'
import { LooseObject } from '..'

interface FormManagerProps {
  object:LooseObject,
  validators:FieldValidator,
  onChange?:(event:EventType) => void,
  onChangeFile?:(event:EventType) => void
  view:ReactElement,
  onSubmit:() => Promise<any>,
  className?:string,
}

interface FormManagerState {
  fieldStatus:FieldStatus,
  isValidated:boolean,
}

class FormManager extends Component<FormManagerProps, FormManagerState> {
  constructor (props:FormManagerProps) {
    super(props)
    this.state = {
      fieldStatus: {},
      isValidated: false
    }
  }

  static defaultProps = {
    className: ''
  }

  /* Lorqu'un champ est modifié, on vérifie sa validité */
  handleChange = (event:EventType) => {
    const { onChange } = this.props
    this.verifyField(event)
    onChange && onChange(event)
  }

  /* Lorqu'un champ est modifié, on vérifie sa validité */
  handleChangeFile = (event:EventType) => {
    const { onChangeFile } = this.props
    this.verifyField(event)
    onChangeFile && onChangeFile(event)
  }

  /* Vérification du champ concerné par la modification utilisateur */
  verifyField = (event:EventType) => {
    const { fieldStatus } = this.state
    const { object, validators } = this.props
    const { name, value } = event.target

    const newObject = {
      ...object,
      [name]: value
    }

    const newFieldStatus = {
      ...fieldStatus,
      ...validateFields([name], newObject, validators)
    }

    this.setState({
      fieldStatus: newFieldStatus
    })
  }

  /* Vérification de la validation du formulaire */
  checkFormIsValid = () => {
    const { object, validators } = this.props

    const fieldnames = Object.keys(validators)

    const fieldStatus = validateFields(fieldnames, object, validators)
    const hasNoError = !containsErrors(fieldStatus)

    this.setState({
      fieldStatus,
      isValidated: true
    })

    return hasNoError
  }

  /* Envoi du formulaire si il est valide */
  submitForm = () => {
    const { onSubmit } = this.props
    if (this.checkFormIsValid() && onSubmit) {
      return onSubmit()
    }
    return Promise.resolve()
  }

  render () {
    const { fieldStatus, isValidated } = this.state
    const { object, view, className, children, ...args } = this.props

    const isValidatedClassName = isValidated ? 'is-validated' : ''

    return (
      <form className={`${isValidatedClassName} ${className}`}>
        {view &&
        React.cloneElement(
          view,
          {
            ...args,
            object,
            handleChange: this.handleChange,
            handleChangeFile: this.handleChangeFile,
            submitForm: this.submitForm,
            fieldStatus
          }
        )}
        {children}
      </form>
    )
  }
}

export default FormManager
