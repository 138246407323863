import axios, { AxiosResponse } from 'axios'

export const API_ADDRESS_SEARCH = 'https://api-adresse.data.gouv.fr/search/'

const http = axios.create({
  headers: {
    'Content-type': 'application/json'
  }
})

function onSuccess (response: AxiosResponse) {
  return response.data
}

function onReject (error: any) {
  return Promise.reject(error.response ? error.response.data : error)
}

http.interceptors.response.use(
  onSuccess, onReject
)

export default http
