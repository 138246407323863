import {
  CardBlock,
  DATE_ISO8601,
  DateUtils,
  ErrorField,
  EventType,
  FieldStatus,
  OMPIFees,
  PaymentMethod,
  Transaction
} from '@inpi-marques/components'
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { storeTransactionUpdateFRMI } from '../../../../store/transaction/transactionActions'
import { useDispatch } from 'react-redux'
import FRMIService from '../../../../services/frmi/FRMIService'
import FeesAndTaxesAmount from './FeesAndTaxesAmount'
import { PAYMENT_METHODS_TYPE_LIST } from '../../../../constants/PaymentMethodsConstants'

interface AccordionCustomHeader {
    children: ReactNode,
    eventKey: string,
    onChange: (isCollapsed: boolean, type: string) => void,
    currentType?: string
}

const ContextAwareToggle: FC<AccordionCustomHeader> = ({ children, eventKey, onChange, currentType }) => {
  const currentEventKey = useContext(AccordionContext)
  const isCurrentEventKey = currentEventKey === eventKey
  const decoratedOnClick = useAccordionToggle(eventKey, () => onChange(!isCurrentEventKey, eventKey))

  return (
    <div
      onClick={decoratedOnClick}
      className='accordion-header text-primary payment-method-label'
    >
      {children}
      <FontAwesomeIcon className='accordion-header__icon h-100' icon={currentType === eventKey ? faMinus : faPlus} />
    </div>
  )
}
interface FeesAndTaxesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus
}

const FeesAndTaxes: FC<FeesAndTaxesProps> = ({ transaction, fieldStatus }) => {
  const dispatch = useDispatch()
  const [ompiFees, setOmpiFees] = useState<OMPIFees>()
  const [isCalculatorLoading, setIsCalculatorLoading] = useState<boolean>(true)

  useEffect(() => {
    transaction.id && FRMIService.getOMPIFees(transaction.id).then((result) => {
      setOmpiFees(result)
      dispatch(storeTransactionUpdateFRMI({
        ...transaction.frmiDetails,
        totalAmountFees: result.totalAmount
      }))
      setIsCalculatorLoading(false)
    })
  }, [])

  const onValueChange = (event: EventType): void => {
    let { name, value } = event.target
    if (name === 'transferDate') {
      value = DateUtils.formateDateToFormat(value, DATE_ISO8601) ?? undefined
    }
    onPaymentMethodChange({ ...transaction.frmiDetails?.paymentMethod, [name]: value })
  }

  const onPaymentMethodChange = (updatedPaymentMethod: PaymentMethod): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      paymentMethod: updatedPaymentMethod
    }))
  }

  const onPaymentMethodTypeChange = (isCollapsed: boolean, type: string): void => {
    dispatch(storeTransactionUpdateFRMI({
      ...transaction.frmiDetails,
      paymentMethod: { type: isCollapsed ? type : undefined }
    }))
  }

  return (
    <div className='feesAndTaxes'>
      <div className='row mb-4 justify-content-between'>
        <header className='col-8'>
          <h1><FormattedMessage id='frmi_fees_and_taxes_title' /></h1>
          <span className='subtitle'><FormattedMessage id='frmi_fees_and_taxes_description' /></span>
        </header>
        <InternalReferenceField
          transaction={transaction}
          className='col-3'
        />
      </div>
      <div className='row col-lg-12 m-0'>
        <div className='col-6'>
          <CardBlock header={<FormattedMessage id='frmi_fees_and_taxes_block_title' />} shadow bodyClassName='p-3'>
            <FeesAndTaxesAmount ompiFees={ompiFees} isCalculatorLoading={isCalculatorLoading} />
          </CardBlock>
        </div>
        <div className='col-6'>
          <CardBlock header={<FormattedMessage id='frmi_payment_method_block_title' />} shadow bodyClassName='p-3'>
            {
              PAYMENT_METHODS_TYPE_LIST.map((paymentMethod, index) => (
                <div key={index}>
                  <Accordion className={index > 0 ? 'mt-3' : ''} defaultActiveKey={transaction.frmiDetails?.paymentMethod?.type}>
                    <Card>
                      <Card.Header className='cursor-pointer'>
                        <ContextAwareToggle eventKey={paymentMethod.value} onChange={onPaymentMethodTypeChange} currentType={transaction.frmiDetails?.paymentMethod?.type}>
                          <FormattedMessage id={paymentMethod.label} />
                        </ContextAwareToggle>
                      </Card.Header>
                      {
                        transaction.frmiDetails?.paymentMethod?.type === paymentMethod.value &&
                          <Accordion.Collapse eventKey={paymentMethod.value}>
                            <Card.Body className='p-4'>
                              <paymentMethod.component
                                fieldStatus={fieldStatus}
                                type={paymentMethod.value}
                                onValueChange={onValueChange}
                                paymentMethod={transaction.frmiDetails.paymentMethod}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                      }
                    </Card>
                  </Accordion>
                </div>
              ))
            }
            {fieldStatus.paymentMethod &&
              <div>
                <ErrorField message={fieldStatus.paymentMethod} />
              </div>}
          </CardBlock>
        </div>
      </div>
    </div>
  )
}

export default FeesAndTaxes
