import {
  INSCRIPTION_RENUNCIATION_TYPE_PARTIAL,
  INSCRIPTION_RENUNCIATION_TYPE_TOTAL,
  InscriptionNatureCode,
  Title,
  Transaction
} from '../../../src'
import { NatureCode } from '../../interfaces/inscription/Inscription'

class InscriptionService {
    /**
    * Récupère le premier code nature de l'inscription
    * (Inscription relative au mandataire)
    * @param natureCodes
    * @returns
    */
    getFirstCodeNature = (natureCodes: InscriptionNatureCode[]): InscriptionNatureCode|undefined =>
      natureCodes.length ? natureCodes[0] : undefined

    /**
   * Récupère le label du champs de suppression de mandataire en fonction du code nature
   * (Inscription relative au mandataire)
   * @returns
   */
   getOldAgentLabel = (natureCode?: InscriptionNatureCode): string =>
     natureCode ? `mandatary_old_agent_${natureCode.code?.toLowerCase()}_label` : 'mandatary_old_agent_label'

  /**
   * Récupère le label du champs d'ajout/édition de mandataire en fonction du code nature
   * (Inscription relative au mandataire)
   * @returns
   */
  getNewAgentLabel = (natureCode?: InscriptionNatureCode): string =>
    natureCode ? `mandatary_new_agent_${natureCode.code?.toLowerCase()}_label` : 'mandatary_new_agent_label'

  /**
     * S'il s'agit d'une renonciation total
     * @param transaction
     * @returns
     */
   isTotalRenunciation = (transaction: Transaction): boolean =>
    transaction.inscription?.renunciation?.type === INSCRIPTION_RENUNCIATION_TYPE_TOTAL.value

 /**
  * S'il s'agit d'une renonciation partielle
  * @param transaction
  * @returns
  */
 isPartialRenunciation = (transaction: Transaction): boolean =>
   transaction.inscription?.renunciation?.type === INSCRIPTION_RENUNCIATION_TYPE_PARTIAL.value

  /**
   * Recherche s'il existe des titres avec le même numNat
   * @param titles
   * @returns
   */
  hasDuplicatedTitlesByNumNat = (titles: Title[]): boolean =>
    titles.some((title1: Title) => titles.filter((title2: Title) => title2.numNat === title1.numNat).length > 1)

  isFieldMandatory = (inscriptionNatureCodes?: InscriptionNatureCode[], natureCodes?: NatureCode[], field?: string): boolean =>
    !!inscriptionNatureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => natureCodes?.some(natureCode => natureCode.code === inscriptionNatureCode.code && natureCode?.mandatoryFields?.includes(field)))
}

export default new InscriptionService()
