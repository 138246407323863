import React, { FC, useEffect, useState } from 'react'
import {
  CERTIFICATE_OF_IDENTITY_BRAND,
  Contributor,
  DATE_ISO8601,
  DateUtils,
  FieldStatus,
  OFFICIAL_COPY_BRAND,
  OFFICIAL_DOCUMENT_TYPE_COPY_TITLE,
  OFFICIAL_DOCUMENT_TYPE_COPY_TRANSACTION,
  OfficialDocumentRequested,
  OfficialDocumentType,
  Record,
  Title,
  Transaction,
  TransactionInfos
} from '@inpi-marques/components'
import TitleValidateContent from '../../../inscription/form/titles/TitleValidateContent'
import OfficialDocumentTypesService from '../../../../services/officialDocument/OfficialDocumentTypesService'
import OfficialDocumentTypeSelection from './officialDocumentType/selection/OfficialDocumentTypeSelection'
import OfficialDocumentTypeInformation from './officialDocumentType/information/OfficialDocumentTypeInformation'
import OfficialDocumentSendingMode from './officialDocumentType/sending-mode/OfficialDocumentSendingMode'
import TransactionFromTitle from './transactionsFromTitle/TransactionFromTitle'
import OfficialDocumentService from '../../../../services/officialDocument/OfficialDocumentService'
import { RootStateOrAny, useSelector } from 'react-redux'

interface OfficialDocumentTypesProps {
    transaction: Transaction,
    fieldStatus: FieldStatus,
    officialDocumentRequested?: OfficialDocumentRequested,
    setOfficialDocumentRequest: (document: OfficialDocumentRequested) => void,
    isEditingMode: boolean,
    currentETSUser?: Contributor
}

/**
 * Composant contenant le formulaire pour ajouter un type de document officiel
 * avec le titre associé
 *
 * @param transaction - Objet transaction
 * @param fieldStatus - Objet contenant les erreurs sur les champs
 * @param officialDocumentRequested - Objet regroupant les infos sur la demande d'un document officiel
 * @param setOfficialDocumentRequest - Fonction pour modifier les infos de la demande
 * @param isEditingMode - Booléen pour savoir si on est à l'édition
 * @param currentETSUser - Objet qui contient les données de l'utilisateur connecté
 * @constructor
 */
const OfficialDocumentRequestedForm: FC<OfficialDocumentTypesProps> = ({
  transaction,
  fieldStatus,
  officialDocumentRequested,
  setOfficialDocumentRequest,
  isEditingMode,
  currentETSUser
}) => {
  const [officialDocumentTypes, setOfficialDocumentTypes] = useState<OfficialDocumentType[]>([])
  const [selectedOfficialDocumentType, setSelectedOfficialDocumentType] = useState<OfficialDocumentType | undefined>(officialDocumentRequested?.officialDocumentType)
  const [title, setTitle] = useState<Title | undefined>(officialDocumentRequested?.title)
  const [transactionFromTitle, setTransactionFromTitle] = useState<TransactionInfos | undefined>({ numNat: officialDocumentRequested?.copyNum, arrivalDate: officialDocumentRequested?.arrivalDate, titleNumNat: officialDocumentRequested?.originalNumNat })
  const currentUser = useSelector((state: RootStateOrAny) => state.user.user)
  const [showBrandCopyWarning, setShowBrandCopyWarning] = useState<boolean>(false)

  /**
   * Savoir si l'option DAS doit être proposée
   * @param depositDate
   */
  const canShowOptionDAS = (depositDate?: string): boolean => {
    return (selectedOfficialDocumentType?.code === CERTIFICATE_OF_IDENTITY_BRAND || selectedOfficialDocumentType?.code === OFFICIAL_COPY_BRAND) && !!depositDate && DateUtils.isAfter(depositDate, '2020-07-05')
  }

  const [canShowDAS, setCanShowDAS] = useState<boolean>(canShowOptionDAS(officialDocumentRequested?.title?.depositDate))

  useEffect(() => {
    OfficialDocumentTypesService.getOfficialDocumentsTypes().then((res: OfficialDocumentType[]) => {
      setOfficialDocumentTypes(res)
    })
  }, [])

  /**
   * Ajout d'un document officiel pour un titre
   */
  const onTitleChange = (updatedTitle: Title) => {
    setTransactionFromTitle(undefined)
    setTitle(updatedTitle)
    updateOptionDASFromRequest(updatedTitle?.depositDate, { ...officialDocumentRequested, title: updatedTitle, copyNum: undefined, arrivalDate: undefined, originalNumNat: undefined })
  }

  /**
   * Ajout d'un document officiel pour une transaction contenant le titre
   */
  const onTransactionFromTitleChange = (updatedTransactionFromTitle?: TransactionInfos) => {
    setTransactionFromTitle(updatedTransactionFromTitle)
    setTitle(undefined)
    setOfficialDocumentRequest({ ...officialDocumentRequested, copyNum: updatedTransactionFromTitle?.numNat, arrivalDate: DateUtils.formateDateToFormat(updatedTransactionFromTitle?.arrivalDate, DATE_ISO8601), originalNumNat: updatedTransactionFromTitle?.titleNumNat, title: undefined })
    setCanShowDAS(false)
  }

  /**
   * Ajout d'un document officiel pour une transaction contenant le titre
   */
  const canRequestRecord = (record: Record): boolean => {
    return OfficialDocumentService.canAskDocumentForRecord(record, currentUser, selectedOfficialDocumentType)
  }

  /**
   * Modifie la valeur de l'option DAS suivant le type de document et la date de dépôt
   */
  const updateOptionDASFromRequest = (depositDate?: string, updatedOfficialDocument?: OfficialDocumentRequested): void => {
    const showDAS = canShowOptionDAS(depositDate)
    setOfficialDocumentRequest({ ...updatedOfficialDocument, useDAS: !showDAS ? false : updatedOfficialDocument?.useDAS, secondaryEmail: !showDAS ? undefined : updatedOfficialDocument?.secondaryEmail })
    setCanShowDAS(showDAS)
  }

  /**
   * A la modification du type de document demandé
   */
  const onTypeChange = async (updatedOfficialDocumentRequest?: OfficialDocumentRequested) => {
    setTransactionFromTitle(undefined)
    setTitle(undefined)
    setShowBrandCopyWarning(false)
    await setOfficialDocumentRequest({
      ...updatedOfficialDocumentRequest,
      title: undefined,
      copyNum: undefined,
      arrivalDate: undefined,
      originalNumNat: undefined,
      useDAS: false,
      secondaryEmail: undefined
    })
    setCanShowDAS(false)
  }

  return (
    <>
      <div className='official-document-requested-form'>
        <div className='separator mt-5 mb-5' />
        <OfficialDocumentTypeSelection
          officialDocumentTypes={officialDocumentTypes}
          selectedOfficialDocumentType={officialDocumentRequested?.officialDocumentType ?? selectedOfficialDocumentType}
          setSelectedOfficialDocument={setSelectedOfficialDocumentType}
          officialDocumentRequested={officialDocumentRequested}
          setOfficialDocumentRequest={onTypeChange}
          fieldStatus={fieldStatus}
        />
        <OfficialDocumentTypeInformation
          selectedOfficialDocument={officialDocumentRequested?.officialDocumentType ?? selectedOfficialDocumentType}
        />
        <div className='separator mt-5 mb-5' />
        {
          ((officialDocumentRequested?.officialDocumentType && OFFICIAL_DOCUMENT_TYPE_COPY_TITLE.includes(officialDocumentRequested?.officialDocumentType?.code)) || (selectedOfficialDocumentType && OFFICIAL_DOCUMENT_TYPE_COPY_TITLE.includes(selectedOfficialDocumentType.code))) && (
            <TitleValidateContent
              className='md-6 mt-3 p-0'
              title={officialDocumentRequested?.title ?? title}
              setTitle={onTitleChange}
              transaction={transaction}
              fieldStatus={{ ...fieldStatus, officialDocumentsFamily: undefined }}
              isEditingMode={isEditingMode && title?.from === 'Inconnu'}
              showOverview={isEditingMode && title?.from !== 'Inconnu'}
              canRequestRecord={canRequestRecord}
              showBrandCopyWarning={showBrandCopyWarning}
              setShowBrandCopyWarning={setShowBrandCopyWarning}
            />
          )
        }
        {
          ((officialDocumentRequested?.officialDocumentType && OFFICIAL_DOCUMENT_TYPE_COPY_TRANSACTION.includes(officialDocumentRequested?.officialDocumentType?.code)) || (selectedOfficialDocumentType && OFFICIAL_DOCUMENT_TYPE_COPY_TRANSACTION.includes(selectedOfficialDocumentType.code))) && (
            <TransactionFromTitle
              className='md-6 mt-3 p-0'
              transactionFromTitle={{ numNat: officialDocumentRequested?.copyNum, arrivalDate: officialDocumentRequested?.arrivalDate, titleNumNat: officialDocumentRequested?.originalNumNat } ?? transactionFromTitle}
              setTransactionFromTitle={onTransactionFromTitleChange}
              transaction={transaction}
              fieldStatus={fieldStatus}
              isEditingMode={isEditingMode}
              officialDocumentType={officialDocumentRequested?.officialDocumentType ?? selectedOfficialDocumentType}
            />
          )
        }
        <OfficialDocumentSendingMode
          fieldStatus={fieldStatus}
          officialDocumentRequested={officialDocumentRequested}
          setOfficialDocumentRequest={setOfficialDocumentRequest}
          currentETSUser={currentETSUser}
          canShowDAS={canShowDAS}
        />
      </div>
    </>
  )
}

export default OfficialDocumentRequestedForm
