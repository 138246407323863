import {
  DEPOSIT_TYPE_DIVISION,
  FRMI_TYPE_EXTENSION,
  INSCRIPTION_TYPE_OFFICE,
  INSCRIPTION_TYPE_RENUNCIATION,
  PROCEDURE_DEPOSIT,
  PROCEDURE_DIVISION, PROCEDURE_FRMI,
  PROCEDURE_INSCRIPTION,
  PROCEDURE_MIFR, PROCEDURE_OFFICIAL_DOCUMENT, PROCEDURE_OPPOSITION, PROCEDURE_RENEWAL,
  PROCEDURE_RENUNCIATION,
  PROCEDURE_REVOCATION_STATEMENT,
  Transaction
} from '../../../src'

class TransactionService {
  /**
   * La transaction est-elle une division
   * @param transaction
   * @returns
   */
  isDivision = (transaction: Transaction): boolean =>
    transaction.procedureType === PROCEDURE_DIVISION.value || (transaction.procedureType === PROCEDURE_DEPOSIT.value && transaction.subProcedureType === DEPOSIT_TYPE_DIVISION.value)

  /**
   * La transaction est-elle une inscription
   * @param transaction
   * @returns
   */
  isInscription = (transaction: Transaction): boolean =>
    transaction.procedureType === PROCEDURE_INSCRIPTION.value

  /**
   * La transaction est-elle une renonciation
   * @param transaction
   * @returns
   */
  isRenunciation = (transaction: Transaction): boolean =>
    transaction.subProcedureType === INSCRIPTION_TYPE_RENUNCIATION.value

  /**
   * La transaction est-elle une inscription d'office
   * @param transaction
   * @returns
   */
  isOfficeInscription = (transaction: Transaction): boolean =>
    transaction.subProcedureType === INSCRIPTION_TYPE_OFFICE.value

  /**
   * Check s'il s'agit d'un dépôt
   * @param transaction
   * @returns
   */
  isDeposit = <T extends Transaction>(transactionOrProcedureType: T | T['procedureType']): boolean => {
    const procedureType: string | undefined = typeof transactionOrProcedureType === 'object' ? transactionOrProcedureType.procedureType : transactionOrProcedureType
    return procedureType === PROCEDURE_DEPOSIT.value
  }

  /**
   * Check s'il s'agit d'une MIFR
   * @param transaction
   * @returns
   */
  isMifr = (transaction: Transaction): boolean =>
    transaction.procedureType === PROCEDURE_MIFR.value

  /**
   * Check s'il s'agit d'une FRMI
   * @param transaction
   * @returns
   */
  isFrmi = (transaction: Transaction): boolean =>
    transaction.procedureType === PROCEDURE_FRMI.value

  /**
   * Check s'il s'agit d'une OP
   * @param transaction
   * @returns
   */
  isFrmiOP = (transaction: Transaction): boolean =>
    transaction.procedureType === PROCEDURE_FRMI.value && transaction.subProcedureType !== FRMI_TYPE_EXTENSION.value

  /**
   * Check s'il s'agit d'un relevé de déchéance
   * @param transaction
   * @returns
   */
  isRevocationStatement = (transaction: Transaction): boolean =>
    transaction.procedureType === PROCEDURE_REVOCATION_STATEMENT.value

  /**
   * La transaction est-elle un renouvellement
   * @param transaction
   * @returns
   */
  isRenewal = (transaction: Transaction): boolean =>
    transaction.procedureType === PROCEDURE_RENEWAL.value

  /**
   * La transaction est-elle un document officiel
   * @param transaction
   * @returns
   */
  isOfficialDocument = (transaction?: Transaction): boolean =>
      transaction?.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value

  /**
   * La transaction est-elle une opposition
   * @param transaction
   * @returns
   */
  isOpposition = (transaction: Transaction): boolean =>
    transaction.procedureType === PROCEDURE_OPPOSITION.value

  /**
   * Retourne un tableau paginé de transactions
   * @param transactions
   * @param nbItemsPerPage
   * @returns
   */
  getPaginatedTransactions = (transactions: any[], nbItemsPerPage: number) => {
    const paginatedTransactions: any[][] = []

    let page: any[] = []
    transactions.forEach((transaction, key: number) => {
      page.push(transaction)
      if ((page.length % nbItemsPerPage === 0) || (key + 1) === transactions.length) {
        paginatedTransactions.push(page)
        page = []
      }
    })
    return paginatedTransactions
  }

  /**
   * Récupère la procédure utilisée pour les contenus administrables.
   * S'il s'agit de la sous-procédure renonciation, on la retourne comme étant une procédure
   * @param transaction
   * @returns
   */
  getAdministrableContentProcedureType = (transaction: Transaction): string =>
    this.isRenunciation(transaction) ? PROCEDURE_RENUNCIATION.value : transaction.procedureType ?? ''
}

export default new TransactionService()
