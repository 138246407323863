import { combineReducers } from 'redux'
import { reduceUserState } from './user/userReducers'
import {
  reducerTransactionFieldStatusState
} from './fieldStatus/fieldStatusReducers'
import {
  reducerTransactionState
} from './transaction/transactionReducers'
import { reducerContentState } from './content/contentReducers'
import { reducerCountDepositState } from './countDeposit/countDepositReducers'

const reducers = combineReducers({
  user: reduceUserState,
  transactionFieldStatus: reducerTransactionFieldStatusState,
  transaction: reducerTransactionState,
  adminContent: reducerContentState,
  homeCount: reducerCountDepositState
})

export default reducers
