import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import HelpCard from './HelpCard'
import { HelpInfo, HelpItem } from '../../interfaces/HelpInterfaces'
import HelpService from '../../services/content/HelpService'
import { FilAriane } from '@inpi-marques/components'
import { HELP_TYPE_CPU } from '../../constants'

const Help: FC = () => {
  const [helpsInfo, setHelpsInfo] = useState<HelpInfo[]>([])

  useEffect(() => {
    HelpService.getHelps().then((result: HelpItem[]) => {
      const helps: HelpInfo[] = []
      result.forEach((helpItem: HelpItem) => {
        if (helpItem.type !== HELP_TYPE_CPU) {
          const indexFound = helps.findIndex((help: HelpInfo) => help.type === helpItem.type)
          if (indexFound !== -1) {
            helps[indexFound].items.push(helpItem)
          } else {
            helps.push({
              type: helpItem.type,
              items: [helpItem]
            })
          }
        }
      })
      setHelpsInfo(helps)
    })
  }, [])

  return (
    <div id='help'>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='help_breadcrumb_title' /></span>
      </FilAriane>
      <div className='row'>
        {helpsInfo.map(helpInfo =>
          <div key={helpInfo.type} className='col-md-3 mb-4'>
            <HelpCard helpInfo={helpInfo} />
          </div>)}
      </div>
    </div>
  )
}

export default Help
