import {
  containsErrors,
  FieldStatus,
  isFilled,
  isMailFormatValid,
  messageIsFilled,
  OFFICIAL_DOCUMENT_TYPE_COPY_TITLE,
  OfficialDocument,
  OfficialDocumentRequested,
  PROCEDURE_OFFICIAL_DOCUMENT,
  TransactionDocument
} from '@inpi-marques/components'
import Message from '../../../../../constants/Message'
import store from '../../../../../store/store'
import { storeTransactionFieldStatusUpdate } from '../../../../../store/fieldStatus/fieldStatusActions'
import TitlesValidator from '../../../../inscription/form/titles/validator/TitlesValidator'

class OfficialDocumentsRequestedValidator {
    getValidatedFieldStatus = (documents: TransactionDocument[], officialDocument?: OfficialDocument): FieldStatus => {
      let officialDocumentsFieldStatus: FieldStatus = {}

      if (!isFilled(officialDocument?.officialDocumentsRequested)) {
        officialDocumentsFieldStatus.officialDocumentsRequested = Message.official_document_requested_required
        return officialDocumentsFieldStatus
      }

      if (officialDocument?.officialDocumentsRequested?.length) {
        for (let i = 0; i < officialDocument?.officialDocumentsRequested?.length; i++) {
          const name = 'quantity' + i
          const error = officialDocument?.officialDocumentsRequested[i]?.usePostalRoute && (!officialDocument?.officialDocumentsRequested[i].quantity || officialDocument?.officialDocumentsRequested[i].quantity <= 0) ? Message.official_document_requested_quantity_required : ''
          officialDocumentsFieldStatus = { ...officialDocumentsFieldStatus, [name]: error }
        }
      }

      officialDocumentsFieldStatus = { ...officialDocumentsFieldStatus, officialDocumentsFamily: this.validateDocumentsFamily(officialDocument?.officialDocumentsRequested) }

      return officialDocumentsFieldStatus
    }

    validateDocumentsFamily = (officialDocumentsRequested?: OfficialDocumentRequested[]) => {
      // on vérifie que tous les documents appartiennent à la même famille
      const familyTypeList = officialDocumentsRequested?.map(document => document.officialDocumentType?.family || '')
      const familyTypeListDistinct = familyTypeList?.filter((family, index) => familyTypeList.indexOf(family) === index)
      if (familyTypeListDistinct && familyTypeListDistinct.length > 1) {
        return Message.official_document_requested_family_error
      }

      return ''
    }

  validateTransactionFromTitle = (officialDocumentRequested?: OfficialDocumentRequested) => ({
    transactionFromTitleArrivalDate: messageIsFilled(officialDocumentRequested?.arrivalDate),
    transactionFromTitleNumNat: messageIsFilled(officialDocumentRequested?.copyNum),
    titleNumNat: messageIsFilled(officialDocumentRequested?.originalNumNat)
  })

  /**
   * Validation de la demande de document officiel
   * @param officialDocumentsRequested
   * @param editIndex
   */
  validateOfficialDocumentRequested = (editIndex: number, officialDocumentsRequested?: OfficialDocumentRequested[]): FieldStatus => {
    const officialDocumentRequestedFieldStatus: FieldStatus = {}

    const officialDocumentRequested = officialDocumentsRequested?.[editIndex]

    /** Type de document officiel sélectionné ou non */
    if (messageIsFilled(officialDocumentRequested?.officialDocumentType?.code)) {
      officialDocumentRequestedFieldStatus.type = Message.official_documents_type_required_type
    }

    /** Type d'envoi sélectionné ou non */
    if (!officialDocumentRequested?.useDAS && !officialDocumentRequested?.usePostalRoute && !officialDocumentRequested?.usePdfPortal) {
      officialDocumentRequestedFieldStatus.useDAS = Message.official_documents_type_required_send
      officialDocumentRequestedFieldStatus.usePostalRoute = Message.official_documents_type_required_send
      officialDocumentRequestedFieldStatus.usePdfPortal = Message.official_documents_type_required_send
    }

    /** L'email est renseigné ou non */
    if (officialDocumentRequested?.useDAS && messageIsFilled(officialDocumentRequested?.email)) {
      officialDocumentRequestedFieldStatus.email = Message.official_documents_type_required_email
    }

    /** L'email est valide ou non */
    if (officialDocumentRequested?.useDAS && officialDocumentRequested?.email && !isMailFormatValid(officialDocumentRequested?.email)) {
      officialDocumentRequestedFieldStatus.email = Message.official_documents_type_invalid_email
    }

    /** L'email secondaire est valide ou non */
    if (officialDocumentRequested?.useDAS && officialDocumentRequested?.email && officialDocumentRequested.secondaryEmail &&
      !isMailFormatValid(officialDocumentRequested?.secondaryEmail)) {
      officialDocumentRequestedFieldStatus.secondaryEmail = Message.official_documents_type_invalid_email
    }

    officialDocumentRequestedFieldStatus.officialDocumentsFamily = this.validateDocumentsFamily(officialDocumentsRequested)

    /** Le titre est renseigné ou non */
    let fieldStatusTitle = {}

    if (officialDocumentRequested?.officialDocumentType?.code && OFFICIAL_DOCUMENT_TYPE_COPY_TITLE.includes(officialDocumentRequested?.officialDocumentType?.code)) {
      fieldStatusTitle = TitlesValidator.validateTitle(officialDocumentRequested?.title, PROCEDURE_OFFICIAL_DOCUMENT.value)
    } else {
      fieldStatusTitle = this.validateTransactionFromTitle(officialDocumentRequested)
    }

    return { ...officialDocumentRequestedFieldStatus, ...fieldStatusTitle }
  }

    validateOnChangeStep = (documents: TransactionDocument[], officialDocument?: OfficialDocument) => {
      const fieldStatus: FieldStatus = this.getValidatedFieldStatus(documents, officialDocument)
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))
      return !containsErrors(fieldStatus)
    }
}

export default new OfficialDocumentsRequestedValidator()
