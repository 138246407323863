import {
  containsErrors,
  DOCUMENT_COMMUNICABILITIES,
  DOCUMENT_TYPES,
  FieldStatus,
  Inscription,
  InscriptionNatureCode,
  messageIsFilled,
  NATURE_CODES,
  NatureCode,
  TransactionDocument,
  CommonInscriptionService
} from '@inpi-marques/components'
import Message from 'constants/Message'
import { storeTransactionFieldStatusUpdate } from 'store/fieldStatus/fieldStatusActions'
import store from 'store/store'

class CorrectionFormValidator {
  /**
   * Partie des pièce à réctifier
   * @param inscription
   * @returns
   */
    validateRectifyPart = (inscription?: Inscription): FieldStatus => {
      if (inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => inscriptionNatureCode.code === NATURE_CODES.EM)) {
        return {
          renewalNumber: inscription.correction?.areRenewalFiles ? messageIsFilled(inscription.correction.renewalNumber) : '',
          actInscriptionNumber: inscription.correction?.areActFiles ? messageIsFilled(inscription.correction.actInscriptionNumber) : '',
          rectifyContent: !inscription.correction?.areActFiles && !inscription.correction?.areDepositFiles && !inscription.correction?.areDivisionFiles && !inscription.correction?.areRenewalFiles ? Message.correction_form_rectify_content_required : '',
          divisionNumber: inscription.correction?.areDivisionFiles && !inscription.correction.divisionNumber ? Message.required_field : ''
        }
      }
      return {}
    }

    /**
     * Validation des communicabilités des documents
     * @param documents
     * @param inscription
     * @returns
     */
    validateDocumentsCommunicability = (documents: TransactionDocument[], inscription?: Inscription): FieldStatus => {
      const correctionDocuments: TransactionDocument[] = documents.filter((document: TransactionDocument) => document.type === DOCUMENT_TYPES.INSCRIPTION_MANDATORY_POWER ||
      document.type === DOCUMENT_TYPES.INSCRIPTION_OTHER
      )

      // Vérification uniquement pour les codes natures "Erreur materielle", Immatriculation de société ou retrait d'extension
      if (!inscription?.natureCodes?.some((inscriptionNatureCode: InscriptionNatureCode) => inscriptionNatureCode.code === NATURE_CODES.EM || inscriptionNatureCode.code === NATURE_CODES.IM || inscriptionNatureCode.code === NATURE_CODES.PE)) {
        return {}
      }

      // Il doit y avoir au moins un fichier communicable
      if (correctionDocuments.every((document: TransactionDocument) => document.communicability === DOCUMENT_COMMUNICABILITIES.NON_COMMUNICABLE)) {
        return {
          documents_communicability: Message.correction_documents_communicability_error
        }
      }
      return {}
    }

    /**
     * Validation des codes natures
     * @param inscription
     * @returns
     */
    validateNatureCode = (inscription?: Inscription): string => {
      if (!inscription?.natureCodes?.length) {
        return Message.correction_form_missing_nature_codes
      } else if (inscription?.natureCodes && inscription.natureCodes.length > 3) {
        return Message.correction_form_too_much_nature_codes
      }
      return ''
    }

    /**
     * Validation de l'étape de Rectification
     * @param documents
     * @param inscription
     * @param natureCodes
     * @returns
     */
    validateCorrectionStep = (documents: TransactionDocument[], inscription?: Inscription, natureCodes?: NatureCode[]): boolean => {
      const fieldStatus: FieldStatus = {
        natureCodes: inscription?.natureCodes && inscription.natureCodes.length > 0 ? '' : Message.correction_form_missing_nature_codes,
        replacedText: CommonInscriptionService.isFieldMandatory(inscription?.natureCodes, natureCodes, 'replacedText') ? messageIsFilled(inscription?.correction?.replacedText) : '',
        replacementText: CommonInscriptionService.isFieldMandatory(inscription?.natureCodes, natureCodes, 'replacementText') ? messageIsFilled(inscription?.correction?.replacementText) : '',
        ...this.validateRectifyPart(inscription),
        ...this.validateDocumentsCommunicability(documents, inscription)
      }
      store.dispatch(storeTransactionFieldStatusUpdate(fieldStatus))

      return !containsErrors(fieldStatus)
    }
}

export default new CorrectionFormValidator()
