import { PROCEDURE_ALL, ALL_ADMINISTRATION_PROCEDURES } from '../../index'
import { IProcedure } from 'src/constants/ProcedureConstants'

class ProceduresService {
  /**
     * Check si une procédure est selectionnée ou non
     * @param procedures
     * @param value
     * @returns
     */
     isProcedureSelected = (procedures: string[], value: string): boolean => procedures.some((procedure: string) => procedure === PROCEDURE_ALL.value || procedure === value)

     /**
      * On met à jour les procédures d'un diagnostique
      * @param procedures
      * @param value
      * @returns
      */
     getUpdatedProcedures = (procedures: string[], value: string) : string[] => {
       /** Je sélectionne / désélectionne "ALL" */
       if (value === PROCEDURE_ALL.value && !this.isProcedureSelected(procedures, value)) {
         return [PROCEDURE_ALL.value]
       } else if (value === PROCEDURE_ALL.value) {
         // On décoche ALL
         return []
       }

       /** Je sélectionne / désélectionne autre chose que "ALL"  */
       // Si la procedure est déjà sélectionnée, alors on la deselectionne
       if (this.isProcedureSelected(procedures, value)) {
         // Si "ALL" est selectionné alors je coche le reste
         if (this.isProcedureSelected(procedures, PROCEDURE_ALL.value)) {
           return ALL_ADMINISTRATION_PROCEDURES.filter((PROCEDURE: IProcedure) => PROCEDURE.value !== PROCEDURE_ALL.value && PROCEDURE.value !== value)
             .map((PROCEDURE: IProcedure) => PROCEDURE.value)
         }
         return procedures.filter((procedure: string) => procedure !== value)
       } else {
         // Sinon, s'il s'agit de la seule qui n'était pas sélectionné alors on retour ALL
         const updatedProcedures: string[] = [...procedures, value]
         if (updatedProcedures.length === (ALL_ADMINISTRATION_PROCEDURES.length - 1)) {
           return [PROCEDURE_ALL.value]
         } else {
           // Sinon, on retourne la liste mise à jour
           return updatedProcedures
         }
       }
     }
}

export default new ProceduresService()
