import { ContributorOptions, ContributorsOptionsForm } from '@inpi-marques/components'

const COMMON_DEPOSITOR_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: true,
  hasContactField: true,
  hasPublicLegalCompanyField: true,
  hasFormationField: true,
  hasCompanyField: false,
  couldBePPOrPM: true,
  hasAddressField: true,
  hasManageableQualities: false,
  isManageableQualitiesRequired: false,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: true,
  couldSearchAddress: true,
  allFieldReadOnly: false
}

const DEPOSIT_DEPOSITOR_OPTIONS: ContributorsOptionsForm = {
  ...COMMON_DEPOSITOR_OPTIONS,
  hasNationality: true
}

const FRMI_DEPOSITORS_OPTIONS: ContributorsOptionsForm = {
  ...COMMON_DEPOSITOR_OPTIONS,
  isEmailRequired: true,
  hasNationality: true,
  hasOMPILanguageSelect: true,
  hasOtherLanguageSelect: true,
  isLegalFormOptional: true
}

const FRMI_HOLDERS_OPTIONS: ContributorsOptionsForm = {
  ...COMMON_DEPOSITOR_OPTIONS,
  isEmailRequired: true,
  hasNationality: true,
  hasOMPILanguageSelect: true,
  hasOtherLanguageSelect: true,
  isLegalFormOptional: true
}

const FRMI_OLD_HOLDERS_OPTIONS: ContributorsOptionsForm = {
  ...COMMON_DEPOSITOR_OPTIONS,
  isEmailRequired: false,
  hasNationality: false,
  hasOMPILanguageSelect: true,
  hasOtherLanguageSelect: true,
  isLegalFormOptional: true,
  hasAddressField: false
}

const FRMI_LICENSEE_OPTIONS: ContributorsOptionsForm = {
  ...COMMON_DEPOSITOR_OPTIONS,
  isEmailRequired: true,
  hasNationality: true,
  hasOMPILanguageSelect: true,
  isLegalFormOptional: true
}

const FRMI_OLD_LICENSEE_OPTIONS: ContributorsOptionsForm = {
  ...COMMON_DEPOSITOR_OPTIONS,
  isEmailRequired: true,
  hasNationality: true,
  isLegalFormOptional: true
}

const ND_HOLDERS_CONTESTED_MARK_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: true,
  hasContactField: true,
  hasPublicLegalCompanyField: true,
  hasFormationField: false,
  hasCompanyField: false,
  couldBePPOrPM: true,
  hasAddressField: true,
  hasManageableQualities: false,
  isManageableQualitiesRequired: false,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: true,
  couldSearchAddress: true,
  allFieldReadOnly: false
}

const DEPOSIT_RECIPIENT_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: true,
  hasPhysiqueField: true,
  hasContactField: true,
  hasPublicLegalCompanyField: false,
  hasFormationField: false,
  hasCompanyField: false,
  couldBePPOrPM: true,
  hasAddressField: true,
  hasManageableQualities: false,
  isManageableQualitiesRequired: false,
  isEmailRequired: false,
  isEmailReadOnly: true,
  showSiren: true,
  couldSearchAddress: true,
  allFieldReadOnly: false,
  askConsent: true
}

const DEPOSIT_AGENT_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: true,
  hasPhysiqueField: true,
  hasContactField: true,
  hasPublicLegalCompanyField: false,
  hasCompanyField: true,
  hasFormationField: false,
  couldBePPOrPM: false,
  hasAddressField: true,
  hasManageableQualities: true,
  isManageableQualitiesRequired: true,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: false,
  couldSearchAddress: true,
  allFieldReadOnly: false
}

const DEPOSIT_AGENTS_TMC_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: true,
  hasPhysiqueField: true,
  hasContactField: true,
  hasPublicLegalCompanyField: false,
  hasCompanyField: true,
  hasFormationField: false,
  couldBePPOrPM: false,
  hasAddressField: true,
  hasManageableQualities: true,
  isManageableQualitiesRequired: false,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: false,
  couldSearchAddress: true,
  allFieldReadOnly: false
}

const DEPOSIT_SIGNATORY_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: false,
  hasPhysiqueField: true,
  hasContactField: true,
  hasPublicLegalCompanyField: false,
  hasCompanyField: false,
  hasFormationField: false,
  couldBePPOrPM: false,
  hasAddressField: false,
  hasManageableQualities: true,
  isManageableQualitiesRequired: true,
  isEmailRequired: true,
  isEmailReadOnly: true,
  showSiren: false,
  allFieldReadOnly: false
}

const PAYMENT_CONTRIBUTOR : ContributorsOptionsForm = {
  hasAutoCopy: false,
  hasPhysiqueField: true,
  hasContactField: true,
  hasPublicLegalCompanyField: false,
  hasCompanyField: false,
  hasFormationField: false,
  couldBePPOrPM: true,
  hasAddressField: true,
  couldSearchAddress: true,
  hasManageableQualities: false,
  isManageableQualitiesRequired: false,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: true
}

const ADDRESS_BOOK_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: false,
  hasPhysiqueField: true,
  hasContactField: true,
  hasPublicLegalCompanyField: false,
  hasCompanyField: false,
  hasFormationField: true,
  couldBePPOrPM: true,
  hasAddressField: true,
  hasManageableQualities: false,
  isManageableQualitiesRequired: false,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: true,
  couldSearchAddress: true
}

const OPPOSITION_OPPONENT_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: true,
  hasContactField: true,
  hasPublicLegalCompanyField: true,
  hasFormationField: true,
  hasCompanyField: false,
  couldBePPOrPM: true,
  hasAddressField: true,
  hasManageableQualities: false,
  isManageableQualitiesRequired: false,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: true,
  couldSearchAddress: true,
  allFieldReadOnly: false
}

const ORGANISERS_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: true,
  hasContactField: true,
  hasPublicLegalCompanyField: true,
  hasFormationField: true,
  hasCompanyField: false,
  couldBePPOrPM: true,
  hasAddressField: true,
  hasManageableQualities: false,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: true,
  couldSearchAddress: true,
  allFieldReadOnly: false
}

const BENEFICIARIES_OPTIONS : ContributorsOptionsForm = {
  hasAutoCopy: true,
  hasContactField: true,
  hasPublicLegalCompanyField: true,
  hasFormationField: true,
  hasCompanyField: false,
  couldBePPOrPM: true,
  hasAddressField: true,
  hasManageableQualities: false,
  isEmailRequired: false,
  isEmailReadOnly: false,
  showSiren: true,
  couldSearchAddress: true,
  allFieldReadOnly: false
}

export const DEPOSIT_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: DEPOSIT_DEPOSITOR_OPTIONS,
  agent: DEPOSIT_AGENT_OPTIONS,
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS,
  address_book: ADDRESS_BOOK_OPTIONS,
  paymentContributor: PAYMENT_CONTRIBUTOR,
  otherApplicants: COMMON_DEPOSITOR_OPTIONS
}

export const NULLITY_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: COMMON_DEPOSITOR_OPTIONS,
  agent: DEPOSIT_AGENT_OPTIONS,
  agentsTMC: DEPOSIT_AGENTS_TMC_OPTIONS,
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  recipientsTMC: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS,
  holdersContestedMark: ND_HOLDERS_CONTESTED_MARK_OPTIONS,
  address_book: ADDRESS_BOOK_OPTIONS,
  paymentContributor: PAYMENT_CONTRIBUTOR
}

export const REVOCATION_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: COMMON_DEPOSITOR_OPTIONS,
  agent: DEPOSIT_AGENT_OPTIONS,
  agentsTMC: DEPOSIT_AGENTS_TMC_OPTIONS,
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  recipientsTMC: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS,
  holdersContestedMark: ND_HOLDERS_CONTESTED_MARK_OPTIONS,
  address_book: ADDRESS_BOOK_OPTIONS,
  paymentContributor: PAYMENT_CONTRIBUTOR
}

export const REVOCATION_STATEMENT_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: COMMON_DEPOSITOR_OPTIONS,
  agent: DEPOSIT_AGENT_OPTIONS,
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS
}

export const OPPOSITION_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  opponents: OPPOSITION_OPPONENT_OPTIONS,
  depositor: DEPOSIT_RECIPIENT_OPTIONS,
  agent: DEPOSIT_AGENT_OPTIONS,
  agentDepositor: DEPOSIT_AGENT_OPTIONS,
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS
}

export const INSCRIPTION_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: COMMON_DEPOSITOR_OPTIONS,
  agent: {
    ...DEPOSIT_AGENT_OPTIONS,
    canBeRecipient: true
  },
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS,
  otherApplicants: COMMON_DEPOSITOR_OPTIONS,
  organisers: ORGANISERS_OPTIONS,
  beneficiaries: BENEFICIARIES_OPTIONS
}

export const DIVISION_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: {
    ...COMMON_DEPOSITOR_OPTIONS,
    canBeRecipient: true
  },
  agent: {
    ...DEPOSIT_AGENT_OPTIONS,
    canBeRecipient: true
  },
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS,
  address_book: ADDRESS_BOOK_OPTIONS,
  paymentContributor: PAYMENT_CONTRIBUTOR
}

export const FRMI_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: FRMI_DEPOSITORS_OPTIONS,
  holders: FRMI_HOLDERS_OPTIONS,
  old_holders: FRMI_OLD_HOLDERS_OPTIONS,
  licensee: FRMI_LICENSEE_OPTIONS,
  old_licensees: FRMI_OLD_LICENSEE_OPTIONS,
  agent: {
    ...DEPOSIT_AGENT_OPTIONS,
    canBeRecipient: true,
    isEmailRequired: true
  },
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS,
  address_book: ADDRESS_BOOK_OPTIONS
}

export const RENEWAL_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: COMMON_DEPOSITOR_OPTIONS,
  agent: DEPOSIT_AGENT_OPTIONS,
  recipient: DEPOSIT_RECIPIENT_OPTIONS,
  signatory: DEPOSIT_SIGNATORY_OPTIONS
}

export const OFFICIAL_DOCUMENT_CONTRIBUTORS_OPTIONS: ContributorOptions = {
  depositors: COMMON_DEPOSITOR_OPTIONS,
  recipient: {
    ...DEPOSIT_RECIPIENT_OPTIONS,
    askConsent: false
  },
  holders: COMMON_DEPOSITOR_OPTIONS
}
