import { ProductClassVersion, Transaction } from '@inpi-marques/components'
import { DepositFromXML, Payment } from '@inpi-marques/components/src/interfaces/deposit/Deposit'
import Message from 'constants/Message'
import http from 'network/http-common'
import { createIntl, IntlShape } from 'react-intl'
import { toast } from 'react-toastify'
import axios, { CancelTokenSource } from 'axios'

interface IPatch {
  [key: string]: string
}

/* global FormData */

class DepositService {
  intl: IntlShape
  source: CancelTokenSource

  constructor () {
    this.intl = createIntl({ locale: 'fr', messages: Message })
    this.source = axios.CancelToken.source()
  }

  /**
   * Edition d'un dépôt
   * @param transactionId
   * @param patches
   * @returns
   */
  editDeposit = async (transactionId: string, patches: IPatch): Promise<Transaction|null> => {
    try {
      const updatedTransaction: Transaction = await http.put(`/api/transactions/${transactionId}`, { ...patches })
      return Promise.resolve(updatedTransaction)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_edit' }))
      return Promise.reject(error)
    }
  }

  /**
   * Edition des produits
   * @returns
   * @param transactionId
   * @param version
   */
  editProducts = async (transactionId: Transaction, version: ProductClassVersion): Promise<Transaction|null> => {
    try {
      const transaction: Transaction = await http.put(`/api/deposits/${transactionId}/products/${version.id}`,
        {
          productsAndServicesVersion: version
        }
      )
      return Promise.resolve(transaction)
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_transaction_edit' }))
      return Promise.reject(error)
    }
  }

  /**
   * Appel serveur pour créer une version de produits et services
   * @returns Promise<Transaction|null>
   * @param transactionId
   * @param version
   */
  createProductsVersion = async (transactionId: string, version: ProductClassVersion): Promise<Transaction|null> => {
    try {
      const transactions: Transaction = await http.post(`/api/deposits/${transactionId}/products`,
        {
          productsAndServicesVersion: version
        }
      )
      return Promise.resolve(transactions)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Mise à jour des propriétés du dépôt correspondant à une étape du formulaire
   * @returns Promise<Deposit|null>
   */
  updateBDDFromStore = async (transaction: Transaction, propertyName: string): Promise<Transaction|null> => {
    if (transaction.id) {
      const patches: IPatch = {}
      patches[propertyName] = (transaction as IPatch)[propertyName]
      // On ajoute la référence client saisie par l'utilisateur
      if (transaction.internalReference) {
        patches.internalReference = transaction.internalReference
      }
      // On ajoute le type de Procédure (Type de dépot, ....)
      if (transaction.subProcedureType) {
        patches.subProcedureType = transaction.subProcedureType
      }

      try {
        const result = await this.editDeposit(transaction.id, patches)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    } else {
      toast.error(this.intl.formatMessage({ id: 'error_update_depository' }))
      return Promise.reject(new Error('Erreur lors de la modification du dossier'))
    }
  }

  /**
   * Récupère le récapitulatif de paiement d'un deposit.
   * @param id
   * @returns
   */
  getPaiement = async (id: string): Promise<Payment> => {
    try {
      const result: Payment = await http.get(`/api/deposits/${id}/paiements`)
      return Promise.resolve(result)
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Appel serveur pour créer un dépôt à partir d'un fichier
   * @returns Promise<Transaction|null>
   * @param file
   */
  createDepositFromXmlOrZipFile = async (file: string): Promise<Transaction|null|undefined> => {
    try {
      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        const response : DepositFromXML = await http.post('/api/deposits/import', formData, { cancelToken: this.source.token })
        if (response.hasFailed) {
          toast.error(Message.deposit_import_error)
          return Promise.reject(response)
        } else if (response.errors && response.errors.length > 0) {
          toast.success(Message.deposit_import_success_with_error)
          return Promise.reject(response)
        } else if (response.warnings && response.warnings.length > 0) {
          toast.success(Message.deposit_import_success_with_error)
          return Promise.reject(response)
        } else {
          toast.success(Message.deposit_import_success)
          return Promise.resolve(response.transaction)
        }
      }
    } catch (error) {
      toast.error(error.message)
      return Promise.reject(error)
    }
  }

  /**
   * Annule une requête en attente
   */
  cancelRequest = () => {
    this.source.cancel()
    this.source = axios.CancelToken.source()
  }

  /**
   * Télécharge le fichier d'exemple du pouvoir
   * @returns
   */
  getPowerExampleFile = async (): Promise<string> => {
    try {
      return await http.get('/file/exemple_pouvoir_en_copropriete_v1.doc', {
        responseType: 'blob'
      })
    } catch (error) {
      toast.error(this.intl.formatMessage({ id: 'error_products_and_services_exemple_file' }))
      return Promise.reject(error)
    }
  }
}

export default new DepositService()
