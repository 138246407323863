import { SelectOption } from 'src/index'
import Message from './Message'

export const BRAND_ORIGIN_FR: SelectOption = {
  value: 'FR',
  label: Message.brand_origin_french
}

export const BRAND_ORIGIN_INTERNATIONAL: SelectOption = {
  value: 'WO',
  label: Message.brand_origin_international
}

export const BRAND_ORIGIN_FRENCH_ITALIAN: SelectOption = {
  value: 'FI',
  label: Message.brand_origin_french_italian
}

export const BRAND_ORIGIN_ITALIAN_FRENCH: SelectOption = {
  value: 'IF',
  label: Message.brand_origin_italian_french
}

export const BRAND_ORIGIN_FR_OLD_LAW_A: SelectOption = {
  value: 'FR-A',
  label: Message.brand_origin_french_old_law_a
}

export const BRAND_ORIGIN_FR_OLD_LAW_B: SelectOption = {
  value: 'FR-B',
  label: Message.brand_origin_french_old_law_b
}
export const BRAND_ORIGINS_SELECT_OPTIONS : SelectOption[] = [
  BRAND_ORIGIN_FR,
  BRAND_ORIGIN_INTERNATIONAL,
  BRAND_ORIGIN_FRENCH_ITALIAN,
  BRAND_ORIGIN_ITALIAN_FRENCH,
  BRAND_ORIGIN_FR_OLD_LAW_A,
  BRAND_ORIGIN_FR_OLD_LAW_B
]

export const BRAND_ORIGIN_REVOCATION_STATEMENT_OPTIONS : SelectOption[] = [
  BRAND_ORIGIN_FR,
  BRAND_ORIGIN_INTERNATIONAL
]
