import moment from 'moment-timezone'
import 'moment/locale/fr'
import {
  DAY_IN_MILLISECONDS, DAYS, HOURS,
  HOUR_IN_MILLISECONDS,
  MINUTE_IN_MILLISECONDS,
  MINUTES, MONTH_IN_MILLISECONDS,
  SECOND_IN_MILLISECONDS,
  SECONDS, YEARS, YEAR_IN_MILLISECONDS, MONTHS, BASIC_DATE, BASIC_DATE_ALT, DATE_WITH_HOUR
} from '../constants/DateFormat'

class DateUtils {
  /**
   * Formatte une date en lui settant l'heure/minutes/secondes d'une date passée en paramètre, sinon la date courante
   * @param dateToFormat
   * @param now
   * @returns
   */
  formatWithHms = (dateToFormat: string|Date|undefined, now: Date = new Date()) : string => {
    if (!dateToFormat) {
      return ''
    }
    return moment(dateToFormat).set('hour', now.getHours()).set('minute', now.getMinutes()).set('second', now.getSeconds()).format()
  }

  /**
   * Permet de réinitialiser une date à 00:00:00
   * @param date
   */
  formatToBeginOfDay = (date: string|null|undefined): string => {
    if (date) {
      return moment(date).tz('Europe/Paris').startOf('day').format()
    }
    return ''
  }

  /**
   * Permet de réinitialiser une date à 23:59:59
   * @param date
   */
  formatToEndOfDay = (date: string|null|undefined): string => {
    if (date) {
      return moment(date).tz('Europe/Paris').endOf('day').format()
    }
    return ''
  }

  /**
   * Permet d'avoir la date du jour
   */
  now = (): string => {
    return moment().format()
  }

  /**
   * Permet d'avoir la date de la fin du mois
   * @param date
   */
  formatToEndOfMonth = (date: string|null|undefined): string => {
    if (date) {
      return moment(date).endOf('months').format()
    }
    return ''
  }

  /**
   * Formatte une date au format dd/MM/yyyy
   *
   * @param date Une chaine de caractère au format ISO-8601,
   *             un objet Date,
   *             un entier représentant un timestamp exprimé en milliseconde
   */
  formatDateFr = (date: string|Date|number|null|undefined): string => {
    if (!date) {
      return ''
    }
    return moment(date).format(BASIC_DATE)
  }

  /**
   * Formatte une date au format DD/MM/YYYY HH:mm
   * @param date
   */
  formatDateWithHour = (date: Date | string | undefined): string => {
    if (!date) {
      return ''
    }
    return moment(date).format(DATE_WITH_HOUR)
  }

  /**
   * Formatte une date au format dd-MM-yyyy
   *
   * @param date Une chaine de caractère au format ISO-8601,
   *             un objet Date,
   *             un entier représentant un timestamp exprimé en milliseconde
   */
  formatDateFrAlt = (date: string|Date|number|null|undefined): string => {
    if (!date) {
      return ''
    }
    return moment(date).format(BASIC_DATE_ALT)
  }

  /**
   * Vérification et formattage d'une date en locale
   * @param date
   * @param format format souhaité
   */
  formateDateToFormat = (date: Date | string | undefined, format?: string|undefined) => {
    if (!date) {
      return null
    }

    const momentDate = moment(date)

    if (momentDate.isValid()) {
      return momentDate.format(format)
    }
    return null
  }

  /**
   * Ajout nbDays jours et formate la date
   * @param date
   * @param nbDays
   * @param format
   */
  addDaysAndFormat = (date: Date|string|undefined, nbDays: number, format: string): string => {
    return moment(date).add(nbDays, 'days').format(format)
  }

  /**
   * Ajout nbMonths et formate la date
   * @param date
   * @param nbMonths
   */
  addMonthsAndFormat = (date: Date|string|undefined, nbMonths: number): string => {
    return moment(date).add(nbMonths, 'months').format()
  }

  /**
   * Ajout nbYears et formate la date
   * @param date
   * @param nbYears
   */
  addYearsAndFormat = (date: Date|string|undefined, nbYears: number): string => {
    return moment(date).add(nbYears, 'years').format()
  }

  /**
   * Supprime nbDays jours et formate la date
   * @param date
   * @param nbDays
   */
  subtractDaysAndFormat = (date: Date|string|undefined, nbDays: number): string => {
    return moment(date).subtract(nbDays, 'days').format()
  }

  /**
   * Supprime nbMonths jours et formate la date
   * @param date
   * @param nbMonths
   */
  subtractMonthsAndFormat = (date: Date|string|undefined, nbMonths: number): string => {
    return moment(date).subtract(nbMonths, 'months').format()
  }

  /**
   * Supprime nbYears années et formate la date
   * @param date
   * @param nbYears
   */
  subtractYearsAndFormat = (date: Date|string|undefined, nbYears: number): string => {
    return moment(date).subtract(nbYears, 'years').format()
  }

  /**
   * Vérifie que first précède second
   * @param first
   * @param second
   */
  isBefore = (first: Date|string|undefined, second: Date|string|undefined): boolean => {
    return moment(first).isBefore(moment(second))
  }

  /**
   * Vérifie que first précède ou est égale à second
   * @param first
   * @param second
   */
  isSameOrBefore = (first: Date|string|undefined, second: Date|string|undefined): boolean => {
    return moment(first).isSameOrBefore(moment(second))
  }

  /**
   * Vérifie que first est après second
   * @param first
   * @param second
   */
  isAfterWithoutTime = (first: Date|string|undefined, second: Date|string|undefined): boolean => {
    return moment(first).format('YYYY-MM-DD') > moment(second).format('YYYY-MM-DD')
  }

  /**
   * Vérifie que first est avant ou égal à second
   * @param first
   * @param second
   */
  isBeforeOrEqualWithoutTime = (first: Date|string|undefined, second: Date|string|undefined): boolean => {
    return moment(first).format('YYYY-MM-DD') <= moment(second).format('YYYY-MM-DD')
  }

  /**
   * Vérifie que first est avant à second
   * @param first
   * @param second
   */
  isBeforeWithoutTime = (first: Date|string|undefined, second: Date|string|undefined): boolean => {
    return moment(first).format('YYYY-MM-DD') < moment(second).format('YYYY-MM-DD')
  }

  /**
   * Ajoute le format du temps donné en paramètre (seconde, minute, etc..)
   * @param timeDifference - Différence en milliseconde entre une date et aujourd'hui
   * @param timeDifferenceFormat - Différence en milliseconde entre une date et aujourd'hui avec un format
   * @param timeFormat
   * @param currentDate - date actuelle
   * @param logDate - date du passé que l'on compare
   * @param label - Label affiché (seconde, minute ...)
   */
  addTimeElapsedFormat = (timeDifference: number,
    timeDifferenceFormat: string,
    timeFormat: number,
    currentDate: any,
    logDate: any,
    label: string) => {
    let time = currentDate.diff(logDate, timeDifferenceFormat) + label

    if (timeDifference > timeFormat * 2) {
      time += 's'
    }
    return time
  }

  /**
   * Récupère le temps écoulé depuis une date donnée en paramètre
   * @param date
   */
  getTimeElapsed = (date: Date) => {
    const logDate = moment(date)
    const currentDate = moment()

    const timeDifference = currentDate.diff(logDate)

    if (timeDifference < MINUTE_IN_MILLISECONDS) {
      return this.addTimeElapsedFormat(timeDifference, SECONDS, SECOND_IN_MILLISECONDS, currentDate, logDate, ' seconde')
    } else if (timeDifference < HOUR_IN_MILLISECONDS) {
      return this.addTimeElapsedFormat(timeDifference, MINUTES, MINUTE_IN_MILLISECONDS, currentDate, logDate, ' minute')
    } else if (timeDifference < DAY_IN_MILLISECONDS) {
      return this.addTimeElapsedFormat(timeDifference, HOURS, HOUR_IN_MILLISECONDS, currentDate, logDate, ' heure')
    } else if (timeDifference < MONTH_IN_MILLISECONDS) {
      return this.addTimeElapsedFormat(timeDifference, DAYS, DAY_IN_MILLISECONDS, currentDate, logDate, ' jour')
    } else if (timeDifference < YEAR_IN_MILLISECONDS) {
      return currentDate.diff(logDate, MONTHS) + ' mois'
    } else {
      return this.addTimeElapsedFormat(timeDifference, YEARS, MONTH_IN_MILLISECONDS, currentDate, logDate, ' année')
    }
  }

  /**
   * Récupère le nombre de jours entre maintenant et la date en paramètre
   * @param date
   */
  nbDaysFromNow = (date: Date|string|number|undefined): number => {
    return moment().diff(moment(date, 'x'), 'days')
  }

  /**
   * Vérifie que la date est comprise entre 2 dates
   */
  isBetween = (date: Date|string, startDate: string, endDate: string): boolean => {
    return moment(date).isBetween(moment(startDate), this.formatToEndOfDay(endDate))
  }

  /**
   * Transforme une date au format dd/mm/yyyy en objet Date
   * @param date
   */
  transformFrFormatIntoDate = (date: string) => {
    const [day, month, year] = date.split('/')
    return new Date(+year, +month - 1, +day)
  }

  /**
   * Vérifie que first est après second
   * @param first
   * @param second
   */
  isAfter = (first: Date|string|undefined, second: Date|string|undefined): boolean => {
    return moment(first).isAfter(moment(second))
  }

  /**
   * Retourne le millesime formaté
   * @param date
   * @returns
   */
  formatMillesimeYear = (date: Date | string | undefined): string|undefined =>
    this.isAfter(date, '1999-12-31') ? this.formateDateToFormat(date, 'yyyy')?.substring(2) ?? '' : undefined
}

export default new DateUtils()
