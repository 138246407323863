import React, { FC, ReactNode, useEffect, useState } from 'react'
import { EventType, FieldProps, FieldStatus } from '../FormInterfaces'
import { buildEventType, TransactionDocument, ErrorField, isFilled, DOCUMENT_FORMATS, downloadFile } from '../../index'
import { faInfoCircle, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Message from '../../../src/constants/Message'
import { resetError as resetErrorUtils, resetStateError } from '../../utils/formUtils'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FileInpiResponse } from '../../interfaces/Common'

interface FileBrowserFieldProps extends FieldProps {
  inputId:string,
  ulClassName?:string,
  label?:ReactNode,
  className?: string,
  buttonLabel:ReactNode,
  value?:File[]|TransactionDocument[]|FileInpiResponse[], // ne pas la passer si on veut avoir le loader
  onChange?:(event:EventType) => void,
  onDelete?:(event:EventType, index:number) => void,
  fieldStatus?:FieldStatus | any,
  acceptApplication?:string,
  maxNumberFile?:number,
  required?:boolean,
  handleDownload?: (document:TransactionDocument) => void,
  readonly?: boolean,
  isSavedFilesDeletable?:boolean,
  nameFieldStatus?: string
  errorMessage?: string,
  maxFileSize?: number,
  disabled?: boolean,
  addToRefs?: (ref, inputId?: string) => void,
  classNameButton?: string,
  informationDoc?: boolean,
  placementTooltip?: string,
  classNameLabel?: string,
  fromBo?: boolean
}

const FileBrowserField:FC<FileBrowserFieldProps> = ({
  inputId,
  ulClassName,
  className = '',
  label,
  buttonLabel,
  value = null,
  fieldStatus = {},
  acceptApplication,
  onChange,
  onDelete,
  required = false,
  maxNumberFile,
  handleDownload,
  readonly = false,
  isSavedFilesDeletable = true,
  nameFieldStatus,
  resetError,
  dispatch,
  errorMessage,
  maxFileSize,
  disabled,
  classNameButton,
  addToRefs,
  informationDoc = false,
  placementTooltip = 'right',
  classNameLabel,
  fromBo = false
}) => {
  const [internalError, setInternalError] = useState<string|undefined>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [showTooltip, changeShowTooltip] = useState<boolean>(false)
  const [formatName, setFormatName] = useState<string[]|undefined>([])

  const handleChange = (event:EventType) => {
    const { files } = event.target
    const currentFile = files && files[0]

    /** On clean l'erreur du store au changement */
    if (dispatch && resetError) {
      resetErrorUtils(dispatch, resetError, fieldStatus, nameFieldStatus || inputId)
    } else if (resetError) {
      resetStateError(resetError, fieldStatus, nameFieldStatus || inputId)
    }

    if (currentFile && acceptApplication && acceptApplication.indexOf(currentFile.type) === -1) {
      setInternalError(errorMessage || Message.file_invalid_type)
    } else {
      const messageError: string = `Le poids du fichier est supérieur à ${Math.floor(maxFileSize / 10e5)}Mo`
      if (maxFileSize && currentFile.size > maxFileSize) {
        setInternalError(errorMessage || messageError)
      } else {
        setInternalError(undefined)
        value && setIsLoading(true)
        onChange && onChange(buildEventType(inputId, files && (maxNumberFile && maxNumberFile > 1 ? files : files[0])))
      }
    }
  }

  const handleDelete = (index:number) => {
    onDelete && onDelete(buildEventType(inputId, value[index]), index)
  }

  const downloadLocalFile = (document: any) => {
    downloadFile({
      data: document.file,
      filename: document.name
    })
  }

  /**
   * Permet d'afficher le statut du formulaire à la place
   * de l'erreur interne en cas de changement de fieldStatus
   * Enlève l'animation de chargement si il y a une erreur ou si le ou les fichiers
   * on bien été enregistrés
   */
  useEffect(() => {
    if (fieldStatus[nameFieldStatus || inputId]) {
      setInternalError(fieldStatus[nameFieldStatus || inputId])
    }

    if (value && isLoading) {
      setIsLoading(false)
    }
  }, [fieldStatus, value])

  // Connaitre le nom du format téléchargeable
  useEffect(() => {
    const arrayAcceptApplication : string[]|undefined = acceptApplication?.split(',')

    if (arrayAcceptApplication) {
      arrayAcceptApplication.map((format) => {
        if (DOCUMENT_FORMATS.DOC.includes(format)) {
          if (!formatName?.includes('doc')) {
            setFormatName([...formatName, 'docx'])
          }
        } else if (DOCUMENT_FORMATS.CSV.includes(format)) {
          if (!formatName?.includes('csv')) {
            setFormatName([...formatName, 'csv'])
          }
        } else {
          const newFormat: string | undefined = format.split('/').pop()?.trim()
          formatName.push(newFormat)
        }
      })
    }
  }, [acceptApplication])

  return (
    <div
      className={`form-group ${readonly ? '' : 'file-browser-field'} ${className}`} ref={(inputRef) => addToRefs && addToRefs(inputRef, inputId)}
    >
      {label &&
        <label className='form-label d-block' htmlFor={inputId}>
          {label}
          {required && <span className='text-danger'> *</span>}
          {informationDoc && acceptApplication &&
            <OverlayTrigger
              placement={placementTooltip}
              show={showTooltip}
              overlay={
                <Popover
                  id='popover-positioned-bottom'
                  onMouseEnter={() => changeShowTooltip(true)}
                  onMouseLeave={() => changeShowTooltip(false)}
                >
                  <Popover.Content>
                    <p>{formatName.length > 1
                      ? `Le fichier doit être à l'un de ses formats: ${formatName.join(', ')} ${maxFileSize ? ` et son poids ne doit pas dépasser ${Math.floor(maxFileSize / 10e5)}Mo` : ''}`
                      : `Le fichier doit être au format ${formatName} ${maxFileSize ? ` et son poids ne doit pas dépasser ${Math.floor(maxFileSize / 10e5)}Mo` : ''}`}
                    </p>
                  </Popover.Content>
                </Popover>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className='ml-2' onMouseEnter={() => changeShowTooltip(true)} onMouseLeave={() => changeShowTooltip(false)} />
            </OverlayTrigger>}
        </label>}
      {isFilled(value) &&
        <ul className={`${ulClassName || ''}`}>
          {
            value?.map((oneDocument, index: number) => (
              <li key={index}>
                <div className='d-flex align-items-center'>
                  {handleDownload
                    ? (
                      <a
                        className={`btn btn-link text-primary text-left ${classNameButton}`}
                        onClick={() => oneDocument.internalName ? handleDownload(oneDocument) : downloadLocalFile(oneDocument)}
                      >{oneDocument.name || oneDocument.filename}
                      </a>
                    )
                    : (
                      <span className='d-block one-line-ellipsis' title={oneDocument.name || oneDocument.filename}>
                        {oneDocument.name || oneDocument.filename}
                      </span>
                    )}
                  {(onDelete && (!fromBo || !(oneDocument as TransactionDocument).isAddedByFo) && !readonly && (isSavedFilesDeletable || !oneDocument.internalName)) && (
                    <span className='icon-delete cursor-pointer text-danger' onClick={() => !disabled && handleDelete(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  )}
                </div>
              </li>
            ))
          }
        </ul>}
      {(
        !maxNumberFile || !value ||
      value.length < maxNumberFile) && !readonly && (
        <div className='d-flex align-items-center'>
          <input
            type='file'
            className='d-none'
            id={inputId}
            onChange={handleChange}
            accept={acceptApplication}
            multiple={maxNumberFile ? maxNumberFile > 1 : false}
            required={required}
            disabled={disabled || isLoading}
            onClick={(event) => { event.target.value = null }}
          />
          <label htmlFor={inputId} className={`m-0 d-flex justify-content-start ${classNameLabel}`}>
            {buttonLabel}
            {isLoading && <FontAwesomeIcon className='loader mr-1 m-auto' icon={faSpinner} />}
          </label>
        </div>
      )}

      <ErrorField message={internalError} />
    </div>
  )
}

export default FileBrowserField
